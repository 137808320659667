///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const convertToPacificTime = (inputTime: string, inputTimezone: string) => {
  // Split the input time into hours and minutes
  const [hours, minutes] = inputTime.split(':').map(Number)
  // Define timezone offsets for each timezone
  const timezoneOffsets: TsInterface_UnspecifiedObject = {
    et: -3,
    ct: -2,
    mt: -1,
    pt: 0, // Pacific Time is not adjusted
  }
  // Calculate the Pacific Time
  let pacificHours = hours + timezoneOffsets[inputTimezone]
  let pacificMinutes = minutes
  // Adjust hours for negative values or overflow
  if (pacificHours < 0) {
    pacificHours += 24
  } else if (pacificHours >= 24) {
    pacificHours -= 24
  }
  // Format the result
  const pacificTime = `${pacificHours.toString().padStart(2, '0')}:${pacificMinutes.toString().padStart(2, '0')}`
  return pacificTime
}

export const timezoneOptions: TsInterface_UnspecifiedObject = {
  et: { key: 'et', value: rLIB('Eastern Time') },
  ct: { key: 'ct', value: rLIB('Central Time') },
  mt: { key: 'mt', value: rLIB('Mountain Time') },
  pt: { key: 'pt', value: rLIB('Pacific Time') },
}

export const googleSheetScraperOptions: TsInterface_UnspecifiedObject = {
  test: {
    value: rLIB('Test'),
    key: 'test',
  },
  material_orders: {
    value: rLIB('Material Orders'),
    key: 'material_orders',
    disabled: true,
  },
  projects: {
    value: rLIB('Projects'),
    key: 'projects',
    disabled: true,
  },
}

export const formInputs_GoogleSheetScraper: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('Google Sheet Name'),
    required: true,
  },
  google_sheet_id: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'google_sheet_id',
    label: rLIB('Google Sheet ID'),
    required: true,
  },
  google_sheet_range: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'google_sheet_range',
    label: rLIB('Google Sheet Range'),
    required: true,
  },
  timestamp_email_scheduled: {
    data_type: 'string',
    input_type: 'timestamp_time',
    key: 'timestamp_email_scheduled',
    label: rLIB('Send Time'),
    required: true,
  },
  timestamp_email_scheduled_timezone: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'timestamp_email_scheduled_timezone',
    label: rLIB('Timezone'),
    options: objectToArray(timezoneOptions),
    required: true,
  },
  parser_procedure: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'parser_procedure',
    label: rLIB('Parser'),
    options: objectToArray(googleSheetScraperOptions),
    required: true,
  },
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:
			[ ] Typescript - 1 instance of any

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material/'
import {
  TsInterface_DynamicAttributes,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormSettings,
  TsType_InputChangeCallback,
} from 'rfbp_core/components/form'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  formAdditionalData: TsInterface_FormAdditionalData
  formData: TsInterface_FormData
  formHooks: TsInterface_FormHooksObject
  formInput: TsInterface_FormInput
  formSettings: TsInterface_FormSettings
  inputChangeCallback: TsType_InputChangeCallback
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

export const listOfUnitedStates = {
  AL: { key: 'AL', value: 'AL' },
  AK: { key: 'AK', value: 'AK' },
  AZ: { key: 'AZ', value: 'AZ' },
  AR: { key: 'AR', value: 'AR' },
  CA: { key: 'CA', value: 'CA' },
  CO: { key: 'CO', value: 'CO' },
  CT: { key: 'CT', value: 'CT' },
  DC: { key: 'DC', value: 'DC' },
  DE: { key: 'DE', value: 'DE' },
  FL: { key: 'FL', value: 'FL' },
  GA: { key: 'GA', value: 'GA' },
  HI: { key: 'HI', value: 'HI' },
  ID: { key: 'ID', value: 'ID' },
  IL: { key: 'IL', value: 'IL' },
  IN: { key: 'IN', value: 'IN' },
  IA: { key: 'IA', value: 'IA' },
  KS: { key: 'KS', value: 'KS' },
  KY: { key: 'KY', value: 'KY' },
  LA: { key: 'LA', value: 'LA' },
  ME: { key: 'ME', value: 'ME' },
  MD: { key: 'MD', value: 'MD' },
  MA: { key: 'MA', value: 'MA' },
  MI: { key: 'MI', value: 'MI' },
  MN: { key: 'MN', value: 'MN' },
  MS: { key: 'MS', value: 'MS' },
  MO: { key: 'MO', value: 'MO' },
  MT: { key: 'MT', value: 'MT' },
  NE: { key: 'NE', value: 'NE' },
  NV: { key: 'NV', value: 'NV' },
  NH: { key: 'NH', value: 'NH' },
  NJ: { key: 'NJ', value: 'NJ' },
  NM: { key: 'NM', value: 'NM' },
  NY: { key: 'NY', value: 'NY' },
  NC: { key: 'NC', value: 'NC' },
  ND: { key: 'ND', value: 'ND' },
  OH: { key: 'OH', value: 'OH' },
  OK: { key: 'OK', value: 'OK' },
  OR: { key: 'OR', value: 'OR' },
  PA: { key: 'PA', value: 'PA' },
  RI: { key: 'RI', value: 'RI' },
  SC: { key: 'SC', value: 'SC' },
  SD: { key: 'SD', value: 'SD' },
  TN: { key: 'TN', value: 'TN' },
  TX: { key: 'TX', value: 'TX' },
  UT: { key: 'UT', value: 'UT' },
  VT: { key: 'VT', value: 'VT' },
  VA: { key: 'VA', value: 'VA' },
  WA: { key: 'WA', value: 'WA' },
  WV: { key: 'WV', value: 'WV' },
  WI: { key: 'WI', value: 'WI' },
  WY: { key: 'WY', value: 'WY' },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const MultipleChoiceSelectStateUSAAbbreviated = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_formData: TsInterface_FormData = getProp(props, 'formData', {})
  let pr_formInput: TsInterface_FormInput = getProp(props, 'formInput', {})
  let pr_formSettings: TsInterface_FormSettings = getProp(props, 'formSettings', {})
  let pr_inputChangeCallback: TsType_InputChangeCallback = props.inputChangeCallback

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const returnLabelText = (): JSX.Element => {
    let labelJSX = <></>
    if (pr_formInput['required'] === true) {
      labelJSX = (
        <>
          {pr_formInput['label']}
          {' *'}
        </>
      )
    } else {
      labelJSX = <>{pr_formInput['label']}</>
    }
    return labelJSX
  }

  // Generate JSX

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    // Dynamic Class Name
    let dynamicClassName = ''
    // Dynamic Attributes
    let dynamicAttributes: TsInterface_DynamicAttributes = {}
    if (pr_formInput['required'] === true) {
      dynamicAttributes['required'] = true
    }
    if (
      pr_formInput['required'] === true &&
      pr_formSettings.highlight_missing === true &&
      (pr_formData == null || pr_formData[pr_formInput['key']] == null || pr_formData[pr_formInput['key']] === '')
    ) {
      dynamicAttributes['error'] = true
    }
    if (pr_formInput['disabled'] === true) {
      dynamicAttributes['disabled'] = true
    }
    // Form Input JSX
    let componentJSX = (
      <Box>
        <div style={{ width: '100%', height: '16px' }}></div>
        <FormControl
          className={dynamicClassName}
          fullWidth
        >
          <InputLabel
            shrink={true}
            id={pr_formInput['key']}
          >
            {returnLabelText()}
          </InputLabel>
          <Select
            color="primary"
            value={pr_formData[pr_formInput.key] || ''}
            id={pr_formInput['key']}
            // label={ formInput["label"] }
            label={returnLabelText()}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                pr_inputChangeCallback(pr_formInput.key, event.target.value, true)
              }
            }}
            variant="outlined"
            notched={true}
            // InputProps={{
            // 	startAdornment: rJSX_InputAdornment( formInput, "start" ),
            // 	endAdornment: rJSX_InputAdornment( formInput, "end" ),
            // }}
            {...dynamicAttributes}
          >
            {objectToArray(listOfUnitedStates).map((option) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ width: '100%', height: '8px' }}></div>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

export const utilityCompaniesList = {
  all_regions: {
    // San Antonio
    'AEP Texas': { key: 'AEP Texas', value: 'AEP Texas' },
    'Austin Energy': { key: 'Austin Energy', value: 'Austin Energy' },
    'Bandera Electric Cooperative': { key: 'Bandera Electric Cooperative', value: 'Bandera Electric Cooperative' },
    'Bluebonnet Electric Cooperative': { key: 'Bluebonnet Electric Cooperative', value: 'Bluebonnet Electric Cooperative' },
    'Centerpoint Energy': { key: 'Centerpoint Energy', value: 'Centerpoint Energy' },
    'CPS Energy': { key: 'CPS Energy', value: 'CPS Energy' },
    'CTEC': { key: 'CTEC', value: 'CTEC' },
    'Denton Municipal Electric': { key: 'Denton Municipal Electric', value: 'Denton Municipal Electric' },
    'Entergy': { key: 'Entergy', value: 'Entergy' },
    'Fayette Electric Cooperative': { key: 'Fayette Electric Cooperative', value: 'Fayette Electric Cooperative' },
    'Giddings Light & Power System': { key: 'Giddings Light & Power System', value: 'Giddings Light & Power System' },
    'GVEC': { key: 'GVEC', value: 'GVEC' },
    'Karnes Electric Cooperative': { key: 'Karnes Electric Cooperative', value: 'Karnes Electric Cooperative' },
    'Lexington Municipal Electric Department': { key: 'Lexington Municipal Electric Department', value: 'Lexington Municipal Electric Department' },
    'Luling Utilities': { key: 'Luling Utilities', value: 'Luling Utilities' },
    'Medina Electric Cooperative': { key: 'Medina Electric Cooperative', value: 'Medina Electric Cooperative' },
    'Oncor': { key: 'Oncor', value: 'Oncor' },
    'Pedernales Electric Coop': { key: 'Pedernales Electric Coop', value: 'Pedernales Electric Coop' },
    'Smithville Utilities Department': { key: 'Smithville Utilities Department', value: 'Smithville Utilities Department' },
    'TNMP': { key: 'TNMP', value: 'TNMP' },
    'Tri County Electric Coop': { key: 'Tri County Electric Coop', value: 'Tri County Electric Coop' },
    'other': { key: 'other', value: 'Other, please specify' },
  },
  san_antonio: {
    'AEP Texas': { key: 'AEP Texas', value: 'AEP Texas' },
    'Austin Energy': { key: 'Austin Energy', value: 'Austin Energy' },
    'Bandera Electric Cooperative': { key: 'Bandera Electric Cooperative', value: 'Bandera Electric Cooperative' },
    'Bluebonnet Electric Cooperative': { key: 'Bluebonnet Electric Cooperative', value: 'Bluebonnet Electric Cooperative' },
    'Centerpoint Energy': { key: 'Centerpoint Energy', value: 'Centerpoint Energy' },
    'CPS Energy': { key: 'CPS Energy', value: 'CPS Energy' },
    'CTEC': { key: 'CTEC', value: 'CTEC' },
    'Denton Municipal Electric': { key: 'Denton Municipal Electric', value: 'Denton Municipal Electric' },
    'Entergy': { key: 'Entergy', value: 'Entergy' },
    'Fayette Electric Cooperative': { key: 'Fayette Electric Cooperative', value: 'Fayette Electric Cooperative' },
    'Giddings Light & Power System': { key: 'Giddings Light & Power System', value: 'Giddings Light & Power System' },
    'GVEC': { key: 'GVEC', value: 'GVEC' },
    'Karnes Electric Cooperative': { key: 'Karnes Electric Cooperative', value: 'Karnes Electric Cooperative' },
    'Lexington Municipal Electric Department': { key: 'Lexington Municipal Electric Department', value: 'Lexington Municipal Electric Department' },
    'Luling Utilities': { key: 'Luling Utilities', value: 'Luling Utilities' },
    'Medina Electric Cooperative': { key: 'Medina Electric Cooperative', value: 'Medina Electric Cooperative' },
    'Oncor': { key: 'Oncor', value: 'Oncor' },
    'Pedernales Electric Coop': { key: 'Pedernales Electric Coop', value: 'Pedernales Electric Coop' },
    'Smithville Utilities Department': { key: 'Smithville Utilities Department', value: 'Smithville Utilities Department' },
    'TNMP': { key: 'TNMP', value: 'TNMP' },
    'Tri County Electric Coop': { key: 'Tri County Electric Coop', value: 'Tri County Electric Coop' },
    'other': { key: 'other', value: 'Other, please specify' },
  },
}

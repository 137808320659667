import { DatabaseRef_TrailerAssignmentHistory_Document } from 'rfbp_aux/services/database_endpoints/operations/trailer_assignment_history'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseDeleteDocument } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
export const tableSettings_TrailerAssignmentHistoryData: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'timestamp_start',
  sortable: false,
}

export const tableColumns_TrailerAssignmentHistoryData = {
  manage: TableCellManage({
    delete: {
      icon: <Icon icon="trash" />,
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Delete Custody Record'),
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: rLIB('Delete'),
            text: rLIB('Are you sure you want to delete this custody record?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                  DatabaseDeleteDocument(DatabaseRef_TrailerAssignmentHistory_Document(res_GCK.clientKey, rowData.key as string))
                    .then((res_DDD) => {
                      resolve(res_DDD)
                    })
                    .catch((rej_DDD) => {
                      reject(rej_DDD)
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                    })
                })
              })
            },
          },
        })
      },
    },
  }),
  associated_user_name: TableCellBasic('associated_user_name', rLIB('Employee'), 'associated_user_name'),
  timestamp_start: TableCellTimestamp('timestamp_start', rLIB('Custody Start Date'), 'timestamp_start', 'D MMM YYYY h:mm a', true),
  timestamp_end: TableCellTimestamp('timestamp_end', rLIB('Custody End Date'), 'timestamp_end', 'D MMM YYYY h:mm a', true),
}

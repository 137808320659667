///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Trailers_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main')
}

export const DatabaseRef_Trailer_Document = (clientKey: string, trailerKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main', trailerKey)
}

export const DatabaseRef_ActiveTrailers_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_Trailers_Search = (clientKey: string, searchProperty: string, searchValue: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: searchProperty, comparator: '>=', value: searchValue },
    { prop: searchProperty, comparator: '<=', value: searchValue + '~' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main'), queryOperatorsArray, orderByArray, {}, limit)
}

// Files
export const DatabaseRef_TrailerFiles_Collection = (clientKey: string, trailerKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main', trailerKey, 'files')
}

export const DatabaseRef_TrailerFile_Document = (clientKey: string, trailerKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main', trailerKey, 'files', fileKey)
}

// Forum
export const DatabaseRef_TrailerForumThreads_Collection = (clientKey: string, trailerKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main', trailerKey, 'forum')
}

export const DatabaseRef_TrailerForumThread_Document = (clientKey: string, trailerKey: string, threadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main', trailerKey, 'forum', threadKey)
}

export const DatabaseRef_TrailerForumMessages_Collection = (clientKey: string, trailerKey: string, threadKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main', trailerKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_TrailerForumMessage_Document = (
  clientKey: string,
  trailerKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main', trailerKey, 'forum', threadKey, 'messages', messageKey)
}

// Trailers for specific garage
export const DatabaseRef_TrailersFilterByGarage_Query = (clientKey: string, garageKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_garage_key', comparator: '==', value: garageKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

// Trailers that are parked
export const DatabaseRef_TrailersFilterByParked_Query = (clientKey: string, parkStatus: boolean): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'field_status', comparator: '==', value: parkStatus }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

// Trailers for specific garage and parked
export const DatabaseRef_TrailersFilterByParkedAndGarage_Query = (clientKey: string, garageKey: string, parkStatus: boolean): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_garage_key', comparator: '==', value: garageKey },
    { prop: 'field_status', comparator: '==', value: parkStatus },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'trailers', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

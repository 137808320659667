///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import { useContext, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_CompanyDocuments_Collection, DatabaseRef_CompanyDocuments_Document } from 'rfbp_aux/services/database_endpoints/operations/documents'
import { StorageRef_CompanyDocumentsFile } from 'rfbp_aux/services/storage_endpoints/company_documents'
import { FileSystemBasic, TsInterface_FileSystemSettings } from 'rfbp_core/components/file_system/file_system_basic'
import { Context_RootData_ClientUser } from 'rfbp_core/services/context'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseCompanyDocumentsPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_COMPANY_DOCUMENTS: JSX.Element = <Trans>Company Documents</Trans>
const se_COMPANY_DOCUMENTS: string = 'Company Documents'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_COMPANY_DOCUMENTS
  }, [])

  // Functions

  // JSX Generation
  const rJSX_FileSystem = (): JSX.Element => {
    let fileSystemSettings: TsInterface_FileSystemSettings = {
      allow_file_archiving: false,
      allow_file_movement: false,
      allow_file_unarchiving: false,
      allow_file_uploads: false,
      allow_folder_creation: false,
      allow_folder_deletion: false,
      allow_folder_movement: false,
      allow_folder_rename: false,
      allow_link_creation: false,
      archive_filter_visible: false,
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role === 'admin') {
      fileSystemSettings = {
        allow_file_archiving: true,
        allow_file_movement: true,
        allow_file_unarchiving: true,
        allow_file_uploads: true,
        allow_folder_creation: true,
        allow_folder_deletion: true,
        allow_folder_movement: true,
        allow_folder_rename: true,
        allow_link_creation: true,
        archive_filter_visible: true,
      }
    }
    let systemJSX = (
      <Box>
        <FileSystemBasic
          fileSystemCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_CompanyDocuments_Collection(clientKey)
          }}
          fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
            return DatabaseRef_CompanyDocuments_Document(clientKey, fileKey)
          }}
          fileSystemStorageEndpoint={(clientKey, fileName) => {
            return StorageRef_CompanyDocumentsFile(clientKey, fileName)
          }}
          fileSystemHardCodedFolders={{}}
          fileSystemSettings={fileSystemSettings}
        />
      </Box>
    )
    return systemJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_COMPANY_DOCUMENTS}
        pageKey={pageKey}
        content={<Box>{rJSX_FileSystem()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

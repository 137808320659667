import { Box, Tooltip, Typography } from '@mui/material'
import { CSSTransition } from 'react-transition-group'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { rJSX_CheckBoxes, rJSX_Slider } from './adjustable_components'

const sectionContentWidth = '60%'

export const rJSX_EquipmentSection = (
  batteryBrand: string,
  inverterBrand: string,
  moduleBrand: string,
  configBundle: TsInterface_UnspecifiedObject,
  shouldRender: boolean,
): JSX.Element => {
  const maxBatteries = 4
  const minBatteries = 0
  const sliderStepSize = 1
  const defaultValue = getProp(configBundle['config'], 'battery_quantity', 0)
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h5">Module Brand: {moduleBrand}</Typography>
      <br />
      <Typography variant="h5">Inverter Brand: {inverterBrand}</Typography>
      <br />
      <Typography variant="h5">Battery Brand: {batteryBrand}</Typography>
      <Box sx={{ width: sectionContentWidth, marginTop: 3 }}>
        {rJSX_Slider('Number of Batteries', minBatteries, maxBatteries, sliderStepSize, defaultValue, configBundle, 'battery_quantity', shouldRender)}
      </Box>
    </Box>
  )
}

export const rJSX_SystemSizeSection = (configBundle: TsInterface_UnspecifiedObject, shouldRender: boolean): JSX.Element => {
  const minOffset = 0
  const maxOffset = 200
  const offsetStepSize = 1
  const defaultOffset = getProp(configBundle['config'], 'offset', 100)
  const editProp = 'offset'
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h5">Size: XX</Typography>
      <br />
      <Typography variant="h5">Offset: XX (KW/h)</Typography>
      <Box sx={{ width: sectionContentWidth, marginTop: 3 }}>
        {rJSX_Slider('Offset Percentage', minOffset, maxOffset, offsetStepSize, defaultOffset, configBundle, editProp, shouldRender)}
      </Box>
    </Box>
  )
}

export const rJSX_WarrantySection = (configBundle: TsInterface_UnspecifiedObject, shouldRender: boolean): JSX.Element => {
  const boxConfigs: TsInterface_UnspecifiedObject = { extended_warranty: { label: 'Extended Warranty?', isCheckedByDefault: true } }
  return (
    <Box sx={{ width: sectionContentWidth, display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
      <Typography variant="h5">Equipment Warranty</Typography>
      <Typography variant="h6">XX</Typography>
      <Tooltip
        title="Inverters come with a 12.5 year manufacturer warranty. The standard price includes an extended warranty for an additional 12.5 years"
        placement="top"
      >
        {rJSX_CheckBoxes(boxConfigs, true, configBundle, 'extended_warranty', shouldRender)}
      </Tooltip>
      <br />
      <Typography variant="h5">Workmanship Warranty</Typography>
      <Typography variant="h6">XX</Typography>
      <br />
      <Typography variant="h5">Production Guarantee (Lease)</Typography>
      <Typography variant="h6">XX</Typography>
    </Box>
  )
}

export const rJSX_FinancingSection = (configBundle: TsInterface_UnspecifiedObject, shouldRender: boolean): JSX.Element => {
  const minDuration = 12.5
  const maxDuration = 25
  const offsetStepSize = 12.5
  const defaultDuration = getProp(configBundle['config'], 'financing_duration', 25)
  const editProp = 'financing_duration'
  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h5">Financing Duration</Typography>
      <Box sx={{ width: sectionContentWidth, marginTop: 3 }}>
        {rJSX_Slider('Length (years)', minDuration, maxDuration, offsetStepSize, defaultDuration, configBundle, editProp, shouldRender)}
      </Box>
    </Box>
  )
}

export const rJSX_SiteDetailsSection = (
  showSiteDetailsAdvancedOptions: boolean,
  configBundle: TsInterface_UnspecifiedObject,
  shouldRender: boolean,
): JSX.Element => {
  const defaultRoofBoxConfigs: TsInterface_UnspecifiedObject = { remove_roof: { label: 'Replace Roof?', isCheckedByDefault: false } }
  const defaultTreeBoxConfigs: TsInterface_UnspecifiedObject = { remove_trees: { label: 'Remove Trees?', isCheckedByDefault: false } }

  const rJSX_SiteDetailsAdvancedOptions = (showSiteDetailsAdvancedOptions: boolean): JSX.Element => {
    const defaultEscalatorBoxConfigs: TsInterface_UnspecifiedObject = {
      no_change: { label: '0%', isCheckedByDefault: false },
      one_nine: { label: '1.9%', isCheckedByDefault: false },
      two_nine: { label: '2.9%', isCheckedByDefault: false },
    }

    const defaultAmenityBoxConfigs: TsInterface_UnspecifiedObject = {
      ev: { label: 'EV', isCheckedByDefault: false },
      pool: { label: 'Pool', isCheckedByDefault: false },
      hot_tub: { label: 'Hot Tub', isCheckedByDefault: false },
    }

    const defaultMountBoxConfigs: TsInterface_UnspecifiedObject = {
      ground: { label: 'Ground Mount', isCheckedByDefault: false },
      car_port: { label: 'Car Port', isCheckedByDefault: false },
      custom: { label: 'Custom', isCheckedByDefault: false },
    }

    const advancedOptions: JSX.Element = (
      <Box
        className="tw-transition-3"
        sx={{ width: '100%' }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'space-between', alignItems: 'start', marginX: 'auto' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginX: 'auto' }}>
            <Typography>Escalator</Typography>
            {rJSX_CheckBoxes(defaultEscalatorBoxConfigs, false, configBundle, 'escalator', shouldRender)}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginX: 'auto' }}>
            <Typography>Mounts</Typography>
            {rJSX_CheckBoxes(defaultMountBoxConfigs, false, configBundle, 'mount', shouldRender)}
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginX: 'auto' }}>
            <Typography>Other Amenities</Typography>
            {rJSX_CheckBoxes(defaultAmenityBoxConfigs, false, configBundle, 'amenities', shouldRender)}
          </Box>
        </Box>
      </Box>
    )

    const sectionJSX = (
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CSSTransition
          in={showSiteDetailsAdvancedOptions}
          // TODO: get this animation working
          timeout={0}
          classNames="fade"
          unmountOnExit
        >
          {advancedOptions}
        </CSSTransition>
      </Box>
    )

    return sectionJSX
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ alignItems: 'flex-start' }}>
        <Tooltip
          title="If your roof will need to be replaced in the next 3-5 years, it's recommended to replace your roof now"
          placement="top"
        >
          {rJSX_CheckBoxes(defaultRoofBoxConfigs, false, configBundle, 'remove_roof', shouldRender)}
        </Tooltip>
        <Tooltip
          title="Will there be trees obstructing your panels that will need to be removed?"
          placement="top"
        >
          {rJSX_CheckBoxes(defaultTreeBoxConfigs, false, configBundle, 'remove_trees', shouldRender)}
        </Tooltip>
      </Box>
      {rJSX_SiteDetailsAdvancedOptions(showSiteDetailsAdvancedOptions)}
    </Box>
  )
}

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_HoaFile = (clientKey: string, hoaKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/hoas/' + hoaKey + '/files/' + fileName)
}

export const StorageRef_Hoa_MessageThread_File = (clientKey: string, hoaKey: string, threadKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/hoas/' + hoaKey + '/threads/' + threadKey + '/files/' + fileName)
}

///////////////////////////////
// Imports
///////////////////////////////

import { doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_MaterialUserPoNumber_Document = (clientKey: string, userAbbreviationYearKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'materials', 'po_number_counters', 'main', userAbbreviationYearKey)
}

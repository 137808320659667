///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Button, Dialog, DialogContent, IconButton, TextField, Toolbar, Typography } from '@mui/material'
import React, { useState } from 'react'
import { DatabaseRef_Vehicle_Document } from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Component
///////////////////////////////

interface ParkedLocationCustomDialogProps {
  us_vehicle: { key: string }
  tableHooks: {
    uc_RootData_ClientKey: string
    uc_setRootData_ClientKey: (key: string) => void
    ur_forceRerender: () => void
    uc_setUserInterface_ErrorDialogDisplay: (error: { display: boolean; error: string }) => void
  }
  uc_setUserInterface_CustomDialogDisplay: (state: any) => void
}

const ParkedLocationCustomDialog: React.FC<ParkedLocationCustomDialogProps> = ({ us_vehicle, tableHooks, uc_setUserInterface_CustomDialogDisplay }) => {
  const [us_parkedLocation, setUs_parkedLocation] = useState<string | null>(null)

  const handleSubmit = () => {
    if (us_parkedLocation && us_parkedLocation !== '') {
      const updateObject = {
        parked_location: us_parkedLocation,
        associated_user_key: '',
        associated_user_name: '',
        field_status: false,
      }

      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_Vehicle_Document(res_GCK.clientKey, us_vehicle.key), updateObject)
            .then((res_DSMD) => {
              uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              tableHooks.ur_forceRerender()
            })
            .catch((rej_DSMD) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  return (
    <Box>
      <Dialog
        className="bp_dialog_lg_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              disabled
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, color: '#fff !important' }}
            >
              <Icon icon="pen-to-square" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              {rLIB('Enter Parked Location')}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            <TextField
              sx={{ width: '100%' }}
              color="primary"
              value={us_parkedLocation || ''}
              label={rLIB('Parked Location')}
              margin="normal"
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  setUs_parkedLocation(event.target.value)
                }
              }}
              variant="outlined"
            />
            <Box className="tw-mt-2 tw-text-right">
              <Button
                variant="contained"
                color="success"
                onClick={handleSubmit}
              >
                <Icon
                  icon="user-plus"
                  className="tw-mr-2"
                />
                {rLIB('Set Parked Location')}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default ParkedLocationCustomDialog

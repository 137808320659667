/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			example component description

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material/'
import React, { forwardRef, useState } from 'react'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { objectToArray } from '../../../../rfbp_core/services/helper_functions/index'

///////////////////////////////
// Typescript
///////////////////////////////

// TODO: Rename and fill out
// type TsType_GMDashboardHeatmapRowCellContent = string | number | JSX.Element
export type TsType_GMDashboardHeatmapRowCellContent = {
  cellValue: string | number | JSX.Element
  dialogFunction?: (cellValue: string | number | JSX.Element, additionalCellData: TsInterface_UnspecifiedObject) => void
  conditionalFormatting?: TsInterface_UnspecifiedObject
  additionalCellData?: TsInterface_UnspecifiedObject
  onClick?: () => void // Add onClick as an optional property
  details: (cellValue: string | number | JSX.Element, additionalCellData: TsInterface_UnspecifiedObject) => void
}

export interface TsInterface_GMDashboardHeatmapTableData {
  summary_rows: TsType_GMDashboardHeatmapRowCellContent[][]
  data_rows: TsType_GMDashboardHeatmapRowCellContent[][]
}

export interface TsInterface_GMDashboardHeatmap {
  tableData: TsInterface_GMDashboardHeatmapTableData
  heatmapLegend?: JSX.Element
}

///////////////////////////////
// Variables
///////////////////////////////
const columnWidths = '150px'

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

// TODO: Rename
export const GMDashboardHeatmap = forwardRef((props: TsInterface_GMDashboardHeatmap, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_tableData: TsInterface_GMDashboardHeatmapTableData = getProp(props, 'tableData', {})
  let pr_heatmapLegend: JSX.Element = getProp(props, 'heatmapLegend', null)

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_showFullHeatmapTableData, us_setShowFullHeatmapTableData] = useState<boolean>(false)
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
  // { sort-end } - hooks

  // Hooks - useEffect
  // useEffect(() => {}, [])

  // Functions
  const openHeatmapCellDialog = (cellData: TsType_GMDashboardHeatmapRowCellContent): void => {
    if (cellData.dialogFunction != null) {
      cellData.dialogFunction(cellData.cellValue, getProp(cellData, 'additionalCellData', {}))
      // uc_setUserInterface_CustomDialogDisplay({
      //   display: true,
      //   dialog: {
      //     dialog_jsx: dialogFunction,
      //     settings: {
      //       max_width: 'md',
      //     },
      //   },
      // })
    }
  }

  // JSX Generation
  const rJSX_ToggleFullDataIcon = (): JSX.Element => {
    let iconJSX = <></>
    if (us_showFullHeatmapTableData) {
      iconJSX = (
        <Icon
          icon="square-caret-down"
          className="tw-mr-2 tw-text-info_main tw-cursor-pointer"
          tooltip={rLIB('Hide Full Data')}
          sx={{ fontSize: '18px' }}
          onClick={() => {
            us_setShowFullHeatmapTableData(false)
          }}
        />
      )
    } else {
      iconJSX = (
        <Icon
          icon="square-caret-up"
          className="tw-mr-2 tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer"
          tooltip={rLIB('Show Full Data')}
          sx={{ fontSize: '18px' }}
          onClick={() => {
            us_setShowFullHeatmapTableData(true)
          }}
        />
      )
    }
    return iconJSX
  }

  const rJSX_LegendIcon = (): JSX.Element => {
    let iconJSX = <></>
    if (pr_heatmapLegend != null) {
      iconJSX = (
        <Icon
          className="tw-mr-2 tw-opacity-50 hover:tw-opacity-100"
          icon="info-circle"
          sx={{ fontSize: '18px' }}
          tooltip={pr_heatmapLegend}
        />
      )
    }
    return iconJSX
  }

  const rJSX_TopLeftSummaryCellIcons = (rowIndex: number, cellIndex: number): JSX.Element => {
    let additionalCellContentJSX = <></>
    if (rowIndex === 0 && cellIndex === 0) {
      additionalCellContentJSX = (
        <Box className="tw-inline-block">
          {rJSX_ToggleFullDataIcon()}
          {rJSX_LegendIcon()}
        </Box>
      )
    }
    return additionalCellContentJSX
  }

  const rJSX_SummaryCellRows = (): JSX.Element => {
    return (
      <TableBody>
        {getProp(pr_tableData, 'summary_rows', []).map((summaryRow: TsType_GMDashboardHeatmapRowCellContent[], rowIndex: number) => (
          <TableRow key={rowIndex}>
            {objectToArray(summaryRow).map((summaryCell: TsType_GMDashboardHeatmapRowCellContent, cellIndex: number) => (
              <TableCell
                sx={{
                  ...getProp(summaryCell, 'conditionalFormatting', {}),
                  width: columnWidths, // Apply consistent column width
                  padding: '8px',
                  boxSizing: 'border-box',
                  fontWeight: 'bold',
                }}
                key={cellIndex}
                onClick={() => {
                  openHeatmapCellDialog(summaryCell)
                }}
              >
                {rJSX_TopLeftSummaryCellIcons(rowIndex, cellIndex)}
                {summaryCell.cellValue}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    )
  }

  const rJSX_TableDataRows = (): JSX.Element => {
    let rowsJSX = <></>
    if (us_showFullHeatmapTableData) {
      rowsJSX = (
        <TableBody>
          {getProp(pr_tableData, 'data_rows', []).map((dataRow: TsType_GMDashboardHeatmapRowCellContent[], index: number) => (
            <TableRow key={index}>
              {objectToArray(dataRow).map((dataCell: TsType_GMDashboardHeatmapRowCellContent, cellIndex: number) => (
                <TableCell
                  sx={{
                    ...getProp(dataCell, 'conditionalFormatting', {}),
                    width: columnWidths, // Apply consistent column width
                    padding: '8px',
                    boxSizing: 'border-box',
                  }}
                  key={cellIndex}
                  onClick={() => {
                    // Make sure dialogFunction is invoked here
                    dataCell.dialogFunction && dataCell.dialogFunction(dataCell.cellValue || '', dataCell.additionalCellData || {})
                  }}
                >
                  {dataCell.cellValue}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      )
    }
    return rowsJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <TableContainer>
          <Table size="small">
            {rJSX_SummaryCellRows()}
            {rJSX_TableDataRows()}
          </Table>
        </TableContainer>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
})

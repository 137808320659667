///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_UtilityFile = (clientKey: string, utilityKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/utilities/' + utilityKey + '/files/' + fileName)
}

export const StorageRef_Utility_MessageThread_File = (clientKey: string, utilityKey: string, threadKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/utilities/' + utilityKey + '/threads/' + threadKey + '/files/' + fileName)
}

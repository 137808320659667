///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Page used to maange meilisearch index creation and other management

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Divider, FormControl, MenuItem, Select, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { searchIndexList } from 'rfbp_aux/config/search_config'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_RootClient_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Json } from 'rfbp_core/components/code_display'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import {
  addDocumentsToSearchIndex,
  createSearchIndex,
  deleteSearchIndex,
  getDocumentsFromSearchIndex,
  getSearchIndexStats,
  getSpecificSearchIndexInfo,
  listSearchIndices,
  rJSX_HighlightedSearchString,
  SearchInput,
  updateSearchIndexFilters,
  updateSearchIndexSettings,
} from 'rfbp_core/components/search'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_ErrorDialog, Context_UserInterface_PromptDialog, Context_UserInterface_Snackbar } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SearchManagementIndexPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_INDEX: JSX.Element = <Trans>Are you sure that you want to delete this index?</Trans>
const s_BUILD_INDEX: JSX.Element = <Trans>Build Index</Trans>
const s_CREATE_CLIENT_INDEXES: JSX.Element = <Trans>Create Client Indexes</Trans>
const s_CREATE_INDEX: JSX.Element = <Trans>Create Index</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_FAILED_TO_BUILD_INDEX: JSX.Element = <Trans>Failed to build index</Trans>
const s_LIST_INDEXES: JSX.Element = <Trans>List Indexes</Trans>
const s_LOAD_INDEX: JSX.Element = <Trans>Load Index</Trans>
const s_LOAD_INDEXES: JSX.Element = <Trans>Load Indexes</Trans>
const s_LOAD_SEARCH_DATA: JSX.Element = <Trans>Load Search Data</Trans>
const s_MANUALLY_INDEX_DATA: JSX.Element = <Trans>Manually Index Data</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing Required Parameters</Trans>
const s_SEARCH_MANAGEMENT: JSX.Element = <Trans>Search Management</Trans>
const s_TEST_SEARCH: JSX.Element = <Trans>Test Search</Trans>
const s_TYPE_DANGER_TO_PROCEED: JSX.Element = <Trans>Type DANGER to proceed</Trans>
const s_UPDATE_INDEX_FILTERS: JSX.Element = <Trans>Update Index Filters</Trans>
const s_UPDATE_INDEX_SETTINGS: JSX.Element = <Trans>Update Index Settings</Trans>
const se_SEARCH_MANAGEMENT: string = 'Search Management'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_clientsList, us_setClientsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_firstIndexedItem, us_setFirstIndexedItem] = useState<TsInterface_UnspecifiedObject | null>({})
  const [us_indexCheckResults, us_setIndexCheckResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_indexRunCount, us_setIndexRunCount] = useState<number>(0)
  const [us_indexSearchResults, us_setIndexSearchResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_indexStats, us_setIndexStats] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lastIndexedItem, us_setLastIndexedItem] = useState<TsInterface_UnspecifiedObject | null>({})
  const [us_lastIndexedItemsCount, us_setLastIndexedItemsCount] = useState<number>(0)
  const [us_loadedIndexes, us_setLoadedIndexes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_manualIndexQueryCursor, us_setManualIndexQueryCursor] = useState<string | null>(null)
  const [us_readyToStartIndex, us_setReadyToStartIndex] = useState<boolean>(false)
  const [us_reloadIndexSearch, us_setReloadIndexSearch] = useState<number>(new Date().getTime())
  const [us_runWholeIndexEndTime, us_setRunWholeIndexEndTime] = useState<Date | null>(null)
  const [us_runWholeIndexStartTime, us_setRunWholeIndexStartTime] = useState<Date | null>(null)
  const [us_runWholeIndexStatus, us_setRunWholeIndexStatus] = useState<string>('not_started')
  const [us_runningManualIndex, us_setRunningManualIndex] = useState<boolean>(false)
  const [us_runningWholeIndex, us_setRunningWholeIndex] = useState<boolean>(false)
  const [us_selectedClientKey, us_setSelectedClientKey] = useState<string>('')
  const [us_selectedSearchIndex, us_setSelectedSearchIndex] = useState<string>('')
  const [us_startRunningWholeIndex, us_setStartRunningWholeIndex] = useState<boolean>(false)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_SEARCH_MANAGEMENT
  }, [])

  useEffect(() => {
    if (us_selectedClientKey != null && us_selectedClientKey !== '' && us_selectedSearchIndex != null && us_selectedSearchIndex !== '') {
      getSpecificSearchIndexInfo(us_selectedClientKey, us_selectedSearchIndex)
        .then((res_GSSII) => {
          us_setIndexCheckResults({
            success: true,
            displayedAttributes: getProp(res_GSSII, 'displayedAttributes', null),
            distinctAttribute: getProp(res_GSSII, 'distinctAttribute', null),
            faceting: getProp(res_GSSII, 'faceting', null),
            filterableAttributes: getProp(res_GSSII, 'filterableAttributes', null),
            pagination: getProp(res_GSSII, 'pagination', null),
            rankingRules: getProp(res_GSSII, 'rankingRules', null),
            searchableAttributes: getProp(res_GSSII, 'searchableAttributes', null),
            sortableAttributes: getProp(res_GSSII, 'sortableAttributes', null),
            stopWords: getProp(res_GSSII, 'stopWords', null),
            synonyms: getProp(res_GSSII, 'synonyms', null),
            typoTolerance: getProp(res_GSSII, 'typoTolerance', null),
          })
        })
        .catch((rej_GSSII) => {
          us_setIndexCheckResults(rej_GSSII)
          console.error(rej_GSSII)
        })
      getSearchIndexStats(us_selectedClientKey, us_selectedSearchIndex).then((res_GSIS) => {
        us_setIndexStats(res_GSIS as TsInterface_UnspecifiedObject)
      })
    }
  }, [us_selectedClientKey, us_selectedSearchIndex, us_reloadIndexSearch])

  useEffect(() => {
    if (us_startRunningWholeIndex === true) {
      us_setStartRunningWholeIndex(false)
      if (us_lastIndexedItemsCount === 1 || (us_indexRunCount >= 1 && us_lastIndexedItemsCount === 0)) {
        // Done with index
        us_setRunningWholeIndex(false)
        us_setRunWholeIndexStatus('complete')
        us_setRunWholeIndexEndTime(new Date())
        ur_forceRerender()
      } else {
        loadAndIndexData()
          .then(() => {
            setTimeout(() => {
              us_setStartRunningWholeIndex(true)
            }, 1000)
          })
          .catch(() => {
            us_setRunningWholeIndex(false)
          })
      }
    }
  }, [us_startRunningWholeIndex])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClientsList(newData)
      ur_forceRerender()
    }
    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_RootClient_Collection(), updateLiveData)
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender])

  // Other Variables
  const dataQueryLimit = 1000

  // Functions
  const returnClientSearchIndexOptions = (): TsInterface_UnspecifiedObject => {
    let indexOptions: TsInterface_UnspecifiedObject = {}
    for (let loopSearchIndexKey in searchIndexList) {
      let loopSearchIndex = searchIndexList[loopSearchIndexKey]
      if (loopSearchIndex.type === 'client') {
        indexOptions[loopSearchIndexKey] = {
          key: loopSearchIndex.indexKey,
          name: loopSearchIndex.indexKey,
        }
      }
    }
    return indexOptions
  }

  const runWholeIndex = () => {
    us_setStartRunningWholeIndex(true)
    us_setRunWholeIndexStatus('running')
    us_setRunWholeIndexStartTime(new Date())
  }

  const loadAndIndexData = (): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (
        us_selectedClientKey != null &&
        us_selectedSearchIndex != null &&
        searchIndexList != null &&
        searchIndexList['client_' + us_selectedSearchIndex] != null &&
        searchIndexList['client_' + us_selectedSearchIndex]['primaryKey'] != null &&
        searchIndexList['client_' + us_selectedSearchIndex]['filterableAttributes'] != null &&
        searchIndexList['client_' + us_selectedSearchIndex]['indexedAttributes'] != null &&
        searchIndexList['client_' + us_selectedSearchIndex]['databaseDataEndpoint'] != null &&
        searchIndexList['client_' + us_selectedSearchIndex]['databaseDataEndpointOrderByProp'] != null
      ) {
        us_setRunningManualIndex(true)
        let databaseEndpoint = searchIndexList['client_' + us_selectedSearchIndex]['databaseDataEndpoint'](
          us_selectedClientKey,
          null,
          us_manualIndexQueryCursor,
          null,
          null,
          dataQueryLimit,
        )
        DatabaseGetCollection(databaseEndpoint)
          .then((res_DGC) => {
            let firstItem: TsInterface_UnspecifiedObject | null = null
            let lastItem: TsInterface_UnspecifiedObject = {}
            let itemCount: number = 0
            let itemsToIndexArray: TsInterface_UnspecifiedObject[] = []
            // Generate Index Records
            for (let loopItemKey in res_DGC.data) {
              let loopItem = res_DGC.data[loopItemKey]
              let loopItemToIndex: TsInterface_UnspecifiedObject = {}
              for (let loopPropKey in searchIndexList['client_' + us_selectedSearchIndex]['indexedAttributes']) {
                let loopPropMappedKey = searchIndexList['client_' + us_selectedSearchIndex]['indexedAttributes'][loopPropKey]
                if (loopItem[loopPropMappedKey] != null) {
                  loopItemToIndex[loopPropKey] = loopItem[loopPropMappedKey]
                }
              }
              if (searchIndexList['client_' + us_selectedSearchIndex]['inactiveBoolToStatus'] != null && loopItem.inactive === true) {
                loopItemToIndex['status'] = searchIndexList['client_' + us_selectedSearchIndex]['inactiveBoolToStatus']
              }
              if (searchIndexList['client_' + us_selectedSearchIndex]['statusToSetIfNull'] != null && loopItemToIndex['status'] == null) {
                loopItemToIndex['status'] = searchIndexList['client_' + us_selectedSearchIndex]['statusToSetIfNull']
              }
              itemsToIndexArray.push(loopItemToIndex)
              if (firstItem == null) {
                firstItem = loopItem
              }
              lastItem = loopItem
              itemCount++
            }
            // Index Search
            addDocumentsToSearchIndex(us_selectedClientKey, us_selectedSearchIndex, itemsToIndexArray)
              .then((res_ADTSI) => {
                us_setIndexRunCount(us_indexRunCount + 1)
                us_setLastIndexedItem(lastItem)
                us_setFirstIndexedItem(firstItem)
                us_setLastIndexedItemsCount(itemCount)
                us_setRunningManualIndex(false)
                if (lastItem != null && lastItem[searchIndexList['client_' + us_selectedSearchIndex]['databaseDataEndpointOrderByProp']] != null) {
                  us_setManualIndexQueryCursor(lastItem[searchIndexList['client_' + us_selectedSearchIndex]['databaseDataEndpointOrderByProp']])
                }
                ur_forceRerender()
                setTimeout(() => {
                  resolve({ success: true })
                }, 1000)
              })
              .catch((rej_ADTSI) => {
                uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: rej_ADTSI.error,
                })
              })
          })
          .catch((rej_DGC) => {
            uc_setUserInterface_ErrorDialogDisplay({
              display: true,
              error: rej_DGC.error,
            })
            us_setRunningManualIndex(false)
            ur_forceRerender()
            reject({ success: false })
          })
      } else {
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            code: 'ER-D-SMI-LAID',
            details: s_MISSING_REQUIRED_PARAMETERS,
            message: s_FAILED_TO_BUILD_INDEX,
          },
        })
        us_setRunningManualIndex(false)
        ur_forceRerender()
        reject({ success: false })
      }
    })
  }

  // JSX Generation
  const rJSX_ClientToggleDropdown = (): JSX.Element => {
    let clientToggleDropdownJSX = (
      <FormControl>
        <Select
          className="bp_thin_select_input tw-mr-2 tw-mb-2"
          value={us_selectedClientKey}
          onChange={(event) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSelectedClientKey(event.target.value)
              us_setReadyToStartIndex(false)
            }
          }}
        >
          {objectToArray(us_clientsList).map((client) => (
            <MenuItem
              value={client.key}
              key={client.key}
            >
              {client.key}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
    return clientToggleDropdownJSX
  }

  const rJSX_SearchIndexDropdown = (): JSX.Element => {
    let searchIndexDropdown = (
      <FormControl>
        <Select
          className="bp_thin_select_input tw-mr-2 tw-mb-2"
          value={us_selectedSearchIndex}
          onChange={(event) => {
            if (event != null && event.target != null && event.target.value != null) {
              us_setSelectedSearchIndex(event.target.value)
              us_setReadyToStartIndex(false)
            }
          }}
        >
          {objectToArray(returnClientSearchIndexOptions()).map((index) => (
            <MenuItem
              value={index.key}
              key={index.key}
            >
              {index.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
    return searchIndexDropdown
  }

  const rJSX_IndexesTab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-mt-1">
        <Button
          className=""
          color="secondary"
          variant="contained"
          onClick={() => {
            listSearchIndices(0, 1000)
              .then((res_LSI) => {
                let parsedSearchResults: TsInterface_UnspecifiedObject = {}
                if (
                  res_LSI != null &&
                  // @ts-expect-error - TODO: reason for error
                  res_LSI.results != null
                ) {
                  for (let loopIndex in getProp(res_LSI, 'results', [])) {
                    let loopResult = getProp(res_LSI, 'results', [])[loopIndex]
                    if (loopResult != null && loopResult.uid != null) {
                      parsedSearchResults[loopResult.uid] = {
                        uid: loopResult.uid,
                        primaryKey: loopResult.primaryKey,
                      }
                    }
                  }
                }
                us_setLoadedIndexes(parsedSearchResults)
              })
              .catch((rej_LSI) => {
                console.error(rej_LSI)
              })
          }}
        >
          <Icon
            icon="cloud-arrow-up"
            className="tw-mr-2"
            size="sm"
          />
          {s_LOAD_INDEXES}
        </Button>
        <Box className="tw-mt-2">
          <Json data={us_loadedIndexes} />
        </Box>
      </Box>
    )
    return tabJSX
  }

  const rJSX_PullSampleSearchIndexResultsButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="info"
        variant="contained"
        disabled={
          us_selectedClientKey === '' || us_selectedSearchIndex === ''
          // getProp( us_indexCheckResults, "success", null ) !== true
        }
        // className="tw-ml-2"
        onClick={() => {
          getDocumentsFromSearchIndex(us_selectedClientKey, us_selectedSearchIndex, 0, 5)
            .then((res_GDFSI) => {
              // @ts-expect-error - TODO: reason for error
              us_setIndexSearchResults(res_GDFSI)
            })
            .catch((rej_GDFSI) => {
              us_setIndexSearchResults(rej_GDFSI)
            })
        }}
      >
        <Icon
          icon="magnifying-glass"
          className="tw-mr-2"
        />
        {s_LOAD_SEARCH_DATA}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_CreateIndexButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="success"
        variant="contained"
        disabled={us_selectedClientKey === '' || us_selectedSearchIndex === '' || getProp(us_indexCheckResults, 'success', null) !== false}
        // className="tw-ml-2"
        onClick={() => {
          if (
            us_selectedClientKey != null &&
            us_selectedSearchIndex != null &&
            searchIndexList != null &&
            searchIndexList['client_' + us_selectedSearchIndex] != null &&
            searchIndexList['client_' + us_selectedSearchIndex]['primaryKey'] != null &&
            searchIndexList['client_' + us_selectedSearchIndex]['filterableAttributes'] != null &&
            searchIndexList['client_' + us_selectedSearchIndex]['searchableAttributes'] != null &&
            searchIndexList['client_' + us_selectedSearchIndex]['displayedAttributes'] != null
          ) {
            createSearchIndex(us_selectedClientKey, us_selectedSearchIndex, searchIndexList['client_' + us_selectedSearchIndex]['primaryKey'])
              .then((res_CSI) => {
                updateSearchIndexFilters(
                  us_selectedClientKey,
                  us_selectedSearchIndex,
                  searchIndexList['client_' + us_selectedSearchIndex]['filterableAttributes'],
                  searchIndexList['client_' + us_selectedSearchIndex]['searchableAttributes'],
                  searchIndexList['client_' + us_selectedSearchIndex]['displayedAttributes'],
                )
                  .then((res_USIF) => {
                    console.log('Index Created Successfully')
                    us_setReloadIndexSearch(new Date().getTime())
                    uc_setUserInterface_SnackbarDisplay({
                      display: true,
                      snackbar: {
                        message: 'Index Created Successfully',
                        alertType: 'success',
                        verticalAlignment: 'top',
                        horizontalAlignment: 'left',
                      },
                    })
                  })
                  .catch((rej_USIF) => {
                    console.error('Index Creation Failed')
                    console.error(rej_USIF)
                    uc_setUserInterface_SnackbarDisplay({
                      display: true,
                      snackbar: {
                        message: 'Index Creation Failed',
                        alertType: 'error',
                        verticalAlignment: 'top',
                        horizontalAlignment: 'left',
                      },
                    })
                  })
              })
              .catch((rej_CSI) => {
                console.error('Index Creation Failed')
                console.error(rej_CSI)
                uc_setUserInterface_SnackbarDisplay({
                  display: true,
                  snackbar: {
                    message: 'Index Creation Failed',
                    alertType: 'error',
                    verticalAlignment: 'top',
                    horizontalAlignment: 'left',
                  },
                })
              })
          } else {
            console.error('MISSING REQUIRED PROPS')
            uc_setUserInterface_SnackbarDisplay({
              display: true,
              snackbar: {
                message: 'Missing Required Props',
                alertType: 'error',
                verticalAlignment: 'top',
                horizontalAlignment: 'left',
              },
            })
          }
        }}
      >
        <Icon
          icon="plus-circle"
          className="tw-mr-2"
        />
        {s_CREATE_INDEX}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_UpdateSettingsOnIndexButton = (disabled: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="success"
        variant="contained"
        disabled={
          disabled || us_selectedClientKey === '' || us_selectedSearchIndex === ''
          // getProp( us_indexCheckResults, "success", null ) !== false
        }
        className="tw-ml-2"
        onClick={() => {
          if (
            us_selectedClientKey != null &&
            us_selectedSearchIndex != null &&
            searchIndexList != null &&
            searchIndexList['client_' + us_selectedSearchIndex] != null &&
            searchIndexList['client_' + us_selectedSearchIndex]['searchIndexSettings'] != null
          ) {
            console.log(getProp(searchIndexList['client_' + us_selectedSearchIndex], 'searchIndexSettings', {}))

            updateSearchIndexSettings(
              us_selectedClientKey,
              us_selectedSearchIndex,
              getProp(searchIndexList['client_' + us_selectedSearchIndex], 'searchIndexSettings', {}),
            )
              .then((res_USIF) => {
                console.log(res_USIF)
                console.log('Index Settings Updated Successfully')
                uc_setUserInterface_SnackbarDisplay({
                  display: true,
                  snackbar: {
                    message: 'Index Settings Updated Successfully',
                    alertType: 'success',
                    verticalAlignment: 'top',
                    horizontalAlignment: 'left',
                  },
                })
              })
              .catch((rej_USIF) => {
                console.error('Index Update Failed')
                console.error(rej_USIF)
                uc_setUserInterface_SnackbarDisplay({
                  display: true,
                  snackbar: {
                    message: 'Index Update Failed',
                    alertType: 'error',
                    verticalAlignment: 'top',
                    horizontalAlignment: 'left',
                  },
                })
              })
          } else {
            console.error('MISSING REQUIRED PROPS')
            uc_setUserInterface_SnackbarDisplay({
              display: true,
              snackbar: {
                message: 'Missing Required Props',
                alertType: 'error',
                verticalAlignment: 'top',
                horizontalAlignment: 'left',
              },
            })
          }
        }}
      >
        <Icon
          icon="arrows-rotate"
          className="tw-mr-2"
        />
        {s_UPDATE_INDEX_SETTINGS}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_UpdateFilterableFieldsOnIndexButton = (disabled: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="success"
        variant="contained"
        disabled={
          disabled || us_selectedClientKey === '' || us_selectedSearchIndex === ''
          // getProp( us_indexCheckResults, "success", null ) !== false
        }
        className="tw-ml-2"
        onClick={() => {
          if (
            us_selectedClientKey != null &&
            us_selectedSearchIndex != null &&
            searchIndexList != null &&
            searchIndexList['client_' + us_selectedSearchIndex] != null &&
            searchIndexList['client_' + us_selectedSearchIndex]['filterableAttributes'] != null &&
            searchIndexList['client_' + us_selectedSearchIndex]['searchableAttributes'] != null &&
            searchIndexList['client_' + us_selectedSearchIndex]['displayedAttributes'] != null
          ) {
            updateSearchIndexFilters(
              us_selectedClientKey,
              us_selectedSearchIndex,
              searchIndexList['client_' + us_selectedSearchIndex]['filterableAttributes'],
              searchIndexList['client_' + us_selectedSearchIndex]['searchableAttributes'],
              searchIndexList['client_' + us_selectedSearchIndex]['displayedAttributes'],
            )
              .then((res_USIF) => {
                console.log('Index Created Successfully')
                uc_setUserInterface_SnackbarDisplay({
                  display: true,
                  snackbar: {
                    message: 'Index Created Successfully',
                    alertType: 'success',
                    verticalAlignment: 'top',
                    horizontalAlignment: 'left',
                  },
                })
              })
              .catch((rej_USIF) => {
                console.error('Index Creation Failed')
                console.error(rej_USIF)
                uc_setUserInterface_SnackbarDisplay({
                  display: true,
                  snackbar: {
                    message: 'Index Creation Failed',
                    alertType: 'error',
                    verticalAlignment: 'top',
                    horizontalAlignment: 'left',
                  },
                })
              })
          } else {
            console.error('MISSING REQUIRED PROPS')
            uc_setUserInterface_SnackbarDisplay({
              display: true,
              snackbar: {
                message: 'Missing Required Props',
                alertType: 'error',
                verticalAlignment: 'top',
                horizontalAlignment: 'left',
              },
            })
          }
        }}
      >
        <Icon
          icon="arrows-rotate"
          className="tw-mr-2"
        />
        {s_UPDATE_INDEX_FILTERS}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DeleteIndexButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="error"
        variant="contained"
        disabled={
          true || // TEMP
          us_selectedClientKey === '' ||
          us_selectedSearchIndex === '' ||
          getProp(us_indexCheckResults, 'success', null) !== true
        }
        className="tw-ml-2"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'error',
              confirm_text: s_DELETE,
              default_value: '',
              header: rLIB('Delete Index'),
              icon: (
                <Icon
                  icon="siren-on"
                  type="solid"
                />
              ),
              input_label: s_TYPE_DANGER_TO_PROCEED,
              input_type: 'text',
              text: <>{s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_INDEX}</>,
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  if (promptValue === 'DANGER') {
                    deleteSearchIndex(us_selectedClientKey, us_selectedSearchIndex)
                      .then((res_DSI) => {
                        uc_setUserInterface_SnackbarDisplay({
                          display: true,
                          snackbar: {
                            message: 'Index Deleted',
                            alertType: 'warning',
                            verticalAlignment: 'top',
                            horizontalAlignment: 'left',
                          },
                        })
                      })
                      .catch((rej_DSI) => {
                        console.error(rej_DSI)
                        uc_setUserInterface_SnackbarDisplay({
                          display: true,
                          snackbar: {
                            message: 'Index Deletion Failed',
                            alertType: 'error',
                            verticalAlignment: 'top',
                            horizontalAlignment: 'left',
                          },
                        })
                      })
                    resolve({ close_dialog: true })
                  } else {
                    resolve({ close_dialog: false })
                  }
                })
              },
            },
          })
        }}
      >
        <Icon
          icon="trash"
          className="tw-mr-2"
        />
        {rLIB('Delete Index')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_StartManualIndexButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="success"
        variant="contained"
        disabled={
          us_selectedClientKey === '' ||
          us_selectedSearchIndex === '' ||
          us_readyToStartIndex === true ||
          us_runningWholeIndex === true ||
          getProp(us_indexCheckResults, 'success', null) !== true
        }
        className="tw-ml-0"
        onClick={() => {
          // Reset Everything
          us_setReadyToStartIndex(true)
          us_setManualIndexQueryCursor(null)
          us_setIndexRunCount(0)
          us_setLastIndexedItemsCount(0)
          us_setRunWholeIndexStatus('not_started')
          us_setRunWholeIndexStartTime(null)
          us_setRunWholeIndexEndTime(null)
        }}
      >
        <Icon
          icon="cloud-arrow-up"
          className="tw-mr-2"
        />
        {s_LOAD_INDEX}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_RunWholeIndexButton = (): JSX.Element => {
    let buttonIconJSX = <Icon icon="play" />
    if (us_runningWholeIndex === true || us_runWholeIndexStatus === 'running') {
      buttonIconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin"
        />
      )
    }
    let buttonJSX = (
      <Button
        color="warning"
        variant="contained"
        className="tw-ml-2"
        disabled={
          us_selectedClientKey === '' ||
          us_selectedSearchIndex === '' ||
          us_runningManualIndex === true ||
          us_readyToStartIndex === false ||
          us_runningWholeIndex === true ||
          us_runWholeIndexStatus === 'running'
        }
        onClick={() => {
          runWholeIndex()
        }}
      >
        <Box className="tw-mr-2 tw-inline-block">{buttonIconJSX}</Box>
        {s_BUILD_INDEX}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_CurrentManualIndexData = (): JSX.Element => {
    let indexDataJSX = <></>
    let startDateString = ''
    let endDateString = ''
    let totalTimeString = ''
    if (us_runWholeIndexStartTime != null) {
      startDateString = returnFormattedDate(us_runWholeIndexStartTime, 'HH:mm:ss')
    }
    if (us_runWholeIndexEndTime != null) {
      endDateString = returnFormattedDate(us_runWholeIndexEndTime, 'HH:mm:ss')
    }
    if (us_runWholeIndexStartTime != null && us_runWholeIndexEndTime != null) {
      let startDate = us_runWholeIndexStartTime.getTime()
      let endDate = us_runWholeIndexEndTime.getTime()
      let milliseconds = endDate - startDate
      const hours = Math.floor(milliseconds / (1000 * 60 * 60))
      milliseconds %= 1000 * 60 * 60
      const minutes = Math.floor(milliseconds / (1000 * 60))
      milliseconds %= 1000 * 60
      const seconds = Math.floor(milliseconds / 1000)
      totalTimeString = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`
    }
    if (us_readyToStartIndex === true) {
      indexDataJSX = (
        <Card className="tw-p-2">
          <Box>Indexing Status: {us_runWholeIndexStatus}</Box>
          <Divider className="tw-my-2" />
          <Box>
            Index Loop Count: {us_indexRunCount} ({dataQueryLimit}x)
          </Box>
          <Box>Number of Items to Index: {us_lastIndexedItemsCount}</Box>
          <Box>Next Database Query Cursor: {us_manualIndexQueryCursor}</Box>
          <Divider className="tw-my-2" />
          <Box>Start Time: {startDateString}</Box>
          <Box>End Time: {endDateString}</Box>
          <Box>Total Time: {totalTimeString}</Box>
          <Divider className="tw-my-2" />
          <Box>First Item being Indexed:</Box>
          <Json
            data={us_firstIndexedItem || {}}
            alphabetized={true}
          />
          <Box>Last Item being Indexed:</Box>
          <Json
            data={us_lastIndexedItem || {}}
            alphabetized={true}
          />
        </Card>
      )
    }
    return indexDataJSX
  }

  const rJSX_CreateIndexesTab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-mt-1">
        <Box className="tw-mb-2">
          {rJSX_ClientToggleDropdown()}
          {rJSX_SearchIndexDropdown()}
          {/* { rJSX_CheckIndexButton() } */}
          {rJSX_CreateIndexButton()}
          {rJSX_UpdateFilterableFieldsOnIndexButton(false)}
          {rJSX_UpdateSettingsOnIndexButton(false)}
          {rJSX_DeleteIndexButton()}
        </Box>
        <Json data={us_indexCheckResults} />
        <Json data={us_indexStats} />
      </Box>
    )
    return tabJSX
  }

  const rJSX_ManuallyIndexDataTab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-mt-1">
        <Box>
          {rJSX_ClientToggleDropdown()}
          {rJSX_SearchIndexDropdown()}
          {rJSX_StartManualIndexButton()}
          {rJSX_RunWholeIndexButton()}
        </Box>
        <Box className="tw-mt-2">{rJSX_CurrentManualIndexData()}</Box>
      </Box>
    )
    return tabJSX
  }

  const rJSX_SearchResult = (
    option: TsInterface_UnspecifiedObject,
    searchInputValue: string | null,
    inputHooks: TsInterface_InputHooksObject,
    additionalSearchData: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let searchResultJSX = (
      <Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
        <Typography>
          {[searchIndexList['client_' + us_selectedSearchIndex]['databaseDataEndpointOrderByProp']]}:{' '}
          {rJSX_HighlightedSearchString(searchInputValue, option[searchIndexList['client_' + us_selectedSearchIndex]['databaseDataEndpointOrderByProp']])}{' '}
        </Typography>
        <Json data={option} />
      </Box>
    )
    return searchResultJSX
  }

  const rJSX_SearchTab = (): JSX.Element => {
    let searchBarJSX = <></>
    if (us_selectedClientKey !== '' && us_selectedSearchIndex !== '') {
      searchBarJSX = (
        <SearchInput
          clientKey={us_selectedClientKey}
          searchIndexKey={us_selectedSearchIndex}
          searchFilters={[]}
          searchResultRenderer={rJSX_SearchResult}
          additionalSearchData={{}}
        />
      )
    }
    let tabJSX = (
      <Box className="tw-mt-1">
        {rJSX_ClientToggleDropdown()}
        {rJSX_SearchIndexDropdown()}
        {rJSX_PullSampleSearchIndexResultsButton()}
        <Box className="tw-py-2">{searchBarJSX}</Box>
        <Json data={us_indexSearchResults} />
      </Box>
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_SEARCH_MANAGEMENT}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'List',
                  tabHeader: s_LIST_INDEXES,
                  tabOnChange: () => {},
                  tabContent: rJSX_IndexesTab(),
                },
                {
                  tabUrlKey: 'Create',
                  tabHeader: s_CREATE_CLIENT_INDEXES,
                  tabOnChange: () => {},
                  tabContent: rJSX_CreateIndexesTab(),
                },
                {
                  tabUrlKey: 'Add_Data',
                  tabHeader: s_MANUALLY_INDEX_DATA,
                  tabOnChange: () => {},
                  tabContent: rJSX_ManuallyIndexDataTab(),
                },
                {
                  tabUrlKey: 'Search',
                  tabHeader: s_TEST_SEARCH,
                  tabOnChange: () => {},
                  tabContent: rJSX_SearchTab(),
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.SearchManagementIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: se_SEARCH_MANAGEMENT,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Function to update user client keys

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Trans } from 'react-i18next'
import { generateAvailableUserLevelPermissions, TsType_ClientTypes, TsType_UserRoles } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_ClientUser_Document,
  DatabaseRef_GlobalUser_Document,
  DatabaseRef_UserLogs_Collection,
} from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { CleanFormData, TsInterface_FormInputs } from 'rfbp_core/components/form'
import { LogChange } from 'rfbp_core/components/logs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { TsInterface_RootData_ClientPermissions, TsInterface_RootData_ClientUser } from 'rfbp_core/services/context'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import {
  TsInterface_CatchError,
  TsInterface_GenericPromiseReject,
  TsInterface_GenericPromiseResolve,
  TsInterface_UnspecifiedObject,
  TsType_VoidFunctionSingleStringParam,
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_FAILED_TO_UPDATE_USER: JSX.Element = <Trans>Failed to update user </Trans>
const s_FAILED_TO_UPDATE_USER_CLIENT_KEY: JSX.Element = <Trans>Failed to update user client key</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing required parameters</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

const updateUserClientKeyProper = (userKey: string, clientKey: string): Promise<TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve> => {
  // TODO - verify that the user has the rights to access the specified clientKey

  return new Promise((resolve, reject) => {
    try {
      if (userKey != null && clientKey != null) {
        let updateObject = {
          client_key: clientKey,
        }
        DatabaseSetMergeDocument(DatabaseRef_GlobalUser_Document(userKey), updateObject)
          .then((res_DSMD: TsInterface_GenericPromiseResolve) => {
            resolve(res_DSMD)
          })
          .catch((rej_DSMD: TsInterface_GenericPromiseReject) => {
            reject(rej_DSMD)
          })
      } else {
        reject({
          success: false,
          error: {
            message: s_FAILED_TO_UPDATE_USER_CLIENT_KEY,
            details: s_MISSING_REQUIRED_PARAMETERS,
            code: 'ER-S-S-UM-UUCK-01',
          },
        })
      }
    } catch (rej_T: unknown) {
      let errorMessage: string | null = null
      let typeAssertedError = rej_T as TsInterface_CatchError
      if (typeAssertedError != null && typeAssertedError.message != null) {
        errorMessage = typeAssertedError.message
      }
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_UPDATE_USER_CLIENT_KEY,
          details: errorMessage,
          code: 'ER-S-S-UM-UUCK-02',
        },
      })
    }
  })
}

// const generateFullPermissionOverridesObject = ( userRole: TsType_UserRoles, clientType: TsType_ClientTypes, rootClientPermissions: TsInterface_RootData_ClientPermissions, effectiveUserPermissionObject: TsInterface_UnspecifiedObject ): TsInterface_UnspecifiedObject => {
// let fullUserPermissionOverrides: TsInterface_UnspecifiedObject = {}
// let userPermissionsList = generateAvailableUserLevelPermissions( userRole, clientType, rootClientPermissions )
// // Loop through permissions and generate a full user permission object for editing
// for ( let sectionKey in userPermissionsList ){
// 	let section = userPermissionsList[ sectionKey ]
// 	if ( section != null && section["permissions"] != null ){
// 		for ( let permissionKey in section["permissions"] ){
// 			let permission = section["permissions"][ permissionKey ]
// 			// Default to any permissions specfied on a user
// 			if ( effectiveUserPermissionObject[ permissionKey ] != null ){
// 				fullUserPermissionOverrides[ permissionKey ] = effectiveUserPermissionObject[ permissionKey ]
// 			} else {
// 				// Otherwise use whatever is specified for the user
// 				if ( permission.access === "always_yes" || permission.access === "default_yes" ){
// 					fullUserPermissionOverrides[ permissionKey ] = true
// 				} else {
// 					fullUserPermissionOverrides[ permissionKey ] = false
// 				}
// 			}
// 		}
// 	}
// }
// return fullUserPermissionOverrides
// }

const generateEffectivePermissionOverridesObject = (
  userRole: TsType_UserRoles,
  clientType: TsType_ClientTypes,
  rootClientPermissions: TsInterface_RootData_ClientPermissions,
  fullUserPermissionObject: TsInterface_UnspecifiedObject,
  rootClientUser: TsInterface_RootData_ClientUser,
): TsInterface_UnspecifiedObject => {
  let effectiveUserPermissionOverrides: TsInterface_UnspecifiedObject = {}
  let userPermissionsList = generateAvailableUserLevelPermissions(userRole, clientType, rootClientPermissions, rootClientUser)
  // Loop through permissions and reduce a full permission object to just the permissions contrary to defaults for a specified user type
  for (let sectionKey in userPermissionsList) {
    let section = userPermissionsList[sectionKey]
    if (section != null && section['permissions'] != null) {
      for (let permissionKey in section['permissions']) {
        let permission = section['permissions'][permissionKey]
        // Default to any permissions specfied on a user
        if (permission.access === 'always_yes') {
          // Not included in override
        } else if (permission.access === 'default_yes') {
          if (fullUserPermissionObject[permissionKey] === false) {
            effectiveUserPermissionOverrides[permissionKey] = false
          }
        } else if (permission.access === 'default_no') {
          if (fullUserPermissionObject[permissionKey] === true) {
            effectiveUserPermissionOverrides[permissionKey] = true
          }
        } else if (permission.access === 'always_no') {
          // Not included in override
        }
      }
    }
  }
  return effectiveUserPermissionOverrides
}

///////////////////////////////
// Exports
///////////////////////////////

export const createUser = (
  formInputs: TsInterface_FormInputs,
  submittedFormData: TsInterface_UnspecifiedObject,
  rootClientPermissions: TsInterface_RootData_ClientPermissions,
  rootClientKey: string,
  setRootClientKey: TsType_VoidFunctionSingleStringParam,
  rootClientUser: TsInterface_UnspecifiedObject,
): Promise<TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve> => {
  return new Promise((resolve, reject) => {
    if (
      submittedFormData != null &&
      submittedFormData['name'] != null &&
      submittedFormData['email'] != null &&
      submittedFormData['phone'] != null &&
      submittedFormData['password'] != null &&
      submittedFormData['user_role'] != null &&
      submittedFormData['associated_organization_type'] != null
    ) {
      let effectivePermissionOverrideOject = generateEffectivePermissionOverridesObject(
        submittedFormData.user_role,
        getProp(rootClientPermissions, 'client_type', null),
        rootClientPermissions,
        submittedFormData.permission_overrides,
        rootClientUser as TsInterface_RootData_ClientUser,
      )
      submittedFormData['permission_overrides'] = effectivePermissionOverrideOject
      let userUpdateObject = CleanFormData(formInputs, submittedFormData)
      getClientKey(rootClientKey, setRootClientKey)
        .then((res_GCK: TsInterface_GenericPromiseResolve) => {
          let editorUserName = getProp(rootClientUser, 'name', null)
          let editorUserKey = getProp(rootClientUser, 'key', null)
          let clientKey = res_GCK.clientKey
          cloudFunctionManageRequest('manageUser', {
            function: 'createUserAccount',
            client_key: clientKey,
            user_info: {
              name: userUpdateObject['name'],
              email: userUpdateObject['email'],
              phone: userUpdateObject['phone'],
              password: userUpdateObject['password'],
              user_role: userUpdateObject['user_role'],
              associated_organization_type: userUpdateObject['associated_organization_type'],
            },
          })
            .then((res_CFMUR: unknown) => {
              // TODO

              // delete userUpdateObject["password"]
              // userUpdateObject["task_roles"] = {}
              // userUpdateObject["task_roles"][ submittedFormData["user_role"] ] = true
              // // userUpdateObject["status"] = "active"
              let typeAssertedCreateResult = res_CFMUR as TsInterface_GenericPromiseResolve
              const logCollectionRef = DatabaseRef_UserLogs_Collection(clientKey, typeAssertedCreateResult.userKey)
              LogChange(logCollectionRef, 'user_account_create', editorUserName, editorUserKey).finally(() => {
                resolve({ success: true, error: {} })
              })
              // }).catch(( rej_DSMD: TsInterface_GenericPromiseReject ) => {
              // 	reject( rej_DSMD )
              // })
            })
            .catch((rej_CFMUR: TsInterface_GenericPromiseReject) => {
              reject(rej_CFMUR)
            })
        })
        .catch((rej_GCK: TsInterface_GenericPromiseReject) => {
          reject(rej_GCK)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to create user'),
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-MU-CU-01',
        },
      })
    }
  })
}

export const updateUser = (
  userKey: string,
  formInputs: TsInterface_FormInputs,
  submittedFormData: TsInterface_UnspecifiedObject,
  rootClientPermissions: TsInterface_RootData_ClientPermissions,
  rootClientKey: string,
  setRootClientKey: TsType_VoidFunctionSingleStringParam,
  rootClientUser: TsInterface_UnspecifiedObject,
): Promise<TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve> => {
  return new Promise((resolve, reject) => {
    if (userKey != null) {
      let effectivePermissionOverrideOject = generateEffectivePermissionOverridesObject(
        submittedFormData.user_role,
        getProp(rootClientPermissions, 'client_type', null),
        rootClientPermissions,
        submittedFormData.permission_overrides,
        rootClientUser as TsInterface_RootData_ClientUser,
      )
      submittedFormData['permission_overrides'] = effectivePermissionOverrideOject
      let userUpdateObject = CleanFormData(formInputs, submittedFormData)
      getClientKey(rootClientKey, setRootClientKey)
        .then((res_GCK: TsInterface_GenericPromiseResolve) => {
          let editorUserName = getProp(rootClientUser, 'name', null)
          let editorUserKey = getProp(rootClientUser, 'key', null)
          let clientKey = res_GCK.clientKey
          userUpdateObject['task_roles'] = {}
          userUpdateObject['task_roles'][submittedFormData['user_role']] = true
          DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(clientKey, userKey), userUpdateObject)
            .then((res_DSMD) => {
              const logCollectionRef = DatabaseRef_UserLogs_Collection(clientKey, userKey)
              LogChange(logCollectionRef, 'user_account_update', editorUserName, editorUserKey).finally(() => {
                resolve(res_DSMD)
              })
            })
            .catch((rej_DSMD: unknown) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK: unknown) => {
          reject(rej_GCK)
        })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_UPDATE_USER,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-MU-UU-01',
        },
      })
    }
  })
}

export const deactivateAccount = (
  clientKey: string,
  userKey: string,
  userName: string,
  updaterUserKey: string,
): Promise<TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve> => {
  return new Promise((resolve, reject) => {
    cloudFunctionManageRequest('manageUser', {
      function: 'disableUserAccount',
      client_key: clientKey,
      user_key: userKey,
    })
      .then((res_CFMUR: unknown) => {
        const logCollectionRef = DatabaseRef_UserLogs_Collection(clientKey, userKey)
        LogChange(logCollectionRef, 'user_account_disable', userName, userKey).finally(() => {
          resolve({ success: true, error: {} })
        })
      })
      .catch((rej_CFMUR: unknown) => {
        reject(rej_CFMUR)
      })
  })
}

export const activateAccount = (
  clientKey: string,
  userKey: string,
  userName: string,
  updaterUserKey: string,
): Promise<TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve> => {
  return new Promise((resolve, reject) => {
    cloudFunctionManageRequest('manageUser', {
      function: 'enableUserAccount',
      client_key: clientKey,
      user_key: userKey,
    })
      .then((res_CFMUR: unknown) => {
        const logCollectionRef = DatabaseRef_UserLogs_Collection(clientKey, userKey)
        LogChange(logCollectionRef, 'user_account_reenable', userName, userKey).finally(() => {
          resolve({ success: true, error: {} })
        })
      })
      .catch((rej_CFMUR: unknown) => {
        reject(rej_CFMUR)
      })
  })
}

export const updateUserClientKey = (
  clientKey: string,
  rootGlobalUser: TsInterface_UnspecifiedObject,
): Promise<TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve> => {
  return new Promise((resolve, reject) => {
    if (rootGlobalUser.super === true) {
      updateUserClientKeyProper(rootGlobalUser.key, clientKey)
        .then((res_UUCKP: TsInterface_GenericPromiseResolve) => {
          resolve(res_UUCKP)
        })
        .catch((rej_UUCKP: TsInterface_GenericPromiseReject) => {
          reject(rej_UUCKP)
        })
    } else {
      cloudFunctionManageRequest('manageUser', {
        function: 'updateUserClientKey',
        client_key: clientKey,
      })
        .then((res_CFMUR: unknown) => {
          resolve(res_CFMUR as TsInterface_GenericPromiseResolve)
        })
        .catch((rej_CFMUR: unknown) => {
          reject(rej_CFMUR)
        })
    }
  })
}

///////////////////////////////

import { themeVariables } from 'rfbp_aux/config/app_theme'
import { objectToArray } from '../../../../rfbp_core/services/helper_functions/index'

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

// TEMP

export const processCrewHoursData = (rawCrewHours: any, mondaysInDateRange: string[]): { summary_rows: any[]; data_rows: any[] } => {
  const today = new Date()
  today.setHours(0, 0, 0, 0) // Normalize today's date

  const formattedData: any = {
    summary_rows: [],
    data_rows: [],
  }

  const dateHeaders = [{ cellValue: 'Crew Hours', conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]
  const totals: any = {}
  const personData: any = {}

  // Initialize each date with a zero total custom metric and check if the date is in the future
  objectToArray(mondaysInDateRange).forEach((monday: string) => {
    const date = new Date(monday)
    date.setDate(date.getDate() + 1) // Add one day to the Monday date
    const formattedDateKey = monday // Keep using the original Monday as the key
    const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })

    totals[formattedDateKey] = null // Initialize totals as null

    dateHeaders.push({
      cellValue: formattedDate,
      conditionalFormatting: { fontWeight: 'bold', width: '120px' },
    })
  })

  // Process each week's data
  Object.entries(rawCrewHours || {}).forEach(([dateStr, weekData]: [string, any]) => {
    const formattedDateKey = dateStr // Use dateStr directly as the key

    Object.entries(weekData.data || {}).forEach(([personId, details]: [string, any]) => {
      if (
        details &&
        'field_hours' in details &&
        'total_panels' in details &&
        'total_units' in details &&
        'total_battery_units' in details &&
        'name' in details
      ) {
        const rightSideOfEquation =
          details.total_panels +
          details.total_battery_units +
          (details.total_pitch_units || 0) + // Ensure pitch units are included, defaulting to 0 if undefined
          details.total_units

        const customMetric = details.field_hours !== 0 && rightSideOfEquation !== 0 ? details.field_hours - rightSideOfEquation : null

        if (!personData[details.name]) {
          personData[details.name] = { details: {} }
          objectToArray(mondaysInDateRange).forEach((monday: string) => {
            personData[details.name].details[monday] = null
          })
        }

        personData[details.name].details[formattedDateKey] = customMetric

        if (customMetric !== null) {
          if (totals[formattedDateKey] === null) {
            totals[formattedDateKey] = 0
          }
          totals[formattedDateKey] += customMetric
        }
      }
    })
  })

  // Construct the data rows for each person
  Object.keys(personData)
    .sort()
    .forEach((personName: string) => {
      const rowData = [
        {
          cellValue: personName,
          conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
        },
      ]
      objectToArray(mondaysInDateRange).forEach((monday: string) => {
        const metricThisWeek = personData[personName].details[monday]
        const isMetricDefined = metricThisWeek !== null && !isNaN(Number(metricThisWeek))
        const isFuture = new Date(monday) > today
        rowData.push({
          cellValue: isMetricDefined ? Number(metricThisWeek).toFixed(2) : '-',
          conditionalFormatting: {
            fontWeight: 'normal',
            backgroundColor: isMetricDefined
              ? Number(metricThisWeek) > 0
                ? themeVariables.error_main
                : Number(metricThisWeek) < 0
                  ? themeVariables.success_main
                  : ''
              : 'inherit',
            opacity: isFuture ? 0.5 : 1,
            width: '120px',
          },
        })
      })
      formattedData.data_rows.push(rowData)
    })

  // Append the total metrics summary row
  formattedData.summary_rows.push(dateHeaders)
  const totalMetricsRow = [
    {
      cellValue: 'Total',
      conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
    },
  ]
  objectToArray(mondaysInDateRange).forEach((monday: string) => {
    const totalValue = totals[monday] !== null && !isNaN(totals[monday]) ? totals[monday].toFixed(2) : '-'
    const isFuture = new Date(monday) > today
    totalMetricsRow.push({
      cellValue: totalValue,
      conditionalFormatting: {
        fontWeight: 'normal',
        backgroundColor: totalValue > 0 ? themeVariables.error_main : totalValue < 0 ? themeVariables.success_main : '',
        opacity: isFuture ? 0.5 : 1,
        width: '120px',
      },
    })
  })
  formattedData.summary_rows.push(totalMetricsRow)

  return formattedData
}

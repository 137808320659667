///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_CompanyDocumentsFile = (clientKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/operations/documents/files/' + fileName)
}

///////////////////////////////
// Imports
///////////////////////////////

import { doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_IntegrationTestData_Document = (clientKey: string, documentKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'test_data', 'main', documentKey)
}

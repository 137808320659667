///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_PayrollAdjustments_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'payroll_adjustments', 'main')
}

export const DatabaseRef_PayrollAdjustments_Document = (clientKey: string, userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'payroll_adjustments', 'main', userKey)
}

export const DatabaseRef_PayrollAdjustments_Unresolved_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'unresolved' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'payroll_adjustments', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_PayrollAdjustments_Week_Query = (clientKey: string, mondayDateKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_payroll_weeks.' + mondayDateKey, comparator: '==', value: true }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'payroll_adjustments', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

export const DatabaseRef_PayrollAdjustment_UserDateQuery = (clientKey: string, dateKey: string, userKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_distance_adder_user_date_key', comparator: '==', value: dateKey + '_' + userKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'payroll_adjustments', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

// TODO query by associated_user_distance_adder_date thing

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Button, Dialog, DialogContent, Divider, IconButton, Stack, Toolbar, Tooltip, Typography } from '@mui/material'
import { openPowerwallOrderSummaryDialog } from 'app/models/powerwall/powerwall_form'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_PowerwallOrder_Document } from 'rfbp_aux/services/database_endpoints/webstore/orders'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
  TsInterface_InputHooksObject,
} from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString } from 'rfbp_core/components/search'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import {
  arrayToObject,
  dynamicSort,
  getProp,
  millisecondsPerDay,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnRoundedDaysBetweenDates,
  returnTimestampFromUnknownDateFormat,
} from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { archivePowerwallProject, editProjectStatus, instantiatePowerwallProject, unarchivePowerwallProject } from './project_services'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// CSS
let progressBarCss: string =
  `
		.project_progress_bar_unassigned {
			background-image: repeating-linear-gradient(
				45deg,
				` +
  themeVariables.gray_600 +
  `,
				` +
  themeVariables.gray_600 +
  ` 4px,
				` +
  themeVariables.gray_500 +
  ` 4px,
				` +
  themeVariables.gray_500 +
  ` 8px
			)
		}
		.project_progress_bar_complete_blue_green {
			background: linear-gradient(to bottom, ` +
  themeVariables.info_light +
  ` 75%, ` +
  themeVariables.success_main +
  ` 75%);
			background-repeat: no-repeat;
		}
		.project_progress_bar_complete_blue_yellow {
			background: linear-gradient(to bottom, ` +
  themeVariables.info_main +
  ` 75%, ` +
  themeVariables.warning_main +
  ` 75%);
			background-repeat: no-repeat;
		}
		.project_progress_bar_complete_blue_red {
			background: linear-gradient(to bottom, ` +
  themeVariables.info_dark +
  ` 75%, ` +
  themeVariables.error_main +
  ` 75%);
			background-repeat: no-repeat;
		}
	`

///////////////////////////////
// Functions
///////////////////////////////

// Search Renderer
export const rJSX_ProjectSearchResult = (
  option: TsInterface_UnspecifiedObject,
  searchInputValue: string | null,
  inputHooks: TsInterface_InputHooksObject,
): JSX.Element => {
  let searchResultJSX = (
    <Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
      <Typography>
        {rLIB('Job Code')}: {rJSX_HighlightedSearchString(searchInputValue, option.id_number)}{' '}
      </Typography>
      <Typography>
        {rLIB('Customer')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_customer_name)}{' '}
      </Typography>
      <Typography>
        {rLIB('Sales Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_sales_rep_name)}{' '}
      </Typography>
      <Typography>
        {rLIB('CSS Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_css_rep_name)}{' '}
      </Typography>
      <Typography>
        {rLIB('Address')}: {rJSX_HighlightedSearchString(searchInputValue, option.location_address)}{' '}
      </Typography>
      <Button
        color={'info'}
        onClick={(event) => {
          if (option.id != null) {
            // TODO - handle other user types probably
            onClickAppNavigation(event, inputHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(option.id as string))
          }
        }}
        variant="contained"
        sx={{ marginBottom: '5px' }}
      >
        <Icon icon="magnifying-glass" />
        {rLIB('View Project')}
      </Button>
      <Divider />
    </Box>
  )
  return searchResultJSX
}

export const rJSX_SalesSearchResult = (
  option: TsInterface_UnspecifiedObject,
  searchInputValue: string | null,
  inputHooks: TsInterface_InputHooksObject,
): JSX.Element => {
  let searchResultJSX = (
    <Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
      <Typography>
        {rLIB('Job Code')}: {rJSX_HighlightedSearchString(searchInputValue, option.id_number)}{' '}
      </Typography>
      <Typography>
        {rLIB('Customer')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_customer_name)}{' '}
      </Typography>
      <Typography>
        {rLIB('Sales Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_sales_rep_name)}{' '}
      </Typography>
      <Typography>
        {rLIB('CSS Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_css_rep_name)}{' '}
      </Typography>
      <Typography>
        {rLIB('Address')}: {rJSX_HighlightedSearchString(searchInputValue, option.location_address)}{' '}
      </Typography>
      <Button
        color={'info'}
        onClick={(event) => {
          if (option.id != null) {
            // TODO - handle other user types probably
            onClickAppNavigation(event, inputHooks.un_routerNavigation, ApplicationPages.SalesProjectViewPage.url(option.id as string))
          }
        }}
        variant="contained"
        sx={{ marginBottom: '5px' }}
      >
        <Icon icon="magnifying-glass" />
        {rLIB('View Project')}
      </Button>
      <Divider />
    </Box>
  )
  return searchResultJSX
}

export const rJSX_ProjectSearchResultWithStatus = (
  option: TsInterface_UnspecifiedObject,
  searchInputValue: string | null,
  inputHooks: TsInterface_InputHooksObject,
  additionalSearchData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let searchResultJSX = (
    <Box sx={{ marginLeft: '8px', marginRight: '8px' }}>
      <Typography>
        {rLIB('Job Code')}: {rJSX_HighlightedSearchString(searchInputValue, option.id_number)}{' '}
      </Typography>
      <Typography>
        {rLIB('Customer')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_customer_name)}{' '}
      </Typography>
      <Typography>
        {rLIB('Sales Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_sales_rep_name)}{' '}
      </Typography>
      <Typography>
        {rLIB('CSS Rep')}: {rJSX_HighlightedSearchString(searchInputValue, option.associated_css_rep_name)}{' '}
      </Typography>
      <Typography>
        {rLIB('Status')}: {rJSX_HighlightedSearchString(searchInputValue, option.status)}{' '}
      </Typography>
      <Typography>
        {rLIB('Address')}: {rJSX_HighlightedSearchString(searchInputValue, option.location_address)}{' '}
      </Typography>
      <Button
        color={'info'}
        onClick={(event) => {
          if (option.id != null) {
            // TODO - handle other user types probably
            onClickAppNavigation(event, inputHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(option.id as string))
          }
        }}
        variant="contained"
        sx={{ marginBottom: '5px' }}
      >
        <Icon icon="magnifying-glass" />
        {rLIB('View Project')}
      </Button>
      <Divider />
    </Box>
  )
  return searchResultJSX
}

// Cell Related JSX
const rJSX_ProjectProgressBarSegment = (phaseKey: string, project: TsInterface_UnspecifiedObject, phaseStats: TsInterface_UnspecifiedObject): JSX.Element => {
  let endTimestamp = new Date().getTime()
  let taskCounts = {
    total: getProp(phaseStats, 'total', 0),
    completed_total: getProp(phaseStats, 'completed_total', 0),
    completed_green: getProp(phaseStats, 'completed_green', 0),
    completed_yellow: getProp(phaseStats, 'completed_yellow', 0),
    completed_red: getProp(phaseStats, 'completed_red', 0),
    ready_green: getProp(phaseStats, 'ready_green', 0),
    ready_yellow: getProp(phaseStats, 'ready_yellow', 0),
    ready_red: getProp(phaseStats, 'ready_red', 0),
    not_ready: getProp(phaseStats, 'not_ready', 0),
    unassigned: getProp(phaseStats, 'unassigned', 0),
  }
  // Override ready color counts based on actual dates
  if (project != null && project['current_active_tasks'] != null && phaseStats != null && phaseStats['active_tasks'] != null) {
    let overrideReadyGreen = 0
    let overrideReadyYellow = 0
    let overrideReadyRed = 0
    for (let loopActiveTaskKey in phaseStats['active_tasks']) {
      let minimalTasks = arrayToObject(project['current_active_tasks'], 'key')
      if (loopActiveTaskKey != null && minimalTasks[loopActiveTaskKey] != null && minimalTasks[loopActiveTaskKey]['timestamp_start'] != null) {
        let minimalTask = minimalTasks[loopActiveTaskKey]
        let startTimestamp = returnTimestampFromUnknownDateFormat(minimalTask['timestamp_start'])
        let elapsedDays = parseInt(((endTimestamp - startTimestamp) / millisecondsPerDay).toString())
        if (minimalTask['status_green_days_cutoff'] != null && elapsedDays <= minimalTask['status_green_days_cutoff']) {
          overrideReadyGreen++
        } else if (minimalTask['status_yellow_days_cutoff'] != null && elapsedDays <= minimalTask['status_yellow_days_cutoff']) {
          overrideReadyYellow++
        } else {
          overrideReadyRed++
        }
      }
    }
    taskCounts['ready_green'] = overrideReadyGreen
    taskCounts['ready_yellow'] = overrideReadyYellow
    taskCounts['ready_red'] = overrideReadyRed
  }
  let segmentJSX = <></>
  if (taskCounts.total > 0) {
    segmentJSX = (
      <Box sx={{ width: '100%', height: '18px' }}>
        <Stack direction="row">
          <Tooltip
            title={
              <>
                {getProp(taskCounts, 'completed_green', 0)} {rLIB('Completed Tasks')}: {rLIB('Green Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2 project_progress_bar_complete_blue_green"
              sx={{
                borderTop: '0px solid ' + themeVariables.info_main,
                // background: themeVariables.success_main,
                width: (getProp(taskCounts, 'completed_green', 0) / taskCounts.total) * 100 + '%',
                height: '18px',
              }}
            ></Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(taskCounts, 'completed_yellow', 0)} {rLIB('Completed Tasks')}: {rLIB('Yellow Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2 project_progress_bar_complete_blue_yellow"
              sx={{
                borderTop: '0px solid ' + themeVariables.info_main,
                // background: themeVariables.warning_main,
                width: (getProp(taskCounts, 'completed_yellow', 0) / taskCounts.total) * 100 + '%',
                height: '18px',
              }}
            ></Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(taskCounts, 'completed_red', 0)} {rLIB('Completed Tasks')}: {rLIB('Red Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2 project_progress_bar_complete_blue_red"
              sx={{
                borderTop: '0px solid ' + themeVariables.info_main,
                // background: themeVariables.error_main,
                width: (getProp(taskCounts, 'completed_red', 0) / taskCounts.total) * 100 + '%',
                height: '18px',
              }}
            ></Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(taskCounts, 'ready_green', 0)} {rLIB('Active Tasks')}: {rLIB('Green Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.success_main,
                width: (getProp(taskCounts, 'ready_green', 0) / taskCounts.total) * 100 + '%',
                height: '18px',
              }}
            ></Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(taskCounts, 'ready_yellow', 0)} {rLIB('Active Tasks')}: {rLIB('Yellow Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.warning_main,
                width: (getProp(taskCounts, 'ready_yellow', 0) / taskCounts.total) * 100 + '%',
                height: '18px',
              }}
            ></Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(taskCounts, 'ready_red', 0)} {rLIB('Active Tasks')}: {rLIB('Red Status')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.error_main,
                width: (getProp(taskCounts, 'ready_red', 0) / taskCounts.total) * 100 + '%',
                height: '18px',
              }}
            ></Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(taskCounts, 'not_ready', 0)} {rLIB('Tasks waiting on Prerequisites')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.gray_600,
                width: (getProp(taskCounts, 'not_ready', 0) / taskCounts.total) * 100 + '%',
                height: '18px',
              }}
            ></Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(taskCounts, 'unassigned', 0)} {rLIB('Unassigned Tasks')}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2 project_progress_bar_unassigned"
              sx={{
                background: themeVariables.gray_500,
                width: (getProp(taskCounts, 'unassigned', 0) / taskCounts.total) * 100 + '%',
                height: '18px',
              }}
            ></Box>
          </Tooltip>
        </Stack>
        <style>{progressBarCss}</style>
      </Box>
    )
  } else {
    segmentJSX = (
      <Tooltip
        title={<>{rLIB('No tasks yet')}</>}
        placement="top"
      >
        <Box sx={{ width: '100%', height: '18px', background: themeVariables.gray_800 }}></Box>
      </Tooltip>
    )
  }
  return segmentJSX
}

export const rJSX_ProjectProgressBar = (
  project: TsInterface_UnspecifiedObject,
  topMargin: string,
  uc_setUserInterface_CustomDialogDisplay: any,
  alreadyInDialog: boolean,
): JSX.Element => {
  // Start Icon
  let startIconJSX = <></>
  if (
    project != null &&
    project['task_completion_stats'] != null &&
    project['task_completion_stats']['sale_to_cap'] != null &&
    (project['task_completion_stats']['sale_to_cap']['completed_total'] > 0 || project['task_completion_stats']['sale_to_cap']['ready_total'] > 0)
  ) {
    startIconJSX = (
      <Tooltip
        title={<Box>{rLIB('Start')}</Box>}
        placement="top"
      >
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.info_dark }}
        >
          <Icon
            icon="square-check"
            size="2x"
            type="solid"
          ></Icon>
        </Box>
      </Tooltip>
    )
  } else {
    startIconJSX = (
      <Tooltip
        title={<Box>{rLIB('Start')}</Box>}
        placement="top"
      >
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.gray_700 }}
        >
          <Icon
            icon="square-ellipsis"
            size="2x"
            type="solid"
          ></Icon>
        </Box>
      </Tooltip>
    )
  }
  // CAP Icon
  let capIconJSX = <></>
  if (
    project != null &&
    project['task_completion_stats'] != null &&
    project['task_completion_stats']['sale_to_cap'] != null &&
    project['task_completion_stats']['sale_to_cap']['total'] > 0 &&
    project['task_completion_stats']['sale_to_cap']['total'] === project['task_completion_stats']['sale_to_cap']['completed_total']
  ) {
    capIconJSX = (
      <Tooltip
        title={<Box>{rLIB('CAP')}</Box>}
        placement="top"
      >
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.info_main }}
        >
          <Icon
            icon="square-check"
            size="2x"
            type="solid"
          ></Icon>
        </Box>
      </Tooltip>
    )
  } else {
    capIconJSX = (
      <Tooltip
        title={<Box>{rLIB('CAP')}</Box>}
        placement="top"
      >
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.gray_700 }}
        >
          <Icon
            icon="square-ellipsis"
            size="2x"
            type="solid"
          ></Icon>
        </Box>
      </Tooltip>
    )
  }
  // Install Icon
  let installIconJSX = <></>
  if (
    project != null &&
    project['task_completion_stats'] != null &&
    project['task_completion_stats']['cap_to_install'] != null &&
    project['task_completion_stats']['cap_to_install']['total'] > 0 &&
    project['task_completion_stats']['cap_to_install']['total'] === project['task_completion_stats']['cap_to_install']['completed_total']
  ) {
    installIconJSX = (
      <Tooltip
        title={<Box>{rLIB('Install')}</Box>}
        placement="top"
      >
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.info_main }}
        >
          <Icon
            icon="square-check"
            size="2x"
            type="solid"
          ></Icon>
        </Box>
      </Tooltip>
    )
  } else {
    installIconJSX = (
      <Tooltip
        title={<Box>{rLIB('Install')}</Box>}
        placement="top"
      >
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.gray_700 }}
        >
          <Icon
            icon="square-ellipsis"
            size="2x"
            type="solid"
          ></Icon>
        </Box>
      </Tooltip>
    )
  }
  // Activation Icon
  let activationIconJSX = <></>
  if (project['task_completion_stats'] == null) {
    project['task_completion_stats'] = {}
  }
  if (project['task_completion_stats'] == null) {
    project['task_completion_stats'] = {}
  }
  if (
    project != null &&
    project['task_completion_stats'] != null &&
    project['task_completion_stats']['install_to_activation'] != null &&
    project['task_completion_stats']['install_to_activation']['total'] > 0 &&
    project['task_completion_stats']['install_to_activation']['total'] === project['task_completion_stats']['install_to_activation']['completed_total']
  ) {
    activationIconJSX = (
      <Tooltip
        title={<Box>{rLIB('Activation')}</Box>}
        placement="top"
      >
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.info_main }}
        >
          <Icon
            icon="square-check"
            size="2x"
            type="solid"
          ></Icon>
        </Box>
      </Tooltip>
    )
  } else {
    activationIconJSX = (
      <Tooltip
        title={<Box>{rLIB('Activation')}</Box>}
        placement="top"
      >
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.gray_700 }}
        >
          <Icon
            icon="square-ellipsis"
            size="2x"
            type="solid"
          ></Icon>
        </Box>
      </Tooltip>
    )
  }
  // Progress Bar
  let progressBarJSX = (
    <Box
      className="tw-text-center tw-whitespace-nowrap"
      onClick={(event) => {
        if (event.detail === 2) {
          // Double Click
          if (alreadyInDialog === false) {
            let dialogJSX = (
              <Box>
                <Dialog
                  // TransitionComponent={ Transition }
                  className="bp_dialog_xl_width"
                  keepMounted
                  onClose={() => {
                    uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                  }}
                  open={true}
                >
                  <AppBar
                    position="static"
                    color="inherit"
                  >
                    <Toolbar>
                      <IconButton
                        aria-label="menu"
                        color="inherit"
                        disabled
                        edge="start"
                        size="large"
                        sx={{ mr: 2, color: '#fff !important' }}
                      >
                        <Icon icon="bars-progress" />
                      </IconButton>
                      <Typography
                        component={'span'}
                        variant={'h6'}
                        sx={{ flexGrow: 1 }}
                      >
                        <Box className="tw-inline-block">
                          {rLIB('Project')}: {project.id_number}
                        </Box>
                      </Typography>
                    </Toolbar>
                  </AppBar>
                  <DialogContent sx={{ padding: '0px' }}>
                    <Box className="tw-py-4">{rJSX_ProjectProgressBar(project, '8px', uc_setUserInterface_CustomDialogDisplay, true)}</Box>
                    <Box className="tw-p-2">
                      <Typography variant="h5">{rLIB('Active Tasks')}</Typography>
                      {rJSX_ActiveTasks(project, true)}
                    </Box>
                  </DialogContent>
                </Dialog>
              </Box>
            )
            uc_setUserInterface_CustomDialogDisplay({
              display: true,
              dialog: {
                dialog_jsx: dialogJSX,
                settings: {
                  max_width: 'lg',
                },
              },
            })
          }
        }
      }}
    >
      <Box sx={{ width: '100%' }}>
        {startIconJSX}
        <Box
          className="tw-inline-block tw-align-top"
          sx={{ marginTop: topMargin, height: '18px', width: 'calc( 31% - 28px )' }}
        >
          {rJSX_ProjectProgressBarSegment('sale_to_cap', project, getProp(project['task_completion_stats'], 'sale_to_cap', {}))}
        </Box>
        {capIconJSX}
        <Box
          className="tw-inline-block tw-align-top"
          sx={{ marginTop: topMargin, height: '18px', width: 'calc( 31% - 28px )' }}
        >
          {rJSX_ProjectProgressBarSegment('cap_to_install', project, getProp(project['task_completion_stats'], 'cap_to_install', {}))}
        </Box>
        {installIconJSX}
        <Box
          className="tw-inline-block tw-align-top"
          sx={{ marginTop: topMargin, height: '18px', width: 'calc( 31% - 28px )' }}
        >
          {rJSX_ProjectProgressBarSegment('install_to_activation', project, getProp(project['task_completion_stats'], 'install_to_activation', {}))}
        </Box>
        {activationIconJSX}
      </Box>
    </Box>
  )
  // Return
  return progressBarJSX
}

const rJSX_ActiveTaskRow = (activeTask: TsInterface_UnspecifiedObject) => {
  let endTimestamp = new Date().getTime()
  let taskStatusIcon = <></>
  let elapsedDays = parseInt(((endTimestamp - returnTimestampFromUnknownDateFormat(activeTask.timestamp_start)) / millisecondsPerDay).toString())
  if (activeTask['status_green_days_cutoff'] != null && elapsedDays <= activeTask['status_green_days_cutoff']) {
    taskStatusIcon = (
      <Tooltip
        title={
          <>
            {rLIB('Active Task')}: {rLIB('Green Status')}
          </>
        }
        placement="top"
      >
        <Box
          className="tw-inline-block tw-mr-2"
          sx={{ color: themeVariables.success_main }}
        >
          <Icon icon="circle-play"></Icon>
        </Box>
      </Tooltip>
    )
  } else if (activeTask['status_yellow_days_cutoff'] != null && elapsedDays <= activeTask['status_yellow_days_cutoff']) {
    taskStatusIcon = (
      <Tooltip
        title={
          <>
            {rLIB('Active Task')}: {rLIB('Yellow Status')}
          </>
        }
        placement="top"
      >
        <Box
          className="tw-inline-block tw-mr-2"
          sx={{ color: themeVariables.warning_dark }}
        >
          <Icon icon="triangle-exclamation"></Icon>
        </Box>
      </Tooltip>
    )
  } else {
    taskStatusIcon = (
      <Tooltip
        title={
          <>
            {rLIB('Active Task')}: {rLIB('Red Status')}
          </>
        }
        placement="top"
      >
        <Box
          className="tw-inline-block tw-mr-2"
          sx={{ color: themeVariables.error_main }}
        >
          <Icon icon="siren-on"></Icon>
        </Box>
      </Tooltip>
    )
  }
  let activeTaskJSX = <></>
  if (activeTask.associated_owner_name != null) {
    activeTaskJSX = (
      <Box>
        {taskStatusIcon}
        {activeTask.name}
        <Box className="tw-inline-block tw-ml-1 tw-opacity-30 tw-italic">({activeTask.associated_owner_name})</Box>
      </Box>
    )
  } else if (activeTask.associated_team_name != null) {
    activeTaskJSX = (
      <Box>
        {taskStatusIcon}
        {activeTask.name}
        <Box className="tw-inline-block tw-ml-1 tw-opacity-30 tw-italic">({activeTask.associated_team_name})</Box>
      </Box>
    )
  } else {
    activeTaskJSX = (
      <Box>
        {taskStatusIcon}
        {activeTask.name}
      </Box>
    )
  }
  return activeTaskJSX
}

export const rJSX_ActiveTasks = (project: TsInterface_UnspecifiedObject, includeDividers: boolean) => {
  let activeTasksJSX = <></>
  let dividerJSX = <></>
  if (includeDividers === true) {
    dividerJSX = <Divider />
  }
  if (project != null && project.current_active_tasks != null && objectToArray(project.current_active_tasks).length > 0) {
    activeTasksJSX = (
      <Box>
        {dividerJSX}

        {objectToArray(project.current_active_tasks)
          .sort(dynamicSort('name', 'asc'))
          .map((activeTask: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              {rJSX_ActiveTaskRow(activeTask)}
              {dividerJSX}
            </Box>
          ))}
      </Box>
    )
  } else {
    activeTasksJSX = (
      <Box className="tw-text-center">
        <Typography variant="subtitle1">{rLIB('No Active Tasks')}</Typography>
      </Box>
    )
  }
  return activeTasksJSX
}

export const rJSX_ActiveTasksCell = (project: TsInterface_UnspecifiedObject) => {
  let taskCountsJSX = <></>
  if (project != null && project.current_active_tasks != null && objectToArray(project.current_active_tasks).length > 0) {
    taskCountsJSX = <Box>{rJSX_ActiveTasks(project, false)}</Box>
  } else {
    taskCountsJSX = (
      <Box className="">
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.error_main }}
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-2"
            size="xl"
          />
        </Box>
        <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('No Active Tasks')}</Box>
      </Box>
    )
  }

  return taskCountsJSX
}

// Table Cell Content
const phoneCallIconCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      let currentTime = new Date()
      if (rowData != null && getProp(rowData, 'timestamp_last_contact_log', null) != null) {
        let lastContactDays = returnRoundedDaysBetweenDates(returnDateFromUnknownDateFormat(getProp(rowData, 'timestamp_last_contact_log', null)), currentTime)
        if (lastContactDays >= 12) {
          cellJSX = (
            <Tooltip
              title={
                <>
                  {rLIB('Last contacted')} {lastContactDays} {rLIB('days ago')}
                </>
              }
              placement="right"
            >
              <Box sx={{ color: themeVariables.error_main }}>
                <Icon icon="phone" />
              </Box>
            </Tooltip>
          )
        } else if (lastContactDays >= 7) {
          cellJSX = (
            <Tooltip
              title={
                <>
                  {rLIB('Last contacted')} {lastContactDays} {rLIB('days ago')}
                </>
              }
              placement="right"
            >
              <Box sx={{ color: themeVariables.warning_main }}>
                <Icon icon="phone" />
              </Box>
            </Tooltip>
          )
        }
      } else if (rowData != null && rowData.timestamp_last_contact_log != null) {
        let lastContactDays = returnRoundedDaysBetweenDates(returnDateFromUnknownDateFormat(getProp(rowData, 'timestamp_last_contact_log', null)), currentTime)
        cellJSX = (
          <Tooltip
            title={
              <>
                {rLIB('Last contacted')} {lastContactDays} {rLIB('days ago')}
              </>
            }
            placement="right"
          >
            <Box sx={{ color: themeVariables.success_main }}>
              <Icon icon="phone" />
            </Box>
          </Tooltip>
        )
      } else {
        cellJSX = (
          <Tooltip
            title={rLIB('No record of contact')}
            placement="right"
          >
            <Box className="tw-opacity-30">
              <Icon icon="phone" />
            </Box>
          </Tooltip>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <></>
    },
    header_sort_by: null,
  },
}

export const rJSX_CustomerRiskStatusCell = (project: TsInterface_UnspecifiedObject) => {
  let cellJSX = <></>
  if (project.associated_customer_risk_status != null && !isNaN(project.associated_customer_risk_status as number)) {
    switch (project['associated_customer_risk_status']) {
      case 5:
        cellJSX = (
          <Tooltip
            title={rLIB('Promoter')}
            placement="right"
          >
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.info_main }}
            >
              <Icon
                icon="face-party"
                type="solid"
                size="xl"
              ></Icon>
            </Box>
          </Tooltip>
        )
        break
      case 4:
        cellJSX = (
          <Tooltip
            title={rLIB('Happy Customer')}
            placement="right"
          >
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.success_main }}
            >
              <Icon
                icon="face-smile-beam"
                type="solid"
                size="xl"
              ></Icon>
            </Box>
          </Tooltip>
        )
        break
      case 3:
        cellJSX = (
          <Tooltip
            title={rLIB('Neutral Customer')}
            placement="right"
          >
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.warning_main }}
            >
              <Icon
                icon="face-meh"
                type="solid"
                size="xl"
              ></Icon>
            </Box>
          </Tooltip>
        )
        break
      case 2:
        cellJSX = (
          <Tooltip
            title={rLIB('Unhappy Customer')}
            placement="right"
          >
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.orange_500 }}
            >
              <Icon
                icon="face-worried"
                type="solid"
                size="xl"
              ></Icon>
            </Box>
          </Tooltip>
        )
        break
      case 1:
        cellJSX = (
          <Tooltip
            title={rLIB('Highly Escalated Customer')}
            placement="right"
          >
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.error_main }}
            >
              <Icon
                icon="face-pouting"
                type="solid"
                size="xl"
              ></Icon>
            </Box>
          </Tooltip>
        )
        break
      case 0:
        cellJSX = (
          <Tooltip
            title={rLIB('Cancelled')}
            placement="right"
          >
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.gray_700 }}
            >
              <Icon
                icon="skull"
                type="solid"
                size="xl"
              ></Icon>
            </Box>
          </Tooltip>
        )
        break
      // case -1:
      // cellJSX =
      // <Tooltip title={ s_GHOSTED } placement="right">
      // 	<Box className="tw-inline-block" sx={{ color: themeVariables.purple_500 }}>
      // 		<Icon icon="ghost" type="solid" size="xl"></Icon>
      // 	</Box>
      // </Tooltip>
      // break
    }
  } else {
    cellJSX = (
      <Tooltip
        title={rLIB('Unknown Status')}
        placement="right"
      >
        <Box
          className="tw-inline-block"
          sx={{ color: themeVariables.gray_700 }}
        >
          <Icon
            icon="circle-question"
            type="solid"
            size="xl"
          ></Icon>
        </Box>
      </Tooltip>
    )
  }
  return cellJSX
}

export const customerRiskStatusCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = rJSX_CustomerRiskStatusCell(rowData)
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <></>
    },
    header_sort_by: null,
  },
}

const adminIdNumberCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      if (rowData.id_number != null) {
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.id_number}
            </Box>
          </>
        )
      } else {
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.warning_main, '&:hover': { background: themeVariables.warning_dark } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(rowData.key as string))
                }
              }}
            >
              {rLIB('Missing')}
            </Box>
          </>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Job Code')
    },
    header_sort_by: null,
  },
}

const salesIdNumberCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      if (rowData.id_number != null) {
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.SalesProjectViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.id_number}
            </Box>
          </>
        )
      } else {
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.warning_main, '&:hover': { background: themeVariables.warning_dark } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.SalesProjectViewPage.url(rowData.key as string))
                }
              }}
            >
              {rLIB('Missing')}
            </Box>
          </>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Job Code')
    },
    header_sort_by: null,
  },
}

const timestampCustomerSignedCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      if (rowData.timestamp_customer_signed != null && !isNaN(rowData.timestamp_customer_signed as number)) {
        cellJSX = <>{returnFormattedDate(rowData.timestamp_customer_signed, 'M/D/YY')}</>
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <>{rLIB('Signed Date')}</>
    },
    header_sort_by: null,
  },
}

const progressCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <Box sx={{ minWidth: '200px' }}>{rJSX_ProjectProgressBar(rowData, '5px', tableHooks.uc_setUserInterface_CustomDialogDisplay, false)}</Box>
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Progress')
    },
    header_sort_by: null,
  },
}

export const activeTasksCell = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return 'tw-p-0'
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      if (rowData == null || rowData.current_active_tasks == null || objectToArray(rowData.current_active_tasks as any).length === 0) {
        cellJSX = (
          <Box
            sx={{ minWidth: '200px', height: '40px', background: themeVariables.warning_main }}
            className="tw-pt-2.5 tw-pl-1"
          >
            {rJSX_ActiveTasksCell(rowData)}
          </Box>
        )
      } else {
        cellJSX = <Box sx={{ minWidth: '200px' }}>{rJSX_ActiveTasksCell(rowData)}</Box>
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return rLIB('Active Tasks')
    },
    header_sort_by: null,
  },
}

// Search Table
export const tableSettings_SearchProjects: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: null,
  use_live_data: true,
  conditional_row_styles: [],
  collapsible_columns: true,
  no_data_message: rLIB('No projects from search params') as JSX.Element,
  searchable: false,
  hide_pagination: true,
}

// Active Table
export const tableSettings_MyProjects: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'associated_customer_name',
  use_live_data: true,
  conditional_row_styles: [],
  collapsible_columns: true,
  no_data_message: rLIB('No Projects') as JSX.Element,
  searchable: true,
  search_settings_database: {
    search_type: 'meilisearch',
    search_client_key: null,
    search_index_key: 'projects',
    search_filters: [],
    search_result_renderer: rJSX_ProjectSearchResult,
  },
}

export const tableSettings_AllProjects: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'associated_customer_name',
  use_live_data: true,
  conditional_row_styles: [],
  collapsible_columns: true,
  no_data_message: rLIB('No Projects') as JSX.Element,
  searchable: true,
  search_settings_database: {
    search_type: 'meilisearch',
    search_client_key: null,
    search_index_key: 'projects',
    search_filters: [],
    search_result_renderer: rJSX_ProjectSearchResult,
  },
}

export const tableSettings_UnassignedProjects: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'associated_customer_name',
  use_live_data: true,
  conditional_row_styles: [],
  collapsible_columns: true,
  no_data_message: rLIB('No Unassigned Projects') as JSX.Element,
  searchable: true,
  search_settings_database: {
    search_type: 'meilisearch',
    search_client_key: null,
    search_index_key: 'projects',
    search_filters: [],
    search_result_renderer: rJSX_ProjectSearchResult,
  },
}

export const tableColumns_ActiveProjects: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          // TODO - handle other user types probably
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(rowData.key as string))
        }
      },
    },
    change_status: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{rLIB('Edit Project Status')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              editProjectStatus(
                res_GCK.clientKey,
                rowData.key as string,
                rowData,
                tableHooks.uc_setUserInterface_FormDialogDisplay,
                tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                tableHooks.uc_RootData_ClientUser,
              )
            })
            .catch((rej_GCK) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            })
        }
      },
    },
  }),
  phone_call_icon: phoneCallIconCell,
  associated_customer_risk_status: customerRiskStatusCell,
  id_number: adminIdNumberCell,
  associated_customer_name: TableCellBasic('associated_customer_name', rLIB('Customer'), 'associated_customer_name'),
  timestamp_customer_signed: timestampCustomerSignedCell,
  TEMP_progress: progressCell,
  TEMP_active_tasks: activeTasksCell,
  associated_css_rep_name: TableCellBasic('associated_css_rep_name', rLIB('CSS Rep'), 'associated_css_rep_name'),
  associated_sales_partner_name: TableCellBasic('associated_sales_partner_name', rLIB('Sales Partner'), 'associated_sales_partner_name'),
  associated_sales_rep_name: TableCellBasic('associated_sales_rep_name', rLIB('Sales Rep'), 'associated_sales_rep_name'),
  location_city: TableCellBasic('location_city', rLIB('City'), 'location_city'),
  location_state: TableCellBasic('location_state', rLIB('State'), 'location_state'),
  associated_region_name: TableCellBasic('associated_region_name', rLIB('Region'), 'associated_region_name'),
}

// Unlinked Table
export const tableSettings_UnlinkedProjects: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'timestamp_created',
  use_live_data: true,
  conditional_row_styles: [],
  collapsible_columns: true,
  searchable: true,
  no_data_message: rLIB('No Unlinked Projects') as JSX.Element,
  search_settings_database: {
    search_type: 'firebase',
    search_no_data_message: rLIB('No projects found by search') as JSX.Element,
    search_property_options: [
      { key: 'associated_customer_name', value: rLIB('Customer') },
      { key: 'associated_customer_email', value: rLIB('Email') },
      { key: 'associated_customer_phone', value: rLIB('Phone') },
    ],
  },
}

export const tableColumns_UnlinkedProjects: TsInterface_TableColumns = {
  manage: TableCellManage({
    merge: {
      icon: (
        <Icon
          type="solid"
          icon="merge"
        />
      ),
      label: <>{rLIB('View and Merge')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectMergePage.url(rowData.key as string))
        }
      },
    },
  }),
  associated_customer_name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData.associated_customer_name != null) {
          cellJSX = (
            <>
              <Box
                className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
                sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
                onClick={(event) => {
                  if (rowData.key != null) {
                    // TODO - handle other user types probably
                    onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectMergePage.url(rowData.key as string))
                  }
                }}
              >
                {rowData.associated_customer_name}
              </Box>
            </>
          )
        } else {
          cellJSX = (
            <>
              <Box
                className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
                sx={{ 'background': themeVariables.warning_main, '&:hover': { background: themeVariables.warning_dark } }}
                onClick={(event) => {
                  if (rowData.key != null) {
                    // TODO - handle other user types probably
                    onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectMergePage.url(rowData.key as string))
                  }
                }}
              >
                {rLIB('Missing')}
              </Box>
            </>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Customer')
      },
      header_sort_by: null,
    },
  },
  associated_customer_email: TableCellBasic('associated_customer_email', rLIB('Email'), 'associated_customer_email'),
  associated_customer_phone: TableCellBasic('associated_customer_phone', rLIB('Phone'), 'associated_customer_phone'),
  associated_creator_user_name: TableCellBasic('associated_creator_user_name', rLIB('Sales Rep'), 'associated_creator_user_name'),
  location_address: TableCellBasic('location_address', rLIB('Address'), 'location_address'),
  location_city: TableCellBasic('location_city', rLIB('City'), 'location_city'),
  location_state: TableCellBasic('location_state', rLIB('State'), 'location_state'),
  location_zip: TableCellBasic('location_zip', rLIB('Zip'), 'location_zip'),
}

// Powerwall Table
export const tableSettings_PowerwallOrders: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  // sort_property: "timestamp_agreed_to_terms",
  sort_property: 'timestamp_created',
  use_live_data: true,
  conditional_row_styles: [],
  collapsible_columns: true,
  searchable: false,
  no_data_message: rLIB('No Powerwall Orders') as JSX.Element,
  // search_settings_database: {
  // search_type: "firebase",
  // search_no_data_message: rLIB("No projects found by search"),
  // search_property_options: [
  // 	{ key: "first_name", value: s_FIRST_NAME },
  // 	{ key: "last_name", value: s_LAST_NAME },
  // ]
  // },
}

const powerwallManageCell = TableCellManage({
  view_order: {
    icon: <Icon icon="magnifying-glass" />,
    label: rLIB('View'),
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      openPowerwallOrderSummaryDialog(tableHooks.uc_setUserInterface_CustomDialogDisplay, rowData)
    },
  },
  create_project: {
    icon: <Icon icon="plus-circle" />,
    label: rLIB('Create Project'),
    conditional_display: {
      active: true,
      logic_type: 'and',
      source: null,
      prop: null,
      comparator: null,
      value: null,
      conditions: [
        {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'associated_project_key',
          comparator: '==',
          value: null,
          conditions: [],
        },
      ],
    },
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      if (rowData != null && rowData.key != null) {
        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            instantiatePowerwallProject(res_GCK.clientKey, rowData.key as string)
              .then((res_IPP) => {
                // Nothing
              })
              .catch((rej_IPP) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_IPP.error })
              })
          })
          .catch((rej_GCK) => {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      } else {
        tableHooks.uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            message: rLIB('Failed to instantiate powerwall project'),
            details: rLIB('Missing required parameters'),
            code: 'ER-D-PT-TCPO-01',
          },
        })
      }
    },
  },
  view_project: {
    icon: <Icon icon="magnifying-glass" />,
    label: rLIB('View Project'),
    conditional_display: {
      active: true,
      logic_type: 'and',
      source: null,
      prop: null,
      comparator: null,
      value: null,
      conditions: [
        {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '!=',
          value: null,
          conditions: [],
        },
      ],
    },
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(rowData.associated_project_key as string))
    },
  },
  archive: {
    icon: <Icon icon="box-archive" />,
    label: rLIB('Archive Order'),
    conditional_display: {
      active: true,
      logic_type: 'comparison',
      source: 'rowData',
      prop: 'status',
      comparator: '!=',
      value: 'archived',
      conditions: [],
    },
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      tableHooks.uc_setUserInterface_PromptDialogDisplay({
        display: true,
        prompt: {
          color: 'error',
          confirm_text: rLIB('Archive Order'),
          default_value: '',
          header: rLIB('Remove order from list'),
          icon: (
            <Icon
              icon="siren-on"
              type="solid"
            />
          ),
          input_label: rLIB('Type DANGER to proceed'),
          input_type: 'text',
          text: rLIB('Are you sure that you want to archive this order?'),
          submit_callback: (promptValue: string) => {
            return new Promise((resolve, reject) => {
              if (promptValue === 'DANGER') {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    archivePowerwallProject(res_GCK.clientKey, rowData.key as string, rowData.status as string)
                      .then((res_APP) => {
                        resolve(res_APP)
                      })
                      .catch((rej_APP) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_APP.error })
                        resolve({ close_dialog: false })
                      })
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    resolve({ close_dialog: false })
                  })
              } else {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                  display: true,
                  error: {
                    message: rLIB('Failed to archive order'),
                    details: (
                      <>
                        {rLIB('You must enter DANGER in order to proceed.')} {rLIB('Otherwise click dismiss')}
                      </>
                    ),
                    code: 'ER-D-PT-DO-01',
                  },
                })
                resolve({ close_dialog: false })
              }
            })
          },
        },
      })
    },
  },
  unarchive: {
    icon: <Icon icon="wand-magic-sparkles" />,
    label: rLIB('Unarchive Order'),
    conditional_display: {
      active: true,
      logic_type: 'comparison',
      source: 'rowData',
      prop: 'status',
      comparator: '==',
      value: 'archived',
      conditions: [],
    },
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unarchivePowerwallProject(res_GCK.clientKey, rowData.key as string, rowData.status_before_archive as string)
            .then((res_UPP) => {
              // Nothing
            })
            .catch((rej_UPP) => {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_UPP.error })
            })
        })
        .catch((rej_GCK) => {
          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    },
  },
})

export const tableColumns_PowerwallOrders: TsInterface_TableColumns = {
  manage: powerwallManageCell,
  icon: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData.associated_project_key != null) {
          cellJSX = (
            <Tooltip
              title={rLIB('Converted to project')}
              placement="right"
            >
              <Box sx={{ color: themeVariables.success_main }}>
                <Icon
                  className="tw-mr-2"
                  icon="circle-check"
                />
              </Box>
            </Tooltip>
          )
        } else {
          cellJSX = (
            <Tooltip
              title={rLIB('New Order')}
              placement="right"
            >
              <Box sx={{ color: themeVariables.warning_main }}>
                <Icon
                  className="tw-mr-2"
                  icon="sparkles"
                />
              </Box>
            </Tooltip>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
  },
  name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let nameJSX = <></>
        if ((rowData.first_name == null || rowData.first_name === '') && (rowData.last_name == null || rowData.last_name === '')) {
          nameJSX = <Box className="tw-italic tw-opacity-30">{rLIB('No name entered')}</Box>
        } else {
          nameJSX = (
            <>
              {rowData.first_name} {rowData.last_name}
            </>
          )
        }
        let cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={() => {
                if (rowData.key != null) {
                  openPowerwallOrderSummaryDialog(tableHooks.uc_setUserInterface_CustomDialogDisplay, rowData)
                }
              }}
            >
              {nameJSX}
            </Box>
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Customer')
      },
      header_sort_by: null,
    },
  },
  email: TableCellBasic('email', rLIB('Email'), 'email'),
  phone: TableCellBasic('phone', rLIB('Phone'), 'phone'),
  status: TableCellBasic('status', rLIB('Status'), 'status'),
  associated_sales_contact_name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // Edit Icon
        let editIconJSX = (
          <Tooltip
            title={rLIB('Edit')}
            placement="top"
          >
            <Box
              className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer tw-align-top"
              onClick={() => {
                // Open Dialog
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {},
                      formData: rowData,
                      formInputs: {
                        associated_sales_contact_name: {
                          data_type: 'string',
                          input_type: 'text_basic',
                          key: 'associated_sales_contact_name',
                          label: rLIB('Sales Contact'),
                          required: false,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              let updateObject = {
                                associated_sales_contact_name: getProp(formSubmittedData, 'associated_sales_contact_name', null),
                              }
                              DatabaseSetMergeDocument(DatabaseRef_PowerwallOrder_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                                .then((res_DBU) => {
                                  resolve(res_DBU)
                                })
                                .catch((rej_DBU) => {
                                  reject(rej_DBU)
                                })
                            })
                            .catch((rej_GCK) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: <>{rLIB('Edit Sales Contact')}</>,
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              }}
            >
              <Icon icon="pen-to-square" />
            </Box>
          </Tooltip>
        )
        let cellJSX = <></>
        if (rowData != null && rowData.associated_sales_contact_name != null) {
          cellJSX = (
            <Box sx={{ maxWidth: '250px' }}>
              <Box className="tw-inline-block">{rowData['associated_sales_contact_name']}</Box>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box sx={{ maxWidth: '250px' }}>
              <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('Not Assigned')}</Box>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{rLIB('Sales Contact')}</>
      },
      header_sort_by: 'notes',
    },
  },
  timestamp_created: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData.timestamp_created != null) {
          cellJSX = (
            <>
              <Box>{returnFormattedDate(returnDateFromUnknownDateFormat(rowData.timestamp_created), 'DD MMM YYYY h:mma')}</Box>
            </>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Order Timestamp')
      },
      header_sort_by: null,
    },
  },
  // notes: TableCellBasic( "notes", rLIB("Notes"), "notes" ),
  notes: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // Edit Icon
        let cellJSX = <></>
        if (rowData != null && rowData.notes != null) {
          cellJSX = (
            <Box sx={{ maxWidth: '250px' }}>
              <Box className="tw-inline-block">{rowData['notes']}</Box>
            </Box>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Notes')
      },
      header_sort_by: 'notes',
    },
  },
  notes2: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // Edit Icon
        let editIconJSX = (
          <Tooltip
            title={rLIB('Edit')}
            placement="top"
          >
            <Box
              className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer tw-align-top"
              onClick={() => {
                // Open Dialog
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: {
                    form: {
                      formAdditionalData: {
                        taskKey: rowData.key,
                      },
                      formData: rowData,
                      formInputs: {
                        internal_notes: {
                          data_type: 'string',
                          input_type: 'text_multiline',
                          key: 'internal_notes',
                          label: rLIB('Notes'),
                          required: false,
                        },
                      },
                      formOnChange: (
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {},
                      formSettings: {},
                      formSubmission: (
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              let updateObject = {
                                internal_notes: getProp(formSubmittedData, 'internal_notes', null),
                              }
                              DatabaseSetMergeDocument(DatabaseRef_PowerwallOrder_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                                .then((res_DSMD) => {
                                  resolve(res_DSMD)
                                })
                                .catch((rej_DSMD) => {
                                  reject(rej_DSMD)
                                })
                            })
                            .catch((rej_GCK) => {
                              formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            })
                        })
                      },
                    },
                    dialog: {
                      formDialogHeaderColor: 'success',
                      formDialogHeaderText: <>{rLIB('Edit Notes')}</>,
                      formDialogIcon: (
                        <Icon
                          type="solid"
                          icon="pen-to-square"
                        />
                      ),
                    },
                  },
                })
              }}
            >
              <Icon icon="pen-to-square" />
            </Box>
          </Tooltip>
        )
        let cellJSX = <></>
        if (rowData != null && rowData.internal_notes != null) {
          cellJSX = (
            <Box sx={{ maxWidth: '250px' }}>
              <Box
                className="tw-inline-block"
                sx={{ color: themeVariables.gray_700 }}
              >
                {rowData['internal_notes']}
              </Box>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box sx={{ maxWidth: '250px' }}>
              <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('No Notes')}</Box>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: 'notes',
    },
  },
  manage2: powerwallManageCell,
}

// Sales Partner Table
export const tableSettings_AllSalesProjects: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'associated_customer_name',
  use_live_data: true,
  conditional_row_styles: [],
  collapsible_columns: true,
  no_data_message: rLIB('No Projects') as JSX.Element,
  // searchable: true,
  // search_settings_database: {
  //   search_type: 'meilisearch',
  //   search_client_key: null,
  //   search_index_key: 'projects',
  //   search_filters: [], // TODO - Add filters for sales partner key in container
  //   search_result_renderer: rJSX_SalesSearchResult,
  // },
}

export const tableColumns_AllSalesProjects: TsInterface_TableColumns = {
  phone_call_icon: phoneCallIconCell,
  associated_customer_risk_status: customerRiskStatusCell,
  id_number: salesIdNumberCell,
  associated_customer_name: TableCellBasic('associated_customer_name', rLIB('Customer'), 'associated_customer_name'),
  timestamp_customer_signed: timestampCustomerSignedCell,
  TEMP_progress: progressCell,
  TEMP_active_tasks: activeTasksCell,
  associated_css_rep_name: TableCellBasic('associated_css_rep_name', rLIB('CSS Rep'), 'associated_css_rep_name'),
  associated_sales_partner_name: TableCellBasic('associated_sales_partner_name', rLIB('Sales Partner'), 'associated_sales_partner_name'),
  associated_sales_rep_name: TableCellBasic('associated_sales_rep_name', rLIB('Sales Rep'), 'associated_sales_rep_name'),
  location_city: TableCellBasic('location_city', rLIB('City'), 'location_city'),
  location_state: TableCellBasic('location_state', rLIB('State'), 'location_state'),
}

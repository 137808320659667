///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material/'
import { logicViewModeOptions, rJSX_LogicPathRender } from 'app/models/ml/logic_engine_ui'
import { returnFormattedDatabaseKey } from 'app/models/ml/ml_functions'
import {
  projectVariablesList,
  tableColumns_HardcodeVariables,
  tableColumns_MLModels,
  tableColumns_ProjectAndMetadataVariables,
  tableSettings_HardcodeVariables,
  tableSettings_MLModels,
  tableSettings_ProjectAndMetadataVariables,
} from 'app/models/ml/ml_ui'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_MLEvaluationProceduresProdData_Document,
  DatabaseRef_MLEvaluationProceduresStagingData_Document,
  DatabaseRef_MLEvaluationProcedures_Document,
} from 'rfbp_aux/services/database_endpoints/machine_learning/evaluation_procedures'
import { DatabaseRef_ActiveMLModels_Query } from 'rfbp_aux/services/database_endpoints/machine_learning/models'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TsInterface_TableAdditionalData } from 'rfbp_core/components/table'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, generateRandomString, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['MLEvaluationProcedureViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_IMAGE_METADATA: JSX.Element = 														<Trans>Image Metadata</Trans>
const s_AGGREGATE_EVALUATIONS: JSX.Element = <Trans>Aggregate Evaluations</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_AGGREGATE_EVALUATION: JSX.Element = (
  <Trans>Are you sure that you want to delete this aggregate evaluation?</Trans>
)
const s_BACK_TO_ALL_ML: JSX.Element = <Trans>Back to all ML</Trans>
const s_CALCULATION_ERROR: JSX.Element = <Trans>Calculation Error</Trans>
const s_CALCULATION_RESULT: JSX.Element = <Trans>Calculation Result</Trans>
const s_CREATED_BY: JSX.Element = <Trans>Created By</Trans>
const s_CREATE_AGGREGATE_EVALUATION: JSX.Element = <Trans>Create Aggregate Evaluation</Trans>
const s_CREATE_VARIABLE: JSX.Element = <Trans>Create Variable</Trans>
const s_CREATE_VARIABLE_FOR_FORMULA: JSX.Element = <Trans>Create Variable for Formula</Trans>
const s_CUSTOM_VARIABLES: JSX.Element = <Trans>Custom Variables</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_AGGREGATE_EVALUATION: JSX.Element = <Trans>Delete Aggregate Evaluation</Trans>
const s_DESCRIPTION: JSX.Element = <Trans>Description</Trans>
const s_ENTER_A_NAME_FOR_THE_NEW_AGGREGATE_EVALUATION: JSX.Element = <Trans>Enter a name for the new aggregate evaluation</Trans>
const s_ENTER_A_NAME_FOR_THE_NEW_VARIABLE: JSX.Element = <Trans>Enter a name for the new variable</Trans>
const s_ENTER_A_NEW_VALUE_FOR: JSX.Element = <Trans>Enter a new value for</Trans>
const s_EVALUATION_NAME: JSX.Element = <Trans>Evaluation Name</Trans>
const s_EVALUATION_PROCEDURE_DETAILS: JSX.Element = <Trans>Evaluation Procedure Details</Trans>
const s_INDIVIDUAL_EVALUATION: JSX.Element = <Trans>Individual Evaluation</Trans>
const s_LOGIC_VIEW_MODE: JSX.Element = <Trans>Logic View Mode</Trans>
const s_ML_EVALUATION_PROCEDURE: JSX.Element = <Trans>ML Evaluation Procedure</Trans>
const s_MODELS: JSX.Element = <Trans>Models</Trans>
const s_NAME: JSX.Element = <Trans>Name</Trans>
const s_PROJECT_VARIABLES: JSX.Element = <Trans>Project Variables</Trans>
const s_UPDATE: JSX.Element = <Trans>Update</Trans>
const s_VARIABLES: JSX.Element = <Trans>Variables</Trans>
const s_VARIABLE_NAME: JSX.Element = <Trans>Variable Name</Trans>
const se_ML_EVALUATION_PROCEDURE: string = 'ML Evaluation Procedure'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_calculationData, us_setCalculationData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_calculationStagingData, us_setCalculationStagingData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadedCalculationData, us_setLoadedCalculationData] = useState<boolean>(false)
  const [us_logicPathTraceData, us_setLogicPathTraceData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_modelsList, us_setModelsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedAggregateEvaluation, us_setSelectedAggregateEvaluation] = useState<string | null>(null)
  const [us_selectedLogicViewType, us_setSelectedLogicViewType] = useState<'view' | 'edit' | 'trace'>('view')
  const [us_showingAggregateCollapsible, us_setShowingAggregateCollapsible] = useState<boolean>(true)
  const [us_showingDetailsCollapsible, us_setShowingDetailsCollapsible] = useState<boolean>(true)
  const [us_showingIndividualCollapsible, us_setShowingIndividualCollapsible] = useState<boolean>(true)
  const [us_showingModelsCollapsible, us_setShowingModelsCollapsible] = useState<boolean>(true)
  const [us_showingVariablesCollapsible, us_setShowingVariablesCollapsible] = useState<boolean>(true)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'ml'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    document.title = se_ML_EVALUATION_PROCEDURE
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCalculationData(newData)
      us_setLoadedCalculationData(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_MLEvaluationProcedures_Document(res_GCK.clientKey, pr_itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCalculationStagingData(newData)
      us_setLoadedCalculationData(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_MLEvaluationProceduresStagingData_Document(res_GCK.clientKey, pr_itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    // if(
    // 	selectedLogicViewType === "trace" &&
    // 	calculationStagingData != null &&
    // 	calculationStagingData.individual_evaluation != null &&
    // 	calculationStagingData.individual_evaluation.logic != null &&
    // 	calculationStagingData.variables != null
    // ){
    // setLogicPathTraceData( evaluateFormulaLogicPath(
    // 	calculationStagingData.individual_evaluation.logic,
    // 	returnTestVariables( calculationStagingData.variables ),
    // 	{}
    // ) )
    // }
    us_setLogicPathTraceData({})
    return () => {}
  }, [us_calculationStagingData, us_selectedLogicViewType])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setModelsList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveMLModels_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Other Variables
  const returnFilteredFormulaVariables = (variableType: string | null): TsInterface_UnspecifiedObject[] => {
    let filteredData: TsInterface_UnspecifiedObject[] = []
    if (us_calculationStagingData != null && us_calculationStagingData['variables'] != null) {
      filteredData = objectToArray(us_calculationStagingData['variables'])
    }
    if (variableType != null) {
      return filteredData.filter((obj) => {
        return obj['variable_type'] === variableType
      })
    } else {
      return filteredData
    }
  }

  const tableAdditionalData_HardcodeVariables: TsInterface_TableAdditionalData = {
    itemKey: pr_itemKey,
  }

  const tableAdditionalData_ProjectVariables: TsInterface_TableAdditionalData = {
    itemKey: pr_itemKey,
    variables_enabled_for_logic: us_calculationStagingData['variables'],
  }

  // const tableAdditionalData_ImageMetadataVariables: TsInterface_TableAdditionalData = {
  // itemKey: pr_itemKey,
  // variables_enabled_for_logic: calculationStagingData["variables"]
  // }

  const tableAdditionalDataMLModels: TsInterface_TableAdditionalData = {
    itemKey: pr_itemKey,
    models_enabled_for_logic: us_calculationStagingData['models'],
  }

  // Functions
  const saveUpdateToFormulaStaging = (updateObject: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      // TODO - Temporary setup, full staging to prod pipeline and version history later
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            {
              type: 'setMerge',
              ref: DatabaseRef_MLEvaluationProceduresStagingData_Document(res_GCK.clientKey, pr_itemKey),
              data: updateObject,
            },
            {
              type: 'setMerge',
              ref: DatabaseRef_MLEvaluationProceduresProdData_Document(res_GCK.clientKey, pr_itemKey),
              data: updateObject,
            },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  const saveIndividualEvaluationFormulaChangeToDatabase = (updateObject: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          saveUpdateToFormulaStaging(updateObject)
            .then((res_SUTFS) => {
              resolve(res_SUTFS)
            })
            .catch((rej_SUTFS) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SUTFS.error })
              reject(rej_SUTFS)
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    })
  }

  const returnTestVariables = (variables: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
    let cleanVariables: TsInterface_UnspecifiedObject = {}
    for (let variableKey in variables) {
      cleanVariables[variableKey] = getProp(variables[variableKey], 'test_value', null)
    }
    return cleanVariables
  }

  const returnFilteredAggregateEvaluations = (aggregateEvaluations: TsInterface_UnspecifiedObject[]): TsInterface_UnspecifiedObject[] => {
    return aggregateEvaluations.filter((obj) => obj.status === 'active')
  }

  // JSX Generation
  const rJSX_EvaluationProceduresDetailsCollapsible = (): JSX.Element => {
    let collapseJSX: JSX.Element = <></>
    collapseJSX = (
      <Card>
        <List
          sx={{ width: '100%' }}
          className="tw-py-0"
        >
          <ListItemButton
            sx={{ background: us_showingDetailsCollapsible ? themeVariables.background_hover : 'inherit' }}
            onClick={() => {
              us_setShowingDetailsCollapsible(!us_showingDetailsCollapsible)
            }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h5"
                  className="tw-font-bold tw-inline-block"
                >
                  {s_EVALUATION_PROCEDURE_DETAILS}
                </Typography>
              }
            />
            {us_showingDetailsCollapsible ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
          </ListItemButton>
          <Collapse
            in={us_showingDetailsCollapsible}
            timeout="auto"
            unmountOnExit
          >
            <Divider />
            <Box className="tw-p-2">
              {rJSX_LineItem(s_NAME, us_calculationData, 'name', false)}
              {rJSX_LineItem(s_DESCRIPTION, us_calculationData, 'description', true)}
              {rJSX_LineItem(s_CREATED_BY, us_calculationData, 'associated_creator_name', false)}
            </Box>
          </Collapse>
        </List>
      </Card>
    )
    return collapseJSX
  }

  const rJSX_ListOfModelsCollapsible = (): JSX.Element => {
    let collapseJSX: JSX.Element = <></>
    collapseJSX = (
      <Card>
        <List
          sx={{ width: '100%' }}
          className="tw-py-0"
        >
          <ListItemButton
            sx={{ background: us_showingModelsCollapsible ? themeVariables.background_hover : 'inherit' }}
            onClick={() => {
              us_setShowingModelsCollapsible(!us_showingModelsCollapsible)
            }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h5"
                  className="tw-font-bold tw-inline-block"
                >
                  {s_MODELS}
                </Typography>
              }
            />
            {us_showingDetailsCollapsible ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
          </ListItemButton>
          <Collapse
            in={us_showingModelsCollapsible}
            timeout="auto"
            unmountOnExit
          >
            <Divider />
            <TableBasic
              tableAdditionalData={tableAdditionalDataMLModels}
              tableColumns={tableColumns_MLModels}
              tableData={objectToArray(us_modelsList)}
              tableSettings={tableSettings_MLModels}
            />
          </Collapse>
        </List>
      </Card>
    )
    return collapseJSX
  }

  const rJSX_ListOfVariablesCollapsible = (): JSX.Element => {
    let collapseJSX: JSX.Element = <></>
    collapseJSX = (
      <Card>
        <List
          sx={{ width: '100%' }}
          className="tw-py-0"
        >
          <ListItemButton
            sx={{ background: us_showingVariablesCollapsible ? themeVariables.background_hover : 'inherit' }}
            onClick={() => {
              us_setShowingVariablesCollapsible(!us_showingVariablesCollapsible)
            }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h5"
                  className="tw-font-bold tw-inline-block"
                >
                  {s_VARIABLES}
                </Typography>
              }
            />
            {us_showingDetailsCollapsible ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
          </ListItemButton>
          <Collapse
            in={us_showingVariablesCollapsible}
            timeout="auto"
            unmountOnExit
          >
            <Divider />
            <TabsBasic
              tabs={[
                {
                  tabHeader: s_CUSTOM_VARIABLES,
                  tabContent: (
                    <Box>
                      <Box className="tw-px-2">{rJSX_NewVariableButton()}</Box>
                      <TableBasic
                        tableAdditionalData={tableAdditionalData_HardcodeVariables}
                        tableColumns={tableColumns_HardcodeVariables}
                        tableData={returnFilteredFormulaVariables('hardcode')}
                        tableSettings={tableSettings_HardcodeVariables}
                      />
                    </Box>
                  ),
                },
                {
                  tabHeader: s_PROJECT_VARIABLES,
                  tabContent: (
                    <Box>
                      <TableBasic
                        tableAdditionalData={tableAdditionalData_ProjectVariables}
                        tableColumns={tableColumns_ProjectAndMetadataVariables}
                        tableData={objectToArray(projectVariablesList)}
                        tableSettings={tableSettings_ProjectAndMetadataVariables}
                      />
                    </Box>
                  ),
                },
                // {
                // tabHeader: s_IMAGE_METADATA,
                // tabContent:
                // <Box>
                // 	<TableBasic
                // 		tableAdditionalData={ tableAdditionalData_ImageMetadataVariables }
                // 		tableColumns={ tableColumns_ProjectAndMetadataVariables }
                // 		tableData={ objectToArray(imageMetadataVariablesList) }
                // 		tableSettings={ tableSettings_ProjectAndMetadataVariables }
                // 	/>
                // </Box>
                // },
              ]}
              tabsSettings={{}}
            />
          </Collapse>
        </List>
      </Card>
    )
    return collapseJSX
  }

  const rJSX_IndividualEvaluationCollapsible = (): JSX.Element => {
    let collapseJSX: JSX.Element = <></>
    collapseJSX = (
      <Card>
        <List
          sx={{ width: '100%' }}
          className="tw-py-0"
        >
          <ListItemButton
            sx={{ background: us_showingIndividualCollapsible ? themeVariables.background_hover : 'inherit' }}
            onClick={() => {
              us_setShowingIndividualCollapsible(!us_showingIndividualCollapsible)
            }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h5"
                  className="tw-font-bold tw-inline-block"
                >
                  {s_INDIVIDUAL_EVALUATION}
                </Typography>
              }
            />
            {us_showingDetailsCollapsible ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
          </ListItemButton>
          <Collapse
            in={us_showingIndividualCollapsible}
            timeout="auto"
            unmountOnExit
          >
            <Divider />
            <Box className="tw-p-2 tw-mt-2">
              {logicPathHeaderJSX()}
              <Divider className="tw-my-2" />
              {rJSX_LogicPathRender(
                // getProp(calculationStagingData, "logic", {}),
                getProp(us_calculationStagingData.individual_evaluation, 'logic', {}),
                us_selectedLogicViewType,
                { variables: returnTestVariables(getProp(us_calculationStagingData, 'variables', {})), calculationPath: us_logicPathTraceData },
                0,
                saveIndividualEvaluationFormulaChangeToDatabase,
                uc_setUserInterface_ConfirmDialogDisplay,
                us_calculationStagingData,
                {
                  condition_if_else: true,
                  condition_switch: true,
                  operation_formula: false,
                  operation_run_model: true,
                  operation_terminate: false,
                  condition_ml_category_if_else: false, // TODO - probably needed
                  operation_generate_message: false,
                },
                ['individual_evaluation', 'logic'],
              )}
            </Box>
          </Collapse>
        </List>
      </Card>
    )
    return collapseJSX
  }

  const rJSX_AggregateEvaluationCollapsible = (): JSX.Element => {
    let collapseJSX: JSX.Element = <></>
    collapseJSX = (
      <Card>
        <List
          sx={{ width: '100%' }}
          className="tw-py-0"
        >
          <ListItemButton
            sx={{ background: us_showingAggregateCollapsible ? themeVariables.background_hover : 'inherit' }}
            onClick={() => {
              us_setShowingAggregateCollapsible(!us_showingAggregateCollapsible)
            }}
          >
            <ListItemText
              primary={
                <Typography
                  variant="h5"
                  className="tw-font-bold tw-inline-block"
                >
                  {s_AGGREGATE_EVALUATIONS}
                </Typography>
              }
            />
            {us_showingDetailsCollapsible ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
          </ListItemButton>
          <Collapse
            in={us_showingAggregateCollapsible}
            timeout="auto"
            unmountOnExit
          >
            <Divider />
            <Box className="tw-p-2">
              <Box className="tw-px-2">
                {rJSX_NewAggregateEvaluationButton()}
                {rJSX_SelectAggreageEvaluation()}
                {rJSX_DeleteAggregateEvaluationButton()}
              </Box>
              {rJSX_AggregateEvaluationLogicPathRender()}
            </Box>
          </Collapse>
        </List>
      </Card>
    )
    return collapseJSX
  }

  const rJSX_AggregateEvaluationLogicPathRender = (): JSX.Element => {
    let logicPathJSX: JSX.Element = <></>
    if (
      us_calculationStagingData != null &&
      us_calculationStagingData.aggregate_evaluations != null &&
      us_selectedAggregateEvaluation != null &&
      us_selectedAggregateEvaluation !== '' &&
      us_calculationStagingData.aggregate_evaluations[us_selectedAggregateEvaluation] != null &&
      us_calculationStagingData.aggregate_evaluations[us_selectedAggregateEvaluation].logic != null
    ) {
      logicPathJSX = (
        <Box className="tw-p-2 tw-mt-2">
          {logicPathHeaderJSX()}
          <Divider className="tw-my-2" />
          {rJSX_LogicPathRender(
            getProp(us_calculationStagingData.aggregate_evaluations[us_selectedAggregateEvaluation], 'logic', {}),
            us_selectedLogicViewType,
            { variables: returnTestVariables(getProp(us_calculationStagingData, 'variables', {})), calculationPath: us_logicPathTraceData },
            0,
            saveIndividualEvaluationFormulaChangeToDatabase,
            uc_setUserInterface_ConfirmDialogDisplay,
            us_calculationStagingData,
            {
              condition_if_else: true,
              condition_switch: true,
              operation_formula: false,
              operation_run_model: false,
              operation_terminate: true,
              condition_ml_category_if_else: true, // TODO - probably needed
              operation_generate_message: true,
            },
            ['aggregate_evaluations', us_selectedAggregateEvaluation, 'logic'],
          )}
        </Box>
      )
    }
    return logicPathJSX
  }

  const rJSX_SelectAggreageEvaluation = (): JSX.Element => {
    let selectJSX = <></>
    selectJSX = (
      <FormControl
        className="bp_thin_select_input bp_thin_select_multiple_input"
        sx={{ minWidth: '130px', marginLeft: '12px', marginBottom: '8px' }}
      >
        <Select
          onChange={(event, value) => {
            us_setSelectedAggregateEvaluation(event.target.value)
          }}
          value={us_selectedAggregateEvaluation || ''}
        >
          {returnFilteredAggregateEvaluations(objectToArray(us_calculationStagingData.aggregate_evaluations))
            .sort(dynamicSort('name', null))
            .map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
                disabled={option['status'] === 'inactive'}
              >
                {option['name']}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    )
    return selectJSX
  }

  const rJSX_NewAggregateEvaluationButton = (): JSX.Element => {
    let buttonJSX: JSX.Element = <></>
    buttonJSX = (
      <Button
        className="tw-mb-2"
        variant="contained"
        // size=""
        color="success"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'success',
              confirm_text: s_CREATE_AGGREGATE_EVALUATION,
              default_value: null,
              header: s_CREATE_AGGREGATE_EVALUATION,
              icon: (
                <Icon
                  icon="pen-to-square"
                  type="solid"
                />
              ),
              input_label: s_EVALUATION_NAME,
              input_type: 'text',
              text: s_ENTER_A_NAME_FOR_THE_NEW_AGGREGATE_EVALUATION,
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  let evaluationKey = new Date().getTime() + '_' + generateRandomString(6, null)
                  let updateObject: TsInterface_UnspecifiedObject = {
                    aggregate_evaluations: {},
                  }
                  updateObject['aggregate_evaluations'][evaluationKey] = {
                    status: 'active',
                    name: promptValue,
                    key: evaluationKey,
                    logic: {},
                  }
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      saveUpdateToFormulaStaging(updateObject)
                        .then((res_SUTFS) => {
                          resolve(res_SUTFS)
                          us_setSelectedAggregateEvaluation(evaluationKey)
                        })
                        .catch((rej_SUTFS) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SUTFS.error })
                          reject(rej_SUTFS)
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }}
      >
        <Icon
          icon="circle-plus"
          className="tw-mr-2"
        />
        {s_CREATE_AGGREGATE_EVALUATION}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DeleteAggregateEvaluationButton = (): JSX.Element => {
    let buttonJSX: JSX.Element = <></>
    if (us_selectedAggregateEvaluation != null && us_selectedAggregateEvaluation !== '') {
      buttonJSX = (
        <Button
          className="tw-mb-2 tw-ml-2"
          variant="contained"
          color="error"
          onClick={() => {
            uc_setUserInterface_ConfirmDialogDisplay({
              display: true,
              confirm: {
                color: 'error',
                header: s_DELETE_AGGREGATE_EVALUATION,
                icon: (
                  <Icon
                    icon="trash"
                    type="solid"
                  />
                ),
                submit_text: s_DELETE,
                text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_AGGREGATE_EVALUATION,
                submit_callback: () => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          aggregate_evaluations: {},
                        }
                        updateObject['aggregate_evaluations'][us_selectedAggregateEvaluation] = null
                        saveUpdateToFormulaStaging(updateObject)
                          .then((res_SUTFS) => {
                            resolve(res_SUTFS)
                            us_setSelectedAggregateEvaluation(null)
                          })
                          .catch((rej_SUTFS) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SUTFS.error })
                            reject(rej_SUTFS)
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
            })
          }}
        >
          <Icon
            icon="trash"
            className="tw-mr-2"
          />
          {s_DELETE_AGGREGATE_EVALUATION}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          className="tw-mb-2 tw-ml-2"
          variant="contained"
          color="error"
          disabled={true}
          onClick={() => {}}
        >
          <Icon
            icon="trash"
            className="tw-mr-2"
          />
          {s_DELETE_AGGREGATE_EVALUATION}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_NewVariableButton = (): JSX.Element => {
    let buttonJSX: JSX.Element = <></>
    buttonJSX = (
      <Button
        className="tw-mb-2"
        variant="contained"
        // size=""
        color="success"
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: {
              color: 'success',
              confirm_text: s_CREATE_VARIABLE,
              default_value: null,
              header: s_CREATE_VARIABLE_FOR_FORMULA,
              icon: (
                <Icon
                  icon="pen-to-square"
                  type="solid"
                />
              ),
              input_label: s_VARIABLE_NAME,
              input_type: 'text',
              text: s_ENTER_A_NAME_FOR_THE_NEW_VARIABLE,
              submit_callback: (promptValue: string) => {
                return new Promise((resolve, reject) => {
                  let variableKey = 'HARDCODE_' + returnFormattedDatabaseKey(promptValue)
                  let updateObject: TsInterface_UnspecifiedObject = {
                    variables: {},
                  }
                  updateObject['variables'][variableKey] = {
                    status: 'active',
                    name: promptValue,
                    key: variableKey,
                    variable_type: 'hardcode',
                    data_type: null,
                    hardcode_value: null,
                  }
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      saveUpdateToFormulaStaging(updateObject)
                        .then((res_SUTFS) => {
                          resolve(res_SUTFS)
                        })
                        .catch((rej_SUTFS) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SUTFS.error })
                          reject(rej_SUTFS)
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }}
      >
        <Icon
          icon="circle-plus"
          className="tw-mr-2"
        />
        {s_CREATE_VARIABLE}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_LineItem = (propName: JSX.Element, data: TsInterface_UnspecifiedObject, propKey: string, editable: boolean): JSX.Element => {
    let lineJSX = <></>
    if (data == null) {
      data = {}
    }
    let propValue = getProp(data, propKey, null)
    let propJSX = <Box className="tw-inline-block tw-italic tw-opacity-30 tw-mr-2">{rLIB('Missing')}</Box>
    if (propValue != null) {
      propJSX = <Box className="tw-inline-block tw-opacity-70 tw-mr-2">{propValue}</Box>
    }
    let editJSX = <></>
    if (editable === true) {
      editJSX = (
        <Box
          className="tw-inline-block tw-cursor-pointer"
          sx={{
            'color': themeVariables.success_light,
            '&:hover': {
              color: themeVariables.success_main,
            },
          }}
          onClick={() => {
            uc_setUserInterface_PromptDialogDisplay({
              display: true,
              prompt: {
                color: 'success',
                confirm_text: <>{s_UPDATE}</>,
                default_value: propValue,
                header: (
                  <>
                    {s_UPDATE}: {propName}
                  </>
                ),
                icon: (
                  <Icon
                    icon="pen-to-square"
                    type="solid"
                  />
                ),
                input_label: propName,
                input_type: 'text',
                text: (
                  <>
                    {s_ENTER_A_NEW_VALUE_FOR} {propName}
                  </>
                ),
                submit_callback: (promptValue: string) => {
                  return new Promise((resolve, reject) => {
                    let updateObject: TsInterface_UnspecifiedObject = {}
                    updateObject[propKey] = promptValue
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_MLEvaluationProcedures_Document(res_GCK.clientKey, pr_itemKey), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
            })
          }}
        >
          <Icon icon="pen-to-square" />
        </Box>
      )
    }
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box className="tw-inline-block tw-mr-2 tw-font-bold">{propName}:</Box>
          {propJSX}
          {editJSX}
        </Typography>
      </Box>
    )
    return lineJSX
  }

  // Logic
  const logicPathHeaderJSX = (): JSX.Element => {
    let headerJSX = <></>
    let logicTraceResult = <></>
    if (us_selectedLogicViewType === 'trace' && us_logicPathTraceData != null && us_logicPathTraceData.success === true) {
      logicTraceResult = (
        <Box className="tw-inline-block tw-ml-2">
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-2">{s_CALCULATION_RESULT}:</Box>
            <Box
              className="tw-inline-block tw-font-bold"
              sx={{ color: themeVariables.success_light }}
            >
              {us_logicPathTraceData.value}
            </Box>
          </Typography>
        </Box>
      )
    }
    if (us_selectedLogicViewType === 'trace' && us_logicPathTraceData != null && us_logicPathTraceData.success === false) {
      logicTraceResult = (
        <Box className="tw-inline-block tw-ml-2">
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-2">{s_CALCULATION_ERROR}:</Box>
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.error_main }}
            >
              {us_logicPathTraceData.error_type} ({us_logicPathTraceData.error_path})
            </Box>
          </Typography>
        </Box>
      )
    }
    headerJSX = (
      <Box>
        <FormControl
          className="bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px' }}
        >
          <InputLabel>{s_LOGIC_VIEW_MODE}</InputLabel>
          <Select
            autoWidth={true}
            label={s_LOGIC_VIEW_MODE}
            onChange={(event, value) => {
              if (event.target.value != null) {
                us_setSelectedLogicViewType(event.target.value as 'view' | 'edit' | 'trace')
              }
            }}
            value={us_selectedLogicViewType}
          >
            {objectToArray(logicViewModeOptions).map((option: TsInterface_UnspecifiedObject) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
                disabled={option['disabled']}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {logicTraceResult}
      </Box>
    )
    return headerJSX
  }

  const rJSX_ContentContainer = (): JSX.Element => {
    let containerJSX = <></>
    if (us_loadedCalculationData === true) {
      containerJSX = (
        <Box>
          <Box className="tw-mt-6">{rJSX_EvaluationProceduresDetailsCollapsible()}</Box>
          <Box className="tw-mt-6">{rJSX_ListOfModelsCollapsible()}</Box>
          <Box className="tw-mt-6">{rJSX_ListOfVariablesCollapsible()}</Box>
          <Box className="tw-mt-6">{rJSX_IndividualEvaluationCollapsible()}</Box>
          <Box className="tw-mt-6">{rJSX_AggregateEvaluationCollapsible()}</Box>
        </Box>
      )
    } else {
      containerJSX = (
        <Box className="tw-text-center">
          <CircularProgress />
        </Box>
      )
    }
    return containerJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_calculationData != null && us_calculationData.name != null) {
      pageHeader = (
        <Box>
          <Box className="tw-inline-block tw-mr-1">{s_ML_EVALUATION_PROCEDURE}:</Box>
          <Box className="tw-inline-block">{us_calculationData.name}</Box>
        </Box>
      )
    } else {
      pageHeader = s_ML_EVALUATION_PROCEDURE
    }
    return pageHeader
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Button
              color="inherit"
              variant="outlined"
              startIcon={<Icon icon="chevron-left"></Icon>}
              onClick={(event) => {
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.MachineLearningIndexPage.url())
              }}
            >
              {s_BACK_TO_ALL_ML}
            </Button>
            <Box className="tw-mt-2">{rJSX_ContentContainer()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

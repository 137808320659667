///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_TrailerAssignmentHistory_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'trailer_assignment_history', 'main')
}

export const DatabaseRef_TrailerAssignmentHistory_Document = (clientKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'trailer_assignment_history', 'main', logKey)
}

export const DatabaseRef_TrailerAssignmentHistory_SpecificTrailer_Query = (clientKey: string, trailerKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_trailer_key', comparator: '==', value: trailerKey }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'trailer_assignment_history', 'main'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}

export const DatabaseRef_TrailerAssignmentHistory_DateRange_Query = (clientKey: string, startTimestamp: Date, endTimestamp: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_start', comparator: '>=', value: startTimestamp },
    { prop: 'timestamp_start', comparator: '<=', value: endTimestamp },
  ]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'trailer_assignment_history', 'main'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}

export const DatabaseRef_TrailerAssignmentHistory_TrailerPreviousRecord_Query = (clientKey: string, startTimestamp: Date, trailerKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_start', comparator: '<=', value: startTimestamp },
    { prop: 'associated_trailer_key', comparator: '==', value: trailerKey },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_start', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'trailer_assignment_history', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    1,
  )
}

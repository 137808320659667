//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button } from '@mui/material'
import React, { useRef, useState } from 'react'
import { Icon } from 'rfbp_core/components/icons'
import { TsInterface_UnspecifiedObject, TsType_MuiButtonVariants, TsType_MuiComponentColors, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

type TsType_FileUploadButtonProps = {
  multiple: boolean
  button: {
    text: JSX.Element
    icon: JSX.Element
    className: string
    color: TsType_MuiComponentColors
    variant: TsType_MuiButtonVariants
    disabled: boolean
  }
  accept: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject) => TsType_UnknownPromise
  additionalFileUploadParams: TsInterface_UnspecifiedObject
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const FileUploadButton: React.FC<TsType_FileUploadButtonProps> = ({
  button = {
    text: <></>,
    icon: <></>,
    className: '',
    color: 'info',
    variant: 'contained',
    disabled: false,
  },
  multiple,
  accept,
  onChange,
  additionalFileUploadParams,
}) => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_uploadingFile, us_setUploadingFile] = useState<boolean>(false)
  const ur_inputRef = useRef(null)
  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    us_setUploadingFile(true)
    onChange(event, additionalFileUploadParams).finally(() => {
      us_setUploadingFile(false)
    })
  }

  // JSX Generation
  const rJSX_ButtonIcon = (): JSX.Element => {
    let iconJSX = button.icon
    if (us_uploadingFile === true) {
      iconJSX = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin tw-mr-2"
        />
      )
    }
    return iconJSX
  }

  // Render
  return (
    <>
      <Box className="tw-inline-block">
        <input
          multiple={multiple}
          onChange={handleChange}
          accept={accept}
          className="tw-hidden"
          type="file"
          ref={ur_inputRef}
        />
        <Button
          disabled={us_uploadingFile || button.disabled}
          variant={button.variant}
          color={button.color}
          className={button.className}
          component="label"
          onClick={() => {
            // @ts-expect-error - TODO: reason for error
            if (ur_inputRef != null && ur_inputRef.current != null && ur_inputRef.current.click != null) {
              // @ts-expect-error - TODO: reason for error
              ur_inputRef.current.click()
            }
          }}
        >
          {rJSX_ButtonIcon()}
          {button.text}
        </Button>
      </Box>
    </>
  )
}

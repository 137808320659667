///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_LeaderboardData_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'leaderboards', 'main')
}

export const DatabaseRef_LeaderboardData_Document = (clientKey: string, userDateKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'leaderboards', 'main', userDateKey)
}

export const DatabaseRef_LeaderboardDataForSpecificDateKey_Query = (clientKey: string, timestampKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'timestamp_key', comparator: '==', value: timestampKey }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'leaderboards', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

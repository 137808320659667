///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_GlobalCronJobOneTimeFailedTasks_Collection = (): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'global', 'cron_job_tasks', 'one_time_failed')
}

export const DatabaseRef_GlobalCronJobOneTimeFailedTask_Document = (taskKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'global', 'cron_job_tasks', 'one_time_failed', taskKey)
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Tooltip } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Regions_Collection } from 'rfbp_aux/services/database_endpoints/directory/regions'
import {
  DatabaseRef_MasterSkusForSupplier_Query,
  DatabaseRef_MasterSKUs_Collection,
  DatabaseRef_MasterSKU_Document,
} from 'rfbp_aux/services/database_endpoints/materials/master_sku_items'
import { DatabaseRef_SupplierCategories_Document, DatabaseRef_Supplier_Document } from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { TableBasic, TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { downloadCSV, dynamicSort, getProp, keyFromString, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import {
  materialSupplierClassificationTypesArray,
  materialSupplierPickBaseUnitOfMeasurementsArray,
  materialSupplierPickPackagingTypesArray,
} from './data/data_material_options'
import { formInputs_NewSku } from './forms/form_new_skus'
import { formInputs_NewSupplierLocation } from './forms/form_new_supplier_location'
import { tableColumns_MaterialSupplierLocations } from './tables/table_material_supplier_locations'
import { tableColumns_MaterialSupplierSKUs, tableSettings_MaterialSupplierSKUs } from './tables/table_material_supplier_skus'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseMaterialsSupplierViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_supplier, us_setSupplier] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Supplier', false) as string
  }, [])

  // TEMP
  // useEffect(() => {
  // // Copying Data Between Suppliers
  // let clientKey = "etw_energy"
  // // let originItemKey = "GV7GrfwAJNj2W5nuuaca" // FAKE
  // let originItemKey = "LXYEUvHezEaq82TeYUio"
  // let destinationItemKey = "b8OGLHiR91DHJQRhofei"
  // console.log("START")
  // // Categories
  // // DatabaseGetDocument( DatabaseRef_SupplierCategories_Document( clientKey, originItemKey ) ).then(( res_DGD ) => {
  // // 	let updateObject = res_DGD.data
  // // 	console.log( updateObject )
  // // 	DatabaseSetMergeDocument( DatabaseRef_SupplierCategories_Document( clientKey, destinationItemKey ), updateObject ).then(( res_DSMD ) => {
  // // 		console.log( res_DSMD )
  // // 	}).catch(( rej_DSMD ) => {
  // // 		console.error( rej_DSMD )
  // // 	})
  // // }).catch(( rej_DGD ) => {
  // // 	console.error( rej_DGD )
  // // })
  // // SKUs
  // DatabaseGetCollection( DatabaseRef_MasterSkusForSupplier_Query( clientKey, originItemKey ) ).then(( res_DGC ) => {
  // 	let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
  // 	for( let loopKey in res_DGC.data ){
  // 		let loopItem = res_DGC.data[ loopKey ]
  // 		let newLoopKey = destinationItemKey + loopKey.replace( originItemKey, "" )
  // 		loopItem["associated_supplier_key"] = destinationItemKey
  // 		loopItem["key"] = newLoopKey
  // 		updateArray.push({
  // 			type: "setMerge",
  // 			ref: DatabaseRef_MasterSKU_Document( clientKey, newLoopKey ),
  // 			data: loopItem
  // 		})
  // 	}
  // 	console.log( updateArray )
  // 	DatabaseStagedBatchUpdate( updateArray ).then(( res_DSBU ) => {
  // 		console.log({success: true})
  // 	}).catch(( rej_DSBU ) => {
  // 		console.log( rej_DSBU )
  // 	})
  // }).catch(( rej_DGC ) => {
  // 	console.error( rej_DGC )
  // })
  // }, [ ])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSupplier(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Supplier_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const tableDatabaseEndpoint_SupplierSKUs = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      // { prop: "status", comparator: "==", value: "active" },
      { prop: 'associated_supplier_key', comparator: '==', value: itemKey },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_MasterSKUs_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const downloadAllSkus = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_MasterSkusForSupplier_Query(res_GCK.clientKey, itemKey))
          .then((res_DGC) => {
            let supplierSkus = objectToArray(res_DGC.data).sort(dynamicSort('unique_identifier', 'asc'))
            let data = [
              [
                'Unique Identifier',
                'Product Name',
                'Manufacturer',
                'Manufacturer Part Number',
                'Category',
                'Classification',
                'Pick Package Type',
                'Unit Quantity per Pick Package',
                'Pick Base Unit of Measurement',
                'Estimated Price',
                'Max Picks per Cart',
              ],
            ]
            for (let loopSkuKey in supplierSkus) {
              let loopFuelCardTransaction = getProp(supplierSkus, loopSkuKey, {})
              let row: string[] = []

              row.push(getProp(loopFuelCardTransaction, 'unique_identifier', ''))
              row.push(getProp(loopFuelCardTransaction, 'name', ''))
              row.push(getProp(loopFuelCardTransaction, 'associated_manufacturer_name', ''))
              row.push(getProp(loopFuelCardTransaction, 'associated_manufacturer_unique_identifier', ''))
              row.push(getProp(loopFuelCardTransaction, 'associated_category_name', ''))
              row.push(getProp(loopFuelCardTransaction, 'associated_classification_name', ''))
              row.push(getProp(loopFuelCardTransaction, 'pick_packaging_type', ''))
              row.push(getProp(loopFuelCardTransaction, 'pick_unit_quantity_per_package', ''))
              row.push(getProp(loopFuelCardTransaction, 'pick_base_unit_of_measurement', ''))
              row.push(getProp(loopFuelCardTransaction, 'pick_estimated_price_per_package', ''))
              row.push(getProp(loopFuelCardTransaction, 'pick_max_per_cart', ''))
              data.push(row)
            }
            downloadCSV('SKUs ' + us_supplier.name, data)
          })
          .catch((rej_DGC) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const createNewSku = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Load Classifications and Categories
        let promiseArray: TsType_UnknownPromise[] = []
        let categoriesData: TsInterface_UnspecifiedObject = {}
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_SupplierCategories_Document(res_GCK.clientKey, itemKey))
            .then((res_DGD) => {
              // categoriesData = res_DGD.data
              for (let loopCategoryKey in getProp(res_DGD.data, 'associated_categories', {})) {
                let loopCategory = getProp(res_DGD.data, 'associated_categories.' + loopCategoryKey, null)
                if (loopCategory != null) {
                  categoriesData[loopCategoryKey] = { key: loopCategory, value: loopCategory }
                }
              }
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            }),
        )
        // After Data Loaded
        Promise.all(promiseArray).finally(() => {
          formInputs_NewSku['associated_category_name']['options'] = objectToArray(categoriesData)
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: formInputs_NewSku,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let skuItemKey = itemKey + '_' + formSubmittedData['unique_identifier']
                    let updateObject: TsInterface_UnspecifiedObject = {
                      associated_supplier_key: itemKey,
                      key: skuItemKey,
                      name: formSubmittedData['name'],
                      status: 'active',
                      timestamp_last_updated: new Date(),
                      unique_identifier: formSubmittedData['unique_identifier'],
                    }
                    if (
                      formSubmittedData != null &&
                      formSubmittedData['pick_max_per_cart'] != null &&
                      !isNaN(parseFloat(formSubmittedData['pick_max_per_cart']))
                    ) {
                      updateObject['pick_max_per_cart'] = Math.abs(parseFloat(formSubmittedData['pick_max_per_cart']))
                    } else {
                      updateObject['pick_max_per_cart'] = 10
                    }
                    if (
                      formSubmittedData != null &&
                      formSubmittedData['pick_unit_quantity_per_package'] != null &&
                      !isNaN(parseFloat(formSubmittedData['pick_unit_quantity_per_package']))
                    ) {
                      updateObject['pick_unit_quantity_per_package'] = Math.abs(parseFloat(formSubmittedData['pick_unit_quantity_per_package']))
                    }
                    if (formSubmittedData != null && formSubmittedData['pick_estimated_price_per_package'] != null) {
                      if (formSubmittedData['pick_estimated_price_per_package'].toString().indexOf('$') === -1) {
                        updateObject['pick_estimated_price_per_package'] = parseFloat(formSubmittedData['pick_estimated_price_per_package'])
                      } else {
                        updateObject['pick_estimated_price_per_package'] = parseFloat(formSubmittedData['pick_estimated_price_per_package'].replace('$', ''))
                      }
                    }
                    if (getProp(formSubmittedData, 'associated_category_name', null) != null) {
                      updateObject['associated_category_name'] = formSubmittedData['associated_category_name']
                      // categories[formSubmittedData['associated_category_name']] = formSubmittedData['associated_category_name']
                    }
                    if (getProp(formSubmittedData, 'associated_classification_name', null) != null) {
                      updateObject['associated_classification_name'] = formSubmittedData['associated_classification_name']
                      // classifications[formSubmittedData['associated_classification_name']] = formSubmittedData['associated_classification_name']
                    }
                    if (getProp(formSubmittedData, 'associated_manufacturer_name', null) != null) {
                      updateObject['associated_manufacturer_name'] = formSubmittedData['associated_manufacturer_name']
                    }
                    if (getProp(formSubmittedData, 'associated_manufacturer_unique_identifier', null) != null) {
                      updateObject['associated_manufacturer_unique_identifier'] = formSubmittedData['associated_manufacturer_unique_identifier']
                    }
                    if (getProp(formSubmittedData, 'pick_base_unit_of_measurement', null) != null) {
                      updateObject['pick_base_unit_of_measurement'] = formSubmittedData['pick_base_unit_of_measurement']
                    } else {
                      updateObject['pick_base_unit_of_measurement'] = 'units'
                    }
                    if (getProp(formSubmittedData, 'pick_packaging_type', null) != null) {
                      updateObject['pick_packaging_type'] = formSubmittedData['pick_packaging_type']
                    } else {
                      updateObject['pick_packaging_type'] = 'each'
                    }
                    DatabaseSetMergeDocument(DatabaseRef_MasterSKU_Document(res_GCK.clientKey, skuItemKey), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        reject(rej_DSMD)
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: rej_DSMD.error,
                        })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'info',
                formDialogHeaderText: rLIB('Create New SKU'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="arrows-left-right"
                  />
                ),
              },
            },
          })
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: rej_GCK.error,
        })
      })
  }

  const createNewSupplierLocation = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // Load Regions
        let promiseArray: TsType_UnknownPromise[] = []
        let activeRegions: TsInterface_UnspecifiedObject = {}
        promiseArray.push(
          DatabaseGetCollection(DatabaseRef_Regions_Collection(res_GCK.clientKey))
            .then((res_DGD) => {
              // categoriesData = res_DGD.data
              for (let loopRegionKey in res_DGD.data) {
                let loopRegion = res_DGD.data[loopRegionKey]
                if (loopRegion != null && loopRegion.status != 'deleted') {
                  activeRegions[loopRegionKey] = { key: loopRegionKey, value: loopRegion.name }
                }
              }
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            }),
        )
        // After Data Loaded
        Promise.all(promiseArray).finally(() => {
          formInputs_NewSupplierLocation['associated_region_key']['options'] = objectToArray(activeRegions).sort(dynamicSort('value', 'asc'))
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: formInputs_NewSupplierLocation,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    let locationKey = keyFromString(formSubmittedData.name)
                    let updateObject: TsInterface_UnspecifiedObject = {
                      locations: {
                        [locationKey]: {
                          status: 'active',
                          key: locationKey,
                          associated_region_name: getProp(getProp(activeRegions, formSubmittedData.associated_region_key, {}), 'value', null),
                          ...formSubmittedData,
                        },
                      },
                    }
                    DatabaseSetMergeDocument(DatabaseRef_Supplier_Document(res_GCK.clientKey, itemKey), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        reject(rej_DSMD)
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: rej_DSMD.error,
                        })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'info',
                formDialogHeaderText: rLIB('Create New Supplier Location'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="circle-plus"
                  />
                ),
              },
            },
          })
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: rej_GCK.error,
        })
      })
  }

  // JSX Generation
  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <>{rLIB('Supplier')}</>
    if (us_supplier != null && us_supplier.name != null) {
      pageHeader = (
        <>
          {rLIB('Supplier')} - {us_supplier.name}
        </>
      )
    }
    return pageHeader
  }

  const rJSX_BackButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="inherit"
          variant="outlined"
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsIndexPage.url() + '?tab=Suppliers')
          }}
          disableElevation
          startIcon={<Icon icon="chevron-left" />}
          className="tw-mr-2"
        >
          {rLIB('Back to all Suppliers')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Back to all Suppliers')}
          placement="top"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsIndexPage.url() + '?tab=Suppliers')
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="chevron-left" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_ImportSkusButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'info'}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import SKUs')}
        importDialogHeader={rLIB('Confirm SKUs Import')}
        importMappingOptions={{
          unique_identifier: {
            key: 'unique_identifier',
            required: true,
            label: rLIB('Unique Identifier'),
            automatch_properties: ['Unique Identifier', 'Product ID'],
          },
          name: {
            key: 'name',
            required: true,
            label: rLIB('Product Name'),
            automatch_properties: ['Product Name', 'Description'],
          },
          associated_manufacturer_name: {
            key: 'associated_manufacturer_name',
            required: false,
            label: rLIB('Manufacturer'),
            automatch_properties: ['Manufacturer'],
          },
          associated_manufacturer_unique_identifier: {
            key: 'associated_manufacturer_unique_identifier',
            required: false,
            label: rLIB('Manufacturer Part Number'),
            automatch_properties: ['Manufacturer Part Number'],
          },
          associated_category_name: {
            key: 'associated_category_name',
            required: false,
            label: rLIB('Category'),
            automatch_properties: ['Category'],
          },
          associated_classification_name: {
            key: 'associated_classification_name',
            required: false,
            label: rLIB('Classification'),
            automatch_properties: ['Classification'],
            possible_values: materialSupplierClassificationTypesArray,
          },
          pick_packaging_type: {
            key: 'pick_packaging_type',
            required: false,
            label: rLIB('Pick Package Type'),
            automatch_properties: ['Pick Type'],
            possible_values: materialSupplierPickPackagingTypesArray,
          },
          pick_unit_quantity_per_package: {
            key: 'pick_unit_quantity_per_package',
            required: false,
            label: rLIB('Unit Quantity per Pick Package'),
            automatch_properties: ['Unit Quantity per Pick'],
          },
          pick_base_unit_of_measurement: {
            key: 'pick_base_unit_of_measurement',
            required: false,
            label: rLIB('Pick Base Unit of Measurement'),
            automatch_properties: ['Base UOM'],
            possible_values: materialSupplierPickBaseUnitOfMeasurementsArray,
          },
          pick_estimated_price_per_package: {
            key: 'pick_estimated_price_per_package',
            required: false,
            label: rLIB('Estimated Price'),
            automatch_properties: ['List Price', 'Unit Sell $ / EA'],
          },
          pick_max_per_cart: {
            key: 'pick_max_per_cart',
            required: false,
            label: rLIB('Max Picks per Cart'),
            automatch_properties: ['Max Picks'],
          },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            // Use Mapping to create slim object
            let consolidatedData: TsInterface_UnspecifiedObject = {}
            let categories: TsInterface_UnspecifiedObject = {}
            let classifications: TsInterface_UnspecifiedObject = {}
            for (let loopRowKey in spreadsheetData) {
              let loopRow = spreadsheetData[loopRowKey]
              if (
                loopRow != null &&
                loopRow['unique_identifier'] != null &&
                loopRow['unique_identifier'] !== '' &&
                loopRow['name'] != null &&
                loopRow['name'] !== ''
              ) {
                let skuItemKey = itemKey + '_' + loopRow['unique_identifier']
                consolidatedData[skuItemKey] = {
                  associated_supplier_key: itemKey,
                  key: skuItemKey,
                  name: loopRow['name'],
                  status: 'active',
                  timestamp_last_updated: new Date(),
                  unique_identifier: loopRow['unique_identifier'],
                }
                if (loopRow != null && loopRow['pick_max_per_cart'] != null && !isNaN(parseFloat(loopRow['pick_max_per_cart']))) {
                  consolidatedData[skuItemKey]['pick_max_per_cart'] = Math.abs(parseFloat(loopRow['pick_max_per_cart']))
                } else {
                  consolidatedData[skuItemKey]['pick_max_per_cart'] = 10
                }
                if (loopRow != null && loopRow['pick_unit_quantity_per_package'] != null && !isNaN(parseFloat(loopRow['pick_unit_quantity_per_package']))) {
                  consolidatedData[skuItemKey]['pick_unit_quantity_per_package'] = Math.abs(parseFloat(loopRow['pick_unit_quantity_per_package']))
                }
                if (loopRow != null && loopRow['pick_estimated_price_per_package'] != null) {
                  if (loopRow['pick_estimated_price_per_package'].toString().indexOf('$') === -1) {
                    consolidatedData[skuItemKey]['pick_estimated_price_per_package'] = parseFloat(loopRow['pick_estimated_price_per_package'])
                  } else {
                    consolidatedData[skuItemKey]['pick_estimated_price_per_package'] = parseFloat(loopRow['pick_estimated_price_per_package'].replace('$', ''))
                  }
                }
                if (getProp(loopRow, 'associated_category_name', null) != null) {
                  consolidatedData[skuItemKey]['associated_category_name'] = loopRow['associated_category_name']
                  categories[loopRow['associated_category_name']] = loopRow['associated_category_name']
                }
                if (getProp(loopRow, 'associated_classification_name', null) != null) {
                  consolidatedData[skuItemKey]['associated_classification_name'] = loopRow['associated_classification_name']
                  classifications[loopRow['associated_classification_name']] = loopRow['associated_classification_name']
                }
                if (getProp(loopRow, 'associated_manufacturer_name', null) != null) {
                  consolidatedData[skuItemKey]['associated_manufacturer_name'] = loopRow['associated_manufacturer_name']
                }
                if (getProp(loopRow, 'associated_manufacturer_unique_identifier', null) != null) {
                  consolidatedData[skuItemKey]['associated_manufacturer_unique_identifier'] = loopRow['associated_manufacturer_unique_identifier']
                }
                if (getProp(loopRow, 'pick_base_unit_of_measurement', null) != null) {
                  consolidatedData[skuItemKey]['pick_base_unit_of_measurement'] = loopRow['pick_base_unit_of_measurement']
                } else {
                  consolidatedData[loopRow['pick_base_unit_of_measurement']] = 'units'
                }
                if (getProp(loopRow, 'pick_packaging_type', null) != null) {
                  consolidatedData[skuItemKey]['pick_packaging_type'] = loopRow['pick_packaging_type']
                } else {
                  consolidatedData[loopRow['pick_packaging_type']] = 'each'
                }
              }
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                // Create Update Array
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                for (let loopRowKey in consolidatedData) {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_MasterSKU_Document(res_GCK.clientKey as string, loopRowKey),
                    data: consolidatedData[loopRowKey],
                  })
                }
                updateArray.push({
                  type: 'setMerge',
                  ref: DatabaseRef_SupplierCategories_Document(res_GCK.clientKey as string, itemKey),
                  data: {
                    associated_categories: categories,
                    associated_classifications: classifications,
                  },
                })
                // Save to Database
                DatabaseStagedBatchUpdate(updateArray)
                  .then((res_DSBU) => {
                    resolve(res_DSBU)
                  })
                  .catch((rej_DSBU) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSBU.error })
                    reject(rej_DSBU)
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_DownloadSkusButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="success"
          variant="outlined"
          onClick={() => {
            downloadAllSkus()
          }}
          disableElevation
          startIcon={<Icon icon="cloud-arrow-down" />}
          className="tw-mr-2"
        >
          {rLIB('Download SKUs')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Download SKUs')}
          placement="top"
        >
          <Button
            color="success"
            variant="outlined"
            onClick={() => {
              downloadAllSkus()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="cloud-arrow-down" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_SkuListTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabContentJSX = (
        <Card className="tw-mt-2">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_MaterialSupplierSKUs}
            tableDatabaseEndpoint={tableDatabaseEndpoint_SupplierSKUs}
            tableSettings={tableSettings_MaterialSupplierSKUs}
          />
        </Card>
      )
    }
    return tabContentJSX
  }

  const rJSX_NewSkuButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            createNewSku()
          }}
          disableElevation
          startIcon={<Icon icon="circle-plus" />}
          className="tw-mr-2"
        >
          {rLIB('Add Sku')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Add Sku')}
          placement="top"
        >
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              createNewSku()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="circle-plus" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_NewLocationButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            createNewSupplierLocation()
          }}
          disableElevation
          startIcon={<Icon icon="circle-plus" />}
          className="tw-mr-2"
        >
          {rLIB('Add Location')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Add Location')}
          placement="top"
        >
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              createNewSupplierLocation()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="circle-plus" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_LocationsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    tabContentJSX = (
      <Card className="tw-mt-2">
        <TableBasic
          tableAdditionalData={{
            supplier_key: itemKey,
          }}
          tableColumns={tableColumns_MaterialSupplierLocations}
          tableData={objectToArray(getProp(us_supplier, 'locations', {}))}
          tableSettings={tableSettings_MaterialSupplierSKUs}
        />
      </Card>
    )
    return tabContentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'SKU_List',
                  tabHeader: rLIB('SKU List'),
                  tabContent: rJSX_SkuListTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 755 },
                    { fullJSX: rJSX_ImportSkusButton(false), minJSX: rJSX_ImportSkusButton(true), sizeCutoff: 385 },
                    { fullJSX: rJSX_DownloadSkusButton(false), minJSX: rJSX_DownloadSkusButton(true), sizeCutoff: 510 },
                    { fullJSX: rJSX_NewSkuButton(false), minJSX: rJSX_NewSkuButton(true), sizeCutoff: 580 },
                  ],
                },
                {
                  tabUrlKey: 'Locations',
                  tabHeader: rLIB('Locations'),
                  tabContent: rJSX_LocationsTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_NewLocationButton(false), minJSX: rJSX_NewLocationButton(true), sizeCutoff: 0 },
                  ],
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.AdminDatabaseMaterialsSupplierViewPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Supplier', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_CombineLogs_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'combine_logs', 'main')
}

export const DatabaseRef_CombineLogs_Document = (clientKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'combine_logs', 'main', logKey)
}

export const DatabaseRef_PreviousUserCombineLogs_Query = (clientKey: string, userKey: string, timestampKey: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_user_key', comparator: '==', value: userKey },
    { prop: 'timestamp_key', comparator: '<', value: timestampKey },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_key', desc: true }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'combine_logs', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    1,
  )
}

export const DatabaseRef_UserCombineLogs_Query = (clientKey: string, userKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_user_key', comparator: '==', value: userKey }]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_key', desc: true }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'combine_logs', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_CombineLogsForSpecificDateKey_Query = (clientKey: string, timestampKey: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'timestamp_key', comparator: '==', value: timestampKey }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'finances', 'combine_logs', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			component listing message threads

		TODO:
			dark mode?

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Avatar, Badge, Box, Typography } from '@mui/material/'
import { themeVariables } from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import {
  generateLastMessageText,
  generateMessageThreadName,
  rJSX_Avatar,
  stringAvatar,
  TsInterface_ChatThread,
  TsInterface_ChatThreadOnClick,
  TsInterface_ChatThreads,
  TsInterface_ChatThreadSettings,
} from 'rfbp_core/components/chat'
import { rLIB } from 'rfbp_core/localization/library'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  chatThreads: TsInterface_ChatThreads
  threadSettings: TsInterface_ChatThreadSettings
  threadOnClick: TsInterface_ChatThreadOnClick
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const ChatThreadsDisplay = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_chatThreads: TsInterface_ComponentProps['chatThreads'] = getProp(props, 'chatThreads', {})
  let pr_threadOnClick: TsInterface_ComponentProps['threadOnClick'] = getProp(props, 'threadOnClick', (threadKey: string) => {})
  let pr_threadSettings: TsInterface_ComponentProps['threadSettings'] = getProp(props, 'threadSettings', {})

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions

  // JSX Generation
  const rJSX_NewThread = (): JSX.Element => {
    // Handle Color (selected vs not)
    let backgroundColor = themeVariables.background_paper
    if (pr_threadSettings.selected_thread_key === 'NEW') {
      backgroundColor = themeVariables.info_light
    }
    // Generate JSX
    let newThreadJSX = <></>
    if (pr_threadSettings != null && pr_threadSettings.include_create_thread === true) {
      newThreadJSX = (
        <Box
          component="div"
          sx={{ backgroundColor: backgroundColor, height: '73px', borderBottom: '1px solid ' + themeVariables.gray_300 + ' !important' }}
          className="tw-rounded-none tw-cursor-pointer tw-p-2 tw-text-center"
          onClick={() => {
            pr_threadOnClick('NEW')
          }}
        >
          <Box className="tw-mt-3">
            <Avatar
              {...stringAvatar('+')}
              sx={{ display: 'inline-flex', bgcolor: themeVariables.gray_500, height: '28px', width: '28px' }}
            />
            <Typography
              sx={{ display: 'inline-flex', paddingLeft: '6px' }}
              variant="h6"
            >
              <strong>{rLIB('New Message')}</strong>
            </Typography>
          </Box>
        </Box>
      )
    }
    return newThreadJSX
  }

  const rJSX_ThreadAvatar = (chatThread: TsInterface_ChatThread, unreadCount: number) => {
    let threadAvatarJSX = <></>
    if (pr_threadSettings != null && chatThread.avatar_override != null) {
      threadAvatarJSX = (
        <Badge
          color="error"
          overlap="circular"
          badgeContent={unreadCount}
          invisible={unreadCount === 0}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {chatThread.avatar_override}
        </Badge>
      )
    } else {
      threadAvatarJSX = rJSX_Avatar(chatThread['associated_member_names'], 'tw-text-base tw-w-7 tw-h-7', unreadCount, pr_threadSettings.associated_viewer_key)
    }
    return threadAvatarJSX
  }

  const rJSX_MessageThread = (chatThread: TsInterface_ChatThread): JSX.Element => {
    // Handle Color (selected vs not)
    let backgroundColor = themeVariables.background_paper
    if (chatThread.key === pr_threadSettings.selected_thread_key) {
      backgroundColor = themeVariables.info_light
    }
    // Get Unread Count
    let unreadCount: number = 0
    if (
      pr_threadSettings != null &&
      pr_threadSettings['associated_viewer_key'] != null &&
      chatThread != null &&
      chatThread['associated_member_unread_count'] != null &&
      chatThread['associated_member_unread_count'][pr_threadSettings['associated_viewer_key']] != null &&
      !isNaN(chatThread['associated_member_unread_count'][pr_threadSettings['associated_viewer_key']])
    ) {
      unreadCount = chatThread['associated_member_unread_count'][pr_threadSettings['associated_viewer_key']]
    }
    // Generate JSX
    let threadJSX = (
      <Box
        component="div"
        sx={{ backgroundColor: backgroundColor, borderBottom: '1px solid ' + themeVariables.gray_300 }}
        className="tw-rounded-none tw-cursor-pointer tw-p-2"
        onClick={() => {
          pr_threadOnClick(chatThread.key)
        }}
      >
        <Box
          component="div"
          className="tw-block"
        >
          <Box component="div">
            <Box
              component="div"
              className="tw-mr-2 tw-inline-block"
            >
              <Box
                component="div"
                className="tw-flex"
              >
                {rJSX_ThreadAvatar(chatThread, unreadCount)}
              </Box>
            </Box>
            <Box
              component="div"
              sx={{ width: 'calc(100% - 56px)' }}
              className="tw-p-l-2 tw-p-t-0.5 tw-align-top tw-inline-block tw-text-ellipsis tw-overflow-hidden tw-whitespace-nowrap"
            >
              <Typography
                sx={{ display: 'inline-block' }}
                variant="body2"
              >
                <strong>
                  {generateMessageThreadName(
                    chatThread['thread_name_override_desktop'],
                    chatThread['associated_member_names'],
                    pr_threadSettings.associated_viewer_key,
                  )}
                </strong>
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="body2"
            className="tw-inline-block"
          >
            {generateLastMessageText(chatThread)}
          </Typography>
        </Box>
      </Box>
    )
    return threadJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <Box component="div">{rJSX_NewThread()}</Box>
        {objectToArray(pr_chatThreads)
          .sort(dynamicSort('last_message_timestamp', 'desc'))
          .map((chatThread, index) => (
            <Box
              component="div"
              key={index}
            >
              {rJSX_MessageThread(chatThread)}
            </Box>
          ))}
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

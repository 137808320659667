///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Suppliers_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'materials', 'suppliers', 'main')
}

export const DatabaseRef_Supplier_Document = (clientKey: string, supplierKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'materials', 'suppliers', 'main', supplierKey)
}

export const DatabaseRef_SupplierCategories_Document = (clientKey: string, supplierKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'materials', 'suppliers', 'main', supplierKey, 'categories', 'active')
}

export const DatabaseRef_ActiveSuppliers_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'suppliers', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_SuppliersWithSpecificPO_Query = (clientKey: string, poAbbreviation: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'po_abbreviation', comparator: '==', value: poAbbreviation }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'suppliers', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

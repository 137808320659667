///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:
			Cleaning function transaction failing


	*/

///////////////////////////////
// Imports
///////////////////////////////

// import {
// } from 'google-spreadsheet'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useCSVReader } from 'react-papaparse'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_SalesHierarchyUser_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_hierarchies'
import { DatabaseRef_SalesPartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import {
  DatabaseRef_ProjectAdditionalData_Document,
  DatabaseRef_ProjectImportResults_Collection,
  DatabaseRef_ProjectImportResults_Document,
  DatabaseRef_ProjectNextIdNumber_Document,
  DatabaseRef_Project_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
// 	GoogleSpreadsheet
import { Box, Button, Card, FormControl, LinearProgress, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material/'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumn,
  TsInterface_TableColumns,
  TsInterface_TableData,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TabsBasic, TsInterface_TabsSettingsBasic } from 'rfbp_core/components/tabs'
import { TabButtons } from 'rfbp_core/components/tabs/tab_buttons'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  DatabaseTransactionIncrement,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray, returnDateCorrectedForTimezoneOffset, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColors, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminProjectManualImportsPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_JOB_CODE: JSX.Element = 								<Trans>Job Code</Trans>
const s_CLEAN: JSX.Element = <Trans>Clean</Trans>
const s_CLEAN_DATA: JSX.Element = <Trans>Clean Data</Trans>
const s_CLEAN_IMPORTED_DATA: JSX.Element = <Trans>Clean Imported Data</Trans>
const s_COUNTY: JSX.Element = <Trans>County</Trans>
const s_CREATE_PROJECT_FROM_FORM: JSX.Element = <Trans>Create Project from Form</Trans>
const s_CUSTOMER_DETAILS: JSX.Element = <Trans>Customer Details</Trans>
const s_CUSTOMER_EMAIL: JSX.Element = <Trans>Customer Email</Trans>
const s_CUSTOMER_NAME: JSX.Element = <Trans>Customer Name</Trans>
const s_CUSTOMER_PHONE: JSX.Element = <Trans>Customer Phone</Trans>
const s_FAILURE: JSX.Element = <Trans>Failure</Trans>
const s_IMPORTS: JSX.Element = <Trans>Imports</Trans>
const s_IMPORT_SPREADSHEET_TO_STAGING: JSX.Element = <Trans>Import spreadsheet to staging</Trans>
const s_INVERTER_MANUFACTURER: JSX.Element = <Trans>Inverter Manufacturer</Trans>
const s_INVERTER_MODEL: JSX.Element = <Trans>Inverter Model</Trans>
const s_INVERTER_QUANTITY: JSX.Element = <Trans>Inverter Quantity</Trans>
const s_JURISDICTION: JSX.Element = <Trans>Jurisdiction</Trans>
const s_LAST_UPLOADED: JSX.Element = <Trans>Last Uploaded</Trans>
const s_LOCATION: JSX.Element = <Trans>Location</Trans>
const s_NEW_PROJECT_FROM_FORM: JSX.Element = <Trans>New Project from Form</Trans>
const s_NO_PROJECTS_TO_CLEAN: JSX.Element = <Trans>No Projects to clean</Trans>
const s_NO_PROJECTS_TO_IMPORT: JSX.Element = <Trans>No Projects to import</Trans>
const s_PANEL_MANUFACTURER: JSX.Element = <Trans>Panel Manufacturer</Trans>
const s_PANEL_MODEL: JSX.Element = <Trans>Panel Model</Trans>
const s_PANEL_QUANTITY: JSX.Element = <Trans>Panel Quantity</Trans>
const s_PROJECT_CREATED_SUCCESSFULLY: JSX.Element = <Trans>Project created successfully</Trans>
const s_PROJECT_MANUAL_IMPORTS: JSX.Element = <Trans>Project Manual Imports</Trans>
const s_PROJECT_UPLOAD_COMPLETED: JSX.Element = <Trans>Project upload completed</Trans>
const s_SALES_PARTNER: JSX.Element = <Trans>Sales Partner</Trans>
const s_SELECT_A_FILE: JSX.Element = <Trans>Select a file</Trans>
const s_SELECT_REGION: JSX.Element = <Trans>Select Region</Trans>
const s_SKIPPED: JSX.Element = <Trans>Skipped</Trans>
const s_STATE: JSX.Element = <Trans>State</Trans>
const s_STORAGE_MANUFACTURER: JSX.Element = <Trans>Storage Manufacturer</Trans>
const s_STORAGE_MODEL: JSX.Element = <Trans>Storage Model</Trans>
const s_STORAGE_QUANTITY: JSX.Element = <Trans>Storage Quantity</Trans>
const s_STREET_ADDRESS: JSX.Element = <Trans>Street Address</Trans>
const s_SUCCESS: JSX.Element = <Trans>Success</Trans>
const s_SYSTEM_DETAILS: JSX.Element = <Trans>System Details</Trans>
const s_SYSTEM_SIZE: JSX.Element = <Trans>System Size</Trans>
const s_TOTAL_STORAGE_KWH: JSX.Element = <Trans>Total Storage (KWH)</Trans>
const s_UPLOAD: JSX.Element = <Trans>Upload</Trans>
const s_USAGE_OFFSET: JSX.Element = <Trans>Usage Offset</Trans>
const s_ZIP_CODE: JSX.Element = <Trans>Zip Code</Trans>
const se_PROJECT_IMPORT = 'Project Import'
// { sort-end } - displayed text

// Import Functions and Mapping
const importParserDefault = (allProjectData: TsInterface_UnspecifiedObject, input: string): null | string | number | boolean | Date => {
  return input
}

const importParserLowercase = (allProjectData: TsInterface_UnspecifiedObject, input: string): null | string | number | boolean | Date => {
  if (input != null) {
    return input.toLowerCase()
  } else {
    return null
  }
}

const dateStringHasTimeComponent = (dateString: string) => {
  // Use a regular expression to check for the presence of time (hh:mm:ss)
  const timeRegex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/
  // Test if the input string matches the time pattern
  return timeRegex.test(dateString)
}

const importParserDate = (allProjectData: TsInterface_UnspecifiedObject, input: string): null | Date => {
  let formattedDate: null | Date = null
  if (input != null && input !== '') {
    if (dateStringHasTimeComponent(input) === true) {
      formattedDate = new Date(input)
    } else {
      // Correct for UTC
      formattedDate = returnDateCorrectedForTimezoneOffset(input)
    }
  }
  return formattedDate
}

const importParserInt = (allProjectData: TsInterface_UnspecifiedObject, input: string): number | null => {
  if (!isNaN(parseInt(input))) {
    return parseInt(input)
  } else {
    return null
  }
}

const importParserFloat = (allProjectData: TsInterface_UnspecifiedObject, input: string): number | null => {
  if (!isNaN(parseFloat(input))) {
    return parseFloat(input)
  } else {
    return null
  }
}

// const importParserAtRiskCustomer = (
// 	allProjectData: TsInterface_UnspecifiedObject,
// 	input: string
// ): null | number => {
// 	if( input === "At Risk Customer" ){
// 		return 1
// 	} else {
// 		return 3
// 	}
// }

const importFormatter: TsInterface_UnspecifiedObject = {
  // Identifiers
  PROSPECT_ID: { mapped_location: 'root', data_type: 'string', mapped_key: 'key', parser: importParserDefault }, // Unique Identifier
  JOB_CODE_ID: { mapped_location: 'root', data_type: 'string', mapped_key: 'id_number', parser: importParserDefault },
  OPPORTUNITY_ID: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_opportunity_id', parser: importParserDefault },
  PROPOSAL_OF_RECORD_ID: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_proposal_of_record_id', parser: importParserDefault },

  // Status
  SERVICE_CONTRACT_STATUS: { mapped_location: 'root', data_type: 'string', mapped_key: 'status_service_contract_status', parser: importParserDefault },
  SITE_DESIGN_STATUS: { mapped_location: 'subC', data_type: 'string', mapped_key: 'status_design_status', parser: importParserDefault },
  OPTY_STAGE: { mapped_location: 'root', data_type: 'string', mapped_key: 'status_sales_opportunity_stage', parser: importParserDefault },

  // Associations
  PURCHASED_THRU: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_vendor_name', parser: importParserDefault },
  PRODUCT_3: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_product_name', parser: importParserDefault },
  CUSTOMER_NAME: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_customer_name', parser: importParserDefault },
  PHONE: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_customer_phone', parser: importParserDefault },
  HOMEOWNER_EMAIL: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_customer_email', parser: importParserLowercase },
  OPTY_STATUS: { mapped_location: 'subC', data_type: 'string', mapped_key: 'associated_sales_opportunity_status', parser: importParserDefault },
  SALES_CHANNEL_4: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_sales_channel', parser: importParserDefault },
  UTILITY_COMPANY: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_utility_company_name', parser: importParserDefault },
  HOA_NAME: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_hoa_name', parser: importParserDefault },
  SALES_REP_NAME: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_sales_rep_name', parser: importParserDefault },
  SALES_REP_EMAIL: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_sales_rep_email', parser: importParserLowercase },
  SALES_REP_TEAM: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_sales_rep_team_name', parser: importParserDefault },
  INSTALL_PARTNER_NAME: { mapped_location: 'subC', data_type: 'string', mapped_key: 'associated_install_partner_name', parser: importParserDefault }, // Ignore Freedom Forever (don't import)
  BUILD_PARTNER_NAME: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_build_partner_name', parser: importParserDefault },
  SRM_INSTALL_CHANNEL: { mapped_location: 'subC', data_type: 'string', mapped_key: 'associated_install_channel', parser: importParserDefault },
  INSTALL_BRANCH_SFDC: { mapped_location: 'root', data_type: 'string', mapped_key: 'associated_install_branch_name', parser: importParserDefault },
  METRO_MARKET: { mapped_location: 'subC', data_type: 'string', mapped_key: 'associated_metro_market_name', parser: importParserDefault },
  FULFILLMENT_PARTNER_NAME: { mapped_location: 'subC', data_type: 'string', mapped_key: 'associated_fulfillment_partner_name', parser: importParserDefault },
  FIRST_NAME: { mapped_location: 'subC', data_type: 'string', mapped_key: 'associated_customer_first_name', parser: importParserDefault },
  LAST_NAME: { mapped_location: 'subC', data_type: 'string', mapped_key: 'associated_customer_last_name', parser: importParserDefault },

  // Timestamps
  LEAD_CREATED_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_lead_created', parser: importParserDate },
  OPTY_CREATED_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_opportunity_created', parser: importParserDate },
  STAGE4_CSO_FT_DATE: { mapped_location: 'root', data_type: 'date', mapped_key: 'timestamp_customer_signed', parser: importParserDate },
  SR_SIGNOFF_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_sales_signoff', parser: importParserDate },
  SRA_SAC_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_site_audit_complete', parser: importParserDate },
  CAP_PREPARED_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_customer_proposal_initiated', parser: importParserDate },
  CAP_APPROVED_FT_DATE: { mapped_location: 'root', data_type: 'date', mapped_key: 'timestamp_customer_proposal_approved', parser: importParserDate },
  INSTALL_FT_DATE: { mapped_location: 'root', data_type: 'date', mapped_key: 'timestamp_install_completed', parser: importParserDate },
  M2_ACHIEVED_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_inspection_complete', parser: importParserDate },
  PTO_RECORDED_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_permission_to_operate', parser: importParserDate },
  SYSTEM_ACTIVATION_FT_DATE: { mapped_location: 'root', data_type: 'date', mapped_key: 'timestamp_system_activation', parser: importParserDate }, // Status Complete if exists
  CANCELLED_FT_DATE: { mapped_location: 'root', data_type: 'date', mapped_key: 'timestamp_project_cancelled', parser: importParserDate }, // Status Delete if exists
  VERIFICATION_CALL_COMPLETED_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_verification_call', parser: importParserDate },
  WELCOME_CALL_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_welcome_call', parser: importParserDate },
  SITE_AUDIT_COMPLETED_OPTY_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_site_audit_complete_opty', parser: importParserDate },
  SITE_AUDIT_COMPLETED_SC_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_site_audit_complete_sc', parser: importParserDate },
  PROJECT_HANDOFF_LT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_project_handoff', parser: importParserDate },
  CAP_APPROVED_LT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_cap_approved_lt', parser: importParserDate },
  PERMIT_SUBMITTED_LT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_permit_submitted', parser: importParserDate },
  PERMIT_APPROVAL_LT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_permit_approval', parser: importParserDate },
  CONSTRUCTION_START_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_construction_start', parser: importParserDate },
  // "CONSTRUCTION_COMPLETE_FT_DATE":			{ mapped_location: "subC", 	data_type: "date",	 	mapped_key: "timestamp_construction_complete", 						parser:	importParserDate		},
  CONSTRUCTION_COMPLETE_FT_DATE: { mapped_location: 'root', data_type: 'date', mapped_key: 'timestamp_construction_complete', parser: importParserDate },
  SUBMIT_INTERCONNECTION_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_interconnection_submission', parser: importParserDate },
  FINAL_INSPECTION_SIGNOFF_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_final_inspection_signoff', parser: importParserDate },
  METER_REGISTRATION_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_meter_registration', parser: importParserDate },
  PTO_FT_DATE: { mapped_location: 'root', data_type: 'date', mapped_key: 'timestamp_pto', parser: importParserDate },
  LAST_MODIFIED_DATE: { mapped_location: 'root', data_type: 'date', mapped_key: 'timestamp_salesforce_last_modified', parser: importParserDate },
  READYTOGO_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_ready_to_go', parser: importParserDate },
  ELECTRICAL_SIGNOFF_FT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_electrical_signoff', parser: importParserDate },

  // Finances
  FINANCING_TYPE_1: { mapped_location: 'subC', data_type: 'string', mapped_key: 'financials_financing_type', parser: importParserDefault },
  FINANCING_TYPE_2: { mapped_location: 'subC', data_type: 'string', mapped_key: 'financials_financing_subtype', parser: importParserDefault },
  BILLING_TYPE: { mapped_location: 'subC', data_type: 'string', mapped_key: 'financials_billing_type', parser: importParserDefault },
  YEAR_1_SOLAR_PRICE_PER_KWH: { mapped_location: 'subC', data_type: 'number', mapped_key: 'financials_year_1_solar_price_per_kwh', parser: importParserFloat },
  ANNUAL_MONTHLY_PAYMENT_ESCALATOR: {
    mapped_location: 'subC',
    data_type: 'number',
    mapped_key: 'financials_annual_monthly_payment_escalator',
    parser: importParserFloat,
  },
  GROSS_PRICE_ELIGIBLE_FOR_ITC: {
    mapped_location: 'subC',
    data_type: 'number',
    mapped_key: 'financials_gross_price_eligible_for_itc',
    parser: importParserFloat,
  },
  CURRENT_NET_PRICE_TO_CUSTOMER: {
    mapped_location: 'subC',
    data_type: 'number',
    mapped_key: 'financials_current_net_price_to_customer',
    parser: importParserFloat,
  },
  GROSS_PRICE_NOT_ELIGIBLE_FOR_ITC: {
    mapped_location: 'subC',
    data_type: 'number',
    mapped_key: 'financials_gross_price_not_eligible_for_itc',
    parser: importParserFloat,
  },
  GROSS_PRICE_OF_SRM_ELECTRICAL: { mapped_location: 'subC', data_type: 'number', mapped_key: 'financials_gross_price_electrical', parser: importParserFloat },
  GROSS_PRICE_OF_SRM_MISC: { mapped_location: 'subC', data_type: 'number', mapped_key: 'financials_gross_price_misc', parser: importParserFloat },
  GROSS_PRICE_OF_SRM_POST_INSTALL: {
    mapped_location: 'subC',
    data_type: 'number',
    mapped_key: 'financials_gross_price_post_install',
    parser: importParserFloat,
  },
  GROSS_PRICE_OF_SRM_ROOFING: { mapped_location: 'subC', data_type: 'number', mapped_key: 'financials_gross_price_roofing', parser: importParserFloat },
  GROSS_PRICE_OF_SRM_STRUCTURAL: { mapped_location: 'subC', data_type: 'number', mapped_key: 'financials_gross_price_structural', parser: importParserFloat },
  TOTAL_MLI_NUMBER: { mapped_location: 'subC', data_type: 'number', mapped_key: 'financials_total_mli_number', parser: importParserFloat },
  PRICE_PER_KWH: { mapped_location: 'subC', data_type: 'number', mapped_key: 'financials_price_per_kwh', parser: importParserFloat },

  // Location
  ZIP: { mapped_location: 'root', data_type: 'string', mapped_key: 'location_zip', parser: importParserDefault },
  CITY: { mapped_location: 'root', data_type: 'string', mapped_key: 'location_city', parser: importParserDefault },
  COUNTY: { mapped_location: 'root', data_type: 'string', mapped_key: 'location_county', parser: importParserDefault },
  ADDRESS: { mapped_location: 'root', data_type: 'string', mapped_key: 'location_address', parser: importParserDefault },
  STATE: { mapped_location: 'root', data_type: 'string', mapped_key: 'location_state', parser: importParserDefault },
  JURISDICTION: { mapped_location: 'root', data_type: 'string', mapped_key: 'location_jurisdiction', parser: importParserDefault },

  // System Size
  PANEL_MFG: { mapped_location: 'root', data_type: 'string', mapped_key: 'system_panel_manufacturer', parser: importParserDefault },
  PANEL_MODEL: { mapped_location: 'root', data_type: 'string', mapped_key: 'system_panel_model', parser: importParserDefault },
  PANEL_QTY: { mapped_location: 'root', data_type: 'number', mapped_key: 'system_panel_quantity', parser: importParserInt },
  STORAGE_MFG: { mapped_location: 'root', data_type: 'string', mapped_key: 'system_storage_manufacturer', parser: importParserDefault },
  STORAGE_MODEL: { mapped_location: 'root', data_type: 'string', mapped_key: 'system_storage_model', parser: importParserDefault },
  STORAGE_QTY: { mapped_location: 'root', data_type: 'number', mapped_key: 'system_storage_quantity', parser: importParserInt },
  TOTAL_STORAGE_KWH: { mapped_location: 'root', data_type: 'number', mapped_key: 'system_storage_total_kwh', parser: importParserFloat },
  INVERTER_MFG: { mapped_location: 'root', data_type: 'string', mapped_key: 'system_inverter_manufacturer', parser: importParserDefault },
  INVERTER_MODEL: { mapped_location: 'root', data_type: 'string', mapped_key: 'system_inverter_model', parser: importParserDefault },
  INVERTER_QTY: { mapped_location: 'root', data_type: 'number', mapped_key: 'system_inverter_quantity', parser: importParserInt },
  SYSTEM_SIZE_DC: { mapped_location: 'root', data_type: 'number', mapped_key: 'system_size_dc', parser: importParserFloat },
  USAGE_OFFSET: { mapped_location: 'root', data_type: 'number', mapped_key: 'system_usage_offset', parser: importParserFloat }, // (percent generated / used - based on 6 months of usage)
  METER_TYPE: { mapped_location: 'subC', data_type: 'string', mapped_key: 'system_meter_type', parser: importParserDefault },

  // Home Details
  HOME_TYPE: { mapped_location: 'subC', data_type: 'string', mapped_key: 'home_building_type', parser: importParserDefault },
  INSTALLATION_TYPE: { mapped_location: 'subC', data_type: 'string', mapped_key: 'home_installation_type', parser: importParserDefault },
  AGE_OF_ROOF: { mapped_location: 'subC', data_type: 'string', mapped_key: 'home_roof_age', parser: importParserDefault },
  // "ROOFING_TYPE": 							{ mapped_location: "subC", 	data_type: "string", 	mapped_key: "home_roof_type", 										parser:	importParserDefault,	},
  ROOFING_TYPE: { mapped_location: 'root', data_type: 'string', mapped_key: 'home_roof_type', parser: importParserDefault },
  SHADING: { mapped_location: 'subC', data_type: 'string', mapped_key: 'home_shading_type', parser: importParserDefault },
  SQUARE_FOOTAGE: { mapped_location: 'subC', data_type: 'number', mapped_key: 'home_square_footage', parser: importParserFloat },
  YEAR_HOME_BUILT: { mapped_location: 'subC', data_type: 'number', mapped_key: 'home_year_built', parser: importParserFloat },
  NUMBER_OF_STORIES: { mapped_location: 'subC', data_type: 'string', mapped_key: 'home_number_of_stories', parser: importParserDefault },

  // At risk Customers
  ARC_CASE_NUMBER: { mapped_location: 'subC', data_type: 'string', mapped_key: 'associated_customer_risk_case_number', parser: importParserDefault }, //  At Risk Customer
  ARC_CREATED_LT_DATE: { mapped_location: 'subC', data_type: 'date', mapped_key: 'timestamp_customer_at_risk', parser: importParserDate },
  // "ARC_RECORD_TYPE": 							{ mapped_location: "root", 	data_type: "string", 	mapped_key: "associated_customer_risk_status", 						parser:	importParserAtRiskCustomer,	},
  // "ARC_CANCELLATION_REASON": 					{ mapped_location: "subC", 	data_type: "string", 	mapped_key: "associated_customer_cancellation_reason", 				parser:	importParserDefault,	},
  ARC_STATUS: { mapped_location: 'subC', data_type: 'string', mapped_key: 'associated_customer_risk_status_details', parser: importParserDefault },

  // Other Project Data
  IS_ACTIVE_SOW: { mapped_location: 'subC', data_type: 'string', mapped_key: 'project_has_active_sow', parser: importParserDefault },
  PROJECT_HAS_ANY_SOW: { mapped_location: 'subC', data_type: 'string', mapped_key: 'project_has_any_sow', parser: importParserDefault },
  PROJECT_HAS_MISC_WORK: { mapped_location: 'subC', data_type: 'string', mapped_key: 'project_has_misc_work', parser: importParserDefault },
  PROJECT_HAS_ELECTRICAL_WORK: { mapped_location: 'subC', data_type: 'string', mapped_key: 'project_has_electrical_work', parser: importParserDefault },
  PROJECT_HAS_POST_INSTALL_REPAIR_WORK: {
    mapped_location: 'subC',
    data_type: 'string',
    mapped_key: 'project_has_post_install_repair_work',
    parser: importParserDefault,
  },
  PROJECT_HAS_ROOFING_WORK: { mapped_location: 'subC', data_type: 'string', mapped_key: 'project_has_roofing_work', parser: importParserDefault },
  PROJECT_HAS_STRUCTURAL_WORK: { mapped_location: 'subC', data_type: 'string', mapped_key: 'project_has_structural_work', parser: importParserDefault },
  VIP_CUSTOMER: { mapped_location: 'subC', data_type: 'string', mapped_key: 'project_vip_customer', parser: importParserDefault },
  SITE_DESIGN_PRIORITY: { mapped_location: 'subC', data_type: 'string', mapped_key: 'project_site_design_priority', parser: importParserDefault },
  POWERWALL_FLAG: { mapped_location: 'subC', data_type: 'string', mapped_key: 'project_flag_powewall', parser: importParserDefault },
  LG_STORAGE_FLAG: { mapped_location: 'subC', data_type: 'string', mapped_key: 'project_flag_storage', parser: importParserDefault },
}

// Custom Cells
const importCutoffMinutes = 60
const loopMillisecondDelay = 300
const loopMillisecondDelayTwo = 800
// const loopMillisecondDelayTwo = 50
const cleanLimitCount = 100
let lastUploadTableCell: TsInterface_TableColumn = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      if (
        rowData != null &&
        rowData.key != null &&
        tableAdditionalData != null &&
        tableAdditionalData.projectPreviousImportResults != null &&
        tableAdditionalData.projectPreviousImportResults['projects'] != null &&
        tableAdditionalData.projectPreviousImportResults['projects'][rowData.key as string] != null &&
        tableAdditionalData.projectPreviousImportResults['projects'][rowData.key as string]['timestamp_last_uploaded'] != null
      ) {
        cellJSX = (
          <Box>
            {returnFormattedDate(
              tableAdditionalData.projectPreviousImportResults['projects'][rowData.key as string]['timestamp_last_uploaded'],
              'D MMM YYYY - h:mm a',
            )}
          </Box>
        )
      }
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <>{s_LAST_UPLOADED}</>
    },
    header_sort_by: null,
  },
}

let uploadProjectButtonTableCell: TsInterface_TableColumn = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      let buttonColor: TsType_MuiComponentColors = 'error'
      let uploadCutoffTime = new Date().getTime() - 1000 * 60 * importCutoffMinutes
      let variant: 'contained' | 'outlined' = 'contained'
      if (
        rowData != null &&
        rowData.key != null &&
        tableAdditionalData != null &&
        tableAdditionalData.projectPreviousImportResults != null &&
        tableAdditionalData.projectPreviousImportResults['projects'] != null &&
        tableAdditionalData.projectPreviousImportResults['projects'][rowData.key as string] != null &&
        tableAdditionalData.projectPreviousImportResults['projects'][rowData.key as string]['timestamp_last_uploaded'] != null
      ) {
        let uploadTimestamp = tableAdditionalData.projectPreviousImportResults['projects'][rowData.key as string]['timestamp_last_uploaded']
        let cleanTimestamp: any = getProp(tableAdditionalData.projectPreviousImportResults['projects'][rowData.key as string], 'timestamp_last_cleaned', null)
        if (cleanTimestamp != null && cleanTimestamp > uploadTimestamp) {
          variant = 'outlined'
        }
        if (uploadTimestamp > uploadCutoffTime) {
          buttonColor = 'success'
        } else {
          buttonColor = 'warning'
        }
      }
      cellJSX = (
        <Button
          color={buttonColor}
          variant={variant}
          startIcon={<Icon icon="upload" />}
          onClick={() => {
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                tableAdditionalData
                  .uploadSpreadsheetDataToProject(res_GCK.clientKey, rowData.key, rowData, 0)
                  .then((res_USDTP: any) => {
                    // let importResultUpdateObject: TsInterface_UnspecifiedObject = { projects: {} }
                    // importResultUpdateObject["projects"][ rowData.key as string ] = {
                    // 	timestamp_last_uploaded: new Date().getTime(),
                    // 	timestamp_failed_to_upload: null
                    // }
                    let importResultUpdateObject: TsInterface_UnspecifiedObject = {
                      key: rowData.key,
                      timestamp_last_uploaded: new Date(),
                    }
                    DatabaseSetMergeDocument(DatabaseRef_ProjectImportResults_Document(res_GCK.clientKey, rowData.key as string), importResultUpdateObject)
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_USDTP: any) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_USDTP.error })
                  })
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }}
        >
          {s_UPLOAD}
        </Button>
      )
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <>{s_LAST_UPLOADED}</>
    },
    header_sort_by: null,
  },
}

let cleanProjectButtonTableCell: TsInterface_TableColumn = {
  cell: {
    cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      let cellJSX = <></>
      let buttonColor: TsType_MuiComponentColors = 'warning'
      let variant: 'contained' | 'outlined' = 'contained'
      if (
        rowData != null &&
        rowData.key != null &&
        tableAdditionalData != null &&
        tableAdditionalData.projectPreviousImportResults != null &&
        tableAdditionalData.projectPreviousImportResults['projects'] != null &&
        tableAdditionalData.projectPreviousImportResults['projects'][rowData.key as string] != null &&
        tableAdditionalData.projectPreviousImportResults['projects'][rowData.key as string]['timestamp_last_cleaned'] != null
      ) {
        variant = 'outlined'
      }
      cellJSX = (
        <Button
          color={buttonColor}
          variant={variant}
          startIcon={<Icon icon="soap" />}
          onClick={() => {
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                tableAdditionalData
                  .postProcessProjectData(res_GCK.clientKey, rowData.key, 0)
                  .then((res_PPPD: any) => {
                    // Nothing
                  })
                  .catch((rej_PPPD: any) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_PPPD.error })
                  })
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }}
        >
          {s_CLEAN}
        </Button>
      )
      return cellJSX
    },
  },
  header: {
    header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return ''
    },
    header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
      return <>{s_CLEAN_DATA}</>
    },
    header_sort_by: null,
  },
}

let salesPartnerOptions: TsInterface_UnspecifiedObject = {
  sunrun: { key: 'sunrun', value: 'Sunrun' },
  tesla: { key: 'tesla', value: 'Tesla', disabled: true },
  internal: { key: 'internal', value: 'Internal', disabled: true },
}

let salesPartnerOptionsInternal: TsInterface_UnspecifiedObject = {
  sunrun: { key: 'sunrun', value: 'Sunrun', disabled: true },
  tesla: { key: 'tesla', value: 'Tesla' },
  internal: { key: 'internal', value: 'Internal' },
}

// Project Import Results
const formSettings_NewProject: TsInterface_FormSettings = {
  // highlight_missing: true,
  // submit_button_alignment: "right",
  // submit_button_hide: false,
  // submit_button_icon: <SaveIcon/>,
  // submit_button_saving_icon: true,
  // submit_button_text: <>{s_SAVE}</>,
}

// Generate Job Code
const formInputs_NewProject: TsInterface_FormInputs = {
  header0: {
    data_type: 'string',
    key: 'header0',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-0">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-0"
          >
            {s_CUSTOMER_DETAILS}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  associated_customer_name: {
    key: 'associated_customer_name',
    label: s_CUSTOMER_NAME,
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  associated_customer_phone: {
    key: 'associated_customer_phone',
    label: s_CUSTOMER_PHONE,
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  associated_customer_email: {
    key: 'associated_customer_email',
    label: s_CUSTOMER_EMAIL,
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  header1: {
    data_type: 'string',
    key: 'header1',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-0">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-0"
          >
            {s_SALES_PARTNER}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  associated_sales_partner_key: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'associated_sales_partner_key',
    options: objectToArray(salesPartnerOptionsInternal),
    label: <>{s_SALES_PARTNER}</>,
    required: true,
  },
  // associated_region_key: {
  //   data_type: 'string',
  //   input_type: 'multiple_choice_select',
  //   key: 'associated_region_key',
  //   options: [],
  //   label: <>{s_REGION}</>,
  //   required: true,
  // },
  header2: {
    data_type: 'string',
    key: 'header2',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-3">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-4"
          >
            {s_LOCATION}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  location_address: {
    key: 'location_address',
    label: s_STREET_ADDRESS,
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  location_city: {
    key: 'location_city',
    label: rLIB('City'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  location_state: {
    key: 'location_state',
    label: s_STATE,
    input_type: 'multiple_choice_select_state_usa',
    required: true,
    data_type: 'string',
  },
  location_zip: {
    key: 'location_zip',
    label: s_ZIP_CODE,
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  location_county: {
    key: 'location_county',
    label: s_COUNTY,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  location_jurisdiction: {
    key: 'location_jurisdiction',
    label: s_JURISDICTION,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  header3: {
    data_type: 'string',
    key: 'header3',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-left tw-mt-3">
          <Typography
            variant="h6"
            sx={{ fontWeight: 700, color: themeVariables.white }}
            className="tw-mt-4"
          >
            {s_SYSTEM_DETAILS}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  system_panel_manufacturer: {
    key: 'system_panel_manufacturer',
    label: s_PANEL_MANUFACTURER,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_panel_model: {
    key: 'system_panel_model',
    label: s_PANEL_MODEL,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_panel_quantity: {
    key: 'system_panel_quantity',
    label: s_PANEL_QUANTITY,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_storage_manufacturer: {
    key: 'system_storage_manufacturer',
    label: s_STORAGE_MANUFACTURER,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_storage_model: {
    key: 'system_storage_model',
    label: s_STORAGE_MODEL,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_storage_quantity: {
    key: 'system_storage_quantity',
    label: s_STORAGE_QUANTITY,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_storage_total_kwh: {
    key: 'system_storage_total_kwh',
    label: s_TOTAL_STORAGE_KWH,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_inverter_manufacturer: {
    key: 'system_inverter_manufacturer',
    label: s_INVERTER_MANUFACTURER,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_inverter_model: {
    key: 'system_inverter_model',
    label: s_INVERTER_MODEL,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_inverter_quantity: {
    key: 'system_inverter_quantity',
    label: s_INVERTER_QUANTITY,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_size_dc: {
    key: 'system_size_dc',
    label: s_SYSTEM_SIZE,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  system_usage_offset: {
    key: 'system_usage_offset',
    label: s_USAGE_OFFSET,
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },

  // associated_sales_partner_key
  // associated_sales_partner_name
  // associated_region_key
  // associated_region_name
}

///////////////////////////////
// Functions
///////////////////////////////

const returnInitialDate = (): string => {
  let date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 7)
  return returnFormattedDate(date, 'YYYY-MM-DD')
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_cleanTableData, us_setCleanTableData] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_fileFlatUploadData, us_setFlatFileUploadData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_fileUploadData, us_setFileUploadData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filterDate, us_setFilterDate] = useState<string>(returnInitialDate())
  const [us_parsingData, us_setParsingData] = useState<boolean>(false)
  const [us_projectPreviousImportResults, us_setProjectPreviousImportResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectsToImportCount, us_setProjectsToImportCount] = useState<number>(0)
  const [us_regionsList, us_setRegionsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_salesHierarchy, us_setSalesHierarchy] = useState<TsInterface_UnspecifiedObject>({})
  const [us_salesPartnersList, us_setSalesPartnersList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedRegion, us_setSelectedRegion] = useState<string>('')
  const [us_selectedSalesPartner, us_setSelectedSalesPartner] = useState<string>('sunrun')
  const [us_tableColumns, us_setTableColumns] = useState<any>({})
  const [us_uploadProgress, us_setUploadProgress] = useState<number>(0)
  const [us_uploadResults, us_setUploadResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_uploadingDataToStaging, us_setUploadingDataToStaging] = useState<boolean>(false)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { CSVReader } = useCSVReader()
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_PROJECT_IMPORT
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSalesPartnersList(newData)
      ur_forceRerender()
    }
    if (uc_RootData_ClientKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SalesPartner_Collection(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSalesPartnersList({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    if (us_selectedSalesPartner != null && us_selectedSalesPartner !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_SalesHierarchyUser_Collection(res_GCK.clientKey, us_selectedSalesPartner))
            .then((res_DGC) => {
              us_setSalesHierarchy(res_DGC.data)
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
              us_setSalesHierarchy({})
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
          us_setSalesHierarchy({})
        })
    }
  }, [us_selectedSalesPartner, uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let dynamicTableColumns: TsInterface_UnspecifiedObject = {}
    dynamicTableColumns['JOB_CODE_ID'] = TableCellBasic('id_number', 'Job Code', null)
    dynamicTableColumns['TEMP_last_upload'] = lastUploadTableCell
    dynamicTableColumns['TEMP_upload_button'] = uploadProjectButtonTableCell
    dynamicTableColumns['TEMP_clean_button'] = cleanProjectButtonTableCell
    // setTableColumns()
    for (let mappingKey in importFormatter) {
      let mappingField = importFormatter[mappingKey]
      if (mappingField != null && mappingField['key'] != null && mappingField['data_type'] != null) {
        let loopHeaderKey = mappingField['key']
        if (mappingField['data_type'] !== 'date') {
          dynamicTableColumns[loopHeaderKey] = TableCellBasic(loopHeaderKey, loopHeaderKey, null)
        } else {
          dynamicTableColumns[loopHeaderKey] = TableCellTimestamp(loopHeaderKey, loopHeaderKey, null, 'DD MMM YYYY - h:mm a', false)
        }
      }
    }
    us_setTableColumns(dynamicTableColumns)
  }, [])

  useEffect(() => {
    let flatData: TsInterface_UnspecifiedObject = {}
    for (let loopItemKey in us_fileUploadData) {
      let loopItem = us_fileUploadData[loopItemKey]
      flatData[loopItemKey] = {
        root: loopItem['root'],
        subcollection: loopItem['subcollection'],
      }
      for (let propKey in loopItem['root']) {
        flatData[loopItemKey][propKey] = loopItem['root'][propKey]
      }
      for (let propKey in loopItem['subcollection']) {
        flatData[loopItemKey][propKey] = loopItem['subcollection'][propKey]
      }
    }
    us_setFlatFileUploadData(flatData)
  }, [us_fileUploadData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let newDataProjects: TsInterface_UnspecifiedObject = {
        projects: newData,
      }
      us_setProjectPreviousImportResults(newDataProjects)
      let projectsToCleanData: TsInterface_UnspecifiedObject[] = []
      if (newDataProjects != null && newDataProjects.projects != null) {
        for (let loopProjectKey in newDataProjects.projects) {
          let loopProject = newDataProjects.projects[loopProjectKey]
          loopProject['key'] = loopProjectKey

          if (loopProject['internal_id_number'] == null) {
            if (loopProject['timestamp_last_cleaned'] == null) {
              projectsToCleanData.push(loopProject)
            }
          }
        }
      }
      us_setCleanTableData(projectsToCleanData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectImportResults_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegionsList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Other Variables
  const tableAdditionalData: TsInterface_TableAdditionalData = {
    uploadResults: us_uploadResults,
    projectPreviousImportResults: us_projectPreviousImportResults,
  }
  const tableSettings: TsInterface_TableSettings = {
    paginated: true,
    pagination_rows_per_page_default: 100,
    pagination_rows_per_page_options: [10, 25, 50, 100],
    show_header: true,
    size: 'small',
    sortable: false,
  }
  const tabsSettings: TsInterface_TabsSettingsBasic = {}

  const tableCleanColumns: TsInterface_TableColumns = {
    TEMP_last_upload: lastUploadTableCell,
    key: TableCellBasic('key', 'key', null),
    TEMP_clean_button: cleanProjectButtonTableCell,
  }

  // Functions
  const createNewProjectFromForm = () => {
    let regionOptions: TsInterface_UnspecifiedObject = {}
    for (let loopRegionKey in us_regionsList) {
      let loopRegion = us_regionsList[loopRegionKey]
      if (loopRegion.status === 'active') {
        regionOptions[loopRegionKey] = {
          key: loopRegionKey,
          value: loopRegion['name'],
        }
      }
    }
    formInputs_NewProject.associated_region_key.options = objectToArray(regionOptions)
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewProject,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: formSettings_NewProject,
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let newProjectData: TsInterface_UnspecifiedObject = { ...formSubmittedData }
                  newProjectData.client_key = res_GCK.clientKey
                  newProjectData.function = 'createProject'
                  cloudFunctionManageRequest('manageProjects', newProjectData)
                    .then((res_CFMR) => {
                      if (res_CFMR != null && getProp(res_CFMR, 'key', null) != null) {
                        directAppNavigation(formHooks.un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(getProp(res_CFMR, 'key', null)))
                      } else {
                        formHooks.uc_setUserInterface_AlertDialogDisplay({
                          display: true,
                          alert: {
                            color: 'primary',
                            header: s_SUCCESS,
                            icon: (
                              <Icon
                                icon="circle-ok"
                                type="solid"
                              />
                            ),
                            text: s_PROJECT_CREATED_SUCCESSFULLY,
                          },
                        })
                      }
                      resolve(res_CFMR)
                    })
                    .catch((rej_CFMR) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMR.error })
                      reject(rej_CFMR)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: s_NEW_PROJECT_FROM_FORM,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const handleFileSelection = (data: any): void => {
    // Instantiate variables
    let formattedData: TsInterface_TableData = []
    // let uploadedFileColumns: TsInterface_UnspecifiedObject = {}
    let uploadedFileColumns: string[] = []
    let importCount = 0
    us_setParsingData(true)
    // let dataMapping: string[] = []
    // Loop through and format data
    for (let cellIndex = 0; cellIndex < data[0].length; cellIndex++) {
      uploadedFileColumns[cellIndex] = data[0][cellIndex]
    }
    for (let rowIndex = 1; rowIndex < data.length; rowIndex++) {
      if (data != null && data[rowIndex] != null) {
        let loopProject = data[rowIndex]
        let formattedDataRow: TsInterface_UnspecifiedObject = {
          root: {},
          subcollection: {},
        }
        for (let cellIndex = 0; cellIndex < loopProject.length; cellIndex++) {
          if (loopProject[cellIndex] != null && uploadedFileColumns[cellIndex]) {
            // Instatiate Variables
            let loopPropKey: null | string = null
            let loopPropValue: null | string | number | boolean | Date = null
            let loopPropDestination: null | string = null
            // Get Prop Key
            if (
              uploadedFileColumns != null &&
              uploadedFileColumns[cellIndex] != null &&
              importFormatter != null &&
              importFormatter[uploadedFileColumns[cellIndex] as string] != null &&
              importFormatter[uploadedFileColumns[cellIndex] as string]['mapped_key'] != null
            ) {
              loopPropKey = importFormatter[uploadedFileColumns[cellIndex] as string]['mapped_key']
            }
            // Get Prop Value
            if (
              uploadedFileColumns != null &&
              uploadedFileColumns[cellIndex] != null &&
              importFormatter != null &&
              importFormatter[uploadedFileColumns[cellIndex] as string] != null &&
              importFormatter[uploadedFileColumns[cellIndex] as string]['parser'] != null
            ) {
              let formattedPropValue = importFormatter[uploadedFileColumns[cellIndex] as string]['parser'](loopProject, loopProject[cellIndex])
              if (formattedPropValue != null) {
                loopPropValue = formattedPropValue
              }
            }
            // Get Prop Destination
            if (
              uploadedFileColumns != null &&
              uploadedFileColumns[cellIndex] != null &&
              importFormatter != null &&
              importFormatter[uploadedFileColumns[cellIndex] as string] != null &&
              importFormatter[uploadedFileColumns[cellIndex] as string]['mapped_location'] != null
            ) {
              loopPropDestination = importFormatter[uploadedFileColumns[cellIndex] as string]['mapped_location']
            }
            // Add to object
            if (loopPropKey != null && loopPropValue != null && loopPropDestination != null) {
              if (loopPropDestination === 'root') {
                formattedDataRow['root'][loopPropKey] = loopPropValue
              } else if (loopPropDestination === 'subC') {
                formattedDataRow['subcollection'][loopPropKey] = loopPropValue
              } else if (loopPropDestination === 'both') {
                formattedDataRow['root'][loopPropKey] = loopPropValue
                formattedDataRow['subcollection'][loopPropKey] = loopPropValue
              }
              // formattedDataRow[ loopPropKey ] = loopPropValue
            }
          }
        }
        // Skipping completed and cancelled projects for now...
        if (
          formattedDataRow != null &&
          formattedDataRow['root'] != null &&
          formattedDataRow['subcollection'] != null &&
          formattedDataRow['subcollection']['associated_install_partner_name'] !== 'Freedom Forever, LLC' &&
          formattedDataRow['subcollection']['associated_build_partner_name'] !== 'Freedom Forever, LLC' &&
          formattedDataRow['subcollection']['timestamp_project_cancelled'] == null &&
          // formattedDataRow["root"]["timestamp_system_activation"] != null &&
          formattedDataRow['root']['timestamp_system_activation'] == null &&
          formattedDataRow['root']['timestamp_salesforce_last_modified'] != null
        ) {
          let cutoffTimestamp = new Date(us_filterDate).getTime()
          let lastModifiedTimestamp = new Date(formattedDataRow['root']['timestamp_salesforce_last_modified']).getTime()
          if (lastModifiedTimestamp > cutoffTimestamp) {
            formattedDataRow['key'] = formattedDataRow['root']['key']
            // formattedDataRow["root"]["status"] = "completed"
            // formattedDataRow["root"]["status_complete"] = false
            formattedData.push(formattedDataRow)
            importCount++
          }
        }
      }
    }
    // Set to state
    us_setFileUploadData(formattedData)
    us_setProjectsToImportCount(importCount)
    us_setParsingData(false)
  }

  const uploadSpreadsheetDataToProjects = () => {
    us_setUploadingDataToStaging(true)
    let lastUpdateTimestamp = new Date().getTime()
    let uploadResultData: TsInterface_UnspecifiedObject = {
      total_to_upload: objectToArray(us_fileUploadData).length,
      successful_uploads: 0,
      failure_uploads: 0,
      timestamp_last_uploaded: lastUpdateTimestamp,
      // projects: {},
    }
    let skippedProjectImports = 0
    let uploadPromiseArray: TsType_UnknownPromise[] = []
    let projectsToUpload: TsInterface_UnspecifiedObject[] = []
    let uploadCutoffTime = new Date().getTime() - 1000 * 60 * importCutoffMinutes
    for (let loopProjectIndex in us_fileUploadData) {
      let loopProject = us_fileUploadData[loopProjectIndex]
      if (
        us_projectPreviousImportResults == null ||
        us_projectPreviousImportResults.projects == null ||
        loopProject == null ||
        loopProject.key == null ||
        us_projectPreviousImportResults.projects[loopProject.key] == null ||
        us_projectPreviousImportResults.projects[loopProject.key]['timestamp_last_uploaded'] == null ||
        us_projectPreviousImportResults.projects[loopProject.key]['timestamp_last_uploaded'] < uploadCutoffTime
      ) {
        projectsToUpload.push(loopProject)
      } else {
        skippedProjectImports++
      }
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        for (let loopItemIndex in projectsToUpload) {
          let loopItem = projectsToUpload[loopItemIndex]
          uploadPromiseArray.push(
            uploadSpreadsheetDataToProject(res_GCK.clientKey, loopItem.key, loopItem, parseInt(loopItemIndex))
              .then((res_USDTP) => {
                uploadResultData['successful_uploads']++
                if (uploadResultData['projects'] == null) {
                  uploadResultData['projects'] = {}
                }
                uploadResultData['projects'][loopItem.key] = {
                  timestamp_last_uploaded: lastUpdateTimestamp,
                  timestamp_failed_to_upload: null,
                }
                us_setUploadResults(uploadResultData)
                us_setUploadProgress(((uploadResultData.successful_uploads + uploadResultData.failure_uploads) / uploadResultData.total_to_upload) * 100)
              })
              .catch((rej_USDTP) => {
                if (uploadResultData['projects'] == null) {
                  uploadResultData['projects'] = {}
                }
                uploadResultData['projects'][loopItem.key] = {
                  timestamp_failed_to_upload: lastUpdateTimestamp,
                }
                uploadResultData['failure_uploads']++
                us_setUploadResults(uploadResultData)
                us_setUploadProgress(((uploadResultData.successful_uploads + uploadResultData.failure_uploads) / uploadResultData.total_to_upload) * 100)
              }),
          )
        }
        Promise.all(uploadPromiseArray).finally(() => {
          us_setUploadResults(uploadResultData)
          us_setUploadingDataToStaging(false)
          uc_setUserInterface_AlertDialogDisplay({
            display: true,
            alert: {
              color: 'info',
              header: s_PROJECT_UPLOAD_COMPLETED,
              icon: <Icon icon="upload" />,
              text: (
                <Box>
                  <Box>
                    <Typography variant="body1">
                      {s_SUCCESS}: {uploadResultData.successful_uploads}
                    </Typography>
                    <Typography variant="body1">
                      {s_FAILURE}: {uploadResultData.failure_uploads}
                    </Typography>
                    <Typography variant="body1">
                      {s_SKIPPED}: {skippedProjectImports}
                    </Typography>
                  </Box>
                  <Box></Box>
                </Box>
              ),
            },
          })
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const uploadSpreadsheetDataToProject = (
    clientKey: string,
    projectKey: string,
    projectData: TsInterface_UnspecifiedObject,
    delay: number,
  ): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        projectData.root['associated_sales_partner_key'] = us_selectedSalesPartner
        projectData.root['associated_sales_partner_name'] = getProp(salesPartnerOptions[us_selectedSalesPartner], 'name', null)
        projectData.root['timestamp_last_spreadsheet_import'] = new Date()
        if (
          us_selectedRegion != null &&
          us_selectedRegion !== '' &&
          us_regionsList != null &&
          us_regionsList[us_selectedRegion] != null &&
          us_regionsList[us_selectedRegion]['name'] != null
        ) {
          projectData.root['associated_region_key'] = us_selectedRegion
          projectData.root['associated_region_name'] = us_regionsList[us_selectedRegion]['name']
        }
        if (
          projectData.root != null &&
          projectData.root.associated_sales_rep_email != null &&
          us_salesHierarchy != null &&
          us_salesHierarchy[projectData.root.associated_sales_rep_email] != null &&
          us_salesHierarchy[projectData.root.associated_sales_rep_email]['all_upstream_manager_emails'] != null
        ) {
          projectData.root['associated_sales_access_all_managers'] =
            us_salesHierarchy[projectData.root.associated_sales_rep_email]['all_upstream_manager_emails']
        }
        if (
          projectData.root != null &&
          projectData.root.associated_sales_rep_email != null &&
          us_salesHierarchy != null &&
          us_salesHierarchy[projectData.root.associated_sales_rep_email] != null &&
          us_salesHierarchy[projectData.root.associated_sales_rep_email]['direct_manager_emails'] != null
        ) {
          projectData.root['associated_sales_access_direct_managers'] = us_salesHierarchy[projectData.root.associated_sales_rep_email]['direct_manager_emails']
        }
        // projectData.root["status_post_spreadsheet_import_check"] = false
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
          { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: projectData.root },
          {
            type: 'setMerge',
            ref: DatabaseRef_ProjectImportResults_Document(clientKey, projectKey),
            data: {
              key: projectKey,
              timestamp_last_uploaded: new Date(),
            },
          },
          { type: 'setMerge', ref: DatabaseRef_ProjectAdditionalData_Document(clientKey, projectKey), data: projectData.subcollection },
        ]
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            resolve(res_DBU)
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      }, loopMillisecondDelay * delay)
    })
  }

  tableAdditionalData['uploadSpreadsheetDataToProject'] = uploadSpreadsheetDataToProject

  const postProcessAllProjects = () => {
    us_setUploadingDataToStaging(true)
    let projectsToClean: string[] = []
    let totalQueued = 0
    for (let loopProjectKey in us_projectPreviousImportResults['projects']) {
      let loopProject = us_projectPreviousImportResults['projects'][loopProjectKey]
      if (totalQueued < cleanLimitCount) {
        if (loopProject != null && loopProject.timestamp_last_cleaned == null) {
          projectsToClean.push(loopProjectKey)
          totalQueued++
        }
      }
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let cleanPromiseArray: TsType_UnknownPromise[] = []
        let completeCount = 0
        for (let loopItemIndex in projectsToClean) {
          let loopItemKey = projectsToClean[loopItemIndex]
          // eslint-disable-next-line no-loop-func
          cleanPromiseArray.push(
            postProcessProjectData(res_GCK.clientKey, loopItemKey, parseInt(loopItemIndex))
              .then((res_PPPD) => {
                completeCount++
                us_setUploadProgress((completeCount / projectsToClean.length) * 100)
                // eslint-disable-next-line no-loop-func
              })
              .catch((rej_PPPD) => {
                completeCount++
                us_setUploadProgress((completeCount / projectsToClean.length) * 100)
              }),
          )
        }
        Promise.all(cleanPromiseArray).finally(() => {
          us_setUploadingDataToStaging(false)
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const postProcessProjectData = (clientKey: string, projectKey: string, delay: number): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // Get Root Project
        DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
          .then((res_DGD) => {
            let projectData = res_DGD.data
            // If it's missing am ID Number
            if (projectData.internal_id_number == null) {
              DatabaseTransactionIncrement(DatabaseRef_ProjectNextIdNumber_Document(clientKey), 'internal_id_number', 1000)
                .then((res_DTI: any) => {
                  let projectUpdateObject = {
                    internal_id_number: res_DTI.incrementValue,
                    status: 'unassigned',
                    status_complete: false,
                    // status: "completed",
                    // status_complete: true,
                    timestamp_last_spreadsheet_import: new Date(),
                  }
                  // let projectImportResultUpdateObject: TsInterface_UnspecifiedObject = {
                  // 	projects: {}
                  // }
                  // projectImportResultUpdateObject["projects"][ projectKey ] = {
                  let projectImportResultUpdateObject = {
                    internal_id_number: res_DTI.incrementValue,
                    timestamp_last_cleaned: new Date(),
                    cleaned: true,
                  }
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                    { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: projectUpdateObject },
                    { type: 'setMerge', ref: DatabaseRef_ProjectImportResults_Document(clientKey, projectKey), data: projectImportResultUpdateObject },
                  ]
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                })
                .catch((rej_DTI) => {
                  reject(rej_DTI)
                })
            } else {
              let projectImportResultUpdateObject = {
                internal_id_number: projectData.internal_id_number,
                timestamp_last_cleaned: new Date(),
                cleaned: true,
              }
              let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                { type: 'setMerge', ref: DatabaseRef_ProjectImportResults_Document(clientKey, projectKey), data: projectImportResultUpdateObject },
              ]
              DatabaseBatchUpdate(updateArray)
                .then((res_DBU) => {
                  resolve(res_DBU)
                })
                .catch((rej_DBU) => {
                  reject(rej_DBU)
                })
            }
          })
          .catch((rej_DGD) => {
            resolve(rej_DGD)
          })
      }, loopMillisecondDelayTwo * delay)
    })
  }

  tableAdditionalData['postProcessProjectData'] = postProcessProjectData

  // JSX Generation
  const rJSX_ImportTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_projectsToImportCount > 0) {
      tableJSX = (
        <Card>
          <TableBasic
            tableAdditionalData={tableAdditionalData}
            tableColumns={us_tableColumns}
            tableData={objectToArray(us_fileFlatUploadData)}
            tableSettings={tableSettings}
          />
        </Card>
      )
    } else {
      tableJSX = (
        <Box className="tw-p-2 tw-text-center">
          <Typography variant="body1">{s_NO_PROJECTS_TO_IMPORT}</Typography>
        </Box>
      )
    }
    return tableJSX
  }

  const rJSX_CleanTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_cleanTableData.length > 0) {
      tableJSX = (
        <Card>
          <TableBasic
            tableAdditionalData={tableAdditionalData}
            tableColumns={tableCleanColumns}
            tableData={us_cleanTableData}
            tableSettings={tableSettings}
          />
        </Card>
      )
    } else {
      tableJSX = (
        <Box className="tw-p-2 tw-text-center">
          <Typography variant="body1">{s_NO_PROJECTS_TO_CLEAN}</Typography>
        </Box>
      )
    }

    return tableJSX
  }

  const rJSX_UploadingDataBar = (): JSX.Element => {
    let loadingBarJSX = <></>
    if (us_uploadingDataToStaging === true) {
      loadingBarJSX = (
        <LinearProgress
          variant="determinate"
          value={us_uploadProgress}
        />
      )
    }
    return loadingBarJSX
  }

  const rJSX_NewProjectButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          variant="contained"
          color="success"
          className="tw-mr-2"
          startIcon={<Icon icon="circle-plus" />}
          onClick={() => {
            createNewProjectFromForm()
          }}
        >
          {s_CREATE_PROJECT_FROM_FORM}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={s_CREATE_PROJECT_FROM_FORM}
          placement="top"
        >
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              createNewProjectFromForm()
            }}
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="circle-plus" />
          </Button>
        </Tooltip>
      )
    }

    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_PROJECT_MANUAL_IMPORTS}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              <TabButtons tabButtons={[{ fullJSX: rJSX_NewProjectButton(false), minJSX: rJSX_NewProjectButton(true), sizeCutoff: 510 }]} />
            </Box>
            <Box>
              <TabsBasic
                tabs={[
                  {
                    tabHeader: s_IMPORTS,
                    tabContent: (
                      <Box className="tw-mt-2">
                        <Box>
                          {/* <Stack spacing={1} direction="row"> */}
                          <CSVReader
                            // https://react-papaparse.js.org/docs#config
                            // https://github.com/Bunlong/react-papaparse/blob/v4.0.0/examples/CSVReaderBasicUpload.tsx
                            onUploadAccepted={(results: any) => {
                              handleFileSelection(results.data)
                            }}
                            noDrag
                          >
                            {({ getRootProps, acceptedFile, ProgressBar, getRemoveFileProps }: any) => (
                              <Button
                                color="success"
                                variant="contained"
                                disableElevation
                                className="tw-mr-2 tw-mb-1"
                                disabled={us_parsingData === true}
                                startIcon={<Icon icon="upload" />}
                                {...getRootProps()}
                              >
                                {s_SELECT_A_FILE}
                              </Button>
                            )}
                          </CSVReader>
                          <Button
                            color="success"
                            variant="contained"
                            className="tw-mr-2 tw-mb-1"
                            disableElevation
                            disabled={objectToArray(us_fileUploadData).length === 0 || us_uploadingDataToStaging}
                            startIcon={<Icon icon="cloud-arrow-up" />}
                            onClick={() => {
                              uploadSpreadsheetDataToProjects()
                            }}
                          >
                            {s_IMPORT_SPREADSHEET_TO_STAGING} ({us_projectsToImportCount})
                          </Button>
                          <FormControl
                            sx={{ marginTop: 0, marginBottom: 0 }}
                            className="tw-mr-2 tw-mb-1"
                          >
                            <TextField
                              className="bp_thin_text_input "
                              sx={{ marginTop: 0, marginBottom: 0 }}
                              color="primary"
                              value={us_filterDate}
                              margin="normal"
                              type="date"
                              // placeholder={ searchButtonText }
                              onChange={(event: any) => {
                                if (event != null && event.target != null && event.target.value != null) {
                                  us_setFilterDate(event.target.value)
                                }
                              }}
                              onBlur={(event: any) => {}}
                              variant="outlined"
                            />
                          </FormControl>
                          <FormControl className="bp_thin_select_input tw-mr-2 tw-mb-1">
                            <Select
                              color="primary"
                              value={us_selectedSalesPartner}
                              disabled={false}
                              onChange={(event: any) => {
                                if (event != null && event.target != null && event.target.value != null) {
                                  us_setSelectedSalesPartner(event.target.value)
                                }
                              }}
                              variant="outlined"
                            >
                              {objectToArray(us_salesPartnersList).map((option: TsInterface_UnspecifiedObject, index: number) => (
                                <MenuItem
                                  key={index}
                                  value={option['key']}
                                  disabled={option['disabled'] === true}
                                >
                                  {option['name']}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <FormControl className="bp_thin_select_input tw-mr-2 tw-mb-1">
                            <Select
                              color="primary"
                              value={us_selectedRegion || ''}
                              onChange={(event: any) => {
                                if (event != null && event.target != null && event.target.value != null) {
                                  us_setSelectedRegion(event.target.value)
                                }
                              }}
                              variant="outlined"
                            >
                              <MenuItem
                                value={''}
                                disabled={true}
                              >
                                {s_SELECT_REGION}
                              </MenuItem>
                              {objectToArray(us_regionsList).map((option: TsInterface_UnspecifiedObject, index: number) => (
                                <MenuItem
                                  key={index}
                                  value={option['key']}
                                >
                                  {option['name']}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Box
                            className="tw-pt-1 tw-opacity-30 tw-px-2 tw-inline-block tw-mb-1"
                            sx={{
                              border: '1px solid rgba(255,255,255,0.6)',
                              borderRadius: '5px',
                              height: '36.5px',
                            }}
                          >
                            {uc_RootData_ClientKey}
                          </Box>
                          {/* </Stack> */}
                        </Box>
                        <Box className="tw-mt-2">
                          {rJSX_UploadingDataBar()}
                          {rJSX_ImportTable()}
                        </Box>
                      </Box>
                    ),
                  },
                  {
                    tabHeader: s_CLEAN_DATA,
                    tabContent: (
                      <Box className="tw-mt-2">
                        <Box>
                          {/* <Stack spacing={1} direction="row"> */}
                          <Button
                            color="warning"
                            variant="contained"
                            disableElevation
                            disabled={us_uploadingDataToStaging}
                            startIcon={<Icon icon="soap" />}
                            onClick={() => {
                              postProcessAllProjects()
                            }}
                          >
                            {s_CLEAN_IMPORTED_DATA} ({us_cleanTableData.length})
                          </Button>
                          {/* </Stack> */}
                        </Box>
                        <Box className="tw-mt-2">
                          {rJSX_UploadingDataBar()}
                          {rJSX_CleanTable()}
                        </Box>
                      </Box>
                    ),
                  },
                ]}
                tabsSettings={tabsSettings}
              />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

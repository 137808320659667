///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, Card, Grid, LinearProgress } from '@mui/material'
import { markMessageAsRead, markMessageAsUnread, sendMessage } from 'app/models/messages'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  ChatHeaderDisplay,
  ChatMessageEntry,
  ChatMessagesDisplay,
  ChatThreadsDisplay,
  TsInterface_ChatHeaderSettings,
  TsInterface_ChatInputSettings,
  TsInterface_ChatMessageSettings,
  TsInterface_ChatRenameMessageThread,
  TsInterface_ChatThreadSettings,
} from 'rfbp_core/components/chat'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp, returnSubstringAfterLastCharacter } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_MARK_THIS_MESSAGE_AS_UNREAD: JSX.Element = <Trans>Are you sure that you want to mark this message as unread?</Trans>
const s_MARK_AS_UNREAD: JSX.Element = <Trans>Mark as unread</Trans>
const s_MARK_MESSAGE_AS_UNREAD: JSX.Element = <Trans>Mark message as unread</Trans>
const s_NO_MESSAGES_YET: JSX.Element = <Trans>No messages yet</Trans>
const s_SELECT_A_MESSAGE_THREAD: JSX.Element = <Trans>Select a message thread</Trans>
// { sort-end } - displayed text

// Chat
export const defaultEmptyMessageThread = {
  associated_member_keys: {},
  associated_member_last_read: {},
  associated_member_names: {},
  associated_member_unread_count: {},
  associated_member_unread: {},
  key: '',
  last_message_sender_key: '',
  last_message_sender_name: '',
  last_message_text: '',
  last_message_timestamp: new Date(),
  total_members: 0,
}

const chatInputSettings: TsInterface_ChatInputSettings = {}

///////////////////////////////
// Functions
///////////////////////////////

const markAsUnread = (
  threadKey: string,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  selectedMessages: any,
  uc_RootData_GlobalUser: any,
  setSelectedMessageThread: any,
  setSelectedMessageThreadKey: any,
  setSelectedMessages: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): void => {
  uc_setUserInterface_ConfirmDialogDisplay({
    display: true,
    confirm: {
      color: 'success',
      header: <>{s_MARK_MESSAGE_AS_UNREAD}</>,
      icon: <Icon icon="bookmark" />,
      submit_callback: () => {
        return new Promise((resolve, reject) => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              // Get Last Message Timestamp minus 1 millisecond
              let lastReadTimestamp: number | null = null
              for (let messageKey in selectedMessages) {
                let message = selectedMessages[messageKey]
                if (lastReadTimestamp == null || message.timestamp > lastReadTimestamp) {
                  lastReadTimestamp = message.timestamp
                }
              }
              if (lastReadTimestamp == null) {
                lastReadTimestamp = new Date().getTime()
              }
              lastReadTimestamp -= 1
              markMessageAsUnread(uc_RootData_GlobalUser.key as string, res_GCK.clientKey, threadKey, new Date(lastReadTimestamp), 1)
              setSelectedMessageThread(defaultEmptyMessageThread)
              setSelectedMessageThreadKey(null)
              setSelectedMessages({})
              resolve(res_GCK)
            })
            .catch((rej_GCK) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              reject(rej_GCK)
            })
        })
      },
      submit_text: s_MARK_AS_UNREAD,
      text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_MARK_THIS_MESSAGE_AS_UNREAD,
    },
  })
}

const chatMarkAsUnread = (
  threadKey: string,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  selectedMessages: any,
  uc_RootData_GlobalUser: any,
  setSelectedMessageThread: any,
  setSelectedMessageThreadKey: any,
  setSelectedMessages: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
) => {
  return new Promise((resolve, reject) => {
    markAsUnread(
      threadKey,
      uc_setUserInterface_ConfirmDialogDisplay,
      uc_RootData_ClientKey,
      uc_setRootData_ClientKey,
      selectedMessages,
      uc_RootData_GlobalUser,
      setSelectedMessageThread,
      setSelectedMessageThreadKey,
      setSelectedMessages,
      uc_setUserInterface_ErrorDialogDisplay,
    )
    resolve({ success: true })
  })
}

const chatInputOnSubmit = (
  message: string,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  uc_RootData_GlobalUser: any,
  uc_RootData_ClientUser: any,
  selectedMessageThread: any,
  setMessageLastSent: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
) => {
  return new Promise((resolve, reject) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        sendMessage(uc_RootData_GlobalUser.key as string, getProp(uc_RootData_ClientUser, 'name', ''), res_GCK.clientKey, selectedMessageThread.key, message)
          .then((res_SM) => {
            setMessageLastSent(new Date().getTime())
            resolve(res_SM)
          })
          .catch((rej_SM) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SM.error })
            reject(rej_SM)
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  })
}

const chatRenameMessageThread: TsInterface_ChatRenameMessageThread = (threadKey: string, threadName: string | null) => {
  return new Promise((resolve, reject) => {
    // Nothing
  })
}

///////////////////////////////
// JSX
///////////////////////////////

export const rJSX_ChatTabHeader = (uc_RootData_ClientUser: any, chatThreads: any): JSX.Element => {
  let tabHeaderJSX = <>{rLIB('Messages')}</>
  let unreadMessageCount = 0
  for (let loopThreadKey in chatThreads) {
    let loopThread = chatThreads[loopThreadKey]
    if (
      uc_RootData_ClientUser != null &&
      uc_RootData_ClientUser.key != null &&
      loopThread != null &&
      loopThread['associated_member_unread'] != null &&
      loopThread['associated_member_unread'][uc_RootData_ClientUser.key] === true
    ) {
      unreadMessageCount++
    }
  }
  if (unreadMessageCount > 0) {
    tabHeaderJSX = (
      <Box>
        <Badge
          badgeContent={unreadMessageCount}
          color="error"
        >
          {rLIB('Messages')}
        </Badge>
      </Box>
    )
  }
  return tabHeaderJSX
}

const rJSX_ChatContainer = (
  uc_RootData_ClientUser: any,
  loadingMessages: any,
  selectedMessageThread: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  selectedMessages: any,
  uc_RootData_GlobalUser: any,
  setSelectedMessageThread: any,
  setSelectedMessageThreadKey: any,
  setSelectedMessages: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  setMessageLastSent: any,
): JSX.Element => {
  // Settings
  const chatMessageSettings: TsInterface_ChatMessageSettings = {
    associated_viewer_key: getProp(uc_RootData_ClientUser, 'key', null),
  }
  const emptyChatHeaderSettings: TsInterface_ChatHeaderSettings = {
    associated_viewer_key: getProp(uc_RootData_ClientUser, 'key', null),
    hide_rename_button: true,
    hide_unread_button: true,
    show_membership_edit_button: true,
  }
  const chatHeaderSettings: TsInterface_ChatHeaderSettings = {
    associated_viewer_key: getProp(uc_RootData_ClientUser, 'key', null),
    hide_rename_button: true,
    show_membership_edit_button: true,
  }
  // JSX
  let containerJSX = <></>
  if (loadingMessages === true) {
    containerJSX = (
      <Card className="etw_chat_threads_container">
        <LinearProgress />
      </Card>
    )
  } else if (selectedMessageThread != null && selectedMessageThread.key != null && selectedMessageThread.key !== '') {
    let chatType = returnSubstringAfterLastCharacter(getProp(selectedMessageThread, 'key', ''), '_')
    let conditionalChatBoxSX = {}
    if (chatType === 'customer') {
      conditionalChatBoxSX = { backgroundColor: themeVariables.error_light }
    }
    chatMessageSettings.avatar_color_override = getProp(selectedMessageThread, 'avatar_color_override', null)
    if (selectedMessageThread['total_members'] === 0) {
      containerJSX = (
        <Card
          sx={conditionalChatBoxSX}
          className="tw-p-2"
        >
          <ChatHeaderDisplay
            chatHeaderSettings={emptyChatHeaderSettings}
            chatThread={selectedMessageThread}
            chatMarkAsUnread={(threadKey) => {
              return new Promise((resolve, reject) => {
                chatMarkAsUnread(
                  threadKey,
                  uc_setUserInterface_ConfirmDialogDisplay,
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  selectedMessages,
                  uc_RootData_GlobalUser,
                  setSelectedMessageThread,
                  setSelectedMessageThreadKey,
                  setSelectedMessages,
                  uc_setUserInterface_ErrorDialogDisplay,
                )
                  .then((res_CMAU) => {
                    // @ts-expect-error - TODO: reason for error
                    resolve(res_CMAU)
                  })
                  .catch((rej_CMAU) => {
                    reject(rej_CMAU)
                  })
              })
            }}
            chatRenameMessageThread={chatRenameMessageThread}
          />
          <Box className="tw-italic tw-opacity-30 tw-text-center">{s_NO_MESSAGES_YET}</Box>
          <ChatMessageEntry
            chatInputOnSubmit={(message) => {
              return new Promise((resolve, reject) => {
                chatInputOnSubmit(
                  message,
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  uc_RootData_GlobalUser,
                  uc_RootData_ClientUser,
                  selectedMessageThread,
                  setMessageLastSent,
                  uc_setUserInterface_ErrorDialogDisplay,
                )
                  .then((res_CIOS) => {
                    // @ts-expect-error - TODO: reason for error
                    resolve(res_CIOS)
                  })
                  .catch((rej_CIOS) => {
                    reject(rej_CIOS)
                  })
              })
            }}
            chatInputSettings={chatInputSettings}
          />
        </Card>
      )
    } else {
      containerJSX = (
        <Card
          sx={conditionalChatBoxSX}
          className="tw-p-2"
        >
          <ChatHeaderDisplay
            chatHeaderSettings={chatHeaderSettings}
            chatThread={selectedMessageThread}
            chatMarkAsUnread={(threadKey) => {
              return new Promise((resolve, reject) => {
                chatMarkAsUnread(
                  threadKey,
                  uc_setUserInterface_ConfirmDialogDisplay,
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  selectedMessages,
                  uc_RootData_GlobalUser,
                  setSelectedMessageThread,
                  setSelectedMessageThreadKey,
                  setSelectedMessages,
                  uc_setUserInterface_ErrorDialogDisplay,
                )
                  .then((res_CMAU) => {
                    // @ts-expect-error - TODO: reason for error
                    resolve(res_CMAU)
                  })
                  .catch((rej_CMAU) => {
                    reject(rej_CMAU)
                  })
              })
            }}
            chatRenameMessageThread={chatRenameMessageThread}
          />
          <Box
            id="etw_chat_message_display"
            className="etw_chat_message_display"
          >
            <ChatMessagesDisplay
              chatThread={selectedMessageThread}
              chatMessages={selectedMessages}
              chatSettings={chatMessageSettings}
            />
          </Box>
          <ChatMessageEntry
            chatInputOnSubmit={(message) => {
              return new Promise((resolve, reject) => {
                chatInputOnSubmit(
                  message,
                  uc_RootData_ClientKey,
                  uc_setRootData_ClientKey,
                  uc_RootData_GlobalUser,
                  uc_RootData_ClientUser,
                  selectedMessageThread,
                  setMessageLastSent,
                  uc_setUserInterface_ErrorDialogDisplay,
                )
                  .then((res_CIOS) => {
                    // @ts-expect-error - TODO: reason for error
                    resolve(res_CIOS)
                  })
                  .catch((rej_CIOS) => {
                    reject(rej_CIOS)
                  })
              })
            }}
            chatInputSettings={chatInputSettings}
          />
        </Card>
      )
    }
  } else {
    containerJSX = <Card className="tw-p-2 tw-text-center etw_chat_threads_container">{s_SELECT_A_MESSAGE_THREAD}</Card>
  }
  return containerJSX
}

///////////////////////////////
// JSX Exports
///////////////////////////////

export const rJSX_MessagesTab = (
  readOrWrite: 'read' | 'write',
  uc_RootData_ClientKey: any,
  uc_RootData_ClientUser: any,
  uc_RootData_GlobalUser: any,
  chatThreads: any,
  loadingMessages: any,
  selectedMessageThread: any,
  selectedMessageThreadKey: any,
  selectedMessages: any,
  setLoadingMessages: any,
  setMessageLastSent: any,
  uc_setRootData_ClientKey: any,
  setSelectedMessageThread: any,
  setSelectedMessageThreadKey: any,
  setSelectedMessages: any,
  uc_setUserInterface_ConfirmDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): JSX.Element => {
  let threadSettings_ProjectChat: TsInterface_ChatThreadSettings = {
    associated_viewer_key: getProp(uc_RootData_GlobalUser, 'key', null),
    include_create_thread: false,
    selected_thread_key: getProp(selectedMessageThread, 'key', null),
  }
  // JSX
  let tabJSX = (
    <Box>
      <Box className="etw_chat_message_container">
        <Box
          component="div"
          sx={{ flexGrow: 1 }}
        >
          <Grid
            container
            spacing={1}
            columns={7}
          >
            <Grid
              item
              sm={2}
              xs={2}
            >
              <Card className="etw_chat_threads_container">
                <ChatThreadsDisplay
                  chatThreads={chatThreads}
                  threadSettings={threadSettings_ProjectChat}
                  threadOnClick={(threadKey) => {
                    if (selectedMessageThreadKey !== threadKey) {
                      setLoadingMessages(true)
                      setTimeout(() => {
                        if (threadKey != null) {
                          let unreadCount = 0
                          if (
                            threadKey != null &&
                            chatThreads != null &&
                            chatThreads[threadKey] != null &&
                            chatThreads[threadKey]['associated_member_unread_count'] != null &&
                            uc_RootData_GlobalUser != null &&
                            uc_RootData_GlobalUser.key != null &&
                            chatThreads[threadKey]['associated_member_unread_count'][uc_RootData_GlobalUser.key] != null
                          ) {
                            unreadCount = chatThreads[threadKey]['associated_member_unread_count'][uc_RootData_GlobalUser.key]
                          }
                          if (unreadCount > 0) {
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                              let newLastReadTimestamp = new Date()
                              markMessageAsRead(uc_RootData_GlobalUser.key as string, res_GCK.clientKey, threadKey, newLastReadTimestamp)
                            })
                          }
                        }
                      }, 500)
                      if (chatThreads != null && chatThreads[threadKey] != null) {
                        setSelectedMessageThread(chatThreads[threadKey])
                        setSelectedMessageThreadKey(threadKey)
                      }
                    }
                  }}
                />
              </Card>
            </Grid>
            <Grid
              item
              sm={5}
              xs={5}
            >
              {rJSX_ChatContainer(
                uc_RootData_GlobalUser,
                loadingMessages,
                selectedMessageThread,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_RootData_ClientKey,
                uc_setRootData_ClientKey,
                selectedMessages,
                uc_RootData_GlobalUser,
                setSelectedMessageThread,
                setSelectedMessageThreadKey,
                setSelectedMessages,
                uc_setUserInterface_ErrorDialogDisplay,
                setMessageLastSent,
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
  return tabJSX
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Spacer line

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import { TsInterface_DataViewerAdditionalData, TsInterface_DataViewerData, TsInterface_DataViewerLine } from 'rfbp_core/components/data_viewer'
import { TsInterface_DataViewerHooksObject } from '../data_viewer_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const ViewerLineSpacer = (marginValue: number): TsInterface_DataViewerLine => {
  let className = 'tw-mt-' + marginValue.toString()
  let viewerLine = {
    line_jsx: (
      viewerData: TsInterface_DataViewerData,
      viewerAdditionalData: TsInterface_DataViewerAdditionalData,
      viewerHooks: TsInterface_DataViewerHooksObject,
    ) => {
      return (
        <Box
          component="div"
          className={className}
        ></Box>
      )
    },
    line_css: (viewerData: TsInterface_DataViewerData, viewerAdditionalData: TsInterface_DataViewerAdditionalData) => {
      return ''
    },
  }
  return viewerLine
}

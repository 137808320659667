///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Button, Chip, Dialog, DialogContent, Divider, IconButton, Toolbar, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import { useContext, useEffect, useReducer, useState } from 'react'
import { DatabaseRef_FinalizedPayroll_DistanceAdderUserData_Document } from 'rfbp_aux/services/database_endpoints/finances/finalized_payroll'
import {
  DatabaseRef_PayrollAdjustments_Collection,
  DatabaseRef_PayrollAdjustment_UserDateQuery,
} from 'rfbp_aux/services/database_endpoints/finances/payroll_adjustments'
import { DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseAddDocument, DatabaseGetCollection, DatabaseGetDocument, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  formatCurrency,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDateKey,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { getLastChronologicalDate, getStartAndEndOfWeek } from '../data/payroll_calculations'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_DistanceAdderAdjustmentDialog {
  payrollMondayKey: string
  taskKey: string
  unitPayAmounts: TsInterface_UnspecifiedObject
  resolveErrorOrWarning: any
  unresolveErrorOrWarning: any
}

///////////////////////////////
// Exports
///////////////////////////////

export const DistanceAdderAdjustmentDialog: React.FC<TsInterface_DistanceAdderAdjustmentDialog> = ({
  payrollMondayKey,
  taskKey,
  unitPayAmounts,
  resolveErrorOrWarning,
  unresolveErrorOrWarning,
}): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const { uc_setUserInterface_FormDialogDisplay } = 											useContext( Context_UserInterface_FormDialog )
  const [us_creatingAdjustment, us_setCreatingAdjustment] = useState<boolean>(false)
  const [us_distanceAdderAdjustments, us_setDistanceAdderAdjustments] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lastLoadedNotLiveData, us_setLastLoadedNotLiveData] = useState<number>(new Date().getTime())
  const [us_originalPayrollDistanceAdderTask, us_setOriginalPayrollDistanceAdderTask] = useState<TsInterface_UnspecifiedObject>({})
  const [us_payrollMondayDate, us_setPayrollMondayDate] = useState<Date | null>(null)
  const [us_taskData, us_setTaskData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskLastDateKey, us_setTaskLastDateKey] = useState<string | null>(null)
  const [us_userList, us_setUserList] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    // Get Task
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskData(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Task_Document(res_GCK.clientKey, taskKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, taskKey])

  useEffect(() => {
    // Get Date Ranges
    let lastDateKey = getLastChronologicalDate(us_taskData['task_completion_scheduled_dates'])
    // let completionDate = returnDateFromUnknownDateFormat( us_taskData["timestamp_completed"] )
    us_setTaskLastDateKey(lastDateKey)
    if (lastDateKey != null && lastDateKey !== '') {
      // Get Dates To check
      let lastDate = returnDateFromUnknownDateFormat(lastDateKey)
      let payrollMondayDate = getStartAndEndOfWeek(lastDate).startOfWeek
      us_setPayrollMondayDate(payrollMondayDate)
    } else {
      us_setPayrollMondayDate(null)
    }
  }, [us_taskData])

  useEffect(() => {
    // Get User List
    if (us_taskLastDateKey != null && us_taskLastDateKey !== '' && us_payrollMondayDate != null) {
      let userNamesOnLastDate = us_taskData['task_completion_scheduled_team_names'][us_taskLastDateKey]
      let userList: TsInterface_UnspecifiedObject = {}
      for (let loopUserKey in userNamesOnLastDate) {
        userList[loopUserKey] = {
          name: userNamesOnLastDate[loopUserKey],
          key: loopUserKey,
        }
      }
      us_setUserList(userList)
    } else {
      us_setUserList({})
    }
  }, [us_payrollMondayDate, us_taskData, us_taskLastDateKey])

  useEffect(() => {
    if (us_lastLoadedNotLiveData > 0) {
      /* Used to reload data on adjustment creation */
    }
    // Get Distance Adders for Task Last Scheduled Date Payroll Week
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (us_taskLastDateKey != null && us_taskLastDateKey !== '' && us_payrollMondayDate != null) {
          let promiseArray: TsType_UnknownPromise[] = []
          let originalPayrollDistanceAdderTask: TsInterface_UnspecifiedObject = {}
          // Loop through Users
          for (let loopUserKey in us_userList) {
            if (loopUserKey !== 'undefined') {
              originalPayrollDistanceAdderTask[loopUserKey] = {}
              // Get Original Distance Adder from Real Payroll Date
              promiseArray.push(
                DatabaseGetDocument(
                  DatabaseRef_FinalizedPayroll_DistanceAdderUserData_Document(res_GCK.clientKey, returnFormattedDateKey(us_payrollMondayDate), loopUserKey),
                )
                  .then((res_DGD) => {
                    if (res_DGD.data != null && res_DGD.data['dates'] != null && res_DGD.data['dates'][us_taskLastDateKey as string] != null) {
                      for (let loopTaskKey in res_DGD.data['dates'][us_taskLastDateKey as string]) {
                        let loopTask = res_DGD.data['dates'][us_taskLastDateKey as string][loopTaskKey]
                        if (loopTask['selected_distance_adder_task'] === true) {
                          originalPayrollDistanceAdderTask[loopUserKey] = loopTask
                        }
                      }
                    }
                  })
                  .catch((rej_DGD) => {
                    console.error(rej_DGD)
                  }),
              )
            }
          }
          Promise.all(promiseArray).then(() => {
            us_setOriginalPayrollDistanceAdderTask(originalPayrollDistanceAdderTask)
          })
        } else {
          us_setOriginalPayrollDistanceAdderTask({})
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_lastLoadedNotLiveData, us_payrollMondayDate, us_taskLastDateKey, us_userList])

  useEffect(() => {
    if (us_lastLoadedNotLiveData > 0) {
      /* Used to reload data on adjustment creation */
    }
    // Get Payroll Adjustments for the Task Last Scheduled Date
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (us_taskLastDateKey != null && us_taskLastDateKey !== '') {
          let promiseArray: TsType_UnknownPromise[] = []
          let distanceAdderAdjustments: TsInterface_UnspecifiedObject = {}
          // Loop through Users
          for (let loopUserKey in us_userList) {
            if (loopUserKey !== 'undefined') {
              distanceAdderAdjustments[loopUserKey] = {}
              // Get Adjustments to Distance Adder
              promiseArray.push(
                DatabaseGetCollection(DatabaseRef_PayrollAdjustment_UserDateQuery(res_GCK.clientKey, us_taskLastDateKey, loopUserKey))
                  .then((res_DGD) => {
                    distanceAdderAdjustments[loopUserKey] = res_DGD.data
                  })
                  .catch((rej_DGD) => {
                    console.error(rej_DGD)
                  }),
              )
            }
          }
          Promise.all(promiseArray).then(() => {
            us_setDistanceAdderAdjustments(distanceAdderAdjustments)
          })
        } else {
          us_setDistanceAdderAdjustments({})
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_lastLoadedNotLiveData, us_taskLastDateKey, us_userList])

  // Functions
  const createAdjustment = (user: TsInterface_UnspecifiedObject, difference: number) => {
    us_setCreatingAdjustment(true)
    // TODO
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (us_taskLastDateKey != null && us_taskLastDateKey !== '' && user != null && user.key != null && user.name != null) {
          let updateObject: TsInterface_UnspecifiedObject = {
            associated_creator_key: getProp(uc_RootData_ClientUser, 'key', null),
            associated_creator_name: getProp(uc_RootData_ClientUser, 'name', null),
            associated_distance_adder_user_date_key: us_taskLastDateKey + '_' + user.key,
            associated_payroll_week_adjustment_amounts: {
              [payrollMondayKey]: Math.abs(difference),
            },
            associated_payroll_weeks: {
              [payrollMondayKey]: true,
            },
            associated_project_id_number: getProp(us_taskData, 'associated_project_id_number', null),
            associated_task_key: taskKey,
            associated_task_name: getProp(us_taskData, 'name', null),
            associated_user_key: user.key,
            associated_user_name: user.name,
            notes:
              'Distance Adder Adjustment for ' +
              user.name +
              ' - ' +
              us_taskLastDateKey +
              ' (' +
              getProp(us_taskData, 'associated_project_id_number', '') +
              ' - ' +
              getProp(us_taskData, 'name', '') +
              ')',
            remaining_adjustment_amount: 0,
            status: 'resolved',
            timestamp_created: new Date(),
            total_adjustment_amount: Math.abs(difference),
            type: 'payroll_bonus_increase',
          }
          DatabaseAddDocument(DatabaseRef_PayrollAdjustments_Collection(res_GCK.clientKey), updateObject, true)
            .then((res_DAD) => {
              us_setCreatingAdjustment(false)
              us_setLastLoadedNotLiveData(new Date().getTime())
            })
            .catch((rej_DAD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              us_setCreatingAdjustment(false)
              us_setLastLoadedNotLiveData(new Date().getTime())
            })
        } else {
          let error = {
            message: rLIB('Failed to create adjustment'),
            details: rLIB('Missing required parameters'),
            code: 'ER-D-PI-CA-01',
          }
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
          us_setCreatingAdjustment(false)
          us_setLastLoadedNotLiveData(new Date().getTime())
        }
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        us_setCreatingAdjustment(false)
        us_setLastLoadedNotLiveData(new Date().getTime())
      })
  }

  // JSX Generation
  const rJSX_UserDistanceAdderSummary = (user: TsInterface_UnspecifiedObject): JSX.Element => {
    // Original Task
    let originalTaskJSX = <></>
    let totalPaidOut = 0
    if (
      us_originalPayrollDistanceAdderTask != null &&
      us_originalPayrollDistanceAdderTask[user.key] != null &&
      us_originalPayrollDistanceAdderTask[user.key]['possible_distance_pay'] != null
    ) {
      totalPaidOut += us_originalPayrollDistanceAdderTask[user.key]['possible_distance_pay']
      originalTaskJSX = (
        <Box className="tw-ml-4 tw-mb-2">
          <Chip
            variant="filled"
            color="success"
            label={formatCurrency(us_originalPayrollDistanceAdderTask[user.key]['possible_distance_pay'])}
            sx={{ fontSize: '18px' }}
            className="tw-align-top"
          />
          <Typography
            variant="h6"
            className="tw-inline-block tw-ml-2"
          >
            {rLIB('Distance Adder on')} {us_taskLastDateKey}
          </Typography>
        </Box>
      )
    } else {
      originalTaskJSX = (
        <Box className="tw-ml-4 tw-mb-2">
          <Chip
            variant="filled"
            color="default"
            label={formatCurrency(0)}
            sx={{ fontSize: '18px' }}
            className="tw-align-top"
          />
          <Typography
            variant="h6"
            className="tw-inline-block tw-opacity-30 tw-ml-2"
          >
            {rLIB('No Original Distance Adder for')} {us_taskLastDateKey}
          </Typography>
        </Box>
      )
    }
    // Adjustments
    let adjustmentsJSX = <></>
    if (us_distanceAdderAdjustments != null && us_distanceAdderAdjustments[user.key] != null) {
      for (let loopAdjustmentKey in us_distanceAdderAdjustments[user.key]) {
        let loopAdjustment = us_distanceAdderAdjustments[user.key][loopAdjustmentKey]
        totalPaidOut += loopAdjustment['total_adjustment_amount']
      }
      adjustmentsJSX = (
        <Box className="tw-ml-4 tw-mb-2">
          {objectToArray(us_distanceAdderAdjustments[user.key])
            .sort(dynamicSort('name', 'asc'))
            .map((loopAdjustment: TsInterface_UnspecifiedObject, index: number) => (
              <Box
                key={index}
                className="tw-mb-2"
              >
                <Chip
                  variant="filled"
                  color="success"
                  label={formatCurrency(loopAdjustment['total_adjustment_amount'])}
                  sx={{ fontSize: '18px' }}
                  className="tw-align-top"
                />
                <Typography
                  variant="h6"
                  className="tw-inline-block tw-ml-2"
                >
                  {rLIB('Adjustment For')} {loopAdjustment.associated_project_id_number} - {loopAdjustment.associated_task_name}
                </Typography>
              </Box>
            ))}
        </Box>
      )
    }
    // Create Adjustment Button
    let createAdjustmentRowJSX = <></>
    if (
      user != null &&
      user.key != null &&
      unitPayAmounts != null &&
      unitPayAmounts[user.key] != null &&
      unitPayAmounts[user.key]['possible_distance_pay'] != null
    ) {
      let difference = unitPayAmounts[user.key]['possible_distance_pay'] - totalPaidOut
      if (difference > 0) {
        createAdjustmentRowJSX = (
          <Box className="tw-ml-4 tw-mb-2">
            <Chip
              variant="filled"
              color="error"
              label={formatCurrency(difference)}
              sx={{ fontSize: '18px' }}
              className="tw-align-top"
            />
            <Typography
              variant="h6"
              className="tw-inline-block tw-ml-2"
            >
              {rLIB('Adjustment Needed For')} {us_taskData.associated_project_id_number} - {us_taskData.name} (
              {formatCurrency(unitPayAmounts[user.key]['possible_distance_pay'])})
            </Typography>
            <Box className="tw-mt-2 tw-ml-8">
              <Button
                disabled={us_creatingAdjustment}
                color="success"
                variant="contained"
                onClick={() => {
                  createAdjustment(user, difference)
                }}
                startIcon={<Icon icon="circle-plus" />}
              >
                {rLIB('New Adjustment')}
              </Button>
            </Box>
          </Box>
        )
      } else {
        createAdjustmentRowJSX = (
          <Box className="tw-ml-4 tw-mb-2">
            <Chip
              variant="filled"
              color="default"
              label={formatCurrency(0)}
              sx={{ fontSize: '18px' }}
              className="tw-align-top"
            />
            <Typography
              variant="h6"
              className="tw-inline-block tw-ml-2"
            >
              {rLIB('Adjustment Needed For')} {us_taskData.associated_project_id_number} - {us_taskData.name} (
              {formatCurrency(unitPayAmounts[user.key]['possible_distance_pay'])})
            </Typography>
          </Box>
        )
      }
    }
    // Summary
    let summaryJSX = (
      <Box>
        <Typography variant="h5">{user.name}</Typography>
        {originalTaskJSX}
        {adjustmentsJSX}
        {createAdjustmentRowJSX}
      </Box>
    )
    return summaryJSX
  }

  const rJSX_ResolveErrorButton = (): JSX.Element => {
    // Determine if the Resolve Button should be disabled
    let disableResolveButton = false
    for (let loopUserKey in us_userList) {
      let totalPaidOut = 0
      if (
        us_originalPayrollDistanceAdderTask != null &&
        us_originalPayrollDistanceAdderTask[loopUserKey] != null &&
        us_originalPayrollDistanceAdderTask[loopUserKey]['possible_distance_pay'] != null
      ) {
        totalPaidOut += us_originalPayrollDistanceAdderTask[loopUserKey]['possible_distance_pay']
      }
      // Adjustments
      if (us_distanceAdderAdjustments != null && us_distanceAdderAdjustments[loopUserKey] != null) {
        for (let loopAdjustmentKey in us_distanceAdderAdjustments[loopUserKey]) {
          let loopAdjustment = us_distanceAdderAdjustments[loopUserKey][loopAdjustmentKey]
          totalPaidOut += loopAdjustment['total_adjustment_amount']
        }
      }
      // Remaining Difference
      if (unitPayAmounts != null && unitPayAmounts[loopUserKey] != null && unitPayAmounts[loopUserKey]['possible_distance_pay'] != null) {
        let difference = unitPayAmounts[loopUserKey]['possible_distance_pay'] - totalPaidOut
        if (difference > 0) {
          disableResolveButton = true
        }
      }
    }
    // Button JSX
    let errorButtonJSX = (
      <Button
        disabled={us_creatingAdjustment || disableResolveButton}
        color="success"
        variant="contained"
        onClick={() => {
          let errorCategoryKey = 'warnings_distance_adders'
          resolveErrorOrWarning(errorCategoryKey, taskKey).then(() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          })
        }}
        startIcon={<Icon icon="circle-exclamation-check" />}
      >
        {rLIB('Resolve Error')}
      </Button>
    )
    return errorButtonJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="message-dollar" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box>
                  {rLIB('Potential Distance Adder Adjustments')} - {us_taskData.associated_project_id_number} - {us_taskData.name}
                </Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <Box className="tw-p-4">
              {objectToArray(us_userList)
                .sort(dynamicSort('name', 'asc'))
                .map((loopUserData: TsInterface_UnspecifiedObject, index: number) => (
                  <Box key={index}>
                    {rJSX_UserDistanceAdderSummary(loopUserData)}

                    <Divider className="tw-my-4" />
                  </Box>
                ))}
              <Box>{rJSX_ResolveErrorButton()}</Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

DistanceAdderAdjustmentDialog.propTypes = {
  payrollMondayKey: PropTypes.string.isRequired,
  taskKey: PropTypes.string.isRequired,
  unitPayAmounts: PropTypes.any.isRequired,
  resolveErrorOrWarning: PropTypes.any.isRequired,
  unresolveErrorOrWarning: PropTypes.any.isRequired,
}
;``

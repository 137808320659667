///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Typography } from '@mui/material/'
import { useContext, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientUser } from 'rfbp_core/services/context'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseToolViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_UNDER_CONSTRUCTION: JSX.Element = <Trans>Under Construction</Trans>
const se_TOOLS: string = 'Tools'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'tools'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    document.title = se_TOOLS
  }, [])

  // Functions

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Tools') as JSX.Element}
        pageKey={pageKey}
        content={
          <Box>
            <Box className="tw-text-center">
              <Icon
                icon="screwdriver-wrench"
                size="5x"
                type="light"
                className="tw-mb-4"
                sx={{ color: themeVariables.primary_main }}
              />
              <Typography variant="h5">{s_UNDER_CONSTRUCTION}</Typography>
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Chip } from '@mui/material'
import { Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Exports
///////////////////////////////

export const tableColumns_APILogs: TsInterface_TableColumns = {
  timestamp: TableCellTimestamp('timestamp', rLIB('Timestamp'), 'timestamp', 'YYYY-MM-DD HH:mm', true),
  method: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Method')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <Box>
            <Chip
              label={getProp(rowData, 'method', '').toUpperCase()}
              variant="outlined"
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  endpoint: TableCellBasic('endpoint', rLIB('Endpoint'), 'endpoint'),
  api_version: TableCellBasic('api_version', rLIB('API Version'), 'api_version'),
  function_version: TableCellBasic('function_version', rLIB('Function Version'), 'function_version'),
  http_status_code: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Status')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.http_status_code != null) {
          let firstCharacter = String(getProp(rowData, 'http_status_code', '0'))[0]
          let color: 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default' = 'default'
          if (firstCharacter === '2') {
            color = 'success'
          } else if (firstCharacter === '3') {
            color = 'info'
          } else if (firstCharacter === '4') {
            color = 'warning'
          } else if (firstCharacter === '5') {
            color = 'error'
          }
          cellJSX = (
            <Box>
              <Chip
                color={color}
                label={getProp(rowData, 'http_status_code', '')}
                variant="outlined"
              />
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  query: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Query')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <Box
            className="tw-p-1 tw-rounded-md tw-bg-background_json tw-cursor-pointer tw-inline-block hover:tw-bg-black tw-text-gray_500 hover:tw-text-white"
            onClick={() => {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Json
                      data={getProp(rowData, 'query', {})}
                      alphabetized={true}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            }}
          >
            <Icon
              icon="code"
              className="tw-mr-2"
            />
            {rLIB('Query')}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  body: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Body')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <Box
            className="tw-p-1 tw-rounded-md tw-bg-background_json tw-cursor-pointer tw-inline-block hover:tw-bg-black tw-text-gray_500 hover:tw-text-white"
            onClick={() => {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Json
                      data={getProp(rowData, 'body', {})}
                      alphabetized={true}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            }}
          >
            <Icon
              icon="code"
              className="tw-mr-2"
            />
            {rLIB('Body')}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  response: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Response')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <Box
            className="tw-p-1 tw-rounded-md tw-bg-background_json tw-cursor-pointer tw-inline-block hover:tw-bg-black tw-text-gray_500 hover:tw-text-white"
            onClick={() => {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Json
                      data={getProp(rowData, 'response', {})}
                      alphabetized={true}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            }}
          >
            <Icon
              icon="code"
              className="tw-mr-2"
            />
            {rLIB('Response')}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  raw: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Raw Data')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <Box
            className="tw-p-1 tw-rounded-md tw-bg-background_json tw-cursor-pointer tw-inline-block hover:tw-bg-black tw-text-gray_500 hover:tw-text-white"
            onClick={() => {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Json
                      data={rowData}
                      alphabetized={true}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            }}
          >
            <Icon
              icon="code"
              className="tw-mr-2"
            />
            {rLIB('Raw Data')}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

export const tableSettings_APILogs: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp',
  use_live_data: true,
}

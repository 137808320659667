///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Divider, Stack, Typography } from '@mui/material/'
import { rJSX_defaultProjectTimeline } from 'app/models/powerwall/powerwall_timeline'
import { EmailAuthProvider, getAuth, linkWithCredential, signInAnonymously } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { rJSX_StagingWarning } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_PowerwallOrder_Document } from 'rfbp_aux/services/database_endpoints/webstore/orders'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { DatabaseGetDocument } from 'rfbp_core/services/database_management'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

// @ts-expect-error - TODO: reason for error
import logoSrc from '../../images/logos/logo_temp.png'
// @ts-expect-error - TODO: reason for error
import bg2Src from '../../images/unauthenticated/powerwall2.jpg'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_EMAIL_PASSWORD_NOTE1 = <Trans>If you want to see your order or review any documents, feel free to log in</Trans>
const s_EMAIL_PASSWORD_NOTE2 = <Trans>On the login page, click on "SEND LOGIN EMAIL" and we'll send you a link to log you right in - no password needed.</Trans>
const s_EMAIL_PASSWORD_NOTE3 = (
  <Trans>
    If you aren't logged into your email on this device, then you can always set up a traditional password login by clicking on "LOG IN WITH PASSWORD" and then
    clicking on "RESET PASSWORD" and we'll send an email for you to set up a password for your account.
  </Trans>
)
const s_ORDER_CONFIRMED = <Trans>Order Confirmed</Trans>
const s_SO_WHAT_HAPPENS_NOW = <Trans>So what happens now?</Trans>
const se_POWERWALL_INSTALLATION = 'Powerwall Installation'
// { sort-end } - displayed text

// CSS - for full page scroll
let pageCSS: string = `
		@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700&display=swap');
		body {
			background: #000000;
		}
		.etw_landing_font {
			font-family: "Inter", "Manrope", "Roboto","Helvetica","Arial",sans-serif;
		}
		.etw_landing_smooth_container {
			scroll-snap-type: y mandatory;
		}
		.etw_landing_smooth_container .etw_landing_smooth_section {
			scroll-behavior: smooth;
			scroll-snap-align: start;
		}
		.etw_contact_form_input_container .MuiInputBase-root {
			border-radius: 30px;
			background: #1F253A;
		}
		/* YELLOW AUTOFILL */
		input:-webkit-autofill {
			/* Override default yellow background */
			-webkit-box-shadow: 0 0 0px 1000px white inset;
		}
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus
		input:-webkit-autofill,
		input:-webkit-autofill:active,
		textarea:-webkit-autofill,
		textarea:-webkit-autofill:hover
		textarea:-webkit-autofill:focus,
		select:-webkit-autofill,
		select:-webkit-autofill:hover,
		select:-webkit-autofill:focus {
		  border:none !important;
		  -webkit-text-fill-color: #fff !important;
		  -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
		  transition: background-color 5000s ease-in-out 0s;
		  background-clip: content-box !important;
		}
	`

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_clientKey: string = params.cid as string
  const pr_userKey: string = params.uid as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_authUserObject, us_setAuthUserObject] = useState<any>(null)
  const [us_powerwallOrderData, us_setPowerwallOrderData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userAuthenticated, us_setUserAuthenticated] = useState<boolean>(false)
  const un_routerNavigation = useNavigate()
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_POWERWALL_INSTALLATION
  }, [])

  useEffect(() => {
    // Uses anonymous authentication if the user is not logged in so that we can still save their data
    const auth = getAuth()
    if (auth != null && auth.currentUser != null && auth.currentUser.uid != null) {
      us_setAuthUserObject(auth.currentUser)
      us_setUserAuthenticated(true)
    } else {
      signInAnonymously(auth)
        .then((res_SIA) => {
          if (res_SIA != null && res_SIA.user != null && res_SIA.user.uid != null) {
            us_setAuthUserObject(res_SIA.user)
            us_setUserAuthenticated(true)
          } else {
            us_setUserAuthenticated(false)
          }
        })
        .catch((rej_SIA) => {
          console.error(rej_SIA)
          us_setUserAuthenticated(false)
        })
    }
  }, [])

  useEffect(() => {
    if (
      us_authUserObject != null &&
      us_authUserObject.uid != null &&
      us_authUserObject.isAnonymous === true &&
      us_authUserObject.uid === pr_userKey &&
      us_powerwallOrderData != null &&
      us_powerwallOrderData.email != null
    ) {
      const auth = getAuth()
      if (auth != null && auth.currentUser != null && auth.currentUser.uid != null) {
        const credential = EmailAuthProvider.credential(us_powerwallOrderData.email, us_authUserObject.uid)
        linkWithCredential(auth.currentUser, credential)
          .then((res_LWC) => {
            // const user = res_LWC.user
          })
          .catch((rej_LWC) => {})
      }
    }
  }, [pr_userKey, pr_clientKey, us_authUserObject, us_powerwallOrderData])

  useEffect(() => {
    if (pr_clientKey != null && pr_userKey != null && us_authUserObject != null && pr_userKey === us_authUserObject.uid && us_userAuthenticated === true) {
      DatabaseGetDocument(DatabaseRef_PowerwallOrder_Document(pr_clientKey, pr_userKey))
        .then((res_DGD) => {
          let powerwallOrder = res_DGD.data
          us_setPowerwallOrderData(res_DGD.data)
          // Hit cloud function to update order status, send email, create account.
          if (powerwallOrder['status'] === 'pending_deposit_payment') {
            cloudFunctionManageRequest('managePayments', {
              function: 'confirmStripeCheckoutSessionCompletion',
              client_key: pr_clientKey,
              user_key: pr_userKey,
              webstore_item_key: 'powerwall_deposit',
            })
              .then((res_CFMPR: any) => {})
              .catch((rej_CFMPR: any) => {
                console.error(rej_CFMPR)
              })
          } else if (powerwallOrder['status'] === 'new') {
            cloudFunctionManageRequest('managePayments', {
              function: 'confirmStripeCheckoutSessionCompletion',
              client_key: pr_clientKey,
              user_key: pr_userKey,
              webstore_item_key: 'order_without_deposit',
            })
              .then((res_CFMPR: any) => {})
              .catch((rej_CFMPR: any) => {
                console.error(rej_CFMPR)
              })
          }
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        })
    }
  }, [pr_clientKey, pr_userKey, us_userAuthenticated, us_authUserObject])

  // Functions

  // JSX Generation
  const rJSX_Header = (): JSX.Element => {
    let headerJSX = (
      <Stack
        direction="row"
        className="tw-justify-between"
      >
        <Box
          className="tw-m-2"
          component="img"
          sx={{ width: 126, height: 36 }}
          alt="logo"
          src={logoSrc}
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.UnauthenticatedLandingPage.url())
          }}
        />
      </Stack>
    )
    return headerJSX
  }

  const rJSX_Intro = (): JSX.Element => {
    let introJSX = (
      <Box
        className="tw-text-center tw-m-auto"
        sx={{ maxWidth: '1000px' }}
      >
        <Typography
          className="etw_landing_font tw-font-light"
          variant="h3"
        >
          {s_ORDER_CONFIRMED}
        </Typography>
        <Typography
          className="etw_landing_font tw-font-regular tw-mt-2"
          variant="h4"
        >
          {s_SO_WHAT_HAPPENS_NOW}
        </Typography>
        <Divider className="tw-my-2" />
      </Box>
    )
    return introJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box className="etw_landing_smooth_container">
        {rJSX_StagingWarning()}
        <Box
          id="intro"
          className="etw_landing_smooth_section tw-w-full"
          sx={{
            backgroundImage: {
              xs: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(' + bg2Src + ')',
              md: 'url(' + bg2Src + ')',
            },
            backgroundColor: '#000',
            backgroundRepeat: 'no-repeat',
            backgroundSize: {
              xs: 'contain',
              md: 'contain',
            },
            backgroundPositionX: {
              xs: 'center',
              md: 'center',
            },
            minHeight: '100vh',
          }}
        >
          <Box
            className="tw-m-auto"
            sx={{ maxWidth: '1000px' }}
          >
            {rJSX_Header()}
          </Box>
          <Divider />
          <Box sx={{ height: { xs: '20px', md: '30px', lg: '40px' } }}></Box>
          {rJSX_Intro()}
          <Box
            className="tw-p-2 tw-m-auto"
            sx={{ maxWidth: '1000px' }}
          >
            {rJSX_defaultProjectTimeline()}
            <Box
              className="tw-mt-6 tw-text-center tw-m-auto tw-p-2 tw-mb-10"
              sx={{
                width: '95%',
                maxWidth: '700px',
                borderRadius: '8px',
                border: '1px solid #fff',
                backgroundColor: 'rgba(0,0,0,.6)',
              }}
            >
              <Typography
                variant="h6"
                className=" tw-font-light"
              >
                {s_EMAIL_PASSWORD_NOTE1}
              </Typography>
              <Button
                className="tw-mt-6"
                color="inherit"
                variant="outlined"
                onClick={(event) => {
                  onClickAppNavigation(event, un_routerNavigation, ApplicationPages.UnauthenticatedLoginPage.url())
                }}
              >
                {rLIB('Go to login page')}
              </Button>
              <Typography
                variant="body1"
                className="tw-mt-6 tw-font-light tw-opacity-40"
              >
                {s_EMAIL_PASSWORD_NOTE2} {s_EMAIL_PASSWORD_NOTE3}
              </Typography>
            </Box>
          </Box>
        </Box>
        <style>{pageCSS}</style>
      </Box>
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

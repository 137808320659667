//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Example documentation for chat components

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import {
  ChatHeaderDisplay,
  ChatMessageEntry,
  ChatMessagesDisplay,
  ChatThreadsDisplay,
  TsInterface_ChatHeaderSettings,
  TsInterface_ChatInputSettings,
  TsInterface_ChatMarkAsUnread,
  TsInterface_ChatMessages,
  TsInterface_ChatMessageSettings,
  TsInterface_ChatRenameMessageThread,
  TsInterface_ChatThread,
  TsInterface_ChatThreadOnClick,
  TsInterface_ChatThreads,
  TsInterface_ChatThreadSettings,
  TsType_ChatInputOnSubmit,
} from 'rfbp_core/components/chat'
import { ComponentDocumentation, TsInterface_ComponentDocumentation } from 'rfbp_core/components/_example_components'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

// Html strings
const ChatMessagesDisplayHtmlExample = `    <ChatMessagesDisplay
	chatMessages={ chatMessages_Example }
	chatSettings={ chatSettings_Example }
	/>`

const ChatThreadsDisplayHtmlExample = `    <ChatThreadsDisplay
	chatThreads={ chatThreadsDisplay_Example }
	threadOnClick={ threadOnClick_Example }
	threadSettings={ threadSettings_Example }
	/>`

const ChatMessageEntryHtmlExample = `    <ChatMessageEntry
	chatInputOnSubmit={ chatInputOnSubmit_Example }
	chatInputSettings={ chatInputSettings_Example }
	/>`

const ChatHeaderDisplayHtmlExample = `    <ChatHeaderDisplay
	chatHeaderSettings={ chatHeaderSettings_Example }
	chatThread={ chatThread_Example }
	chatMarkAsUnread={ chatMarkAsUnread_Example }
	chatRenameMessageThread={ chatRenameMessageThread_Example }
	/>`

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const ExampleChat = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Other Variables
  const chatMessages_Example: TsInterface_ChatMessages = {
    c1: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message: 'All right Jim, your quarterlies look very good. How are things going at the library?',
      timestamp: 1655390083000,
    },
    c2: {
      associated_sender_key: 'jh',
      associated_sender_name: 'Jim Halpert',
      message: "Oh, I told you. Couldn't close it, so—",
      timestamp: 1655390143000,
    },
    c3: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message: "So you've come to the master for guidance? Is this what you're saying, grasshopper?",
      timestamp: 1655390160000,
    },
    c4: {
      associated_sender_key: 'jh',
      associated_sender_name: 'Jim Halpert',
      message: 'Actually, you called me in here, but yeah.',
      timestamp: 1655390192000,
    },
    c5: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message: "All right, well, let me show you how it's done.",
      timestamp: 1655390205000,
    },
    c6: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message:
        "Yes, I'd like to speak to your office manager, please. Yes, hello! This is Michael Scott, I am the Regional Manager of Dunder Mifflin Paper Products. Just wanted to talk to you, manager-a-manager.",
      timestamp: 1655390213000,
    },
    c7: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message:
        "All right, done deal! Thank you very much, sir. You're a gentleman and a scholar. Oh, I'm sorry. Okay. I'm sorry. My mistake. (hangs up) That was a woman I was talking to. So. . .she had a very low voice. Probably a smoker. So. . .so that's the way it's done.",
      timestamp: 1655390221000,
    },

    c8: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message:
        "I've been in Dundler Mifflin for twelve years, the last four as regional manager. If you want to come through here, (opens the door to the main office) so we have the entire floor, so this is my kingdom, as far as the eye can see, ah this is our receptionist Pam.",
      timestamp: 1655397421000,
    },
    c9: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message: "Pam, Pam Pam! Pam Beesly. Pam has been with us for' for ever, right Pam?",
      timestamp: 1655397438000,
    },
    c10: {
      associated_sender_key: 'pb',
      associated_sender_name: 'Pam Beesly',
      message: "Well, I don't know!",
      timestamp: 1655397465000,
    },
    c11: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message: " If you think she's cute now you should have seen her a couple of years ago! (makes faces)",
      timestamp: 1655397483000,
    },
    c12: {
      associated_sender_key: 'pb',
      associated_sender_name: 'Pam Beesly',
      message: 'What?',
      timestamp: 1655397486000,
    },
    c13: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message: 'Any messages?',
      timestamp: 1655397515000,
    },
    c14: {
      associated_sender_key: 'pb',
      associated_sender_name: 'Pam Beesly',
      message: 'Yeah, just a fax (hands the fax to Michael).',
      timestamp: 1655397531000,
    },
    c15: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message: "Pam, this is from corporate. How many times have I told you that there's a special filing cabinet for things from corporate.",
      timestamp: 1655397541000,
    },
    c16: {
      associated_sender_key: 'pb',
      associated_sender_name: 'Pam Beesly',
      message: "You haven't told me!",
      timestamp: 1655397545000,
    },
    c17: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message: "It's called the wastepaper basket.",
      timestamp: 1655397571000,
    },
    c18: {
      associated_sender_key: 'ms',
      associated_sender_name: 'Michael Scott',
      message: 'Look at that! Look at that face!',
      timestamp: 1655397591000,
    },

    c19: {
      associated_sender_key: 'ds',
      associated_sender_name: 'Dwight Schrute',
      message: "He put my stuff in jello again. That's real professional, thanks. That's the third time and it wasn't funny the first time.",
      timestamp: 1655483991000,
    },
    c20: {
      associated_sender_key: 'ds',
      associated_sender_name: 'Dwight Schrute',
      message: "It's ok here, but people some how is taking advantage because it's so relaxed. I'm a volunteer sheriff's deputy on the week-ends",
      timestamp: 1655484171000,
    },
    c21: {
      associated_sender_key: 'ds',
      associated_sender_name: 'Dwight Schrute',
      message: "And you cannot screw around there. It's one of the rules.",
      timestamp: 1655484231000,
    },
  }
  const chatMessageSettings_Example: TsInterface_ChatMessageSettings = {
    associated_viewer_key: 'pb',
    // timestamp_last_read: 1655483991001
  }
  const chatThreads_Example: TsInterface_ChatThreads = {
    t1: {
      key: 't1',
      associated_member_keys: { user1: true, pb: true },
      associated_member_last_read: { user1: new Date(), pb: new Date() },
      associated_member_names: { user1: 'USER', pb: 'Pam Beesly' },
      associated_member_unread_count: { user1: 0, pb: 0 },
      associated_member_unread: { user1: false, pb: false },
      last_message_sender_key: 'pb',
      last_message_sender_name: 'Pam Beesly',
      last_message_text: "There's A Lot Of Beauty In Ordinary Things. Isn't That Kind Of The Point?",
      last_message_timestamp: new Date(),
      total_members: 2,
    },
    t2: {
      key: 't2',
      associated_member_keys: { user1: true, ds: true },
      associated_member_last_read: { user1: new Date(), ds: new Date() },
      associated_member_names: { user1: 'USER', ds: 'Dwight Shrute' },
      associated_member_unread_count: { user1: 1, ds: 0 },
      associated_member_unread: { user1: true, ds: false },
      last_message_sender_key: 'ds',
      last_message_sender_name: 'Dwight Shrute',
      last_message_text: "Whenever I'm about to do something, I think 'Would an idiot do that?",
      last_message_timestamp: new Date(),
      total_members: 2,
    },
    t3: {
      key: 't3',
      associated_member_keys: { user1: true, ms: true },
      associated_member_last_read: { user1: new Date(), ms: new Date() },
      associated_member_names: { user1: 'USER', ms: 'Michael Scott' },
      associated_member_unread_count: { user1: 1, ms: 0 },
      associated_member_unread: { user1: true, ms: false },
      last_message_sender_key: 'ms',
      last_message_sender_name: 'Michael Scott',
      last_message_text: 'The worst thing about prison was the dementors.',
      last_message_timestamp: new Date(),
      total_members: 2,
    },
    t4: {
      key: 't4',
      associated_member_keys: { user1: true, ms: true, rh: true },
      associated_member_last_read: { user1: new Date(), ms: new Date(), rh: new Date() },
      associated_member_names: { user1: 'USER', ms: 'Michael Scott', rh: 'Ryan Howard' },
      associated_member_unread_count: { user1: 0, ms: 0, rh: 0 },
      associated_member_unread: { user1: false, ms: false, rh: false },
      last_message_sender_key: 'rh',
      last_message_sender_name: 'Michael Scott',
      last_message_text:
        "Last year, Creed asked me how to set up a blog. Wanting to protect the world from being exposed to Creed's brain, I opened up a Word document on his computer and put an address at the top. I've read some of it. Even for the internet, it's… pretty shocking",
      last_message_timestamp: new Date(),
      total_members: 2,
    },
  }
  const threadSettings_Example: TsInterface_ChatThreadSettings = {
    associated_viewer_key: 'user1',
    include_create_thread: true,
    selected_thread_key: null,
  }
  const threadOnClick_Example: TsInterface_ChatThreadOnClick = (threadKey: string) => {}

  const chatInputSettings_Example: TsInterface_ChatInputSettings = {
    // TODO
  }
  const chatInputOnSubmit_Example: TsType_ChatInputOnSubmit = (message: string) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ success: true })
      }, 1000)
    })
  }

  const chatHeaderSettings_Example: TsInterface_ChatHeaderSettings = {
    associated_viewer_key: 'user1',
  }
  const chatThread_Example: TsInterface_ChatThread = chatThreads_Example.t4
  const chatMarkAsUnread_Example: TsInterface_ChatMarkAsUnread = (threadKey: string) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  }
  const chatRenameMessageThread_Example: TsInterface_ChatRenameMessageThread = (threadKey: string, threadName: string | null) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  }

  const documentationObject: TsInterface_ComponentDocumentation = {
    group_name: 'Chat',
    group_description: 'Components related to chat',
    components: {
      ChatMessagesDisplay: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">ChatMessagesDisplay</span>
          </Box>
        ),
        component_description: 'Basic text only chat component',
        component_example_jsx_string: ChatMessagesDisplayHtmlExample,
        component_jsx: (
          <Box>
            <ChatMessagesDisplay
              chatThread={chatThread_Example}
              chatMessages={chatMessages_Example}
              chatSettings={chatMessageSettings_Example}
            />
          </Box>
        ),
        component_props: {
          chatMessages: {
            propKey: 'chatMessages',
            propTypeOrInterface: 'TsInterface_ChatMessages',
            propDescription: 'Messages to render in chat',
            propExampleValue: chatMessages_Example,
          },
          chatSettings: {
            propKey: 'chatSettings',
            propTypeOrInterface: 'TsInterface_ChatMessageSettings',
            propDescription: 'Settings on how to render chat',
            propExampleValue: chatMessageSettings_Example,
          },
        },
        component_todo: {
          1: 'Dark Theme',
        },
      },
      ChatThreadsDisplay: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">ChatThreadsDisplay</span>
          </Box>
        ),
        component_description: 'List of chat threads with on click events',
        component_example_jsx_string: ChatThreadsDisplayHtmlExample,
        component_jsx: (
          <Box>
            <ChatThreadsDisplay
              chatThreads={chatThreads_Example}
              threadSettings={threadSettings_Example}
              threadOnClick={threadOnClick_Example}
            />
          </Box>
        ),
        component_props: {
          chatThreads: {
            propKey: 'chatThreads',
            propTypeOrInterface: 'TsInterface_ChatMessages',
            propDescription: 'Threads to render in list',
            propExampleValue: chatThreads_Example,
          },
          threadSettings: {
            propKey: 'threadSettings',
            propTypeOrInterface: 'TsInterface_ChatMessageSettings',
            propDescription: 'Settings on how to render thread list',
            propExampleValue: threadSettings_Example,
          },
          threadOnClick: {
            propKey: 'threadOnClick',
            propTypeOrInterface: 'TsInterface_ChatThreadOnClick',
            propDescription: 'Callback when clicking on a thread',
            propExampleValue: 'threadOnClick',
          },
        },
        component_todo: {
          1: 'Dark Theme',
        },
      },
      ChatMessageEntry: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">ChatMessageEntry</span>
          </Box>
        ),
        component_description: 'Text Entry for message threads',
        component_example_jsx_string: ChatMessageEntryHtmlExample,
        component_jsx: (
          <Box>
            <ChatMessageEntry
              chatInputOnSubmit={chatInputOnSubmit_Example}
              chatInputSettings={chatInputSettings_Example}
            />
          </Box>
        ),
        component_props: {
          chatInputOnSubmit: {
            propKey: 'chatInputOnSubmit',
            propTypeOrInterface: 'TsType_ChatInputOnSubmit',
            propDescription: 'Callback when submitting chat text entry (passes chat message)',
            propExampleValue: 'threadOnClick',
          },
          chatInputSettings: {
            propKey: 'chatInputSettings',
            propTypeOrInterface: 'TsInterface_ChatInputSettings',
            propDescription: 'Settings used to modify chat input (not fully implemented)',
            propExampleValue: 'threadOnClick',
          },
        },
        component_todo: {
          0: 'Use settings to set messsage text and send button icon / color?',
        },
      },
      ChatHeaderDisplay: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">ChatHeaderDisplay</span>
          </Box>
        ),
        component_description: 'Header for Messages',
        component_example_jsx_string: ChatHeaderDisplayHtmlExample,
        component_jsx: (
          <Box>
            <ChatHeaderDisplay
              chatHeaderSettings={chatHeaderSettings_Example}
              chatThread={chatThread_Example}
              chatMarkAsUnread={chatMarkAsUnread_Example}
              chatRenameMessageThread={chatRenameMessageThread_Example}
            />
          </Box>
        ),
        component_props: {
          chatHeaderSettings: {
            propKey: 'chatHeaderSettings',
            propTypeOrInterface: 'TsInterface_ChatHeaderSettings',
            propDescription: 'Settings that define the chat header',
            propExampleValue: chatHeaderSettings_Example,
          },
          chatThread: {
            propKey: 'chatThread',
            propTypeOrInterface: 'TsInterface_ChatThread',
            propDescription: 'Thread passed into chat',
            propExampleValue: chatThread_Example,
          },
          chatMarkAsUnread: {
            propKey: 'chatMarkAsUnread',
            propTypeOrInterface: 'TsInterface_ChatMarkAsUnread',
            propDescription: 'Callback when thread is marked as unread',
            propExampleValue: 'chatMarkAsUnread',
          },
          chatRenameMessageThread: {
            propKey: 'chatRenameMessageThread',
            propTypeOrInterface: 'TsInterface_ChatRenameMessageThread',
            propDescription: 'Callback to change thread name (prompt included in component)',
            propExampleValue: 'chatRenameMessageThread',
          },
        },
        component_todo: {
          0: 'Use settings to set messsage text and send button icon / color?',
        },
      },
    },
  }

  // Functions

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <ComponentDocumentation documentation={documentationObject} />
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

export const returnFormattedDatabaseKey = (str: string): string => {
  let lowerCaseStr = str.toLowerCase()
  let trimmedStr = lowerCaseStr.trim()
  let withUnderscores = trimmedStr.replace(/ /g, '_')
  let cleanedString = withUnderscores.replace(/[^a-z0-9_]/g, '')
  return cleanedString
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { useContext, useEffect, useReducer } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_MLEvaluationProceduresProdData_Document,
  DatabaseRef_MLEvaluationProceduresStagingData_Document,
  DatabaseRef_MLEvaluationProcedures_Collection,
  DatabaseRef_MLEvaluationProcedures_Document,
} from 'rfbp_aux/services/database_endpoints/machine_learning/evaluation_procedures'
import { DatabaseRef_MLModels_Collection } from 'rfbp_aux/services/database_endpoints/machine_learning/models'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { TableDatabase } from 'rfbp_core/components/table/table_database'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['MachineLearningIndexPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_ADD_CATEGORY: JSX.Element = 												<Trans>Add Category</Trans>
// const s_ADD_MODEL_CATEGORY: JSX.Element = 										<Trans>Add Model Category</Trans>
// const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_MODEL: JSX.Element = 			<Trans>Are you sure that you want to delete this model</Trans>
// const s_CATEGORY: JSX.Element = 													<Trans>Category</Trans>
// const s_DELETE_MODEL: JSX.Element = 												<Trans>Delete Model</Trans>
// const s_EDIT: JSX.Element = 														<Trans>Edit</Trans>
// const s_EDIT_MODEL: JSX.Element = 												<Trans>Edit Model</Trans>
// const s_ENDPOINT: JSX.Element = 													<Trans>Endpoint</Trans>
// const s_ENTER_A_NAME_FOR_THIS_CATEGORY: JSX.Element = 							<Trans>Enter a name for this category</Trans>
// const s_MODEL_INPUT_TYPE: JSX.Element = 											<Trans>Model Input Type</Trans>
// const s_MODEL_OUTPUT_TYPE: JSX.Element = 										<Trans>Model Output Type</Trans>
// const s_NEW_MODEL: JSX.Element = 												<Trans>New Model</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_PROCEDURE: JSX.Element = <Trans>Are you sure that you want to delete this procedure</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_PROCEDURE: JSX.Element = <Trans>Delete Procedure</Trans>
const s_EVALUATION_PROCEDURES: JSX.Element = <Trans>Evaluation Procedures</Trans>
const s_EVALUATION_PROCEDURE_NAME: JSX.Element = <Trans>Evaluation Procedure Name</Trans>
const s_ML_CONFIG: JSX.Element = <Trans>ML Config</Trans>
const s_MODELS: JSX.Element = <Trans>Models</Trans>
const s_MODEL_CATEGORIES: JSX.Element = <Trans>Model Categories</Trans>
const s_MODEL_NAME: JSX.Element = <Trans>Model Name</Trans>
const s_NEW_EVALUATION_PROCEDURE: JSX.Element = <Trans>New Evaluation Procedure</Trans>
const s_VIEW: JSX.Element = <Trans>View</Trans>
const se_ML_CONFIG: string = 'ML Config'
// { sort-end } - displayed text

// Forms
// const mlModelInputTypeOptions: TsInterface_UnspecifiedObject = {
// image_url: {
// 	key: "image_url",
// 	value: "Image URL"
// }
// }

// const mlModelOutputTypeOptions: TsInterface_UnspecifiedObject = {
// ml_confidence_categories: {
// 	key: "ml_confidence_categories",
// 	value: "ML Confidence Categories"
// }
// }

// const formInputs_NewMLModel: TsInterface_FormInputs = {
// name: {
// 	key: "name",
// 	label: s_MODEL_NAME,
// 	input_type: "text_basic",
// 	required: true,
// 	data_type: "string",
// },
// endpoint: {
// 	key: "endpoint",
// 	label: s_ENDPOINT,
// 	input_type: "text_basic",
// 	required: true,
// 	data_type: "string",
// },
// input_type: {
// 	key: "input_type",
// 	label: s_MODEL_INPUT_TYPE,
// 	input_type: "multiple_choice_radio",
// 	required: true,
// 	data_type: "string",
// 	options: objectToArray( mlModelInputTypeOptions )
// },
// output_type: {
// 	key: "output_type",
// 	label: s_MODEL_OUTPUT_TYPE,
// 	input_type: "multiple_choice_radio",
// 	required: true,
// 	data_type: "string",
// 	options: objectToArray( mlModelOutputTypeOptions )
// },
// categories: {
// 	data_type: "string",
// 	key: "categories",
// 	input_type: "custom_form_input_jsx",
// 	label: <></>,
// 	required: true,
// 	renderCustomFormInput: ( formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks ) => {
// 		let inputJSX = <></>
// 		if( formData != null && formData.output_type === "ml_confidence_categories"){
// 			// Helper Functions
// 			const removeStringFromArray = (arr: string[], string: string) => {
// 				// Filter out the string from the array
// 				const result = arr.filter((item) => item !== string);
// 				return result;
// 			}
// 			const removeDuplicates = (arr: string[]) => {
// 				// @ts-expect-error - TODO: reason for error
// 				return [...new Set(arr)];
// 			  }
// 			// JSX
// 			inputJSX =
// 			<Box className="tw-mb-2">
// 				<Typography variant="body1" className="tw-opacity-70">{ s_MODEL_CATEGORIES }</Typography>
// 				<Box className="tw-mt-2 tw-ml-8">
// 					{/* @ts-expect-error  - TODO: reason */}
// 					{ getProp( formData, "categories", [] ).sort().map(( category, index ) => (
// 						<Chip
// 							className="tw-mr-2 tw-mb-2"
// 							variant='filled'
// 							key={ index }
// 							color="info"
// 							label={ category }
// 							onDelete={ () => {
// 								console.log("DELETE")
// 								console.log(category)
// 								formInputChange( "categories", removeStringFromArray( getProp( formData, "categories", [] ), category ), true )
// 							}}
// 						/>
// 					))}
// 				</Box>
// 				<Box className="tw-mt-2 tw-ml-8">
// 					<Button variant="contained" color="success" onClick={ () => {
// 						formHooks.uc_setUserInterface_PromptDialogDisplay({
// 							display: true,
// 							prompt: {
// 								color: "info",
// 								confirm_text: s_ADD_CATEGORY,
// 								default_value: null,
// 								header: s_ADD_MODEL_CATEGORY,
// 								icon: <Icon icon="pen-to-square" type="solid" />,
// 								input_label: s_CATEGORY,
// 								input_type: "text",
// 								text: s_ENTER_A_NAME_FOR_THIS_CATEGORY,
// 								submit_callback: (  promptValue: string ) => {
// 									return new Promise( ( resolve, reject ) => {
// 										let newCategoriesArray = getProp( formData, "categories", [] )
// 										newCategoriesArray.push( promptValue )
// 										formInputChange( "categories", removeDuplicates(newCategoriesArray), true )
// 										resolve({success: true})
// 									})
// 								}
// 							}
// 						})
// 					}}>
// 						<Icon icon="circle-plus" className="tw-mr-2" />
// 						{ s_ADD_CATEGORY }
// 					</Button>
// 				</Box>
// 			</Box>
// 		}
// 		return inputJSX
// 	}
// },
// }

const formInputs_NewMLEvaluationProcedure: TsInterface_FormInputs = {
  name: {
    key: 'name',
    label: s_EVALUATION_PROCEDURE_NAME,
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
}

// Tables
const tableSettings_MLModels: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
}

const tableSettings_EvaluationProcedures: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
}

const tableColumns_MLModels: TsInterface_TableColumns = {
  name: TableCellBasic('name', s_MODEL_NAME, 'name'),
  // endpoint: TableCellBasic( "endpoint", s_ENDPOINT, "endpoint" ),
  // input_type: TableCellBasic( "input_type", s_MODEL_INPUT_TYPE, "input_type" ),
  // output_type: TableCellBasic( "output_type", s_MODEL_OUTPUT_TYPE, "output_type" ),
  // input_type: {
  // cell: {
  // 	cell_css: (
  // 		rowData: TsInterface_TableDataRow,
  // 		tableAdditionalData: TsInterface_TableAdditionalData
  // 	) => { return "" },
  // 	cell_jsx: (
  // 		rowData: TsInterface_TableDataRow,
  // 		tableAdditionalData: TsInterface_TableAdditionalData,
  // 		tableHooks: TsInterface_TableHooks
  // 	) => {
  // 		let formattedValue = getProp( rowData, "input_type", "" )
  // 		if(
  // 			formattedValue != null &&
  // 			formattedValue !== "" &&
  // 			mlModelInputTypeOptions != null &&
  // 			mlModelInputTypeOptions[ formattedValue ] != null &&
  // 			mlModelInputTypeOptions[ formattedValue ].value != null
  // 		){
  // 			formattedValue = mlModelInputTypeOptions[ formattedValue ].value
  // 		}
  // 		let cellJSX =
  // 		<Box>
  // 			{ formattedValue }
  // 		</Box>
  // 		return cellJSX
  // 	},
  // },
  // header: {
  // 	header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
  // 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_MODEL_INPUT_TYPE },
  // 	header_sort_by: null
  // }
  // },
  // output_type: {
  // cell: {
  // 	cell_css: (
  // 		rowData: TsInterface_TableDataRow,
  // 		tableAdditionalData: TsInterface_TableAdditionalData
  // 	) => { return "" },
  // 	cell_jsx: (
  // 		rowData: TsInterface_TableDataRow,
  // 		tableAdditionalData: TsInterface_TableAdditionalData,
  // 		tableHooks: TsInterface_TableHooks
  // 	) => {
  // 		let formattedValue = getProp( rowData, "output_type", "" )
  // 		if(
  // 			formattedValue != null &&
  // 			formattedValue !== "" &&
  // 			mlModelOutputTypeOptions != null &&
  // 			mlModelOutputTypeOptions[ formattedValue ] != null &&
  // 			mlModelOutputTypeOptions[ formattedValue ].value != null
  // 		){
  // 			formattedValue = mlModelOutputTypeOptions[ formattedValue ].value
  // 		}
  // 		let cellJSX =
  // 		<Box>
  // 			{ formattedValue }
  // 		</Box>
  // 		return cellJSX
  // 	},
  // },
  // header: {
  // 	header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
  // 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_MODEL_OUTPUT_TYPE },
  // 	header_sort_by: null
  // }
  // },
  categories: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let categories = getProp(rowData, 'categories', [])
        let cellJSX = <Box>{categories.sort().join(', ')}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_MODEL_CATEGORIES
      },
      header_sort_by: null,
    },
  },
}

const tableColumns_EvaluationProcedures: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: <Icon icon="magnifying-glass" />,
      label: s_VIEW,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.MLEvaluationProcedureViewPage.url(rowData.key as string))
      },
    },
    delete: {
      icon: <Icon icon="trash" />,
      label: s_DELETE,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: <>{s_DELETE_PROCEDURE}</>,
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: s_DELETE,
            text: <>{s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_PROCEDURE}</>,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_MLEvaluationProcedures_Document(res_GCK.clientKey, rowData.key as string), {
                      status: 'deleted',
                    })
                      .then((res_DSMD) => {
                        resolve({ success: true })
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      },
    },
  }),
  // name: TableCellBasic( "name", s_EVALUATION_PROCEDURE_NAME, "name" ),
  name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.MLEvaluationProcedureViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_EVALUATION_PROCEDURE_NAME
      },
      header_sort_by: null,
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'ml'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  // useEffect(() => {
  // 	document.title = se_ML_CONFIG
  // }, [ ])

  useEffect(() => {
    // copyModelsToClient( "ajhotrum1" ).then(( res_CMTC ) => {
    // 	console.log("DONE")
    // }).catch(( rej_CMTC ) => {
    // 	console.error("ERROR")
    // })
    // copyModelsToClient( "jmorrell1" ).then(( res_CMTC ) => {
    // 	console.log("DONE")
    // }).catch(( rej_CMTC ) => {
    // 	console.error("ERROR")
    // })
    // copyModelsToClient( "ccolson1" ).then(( res_CMTC ) => {
    // 	console.log("DONE")
    // }).catch(( rej_CMTC ) => {
    // 	console.error("ERROR")
    // })
    // copyModelsToClient( "etw_energy_test" ).then(( res_CMTC ) => {
    // 	console.log("DONE")
    // }).catch(( rej_CMTC ) => {
    // 	console.error("ERROR")
    // })
    // copyModelsToClient( "etw_energy" ).then(( res_CMTC ) => {
    // 	console.log("DONE")
    // }).catch(( rej_CMTC ) => {
    // 	console.error("ERROR")
    // })
    // let evalProcedureKey = "18wiggzFBZjkDDHdBrle" // Rooftop Conduit Run
    // let evalProcedureKey = "8EIe10jifGq7dbYkXgEK" // String Map Optimizer Sheet
    // let evalProcedureKey = "E2JIAXKLH26hL01smgMn" // Conduit Support Flashing
    // let evalProcedureKey = "KTEOiEJmtYgkcNCJaIL8" // Array Flashing Penetration
    // let evalProcedureKey = "P1UdFRzPCEaVyIKc1LCm" // Fall Pro Penetrations Flashed Sealed
    // let evalProcedureKey = "RenoLPxsrBf4wFJpTFhw" // System Grounding and Bonding EGC GEC Groundrod
    // let evalProcedureKey = "TKp7ssnJOs4rYG4KcQBY" // J-Box Conduit Entries
    // let evalProcedureKey = "TTxkFC90pQy6ZlcigP0O" // Racking Splicing Grounding Wideshot
    // let evalProcedureKey = "ZZvNrRDNLlWLQhN0qY85" // Safe to Start
    // let evalProcedureKey = "aJUoHd6gOyJRHvbBldUr" // BOS Exteriors
    // let evalProcedureKey = "deW7XZarT1DBs5lyleVf" // BOS Interiors
    // let evalProcedureKey = "duOjRX1mmdxPJ27mTQ22" // MLPE RSD
    // let evalProcedureKey = "eWgMkPC933Go2NbzGjOW" // Comm Wire Terminations
    // let evalProcedureKey = "etCni9Xs4glsPlfBQ8De" // Clamps Caps Complete Arrays
    // let evalProcedureKey = "gZk93QrjSNT0Z3KNgkRs" // Electrical PPE
    // let evalProcedureKey = "oLT3P9aATXGDbVDrCQpU" // Inverter Label
    // let evalProcedureKey = "qwNdh3AFRkq5zptJ2tmH" // Conduit Penetration Exiting Roof
    // let evalProcedureKey = "rjTPEO6QMoleuyfPKeFp" // Wire Management
    // let evalProcedureKey = "zbMkmONvXTtCwAlYB5sA" //Circuit Breakers
    // let evalProcedureKey = "DEMO_Array_Flashing_Penetration"
    // let evalProcedureKey = "DEMO_Array_Grounding"
    // let evalProcedureKey = "DEMO_Conduit_Support_Flashing"
    // let evalProcedureKey = "DEMO_Electrical_PPE"
    // let evalProcedureKey = "DEMO_Exteriors"
    // let evalProcedureKey = "DEMO_Inverter_Label"
    // let evalProcedureKey = "DEMO_Roof_Leaks"
    // copyEvaluationProcedureToClient( "ajhotrum1", evalProcedureKey, "etw_energy", evalProcedureKey, "" ).then(( res_CEPTC ) => {
    // 	console.log("DONE: " + evalProcedureKey)
    // }).catch(( rej_CEPTC ) => {
    // 	console.error("ERROR")
    // })
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const createNewEvaluationProcedure = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewMLEvaluationProcedure,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  formSubmittedData.status = 'active'
                  formSubmittedData.timestamp_created = new Date()
                  formSubmittedData.associated_creator_key = getProp(uc_RootData_ClientUser, 'key', null)
                  formSubmittedData.associated_creator_name = getProp(uc_RootData_ClientUser, 'name', null)
                  DatabaseAddDocument(DatabaseRef_MLEvaluationProcedures_Collection(res_GCK.clientKey), formSubmittedData, true)
                    .then((res_DAD) => {
                      let logicUpdateObject: TsInterface_UnspecifiedObject = {
                        logic_engine_version: 1,
                        individual_evaluation: {
                          logic: {},
                        },
                        aggregate_evaluations: {},
                        variables: {},
                        models: {},
                      }
                      let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_MLEvaluationProceduresStagingData_Document(res_GCK.clientKey, res_DAD.key),
                          data: logicUpdateObject,
                        },
                        {
                          type: 'setMerge',
                          ref: DatabaseRef_MLEvaluationProceduresProdData_Document(res_GCK.clientKey, res_DAD.key),
                          data: logicUpdateObject,
                        },
                      ]
                      DatabaseBatchUpdate(updateArray)
                        .then((res_DBU) => {
                          directAppNavigation(formHooks.un_routerNavigation, ApplicationPages.MLEvaluationProcedureViewPage.url(res_DAD.key as string))
                          resolve(res_DAD)
                        })
                        .catch((rej_DBU) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                          reject(rej_DBU)
                        })
                    })
                    .catch((rej_DAD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: s_NEW_EVALUATION_PROCEDURE,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="code-branch"
              className="robot"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_ModelTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_MLModels}
            tableDatabaseEndpoint={tableDatabaseEndpoint_MLModels}
            tableSettings={tableSettings_MLModels}
          />
        </Card>
      )
    } else {
      tabJSX = <></>
    }
    return tabJSX
  }

  const rJSX_EvaluationProceduresTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_EvaluationProcedures}
            tableDatabaseEndpoint={tableDatabaseEndpoint_EvaluationProcedures}
            tableSettings={tableSettings_EvaluationProcedures}
          />
        </Card>
      )
    } else {
      tabJSX = <></>
    }
    return tabJSX
  }

  const tableDatabaseEndpoint_MLModels = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 15)
    return generateDatabaseQuery(DatabaseRef_MLModels_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_EvaluationProcedures = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 15)
    return generateDatabaseQuery(
      DatabaseRef_MLEvaluationProcedures_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const rJSX_NewEvaluationProcedureButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        className="tw-mr-2"
        onClick={() => {
          createNewEvaluationProcedure()
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Evaluation Procedure')}
      </Button>
    )
  }

  const rJSX_NewModelButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        className="tw-mr-2"
        disabled={true}
        onClick={() => {}}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Model')}
      </Button>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_ML_CONFIG}
        pageKey={pageKey}
        content={
          <Box>
            <Box></Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Evaluation_Procedures',
                  tabButtons: [
                    {
                      fullJSX: rJSX_NewEvaluationProcedureButton(),
                      minJSX: rJSX_NewEvaluationProcedureButton(),
                      sizeCutoff: 0,
                    },
                  ],
                  tabHeader: s_EVALUATION_PROCEDURES,
                  tabContent: <Box>{rJSX_EvaluationProceduresTab()}</Box>,
                },
                {
                  tabUrlKey: 'Models',
                  tabHeader: s_MODELS,
                  tabButtons: [
                    {
                      fullJSX: rJSX_NewModelButton(),
                      minJSX: rJSX_NewModelButton(),
                      sizeCutoff: 0,
                    },
                  ],
                  tabContent: <Box>{rJSX_ModelTab()}</Box>,
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.MachineLearningIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: se_ML_CONFIG,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

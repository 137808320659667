//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material/'
import {
  TsInterface_DynamicAttributes,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormSettings,
  TsType_FormInputOptionArray,
  TsType_InputChangeCallback,
} from 'rfbp_core/components/form'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  formAdditionalData: TsInterface_FormAdditionalData
  formData: TsInterface_FormData
  formHooks: TsInterface_FormHooksObject
  formInput: TsInterface_FormInput
  formSettings: TsInterface_FormSettings
  inputChangeCallback: TsType_InputChangeCallback
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const MultipleChoiceRadio = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_formData: TsInterface_FormData = getProp(props, 'formData', {})
  let pr_formInput: TsInterface_FormInput = getProp(props, 'formInput', {})
  let pr_formSettings: TsInterface_FormSettings = getProp(props, 'formSettings', {})
  let pr_inputOptions: TsType_FormInputOptionArray = getProp(pr_formInput, 'options', [])
  let pr_inputChangeCallback: TsType_InputChangeCallback = props.inputChangeCallback

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions

  // Generate JSX

  // JSX Generation
  const rJSX_RequiredMarker = (): JSX.Element => {
    let returnJSX: JSX.Element = <></>
    if (pr_formInput['required'] === true) {
      returnJSX = <span className="">*</span>
    }
    return returnJSX
  }

  const rJSX_Component = (): JSX.Element => {
    // Dynamic Class Name
    let dynamicClassName = ''
    let dynamicOptionsClassName = ''
    // Dynamic Attributes
    let dynamicAttributes: TsInterface_DynamicAttributes = {}
    if (pr_formInput['required'] === true) {
      dynamicAttributes['required'] = true
    }
    if (
      pr_formInput['required'] === true &&
      pr_formSettings.highlight_missing === true &&
      (pr_formData == null || pr_formData[pr_formInput['key']] == null || pr_formData[pr_formInput['key']] === '')
    ) {
      // dynamicAttributes["error"] = true
    }
    if (pr_formInput['disabled'] === true) {
      dynamicAttributes['disabled'] = true
    }
    dynamicOptionsClassName += ' tw-ml-8'
    // Form Input JSX
    let componentJSX = (
      <Box className="tw-mt-2">
        <FormControl
          className={dynamicClassName}
          fullWidth
        >
          <FormLabel component="legend">
            {pr_formInput['label']}
            {rJSX_RequiredMarker()}
          </FormLabel>
          <RadioGroup
            className={dynamicOptionsClassName}
            id={pr_formInput['key']}
            name={pr_formInput['key']}
            onChange={(event, value) => {
              if (value != null) {
                pr_inputChangeCallback(pr_formInput.key, value, true)
              }
            }}
            value={pr_formData[pr_formInput['key']] || null}
            sx={{ display: 'block' }}
            {...dynamicAttributes}
          >
            {pr_inputOptions.map((option) => (
              <Box key={option['key']}>
                <FormControlLabel
                  sx={{ display: 'flex' }}
                  control={<Radio {...dynamicAttributes} />}
                  key={option['key']}
                  label={option['value']}
                  value={option['key']}
                  disabled={option['disabled']}
                />
              </Box>
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Switch, Tooltip, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ClientUser_Document } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

const apiEndpointPermissions = {
  contacts_read: { key: 'contacts_read', value: 'Contacts Read' },
  contacts_write: { key: 'contacts_write', value: 'Contacts Write' },
  projects_read: { key: 'projects_read', value: 'Projects Read' },
  projects_write: { key: 'projects_write', value: 'Projects Write' },
}

///////////////////////////////
// Functions
///////////////////////////////

const returnHardCodedLocationOptions = (clientKey: string): TsInterface_UnspecifiedObject => {
  let locationOptions: TsInterface_UnspecifiedObject = {}
  switch (clientKey) {
    case 'etw_energy':
      locationOptions = {
        peoria: {
          key: 'peoria',
          name: 'Peoria',
        },
        san_antonio: {
          key: 'san_antonio',
          name: 'San Antonio',
        },
      }
      break
    default:
    // Nothing
  }
  return locationOptions
}

///////////////////////////////
// Exports
///////////////////////////////

export const tableColumns_APIUsers: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('User'), 'name'),
  api_organization: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('API Organization')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // Edit Icon
        let editIconJSX = <></>
        if (rowData != null && rowData.api_access !== true) {
          editIconJSX = (
            <Tooltip
              title={rLIB('Edit API Organization')}
              placement="right"
            >
              <Box className="tw-inline-block">
                <Icon
                  icon="pen-to-square"
                  className="tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 tw-inline-block"
                  onClick={() => {
                    tableHooks.uc_setUserInterface_PromptDialogDisplay({
                      display: true,
                      prompt: {
                        color: 'success',
                        confirm_text: rLIB('Save'),
                        default_value: getProp(rowData, 'api_organization', null),
                        header: rLIB('Edit API Organization'),
                        icon: (
                          <Icon
                            icon="pen-to-square"
                            type="solid"
                          />
                        ),
                        input_label: rLIB('API Organization'),
                        input_type: 'text',
                        text: rLIB("Enter a name for this user's API Organization"),
                        submit_callback: (promptValue: string) => {
                          return new Promise((resolve, reject) => {
                            let updateObject = {
                              api_organization: promptValue,
                            }
                            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                                  .then((res_DSMD) => {
                                    resolve(res_DSMD)
                                  })
                                  .catch((rej_DSMD) => {
                                    console.error(rej_DSMD)
                                    reject(rej_DSMD)
                                  })
                              })
                              .catch((rej_GCK) => {
                                console.error(rej_GCK)
                                reject(rej_GCK)
                              })
                          })
                        },
                      },
                    })
                  }}
                />
              </Box>
            </Tooltip>
          )
        }
        // Full JSX
        if (rowData != null && rowData['api_organization'] != null) {
          cellJSX = (
            <Box>
              <Typography
                variant="body1"
                className="tw-mr-2 tw-inline-block"
              >
                {rowData['api_organization']}
              </Typography>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Typography
                variant="body1"
                className="tw-opacity-20 tw-italic tw-mr-2 tw-inline-block"
              >
                {rLIB('Missing')}
              </Typography>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  api_locations: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('GHL Location Access')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        const rJSX_Location = (location: TsInterface_UnspecifiedObject): JSX.Element => {
          let locationJSX = <></>
          if (rowData != null && rowData.api_access === true) {
            if (
              location != null &&
              location.key != null &&
              rowData != null &&
              rowData['api_locations'] != null &&
              getProp(rowData['api_locations'], location.key, false) === true
            ) {
              locationJSX = (
                <Box>
                  <Icon
                    icon="square-check"
                    sx={{ color: themeVariables.white }}
                    className="tw-mr-2 tw-opacity-30"
                  />
                  {location.name}
                </Box>
              )
            } else {
              locationJSX = (
                <Box className="tw-opacity-20">
                  <Icon
                    icon="square"
                    type="regular"
                    sx={{ color: themeVariables.white }}
                    className="tw-mr-2"
                  />
                  {location.name}
                </Box>
              )
            }
          } else {
            if (
              location != null &&
              location.key != null &&
              rowData != null &&
              rowData['api_locations'] != null &&
              getProp(rowData['api_locations'], location.key, false) === true
            ) {
              locationJSX = (
                <Box>
                  <Icon
                    icon="square-check"
                    sx={{ color: themeVariables.success_main }}
                    className="tw-mr-2 tw-cursor-pointer"
                    onClick={() => {
                      let updateObject = {
                        api_locations: {
                          [location.key]: false,
                        },
                      }
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                            .then((res_DSMD) => {
                              // Nothing
                            })
                            .catch((rej_DSMD) => {
                              console.error(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }}
                  />
                  {location.name}
                </Box>
              )
            } else {
              locationJSX = (
                <Box className="tw-opacity-20">
                  <Icon
                    icon="square"
                    type="regular"
                    sx={{ color: themeVariables.white }}
                    className="tw-mr-2 tw-cursor-pointer"
                    onClick={() => {
                      let updateObject = {
                        api_locations: {
                          [location.key]: true,
                        },
                      }
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                            .then((res_DSMD) => {
                              // Nothing
                            })
                            .catch((rej_DSMD) => {
                              console.error(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }}
                  />
                  {location.name}
                </Box>
              )
            }
          }
          return locationJSX
        }
        let locationOptions = returnHardCodedLocationOptions(tableHooks.uc_RootData_ClientKey as string)
        cellJSX = (
          <Box>
            {objectToArray(locationOptions)
              .sort(dynamicSort('name', 'asc'))
              .map((location: TsInterface_UnspecifiedObject, index: number) => (
                <Box key={index}>{rJSX_Location(location)}</Box>
              ))}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  api_access: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('API Access') as JSX.Element
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // const checkIfDisabled = (): boolean => {
        //   let disabled = false
        //   if (rowData == null || rowData.key == null || rowData.api_organization == null || rowData.api_organization === '') {
        //     disabled = true
        //   }
        //   let foundActiveLocation = false
        //   if (rowData != null && rowData['api_locations'] != null) {
        //     let apiLocations = getProp(rowData, 'api_locations', {})
        //     for (let loopApiLocationKey in apiLocations) {
        //       if (apiLocations[loopApiLocationKey] === true) {
        //         foundActiveLocation = true
        //       }
        //     }
        //   } else {
        //     disabled = true
        //   }
        //   if (foundActiveLocation === false) {
        //     disabled = true
        //   }
        //   return disabled
        // }
        cellJSX = (
          <Box>
            <Switch
              checked={rowData.api_access === true ? true : false}
              // disabled={checkIfDisabled()}
              color="info"
              onChange={(event, value) => {
                let updateObject: TsInterface_UnspecifiedObject = {}
                if (rowData.key != null && value === true) {
                  updateObject.api_access = true
                } else if (rowData.key != null && value === false) {
                  updateObject.api_access = false
                }
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        console.error(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  api_endpoint_permissions: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('API Endpoint Permissions')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        const rJSX_Permission = (permission: TsInterface_UnspecifiedObject): JSX.Element => {
          let permissionsJSX = <></>
          if (rowData != null && rowData.api_access === true) {
            apiEndpointPermissions

            if (
              permission != null &&
              permission.key != null &&
              rowData != null &&
              rowData['api_endpoint_permissions'] != null &&
              getProp(rowData['api_endpoint_permissions'], permission.key, false) === true
            ) {
              permissionsJSX = (
                <Box>
                  <Icon
                    icon="square-check"
                    sx={{ color: themeVariables.white }}
                    className="tw-mr-2 tw-opacity-30 tw-cursor-pointer"
                    onClick={() => {
                      let updateObject = {
                        api_endpoint_permissions: {
                          [permission.key]: false,
                        },
                      }
                      console.log(updateObject)
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                            .then((res_DSMD) => {
                              // Nothing
                            })
                            .catch((rej_DSMD) => {
                              console.error(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }}
                  />
                  {permission.value}
                </Box>
              )
            } else {
              permissionsJSX = (
                <Box className="tw-opacity-20">
                  <Icon
                    icon="square"
                    type="regular"
                    sx={{ color: themeVariables.white }}
                    className="tw-mr-2 tw-cursor-pointer"
                    onClick={() => {
                      let updateObject = {
                        api_endpoint_permissions: {
                          [permission.key]: true,
                        },
                      }
                      console.log(updateObject)
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                            .then((res_DSMD) => {
                              // Nothing
                            })
                            .catch((rej_DSMD) => {
                              console.error(rej_DSMD)
                            })
                        })
                        .catch((rej_GCK) => {
                          console.error(rej_GCK)
                        })
                    }}
                  />
                  {permission.value}
                </Box>
              )
            }
          }
          return permissionsJSX
        }
        cellJSX = (
          <Box>
            {objectToArray(apiEndpointPermissions)
              .sort(dynamicSort('value', 'asc'))
              .map((permission: TsInterface_UnspecifiedObject, index: number) => (
                <Box key={index}>{rJSX_Permission(permission)}</Box>
              ))}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  key: TableCellBasic('key', rLIB('UID'), 'key'),
  api_key: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('API Key Generated')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData.api_key != null) {
          cellJSX = (
            <Button
              size="small"
              variant="outlined"
              color="success"
            >
              {rLIB('API Key Generated')}
            </Button>
          )
        } else {
          cellJSX = (
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              className="tw-opacity-30"
            >
              {rLIB('API Key Not Generated')}
            </Button>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

export const tableSettings_APIUsers: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
}

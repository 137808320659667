///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, Button, Card } from '@mui/material'
import {
  DatabaseRef_ProjectContactLogs_Collection,
  DatabaseRef_ProjectContactLogs_Document,
  DatabaseRef_Project_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import {
  generateRandomString,
  getProp,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnRoundedDaysBetweenDates,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Forms
const formInputs_ContactLog: TsInterface_FormInputs = {
  contact_date: {
    key: 'contact_date',
    label: rLIB('Contact Date'),
    input_type: 'timestamp_datetime',
    required: true,
    data_type: 'string',
  },
  // contact_person: {
  // key: "contact_person",
  // label: rLIB("Person who talked to customer"),
  // input_type: "text_basic",
  // required: true,
  // data_type: "string",
  // },
  contact_type: {
    key: 'contact_type',
    label: rLIB('Contact Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: [
      { key: 'phone', value: rLIB('Phone') },
      { key: 'email', value: rLIB('Email') },
      { key: 'sms', value: rLIB('SMS') },
    ],
  },
  notes: {
    key: 'notes',
    label: rLIB('Notes'),
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },
}

const formSettings_ContactLog: TsInterface_FormSettings = {
  //
}

// Tables
const tableColumns_ContactLogs: TsInterface_TableColumns = {
  contact_date: TableCellTimestamp('contact_date', rLIB('Contact Date'), 'contact_date', 'D MMM YYYY - h:mm a', false),
  contact_person: TableCellBasic('contact_person', rLIB('Person who talked to customer'), 'contact_person'),
  contact_type: TableCellBasic('contact_type', rLIB('Contact Type'), 'contact_type'),
  // notes: TableCellBasic("notes", rLIB("Notes"), "notes"),
  notes: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box sx={{ maxWidth: '500px' }}>{rowData.notes}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Notes')
      },
      header_sort_by: 'notes',
    },
  },
}

const tableSettings_ContactLogs: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'contact_date',
  use_live_data: true,
  conditional_row_styles: [],
}

///////////////////////////////
// Functions
///////////////////////////////

const tableDatabaseEndpoint_ContactLogs = (
  queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
  tableAdditionalData: TsInterface_TableAdditionalData,
) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'contact_date', desc: true }]
  let queryCursorsObject: TsInterface_QueryCursorsObject = {}
  if (queryGenerationData['startAfter'] != null) {
    queryCursorsObject['startAfter'] = queryGenerationData.startAfter
  }
  if (queryGenerationData['startAt'] != null) {
    queryCursorsObject['startAt'] = queryGenerationData.startAt
  }
  if (queryGenerationData['endAt'] != null) {
    queryCursorsObject['endAt'] = queryGenerationData.endAt
  }
  if (queryGenerationData['endBefore'] != null) {
    queryCursorsObject['endBefore'] = queryGenerationData.endBefore
  }
  let limit = getProp(queryGenerationData, 'limit', 25)
  return generateDatabaseQuery(
    DatabaseRef_ProjectContactLogs_Collection(tableAdditionalData.uc_RootData_ClientKey as string, tableAdditionalData.projectKey),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}

///////////////////////////////
// JSX
///////////////////////////////

const rJSX_CreateContactLogButton = (
  readOrWrite: 'read' | 'write',
  projectKey: any,
  rootProject: any,
  uc_RootData_ClientUser: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
): JSX.Element => {
  let buttonJSX = (
    <Button
      color="success"
      variant="contained"
      startIcon={<Icon icon="circle-plus" />}
      disabled={readOrWrite === 'read'}
      onClick={() => {
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {},
              formData: {
                contact_date: returnFormattedDate(new Date(), 'YYYY-MM-DDTHH:mm'),
              },
              formInputs: formInputs_ContactLog,
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: formSettings_ContactLog,
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let logUpdateObject = formSubmittedData
                      let contactLogKey = new Date().getTime() + '_' + generateRandomString(6, null)
                      logUpdateObject['contact_person'] = getProp(uc_RootData_ClientUser, 'name', '')
                      logUpdateObject['contact_date'] = new Date(logUpdateObject['contact_date'])
                      logUpdateObject['key'] = contactLogKey
                      let projectUpdateObject = {
                        timestamp_last_contact_log: logUpdateObject['contact_date'],
                        last_contact_log_person: logUpdateObject['contact_person'],
                        last_contact_log_type: logUpdateObject['contact_type'],
                        last_contact_log_notes: logUpdateObject['notes'],
                      }
                      if (
                        rootProject != null &&
                        rootProject['timestamp_last_contact_log'] != null &&
                        returnDateFromUnknownDateFormat(rootProject['timestamp_last_contact_log']).getTime() > logUpdateObject['contact_date'].getTime()
                      ) {
                        DatabaseAddDocument(DatabaseRef_ProjectContactLogs_Collection(res_GCK.clientKey, projectKey), formSubmittedData, true)
                          .then((res_DAD) => {
                            resolve(res_DAD)
                          })
                          .catch((rej_DAD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                            reject(rej_DAD)
                          })
                      } else {
                        let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                          {
                            type: 'setMerge',
                            ref: DatabaseRef_ProjectContactLogs_Document(res_GCK.clientKey, projectKey, contactLogKey),
                            data: logUpdateObject,
                          },
                          { type: 'setMerge', ref: DatabaseRef_Project_Document(res_GCK.clientKey, projectKey), data: projectUpdateObject },
                        ]
                        DatabaseBatchUpdate(updateArray)
                          .then((res_DBU) => {
                            resolve(res_DBU)
                          })
                          .catch((rej_DBU) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                            reject(rej_DBU)
                          })
                      }
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: rLIB('New contact log'),
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      }}
    >
      {rLIB('Add Contact Log')}
    </Button>
  )
  return buttonJSX
}

///////////////////////////////
// JSX Exports
///////////////////////////////

export const rJSX_ContactLogsTabHeader = (rootProject: any): JSX.Element => {
  let tabHeaderJSX = <>{rLIB('Contact Log')}</>
  let currentTime = new Date()
  if (rootProject != null && getProp(rootProject, 'timestamp_last_contact_log', null) != null) {
    if (returnRoundedDaysBetweenDates(returnDateFromUnknownDateFormat(getProp(rootProject, 'timestamp_last_contact_log', null)), currentTime) >= 12) {
      tabHeaderJSX = (
        <Box>
          <Badge
            badgeContent={<Icon icon="phone" />}
            color="error"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <>{rLIB('Contact Log')}</>
          </Badge>
        </Box>
      )
    } else if (returnRoundedDaysBetweenDates(returnDateFromUnknownDateFormat(getProp(rootProject, 'timestamp_last_contact_log', null)), currentTime) >= 7) {
      tabHeaderJSX = (
        <Box>
          <Badge
            badgeContent={<Icon icon="phone" />}
            color="warning"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <>{rLIB('Contact Log')}</>
          </Badge>
        </Box>
      )
    }
  }
  return tabHeaderJSX
}

export const rJSX_ContactLogTab = (
  readOrWrite: 'read' | 'write',
  uc_RootData_ClientKey: any,
  uc_RootData_ClientUser: any,
  projectKey: any,
  rootProject: any,
  uc_setRootData_ClientKey: any,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_FormDialogDisplay: any,
): JSX.Element => {
  let tabJSX = <></>
  const rJSX_LastContactDate = (): JSX.Element => {
    let contactDateJSX = <></>
    if (getProp(rootProject, 'timestamp_last_contact_log', null) != null) {
      contactDateJSX = (
        <Button
          className="tw-ml-2"
          color="inherit"
          variant="outlined"
          startIcon={
            <Icon
              icon="calendar"
              type="light"
            ></Icon>
          }
          disabled={true}
        >
          <>
            {rLIB('Last Contact Date')}: {returnFormattedDate(getProp(rootProject, 'timestamp_last_contact_log', null), 'D MMM YYYY')}
          </>
        </Button>
      )
    }
    return contactDateJSX
  }
  tabJSX = (
    <Box>
      <Box className="tw-text-left tw-mb-2 tw-mt-2">
        {rJSX_CreateContactLogButton(
          readOrWrite,
          projectKey,
          rootProject,
          uc_RootData_ClientUser,
          uc_RootData_ClientKey,
          uc_setRootData_ClientKey,
          uc_setUserInterface_FormDialogDisplay,
          uc_setUserInterface_ErrorDialogDisplay,
        )}
        {rJSX_LastContactDate()}
      </Box>
      <Card>
        <TableDatabase
          tableAdditionalData={{
            uc_RootData_ClientKey: uc_RootData_ClientKey,
            projectKey: projectKey,
          }}
          tableColumns={tableColumns_ContactLogs}
          tableDatabaseEndpoint={tableDatabaseEndpoint_ContactLogs}
          tableSettings={tableSettings_ContactLogs}
        />
      </Card>
    </Box>
  )
  return tabJSX
}

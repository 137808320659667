///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const onClickAppNavigation = (event: any, un_routerNavigation: any, url: string) => {
  if (event.metaKey === true || event.ctrlKey) {
    let fullUrl = window.location.origin + url
    window.open(fullUrl, '_blank')
  } else {
    un_routerNavigation(url)
  }
}

export const directAppNavigation = (un_routerNavigation: any, url: string) => {
  un_routerNavigation(url)
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { DatabaseRef_Feedback_Collection } from 'rfbp_aux/services/database_endpoints/operations/feedback'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_RootData_GlobalUser } from 'rfbp_core/services/context'
import { DatabaseAddDocument } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

export const formSettings_FeedbackNew: TsInterface_FormSettings = {}

export const formInputs_FeedbackNew: TsInterface_FormInputs = {
  // associated_user_key
  // status: unread
  // timestamp_created
  timestamp_created: {
    key: 'timestamp_created',
    label: rLIB('Feedback Date'),
    input_type: 'timestamp_datetime',
    required: true,
    data_type: 'number',
  },

  associated_user_name: {
    key: 'associated_user_name',
    label: rLIB('Person giving Feedback'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  feedback_topic: {
    key: 'feedback_topic',
    label: rLIB('Feedback Topic'),
    input_type: 'multiple_choice_radio',
    required: false,
    data_type: 'string',
    options: [
      { key: 'Support', value: 'Support' },
      { key: 'Software', value: 'Software' },
      { key: 'Other', value: 'Other' },
    ],
  },
  feedback_rating: {
    key: 'feedback_rating',
    label: rLIB('Feedback Rating'),
    input_type: 'multiple_choice_radio', // TODO - custom NPS instead
    required: true,
    data_type: 'number',
    options: [
      { key: 0, value: 0 },
      { key: 1, value: 1 },
      { key: 2, value: 2 },
      { key: 3, value: 3 },
      { key: 4, value: 4 },
      { key: 5, value: 5 },
      { key: 6, value: 6 },
      { key: 7, value: 7 },
      { key: 8, value: 8 },
      { key: 9, value: 9 },
      { key: 10, value: 10 },
    ],
  },
  feedback_notes: {
    key: 'feedback_notes',
    label: rLIB('Feedback Notes'),
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },
}

export const createCustomerFeedback = (
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_GlobalUser: TsInterface_RootData_GlobalUser,
  projectKey: string | null,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
) => {
  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: { associated_user_key: uc_RootData_GlobalUser.key },
        formData: {},
        formInputs: formInputs_FeedbackNew,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: formSettings_FeedbackNew,
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateObject = formSubmittedData
                updateObject['associated_user_key'] = formAdditionalData.associated_user_key
                updateObject['associated_project_key'] = projectKey
                updateObject['timestamp_created'] = new Date(updateObject.timestamp_created)
                DatabaseAddDocument(DatabaseRef_Feedback_Collection(res_GCK.clientKey), updateObject, true)
                  .then((res_DAD) => {
                    resolve(res_DAD)
                  })
                  .catch((rej_DAD) => {
                    reject(rej_DAD)
                  })
              })
              .catch((rej_GCK) => {
                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: rLIB('Create New Feedback'),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

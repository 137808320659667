import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

export const DatabaseRef_SalesOpportunities_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'main')
}

export const DatabaseRef_SalesOpportunity_Document = (clientKey: string, opportunityKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'main', opportunityKey)
}

export const DatabaseRef_SalesEstimates_Collection = (clientKey: string, opportunityKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'main', opportunityKey, 'estimates')
}

export const DatabaseRef_SalesEstimate_Document = (clientKey: string, opportunityKey: string, estimateKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'main', opportunityKey, 'estimates', estimateKey)
}

export const DatabaseRef_SalesOpportunity_PageAnalyticsSessions_Collection = (clientKey: string, opportunityKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'main', opportunityKey, 'sessions')
}

// Finder Sessions
export const DatabaseRef_SalesOpportunityDiscoverySession_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'discovery')
}

export const DatabaseRef_SalesOpportunityDiscoverySession_Document = (clientKey: string, sessionKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'discovery', sessionKey)
}

export const DatabaseRef_SalesOpportunityDiscoverySession_Addresses_Collection = (clientKey: string, sessionKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'discovery', sessionKey, 'addresses')
}

export const DatabaseRef_SalesOpportunityDiscoverySession_Address_Document = (
  clientKey: string,
  sessionKey: string,
  addressKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'discovery', sessionKey, 'addresses', addressKey)
}

export const DatabaseRef_SalesOpportunityDiscoverySession_AddressGoogleSolarData_Collection = (
  clientKey: string,
  sessionKey: string,
): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'discovery', sessionKey, 'address_google_solar_data')
}

export const DatabaseRef_SalesOpportunityDiscoverySession_AddressGoogleSolarData_Document = (
  clientKey: string,
  sessionKey: string,
  addressKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'discovery', sessionKey, 'address_google_solar_data', addressKey)
}

export const DatabaseRef_UnprotectedOpportunityUpdateTime_Document = (opportunityKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'global', 'unprotected_opportunity_update_time', 'main', opportunityKey)
}

export const DatabaseRef_LightreachTables_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'sales', 'lightreach_tables', 'main')
}

export const DatabaseRef_LightreachTable_Document = (clientKey: string, state: string, productionRatio: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'lightreach_tables', 'main', state, 'production_ratio', productionRatio)
}

export const DatabaseRef_SalesOpportunity_DiscoverySession_Query = (
  clientKey: string,
  queryOperatorsArray: TsInterface_QueryOperatorsArray,
  limit: number | null,
) => {
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'main'), queryOperatorsArray, [], {}, limit)
}

export const DatabaseRef_GoogleSolarData_Document = (clientKey: string, opportunityKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'google_solar_data', 'main', opportunityKey)
}

export const DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_Document = (
  clientKey: string,
  sessionKey: string,
  routeOptimizationKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'sales', 'opportunities', 'discovery', sessionKey, 'route_optimization', routeOptimizationKey)
}

export const DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_VisitCompletion_Document = (
  clientKey: string,
  sessionKey: string,
  routeOptimizationKey: string,
  routeKey: string,
): DocumentReference<DocumentData> => {
  return doc(
    getFirestore(),
    'clients',
    clientKey,
    'sales',
    'opportunities',
    'discovery',
    sessionKey,
    'route_optimization',
    routeOptimizationKey,
    'completion',
    routeKey,
  )
}

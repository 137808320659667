///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_SpendingLimits_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'spending_limits', 'main')
}

export const DatabaseRef_Approvals_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'purchase_approvals', 'main')
}

export const DatabaseRef_Approvals_Document = (clientKey: string, approvalKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'purchase_approvals', 'main', approvalKey)
}

export const DatabaseRef_Transactions_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  // TODO - This is a placeholder, need to update this to use Stripe API
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'transactions', 'main')
}

import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

export const formInputs_NewVehicle: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('Vehicle Name'),
    required: true,
  },
  vin: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'vin',
    label: rLIB('Vin'),
    required: true,
  },
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import Masonry from '@mui/lab/Masonry'
import { Box, Button, Card, Divider, Typography } from '@mui/material/'
import { returnProjectDesignPreferences } from 'app/models/projects/project_design_preferences'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_OpenSearchProjects_Query,
  DatabaseRef_ProjectDesignPreferences_Document,
  DatabaseRef_ProjectLogs_Document,
  DatabaseRef_ProjectTaskWorkflow_Document,
  DatabaseRef_Project_Document,
  DatabaseRef_ProtoProjectDesignPreferences_Document,
  DatabaseRef_ProtoProject_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  DatabaseRef_AllProjectTasks_Query,
  DatabaseRef_AllProtoProjectTasks_Query,
  DatabaseRef_ProjectTasksForPrerequisiteTask_Query,
  DatabaseRef_Task_Document,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString } from 'rfbp_core/components/search'
import { TsInterface_TabsSettingsBasic } from 'rfbp_core/components/tabs'
import { TabsBasic } from 'rfbp_core/components/tabs/tabs_basic'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
  TsInterface_PromptDialogObject,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { cloneObjectWithoutReference, generateRandomString, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminActiveProjectMergePage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_MERGE_THESE_PROJECTS: JSX.Element = <Trans>Are you sure that you want to merge these projects?</Trans>
const s_BACK_TO_ALL_ACTIVE_PROJECTS: JSX.Element = <Trans>Back to all active projects</Trans>
const s_CITY: JSX.Element = <Trans>City</Trans>
const s_CONFLICT_BETWEEN_SCHEDULED_SITE_AUDIT_TIMES_BETWEEN_PROJECTS: JSX.Element = <Trans>Conflict between scheduled site audit times between projects</Trans>
const s_CUSTOMER_EMAIL: JSX.Element = <Trans>Customer Email</Trans>
const s_CUSTOMER_NAME: JSX.Element = <Trans>Customer Name</Trans>
const s_CUSTOMER_PHONE: JSX.Element = <Trans>Customer Phone</Trans>
const s_DESIGN_PREFERENCES: JSX.Element = <Trans>Design Preferences</Trans>
const s_ENTER_THE_PROJECTS_UNIQUE_IDENTIFIER: JSX.Element = <Trans>Enter the projects unique identifier</Trans>
const s_FAILED_TO_MERGE_PROJECTS: JSX.Element = <Trans>Failed to merge projects</Trans>
const s_FIND_PROJECT_TO_MERGE: JSX.Element = <Trans>Find Project to merge</Trans>
const s_LOCATION: JSX.Element = <Trans>Location</Trans>
const s_MERGE: JSX.Element = <Trans>Merge</Trans>
const s_MERGE_PROJECT: JSX.Element = <Trans>Merge Project</Trans>
const s_MERGE_PROTO_PROJECT_WITH_PROJECT: JSX.Element = <Trans>Merge Proto Project with Project</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_MISSING_REQUIRED_KEYS_OR_TASKS: JSX.Element = <Trans>Missing required keys or tasks</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing required parameters</Trans>
const s_NO_MATCHES: JSX.Element = <Trans>No Matches</Trans>
const s_POTENTIAL_PROJECT_MATCHES: JSX.Element = <Trans>Potential Project Matches</Trans>
const s_PROJECT_ALREADY_MERGED_WITH_ANOTHER_PROTO_PROJECT: JSX.Element = <Trans>Project already merged with another proto project</Trans>
const s_PROJECT_LINKED: JSX.Element = <Trans>Project Linked</Trans>
const s_PROJECT_PROSPECT_ID: JSX.Element = <Trans>Project prospect ID</Trans>
const s_PROTO_PROJECT: JSX.Element = <Trans>Proto Project</Trans>
const s_PROTO_PROJECT_DETAILS: JSX.Element = <Trans>Proto Project Details</Trans>
const s_SALES_REP: JSX.Element = <Trans>Sales Rep</Trans>
const s_SEARCH: JSX.Element = <Trans>Search</Trans>
const s_SEARCH_FOR_SPECIFIC_PROJECT: JSX.Element = <Trans>Search for specific project</Trans>
const s_STATE: JSX.Element = <Trans>State</Trans>
const s_THE_PROJECT_TO_MERGE_INTO_NEEDS_A_WORKFLOW_TO_PROCEED: JSX.Element = <Trans>The project to merge into needs a workflow to proceed</Trans>
const s_UNEXPECTED_DATA_FORMAT_PLEASE_CONTACT_SOFTWARE_SUPPORT: JSX.Element = <Trans>Unexpected data format. Please contact software support</Trans>
const s_VIEW_LINKED_PROJECT: JSX.Element = <Trans>View Linked Project</Trans>
const se_MERGE_PROTO_PROJECT = 'Merge Proto Project'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

const mergeProtoAndFullProjects = (
  userKey: string,
  userName: string,
  clientKey: string,
  projectKey: string,
  pr_protoProjectKey: string,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    if (clientKey != null && projectKey != null && pr_protoProjectKey != null) {
      // Instantiate Variables
      let promiseArray1: TsType_UnknownPromise[] = []
      let promiseArray2: TsType_UnknownPromise[] = []
      let project: TsInterface_UnspecifiedObject = {}
      // let projectDesignPreferences: TsInterface_UnspecifiedObject = {}
      let projectTasks: TsInterface_UnspecifiedObject = {}
      let projectWorkflow: TsInterface_UnspecifiedObject = {}
      let protoProject: TsInterface_UnspecifiedObject = {}
      let protoProjectDesignPreferences: TsInterface_UnspecifiedObject = {}
      let protoProjectTasks: TsInterface_UnspecifiedObject = {}
      let completionTimestamp = new Date()
      // Load Data - Projects
      promiseArray1.push(
        DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, projectKey))
          .then((res_DGD) => {
            project = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
      // promiseArray1.push( DatabaseGetDocument( DatabaseRef_ProjectDesignPreferences_Document( clientKey, projectKey ) ).then(( res_DGD ) => {
      // 	projectDesignPreferences = res_DGD.data
      // }).catch(( rej_DGD ) => {
      // 	console.error( rej_DGD )
      // }) )
      promiseArray1.push(
        DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(clientKey, projectKey))
          .then((res_DGD) => {
            projectWorkflow = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
      promiseArray1.push(
        DatabaseGetCollection(DatabaseRef_AllProjectTasks_Query(clientKey, projectKey))
          .then((res_DGC) => {
            projectTasks = res_DGC.data
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          }),
      )
      // Load Data - Proto Projects
      promiseArray1.push(
        DatabaseGetDocument(DatabaseRef_ProtoProject_Document(clientKey, pr_protoProjectKey))
          .then((res_DGD) => {
            protoProject = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
      promiseArray1.push(
        DatabaseGetDocument(DatabaseRef_ProtoProjectDesignPreferences_Document(clientKey, pr_protoProjectKey))
          .then((res_DGD) => {
            protoProjectDesignPreferences = res_DGD.data
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          }),
      )
      promiseArray1.push(
        DatabaseGetCollection(DatabaseRef_AllProtoProjectTasks_Query(clientKey, pr_protoProjectKey))
          .then((res_DGC) => {
            protoProjectTasks = res_DGC.data
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          }),
      )
      // After Data Loaded
      Promise.all(promiseArray1).finally(() => {
        // Get Project Site Audit Tasks
        let protoScheduleSiteAuditTaskKey: null | string = null
        let protoCompleteSiteAuditTaskKey: null | string = null
        let projectScheduleSiteAuditTaskBaseKey: null | string = null
        let projectScheduleSiteAuditTaskKey: null | string = null
        let projectCompleteSiteAuditTaskKey: null | string = null
        if (
          projectWorkflow != null &&
          projectWorkflow.config != null &&
          projectWorkflow.config.site_audit != null &&
          projectWorkflow.config.site_audit.associated_dispatcher_task_key != null &&
          projectTasks != null &&
          projectTasks[projectKey + '_' + projectWorkflow.config.site_audit.associated_dispatcher_task_key] != null
        ) {
          projectScheduleSiteAuditTaskKey = projectKey + '_' + projectWorkflow.config.site_audit.associated_dispatcher_task_key
          projectScheduleSiteAuditTaskBaseKey = projectWorkflow.config.site_audit.associated_dispatcher_task_key
        }
        if (
          projectScheduleSiteAuditTaskBaseKey != null &&
          projectWorkflow != null &&
          projectWorkflow['tasks'] != null &&
          projectWorkflow['tasks'][projectScheduleSiteAuditTaskBaseKey] != null &&
          projectWorkflow['tasks'][projectScheduleSiteAuditTaskBaseKey]['associated_dispatched_task_key'] != null &&
          projectTasks != null &&
          projectTasks[projectWorkflow['tasks'][projectScheduleSiteAuditTaskBaseKey]['associated_dispatched_task_key']] != null
        ) {
          projectCompleteSiteAuditTaskKey = projectWorkflow['tasks'][projectScheduleSiteAuditTaskBaseKey]['associated_dispatched_task_key']
        }
        if (
          protoProject != null &&
          protoProject.key != null &&
          protoProjectTasks != null &&
          protoProjectTasks[protoProject.key + '_schedule_site_audit'] != null
        ) {
          protoScheduleSiteAuditTaskKey = protoProject.key + '_schedule_site_audit'
        }
        if (
          protoProject != null &&
          protoProject.key != null &&
          protoProjectTasks != null &&
          protoProjectTasks != null &&
          protoProjectTasks[protoProject.key + '_complete_site_audit'] != null
        ) {
          protoCompleteSiteAuditTaskKey = protoProject.key + '_complete_site_audit'
        }
        if (project.associated_proto_project_key != null) {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_MERGE_PROJECTS,
              details: s_PROJECT_ALREADY_MERGED_WITH_ANOTHER_PROTO_PROJECT,
              code: 'ER-D-APM-MPAFP-01',
            },
          })
        } else if (project.associated_task_workflow_key == null) {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_MERGE_PROJECTS,
              details: s_THE_PROJECT_TO_MERGE_INTO_NEEDS_A_WORKFLOW_TO_PROCEED,
              code: 'ER-D-APM-MPAFP-01',
            },
          })
        } else if (protoProjectTasks == null || objectToArray(protoProjectTasks).length !== 2) {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_MERGE_PROJECTS,
              details: s_UNEXPECTED_DATA_FORMAT_PLEASE_CONTACT_SOFTWARE_SUPPORT,
              code: 'ER-D-APM-MPAFP-01',
            },
          })
        } else if (
          protoScheduleSiteAuditTaskKey == null ||
          protoCompleteSiteAuditTaskKey == null ||
          projectScheduleSiteAuditTaskKey == null ||
          projectCompleteSiteAuditTaskKey == null ||
          projectTasks[projectScheduleSiteAuditTaskKey] == null ||
          projectTasks[projectCompleteSiteAuditTaskKey] == null ||
          protoProjectTasks[protoScheduleSiteAuditTaskKey] == null ||
          protoProjectTasks[protoCompleteSiteAuditTaskKey] == null
        ) {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_MERGE_PROJECTS,
              details: s_MISSING_REQUIRED_KEYS_OR_TASKS,
              code: 'ER-D-APM-MPAFP-01',
            },
          })
        } else if (projectTasks[projectScheduleSiteAuditTaskKey] != null && projectTasks[projectScheduleSiteAuditTaskKey]['status'] === 'completed') {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_MERGE_PROJECTS,
              details: s_CONFLICT_BETWEEN_SCHEDULED_SITE_AUDIT_TIMES_BETWEEN_PROJECTS,
              code: 'ER-D-APM-MPAFP-01',
            },
          })
        } else {
          let downstreamScheduledTasks: TsInterface_UnspecifiedObject = {}
          promiseArray2.push(
            DatabaseGetCollection(DatabaseRef_ProjectTasksForPrerequisiteTask_Query(clientKey, projectScheduleSiteAuditTaskKey))
              .then((res_DGC) => {
                downstreamScheduledTasks = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
          // After downstream tasks loaded
          Promise.all(promiseArray2).finally(() => {
            // Generate Update Object for batch update
            let project_UpdateObject: TsInterface_UnspecifiedObject = {
              associated_proto_project_key: pr_protoProjectKey,
              // TODO - Other Merged Fields?
            }
            let projectDesignPreferences_UpdateObject: TsInterface_UnspecifiedObject = protoProjectDesignPreferences
            let protoProject_UpdateObject = {
              associated_project_key: projectKey,
              status: 'archived',
            }
            let protoProject_SiteAuditScheduleTask_UpdateObject: TsInterface_UnspecifiedObject = {
              status: 'deleted',
              status_complete: true,
              timestamp_last_updated: new Date(),
            }
            let protoProject_SiteAuditCompleteTask_UpdateObject: TsInterface_UnspecifiedObject = {
              status: 'deleted',
              status_complete: true,
              task_completion_scheduled_dates: [],
              timestamp_last_updated: new Date(),
            }
            // Schedule Site Audit Update Object
            let project_SiteAuditScheduleTask_UpdateObject: TsInterface_UnspecifiedObject = {
              timestamp_last_updated: new Date(),
            }
            let protoScheduleSiteAuditTask = protoProjectTasks[protoScheduleSiteAuditTaskKey as string]
            if (protoScheduleSiteAuditTask['timestamp_completed'] != null) {
              project_SiteAuditScheduleTask_UpdateObject['timestamp_completed'] = protoScheduleSiteAuditTask['timestamp_completed']
            }
            if (protoScheduleSiteAuditTask['timestamp_completed_by_key'] != null) {
              project_SiteAuditScheduleTask_UpdateObject['timestamp_completed_by_key'] = protoScheduleSiteAuditTask['timestamp_completed_by_key']
            }
            if (protoScheduleSiteAuditTask['timestamp_completed_by_name'] != null) {
              project_SiteAuditScheduleTask_UpdateObject['timestamp_completed_by_name'] = protoScheduleSiteAuditTask['timestamp_completed_by_name']
            }
            if (protoScheduleSiteAuditTask['status_complete'] != null) {
              project_SiteAuditScheduleTask_UpdateObject['status_complete'] = protoScheduleSiteAuditTask['status_complete']
            }
            if (protoScheduleSiteAuditTask['status'] != null) {
              project_SiteAuditScheduleTask_UpdateObject['status'] = protoScheduleSiteAuditTask['status']
            }
            // Complete Site Audit Update Object
            let project_SiteAuditCompleteTask_UpdateObject: TsInterface_UnspecifiedObject = {
              associated_dispatcher_task_key: projectScheduleSiteAuditTaskKey,
              timestamp_last_updated: new Date(),
            }
            let protoCompleteSiteAuditTask = protoProjectTasks[protoCompleteSiteAuditTaskKey as string]
            if (protoCompleteSiteAuditTask['ready_to_start'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['ready_to_start'] = protoCompleteSiteAuditTask['ready_to_start']
            }
            if (protoCompleteSiteAuditTask['prerequisite_tasks_completion'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['prerequisite_tasks_completion'] = protoCompleteSiteAuditTask['prerequisite_tasks_completion']
            }
            if (protoCompleteSiteAuditTask['associated_team_key'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['associated_team_key'] = protoCompleteSiteAuditTask['associated_team_key']
            }
            if (protoCompleteSiteAuditTask['associated_team_name'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['associated_team_name'] = protoCompleteSiteAuditTask['associated_team_name']
            }
            if (protoCompleteSiteAuditTask['associated_team_type'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['associated_team_type'] = protoCompleteSiteAuditTask['associated_team_type']
            }
            if (protoCompleteSiteAuditTask['status'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['status'] = protoCompleteSiteAuditTask['status']
            }
            if (protoCompleteSiteAuditTask['timestamp_scheduled'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['timestamp_scheduled'] = protoCompleteSiteAuditTask['timestamp_scheduled']
            }
            if (protoCompleteSiteAuditTask['associated_scheduled_time_slot'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['associated_scheduled_time_slot'] = protoCompleteSiteAuditTask['associated_scheduled_time_slot']
            }
            if (protoCompleteSiteAuditTask['task_completion_scheduled_dates'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['task_completion_scheduled_dates'] = protoCompleteSiteAuditTask['task_completion_scheduled_dates']
            }
            if (protoCompleteSiteAuditTask['task_completion_scheduled_start_times'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['task_completion_scheduled_start_times'] =
                protoCompleteSiteAuditTask['task_completion_scheduled_start_times']
            }
            if (protoCompleteSiteAuditTask['task_completion_scheduled_end_times'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['task_completion_scheduled_end_times'] =
                protoCompleteSiteAuditTask['task_completion_scheduled_end_times']
            }
            if (protoCompleteSiteAuditTask['task_completion_scheduled_team_keys'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['task_completion_scheduled_team_keys'] =
                protoCompleteSiteAuditTask['task_completion_scheduled_team_keys']
            }
            if (protoCompleteSiteAuditTask['task_completion_scheduled_team_names'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['task_completion_scheduled_team_names'] =
                protoCompleteSiteAuditTask['task_completion_scheduled_team_names']
            }
            if (protoCompleteSiteAuditTask['task_completion_scheduled_team_roles'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['task_completion_scheduled_team_roles'] =
                protoCompleteSiteAuditTask['task_completion_scheduled_team_roles']
            }
            if (protoCompleteSiteAuditTask['location_latitude'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['location_latitude'] = protoCompleteSiteAuditTask['location_latitude']
            }
            if (protoCompleteSiteAuditTask['location_longitude'] != null) {
              project_SiteAuditCompleteTask_UpdateObject['location_longitude'] = protoCompleteSiteAuditTask['location_longitude']
            }
            // Downstream Schedule
            let downstreamScheduledTasksUpdateObject: TsInterface_UnspecifiedObject = {
              ready_to_start: true,
              timestamp_last_updated: new Date(),
              prerequisite_tasks_completion: {},
            }
            if (protoScheduleSiteAuditTask['timestamp_completed'] != null) {
              if (protoCompleteSiteAuditTask['prerequisite_tasks_completion'] == null) {
                protoCompleteSiteAuditTask['prerequisite_tasks_completion'] = {}
              }
              protoCompleteSiteAuditTask['prerequisite_tasks_completion'][projectScheduleSiteAuditTaskKey as string] = completionTimestamp
              downstreamScheduledTasksUpdateObject['prerequisite_tasks_completion'][projectScheduleSiteAuditTaskKey as string] = completionTimestamp
            }
            // Log
            let logKey = completionTimestamp.getTime().toString() + '_' + generateRandomString(6, null)
            let projectLogUpdateObject = {
              timestamp: completionTimestamp,
              associated_user_key: userKey,
              associated_user_name: userName,
              event: 'merged_with_proto_project',
            }
            // Full Update Array
            let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
              { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: project_UpdateObject },
              { type: 'setMerge', ref: DatabaseRef_ProjectDesignPreferences_Document(clientKey, projectKey), data: projectDesignPreferences_UpdateObject },
              { type: 'setMerge', ref: DatabaseRef_ProtoProject_Document(clientKey, pr_protoProjectKey), data: protoProject_UpdateObject },
              {
                type: 'setMerge',
                ref: DatabaseRef_Task_Document(clientKey, protoScheduleSiteAuditTaskKey as string),
                data: protoProject_SiteAuditScheduleTask_UpdateObject,
              },
              {
                type: 'setMerge',
                ref: DatabaseRef_Task_Document(clientKey, protoCompleteSiteAuditTaskKey as string),
                data: protoProject_SiteAuditCompleteTask_UpdateObject,
              },
              { type: 'setMerge', ref: DatabaseRef_ProjectLogs_Document(clientKey, projectKey, logKey), data: projectLogUpdateObject },
              {
                type: 'update',
                ref: DatabaseRef_Task_Document(clientKey, projectScheduleSiteAuditTaskKey as string),
                data: project_SiteAuditScheduleTask_UpdateObject,
              },
              {
                type: 'setMerge',
                ref: DatabaseRef_Task_Document(clientKey, projectCompleteSiteAuditTaskKey as string),
                data: project_SiteAuditCompleteTask_UpdateObject,
              },
            ]
            // Loop through and update downstream tasks
            for (let loopDownstreamTaskKey in downstreamScheduledTasks) {
              let downstreamTask = downstreamScheduledTasks[loopDownstreamTaskKey]
              let copyOfScheduledTaskUpdateObject = cloneObjectWithoutReference(downstreamScheduledTasksUpdateObject)
              copyOfScheduledTaskUpdateObject['timestamp_last_updated'] = new Date()
              if (downstreamTask['prerequisite_tasks_completion'] != null) {
                for (let prerequisiteTaskKey in downstreamTask['prerequisite_tasks_completion']) {
                  copyOfScheduledTaskUpdateObject['prerequisite_tasks_completion'][prerequisiteTaskKey] =
                    downstreamTask['prerequisite_tasks_completion'][prerequisiteTaskKey]
                }
              }
              if (loopDownstreamTaskKey === projectScheduleSiteAuditTaskKey) {
                // Already Handled Above
              } else if (loopDownstreamTaskKey === projectCompleteSiteAuditTaskKey) {
                // Already Handled Above
              } else {
                updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopDownstreamTaskKey), data: copyOfScheduledTaskUpdateObject })
              }
            }
            // Batch Update
            DatabaseBatchUpdate(updateArray)
              .then((res_DBU) => {
                resolve(res_DBU)
              })
              .catch((rej_DBU) => {
                reject(rej_DBU)
              })
          })
        }
      })
    } else {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_MERGE_PROJECTS,
          details: s_MISSING_REQUIRED_PARAMETERS,
          code: 'ER-D-APM-MPAFP-01',
        },
      })
    }
  })
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_protoProjectKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_projectsMatchingCustomerAddress, us_setProjectsMatchingCustomerAddress] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectsMatchingCustomerEmail, us_setProjectsMatchingCustomerEmail] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectsMatchingCustomerName, us_setProjectsMatchingCustomerName] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectsMatchingCustomerPhone, us_setProjectsMatchingCustomerPhone] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectsMatchingUniqueIdentifier, us_setProjectsMatchingUniqueIdentifier] = useState<TsInterface_UnspecifiedObject>({})
  const [us_protoProject, us_setProtoProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_protoProjectDesignPreferences, us_setProtoProjectDesignPreferences] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_MERGE_PROTO_PROJECT
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProtoProject(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProtoProject_Document(res_GCK.clientKey, pr_protoProjectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_protoProjectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProtoProjectDesignPreferences(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProtoProjectDesignPreferences_Document(res_GCK.clientKey, pr_protoProjectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_protoProjectKey])

  useEffect(() => {
    if (us_protoProject != null && us_protoProject['associated_customer_name'] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(
            DatabaseRef_OpenSearchProjects_Query(res_GCK.clientKey, 'associated_customer_name', us_protoProject['associated_customer_name']),
          )
            .then((res_DGC) => {
              us_setProjectsMatchingCustomerName(res_DGC.data)
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_protoProjectKey, us_protoProject])

  useEffect(() => {
    if (us_protoProject != null && us_protoProject['associated_customer_email'] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(
            DatabaseRef_OpenSearchProjects_Query(res_GCK.clientKey, 'associated_customer_email', us_protoProject['associated_customer_email']),
          )
            .then((res_DGC) => {
              us_setProjectsMatchingCustomerEmail(res_DGC.data)
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_protoProjectKey, us_protoProject])

  useEffect(() => {
    if (us_protoProject != null && us_protoProject['associated_customer_phone'] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(
            DatabaseRef_OpenSearchProjects_Query(res_GCK.clientKey, 'associated_customer_phone', us_protoProject['associated_customer_phone']),
          )
            .then((res_DGC) => {
              us_setProjectsMatchingCustomerPhone(res_DGC.data)
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_protoProjectKey, us_protoProject])

  useEffect(() => {
    if (us_protoProject != null && us_protoProject['location_address'] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetCollection(DatabaseRef_OpenSearchProjects_Query(res_GCK.clientKey, 'location_address', us_protoProject['location_address']))
            .then((res_DGC) => {
              us_setProjectsMatchingCustomerAddress(res_DGC.data)
            })
            .catch((rej_DGC) => {
              console.error(rej_DGC)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_protoProjectKey, us_protoProject])

  // Other Variables
  const tabsSettings: TsInterface_TabsSettingsBasic = {}

  // Functions
  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    if (us_protoProject != null && us_protoProject.associated_customer_name != null) {
      pageHeader = (
        <>
          {s_PROTO_PROJECT}: {us_protoProject.associated_customer_name}
        </>
      )
    } else {
      pageHeader = s_PROTO_PROJECT
    }
    return pageHeader
  }

  const returnMergedPotentialProjectMatches = (): TsInterface_UnspecifiedObject => {
    let mergedProjects: TsInterface_UnspecifiedObject = {}
    for (let loopProjectKey in us_projectsMatchingCustomerName) {
      mergedProjects[loopProjectKey] = us_projectsMatchingCustomerName[loopProjectKey]
    }
    for (let loopProjectKey in us_projectsMatchingCustomerEmail) {
      mergedProjects[loopProjectKey] = us_projectsMatchingCustomerEmail[loopProjectKey]
    }
    for (let loopProjectKey in us_projectsMatchingCustomerPhone) {
      mergedProjects[loopProjectKey] = us_projectsMatchingCustomerPhone[loopProjectKey]
    }
    for (let loopProjectKey in us_projectsMatchingCustomerAddress) {
      mergedProjects[loopProjectKey] = us_projectsMatchingCustomerAddress[loopProjectKey]
    }
    for (let loopProjectKey in us_projectsMatchingUniqueIdentifier) {
      mergedProjects[loopProjectKey] = us_projectsMatchingUniqueIdentifier[loopProjectKey]
    }
    return mergedProjects
  }

  const openSearchProjectDialog = (project: TsInterface_UnspecifiedObject): void => {
    const promptObject: TsInterface_PromptDialogObject = {
      color: 'info',
      confirm_text: s_SEARCH,
      default_value: null,
      header: s_SEARCH_FOR_SPECIFIC_PROJECT,
      icon: (
        <Icon
          icon="magnifying-glass"
          type="solid"
        />
      ),
      input_label: s_PROJECT_PROSPECT_ID,
      input_type: 'text',
      text: s_ENTER_THE_PROJECTS_UNIQUE_IDENTIFIER,
      submit_callback: (promptValue: string) => {
        return new Promise((resolve, reject) => {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
            .then((res_GCK) => {
              DatabaseGetDocument(DatabaseRef_Project_Document(res_GCK.clientKey, promptValue))
                .then((res_DGD) => {
                  if (res_DGD.data != null && res_DGD.data.key != null) {
                    let objectToSetToState: TsInterface_UnspecifiedObject = {}
                    objectToSetToState[res_DGD.data.key] = res_DGD.data
                    us_setProjectsMatchingUniqueIdentifier(objectToSetToState)
                  }
                  resolve({ success: true })
                })
                .catch((rej_DGD) => {
                  console.error(rej_DGD)
                  reject(rej_DGD)
                })
            })
            .catch((rej_GCK) => {
              console.error(rej_GCK)
              reject(rej_GCK)
            })
        })
      },
    }
    uc_setUserInterface_PromptDialogDisplay({
      display: true,
      prompt: promptObject,
    })
  }

  // JSX Generation
  const returnProjectDataItem = (
    projectData: TsInterface_UnspecifiedObject,
    propName: JSX.Element | string,
    propKey: string,
    highlightText: boolean,
    comparePropKey: string | null,
  ): JSX.Element => {
    let dataItemJSX = <></>
    let propValue: string | null | JSX.Element = null
    if (propKey != null && projectData != null && projectData[propKey] != null) {
      if (highlightText === true) {
        let protoProjectValue = getProp(us_protoProject, comparePropKey, null)
        propValue = <Box className="tw-inline-block tw-ml-1">{rJSX_HighlightedSearchString(protoProjectValue, projectData[propKey])}</Box>
      } else {
        propValue = <Box className="tw-inline-block tw-ml-1 tw-opacity-60">{projectData[propKey]}</Box>
      }
    } else {
      propValue = <Box className="tw-inline-block tw-ml-1 tw-italic tw-opacity-30">{s_MISSING}</Box>
    }
    dataItemJSX = (
      <Typography variant="subtitle1">
        {propName}: {propValue}
      </Typography>
    )
    return dataItemJSX
  }

  const rJSX_PotentialMatchingProjects = (): JSX.Element => {
    let projectMatchJSX = <></>
    let potentialProjectMatches = returnMergedPotentialProjectMatches()

    if (us_protoProject.associated_project_key != null) {
      projectMatchJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-inline-block tw-opacity-50"
          >
            {s_PROJECT_LINKED}
          </Typography>
          <Card className="tw-p-2 tw-mb-2 tw-text-center">
            <Button
              className="tw-mt-1"
              color="info"
              variant="outlined"
              startIcon={<Icon icon="magnifying-glass"></Icon>}
              onClick={(event) => {
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(us_protoProject.associated_project_key))
              }}
            >
              {s_VIEW_LINKED_PROJECT}
            </Button>
          </Card>
        </Box>
      )
    } else if (objectToArray(potentialProjectMatches).length > 0) {
      projectMatchJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-inline-block tw-opacity-50"
          >
            {s_POTENTIAL_PROJECT_MATCHES}
          </Typography>
          {objectToArray(potentialProjectMatches).map((project, index) => (
            <Card
              key={index}
              className="tw-p-2 tw-mb-2"
            >
              {returnProjectDataItem(project, s_PROJECT_PROSPECT_ID, 'key', false, null)}
              <Divider className="tw-my-2" />
              {returnProjectDataItem(project, s_CUSTOMER_NAME, 'associated_customer_name', true, 'associated_customer_name')}
              {returnProjectDataItem(project, s_CUSTOMER_EMAIL, 'associated_customer_email', true, 'associated_customer_email')}
              {returnProjectDataItem(project, s_CUSTOMER_PHONE, 'associated_customer_phone', true, 'associated_customer_phone')}
              <Divider className="tw-my-2" />
              {returnProjectDataItem(project, s_SALES_REP, 'associated_sales_rep_name', true, 'associated_creator_user_name')}
              <Divider className="tw-my-2" />
              {returnProjectDataItem(project, rLIB('Address'), 'location_address', true, 'location_address')}
              {returnProjectDataItem(project, s_CITY, 'location_city', true, 'location_city')}
              {returnProjectDataItem(project, s_STATE, 'location_state', true, 'location_state')}
              {returnProjectDataItem(project, s_LOCATION, 'location_zip', true, 'location_zip')}
              <Button
                className="tw-mt-1"
                color="warning"
                variant="outlined"
                startIcon={<Icon icon="merge"></Icon>}
                onClick={() => {
                  uc_setUserInterface_ConfirmDialogDisplay({
                    display: true,
                    confirm: {
                      color: 'warning',
                      header: <>{s_MERGE_PROTO_PROJECT_WITH_PROJECT}</>,
                      icon: (
                        <Icon
                          icon="merge"
                          type="solid"
                        />
                      ),
                      submit_text: s_MERGE,
                      text: <>{s_ARE_YOU_SURE_THAT_YOU_WANT_TO_MERGE_THESE_PROJECTS}</>,
                      submit_callback: () => {
                        return new Promise((resolve, reject) => {
                          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              mergeProtoAndFullProjects(
                                getProp(uc_RootData_ClientUser, 'key', null),
                                getProp(uc_RootData_ClientUser, 'name', null),
                                res_GCK.clientKey,
                                project.key,
                                pr_protoProjectKey,
                              )
                                .then((res_MPAFP) => {
                                  resolve(res_MPAFP)
                                })
                                .catch((rej_MPAFP) => {
                                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_MPAFP.error })
                                  reject(rej_MPAFP)
                                })
                            })
                            .catch((rej_GCK) => {
                              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              reject(rej_GCK)
                            })
                        })
                      },
                    },
                  })
                }}
              >
                {s_MERGE_PROJECT}
              </Button>
            </Card>
          ))}
        </Box>
      )
    } else {
      projectMatchJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-inline-block tw-opacity-50"
          >
            {s_POTENTIAL_PROJECT_MATCHES}
          </Typography>
          <Card className="tw-p-2 tw-text-center">
            <Typography
              variant="h6"
              className="tw-my-2 tw-opacity-50"
            >
              {s_NO_MATCHES}
            </Typography>
            <Button
              className="tw-ml-2 tw-mb-2"
              color="info"
              variant="outlined"
              startIcon={<Icon icon="magnifying-glass"></Icon>}
              onClick={() => {
                openSearchProjectDialog(us_protoProject)
              }}
            >
              {s_FIND_PROJECT_TO_MERGE}
            </Button>
          </Card>
        </Box>
      )
    }
    return projectMatchJSX
  }

  const rJSX_GenericProjectDetailsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Masonry
          columns={{ xs: 1, sm: 2, md: 2 }}
          spacing={2}
        >
          <Box>
            <Typography
              variant="h6"
              className="tw-inline-block tw-opacity-50"
            >
              {s_PROTO_PROJECT_DETAILS}
            </Typography>
            <Card className="tw-p-2">
              <Typography variant="subtitle1">
                {s_PROJECT_PROSPECT_ID}: {<Box className="tw-inline-block tw-ml-1 tw-italic tw-opacity-30">{s_MISSING}</Box>}
              </Typography>
              <Divider className="tw-my-2" />
              {returnProjectDataItem(us_protoProject, s_CUSTOMER_NAME, 'associated_customer_name', false, null)}
              {returnProjectDataItem(us_protoProject, s_CUSTOMER_EMAIL, 'associated_customer_email', false, null)}
              {returnProjectDataItem(us_protoProject, s_CUSTOMER_PHONE, 'associated_customer_phone', false, null)}
              <Divider className="tw-my-2" />
              {returnProjectDataItem(us_protoProject, s_SALES_REP, 'associated_creator_user_name', false, null)}
              <Divider className="tw-my-2" />
              {returnProjectDataItem(us_protoProject, rLIB('Address'), 'location_address', false, null)}
              {returnProjectDataItem(us_protoProject, s_CITY, 'location_city', false, null)}
              {returnProjectDataItem(us_protoProject, s_STATE, 'location_state', false, null)}
              {returnProjectDataItem(us_protoProject, s_LOCATION, 'location_zip', false, null)}
            </Card>
          </Box>
          {rJSX_PotentialMatchingProjects()}
        </Masonry>
      </Box>
    )
    return tabJSX
  }

  const rJSX_DesignPreferencesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Box>
          {returnProjectDesignPreferences(
            'proto',
            uc_RootData_ClientKey,
            pr_protoProjectKey,
            us_protoProjectDesignPreferences,
            uc_setUserInterface_FormDialogDisplay,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
          )}
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              <Button
                className="tw-mb-2"
                color="inherit"
                variant="outlined"
                startIcon={<Icon icon="chevron-left"></Icon>}
                onClick={(event) => {
                  onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectsListPage.url())
                }}
              >
                {s_BACK_TO_ALL_ACTIVE_PROJECTS}
              </Button>
              <Button
                className="tw-ml-2 tw-mb-2"
                color="info"
                variant="contained"
                disabled={us_protoProject.associated_project_key != null}
                startIcon={<Icon icon="magnifying-glass"></Icon>}
                onClick={() => {
                  openSearchProjectDialog(us_protoProject)
                }}
              >
                {s_FIND_PROJECT_TO_MERGE}
              </Button>
            </Box>
            <TabsBasic
              tabs={[
                {
                  tabHeader: s_PROTO_PROJECT,
                  tabOnChange: () => {},
                  tabContent: <Box className="tw-m-auto">{rJSX_GenericProjectDetailsTab()}</Box>,
                },
                {
                  tabHeader: s_DESIGN_PREFERENCES,
                  tabOnChange: () => {},
                  tabContent: <Box className="tw-m-auto">{rJSX_DesignPreferencesTab()}</Box>,
                },
              ]}
              tabsSettings={tabsSettings}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

// TEMP
export const returnImageTags = (clientKey: string | null): string[] => {
  let imageTags: string[] = []
  switch (clientKey) {
    // case "clientKey":
    default:
      imageTags = [
        'Attic',
        'Battery',
        'Electrical Main Panel',
        'Electrical Sub Panel',
        // "Garage",
        'Home Exterior',
        'Home Interior',
        'Home Upgrades',
        'Huddle',
        'Install',
        'Inverter',
        'Roof',
        'Site Audit',
        'Utility Meter',
        'Wiring',
      ]
  }
  return imageTags
}

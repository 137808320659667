///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_LifetimePanels_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'lifetime_panels', 'main')
}

export const DatabaseRef_LifetimePanels_Document = (clientKey: string, userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'lifetime_panels', 'main', userKey)
}

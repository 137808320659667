///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:
			1 @ts-expect-error - really annoying casting problem on permission overrides

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material/'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  ApplicationPages,
  ClientTypes,
  ClientUserRoles,
  generateAvailableUserLevelPermissions,
  TsType_ClientTypes,
  TsType_UserRoles,
} from 'rfbp_aux/data/application_structure'
import { TsInterface_FormInputs, TsInterface_FormSettings, TsType_FormOnChange, TsType_FormSubmission } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_RootData_ClientPermissions } from 'rfbp_core/services/context'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { createUser, updateUser } from './user_functions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_DEFAULT: JSX.Element = <Trans>Default</Trans>
const s_DEFAULT_MINIMUM_WAGE_STATE: JSX.Element = <Trans>Default Minimum Wage State</Trans>
const s_EMAIL: JSX.Element = <Trans>Email</Trans>
const s_EXTERNAL_USER: JSX.Element = <Trans>External User</Trans>
const s_FIELD_WORK: JSX.Element = <Trans>Field Work</Trans>
const s_HARDCODED_PAYROLL_BONUS: JSX.Element = <Trans>Hardcoded Payroll Bonus</Trans>
const s_HOURLY: JSX.Element = <Trans>Hourly</Trans>
const s_HOURLY_OR_SALARIED: JSX.Element = <Trans>Hourly or Salaried</Trans>
const s_HOURS: JSX.Element = <Trans>Hours</Trans>
const s_INTERNAL_OR_EXTERNAL_USER: JSX.Element = <Trans>Internal or External User</Trans>
const s_INTERNAL_USER: JSX.Element = <Trans>Internal User</Trans>
const s_NON_WORKING_TIME: JSX.Element = <Trans>Non Working Time</Trans>
const s_PASSWORD: JSX.Element = <Trans>Password</Trans>
const s_PAYROLL_ORGANIZATION_ID: JSX.Element = <Trans>Payroll Organization ID</Trans>
const s_PAYROLL_USER_ID: JSX.Element = <Trans>Payroll User ID</Trans>
const s_PERMISSIONS: JSX.Element = <Trans>Permissions</Trans>
const s_PHONE: JSX.Element = <Trans>Phone</Trans>
const s_SALARIED_EXEMPT: JSX.Element = <Trans>Salaried Exempt</Trans>
const s_SALARIED_NOT_EXEMPT: JSX.Element = <Trans>Salaried Not Exempt</Trans>
const s_SAVE: JSX.Element = <Trans>Save</Trans>
const s_TERMINATED: JSX.Element = <Trans>Terminated</Trans>
const s_USER_NAME: JSX.Element = <Trans>User Name</Trans>
const s_USER_PERMISSIONS: JSX.Element = <Trans>User Permissions</Trans>
const s_USER_TYPE: JSX.Element = <Trans>User Type</Trans>
// { sort-end } - displayed text

// CSS
let inputCss: string = `
		.etw-dta-input input { padding: 4px }
	`

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const formOnChange_User: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
  if (
    formData.user_role != null &&
    ClientUserRoles != null &&
    ClientUserRoles[formData.user_role as string] != null &&
    ClientUserRoles[formData.user_role as string]['default_associated_organization_type'] != null
  ) {
    formData['associated_organization_type'] = ClientUserRoles[formData.user_role as string]['default_associated_organization_type']
  }
}

export const formInputs_UserNew: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: s_USER_NAME,
    required: true,
  },
  phone: {
    data_type: 'string',
    input_type: 'phone_number_usa',
    key: 'phone',
    label: s_PHONE,
    required: true,
    background_color: '#272B33',
  },
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: s_EMAIL,
    required: true,
  },
  password: {
    data_type: 'string',
    input_type: 'text_password',
    key: 'password',
    label: s_PASSWORD,
    required: true,
  },
  user_role: {
    data_type: 'string',
    key: 'user_role',
    input_type: 'custom_form_input_jsx',
    label: s_USER_TYPE,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      // Function to update permission override checkboxes
      const updatePermissionOverrides = (event: React.ChangeEvent, value: TsType_UserRoles) => {
        let userPermissionsList = generateAvailableUserLevelPermissions(
          value,
          formAdditionalData['client_type'] as TsType_ClientTypes,
          formAdditionalData['root_client_permissions'] as unknown as TsInterface_RootData_ClientPermissions,
          formHooks.uc_RootData_ClientUser,
        )
        formData['permission_overrides'] = {}
        for (let sectionKey in userPermissionsList) {
          let section = userPermissionsList[sectionKey]
          if (section != null && section['permissions'] != null) {
            for (let permissionKey in section['permissions']) {
              let permission = section['permissions'][permissionKey]
              if (permission.access === 'always_yes' || permission.access === 'default_yes') {
                formData['permission_overrides'][permissionKey] = true
              } else {
                formData['permission_overrides'][permissionKey] = false
              }
            }
          }
        }
        // formInputChange(event, value, "user_role", "custom_form_input_jsx", formInput, {} )
        formInputChange('user_role', value, true)
        // formInputChange(event, formData["permission_overrides"], "permission_overrides", "custom_form_input_jsx", formInput, {} )
        formInputChange('permission_overrides', formData['permission_overrides'], true)
      }
      // Generate user role options for custom form input
      let clientUserRoleOptions: TsInterface_UnspecifiedObject = {}
      if (
        formAdditionalData != null &&
        formAdditionalData.client_type != null &&
        ClientTypes != null &&
        ClientTypes[formAdditionalData.client_type as TsType_ClientTypes] != null &&
        ClientTypes[formAdditionalData.client_type as TsType_ClientTypes]['user_roles'] != null
      ) {
        for (let loopUserRoleKey in ClientTypes[formAdditionalData.client_type as TsType_ClientTypes]['user_roles']) {
          let loopUserRole = ClientTypes[formAdditionalData.client_type as TsType_ClientTypes]['user_roles'][loopUserRoleKey]
          // @ts-expect-error - TODO: reason for error
          if (loopUserRole.can_be_primary_role === true) {
            clientUserRoleOptions[loopUserRoleKey] = loopUserRole
          }
        }
      }
      let inputJSX = (
        <Box className="tw-mt-4">
          <FormControl className="bp_thin_select_input">
            <Select
              color="primary"
              value={formData[formInput['key']] || ''}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updatePermissionOverrides(event, event.target.value as TsType_UserRoles)
                }
              }}
              variant="outlined"
            >
              {objectToArray(clientUserRoleOptions).map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  <Chip
                    size={'small'}
                    label={ClientUserRoles[option['key']]['name']}
                    color={ClientUserRoles[option['key']]['color']}
                    icon={ClientUserRoles[option['key']]['icon']}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )
      return inputJSX
    },
  },
  associated_organization_type: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'associated_organization_type',
    label: s_INTERNAL_OR_EXTERNAL_USER,
    required: true,
    options: [
      { key: 'internal', value: s_INTERNAL_USER },
      { key: 'external', value: s_EXTERNAL_USER },
    ],
  },
  permission_overrides: {
    data_type: 'string',
    key: 'permission_overrides',
    input_type: 'custom_form_input_jsx',
    label: s_PERMISSIONS,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      // Function to toggle permission overrides
      const togglePermissionCheckbox = (event: React.ChangeEvent, permissionKey: string, value: boolean) => {
        if (formData == null) {
          formData = { permission_overrides: {} }
        }
        if (formData['permission_overrides'] == null) {
          formData['permission_overrides'] = {}
        }
        // @ts-expect-error - TODO: reason for error
        formData['permission_overrides'][permissionKey] = value
        // formInputChange(event, formData["permission_overrides"], "permission_overrides", "custom_form_input_jsx", formInput, {} )
        formInputChange('permission_overrides', formData['permission_overrides'], true)
      }
      // Function to color checkboxes
      const returnCheckboxColor = (defaultOverrideBool: boolean, actualOverrideBool: boolean) => {
        let sx
        if (defaultOverrideBool === actualOverrideBool) {
          sx = { 'color': themeVariables.success_main, '&.Mui-checked': { color: themeVariables.success_main } }
        } else {
          sx = { 'color': themeVariables.error_main, '&.Mui-checked': { color: themeVariables.error_main } }
        }
        return sx
      }
      // Function to return checkbox
      const rJSX_Checkbox = (individualPermission: TsInterface_UnspecifiedObject) => {
        let checkboxJSX
        if (individualPermission.access === 'always_yes') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(formData['permission_overrides'], individualPermission.permissionKey, true)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  disabled
                />
              }
              label={individualPermission.pageName}
            />
          )
        } else if (individualPermission.access === 'default_yes') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(formData['permission_overrides'], individualPermission.permissionKey, true)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  sx={returnCheckboxColor(true, getProp(formData['permission_overrides'], individualPermission.permissionKey, true))}
                />
              }
              label={individualPermission.pageName}
            />
          )
        } else if (individualPermission.access === 'default_no') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(formData['permission_overrides'], individualPermission.permissionKey, false)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  sx={returnCheckboxColor(false, getProp(formData['permission_overrides'], individualPermission.permissionKey, false))}
                />
              }
              label={individualPermission.pageName}
            />
          )
        } else if (individualPermission.access === 'always_no') {
          checkboxJSX = (
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(formData['permission_overrides'], individualPermission.permissionKey, false)}
                  onChange={(event, value) => {
                    togglePermissionCheckbox(event, individualPermission.permissionKey, value)
                  }}
                  disabled
                />
              }
              label={individualPermission.pageName}
            />
          )
        }
        return checkboxJSX
      }
      // TODO - checkboxes to override permissions
      let inputJSX
      if (
        formData != null &&
        formData['user_role'] != null &&
        formAdditionalData != null &&
        formAdditionalData['client_type'] != null &&
        formAdditionalData['root_client_permissions'] != null
      ) {
        let userPermissionsList = generateAvailableUserLevelPermissions(
          formData['user_role'] as TsType_UserRoles,
          formAdditionalData['client_type'] as TsType_ClientTypes,
          formAdditionalData['root_client_permissions'] as unknown as TsInterface_RootData_ClientPermissions,
          formHooks.uc_RootData_ClientUser,
        )
        inputJSX = (
          <div className="tw-mt-6">
            <FormLabel component="legend">{s_USER_PERMISSIONS}</FormLabel>
            <Box
              component="div"
              className="tw-mt-1"
              sx={{ marginLeft: '24px' }}
            >
              {objectToArray(userPermissionsList).map((permissionSection) => (
                <span key={permissionSection.sectionKey}>
                  <Typography
                    variant="subtitle1"
                    display="block"
                    color="primary"
                  >
                    {permissionSection.sectionName}
                  </Typography>
                  {objectToArray(permissionSection.permissions).map((permission) => (
                    <span key={permission.permissionKey}>{rJSX_Checkbox(permission)}</span>
                  ))}
                </span>
              ))}
            </Box>
          </div>
        )
      } else {
        inputJSX = <div></div>
      }
      return inputJSX
    },
  },
}

export const formInputs_UserEdit: TsInterface_FormInputs = {
  name: formInputs_UserNew['name'],
  phone: formInputs_UserNew['phone'],
  user_role: formInputs_UserNew['user_role'],
  associated_organization_type: formInputs_UserNew['associated_organization_type'],
  permission_overrides: formInputs_UserNew['permission_overrides'],
  // display_form_data_json: {
  // key: "display_form_data_json",
  // input_type: "display_form_data_json",
  // label: <></>,
  // data_type: "string"
  // }
}

export const formSubmission_UserCreate: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    if (formSubmittedData != null && formSubmittedData['associated_organization_type'] === 'internal') {
      formSubmittedData['hourly_or_salaried'] = 'hourly'
    }
    createUser(
      formInputs_UserNew,
      formSubmittedData,
      formHooks.uc_RootData_ClientPermissions,
      formHooks.uc_RootData_ClientKey,
      formHooks.uc_setRootData_ClientKey,
      formHooks.uc_RootData_ClientUser,
    )
      .then((res_CU) => {
        directAppNavigation(formHooks.un_routerNavigation, ApplicationPages.AdminDatabaseUserListPage.url())
        resolve(res_CU)
      })
      .catch((rej_CU) => {
        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CU.error })
        reject(rej_CU)
      })
  })
}

export const formSubmission_UserUpdate: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
  return new Promise((resolve, reject) => {
    updateUser(
      formAdditionalData.key as string,
      formInputs_UserEdit,
      formSubmittedData,
      formHooks.uc_RootData_ClientPermissions,
      formHooks.uc_RootData_ClientKey,
      formHooks.uc_setRootData_ClientKey,
      formHooks.uc_RootData_ClientUser,
    )
      .then((res_UU) => {
        directAppNavigation(formHooks.un_routerNavigation, ApplicationPages.AdminDatabaseUserListPage.url())
        resolve(res_UU)
      })
      .catch((rej_UU) => {
        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_UU.error })
        reject(rej_UU)
      })
  })
}

export const formSettings_User: TsInterface_FormSettings = {
  highlight_missing: true,
  submit_button_alignment: 'right',
  submit_button_hide: false,
  submit_button_icon: <Icon icon="floppy-disk" />,
  submit_button_saving_icon: true,
  submit_button_text: <>{s_SAVE}</>,
}

// Timecards
export const hourlyOrSalariedOptions: TsInterface_UnspecifiedObject = {
  hourly: { key: 'hourly', value: s_HOURLY, abbreviation: 'H' },
  salaried_exempt: { key: 'salaried_exempt', value: s_SALARIED_EXEMPT, abbreviation: 'SE' },
  salaried_non_exempt: { key: 'salaried_non_exempt', value: s_SALARIED_NOT_EXEMPT, abbreviation: 'SN' },
  terminated: { key: 'terminated', value: s_TERMINATED, abbreviation: 'T' },
}

export const formInputs_UserHourlyOrSalaried: TsInterface_FormInputs = {
  hourly_or_salaried: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'hourly_or_salaried',
    label: s_HOURLY_OR_SALARIED,
    required: true,
    options: objectToArray(hourlyOrSalariedOptions),
  },
}

export const formInputs_DefaultLocationState: TsInterface_FormInputs = {
  default_location_state: {
    data_type: 'string',
    input_type: 'multiple_choice_select_state_usa_abbreviated',
    key: 'default_location_state',
    label: s_DEFAULT_MINIMUM_WAGE_STATE,
    required: true,
  },
}

export const formInputs_PayrollUserId: TsInterface_FormInputs = {
  id_number_payroll: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'id_number_payroll',
    label: s_PAYROLL_USER_ID,
    required: true,
  },
}

export const formInputs_PayrollOrganizationCode: TsInterface_FormInputs = {
  id_organization_payroll: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'id_organization_payroll',
    label: s_PAYROLL_ORGANIZATION_ID,
    required: true,
  },
}

export const formInputs_HardcodedBonus: TsInterface_FormInputs = {
  hardcoded_bonus: {
    data_type: 'number',
    input_type: 'text_number',
    key: 'hardcoded_bonus',
    label: s_HARDCODED_PAYROLL_BONUS,
    required: true,
  },
}

export const formInputs_DefaultTimeAllocations: TsInterface_FormInputs = {
  allocated_times: {
    data_type: 'string',
    key: 'allocated_times',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      // Variables
      // let allocationTypes = getProp( formAdditionalData, "allocationTypes", {} )
      let allAllocationTypes = getProp(formAdditionalData, 'allocationTypes', {})
      let allocationTypes = {
        admin_time: getProp(allAllocationTypes, 'admin_time', {}),
      }
      // Filter Subtypes
      const returnActiveSubtypes = (subtypeObject: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject[] => {
        return objectToArray(subtypeObject).filter((object) => {
          return object['status'] === 'active'
        })
      }
      // Return Text Input Value
      const returnTextInputValue = (type: TsInterface_UnspecifiedObject, subtype: TsInterface_UnspecifiedObject) => {
        // Fix Form Data if null
        if (formData['allocated_times'] == null) {
          formData['allocated_times'] = {}
        }
        // @ts-expect-error - TODO: reason for error
        if (formData['allocated_times'][type.key] == null) {
          // @ts-expect-error - TODO: reason for error
          formData['allocated_times'][type.key] = {}
        }
        // @ts-expect-error - TODO: reason for error
        return formData['allocated_times'][type.key][subtype.key] || 0
      }
      const rJSX_DefaultAllocationHeader = () => {
        let headerJSX = <></>
        if (formAdditionalData.showDefaultTimeAllocations === true) {
          headerJSX = <TableCell className="tw-px-1 tw-opacity-30">{s_DEFAULT}</TableCell>
        }
        return headerJSX
      }
      const rJSX_DefaultAllocationCell = (type: TsInterface_UnspecifiedObject, subtype: TsInterface_UnspecifiedObject) => {
        let cellJSX = <></>
        if (formAdditionalData.showDefaultTimeAllocations === true) {
          let defaultValue = ''
          if (
            formAdditionalData != null &&
            formAdditionalData['userDefaultTimeAllocations'] != null &&
            // @ts-expect-error - TODO: reason for error
            formAdditionalData['userDefaultTimeAllocations']['allocated_times'] != null &&
            type != null &&
            type.key != null &&
            subtype != null &&
            subtype.key != null &&
            // @ts-expect-error - TODO: reason for error
            formAdditionalData['userDefaultTimeAllocations']['allocated_times'][type.key] != null &&
            // @ts-expect-error - TODO: reason for error
            formAdditionalData['userDefaultTimeAllocations']['allocated_times'][type.key][subtype.key] != null
          ) {
            // @ts-expect-error - TODO: reason for error
            defaultValue = formAdditionalData['userDefaultTimeAllocations']['allocated_times'][type.key][subtype.key]
          }
          cellJSX = <TableCell className="tw-px-1 tw-opacity-30">{defaultValue}</TableCell>
        }
        return cellJSX
      }
      // Input JSX
      let inputJSX = (
        <Box className="tw-mb-2">
          <TableContainer>
            <Table size="small">
              {objectToArray(allocationTypes)
                .sort(dynamicSort('name', null))
                .map((type, typeIndex) => (
                  <TableBody key={typeIndex}>
                    <TableRow sx={{ background: themeVariables.background_json }}>
                      <TableCell className="tw-px-1">{type.name}</TableCell>
                      <TableCell className="tw-px-1"></TableCell>
                      {rJSX_DefaultAllocationHeader()}
                      <TableCell className="tw-px-1 tw-opacity-30">{s_HOURS}</TableCell>
                    </TableRow>
                    {returnActiveSubtypes(type.subtypes)
                      .sort(dynamicSort('name', null))
                      .map((subtype, subtypeIndex) => (
                        <TableRow
                          key={subtypeIndex}
                          sx={{ background: themeVariables.background_paper }}
                        >
                          <TableCell className="tw-px-1"></TableCell>
                          <TableCell className="tw-px-1">{subtype.name}</TableCell>
                          {rJSX_DefaultAllocationCell(type, subtype)}
                          <TableCell
                            className="tw-px-1"
                            sx={{ maxWidth: '100px' }}
                          >
                            <TextField
                              className="tw-w-full etw-dta-input"
                              sx={{ padding: '1px' }}
                              type="number"
                              // value={ getProp( formData["allocated_times"][ type.key ], subtype.key, null ) }
                              value={returnTextInputValue(type, subtype)}
                              onChange={(event) => {
                                if (
                                  event != null &&
                                  event.target != null &&
                                  event.target.value != null &&
                                  type != null &&
                                  type.key != null &&
                                  subtype != null &&
                                  subtype.key != null
                                ) {
                                  if (formData['allocated_times'] == null) {
                                    formData['allocated_times'] = {}
                                  }
                                  // @ts-expect-error - TODO: reason for error
                                  if (formData['allocated_times'][type.key] == null) {
                                    // @ts-expect-error - TODO: reason for error
                                    formData['allocated_times'][type.key] = {}
                                  }
                                  // @ts-expect-error - TODO: reason for error
                                  formData['allocated_times'][type.key][subtype.key] = parseFloat(event.target.value)
                                  formInputChange('allocated_times', formData['allocated_times'], true)
                                }
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                ))}
            </Table>
          </TableContainer>
          <style>{inputCss}</style>
        </Box>
      )
      return inputJSX
    },
  },
}

export const formInputs_ClockInAllocationSelection: TsInterface_FormInputs = {
  selected_allocation: {
    data_type: 'string',
    key: 'selected_allocation',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = <></>
      // SX
      const returnSX_BackgroundColors = (option: TsInterface_UnspecifiedObject) => {
        let backgroundColorsSx = {
          'background': themeVariables.background_json,
          '&:hover': { background: themeVariables.background_paper },
          'minHeight': '72px',
        }
        if (
          formData != null &&
          formData['selected_allocation'] != null &&
          // @ts-expect-error - TODO: reason for error
          formData['selected_allocation'].key === option.key
        ) {
          backgroundColorsSx = {
            'background': themeVariables.info_main,
            '&:hover': { background: themeVariables.info_light },
            'minHeight': '72px',
          }
        }
        return backgroundColorsSx
      }
      // Button
      const returnButtonContent = (option: TsInterface_UnspecifiedObject) => {
        let buttonContent = <></>
        if (option.allocation_type_key === 'admin_time') {
          buttonContent = (
            <Box>
              <Typography
                variant="h6"
                className="tw-font-light"
              >
                {option.full_name}
              </Typography>
              <Typography
                variant="body1"
                className="tw-opacity-30"
              >
                {getProp(option, 'hours', 0)} {s_HOURS}
              </Typography>
            </Box>
          )
        } else if (option.allocation_type_key === 'field_work') {
          buttonContent = (
            <Box>
              <Typography
                variant="h6"
                className="tw-font-light"
              >
                {s_FIELD_WORK}: {option.allocation_type_name}
              </Typography>
              <Typography
                variant="body1"
                className="tw-opacity-30"
              >
                {option.allocation_subtype_name}
              </Typography>
            </Box>
          )
        } else if (option.allocation_type_key === 'break') {
          buttonContent = (
            <Box>
              <Typography
                variant="h6"
                className="tw-font-light"
                sx={{ marginTop: '10px' }}
              >
                {rLIB('Break')}
              </Typography>
            </Box>
          )
        } else if (option.allocation_type_key === 'non_working_time') {
          buttonContent = (
            <Box>
              <Typography
                variant="h6"
                className="tw-font-light"
              >
                {s_NON_WORKING_TIME}
              </Typography>
              <Typography
                variant="body1"
                className="tw-opacity-30"
              >
                {option.allocation_subtype_name}
              </Typography>
            </Box>
          )
        }
        return buttonContent
      }
      // JSX
      inputJSX = (
        <Box className="tw-mb-2">
          {/* @ts-expect-error  - TODO: reason */}
          {objectToArray(formAdditionalData.clockInOptions)
            .sort(dynamicSort('order', null))
            .map((option, index) => (
              <Box
                key={index}
                className="tw-w-full tw-my-2 tw-p-2 tw-rounded-lg tw-cursor-pointer"
                sx={returnSX_BackgroundColors(option)}
                onClick={() => {
                  formData['selected_allocation'] = option
                  formInputChange('selected_allocation', formData['selected_allocation'], true)
                }}
              >
                {returnButtonContent(option)}
              </Box>
            ))}
        </Box>
      )
      return inputJSX
    },
  },
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Chip, Tooltip, Typography } from '@mui/material/'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useEffect, useState } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import Stripe from 'stripe'
import { Tab as ApprovalsTab } from './tabs/tab_spending_approvals'
import { Tab as CardholdersTab } from './tabs/tab_spending_cardholders'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminFinanceSpendingIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const [us_authorizations, us_setAuthorizations] = useState<Stripe.Issuing.Authorization[]>([])
  const [us_transactions, us_setTransactions] = useState<Stripe.Issuing.Transaction[]>([])
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Spending', false) as string
  }, [])

  useEffect(() => {
    cloudFunctionManageRequest('manageFinances', {
      function: 'fetchAuthorizations',
      livemode: true,
    })
      .then((res_CFMMR: any) => {
        us_setAuthorizations(res_CFMMR.authorizations)
      })
      .catch((rej_CFMMR) => {
        console.error(rej_CFMMR)
      })
  }, [])

  useEffect(() => {
    cloudFunctionManageRequest('manageFinances', {
      function: 'fetchTransactions',
      livemode: true,
    })
      .then((res_CFMMR: any) => {
        console.log('res_CFMMR')
        console.log(res_CFMMR)
        us_setTransactions(res_CFMMR.transactions)
      })
      .catch((rej_CFMMR) => {
        console.error(rej_CFMMR)
      })
  }, [])

  // Functions

  // JSX Generation

  const rJSX_AuthorizationsTab = (): JSX.Element => {
    const columns: GridColDef[] = [
      {
        field: 'created',
        headerName: 'DATE',
        width: 120,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>
              {new Date(params.value * 1000).toLocaleString('en-US', { month: 'short' })} {new Date(params.value * 1000).getDate()}
            </Typography>
          </Box>
        ),
      },
      {
        field: 'merchant_data.name',
        headerName: 'DESCRIPTION',
        width: 200,
        renderCell: (params) => {
          const name = params.row.merchant_data?.name || ''
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'card.cardholder.name',
        headerName: 'NAME ON CARD',
        width: 200,
        renderCell: (params) => {
          const name = params.row.card.cardholder.name || ''
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'card.last4',
        headerName: 'CARD',
        width: 100,
        renderCell: (params) => {
          const name = '****' + params.row.card.last4 || ''
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'approved',
        headerName: 'STATUS',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title={params.row.metadata?.error_details ? `${params.row.metadata?.error_details || ''}`.trim() : ''}
            arrow
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {params.value ? (
                <>
                  <CheckCircleOutlineIcon
                    color="success"
                    sx={{ mr: 1 }}
                  />
                  <Chip
                    label="Completed"
                    color="success"
                    size="small"
                  />
                </>
              ) : (
                <>
                  <ErrorOutlineIcon
                    color="error"
                    sx={{ mr: 1 }}
                  />
                  <Chip
                    label="Failed"
                    color="error"
                    size="small"
                  />
                </>
              )}
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'amount',
        headerName: 'AMOUNT',
        flex: 1,
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{`$${(params.value / 100).toFixed(2)}`}</Typography>
          </Box>
        ),
      },
    ]

    let tabJSX = (
      <Box
        sx={{
          flex: 1,
          height: '100%',
        }}
      >
        <DataGrid
          rows={us_authorizations}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          disableRowSelectionOnClick
          pageSizeOptions={[25]}
          localeText={{
            noRowsLabel: '',
          }}
          sx={{
            'width': '100%',
            '& .MuiDataGrid-main': {
              width: '100%',
            },
          }}
        />
      </Box>
    )
    return tabJSX
  }

  const rJSX_TransactionsTab = (): JSX.Element => {
    const columns: GridColDef[] = [
      {
        field: 'created',
        headerName: 'DATE',
        width: 120,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{new Date(params.value * 1000).toLocaleDateString()}</Typography>
          </Box>
        ),
      },
      {
        field: 'merchant_data.name',
        headerName: 'DESCRIPTION',
        width: 200,
        renderCell: (params) => {
          const name = params.row.merchant_data?.name || ''
          console.log('params')
          console.log(params)
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'type',
        headerName: 'STATUS',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {params.value === 'capture' ? (
              <>
                <CheckCircleOutlineIcon
                  color="success"
                  sx={{ mr: 1 }}
                />
                <Chip
                  label="Completed"
                  color="success"
                  size="small"
                />
              </>
            ) : (
              <>
                <ErrorOutlineIcon
                  color="error"
                  sx={{ mr: 1 }}
                />
                <Chip
                  label="Failed"
                  color="error"
                  size="small"
                />
              </>
            )}
          </Box>
        ),
      },
      {
        field: 'amount',
        headerName: 'AMOUNT',
        width: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{`$${(params.value / -100).toFixed(2)}`}</Typography>
          </Box>
        ),
      },
    ]

    let tabJSX = (
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        <DataGrid
          rows={us_transactions}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25]}
          localeText={{
            noRowsLabel: '',
          }}
        />
      </Box>
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Spending')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminFinanceSpendingIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Spending', false) as string,
              }}
              tabs={[
                {
                  tabHeader: rLIB('Cardholders'),
                  tabUrlKey: 'cardholders',
                  tabButtons: [],
                  tabContent: (
                    <Box>
                      <CardholdersTab
                        selectedDate={us_selectedDate}
                        setSelectedDate={us_setSelectedDate}
                      />
                    </Box>
                  ),
                },
                {
                  tabHeader: rLIB('Authorizations'),
                  tabUrlKey: 'authorizations',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_AuthorizationsTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Transactions'),
                  tabUrlKey: 'transactions',
                  tabButtons: [],
                  tabContent: <Box>{rJSX_TransactionsTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Approvals'),
                  tabUrlKey: 'approvals',
                  tabButtons: [],
                  tabContent: (
                    <Box>
                      <ApprovalsTab
                        selectedDate={us_selectedDate}
                        setSelectedDate={us_setSelectedDate}
                      />
                    </Box>
                  ),
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_GlobalCronJobOneTimeTasks_Collection = (): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'global', 'cron_job_tasks', 'one_time')
}

export const DatabaseRef_GlobalCronJobOneTimeTask_Document = (taskKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'global', 'cron_job_tasks', 'one_time', taskKey)
}

export const DatabaseRef_GlobalCronJobOneTimeTaskReady_Query = (timestamp: Date, limit: number): Query<DocumentData> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'timestamp_to_run', comparator: '<=', value: timestamp }]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_to_run', desc: false }]
  return generateDatabaseQuery(collection(getFirestore(), 'global', 'cron_job_tasks', 'one_time'), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
}

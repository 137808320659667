//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Main home page for authenticated users

		TODO:
			[ ] Auto navigate to proper page based on user role

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Link, Typography } from '@mui/material/'
import {
  formInputs_DisabledBackupLoads,
  formInputs_DisabledContactInfo,
  formInputs_DisabledHomeInfo,
  rJSX_OrderFinanceDetails,
  supportEmail,
  supportPhone,
} from 'app/models/powerwall/powerwall_form'
import { rJSX_defaultProjectTimeline } from 'app/models/powerwall/powerwall_timeline'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, ClientTypes, generateApplicationNavigationObject, TsInterface_NavigationObject } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_PowerwallOrder_Document } from 'rfbp_aux/services/database_endpoints/webstore/orders'
import {
  Form,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_UserPermissions,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { cloneObjectWithoutReference, dynamicSort, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['HomePage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_BACKUP_LOADS: JSX.Element = <Trans>Backup Loads</Trans>
const s_CONTACT_INFO: JSX.Element = <Trans>Contact Info</Trans>
const s_DOWNLOAD_FOR_ANDROID: JSX.Element = <Trans>Download for Android</Trans>
const s_DOWNLOAD_FOR_IOS: JSX.Element = <Trans>Download for iOS</Trans>
const s_DOWNLOAD_THE_ETW_APP_TO_ACCESS_YOUR_ACCOUNT: JSX.Element = <Trans>Download the ETW app to access your account</Trans>
const s_HOME_INFO: JSX.Element = <Trans>Home Info</Trans>
const s_ORDER: JSX.Element = <Trans>Order</Trans>
const s_PHONE: JSX.Element = <Trans>Phone</Trans>
const s_POWERWALL_ORDER_SUMMARY: JSX.Element = <Trans>Powerwall Order Summary</Trans>
const s_SUPPORT: JSX.Element = <Trans>Support</Trans>
const s_SUPPORT_TEXT: JSX.Element = <Trans>If you have any questions or concerns, feel free to reach out to our team</Trans>
const s_TIMELINE: JSX.Element = <Trans>Timeline</Trans>
const s_WELCOME: JSX.Element = <Trans>Welcome!</Trans>
const se_ETW_ENERGY = 'ETW Energy'
// { sort-end } - displayed text

// Forms
// Disable All Forms
let disabledFormInputs_ContactInfo = { ...formInputs_DisabledContactInfo }
for (let inputKey in disabledFormInputs_ContactInfo) {
  disabledFormInputs_ContactInfo[inputKey]['disabled'] = true
}
let disabledFormInputs_HomeInfo = { ...formInputs_DisabledHomeInfo }
for (let inputKey in disabledFormInputs_HomeInfo) {
  disabledFormInputs_HomeInfo[inputKey]['disabled'] = true
}
let disabledFormInputs_BackupLoads = { ...formInputs_DisabledBackupLoads }
for (let inputKey in disabledFormInputs_BackupLoads) {
  disabledFormInputs_BackupLoads[inputKey]['disabled'] = true
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_deviceOS, us_setDeviceOS] = useState<string>('')
  const [us_foundPowerwallOrder, us_setFoundPowerwallOrder] = useState<boolean | null>(null)
  const [us_navPages, us_setNavPages] = useState({})
  const [us_powerwallOrderData, us_setPowerwallOrderData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_restrictedDesktopAccess, us_setRestrictedDesktopAccess] = useState<boolean | null>(null)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_UserPermissions } = useContext(Context_RootData_UserPermissions)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_ETW_ENERGY
  }, [])

  useEffect(() => {
    const userAgent = navigator.userAgent
    if (/android/i.test(userAgent)) {
      us_setDeviceOS('android')
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      us_setDeviceOS('ios')
    } else {
      us_setDeviceOS('other')
    }
  }, [])

  useEffect(() => {
    if (
      uc_RootData_ClientPermissions != null &&
      uc_RootData_ClientPermissions['client_type'] != null &&
      uc_RootData_ClientUser != null &&
      uc_RootData_ClientUser['user_role'] != null &&
      ClientTypes != null &&
      ClientTypes[uc_RootData_ClientPermissions['client_type']] != null &&
      ClientTypes[uc_RootData_ClientPermissions['client_type']]['user_roles'] != null &&
      ClientTypes[uc_RootData_ClientPermissions['client_type']]['user_roles'][uc_RootData_ClientUser['user_role']] != null &&
      ClientTypes[uc_RootData_ClientPermissions['client_type']]['user_roles'][uc_RootData_ClientUser['user_role']]['desktop_access'] === false
    ) {
      us_setRestrictedDesktopAccess(true)
      // TODO - change something
    } else if (
      uc_RootData_ClientPermissions != null &&
      uc_RootData_ClientPermissions['client_type'] != null &&
      uc_RootData_ClientUser != null &&
      uc_RootData_ClientUser['user_role'] != null &&
      ClientTypes != null &&
      ClientTypes[uc_RootData_ClientPermissions['client_type']] != null &&
      ClientTypes[uc_RootData_ClientPermissions['client_type']]['user_roles'] != null &&
      ClientTypes[uc_RootData_ClientPermissions['client_type']]['user_roles'][uc_RootData_ClientUser['user_role']] != null &&
      ClientTypes[uc_RootData_ClientPermissions['client_type']]['user_roles'][uc_RootData_ClientUser['user_role']]['home_redirect_page'] != null &&
      ClientTypes[uc_RootData_ClientPermissions['client_type']]['user_roles'][uc_RootData_ClientUser['user_role']]['home_redirect_page']['key'] != null &&
      ClientTypes[uc_RootData_ClientPermissions['client_type']]['user_roles'][uc_RootData_ClientUser['user_role']]['home_redirect_page']['url'] != null
    ) {
      us_setRestrictedDesktopAccess(false)
      let redirectPageKey =
        ClientTypes[uc_RootData_ClientPermissions['client_type']]['user_roles'][uc_RootData_ClientUser['user_role']]['home_redirect_page']['key']
      setTimeout(() => {
        if (uc_RootData_UserPermissions != null && uc_RootData_UserPermissions[redirectPageKey] === true) {
          directAppNavigation(
            un_routerNavigation,
            ClientTypes[uc_RootData_ClientPermissions['client_type'] as string]['user_roles'][uc_RootData_ClientUser['user_role']]['home_redirect_page'][
              'url'
            ](),
          )
        }
      }, 1)
    } else {
      us_setRestrictedDesktopAccess(false)
    }
  }, [uc_RootData_ClientPermissions, uc_RootData_ClientUser, uc_RootData_UserPermissions, un_routerNavigation])

  useEffect(() => {
    if (
      uc_RootData_ClientPermissions != null &&
      uc_RootData_ClientPermissions['client_type'] != null &&
      uc_RootData_ClientUser != null &&
      uc_RootData_ClientUser['user_role'] != null &&
      uc_RootData_UserPermissions != null
    ) {
      let initialApplicationNavigationObject = generateApplicationNavigationObject(
        uc_RootData_ClientPermissions['client_type'],
        uc_RootData_ClientUser['user_role'],
        uc_RootData_ClientUser,
      )
      let permittedApplicationNavigationObject: TsInterface_NavigationObject = {}
      for (let loopSectionKey in initialApplicationNavigationObject) {
        let loopSection = initialApplicationNavigationObject[loopSectionKey]
        for (let loopLinkKey in loopSection['links']) {
          let loopLink = loopSection['links'][loopLinkKey]
          if (uc_RootData_UserPermissions != null && uc_RootData_UserPermissions[loopLinkKey] === true && loopLink != null) {
            if (permittedApplicationNavigationObject[loopSectionKey] == null) {
              permittedApplicationNavigationObject[loopSectionKey] = cloneObjectWithoutReference(loopSection)
              permittedApplicationNavigationObject[loopSectionKey]['links'] = {}
            }
            permittedApplicationNavigationObject[loopSectionKey]['links'][loopLinkKey] = loopLink
          }
        }
      }
      let sideBarNavObject = permittedApplicationNavigationObject
      let flatNavLinks: TsInterface_UnspecifiedObject[] = []
      for (let loopNavSectionKey in sideBarNavObject) {
        let loopNavSection = sideBarNavObject[loopNavSectionKey]
        if (loopNavSection != null && loopNavSection['links'] != null) {
          for (let pageKey in loopNavSection['links']) {
            let loopPage = loopNavSection['links'][pageKey]
            flatNavLinks.push(loopPage)
          }
        }
      }
      us_setNavPages(flatNavLinks)
    }
  }, [uc_RootData_UserPermissions, uc_RootData_ClientUser, uc_RootData_ClientPermissions])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setPowerwallOrderData(newData)
        setTimeout(() => {
          us_setFoundPowerwallOrder(true)
          ur_forceRerender()
        }, 1)
      } else {
        setTimeout(() => {
          us_setFoundPowerwallOrder(false)
          ur_forceRerender()
        }, 1)
      }
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.key != null && uc_RootData_ClientUser.key !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_PowerwallOrder_Document(res_GCK.clientKey, uc_RootData_ClientUser.key), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
          us_setFoundPowerwallOrder(false)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [ur_forceRerender, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_RootData_ClientUser])

  // Functions

  // JSX Generation
  const rJSX_DownloadAppLinks = (): JSX.Element => {
    let downloadAppLinksJSX = <></>
    let androidButton = (
      <Button
        variant="outlined"
        color="inherit"
        className="tw-m-2 tw-normal-case"
        onClick={() => {
          let url = 'https://play.google.com/store/apps/details?id=com.etwenergy&hl=en_US&gl=US'
          window.open(url, '_blank')
        }}
      >
        <Typography variant="h6">{s_DOWNLOAD_FOR_ANDROID}</Typography>
      </Button>
    )
    let iosButton = (
      <Button
        variant="outlined"
        color="inherit"
        className="tw-m-2 tw-normal-case"
        onClick={() => {
          let url = 'https://apps.apple.com/us/app/etw-energy/id6444813338'
          window.open(url, '_blank')
        }}
      >
        <Typography variant="h6">{s_DOWNLOAD_FOR_IOS}</Typography>
      </Button>
    )
    // Render based on device
    if (us_deviceOS === 'android') {
      downloadAppLinksJSX = <Box>{androidButton}</Box>
    } else if (us_deviceOS === 'ios') {
      downloadAppLinksJSX = <Box>{iosButton}</Box>
    } else if (us_deviceOS === 'other') {
      downloadAppLinksJSX = (
        <Box>
          {androidButton}
          {iosButton}
        </Box>
      )
    }
    return downloadAppLinksJSX
  }

  const rJSX_SupportTab = (): JSX.Element => {
    let supportTabJSX = <></>
    // export const supportEmail = "pwinstalls@etwenergy.com"
    // export const supportPhone = "512-200-2576"
    supportTabJSX = (
      <Box>
        <Typography
          variant="h6"
          className="tw-text-center"
        >
          {s_SUPPORT_TEXT}
        </Typography>
        <Typography
          variant="h6"
          className="tw-text-center"
        >
          {rLIB('Email')}:
          <Link
            href={`mailto:${supportEmail}`}
            target="_blank"
            className="tw-ml-2"
          >
            {supportEmail}
          </Link>
        </Typography>
        <Typography
          variant="h6"
          className="tw-text-center"
        >
          {s_PHONE}:
          <Link
            href={`tel:${supportPhone}`}
            target="_blank"
            className="tw-ml-2"
          >
            {supportPhone}
          </Link>
        </Typography>
      </Box>
    )
    return supportTabJSX
  }

  const rJSX_PowerwallOrder = (): JSX.Element => {
    let powerwallOrderJSX = <></>
    powerwallOrderJSX = (
      <Box
        className="tw-m-auto tw-mb-6"
        sx={{ maxWidth: '800px' }}
      >
        <Typography
          variant="h4"
          className="tw-text-center"
        >
          {s_POWERWALL_ORDER_SUMMARY}
        </Typography>
        <TabsBasic
          tabs={[
            {
              tabHeader: <>{s_TIMELINE}</>,
              tabContent: (
                <Box className="tw-p-2">
                  <Card
                    className="tw-m-auto tw-p-2"
                    sx={{ maxWidth: '800px' }}
                  >
                    {rJSX_defaultProjectTimeline()}
                  </Card>
                </Box>
              ),
            },
            {
              tabHeader: <>{s_CONTACT_INFO}</>,
              tabContent: (
                <Box className="tw-p-2">
                  <Card
                    className="tw-m-auto tw-p-2"
                    sx={{ maxWidth: '800px' }}
                  >
                    <Form
                      formAdditionalData={{
                        disabled_all_inputs: true,
                      }}
                      formData={us_powerwallOrderData}
                      formInputs={disabledFormInputs_ContactInfo}
                      formOnChange={(
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {
                        /* Nothing */
                      }}
                      formSettings={{
                        highlight_missing: false,
                        submit_button_hide: true,
                      }}
                      formSubmission={(
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          resolve({ success: true })
                        })
                      }}
                    />
                  </Card>
                </Box>
              ),
            },
            {
              tabHeader: <>{s_HOME_INFO}</>,
              tabContent: (
                <Box className="tw-p-2">
                  <Card
                    className="tw-m-auto tw-p-2"
                    sx={{ maxWidth: '800px' }}
                  >
                    <Form
                      formAdditionalData={{
                        disabled_all_inputs: true,
                      }}
                      formData={us_powerwallOrderData}
                      formInputs={disabledFormInputs_HomeInfo}
                      formOnChange={(
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {
                        /* Nothing */
                      }}
                      formSettings={{
                        highlight_missing: false,
                        submit_button_hide: true,
                      }}
                      formSubmission={(
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          resolve({ success: true })
                        })
                      }}
                    />
                  </Card>
                </Box>
              ),
            },
            {
              tabHeader: <>{s_BACKUP_LOADS}</>,
              tabContent: (
                <Box className="tw-p-2">
                  <Card
                    className="tw-m-auto tw-p-2"
                    sx={{ maxWidth: '800px' }}
                  >
                    <Form
                      formAdditionalData={{
                        disabled_all_inputs: true,
                        showImage: true,
                      }}
                      formData={us_powerwallOrderData}
                      formInputs={disabledFormInputs_BackupLoads}
                      formOnChange={(
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formData: TsInterface_FormData,
                        formInputs: TsInterface_FormInputs,
                        formSettings: TsInterface_FormSettings,
                      ) => {
                        /* Nothing */
                      }}
                      formSettings={{
                        highlight_missing: false,
                        submit_button_hide: true,
                      }}
                      formSubmission={(
                        formSubmittedData: TsInterface_FormSubmittedData,
                        formAdditionalData: TsInterface_FormAdditionalData,
                        formHooks: TsInterface_FormHooksObject,
                      ) => {
                        return new Promise((resolve, reject) => {
                          resolve({ success: true })
                        })
                      }}
                    />
                  </Card>
                </Box>
              ),
            },
            {
              tabHeader: <>{s_ORDER}</>,
              tabContent: <Card className="tw-p-2">{rJSX_OrderFinanceDetails(null, us_powerwallOrderData, null, null)}</Card>,
            },
            {
              tabHeader: <>{s_SUPPORT}</>,
              tabContent: <Card className="tw-p-2">{rJSX_SupportTab()}</Card>,
            },
          ]}
          tabsSettings={{}}
        />
      </Box>
    )
    return powerwallOrderJSX
  }

  const rJSX_HomePage = (): JSX.Element => {
    let pageJSX = <></>
    if (us_foundPowerwallOrder === true) {
      pageJSX = rJSX_PowerwallOrder()
    } else if (us_foundPowerwallOrder === false) {
      if (us_restrictedDesktopAccess === true) {
        pageJSX = (
          <Box className="tw-text-center">
            <Typography
              variant="h3"
              className="tw-mb-4"
            >
              {s_WELCOME}
            </Typography>
            <Typography
              variant="h5"
              className="tw-mb-4"
            >
              {s_DOWNLOAD_THE_ETW_APP_TO_ACCESS_YOUR_ACCOUNT}
            </Typography>
            <Box>{rJSX_DownloadAppLinks()}</Box>
          </Box>
        )
      } else if (us_restrictedDesktopAccess === false) {
        pageJSX = (
          <Box
            className="tw-text-center tw-m-auto"
            sx={{ maxWidth: '800px' }}
          >
            {objectToArray(us_navPages)
              .sort(dynamicSort('key', null))
              .map((page, pageIndex) => (
                <Box
                  onClick={(event) => {
                    onClickAppNavigation(event, un_routerNavigation, page.url)
                  }}
                  key={pageIndex}
                  className="tw-inline-block tw-text-center tw-m-2 tw-p-2 tw-border-2 tw-rounded-xl tw-border-solid tw-cursor-pointer"
                  sx={{
                    'height': '100px',
                    'width': '280px',
                    'borderColor': themeVariables.white,
                    '&:hover': {
                      backgroundColor: themeVariables.background_paper,
                      borderColor: themeVariables.info_main,
                      color: themeVariables.info_main,
                    },
                  }}
                >
                  <Box className="tw-mt-1">{page.icon_large}</Box>
                  <Box className="tw-mt-2">{page.name}</Box>
                </Box>
              ))}
          </Box>
        )
      }
    }
    return pageJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX: JSX.Element = (
      <AuthenticatedContainer
        pageHeader={<></>}
        pageKey={pageKey}
        content={<Box>{rJSX_HomePage()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

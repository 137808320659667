///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Services contain business logic that is completely abstracted from interfaces

		TODO:

			[ ] TYPESCRIPT REFACTOR STATUS:
				3	any
				0	UnspecifiedObjectInterface
				0	@ts-expect-error
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { DatabaseRef_MessageThread_Document } from 'rfbp_aux/services/database_endpoints/operations/messages'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { TsInterface_GenericPromiseReject, TsInterface_GenericPromiseResolve } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Types
///////////////////////////////

// interface TsInterface_ChatMemberObject {
// 	[ userKey: string ]: boolean
// }

interface TsInterface_MessageThreadUpdateObject {
  key?: string
  associated_member_last_read: {
    [userKey: string]: Date
  }
  associated_member_unread_count: {
    [userKey: string]: number
  }
  associated_member_unread: {
    [userKey: string]: boolean
  }
  associated_member_names?: {
    [userKey: string]: string
  }
  associated_member_keys?: {
    [userKey: string]: string
  }
  associated_project_key?: string
  last_message_sender_key?: string
  last_message_sender_name?: string
  last_message_text?: string
  last_message_timestamp?: Date
  total_members?: number
  thread_name_override_desktop?: string | null
}

// interface TsInterface_MemberKeysObject {
// 	[ userKey: string ]: boolean
// }

// interface TsInterface_MemberLastReadObject {
// 	[ userKey: string ]: number
// }

// interface TsInterface_MemberNamesObject {
// 	[ userKey: string ]: string
// }

// interface TsInterface_MemberUnreadObject {
// 	[ userKey: string ]: number
// }

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

// const returnChatTypeAndKeys = ( threadKey: string ): TsInterface_UnspecifiedObject => {
// let lastUnderscore = threadKey.lastIndexOf("_")
// let projectKey = threadKey.substring(0, lastUnderscore)
// let threadType = threadKey.substring(lastUnderscore + 1, threadKey.length)
// return {
// 	threadKey: threadKey,
// 	projectKey: projectKey,
// 	threadType: threadType,
// }
// }

///////////////////////////////
// Exports
///////////////////////////////

export const sendMessage = (
  senderKey: string,
  senderName: string,
  clientKey: string,
  messageThreadKey: string,
  message: string,
): Promise<TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve> => {
  return new Promise((resolve, reject) => {
    cloudFunctionManageRequest('manageMessages', {
      function: 'sendMessage',
      sender_name: senderName,
      client_key: clientKey,
      message_thread_key: messageThreadKey,
      message: message,
    })
      .then((res_CFMMR: any) => {
        resolve(res_CFMMR)
      })
      .catch((rej_CFMMR) => {
        reject(rej_CFMMR)
      })
  })
}

export const markMessageAsRead = (
  userKey: string,
  clientKey: string,
  messageThreadKey: string,
  lastReadTimestamp: Date,
): Promise<TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve> => {
  return new Promise((resolve, reject) => {
    if (lastReadTimestamp == null) {
      lastReadTimestamp = new Date()
    }
    if (userKey != null && clientKey != null && messageThreadKey != null) {
      let messageThreadUpdateObject: TsInterface_MessageThreadUpdateObject = {
        associated_member_last_read: {},
        associated_member_unread_count: {},
        associated_member_unread: {},
      }
      messageThreadUpdateObject['associated_member_last_read'][userKey] = lastReadTimestamp
      messageThreadUpdateObject['associated_member_unread_count'][userKey] = 0
      messageThreadUpdateObject['associated_member_unread'][userKey] = false
      DatabaseSetMergeDocument(DatabaseRef_MessageThread_Document(clientKey, messageThreadKey), messageThreadUpdateObject)
        .then((res_DSMD) => {
          resolve(res_DSMD)
        })
        .catch((rej_DSMD) => {
          reject(rej_DSMD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Unable to mark message as read'),
          details: rLIB('Missing Required Parameters'),
          code: 'ER-S-S-M-MMAR-01',
        },
      })
    }
  })
}

export const markMessageAsUnread = (
  userKey: string,
  clientKey: string,
  messageThreadKey: string,
  lastReadTimestamp: Date,
  unreadCount: number,
): Promise<TsInterface_GenericPromiseReject | TsInterface_GenericPromiseResolve> => {
  return new Promise((resolve, reject) => {
    if (lastReadTimestamp == null) {
      lastReadTimestamp = new Date()
    }
    if (unreadCount == null) {
      unreadCount = 1
    }
    if (userKey != null && clientKey != null && messageThreadKey != null && !isNaN(unreadCount)) {
      let messageThreadUpdateObject: TsInterface_MessageThreadUpdateObject = {
        associated_member_last_read: {},
        associated_member_unread_count: {},
        associated_member_unread: {},
      }
      messageThreadUpdateObject['associated_member_last_read'][userKey] = lastReadTimestamp
      messageThreadUpdateObject['associated_member_unread_count'][userKey] = unreadCount
      messageThreadUpdateObject['associated_member_unread'][userKey] = true
      DatabaseSetMergeDocument(DatabaseRef_MessageThread_Document(clientKey, messageThreadKey), messageThreadUpdateObject)
        .then((res_DSMD) => {
          resolve(res_DSMD)
        })
        .catch((rej_DSMD) => {
          reject(rej_DSMD)
        })
    } else {
      reject({
        success: false,
        error: {
          message: rLIB('Unable to mark message as unread'),
          details: rLIB('Missing Required Parameters'),
          code: 'ER-S-S-M-MMAU-01',
        },
      })
    }
  })
}

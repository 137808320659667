///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

        	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { eachWeekOfInterval, endOfMonth, format, isWithinInterval, startOfMonth, startOfWeek } from 'date-fns'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_TaskBlueprints_Collection } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import {
  DatabaseRef_CombinedVehiclesByDate_Query,
  DatabaseRef_CycleTimeByActiveProject_Query,
  DatabaseRef_CycleTimeByDate_Query,
  DatabaseRef_NewCrewHoursByDate_Query,
  DatabaseRef_PanelsInstalledByDate_Query,
  DatabaseRef_SafetyReviewByDate_Query,
} from 'rfbp_aux/services/database_endpoints/operations/gm_dashboards'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_CustomDialog, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { downloadCSV, formatDateToYYYYMMDD, objectToArray, returnFormattedDateKey } from '../../../rfbp_core/services/helper_functions/index'
import { GMDashboardHeatmap } from './components/gm_dashboard_heatmap'

import { getDocs } from 'firebase/firestore'
import { DatabaseRef_SalesPartner_Collection } from 'rfbp_aux/services/database_endpoints/directory/sales_partners'
import { DatabaseRef_FinalizedPayroll_EmployeeFullPayrollDataByDateAndName_Query } from 'rfbp_aux/services/database_endpoints/finances/finalized_payroll'
import { DatabaseRef_ScheduledTimeOff_Query } from 'rfbp_aux/services/database_endpoints/operations/schedules'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { processCrewHoursData } from './services/data_crew_hours'
import { processCycleTimeCSToInstallData } from './services/data_cycle_time_cs_to_install'
import { processCycleTimeCSToPermitRequested } from './services/data_cycle_time_cs_to_permit_requested'
import { processCycleTimeCSToPto } from './services/data_cycle_time_cs_to_pto'
import { processCycleTimePermitApprovalToInstallData } from './services/data_cycle_time_permit_approval_to_install'
import { processCycleTimeRtgToInstallData } from './services/data_cycle_time_rtg_to_install'
import { processPanelsInstalledData } from './services/data_panels_installed'
import { processSafetyReviewData } from './services/data_safety_review'
import { processVehicleMilesData } from './services/data_vehicle_usage'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['GMDashboardIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  const currentDate = new Date()
  const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1)
  const lastDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
  const [us_selectedStartDate, us_setSelectedStartDate] = useState<Date>(firstDayOfPreviousMonth)
  const [us_selectedEndDate, us_setSelectedEndDate] = useState<Date>(lastDayOfCurrentMonth)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const [us_refreshingColumn, us_setRefreshingColumn] = useState<string | null>(null)
  const [us_mondaysInDateRange, us_setMondaysInDateRange] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawCrewHours, us_setRawCrewHours] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedCrewHours, us_setFormattedCrewHours] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalCrewHours, us_setFinalCrewHours] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedSafetyReviews, us_setFormattedSafetyReviews] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalSafetyReviews, us_setFinalSafetyReviews] = useState<TsInterface_UnspecifiedObject>({})
  const [us_salesPartners, us_setSalesPartners] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawPanelsInstalled, us_setRawPanelsInstalled] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedPanelsInstalled, us_setFormattedPanelsInstalled] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalPanelsInstalled, us_setFinalPanelsInstalled] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawRTGToInstall, us_setRawRTGToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedRTGToInstall, us_setFormattedRTGToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalRTGToInstall, us_setFinalRTGToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const [us_downloadingCycleTimes, us_setDownloadingCycleTimes] = useState<boolean>(false)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const [us_organizedTimeOff, us_setOrganizedTimeOff] = useState<any>({})
  const [us_formattedCSToInstall, us_setFormattedCSToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalCSToInstall, us_setFinalCSToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedPermitApprovalToInstall, us_setFormattedPermitApprovalToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalPermitApprovalToInstall, us_setFinalPermitApprovalToInstall] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedCsToPto, us_setFormattedCsToPto] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalCsToPto, us_setFinalCsToPto] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedCsToPermitRequested, us_setFormattedCsToPermitRequested] = useState<TsInterface_UnspecifiedObject>({})
  const [us_finalCsToPermitRequested, us_setFinalCsToPermitRequested] = useState<TsInterface_UnspecifiedObject>({})
  const [us_combinedVehicles, us_setCombinedVehicles] = useState<any>({})
  const [us_formattedCombinedVehicles, us_setFormattedCombinedVehicles] = useState<any>({})
  const [us_finalCombinedVehicles, us_setFinalCombinedVehicles] = useState<any>({})

  //DO NOT DELETE: For testing caching functions
  // useEffect(() => {
  //   cloudFunctionManageRequest('manageData', {
  //     function: 'cacheGMDashboardPanelsInstalledData',
  //     client_key: 'etw_energy',
  //     monday_date_key: new Date('2024-07-08 00:00:00').toISOString(),
  //   })
  //     .then((res_CFMR) => {
  //       console.log(res_CFMR)
  //       us_setRefreshingColumn(null)
  //     })
  //     .catch((rej_CFMR) => {
  //       console.error(rej_CFMR)
  //       us_setRefreshingColumn(null)
  //     })
  // }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('GM Dashboard', false) as string
  }, [])

  //Get Mondays in Date Range
  useEffect(() => {
    const interval = { start: us_selectedStartDate, end: us_selectedEndDate }

    // Generate all the Mondays within the interval
    const mondays = eachWeekOfInterval(interval, { weekStartsOn: 1 })
      .map((weekStart) => startOfWeek(weekStart, { weekStartsOn: 1 }))
      .filter((monday) => isWithinInterval(monday, interval)) // Check if the Monday is within the interval
      .map((monday) => monday.toISOString().split('T')[0]) // Convert to string format

    us_setMondaysInDateRange(mondays)
  }, [us_selectedStartDate, us_selectedEndDate, ur_forceRerender])

  // Get Regions
  // useEffect(() => {
  //   let unsubscribeLiveData: TsType_VoidFunction
  //   const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
  //     //us_setRegionsLists(newData)
  //     ur_forceRerender()
  //   }
  //   getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  //     .then((res_GCK) => {
  //       unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Regions_Collection(res_GCK.clientKey), updateLiveData)
  //     })
  //     .catch((rej_GCK) => {
  //       console.error(rej_GCK)
  //     })
  //   return () => {
  //     if (typeof unsubscribeLiveData === 'function') {
  //       unsubscribeLiveData()
  //     }
  //   }
  // }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  //Get and process Safety Reviews
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // Process the raw data using the service function
      const processedData = processSafetyReviewData(newData, us_mondaysInDateRange as any)

      // Set the processed data into the state
      us_setFormattedSafetyReviews(processedData)

      ur_forceRerender()
    }

    if (!uc_RootData_ClientKey) {
      return
    }

    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SafetyReviewByDate_Query(uc_RootData_ClientKey, startDate, endDate), updateLiveData)

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, ur_forceRerender, us_mondaysInDateRange, us_refreshingColumn])

  //handle refreshing column logic for safety reviews
  useEffect(() => {
    if (!us_formattedSafetyReviews || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedSafetyReviews,
      'safety_reviews_', // dashboardName prefix
      'cacheGMDashboardSafetyReviewData', // corresponding cloud function
    )

    // Save the final data with dialogs into the state
    us_setFinalSafetyReviews(refreshedData)
  }, [us_formattedSafetyReviews, us_mondaysInDateRange])

  //get time off data
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      const organizedTimeOffData = organizeTimeOffByWeekAndTeam(newData, us_mondaysInDateRange as any)
      us_setOrganizedTimeOff(organizedTimeOffData) // Assuming you have a state to store this data
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ScheduledTimeOff_Query(res_GCK.clientKey, startDate, endDate), updateLiveData)
      })
      .catch((rej_GCK) => {})

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, us_mondaysInDateRange])

  //Get and process panels installed
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRawPanelsInstalled(newData)

      // Process the raw data using the service function only if time off data is available
      if (us_organizedTimeOff && Object.keys(us_organizedTimeOff).length > 0) {
        const processedData = processPanelsInstalledData(newData, us_mondaysInDateRange as any, us_organizedTimeOff)

        // Set the processed data into the state
        us_setFormattedPanelsInstalled(processedData as any)

        ur_forceRerender()
      }
    }

    if (!uc_RootData_ClientKey || !us_organizedTimeOff || Object.keys(us_organizedTimeOff).length === 0) {
      return
    }

    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_PanelsInstalledByDate_Query(uc_RootData_ClientKey, startDate, endDate), updateLiveData)

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, us_mondaysInDateRange, us_organizedTimeOff, us_refreshingColumn])

  //Handle refreshing column logic and dialog for panels installed

  useEffect(() => {
    if (!us_formattedPanelsInstalled || !us_mondaysInDateRange) return

    // Handle the refresh columns

    const refreshedData = handleRefreshColumns(us_formattedPanelsInstalled, 'panels_installed_', 'cacheGMDashboardPanelsInstalledData')

    // Handle the dialog setup
    const finalData = handleDialogSetup(refreshedData)

    // Save the final data into a new state
    us_setFinalPanelsInstalled(finalData)
  }, [us_formattedPanelsInstalled, us_mondaysInDateRange])

  const handleDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any) =>
      row.map((cell: any) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('/')) {
          const [installedPanels, expectedPanels] = cell.cellValue.split('/').map(Number)
          const dateKey = cell.dateKey
          const teamName = row[0].cellValue

          return {
            ...cell,
            dialogFunction: () => {
              const teamDetails = us_rawPanelsInstalled[dateKey]?.data?.[teamName] || {}

              if (!teamDetails.tasks) return

              const totalInstalled = teamDetails.tasks.reduce((acc: any, task: any) => acc + task.panels + task.batteries * 10, 0)
              const totalPanels = teamDetails.tasks.reduce((acc: any, task: any) => acc + task.panels, 0)
              const totalBatteries = teamDetails.tasks.reduce((acc: any, task: any) => acc + task.batteries, 0)

              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">
                          {teamName} - {dateKey}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: installedPanels >= expectedPanels ? '#28a56c' : '#c82424',
                            fontWeight: 'bold',
                          }}
                        >
                          {installedPanels} / {expectedPanels} panels
                        </Typography>

                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Task Name')}</TableCell>
                                <TableCell>{rLIB('Completion Date')}</TableCell>
                                <TableCell>{rLIB('Panels')}</TableCell>
                                <TableCell>{rLIB('Batteries')}</TableCell>
                                <TableCell>{rLIB('Total')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {teamDetails.tasks
                                .sort((a: any, b: any) => new Date(a.latest_task_completion_date).getTime() - new Date(b.latest_task_completion_date).getTime())
                                .map((task: any, index: any) => (
                                  <TableRow key={index}>
                                    <TableCell>{task.associated_project_id_number}</TableCell>
                                    <TableCell>{task.project_name}</TableCell>
                                    <TableCell>
                                      {new Date(
                                        new Date(task.latest_task_completion_date).setDate(new Date(task.latest_task_completion_date).getDate() + 1),
                                      ).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell>{task.panels}</TableCell>
                                    <TableCell>{task.batteries}</TableCell>
                                    <TableCell>{task.panels + task.batteries * 10}</TableCell>
                                  </TableRow>
                                ))}
                              <TableRow>
                                <TableCell className="tw-font-bold">Total</TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell className="tw-font-bold">{totalPanels}</TableCell>
                                <TableCell className="tw-font-bold">{totalBatteries}</TableCell>
                                <TableCell className="tw-font-bold">{totalInstalled}</TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: installedPanels > 0 ? 'pointer' : 'default',
            },
          }
        }
        return cell
      }),
    )

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //cycle time rtg - install
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSalesPartners(newData)
    }

    if (!uc_RootData_ClientKey) {
      return
    }

    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SalesPartner_Collection(uc_RootData_ClientKey), updateLiveData)

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey])

  useEffect(() => {
    if (!us_salesPartners) return // Wait for sales partners data

    let unsubscribeLiveData: TsType_VoidFunction

    const updateLiveData = (newData: any) => {
      const enrichedData = Object.keys(newData).reduce((acc: any, dateKey: any) => {
        const dailyData = newData[dateKey].data
        const enrichedDailyData = Object.keys(dailyData).reduce((accInner: any, projectKey: any) => {
          const projectData = dailyData[projectKey]
          const salesPartnerName = us_salesPartners[projectData.associated_sales_partner_key]?.name || 'Unknown Partner'
          accInner[projectKey] = { ...projectData, salesPartnerName }
          return accInner
        }, {})
        acc[dateKey] = { ...newData[dateKey], data: enrichedDailyData }
        return acc
      }, {})

      us_setRawRTGToInstall(enrichedData)
    }

    if (!uc_RootData_ClientKey) {
      return
    }

    const newStartDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const newEndDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_CycleTimeByDate_Query(uc_RootData_ClientKey, newStartDate, newEndDate), updateLiveData)

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, us_selectedStartDate, us_selectedEndDate, us_salesPartners])

  useEffect(() => {
    if (!us_rawRTGToInstall || !us_salesPartners || !us_mondaysInDateRange) return

    const processedData = processCycleTimeRtgToInstallData(us_rawRTGToInstall, us_mondaysInDateRange as any, us_salesPartners)
    us_setFormattedRTGToInstall(processedData as any)
  }, [us_rawRTGToInstall, us_salesPartners, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedRTGToInstall || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedRTGToInstall,
      'cycle_times_rtg_to_install', // dashboardName prefix
      'cacheGMDashboardCycleTimesData', // corresponding cloud function
    )

    // Avoid unnecessary state updates by checking if the data has actually changed

    const finalData = handleCycleTimeDialogSetup(refreshedData)
    us_setFinalRTGToInstall(finalData)
  }, [us_formattedRTGToInstall, us_mondaysInDateRange])

  const handleCycleTimeDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) => {
      return row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('days')) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const partnerRegionKey = row[0].cellValue // First cell contains the partner and region key

          return {
            ...cell,
            dialogFunction: () => {
              // Extract data related to the dialog
              const detailData = us_rawRTGToInstall[formattedDateKey]?.data || {}
              const dialogEntries = Object.values(detailData)
                .filter((d: any) => `${d.salesPartnerName} - ${d.associated_region_name}` === partnerRegionKey)
                .sort((a: any, b: any) => new Date(a.install_date).getTime() - new Date(b.install_date).getTime())

              const validEntries = dialogEntries.filter((entry: any) => entry.time_delay_from_rtg_to_install !== 0)
              const totalDelay = validEntries.reduce((acc: number, cur: any) => acc + cur.time_delay_from_rtg_to_install, 0)
              const averageDelay = validEntries.length > 0 ? totalDelay / validEntries.length : 0

              // Show the dialog with the details
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">{partnerRegionKey}</Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Region')}</TableCell>
                                <TableCell>{rLIB('PICC/RTG')}</TableCell>
                                <TableCell>{rLIB('Install')}</TableCell>
                                <TableCell>{rLIB('Delay (Days)')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {validEntries.map((project: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{project.id_number}</TableCell>
                                  <TableCell>{project.associated_region_name}</TableCell>
                                  <TableCell>{new Date(project.install_picc_rtg).toISOString().split('T')[0]}</TableCell>
                                  <TableCell>{project.install_date}</TableCell>
                                  <TableCell>{project.time_delay_from_rtg_to_install}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {rLIB('Average Delay')}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor: averageDelay <= 14 ? themeVariables.success_main : themeVariables.error_main,
                                    color: '#FFFFFF',
                                  }}
                                >
                                  {validEntries.length > 0 ? `${Math.round(averageDelay)} days` : '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: 'pointer', // Make the cell clickable
            },
          }
        }
        return cell
      })
    })

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //cycle time CS - Install
  useEffect(() => {
    if (!us_rawRTGToInstall || !us_salesPartners || !us_mondaysInDateRange) return

    const processedData = processCycleTimeCSToInstallData(us_rawRTGToInstall, us_mondaysInDateRange as any, us_salesPartners)
    us_setFormattedCSToInstall(processedData as any)
  }, [us_rawRTGToInstall, us_salesPartners, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedCSToInstall || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedCSToInstall,
      'cycle_times_cs-to-install', // dashboardName prefix
      'cacheGMDashboardCycleTimesData', // corresponding cloud function
    )

    const finalData = handleCycleTimeCSToInstallDialogSetup(refreshedData)
    us_setFinalCSToInstall(finalData)
  }, [us_formattedCSToInstall, us_mondaysInDateRange])

  const handleCycleTimeCSToInstallDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) => {
      return row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('days')) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const partnerRegionKey = row[0].cellValue // First cell contains the partner and region key

          return {
            ...cell,
            dialogFunction: () => {
              // Extract data related to the dialog
              const detailData = us_rawRTGToInstall[formattedDateKey]?.data || {}
              const dialogEntries = Object.values(detailData)
                .filter((d: any) => `${d.salesPartnerName} - ${d.associated_region_name}` === partnerRegionKey)
                .sort((a: any, b: any) => new Date(a.install_date).getTime() - new Date(b.install_date).getTime())

              const validEntries = dialogEntries.filter((entry: any) => entry.time_delay_from_cs_to_install !== 0)
              const totalDelay = validEntries.reduce((acc: number, cur: any) => acc + cur.time_delay_from_cs_to_install, 0)
              const averageDelay = validEntries.length > 0 ? totalDelay / validEntries.length : 0

              // Show the dialog with the details
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">{partnerRegionKey}</Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Region')}</TableCell>
                                <TableCell>{rLIB('Customer Signature')}</TableCell>
                                <TableCell>{rLIB('Install')}</TableCell>
                                <TableCell>{rLIB('Delay (Days)')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {validEntries.map((project: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{project.id_number}</TableCell>
                                  <TableCell>{project.associated_region_name}</TableCell>
                                  <TableCell>{new Date(project.sale_customer_signature).toISOString().split('T')[0]}</TableCell>
                                  <TableCell>{project.install_date}</TableCell>
                                  <TableCell>{project.time_delay_from_cs_to_install}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {rLIB('Average Delay')}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor: averageDelay <= 14 ? themeVariables.success_main : themeVariables.error_main,
                                    color: '#FFFFFF',
                                  }}
                                >
                                  {validEntries.length > 0 ? `${Math.round(averageDelay)} days` : '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: 'pointer', // Make the cell clickable
            },
          }
        }
        return cell
      })
    })

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //cycle time Permit Approval - Install
  useEffect(() => {
    if (!us_rawRTGToInstall || !us_salesPartners || !us_mondaysInDateRange) return

    const processedData = processCycleTimePermitApprovalToInstallData(us_rawRTGToInstall, us_mondaysInDateRange as any, us_salesPartners)
    us_setFormattedPermitApprovalToInstall(processedData as any)
  }, [us_rawRTGToInstall, us_salesPartners, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedPermitApprovalToInstall || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedPermitApprovalToInstall,
      'cycle_times_permit_approval_to_install', // dashboardName prefix
      'cacheGMDashboardCycleTimesData', // corresponding cloud function
    )

    // Avoid unnecessary state updates by checking if the data has actually changed

    const finalData = handleCycleTimePermitApprovalToInstallDialogSetup(refreshedData)
    us_setFinalPermitApprovalToInstall(finalData)
  }, [us_formattedPermitApprovalToInstall, us_mondaysInDateRange])

  const handleCycleTimePermitApprovalToInstallDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) => {
      return row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('days')) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const partnerRegionKey = row[0].cellValue // First cell contains the partner and region key

          return {
            ...cell,
            dialogFunction: () => {
              // Extract data related to the dialog
              const detailData = us_rawRTGToInstall[formattedDateKey]?.data || {}
              const dialogEntries = Object.values(detailData)
                .filter((d: any) => `${d.salesPartnerName} - ${d.associated_region_name}` === partnerRegionKey)
                .sort((a: any, b: any) => new Date(a.install_date).getTime() - new Date(b.install_date).getTime())

              const validEntries = dialogEntries.filter((entry: any) => entry.time_delay_from_permit_approval_to_install !== 0)
              const totalDelay = validEntries.reduce((acc: number, cur: any) => acc + cur.time_delay_from_permit_approval_to_install, 0)
              const averageDelay = validEntries.length > 0 ? totalDelay / validEntries.length : 0

              // Show the dialog with the details
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">{partnerRegionKey}</Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Region')}</TableCell>
                                <TableCell>{rLIB('Permit Approval')}</TableCell>
                                <TableCell>{rLIB('Install')}</TableCell>
                                <TableCell>{rLIB('Delay (Days)')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {validEntries.map((project: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{project.id_number}</TableCell>
                                  <TableCell>{project.associated_region_name}</TableCell>
                                  <TableCell>{new Date(project.permit_approval).toISOString().split('T')[0]}</TableCell>
                                  <TableCell>{project.install_date}</TableCell>
                                  <TableCell>{project.time_delay_from_permit_approval_to_install}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {rLIB('Average Delay')}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor: averageDelay <= 14 ? themeVariables.success_main : themeVariables.error_main,
                                    color: '#FFFFFF',
                                  }}
                                >
                                  {validEntries.length > 0 ? `${Math.round(averageDelay)} days` : '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: 'pointer', // Make the cell clickable
            },
          }
        }
        return cell
      })
    })

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //cycle time CS - PTO
  useEffect(() => {
    if (!us_rawRTGToInstall || !us_salesPartners || !us_mondaysInDateRange) return

    const processedData = processCycleTimeCSToPto(us_rawRTGToInstall, us_mondaysInDateRange as any, us_salesPartners)
    us_setFormattedCsToPto(processedData as any)
  }, [us_rawRTGToInstall, us_salesPartners, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedCsToPto || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedCsToPto,
      'cycle_times_cs_to_pto', // dashboardName prefix
      'cacheGMDashboardCycleTimesData', // corresponding cloud function
    )

    const finalData = handleCycleTimeCsToPtoDialogSetup(refreshedData)
    us_setFinalCsToPto(finalData)
  }, [us_formattedCsToPto, us_mondaysInDateRange])

  const handleCycleTimeCsToPtoDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) => {
      return row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('days')) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const partnerRegionKey = row[0].cellValue // First cell contains the partner and region key

          return {
            ...cell,
            dialogFunction: () => {
              // Extract data related to the dialog
              const detailData = us_rawRTGToInstall[formattedDateKey]?.data || {}
              const dialogEntries = Object.values(detailData)
                .filter((d: any) => `${d.salesPartnerName} - ${d.associated_region_name}` === partnerRegionKey)
                .sort((a: any, b: any) => new Date(a.install_date).getTime() - new Date(b.install_date).getTime())

              const validEntries = dialogEntries.filter((entry: any) => entry.time_delay_from_customer_signature_to_pto !== 0)
              const totalDelay = validEntries.reduce((acc: number, cur: any) => acc + cur.time_delay_from_customer_signature_to_pto, 0)
              const averageDelay = validEntries.length > 0 ? totalDelay / validEntries.length : 0

              // Show the dialog with the details
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">{partnerRegionKey}</Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Region')}</TableCell>
                                <TableCell>{rLIB('Customer Signature')}</TableCell>
                                <TableCell>{rLIB('PTO')}</TableCell>
                                <TableCell>{rLIB('Delay (Days)')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {validEntries.map((project: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{project.id_number}</TableCell>
                                  <TableCell>{project.associated_region_name}</TableCell>
                                  <TableCell>{new Date(project.sale_customer_signature).toISOString().split('T')[0]}</TableCell>
                                  <TableCell>{new Date(project.activation_final_interconnection_approval).toISOString().split('T')[0]}</TableCell>

                                  <TableCell>{project.time_delay_from_customer_signature_to_pto}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {rLIB('Average Delay')}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor: averageDelay <= 14 ? themeVariables.success_main : themeVariables.error_main,
                                    color: '#FFFFFF',
                                  }}
                                >
                                  {validEntries.length > 0 ? `${Math.round(averageDelay)} days` : '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: 'pointer', // Make the cell clickable
            },
          }
        }
        return cell
      })
    })

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //cycle time CS - Permit Requested
  useEffect(() => {
    if (!us_rawRTGToInstall || !us_salesPartners || !us_mondaysInDateRange) return

    const processedData = processCycleTimeCSToPermitRequested(us_rawRTGToInstall, us_mondaysInDateRange as any, us_salesPartners)
    us_setFormattedCsToPermitRequested(processedData as any)
  }, [us_rawRTGToInstall, us_salesPartners, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedCsToPermitRequested || !us_mondaysInDateRange) return

    // Use the generalized refresh function
    const refreshedData = handleRefreshColumns(
      us_formattedCsToPermitRequested,
      'cycle_times_cs_to_permit_requested', // dashboardName prefix
      'cacheGMDashboardCycleTimesData', // corresponding cloud function
    )

    const finalData = handleCycleTimeCsToPermitRequestedDialogSetup(refreshedData)
    us_setFinalCsToPermitRequested(finalData)
  }, [us_formattedCsToPermitRequested, us_mondaysInDateRange])

  const handleCycleTimeCsToPermitRequestedDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) => {
      return row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && cell.cellValue.includes('days')) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const partnerRegionKey = row[0].cellValue // First cell contains the partner and region key

          return {
            ...cell,
            dialogFunction: () => {
              // Extract data related to the dialog
              const detailData = us_rawRTGToInstall[formattedDateKey]?.data || {}
              const dialogEntries = Object.values(detailData)
                .filter((d: any) => `${d.salesPartnerName} - ${d.associated_region_name}` === partnerRegionKey)
                .sort((a: any, b: any) => new Date(a.install_date).getTime() - new Date(b.install_date).getTime())

              const validEntries = dialogEntries.filter((entry: any) => entry.time_delay_from_customer_signature_to_permit_requested !== 0)
              const totalDelay = validEntries.reduce((acc: number, cur: any) => acc + cur.time_delay_from_customer_signature_to_permit_requested, 0)
              const averageDelay = validEntries.length > 0 ? totalDelay / validEntries.length : 0

              // Show the dialog with the details
              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <Typography variant="h6">{partnerRegionKey}</Typography>
                        <TableContainer component={Paper}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>{rLIB('Job Code')}</TableCell>
                                <TableCell>{rLIB('Region')}</TableCell>
                                <TableCell>{rLIB('Customer Signature')}</TableCell>
                                <TableCell>{rLIB('Permit Requested')}</TableCell>
                                <TableCell>{rLIB('Delay (Days)')}</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {validEntries.map((project: any, index: number) => (
                                <TableRow key={index}>
                                  <TableCell>{project.id_number}</TableCell>
                                  <TableCell>{project.associated_region_name}</TableCell>
                                  <TableCell>{new Date(project.sale_customer_signature).toISOString().split('T')[0]}</TableCell>
                                  <TableCell>{new Date(project.permitting_and_approvals_building_permit_submission).toISOString().split('T')[0]}</TableCell>

                                  <TableCell>{project.time_delay_from_customer_signature_to_permit_requested}</TableCell>
                                </TableRow>
                              ))}
                              <TableRow>
                                <TableCell
                                  colSpan={4}
                                  style={{ fontWeight: 'bold' }}
                                >
                                  {rLIB('Average Delay')}
                                </TableCell>
                                <TableCell
                                  style={{
                                    fontWeight: 'bold',
                                    backgroundColor: averageDelay <= 14 ? themeVariables.success_main : themeVariables.error_main,
                                    color: '#FFFFFF',
                                  }}
                                >
                                  {validEntries.length > 0 ? `${Math.round(averageDelay)} days` : '-'}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              ...cell.conditionalFormatting,
              cursor: 'pointer', // Make the cell clickable
            },
          }
        }
        return cell
      })
    })

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //get crew hours
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRawCrewHours(newData)
      ur_forceRerender()
    }
    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_NewCrewHoursByDate_Query(res_GCK.clientKey, startDate, endDate), updateLiveData)
      })
      .catch((rej_GCK) => {})
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedStartDate, us_selectedEndDate, ur_forceRerender])

  useEffect(() => {
    // Ensure all necessary data is available before calling the service function
    if (!us_rawCrewHours || !us_mondaysInDateRange) return

    // Call the service function to process the data
    const processedData = processCrewHoursData(us_rawCrewHours, us_mondaysInDateRange as any)

    // Save the processed data to the state
    us_setFormattedCrewHours(processedData as any)
  }, [us_rawCrewHours, us_salesPartners, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedCrewHours || !us_mondaysInDateRange) return

    // Handle the refresh columns
    const refreshedData = handleRefreshColumns(
      us_formattedCrewHours,
      'crew_hours_', // dashboardName prefix
      'cacheGMDashboardCrewHoursData', // corresponding cloud function
    )

    // Apply the dialog setup to the refreshed data
    const finalData = handleCrewHoursDialogSetup(refreshedData)

    // Save the final data into a new state
    us_setFinalCrewHours(finalData)
  }, [us_formattedCrewHours, us_mondaysInDateRange])

  const handleCrewHoursDialogSetup = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      return data // Return original data if structure is invalid
    }

    const newDataRows = data.data_rows.map((row: any, rowIndex: number) =>
      row.map((cell: any, cellIndex: number) => {
        if (typeof cell.cellValue === 'string' && !isNaN(Number(cell.cellValue))) {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1] // Extract the corresponding date key
          const personName = row[0].cellValue // First cell contains the person's name
          let personKey: string | undefined

          // Find the correct key for the person based on the name
          Object.entries(us_rawCrewHours[formattedDateKey]?.data || {}).forEach(([key, data]: [string, any]) => {
            if (data.name === personName) {
              personKey = key
            }
          })

          if (personKey) {
            return {
              ...cell,
              dialogFunction: () => {
                const formattedDateForQuery = formatDateToYYYYMMDD(new Date(new Date(formattedDateKey).setDate(new Date(formattedDateKey).getDate() + 1)))

                const query = DatabaseRef_FinalizedPayroll_EmployeeFullPayrollDataByDateAndName_Query(
                  uc_RootData_ClientKey as any,
                  formattedDateForQuery,
                  personKey!,
                )

                getDocs(query)
                  .then((snapshot) => {
                    const fetchedData = snapshot.docs.map((doc) => doc.data())

                    const projectDetails = fetchedData.flatMap((data: any) => {
                      return Object.entries(data.grouped_codes.FIELD || {}).map(([jobCode, hours]: [string, any]) => {
                        const [job, jobType] = jobCode.split(' - ')
                        const taskBreakdown: any = Object.values(data.unit_pay_task_breakdown || {}).find((task: any) => task.key === jobCode)

                        let panels = 0
                        let units = 0
                        let total: any = '-'
                        let shouldIncludeHoursInTotal = true

                        if (taskBreakdown && taskBreakdown.breakdown) {
                          if (taskBreakdown.task_unit_evaluation === 'units') {
                            units = taskBreakdown.breakdown.task_units
                            panels = 0
                            total =
                              typeof hours === 'number' ? hours - (units + taskBreakdown.breakdown.battery_units + taskBreakdown.breakdown.pitch_units) : '-'
                          } else if (taskBreakdown.task_unit_evaluation === 'panels') {
                            units = 0
                            panels = taskBreakdown.breakdown.task_units
                            total =
                              typeof hours === 'number'
                                ? hours - (panels + taskBreakdown.breakdown.battery_units + taskBreakdown.breakdown.pitch_units) / 2
                                : '-'
                          }
                          shouldIncludeHoursInTotal = !(
                            units === 0 &&
                            panels === 0 &&
                            taskBreakdown.breakdown.battery_units === 0 &&
                            taskBreakdown.breakdown.pitch_units === 0
                          )
                        } else {
                          shouldIncludeHoursInTotal = false
                        }

                        return {
                          jobCode: job,
                          jobType,
                          hours: typeof hours === 'number' ? hours : '-',
                          panels,
                          batteryAdders: taskBreakdown?.breakdown?.battery_units ?? '-',
                          pitchUnits: taskBreakdown?.breakdown?.pitch_units ?? '-',
                          distanceFromWarehouse: taskBreakdown?.breakdown?.adder_related_fields?.distance_from_warehouse ?? '-',
                          units,
                          total: typeof total === 'number' ? total.toFixed(2) : total,
                          shouldIncludeHoursInTotal,
                        }
                      })
                    })

                    // Calculate totals for the dialog
                    const totalsForDialog = {
                      hours: 0,
                      panels: 0,
                      batteryAdders: 0,
                      pitchUnits: 0,
                      units: 0,
                      total: 0,
                    }

                    projectDetails.forEach((project: any) => {
                      if (project.shouldIncludeHoursInTotal && typeof project.hours === 'number') totalsForDialog.hours += project.hours
                      if (typeof project.panels === 'number') totalsForDialog.panels += project.panels
                      if (typeof project.batteryAdders === 'number') totalsForDialog.batteryAdders += project.batteryAdders
                      if (typeof project.pitchUnits === 'number') totalsForDialog.pitchUnits += project.pitchUnits
                      if (typeof project.units === 'number') totalsForDialog.units += project.units

                      if (!isNaN(parseFloat(project.total))) totalsForDialog.total += parseFloat(project.total)
                    })

                    uc_setUserInterface_CustomDialogDisplay({
                      display: true,
                      dialog: {
                        dialog_jsx: (
                          <Card>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                  variant="h6"
                                  sx={{ marginRight: '16px' }}
                                >
                                  {personName}
                                </Typography>
                                <Typography variant="subtitle1">{formattedDateKey}</Typography>
                              </Box>
                            </Box>
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>{rLIB('Job Code')}</TableCell>
                                    <TableCell>{rLIB('Job Type')}</TableCell>
                                    <TableCell>{rLIB('Hours')}</TableCell>
                                    <TableCell>{rLIB('Panels')}</TableCell>
                                    <TableCell>{rLIB('Battery Adders')}</TableCell>
                                    <TableCell>{rLIB('Units')}</TableCell>
                                    <TableCell>{rLIB('Pitch Adders')}</TableCell>
                                    <TableCell>{rLIB('Distance from Warehouse')}</TableCell>
                                    <TableCell>{rLIB('Total')}</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {projectDetails.map((project: any, index: number) => (
                                    <TableRow
                                      key={index}
                                      style={{ opacity: project.shouldIncludeHoursInTotal ? 1 : 0.5 }}
                                    >
                                      <TableCell>{project.jobCode}</TableCell>
                                      <TableCell>{project.jobType}</TableCell>
                                      <TableCell>{project.hours}</TableCell>
                                      <TableCell>{project.panels}</TableCell>
                                      <TableCell>{project.batteryAdders}</TableCell>
                                      <TableCell>{project.units}</TableCell>
                                      <TableCell>{project.pitchUnits}</TableCell>
                                      <TableCell>{project.distanceFromWarehouse}</TableCell>
                                      <TableCell>{project.total}</TableCell>
                                    </TableRow>
                                  ))}
                                  <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>{rLIB('Total')}</TableCell>
                                    <TableCell />
                                    <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.hours.toFixed(2)}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.panels}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.batteryAdders}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.units}</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>{totalsForDialog.pitchUnits}</TableCell>
                                    <TableCell />
                                    <TableCell
                                      style={{
                                        fontWeight: 'bold',
                                        backgroundColor:
                                          totalsForDialog.total > 0 ? themeVariables.error_main : totalsForDialog.total < 0 ? themeVariables.success_main : '',
                                      }}
                                    >
                                      {totalsForDialog.total.toFixed(2)}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Card>
                        ),
                        settings: { max_width: 'lg' },
                      },
                    })
                  })
                  .catch((error) => {})
              },
              conditionalFormatting: {
                ...cell.conditionalFormatting,
                cursor: 'pointer',
              },
            }
          }
        }
        return cell
      }),
    )

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  //get vehicles
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setCombinedVehicles(newData)
      ur_forceRerender()
    }
    const startDate = new Date(us_selectedStartDate.getFullYear(), us_selectedStartDate.getMonth(), us_selectedStartDate.getDate())
    const endDate = new Date(us_selectedEndDate.getFullYear(), us_selectedEndDate.getMonth() + 1, 0)

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_CombinedVehiclesByDate_Query(res_GCK.clientKey, startDate, endDate), updateLiveData)
      })
      .catch((rej_GCK) => {})
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedStartDate, us_selectedEndDate, ur_forceRerender])

  useEffect(() => {
    // Ensure all necessary data is available before calling the service function
    if (!us_combinedVehicles || !us_mondaysInDateRange) return

    // Call the service function to process the data
    const processedData = processVehicleMilesData(us_combinedVehicles, us_mondaysInDateRange as any)

    // Save the processed data to the state
    us_setFormattedCombinedVehicles(processedData as any)
  }, [us_combinedVehicles, us_mondaysInDateRange, us_refreshingColumn])

  useEffect(() => {
    if (!us_formattedCombinedVehicles || !us_mondaysInDateRange) return

    // Handle the refresh functionality first
    const refreshedData = handleRefreshColumns(us_formattedCombinedVehicles, 'vehicle_usage_', 'cacheGMDashboardVehicleData')

    // Handle the dialog functionality after refresh
    const finalData = handleDialogFunctionality(refreshedData)

    // Update the state with the final processed data

    us_setFinalCombinedVehicles(finalData)
  }, [us_formattedCombinedVehicles, us_mondaysInDateRange, us_refreshingColumn, uc_RootData_ClientKey, ur_forceRerender])

  const handleDialogFunctionality = (data: any) => {
    if (!data || !Array.isArray(data.data_rows)) {
      console.error('Invalid data structure in handleDialogSetup:', data)
      return data // Return original data if structure is invalid
    }

    // Remove "Unknown Driver" data
    const cleanedDataRows = data.data_rows.filter((row: any) => row[0]?.cellValue !== 'Unknown Driver')

    const newDataRows = cleanedDataRows.map((row: any) =>
      row.map((cell: any, cellIndex: number) => {
        if (cellIndex > 0 && typeof cell.cellValue !== 'string') {
          const formattedDateKey = us_mondaysInDateRange[cellIndex - 1]
          const driverName = row[0]?.cellValue

          const weekData = us_combinedVehicles?.[formattedDateKey]?.data?.[driverName]
          if (!weekData) {
            console.warn(`No weekData found for driver: ${driverName}, date: ${formattedDateKey}`)
            return cell
          }

          const additionalMiles = weekData.additional_miles || 0
          const cellValue = cell.cellValue

          // Handle rounding and NaN checks
          const difference = isNaN(cellValue) ? '-' : roundNumber(cellValue)

          const hasWarningIcon = Object.keys(weekData).some((date) => {
            const dayData = weekData[date]
            return dayData && ((dayData.miles_driven > 0 && dayData.expected_miles === 0) || (dayData.miles_driven === 0 && dayData.expected_miles > 0))
          })

          return {
            ...cell,
            cellValue: (
              <span style={{ cursor: 'pointer', display: 'inline-block' }}>
                {difference === 0 ? '-' : difference}
                {hasWarningIcon && (
                  <Icon
                    icon="exclamation-triangle"
                    sx={{ color: themeVariables.warning_main, marginLeft: '4px' }}
                    tooltip={
                      Object.keys(weekData).some((date) => {
                        const dayData = weekData[date]
                        return dayData && dayData.miles_driven > 0 && dayData.expected_miles === 0
                      })
                        ? 'Driven miles but no expected miles for this week'
                        : 'Expected miles but no miles driven for this week'
                    }
                  />
                )}
              </span>
            ),
            dialogFunction: () => {
              if (!weekData || Object.keys(weekData).length === 0) {
                console.warn(`No valid data to show in dialog for driver: ${driverName}, date: ${formattedDateKey}`)
                return
              }

              const dialogEntries = Object.keys(weekData)
                .filter((date) => weekData[date] && weekData[date].trips) // Ensure dayData and trips are defined
                .map((date) => {
                  const dayData = weekData[date]
                  const adjustedDate = new Date(new Date(date).setDate(new Date(date).getDate() + 1)).toLocaleDateString()

                  const expectedMilesWithAdditional = (dayData?.expected_miles || 0) + (dayData?.expected_miles === 0 ? 0 : additionalMiles)

                  return {
                    date: adjustedDate,
                    total_miles: dayData ? roundNumber(dayData.miles_driven) : 0,
                    expected_distance: dayData && dayData.expected_miles > 0 ? roundNumber(dayData.expected_miles) : 0,
                    total_duration: dayData ? dayData.total_duration : 0,
                    numTasks: dayData ? dayData.num_tasks : 0,
                    projectIDs: dayData ? dayData.projects : [],
                    additional_miles: dayData?.expected_miles === 0 ? 0 : additionalMiles,
                    total_expected_miles: roundNumber(expectedMilesWithAdditional),
                    vehicles: dayData ? Array.from(new Set(dayData.trips.map((trip: any) => trip.vehicle_name))).join(', ') : '',
                    warning:
                      dayData && ((dayData.miles_driven > 0 && dayData.expected_miles === 0) || (dayData.miles_driven === 0 && dayData.expected_miles > 0)),
                  }
                })
                .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

              uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Card>
                      <Box sx={{ padding: '16px' }}>
                        <TabsComponent
                          dialogEntries={dialogEntries}
                          driverName={driverName}
                          weekStartDate={formattedDateKey}
                        />
                      </Box>
                    </Card>
                  ),
                  settings: { max_width: 'lg' },
                },
              })
            },
            conditionalFormatting: {
              backgroundColor: difference === '-' ? 'inherit' : difference < 0 ? themeVariables.success_main : themeVariables.error_main,
              color: 'white',
              cursor: 'pointer',
              width: '120px',
            },
          }
        }
        return cell
      }),
    )

    return {
      ...data,
      data_rows: newDataRows,
    }
  }

  const roundNumber = (num: number): number => {
    if (num > 0 && num < 1) return 1 // If the number is greater than 0 but less than 1, round it up to 1.
    return Math.round(num) // Otherwise, round the number to the nearest integer.
  }

  const TabsComponent = ({ dialogEntries, driverName, weekStartDate }: any) => {
    const [tabValue, setTabValue] = useState<any>(0)

    const handleTabChange = (event: any, newValue: any) => {
      setTabValue(newValue)
    }

    const totalMilesDriven = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.total_miles || 0), 0)
    const totalDuration = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.total_duration || 0), 0)
    const totalExpectedMiles = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.expected_distance || 0), 0)
    const totalAdditionalMiles = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.additional_miles || 0), 0)
    const totalTotalExpectedMiles = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.total_expected_miles || 0), 0)
    const totalNumTasks = dialogEntries.reduce((acc: any, entry: any) => acc + (entry.numTasks || 0), 0)

    const summaryDifference = totalMilesDriven - totalTotalExpectedMiles

    const hasWarning = dialogEntries.some(
      (entry: any) => (entry.total_miles > 0 && entry.total_expected_miles === 0) || (entry.total_miles === 0 && entry.total_expected_miles > 0),
    )

    return (
      <>
        <Typography
          variant="h6"
          sx={{ padding: '16px' }}
        >
          {driverName} -{' '}
          {new Date(new Date(weekStartDate).setDate(new Date(weekStartDate).getDate() + 1)).toLocaleDateString('en-US', { month: 'short', day: '2-digit' })}
        </Typography>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          aria-label="Mileage Data Tabs"
        >
          <Tab label="Summary" />
          <Tab label="Miles Driven" />
          <Tab label="Expected Miles" />
        </Tabs>
        {tabValue === 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Total Miles Driven</TableCell>
                  <TableCell>Total Expected Miles</TableCell>
                  <TableCell>Difference</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{totalMilesDriven}</TableCell>
                  <TableCell>{totalTotalExpectedMiles}</TableCell>
                  <TableCell sx={{ color: summaryDifference <= 0 ? themeVariables.success_main : themeVariables.error_main, fontWeight: 'bold' }}>
                    {summaryDifference} {summaryDifference > 0 ? 'miles over limit' : 'miles under limit'}
                    {hasWarning && (
                      <Icon
                        icon="exclamation-triangle"
                        className="tw-ml-2"
                        sx={{ color: themeVariables.warning_main }}
                        tooltip="Warning: check the miles driven and expected miles tabs for more details"
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {tabValue === 1 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Miles Driven</TableCell>
                  <TableCell>Total Duration (min)</TableCell>
                  <TableCell>Vehicle Name</TableCell>
                  <TableCell>Warning</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogEntries.map((entry: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell>{entry.date}</TableCell>
                    <TableCell>{entry.total_miles}</TableCell>
                    <TableCell>{entry.total_duration}</TableCell>
                    <TableCell>{entry.vehicles}</TableCell>
                    <TableCell>
                      {entry.warning && (
                        <>
                          <Icon
                            icon="exclamation-triangle"
                            sx={{ color: themeVariables.warning_main }}
                            tooltip={
                              entry.total_miles > 0 && entry.total_expected_miles === 0
                                ? 'Driven miles but no expected miles for the day'
                                : 'Expected miles but no miles driven for the day'
                            }
                          />
                          <Typography
                            variant="caption"
                            sx={{ color: themeVariables.warning_main, marginLeft: '4px' }}
                          >
                            {entry.total_miles > 0 && entry.total_expected_miles === 0
                              ? 'Driven miles but no expected miles for the day'
                              : 'Expected miles but no miles driven for the day'}
                          </Typography>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalMilesDriven}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalDuration}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {tabValue === 2 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Expected Miles</TableCell>
                  <TableCell>Additional Miles</TableCell>
                  <TableCell>Total Expected Miles</TableCell>
                  <TableCell>Number of Tasks</TableCell>
                  <TableCell>Project IDs</TableCell>
                  <TableCell>Warning</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogEntries.map((entry: any, index: any) => (
                  <TableRow key={index}>
                    <TableCell>{entry.date}</TableCell>
                    <TableCell>{entry.expected_distance}</TableCell>
                    <TableCell>{entry.additional_miles}</TableCell>
                    <TableCell>{entry.total_expected_miles}</TableCell>
                    <TableCell>{entry.numTasks}</TableCell>
                    <TableCell>{entry.projectIDs.join(', ')}</TableCell>
                    <TableCell>
                      {entry.warning && (
                        <>
                          <Icon
                            icon="exclamation-triangle"
                            sx={{ color: themeVariables.warning_main }}
                            tooltip={
                              entry.total_miles > 0 && entry.total_expected_miles === 0
                                ? 'Driven miles but no expected miles for the day'
                                : 'Expected miles but no miles driven for the day'
                            }
                          />
                          <Typography
                            variant="caption"
                            sx={{ color: themeVariables.warning_main, marginLeft: '4px' }}
                          >
                            {entry.total_miles > 0 && entry.total_expected_miles === 0
                              ? 'Driven miles but no expected miles for the day'
                              : 'Expected miles but no miles driven for the day'}
                          </Typography>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalExpectedMiles}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalAdditionalMiles}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalTotalExpectedMiles}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{totalNumTasks}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{''}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>{''}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </>
    )
  }

  const handleRefreshColumns = (data: any, dashboardName: any, dashboardFunction: any) => {
    if (!data || !Array.isArray(data.summary_rows)) {
      return data // Return original data if structure is invalid
    }

    const newSummaryRows = data.summary_rows.map((row: any, rowIndex: any) => {
      if (rowIndex === 0) {
        return row.map((cell: any, cellIndex: any) => {
          if (cellIndex > 0 && typeof cell.cellValue === 'string') {
            const formattedDateKey = us_mondaysInDateRange[cellIndex - 1]

            return {
              ...cell,
              cellValue: (
                <>
                  {cell.cellValue}
                  {us_refreshingColumn === dashboardName + formattedDateKey ? (
                    <Icon
                      icon="arrows-rotate"
                      sx={{ color: themeVariables.success_main }} // Change to green
                      className="tw-ml-1 bp_spin" // Add spinning class
                      tooltip="Refreshing Data"
                    />
                  ) : (
                    <Icon
                      icon="arrows-rotate"
                      className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-1"
                      tooltip="Refresh Data"
                      onClick={() => {
                        // Immediately update the state to start spinning
                        us_setRefreshingColumn(dashboardName + formattedDateKey)

                        // Ensure the UI re-renders with the spinning icon
                        setTimeout(() => {
                          cloudFunctionManageRequest('manageData', {
                            function: dashboardFunction,
                            client_key: uc_RootData_ClientKey,
                            monday_date_key: formattedDateKey,
                          })
                            .then(() => {
                              // Stop the refreshing icon once the data is refreshed
                              us_setRefreshingColumn(null)
                            })
                            .catch((error) => {
                              us_setRefreshingColumn(null) // Reset the state on error too
                            })
                        }, 0) // Ensure the UI updates before the async operation
                      }}
                    />
                  )}
                </>
              ),
            }
          }
          return cell
        })
      }
      return row
    })

    return {
      ...data,
      summary_rows: newSummaryRows,
    }
  }

  // Function to organize time-off data by week and team
  const organizeTimeOffByWeekAndTeam = (us_timeOff: any, us_mondaysInDateRange: string[]) => {
    const timeOffByWeekAndTeam: any = {}

    objectToArray(us_mondaysInDateRange).forEach((monday: string) => {
      timeOffByWeekAndTeam[monday] = {} // Initialize week object
    })

    Object.values(us_timeOff).forEach((timeOffEntry: any) => {
      const timeOffDate = new Date(timeOffEntry.timestamp_time_off.seconds * 1000)
      const timeOffDateKey = returnFormattedDateKey(timeOffDate)

      // Find the week for this time-off date
      const weekStart = objectToArray(us_mondaysInDateRange).find((monday: string) => {
        const weekStartDate = new Date(monday)
        const weekEndDate = new Date(weekStartDate)
        weekEndDate.setDate(weekEndDate.getDate() + 6) // End of the week (Sunday)

        return timeOffDate >= weekStartDate && timeOffDate <= weekEndDate
      })

      if (weekStart) {
        const teamKey = timeOffEntry.associated_team_key
        if (!timeOffByWeekAndTeam[weekStart][teamKey]) {
          timeOffByWeekAndTeam[weekStart][teamKey] = {
            associated_team_key: teamKey,
            associated_team_name: timeOffEntry.associated_team_name,
            time_off_type: timeOffEntry.time_off_type,
            days_off: [],
          }
        }

        // Add the specific day of time off
        timeOffByWeekAndTeam[weekStart][teamKey].days_off.push(timeOffDateKey)
      }
    })

    return timeOffByWeekAndTeam
  }

  // JSX Generation
  const changeCalendarStartMonth = (newDate: Date | null): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      if (newDate) {
        us_setSelectedStartDate(newDate)
        ur_forceRerender()
      }
      resolve({ success: true })
    })
  }

  const changeCalendarEndMonth = (newDate: Date | null): TsType_UnknownPromise => {
    return new Promise((resolve) => {
      if (newDate) {
        us_setSelectedEndDate(newDate)
        ur_forceRerender()
      }
      resolve({ success: true })
    })
  }

  const rJSX_StartMonthPicker = (disabled: boolean, us_selectedStartDate: Date | null, changeCalendarStartMonth: (date: Date | null) => void): JSX.Element => {
    const currentYear = new Date().getFullYear()
    const minDate = new Date(currentYear, 0, 1)
    const maxDate = new Date(currentYear, 11, 31)

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className="tw-mr-1"
          views={['month']}
          label={rLIB('Start Month')}
          minDate={minDate}
          maxDate={maxDate}
          value={us_selectedStartDate || new Date()}
          onChange={(newValue) => {
            if (newValue) {
              changeCalendarStartMonth(startOfMonth(newValue))
            } else {
              changeCalendarStartMonth(null)
            }
          }}
          disabled={disabled}
          renderInput={(params: any) => {
            const monthValue = params.inputProps.value ? format(new Date(params.inputProps.value), 'MMM yyyy') : ''
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true,
                }}
                value={monthValue}
                sx={{
                  'width': 150,
                  'height': 60,
                  '.MuiInputBase-input': { height: '3px' },
                }}
              />
            )
          }}
        />
      </LocalizationProvider>
    )
  }

  const rJSX_EndMonthPicker = (disabled: boolean, us_selectedEndDate: Date | null, changeCalendarEndMonth: (date: Date | null) => void): JSX.Element => {
    const currentYear = new Date().getFullYear()
    const minDate = new Date(currentYear, 0, 1)
    const maxDate = new Date(currentYear, 11, 31)

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          className="tw-mr-1"
          views={['month']}
          label={rLIB('End Month')}
          minDate={minDate}
          maxDate={maxDate}
          value={us_selectedEndDate || new Date()}
          onChange={(newValue) => {
            if (newValue) {
              changeCalendarEndMonth(endOfMonth(newValue))
            } else {
              changeCalendarEndMonth(null)
            }
          }}
          disabled={disabled}
          renderInput={(params: any) => {
            const monthValue = params.inputProps.value ? format(new Date(params.inputProps.value), 'MMM yyyy') : ''
            return (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  readOnly: true, // Prevent manual editing
                }}
                value={monthValue}
                sx={{
                  'width': 150,
                  'height': 60,
                  '.MuiInputBase-input': { height: '3px' },
                }}
              />
            )
          }}
        />
      </LocalizationProvider>
    )
  }

  // const rJSX_RegionDropdown = (shrinkButton: boolean): JSX.Element => {
  //   let dropdownJSX = (
  //     <Box className="tw-inline-block tw-align-top">
  //       <FormControl className="bp_thin_select_input">
  //         <InputLabel id={'region_filter'}>{rLIB('Region')}</InputLabel>
  //         <Select
  //           id={'region_filter'}
  //           labelId={'region_filter'}
  //           color="primary"
  //           value={us_selectedRegion}
  //           label={rLIB('Region')}
  //           onChange={(event: any) => {
  //             if (event != null && event.target != null && event.target.value != null) {
  //               us_setSelectedRegion(event.target.value)
  //             }
  //           }}
  //           variant="outlined"
  //         >
  //           <MenuItem value={'ALL_REGIONS'}>{rLIB('All Regions')}</MenuItem>
  //           {objectToArray(us_regionLists)
  //             .sort(dynamicSort('name', null))
  //             .map((option: TsInterface_UnspecifiedObject, index: number) => (
  //               <MenuItem
  //                 key={index}
  //                 value={option['key']}
  //               >
  //                 {option['name']}
  //               </MenuItem>
  //             ))}
  //         </Select>
  //       </FormControl>
  //     </Box>
  //   )
  //   return dropdownJSX
  // }

  const rJSX_CycleTimeExportButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="success"
        startIcon={<Icon icon="file-arrow-down" />}
        disabled={us_downloadingCycleTimes}
        onClick={() => {
          us_setDownloadingCycleTimes(true)
          downloadCycleTimeCSV()
            .then((res_DNC: any) => {
              us_setDownloadingCycleTimes(false)
            })
            .catch((res_DNC: any) => {
              us_setDownloadingCycleTimes(false)
              uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: res_DNC.error,
              })
            })
        }}
      >
        {rLIB('Download Cycle Times')}
      </Button>
    )
    return buttonJSX
  }

  const downloadCycleTimeCSV = () => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // First get the task blueprint data
          DatabaseGetCollection(DatabaseRef_TaskBlueprints_Collection(res_GCK.clientKey))
            .then((res_TaskBlueprint) => {
              const taskNameMap: any = {}
              // Create a map of task keys to human-readable names
              Object.keys(res_TaskBlueprint.data).forEach((key) => {
                if (res_TaskBlueprint.data[key].name) {
                  taskNameMap[key] = res_TaskBlueprint.data[key].name
                }
              })

              // Now get the project data
              DatabaseGetCollection(DatabaseRef_CycleTimeByActiveProject_Query(res_GCK.clientKey))
                .then((res_DGC) => {
                  let csvData = [['Job Code', 'Task Name', 'Completion Date']] // Include headers as the first row

                  // Iterate over each project/job
                  for (let jobKey in res_DGC.data) {
                    let job = res_DGC.data[jobKey]
                    let jobCode = job.id_number // Job code for this job

                    // Gather all tasks and prepare them for sorting
                    const tasks = Object.entries(job.task_completion_timestamps)
                      .map(([taskKey, timestamp]) => {
                        // Clean up task key by removing the first number and underscore
                        let cleanedTaskKey = taskKey.split('_').slice(1).join('_')
                        let taskName = taskNameMap[cleanedTaskKey] || cleanedTaskKey // Use map or cleaned key
                        return { taskName, timestamp: timestamp ? Number(timestamp) : null }
                      })
                      .filter((task) => task.timestamp !== null) // Filter out tasks without a timestamp

                    // Sort tasks by timestamp, and alphabetically if timestamps are the same
                    tasks.sort((a: any, b: any) => {
                      if (a.timestamp === b.timestamp) {
                        return a.taskName.localeCompare(b.taskName) // Alphabetical sort if timestamps are the same
                      }
                      return a.timestamp - b.timestamp // Timestamp sort
                    })

                    // Add sorted tasks to the csvData with job code on each line
                    tasks.forEach(({ taskName, timestamp }) => {
                      if (timestamp !== null) {
                        let formattedDate = new Date(timestamp).toISOString().split('T')[0]
                        csvData.push([jobCode, taskName, formattedDate])
                      }
                    })
                  }

                  downloadCSV('CycleTimeExport', csvData)
                    .then((res_DC) => {
                      resolve(res_DC)
                    })
                    .catch((rej_DC) => {
                      reject(rej_DC)
                    })
                })
                .catch((rej_DGC) => {
                  reject(rej_DGC)
                })
            })
            .catch((rej_TaskBlueprint) => {
              reject(rej_TaskBlueprint)
            })
        })
        .catch((rej_GCK) => {
          reject(rej_GCK)
        })
    })
  }

  const rJSX_Page = (): JSX.Element => {
    const { uc_RootData_ClientKey } = useContext(Context_RootData_ClientKey) // Get client key from context

    return (
      <AuthenticatedContainer
        pageHeader={rLIB('GM Dashboard')}
        pageKey={pageKey}
        content={
          <Box>
            {/* Global Controls Above the Accordion */}
            <Box className="mb-2 flex items-center gap-2">
              {rJSX_StartMonthPicker(false, us_selectedStartDate, changeCalendarStartMonth)}
              {rJSX_EndMonthPicker(false, us_selectedEndDate, changeCalendarEndMonth)}
              {rJSX_CycleTimeExportButton()}
              {/* {rJSX_WeeklyMonthlyToggle()} */}
            </Box>
            {/* First Heatmap for Panels Installed */}
            <Card className="tw-mb-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Met or exceeded expected panels (20 panels/working day)')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Installed fewer panels than expected (20 panels/working day)')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalPanelsInstalled.summary_rows || [],
                  data_rows: us_finalPanelsInstalled.data_rows || [],
                }}
              />
            </Card>
            {/* Heatmap for Crew Hours */}
            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Box className="tw-mb-2">
                      <Typography variant="body2">
                        {rLIB('Hours against max allocation. Includes pitch and roof type adders for install. Does not include distance adders.')}
                      </Typography>
                    </Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Employee took less hours than max allocation')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Employee took more hours than max allocation')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalCrewHours.summary_rows,
                  data_rows: us_finalCrewHours.data_rows,
                }}
              />
            </Card>
            {/* Heatmap for Safety Reviews */}
            <Card className="tw-mt-4">
              <Box className="tw-flex tw-justify-between tw-items-center tw-mb-2"></Box>
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Safety review completed')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Safety review failed or not completed')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalSafetyReviews.summary_rows || [],
                  data_rows: us_finalSafetyReviews.data_rows || [],
                }}
              />
            </Card>
            {/* Heatmap for Cycle Time RTG To Install */}
            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('14 days or less')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('More than 14 days')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalRTGToInstall.summary_rows,
                  data_rows: us_finalRTGToInstall.data_rows,
                }}
              />
            </Card>
            {/* Heatmap for Cycle Time Customer Signature To Install */}
            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('14 days or less')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('More than 14 days')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalCSToInstall.summary_rows,
                  data_rows: us_finalCSToInstall.data_rows,
                }}
              />
            </Card>
            {/* Heatmap for Cycle Time Permit Approval To Install */}
            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('14 days or less')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('More than 14 days')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalPermitApprovalToInstall.summary_rows,
                  data_rows: us_finalPermitApprovalToInstall.data_rows,
                }}
              />
            </Card>
            {/* Heatmap for Cycle Time Customer Signature to PTO */}
            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('14 days or less')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('More than 14 days')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalCsToPto.summary_rows,
                  data_rows: us_finalCsToPto.data_rows,
                }}
              />
            </Card>
            {/* Heatmap for Cycle Time Customer Signature to Permit Requested */}
            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('14 days or less')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('More than 14 days')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalCsToPermitRequested.summary_rows,
                  data_rows: us_finalCsToPermitRequested.data_rows,
                }}
              />
            </Card>
            {/* Heatmap for Vehicle Usage */}
            <Card className="tw-mt-4">
              <GMDashboardHeatmap
                heatmapLegend={
                  <Box>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                      sx={{ marginBottom: 1 }}
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.success_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Actual miles were less than or equal to expected miles')}</Typography>
                    </Stack>
                    <Stack
                      spacing={2}
                      direction="row"
                      alignItems="center"
                    >
                      <Box
                        sx={{
                          width: '20px',
                          height: '20px',
                          backgroundColor: themeVariables.error_main,
                          borderRadius: '5px',
                        }}
                      ></Box>
                      <Typography variant="body2">{rLIB('Actual miles were greater than or equal to expected miles')}</Typography>
                    </Stack>
                  </Box>
                }
                tableData={{
                  summary_rows: us_finalCombinedVehicles.summary_rows,
                  data_rows: us_finalCombinedVehicles.data_rows,
                }}
              />
            </Card>
            <Box>
              {/* <Json data={us_rawTimeOff}></Json>
              <Json data={us_rawPanelsInstalled}></Json>
              <Json data={us_organizedTimeOff}></Json> */}
              {/* <Json data={us_formattedPanelsInstalled}></Json> */}
              {/* <Json data={us_formattedCombinedVehicles}></Json> */}
              {/* <Json data={us_combinedVehicles}></Json> */}
            </Box>
          </Box>
        }
      />
    )
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Card } from '@mui/material/'
import { useEffect } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { TableDatabase } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  tableColumns_CronjobsOneTimeFailure,
  tableDatabaseEndpoint_CronjobsOneTimeFailure,
  tableSettings_CronjobsOneTimeFailure,
} from './tables/cronjobs_one_time_failure'
import {
  tableColumns_CronjobsOneTimeQueued,
  tableDatabaseEndpoint_CronjobsOneTimeQueued,
  tableSettings_CronjobsOneTimeQueued,
} from './tables/cronjobs_one_time_queued'
import { tableColumns_CronjobsOneSuccess, tableDatabaseEndpoint_CronjobsOneSuccess, tableSettings_CronjobsOneSuccess } from './tables/cronjobs_one_time_success'
import { tableColumns_RecurringCronJobs, tableDatabaseEndpoint_RecurringCronJobs, tableSettings_RecurringCronJobs } from './tables/cronjobs_recurring'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminCronjobManagementIndexPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Cronjobs', false) as string
  }, [])

  // Functions

  // JSX Generation
  const rJSX_RecurringCronjobsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="">
        <TableDatabase
          tableAdditionalData={{}}
          tableColumns={tableColumns_RecurringCronJobs}
          tableDatabaseEndpoint={tableDatabaseEndpoint_RecurringCronJobs}
          tableSettings={tableSettings_RecurringCronJobs}
        />
      </Card>
    )
    return tabJSX
  }

  const rJSX_OneTimeQueuedCronjobsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="">
        <TableDatabase
          tableAdditionalData={{}}
          tableColumns={tableColumns_CronjobsOneTimeQueued}
          tableDatabaseEndpoint={tableDatabaseEndpoint_CronjobsOneTimeQueued}
          tableSettings={tableSettings_CronjobsOneTimeQueued}
        />
      </Card>
    )
    return tabJSX
  }

  const rJSX_OneTimeSuccessfulCronjobsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="">
        <TableDatabase
          tableAdditionalData={{}}
          tableColumns={tableColumns_CronjobsOneSuccess}
          tableDatabaseEndpoint={tableDatabaseEndpoint_CronjobsOneSuccess}
          tableSettings={tableSettings_CronjobsOneSuccess}
        />
      </Card>
    )
    return tabJSX
  }

  const rJSX_OneTimeFailureCronjobsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="">
        <TableDatabase
          tableAdditionalData={{}}
          tableColumns={tableColumns_CronjobsOneTimeFailure}
          tableDatabaseEndpoint={tableDatabaseEndpoint_CronjobsOneTimeFailure}
          tableSettings={tableSettings_CronjobsOneTimeFailure}
        />
      </Card>
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Cronjobs')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminCronjobManagementIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('API Management', false) as string,
              }}
              tabs={[
                {
                  tabUrlKey: 'daily',
                  tabHeader: rLIB('Daily'),
                  tabContent: rJSX_RecurringCronjobsTab(),
                },
                {
                  tabUrlKey: 'one_time_queued',
                  tabHeader: rLIB('One Time Queued'),
                  tabContent: rJSX_OneTimeQueuedCronjobsTab(),
                },
                {
                  tabUrlKey: 'one_time_successful',
                  tabHeader: rLIB('One Time Successful'),
                  tabContent: rJSX_OneTimeSuccessfulCronjobsTab(),
                },
                {
                  tabUrlKey: 'one_time_failed',
                  tabHeader: rLIB('One Time Failed'),
                  tabContent: rJSX_OneTimeFailureCronjobsTab(),
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			// https://github.com/wojtekmaj/react-clock

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Divider, Typography } from '@mui/material'
import {
  createBreakEndPunch,
  createBreakStartPunch,
  createClockInPunch,
  createClockOutPunch,
  returnClockInOptions,
} from 'app/models/timesheets/timesheet_functions'
import { formInputs_ClockInAllocationSelection } from 'app/models/users/user_form'
import { useContext, useEffect, useReducer, useState } from 'react'
import Clock from 'react-clock'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatabaseRef_TimeSheetsAllocationTypes_Document } from 'rfbp_aux/services/database_endpoints/timesheets/allocation_types'
import { DatabaseRef_TEMP_MostRecentTimePunchForUser_Query } from 'rfbp_aux/services/database_endpoints/timesheets/time_punches'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import {
  getProp,
  returnDateCorrectedForTimezoneOffset,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnFormattedDateKey,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TabProps {}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CLOCK_OUT: JSX.Element = <Trans>Are you sure that you want to clock out?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_START_A_BREAK: JSX.Element = <Trans>Are you sure that you want to start a break?</Trans>
const s_CLOCKED_IN_AT: JSX.Element = <Trans>Clocked in at</Trans>
const s_CLOCK_IN: JSX.Element = <Trans>Clock In</Trans>
const s_CLOCK_OUT: JSX.Element = <Trans>Clock Out</Trans>
const s_CURRENTLY_CLOCKED_OUT: JSX.Element = <Trans>Currently Clocked Out</Trans>
const s_END_BREAK: JSX.Element = <Trans>End Break</Trans>
const s_IF_YOU_NEED_TO_CHANGE_YOUR_SCHEDULED_TIME_OFF_GO_TO_THE_REVIEW_AND_SUBMIT_TAB: JSX.Element = (
  <Trans>If you need to change your sheduled time off, go to the "Review and Submit" tab</Trans>
)
const s_SCHEDULED_TIME_OFF: JSX.Element = <Trans>Scheduled Time Off</Trans>
const s_SELECT_CLOCK_IN_TYPE: JSX.Element = <Trans>Select Clock In Type</Trans>
const s_STARTED_BREAK_AT: JSX.Element = <Trans>Started break at</Trans>
// { sort-end } - displayed text

// CSS
let pageCSS: string =
  `
		.react-clock {
			margin: auto;
		}
		.react-clock__face {
			background: ` +
  themeVariables.transparent +
  `;
			border: 6px solid ` +
  themeVariables.info_main +
  `;
		}
		.react-clock__mark {
			background-color: ` +
  themeVariables.black +
  `;
		}
		.react-clock__mark__body {
			background-color: ` +
  themeVariables.info_main +
  `;
		}
		.react-clock__hour-hand__body {
			background-color: ` +
  themeVariables.primary_main +
  ` !important;
		}
		.react-clock__minute-hand__body {
			background-color: ` +
  themeVariables.primary_main +
  ` !important;
		}
		.react-clock__second-hand__body {
			background-color: ` +
  themeVariables.error_light +
  ` !important;
		}
	`

///////////////////////////////
// Functions
///////////////////////////////

function getTimeElapsed(date: Date): string {
  // Get the current date and time
  let currentDate = new Date()

  // Calculate the time difference in milliseconds
  let timeDiff = Math.abs(currentDate.getTime() - date.getTime())

  // Convert the time difference to seconds
  let seconds = Math.floor(timeDiff / 1000)

  // Calculate the hours, minutes, and remaining seconds
  let hours = Math.floor(seconds / 3600)
  let remainingSeconds = seconds % 3600
  let minutes = Math.floor(remainingSeconds / 60)
  remainingSeconds = remainingSeconds % 60

  // Format the time as "h:mm:ss"
  let formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ':' + (remainingSeconds < 10 ? '0' : '') + remainingSeconds

  // Return the formatted time
  return formattedTime
}

function getStartAndEndOfWeek(date: Date) {
  const startOfWeek = new Date(date)
  startOfWeek.setHours(0, 0, 0, 0)
  startOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 0)
  const endOfWeek = new Date(date)
  endOfWeek.setHours(23, 59, 59, 999)
  endOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 6)
  return {
    startOfWeek,
    endOfWeek,
  }
}

///////////////////////////////
// JSX Exports
///////////////////////////////

export const Tab: React.FC<TabProps> = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_adminTimeAllocationTypes, us_setAdminTimeAllocationTypes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_breakAllocationTypes, us_setBreakAllocationTypes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clockDate, us_setClockDate] = useState<Date>(new Date())
  const [us_creatingTimePunch, us_setCreatingTimePunch] = useState<boolean>(false)
  const [us_loadedMostRecentClockPunch, us_setLoadedMostRecentClockPunch] = useState<boolean>(false)
  const [us_mostRecentClockPunch, us_setMostRecentClockPunch] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasks, us_setScheduledTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_timestampQueryDate, us_setTimestampQueryDate] = useState<Date>(new Date())
  const [us_weekStartDate, us_setWeekStartDate] = useState<Date | null>(null)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let weekBoundingDates = getStartAndEndOfWeek(new Date())
    us_setWeekStartDate(weekBoundingDates.startOfWeek)
    return () => {}
  }, [])

  useEffect(() => {
    // Used to render clock
    const interval = setInterval(() => us_setClockDate(new Date()), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  // TEMP
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLoadedMostRecentClockPunch(true)
      // Loop through and ind the most recent not deleted clock in
      let mostRecentActivePunch: TsInterface_UnspecifiedObject | null = null
      for (let loopPunchKey in newData) {
        let loopPunch = newData[loopPunchKey]
        if (
          loopPunch != null &&
          loopPunch.status !== 'deleted' &&
          loopPunch.timestamp != null &&
          (mostRecentActivePunch == null ||
            returnDateFromUnknownDateFormat(loopPunch.timestamp) > returnDateFromUnknownDateFormat(mostRecentActivePunch.timestamp))
        ) {
          mostRecentActivePunch = loopPunch
        }
      }
      if (mostRecentActivePunch != null) {
        us_setMostRecentClockPunch(mostRecentActivePunch)
        ur_forceRerender()
      }
    }
    if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_TEMP_MostRecentTimePunchForUser_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string, us_timestampQueryDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser, us_timestampQueryDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasks(newData)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, returnFormattedDateKey(new Date()), uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAdminTimeAllocationTypes(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TimeSheetsAllocationTypes_Document(res_GCK.clientKey, 'admin_time'), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setBreakAllocationTypes(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TimeSheetsAllocationTypes_Document(res_GCK.clientKey, 'break'), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Functions
  const clockIn = (): void => {
    if (us_weekStartDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          returnClockInOptions(
            res_GCK.clientKey,
            uc_RootData_ClientUser.key,
            returnDateCorrectedForTimezoneOffset(us_weekStartDate),
            us_adminTimeAllocationTypes,
            us_scheduledTasks,
          )
            .then((res_RCIO) => {
              // @ts-expect-error - TODO: reason for error
              let clockInOptions = res_RCIO.clockInOptions
              // Open Dialog
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {
                      clockInOptions: clockInOptions,
                    },
                    formData: {},
                    formInputs: formInputs_ClockInAllocationSelection,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {
                      submit_button_hide: true,
                    },
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        let selectedAllocation = getProp(formSubmittedData, 'selected_allocation', {})
                        us_setCreatingTimePunch(true)
                        // Create Punch
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let clockTimestamp = new Date()
                            createClockInPunch(
                              res_GCK.clientKey,
                              clockTimestamp,
                              clockTimestamp,
                              getProp(uc_RootData_ClientUser, 'key', null),
                              getProp(uc_RootData_ClientUser, 'name', null),
                              getProp(uc_RootData_ClientUser, 'key', null),
                              getProp(uc_RootData_ClientUser, 'name', null),
                              getProp(selectedAllocation, 'allocation_type_key', null),
                              getProp(selectedAllocation, 'allocation_type_name', null),
                              getProp(selectedAllocation, 'allocation_type_code', null),
                              getProp(selectedAllocation, 'allocation_subtype_key', null),
                              getProp(selectedAllocation, 'allocation_subtype_name', null),
                              getProp(selectedAllocation, 'allocation_subtype_code', null),
                              getProp(selectedAllocation, 'associated_project_key', null),
                              getProp(selectedAllocation, 'associated_project_tasks', null),
                            )
                              .then((res_CCIP) => {
                                resolve(res_CCIP)
                                us_setTimestampQueryDate(new Date(new Date().getTime() + 1500))
                                us_setCreatingTimePunch(false)
                                ur_forceRerender()
                              })
                              .catch((rej_CCIP) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CCIP.error })
                                reject(rej_CCIP)
                                us_setCreatingTimePunch(false)
                                ur_forceRerender()
                              })
                          })
                          .catch((rej_GCK) => {
                            console.error(rej_GCK)
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                            us_setCreatingTimePunch(false)
                            ur_forceRerender()
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: s_SELECT_CLOCK_IN_TYPE,
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="clock"
                      />
                    ),
                  },
                },
              })
            })
            .catch((rej_RCIO) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_RCIO.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  const clockOut = (): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'primary',
        header: s_CLOCK_OUT,
        icon: (
          <Icon
            icon="clock"
            type="solid"
          />
        ),
        submit_text: s_CLOCK_OUT,
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_CLOCK_OUT,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            us_setCreatingTimePunch(true)
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let clockTimestamp = new Date()
                createClockOutPunch(
                  res_GCK.clientKey,
                  clockTimestamp,
                  clockTimestamp,
                  getProp(uc_RootData_ClientUser, 'key', null),
                  getProp(uc_RootData_ClientUser, 'name', null),
                  getProp(uc_RootData_ClientUser, 'key', null),
                  getProp(uc_RootData_ClientUser, 'name', null),
                )
                  .then((res_CCOP) => {
                    resolve(res_CCOP)
                    us_setTimestampQueryDate(new Date(new Date().getTime() + 1500))
                    us_setCreatingTimePunch(false)
                    ur_forceRerender()
                  })
                  .catch((rej_CCOP) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CCOP.error })
                    reject(rej_CCOP)
                    us_setCreatingTimePunch(false)
                  })
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
                us_setCreatingTimePunch(false)
              })
          })
        },
      },
    })
  }

  const startBreak = (): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'warning',
        header: rLIB('Start Break'),
        icon: (
          <Icon
            icon="clock"
            type="solid"
          />
        ),
        submit_text: rLIB('Start Break'),
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_START_A_BREAK,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            us_setCreatingTimePunch(true)
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let clockTimestamp = new Date()
                createBreakStartPunch(
                  res_GCK.clientKey,
                  clockTimestamp,
                  clockTimestamp,
                  getProp(uc_RootData_ClientUser, 'key', null),
                  getProp(uc_RootData_ClientUser, 'name', null),
                  getProp(uc_RootData_ClientUser, 'key', null),
                  getProp(uc_RootData_ClientUser, 'name', null),
                  getProp(us_breakAllocationTypes, 'code', null),
                )
                  .then((res_CBSP) => {
                    resolve(res_CBSP)
                    us_setTimestampQueryDate(new Date(new Date().getTime() + 1500))
                    us_setCreatingTimePunch(false)
                    ur_forceRerender()
                  })
                  .catch((rej_CBSP) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CBSP.error })
                    reject(rej_CBSP)
                    us_setCreatingTimePunch(false)
                  })
              })
              .catch((rej_GCK) => {
                console.error(rej_GCK)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
                us_setCreatingTimePunch(false)
              })
          })
        },
      },
    })
  }

  const endBreak = (): void => {
    if (us_weekStartDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          returnClockInOptions(
            res_GCK.clientKey,
            uc_RootData_ClientUser.key,
            returnDateCorrectedForTimezoneOffset(us_weekStartDate),
            us_adminTimeAllocationTypes,
            us_scheduledTasks,
          )
            .then((res_RCIO) => {
              // @ts-expect-error - TODO: reason for error
              let clockInOptions = res_RCIO.clockInOptions
              // Open Dialog
              uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {
                      clockInOptions: clockInOptions,
                    },
                    formData: {},
                    formInputs: formInputs_ClockInAllocationSelection,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {
                      submit_button_hide: true,
                    },
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        let selectedAllocation = getProp(formSubmittedData, 'selected_allocation', {})
                        us_setCreatingTimePunch(true)
                        // Create Punch
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let clockTimestamp = new Date()
                            createBreakEndPunch(
                              res_GCK.clientKey,
                              clockTimestamp,
                              clockTimestamp,
                              getProp(uc_RootData_ClientUser, 'key', null),
                              getProp(uc_RootData_ClientUser, 'name', null),
                              getProp(uc_RootData_ClientUser, 'key', null),
                              getProp(uc_RootData_ClientUser, 'name', null),
                              getProp(selectedAllocation, 'allocation_type_key', null),
                              getProp(selectedAllocation, 'allocation_type_name', null),
                              getProp(selectedAllocation, 'allocation_type_code', null),
                              getProp(selectedAllocation, 'allocation_subtype_key', null),
                              getProp(selectedAllocation, 'allocation_subtype_name', null),
                              getProp(selectedAllocation, 'allocation_subtype_code', null),
                              getProp(us_breakAllocationTypes, 'code', null),
                              getProp(selectedAllocation, 'associated_project_key', null),
                              getProp(selectedAllocation, 'associated_project_tasks', null),
                            )
                              .then((res_CBEP) => {
                                resolve(res_CBEP)
                                us_setTimestampQueryDate(new Date(new Date().getTime() + 1500))
                                us_setCreatingTimePunch(false)
                                ur_forceRerender()
                              })
                              .catch((rej_CBEP) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CBEP.error })
                                reject(rej_CBEP)
                                us_setCreatingTimePunch(false)
                              })
                          })
                          .catch((rej_GCK) => {
                            console.error(rej_GCK)
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                            us_setCreatingTimePunch(false)
                            ur_forceRerender()
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'warning',
                    formDialogHeaderText: (
                      <>
                        {s_END_BREAK} - {s_SELECT_CLOCK_IN_TYPE}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="clock"
                      />
                    ),
                  },
                },
              })
            })
            .catch((rej_RCIO) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_RCIO.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  // Call Functions

  // JSX Generation
  const rJSX_ClockInButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_mostRecentClockPunch != null) {
      buttonJSX = (
        <Button
          className="tw-mt-2"
          variant="contained"
          color="primary"
          onClick={() => {
            clockIn()
          }}
          disabled={us_weekStartDate == null || us_creatingTimePunch === true}
        >
          <Typography variant="h5">{s_CLOCK_IN}</Typography>
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_ClockOutButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_mostRecentClockPunch != null) {
      buttonJSX = (
        <Button
          className="tw-mt-2"
          variant="contained"
          color="primary"
          onClick={() => {
            clockOut()
          }}
          disabled={us_weekStartDate == null || us_creatingTimePunch === true}
        >
          <Typography variant="h5">{s_CLOCK_OUT}</Typography>
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_BreakStartButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_mostRecentClockPunch != null) {
      buttonJSX = (
        <Button
          className="tw-mt-2 tw-ml-2"
          variant="contained"
          color="warning"
          onClick={() => {
            startBreak()
          }}
          disabled={us_weekStartDate == null || us_creatingTimePunch === true}
        >
          <Typography variant="h5">{rLIB('Start Break')}</Typography>
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_BreakEndButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_mostRecentClockPunch != null) {
      buttonJSX = (
        <Button
          className="tw-mt-2"
          variant="contained"
          color="warning"
          onClick={() => {
            endBreak()
          }}
          disabled={us_weekStartDate == null || us_creatingTimePunch === true}
        >
          <Typography variant="h5">{s_END_BREAK}</Typography>
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_TimeClockButtons = (): JSX.Element => {
    let buttonsJSX = <></>
    // If most recent punch loaded
    if (us_loadedMostRecentClockPunch === true) {
      // Determine current clocked in status and type
      let lastPunchType = 'clock_out'
      let lastPunchAllocation
      if (us_mostRecentClockPunch != null && us_mostRecentClockPunch.type != null) {
        lastPunchType = us_mostRecentClockPunch.type
        lastPunchAllocation = getProp(us_mostRecentClockPunch, 'associated_allocation_type_key', null)
      } else {
        lastPunchType = 'clock_out'
      }
      // Render
      if (lastPunchType === 'clock_in' && lastPunchAllocation === 'admin_time') {
        buttonsJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {s_CLOCKED_IN_AT} {returnFormattedDate(us_mostRecentClockPunch.timestamp, 'h:mm a MM/DD/YY')} (
              {getTimeElapsed(returnDateFromUnknownDateFormat(us_mostRecentClockPunch.timestamp))})
            </Typography>
            <Typography
              variant="body1"
              className="tw-opacity-100"
            >
              {us_mostRecentClockPunch.associated_allocation_type_name} - {us_mostRecentClockPunch.associated_allocation_subtype_name}
            </Typography>
            {rJSX_ClockOutButton()}
            {rJSX_BreakStartButton()}
          </Box>
        )
      } else if (lastPunchType === 'clock_in' && lastPunchAllocation === 'break') {
        buttonsJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {s_STARTED_BREAK_AT} {returnFormattedDate(us_mostRecentClockPunch.timestamp, 'h:mm a MM/DD/YY')} (
              {getTimeElapsed(returnDateFromUnknownDateFormat(us_mostRecentClockPunch.timestamp))})
            </Typography>
            {rJSX_BreakEndButton()}
          </Box>
        )
      } else if (lastPunchType === 'clock_in' && lastPunchAllocation === 'field_work') {
        buttonsJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {s_CLOCKED_IN_AT} {returnFormattedDate(us_mostRecentClockPunch.timestamp, 'h:mm a MM/DD/YY')} (
              {getTimeElapsed(returnDateFromUnknownDateFormat(us_mostRecentClockPunch.timestamp))})
            </Typography>
            <Typography
              variant="body1"
              className="tw-opacity-100"
            >
              {us_mostRecentClockPunch.associated_allocation_type_name} - {us_mostRecentClockPunch.associated_allocation_subtype_name}
            </Typography>
            {rJSX_ClockOutButton()}
            {rJSX_BreakStartButton()}
          </Box>
        )
      } else if (lastPunchType === 'clock_in' && lastPunchAllocation === 'non_working_time') {
        buttonsJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {s_SCHEDULED_TIME_OFF}
            </Typography>
            <Typography
              variant="body1"
              className="tw-opacity-50"
            >
              {s_IF_YOU_NEED_TO_CHANGE_YOUR_SCHEDULED_TIME_OFF_GO_TO_THE_REVIEW_AND_SUBMIT_TAB}
            </Typography>
          </Box>
        )
      } else {
        buttonsJSX = (
          <Box>
            <Typography
              variant="h6"
              className="tw-opacity-50"
            >
              {s_CURRENTLY_CLOCKED_OUT}
            </Typography>
            {rJSX_ClockInButton()}
          </Box>
        )
      }
    }
    return buttonsJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-text-center tw-pt-1">
        <Clock
          hourHandLength={50}
          hourHandOppositeLength={0}
          hourHandWidth={6}
          hourMarksLength={10}
          hourMarksWidth={3}
          minuteHandLength={70}
          minuteHandOppositeLength={0}
          minuteHandWidth={4}
          minuteMarksLength={6}
          minuteMarksWidth={1}
          renderHourMarks={true}
          renderMinuteHand={true}
          renderMinuteMarks={true}
          renderNumbers={false}
          renderSecondHand={true}
          secondHandLength={90}
          secondHandOppositeLength={0}
          secondHandWidth={2}
          size={200}
          value={us_clockDate}
        />
        <Typography
          className=" tw-mt-8 tw-font-medium"
          variant="h4"
        >
          {returnFormattedDate(us_clockDate, 'MMMM DD, YYYY')}
        </Typography>
        <Typography
          className="tw-font-bold"
          variant="h3"
        >
          {returnFormattedDate(us_clockDate, 'h:mm a')}
        </Typography>
        <Box
          className="tw-my-4 tw-mx-auto"
          sx={{ maxWidth: '600px' }}
        >
          <Divider />
        </Box>
        <Box>{rJSX_TimeClockButtons()}</Box>
        <style>{pageCSS}</style>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_TimePunches_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'timesheets', 'time_punches', 'main')
}

export const DatabaseRef_TimePunches_DateRange_Query = (clientKey: string, startDate: Date, endDate: Date): Query<DocumentData> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp', comparator: '>=', value: startDate },
    { prop: 'timestamp', comparator: '<=', value: endDate },
  ]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'time_punches', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_TimePunches_Document = (clientKey: string, punchKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'timesheets', 'time_punches', 'main', punchKey)
}

export const DatabaseRef_TimePunchesHistory_Collection = (clientKey: string, punchKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'timesheets', 'time_punches', 'main', punchKey, 'history')
}

export const DatabaseRef_TimePunchesHistory_Document = (clientKey: string, punchKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'timesheets', 'time_punches', 'main', punchKey, 'history', logKey)
}

export const DatabaseRef_TimePunches_UserDateRange_Query = (clientKey: string, userKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_user_key', comparator: '==', value: userKey },
    { prop: 'timestamp', comparator: '>=', value: startDate },
    { prop: 'timestamp', comparator: '<=', value: endDate },
  ]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'time_punches', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_MostRecentTimePunchForUser_Query = (clientKey: string, userKey: string, timestampDate: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_user_key', comparator: '==', value: userKey },
    { prop: 'timestamp', comparator: '<=', value: timestampDate },
  ]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'time_punches', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    1,
  )
}

export const DatabaseRef_TEMP_MostRecentTimePunchForUser_Query = (clientKey: string, userKey: string, timestampDate: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_user_key', comparator: '==', value: userKey },
    { prop: 'timestamp', comparator: '<=', value: timestampDate },
  ]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'time_punches', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    20,
  )
}

export const DatabaseRef_TimePunches_SpecificTask_Query = (clientKey: string, taskKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_tasks.' + taskKey, comparator: '!=', value: 'THIS_SHOULD_QUERY_ANY_TASK_WITH_THIS_KEY_AND_ANY_OTHER_VALUE' },
  ]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'time_punches', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_TimePunches_UserNextPunch_Query = (clientKey: string, userKey: string, timestampDate: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_user_key', comparator: '==', value: userKey },
    { prop: 'timestamp', comparator: '>', value: timestampDate },
  ]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: false }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'time_punches', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    1,
  )
}

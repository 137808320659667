///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Divider, Stack } from '@mui/material/'
import { returnCurrentLegalVersionNumbers, returnPrivacyPolicyText, rJSX_LegalDocument } from 'app/models/legal/legal_documents_version_history'
import { useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { rJSX_StagingWarning } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { rLIB } from 'rfbp_core/localization/library'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'

// @ts-expect-error - TODO: reason for error
import logoSrc from '../../images/logos/logo_temp.png'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ALL_RIGHTS_RESERVED: JSX.Element = <Trans>All right reserved</Trans>
const s_COPYRIGHT: JSX.Element = <Trans>Copyright</Trans>
const s_ETW_ENERGY_LLC: JSX.Element = <Trans>ETW Energy, LLC</Trans>
const s_PRIVACY_POLICY: JSX.Element = <Trans>Privacy Policy</Trans>
const s_TERMS_OF_SERVICE: JSX.Element = <Trans>Terms of Service</Trans>
const se_PRIVACY_POLICY = 'Privacy Policy'
// { sort-end } - displayed text

// CSS
let pageCSS: string = `
		body {
			background: #000000;
		}
	`

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_PRIVACY_POLICY
  }, [])

  // Functions

  // JSX Generation
  const rJSX_Header = (): JSX.Element => {
    let headerJSX = (
      <Stack
        direction="row"
        className="tw-justify-between"
      >
        <Box
          className="tw-m-2 tw-cursor-pointer"
          component="img"
          sx={{ width: 126, height: 36 }}
          alt="logo"
          src={logoSrc}
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.UnauthenticatedLandingPage.url())
          }}
        />
        <Box>
          <Button
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, ApplicationPages.UnauthenticatedLoginPage.url())
            }}
            className="tw-rounded-full tw-my-2 tw-ml-2 tw-normal-case tw-mr-1"
            variant="outlined"
          >
            {rLIB('Login')}
          </Button>
        </Box>
      </Stack>
    )
    return headerJSX
  }

  const rJSX_Footer = (): JSX.Element => {
    let footerJSX = <></>
    footerJSX = (
      <Box sx={{ marginTop: 'auto' }}>
        <Divider />
        <Stack
          direction="row"
          className="tw-justify-between"
        >
          <Box className="tw-text-center">
            <Button
              size="small"
              color="inherit"
              className="tw-ml-1"
              onClick={(event) => {
                // Nothing
              }}
            >
              {s_PRIVACY_POLICY}
            </Button>
            <Button
              size="small"
              color="inherit"
              className="tw-opacity-30 tw-ml-1 tw-cursor-pointer"
              onClick={(event) => {
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.TermsOfServicePage.url())
              }}
            >
              {s_TERMS_OF_SERVICE}
            </Button>
          </Box>
          <Box
            className="tw-mt-1 tw-mr-1 tw-opacity-30 tw-text-center"
            sx={{ fontSize: '13px' }}
          >
            {s_COPYRIGHT} © {new Date().getFullYear()} {s_ETW_ENERGY_LLC}. {s_ALL_RIGHTS_RESERVED}.
          </Box>
        </Stack>
      </Box>
    )
    return footerJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box>
        {rJSX_StagingWarning()}
        {rJSX_Header()}
        <Divider />
        <Box
          className="tw-p-4 tw-m-auto"
          sx={{ maxWidth: '1200px' }}
        >
          <Card
            className="tw-p-4"
            sx={{ background: themeVariables.background_default }}
          >
            {rJSX_LegalDocument(returnPrivacyPolicyText(returnCurrentLegalVersionNumbers()['PrivacyPolicy']))}
          </Card>
        </Box>
        {rJSX_Footer()}
        <style>{pageCSS}</style>
      </Box>
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

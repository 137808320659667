///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import { returnProjectDocumentFolders } from 'app/models/projects/project_document_folders'
import { useContext, useEffect, useReducer, useState } from 'react'
import { DatabaseRef_Project_Documents_Collection } from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { TsType_DocumentTableFilterOptions } from '../active_projects_view'
import { rJSX_DocumentsTab } from '../project_view/tab_documents'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectFilesView {
  projectKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

// Table

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const ProjectFilesView: React.FC<TsInterface_ProjectFilesView> = ({ projectKey }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_documentTableFilter, us_setDocumentTableFilter] = useState<TsType_DocumentTableFilterOptions>('not_archived')
  const [us_documentsViewLevel, us_setDocumentsViewLevel] = useState<string>('root') // root | folder
  const [us_projectDocumentFolders, us_setProjectDocumentFolders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDocumentsFolder, us_setSelectedDocumentsFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDocumentsFolderData, us_setSelectedDocumentsFolderData] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setProjectDocumentFolders(returnProjectDocumentFolders(res_GCK.clientKey))
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSelectedDocumentsFolderData(newData)
      }
      ur_forceRerender()
    }
    if (projectKey != null && us_selectedDocumentsFolder != null && us_selectedDocumentsFolder.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_Project_Documents_Collection(res_GCK.clientKey, projectKey, us_selectedDocumentsFolder.key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedDocumentsFolderData({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedDocumentsFolder, projectKey, ur_forceRerender])

  // Functions

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        {rJSX_DocumentsTab(
          'read',
          uc_RootData_ClientKey,
          uc_RootData_ClientUser,
          us_documentTableFilter,
          us_documentsViewLevel,
          us_projectDocumentFolders,
          projectKey,
          us_selectedDocumentsFolder,
          us_selectedDocumentsFolderData,
          us_setDocumentTableFilter,
          us_setDocumentsViewLevel,
          uc_setRootData_ClientKey,
          us_setSelectedDocumentsFolder,
          us_setSelectedDocumentsFolderData,
          uc_setUserInterface_ErrorDialogDisplay,
          uc_setUserInterface_FormDialogDisplay,
        )}
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

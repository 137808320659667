///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { DatabaseRef_PayrollRates_MinimumWage_Document } from 'rfbp_aux/services/database_endpoints/finances/payroll_rates'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { formatCurrency, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Exports
///////////////////////////////

export const tableSettings_MinimumWage: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: false,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 245px)',
}

export const tableColumns_MinimumWage: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('State'), 'name'),
  effective_date_1: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Effective Date 1')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let editIconJSX = (
          <Icon
            icon="pen-to-square"
            className="tw-ml-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-success_main"
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {},
                    formInputs: {
                      effective_date_1: {
                        data_type: 'string',
                        input_type: 'timestamp_datetime',
                        key: 'effective_date_1',
                        label: rLIB('Effective Date 1'),
                        required: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          [rowData.key as string]: {
                            effective_date_1: new Date(formSubmittedData.effective_date_1),
                          },
                        }
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_PayrollRates_MinimumWage_Document(res_GCK.clientKey), updateObject)
                              .then((res_SMD) => {
                                resolve(res_SMD)
                              })
                              .catch((rej_SMD) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SMD.error })
                                reject(rej_SMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Effective Date 1')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        let valueJSX = <></>
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData['us_minimumWageData'] != null &&
          tableAdditionalData['us_minimumWageData'][rowData.key as string] != null &&
          tableAdditionalData['us_minimumWageData'][rowData.key as string]['effective_date_1'] != null
        ) {
          valueJSX = (
            <Box className="tw-inline-block">
              {returnFormattedDate(tableAdditionalData['us_minimumWageData'][rowData.key as string]['effective_date_1'], 'D MMM YYYY h:mm a')}
            </Box>
          )
        } else {
          valueJSX = <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
        }
        let cellJSX = (
          <Box>
            {valueJSX}
            {editIconJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  minimum_wage_1: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Minimum Wage 1')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let editIconJSX = (
          <Icon
            icon="pen-to-square"
            className="tw-ml-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-success_main"
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {},
                    formInputs: {
                      minimum_wage_1: {
                        data_type: 'number',
                        input_type: 'text_number',
                        key: 'minimum_wage_1',
                        label: rLIB('Minimum Wage 1'),
                        required: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          [rowData.key as string]: {
                            minimum_wage_1: formSubmittedData.minimum_wage_1,
                          },
                        }
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_PayrollRates_MinimumWage_Document(res_GCK.clientKey), updateObject)
                              .then((res_SMD) => {
                                resolve(res_SMD)
                              })
                              .catch((rej_SMD) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SMD.error })
                                reject(rej_SMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Minimum Wage 1')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        let valueJSX = <></>
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData['us_minimumWageData'] != null &&
          tableAdditionalData['us_minimumWageData'][rowData.key as string] != null &&
          tableAdditionalData['us_minimumWageData'][rowData.key as string]['minimum_wage_1'] != null
        ) {
          valueJSX = (
            <Box className="tw-inline-block">
              {formatCurrency(tableAdditionalData['us_minimumWageData'][rowData.key as string]['minimum_wage_1'], 'USD', 'en-US')}
            </Box>
          )
        } else {
          valueJSX = <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
        }
        let cellJSX = (
          <Box>
            {valueJSX}
            {editIconJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  effective_date_2: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Effective Date 2')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let editIconJSX = (
          <Icon
            icon="pen-to-square"
            className="tw-ml-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-success_main"
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {},
                    formInputs: {
                      effective_date_2: {
                        data_type: 'string',
                        input_type: 'timestamp_datetime',
                        key: 'effective_date_2',
                        label: rLIB('Effective Date 2'),
                        required: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          [rowData.key as string]: {
                            effective_date_2: new Date(formSubmittedData.effective_date_2),
                          },
                        }
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_PayrollRates_MinimumWage_Document(res_GCK.clientKey), updateObject)
                              .then((res_SMD) => {
                                resolve(res_SMD)
                              })
                              .catch((rej_SMD) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SMD.error })
                                reject(rej_SMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Effective Date 2')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        let valueJSX = <></>
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData['us_minimumWageData'] != null &&
          tableAdditionalData['us_minimumWageData'][rowData.key as string] != null &&
          tableAdditionalData['us_minimumWageData'][rowData.key as string]['effective_date_2'] != null
        ) {
          valueJSX = (
            <Box className="tw-inline-block">
              {returnFormattedDate(tableAdditionalData['us_minimumWageData'][rowData.key as string]['effective_date_2'], 'D MMM YYYY h:mm a')}
            </Box>
          )
        } else {
          valueJSX = <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
        }
        let cellJSX = (
          <Box>
            {valueJSX}
            {editIconJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  minimum_wage_2: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Minimum Wage 2')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let editIconJSX = (
          <Icon
            icon="pen-to-square"
            className="tw-ml-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-success_main"
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {},
                    formInputs: {
                      minimum_wage_2: {
                        data_type: 'number',
                        input_type: 'text_number',
                        key: 'minimum_wage_2',
                        label: rLIB('Minimum Wage 2'),
                        required: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          [rowData.key as string]: {
                            minimum_wage_2: formSubmittedData.minimum_wage_2,
                          },
                        }
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_PayrollRates_MinimumWage_Document(res_GCK.clientKey), updateObject)
                              .then((res_SMD) => {
                                resolve(res_SMD)
                              })
                              .catch((rej_SMD) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SMD.error })
                                reject(rej_SMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Minimum Wage 2')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        let valueJSX = <></>
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData['us_minimumWageData'] != null &&
          tableAdditionalData['us_minimumWageData'][rowData.key as string] != null &&
          tableAdditionalData['us_minimumWageData'][rowData.key as string]['minimum_wage_2'] != null
        ) {
          valueJSX = (
            <Box className="tw-inline-block">
              {formatCurrency(tableAdditionalData['us_minimumWageData'][rowData.key as string]['minimum_wage_2'], 'USD', 'en-US')}
            </Box>
          )
        } else {
          valueJSX = <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
        }
        let cellJSX = (
          <Box>
            {valueJSX}
            {editIconJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, schedulingTeamTypes } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_PayrollRates_TaskUnitMapping_Document } from 'rfbp_aux/services/database_endpoints/finances/payroll_rates'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_ScheduledTasksInDateRange_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  DatabaseRef_GroupedTimePunches_Document,
  DatabaseRef_GroupedTimePunches_Task_Query,
} from 'rfbp_aux/services/database_endpoints/timesheets/linked_time_punches'
import { DatabaseRef_TimePunches_DateRange_Query } from 'rfbp_aux/services/database_endpoints/timesheets/time_punches'
import { DatePicker } from 'rfbp_core/components/date_picker/date_picker'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseStagedBatchUpdate,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnDateFromUnknownDateFormat, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { getStartAndEndOfWeek } from '../payroll/data/payroll_calculations'
import { formatDateRange } from '../payroll/tables/payroll_tables'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['TaskEfficiencyPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

function splitAfterFirstInstance(inputString: string, searchString: string) {
  const index = inputString.indexOf(searchString)
  return inputString.slice(index + searchString.length)
}

const getScheduledTasksAndHoursForDateRange = async (clientKey: string, startDate: Date, endDate: Date): Promise<any> => {
  return new Promise((resolve, reject) => {
    // let combinedData: TsInterface_UnspecifiedObject = {}
    let promiseArray1: TsType_UnknownPromise[] = []
    let promiseArray2: TsType_UnknownPromise[] = []
    let payrollRatesData: TsInterface_UnspecifiedObject = {}
    promiseArray1.push(
      DatabaseGetDocument(DatabaseRef_PayrollRates_TaskUnitMapping_Document(clientKey))
        .then((res_DGD) => {
          payrollRatesData = res_DGD.data
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    Promise.all(promiseArray1).finally(() => {
      DatabaseGetCollection(DatabaseRef_ScheduledTasksInDateRange_Query(clientKey, startDate, endDate))
        .then((res_DGC) => {
          let scheduledTasksData = res_DGC.data
          for (let loopTaskKey in scheduledTasksData) {
            let loopTask = scheduledTasksData[loopTaskKey]
            scheduledTasksData[loopTaskKey].TEMP_task_unit_evaluation = 'MISSING'
            // Get Task Unit Mapping and projects to get expected hours
            let taskUnitMapping: TsInterface_UnspecifiedObject | null = null
            if (
              payrollRatesData != null &&
              loopTask != null &&
              loopTask.associated_task_key != null &&
              payrollRatesData[loopTask.associated_task_key] != null
            ) {
              taskUnitMapping = payrollRatesData[loopTask.associated_task_key]
            } else if (
              payrollRatesData != null &&
              loopTask != null &&
              loopTask.associated_task_key != null &&
              loopTask.associated_task_key.indexOf('_sow_') > -1 &&
              splitAfterFirstInstance(loopTask.associated_task_key, '_sow_') != null &&
              payrollRatesData[splitAfterFirstInstance(loopTask.associated_task_key, '_sow_')] != null
            ) {
              taskUnitMapping = payrollRatesData[splitAfterFirstInstance(loopTask.associated_task_key, '_sow_')]
            }
            if (taskUnitMapping != null) {
              switch (getProp(taskUnitMapping, 'task_unit_evaluation', null)) {
                case 'hardcode':
                  scheduledTasksData[loopTaskKey].TEMP_task_unit_evaluation = 'hardcode'
                  scheduledTasksData[loopTaskKey].TEMP_task_units = getProp(taskUnitMapping, 'task_units', 0)
                  break
                case 'panels':
                  if (loopTask != null && loopTask.associated_project_key != null) {
                    scheduledTasksData[loopTaskKey].TEMP_task_unit_evaluation = 'panels'
                    promiseArray2.push(
                      DatabaseGetDocument(DatabaseRef_Project_Document(clientKey, loopTask.associated_project_key))
                        .then((res_DGD) => {
                          // scheduledTasksData[loopTaskKey].TEMP_project = res_DGD.data
                          scheduledTasksData[loopTaskKey].TEMP_system_panel_quantity = getProp(res_DGD.data, 'system_panel_quantity', null)
                          scheduledTasksData[loopTaskKey].TEMP_system_storage_quantity = getProp(res_DGD.data, 'system_storage_quantity', null)
                          scheduledTasksData[loopTaskKey].TEMP_system_max_roof_pitch = getProp(res_DGD.data, 'system_max_roof_pitch', null)
                          if (getProp(res_DGD.data, 'system_panel_quantity', null) != null) {
                            scheduledTasksData[loopTaskKey].TEMP_task_units = getProp(res_DGD.data, 'system_panel_quantity', null) / 2
                          } else {
                            scheduledTasksData[loopTaskKey].TEMP_task_units = 0
                          }
                        })
                        .catch((rej_DGD) => {
                          console.error(rej_DGD)
                          scheduledTasksData[loopTaskKey].TEMP_task_units = 0
                        }),
                    )
                  } else {
                    scheduledTasksData[loopTaskKey].TEMP_task_units = 0
                  }
                  break
                case 'custom':
                  scheduledTasksData[loopTaskKey].TEMP_task_unit_evaluation = 'custom'
                  scheduledTasksData[loopTaskKey].TEMP_task_units = getProp(loopTask, 'associated_payroll_custom_task_units', 0)

                  // associated_payroll_custom_task_units

                  break
              }
            }
            // Get Linked Timestamps to get actual hours
            promiseArray2.push(
              DatabaseGetCollection(DatabaseRef_GroupedTimePunches_Task_Query(clientKey, loopTaskKey))
                .then((res_DGC) => {
                  scheduledTasksData[loopTaskKey].TEMP_TimePunches = res_DGC.data
                })
                .catch((rej_DGC) => {
                  console.error(rej_DGC)
                }),
            )
          }
          // Data Processing
          Promise.all(promiseArray2).finally(() => {
            // Add a couple additional fields here
            for (let loopTaskKey in scheduledTasksData) {
              let loopTask = scheduledTasksData[loopTaskKey]
              let totalMinutes = 0
              let totalHours = 0
              let userHours: TsInterface_UnspecifiedObject = {}
              if (loopTask.TEMP_TimePunches != null) {
                for (let loopPunchKey in loopTask.TEMP_TimePunches) {
                  let loopPunch = loopTask.TEMP_TimePunches[loopPunchKey]
                  totalMinutes += loopPunch.clocked_minutes
                  if (userHours[loopPunch.associated_user_key] == null) {
                    userHours[loopPunch.associated_user_key] = {
                      name: loopPunch.associated_user_name,
                      minutes: 0,
                    }
                  }
                  userHours[loopPunch.associated_user_key].minutes += loopPunch.clocked_minutes
                }
              }
              totalHours = totalMinutes / 60
              scheduledTasksData[loopTaskKey].TEMP_total_hours_for_whole_team = totalHours
              // Get Number of people on task
              let numberOfPeople = 0
              let peopleOnTask: TsInterface_UnspecifiedObject = {}
              for (let loopDateKey in getProp(loopTask, 'task_completion_scheduled_team_names', {})) {
                let loopDate = loopTask.task_completion_scheduled_team_names[loopDateKey]
                for (let loopNameKey in loopDate) {
                  let loopName = loopDate[loopNameKey]
                  if (peopleOnTask[loopName] == null) {
                    peopleOnTask[loopName] = true
                    numberOfPeople++
                  }
                  if (userHours[loopNameKey] == null) {
                    userHours[loopNameKey] = {
                      name: loopName,
                      minutes: 0,
                    }
                  }
                  if (userHours[loopNameKey] != null && userHours[loopNameKey].name == null && loopName != null) {
                    userHours[loopNameKey].name = loopName
                  }
                }
              }
              scheduledTasksData[loopTaskKey].TEMP_total_hours_by_team_member = userHours
              scheduledTasksData[loopTaskKey].TEMP_number_of_people_on_task = numberOfPeople
              scheduledTasksData[loopTaskKey].TEMP_people_on_task = peopleOnTask
              if (numberOfPeople > 0) {
                scheduledTasksData[loopTaskKey].TEMP_total_hours_per_person = parseFloat((totalHours / numberOfPeople).toFixed(1))
                // if (scheduledTasksData[loopTaskKey].TEMP_task_units != null && scheduledTasksData[loopTaskKey].TEMP_task_units > 0) {
                scheduledTasksData[loopTaskKey].TEMP_hours_per_person = parseFloat((totalHours / numberOfPeople).toFixed(1))
                // } else {
                // scheduledTasksData[loopTaskKey].TEMP_hours_per_person = 0
                // }
              } else {
                scheduledTasksData[loopTaskKey].TEMP_total_hours_per_person = 0
                scheduledTasksData[loopTaskKey].TEMP_hours_per_person = 0
              }
              // Get Percent Complete
              if (scheduledTasksData[loopTaskKey].TEMP_hours_per_person != 0 && scheduledTasksData[loopTaskKey].TEMP_task_units != 0) {
                scheduledTasksData[loopTaskKey].TEMP_percent_complete =
                  (scheduledTasksData[loopTaskKey].TEMP_hours_per_person / scheduledTasksData[loopTaskKey].TEMP_task_units) * 100
              } else {
                scheduledTasksData[loopTaskKey].TEMP_percent_complete = 0
              }
              if (isNaN(scheduledTasksData[loopTaskKey].TEMP_percent_complete)) {
                scheduledTasksData[loopTaskKey].TEMP_percent_complete = 0
              }
            }
            // TODO: Loop through and generate user data
            let userData: TsInterface_UnspecifiedObject = {}
            for (let loopTaskKey in scheduledTasksData) {
              let loopTask = scheduledTasksData[loopTaskKey]
              for (let loopUserKey in loopTask.TEMP_total_hours_by_team_member) {
                let loopUser = loopTask.TEMP_total_hours_by_team_member[loopUserKey]
                if (userData[loopUserKey] == null) {
                  userData[loopUserKey] = {
                    name: loopUser.name,
                    total_hours: 0,
                    total_units: 0,
                    total_percent: 0,
                    tasks: {},
                  }
                }
                userData[loopUserKey].total_hours += loopUser.minutes / 60
                if (loopTask.TEMP_task_units == null) {
                  loopTask.TEMP_task_units = 0
                }
                userData[loopUserKey].total_units += loopTask.TEMP_task_units
                userData[loopUserKey].total_percent = (userData[loopUserKey].total_hours / userData[loopUserKey].total_units) * 100
                userData[loopUserKey].tasks[loopTaskKey] = {
                  hours: loopUser.minutes / 60,
                  units: loopTask.TEMP_task_units,
                  percent: (loopUser.minutes / 60 / loopTask.TEMP_task_units) * 100,
                  associated_project_id_number: loopTask.associated_project_id_number,
                  name: loopTask.name,
                }
              }
            }
            // Return Data
            resolve({
              success: true,
              task_data: scheduledTasksData,
              user_data: userData,
            })
          })
        })
        .catch((rej_DGC) => {
          reject(rej_DGC)
        })
    })
  })
}

// TODO: Move to Server
const cacheCleanPunchData = async (clientKey: string, startDate: Date, endDate: Date): Promise<any> => {
  return new Promise((resolve, reject) => {
    // Instantiate Variables
    let promiseArray: TsType_UnknownPromise[] = []
    let employeePunchData: TsInterface_UnspecifiedObject = {}
    let errorsLoadingData = false
    let rawPunchData: TsInterface_UnspecifiedObject = {}
    let processedPunchData: TsInterface_UnspecifiedObject = {}
    // Load Data
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TimePunches_DateRange_Query(clientKey, startDate, endDate))
        .then((res_DGC) => {
          rawPunchData = res_DGC.data
        })
        .catch((rej_DGC) => {
          errorsLoadingData = true
          console.error(rej_DGC)
        }),
    )
    // After Data Loaded
    Promise.all(promiseArray).finally(() => {
      if (errorsLoadingData === false) {
        // Loop through and separate punches by employee
        for (let loopPunchKey in rawPunchData) {
          let loopPunch = rawPunchData[loopPunchKey]
          if (employeePunchData[loopPunch.associated_user_key] == null) {
            employeePunchData[loopPunch.associated_user_key] = []
          }
          loopPunch.timestamp = returnDateFromUnknownDateFormat(loopPunch.timestamp).getTime()
          if (loopPunch.status !== 'deleted') employeePunchData[loopPunch.associated_user_key].push(loopPunch)
        }
        // Loop through employees and sort punches by timestamp
        for (let loopEmployeeKey in employeePunchData) {
          let loopEmployeePunches = employeePunchData[loopEmployeeKey]
          loopEmployeePunches.sort((a: { timestamp: number }, b: { timestamp: number }) => {
            return a.timestamp - b.timestamp
          })
          // Loop through punches and make sure that the first punch is a clock in and the last punch is a clock out
          if (loopEmployeePunches != null && loopEmployeePunches[0] != null && loopEmployeePunches[0].type !== 'clock_in') {
            // Error: First punch is not a clock in
          }
          if (
            loopEmployeePunches != null &&
            loopEmployeePunches[loopEmployeePunches.length - 1] != null &&
            loopEmployeePunches[loopEmployeePunches.length - 1].type !== 'clock_out'
          ) {
            // Error: Last punch is not a clock out
          }
          // Loop through punches and make sure that they alternate between clock in and clock out
          // Also total up the time between punches
          for (let loopPunchIndex in loopEmployeePunches) {
            let loopPunch = loopEmployeePunches[loopPunchIndex]
            if (parseInt(loopPunchIndex) !== 0) {
              let previousLoopPunch = loopEmployeePunches[parseInt(loopPunchIndex) - 1]
              if (loopPunch.type === previousLoopPunch.type) {
                // Error: Back to back punches
              }
              if (loopPunch.type !== 'clock_in' && loopPunch.type !== 'clock_out') {
                // Error: Punch type is not clock in or clock out
              }
              if (loopPunch.type === 'clock_out') {
                // Subtract previous punch timestamp from current punch timestamp to get total time
                let totalMinutes = (loopPunch.timestamp - previousLoopPunch.timestamp) / (1000 * 60)
                processedPunchData[loopPunch.key] = {
                  associated_clock_in_key: previousLoopPunch.key,
                  associated_tasks: previousLoopPunch.associated_tasks,
                  associated_task_keys: {},
                  associated_user_key: loopPunch.associated_user_key,
                  associated_user_name: loopPunch.associated_user_name,
                  clocked_minutes: totalMinutes,
                  key: loopPunch.key,
                  status: getProp(loopPunch, 'status', 'active'),
                  timestamp: loopPunch.timestamp,
                }
                for (let loopTaskKey in previousLoopPunch.associated_tasks) {
                  processedPunchData[loopPunch.key].associated_task_keys[loopTaskKey] = true
                }
              }
            }
          }
        }
        // Save Data
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let loopPunchKey in processedPunchData) {
          updateArray.push({
            type: 'setMerge',
            ref: DatabaseRef_GroupedTimePunches_Document(clientKey as string, loopPunchKey),
            data: processedPunchData[loopPunchKey],
          })
        }
        // Save to Database
        DatabaseStagedBatchUpdate(updateArray)
          .then((res_DSBU) => {
            resolve(res_DSBU)
          })
          .catch((rej_DSBU) => {
            reject(rej_DSBU)
          })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to aggregate timesheet data'),
            details: rLIB('Failed to query database'),
            code: 'ER-D-PC-RAPDFPC-01',
          },
        })
      }
    })
  })
}

const tableColumns_TaskEfficiency: TsInterface_TableColumns = {
  associated_project_id_number: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            <Box>{getProp(rowData, 'associated_project_id_number', '-')}</Box>
            <Box>{getProp(rowData, 'name', '-')}</Box>
            <Box className="tw-opacity-40">{getProp(rowData, 'associated_team_name', '-')}</Box>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Task') as JSX.Element
      },
      header_sort_by: 'associated_project_id_number',
    },
  },
  timestamp_completed: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let scheduledDateJSX = <></>
        let completionDateJSX = <></>
        if (getProp(rowData, 'timestamp_completed', null) != null) {
          completionDateJSX = (
            <Box>
              <Box className="tw-inline-block tw-mr-2">{rLIB('Completed')}:</Box>
              <Box className="tw-inline-block">{returnFormattedDate(rowData.timestamp_completed, 'D MMM YY')}</Box>
            </Box>
          )
        } else {
          completionDateJSX = (
            <Box>
              <Box className="tw-text-warning_main tw-inline-block tw-mr-2">{rLIB('Completed')}:</Box>
              <Box className="tw-text-warning_main tw-inline-block">{rLIB('Task Not Completed')}</Box>
            </Box>
          )
        }
        scheduledDateJSX = (
          <Box>
            <Box className="tw-inline-block tw-mr-2">{rLIB('Scheduled')}:</Box>
            <Box className="tw-inline-block">{formatDateRange(getProp(rowData, 'task_completion_scheduled_dates', []))}</Box>
          </Box>
        )
        cellJSX = (
          <Box>
            {scheduledDateJSX}
            {/* <Divider className="tw-my-1" /> */}
            {completionDateJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Dates') as JSX.Element
      },
      header_sort_by: 'timestamp_completed',
    },
  },
  associated_team_type: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          schedulingTeamTypes != null &&
          schedulingTeamTypes[rowData.associated_team_type as string] != null &&
          schedulingTeamTypes[rowData.associated_team_type as string]['value'] != null
        ) {
          cellJSX = <>{schedulingTeamTypes[rowData.associated_team_type as string]['value']}</>
        } else {
          cellJSX = <>{getProp(rowData, 'TEMP_task_units', 0)}</>
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Task Type') as JSX.Element
      },
      header_sort_by: 'associated_team_type',
    },
  },
  TEMP_task_units: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(rowData, 'TEMP_task_units', 0) == 0) {
          cellCSS = 'tw-bg-error_main'
        }
        return cellCSS
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = <>{getProp(rowData, 'TEMP_task_units', 0)}</>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Units') as JSX.Element
      },
      header_sort_by: 'TEMP_task_units',
    },
  },
  TEMP_hours_per_person: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (getProp(rowData, 'TEMP_hours_per_person', 0) == 0) {
          cellCSS = 'tw-bg-error_main'
        }
        return cellCSS
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = <>{getProp(rowData, 'TEMP_hours_per_person', 0)}</>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Avg Hours/Team Member') as JSX.Element
      },
      header_sort_by: 'TEMP_hours_per_person',
    },
  },
  TEMP_percent_complete: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // Cell
        let cellJSX = <Box>{getProp(rowData, 'TEMP_percent_complete', 0).toFixed(0)}%</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Percent') as JSX.Element
      },
      header_sort_by: 'TEMP_percent_complete',
    },
  },
  TEMP_progress: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // Progress Bar
        const rJSX_ProgressBar = (employee: TsInterface_UnspecifiedObject): JSX.Element => {
          let progressBarJSX = <></>

          let progressWidth = 0
          let originalProgressWidth = 0
          if (getProp(rowData, 'TEMP_task_units', 0) > 0) {
            progressWidth = (employee['minutes'] / 60 / getProp(rowData, 'TEMP_task_units', 0)) * 100
            originalProgressWidth = progressWidth
          }
          if (progressWidth > 100) {
            progressWidth = 100
          }
          if (progressWidth < 0) {
            progressWidth = 0
          }
          // Progress Color
          let backgroundColor = themeVariables.background_default
          if (originalProgressWidth > 120) {
            backgroundColor = themeVariables.error_main
          } else if (originalProgressWidth > 100) {
            backgroundColor = themeVariables.warning_main
          } else {
            backgroundColor = `linear-gradient(to right, ${themeVariables.success_main} ${progressWidth}%, ${themeVariables.background_default} ${progressWidth}%)`
          }
          progressBarJSX = (
            <Box sx={{ width: '300px', padding: '2px' }}>
              <Box
                sx={{
                  // width: '100%', height: '32px', borderRadius: '6px', padding: '6px', background: themeVariables.background_default }}>
                  width: '100%',
                  height: '32px',
                  borderRadius: '6px',
                  padding: '6px',
                  background: backgroundColor,
                }}
              >
                <Box className="tw-mr-2 tw-inline-block">{employee['name']}</Box>
                <Box className="tw-opacity-40 tw-inline-block">
                  {(employee['minutes'] / 60).toFixed(1)} {rLIB('hrs')}
                </Box>
              </Box>
            </Box>
          )
          return progressBarJSX
        }
        // Cell
        let cellJSX = (
          <Box>
            {objectToArray(getProp(rowData, 'TEMP_total_hours_by_team_member', {}))
              .sort(dynamicSort('name', null))
              .map((employee: TsInterface_UnspecifiedObject, index: number) => (
                <Box key={index}>{rJSX_ProgressBar(employee)}</Box>
              ))}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Progress') as JSX.Element
      },
      header_sort_by: 'name',
    },
  },
}

const tableSettings_TaskEfficiency: TsInterface_TableSettings = {
  paginated: false,
  size: 'small',
  sort_direction: 'desc',
  sort_property_default: 'TEMP_percent_complete',
  sortable: true,
  alternate_row_color_hex: themeVariables.background_hover,
  alternate_row_colors: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 180px)',
}

const tableColumns_EmployeeEfficiency: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Employee'), 'name'),
  total_units: TableCellBasic('total_units', rLIB('Units'), 'total_units'),
  total_hours: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = <>{getProp(rowData, 'total_hours', 0).toFixed(1)}</>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Hours') as JSX.Element
      },
      header_sort_by: 'total_hours',
    },
  },
  total_percent: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = <>{getProp(rowData, 'total_percent', 0).toFixed(1)}%</>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Percent') as JSX.Element
      },
      header_sort_by: 'total_percent',
    },
  },
  TEMP_progress: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // Progress Bar
        const rJSX_ProgressBar = (task: TsInterface_UnspecifiedObject): JSX.Element => {
          let progressBarJSX = <></>
          let progressWidth = 0
          let originalProgressWidth = 0
          if (getProp(task, 'units', 0) > 0) {
            progressWidth = (task['hours'] / getProp(task, 'units', 0)) * 100
            originalProgressWidth = progressWidth
          }
          if (progressWidth > 100) {
            progressWidth = 100
          }
          if (progressWidth < 0) {
            progressWidth = 0
          }
          // Progress Color
          let backgroundColor = themeVariables.background_default
          if (originalProgressWidth > 120) {
            backgroundColor = themeVariables.error_main
          } else if (originalProgressWidth > 100) {
            backgroundColor = themeVariables.warning_main
          } else {
            backgroundColor = `linear-gradient(to right, ${themeVariables.success_main} ${progressWidth}%, ${themeVariables.background_default} ${progressWidth}%)`
          }
          progressBarJSX = (
            <Box sx={{ width: '500px', padding: '2px' }}>
              <Box
                sx={{
                  // width: '100%', height: '32px', borderRadius: '6px', padding: '6px', background: themeVariables.background_default }}>
                  width: '100%',
                  minHeight: '32px',
                  borderRadius: '6px',
                  padding: '6px',
                  background: backgroundColor,
                }}
              >
                <Box className="tw-mr-2 tw-inline-block">
                  {task['associated_project_id_number']} - {task['name']}
                </Box>
                <Box className="tw-opacity-40 tw-inline-block">
                  {task['hours'].toFixed(1)} {rLIB('hrs')} / {task['units'].toFixed(1)} {rLIB('units')}
                </Box>
              </Box>
            </Box>
          )
          return progressBarJSX
        }
        // Cell
        let cellJSX = (
          <Box>
            {objectToArray(getProp(rowData, 'tasks', {}))
              .sort(dynamicSort('associated_project_id_number', null))
              .map((task: TsInterface_UnspecifiedObject, index: number) => (
                <Box key={index}>{rJSX_ProgressBar(task)}</Box>
              ))}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Progress') as JSX.Element
      },
      header_sort_by: 'progress',
    },
  },
}

const tableSettings_EmployeeEfficiency: TsInterface_TableSettings = {
  paginated: false,
  size: 'small',
  sort_direction: 'desc',
  sort_property_default: 'total_percent',
  sortable: true,
  alternate_row_color_hex: themeVariables.background_hover,
  alternate_row_colors: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 180px)',
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_efficiencyTaskData, us_setEfficiencyTaskData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userTaskData, us_setUserTaskData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const [us_weekEndDate, us_setWeekEndDate] = useState<Date | null>(null)
  const [us_weekStartDate, us_setWeekStartDate] = useState<Date | null>(null)
  const [us_cachingData, us_setCachingData] = useState<boolean>(false)
  const [us_lastCachedTimestamp, us_setLastCachedTimestamp] = useState<Date>(new Date())
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Task Efficiency', false) as string
  }, [])

  useEffect(() => {
    let weekBoundingDates = getStartAndEndOfWeek(us_selectedDate)
    us_setWeekStartDate(weekBoundingDates.startOfWeek)
    us_setWeekEndDate(weekBoundingDates.endOfWeek)
    return () => {}
  }, [us_selectedDate])

  useEffect(() => {
    if (us_weekStartDate != null && us_weekEndDate != null && us_lastCachedTimestamp != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
        getScheduledTasksAndHoursForDateRange(res_GCK.clientKey, us_weekStartDate, us_weekEndDate).then((res_GSTAHFDR) => {
          us_setEfficiencyTaskData(res_GSTAHFDR.task_data)
          us_setUserTaskData(res_GSTAHFDR.user_data)
        })
      })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_weekEndDate, us_weekStartDate, us_lastCachedTimestamp])

  // Functions
  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      // let formattedDateKey = returnFormattedDateKey( new Date( newDate ) )
      us_setSelectedDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  // JSX Generation
  const rJSX_DatePicker = (disabled: boolean): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Date')}
          datePickerDate={us_selectedDate}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
          datePickerDisabled={disabled}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_CacheButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="info"
        variant="outlined"
        disabled={us_cachingData}
        onClick={() => {
          ur_forceRerender()
          if (us_weekStartDate != null && us_weekEndDate != null) {
            us_setCachingData(true)
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                cacheCleanPunchData(res_GCK.clientKey, us_weekStartDate, us_weekEndDate)
                  .then((res_CCPD) => {
                    us_setCachingData(false)
                    us_setLastCachedTimestamp(new Date())
                  })
                  .catch((rej_CCPD) => {
                    us_setCachingData(false)
                  })
              })
              .catch((rej_GCK) => {
                us_setCachingData(false)
              })
          }
        }}
        startIcon={
          us_cachingData ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="database" />
          )
        }
      >
        {rLIB('Recache Timecard Data')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_TaskTable = (): JSX.Element => {
    let tableJSX = (
      <Card>
        <TableBasic
          tableAdditionalData={{}}
          tableColumns={tableColumns_TaskEfficiency}
          tableData={objectToArray(us_efficiencyTaskData)}
          tableSettings={tableSettings_TaskEfficiency}
        />
      </Card>
    )
    return tableJSX
  }

  const rJSX_EmployeesTable = (): JSX.Element => {
    let tableJSX = (
      <Card>
        <TableBasic
          tableAdditionalData={{}}
          tableColumns={tableColumns_EmployeeEfficiency}
          tableData={objectToArray(us_userTaskData)}
          tableSettings={tableSettings_EmployeeEfficiency}
        />
      </Card>
    )
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Task Efficiency')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'tasks',
                  tabHeader: rLIB('Tasks'),
                  tabButtons: [
                    { fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(false), sizeCutoff: 0 },
                    { fullJSX: rJSX_CacheButton(), minJSX: rJSX_CacheButton(), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_TaskTable(),
                },
                {
                  tabOnChange: () => {},
                  tabUrlKey: 'employees',
                  tabHeader: rLIB('Employees'),
                  tabButtons: [
                    { fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(false), sizeCutoff: 0 },
                    { fullJSX: rJSX_CacheButton(), minJSX: rJSX_CacheButton(), sizeCutoff: 0 },
                  ],
                  tabContent: rJSX_EmployeesTable(),
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.TaskEfficiencyPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Task Efficiency', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

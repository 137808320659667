import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

export const DatabaseRef_Hoas_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'hoas', 'main')
}

export const DatabaseRef_Hoa_Document = (clientKey: string, hoaKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'hoas', 'main', hoaKey)
}

export const DatabaseRef_Hoas_Search = (clientKey: string, searchProperty: string, searchValue: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: searchProperty, comparator: '>=', value: searchValue },
    { prop: searchProperty, comparator: '<=', value: searchValue + '~' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'hoas', 'main'), queryOperatorsArray, orderByArray, {}, limit)
}

// Files
export const DatabaseRef_HoaFiles_Collection = (clientKey: string, hoaKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'hoas', 'main', hoaKey, 'files')
}

export const DatabaseRef_HoaFile_Document = (clientKey: string, hoaKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'hoas', 'main', hoaKey, 'files', fileKey)
}

// Forum
export const DatabaseRef_HoaForumThreads_Collection = (clientKey: string, hoaKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'hoas', 'main', hoaKey, 'forum')
}

export const DatabaseRef_HoaForumThread_Document = (clientKey: string, hoaKey: string, threadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'hoas', 'main', hoaKey, 'forum', threadKey)
}

export const DatabaseRef_HoaForumMessages_Collection = (clientKey: string, hoaKey: string, threadKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'hoas', 'main', hoaKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_HoaForumMessage_Document = (
  clientKey: string,
  hoaKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'hoas', 'main', hoaKey, 'forum', threadKey, 'messages', messageKey)
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Orders_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'materials', 'orders', 'main')
}

export const DatabaseRef_Order_Document = (clientKey: string, orderKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'materials', 'orders', 'main', orderKey)
}

export const DatabaseRef_OrdersScheduledBetweenDates_Query = (clientKey: string, monDateKey: string, sunDateKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_scheduled_pickup_date_key', comparator: '>=', value: monDateKey },
    { prop: 'timestamp_scheduled_pickup_date_key', comparator: '<=', value: sunDateKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'orders', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_OrdersScheduledBetweenDates_ForSpecificSupplier_Query = (
  clientKey: string,
  supplierKey: string,
  startDateKey: string,
  endDateKey: string,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_supplier_key', comparator: '==', value: supplierKey },
    { prop: 'timestamp_scheduled_pickup_date_key', comparator: '>=', value: startDateKey },
    { prop: 'timestamp_scheduled_pickup_date_key', comparator: '<=', value: endDateKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'materials', 'orders', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

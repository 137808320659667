///////////////////////////////
// Imports
///////////////////////////////

import { Box, Chip, Switch } from '@mui/material'
import { DatabaseRef_TaskBlueprint_Document } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_PayrollRates_TaskUnitMapping_Document } from 'rfbp_aux/services/database_endpoints/finances/payroll_rates'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { formatCurrency, getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { unitWorkTypes } from '../data/payroll_calculations'

///////////////////////////////
// Functions
///////////////////////////////

const rJSX_UnitWorkTypeMappingChip = (
  version: 'v1' | 'v2',
  task: TsInterface_UnspecifiedObject,
  tableAdditionalData: TsInterface_UnspecifiedObject,
  taskUnitMapping: TsInterface_UnspecifiedObject,
  tableHooks: TsInterface_TableHooks,
): JSX.Element => {
  let chipJSX = <></>
  let unitWorkTypeOptions: TsInterface_UnspecifiedObject[] = []
  for (let loopUnitWorkTypeKey in unitWorkTypes) {
    let loopUnitWorkType = unitWorkTypes[loopUnitWorkTypeKey]
    unitWorkTypeOptions.push({
      key: loopUnitWorkTypeKey,
      value: loopUnitWorkType.abbreviation,
    })
  }
  let formInputs_UnitWorkType: TsInterface_FormInputs = {
    unit_work_type: {
      key: 'unit_work_type',
      label: rLIB('Unit Work Type'),
      input_type: 'multiple_choice_radio',
      required: true,
      data_type: 'string',
      options: unitWorkTypeOptions,
      submit_on_change: true,
    },
  }
  if (
    task != null &&
    task.key != null &&
    taskUnitMapping != null &&
    taskUnitMapping[task.key] != null &&
    taskUnitMapping[task.key]['unit_work_type'] != null &&
    unitWorkTypes[taskUnitMapping[task.key]['unit_work_type']] != null &&
    unitWorkTypes[taskUnitMapping[task.key]['unit_work_type']]['abbreviation'] != null
  ) {
    chipJSX = (
      <Box className="tw-inline-block">
        <Chip
          sx={{ maxWidth: '190px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={unitWorkTypes[taskUnitMapping[task.key]['unit_work_type']]['abbreviation']}
          variant="filled"
          onClick={() => {
            if (tableAdditionalData.editable === true) {
              if (version === 'v2') {
                openV2PropertyEditForm(task, formInputs_UnitWorkType, tableHooks)
              } else if (version === 'v1') {
                openV1PropertyEditForm(task, formInputs_UnitWorkType, tableAdditionalData, tableHooks)
              }
            }
          }}
        />
      </Box>
    )
  } else if (task != null && task.key != null && taskUnitMapping[task.key] != null && taskUnitMapping[task.key]['unit_work_type'] != null) {
    chipJSX = (
      <Chip
        sx={{ maxWidth: '190px' }}
        className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
        label={taskUnitMapping[task.key]['unit_work_type']}
        variant="filled"
        color="warning"
        onClick={() => {
          if (tableAdditionalData.editable === true) {
            if (version === 'v2') {
              openV2PropertyEditForm(task, formInputs_UnitWorkType, tableHooks)
            } else if (version === 'v1') {
              openV1PropertyEditForm(task, formInputs_UnitWorkType, tableAdditionalData, tableHooks)
            }
          }
        }}
      />
    )
  } else {
    chipJSX = (
      <Chip
        sx={{ maxWidth: '190px' }}
        className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
        label={rLIB('Not Set')}
        variant="filled"
        color="error"
        onClick={() => {
          if (tableAdditionalData.editable === true) {
            if (version === 'v2') {
              openV2PropertyEditForm(task, formInputs_UnitWorkType, tableHooks)
            } else if (version === 'v1') {
              openV1PropertyEditForm(task, formInputs_UnitWorkType, tableAdditionalData, tableHooks)
            }
          }
        }}
      />
    )
  }
  return chipJSX
}

const rJSX_TaskUnitsMappingChip = (
  version: 'v1' | 'v2',
  task: TsInterface_UnspecifiedObject,
  tableAdditionalData: TsInterface_UnspecifiedObject,
  taskUnitMapping: TsInterface_UnspecifiedObject,
  hardcodeValueOnly: boolean,
  tableHooks: TsInterface_TableHooks,
): JSX.Element => {
  let chipJSX = <></>
  let formInputs_TaskUnits: TsInterface_FormInputs = {
    task_unit_evaluation: {
      key: 'task_unit_evaluation',
      label: rLIB('Task Unit Evaluation'),
      input_type: 'multiple_choice_radio',
      required: true,
      data_type: 'string',
      options: [
        { value: rLIB('Case by Case Basis'), key: 'custom', disabled: hardcodeValueOnly },
        { value: rLIB('Number of Panels'), key: 'panels', disabled: hardcodeValueOnly },
        { value: rLIB('Specific Value'), key: 'hardcode' },
      ],
    },
    task_units: {
      conditional_display: {
        active: true,
        logic: {
          active: true,
          logic_type: 'comparison',
          source: 'formData',
          prop: 'task_unit_evaluation',
          comparator: '==',
          value: 'hardcode',
          conditions: [],
        },
      },
      conditional_require: {
        active: true,
        logic: {
          active: true,
          logic_type: 'comparison',
          source: 'formData',
          prop: 'task_unit_evaluation',
          comparator: '==',
          value: 'hardcode',
          conditions: [],
        },
      },
      key: 'task_units',
      label: rLIB('Units'),
      input_type: 'text_number',
      required: false,
      data_type: 'number',
    },
  }
  // Default Chip
  chipJSX = (
    <Chip
      sx={{ maxWidth: '190px' }}
      className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
      label={rLIB('Not Set')}
      variant="filled"
      color="error"
      onClick={() => {
        if (tableAdditionalData.editable === true) {
          if (version === 'v2') {
            openV2PropertyEditForm(task, formInputs_TaskUnits, tableHooks)
          } else if (version === 'v1') {
            openV1PropertyEditForm(task, formInputs_TaskUnits, tableAdditionalData, tableHooks)
          }
        }
      }}
    />
  )
  //
  if (
    task != null &&
    task.key != null &&
    taskUnitMapping != null &&
    taskUnitMapping[task.key] != null &&
    taskUnitMapping[task.key]['task_unit_evaluation'] === 'custom'
  ) {
    chipJSX = (
      <Box className="tw-inline-block">
        <Chip
          sx={{ maxWidth: '190px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          color="warning"
          label={rLIB('Case by Case Basis')}
          variant="outlined"
          onClick={() => {
            if (tableAdditionalData.editable === true) {
              if (version === 'v2') {
                openV2PropertyEditForm(task, formInputs_TaskUnits, tableHooks)
              } else if (version === 'v1') {
                openV1PropertyEditForm(task, formInputs_TaskUnits, tableAdditionalData, tableHooks)
              }
            }
          }}
        />
      </Box>
    )
  } else if (
    task != null &&
    task.key != null &&
    taskUnitMapping != null &&
    taskUnitMapping[task.key] != null &&
    taskUnitMapping[task.key]['task_unit_evaluation'] === 'panels'
  ) {
    chipJSX = (
      <Box className="tw-inline-block">
        <Chip
          sx={{ maxWidth: '190px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          color="warning"
          label={rLIB('Number of Panels')}
          variant="outlined"
          onClick={() => {
            if (tableAdditionalData.editable === true) {
              if (version === 'v2') {
                openV2PropertyEditForm(task, formInputs_TaskUnits, tableHooks)
              } else if (version === 'v1') {
                openV1PropertyEditForm(task, formInputs_TaskUnits, tableAdditionalData, tableHooks)
              }
            }
          }}
        />
      </Box>
    )
  } else if (
    task != null &&
    task.key != null &&
    taskUnitMapping != null &&
    taskUnitMapping[task.key] != null &&
    taskUnitMapping[task.key]['task_unit_evaluation'] === 'hardcode'
  ) {
    if (task != null && task.key != null && taskUnitMapping != null && taskUnitMapping[task.key] != null && taskUnitMapping[task.key]['task_units'] != null) {
      chipJSX = (
        <Box className="tw-inline-block">
          <Chip
            sx={{ maxWidth: '190px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={taskUnitMapping[task.key]['task_units']}
            variant="filled"
            onClick={() => {
              if (tableAdditionalData.editable === true) {
                if (version === 'v2') {
                  openV2PropertyEditForm(task, formInputs_TaskUnits, tableHooks)
                } else if (version === 'v1') {
                  openV1PropertyEditForm(task, formInputs_TaskUnits, tableAdditionalData, tableHooks)
                }
              }
            }}
          />
        </Box>
      )
    } else {
      // Use Default Chip
    }
  } else {
    if (task != null && task.key != null && taskUnitMapping != null && taskUnitMapping[task.key] != null && taskUnitMapping[task.key]['task_units'] != null) {
      chipJSX = (
        <Box className="tw-inline-block">
          <Chip
            sx={{ maxWidth: '190px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            color="warning"
            label={taskUnitMapping[task.key]['task_units']}
            variant="filled"
            onClick={() => {
              if (tableAdditionalData.editable === true) {
                if (version === 'v2') {
                  openV2PropertyEditForm(task, formInputs_TaskUnits, tableHooks)
                } else if (version === 'v1') {
                  openV1PropertyEditForm(task, formInputs_TaskUnits, tableAdditionalData, tableHooks)
                }
              }
            }}
          />
        </Box>
      )
    } else {
      // Use Default Chip
    }
  }
  // Unit Type Mismatch
  let mismatchIconJSX = <></>
  if (
    task != null &&
    task.key != null &&
    taskUnitMapping != null &&
    taskUnitMapping[task.key] != null &&
    taskUnitMapping[task.key]['unit_work_type'] != null &&
    taskUnitMapping[task.key]['task_unit_evaluation'] != null
  ) {
    let expectedUnitWorkType = getProp(unitWorkTypes, taskUnitMapping[task.key]['unit_work_type'], {})['unit_type']
    if (
      expectedUnitWorkType != null &&
      expectedUnitWorkType === 'panels' &&
      (taskUnitMapping[task.key]['task_unit_evaluation'] === 'custom' || taskUnitMapping[task.key]['task_unit_evaluation'] === 'hardcode')
    ) {
      mismatchIconJSX = (
        <Icon
          icon="exclamation-triangle"
          className="tw-ml-2 tw-text-warning_main"
          sx={{ verticalAlign: 'top', fontSize: '18px' }}
          tooltip={rLIB('Unit Type Mismatch')}
          tooltipPlacement="right"
        />
      )
    }
    if (expectedUnitWorkType != null && expectedUnitWorkType === 'units' && taskUnitMapping[task.key]['task_unit_evaluation'] === 'panels') {
      mismatchIconJSX = (
        <Icon
          icon="exclamation-triangle"
          className="tw-ml-2 tw-text-warning_main"
          sx={{ verticalAlign: 'top', fontSize: '18px' }}
          tooltip={rLIB('Unit Type Mismatch')}
          tooltipPlacement="right"
        />
      )
    }
  }
  // unit_type

  let containerJSX = (
    <Box>
      {chipJSX}
      {mismatchIconJSX}
    </Box>
  )
  return containerJSX
}

const rJSX_TaskInvoiceRateChip = (
  version: 'v1' | 'v2',
  task: TsInterface_UnspecifiedObject,
  tableAdditionalData: TsInterface_UnspecifiedObject,
  propKeyModifier: string,
  tableHooks: TsInterface_TableHooks,
): JSX.Element => {
  let chipJSX = <></>
  let formInputs_TaskRate: TsInterface_FormInputs = {}
  formInputs_TaskRate[propKeyModifier + '_task_rate'] = {
    key: propKeyModifier + '_task_rate',
    label: rLIB('Default Invoice Rate'),
    input_type: 'text_number',
    required: false,
    data_type: 'number',
  }
  if (task[propKeyModifier + '_task_rate'] != null) {
    chipJSX = (
      <Box className="tw-inline-block">
        <Chip
          sx={{ maxWidth: '190px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={formatCurrency(task[propKeyModifier + '_task_rate'], 'USD', 'en-US')}
          variant="filled"
          onClick={() => {
            if (tableAdditionalData.editable === true) {
              if (version === 'v2') {
                openV2DirectPropertyEditForm(task, formInputs_TaskRate, tableHooks)
              } else if (version === 'v1') {
                openV1PropertyEditForm(task, formInputs_TaskRate, tableAdditionalData, tableHooks)
              }
            }
          }}
        />
      </Box>
    )
  } else {
    chipJSX = (
      <Chip
        sx={{ maxWidth: '190px' }}
        className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
        label={rLIB('Not Set')}
        variant="filled"
        color="error"
        onClick={() => {
          if (tableAdditionalData.editable === true) {
            if (version === 'v2') {
              openV2DirectPropertyEditForm(task, formInputs_TaskRate, tableHooks)
            } else if (version === 'v1') {
              openV1PropertyEditForm(task, formInputs_TaskRate, tableAdditionalData, tableHooks)
            }
          }
        }}
      />
    )
  }
  return chipJSX
}

const openV2DirectPropertyEditForm = (task: TsInterface_UnspecifiedObject, formInputs: TsInterface_FormInputs, tableHooks: TsInterface_TableHooks): void => {
  tableHooks.uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: task,
        formInputs: formInputs,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, task.key), formSubmittedData)
                  .then((res_DSMD) => {
                    resolve({ success: true })
                    // setLastEditedTaskKey( task.key )
                  })
                  .catch((rej_DSMD) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                  })
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: (
          <>
            {rLIB('Edit Task Financials')} - {task.name}
          </>
        ),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

const openV2PropertyEditForm = (task: TsInterface_UnspecifiedObject, formInputs: TsInterface_FormInputs, tableHooks: TsInterface_TableHooks): void => {
  tableHooks.uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: task,
        formInputs: formInputs,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            formSubmittedData['workflow_version'] = 2
            let updateObject: TsInterface_UnspecifiedObject = {
              [task.key]: formSubmittedData,
            }
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_PayrollRates_TaskUnitMapping_Document(res_GCK.clientKey), updateObject)
                  .then((res_DSMD) => {
                    resolve({ success: true })
                    // setLastEditedTaskKey( task.key )
                  })
                  .catch((rej_DSMD) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                  })
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: (
          <>
            {rLIB('Edit Task Financials')} - {task.name}
          </>
        ),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

// TODO: - hunt down where this is called
const openV1PropertyEditForm = (
  task: TsInterface_UnspecifiedObject,
  formInputs: TsInterface_FormInputs,
  tableAdditionalData: TsInterface_UnspecifiedObject,
  tableHooks: TsInterface_TableHooks,
): void => {
  let taskData: TsInterface_UnspecifiedObject = {}
  if (
    tableAdditionalData.us_payrollRates != null &&
    tableAdditionalData.us_payrollRates['task_unit_mapping'] != null &&
    tableAdditionalData.us_payrollRates['task_unit_mapping'][task.key as string] != null
  ) {
    taskData = tableAdditionalData.us_payrollRates['task_unit_mapping'][task.key as string]
  }
  // Open Form Dialog
  tableHooks.uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: taskData,
        formInputs: formInputs,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                formSubmittedData['workflow_version'] = 1
                let updateObject: TsInterface_UnspecifiedObject = {
                  [task.key]: formSubmittedData,
                }
                DatabaseSetMergeDocument(DatabaseRef_PayrollRates_TaskUnitMapping_Document(res_GCK.clientKey), updateObject)
                  .then((res_DSMD) => {
                    resolve({ success: true })
                  })
                  .catch((rej_DSMD) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                  })
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: (
          <>
            {rLIB('Edit Task Financials')} - {task.name}
          </>
        ),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

///////////////////////////////
// Exports
///////////////////////////////

export const tableSettings_V2TasksPayrollMapping: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 350px)',
}

export const tableSettings_V1TasksPayrollMapping: TsInterface_TableSettings = {
  paginated: false,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: false,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 300px)',
}

export const tableColumns_V1TasksPayrollMapping: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Task Name'), null),
  // key: TableCellBasic( "key", <></>, null ),
  unit_work_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Unit Work Type')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            {rJSX_UnitWorkTypeMappingChip(
              'v1',
              rowData,
              tableAdditionalData,
              getProp(getProp(tableAdditionalData, 'us_payrollRates', {}), 'task_unit_mapping', {}),
              tableHooks,
            )}
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  task_units: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Units')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            {rJSX_TaskUnitsMappingChip(
              'v1',
              rowData,
              tableAdditionalData,
              getProp(getProp(tableAdditionalData, 'us_payrollRates', {}), 'task_unit_mapping', {}),
              false,
              tableHooks,
            )}
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

export const tableColumns_V2TasksPayrollMapping: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Task Name'), null),
  unit_work_type: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Unit Work Type')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            {rJSX_UnitWorkTypeMappingChip(
              'v2',
              rowData,
              tableAdditionalData,
              getProp(getProp(tableAdditionalData, 'us_payrollRates', {}), 'task_unit_mapping', {}),
              tableHooks,
            )}
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  task_units: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Units')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            {rJSX_TaskUnitsMappingChip(
              'v2',
              rowData,
              tableAdditionalData,
              getProp(getProp(tableAdditionalData, 'us_payrollRates', {}), 'task_unit_mapping', {}),
              false,
              tableHooks,
            )}
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  DEFAULT_bill_to_sales_partner: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Bill to Sales Partner')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-background_json'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            <Switch
              color="info"
              checked={(rowData.DEFAULT_bill_to_sales_partner as boolean) || false}
              onChange={(event, value) => {
                if (rowData.key != null && value === true) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    DEFAULT_bill_to_sales_partner: true,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                } else if (rowData.key != null && value === false) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    DEFAULT_bill_to_sales_partner: false,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-bg-background_json'
        return cellCSS
      },
    },
  },
  DEFAULT_bill_to_customer: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Bill to Customer')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-background_json'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <Box>
            <Switch
              color="info"
              checked={(rowData.DEFAULT_bill_to_customer as boolean) || false}
              onChange={(event, value) => {
                if (rowData.key != null && value === true) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    DEFAULT_bill_to_customer: true,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                } else if (rowData.key != null && value === false) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    DEFAULT_bill_to_customer: false,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_TaskBlueprint_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DSMD) => {
                          // Nothing
                        })
                        .catch((rej_DSMD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-bg-background_json'
        return cellCSS
      },
    },
  },
  task_rate: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Default Invoice Rate')
      },
      header_sort_by: null,
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-bg-background_json'
      },
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <>{rJSX_TaskInvoiceRateChip('v2', rowData, tableAdditionalData, 'DEFAULT', tableHooks)}</>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = 'tw-bg-background_json'
        return cellCSS
      },
    },
  },
}

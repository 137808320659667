///////////////////////////////
// Imports
///////////////////////////////

import { collection, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_TimeSheets_Document = (clientKey: string, timesheetKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'timesheets', 'time_sheets', 'main', timesheetKey)
}

export const DatabaseRef_TimeSheets_SpecificDate_Query = (clientKey: string, dateKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_date_key', comparator: '==', value: dateKey }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'time_sheets', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ProjectsByIdNumber_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { DatabaseGetCollection } from 'rfbp_core/services/database_management'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminActiveProjectUrlSearchPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_MULTIPLE_PROJECTS_FOR_SEARCH_QUERY: JSX.Element = <Trans>Multiple projects for search query</Trans>
const s_NO_PROJECTS_FOR_SEARCH_QUERY: JSX.Element = <Trans>No projects for search query</Trans>
const se_SEARCH: string = 'Search'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_projects, us_setProjects] = useState<TsInterface_UnspecifiedObject>({})
  const [us_searchComplete, us_setSearchComplete] = useState<boolean>(false)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_SEARCH + ': ' + pr_itemKey
  }, [pr_itemKey])

  useEffect(() => {
    if (us_projects != null && objectToArray(us_projects).length === 1 && objectToArray(us_projects)[0].key != null) {
      directAppNavigation(un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(objectToArray(us_projects)[0].key))
    }
  }, [us_projects, un_routerNavigation])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_ProjectsByIdNumber_Query(res_GCK.clientKey, pr_itemKey))
          .then((res_DGC) => {
            us_setProjects(res_DGC.data)
            us_setSearchComplete(true)
            ur_forceRerender()
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_itemKey, ur_forceRerender])

  // Functions

  // JSX Generation
  const rJSX_PageContent = (): JSX.Element => {
    let pageContentJSX = <></>
    if (us_searchComplete === false) {
      pageContentJSX = <Box className="tw-text-center">{/* <CircularProgress variant="indeterminate" color="primary" className="tw-m-auto" /> */}</Box>
    } else if (objectToArray(us_projects).length === 0) {
      pageContentJSX = (
        <Box className="tw-text-center">
          <Typography variant="h4">{s_NO_PROJECTS_FOR_SEARCH_QUERY}</Typography>
          <Typography
            variant="h5"
            className="tw-opacity-40"
          >
            {pr_itemKey}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            className="tw-mt-4"
            startIcon={<Icon icon="chevron-left"></Icon>}
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectsListPage.url())
            }}
          >
            {rLIB('Back to all active projects')}
          </Button>
        </Box>
      )
    } else if (objectToArray(us_projects).length === 1) {
      pageContentJSX = <Box className="tw-text-center">{/* <CircularProgress variant="indeterminate" color="primary" className="tw-m-auto" /> */}</Box>
    } else if (objectToArray(us_projects).length > 1) {
      pageContentJSX = (
        <Box className="tw-text-center">
          <Typography variant="h4">{s_MULTIPLE_PROJECTS_FOR_SEARCH_QUERY}</Typography>
          <Typography
            variant="h5"
            className="tw-opacity-40"
          >
            {pr_itemKey}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            className="tw-mt-4"
            startIcon={<Icon icon="chevron-left"></Icon>}
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectsListPage.url())
            }}
          >
            {rLIB('Back to all active projects')}
          </Button>
        </Box>
      )
    }
    return pageContentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={<></>}
        pageKey={pageKey}
        content={<Box>{rJSX_PageContent()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

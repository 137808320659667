//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:
			[ ] Typescript - 1 instance of any

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsType_InputChangeCallback,
} from 'rfbp_core/components/form'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  formAdditionalData: TsInterface_FormAdditionalData
  formData: TsInterface_FormData
  formHooks: TsInterface_FormHooksObject
  formInput: TsInterface_FormInput
  formInputs: TsInterface_FormInputs
  formSettings: TsInterface_FormSettings
  inputChangeCallback: TsType_InputChangeCallback
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const CustomFormInputJSX = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_formAdditionalData: TsInterface_FormAdditionalData = getProp(props, 'formAdditionalData', {})
  let pr_formData: TsInterface_FormData = getProp(props, 'formData', {})
  let pr_formInput: TsInterface_FormInput = getProp(props, 'formInput', {})
  let pr_formInputs: TsInterface_FormInputs = getProp(props, 'formInputs', {})
  let pr_formSettings: TsInterface_FormSettings = getProp(props, 'formSettings', {})
  let pr_formHooks: TsInterface_FormHooksObject = getProp(props, 'formHooks', {})
  let pr_inputChangeCallback: TsType_InputChangeCallback = props.inputChangeCallback

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions

  // Generate JSX

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    // Custom Input
    let formInputJSX = <></>
    if (pr_formInput != null && pr_formInput.renderCustomFormInput != null) {
      formInputJSX = pr_formInput.renderCustomFormInput(
        pr_formInput,
        pr_formInputs,
        pr_formData,
        pr_inputChangeCallback,
        pr_formSettings,
        pr_formAdditionalData,
        pr_formHooks,
      )
    }
    // Form Input JSX
    let componentJSX = <Box>{formInputJSX}</Box>
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

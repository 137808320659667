///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_InboundDataProcedure_Document = (procedureKey: string) => {
  return doc(getFirestore(), 'global', 'inbound_data_procedures', 'main', procedureKey)
}

export const DatabaseRef_InboundDataProcedure_Collection = (): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'global', 'inbound_data_procedures', 'main')
}

export const DatabaseRef_Client_InboundDataProcedure_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'client_key', comparator: '==', value: clientKey }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'global', 'inbound_data_procedures', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_Client_InboundDataProcedure_Email_Query = (emailAddress: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'email_address', comparator: '==', value: emailAddress }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'global', 'inbound_data_procedures', 'main'), queryOperatorsArray, orderByArray, {}, null)
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, ButtonGroup, capitalize, Card, Chip, Divider, FormControl, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material/'
import pluralize from 'pluralize'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Regions_Collection } from 'rfbp_aux/services/database_endpoints/directory/regions'
import {
  DatabaseRef_MasterSkuItem_ManufacturerIDAndSupplier_Query,
  DatabaseRef_MasterSKU_Document,
} from 'rfbp_aux/services/database_endpoints/materials/master_sku_items'
import { DatabaseRef_Order_Document } from 'rfbp_aux/services/database_endpoints/materials/orders'
import { DatabaseRef_SkuLineItemsOnOrder_Query, DatabaseRef_SkuLineItem_Document } from 'rfbp_aux/services/database_endpoints/materials/sku_line_items'
import { DatabaseRef_Supplier_Document } from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import { DatabaseRef_Project_Document } from 'rfbp_aux/services/database_endpoints/operations/projects'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { searchIndex } from 'rfbp_core/components/search'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseDeleteDocument,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
} from 'rfbp_core/services/database_management'
import { dynamicSort, formatCurrency, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { ProjectFilesView } from '../projects/components/files'
import { ProjectImagesView } from '../projects/components/images'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseMaterialsOrderViewPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_associatedProject, us_setAssociatedProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_associatedSupplier, us_setAssociatedSupplier] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lineItemSkus, us_setLineItemSkus] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadingGoogleSpreadsheet, us_setLoadingGoogleSpreadsheet] = useState<boolean>(false)
  const [us_regionsList, us_setRegionsList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootOrder, us_setRootOrder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_searchIndexKey, us_setSearchIndexKey] = useState<'materials_by_sku' | 'materials'>('materials')
  const [us_searchResults, us_setSearchResults] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_searchValue, us_setSearchValue] = useState<string>('')
  const [us_supplierLocationOptions, us_setSupplierLocationOptions] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Order', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegionsList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_Regions_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAssociatedProject(newData)
      ur_forceRerender()
    }
    if (us_rootOrder != null && us_rootOrder['associated_project_key'] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, us_rootOrder['associated_project_key']), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootOrder])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAssociatedSupplier(newData)
      let supplierLocationOptions: TsInterface_UnspecifiedObject = {}
      for (let loopSupplierKey in newData['locations']) {
        let loopSupplierLocation = newData['locations'][loopSupplierKey]
        if (loopSupplierLocation != null && loopSupplierLocation['status'] === 'active') {
          supplierLocationOptions[loopSupplierKey] = { key: loopSupplierKey, value: loopSupplierLocation['name'] }
        }
      }
      us_setSupplierLocationOptions(supplierLocationOptions)
      ur_forceRerender()
    }
    if (us_rootOrder != null && us_rootOrder['associated_supplier_key'] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_Supplier_Document(res_GCK.clientKey, us_rootOrder['associated_supplier_key']),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_rootOrder])

  useEffect(() => {
    // Get Search Results from Meilisearch
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let clientKey = res_GCK.clientKey
        searchIndex(
          clientKey,
          us_searchIndexKey,
          us_searchValue,
          ['associated_supplier_key = ' + getProp(us_rootOrder, 'associated_supplier_key', null), 'status = active'],
          searchLimit,
        )
          .then((res_SI) => {
            let searchResults = getProp(res_SI, 'hits', [])
            // Sort Search Results Alphabetically
            if (us_searchIndexKey === 'materials') {
              searchResults.sort(dynamicSort('name', 'asc'))
            }
            // Split into two arrays - Items in Cart and Items Not in Cart
            let itemsInCart: TsInterface_UnspecifiedObject[] = []
            let itemsNotInCart: TsInterface_UnspecifiedObject[] = []
            for (let loopSkuIndex in searchResults) {
              let loopSkuItem = searchResults[loopSkuIndex]
              if (
                itemKey != null &&
                loopSkuItem != null &&
                loopSkuItem['key'] != null &&
                us_lineItemSkus != null &&
                us_lineItemSkus[itemKey + '_' + loopSkuItem['key']] != null &&
                us_lineItemSkus[itemKey + '_' + loopSkuItem['key']]['associated_sku_pick_requested_quantity'] != null &&
                us_lineItemSkus[itemKey + '_' + loopSkuItem['key']]['associated_sku_pick_requested_quantity'] > 0
              ) {
                itemsInCart.push(loopSkuItem)
              } else {
                itemsNotInCart.push(loopSkuItem)
              }
            }
            // Join the two arrays
            let sortedSearchResults = itemsInCart.concat(itemsNotInCart)
            us_setSearchResults(sortedSearchResults)
          })
          .catch((rej_SI) => {
            us_setSearchResults([])
            console.error(rej_SI)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, us_lineItemSkus, us_rootOrder, us_searchValue, us_searchIndexKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootOrder(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLineItemSkus(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SkuLineItemsOnOrder_Query(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_setLineItemSkus])

  // Other Variables
  const searchLimit = 50

  // Functions
  const decrementSkuItemQuantity = (skuItem: TsInterface_UnspecifiedObject, dropdownValue: number | string) => {
    if (!isNaN(parseFloat(dropdownValue as string))) {
      if (parseFloat(dropdownValue as string) > 1) {
        let updateObject = {
          associated_sku_pick_requested_quantity: parseFloat(dropdownValue as string) - 1,
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, skuItem.key), updateObject)
              .then((res_DSMD) => {
                // Done
              })
              .catch((rej_DSMD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      } else {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Remove from Shopping Cart'),
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: rLIB('Delete'),
            text: rLIB('Are you sure that you want to remove this item from your shopping cart?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseDeleteDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, skuItem.key))
                      .then((res_DDD) => {
                        resolve(res_DDD)
                      })
                      .catch((rej_DDD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              })
            },
          },
        })
      }
    }
  }

  const incrementSkuItemQuantity = (skuItem: TsInterface_UnspecifiedObject, dropdownValue: number | string) => {
    // let maxPerCart = getProp( skuItem, "associated_sku_pick_max_per_cart", 100 )
    let maxPerCart = 100
    if (!isNaN(parseFloat(dropdownValue as string)) && parseFloat(dropdownValue as string) < maxPerCart) {
      let updateObject = {
        associated_sku_pick_requested_quantity: parseFloat(dropdownValue as string) + 1,
      }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, skuItem.key), updateObject)
            .then((res_DSMD) => {
              // Done
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  const updateOrderItem = (skuItem: TsInterface_UnspecifiedObject, quantity: number, showError: boolean) => {
    return new Promise((resolve, reject) => {
      if (us_rootOrder != null && us_rootOrder['key'] != null && skuItem != null && skuItem['key'] != null && skuItem['name'] != null) {
        let lineItemSkuKey = us_rootOrder['key'] + '_' + skuItem['key']
        let updateObject = {
          associated_order_key: us_rootOrder['key'],
          associated_order_po_number: getProp(us_rootOrder, 'po_number', null),
          associated_project_id_number: getProp(us_rootOrder, 'associated_project_id_number', null),
          associated_project_key: getProp(us_rootOrder, 'associated_project_key', null),
          associated_sku_category_name: getProp(skuItem, 'associated_category_name', null),
          associated_sku_classification_name: getProp(skuItem, 'associated_classification_name', null),
          associated_sku_manufacturer_unique_identifier: getProp(skuItem, 'associated_manufacturer_unique_identifier', null),
          associated_sku_estimated_photo_url: getProp(skuItem, 'photo_url', null),
          associated_sku_key: skuItem['key'],
          associated_sku_name: skuItem['name'],
          associated_sku_pick_base_unit_of_measurement: getProp(skuItem, 'pick_base_unit_of_measurement', null),
          associated_sku_pick_estimated_price_per_package: getProp(skuItem, 'pick_estimated_price_per_package', null),
          associated_sku_pick_max_per_cart: getProp(skuItem, 'pick_max_per_cart', null),
          associated_sku_pick_packaging_type: getProp(skuItem, 'pick_packaging_type', null),
          associated_sku_pick_requested_quantity: quantity,
          associated_sku_pick_unit_quantity_per_package: getProp(skuItem, 'pick_unit_quantity_per_package', null),
          associated_sku_unique_identifier: getProp(skuItem, 'unique_identifier', null),
          associated_supplier_key: getProp(us_rootOrder, 'associated_supplier_key', null),
          associated_supplier_name: getProp(us_rootOrder, 'associated_supplier_name', null),
          associated_task_key: getProp(us_rootOrder, 'associated_task_key', null),
          associated_task_name: getProp(us_rootOrder, 'associated_task_name', null),
          associated_team_key: getProp(us_rootOrder, 'associated_team_key', null),
          associated_team_member_keys: getProp(us_rootOrder, 'associated_team_member_keys', null),
          associated_team_member_names: getProp(us_rootOrder, 'associated_team_member_names', null),
          associated_team_name: getProp(us_rootOrder, 'associated_team_name', null),
          associated_user_key: getProp(us_rootOrder, 'associated_user_key', null),
          associated_user_name: getProp(us_rootOrder, 'associated_user_name', null),
          key: lineItemSkuKey,
          timestamp_scheduled_pickup_date_key: getProp(us_rootOrder, 'timestamp_scheduled_pickup_date_key', null),
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseSetMergeDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, lineItemSkuKey), updateObject)
              .then((res_DSMD) => {
                resolve(res_DSMD)
              })
              .catch((rej_DSMD) => {
                if (showError === true) {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                }
                reject(rej_DSMD)
              })
          })
          .catch((rej_GCK) => {
            if (showError === true) {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            }
            reject(rej_GCK)
          })
      } else {
        let error = {
          message: rLIB('Failed to update Order Item'),
          details: rLIB('Missing Required Data'),
          code: 'ER-D-OV-UOI-01',
        }
        if (showError === true) {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
        }
        reject({ success: false, error: error })
      }
    })
  }

  const returnEstimatedInvoiceTotal = (): number => {
    let total: number = 0
    for (let loopSkuKey in us_lineItemSkus) {
      let loopSku = us_lineItemSkus[loopSkuKey]
      if (loopSku != null && loopSku['associated_sku_pick_requested_quantity'] != null && loopSku['associated_sku_pick_estimated_price_per_package'] != null) {
        let quantityPerPackageMultiplier = getProp(loopSku, 'associated_sku_pick_unit_quantity_per_package', 1)
        total += loopSku['associated_sku_pick_requested_quantity'] * loopSku['associated_sku_pick_estimated_price_per_package'] * quantityPerPackageMultiplier
      }
    }
    return total
  }

  const MESSY_attemptToLoadDataFromGoogleSpreadsheet = () => {
    us_setLoadingGoogleSpreadsheet(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        cloudFunctionManageRequest('manageData', {
          function: 'return_google_sheets_scraper',
          client_key: res_GCK.clientKey,
          google_sheet_id: '1CjWOfZHuP_rhQYeJ3DcfHTPTbqEqaKIJ3NBJgh8RYfI', // TODO: Not hardcoded
          google_sheet_range: 'Sheet1',
        })
          .then((res_CFMR) => {
            let skusFromSpreadsheet: TsInterface_UnspecifiedObject[] = []
            let spreadsheetData = getProp(getProp(res_CFMR, 'data', {}), 'values', [])
            // Loop through data rows
            for (let loopRowIndex in spreadsheetData) {
              let loopRow = spreadsheetData[loopRowIndex]
              if (loopRow.length > 0 && loopRow[2] != null && loopRow[7] != null && loopRow[8] != null) {
                let jobCode = loopRow[2]
                let skuQuantity = loopRow[7]
                let skuName = loopRow[8]
                if (jobCode != null && jobCode != '' && us_rootOrder != null && us_rootOrder['associated_project_id_number'] === jobCode) {
                  skusFromSpreadsheet.push({
                    job_code: jobCode,
                    sku_quantity: skuQuantity,
                    sku_name: skuName,
                  })
                }
              }
            }
            // Loop through skus and attempt to add to order
            let totalSkusToAdd = skusFromSpreadsheet.length
            let promiseArray1: Promise<unknown>[] = []
            let promiseArray2: Promise<unknown>[] = []
            for (let loopSkuIndex in skusFromSpreadsheet) {
              let loopSku = skusFromSpreadsheet[loopSkuIndex]
              promiseArray1.push(
                DatabaseGetDocument(DatabaseRef_MasterSKU_Document(res_GCK.clientKey, us_associatedSupplier.key + '_' + loopSku.sku_name))
                  .then((res_DGD) => {
                    skusFromSpreadsheet[loopSkuIndex]['associated_sku_item'] = res_DGD.data
                  })
                  .catch((rej_DGD) => {
                    console.error(rej_DGD)
                  }),
              )
              promiseArray1.push(
                DatabaseGetCollection(DatabaseRef_MasterSkuItem_ManufacturerIDAndSupplier_Query(res_GCK.clientKey, loopSku.sku_name, us_associatedSupplier.key))
                  .then((res_DGC) => {
                    skusFromSpreadsheet[loopSkuIndex]['associated_manufacturer_sku_items'] = res_DGC.data
                  })
                  .catch((rej_DGC) => {
                    console.error(rej_DGC)
                  }),
              )
            }
            Promise.all(promiseArray1).finally(() => {
              // Loop through and get totals and show confirm dialog
              let totalSkusAdded = 0
              let totalSkusSkipped = 0
              let totalSkusFailed = 0
              for (let loopSkuIndex in skusFromSpreadsheet) {
                let loopSku = skusFromSpreadsheet[loopSkuIndex]
                if (loopSku.associated_sku_item != null && loopSku.associated_sku_item.key != null) {
                  promiseArray2.push(
                    updateOrderItem(loopSku.associated_sku_item, loopSku.sku_quantity, false)
                      .then(() => {
                        totalSkusAdded++
                      })
                      .catch(() => {
                        totalSkusFailed++
                      }),
                  )
                } else if (
                  loopSku.associated_manufacturer_sku_items != null &&
                  objectToArray(loopSku.associated_manufacturer_sku_items).length > 0 &&
                  objectToArray(loopSku.associated_manufacturer_sku_items)[0].key != null
                ) {
                  promiseArray2.push(
                    updateOrderItem(objectToArray(loopSku.associated_manufacturer_sku_items)[0], loopSku.sku_quantity, false)
                      .then(() => {
                        totalSkusAdded++
                      })
                      .catch(() => {
                        totalSkusFailed++
                      }),
                  )
                } else {
                  totalSkusSkipped++
                }
              }
              Promise.all(promiseArray2).finally(() => {
                let textJSX = (
                  <Box>
                    <Box>{us_rootOrder['associated_project_id_number']}</Box>
                    <Box>
                      {totalSkusToAdd > 0 ? (
                        <>
                          {totalSkusToAdd} {rLIB('skus found in google spreadsheet')}
                        </>
                      ) : (
                        <>{rLIB('No skus found in google spreadsheet')}</>
                      )}
                    </Box>
                    <Box>
                      {totalSkusAdded > 0 ? (
                        <>
                          {totalSkusAdded} {rLIB('skus added')}
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Box>
                      {totalSkusFailed > 0 ? (
                        <>
                          {totalSkusSkipped} {rLIB('skus failed to be added')}
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                    <Box>
                      {totalSkusSkipped > 0 ? (
                        <>
                          {totalSkusSkipped} {rLIB('skus from spreadsheet not found in database')}
                        </>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                )
                uc_setUserInterface_AlertDialogDisplay({
                  display: true,
                  alert: {
                    color: 'info',
                    header: rLIB('Skus Added from Spreadsheet') as JSX.Element,
                    icon: <Icon icon="wand-magic-sparkles" />,
                    text: textJSX,
                  },
                })
              })

              // Finish with Data Loading
              us_setLoadingGoogleSpreadsheet(false)
            })

            /*
            Found X Skus on spreadsheet
            Found Y Database Matches (Z Not Found)
            Add to Order?
            */
          })
          .catch((rej_CFMR) => {
            console.error(rej_CFMR)
            us_setLoadingGoogleSpreadsheet(false)
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        us_setLoadingGoogleSpreadsheet(false)
      })
  }

  // JSX Generation
  const rJSX_BackButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="inherit"
          variant="outlined"
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsIndexPage.url() + '?tab=Orders')
          }}
          disableElevation
          startIcon={<Icon icon="chevron-left" />}
          className="tw-mr-2"
        >
          {rLIB('Back to all Orders')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Back to all Orders')}
          placement="top"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsIndexPage.url() + '?tab=Orders')
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="chevron-left" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_LoadFromGoogleSpreadsheetButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="success"
          variant="contained"
          onClick={(event) => {
            MESSY_attemptToLoadDataFromGoogleSpreadsheet()
          }}
          disableElevation
          startIcon={
            us_loadingGoogleSpreadsheet ? (
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            ) : (
              <Icon icon="file-spreadsheet" />
            )
          }
          className="tw-mr-2"
          disabled={us_loadingGoogleSpreadsheet || us_rootOrder.status !== 'new'}
        >
          {rLIB('Load from google spreadsheet')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Load from google spreadsheet')}
          placement="top"
        >
          <Button
            color="success"
            variant="contained"
            onClick={(event) => {
              MESSY_attemptToLoadDataFromGoogleSpreadsheet()
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
            disabled={us_loadingGoogleSpreadsheet || us_rootOrder.status !== 'new'}
          >
            {us_loadingGoogleSpreadsheet ? (
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            ) : (
              <Icon icon="file-spreadsheet" />
            )}
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  // Search Tab
  const rJSX_SearchResultsCount = (): JSX.Element => {
    let countJSX = <></>
    if (us_searchValue !== '' && us_searchResults != null && us_searchResults.length >= searchLimit) {
      countJSX = (
        <Typography
          variant="body1"
          className="tw-opacity-40 tw-mb-2"
        >
          {rLIB('More than')} {us_searchResults.length} {rLIB('Search')} {pluralize('Result', us_searchResults.length)}
        </Typography>
      )
    } else if (us_searchValue !== '' && us_searchResults != null && us_searchResults.length > 0) {
      countJSX = (
        <Typography
          variant="body1"
          className="tw-opacity-40 tw-mb-2"
        >
          {us_searchResults.length} {rLIB('Search')} {pluralize('Result', us_searchResults.length)}
        </Typography>
      )
    }
    return countJSX
  }

  const rJSX_SearchBar = (): JSX.Element => {
    let searchBarJSX = (
      <Stack
        direction={'row'}
        spacing={1}
      >
        <Box sx={{ width: 'calc(100% - 0px)' }}>
          <FormControl fullWidth>
            <TextField
              color="primary"
              value={us_searchValue}
              margin="normal"
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  us_setSearchValue(event.target.value)
                  // updateSearchResults( event.target.value )
                }
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: (
                  <Icon
                    icon="magnifying-glass"
                    className="tw-mr-2"
                  />
                ),
              }}
            />
          </FormControl>
          {rJSX_SearchResultsCount()}
        </Box>
        <Box className="tw-pt-4">
          <ButtonGroup variant="outlined">
            <Tooltip
              title={rLIB('Search by anything')}
              placement="top"
            >
              <Button
                sx={{ height: '56px' }}
                className="tw-align-top tw-text-center"
                color="secondary"
                variant={us_searchIndexKey === 'materials' ? 'contained' : 'outlined'}
                onClick={() => {
                  us_setSearchIndexKey('materials')
                }}
              >
                <Icon
                  icon="magnifying-glass"
                  sx={{ fontSize: '18px' }}
                />
              </Button>
            </Tooltip>
            <Tooltip
              title={rLIB('Search by SKU')}
              placement="top"
            >
              <Button
                sx={{ height: '56px' }}
                className="tw-align-top tw-text-center"
                color="secondary"
                variant={us_searchIndexKey === 'materials_by_sku' ? 'contained' : 'outlined'}
                onClick={() => {
                  us_setSearchIndexKey('materials_by_sku')
                }}
              >
                <Icon
                  icon="barcode"
                  sx={{ fontSize: '18px' }}
                />
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Box>
      </Stack>
    )
    return searchBarJSX
  }

  const rJSX_MaterialSearchResultSkuItemImage = (photoUrl: string | null): JSX.Element => {
    let imageJSX = <></>
    if (photoUrl != null) {
      imageJSX = (
        <Box
          sx={{ width: '80px', height: '80px', background: '#FFF' }}
          className="tw-mr-2 tw-mt-2 tw-flex tw-items-center tw-justify-center"
        >
          <img
            className="tw-max-w-full tw-max-h-full tw-self-center"
            src={photoUrl}
            alt={photoUrl}
            loading="lazy"
            width={'80px'}
          />
        </Box>
      )
    } else {
      imageJSX = (
        <Box
          sx={{ width: '80px', height: '80px', background: themeVariables.gray_800 }}
          className="tw-mr-2 tw-mt-2 tw-text-center"
        >
          <Box className="tw-pt-3">
            <Icon
              className="tw-opacity-20"
              sx={{ fontSize: '40px' }}
              icon="circle-question"
            />
          </Box>
          <Typography
            variant="caption"
            className="tw-opacity-20"
          >
            {rLIB('No Image')}
          </Typography>
        </Box>
      )
    }
    return imageJSX
  }

  const rJSX_MaterialSearchResultOrderQuantityDropdown = (skuItem: TsInterface_UnspecifiedObject, includedInOrder: boolean) => {
    let dropdownJSX = <></>
    // Determine Order Quantity Dropdown Options
    // let maxPerCart = getProp( skuItem, "pick_max_per_cart", 100 )
    let maxPerCart = 100
    let possibleOrderQuantities: TsInterface_UnspecifiedObject[] = []
    for (let i = 1; i <= maxPerCart; i++) {
      possibleOrderQuantities.push({
        key: i,
        value: i,
      })
    }
    // Dropdown Value
    let dropdownValue = ''
    if (
      itemKey != null &&
      skuItem != null &&
      skuItem['key'] != null &&
      us_lineItemSkus != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']] != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'] != null
    ) {
      dropdownValue = us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity']
    }
    // JSX
    dropdownJSX = (
      <Stack
        direction="row"
        className="tw-justify-between"
      >
        <Box
          className="tw-inline-block tw-mr-2"
          sx={{ width: 'calc( 100% - 160px )' }}
        >
          {/* <Icon
						icon="circle-minus"
						className="tw-mr-2 tw-align-top tw-mt-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100"
						sx={{ fontSize: "20px" }}
						onClick={ () => {
							decrementSkuItemQuantity( skuItem, dropdownValue )
						}}
					/> */}
          <FormControl>
            <Select
              className="bp_thin_select_input"
              color="primary"
              value={dropdownValue}
              onChange={(event: any) => {
                if (event != null && event.target != null && event.target.value != null) {
                  updateOrderItem(skuItem, event.target.value, true)
                }
              }}
              variant="outlined"
              notched={true}
            >
              {possibleOrderQuantities.map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['value']}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* <Icon
						icon="circle-plus"
						className="tw-ml-2 tw-align-top tw-mt-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100"
						sx={{ fontSize: "20px" }}
						onClick={ () => {
							incrementSkuItemQuantity( skuItem, dropdownValue )
						}}
					/> */}
          {rJSX_SearchResult_PackagingUnitQuantityText(skuItem, parseFloat(dropdownValue), dropdownValue)}
        </Box>
        <Box className="tw-inline-block">
          {includedInOrder === true ? (
            <Typography
              variant="body1"
              className="tw-inline-block tw-align-top tw-mt-1.5"
              sx={{ color: themeVariables.success_main }}
            >
              <Icon
                icon="cart-shopping"
                className="tw-mr-2"
              ></Icon>
              {rLIB('In Shopping Cart')}
            </Typography>
          ) : (
            <></>
          )}
        </Box>
      </Stack>
    )
    return dropdownJSX
  }

  const rJSX_SearchEstimatedPrice = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let estimatedPriceJSX = <></>
    if (
      skuItem != null &&
      skuItem['pick_estimated_price_per_package'] != null &&
      itemKey != null &&
      skuItem != null &&
      skuItem['key'] != null &&
      us_lineItemSkus != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']] != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'] != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'] > 0
    ) {
      let quantityPerPackageMultiplier = getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)
      estimatedPriceJSX = (
        <Typography
          variant="body1"
          className=""
        >
          {formatCurrency(
            quantityPerPackageMultiplier *
              skuItem['pick_estimated_price_per_package'] *
              us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'],
          )}
        </Typography>
      )
    }
    return estimatedPriceJSX
  }

  const rJSX_SearchResult_PackagingUnitQuantityText = (
    skuItem: TsInterface_UnspecifiedObject,
    quantityValue: number,
    dropdownValue: number | string,
  ): JSX.Element => {
    let textJSX = <></>
    if (isNaN(quantityValue)) {
      quantityValue = 1
    }
    let totalTextJSX = <></>
    if (dropdownValue !== '') {
      totalTextJSX = (
        <Box className="tw-inline-block tw-opacity-30 tw-ml-2">
          ({getProp(skuItem, 'pick_unit_quantity_per_package', 1) * quantityValue} {rLIB('total')})
        </Box>
      )
    }
    // quantityValue *= quantityPerPackageMultiplier
    if (skuItem.pick_packaging_type != null && skuItem.pick_packaging_type !== 'ea') {
      if (skuItem.pick_base_unit_of_measurement === 'ft') {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {getProp(skuItem, 'pick_unit_quantity_per_package', 1)} {rLIB('ft')} {pluralize(skuItem['pick_packaging_type'], quantityValue)}
            {totalTextJSX}
          </Box>
        )
      } else {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {pluralize(skuItem['pick_packaging_type'], quantityValue)} {rLIB('of')} {getProp(skuItem, 'pick_unit_quantity_per_package', 1)}
            {totalTextJSX}
          </Box>
        )
      }
    } else {
      if (skuItem.pick_base_unit_of_measurement === 'ft') {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {rLIB('ft')}
            {totalTextJSX}
          </Box>
        )
      } else {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {totalTextJSX}
          </Box>
        )
      }
    }
    return textJSX
  }

  const rJSX_SearchResultSkuItem = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let includedInOrder = false
    if (
      itemKey != null &&
      skuItem != null &&
      skuItem['key'] != null &&
      us_lineItemSkus != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']] != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'] != null &&
      us_lineItemSkus[itemKey + '_' + skuItem['key']]['associated_sku_pick_requested_quantity'] > 0
    ) {
      includedInOrder = true
    }
    // JSX
    let quantityPerPackageMultiplier = getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)
    let skuItemJSX = (
      <Card
        sx={{
          border: includedInOrder === true ? '2px solid ' + themeVariables.success_main : '2px solid ' + themeVariables.gray_800,
          borderRadius: '8px',
        }}
        className="tw-px-3 tw-pt-1 tw-pb-3 tw-my-2"
      >
        <Stack direction="row">
          {rJSX_MaterialSearchResultSkuItemImage(getProp(skuItem, 'photo_url', null))}
          <Box sx={{ width: 'calc( 100% - 88px )' }}>
            <Stack
              direction="row"
              className="tw-justify-between"
            >
              <Box sx={{ width: 'calc( 100% - 100px )' }}>
                <Box>
                  <Typography
                    variant="body1"
                    className="tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis"
                  >
                    {getProp(skuItem, 'name', '')}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  className="tw-opacity-40"
                >
                  {getProp(skuItem, 'unique_identifier', '')}
                </Typography>
                <Typography
                  variant="body1"
                  className="tw-opacity-40"
                >
                  {getProp(skuItem, 'associated_manufacturer_unique_identifier', '')}
                </Typography>
              </Box>
              <Box
                sx={{ width: '100px' }}
                className="tw-text-right"
              >
                {rJSX_SearchEstimatedPrice(skuItem)}
                <Typography
                  variant="body1"
                  className="tw-opacity-40"
                >
                  {getProp(skuItem, 'pick_estimated_price_per_package', null) != null
                    ? formatCurrency(quantityPerPackageMultiplier * getProp(skuItem, 'pick_estimated_price_per_package', null))
                    : '$UNK'}{' '}
                  /{getProp(skuItem, 'pick_base_unit_of_measurement', rLIB('ea'))}
                </Typography>
              </Box>
            </Stack>
            <Box className="tw-pt-1">{rJSX_MaterialSearchResultOrderQuantityDropdown(skuItem, includedInOrder)}</Box>
          </Box>
        </Stack>
      </Card>
    )
    return skuItemJSX
  }

  const rJSX_SearchResultContainer = (): JSX.Element => {
    let containerJSX = <></>
    if (us_searchValue.length > 0) {
      containerJSX = (
        <Box>
          {us_searchResults.map((skuItem: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>{rJSX_SearchResultSkuItem(skuItem)}</Box>
          ))}
        </Box>
      )
    }
    return containerJSX
  }

  const rJSX_SearchTabContent = (): JSX.Element => {
    let tabContentJSX = (
      <Box>
        <Box
          className="tw-m-auto"
          sx={{ maxWidth: '800px' }}
        >
          {rJSX_SearchBar()}
          {rJSX_SearchResultContainer()}
        </Box>
      </Box>
    )
    return tabContentJSX
  }

  // Shopping Cart Tab
  const rJSX_DeleteFromShoppingCartButton = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = (
      <Button
        color="error"
        variant="outlined"
        startIcon={<Icon icon="trash" />}
        className="tw-align-top"
        disabled={us_rootOrder.status !== 'new'}
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Remove from Shopping Cart'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to remove this item from your shopping cart?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseDeleteDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, skuItem.key))
                        .then((res_DDD) => {
                          resolve(res_DDD)
                        })
                        .catch((rej_DDD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }}
      >
        {rLIB('Delete')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ShoppingCartOrderQuantityDropdown = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    // Determine Order Quantity Dropdown Options
    let possibleOrderQuantities: TsInterface_UnspecifiedObject[] = []
    // let maxPerCart = getProp( skuItem, "associated_sku_pick_max_per_cart", 100 )
    let maxPerCart = 100
    for (let i = 1; i <= maxPerCart; i++) {
      possibleOrderQuantities.push({
        key: i,
        value: i,
      })
    }
    // Dropdown Value
    let dropdownValue = ''
    if (
      itemKey != null &&
      skuItem != null &&
      skuItem['key'] != null &&
      us_lineItemSkus != null &&
      us_lineItemSkus[skuItem['key']] != null &&
      us_lineItemSkus[skuItem['key']]['associated_sku_pick_requested_quantity'] != null
    ) {
      dropdownValue = us_lineItemSkus[skuItem['key']]['associated_sku_pick_requested_quantity']
    }
    let dropdownJSX = <></>
    dropdownJSX = (
      <Box className="tw-inline-block">
        <Icon
          icon="circle-minus"
          className="tw-mr-2 tw-align-top tw-mt-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100"
          sx={{ fontSize: '20px' }}
          onClick={() => {
            decrementSkuItemQuantity(skuItem, dropdownValue)
          }}
        />
        <FormControl>
          <Select
            className="bp_thin_select_input"
            color="primary"
            value={dropdownValue}
            disabled={us_rootOrder.status !== 'new'}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                let updateObject = {
                  associated_sku_pick_requested_quantity: event.target.value,
                }
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_SkuLineItem_Document(res_GCK.clientKey, skuItem.key), updateObject)
                      .then((res_DSMD) => {
                        // Done
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              }
            }}
            variant="outlined"
            notched={true}
          >
            {possibleOrderQuantities.map((option: TsInterface_UnspecifiedObject, index: number) => (
              <MenuItem
                key={index}
                value={option['key']}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Icon
          icon="circle-plus"
          className="tw-ml-2 tw-align-top tw-mt-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100"
          sx={{ fontSize: '20px' }}
          onClick={() => {
            incrementSkuItemQuantity(skuItem, dropdownValue)
          }}
        />
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_ShoppingCart_PackagingUnitQuantityText = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let textJSX = <></>
    let quantityValue = 1
    if (
      skuItem != null &&
      skuItem['key'] != null &&
      us_lineItemSkus != null &&
      us_lineItemSkus[skuItem['key']] != null &&
      us_lineItemSkus[skuItem['key']]['associated_sku_pick_requested_quantity'] != null &&
      !isNaN(parseFloat(us_lineItemSkus[skuItem['key']]['associated_sku_pick_requested_quantity']))
    ) {
      quantityValue = parseFloat(us_lineItemSkus[skuItem['key']]['associated_sku_pick_requested_quantity'])
    }
    let totalTextJSX = <></>
    totalTextJSX = (
      <Box className="tw-inline-block tw-opacity-30 tw-ml-2">
        ({getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1) * quantityValue} {rLIB('total')})
      </Box>
    )
    if (skuItem.associated_sku_pick_packaging_type != null && skuItem.associated_sku_pick_packaging_type !== 'ea') {
      if (skuItem.associated_sku_pick_base_unit_of_measurement === 'ft') {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)} {rLIB('ft')}{' '}
            {pluralize(skuItem['associated_sku_pick_packaging_type'], quantityValue)}
            {totalTextJSX}
          </Box>
        )
      } else {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {pluralize(skuItem['associated_sku_pick_packaging_type'], quantityValue)} {rLIB('of')}{' '}
            {getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)}
            {totalTextJSX}
          </Box>
        )
      }
    } else {
      if (skuItem.associated_sku_pick_base_unit_of_measurement === 'ft') {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {rLIB('ft')}
            {totalTextJSX}
          </Box>
        )
      } else {
        textJSX = (
          <Box
            sx={{ marginTop: '6px' }}
            className="tw-inline-block tw-ml-2 tw-opacity-100 tw-align-top"
          >
            {totalTextJSX}
          </Box>
        )
      }
    }
    return textJSX
  }

  const rJSX_ShoppingCartOrderQuantityDropdownRow = (skuItem: TsInterface_UnspecifiedObject, includedInOrder: boolean): JSX.Element => {
    let dropdownRowJSX = <></>
    // JSX
    dropdownRowJSX = (
      <Stack
        className="tw-justify-between"
        direction="row"
      >
        <Box
          className="tw-inline-block tw-mr-2"
          sx={{ width: 'calc( 100% - 110px )' }}
        >
          {rJSX_ShoppingCartOrderQuantityDropdown(skuItem)}
          {rJSX_ShoppingCart_PackagingUnitQuantityText(skuItem)}
          {rJSX_ShoppingCartSupplierStatus(skuItem)}
        </Box>
        {rJSX_DeleteFromShoppingCartButton(skuItem)}
      </Stack>
    )
    return dropdownRowJSX
  }

  const rJSX_ShoppingCartSupplierStatus = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let supplierStatusJSX = <></>
    switch (getProp(skuItem, 'associated_supplier_inventory_status', null)) {
      case 'in_stock':
        supplierStatusJSX = (
          <Box className="tw-inline-block tw-mt-0.5 tw-align-top">
            <Chip
              className="tw-ml-2"
              color="success"
              variant="outlined"
              // sx={ chipSX }
              label={
                <Box>
                  <Icon
                    icon="badge-check"
                    className="tw-mr-1.5"
                    sx={{ fontSize: '16px' }}
                  />
                  <Typography
                    variant="body1"
                    className="tw-inline-block"
                  >
                    {rLIB('In Stock')}
                  </Typography>
                </Box>
              }
            />
          </Box>
        )
        break
      case 'on_order':
        supplierStatusJSX = (
          <Box className="tw-inline-block tw-mt-0.5 tw-align-top">
            <Chip
              className="tw-ml-2"
              color="warning"
              variant="outlined"
              // sx={ chipSX }
              label={
                <Box>
                  <Icon
                    icon="clock"
                    className="tw-mr-1.5"
                    sx={{ fontSize: '16px' }}
                  />
                  <Typography
                    variant="body1"
                    className="tw-inline-block"
                  >
                    {rLIB('On Order')}
                  </Typography>
                </Box>
              }
            />
          </Box>
        )
        break
      case 'out_of_stock':
        supplierStatusJSX = (
          <Box className="tw-inline-block tw-mt-0.5 tw-align-top">
            <Chip
              className="tw-ml-2"
              color="error"
              variant="outlined"
              // sx={ chipSX }
              label={
                <Box>
                  <Icon
                    icon="triangle-exclamation"
                    className="tw-mr-1.5"
                    sx={{ fontSize: '16px' }}
                  />
                  <Typography
                    variant="body1"
                    className="tw-inline-block"
                  >
                    {rLIB('Out of Stock')}
                  </Typography>
                </Box>
              }
            />
          </Box>
        )
        break
      default:
        // NOTHING
        break
    }
    return supplierStatusJSX
  }

  const rJSX_ShoppingCartSkuItem = (skuItem: TsInterface_UnspecifiedObject): JSX.Element => {
    let includedInOrder = true
    // JSX
    let quantityPerPackageMultiplier = getProp(skuItem, 'associated_sku_pick_unit_quantity_per_package', 1)
    let skuItemJSX = (
      <Card
        sx={{
          border: '2px solid ' + themeVariables.gray_800,
          borderRadius: '8px',
        }}
        className="tw-px-3 tw-pt-1 tw-pb-3 tw-my-2"
      >
        <Stack direction="row">
          {rJSX_MaterialSearchResultSkuItemImage(getProp(skuItem, 'associated_sku_estimated_photo_url', null))}
          <Box sx={{ width: 'calc( 100% - 88px )' }}>
            <Stack
              direction="row"
              className="tw-justify-between"
            >
              <Box sx={{ width: 'calc( 100% - 100px )' }}>
                <Box>
                  <Typography
                    variant="body1"
                    className="tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis"
                  >
                    {getProp(skuItem, 'associated_sku_name', '')}
                  </Typography>
                </Box>
                <Typography
                  variant="body1"
                  className="tw-opacity-40"
                >
                  {getProp(skuItem, 'associated_sku_unique_identifier', '')}
                </Typography>
              </Box>
              <Box
                sx={{ width: '100px' }}
                className="tw-text-right"
              >
                <Typography
                  variant="body1"
                  className=" "
                >
                  {getProp(skuItem, 'associated_sku_pick_estimated_price_per_package', null) != null
                    ? formatCurrency(
                        quantityPerPackageMultiplier *
                          getProp(skuItem, 'associated_sku_pick_estimated_price_per_package', null) *
                          getProp(skuItem, 'associated_sku_pick_requested_quantity', 1),
                      )
                    : '$UNK'}
                </Typography>
                <Typography
                  variant="body1"
                  className="tw-opacity-40 "
                >
                  {getProp(skuItem, 'associated_sku_pick_estimated_price_per_package', null) != null
                    ? formatCurrency(getProp(skuItem, 'associated_sku_pick_estimated_price_per_package', null))
                    : '$UNK'}{' '}
                  /{getProp(skuItem, 'associated_sku_pick_base_unit_of_measurement', rLIB('ea'))}
                </Typography>
              </Box>
            </Stack>
            <Box className="tw-pt-1">{rJSX_ShoppingCartOrderQuantityDropdownRow(skuItem, includedInOrder)}</Box>
          </Box>
        </Stack>
      </Card>
    )
    return skuItemJSX
  }

  const rJSX_EditDateIcon = (): JSX.Element => {
    let iconJSX = (
      <Icon
        icon="pen-to-square"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: us_rootOrder,
                formInputs: {
                  timestamp_scheduled_pickup_date_key: {
                    data_type: 'string',
                    input_type: 'timestamp_date',
                    key: 'timestamp_scheduled_pickup_date_key',
                    label: rLIB('Scheduled Pickup/Delivery Date'),
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), formSubmittedData)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Scheduled Pickup/Delivery Date'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
      />
    )
    if (us_rootOrder.status !== 'new') {
      iconJSX = <></>
    }
    return iconJSX
  }

  const rJSX_EditSupplierLocationIcon = (): JSX.Element => {
    let iconJSX = (
      <Icon
        icon="pen-to-square"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: us_rootOrder,
                formInputs: {
                  associated_supplier_location_key: {
                    key: 'associated_supplier_location_key',
                    label: rLIB('Supplier Location'),
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    options: objectToArray(us_supplierLocationOptions),
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          associated_supplier_location_key: formSubmittedData.associated_supplier_location_key,
                          associated_supplier_location_name: getProp(
                            getProp(us_supplierLocationOptions, formSubmittedData.associated_supplier_location_key, {}),
                            'value',
                            null,
                          ),
                        }
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Scheduled Pickup/Delivery Date'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
      />
    )
    if (us_rootOrder.status !== 'new') {
      iconJSX = <></>
    }
    return iconJSX
  }

  const rJSX_EditDeliveryTypeIcon = (): JSX.Element => {
    let iconJSX = (
      <Icon
        icon="pen-to-square"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: us_rootOrder,
                formInputs: {
                  delivery_type: {
                    key: 'delivery_type',
                    label: rLIB('Delivery Type'),
                    input_type: 'multiple_choice_radio',
                    submit_on_change: true,
                    required: true,
                    data_type: 'string',
                    options: [
                      { key: 'pickup', value: rLIB('Pickup') },
                      { key: 'delivery', value: rLIB('Delivery') },
                    ],
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {
                  submit_button_hide: true,
                },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), formSubmittedData)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Scheduled Pickup/Delivery Date'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
      />
    )
    if (us_rootOrder.status !== 'new') {
      iconJSX = <></>
    }
    return iconJSX
  }

  const rJSX_EditDeliveryNotesIcon = (): JSX.Element => {
    let iconJSX = (
      <Icon
        icon="pen-to-square"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: us_rootOrder,
                formInputs: {
                  delivery_notes: {
                    key: 'delivery_notes',
                    label: rLIB('Delivery Notes'),
                    input_type: 'text_multiline',
                    required: true,
                    data_type: 'string',
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {
                  // submit_button_hide: true,
                },
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseSetMergeDocument(DatabaseRef_Order_Document(res_GCK.clientKey, itemKey), formSubmittedData)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Delivery Notes'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
        className="tw-pl-2 tw-cursor-pointer hover:tw-text-success_main tw-opacity-30 hover:tw-opacity-100"
        tooltip={rLIB('Edit')}
        tooltipPlacement="right"
      />
    )
    if (us_rootOrder.status !== 'new') {
      iconJSX = <></>
    }
    return iconJSX
  }

  const rJSX_RegionWarningBanner = (): JSX.Element => {
    let bannerJSX = <></>
    if (
      us_associatedProject != null &&
      us_associatedProject.associated_region_key != null &&
      us_rootOrder != null &&
      us_rootOrder.associated_supplier_location_key != null &&
      us_associatedSupplier != null &&
      us_associatedSupplier.locations != null &&
      us_associatedSupplier.locations[us_rootOrder.associated_supplier_location_key] != null &&
      us_associatedSupplier.locations[us_rootOrder.associated_supplier_location_key].associated_region_key != null &&
      us_associatedProject.associated_region_key !== us_associatedSupplier.locations[us_rootOrder.associated_supplier_location_key].associated_region_key
    ) {
      bannerJSX = (
        <Box
          className="tw-rounded tw-p-2"
          sx={{ background: themeVariables.warning_main }}
        >
          <Box>
            {rLIB('Region assigned to supplier location')}:{' '}
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.error_main, fontWeight: 700 }}
            >
              {getProp(
                getProp(us_regionsList, us_associatedSupplier.locations[us_rootOrder.associated_supplier_location_key].associated_region_key, {}),
                'name',
                '',
              )}
            </Box>
          </Box>
          <Box>
            {rLIB('Region assigned to project')}:{' '}
            <Box
              className="tw-inline-block"
              sx={{ color: themeVariables.error_main, fontWeight: 700 }}
            >
              {getProp(getProp(us_regionsList, us_associatedProject.associated_region_key, {}), 'name', '')}
            </Box>
          </Box>
        </Box>
      )
    }
    return bannerJSX
  }

  const rJSX_ShoppingCartTabContent = (): JSX.Element => {
    let tabContentJSX = (
      <Box
        className="tw-m-auto"
        sx={{ maxWidth: '800px' }}
      >
        <Card className="tw-p-4">
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Order')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'po_number', '')}</Box>
          </Typography>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Supplier')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'associated_supplier_name', '')}</Box>
          </Typography>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Supplier Location')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'associated_supplier_location_name', '')}</Box>
            {rJSX_EditSupplierLocationIcon()}
          </Typography>
          {rJSX_RegionWarningBanner()}
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Project')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'associated_project_id_number', '')}</Box>
          </Typography>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Task')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'associated_task_name', '')}</Box>
          </Typography>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Team')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'associated_team_name', '')}</Box>
          </Typography>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Scheduled Pickup/Delivery Date')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'timestamp_scheduled_pickup_date_key', '')}</Box>
            {rJSX_EditDateIcon()}
          </Typography>
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Delivery Type')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{capitalize(getProp(us_rootOrder, 'delivery_type', ''))}</Box>
            {rJSX_EditDeliveryTypeIcon()}
          </Typography>
          {getProp(us_rootOrder, 'delivery_type', '') === 'delivery' ? (
            <Typography variant="h6">
              <Box className="tw-inline-block tw-mr-1">{rLIB('Delivery Notes')}:</Box>
              <Box className="tw-inline-block tw-opacity-40">{getProp(us_rootOrder, 'delivery_notes', '')}</Box>
              {rJSX_EditDeliveryNotesIcon()}
            </Typography>
          ) : (
            <></>
          )}
          <Divider className="tw-my-2" />
          <Typography variant="h6">
            <Box className="tw-inline-block tw-mr-1">{rLIB('Estimated Total')}:</Box>
            <Box className="tw-inline-block tw-opacity-40">{formatCurrency(returnEstimatedInvoiceTotal())}</Box>
          </Typography>
        </Card>
        <Divider className="tw-mt-2" />
        <Box>
          {objectToArray(us_lineItemSkus)
            .sort(dynamicSort('name', 'asc'))
            .map((skuItem: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>{rJSX_ShoppingCartSkuItem(skuItem)}</Box>
            ))}
        </Box>
      </Box>
    )
    return tabContentJSX
  }

  const rJSX_ProjectImagesTabContent = (): JSX.Element => {
    let tabContentJSX = (
      <Box>
        <ProjectImagesView projectKey={getProp(us_rootOrder, 'associated_project_key', null)} />
      </Box>
    )
    return tabContentJSX
  }

  const rJSX_ProjectDocsTabContent = (): JSX.Element => {
    let tabContentJSX = (
      <Box>
        <ProjectFilesView projectKey={getProp(us_rootOrder, 'associated_project_key', null)} />
      </Box>
    )
    return tabContentJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <>{rLIB('Order')}</>
    if (us_rootOrder != null && us_rootOrder.po_number != null) {
      pageHeader = (
        <>
          {rLIB('Order')} - {us_rootOrder.po_number}
        </>
      )
    }
    return pageHeader
  }

  const rJSX_Page = (): JSX.Element => {
    let tabs = [
      {
        tabUrlKey: 'Shopping_Cart',
        tabHeader: rLIB('Shopping Cart'),
        tabContent: rJSX_ShoppingCartTabContent(),
        tabButtons: [
          { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
          { fullJSX: rJSX_LoadFromGoogleSpreadsheetButton(false), minJSX: rJSX_LoadFromGoogleSpreadsheetButton(true), sizeCutoff: 0 },
        ],
      },
      {
        tabUrlKey: 'Images',
        tabHeader: rLIB('Project Images'),
        tabContent: rJSX_ProjectImagesTabContent(),
        tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
      },
      {
        tabUrlKey: 'Docs',
        tabHeader: rLIB('Project Docs'),
        tabContent: rJSX_ProjectDocsTabContent(),
        tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
      },
    ]
    if (us_rootOrder.status === 'new') {
      tabs.unshift({
        tabUrlKey: 'Search',
        tabHeader: rLIB('Search'),
        tabContent: rJSX_SearchTabContent(),
        tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
      })
    }
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={tabs}
              tabsSettings={{
                baseUrl: ApplicationPages.AdminDatabaseMaterialsOrderViewPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Order', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:


	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import { downloadAggregatedPunchData, downloadPanelPayReport } from 'app/models/finance/beta_reports'
import {
  aggregateTimesheetPunchData,
  cleanPunchDataBeforeEvaluation,
  convertToTimeFormat,
  refreshTimeSheetAdminEvaluation,
} from 'app/models/timesheets/timesheet_functions'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_TimecardHourlyUsers_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_CancelledScheduledTasksInDateRange_Query } from 'rfbp_aux/services/database_endpoints/operations/cancelled_scheduled_tasks'
import { DatabaseRef_ProjectsByFICDate_Query, DatabaseRef_ProjectsByFICOverrideDate_Query } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_PanelPayReport_Document } from 'rfbp_aux/services/database_endpoints/operations/report_configs'
import {
  DatabaseRef_ScheduledTasksInDateRange_Query,
  DatabaseRef_TaskForSpecificProjectAndTaskKey_Query,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import {
  DatabaseRef_DefaultAllocatedTime_Collection,
  DatabaseRef_OverrideAllocatedTime_SpecificDate_Query,
} from 'rfbp_aux/services/database_endpoints/timesheets/allocated_time'
import {
  DatabaseRef_TimePunchesHistory_Collection,
  DatabaseRef_TimePunches_DateRange_Query,
  DatabaseRef_TimePunches_Document,
  DatabaseRef_TimePunches_UserDateRange_Query,
} from 'rfbp_aux/services/database_endpoints/timesheets/time_punches'
import { DatabaseRef_TimeSheets_Document, DatabaseRef_TimeSheets_SpecificDate_Query } from 'rfbp_aux/services/database_endpoints/timesheets/time_sheets'
import { Json } from 'rfbp_core/components/code_display'
import { DatePicker } from 'rfbp_core/components/date_picker/date_picker'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableManageAction,
  TsInterface_TableManageActionsObject,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
} from 'rfbp_core/services/database_management'
import { downloadCSV, dynamicSort, getProp, objectToArray, returnFormattedDate, returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_IndividualTimeSheet } from '../components/individual_week_timesheet'
import { TimesheetPunchEdit } from '../components/timesheet_punch_edit'

///////////////////////////////
// Typescript
///////////////////////////////

interface TabProps {
  selectedDate: Date
  setSelectedDate: any
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_LOAD_ANALYSIS: JSX.Element = 															<Trans>Load Analysis</Trans>
const s_ADMIN_TIME: JSX.Element = <Trans>Admin Time</Trans>
const s_AGGREGATED_PUNCHES: JSX.Element = <Trans>aggregated punches</Trans>
const s_APPROVAL: JSX.Element = <Trans>Approval</Trans>
const s_APPROVE: JSX.Element = <Trans>Approve</Trans>
const s_APPROVED: JSX.Element = <Trans>Approved</Trans>
const s_APPROVE_TIMESHEET: JSX.Element = <Trans>Approve Timesheet</Trans>
const s_APPROVE_TIMESHEET_BEFORE_EMPLOYEE_SUBMISSION: JSX.Element = <Trans>Approve timesheet before employee submission</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_APPROVE_THIS_TIMESHEET: JSX.Element = <Trans>Are you sure that you want to approve this timesheet?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_TIME_PUNCH: JSX.Element = <Trans>Are you sure that you want to delete this time punch</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_MARK_THIS_CORRECTION_AS_MANAGED: JSX.Element = (
  <Trans>Are you sure that you want to mark this correction as managed?</Trans>
)
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_MARK_THIS_CORRECTION_AS_NOT_MANAGED: JSX.Element = (
  <Trans>Are you sure that you want to mark this correction as not managed?</Trans>
)
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNAPPROVE_THIS_TIMESHEET: JSX.Element = <Trans>Are you sure that you want to unapprove this timesheet?</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDELETE_THIS_TIME_PUNCH: JSX.Element = <Trans>Are you sure that you want to undelete this time punch</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDO_THIS_EMPLOYEES_SUBMISSION: JSX.Element = <Trans>Are you sure that you want to undo this employee's submission</Trans>
const s_BREAK: JSX.Element = <Trans>Break</Trans>
const s_BY_ALLOCATION: JSX.Element = <Trans>By Allocation</Trans>
const s_BY_DAY: JSX.Element = <Trans>By Day</Trans>
const s_BY_SUBALLOCATION: JSX.Element = <Trans>By Suballocation</Trans>
const s_CREATE_TIME_PUNCH: JSX.Element = <Trans>Create time punch</Trans>
const s_CURRENT: JSX.Element = <Trans>Current</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_TIME_PUNCH: JSX.Element = <Trans>Delete Time Punch</Trans>
const s_DOWNLOADS: JSX.Element = <Trans>Downloads</Trans>
const s_EDIT: JSX.Element = <Trans>Edit</Trans>
const s_EDIT_TIME_PUNCH: JSX.Element = <Trans>Edit Time Punch</Trans>
const s_ERRORS: JSX.Element = <Trans>Errors</Trans>
const s_FAILED_TO_APPROVE_TIMESHEET: JSX.Element = <Trans>Failed to approve timesheet</Trans>
const s_FAILED_TO_DOWNLOAD_REPORT: JSX.Element = <Trans>Failed to download report</Trans>
const s_FAILED_TO_UNAPPROVE_TIMESHEET: JSX.Element = <Trans>Failed to unapprove timesheet</Trans>
const s_FIELD_WORK: JSX.Element = <Trans>Field Work</Trans>
const s_FIXED: JSX.Element = <Trans>Fixed</Trans>
const s_HISTORY: JSX.Element = <Trans>History</Trans>
const s_IT_HAS_NOT_BEEN_SUBMITTED_YET: JSX.Element = <Trans>It has not been submitted yet</Trans>
const s_MARK_CORRECTION_AS_MANAGED: JSX.Element = <Trans>Mark correction as managed</Trans>
const s_MARK_CORRECTION_AS_NOT_MANAGED: JSX.Element = <Trans>Mark correction as not managed</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_MISSING_REQUIRED_CONFIGURATIONS: JSX.Element = <Trans>Missing required configurations</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing Required Parameters</Trans>
const s_NAME: JSX.Element = <Trans>Name</Trans>
const s_NEXT_USER_WITH_AN_ERROR: JSX.Element = <Trans>Next user with an error</Trans>
const s_NON_WORKING_TIME: JSX.Element = <Trans>Non Working Time</Trans>
const s_NOT_APPROVED: JSX.Element = <Trans>Not Approved</Trans>
const s_NOT_SUBMITTED: JSX.Element = <Trans>Not Submitted</Trans>
const s_NO_DATA_TO_ANALYIZE: JSX.Element = <Trans>No data to analyze</Trans>
const s_NO_ERRORS_OR_WARNINGS: JSX.Element = <Trans>No errors or warnings</Trans>
const s_NO_REQUESTED_CORRECTIONS: JSX.Element = <Trans>No Requested Corrections</Trans>
const s_OTHERWISE_CLICK_DISMISS: JSX.Element = <Trans>Otherwise click "Dismiss"</Trans>
const s_PANEL_PAY: JSX.Element = <Trans>Panel Pay</Trans>
const s_PAYROLL: JSX.Element = <Trans>Payroll</Trans>
const s_RAW_PUNCHES: JSX.Element = <Trans>raw punches</Trans>
const s_REQUESTED_CORRECTIONS: JSX.Element = <Trans>Requested Corrections</Trans>
const s_SHOWING_ALL_USERS: JSX.Element = <Trans>Showing All Users</Trans>
const s_SHOW_ALL_USERS: JSX.Element = <Trans>Show All Users</Trans>
const s_SUBMISSION: JSX.Element = <Trans>Submission</Trans>
const s_SUBMITTED: JSX.Element = <Trans>Submitted</Trans>
const s_THEY_WILL_NEED_TO_RESUBMIT_THEIR_TIMESHEET_IF_YOU_DO: JSX.Element = <Trans>They will need to resubmit their timesheet if you do</Trans>
const s_TIME_PUNCH_LOG_HISTORY: JSX.Element = <Trans>Time punch log history</Trans>
const s_TOTAL: JSX.Element = <Trans>Total</Trans>
const s_TYPE_DANGER_TO_PROCEED: JSX.Element = <Trans>Type "DANGER" to proceed</Trans>
const s_UNAPPROVE: JSX.Element = <Trans>Unapprove</Trans>
const s_UNAPPROVE_TIMESHEET: JSX.Element = <Trans>Unapprove Timesheet</Trans>
const s_UNDELETE: JSX.Element = <Trans>Undelete</Trans>
const s_UNDELETE_TIME_PUNCH: JSX.Element = <Trans>Undelete Time Punch</Trans>
const s_UNDO_EMPLOYEE_TIMESHEET_SUBMISSION: JSX.Element = <Trans>Undo Employee Timesheet Submission</Trans>
const s_UNDO_SUBMISSION: JSX.Element = <Trans>Undo Submission</Trans>
const s_UPDATE: JSX.Element = <Trans>Update</Trans>
const s_VIEW_LOGS: JSX.Element = <Trans>View Logs</Trans>
const s_VIEW_TIMESHEET_DETAILS: JSX.Element = <Trans>View Timesheet Details</Trans>
const s_WARNINGS: JSX.Element = <Trans>Warnings</Trans>
const s_YOU_MUST_ENTER_DANGER_IN_ORDER_TO_PROCEED: JSX.Element = <Trans>You must enter "DANGER" in order to proceed.</Trans>
// { sort-end } - displayed text

// Table
const tableSettings_TimeSheetReviewUsers: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
  collapsible_columns: true,
}

const selectIndividualTimesheetUser: TsInterface_TableManageAction = {
  icon: <Icon icon="magnifying-glass" />,
  label: <>{s_VIEW_TIMESHEET_DETAILS}</>,
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    if (
      tableAdditionalData != null &&
      tableAdditionalData.setSelectedIndividualKey != null &&
      tableAdditionalData.setSelectedViewType != null &&
      rowData != null &&
      rowData.key != null
    ) {
      tableAdditionalData.setSelectedViewType('individual')
      tableAdditionalData.setSelectedIndividualKey(rowData.key)
    }
  },
}

const undoTimesheetSubmission: TsInterface_TableManageAction = {
  icon: <Icon icon="rotate-left" />,
  label: <>{s_UNDO_SUBMISSION}</>,
  conditional_display: {
    active: true,
    logic_type: 'comparison',
    source: 'rowData',
    prop: 'timestamp_submitted',
    comparator: '!=',
    value: null,
    conditions: [],
  },
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    // Function
    const undoTimesheetSubmission = () => {
      return new Promise((resolve, reject) => {
        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            let updateObject: TsInterface_UnspecifiedObject = {
              timestamp_submitted: null,
              associated_submitter_key: null,
              associated_submitter_name: null,
              // associated_date_key
            }
            if (tableAdditionalData != null && tableAdditionalData.dateKey != null) {
              updateObject['associated_date_key'] = tableAdditionalData.dateKey
            }
            if (rowData != null && rowData.timesheet_key != null) {
              DatabaseSetMergeDocument(DatabaseRef_TimeSheets_Document(res_GCK.clientKey, rowData.timesheet_key as string), updateObject)
                .then((res_DSMD) => {
                  resolve(res_DSMD)
                })
                .catch((rej_DSMD) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                  reject(rej_DSMD)
                })
            } else {
              let error = {
                message: s_FAILED_TO_APPROVE_TIMESHEET,
                details: s_MISSING_REQUIRED_PARAMETERS,
                code: 'ER-D-TAR-AIT-01',
              }
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
              reject({ success: false, error: error })
            }
          })
          .catch((rej_GCK) => {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            reject(rej_GCK)
          })
      })
    }
    // Dialog
    tableHooks.uc_setUserInterface_PromptDialogDisplay({
      display: true,
      prompt: {
        color: 'error',
        confirm_text: s_UNDO_SUBMISSION,
        default_value: '',
        header: s_UNDO_EMPLOYEE_TIMESHEET_SUBMISSION,
        icon: (
          <Icon
            icon="siren-on"
            type="solid"
          />
        ),
        input_label: s_TYPE_DANGER_TO_PROCEED,
        input_type: 'text',
        text: (
          <>
            {s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDO_THIS_EMPLOYEES_SUBMISSION} {s_THEY_WILL_NEED_TO_RESUBMIT_THEIR_TIMESHEET_IF_YOU_DO}
          </>
        ),
        submit_callback: (promptValue: string) => {
          return new Promise((resolve, reject) => {
            if (promptValue === 'DANGER') {
              undoTimesheetSubmission()
                .then((res_UTS) => {
                  resolve(res_UTS)
                })
                .catch((rej_UTS) => {
                  reject(rej_UTS)
                })
            } else {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  message: s_FAILED_TO_APPROVE_TIMESHEET,
                  details: (
                    <>
                      {s_YOU_MUST_ENTER_DANGER_IN_ORDER_TO_PROCEED} {s_OTHERWISE_CLICK_DISMISS}
                    </>
                  ),
                  code: 'ER-D-TAR-UTS-01',
                },
              })
              resolve({ close_dialog: false })
            }
          })
        },
      },
    })
  },
}

const approveIndividualTimesheet: TsInterface_TableManageAction = {
  icon: <Icon icon="clipboard-check" />,
  label: <>{s_APPROVE_TIMESHEET}</>,
  conditional_display: {
    active: true,
    logic_type: 'comparison',
    source: 'rowData',
    prop: 'timestamp_approved',
    comparator: '==',
    value: null,
    conditions: [],
  },
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    // Functions to approve a timesheet
    const approveTimesheet = () => {
      return new Promise((resolve, reject) => {
        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
          .then((res_GCK) => {
            if (
              rowData != null &&
              rowData.key != null &&
              tableAdditionalData != null &&
              tableAdditionalData.weekStartDate != null &&
              tableAdditionalData.weekEndDate != null
            ) {
              DatabaseGetCollection(
                DatabaseRef_TimePunches_UserDateRange_Query(
                  res_GCK.clientKey,
                  rowData.key as string,
                  tableAdditionalData.weekStartDate,
                  tableAdditionalData.weekEndDate,
                ),
              )
                .then((res_DGCs) => {
                  let rawPunchData = res_DGCs.data

                  let aggregatePunchData = aggregateTimesheetPunchData(objectToArray(cleanPunchDataBeforeEvaluation(rawPunchData)))

                  // Get Raw Punch Data

                  let breakdownTotals = getProp(aggregatePunchData, 'breakdowns', {})
                  let adminTimeBreakdown = getProp(breakdownTotals, 'admin_time', {})
                  let breakBreakdown = getProp(breakdownTotals, 'break', {})
                  let fieldWorkBreakdown = getProp(breakdownTotals, 'field_work', {})
                  let nonWorkingTimeBreakdown = getProp(breakdownTotals, 'non_working_time', {})
                  let updateObject: TsInterface_UnspecifiedObject = {
                    timestamp_approved: new Date(),
                    associated_approver_key: getProp(tableHooks.uc_RootData_ClientUser, 'key', null),
                    associated_approver_name: getProp(tableHooks.uc_RootData_ClientUser, 'name', null),
                    // associated_date_key
                    total_hours: {
                      admin_time: getProp(adminTimeBreakdown, 'total_minutes', 0) / 60,
                      break: getProp(breakBreakdown, 'total_minutes', 0) / 60,
                      field_work: getProp(fieldWorkBreakdown, 'total_minutes', 0) / 60,
                      non_working_time: getProp(nonWorkingTimeBreakdown, 'total_minutes', 0) / 60,
                    },
                  }
                  if (tableAdditionalData != null && tableAdditionalData.dateKey != null) {
                    updateObject['associated_date_key'] = tableAdditionalData.dateKey
                  }
                  if (rowData != null && rowData.timesheet_key != null) {
                    DatabaseSetMergeDocument(DatabaseRef_TimeSheets_Document(res_GCK.clientKey, rowData.timesheet_key as string), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        reject(rej_DSMD)
                      })
                  } else {
                    let error = {
                      message: s_FAILED_TO_APPROVE_TIMESHEET,
                      details: s_MISSING_REQUIRED_PARAMETERS,
                      code: 'ER-D-TAR-AIT-01',
                    }
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                    reject({ success: false, error: error })
                  }
                })
                .catch((rej_DGC) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
                })
            } else {
              tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                display: true,
                error: {
                  message: s_FAILED_TO_APPROVE_TIMESHEET,
                  details: s_MISSING_REQUIRED_PARAMETERS,
                  code: 'ER-D-TAR-AIT-01',
                },
              })
            }
          })
          .catch((rej_GCK) => {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
            reject(rej_GCK)
          })
      })
    }
    const confirmApproveTimesheet = () => {
      return new Promise((resolve, reject) => {
        tableHooks.uc_setUserInterface_PromptDialogDisplay({
          display: true,
          prompt: {
            color: 'error',
            confirm_text: s_APPROVE_TIMESHEET,
            default_value: '',
            header: s_APPROVE_TIMESHEET_BEFORE_EMPLOYEE_SUBMISSION,
            icon: (
              <Icon
                icon="siren-on"
                type="solid"
              />
            ),
            input_label: s_TYPE_DANGER_TO_PROCEED,
            input_type: 'text',
            text: (
              <>
                {s_ARE_YOU_SURE_THAT_YOU_WANT_TO_APPROVE_THIS_TIMESHEET} {s_IT_HAS_NOT_BEEN_SUBMITTED_YET}
              </>
            ),
            submit_callback: (promptValue: string) => {
              return new Promise((resolve2, reject2) => {
                if (promptValue === 'DANGER') {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey).then((res_GCK) => {
                    approveTimesheet()
                      .then((res_AT) => {
                        resolve2(res_AT)
                        resolve(res_AT)
                      })
                      .catch((rej_AT) => {
                        reject2(rej_AT)
                        reject(rej_AT)
                      })
                  })
                } else {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                    display: true,
                    error: {
                      message: s_FAILED_TO_APPROVE_TIMESHEET,
                      details: (
                        <>
                          {s_YOU_MUST_ENTER_DANGER_IN_ORDER_TO_PROCEED} {s_OTHERWISE_CLICK_DISMISS}
                        </>
                      ),
                      code: 'ER-D-TAR-AIT-01',
                    },
                  })
                  resolve2({ close_dialog: false })
                  resolve({ close_dialog: false })
                }
              })
            },
          },
        })
      })
    }
    // Open Confirm Dialog
    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'success',
        header: s_APPROVE_TIMESHEET,
        icon: (
          <Icon
            icon="clipboard-check"
            type="solid"
          />
        ),
        submit_text: s_APPROVE,
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_APPROVE_THIS_TIMESHEET,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            if (rowData.timestamp_submitted == null) {
              confirmApproveTimesheet()
                .then((res_AT) => {
                  resolve(res_AT)
                })
                .catch((rej_AT) => {
                  reject(rej_AT)
                })
            } else {
              approveTimesheet()
                .then((res_AT) => {
                  resolve(res_AT)
                })
                .catch((rej_AT) => {
                  reject(rej_AT)
                })
            }
          })
        },
      },
    })
  },
}

const unapproveIndividualTimesheet: TsInterface_TableManageAction = {
  icon: <Icon icon="ban" />,
  label: <>{s_UNAPPROVE_TIMESHEET}</>,
  conditional_display: {
    active: true,
    logic_type: 'comparison',
    source: 'rowData',
    prop: 'timestamp_approved',
    comparator: '!=',
    value: null,
    conditions: [],
  },
  onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: s_UNAPPROVE_TIMESHEET,
        icon: (
          <Icon
            icon="ban"
            type="solid"
          />
        ),
        submit_text: s_UNAPPROVE,
        text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNAPPROVE_THIS_TIMESHEET,
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateObject = {
                  timestamp_approved: null,
                  associated_approver_key: null,
                  associated_approver_name: null,
                }
                if (rowData != null && rowData.timesheet_key != null) {
                  DatabaseSetMergeDocument(DatabaseRef_TimeSheets_Document(res_GCK.clientKey, rowData.timesheet_key as string), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                } else {
                  let error = {
                    message: s_FAILED_TO_UNAPPROVE_TIMESHEET,
                    details: s_MISSING_REQUIRED_PARAMETERS,
                    code: 'ER-D-TAR-UIT-01',
                  }
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                  reject({ success: false, error: error })
                }
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
    })
  },
}

const tableColumns_TimeSheetReviewUsers: TsInterface_TableColumns = {
  manage: TableCellManage({
    select: selectIndividualTimesheetUser,
    undo_submission: undoTimesheetSubmission,
    approve: approveIndividualTimesheet,
    unapprove: unapproveIndividualTimesheet,
  }),
  name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let className: string = ''
        return className
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={() => {
                if (
                  tableAdditionalData != null &&
                  tableAdditionalData.setSelectedIndividualKey != null &&
                  tableAdditionalData.setSelectedViewType != null &&
                  rowData != null &&
                  rowData.key != null
                ) {
                  tableAdditionalData.setSelectedViewType('individual')
                  tableAdditionalData.setSelectedIndividualKey(rowData.key)
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_NAME
      },
      header_sort_by: null,
    },
  },
  // notify: {
  // header: {
  // 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => {
  // 		if( tableAdditionalData.readOrWrite === "write" ){
  // 			return s_NOTIFY
  // 		} else {
  // 			return <></>
  // 		}
  // 	},
  // 	header_sort_by: null
  // },
  // cell: {
  // 	cell_jsx: (
  // 		rowData: TsInterface_TableDataRow,
  // 		tableAdditionalData: TsInterface_TableAdditionalData,
  // 		tableHooks: TsInterface_TableHooks
  // 	) => {
  // 		let cellJSX = <></>
  // 		cellJSX =
  // 		<Box
  // 			className="tw-text-center"
  // 			onClick={ () => {
  // 				getClientKey( tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey ).then(( res_GCK ) => {
  // 					if( rowData.key != null ){
  // 						openUserPushNotificationDialog( tableHooks.uc_setUserInterface_CustomDialogDisplay, res_GCK.clientKey, rowData.key as string )
  // 					}
  // 				}).catch(( rej_GCK ) => {
  // 					tableHooks.uc_setUserInterface_ErrorDialogDisplay({display: true, error: rej_GCK.error })
  // 				})
  // 			}}
  // 			sx={{ fontSize: "16px" }}
  // 		>
  // 			<Icon icon="bell" className="tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100" />
  // 		</Box>
  // 		return cellJSX
  // 	},
  // 	cell_css: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData ) => {
  // 		let cellCSS = "tw-w-4"
  // 		return cellCSS
  // 	}
  // },
  // },
  status: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let className: string = ''
        if (rowData != null && rowData.timestamp_approved != null) {
          className = 'tw-text-center tw-bg-success_main'
        } else if (rowData != null && rowData.timestamp_submitted != null) {
          className = 'tw-text-center tw-bg-info_main'
        } else if (rowData != null && rowData.temp_has_timesheet_data != null) {
          className = 'tw-text-center tw-bg-warning_main'
        } else {
          className = 'tw-text-center tw-bg-error_main'
        }
        return className
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>

        if (rowData != null && rowData.timestamp_approved != null) {
          cellJSX = (
            <Tooltip
              placement="top"
              title={s_APPROVED}
            >
              <Box sx={{ fontSize: '16px' }}>
                <Icon icon="badge-check" />
              </Box>
            </Tooltip>
          )
        } else if (rowData != null && rowData.timestamp_submitted != null) {
          cellJSX = (
            <Tooltip
              placement="top"
              title={s_SUBMITTED}
            >
              <Box sx={{ fontSize: '16px' }}>
                <Icon icon="paper-plane" />
              </Box>
            </Tooltip>
          )
        } else if (rowData != null && rowData.temp_has_timesheet_data != null) {
          cellJSX = (
            <Tooltip
              placement="top"
              title={s_NOT_SUBMITTED}
            >
              <Box sx={{ fontSize: '16px' }}>
                <Icon icon="pause" />
              </Box>
            </Tooltip>
          )
        } else {
          cellJSX = (
            <Tooltip
              placement="top"
              title={s_MISSING}
            >
              <Box sx={{ fontSize: '16px' }}>
                <Icon icon="message-exclamation" />
              </Box>
            </Tooltip>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
  },
  analysis: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let className: string = ''
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.adminTimesheetEvaluationResults != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string] != null
        ) {
          let driverPunchData = tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]
          if (driverPunchData['error_count'] > 0) {
            className = 'tw-text-center tw-bg-error_main'
          } else if (driverPunchData['warning_count'] > 0) {
            className = 'tw-text-center tw-bg-warning_main'
          } else {
            className = 'tw-text-center tw-bg-success_main'
          }
        } else {
          className = 'tw-text-center tw-bg-gray_600'
        }
        return className
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.adminTimesheetEvaluationResults != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string] != null
        ) {
          let driverPunchData = tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]
          if (driverPunchData['error_count'] > 0) {
            cellJSX = (
              <Tooltip
                placement="top"
                title={
                  <>
                    {driverPunchData['error_count']} {s_ERRORS}
                  </>
                }
              >
                <Box sx={{ color: themeVariables.white, fontSize: '16px' }}>
                  <Icon icon="triangle-exclamation" />
                </Box>
              </Tooltip>
            )
          } else if (driverPunchData['warning_count'] > 0) {
            cellJSX = (
              <Tooltip
                placement="top"
                title={
                  <>
                    {driverPunchData['warning_count']} {s_WARNINGS}
                  </>
                }
              >
                <Box sx={{ color: themeVariables.white, fontSize: '16px' }}>
                  <Icon icon="triangle-exclamation" />
                </Box>
              </Tooltip>
            )
          } else {
            cellJSX = (
              <Tooltip
                placement="top"
                title={s_NO_ERRORS_OR_WARNINGS}
              >
                <Box sx={{ color: themeVariables.white, fontSize: '16px' }}>
                  <Icon icon="circle-check" />
                </Box>
              </Tooltip>
            )
          }
        } else {
          cellJSX = (
            <Tooltip
              placement="top"
              title={s_NO_DATA_TO_ANALYIZE}
            >
              <Box sx={{ color: themeVariables.white, fontSize: '16px' }}>
                <Icon icon="square-question" />
              </Box>
            </Tooltip>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
  },
  corrections: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let className: string = ''
        if (
          rowData != null &&
          rowData.requested_corrections != null &&
          objectToArray(rowData.requested_corrections as unknown as TsInterface_UnspecifiedObject).length > 0
        ) {
          let managedCount = 0
          // Get managed Count
          for (let loopCorrectionKey in getProp(rowData, 'requested_corrections', {})) {
            let loopCorrection = getProp(rowData, 'requested_corrections', {})[loopCorrectionKey]
            if (loopCorrection != null && loopCorrection.timestamp_managed != null) {
              managedCount++
            }
          }
          if (managedCount < objectToArray(rowData.requested_corrections as unknown as TsInterface_UnspecifiedObject).length) {
            className = 'tw-text-center tw-bg-error_main'
          } else {
            className = 'tw-text-center tw-bg-success_main'
          }
        } else {
          className = 'tw-text-center tw-bg-success_main'
        }
        return className
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData.requested_corrections != null &&
          objectToArray(rowData.requested_corrections as unknown as TsInterface_UnspecifiedObject).length > 0
        ) {
          let correctionCount = objectToArray(rowData.requested_corrections as unknown as TsInterface_UnspecifiedObject).length
          let managedCount = 0
          // Get managed Count
          for (let loopCorrectionKey in getProp(rowData, 'requested_corrections', {})) {
            let loopCorrection = getProp(rowData, 'requested_corrections', {})[loopCorrectionKey]
            if (loopCorrection != null && loopCorrection.timestamp_managed != null) {
              managedCount++
            }
          }
          let fixedJSX = (
            <>
              {' '}
              ({managedCount} {s_FIXED})
            </>
          )
          let correctionIconJSX = <Icon icon="message-exclamation" />
          if (managedCount < correctionCount) {
            correctionIconJSX = <Icon icon="message-exclamation" />
          } else {
            correctionIconJSX = <Icon icon="thumbs-up" />
          }
          // JSX
          cellJSX = (
            <Tooltip
              placement="top"
              title={
                <>
                  {correctionCount} {s_REQUESTED_CORRECTIONS} {fixedJSX}
                </>
              }
            >
              <Box sx={{ fontSize: '16px' }}>{correctionIconJSX}</Box>
            </Tooltip>
          )
        } else {
          cellJSX = (
            <Tooltip
              placement="top"
              title={s_NO_REQUESTED_CORRECTIONS}
            >
              <Box sx={{ fontSize: '16px' }}>
                <Icon icon="thumbs-up" />
              </Box>
            </Tooltip>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
  },
  total_hours_admin_time: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // let submittedValueJSX = <></>
        let analysisValueJSX = <></>
        // Evaluation Value
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.adminTimesheetEvaluationResults != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['admin_time'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['admin_time'][
            'total_minutes'
          ] != null &&
          parseFloat(
            tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['admin_time'][
              'total_minutes'
            ],
          ) > 0
        ) {
          // let backgroundColor = themeVariables.success_main
          // if(
          // 	tableAdditionalData.adminTimesheetEvaluationResults["formatted_data"]["driver_punches"][ rowData.key as string ]["breakdowns"]["admin_time"]["total_minutes"] !== rowData.timesheet_total_minutes_admin_time
          // ){
          // 	backgroundColor = themeVariables.warning_main
          // }
          analysisValueJSX = (
            <Box>
              <Box
                className="tw-m-1 tw-px-1 tw-rounded tw-inline-block"
                sx={{ background: themeVariables.info_main, fontSize: '16px' }}
              >
                {convertToTimeFormat(
                  tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['admin_time'][
                    'total_minutes'
                  ],
                )}
              </Box>
            </Box>
          )
        } else {
          analysisValueJSX = (
            <Box
              className="tw-m-1 tw-px-1 tw-rounded tw-inline-block tw-opacity-30"
              sx={{ background: themeVariables.gray_700, fontSize: '16px' }}
            >
              {convertToTimeFormat(0)}
            </Box>
          )
        }
        // Cell JSX
        cellJSX = (
          <Box>
            {/* { submittedValueJSX } */}
            {analysisValueJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_ADMIN_TIME
      },
      header_sort_by: null,
    },
  },
  total_hours_break: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // let submittedValueJSX = <></>
        let analysisValueJSX = <></>
        // Evaluation Value
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.adminTimesheetEvaluationResults != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['break'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['break'][
            'total_minutes'
          ] != null &&
          parseFloat(
            tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['break'][
              'total_minutes'
            ],
          ) > 0
        ) {
          // let backgroundColor = themeVariables.success_main
          // if(
          // 	tableAdditionalData.adminTimesheetEvaluationResults["formatted_data"]["driver_punches"][ rowData.key as string ]["breakdowns"]["break"]["total_minutes"] !== rowData.timesheet_total_minutes_break
          // ){
          // 	backgroundColor = themeVariables.warning_main
          // }
          analysisValueJSX = (
            <Box>
              <Box
                className="tw-m-1 tw-px-1 tw-rounded tw-inline-block"
                sx={{ background: themeVariables.info_main, fontSize: '16px' }}
              >
                {convertToTimeFormat(
                  tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['break'][
                    'total_minutes'
                  ],
                )}
              </Box>
            </Box>
          )
        } else {
          analysisValueJSX = (
            <Box
              className="tw-m-1 tw-px-1 tw-rounded tw-inline-block tw-opacity-30"
              sx={{ background: themeVariables.gray_700, fontSize: '16px' }}
            >
              {convertToTimeFormat(0)}
            </Box>
          )
        }
        // Cell JSX
        cellJSX = (
          <Box>
            {/* { submittedValueJSX } */}
            {analysisValueJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_BREAK
      },
      header_sort_by: null,
    },
  },
  total_hours_field_work: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // let submittedValueJSX = <></>
        let analysisValueJSX = <></>
        // Evaluation Value
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.adminTimesheetEvaluationResults != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['field_work'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['field_work'][
            'total_minutes'
          ] != null &&
          parseFloat(
            tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['field_work'][
              'total_minutes'
            ],
          ) > 0
        ) {
          // let backgroundColor = themeVariables.success_main
          // if(
          // 	tableAdditionalData.adminTimesheetEvaluationResults["formatted_data"]["driver_punches"][ rowData.key as string ]["breakdowns"]["field_work"]["total_minutes"] !== rowData.timesheet_total_minutes_field_work
          // ){
          // 	backgroundColor = themeVariables.warning_main
          // }
          analysisValueJSX = (
            <Box>
              <Box
                className="tw-m-1 tw-px-1 tw-rounded tw-inline-block"
                sx={{ background: themeVariables.info_main, fontSize: '16px' }}
              >
                {convertToTimeFormat(
                  tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['field_work'][
                    'total_minutes'
                  ],
                )}
              </Box>
            </Box>
          )
        } else {
          analysisValueJSX = (
            <Box
              className="tw-m-1 tw-px-1 tw-rounded tw-inline-block tw-opacity-30"
              sx={{ background: themeVariables.gray_700, fontSize: '16px' }}
            >
              {convertToTimeFormat(0)}
            </Box>
          )
        }
        // Cell JSX
        cellJSX = (
          <Box>
            {/* { submittedValueJSX } */}
            {analysisValueJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_FIELD_WORK
      },
      header_sort_by: null,
    },
  },
  total_hours_non_working_time: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // let submittedValueJSX = <></>
        let analysisValueJSX = <></>
        // Evaluation Value
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.adminTimesheetEvaluationResults != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['non_working_time'] !=
            null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['non_working_time'][
            'total_minutes'
          ] != null &&
          parseFloat(
            tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns']['non_working_time'][
              'total_minutes'
            ],
          ) > 0
        ) {
          // let backgroundColor = themeVariables.success_main
          // if(
          // 	tableAdditionalData.adminTimesheetEvaluationResults["formatted_data"]["driver_punches"][ rowData.key as string ]["breakdowns"]["non_working_time"]["total_minutes"] !== rowData.timesheet_total_minutes_non_working_time
          // ){
          // 	backgroundColor = themeVariables.warning_main
          // }
          analysisValueJSX = (
            <Box>
              <Box
                className="tw-m-1 tw-px-1 tw-rounded tw-inline-block"
                sx={{ background: themeVariables.info_main, fontSize: '16px' }}
              >
                {convertToTimeFormat(
                  tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['breakdowns'][
                    'non_working_time'
                  ]['total_minutes'],
                )}
              </Box>
            </Box>
          )
        } else {
          analysisValueJSX = (
            <Box
              className="tw-m-1 tw-px-1 tw-rounded tw-inline-block tw-opacity-30"
              sx={{ background: themeVariables.gray_700, fontSize: '16px' }}
            >
              {convertToTimeFormat(0)}
            </Box>
          )
        }
        // Cell JSX
        cellJSX = (
          <Box>
            {/* { submittedValueJSX } */}
            {analysisValueJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_NON_WORKING_TIME
      },
      header_sort_by: null,
    },
  },
  total_hours: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        // let submittedValueJSX = <></>
        let analysisValueJSX = <></>
        // Evaluation Value
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.adminTimesheetEvaluationResults != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['totals'] != null &&
          tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['totals']['total_minutes'] != null &&
          parseFloat(
            tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['totals']['total_minutes'],
          ) > 0
        ) {
          // let backgroundColor = themeVariables.success_main
          // if(
          // 	tableAdditionalData.adminTimesheetEvaluationResults["formatted_data"]["driver_punches"][ rowData.key as string ]["totals"]["total_minutes"] !== rowData.timesheet_total_minutes
          // ){
          // 	backgroundColor = themeVariables.warning_main
          // }
          analysisValueJSX = (
            <Box>
              <Box
                className="tw-m-1 tw-px-1 tw-rounded tw-inline-block"
                sx={{ background: themeVariables.info_main, fontSize: '16px' }}
              >
                {convertToTimeFormat(
                  tableAdditionalData.adminTimesheetEvaluationResults['formatted_data']['driver_punches'][rowData.key as string]['totals']['total_minutes'],
                )}
              </Box>
            </Box>
          )
        } else {
          analysisValueJSX = (
            <Box
              className="tw-m-1 tw-px-1 tw-rounded tw-inline-block tw-opacity-30"
              sx={{ background: themeVariables.gray_700, fontSize: '16px' }}
            >
              {convertToTimeFormat(0)}
            </Box>
          )
        }
        // Cell JSX
        cellJSX = (
          <Box>
            {/* { submittedValueJSX } */}
            {analysisValueJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_TOTAL
      },
      header_sort_by: null,
    },
  },
  timestamp_submitted: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let className: string = ''
        if (rowData != null && rowData.timestamp_submitted != null) {
          className = 'tw-text-center tw-bg-info_main'
        } else {
          // Nothing
        }
        return className
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.timestamp_submitted != null) {
          cellJSX = (
            <Box>
              <Box>
                {/* @ts-expect-error  - TODO: reason */}
                {returnFormattedDate(rowData.timestamp_submitted as Date, 'D MMM YYYY - h:mm a')}
              </Box>
              <Box>{rowData.associated_submitter_name}</Box>
            </Box>
          )
        } else {
          cellJSX = <Box className="tw-opacity-40 tw-italic">{s_NOT_SUBMITTED}</Box>
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{s_SUBMISSION}</>
      },
      header_sort_by: null,
    },
  },
  timestamp_approved: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let className: string = ''
        if (rowData != null && rowData.timestamp_approved != null) {
          className = 'tw-text-center tw-bg-success_main'
        } else {
          // Nothing
        }
        return className
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.timestamp_approved != null) {
          cellJSX = (
            <Box>
              <Box>
                {/* @ts-expect-error  - TODO: reason */}
                {returnFormattedDate(rowData.timestamp_approved as Date, 'D MMM YYYY - h:mm a')}
              </Box>
              <Box className="tw-opacity-50 tw-italic">{rowData.associated_approver_name}</Box>
            </Box>
          )
        } else {
          cellJSX = <Box className="tw-opacity-40 tw-italic">{s_NOT_APPROVED}</Box>
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{s_APPROVAL}</>
      },
      header_sort_by: null,
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

function getStartAndEndOfWeek(date: Date) {
  const startOfWeek = new Date(date)
  startOfWeek.setHours(0, 0, 0, 0)
  startOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 0)
  const endOfWeek = new Date(date)
  endOfWeek.setHours(23, 59, 59, 999)
  endOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 6)
  return {
    startOfWeek,
    endOfWeek,
  }
}

function findUserIndex(usersArray: TsInterface_UnspecifiedObject[], userKey: string): number {
  for (let i = 0; i < usersArray.length; i++) {
    if (usersArray[i].key === userKey) {
      return i
    }
  }
  return -1
}

const downloadWeekPanelPay = (clientKey: string, weekStartDate: Date, weekEndDate: Date): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // Instantiate Variables
    let promiseArray1: TsType_UnknownPromise[] = []
    let promiseArray2: TsType_UnknownPromise[] = []
    let reportConfig: TsInterface_UnspecifiedObject = {}
    let projects: TsInterface_UnspecifiedObject = {}
    let projectsWithOverrideDate: TsInterface_UnspecifiedObject = {}
    let tasks: TsInterface_UnspecifiedObject = {}
    let installTaskKeyArray: string[] = []
    // Load data
    promiseArray1.push(
      DatabaseGetDocument(DatabaseRef_PanelPayReport_Document(clientKey))
        .then((res_DGD) => {
          reportConfig = res_DGD.data
          installTaskKeyArray = getProp(reportConfig, 'install_task_keys_array', [])
        })
        .catch((rej_DGD) => {
          console.error(rej_DGD)
        }),
    )
    promiseArray1.push(
      DatabaseGetCollection(DatabaseRef_ProjectsByFICDate_Query(clientKey, weekStartDate, weekEndDate))
        .then((res_DGC) => {
          projects = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    promiseArray1.push(
      DatabaseGetCollection(DatabaseRef_ProjectsByFICOverrideDate_Query(clientKey, weekStartDate, weekEndDate))
        .then((res_DGC) => {
          projectsWithOverrideDate = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )
    // After Initial Data Loaded
    Promise.all(promiseArray1).finally(() => {
      // Consolidate Projects
      let consolidatedProjects: TsInterface_UnspecifiedObject = {}
      for (let loopProjectKey in projects) {
        let loopProject = projects[loopProjectKey]
        if (loopProject['timestamp_fic_override_date'] == null) {
          consolidatedProjects[loopProjectKey] = loopProject
        }
      }
      for (let loopProjectKey in projectsWithOverrideDate) {
        let loopProject = projectsWithOverrideDate[loopProjectKey]
        loopProject.timestamp_construction_complete = loopProject.timestamp_fic_override_date
        consolidatedProjects[loopProjectKey] = loopProject
      }
      // Generate Report
      if (installTaskKeyArray.length) {
        // Loop through consolidatedProjects
        for (let loopProjectKey in consolidatedProjects) {
          promiseArray2.push(
            DatabaseGetCollection(DatabaseRef_TaskForSpecificProjectAndTaskKey_Query(clientKey, loopProjectKey, installTaskKeyArray))
              .then((res_DGC) => {
                tasks[loopProjectKey] = res_DGC.data
              })
              .catch((rej_DGC) => {
                console.error(rej_DGC)
              }),
          )
        }
        // After Task Data is loaded
        Promise.all(promiseArray2).finally(() => {
          // Instatiate Report Variables
          let reportData: any[][] = []
          let reportHeaders = [
            'Panel Pay Week', // Monday M/D/YYYY
            'Install Start', // M/D/YYYY for first scheduled date
            'FIC Date', // M/D/YYYY, timestamp_fic_date - UPDATED TO timestamp_construction_complete
            'Job Code', // project.id_number
            'Zip', // project.location_zip
            'Region', // project.associated_region_name
            'Roof Pitch', // project.system_max_roof_pitch
            'Has Pitch Adder', // Yes if >= 35, otherwise No
            'Distance From Warehouse', // project.location_distance_from_warehouse
            'Has Distance Adder', // Each region has a threshold, if over threshold, then Yes, otherwise No
            'Batteries', // project.system_storage_quantity + project.system_storage_manufacturer + project.system_storage_model
            'Has Battery Adder', // Yes if there is a battery
            'Roof Type', // ADDITIONAL SALESFORCE DATA - home_roof_type
            'Number of Panels', // project.system_panel_quantity
            'Utility', // project.associated_utility_company_name,
            'CSS Owner', // project.associated_css_rep_name,
            'Crew/BP', // project.associated_team_name,
            'Foreman', // get from crew info - last day
            'Lead',
            'Installer1',
            'Installer2',
            'Other Crew Members', // get from crew info - any not on last day or duplicated foreman or lead
            'Crew Size',
            'System Size', // project.system_size_dc
            'Using FIC Override Date',
            'Errors', // Multiple Install tasks, missing install task, crew changes, missing distance (other fields for adders)
          ]
          reportData.push(reportHeaders)
          // oop through and generate data rows
          for (let loopProjectKey in consolidatedProjects) {
            let loopProject = consolidatedProjects[loopProjectKey]
            let loopProjectTasks = getProp(tasks, loopProjectKey, {})
            let installTask: TsInterface_UnspecifiedObject = {}
            let errorsArray: string[] = []
            let installTaskCount = objectToArray(loopProjectTasks).length
            if (installTaskCount === 0) {
              errorsArray.push('MISSING INSTALL TASKS')
            } else if (installTaskCount === 1) {
              installTask = objectToArray(loopProjectTasks)[0]
            } else if (installTaskCount > 1) {
              errorsArray.push('MULTIPLE INSTALL TASKS')
              installTask = objectToArray(loopProjectTasks)[0]
            }
            let projectDataRow: any[] = []
            projectDataRow.push(returnFormattedDate(weekStartDate, 'M/D/YYYY'))
            let scheduledDatesArray = getProp(installTask, 'task_completion_scheduled_dates', null)
            if (scheduledDatesArray != null && scheduledDatesArray.length > 0) {
              let firstScheduledDate = scheduledDatesArray[0]
              let firstScheduledDateObject = new Date(firstScheduledDate)
              let correctedScheduledDate = new Date(
                firstScheduledDateObject.getFullYear(),
                firstScheduledDateObject.getMonth(),
                firstScheduledDateObject.getDate(),
                firstScheduledDateObject.getHours() + firstScheduledDateObject.getTimezoneOffset() / 60,
              )
              let firstScheduledDateFormatted = returnFormattedDate(correctedScheduledDate, 'M/D/YYYY')
              projectDataRow.push(firstScheduledDateFormatted)
            } else {
              errorsArray.push('MISSING SCHEDULED DATES')
              projectDataRow.push('')
            }
            // projectDataRow.push( returnFormattedDate( loopProject.timestamp_fic_date, "M/D/YYYY" ) )
            projectDataRow.push(returnFormattedDate(loopProject.timestamp_construction_complete, 'M/D/YYYY'))
            projectDataRow.push(getProp(loopProject, 'id_number', ''))
            projectDataRow.push(getProp(loopProject, 'location_zip', ''))
            if (getProp(loopProject, 'associated_region_key', null) == null || getProp(loopProject, 'associated_region_name', null) == null) {
              errorsArray.push('PROJECT NOT ASSIGNED TO A REGION')
            }
            projectDataRow.push(getProp(loopProject, 'associated_region_name', ''))
            if (getProp(loopProject, 'system_max_roof_pitch', null) == null) {
              errorsArray.push('MISSING ROOF PITCH')
            }
            projectDataRow.push(getProp(loopProject, 'system_max_roof_pitch', ''))
            if (getProp(loopProject, 'system_max_roof_pitch', null) != null) {
              if (getProp(loopProject, 'system_max_roof_pitch', null) >= 35) {
                projectDataRow.push('Yes')
              } else {
                projectDataRow.push('No')
              }
            } else {
              projectDataRow.push('')
              errorsArray.push('MISSING ROOF PITCH')
            }
            projectDataRow.push(getProp(loopProject, 'location_distance_from_warehouse', ''))
            if (getProp(loopProject, 'location_distance_from_warehouse', null) == null) {
              errorsArray.push('MISSING DISTANCE FROM WAREHOUSE')
              projectDataRow.push('UNKNOWN')
            } else {
              let distanceFromWarehouse = parseFloat(getProp(loopProject, 'location_distance_from_warehouse', 0))
              if (
                getProp(loopProject, 'associated_region_key', null) != null &&
                reportConfig != null &&
                reportConfig['regions'] != null &&
                reportConfig['regions'][getProp(loopProject, 'associated_region_key', null)] != null &&
                reportConfig['regions'][getProp(loopProject, 'associated_region_key', null)]['distance_threshold'] != null
              ) {
                if (distanceFromWarehouse >= reportConfig['regions'][getProp(loopProject, 'associated_region_key', null)]['distance_threshold']) {
                  projectDataRow.push('Yes')
                } else {
                  projectDataRow.push('No')
                }
              } else {
                projectDataRow.push('UNKNOWN')
                if (getProp(loopProject, 'associated_region_key', null) != null) {
                  errorsArray.push('MISSING DISTANCE THRESHOLD FOR REGION - TALK TO JOHN')
                }
              }
            }
            projectDataRow.push(
              getProp(loopProject, 'system_storage_quantity', '') +
                ' ' +
                getProp(loopProject, 'system_storage_manufacturer', '') +
                ' ' +
                getProp(loopProject, 'system_storage_model', ''),
            )
            if (getProp(loopProject, 'system_storage_quantity', 0) > 0) {
              projectDataRow.push('Yes')
            } else {
              projectDataRow.push('No')
            }
            projectDataRow.push(getProp(loopProject, 'home_roof_type', ''))
            if (getProp(loopProject, 'system_panel_quantity', null) == null) {
              errorsArray.push('MISSING NUMBER OF PANELS')
            }
            projectDataRow.push(getProp(loopProject, 'system_panel_quantity', ''))
            projectDataRow.push(getProp(loopProject, 'associated_utility_company_name', ''))
            projectDataRow.push(getProp(loopProject, 'associated_css_rep_name', ''))
            projectDataRow.push(getProp(installTask, 'associated_team_name', ''))
            // Loop through and determine crew composition
            let crewAssignments: TsInterface_UnspecifiedObject = {
              install_lead: {},
              install_foreman: {},
              installer: {},
            }
            let usersAssignedToTask: TsInterface_UnspecifiedObject = {}
            let usersAssignedToTaskWithRoles: TsInterface_UnspecifiedObject = {}
            let usersAssignedToTaskWithoutRoles: TsInterface_UnspecifiedObject = {}
            // Loop through and get all users assigned to task
            for (let loopDateKey in getProp(installTask, 'task_completion_scheduled_team_names', {})) {
              let loopDate = getProp(installTask, 'task_completion_scheduled_team_names', {})[loopDateKey]
              for (let loopUserKey in loopDate) {
                let loopUserName = loopDate[loopUserKey]
                usersAssignedToTask[loopUserKey] = loopUserName
              }
            }
            // Loop through and find users assigned to role
            for (let loopDateKey in getProp(installTask, 'task_completion_scheduled_team_roles', {})) {
              let loopDate = getProp(installTask, 'task_completion_scheduled_team_roles', {})[loopDateKey]
              for (let loopUserKey in loopDate) {
                let loopUserRole = loopDate[loopUserKey]
                if (crewAssignments[loopUserRole] != null) {
                  crewAssignments[loopUserRole][loopUserKey] = getProp(usersAssignedToTask, loopUserKey, 'MISSING NAME')
                  usersAssignedToTaskWithRoles[loopUserKey] = getProp(usersAssignedToTask, loopUserKey, 'MISSING NAME')
                } else {
                  errorsArray.push(getProp(usersAssignedToTask, loopUserKey, 'USER') + ' ASSIGNED TO ' + loopUserRole + ' ROLE')
                }
              }
            }
            // Loop through and find users not assigned to role
            for (let loopUserKey in usersAssignedToTask) {
              if (usersAssignedToTaskWithRoles[loopUserKey] === 'MISSING NAME') {
                errorsArray.push('CREW MEMBERS MISSING NAMES')
              }
              if (usersAssignedToTaskWithRoles[loopUserKey] == null) {
                usersAssignedToTaskWithoutRoles[loopUserKey] = getProp(usersAssignedToTask, loopUserKey, 'MISSING NAME')
              }
            }
            let foremanName = ''
            let leadName = ''
            let installer1Name = ''
            let installer2Name = ''
            let additionalTeamMembersArray: string[] = []
            let crewSize = 0
            // Foreman
            if (crewAssignments['install_foreman'] != null && objectToArray(crewAssignments['install_foreman']).length === 1) {
              foremanName = objectToArray(crewAssignments['install_foreman'])[0]
              crewSize++
            } else if (crewAssignments['install_foreman'] != null && objectToArray(crewAssignments['install_foreman']).length > 1) {
              foremanName = objectToArray(crewAssignments['install_foreman'])[0]
              crewSize++
              errorsArray.push('MULTIPLE FOREMEN ON CREW')
              for (let loopIndex = 1; loopIndex < objectToArray(crewAssignments['install_foreman']).length; loopIndex++) {
                additionalTeamMembersArray.push(objectToArray(crewAssignments['install_foreman'])[loopIndex])
              }
            } else {
              errorsArray.push('NO FOREMAN ON CREW')
            }
            // Lead
            if (crewAssignments['install_lead'] != null && objectToArray(crewAssignments['install_lead']).length === 1) {
              leadName = objectToArray(crewAssignments['install_lead'])[0]
              crewSize++
            } else if (crewAssignments['install_lead'] != null && objectToArray(crewAssignments['install_lead']).length > 1) {
              leadName = objectToArray(crewAssignments['install_lead'])[0]
              crewSize++
              errorsArray.push('MULTIPLE LEADS ON CREW')
              for (let loopIndex = 1; loopIndex < objectToArray(crewAssignments['install_lead']).length; loopIndex++) {
                additionalTeamMembersArray.push(objectToArray(crewAssignments['install_lead'])[loopIndex])
              }
            } else {
              errorsArray.push('NO LEAD ON CREW')
            }
            // Installers
            if (crewAssignments['installer'] != null && objectToArray(crewAssignments['installer']).length === 1) {
              installer1Name = objectToArray(crewAssignments['installer'])[0]
              crewSize++
            } else if (crewAssignments['installer'] != null && objectToArray(crewAssignments['installer']).length === 2) {
              installer1Name = objectToArray(crewAssignments['installer'])[0]
              installer2Name = objectToArray(crewAssignments['installer'])[1]
              crewSize += 2
            } else if (crewAssignments['installer'] != null && objectToArray(crewAssignments['installer']).length > 2) {
              installer1Name = objectToArray(crewAssignments['installer'])[0]
              installer2Name = objectToArray(crewAssignments['installer'])[1]
              crewSize += 2
              errorsArray.push('MORE THAN 2 INSTALLERS ON CREW')
              for (let loopIndex = 2; loopIndex < objectToArray(crewAssignments['installer']).length; loopIndex++) {
                additionalTeamMembersArray.push(objectToArray(crewAssignments['installer'])[loopIndex])
              }
            } else {
              errorsArray.push('NO INSTALLERS ON CREW')
            }
            projectDataRow.push(foremanName)
            projectDataRow.push(leadName)
            projectDataRow.push(installer1Name)
            projectDataRow.push(installer2Name)
            projectDataRow.push(additionalTeamMembersArray.join(' | '))
            projectDataRow.push(crewSize)
            projectDataRow.push(getProp(loopProject, 'system_size_dc', ''))
            if (getProp(loopProject, 'timestamp_fic_override_date', null) != null) {
              projectDataRow.push('Yes')
            } else {
              projectDataRow.push('')
            }
            projectDataRow.push(errorsArray.join(' | '))
            reportData.push(projectDataRow)
          }
          let fileName = 'Panel Pay (' + returnFormattedDate(weekStartDate, 'D MMM YYYY') + ' to ' + returnFormattedDate(weekEndDate, 'D MMM YYYY') + ').csv'
          downloadCSV(fileName, reportData)
            .then((res_DCSV) => {
              resolve(res_DCSV)
            })
            .catch((rej_DCSV) => {
              reject(rej_DCSV)
            })
        })
      } else {
        reject({
          success: false,
          error: {
            message: s_FAILED_TO_DOWNLOAD_REPORT,
            details: s_MISSING_REQUIRED_CONFIGURATIONS,
            code: 'ER-D-TAR-DWPP-01',
          },
        })
      }
    })
  })
}

///////////////////////////////
// JSX Exports
///////////////////////////////

export const Tab: React.FC<TabProps> = ({ selectedDate, setSelectedDate }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeHourlyUsers, us_setActiveHourlyUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_adminTimesheetEvaluationResults, us_setAdminTimesheetEvaluationResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_aggregatePunchData, us_setAggregatePunchData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_analysisCancelledTasks, us_setAnalysisCancelledTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_analysisDefaultAllocations, us_setAnalysisDefaultAllocations] = useState<TsInterface_UnspecifiedObject>({})
  const [us_analysisOverrideAllocations, us_setAnalysisOverrideAllocations] = useState<TsInterface_UnspecifiedObject>({})
  const [us_analysisRawPunchData, us_setAnalysisRawPunchData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_analysisTasks, us_setAnalysisTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_combinedUserTimesheetData, us_setCombinedUserTimesheetData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_downloadingData, us_setDownloadingData] = useState<boolean>(false)
  const [us_rawPunchData, us_setRawPunchData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedIndividualKey, us_setSelectedIndividualKey] = useState<string | null>(null)
  const [us_selectedViewType, us_setSelectedViewType] = useState<'all' | 'individual'>('all')
  const [us_showLiveTotals, us_setShowLiveTotals] = useState<boolean>(true)
  const [us_tableViewMode, us_setTableViewMode] = useState<'aggregated' | 'raw'>('raw')
  const [us_weekEndDate, us_setWeekEndDate] = useState<Date | null>(null)
  const [us_weekStartDate, us_setWeekStartDate] = useState<Date | null>(null)
  const [us_weekTimesheets, us_setWeekTimesheets] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    us_setShowLiveTotals(true)
    return () => {}
  }, [])

  useEffect(() => {
    let weekBoundingDates = getStartAndEndOfWeek(selectedDate)
    us_setWeekStartDate(weekBoundingDates.startOfWeek)
    us_setWeekEndDate(weekBoundingDates.endOfWeek)
    return () => {}
  }, [selectedDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setWeekTimesheets(newData)
      } else {
        us_setWeekTimesheets({})
      }
      ur_forceRerender()
    }
    if (us_weekStartDate != null) {
      let dateKey = returnFormattedDateKey(us_weekStartDate)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TimeSheets_SpecificDate_Query(res_GCK.clientKey, dateKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekStartDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setActiveHourlyUsers(newData)
      } else {
        us_setActiveHourlyUsers({})
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TimecardHourlyUsers_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekStartDate])

  useEffect(() => {
    if (us_selectedViewType != null && us_selectedIndividualKey != null) {
      // Refresh Aggregate Punch Data every 10 seconds
      us_setAggregatePunchData(aggregateTimesheetPunchData(objectToArray(cleanPunchDataBeforeEvaluation(us_rawPunchData))))
      const interval = setInterval(() => {
        us_setAggregatePunchData(aggregateTimesheetPunchData(objectToArray(cleanPunchDataBeforeEvaluation(us_rawPunchData))))
      }, 10000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [us_rawPunchData, us_selectedViewType, us_selectedIndividualKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRawPunchData(newData)
      ur_forceRerender()
    }
    if (us_selectedViewType != null && us_selectedIndividualKey != null && us_weekStartDate != null && us_weekEndDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_TimePunches_UserDateRange_Query(res_GCK.clientKey, us_selectedIndividualKey, us_weekStartDate, us_weekEndDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekStartDate, us_weekEndDate, us_selectedIndividualKey, us_selectedViewType])

  useEffect(() => {
    let newCombinedUserTimesheetData: TsInterface_UnspecifiedObject = {}
    for (let loopUserKey in us_activeHourlyUsers) {
      let loopUser = us_activeHourlyUsers[loopUserKey]
      if (
        loopUserKey != null &&
        us_weekStartDate != null &&
        us_weekTimesheets != null &&
        us_weekTimesheets[loopUserKey + '_' + returnFormattedDateKey(us_weekStartDate)] != null
      ) {
        let userTimesheet = us_weekTimesheets[loopUserKey + '_' + returnFormattedDateKey(us_weekStartDate)]
        let userTimesheetHours = getProp(userTimesheet, 'total_hours', {})
        newCombinedUserTimesheetData[loopUserKey] = {
          // User
          name: getProp(loopUser, 'name', ''),
          email: getProp(loopUser, 'email', ''),
          phone: getProp(loopUser, 'phone', ''),
          key: loopUserKey,
          id_number_payroll: getProp(loopUser, 'id_number_payroll', ''),
          id_organization_payroll: getProp(loopUser, 'id_organization_payroll', ''),
          hourly_or_salaried: getProp(loopUser, 'hourly_or_salaried', ''),
          // Time sheet
          temp_has_timesheet_data: true,
          timesheet_key: loopUserKey + '_' + returnFormattedDateKey(us_weekStartDate),
          timesheet_total_minutes:
            (getProp(userTimesheetHours, 'admin_time', 0) +
              getProp(userTimesheetHours, 'break', 0) +
              getProp(userTimesheetHours, 'field_work', 0) +
              getProp(userTimesheetHours, 'non_working_time', 0)) *
            60,
          timesheet_total_minutes_admin_time: getProp(userTimesheetHours, 'admin_time', 0) * 60,
          timesheet_total_minutes_break: getProp(userTimesheetHours, 'break', 0) * 60,
          timesheet_total_minutes_field_work: getProp(userTimesheetHours, 'field_work', 0) * 60,
          timesheet_total_minutes_non_working_time: getProp(userTimesheetHours, 'non_working_time', 0) * 60,
          requested_corrections: getProp(userTimesheet, 'requested_corrections', {}),
          timestamp_approved: getProp(userTimesheet, 'timestamp_approved', null),
          associated_approver_name: getProp(userTimesheet, 'associated_approver_name', null),
          timestamp_submitted: getProp(userTimesheet, 'timestamp_submitted', null),
        }
      } else if (loopUserKey != null && us_weekStartDate != null) {
        newCombinedUserTimesheetData[loopUserKey] = {
          // User
          name: getProp(loopUser, 'name', ''),
          email: getProp(loopUser, 'email', ''),
          phone: getProp(loopUser, 'phone', ''),
          key: loopUserKey,
          id_number_payroll: getProp(loopUser, 'id_number_payroll', ''),
          id_organization_payroll: getProp(loopUser, 'id_organization_payroll', ''),
          hourly_or_salaried: getProp(loopUser, 'hourly_or_salaried', ''),
          // Time sheet
          temp_has_timesheet_data: false,
          timesheet_key: loopUserKey + '_' + returnFormattedDateKey(us_weekStartDate),
          timesheet_total_minutes: null,
          timesheet_total_minutes_admin_time: null,
          timesheet_total_minutes_break: null,
          timesheet_total_minutes_field_work: null,
          timesheet_total_minutes_non_working_time: null,
          requested_corrections: null,
          timestamp_approved: null,
          associated_approver_name: null,
          timestamp_submitted: null,
        }
      }
    }
    us_setCombinedUserTimesheetData(newCombinedUserTimesheetData)
  }, [ur_forceRerender, us_weekStartDate, us_weekTimesheets, us_activeHourlyUsers])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setWeekTimesheets(newData)
      } else {
        us_setWeekTimesheets({})
      }
      ur_forceRerender()
    }
    if (us_weekStartDate != null) {
      let dateKey = returnFormattedDateKey(us_weekStartDate)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TimeSheets_SpecificDate_Query(res_GCK.clientKey, dateKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekStartDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setAnalysisRawPunchData(newData)
      } else {
        us_setAnalysisRawPunchData({})
      }
      ur_forceRerender()
    }
    if (us_showLiveTotals === true && us_weekStartDate != null && us_weekEndDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_TimePunches_DateRange_Query(res_GCK.clientKey, us_weekStartDate, us_weekEndDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekStartDate, us_weekEndDate, us_showLiveTotals])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setAnalysisDefaultAllocations(newData)
      } else {
        us_setAnalysisDefaultAllocations({})
      }
      ur_forceRerender()
    }
    if (us_showLiveTotals === true) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_DefaultAllocatedTime_Collection(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_showLiveTotals])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setAnalysisOverrideAllocations(newData)
      } else {
        us_setAnalysisOverrideAllocations({})
      }
      ur_forceRerender()
    }
    if (us_showLiveTotals === true && us_weekStartDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_OverrideAllocatedTime_SpecificDate_Query(res_GCK.clientKey, returnFormattedDateKey(us_weekStartDate)),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekStartDate, us_showLiveTotals])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setAnalysisTasks(newData)
      } else {
        us_setAnalysisTasks({})
      }
      ur_forceRerender()
    }
    if (us_showLiveTotals === true && us_weekStartDate != null && us_weekEndDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksInDateRange_Query(res_GCK.clientKey, us_weekStartDate, us_weekEndDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekStartDate, us_weekEndDate, us_showLiveTotals])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setAnalysisCancelledTasks(newData)
      } else {
        us_setAnalysisCancelledTasks({})
      }
      ur_forceRerender()
    }
    if (us_showLiveTotals === true && us_weekStartDate != null && us_weekEndDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_CancelledScheduledTasksInDateRange_Query(res_GCK.clientKey, us_weekStartDate, us_weekEndDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekStartDate, us_weekEndDate, us_showLiveTotals])

  useEffect(() => {
    refreshTimeSheetAdminEvaluation(
      us_analysisRawPunchData,
      us_analysisDefaultAllocations,
      us_analysisOverrideAllocations,
      us_weekTimesheets,
      us_analysisTasks,
      us_analysisCancelledTasks,
      us_activeHourlyUsers,
    )
      .then((res_RTSAE) => {
        // @ts-expect-error - TODO: reason for error
        us_setAdminTimesheetEvaluationResults(res_RTSAE.data)
      })
      .catch((rej_RTSAE) => {
        console.error(rej_RTSAE)

        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_RTSAE.error })
      })
    return () => {}
  }, [
    us_analysisRawPunchData,
    us_analysisDefaultAllocations,
    us_analysisOverrideAllocations,
    us_weekTimesheets,
    us_analysisTasks,
    us_analysisCancelledTasks,
    uc_setUserInterface_ErrorDialogDisplay,
    us_activeHourlyUsers,
  ])

  // Other Variables
  const weekRowEditActions: TsInterface_TableManageActionsObject = {
    // Nothing
  }

  const eventRowEditActions: TsInterface_TableManageActionsObject = {
    // TODO - maybe just have edit and delete in rawRowEditActions for admin users
  }

  const rawRowEditActions: TsInterface_TableManageActionsObject = {
    view_logs: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: s_VIEW_LOGS,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            let promiseArray: TsType_UnknownPromise[] = []
            let timePunch: TsInterface_UnspecifiedObject = {}
            let timePunchHistory: TsInterface_UnspecifiedObject = {}
            promiseArray.push(
              DatabaseGetDocument(DatabaseRef_TimePunches_Document(res_GCK.clientKey, rowData.key as string)).then((res_DGD) => {
                timePunch = res_DGD.data
              }),
            )
            promiseArray.push(
              DatabaseGetCollection(DatabaseRef_TimePunchesHistory_Collection(res_GCK.clientKey, rowData.key as string)).then((res_DGC) => {
                timePunchHistory = res_DGC.data
              }),
            )
            Promise.all(promiseArray).finally(() => {
              let dialogJSX = (
                <Box>
                  <Dialog
                    // TransitionComponent={ Transition }
                    className="bp_dialog_xl_width"
                    keepMounted
                    onClose={() => {
                      tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                    }}
                    open={true}
                  >
                    <AppBar
                      position="static"
                      color="inherit"
                    >
                      <Toolbar>
                        <IconButton
                          aria-label="menu"
                          color="inherit"
                          disabled
                          edge="start"
                          size="large"
                          sx={{ mr: 2, color: '#fff !important' }}
                        >
                          <Icon icon="list-timeline" />
                        </IconButton>
                        <Typography
                          component={'span'}
                          variant={'h6'}
                          sx={{ flexGrow: 1 }}
                        >
                          <Box className="tw-inline-block">{s_TIME_PUNCH_LOG_HISTORY}</Box>
                        </Typography>
                      </Toolbar>
                    </AppBar>
                    <DialogContent sx={{ padding: '0px' }}>
                      <Typography
                        variant="h6"
                        className="tw-px-4 tw-pt-1"
                      >
                        {s_CURRENT}
                      </Typography>
                      <Json
                        data={timePunch}
                        alphabetized={true}
                      />
                      <Typography
                        variant="h6"
                        className="tw-px-4"
                      >
                        {s_HISTORY}
                      </Typography>
                      <Json
                        data={timePunchHistory}
                        alphabetized={true}
                      />
                    </DialogContent>
                  </Dialog>
                </Box>
              )
              // Open Dialog
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: dialogJSX,
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            })
          })
          .catch((rej_GCK) => {
            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      },
    },
    edit: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: s_EDIT,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // JSX
        let dialogJSX = (
          <Box>
            <Dialog
              // TransitionComponent={ Transition }
              className="bp_dialog_xl_width"
              keepMounted
              onClose={() => {
                tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              }}
              open={true}
            >
              <AppBar
                position="static"
                color="inherit"
              >
                <Toolbar>
                  <IconButton
                    aria-label="menu"
                    color="inherit"
                    disabled
                    edge="start"
                    size="large"
                    sx={{ mr: 2, color: '#fff !important' }}
                  >
                    <Icon icon="pen-to-square" />
                  </IconButton>
                  <Typography
                    component={'span'}
                    variant={'h6'}
                    sx={{ flexGrow: 1 }}
                  >
                    <Box className="tw-inline-block">
                      {s_EDIT_TIME_PUNCH} - {rowData.associated_user_name}
                    </Box>
                  </Typography>
                </Toolbar>
              </AppBar>
              <DialogContent sx={{ padding: '0px' }}>
                <TimesheetPunchEdit
                  type="edit"
                  userKey={us_selectedIndividualKey}
                  punchData={rowData}
                  startDate={us_weekStartDate}
                  endDate={us_weekEndDate}
                />
              </DialogContent>
            </Dialog>
          </Box>
        )
        // Open Dialog
        tableHooks.uc_setUserInterface_CustomDialogDisplay({
          display: true,
          dialog: {
            dialog_jsx: dialogJSX,
            settings: {
              max_width: 'lg',
            },
          },
        })
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: s_DELETE,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: s_DELETE_TIME_PUNCH,
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: s_DELETE,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_TIME_PUNCH,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = { status: 'deleted' }
                    DatabaseSetMergeDocument(DatabaseRef_TimePunches_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        reject(rej_DSMD)
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    reject(rej_GCK)
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              })
            },
          },
        })
      },
    },
    undelete: {
      icon: (
        <Icon
          type="solid"
          icon="trash-can-undo"
        />
      ),
      label: s_UNDELETE,
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'warning',
            header: s_UNDELETE_TIME_PUNCH,
            icon: (
              <Icon
                icon="trash-can-undo"
                type="solid"
              />
            ),
            submit_text: s_UNDELETE,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UNDELETE_THIS_TIME_PUNCH,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateObject: TsInterface_UnspecifiedObject = { status: 'active' }
                    DatabaseSetMergeDocument(DatabaseRef_TimePunches_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                      })
                      .catch((rej_DSMD) => {
                        reject(rej_DSMD)
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    reject(rej_GCK)
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              })
            },
          },
        })
      },
    },
  }

  const otherRowEditActions: TsInterface_TableManageActionsObject = {
    mark_correction_as_managed: {
      icon: (
        <Icon
          type="solid"
          icon="message-check"
        />
      ),
      label: s_MARK_CORRECTION_AS_MANAGED,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'success',
            header: s_MARK_CORRECTION_AS_MANAGED,
            icon: (
              <Icon
                icon="message-check"
                type="solid"
              />
            ),
            submit_text: s_UPDATE,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_MARK_THIS_CORRECTION_AS_MANAGED,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let requestedCorrections = getProp(tableAdditionalData, 'submissionData', {})
                    let updateObject: TsInterface_UnspecifiedObject = {
                      requested_corrections: getProp(requestedCorrections, 'requested_corrections', {}),
                    }
                    let selectedDateKey = returnFormattedDateKey(getProp(rowData, 'date', null))
                    let timesheetKey = tableAdditionalData.userKey + '_' + tableAdditionalData.startDateKey
                    updateObject['requested_corrections'][selectedDateKey]['timestamp_managed'] = new Date()
                    updateObject['requested_corrections'][selectedDateKey]['timestamp_managed_by_name'] = getProp(
                      tableHooks.uc_RootData_ClientUser,
                      'name',
                      null,
                    )
                    DatabaseUpdateDocument(DatabaseRef_TimeSheets_Document(res_GCK.clientKey, timesheetKey), updateObject)
                      .then((res_DUD) => {
                        resolve(res_DUD)
                      })
                      .catch((rej_DUD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                        reject(rej_DUD)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      },
    },
    mark_correction_as_unmanaged: {
      icon: (
        <Icon
          type="solid"
          icon="message-xmark"
        />
      ),
      label: s_MARK_CORRECTION_AS_NOT_MANAGED,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'warning',
            header: s_MARK_CORRECTION_AS_NOT_MANAGED,
            icon: (
              <Icon
                icon="message-xmark"
                type="solid"
              />
            ),
            submit_text: s_UPDATE,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_MARK_THIS_CORRECTION_AS_NOT_MANAGED,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let requestedCorrections = getProp(tableAdditionalData, 'submissionData', {})
                    let updateObject: TsInterface_UnspecifiedObject = {
                      requested_corrections: getProp(requestedCorrections, 'requested_corrections', {}),
                    }
                    let selectedDateKey = returnFormattedDateKey(getProp(rowData, 'date', null))
                    let timesheetKey = tableAdditionalData.userKey + '_' + tableAdditionalData.startDateKey
                    updateObject['requested_corrections'][selectedDateKey]['timestamp_managed'] = null
                    updateObject['requested_corrections'][selectedDateKey]['timestamp_managed_by_name'] = null
                    DatabaseUpdateDocument(DatabaseRef_TimeSheets_Document(res_GCK.clientKey, timesheetKey), updateObject)
                      .then((res_DUD) => {
                        resolve(res_DUD)
                      })
                      .catch((rej_DUD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                        reject(rej_DUD)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      },
    },
  }

  const createNewPunch = (userKey: string) => {
    let userName: string | JSX.Element = <></>
    if (
      us_activeHourlyUsers != null &&
      us_selectedIndividualKey != null &&
      us_activeHourlyUsers[us_selectedIndividualKey] != null &&
      us_activeHourlyUsers[us_selectedIndividualKey]['name'] != null
    ) {
      userName = us_activeHourlyUsers[us_selectedIndividualKey]['name']
    }
    let dialogJSX = (
      <Box>
        <Dialog
          // TransitionComponent={ Transition }
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="pen-to-square" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                <Box className="tw-inline-block">
                  {s_CREATE_TIME_PUNCH} - {userName}
                </Box>
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            <TimesheetPunchEdit
              type="new"
              userKey={us_selectedIndividualKey}
              punchData={{
                associated_user_key: userKey,
              }}
              startDate={us_weekStartDate}
              endDate={us_weekEndDate}
            />
          </DialogContent>
        </Dialog>
      </Box>
    )
    // Open Dialog
    uc_setUserInterface_CustomDialogDisplay({
      display: true,
      dialog: {
        dialog_jsx: dialogJSX,
        settings: {
          max_width: 'lg',
        },
      },
    })
  }

  // Functions
  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      // let formattedDateKey = returnFormattedDateKey( new Date( newDate ) )
      setSelectedDate(new Date(newDate))
      us_setAdminTimesheetEvaluationResults({})
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const downloadDataWeek = (downloadType: 'payroll' | 'categories' | 'raw') => {
    if (us_weekStartDate != null && us_weekEndDate != null) {
      us_setDownloadingData(true)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          downloadAggregatedPunchData(res_GCK.clientKey, us_weekStartDate, us_weekEndDate, false, uc_setUserInterface_CustomDialogDisplay, downloadType)
            .then((res_DAPD) => {
              us_setDownloadingData(false)
            })
            .catch((rej_DAPD) => {
              us_setDownloadingData(false)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAPD.error })
              console.error(rej_DAPD)
            })
        })
        .catch((rej_GCK) => {
          us_setDownloadingData(false)
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          console.error(rej_GCK)
        })
    }
  }

  const downloadDataDay = (downloadType: 'payroll' | 'categories' | 'raw') => {
    if (selectedDate != null) {
      const startOfDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0)
      const endOfDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1, 0, 0, 0)
      us_setDownloadingData(true)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          downloadAggregatedPunchData(res_GCK.clientKey, startOfDay, endOfDay, false, uc_setUserInterface_CustomDialogDisplay, downloadType)
            .then((res_DAPD) => {
              us_setDownloadingData(false)
            })
            .catch((rej_DAPD) => {
              us_setDownloadingData(false)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAPD.error })
              console.error(rej_DAPD)
            })
        })
        .catch((rej_GCK) => {
          us_setDownloadingData(false)
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          console.error(rej_GCK)
        })
    }
  }

  const downloadDayBreakdownByWeek = () => {
    if (us_weekStartDate != null && us_weekEndDate != null) {
      us_setDownloadingData(true)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          downloadPanelPayReport(res_GCK.clientKey, us_weekStartDate, us_weekEndDate, uc_setUserInterface_CustomDialogDisplay)
            .then((res_DPPR) => {
              us_setDownloadingData(false)
            })
            .catch((rej_DPPR) => {
              us_setDownloadingData(false)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DPPR.error })
              console.error(rej_DPPR)
            })
        })
        .catch((rej_GCK) => {
          us_setDownloadingData(false)
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          console.error(rej_GCK)
        })
    }
  }

  const downloadDayBreakdownByDay = () => {
    if (selectedDate != null) {
      const startOfDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0)
      const endOfDay = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate() + 1, 0, 0, 0)
      us_setDownloadingData(true)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          downloadPanelPayReport(res_GCK.clientKey, startOfDay, endOfDay, uc_setUserInterface_CustomDialogDisplay)
            .then((res_DPPR) => {
              us_setDownloadingData(false)
            })
            .catch((rej_DPPR) => {
              us_setDownloadingData(false)
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DPPR.error })
              console.error(rej_DPPR)
            })
        })
        .catch((rej_GCK) => {
          us_setDownloadingData(false)
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          console.error(rej_GCK)
        })
    }
  }

  // TODO - download panel pay

  // system_panel_quantity
  // system_storage_quantity
  // associated_region_name
  // system_storage_total_kwh
  // location_distance_from_warehouse
  // system_max_roof_pitch
  // system_number_of_arrays
  // system_number_of_strings
  // system_has_attic_run

  // COUNT OF CREW - composition / team makeup

  const tableAdditionalData_TimeSheetReviewUsers: TsInterface_TableAdditionalData = {
    setSelectedIndividualKey: us_setSelectedIndividualKey,
    setSelectedViewType: us_setSelectedViewType,
    adminTimesheetEvaluationResults: us_adminTimesheetEvaluationResults,
  }
  if (us_weekStartDate != null) {
    tableAdditionalData_TimeSheetReviewUsers['dateKey'] = returnFormattedDateKey(us_weekStartDate)
    tableAdditionalData_TimeSheetReviewUsers['weekStartDate'] = us_weekStartDate
  }
  if (us_weekEndDate != null) {
    tableAdditionalData_TimeSheetReviewUsers['weekEndDate'] = us_weekEndDate
  }

  function findNextUserWithErrors(users: TsInterface_UnspecifiedObject, currentUserKey: string, propToCheckAgainst: string) {
    // // Sort the array of objects by the "name" prop
    // users.sort((a, b) => a.name.localeCompare(b.name));
    // Find the current user object
    const currentUser = users.find((user: { key: string }) => user.key === currentUserKey)
    if (!currentUser) {
      return null // Current user not found in the array
    }
    // Find the index of the current user in the sorted array
    const currentUserIndex = users.indexOf(currentUser)
    // Iterate over users starting from the next index
    let nextUserKey = null
    for (let i = currentUserIndex + 1; i < users.length; i++) {
      const user = users[i]
      if (user[propToCheckAgainst] >= 1 && user.key !== currentUserKey && nextUserKey == null) {
        nextUserKey = user.key
      }
    }
    if (nextUserKey == null) {
      for (let i = 0; i < users.length; i++) {
        const user = users[i]
        if (user[propToCheckAgainst] >= 1 && user.key !== currentUserKey && nextUserKey == null) {
          nextUserKey = user.key
        }
      }
    }
    return nextUserKey // No user found with 1 or more error OR warning
  }

  // Call Functions

  // JSX Generation
  const rJSX_DatePicker = (disabled: boolean): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mb-2 tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Date')}
          datePickerDate={selectedDate}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
          datePickerDisabled={disabled}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_DownloadButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        className="tw-mb-2 tw-mr-2"
        variant="contained"
        color="info"
        onClick={() => {
          let dialogJSX = (
            <Box>
              <Dialog
                className="bp_dialog_md_width"
                keepMounted
                onClose={() => {
                  uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                }}
                open={true}
              >
                <AppBar
                  position="static"
                  color="inherit"
                >
                  <Toolbar>
                    <IconButton
                      aria-label="menu"
                      color="inherit"
                      disabled
                      edge="start"
                      size="large"
                      sx={{ mr: 2, color: '#fff !important' }}
                    >
                      <Icon icon="download" />
                    </IconButton>
                    <Typography
                      component={'span'}
                      variant={'h6'}
                      sx={{ flexGrow: 1 }}
                    >
                      <Box className="tw-inline-block">{s_DOWNLOADS}</Box>
                    </Typography>
                  </Toolbar>
                </AppBar>
                <DialogContent sx={{ padding: '0px' }}>
                  <Box className="tw-pt-2">
                    <TableContainer>
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell className="tw-text-center">
                              {returnFormattedDate(us_weekStartDate, 'D MMM YYYY')} - {returnFormattedDate(us_weekEndDate, 'D MMM YYYY')}
                            </TableCell>
                            <TableCell className="tw-text-center">{returnFormattedDate(selectedDate, 'D MMM YYYY')}</TableCell>
                          </TableRow>
                          {/* <TableRow>
                            <TableCell className="tw-text-center">
                              <Button
                                className="tw-m-auto"
                                variant="contained"
                                color="info"
                                onClick={() => {
                                  downloadDataWeek('payroll')
                                }}
                              >
                                {s_PAYROLL}
                              </Button>
                            </TableCell>
                            <TableCell className="tw-text-center">
                              <Button
                                className="tw-m-auto"
                                variant="contained"
                                color="info"
                                disabled={true}
                                onClick={() => {
                                  downloadDataDay('payroll')
                                }}
                              >
                                {s_PAYROLL}
                              </Button>
                            </TableCell>
                          </TableRow> */}
                          <TableRow>
                            <TableCell className="tw-text-center">
                              <Button
                                className="tw-m-auto"
                                variant="contained"
                                color="info"
                                onClick={() => {
                                  downloadDataWeek('categories')
                                }}
                              >
                                {/* <Icon icon="shelves" className="tw-mr-2" /> */}
                                {s_BY_ALLOCATION}
                              </Button>
                            </TableCell>
                            <TableCell className="tw-text-center">
                              <Button
                                className="tw-m-auto"
                                variant="contained"
                                color="info"
                                onClick={() => {
                                  downloadDataDay('categories')
                                }}
                              >
                                {/* <Icon icon="shelves" className="tw-mr-2" type="regular" /> */}
                                {s_BY_ALLOCATION}
                              </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="tw-text-center">
                              <Button
                                className="tw-m-auto"
                                variant="contained"
                                color="info"
                                onClick={() => {
                                  downloadDataWeek('raw')
                                }}
                              >
                                {/* <Icon icon="table-list" className="tw-mr-2" /> */}
                                {s_BY_SUBALLOCATION}
                              </Button>
                            </TableCell>
                            <TableCell className="tw-text-center">
                              <Button
                                className="tw-m-auto"
                                variant="contained"
                                color="info"
                                onClick={() => {
                                  downloadDataDay('raw')
                                }}
                              >
                                {/* <Icon icon="table-list" className="tw-mr-2" type="regular" /> */}
                                {s_BY_SUBALLOCATION}
                              </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="tw-text-center">
                              <Button
                                className="tw-m-auto"
                                variant="contained"
                                color="info"
                                onClick={() => {
                                  downloadDayBreakdownByWeek()
                                }}
                              >
                                {/* <Icon icon="calendar-day" className="tw-mr-2" /> */}
                                {s_BY_DAY}
                              </Button>
                            </TableCell>
                            <TableCell className="tw-text-center">
                              <Button
                                className="tw-m-auto"
                                variant="contained"
                                color="info"
                                onClick={() => {
                                  downloadDayBreakdownByDay()
                                }}
                              >
                                {/* <Icon icon="calendar-day" className="tw-mr-2" /> */}
                                {s_BY_DAY}
                              </Button>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="tw-text-center">
                              <Button
                                className="tw-m-auto"
                                variant="contained"
                                color="info"
                                onClick={() => {
                                  if (us_weekStartDate != null && us_weekEndDate != null) {
                                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
                                      downloadWeekPanelPay(res_GCK.clientKey, us_weekStartDate, us_weekEndDate)
                                    })
                                  }
                                }}
                              >
                                {s_PANEL_PAY}
                              </Button>
                            </TableCell>
                            <TableCell className="tw-text-center">
                              <Button
                                className="tw-m-auto"
                                variant="contained"
                                color="info"
                                disabled={true}
                                onClick={() => {
                                  // Nothing
                                }}
                              >
                                {s_PANEL_PAY}
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </DialogContent>
              </Dialog>
            </Box>
          )
          // Open Dialog
          uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: dialogJSX,
              settings: {
                max_width: 'lg',
              },
            },
          })
        }}
        disabled={us_downloadingData === true}
      >
        <Icon
          icon="download"
          className="tw-mr-2"
        />
        {s_DOWNLOADS}
      </Button>
    )
    return buttonJSX
  }

  // const rJSX_RefreshAnalysisButton = (): JSX.Element => {
  // let buttonJSX =
  // <Button
  // 	className="tw-mb-2 tw-mr-2"
  // 	variant="contained"
  // 	color="warning"
  // 	onClick={ () => {
  // 		if( weekStartDate != null && weekEndDate != null ){
  // 			setShowLiveTotals( true )
  // 		}
  // 	}}
  // 	disabled={ showLiveTotals === true }
  // >
  // 	<Icon icon="magnifying-glass-chart" className="tw-mr-2" />
  // 	{ s_LOAD_ANALYSIS }
  // </Button>
  // return buttonJSX
  // }

  const rJSX_ShowingAllUsersButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        className="tw-mb-2 tw-mr-2"
        variant="outlined"
        color="inherit"
        disabled={true}
        onClick={() => {}}
      >
        <Icon
          icon="users"
          className="tw-mr-2"
        />
        {s_SHOWING_ALL_USERS}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_ViewAllUsersButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        className="tw-mb-2 tw-mr-2"
        variant="outlined"
        color="inherit"
        onClick={() => {
          us_setSelectedViewType('all')
          us_setSelectedIndividualKey(null)
        }}
      >
        <Icon
          icon="users"
          className="tw-mr-2"
        />
        {s_SHOW_ALL_USERS}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_UsersTable = (): JSX.Element => {
    let tableJSX = <></>
    tableJSX = (
      <Box>
        <Card className="tw-mb-2">
          <TableBasic
            tableAdditionalData={tableAdditionalData_TimeSheetReviewUsers}
            tableColumns={tableColumns_TimeSheetReviewUsers}
            tableData={objectToArray(us_combinedUserTimesheetData)}
            tableSettings={tableSettings_TimeSheetReviewUsers}
          />
        </Card>
      </Box>
    )
    return tableJSX
  }

  const rJSX_ToggleTableTypeButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_tableViewMode === 'aggregated') {
      buttonJSX = (
        <Button
          className="tw-mb-2 tw-mr-2"
          variant="contained"
          color="secondary"
          onClick={() => {
            us_setTableViewMode('raw')
          }}
        >
          <Icon
            icon="box-check"
            className="tw-mr-2"
          />
          {s_AGGREGATED_PUNCHES}
        </Button>
      )
    } else if (us_tableViewMode === 'raw') {
      buttonJSX = (
        <Button
          className="tw-mb-2 tw-mr-2"
          variant="contained"
          color="secondary"
          onClick={() => {
            us_setTableViewMode('aggregated')
          }}
        >
          <Icon
            icon="list-timeline"
            className="tw-mr-2"
          />
          {s_RAW_PUNCHES}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_selectedViewType === 'all') {
      tabJSX = (
        <Box className="tw-pt-1">
          <Box>
            {rJSX_DatePicker(false)}
            {rJSX_ShowingAllUsersButton()}
            {rJSX_DownloadButton()}
            {/* { rJSX_RefreshAnalysisButton() } */}
          </Box>
          <Box>{rJSX_UsersTable()}</Box>
        </Box>
      )
    } else if (us_selectedViewType === 'individual') {
      if (us_weekStartDate != null && us_weekEndDate != null && us_selectedIndividualKey != null) {
        let submisssionData: TsInterface_UnspecifiedObject = {}
        let startDateKey = returnFormattedDateKey(us_weekStartDate)
        let endDateKey = returnFormattedDateKey(us_weekEndDate)
        let timeSheetHideActions = false
        if (submisssionData != null && submisssionData.timestamp_submitted != null) {
          timeSheetHideActions = true
        }
        // Get Submission Data for Individual
        if (
          us_selectedIndividualKey != null &&
          us_weekStartDate != null &&
          us_weekTimesheets != null &&
          us_weekTimesheets[us_selectedIndividualKey + '_' + returnFormattedDateKey(us_weekStartDate)] != null
        ) {
          submisssionData = us_weekTimesheets[us_selectedIndividualKey + '_' + returnFormattedDateKey(us_weekStartDate)]
        }
        // User Name
        let userNameJSX = <></>
        if (
          us_activeHourlyUsers != null &&
          us_activeHourlyUsers[us_selectedIndividualKey] != null &&
          us_activeHourlyUsers[us_selectedIndividualKey].name != null
        ) {
          // Fast User Switching Buttons
          let nextUserButtonJSX = <></>
          let previousUserButtonJSX = <></>
          let orderedUsersArray = objectToArray(us_combinedUserTimesheetData).sort(dynamicSort('name', 'asc'))
          let currentUserIndex = findUserIndex(orderedUsersArray, us_selectedIndividualKey)
          if (
            currentUserIndex != null &&
            currentUserIndex !== -1 &&
            orderedUsersArray[currentUserIndex] != null &&
            orderedUsersArray[currentUserIndex - 1] != null &&
            orderedUsersArray[currentUserIndex - 1]['key'] != null &&
            orderedUsersArray[currentUserIndex - 1]['name'] != null
          ) {
            previousUserButtonJSX = (
              <Tooltip
                placement="top"
                title={orderedUsersArray[currentUserIndex - 1]['name']}
              >
                <Button
                  className="tw-mt-0 tw-mr-2"
                  variant="outlined"
                  color="inherit"
                  // size="small"
                  onClick={() => {
                    us_setSelectedIndividualKey(orderedUsersArray[currentUserIndex - 1]['key'])
                  }}
                >
                  <Icon
                    icon="arrow-left"
                    className="tw-mr-0 tw-p-0.5"
                  />
                </Button>
              </Tooltip>
            )
          } else {
            previousUserButtonJSX = (
              <Button
                className="tw-mt-0 tw-mr-2"
                variant="outlined"
                color="inherit"
                // size="small"
                disabled={true}
                onClick={() => {
                  // Nothing
                }}
              >
                <Icon
                  icon="arrow-left"
                  className="tw-mr-0 tw-p-0.5"
                />
              </Button>
            )
          }
          if (
            currentUserIndex != null &&
            currentUserIndex !== -1 &&
            orderedUsersArray[currentUserIndex] != null &&
            orderedUsersArray[currentUserIndex + 1] != null &&
            orderedUsersArray[currentUserIndex + 1]['key'] != null &&
            orderedUsersArray[currentUserIndex + 1]['name'] != null
          ) {
            nextUserButtonJSX = (
              <Tooltip
                placement="top"
                title={orderedUsersArray[currentUserIndex + 1]['name']}
              >
                <Button
                  className="tw-mt-0 tw-mr-2"
                  variant="outlined"
                  color="inherit"
                  // size="small"
                  onClick={() => {
                    us_setSelectedIndividualKey(orderedUsersArray[currentUserIndex + 1]['key'])
                  }}
                >
                  <Icon
                    icon="arrow-right"
                    className="tw-mr-0 tw-p-0.5"
                  />
                </Button>
              </Tooltip>
            )
          } else {
            nextUserButtonJSX = (
              <Button
                className="tw-mt-0 tw-mr-2"
                variant="outlined"
                color="inherit"
                // size="small"
                disabled={true}
                onClick={() => {
                  // Nothing
                }}
              >
                <Icon
                  icon="arrow-right"
                  className="tw-mr-0 tw-p-0.5"
                />
              </Button>
            )
          }
          // Next user with errors
          let usersWithErrors = []
          if (
            us_adminTimesheetEvaluationResults != null &&
            us_adminTimesheetEvaluationResults.formatted_data != null &&
            us_adminTimesheetEvaluationResults.formatted_data.driver_punches != null
          ) {
            usersWithErrors = objectToArray(us_adminTimesheetEvaluationResults.formatted_data.driver_punches).filter((obj) => obj.error_count > 0)
          }
          let nextUserWithErrorButtonJSX = <></>
          let disabledNextUserWithErrorButtonJSX = (
            <Button
              className="tw-mt-0 tw-mr-2"
              variant="outlined"
              color="error"
              size="small"
              disabled={true}
              onClick={() => {}}
            >
              <Icon
                icon="arrow-right"
                className="tw-mr-2"
              />
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              ({usersWithErrors.length})
            </Button>
          )
          if (
            us_showLiveTotals === true &&
            us_adminTimesheetEvaluationResults != null &&
            us_adminTimesheetEvaluationResults.formatted_data != null &&
            us_adminTimesheetEvaluationResults.formatted_data.driver_punches != null &&
            objectToArray(us_adminTimesheetEvaluationResults.formatted_data.driver_punches).length > 0 &&
            objectToArray(us_adminTimesheetEvaluationResults.formatted_data.driver_punches).filter((obj) => obj.error_count > 0).length > 0
          ) {
            if (usersWithErrors.length === 1 && usersWithErrors[0].key === us_selectedIndividualKey) {
              nextUserWithErrorButtonJSX = disabledNextUserWithErrorButtonJSX
            } else if (usersWithErrors.length > 0) {
              nextUserWithErrorButtonJSX = (
                <Tooltip
                  placement="top"
                  title={s_NEXT_USER_WITH_AN_ERROR}
                >
                  <Button
                    className="tw-mt-0 tw-mr-2"
                    variant="outlined"
                    color="error"
                    size="small"
                    onClick={() => {
                      let nextErrorUserKey: string | null = findNextUserWithErrors(
                        objectToArray(us_adminTimesheetEvaluationResults.formatted_data.driver_punches),
                        us_selectedIndividualKey,
                        'error_count',
                      )
                      if (nextErrorUserKey != null) {
                        us_setSelectedIndividualKey(nextErrorUserKey)
                      }
                    }}
                  >
                    <Icon
                      icon="arrow-right"
                      className="tw-mr-2"
                    />
                    <Icon
                      icon="triangle-exclamation"
                      className="tw-mr-2"
                    />
                    ({usersWithErrors.length})
                  </Button>
                </Tooltip>
              )
            } else {
              nextUserWithErrorButtonJSX = disabledNextUserWithErrorButtonJSX
            }
          } else if (us_showLiveTotals === true) {
            nextUserWithErrorButtonJSX = disabledNextUserWithErrorButtonJSX
          }
          // Next user with warnings
          let nextUserWithWarningButtonJSX = <></>
          let usersWithWarnings = []
          if (
            us_adminTimesheetEvaluationResults != null &&
            us_adminTimesheetEvaluationResults.formatted_data != null &&
            us_adminTimesheetEvaluationResults.formatted_data.driver_punches != null
          ) {
            usersWithWarnings = objectToArray(us_adminTimesheetEvaluationResults.formatted_data.driver_punches).filter((obj) => obj.warning_count > 0)
          }
          let disabledNextUserWithWarningButtonJSX = (
            <Button
              className="tw-mt-0 tw-mr-2"
              variant="outlined"
              color="warning"
              size="small"
              disabled={true}
              onClick={() => {}}
            >
              <Icon
                icon="arrow-right"
                className="tw-mr-2"
              />
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              ({usersWithWarnings.length})
            </Button>
          )
          if (
            us_showLiveTotals === true &&
            us_adminTimesheetEvaluationResults != null &&
            us_adminTimesheetEvaluationResults.formatted_data != null &&
            us_adminTimesheetEvaluationResults.formatted_data.driver_punches != null &&
            objectToArray(us_adminTimesheetEvaluationResults.formatted_data.driver_punches).length > 0 &&
            objectToArray(us_adminTimesheetEvaluationResults.formatted_data.driver_punches).filter((obj) => obj.warning_count > 0).length > 0
          ) {
            if (usersWithWarnings.length === 1 && usersWithWarnings[0].key === us_selectedIndividualKey) {
              nextUserWithWarningButtonJSX = disabledNextUserWithWarningButtonJSX
            } else if (usersWithWarnings.length > 0) {
              nextUserWithWarningButtonJSX = (
                <Tooltip
                  placement="top"
                  title={s_NEXT_USER_WITH_AN_ERROR}
                >
                  <Button
                    className="tw-mt-0 tw-mr-2"
                    variant="outlined"
                    color="warning"
                    size="small"
                    onClick={() => {
                      let nextWarningUserKey: string | null = findNextUserWithErrors(
                        objectToArray(us_adminTimesheetEvaluationResults.formatted_data.driver_punches),
                        us_selectedIndividualKey,
                        'warning_count',
                      )
                      if (nextWarningUserKey != null) {
                        us_setSelectedIndividualKey(nextWarningUserKey)
                      }
                    }}
                  >
                    <Icon
                      icon="arrow-right"
                      className="tw-mr-2"
                    />
                    <Icon
                      icon="triangle-exclamation"
                      className="tw-mr-2"
                    />
                    ({usersWithWarnings.length})
                  </Button>
                </Tooltip>
              )
            } else {
              nextUserWithWarningButtonJSX = disabledNextUserWithWarningButtonJSX
            }
          } else if (us_showLiveTotals === true) {
            nextUserWithWarningButtonJSX = disabledNextUserWithWarningButtonJSX
          }
          // Next user with corrections
          let nextUserWithCorrectionsButtonJSX = <></>
          // Full JSX
          userNameJSX = (
            <Box className="tw-mb-2 tw-inline-block">
              <Icon
                icon="user"
                className="tw-mr-2 tw-inline-block tw-align-middle"
              />
              <Typography
                className="tw-mr-2 tw-inline-block tw-align-middle"
                variant="h6"
              >
                {getProp(us_activeHourlyUsers, us_selectedIndividualKey + '.name', '')}
              </Typography>
              {previousUserButtonJSX}
              {nextUserButtonJSX}
              {nextUserWithErrorButtonJSX}
              {nextUserWithWarningButtonJSX}
              {nextUserWithCorrectionsButtonJSX}
            </Box>
          )
        }
        // JSX
        tabJSX = (
          <Box className="tw-pt-1">
            <Box>
              {rJSX_DatePicker(false)}
              {rJSX_ViewAllUsersButton()}
              {rJSX_ToggleTableTypeButton()}
            </Box>
            <Box>
              {userNameJSX}
              <Box>
                {rJSX_IndividualTimeSheet(
                  'user',
                  us_selectedIndividualKey,
                  startDateKey,
                  endDateKey,
                  us_aggregatePunchData,
                  submisssionData,
                  weekRowEditActions,
                  eventRowEditActions,
                  otherRowEditActions,
                  us_rawPunchData,
                  rawRowEditActions,
                  us_tableViewMode,
                  us_setTableViewMode,
                  timeSheetHideActions,
                  createNewPunch,
                )}
              </Box>
              <Box className="tw-text-right tw-opacity-20 tw-italic tw-pr-2">
                {us_selectedIndividualKey}_{startDateKey}
              </Box>
            </Box>
          </Box>
        )
      }
    }
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
}

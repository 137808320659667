//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Renders a full month calendar with events on days rendered using a

		TODO:
			[ ] Feature - Custom Renderer Prop that supports clicks on events
			[ ] Feature - An on click event for a specific date
			// Calendar Settings
			// Calendar Data
			// Calendar Additional Data
			// Calendar Day Renderer
				// Events, On Clicks, etc
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Grid, Typography } from '@mui/material/'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import {
  TsInterface_CalendarAdditionalData,
  TsInterface_CalendarData,
  TsInterface_CalendarEvent,
  TsInterface_CalendarSettings,
  TsType_CalendarDateChangeCallback,
} from 'rfbp_core/components/calendar'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { dynamicSort, getProp, millisecondsPerDay, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Types
///////////////////////////////

interface TsInterface_ComponentProps {
  calendarAdditionalData: TsInterface_CalendarAdditionalData
  calendarData: TsInterface_CalendarData
  calendarDateChangeCallback: TsType_CalendarDateChangeCallback
  calendarSettings: TsInterface_CalendarSettings
}

interface TsInterface_CalendarDayStyles {
  background?: string
  maxHeight: string
  minHeight: string
  overflow?: string
}

interface DayObjectInterface {
  date: Date
  day: number
  events: TsInterface_CalendarEvent[]
  is_current_day: boolean
  is_current_month: boolean
  month: JSX.Element | undefined | string
  week_number: number[]
  weekday: JSX.Element | undefined
  year: number
}

type CalendarRowArrayInterface = Array<DayObjectInterface>

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_APR: JSX.Element = <Trans>Apr</Trans>
const s_AUG: JSX.Element = <Trans>Aug</Trans>
const s_AUGUST: JSX.Element = <Trans>August</Trans>
const s_DEC: JSX.Element = <Trans>Dec</Trans>
const s_DECEMBER: JSX.Element = <Trans>December</Trans>
const s_FEB: JSX.Element = <Trans>Feb</Trans>
const s_FEBRUARY: JSX.Element = <Trans>February</Trans>
const s_FRI: JSX.Element = <Trans>Fri</Trans>
const s_FRIDAY: JSX.Element = <Trans>Friday</Trans>
const s_JAN: JSX.Element = <Trans>Jan</Trans>
const s_JANUARY: JSX.Element = <Trans>January</Trans>
const s_JUL: JSX.Element = <Trans>Jul</Trans>
const s_JULY: JSX.Element = <Trans>July</Trans>
const s_JUN: JSX.Element = <Trans>Jun</Trans>
const s_JUNE: JSX.Element = <Trans>June</Trans>
const s_MAR: JSX.Element = <Trans>Mar</Trans>
const s_MARCH: JSX.Element = <Trans>March</Trans>
const s_MAY: JSX.Element = <Trans>May</Trans>
const s_MON: JSX.Element = <Trans>Mon</Trans>
const s_MONDAY: JSX.Element = <Trans>Monday</Trans>
const s_NOV: JSX.Element = <Trans>Nov</Trans>
const s_NOVEMBER: JSX.Element = <Trans>November</Trans>
const s_OCT: JSX.Element = <Trans>Oct</Trans>
const s_OCTOBER: JSX.Element = <Trans>October</Trans>
const s_SAT: JSX.Element = <Trans>Sat</Trans>
const s_SATURDAY: JSX.Element = <Trans>Saturday</Trans>
const s_SEP: JSX.Element = <Trans>Sep</Trans>
const s_SEPTEMBER: JSX.Element = <Trans>September</Trans>
const s_SUN: JSX.Element = <Trans>Sun</Trans>
const s_SUNDAY: JSX.Element = <Trans>Sunday</Trans>
const s_THU: JSX.Element = <Trans>Thu</Trans>
const s_THURSDAY: JSX.Element = <Trans>Thursday</Trans>
const s_TODAY: JSX.Element = <Trans>Today</Trans>
const s_TUE: JSX.Element = <Trans>Tue</Trans>
const s_TUESDAY: JSX.Element = <Trans>Tuesday</Trans>
const s_WED: JSX.Element = <Trans>Wed</Trans>
const s_WEDNESDAY: JSX.Element = <Trans>Wednesday</Trans>
const s_WEEK: JSX.Element = <Trans>Week</Trans>
// { sort-end } - displayed text

// Other
const defaultCalendarDateChangeCallback = (startDate: Date, endDate: Date) => {
  return new Promise((resolve, reject) => {
    resolve({ success: true })
  })
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const CalendarMonth = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_calendarData: TsInterface_CalendarData = getProp(props, 'calendarData', {})
  let pr_calendarDateChangeCallback: TsType_CalendarDateChangeCallback = getProp(props, 'calendarDateChangeCallback', defaultCalendarDateChangeCallback)
  let pr_calendarSettings: TsInterface_CalendarSettings = getProp(props, 'calendarSettings', {})
  let pr_highlightedDate: Date = getProp(pr_calendarSettings, 'highlighted_date', new Date())
  let pr_initialSelectedDate: Date = getProp(pr_calendarSettings, 'initial_selected_date', new Date())

  let eventRenderer = (event: TsInterface_CalendarEvent) => {
    let eventJSX: JSX.Element = (
      <Box>
        <Icon
          icon="circle"
          className="tw-inline-block tw-text-xs tw-text-info_main tw-mr-1"
        />
        <Typography
          variant="body2"
          className="tw-inline-block"
        >
          {returnFormattedDate(event.event_timestamp, 'HH:mm')} {event.name}
        </Typography>
      </Box>
    )
    return eventJSX
  }

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(pr_initialSelectedDate)
  const [us_themeColors, us_setThemeColors] = useState<TsInterface_UnspecifiedObject>({})
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (themeVariables.mode === 'dark') {
      us_setThemeColors({
        calendarColorLight: themeVariables.gray_800,
        calendarColorMain: themeVariables.gray_900,
        calendarColorDark: themeVariables.background_json,
      })
    } else {
      us_setThemeColors({
        calendarColorLight: themeVariables.gray_200,
        calendarColorMain: themeVariables.gray_300,
        calendarColorDark: themeVariables.gray_400,
      })
    }
  }, [])

  // Other Variables
  let tempCalendarVisibleRows: CalendarRowArrayInterface[] = []
  let calendarVisibleRows: CalendarRowArrayInterface[] = []
  let selectedMonth: number = us_selectedDate.getMonth()
  let selectedYear: number = us_selectedDate.getFullYear()
  let highlightedDay: number = pr_highlightedDate.getDate()
  let highlightedMonth: number = pr_highlightedDate.getMonth()
  let highlightedYear: number = pr_highlightedDate.getFullYear()

  // Functions
  const getWeekNumber = (d: Date): number[] => {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()))
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7))
    // Get first day of year
    let yearStart: Date = new Date(Date.UTC(d.getUTCFullYear(), 0, 1))
    // Calculate full weeks to nearest Thursday
    let weekNo: number = Math.ceil(((d.getTime() - yearStart.getTime()) / millisecondsPerDay + 1) / 7)
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo]
  }

  const rJSX_Weekday = (dayNumber: number, dateLength: string): JSX.Element => {
    let weekdayName: JSX.Element = <></>
    if (dateLength === 'short') {
      switch (dayNumber) {
        case 0:
          weekdayName = s_SUN
          break
        case 1:
          weekdayName = s_MON
          break
        case 2:
          weekdayName = s_TUE
          break
        case 3:
          weekdayName = s_WED
          break
        case 4:
          weekdayName = s_THU
          break
        case 5:
          weekdayName = s_FRI
          break
        case 6:
          weekdayName = s_SAT
          break
        default:
          break
      }
    } else {
      switch (dayNumber) {
        case 0:
          weekdayName = s_SUNDAY
          break
        case 1:
          weekdayName = s_MONDAY
          break
        case 2:
          weekdayName = s_TUESDAY
          break
        case 3:
          weekdayName = s_WEDNESDAY
          break
        case 4:
          weekdayName = s_THURSDAY
          break
        case 5:
          weekdayName = s_FRIDAY
          break
        case 6:
          weekdayName = s_SATURDAY
          break
        default:
          break
      }
    }
    return weekdayName
  }

  const rJSX_Month = (monthNumber: number, dateLength: string): JSX.Element | string => {
    let monthName: JSX.Element | string = <></>
    if (dateLength === 'short') {
      switch (monthNumber) {
        case 0:
          monthName = s_JAN
          break
        case 1:
          monthName = s_FEB
          break
        case 2:
          monthName = s_MAR
          break
        case 3:
          monthName = s_APR
          break
        case 4:
          monthName = s_MAY
          break
        case 5:
          monthName = s_JUN
          break
        case 6:
          monthName = s_JUL
          break
        case 7:
          monthName = s_AUG
          break
        case 8:
          monthName = s_SEP
          break
        case 9:
          monthName = s_OCT
          break
        case 10:
          monthName = s_NOV
          break
        case 11:
          monthName = s_DEC
          break
        default:
          break
      }
    } else {
      switch (monthNumber) {
        case 0:
          monthName = s_JANUARY
          break
        case 1:
          monthName = s_FEBRUARY
          break
        case 2:
          monthName = s_MARCH
          break
        case 3:
          monthName = rLIB('April')
          break
        case 4:
          monthName = s_MAY
          break
        case 5:
          monthName = s_JUNE
          break
        case 6:
          monthName = s_JULY
          break
        case 7:
          monthName = s_AUGUST
          break
        case 8:
          monthName = s_SEPTEMBER
          break
        case 9:
          monthName = s_OCTOBER
          break
        case 10:
          monthName = s_NOVEMBER
          break
        case 11:
          monthName = s_DECEMBER
          break
        default:
          break
      }
    }
    return monthName
  }

  const generateCalendarRows = (): void => {
    let firstCalendarDate: Date = new Date(selectedYear, selectedMonth, 1)
    let lastCalendarDate: Date = new Date(selectedYear, selectedMonth + 1, 0)
    let firstDayOfWeek: number = firstCalendarDate.getDay()
    let lastDayOfWeek: number = lastCalendarDate.getDay()
    let prevMonthVisibleDays: number = firstDayOfWeek
    let nextMonthVisibleDays: number = 6 - lastDayOfWeek
    let firstVisbleDay: Date = new Date(selectedYear, selectedMonth, 1 - prevMonthVisibleDays)
    let lastVisibleDay: Date = new Date(selectedYear, selectedMonth + 1, 0 + nextMonthVisibleDays)
    let totalDays: number = (lastVisibleDay.getTime() - firstVisbleDay.getTime()) / millisecondsPerDay + 1
    for (let i = 0; i < totalDays; i++) {
      let rowIndex = Math.floor(i / 7)
      if (tempCalendarVisibleRows[rowIndex] == null) {
        tempCalendarVisibleRows[rowIndex] = []
      }
      let loopDate = new Date(firstVisbleDay.getTime() + i * millisecondsPerDay)
      let isCurrentMonth = false
      if (selectedMonth === loopDate.getMonth()) {
        isCurrentMonth = true
      }
      let isCurrentDay = false
      if (highlightedDay === loopDate.getDate() && highlightedMonth === loopDate.getMonth() && highlightedYear === loopDate.getFullYear()) {
        isCurrentDay = true
      }
      let dayEvents = []
      let eventStartBoundary = new Date(loopDate.getFullYear(), loopDate.getMonth(), loopDate.getDate(), 0, 0, 0).getTime()
      let eventEndBoundary = new Date(loopDate.getFullYear(), loopDate.getMonth(), loopDate.getDate() + 1, 0, 0, 0).getTime()
      for (let eventsKey in pr_calendarData) {
        let event = pr_calendarData[eventsKey]
        if (eventStartBoundary <= event['event_timestamp'] && event['event_timestamp'] < eventEndBoundary) {
          dayEvents.push(event)
        }
      }
      tempCalendarVisibleRows[rowIndex].push({
        date: loopDate,
        day: loopDate.getDate(),
        weekday: rJSX_Weekday(loopDate.getDay(), 'short'),
        month: rJSX_Month(loopDate.getMonth(), 'short'),
        year: loopDate.getFullYear(),
        week_number: getWeekNumber(loopDate),
        is_current_month: isCurrentMonth,
        is_current_day: isCurrentDay,
        events: dayEvents,
      })
    }
    // Finalize
    for (let rowIndex in tempCalendarVisibleRows) {
      let showRow = false
      for (let dayKey in tempCalendarVisibleRows[rowIndex]) {
        let loopDay = tempCalendarVisibleRows[rowIndex][dayKey]
        if (loopDay != null && loopDay.is_current_month === true) {
          showRow = true
        }
      }
      if (showRow === true) {
        calendarVisibleRows.push(tempCalendarVisibleRows[rowIndex])
      }
    }
  }

  const updateCalendarDateBounds = (newSelectedDate: Date) => {
    us_setSelectedDate(newSelectedDate)
    let firstCalendarDate = new Date(newSelectedDate.getFullYear(), newSelectedDate.getMonth() + 0, 1)
    let lastCalendarDate = new Date(newSelectedDate.getFullYear(), newSelectedDate.getMonth() + 1, 0)
    let firstDayOfWeek: number = firstCalendarDate.getDay()
    let lastDayOfWeek: number = lastCalendarDate.getDay()
    let prevMonthVisibleDays: number = firstDayOfWeek
    let nextMonthVisibleDays: number = 6 - lastDayOfWeek
    let firstVisbleDay: Date = new Date(newSelectedDate.getFullYear(), newSelectedDate.getMonth() + 0, 1 - prevMonthVisibleDays)
    let lastVisibleDay: Date = new Date(newSelectedDate.getFullYear(), newSelectedDate.getMonth() + 1, 0 + nextMonthVisibleDays + 1)
    pr_calendarDateChangeCallback(firstVisbleDay, lastVisibleDay)
  }

  const moveCalendarBack = (): void => {
    let newSelectedDate = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth() - 1, 1)
    updateCalendarDateBounds(newSelectedDate)
  }

  const moveToToday = (): void => {
    let newSelectedDate = new Date(pr_highlightedDate.getFullYear(), pr_highlightedDate.getMonth() + 0, 1)
    updateCalendarDateBounds(newSelectedDate)
  }

  const moveCalendarForward = (): void => {
    let newSelectedDate = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth() + 1, 1)
    updateCalendarDateBounds(newSelectedDate)
  }

  generateCalendarRows()

  // JSX Generation
  const rJSX_DayContainer = (day: DayObjectInterface): JSX.Element => {
    let dayStyles: TsInterface_CalendarDayStyles = {
      minHeight: '12.5vh',
      maxHeight: '12.5vh',
    }
    let eventContainerStyles: TsInterface_CalendarDayStyles = {
      minHeight: '8.5vh',
      maxHeight: '8.5vh',
      overflow: 'scroll',
    }
    if (day != null && day.is_current_month === false) {
      dayStyles['background'] = us_themeColors.calendarColorLight
    }
    let dayContainer: JSX.Element = <></>
    if (day != null) {
      if (day.events != null && day.events.length > 0) {
        day.events.sort(dynamicSort('event_timestamp', null))
        dayContainer = (
          <Box
            component="div"
            className="tw-p-2 tw-text-left"
            style={dayStyles}
          >
            <Typography variant="body2">{day['day']}</Typography>
            <Box style={eventContainerStyles}>
              {day.events.map((event: TsInterface_CalendarEvent, index: number) => (
                <Box key={index}>{eventRenderer(event)}</Box>
              ))}
            </Box>
          </Box>
        )
      } else {
        let className: string = 'tw-inline-block tw-p-0.5 tw-rounded-full tw-w-6 tw-text-center'
        if (day.is_current_day) {
          className = 'tw-inline-block tw-p-0.5 tw-rounded-full tw-w-6 tw-text-center tw-bg-info_main tw-text-white'
        }
        dayContainer = (
          <Box
            component="div"
            className="tw-p-2 tw-text-left"
            style={dayStyles}
          >
            <Typography
              variant="body2"
              className={className}
            >
              {day['day']}
            </Typography>
          </Box>
        )
      }
    } else {
      dayContainer = (
        <Box
          component="div"
          className="tw-p-2 tw-text-left"
          style={dayStyles}
        >
          <Typography variant="body2"></Typography>
        </Box>
      )
    }
    return dayContainer
  }

  const rJSX_WeekNumber = (calendarRow: DayObjectInterface[]): JSX.Element => {
    let weekNumberJSX: JSX.Element = <></>
    if (pr_calendarSettings.show_week_number === true) {
      weekNumberJSX = (
        <Box
          component="div"
          className="tw-p-2"
          style={{ borderColor: us_themeColors.calendarColorDark, backgroundColor: us_themeColors.calendarColorMain }}
          sx={{ borderBottom: 1 }}
        >
          <Typography variant="body2">
            <strong>
              {s_WEEK} {calendarRow[0]['week_number'][1]}:{' '}
            </strong>
            <span>
              {calendarRow[0]['month']} {calendarRow[0]['day']} - {calendarRow[6]['month']} {calendarRow[6]['day']}, {calendarRow[6]['year']}
            </span>
          </Typography>
        </Box>
      )
    } else {
      weekNumberJSX = <></>
    }
    return weekNumberJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let calendarJSX: JSX.Element = (
      <Card>
        <Grid
          container
          spacing={0}
          columns={7}
        >
          <Grid
            item
            sm={7}
            xs={7}
            className="tw-text-left"
            style={{ borderColor: us_themeColors.calendarColorDark }}
            sx={{ borderBottom: 1 }}
          >
            <Box
              component="div"
              className="tw-p-2"
              style={{ background: us_themeColors.calendarColorMain }}
            >
              <Button
                variant="outlined"
                className="tw-ml-2 tw-px-1 tw-inline-block tw-text-center"
                onClick={(event) => {
                  moveToToday()
                }}
              >
                {s_TODAY}
              </Button>
              <Button
                className="tw-px-1 tw-inline-block tw-text-center tw-mx-2 tw-min-w-0"
                onClick={(event) => {
                  moveCalendarBack()
                }}
              >
                <Icon
                  icon="chevron-left"
                  size="sm"
                  className="tw-align-middle tw-mb-1"
                />
              </Button>
              <Button
                className="tw-px-1 tw-inline-block tw-text-center tw-mx-2 tw-min-w-0"
                onClick={(event) => {
                  moveCalendarForward()
                }}
              >
                <Icon
                  icon="chevron-right"
                  size="sm"
                  className="tw-align-middle tw-mb-1"
                />
              </Button>
              <Typography
                variant="h5"
                className="tw-inline-block"
              >
                <strong>
                  {rJSX_Month(selectedMonth, 'full')} {selectedYear}
                </strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          columns={7}
        >
          <Grid
            item
            sm={1}
            xs={1}
            className="tw-text-center"
            style={{ borderColor: us_themeColors.calendarColorDark }}
            sx={{ borderBottom: 1 }}
          >
            <Box
              component="div"
              className="tw-p-2"
              style={{ background: us_themeColors.calendarColorMain }}
            >
              <Typography variant="body2">
                <strong>{s_SUN}</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            sm={1}
            xs={1}
            className="tw-text-center"
            style={{ borderColor: us_themeColors.calendarColorDark }}
            sx={{ borderBottom: 1, borderLeft: 1 }}
          >
            <Box
              component="div"
              className="tw-p-2"
              style={{ background: us_themeColors.calendarColorMain }}
            >
              <Typography variant="body2">
                <strong>{s_MON}</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            sm={1}
            xs={1}
            className="tw-text-center"
            style={{ borderColor: us_themeColors.calendarColorDark }}
            sx={{ borderBottom: 1, borderLeft: 1 }}
          >
            <Box
              component="div"
              className="tw-p-2"
              style={{ background: us_themeColors.calendarColorMain }}
            >
              <Typography variant="body2">
                <strong>{s_TUE}</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            sm={1}
            xs={1}
            className="tw-text-center"
            style={{ borderColor: us_themeColors.calendarColorDark }}
            sx={{ borderBottom: 1, borderLeft: 1 }}
          >
            <Box
              component="div"
              className="tw-p-2"
              style={{ background: us_themeColors.calendarColorMain }}
            >
              <Typography variant="body2">
                <strong>{s_WED}</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            sm={1}
            xs={1}
            className="tw-text-center"
            style={{ borderColor: us_themeColors.calendarColorDark }}
            sx={{ borderBottom: 1, borderLeft: 1 }}
          >
            <Box
              component="div"
              className="tw-p-2"
              style={{ background: us_themeColors.calendarColorMain }}
            >
              <Typography variant="body2">
                <strong>{s_THU}</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            sm={1}
            xs={1}
            className="tw-text-center"
            style={{ borderColor: us_themeColors.calendarColorDark }}
            sx={{ borderBottom: 1, borderLeft: 1 }}
          >
            <Box
              component="div"
              className="tw-p-2"
              style={{ background: us_themeColors.calendarColorMain }}
            >
              <Typography variant="body2">
                <strong>{s_FRI}</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            sm={1}
            xs={1}
            className="tw-text-center"
            style={{ borderColor: us_themeColors.calendarColorDark }}
            sx={{ borderBottom: 1, borderLeft: 1 }}
          >
            <Box
              component="div"
              className="tw-p-2"
              style={{ background: us_themeColors.calendarColorMain }}
            >
              <Typography variant="body2">
                <strong>{s_SAT}</strong>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {calendarVisibleRows.map((calendarRow, index) => (
          <Box key={index}>
            {rJSX_WeekNumber(calendarRow)}
            <Grid
              container
              spacing={0}
              columns={7}
            >
              <Grid
                item
                sm={1}
                xs={1}
                className="tw-text-center"
                style={{ borderColor: us_themeColors.calendarColorDark }}
                sx={{ borderBottom: 1 }}
              >
                {rJSX_DayContainer(calendarRow[0])}
              </Grid>
              <Grid
                item
                sm={1}
                xs={1}
                className="tw-text-center"
                style={{ borderColor: us_themeColors.calendarColorDark }}
                sx={{ borderBottom: 1, borderLeft: 1 }}
              >
                {rJSX_DayContainer(calendarRow[1])}
              </Grid>
              <Grid
                item
                sm={1}
                xs={1}
                className="tw-text-center"
                style={{ borderColor: us_themeColors.calendarColorDark }}
                sx={{ borderBottom: 1, borderLeft: 1 }}
              >
                {rJSX_DayContainer(calendarRow[2])}
              </Grid>
              <Grid
                item
                sm={1}
                xs={1}
                className="tw-text-center"
                style={{ borderColor: us_themeColors.calendarColorDark }}
                sx={{ borderBottom: 1, borderLeft: 1 }}
              >
                {rJSX_DayContainer(calendarRow[3])}
              </Grid>
              <Grid
                item
                sm={1}
                xs={1}
                className="tw-text-center"
                style={{ borderColor: us_themeColors.calendarColorDark }}
                sx={{ borderBottom: 1, borderLeft: 1 }}
              >
                {rJSX_DayContainer(calendarRow[4])}
              </Grid>
              <Grid
                item
                sm={1}
                xs={1}
                className="tw-text-center"
                style={{ borderColor: us_themeColors.calendarColorDark }}
                sx={{ borderBottom: 1, borderLeft: 1 }}
              >
                {rJSX_DayContainer(calendarRow[5])}
              </Grid>
              <Grid
                item
                sm={1}
                xs={1}
                className="tw-text-center"
                style={{ borderColor: us_themeColors.calendarColorDark }}
                sx={{ borderBottom: 1, borderLeft: 1 }}
              >
                {rJSX_DayContainer(calendarRow[6])}
              </Grid>
            </Grid>
          </Box>
        ))}
      </Card>
    )
    return calendarJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

import { returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { objectToArray } from '../../../../rfbp_core/services/helper_functions/index'

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

// TEMP

export const processCycleTimeCSToPermitRequested = (
  rawCycleTimeRtgToInstall: any,
  mondaysInDateRange: string[],
  salesPartners: any,
  isServer: boolean = false, // Optional parameter to specify if running on the server
): { summary_rows: any[]; data_rows: any[] } | null => {
  if (!rawCycleTimeRtgToInstall || !mondaysInDateRange) return null

  const today = new Date()
  today.setHours(0, 0, 0, 0) // Normalize today's date

  const formattedData: any = {
    summary_rows: [],
    data_rows: [],
  }

  const dateHeaders: any[] = [
    {
      cellValue: 'Cycle Time (CS to Permit Requested)',
      conditionalFormatting: { fontWeight: 'bold', width: '200px' },
    },
  ]
  const weeklyTotals: any = {}
  const totalAverages: any = {}

  objectToArray(mondaysInDateRange).forEach((monday: string) => {
    const date: Date = new Date(monday)

    // Adjust the date only if running on the desktop
    if (!isServer) {
      date.setUTCDate(date.getUTCDate() + 1) // Adjust date to ensure Monday display across environments
    }

    const formattedDateKey: string = returnFormattedDateKey(date)
    const formattedDate: string = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
    const isFuture: boolean = date > today

    weeklyTotals[formattedDateKey] = { installed: 0, expected: 0 } // Initialize totals

    dateHeaders.push({
      cellValue: formattedDate,
      conditionalFormatting: { fontWeight: 'bold', width: '120px', opacity: isFuture ? 0.5 : 1 },
    })
    totalAverages[monday] = []
  })

  formattedData.summary_rows.push(dateHeaders)

  const partnersRegionsDelays: any = {}
  Object.entries<any>(rawCycleTimeRtgToInstall).forEach(([dateStr, weekData]: [string, any]) => {
    Object.values<any>(weekData.data).forEach((entry: any) => {
      // Exclude the entry if either field is missing
      if (!entry.sale_customer_signature || !entry.permitting_and_approvals_building_permit_submission) {
        return
      }

      const salesPartnerName = salesPartners[entry.associated_sales_partner_key]?.name || 'Unknown Partner'
      const partnerRegionKey: string = `${salesPartnerName} - ${entry.associated_region_name}`

      if (!partnersRegionsDelays[partnerRegionKey]) {
        partnersRegionsDelays[partnerRegionKey] = {}
      }
      if (!partnersRegionsDelays[partnerRegionKey][dateStr]) {
        partnersRegionsDelays[partnerRegionKey][dateStr] = []
      }

      const diffTime = Math.abs(
        new Date(entry.permitting_and_approvals_building_permit_submission).getTime() - new Date(entry.sale_customer_signature).getTime(),
      )
      const delay = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      totalAverages[dateStr].push(delay)

      partnersRegionsDelays[partnerRegionKey][dateStr].push({
        delay,
      })
    })
  })

  const totalRow: any[] = [{ cellValue: 'Average', conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]
  Object.keys(totalAverages).forEach((monday: any) => {
    const delays: any = totalAverages[monday]
    let averageDelay: any = '-'
    if (delays.length > 0) {
      averageDelay = Math.round(delays.reduce((acc: number, cur: number) => acc + cur, 0) / delays.length) + ' days'
      totalRow.push({
        cellValue: averageDelay,
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: parseInt(averageDelay) > 14 ? '#c82424' : '#28a56c',
          opacity: 1,
          width: '120px',
        },
      })
    } else {
      totalRow.push({
        cellValue: '-',
        conditionalFormatting: {
          fontWeight: 'normal',
          backgroundColor: '',
          opacity: 1,
          width: '120px',
        },
      })
    }
  })

  formattedData.summary_rows.push(totalRow)

  Object.keys(partnersRegionsDelays).forEach((partnerRegionKey: any) => {
    const rowData: any[] = [
      {
        cellValue: partnerRegionKey,
        conditionalFormatting: { fontWeight: 'bold', width: '200px' },
      },
    ]

    mondaysInDateRange.forEach((monday: string) => {
      const delays: any = partnersRegionsDelays[partnerRegionKey][monday] || []
      let averageDelay: string = '-'

      if (delays.length > 0) {
        const totalDelay = delays.reduce((acc: number, entry: any) => acc + entry.delay, 0)
        averageDelay = Math.round(totalDelay / delays.length) + ' days'
      }

      rowData.push({
        cellValue: averageDelay,
        conditionalFormatting: {
          backgroundColor: averageDelay !== '-' ? (parseInt(averageDelay) > 14 ? '#c82424' : '#28a56c') : '',
          opacity: 1,
          width: '120px',
        },
      })
    })

    if (rowData.length > 1) {
      formattedData.data_rows.push(rowData)
    }
  })

  return formattedData
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Helper Functions provide independent utility functions that can be used throughout the application

			DayJS

			Format	Output				Description
			YY		18					Two-digit year
			YYYY	2018				Four-digit year
			M		1-12				The month, beginning at 1
			MM		01-12				The month, 2-digits
			MMM		Jan-Dec				The abbreviated month name
			MMMM	January-December	The full month name
			D		1-31				The day of the month
			DD		01-31				The day of the month, 2-digits
			d		0-6	The 			day of the week, with Sunday as 0
			dd		Su-Sa				The min name of the day of the week
			ddd		Sun-Sat				The short name of the day of the week
			dddd	Sunday-Saturday		The name of the day of the week
			H		0-23				The hour
			HH		00-23				The hour, 2-digits
			h		1-12				The hour, 12-hour clock
			hh		01-12				The hour, 12-hour clock, 2-digits
			m		0-59				The minute
			mm		00-59				The minute, 2-digits
			s		0-59				The second
			ss		00-59				The second, 2-digits
			SSS		000-999				The millisecond, 3-digits
			Z		+05:00				The offset from UTC, ±HH:mm
			ZZ		+0500				The offset from UTC, ±HHmm
			A		AM PM
			a		am pm

		TODO:
			[ ] Cleanup - remove unused functions to trim this down (maybe comment out for now if not used)
			[ ] Typescript - lots of cleanup
			[ ] Typescript - Multiple instances of any
			[ ] Typescript - 9 instances of TsInterface_UnspecifiedObject
			[ ] Typescript - 1 instance of unknown
	*/

///////////////////////////////
// Imports
///////////////////////////////

import dayjs from 'dayjs'
import { DateTime } from 'luxon'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

// type TsType_MaybeUnspecifiedObject = TsInterface_UnspecifiedObject | null | undefined
type TsType_ReportCellContents = string | number | boolean | null | undefined
type TsType_TwoDimensionalArray = TsType_ReportCellContents[][]

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const isValidDate = (date: Date | number | string | null) => {
  return date && Object.prototype.toString.call(date) === '[object Date]' && !isNaN(date as number)
}

///////////////////////////////
// Exports
///////////////////////////////

// Data
export const millisecondsPerDay: number = 86400000

export const returnFormattedDate = (inputDate: any, format: string): string => {
  return dayjs(returnTimestampFromUnknownDateFormat(inputDate)).format(format)
}

export const returnTimezoneAbbreviation = (inputDate: any): string => {
  let date = returnDateFromUnknownDateFormat(inputDate)
  const dt = DateTime.fromJSDate(date)
  // Check if the provided date is valid
  if (!dt.isValid) {
    return 'Invalid Date'
  }
  // Get the timezone abbreviation with DST handling
  const timezoneAbbreviation = dt.toFormat('ZZZZ')
  return timezoneAbbreviation
  // return `${timezoneAbbreviation} (${dt.zoneName})`;
}

export const returnTimestampFromUnknownDateFormat = (inputDate: any): number => {
  const dateStringRegex = /^\d{4}-\d{2}-\d{2}$/ // yyyy-mm-dd
  if (inputDate != null && isValidDate(inputDate)) {
    return inputDate.getTime()
  } else if (inputDate != null && inputDate.toDate != null) {
    return inputDate.toDate().getTime()
  } else if (dateStringRegex.test(inputDate) === true) {
    return new Date(inputDate).getTime()
  } else if (inputDate != null && !isNaN(parseInt(inputDate))) {
    return parseInt(inputDate)
  } else if (
    inputDate != null &&
    inputDate.seconds != null &&
    !isNaN(parseInt(inputDate.seconds)) != null
    // inputDate.nanoseconds != null &&
    // !isNaN( parseInt( inputDate.nanoseconds )) != null
  ) {
    return parseInt(inputDate.seconds) * 1000
  } else {
    return inputDate
  }
}

export const returnDateFromUnknownDateFormat = (inputDate: any): Date => {
  const dateStringRegex = /^\d{4}-\d{2}-\d{2}$/ // yyyy-mm-dd
  if (inputDate != null && isValidDate(inputDate)) {
    return inputDate
  } else if (inputDate != null && inputDate.toDate != null) {
    return inputDate.toDate()
  } else if (dateStringRegex.test(inputDate) === true) {
    return new Date(inputDate)
  } else if (inputDate != null && !isNaN(parseInt(inputDate))) {
    return new Date(inputDate)
  } else if (
    inputDate != null &&
    inputDate.seconds != null &&
    !isNaN(parseInt(inputDate.seconds)) != null
    // inputDate.nanoseconds != null &&
    // !isNaN( parseInt( inputDate.nanoseconds )) != null
  ) {
    return new Date(parseInt(inputDate.seconds) * 1000)
  } else {
    return inputDate
  }
}

export const returnDateCorrectedForTimezoneOffset = (inputDate: Date | string): Date => {
  let uncorrectedDate = returnDateFromUnknownDateFormat(inputDate)
  let correctedDate = new Date(
    uncorrectedDate.getFullYear(),
    uncorrectedDate.getMonth(),
    uncorrectedDate.getDate(),
    uncorrectedDate.getHours() + uncorrectedDate.getTimezoneOffset() / 60,
  )
  return correctedDate
}

export const returnSubstringAfterLastCharacter = (inputString: string, searchCharacter: string): string => {
  let outputString = ''
  if (inputString != null && searchCharacter != null) {
    let lastCharacterLocation = inputString.lastIndexOf(searchCharacter) + 1
    outputString = inputString.substring(lastCharacterLocation, inputString.length)
  }
  return outputString
}

export const formatDateToYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear()
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  let fullDateKey = year.toString() + '-' + month.toString() + '-' + day.toString()
  return fullDateKey
}

export const returnExactMinutesBetweenDates = (date1: Date, date2: Date) => {
  // Convert both dates to milliseconds
  let date1InMs = date1.getTime()
  let date2InMs = date2.getTime()
  // Calculate the difference in milliseconds
  let differenceInMs = date2InMs - date1InMs
  // Convert the difference in milliseconds to days
  let differenceInDays = differenceInMs / (1000 * 60)
  return differenceInDays
}

export const returnExactDaysBetweenDates = (date1: Date, date2: Date) => {
  // Convert both dates to milliseconds
  let date1InMs = date1.getTime()
  let date2InMs = date2.getTime()
  // Calculate the difference in milliseconds
  let differenceInMs = date2InMs - date1InMs
  // Convert the difference in milliseconds to days
  let differenceInDays = differenceInMs / (1000 * 3600 * 24)
  return differenceInDays
}

export const returnRoundedDaysBetweenDates = (date1: Date, date2: Date) => {
  // Convert both dates to milliseconds
  let date1InMs = date1.getTime()
  let date2InMs = date2.getTime()
  // Calculate the difference in milliseconds
  let differenceInMs = date2InMs - date1InMs
  // Convert the difference in milliseconds to days
  let differenceInDays = differenceInMs / (1000 * 3600 * 24)
  return Math.round(differenceInDays)
}

// Get property from objects
export const getProp = (object: any, path: any, defval: any): any => {
  if (defval == null) {
    defval = null
  }
  if (path == null) return defval
  if (typeof path === 'string') path = path.split('.')
  return path.reduce((xs: any, x: any) => {
    let returnValue = defval
    if (xs != null && xs[x] != null) {
      returnValue = xs[x]
    }
    return returnValue
  }, object)
}

export const returnStringFromValue = (value: string | number | boolean | null): string => {
  let stringValue = ''
  if (typeof value == 'string') {
    stringValue = value
  } else if (typeof value == 'number') {
    stringValue = value.toString()
  } else if (value === true) {
    stringValue = 'true'
  } else if (value === false) {
    stringValue = 'false'
  }
  return stringValue
}

// String Manipulation
export const returnFormattedPhoneNumberString = (phoneNumberString: string): string => {
  let formattedPhoneNumberString
  // Append a 1 if the phone number is 11 digits long
  if (phoneNumberString != null && phoneNumberString.length === 10) {
    phoneNumberString = '1' + phoneNumberString
  }
  if (phoneNumberString != null) {
    formattedPhoneNumberString = phoneNumberString.substring(0, 1) + ' ('
    formattedPhoneNumberString += phoneNumberString.substring(1, 4) + ') '
    formattedPhoneNumberString += phoneNumberString.substring(4, 7) + '-'
    formattedPhoneNumberString += phoneNumberString.substring(7, 11)
  } else {
    formattedPhoneNumberString = ''
  }
  return formattedPhoneNumberString
}

export const replaceNewlinesWithHtmlBreaks = (inputString: string): JSX.Element => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: inputString.replace(/\n/g, '<br/>') }}
      className="tw-inline-block"
    ></div>
  )
}

// Object / Array manipulation
export const deleteUndefinedPropertiesFromObject = (obj: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  for (let prop in obj) {
    if (typeof obj[prop] === 'undefined') {
      delete obj[prop]
    } else if (typeof obj[prop] === 'object' && obj[prop] !== null) {
      obj[prop] = deleteUndefinedPropertiesFromObject(obj[prop])
    }
  }
  return obj
}

export const isAnObject = (input: any): boolean => {
  if (input != null && typeof input === 'object') {
    return true
  } else {
    return false
  }
}

export const cloneObjectWithoutReference = (object: TsInterface_UnspecifiedObject): any => {
  return JSON.parse(JSON.stringify(object))
}

export const objectToArray = (object: TsInterface_UnspecifiedObject): any[] => {
  let array = []
  for (let index in object) {
    // if (object.hasOwnProperty(index) && object[index] != null && object[index] !== "" && index.substring(0, 1) !== "$" && typeof object[index] != "function") {
    if (Object.prototype.hasOwnProperty.call(object, index) && object[index] != null) {
      array.push(object[index])
    }
  }
  return array
}

export const arrayToObject = (array: any[], key = 'key'): TsInterface_UnspecifiedObject => {
  let object: TsInterface_UnspecifiedObject = {}
  for (let i in array) {
    if (array[i][key] != null) {
      object[array[i][key]] = array[i]
    } else {
      object[i] = array[i]
    }
  }
  return object
}

export const mergeTwoObjects = (obj1: TsInterface_UnspecifiedObject, obj2: TsInterface_UnspecifiedObject) => {
  const result: TsInterface_UnspecifiedObject = {}
  for (let key in obj1) {
    if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object' && obj1[key] !== null && obj2[key] !== null) {
      result[key] = mergeTwoObjects(obj1[key], obj2[key])
    } else if (obj1[key] instanceof Date) {
      result[key] = new Date(obj1[key])
    } else {
      result[key] = obj1[key]
    }
  }
  for (let key in obj2) {
    if (typeof obj2[key] === 'object' && typeof obj1[key] !== 'object' && obj2[key] !== null) {
      if (obj2[key] instanceof Date) {
        result[key] = new Date(obj2[key])
      } else {
        result[key] = mergeTwoObjects({}, obj2[key])
      }
    } else if (typeof obj1[key] === 'undefined') {
      if (obj2[key] instanceof Date) {
        result[key] = new Date(obj2[key])
      } else {
        result[key] = obj2[key]
      }
    }
  }
  return result
}

// Sorting
export const searchAndSort = (dataArray: TsInterface_UnspecifiedObject[], searchProp: string, searchValue: string): TsInterface_UnspecifiedObject[] => {
  let sortedData = dataArray
    .filter((prof) => {
      // Filter results by doing case insensitive match on searchProp here
      return prof[searchProp].toLowerCase().includes(searchValue.toLowerCase())
    })
    .sort((a, b) => {
      // Sort results by matching searchProp with searchValue position in searchProp
      if (a[searchProp].toLowerCase().indexOf(searchValue.toLowerCase()) > b[searchProp].toLowerCase().indexOf(searchValue.toLowerCase())) {
        return 1
      } else if (a[searchProp].toLowerCase().indexOf(searchValue.toLowerCase()) < b[searchProp].toLowerCase().indexOf(searchValue.toLowerCase())) {
        return -1
      } else {
        if (a[searchProp] > b[searchProp]) {
          return 1
        } else {
          return -1
        }
      }
    })
  return sortedData
}

export const filterByValue = (array: TsInterface_UnspecifiedObject[], prop: string, value: string) => {
  return array.filter((object) => {
    return object[prop] === value
  })
}

export const filterByPartialValue = (array: TsInterface_UnspecifiedObject[], prop: string, value: string) => {
  return array.filter((object) => {
    return object[prop].toString().toLowerCase().includes(value.toLowerCase())
  })
}

// TODO: - figure out how to specify function type as output of this function
// export const dynamicSort = (sortProperty: string, sortDirection: string | null) => {
//   let sortOrder = 1
//   if (sortDirection === 'desc') {
//     sortOrder = -1
//   }
//   return (a: any, b: any): number => {
//     let result = a[sortProperty] < b[sortProperty] ? -1 : a[sortProperty] > b[sortProperty] ? 1 : 0
//     // let result = (a[ sortProperty ] < b[ sortProperty ]) ? -1 : (a[ sortProperty ] > b[ sortProperty ]) ? 1 : -1
//     return result * sortOrder
//   }
// }

export const dynamicSort = (sortProperty: string, sortDirection: string | null) => {
  let sortOrder = 1
  if (sortDirection === 'desc') {
    sortOrder = -1
  }
  return (a: any, b: any): number => {
    const valueA = a[sortProperty] ?? ''
    const valueB = b[sortProperty] ?? ''
    if (valueA < valueB) {
      return -1 * sortOrder
    }
    if (valueA > valueB) {
      return 1 * sortOrder
    }
    // When values are equal or missing, return 0 to maintain stability
    return 0
  }
}

export const exhaustiveSort = (array: any[], sortProperty: string, sortDirection: string): any[] => {
  // Instantiate Variables
  let fieldsToSort = []
  let copiedArray = []
  let finalArray = []
  let unsortableItems: TsInterface_UnspecifiedObject = {}
  // Loop through and create an array of just mapped properties
  for (let loopIndex1 in array) {
    copiedArray.push(array[loopIndex1])
    if (array[loopIndex1] != null && array[loopIndex1][sortProperty] != null && isNaN(array[loopIndex1][sortProperty])) {
      try {
        fieldsToSort.push(array[loopIndex1][sortProperty].toLowerCase())
      } catch (rej_T) {
        fieldsToSort.push(array[loopIndex1][sortProperty])
      }
    } else if (array[loopIndex1] != null && array[loopIndex1][sortProperty] != null && !isNaN(array[loopIndex1][sortProperty])) {
      fieldsToSort.push(array[loopIndex1][sortProperty])
    } else {
      fieldsToSort.push(null)
    }
  }
  // Sort the fieldsToSort
  fieldsToSort.sort()
  // Loop through sorted fields
  for (let loopIndex2 in fieldsToSort) {
    let loopIndex2Number = parseInt(loopIndex2)
    let foundMatch = false
    for (let loopIndex3 in copiedArray) {
      let loopIndex3Number = parseInt(loopIndex3)
      if (foundMatch === false) {
        if (
          copiedArray[loopIndex3Number] != null &&
          (copiedArray[loopIndex3Number][sortProperty] === fieldsToSort[loopIndex2Number] ||
            (copiedArray[loopIndex3Number][sortProperty] != null &&
              isNaN(copiedArray[loopIndex3Number][sortProperty]) &&
              copiedArray[loopIndex3Number][sortProperty].toLowerCase() === fieldsToSort[loopIndex2Number]))
        ) {
          finalArray.push(copiedArray[loopIndex3Number])
          copiedArray.splice(loopIndex3Number, 1)
          foundMatch = true
        } else if (copiedArray[loopIndex3Number] != null && copiedArray[loopIndex3Number][sortProperty] == null) {
          unsortableItems[loopIndex3Number.toString()] = copiedArray[loopIndex3Number]
        }
      }
    }
  }
  // Add Unsortable Items to the end of the final array
  for (let unsortableLoopIndex in unsortableItems) {
    finalArray.push(unsortableItems[unsortableLoopIndex])
  }
  // Reverse Array
  if (sortDirection === 'desc' || sortDirection === '-') {
    finalArray.reverse()
  }
  return finalArray
}

export const initialsFromString = (name: string): string => {
  let initials
  if (name != null && typeof name.split === 'function' && name.split(' ')[0] != null && name.split(' ')[1]) {
    initials = `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
  } else if (name != null && typeof name.split === 'function' && name.split(' ')[0] != null) {
    initials = `${name.split(' ')[0][0]}`
  } else {
    initials = '?'
    name = '?'
  }
  return initials
}

// export const downloadCSV = (fileName: string, dataArray: TsType_TwoDimensionalArray): Promise<unknown> => {

// console.log(dataArray)

// return new Promise((resolve, reject) => {
//   let csvContent = "data:text/csv;charset=utf-8,";
//   for (let rowIndex in dataArray) {
// 	let rowIndexNumber: number = parseInt(rowIndex);
// 	let row = dataArray[rowIndex];
// 	let dataString = "";
// 	for (let cellIndex in row) {
// 	  if (dataString.length > 0) {
// 		dataString += ",";
// 	  }
// 	  let cellValue = row[cellIndex];
// 	  if (typeof cellValue === "string") {
// 		cellValue = cellValue
// 		  .replace(/#/g, 'number')
// 		  .replace(/,/g, '')
// 		  .replace(/'/g, '')
// 		  .replace(/[(]/g, '')
// 		  .replace(/[)]/g, '')
// 		  .replace(/[/]/g, '')
// 		  .replace(/&/g, 'and');
// 	  }
// 	  dataString += cellValue;
// 	}
// 	csvContent += rowIndexNumber < dataArray.length ? dataString + "\n" : dataString;
//   }

//   console.log(csvContent)

//   let encodedUri = encodeURI(csvContent);
//   let link = document.createElement("a");
//   link.setAttribute("href", encodedUri);
//   link.setAttribute("download", fileName + ".csv");
//   document.body.appendChild(link);
//   link.click();
//   resolve({ success: true });
// });
// };

export const downloadCSV = (fileName: string, dataArray: TsType_TwoDimensionalArray): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    let csvContent = 'data:text/csv;charset=utf-8,'
    // Function to properly escape a cell value for CSV
    // const escapeCellValue = (value: any) => {
    // 	if (typeof value === "string") {
    // 	return value
    // 		.replace(/"/g, '""') // Escape double quotes by doubling them
    // 		.replace(/,/g, '')  // Remove commas, so they don't interfere with CSV structure
    // 		.replace(/\n/g, '')  // Remove line breaks, so they don't interfere with CSV structure
    // 		.replace(/\r/g, '')  // Remove line breaks, so they don't interfere with CSV structure
    // 	}
    // 	return value;
    // };
    const escapeCellValue = (value: any) => {
      if (typeof value === 'string') {
        // Remove characters that can break CSV files, such as #
        value = value.replace(/"/g, '""') // Escape double quotes by doubling them
        value = value.replace(/,/g, '') // Remove commas
        value = value.replace(/\n/g, '') // Remove line breaks
        value = value.replace(/\r/g, '') // Remove carriage returns
        value = value.replace(/#/g, '') // Remove # character
        return value
      }
      return value
    }
    for (let rowIndex = 0; rowIndex < dataArray.length; rowIndex++) {
      let row = dataArray[rowIndex]
      let rowData = row.map(escapeCellValue).join(',')
      csvContent += rowData + '\n'
    }
    let encodedUri = encodeURI(csvContent)
    let link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', fileName + '.csv')
    document.body.appendChild(link)
    link.click()
    resolve({ success: true })
  })
}

export const excelColumnIndexToLetter = (index: number): string => {
  let letter = ''
  let letterOptions = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
  if (index <= 25) {
    letter = letterOptions[index]
  } else {
    let firstLetterIndex = Math.floor(index / 26) - 1
    let secondLetterIndex = index % 26
    letter = letterOptions[firstLetterIndex] + '' + letterOptions[secondLetterIndex]
  }
  return letter
}

// Random String Generation
export const generateRandomString = (length: number, chars: string | null): string => {
  let result = ''
  if (length == null) {
    length = 6
  }
  if (chars == null) {
    chars = '0123456789abcdefghijklmnopqrstuvwxyz'
  }
  for (let i = length; i > 0; --i) {
    result += chars[Math.round(Math.random() * (chars.length - 1))]
  }
  return result
}

export const underscoresToSpaces = (string: string): string => {
  return string.replace(/_/g, ' ')
}

export const underscoresToSpacesAndCapitalize = (string: string): string => {
  return string
    .replace(/_/g, ' ')
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const keyFromString = (string: string): string => {
  string = string.toLowerCase()
  return string.replace(/ /g, '_').replace(/[/]/g, '_').replace(/[*]/g, '').replace(/[()]/g, '')
}

export const generateRandomInteger = (min: number, max: number): number => {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min + 1)) + min
}

export const returnFormattedDateKey = (date: Date): string => {
  let day: number = date.getDate()
  let month: number = date.getMonth() + 1
  let year: number = date.getFullYear()
  let compositeDate: string = year.toString() + '-'
  if (month < 10) {
    compositeDate += '0' + month.toString() + '-'
  } else {
    compositeDate += month.toString() + '-'
  }
  if (day < 10) {
    compositeDate += '0' + day.toString()
  } else {
    compositeDate += day.toString()
  }
  return compositeDate
}

export const generateFormOptions = (dataObject: TsInterface_UnspecifiedObject, keyMapping: string, valueMapping: string): TsInterface_UnspecifiedObject[] => {
  let formOptions: TsInterface_UnspecifiedObject[] = []
  for (let itemKey in dataObject) {
    let loopItem = dataObject[itemKey]
    if (loopItem != null && loopItem[keyMapping] != null && loopItem[valueMapping] != null) {
      formOptions.push({ key: loopItem[keyMapping], value: loopItem[valueMapping] })
    }
  }
  return formOptions
}

export const isDaylightSavings = (date: Date) => {
  let january = new Date(date.getFullYear(), 0, 1)
  let july = new Date(date.getFullYear(), 6, 1)
  let stdTimezoneOffset = Math.max(january.getTimezoneOffset(), july.getTimezoneOffset())
  let isDst = date.getTimezoneOffset() < stdTimezoneOffset
  return isDst
}

export const formatCurrency = (number: number, currency: string = 'USD', locale: string = 'en-US') => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).format(number)
}

export const returnStandardizedUSPhoneNumber = (phoneNumber: string): string => {
  // Remove all non-numeric characters from the input
  const numericOnly = phoneNumber.replace(/\D/g, '')
  // Check if the number is 10 digits long (without country code)
  // or 11 digits long (with country code)
  if (numericOnly.length === 10) {
    // If it's 10 digits, assume it's a US number without the country code
    return `+1${numericOnly}`
  } else if (numericOnly.length === 11 && numericOnly.startsWith('1')) {
    // If it's 11 digits and starts with '1', assume it's a US number with the country code
    return `+${numericOnly}`
  } else {
    // If the number does not conform to expected formats, return an error message
    return 'Invalid phone number'
  }
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Divider, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import { Json } from 'rfbp_core/components/code_display'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

const rJSX_LegalDocumentSection = (section: TsInterface_UnspecifiedObject): JSX.Element => {
  let legalDocSectionJSX: JSX.Element = <></>
  if (section.type === 'header') {
    legalDocSectionJSX = (
      <Box className="tw-mb-8">
        <Typography variant="h4">{section.header}</Typography>
      </Box>
    )
  } else if (section.type === 'text') {
    legalDocSectionJSX = (
      <Box className="tw-mb-8">
        <Typography variant="h6">{section.subheader}</Typography>
        {section.paragraphs.map((paragraph: string, index: number) => (
          <Typography
            key={index}
            variant="body1"
            className="tw-ml-6 tw-mb-2"
          >
            {paragraph}
          </Typography>
        ))}
      </Box>
    )
  } else if (section.type === 'linebreak') {
    legalDocSectionJSX = (
      <Box className="tw-my-8">
        <Divider />
      </Box>
    )
  } else {
    legalDocSectionJSX = <Json data={section} />
  }
  return legalDocSectionJSX
}

export const rJSX_LegalDocument = (legalDocContent: TsInterface_UnspecifiedObject[]): JSX.Element => {
  let legalDocJSX: JSX.Element = (
    <Box>
      {legalDocContent.map((section: TsInterface_UnspecifiedObject, index: number) => (
        <Box key={index}>{rJSX_LegalDocumentSection(section)}</Box>
      ))}
    </Box>
  )
  return legalDocJSX
}

export const returnPowerwallInstallAgreementText = (versionKey: string): TsInterface_UnspecifiedObject[] => {
  // 25 May 2023
  let versionHistory: TsInterface_UnspecifiedObject = {
    '2023-05-25': [
      {
        type: 'header',
        header: `Powerwall Installation Agreement`,
      },
      {
        type: 'text',
        subheader: null,
        paragraphs: [
          `Thank you for your purchase! We’re very excited that you’re joining the millions of people worldwide who are making the switch to renewable energy. This document (the “Agreement”) outlines some basic terms that will govern the installation of your Powerwalls (the “System”) including what you’re getting, your rights, our obligations and how we will resolve any disputes. This document also includes some exhibits that have required disclosures for your review and acceptance.`,
          `This Agreement is between you (“you”, “your”) and ETW Energy (TX), LLC (“ETW”, “we”, “us”, “our”), a Delaware corporation. You and ETW acknowledge that Powerwall units are to be installed on your property specified on the Pricing Sheet (the “Home”). The Agreement is effective on the date you placed your order with us (the “Effective Date”) and includes all of the items that are part of this document and the Pricing Sheet. You are entitled to a completely filled out copy of this Agreement signed by both you and ETW before any work begins.`,
        ],
      },
      {
        type: 'text',
        subheader: `1. Your Purchase Price.`,
        paragraphs: [
          `You have agreed to purchase your System at the price described in your Pricing Sheet. Your Pricing Sheet, which is considered part of this Agreement, will be finalized after the Site Audit and includes taxes. You have 10 days after you receive the Pricing Sheet and this Agreement to reject them. If you don’t reject them, or if you move forward with scheduling your installation, that means you are agreeing to the Pricing Sheet and the terms in this Agreement. You are responsible for paying any property taxes on your System that your state or local jurisdiction may levy.`,
        ],
      },
      {
        type: 'text',
        subheader: `2. Updates to Pricing Sheet.`,
        paragraphs: [
          `Even though we have completed the initial design of your System, we might need to make changes to that design, which also means we might need to update the pricing or System size based on a variety of factors, such as installation complexity or product availability. If that happens, we will update the Pricing Sheet. You have 10 days after you receive the updated Pricing Sheet to reject it. If you don’t reject it, or if you move forward with scheduling your installation, that means you are agreeing to the updated Pricing Sheet and authorize us to develop and submit permit packets and otherwise prepare for your upcoming installation. The updated Pricing Sheet replaces any prior Pricing Sheet and is considered part of this Agreement that you are agreeing to (unless you rejected the update).`,
        ],
      },
      {
        type: 'text',
        subheader: `3. Cancellation.`,
        paragraphs: [
          `At any point prior to the time when you schedule your installation, you can cancel your order for any reason. And so can we. We both just need to let the other know in writing so there is no misunderstanding. We will return your order fee for any cancellations up until when you have agreed to the final System design and Pricing Sheet, after which your order fee is non-refundable. For clarity, you will be responsible for the full purchase price once you schedule the installation, provided we complete the install and activate your system.`,
        ],
      },
      {
        type: 'text',
        subheader: `4. Our responsibility.`,
        paragraphs: [
          `Payment of your purchase price in full for your System includes ETW designing, permitting, HOA approvals, installing, inspecting and obtaining a Permission to Operate (or “PTO”) from your utility, to the extent that these steps are required for your System. There can sometimes be delays in the process and ETW will work with you and third parties (e.g., your HOA, your utility) to try and minimize such delays.`,
        ],
      },
      {
        type: 'text',
        subheader: `5. Installation.`,
        paragraphs: [
          `We will contact you to schedule installation of your System. During installation, we might choose to make minor repairs commonly needed to install the System at no additional cost to you (like simple electric upgrades and site preparation). We promise to repair or pay for damage we directly cause to your home or your property during installation of your System. If we fail to do that, please let us know, and we will make the repair (or have someone repair it) at our cost (and that will be your only remedy). But you need to let us know in writing and within a certain amount of time following the damage: within 5 years for Powerwall installations and 1 year for anything else unrelated to your System.`,
        ],
      },
      {
        type: 'text',
        subheader: `6. Billing.`,
        paragraphs: [
          `You can pay for the System via a credit card or ACH. Prior to install, we will ask you to have a credit card or ACH on file, which we will bill within 10 days after PTO. If ETW does not receive payment within 7 days after this date, we may charge you an administrative fee at 1.5% per month of the outstanding balance, unless limited to a smaller amount by the applicable laws in your area. This administrative fee is not an interest charge, finance charge, time price differential or other such charge or payment of a similar nature.`,
        ],
      },
      {
        type: 'text',
        subheader: `7. Equipment Limited Warranties.`,
        paragraphs: [
          `Your Powerwall units are covered directly by Tesla’s Powerwall Limited Warranty. Please refer to Tesla’s website or sale terms for details. Specifically, ETW does not provide equipment warranties.`,
        ],
      },
      {
        type: 'text',
        subheader: `8. Workmanship Warranties.`,
        paragraphs: [
          `ETW offers a 5 year (the “Limited Warranty Period”) limited workmanship warranty (the “Workmanship Warranty”), which protects you against any material defects associated with the installation of the System. Workmanship begins after installation is completed. During the Limited Warranty Period we will, at our expense, repair or replace any material errors related to our work under this Agreement. Our Workmanship Warranty does not cover normal wear and tear, performing repair or replacement work on the System, failures caused by manufacturer’s defects or user error, cosmetic issues (fading of paints and finishes), or damage resulting from mold or fungus.`,
          `Other than the statements above, ETW does not make any other express or implied warranty to you. You own the System and are solely financially responsible for any needed repairs or replacements except those specified in our limited warranty obligations above. We aren’t responsible for performing service or repairs due to your negligence, acts of God or Force Majeure, exposure to harmful materials and chemicals, vandalism, theft, or damaging the System, or if someone other than ETW does work on any part of the System. If you desire for ETW to perform repair or replacement work of the System that has broken, outside of our limited warranty obligations, ETW may choose to offer to perform this work at a fee to you.`,
        ],
      },
      {
        type: 'text',
        subheader: `9. Limitation of Liability.`,
        paragraphs: [
          `If there is a dispute, the maximum amount that either of us will have to pay the other is the price in the Price Sheet (as updated, if applicable) for anything arising out of this Agreement. Also, neither of us will have to pay the other for any indirect damages (referred to as “special or consequential damages”). The following capitalized paragraphs are important and provide more specificity on these limitations.`,
          `NOTWITHSTANDING ANY BREACH OF THIS AGREEMENT, ANY FAILURE OF THE System, OR ANY NEGLIGENT ACT THAT CAUSED ANY INJURY OR LOSS (WHETHER PROPERTY DAMAGE, PERSONAL INJURY, OR DEATH) TO ANYONE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE AND YOU AGREE THAT, UNLESS SUCH INJURY OR LOSS WAS CAUSED BY A PARTY’S GROSS NEGLIGENCE, FRAUD, OR WILLFUL INJURY, SUCH PARTY’S LIABILITY ARISING OUT OF OR RELATING TO (1) EQUIPMENT REPAIRS OR REPLACEMENT UNDER THIS AGREEMENT, SHALL IN NO EVENT EXCEED THE TOTAL CONTRACT PRICE, AS THAT TERM IS DEFINED ABOVE, AND (2) DAMAGE TO PERSONS AND PROPERTY, SHALL IN NO EVENT EXCEED $1,000,000. YOU AND WE AGREE THAT THIS AMOUNT IS A FAIR REPRESENTATION OF THE DAMAGES THAT YOU OR WE EXPECT TO INCUR IN THE CASE OF ANY INJURY OR LOSS HEREUNDER.`,
          `TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER YOU NOR WE MAY BRING A CLAIM AGAINST THE OTHER PARTY OR SUCH PARTY’S AFFILIATES, OWNERS, DIRECTORS, EMPLOYEES, AGENTS, CONTRACTORS, OR SUCCESSORS AND ASSIGNS (COLLECTIVELY, THE “RELATED PARTIES”) FOR ANY SPECIAL, EXEMPLARY, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES (WHETHER OR NOT THE CLAIM THEREFOR IS BASED ON CONTRACT, TORT, DUTY IMPOSED BY LAW, OR OTHERWISE), IN CONNECTION WITH, ARISING OUT OF, OR IN ANY WAY RELATED TO THE TRANSACTIONS CONTEMPLATED BY THIS AGREEMENT OR ANY ACT OR OMISSION OR EVENT OCCURRING IN CONNECTION THEREWITH. YOU FURTHER AGREE THAT NO CLAIM, LAWSUIT, OR ANY OTHER LEGAL OR ARBITRATION PROCEEDING IN CONNECTION WITH, ARISING OUT OF, OR IN ANY WAY RELATED TO THIS AGREEMENT MAY BE BROUGHT MORE THAN ONE (1) YEAR AFTER THE INCIDENT GIVING RISE TO SUCH CLAIM, OR AS LIMITED BY APPLICABLE LAW.`,
          `IN NO EVENT WILL WE BE LIABLE TO YOU OR TO ANYONE FOR LOSS OF PROFITS, DAMAGES FROM POWER INTERRUPTION OR OTHER SPECIAL, CONSEQUENTIAL, INCIDENTAL OR INDIRECT DAMAGES.`,
        ],
      },
      {
        type: 'text',
        subheader: `10. Governing Law.`,
        paragraphs: [
          `This Agreement is governed by the laws of the State where your System is installed. You agree that ETW has the right to obtain photographic images of the System and the Home, and to use such photographic images for internal and quality control purposes.`,
        ],
      },
      {
        type: 'text',
        subheader: `11. Indemnification.`,
        paragraphs: [
          `To the fullest extent permitted by applicable law, you agree to indemnify, advance expenses, and hold harmless ETW and our Related Parties from any and all claims, actions, costs, expenses (including reasonable attorneys’ fees and expenses), damages, liabilities, penalties, losses, obligations, injuries, demands, and liens of any kind or nature in connection with, arising out of, or in any way related to your breach of this Agreement, your negligence or willful misconduct, or your violation of law. Your indemnification obligations under this section shall not apply if the harm or damage that is the basis for such claim occurred while one of our employees or agents was at your Property and such harm or damage was caused by the negligence or willful misconduct of such employee or agent.`,
          `ETW is not responsible for any delay or failure in the performance under this Agreement due to Force Majeure. “Force Majeure” means any event, condition or circumstance beyond the reasonable control of, and not caused by, ETW’s fault or negligence. These include acts of God, war, riot, terrorism, or a strike, lockout or significant labor dispute.`,
        ],
      },
      {
        type: 'text',
        subheader: `12. Notices in writing.`,
        paragraphs: [
          `All notices, demands, or requests related to this Agreement must be in writing and will be sent to you at your Home’s mailing address or email address that you provided to us and is available on your profile. We will primarily use email to communicate with you, unless you request correspondence solely via first class mail. Any notice or communication made in electronic form will have the same legal effect and enforceability as if made in non-electronic form.`,
          `Correspondence, notices, demands or requests should be sent to ETW at: 3331 N Panam Expressway, San Antonio TX 78219 and customercare@etwenergy.com`,
        ],
      },
      {
        type: 'text',
        subheader: `13. Agreement to Arbitrate.`,
        paragraphs: [
          `Please read this part carefully because it means you waive your right to bring a class action lawsuit against ETW and our partners. You and we mutually agree that any party may elect to settle any legal disagreements confidentially via binding arbitration in the nearest AAA (arbitration services provider defined below) jurisdiction to your home. The details of how this works are as follows:`,
          `If you have a dispute with ETW or its affiliates (“ETW”) arising out of or relating to any aspect of this Agreement or your purchase of the System, please send us an email to customercare@etwenergy.com, describing your dispute and how you would like it resolved. If it is not resolved within 60 days from the date of your email, you agree that your dispute can only be resolved by a single arbitrator in an arbitration administered by the American Arbitration Association (AAA) under its Consumer Arbitration Rules. This includes claims arising before you ordered your System (such as claims related to statements ETW made about our products). We will pay all AAA fees for any arbitration, which will be held in the city or county of your legal residence. To learn more about the Rules and how to begin an arbitration, you can call any AAA office or go to www.adr.org. The arbitrator can only resolve disputes between you and ETW, and cannot consolidate claims from others without consent from you, ETW, and the others. You can only bring claims in arbitration against ETW in your individual capacity and not as a plaintiff or class member in any class or representative action (and the same is true for ETW). If a court or arbitrator decides that any part of this agreement to arbitrate cannot be enforced as to a specific claim for relief or remedy (such as what lawyers call “injunctive” or “declaratory” relief), then that claim or remedy (and only that claim or remedy) will be carved out of the arbitration and can be filed in court; all other claims must be arbitrated. If you prefer, you can take your individual dispute to a small claims court instead.`,
        ],
      },
      {
        type: 'linebreak',
      },
      {
        type: 'header',
        header: `EXHIBIT A: TEXAS DISCLOSURE STATEMENT`,
      },
      {
        type: 'text',
        subheader: `KNOW YOUR RIGHTS AND RESPONSIBILITIES UNDER THE LAW`,
        paragraphs: [
          `You are about to enter into a transaction to build a new home or remodel existing residential property. Texas law requires your contractor to provide you with this brief overview of some of your rights, responsibilities, and risks in this transaction.`,
        ],
      },
      {
        type: 'text',
        subheader: `CONVEYANCE TO CONTRACTOR NOT REQUIRED`,
        paragraphs: [
          `Your contractor may not require you to convey your real property to your contractor as a condition to the agreement for the construction of improvements on your property.`,
        ],
      },
      {
        type: 'text',
        subheader: `KNOW YOUR CONTRACTOR`,
        paragraphs: [
          `Before you enter into your agreement for the construction of improvements to your real property, make sure that you have investigated your contractor. Obtain and verify references from other people who have used the contractor for the type and size of construction project on your property.`,
        ],
      },
      {
        type: 'text',
        subheader: `GET IT IN WRITING`,
        paragraphs: [
          `Make sure that you have a written agreement with your contractor that includes: (1) a description of the work the contractor is to perform; (2) the required or estimated time for completion of the work; (3) the cost of the work or how the cost will be determined; and (4) the procedure and method of payment, including provisions for statutory retainage and conditions for final payment. If your contractor made a promise, warranty, or representation to you concerning the work the contractor is to perform, make sure that promise, warranty, or representation is specified in the written agreement. An oral promise that is not included in the written agreement may not be enforceable under Texas law.`,
        ],
      },
      {
        type: 'text',
        subheader: `READ BEFORE YOU SIGN`,
        paragraphs: [
          `Do not sign any document before you have read and understood it. NEVER SIGN A DOCUMENT THAT INCLUDES AN UNTRUE STATEMENT. Take your time in reviewing documents. If you borrow money from a lender to pay for the improvements, you are entitled to have the loan closing documents furnished to you for review at least one business day before the closing. Do not waive this requirement unless a bona fide emergency or another good cause exists, and make sure you understand the documents before you sign them. If you fail to comply with the terms of the documents, you could lose your property. You are entitled to have your own attorney review any documents. If you have any question about the meaning of a document, consult an attorney.`,
        ],
      },
      {
        type: 'text',
        subheader: `GET A LIST OF SUBCONTRACTORS AND SUPPLIERS`,
        paragraphs: [
          `Before construction commences, your contractor is required to provide you with a list of the subcontractors and suppliers the contractor intends to use on your project. Your contractor is required to supply updated information on any subcontractors and suppliers added after the list is provided. Your contractor is not required to supply this information if you sign a written waiver of your rights to receive this information.`,
        ],
      },
      {
        type: 'text',
        subheader: `MONITOR THE WORK`,
        paragraphs: [
          `Lenders and governmental authorities may inspect the work in progress from time to time for their own purposes. These inspections are not intended as quality control inspections. Quality control is a matter for you and your contractor. To ensure that your home is being constructed in accordance with your wishes and specifications, you should inspect the work yourself or have your own independent inspector review the work in progress.`,
        ],
      },
      {
        type: 'text',
        subheader: `MONITOR PAYMENTS`,
        paragraphs: [
          `If you use a lender, your lender is required to provide you with a periodic statement showing the money disbursed by the lender from the proceeds of your loan. Each time your contractor requests payment from you or your lender for work performed, your contractor is also required to furnish you with a disbursement statement that lists the name and address of each subcontractor or supplier that the contractor intends to pay from the requested funds. Review these statements and make sure that the money is being properly disbursed.`,
        ],
      },
      {
        type: 'text',
        subheader: `CLAIMS BY SUBCONTRACTORS AND SUPPLIERS`,
        paragraphs: [
          `Under Texas law, if a subcontractor or supplier who furnishes labor or materials for the construction of improvements on your property is not paid, you may become liable and your property may be subject to a lien for the unpaid amount, even if you have not contracted directly with the subcontractor or supplier. To avoid liability, you should take the following actions:`,
          `1. If you receive a written notice from a subcontractor or supplier, you should withhold payment from your contractor for the amount of the claim stated in the notice until the dispute between your contractor and the subcontractor or supplier is resolved. If your lender is disbursing money directly to your contractor, you should immediately provide a copy of the notice to your lender and instruct the lender to withhold payment in the amount of the claim stated in the notice. If you continue to pay the contractor after receiving the written notice without withholding the amount of the claim, you may be liable and your property may be subject to a lien for the amount you failed to withhold.`,
          `2. During construction and for 30 days after final completion, termination, or abandonment of the contract by the contractor, you should withhold or cause your lender to withhold 10 percent of the amount of payments made for the work performed by your contractor. This is sometimes referred to as ’statutory retainage.’ If you choose not to withhold the 10 percent for at least 30 days after final completion, termination, or abandonment of the contract by the contractor and if a valid claim is timely made by a claimant and your contractor fails to pay the claim, you may be personally liable and your property may be subject to a lien up to the amount that you failed to withhold.`,
          `If a claim is not paid within a certain time period, the claimant is required to file a mechanic’s lien affidavit in the real property records in the county where the property is located. A mechanic’s lien affidavit is not a lien on your property, but the filing of the affidavit could result in a court imposing a lien on your property if the claimant is successful in litigation to enforce the lien claim.`,
        ],
      },
      {
        type: 'text',
        subheader: `SOME CLAIMS MAY NOT BE VALID `,
        paragraphs: [
          `When you receive a written notice of a claim or when a mechanic’s lien affidavit is filed on your property, you should know your legal rights and responsibilities regarding the claim. Not all claims are valid. A notice of a claim by a subcontractor or supplier is required to be sent, and the mechanic’s lien affidavit is required to be filed, within strict time periods. The notice and the affidavit must contain certain information. All claimants may not fully comply with the legal requirements to collect on a claim. If you have paid the contractor in full before receiving a notice of a claim and have fully complied with the law regarding statutory retainage, you may not be liable for that claim. Accordingly, you should consult your attorney when you receive a written notice of a claim to determine the true extent of your liability or potential liability for that claim.`,
        ],
      },
      {
        type: 'text',
        subheader: `OBTAIN A LIEN RELEASE AND A BILLS-PAID AFFIDAVIT `,
        paragraphs: [
          `When you receive a notice of claim, do not release withheld funds without obtaining a signed and notarized release of lien and claim from the claimant. You can also reduce the risk of having a claim filed by a subcontractor or supplier by requiring as a condition of each payment made by you or your lender that your contractor furnish you with an affidavit stating that all bills have been paid. Under Texas law, on final completion of the work and before final payment, the contractor is required to furnish you with an affidavit stating that all bills have been paid. If the contractor discloses any unpaid bill in the affidavit, you should withhold payment in the amount of the unpaid bill until you receive a waiver of lien or release from that subcontractor or supplier.`,
        ],
      },
      {
        type: 'text',
        subheader: `OBTAIN TITLE INSURANCE PROTECTION`,
        paragraphs: [
          `You may be able to obtain a title insurance policy to insure that the title to your property and the existing improvements on your property are free from liens claimed by subcontractors and suppliers. If your policy is issued before the improvements are completed and covers the value of the improvements to be completed, you should obtain, on the completion of the improvements and as a condition of your final payment, a ’completion of improvements’ policy endorsement. This endorsement will protect your property from liens claimed by subcontractors and suppliers that may arise from the date the original title policy is issued to the date of the endorsement.”`,
        ],
      },
      {
        type: 'linebreak',
      },
      {
        type: 'header',
        header: `EXHIBIT B: FAQS ON ARBITRATION`,
      },
      {
        type: 'text',
        subheader: `What is mediation?`,
        paragraphs: [
          `Mediation is an informal negotiation assisted by a neutral third party (the mediator). Pursuant to the Parties’ Agreement, mediation is a precondition to any arbitration.`,
        ],
      },
      {
        type: 'text',
        subheader: `What is arbitration? `,
        paragraphs: [
          `An alternative to a court case. In arbitration, a third-party arbitrator (“TPA”) resolves “Disputes” in a hearing. It is less formal than a court case.`,
        ],
      },
      {
        type: 'text',
        subheader: `Is arbitration different from court and jury trials? `,
        paragraphs: [
          `Yes. The hearing is private. There is no jury. It is usually less formal, faster, and less expensive than a lawsuit. Pre-hearing fact finding (called “discovery”) is limited. Appeals are limited. The arbitrator’s findings are binding, and courts rarely overturn arbitration awards. The arbitrator may grant any legal or equitable remedy available in court.`,
        ],
      },
      {
        type: 'text',
        subheader: `Who does this cover? `,
        paragraphs: [
          `You, us, and certain Related Parties (defined above). Either you or we may, after the required mediation and without the other’s consent, elect to resolve disputes by binding arbitration.`,
        ],
      },
      {
        type: 'text',
        subheader: `What does this cover?`,
        paragraphs: [
          `All Disputes (except certain Disputes about this clause). This governs all disputes that would usually be decided in court and are between us (or any Related Party) and you, including without limitation all claims related to or arising out of this Agreement, the Equipment or our relationship with you (“Disputes”). Disputes include claims related to amendments, Lease Disclosures, Change Orders, collections, privacy and Customer Information, claims related to the validity of this Agreement, AND THE ARBITRABILITY OF ANY DISPUTE(S). In short, Disputes has the broadest reasonable meaning.`,
        ],
      },

      {
        type: 'text',
        subheader: `Who handles the mediation and arbitration? `,
        paragraphs: [`American Arbitration Association (“AAA”). The arbitration company will be the AAA, 1.800.778.7879, www.adr.org`],
      },
      {
        type: 'text',
        subeheader: `What are the rules of the arbitration? `,
        paragraphs: [
          `Those in this clause along with the AAA Rules. Arbitrations are conducted under this Clause and the applicable AAA Active Rules and Procedures in effect at the time the arbitration is commenced. This Agreement is also subject to the AAA Consumer Arbitration Rules pursuant to the Consumer Due Process Protocol, which set forth certain protections to you (including a maximum filing fee). Any other arbitration rules that conflict with this Clause don't apply.`,
        ],
      },
      {
        type: 'text',
        subeheader: `Where will the arbitration hearing be held? `,
        paragraphs: [
          `In your hometown area. If the Parties don't agree to the locale where the hearing is to be held, the AAA will determine the location of the hearing. You can find more information in the AAA Policy on Consumer Arbitrations, which is available here - https://www.adr.org/sites/default/files/Consumer_Rules_Web_0.pdf.`,
        ],
      },
      {
        type: 'text',
        subeheader: `Can Disputes be brought in court?`,
        paragraphs: [
          `Sometimes. Either party may bring a lawsuit if the other party does not demand arbitration. We will not demand arbitration of any lawsuit you bring as an individual action in small-claims court. Neither You nor We waive the right to arbitrate by using self help remedies, such as repossession or by filing an action to recover the Equipment, deficiency balance, or for individual injunctive relief.`,
        ],
      },
      {
        type: 'text',
        subeheader: `Are you giving up any rights?`,
        paragraphs: [
          `Yes. For Disputes subject to this clause, you give up your right to:`,
          `- have juries decide Disputes;`,
          `- have courts, other than small-claims courts, decide Disputes; `,
          `- serve as a private attorney general or in a representative capacity; `,
          `- join a Dispute you have with a dispute by other consumers; `,
          `- bring or be a class member in a class action or class arbitration; `,
          `- and have a jury trial. `,
        ],
      },
      {
        type: 'text',
        subeheader: `Can you or another consumer start class arbitration? `,
        paragraphs: [
          `No. AAA is not allowed to handle any Dispute between the Parties on a class or representative basis. All Disputes subject to this clause must be decided in an INDIVIDUAL arbitration. This clause will be void if a court rules that the TPA can decide a Dispute on a class basis and the court’s ruling is not reversed on appeal. `,
        ],
      },
      {
        type: 'text',
        subeheader: `What law applies? `,
        paragraphs: [
          `The Federal Arbitration Act (“FAA”). This Agreement involves interstate commerce. THUS, the FAA governs this clause. The TPA must apply substantive law consistent with the FAA. The TPA must honor statutes of limitation and privilege rights. Punitive damages are governed by the constitutional standards that apply in judicial proceedings. `,
        ],
      },
      {
        type: 'text',
        subeheader: `Will anything you do make the arbitration clause ineffective? `,
        paragraphs: [
          `No. This clause stays in force even if you: (1) cancel this Agreement; (2) default, renew, prepay, or pay the Agreement in full; or (3) go into or through bankruptcy. `,
        ],
      },
      {
        type: 'text',
        subeheader: `Will the arbitration clause survive termination of this Agreement? `,
        paragraphs: [`Yes. This clause will remain in effect for Disputes that commence even after the Agreement has terminated.`],
      },
      {
        type: 'linebreak',
      },
      {
        type: 'header',
        header: `EXHIBIT C: OUR INSURANCE`,
      },
      {
        type: 'text',
        subeheader: null,
        paragraphs: [
          `ETW carries certain insurance policies covering our company and our employees, including commercial general liability insurance written by Westchester Surplus Lines Ins. Co. (Policy No. G47366422001) and worker’s compensation insurance for all our employees written by Indemnity Insurance Co. of North America (Policy No. C51844488). `,
          `Our insurance DOES NOT cover your negligence or willful damage of the Equipment.`,
        ],
      },
      {
        type: 'text',
        subeheader: `Our Subcontractors’ Insurance`,
        paragraphs: [
          `We require our subcontractors to carry comprehensive insurance coverage, including: (1) worker’s compensation; (2) employer’s liability of at least one million dollars; (3) commercial general liability of at least one million dollars per occurrence and two million dollars annually in aggregate; (4) commercial automobile liability of at least one million dollars per accident covering both bodily injury and property damage; (5) excess liability insurance of up to one million dollars per occurrence; and (6) any other insurance required by applicable laws or regulation.`,
        ],
      },
    ],
    '2023-06-15': [
      {
        type: 'header',
        header: `Powerwall Installation Agreement`,
      },
      {
        type: 'text',
        subheader: null,
        paragraphs: [
          `Thank you for your purchase! We’re very excited that you’re joining the millions of people worldwide who are making the switch to renewable energy. This document (the “Agreement”) outlines some basic terms that will govern the installation of your Powerwalls (the “System”) including what you’re getting, your rights, our obligations and how we will resolve any disputes. This document also includes some exhibits that have required disclosures for your review and acceptance.`,
          `This Agreement is between you (“you”, “your”) and ETW Energy (TX), LLC (“ETW”, “we”, “us”, “our”), a Delaware corporation. You and ETW acknowledge that Powerwall units are to be installed on your property specified on the Pricing Sheet (the “Home”). The Agreement is effective on the date you placed your order with us (the “Effective Date”) and includes all of the items that are part of this document and the Pricing Sheet. You are entitled to a completely filled out copy of this Agreement and a Pricing Sheet before any work begins.`,
        ],
      },
      {
        type: 'text',
        subheader: `1. Your Purchase Price.`,
        paragraphs: [
          `You have agreed to purchase your System at the price described in your Pricing Sheet. Your Pricing Sheet, which is considered part of this Agreement, will be finalized after the Site Audit and includes applicable sales tax. You are responsible for paying any property taxes on your System that your state or local jurisdiction may levy.`,
        ],
      },
      {
        type: 'text',
        subheader: `2. Updates to Pricing Sheet.`,
        paragraphs: [
          `Even though we have completed the initial design of your System, we might need to make changes to that design, which also means we might need to update the pricing or System size based on a variety of factors, such as installation complexity or product availability. If that happens, we will update the Pricing Sheet and will explicitly ask for your signature. If you move forward with scheduling your installation, that means you are agreeing to the updated Pricing Sheet and authorize us to develop and submit permit packets and otherwise prepare for your upcoming installation. The updated Pricing Sheet replaces any prior Pricing Sheet and is considered part of this Agreement that you are agreeing to (unless you rejected the update). `,
        ],
      },
      {
        type: 'text',
        subheader: `3. Cancellation.`,
        paragraphs: [
          `At any point prior to the time when you schedule your installation, you can cancel your order for any reason. And so can we. We both just need to let the other know in writing so there is no misunderstanding. We will return your order fee for any cancellations up until when you have agreed to the final System design and Pricing Sheet, after which your order fee is non-refundable. For clarity, you will be responsible for the full purchase price once you schedule the installation, provided we complete the install and activate your system.`,
        ],
      },
      {
        type: 'text',
        subheader: `4. Our responsibility.`,
        paragraphs: [
          `Payment of your purchase price in full for your System includes ETW designing, permitting, HOA approvals, installing, inspecting and obtaining a Permission to Operate (or “PTO”) from your utility, to the extent that these steps are required for your System. There can sometimes be delays in the process and ETW will work with you and third parties (e.g., your HOA, your utility) to try and minimize such delays.`,
        ],
      },
      {
        type: 'text',
        subheader: `5. Installation.`,
        paragraphs: [
          `You have represented to us that all necessary parties have consented to us installing the System at the Home. We will contact you to schedule installation of your System. During installation, we might choose to make minor repairs commonly needed to install the System at no additional cost to you (like simple electric upgrades and site preparation). We promise to repair or pay for damage we directly cause to your home or your property during installation of your System. If we fail to do that, please let us know, and we will make the repair (or have someone repair it) at our cost (and that will be your only remedy). But you need to let us know in writing and within a certain amount of time following the damage: within 2 years for Powerwall installations and 1 year for anything else unrelated to your System. If you decide to uninstall the system during this time, we are not responsible for restoring the home to its original condition.`,
        ],
      },
      {
        type: 'text',
        subheader: `6. Billing.`,
        paragraphs: [
          `You can pay for the System via a credit card or ACH. Prior to install, we will ask you to have a credit card or ACH on file, which we will bill within 10 days after PTO. If ETW does not receive payment within 7 days after this date, we may charge you an administrative fee at 1.5% per month of the outstanding balance, unless limited to a smaller amount by the applicable laws in your area. This administrative fee is not an interest charge, finance charge, time price differential or other such charge or payment of a similar nature.`,
        ],
      },
      {
        type: 'text',
        subheader: `7. Equipment Limited Warranties.`,
        paragraphs: [
          `ETW does not provide equipment warranties. Please refer to Tesla’s website or sale terms for details on the warranties offered on the System.`,
        ],
      },
      {
        type: 'text',
        subheader: `8. Workmanship Warranties.`,
        paragraphs: [
          `ETW offers a 2 year (the “Limited Warranty Period”) limited workmanship warranty (the “Workmanship Warranty”), which protects you against any material defects associated with the installation of the System. The Limited Warranty Period begins after installation is completed. During the Limited Warranty Period we will, at our expense, repair or replace any material errors caused by negligent work done by us under this Agreement. Our Workmanship Warranty does not cover normal wear and tear, performing repair or replacement work on the System, failures caused by manufacturer’s defects or user error, cosmetic issues (fading of paints and finishes), or damage resulting from mold or fungus.`,
          `Other than the statements above, ETW does not make any other express or implied warranty to you. You own the System and are solely financially responsible for any needed repairs or replacements except those specified in our limited warranty obligations above. We aren’t responsible for performing service or repairs due to your negligence, acts of God or Force Majeure, exposure to harmful materials and chemicals, vandalism, theft, or damaging the System, or if someone other than ETW does work on any part of the System. If you desire for ETW to perform repair or replacement work of the System that has broken, outside of our limited warranty obligations, ETW may choose to offer to perform this work at a fee to you.`,
        ],
      },
      {
        type: 'text',
        subheader: `9. Limitation of Liability.`,
        paragraphs: [
          `If there is a dispute, the maximum amount that either of us will have to pay the other is the price in the Price Sheet (as updated, if applicable) for anything arising out of this Agreement. Also, neither of us will have to pay the other for any indirect damages (referred to as “special or consequential damages”). The following capitalized paragraphs are important and provide more specificity on these limitations.`,
          `NOTWITHSTANDING ANY BREACH OF THIS AGREEMENT, ANY FAILURE OF THE System, OR ANY NEGLIGENT ACT THAT CAUSED ANY INJURY OR LOSS (WHETHER PROPERTY DAMAGE, PERSONAL INJURY, OR DEATH) TO ANYONE, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE AND YOU AGREE THAT, UNLESS SUCH INJURY OR LOSS WAS CAUSED BY A PARTY’S GROSS NEGLIGENCE, FRAUD, OR WILLFUL INJURY, SUCH PARTY’S LIABILITY ARISING OUT OF OR RELATING TO (1) EQUIPMENT REPAIRS OR REPLACEMENT UNDER THIS AGREEMENT, SHALL IN NO EVENT EXCEED THE CASH PRICE IDENTIFIED IN THE PRICING SHEET, AND (2) DAMAGE TO PERSONS AND PROPERTY, SHALL IN NO EVENT EXCEED $1,000,000. YOU AND WE AGREE THAT THIS AMOUNT IS A FAIR REPRESENTATION OF THE DAMAGES THAT YOU OR WE EXPECT TO INCUR IN THE CASE OF ANY INJURY OR LOSS HEREUNDER.`,
          `TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER YOU NOR WE MAY BRING A CLAIM AGAINST THE OTHER PARTY OR SUCH PARTY’S AFFILIATES, OWNERS, DIRECTORS, EMPLOYEES, AGENTS, CONTRACTORS, OR SUCCESSORS AND ASSIGNS (COLLECTIVELY, THE “RELATED PARTIES”) FOR ANY SPECIAL, EXEMPLARY, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES (WHETHER OR NOT THE CLAIM THEREFOR IS BASED ON CONTRACT, TORT, DUTY IMPOSED BY LAW, OR OTHERWISE), IN CONNECTION WITH, ARISING OUT OF, OR IN ANY WAY RELATED TO THE TRANSACTIONS CONTEMPLATED BY THIS AGREEMENT OR ANY ACT OR OMISSION OR EVENT OCCURRING IN CONNECTION THEREWITH. YOU FURTHER AGREE THAT NO CLAIM, LAWSUIT, OR ANY OTHER LEGAL OR ARBITRATION PROCEEDING IN CONNECTION WITH, ARISING OUT OF, OR IN ANY WAY RELATED TO THIS AGREEMENT MAY BE BROUGHT MORE THAN ONE (1) YEAR AFTER THE INCIDENT GIVING RISE TO SUCH CLAIM, OR AS LIMITED BY APPLICABLE LAW.`,
          `IN NO EVENT WILL WE BE LIABLE TO YOU OR TO ANYONE FOR LOSS OF PROFITS, DAMAGES FROM POWER INTERRUPTION OR OTHER SPECIAL, CONSEQUENTIAL, INCIDENTAL OR INDIRECT DAMAGES.`,
        ],
      },
      {
        type: 'text',
        subheader: `10. Governing Law.`,
        paragraphs: [
          `This Agreement is governed by the laws of the State where your System is installed. You agree that ETW has the right to obtain photographic images of the System and the Home, and to use such photographic images for internal and quality control purposes.`,
        ],
      },
      {
        type: 'text',
        subheader: `11. Indemnification.`,
        paragraphs: [
          `To the fullest extent permitted by applicable law, you agree to indemnify, advance expenses, and hold harmless ETW and our Related Parties from any and all claims, actions, costs, expenses (including reasonable attorneys’ fees and expenses), damages, liabilities, penalties, losses, obligations, injuries, demands, and liens of any kind or nature in connection with, arising out of, or in any way related to your breach of this Agreement, your negligence or willful misconduct, or your violation of law. Your indemnification obligations under this section shall not apply if the harm or damage that is the basis for such claim occurred while one of our employees or agents was at your Property and such harm or damage was caused by the negligence or willful misconduct of such employee or agent.`,
          `ETW is not responsible for any delay or failure in the performance under this Agreement due to Force Majeure. “Force Majeure” means any event, condition or circumstance beyond the reasonable control of, and not caused by, ETW’s fault or negligence. These include acts of God, war, riot, terrorism, or a strike, lockout or significant labor dispute.`,
        ],
      },
      {
        type: 'text',
        subheader: `12. Notices in writing.`,
        paragraphs: [
          `All notices, demands, or requests related to this Agreement must be in writing and will be sent to you at your Home’s mailing address or email address that you provided to us and is available on your profile. We will primarily use email to communicate with you, unless you request correspondence solely via first class mail. Any notice or communication we send to you in electronic form will have the same legal effect and enforceability as if made in non-electronic form.`,
          `Correspondence, notices, demands or requests should be sent to ETW at: 3331 N Panam Expressway, San Antonio TX 78219 and customercare@etwenergy.com`,
        ],
      },
      {
        type: 'text',
        subheader: `13. Agreement to Arbitrate.`,
        paragraphs: [
          `Please read this part carefully because it means you waive your right to bring a class action lawsuit against ETW and our partners. You and we mutually agree that any party may elect to settle any legal disagreements confidentially via binding arbitration in the nearest AAA (arbitration services provider defined below) jurisdiction to your home. The details of how this works are as follows:`,
          `If you have a dispute with ETW or its affiliates (“ETW”) arising out of or relating to any aspect of this Agreement or your purchase of the System, please send us an email to customercare@etwenergy.com, describing your dispute and how you would like it resolved. If it is not resolved within 60 days from the date of your email, you agree that your dispute can only be resolved by a single arbitrator in an arbitration administered by the American Arbitration Association (AAA) under its Consumer Arbitration Rules. This includes claims arising before you ordered your System (such as claims related to statements ETW made about our products). We will evenly split all AAA fees for any arbitration, which will be held in the city or county of your legal residence. To learn more about the Rules and how to begin an arbitration, you can call any AAA office or go to www.adr.org. The arbitrator can only resolve disputes between you and ETW, and cannot consolidate claims from others without consent from you, ETW, and the others. You can only bring claims in arbitration against ETW in your individual capacity and not as a plaintiff or class member in any class or representative action (and the same is true for ETW). If a court or arbitrator decides that any part of this agreement to arbitrate cannot be enforced as to a specific claim for relief or remedy (such as what lawyers call “injunctive” or “declaratory” relief), then that claim or remedy (and only that claim or remedy) will be carved out of the arbitration and can be filed in court; all other claims must be arbitrated. If you prefer, you can take your individual dispute to a small claims court instead.`,
        ],
      },
      {
        type: 'text',
        subheader: `14. Amendments and Assignability.`,
        paragraphs: [
          `Neither you nor we can amend this Agreement without the other approving the amendment. Neither of you nor we can assign this Agreement to someone else without the other approving it. `,
        ],
      },
      {
        type: 'text',
        subheader: `15. Entire Agreement.`,
        paragraphs: [
          `This Agreement, the Pricing Sheet, and Payment Terms constitute the entire agreement we have with you with regard to the matters discussed in this Agreement. There are no other written or oral promises that we’ve made to you or that you’ve made to us. If this Agreement conflicts with anything in the Pricing Sheet or Payment Terms, the terms of this Agreement will be definitive terms.`,
        ],
      },
      {
        type: 'linebreak',
      },
      {
        type: 'header',
        header: `TEXAS DISCLOSURE STATEMENT`,
      },
      {
        type: 'text',
        subheader: `KNOW YOUR RIGHTS AND RESPONSIBILITIES UNDER THE LAW`,
        paragraphs: [
          `You are about to enter into a transaction to build a new home or remodel existing residential property. Texas law requires your contractor to provide you with this brief overview of some of your rights, responsibilities, and risks in this transaction.`,
        ],
      },
      {
        type: 'text',
        subheader: `CONVEYANCE TO CONTRACTOR NOT REQUIRED`,
        paragraphs: [
          `Your contractor may not require you to convey your real property to your contractor as a condition to the agreement for the construction of improvements on your property. `,
        ],
      },
      {
        type: 'text',
        subheader: `KNOW YOUR CONTRACTOR`,
        paragraphs: [
          `Before you enter into your agreement for the construction of improvements to your real property, make sure that you have investigated your contractor. Obtain and verify references from other people who have used the contractor for the type and size of construction project on your property.`,
        ],
      },
      {
        type: 'text',
        subheader: `GET IT IN WRITING`,
        paragraphs: [
          `Make sure that you have a written agreement with your contractor that includes: (1) a description of the work the contractor is to perform; (2) the required or estimated time for completion of the work; (3) the cost of the work or how the cost will be determined; and (4) the procedure and method of payment, including provisions for statutory retainage and conditions for final payment. If your contractor made a promise, warranty, or representation to you concerning the work the contractor is to perform, make sure that promise, warranty, or representation is specified in the written agreement. An oral promise that is not included in the written agreement may not be enforceable under Texas law.`,
        ],
      },
      {
        type: 'text',
        subheader: `READ BEFORE YOU SIGN`,
        paragraphs: [
          `Do not sign any document before you have read and understood it. NEVER SIGN A DOCUMENT THAT INCLUDES AN UNTRUE STATEMENT. Take your time in reviewing documents. If you borrow money from a lender to pay for the improvements, you are entitled to have the loan closing documents furnished to you for review at least one business day before the closing. Do not waive this requirement unless a bona fide emergency or another good cause exists, and make sure you understand the documents before you sign them. If you fail to comply with the terms of the documents, you could lose your property. You are entitled to have your own attorney review any documents. If you have any question about the meaning of a document, consult an attorney.`,
        ],
      },
      {
        type: 'text',
        subheader: `GET A LIST OF SUBCONTRACTORS AND SUPPLIERS`,
        paragraphs: [
          `Before construction commences, your contractor is required to provide you with a list of the subcontractors and suppliers the contractor intends to use on your project. Your contractor is required to supply updated information on any subcontractors and suppliers added after the list is provided. Your contractor is not required to supply this information if you sign a written waiver of your rights to receive this information.`,
        ],
      },
      {
        type: 'text',
        subheader: `MONITOR THE WORK`,
        paragraphs: [
          `Lenders and governmental authorities may inspect the work in progress from time to time for their own purposes. These inspections are not intended as quality control inspections. Quality control is a matter for you and your contractor. To ensure that your home is being constructed in accordance with your wishes and specifications, you should inspect the work yourself or have your own independent inspector review the work in progress.`,
        ],
      },
      {
        type: 'text',
        subheader: `MONITOR PAYMENTS`,
        paragraphs: [
          `If you use a lender, your lender is required to provide you with a periodic statement showing the money disbursed by the lender from the proceeds of your loan. Each time your contractor requests payment from you or your lender for work performed, your contractor is also required to furnish you with a disbursement statement that lists the name and address of each subcontractor or supplier that the contractor intends to pay from the requested funds. Review these statements and make sure that the money is being properly disbursed.`,
        ],
      },
      {
        type: 'text',
        subheader: `CLAIMS BY SUBCONTRACTORS AND SUPPLIERS`,
        paragraphs: [
          `Under Texas law, if a subcontractor or supplier who furnishes labor or materials for the construction of improvements on your property is not paid, you may become liable and your property may be subject to a lien for the unpaid amount, even if you have not contracted directly with the subcontractor or supplier. To avoid liability, you should take the following actions:`,
          `1. If you receive a written notice from a subcontractor or supplier, you should withhold payment from your contractor for the amount of the claim stated in the notice until the dispute between your contractor and the subcontractor or supplier is resolved. If your lender is disbursing money directly to your contractor, you should immediately provide a copy of the notice to your lender and instruct the lender to withhold payment in the amount of the claim stated in the notice. If you continue to pay the contractor after receiving the written notice without withholding the amount of the claim, you may be liable and your property may be subject to a lien for the amount you failed to withhold.`,
          `2. During construction and for 30 days after final completion, termination, or abandonment of the contract by the contractor, you should withhold or cause your lender to withhold 10 percent of the amount of payments made for the work performed by your contractor. This is sometimes referred to as ’statutory retainage.’ If you choose not to withhold the 10 percent for at least 30 days after final completion, termination, or abandonment of the contract by the contractor and if a valid claim is timely made by a claimant and your contractor fails to pay the claim, you may be personally liable and your property may be subject to a lien up to the amount that you failed to withhold.`,
          `If a claim is not paid within a certain time period, the claimant is required to file a mechanic’s lien affidavit in the real property records in the county where the property is located. A mechanic’s lien affidavit is not a lien on your property, but the filing of the affidavit could result in a court imposing a lien on your property if the claimant is successful in litigation to enforce the lien claim.`,
        ],
      },
      {
        type: 'text',
        subheader: `SOME CLAIMS MAY NOT BE VALID`,
        paragraphs: [
          `When you receive a written notice of a claim or when a mechanic’s lien affidavit is filed on your property, you should know your legal rights and responsibilities regarding the claim. Not all claims are valid. A notice of a claim by a subcontractor or supplier is required to be sent, and the mechanic’s lien affidavit is required to be filed, within strict time periods. The notice and the affidavit must contain certain information. All claimants may not fully comply with the legal requirements to collect on a claim. If you have paid the contractor in full before receiving a notice of a claim and have fully complied with the law regarding statutory retainage, you may not be liable for that claim. Accordingly, you should consult your attorney when you receive a written notice of a claim to determine the true extent of your liability or potential liability for that claim.`,
        ],
      },
      {
        type: 'text',
        subheader: `OBTAIN A LIEN RELEASE AND A BILLS-PAID AFFIDAVIT`,
        paragraphs: [
          `When you receive a notice of claim, do not release withheld funds without obtaining a signed and notarized release of lien and claim from the claimant. You can also reduce the risk of having a claim filed by a subcontractor or supplier by requiring as a condition of each payment made by you or your lender that your contractor furnish you with an affidavit stating that all bills have been paid. Under Texas law, on final completion of the work and before final payment, the contractor is required to furnish you with an affidavit stating that all bills have been paid. If the contractor discloses any unpaid bill in the affidavit, you should withhold payment in the amount of the unpaid bill until you receive a waiver of lien or release from that subcontractor or supplier.`,
        ],
      },
      {
        type: 'text',
        subheader: `OBTAIN TITLE INSURANCE PROTECTION`,
        paragraphs: [
          `You may be able to obtain a title insurance policy to insure that the title to your property and the existing improvements on your property are free from liens claimed by subcontractors and suppliers. If your policy is issued before the improvements are completed and covers the value of the improvements to be completed, you should obtain, on the completion of the improvements and as a condition of your final payment, a ’completion of improvements’ policy endorsement. This endorsement will protect your property from liens claimed by subcontractors and suppliers that may arise from the date the original title policy is issued to the date of the endorsement.”`,
        ],
      },
    ],
  }
  return versionHistory[versionKey]
}

export const returnPowerwallInstallAgreementText_ExhibitA = (versionKey: string): TsInterface_UnspecifiedObject[] => {
  // 25 May 2023
  let versionHistory: TsInterface_UnspecifiedObject = {
    '2023-06-15': [
      {
        type: 'header',
        header: `EXHIBIT A: FAQS ON ARBITRATION`,
      },
      {
        type: 'text',
        subheader: `What is mediation? `,
        paragraphs: [
          `Mediation is an informal negotiation assisted by a neutral third party (the mediator). Pursuant to the Parties’ Agreement, mediation is a precondition to any arbitration.`,
        ],
      },
      {
        type: 'text',
        subheader: `What is arbitration?`,
        paragraphs: [
          `An alternative to a court case. In arbitration, a third-party arbitrator (“TPA”) resolves “Disputes” in a hearing. It is less formal than a court case.`,
        ],
      },
      {
        type: 'text',
        subheader: `Is arbitration different from court and jury trials?`,
        paragraphs: [
          `Yes. The hearing is private. There is no jury. It is usually less formal, faster, and less expensive than a lawsuit. Pre-hearing fact finding (called “discovery”) is limited. Appeals are limited. The arbitrator’s findings are binding, and courts rarely overturn arbitration awards. The arbitrator may grant any legal or equitable remedy available in court.`,
        ],
      },
      {
        type: 'text',
        subheader: `Who does this cover?`,
        paragraphs: [
          `You, us, and certain Related Parties (defined in Section 9 of the Powerwall Installation Agreement). Either you or we may, after the required mediation and without the other’s consent, elect to resolve disputes by binding arbitration.`,
        ],
      },
      {
        type: 'text',
        subheader: `What does this cover?`,
        paragraphs: [
          `All Disputes (except certain Disputes about this clause). This governs all disputes that would usually be decided in court and are between us (or any Related Party) and you, including without limitation all claims related to or arising out of this Agreement, the System or our relationship with you (“Disputes”). Disputes include claims related to amendments, Lease Disclosures, Change Orders, collections, privacy and Customer Information, claims related to the validity of this Agreement, AND THE ARBITRABILITY OF ANY DISPUTE(S). In short, Disputes has the broadest reasonable meaning.`,
        ],
      },
      {
        type: 'text',
        subheader: `Who handles the mediation and arbitration?`,
        paragraphs: [`American Arbitration Association (“AAA”). The arbitration company will be the AAA, 1.800.778.7879, www.adr.org`],
      },
      {
        type: 'text',
        subheader: `What are the rules of the arbitration?`,
        paragraphs: [
          `Those in this Agreement along with the AAA Rules. Arbitrations are conducted under this Agreement and the applicable AAA Active Rules and Procedures in effect at the time the arbitration is commenced. This Agreement is also subject to the AAA Consumer Arbitration Rules pursuant to the Consumer Due Process Protocol, which set forth certain protections to you (including a maximum filing fee). Any other arbitration rules that conflict with this Clause don't apply.`,
        ],
      },
      {
        type: 'text',
        subheader: `Where will the arbitration hearing be held?`,
        paragraphs: [
          `In your hometown area. If the Parties don't agree to the locale where the hearing is to be held, the AAA will determine the location of the hearing. You can find more information in the AAA Policy on Consumer Arbitrations, which is available here - https://www.adr.org/sites/default/files/Consumer_Rules_Web_0.pdf.`,
        ],
      },
      {
        type: 'text',
        subheader: `Can Disputes be brought in court? `,
        paragraphs: [
          `Sometimes. Either party may bring a lawsuit if the other party does not demand arbitration. We will not demand arbitration of any lawsuit you bring as an individual action in small-claims court. Neither You nor We waive the right to arbitrate by using self help remedies, such as repossession or by filing an action to recover the System, deficiency balance, or for individual injunctive relief.`,
        ],
      },
      {
        type: 'text',
        subheader: `Are you giving up any rights? `,
        paragraphs: [
          `Yes. For Disputes subject to this clause, you give up your right to:`,
          `- have juries decide Disputes;`,
          `- have courts, other than small-claims courts, decide Disputes; `,
          `- serve as a private attorney general or in a representative capacity; `,
          `- join a Dispute you have with a dispute by other consumers; `,
          `- bring or be a class member in a class action or class arbitration; `,
          `- and have a jury trial. `,
        ],
      },
      {
        type: 'text',
        subheader: `Can you or another consumer start class arbitration?`,
        paragraphs: [
          `No. AAA is not allowed to handle any Dispute between the Parties on a class or representative basis. All Disputes subject to this clause must be decided in an INDIVIDUAL arbitration. This clause will be void if a court rules that the TPA can decide a Dispute on a class basis and the court’s ruling is not reversed on appeal.`,
        ],
      },
      {
        type: 'text',
        subheader: `What law applies?`,
        paragraphs: [
          `The Federal Arbitration Act (“FAA”). This Agreement involves interstate commerce. THUS, the FAA governs this clause. The TPA must apply substantive law consistent with the FAA. The TPA must honor statutes of limitation and privilege rights. Punitive damages are governed by the constitutional standards that apply in judicial proceedings.`,
        ],
      },
      {
        type: 'text',
        subheader: `Will anything you do make the arbitration clause ineffective?`,
        paragraphs: [
          `No. This clause stays in force even if you: (1) cancel this Agreement; (2) default, renew, prepay, or pay the Agreement in full; or (3) go into or through bankruptcy.`,
        ],
      },
      {
        type: 'text',
        subheader: `Will the arbitration clause survive termination of this Agreement?`,
        paragraphs: [`Yes. This clause will remain in effect for Disputes that commence even after the Agreement has terminated.`],
      },
    ],
  }
  return versionHistory[versionKey]
}

export const returnPowerwallInstallAgreementText_ExhibitB = (versionKey: string): TsInterface_UnspecifiedObject[] => {
  // 25 May 2023
  let versionHistory: TsInterface_UnspecifiedObject = {
    '2023-06-15': [
      {
        type: 'header',
        header: `EXHIBIT B: OUR INSURANCE`,
      },
      {
        type: 'text',
        subheader: null,
        paragraphs: [
          `ETW carries certain insurance policies covering our company and our employees, including commercial general liability insurance written by Westchester Surplus Lines Ins. Co. (Policy No. G47366422001) and worker’s compensation insurance for all our employees written by Indemnity Insurance Co. of North America (Policy No. C51844488).`,
          `Our insurance DOES NOT cover your negligence or willful damage to the System.`,
        ],
      },
      {
        type: 'text',
        subheader: `Our Subcontractors’ Insurance`,
        paragraphs: [
          `We require our subcontractors to carry comprehensive insurance coverage, including: (1) worker’s compensation; (2) employer’s liability of at least one million dollars; (3) commercial general liability of at least one million dollars per occurrence and two million dollars annually in aggregate; (4) commercial automobile liability of at least one million dollars per accident covering both bodily injury and property damage; (5) excess liability insurance of up to one million dollars per occurrence; and (6) any other insurance required by applicable laws or regulation.`,
        ],
      },
    ],
  }
  return versionHistory[versionKey]
}

export const returnPaymentTermsText = (versionKey: string): TsInterface_UnspecifiedObject[] => {
  // 25 May 2023
  let versionHistory: TsInterface_UnspecifiedObject = {
    '2023-05-25': [
      {
        type: 'header',
        header: `Payment Terms`,
      },
      {
        type: 'text',
        subheader: null,
        paragraphs: [
          `These Payment Terms describe your agreement to pay for current and future goods and services, together with any charges or fees applied by us related to goods or services. In these Payment Terms, “ETW”, “we” and “us” mean the ETW Energy (TX), LLC and its affiliates supplying the goods and services in your area.`,
          `In order to obtain installation services that require authorized automatic recurring or one-time payments, you allow ETW to store, maintain, and recover funds from your specified payment method in accordance with these Payment Terms. ETW may amend these terms from time to time and any changes are effective when posted to this page.`,
          `When you add a payment method to your ETW Account, such as a credit card, certain information including, but not limited to device location, device identification number, and card information may be sent to ETW and shared with our payment processor. Card information is encrypted during the transmission and ETW will not have access to the actual card number. When the stored payment method or a new credit card is used for purchasing goods and services on a ETW hosted website or application, the necessary information to process payment will be shared with our payment processors and our banking partners, to process your payment, comply with financial regulations, to prevent fraud, and for troubleshooting any payment issues. When you pay using your bank account details, those details are stored by our payment processor in an encrypted format that prevents unauthorized access without specific permissions. For information on how we handle your payment information, please see ETW’s Privacy Notice.`,
          `You must maintain at least one valid payment method in your ETW Account. Any services received may result in charges being applied to your saved payment method, and charges may include taxes as required by law. Your failure to maintain accurate, complete, and up-to-date payment information, including an invalid or expired payment method, may result in a pause or suspension of services.`,
          `When you request a bank payment such as an Automated Clearing House (ACH) payment transfer, and where required by law, we will inform you at least three days before the initial payment due date, and up to one day before payment is collected. You may amend or cancel this authorization at any time by providing us with thirty (30) days’ notice. You have certain recourse rights if any debit does not comply with this agreement, such as the right to receive reimbursement in the event of an unauthorized debit inconsistent with these terms. To obtain more information on your recourse rights, contact your financial institution.`,
          `Persons authorized by you can also make use of the services described in these Payment Terms. In this case, you will remain responsible to ETW for payment of the applicable charges and ETW will require payment from you using the payment method saved in your ETW Account. You will be able to review a summary of transactions in your ETW Account.`,
          `In the event that any attempt to recover funds from you should fail using the payment method saved in your ETW Account, you agree to allow ETW to recover all or less than all of the amount owed for goods or services as set out in this clause. If we are unable to collect payment, we may contact you based on your information on file or may request payment when we are performing services for you.`,
        ],
      },
    ],
    '2023-06-15': [
      {
        type: 'header',
        header: `Payment Terms`,
      },
      {
        type: 'text',
        subheader: null,
        paragraphs: [
          `These Payment Terms describe your agreement to pay for current and future goods and services, together with any charges or fees applied by us related to goods or services. In these Payment Terms, “ETW”, “we” and “us” mean the ETW Energy (TX), LLC and its affiliates supplying the goods and services in your area.`,
          `In order to obtain installation services that require authorized automatic recurring or one-time payments, you allow ETW to store, maintain, and recover funds from your specified payment method in accordance with these Payment Terms. ETW may amend these terms from time to time and any changes are effective when posted to this page.`,
          `When you add a payment method to your ETW Account, such as a credit card, certain information including, but not limited to device location, device identification number, and card information may be sent to ETW and shared with our payment processor. Card information is encrypted during the transmission and ETW will not have access to the actual card number. When the stored payment method or a new credit card is used for purchasing goods and services on a ETW hosted website or application, the necessary information to process payment will be shared with our payment processors and our banking partners, to process your payment, comply with financial regulations, to prevent fraud, and for troubleshooting any payment issues. When you pay using your bank account details, those details are stored by our payment processor in an encrypted format that prevents unauthorized access without specific permissions. For information on how we handle your payment information, please see ETW’s Privacy Notice.`,
          `You must maintain at least one valid payment method in your ETW Account. Any services received may result in charges being applied to your saved payment method, and charges may include taxes as required by law. Your failure to maintain accurate, complete, and up-to-date payment information, including an invalid or expired payment method, may result in a pause or suspension of services.`,
          `When you request a bank payment such as an Automated Clearing House (ACH) payment transfer, and where required by law, we will inform you at least three days before the initial payment due date, and up to one day before payment is collected. You may amend or cancel this authorization at any time by providing us with thirty (30) days’ notice. You have certain recourse rights if any debit does not comply with this agreement, such as the right to receive reimbursement in the event of an unauthorized debit inconsistent with these terms. To obtain more information on your recourse rights, contact your financial institution.`,
          `Persons authorized by you can also make use of the services described in these Payment Terms. In this case, you will remain responsible to ETW for payment of the applicable charges and ETW will require payment from you using the payment method saved in your ETW Account. You will be able to review a summary of transactions in your ETW Account.`,
          `In the event that any attempt to recover funds from you should fail using the payment method saved in your ETW Account, you agree to allow ETW to recover all or less than all of the amount owed for goods or services as set out in this clause. If we are unable to collect payment, we may contact you based on your information on file or may request payment when we are performing services for you`,
          `In addition, if we do not receive full payment within 30 days of the payment being due, we reserve the right to engage the services of a collections agency to recover the unpaid amounts that are owed. You will be responsible for any costs associated with the collections process.`,
          `Nothing in these payment terms shall limit or prejudice any other rights or remedies available to us under applicable law.`,
        ],
      },
    ],
  }
  return versionHistory[versionKey]
}

export const returnPrivacyPolicyText = (versionKey: string): TsInterface_UnspecifiedObject[] => {
  // 25 May 2023
  let versionHistory: TsInterface_UnspecifiedObject = {
    '2023-05-25': [
      {
        type: 'header',
        header: `PRIVACY POLICY`,
      },
      {
        type: 'text',
        subheader: null,
        paragraphs: [
          `This privacy policy ("Policy") describes how ETW Energy, LLC ("we," "us," or "our") collects, uses, and shares personal information of users of this website (the "Site").`,
        ],
      },
      {
        type: 'text',
        subheader: `WHAT WE COLLECT`,
        paragraphs: [
          `We may collect personal information from you, such as your name, email address, phone number, or mailing address, when you visit our Site, register on the Site, place an order, subscribe to our newsletter, respond to a survey, fill out a form, or otherwise interact with us. We may also collect demographic information, such as your age or gender, and information about your computer or mobile device, including IP address and browser type.`,
        ],
      },
      {
        type: 'text',
        subheader: `HOW WE USE YOUR INFORMATION`,
        paragraphs: [
          `We may use your personal information to:`,
          `- Provide products and services to you`,
          `- Respond to your inquiries and customer service requests`,
          `- Send you promotional materials and newsletters (you can opt out)`,
          `- Improve our Site and marketing efforts`,
          `- Conduct research and analysis`,
          `- Comply with applicable laws, regulations, and legal processes`,
          `- We may also share your personal information with third-party service providers who help us provide services to you, such as hardware manufacturers, financing providers and payment processors`,
        ],
      },
      {
        type: 'text',
        subheader: `COOKIES AND TRACKING TECHNOLOGIES`,
        paragraphs: [
          `We may use cookies, web beacons, and other tracking technologies to collect information about your use of our Site and to personalize your experience. Cookies are small data files that are stored on your device when you visit a website. Web beacons are small, transparent images that are embedded in web pages and emails. We may use cookies and web beacons to:`,
          `- Remember your preferences and settings`,
          `- Deliver relevant advertising and content`,
          `- Measure and analyze site usage and performance`,
          `- Provide social media features`,
          `You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this Site may become inaccessible or not function properly.`,
        ],
      },
      {
        type: 'text',
        subheader: `THIRD-PARTY LINKS`,
        paragraphs: [
          `Our Site may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices of these third-party sites or services. We encourage you to read the privacy policies of any third-party site or service before providing any personal information.`,
        ],
      },
      {
        type: 'text',
        subheader: `SECURITY`,
        paragraphs: [
          `We take reasonable measures to protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.`,
        ],
      },
      {
        type: 'text',
        subheader: `YOUR CHOICES`,
        paragraphs: [
          `You may opt out of receiving promotional emails from us by following the instructions in those emails. If you opt out, we may still send you non-promotional emails, such as those about your account or our ongoing business relations.`,
          `You may also review, update, or delete your personal information by contacting us via email at hello@etwenergy.com`,
        ],
      },
      {
        type: 'text',
        subheader: `CHILDREN'S PRIVACY`,
        paragraphs: [
          `Our Site is not intended for use by children under the age of 18. We do not knowingly collect personal information from children under 18. If you are under 18, do not use or provide any information on this Site or on or through any of its features. If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information.`,
        ],
      },
      {
        type: 'text',
        subheader: `CHANGES TO THIS POLICY`,
        paragraphs: [
          `We may update this Policy from time to time. If we make material changes to this Policy, we will notify you by email or by posting a notice on our Site. Your continued use of our Site after we make changes is deemed to be acceptance of those changes.`,
        ],
      },
      {
        type: 'text',
        subheader: `DISPUTE RESOLUTION`,
        paragraphs: [
          `Any dispute arising from or relating to this Privacy Policy or our handling of your personal information shall be resolved by binding arbitration in accordance with the rules of the American Arbitration Association. Any arbitration award may be enforced by any court having jurisdiction.`,
        ],
      },
      {
        type: 'text',
        subheader: `MISCELLANEOUS`,
        paragraphs: [
          `This Privacy Policy constitutes the entire agreement between you and us with respect to our handling of your personal information, and supersedes all prior or contemporaneous communications and proposals, whether oral or written, between you and us. If any provision of this Privacy Policy is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Privacy Policy shall otherwise remain in full force and effect and enforceable. Our failure to enforce any provision of this Privacy Policy shall not be deemed a waiver of such provision or of the right to enforce such provision.`,
        ],
      },
      {
        type: 'text',
        subheader: `CONTACT US`,
        paragraphs: [`If you have any questions about this Privacy Policy or our privacy practices, you may contact us via email at hello@etwenergy.com`],
      },
    ],
    '2023-08-04': [
      {
        type: 'header',
        header: `PRIVACY POLICY`,
      },
      {
        type: 'text',
        subheader: null,
        paragraphs: [
          `This privacy policy ("Policy") describes how ETW Energy, LLC ("we," "us," or "our") collects, uses, and shares personal information of users of this website (the "Site").`,
        ],
      },
      {
        type: 'text',
        subheader: `WHAT WE COLLECT`,
        paragraphs: [
          `We may collect personal information from you, such as your name, email address, phone number, or mailing address, when you visit our Site, register on the Site, place an order, subscribe to our newsletter, respond to a survey, fill out a form, or otherwise interact with us. We may also collect demographic information, such as your age or gender, and information about your computer or mobile device, including IP address and browser type.`,
        ],
      },
      {
        type: 'text',
        subheader: `HOW WE USE YOUR INFORMATION`,
        paragraphs: [
          `We may use your personal information to:`,
          `- Provide products and services to you`,
          `- Respond to your inquiries and customer service requests`,
          `- Send you promotional materials and newsletters (you can opt out)`,
          `- Improve our Site and marketing efforts`,
          `- Conduct research and analysis`,
          `- Comply with applicable laws, regulations, and legal processes`,
          `- We may also share your personal information with third-party service providers who help us provide services to you, such as hardware manufacturers, financing providers and payment processors`,
        ],
      },
      {
        type: 'text',
        subheader: `COOKIES AND TRACKING TECHNOLOGIES`,
        paragraphs: [
          `We may use cookies, web beacons, and other tracking technologies to collect information about your use of our Site and to personalize your experience. Cookies are small data files that are stored on your device when you visit a website. Web beacons are small, transparent images that are embedded in web pages and emails. We may use cookies and web beacons to:`,
          `- Remember your preferences and settings`,
          `- Deliver relevant advertising and content`,
          `- Measure and analyze site usage and performance`,
          `- Provide social media features`,
          `You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this Site may become inaccessible or not function properly.`,
        ],
      },
      {
        type: 'text',
        subheader: `BACKGROUND LOCATION FEATURE`,
        paragraphs: [
          `Our app includes a background location feature that allows us to collect and use your device's location even when the app is not in use. This feature is used to provide you with location-based services, such as real-time tracking or geofencing alerts.`,
        ],
      },
      {
        type: 'text',
        subheader: `COLLECTION AND USE OF LOCATION DATA`,
        paragraphs: [
          `We may collect your device's precise location information (e.g., GPS signals) and use it to improve the functionality of our app and enhance your user experience. The location data collected in the background is used solely for the purposes stated in this privacy policy.`,
        ],
      },
      {
        type: 'text',
        subheader: `OPTING OUT`,
        paragraphs: [
          `You have the option to enable or disable the background location feature at any time through the app's settings. However, please note that disabling this feature may limit certain functionalities of the app.`,
        ],
      },
      {
        type: 'text',
        subheader: `THIRD-PARTY LINKS`,
        paragraphs: [
          `Our Site may contain links to third-party websites or services that are not owned or controlled by us. We are not responsible for the privacy practices of these third-party sites or services. We encourage you to read the privacy policies of any third-party site or service before providing any personal information.`,
        ],
      },
      {
        type: 'text',
        subheader: `SECURITY`,
        paragraphs: [
          `We take reasonable measures to protect your personal information from loss, theft, misuse, unauthorized access, disclosure, alteration, and destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure, and we cannot guarantee absolute security.`,
        ],
      },
      {
        type: 'text',
        subheader: `YOUR CHOICES`,
        paragraphs: [
          `You may opt out of receiving promotional emails from us by following the instructions in those emails. If you opt out, we may still send you non-promotional emails, such as those about your account or our ongoing business relations.`,
          `You may also review, update, or delete your personal information by contacting us via email at hello@etwenergy.com`,
        ],
      },
      {
        type: 'text',
        subheader: `CHILDREN'S PRIVACY`,
        paragraphs: [
          `Our Site is not intended for use by children under the age of 18. We do not knowingly collect personal information from children under 18. If you are under 18, do not use or provide any information on this Site or on or through any of its features. If we learn we have collected or received personal information from a child under 18 without verification of parental consent, we will delete that information.`,
        ],
      },
      {
        type: 'text',
        subheader: `CHANGES TO THIS POLICY`,
        paragraphs: [
          `We may update this Policy from time to time. If we make material changes to this Policy, we will notify you by email or by posting a notice on our Site. Your continued use of our Site after we make changes is deemed to be acceptance of those changes.`,
        ],
      },
      {
        type: 'text',
        subheader: `DISPUTE RESOLUTION`,
        paragraphs: [
          `Any dispute arising from or relating to this Privacy Policy or our handling of your personal information shall be resolved by binding arbitration in accordance with the rules of the American Arbitration Association. Any arbitration award may be enforced by any court having jurisdiction.`,
        ],
      },
      {
        type: 'text',
        subheader: `MISCELLANEOUS`,
        paragraphs: [
          `This Privacy Policy constitutes the entire agreement between you and us with respect to our handling of your personal information, and supersedes all prior or contemporaneous communications and proposals, whether oral or written, between you and us. If any provision of this Privacy Policy is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that this Privacy Policy shall otherwise remain in full force and effect and enforceable. Our failure to enforce any provision of this Privacy Policy shall not be deemed a waiver of such provision or of the right to enforce such provision.`,
        ],
      },
      {
        type: 'text',
        subheader: `CONTACT US`,
        paragraphs: [`If you have any questions about this Privacy Policy or our privacy practices, you may contact us via email at hello@etwenergy.com`],
      },
    ],
  }
  return versionHistory[versionKey]
}

export const returnTermsOfServiceText = (versionKey: string): TsInterface_UnspecifiedObject[] => {
  // 25 May 2023
  let versionHistory: TsInterface_UnspecifiedObject = {
    '2023-05-25': [
      {
        type: 'header',
        header: `TERMS OF SERVICE`,
      },
      {
        type: 'text',
        subheader: null,
        paragraphs: [
          `Welcome to ETW Energy ("Site"), owned and operated by ETW Energy, LLC ("we," "us," or "our"). By accessing or using our Site, you agree to be bound by these terms of service ("Terms"). Please read these Terms carefully before using our Site.`,
        ],
      },
      {
        type: 'text',
        subheader: `ACCEPTANCE OF TERMS`,
        paragraphs: [
          `By using our Site, you agree to be bound by these Terms. If you do not agree to these Terms, do not use our Site. We may modify these Terms at any time, and such modifications shall be effective immediately upon posting on our Site. Your continued use of our Site after such modifications constitutes your acceptance of the modified Terms.`,
        ],
      },
      {
        type: 'text',
        subheader: `USER ACCOUNTS`,
        paragraphs: [
          `Some parts of our Site may require you to create an account. You agree to provide accurate and complete information when creating an account, and to keep your account information up-to-date. You are responsible for maintaining the confidentiality of your account login information, and you are responsible for all activities that occur under your account.`,
        ],
      },
      {
        type: 'text',
        subheader: `CONTENT`,
        paragraphs: [
          `Our Site may allow you to post, submit, or upload content, such as comments, reviews, or other materials ("User Content"). You retain ownership of your User Content, but by submitting User Content to our Site, you grant us a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sublicensable, transferable license to use, reproduce, distribute, prepare derivative works of, display, and perform your User Content in connection with our Site and our business.`,
          `You represent and warrant that: (i) you own or have the necessary licenses, rights, consents, and permissions to use and authorize us to use your User Content as necessary to exercise the licenses granted by you in these Terms; and (ii) your User Content does not infringe or violate the intellectual property rights, privacy rights, publicity rights, or other rights of any third party.`,
        ],
      },
      {
        type: 'text',
        subheader: `PROHIBITED ACTIVITIES`,
        paragraphs: [
          `You agree not to use our Site for any unlawful purpose or in any way that violates these Terms. You may not:`,
          `- Use our Site to transmit, distribute, or store material that infringes on any intellectual property right or other proprietary right of any third party.`,
          `- Use our Site to transmit, distribute, or store material that is unlawful, defamatory, libelous, obscene, discriminatory, or otherwise objectionable.`,
          `- Use our Site to impersonate any person or entity, or falsely state or otherwise misrepresent your affiliation with a person or entity.`,
          `- Use our Site to engage in any activity that interferes with or disrupts our Site, or any servers or networks connected to our Site.`,
          `- We reserve the right to terminate your access to our Site at any time for any violation of these Terms.`,
        ],
      },
      {
        type: 'text',
        subheader: `INTELLECTUAL PROPERTY`,
        paragraphs: [
          `Our Site and its contents are protected by copyright, trademark, and other intellectual property laws. You may not reproduce, modify, distribute, or display any part of our Site or its contents without our prior written consent.`,
        ],
      },
      {
        type: 'text',
        subheader: `DISCLAIMERS`,
        paragraphs: [
          `Our Site is provided "as is" and "as available" without warranties of any kind, either express or implied. We do not warrant that our Site will be uninterrupted or error-free, or that our Site or the servers that make our Site available are free from viruses or other harmful components.`,
        ],
      },
      {
        type: 'text',
        subheader: `LIMITATION OF LIABILITY`,
        paragraphs: [
          `In no event shall we be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our Site, whether based on contract, tort, strict liability, or other legal theory, even if we have been advised of the possibility of such damages.`,
        ],
      },
      {
        type: 'text',
        subheader: `INDEMNIFICATION`,
        paragraphs: [
          `You agree to defend, indemnify, and hold harmless us, our affiliates, and our respective directors, officers, employees, and agents from and against any and all claims, damages, costs, liabilities, and expenses (including, but not limited to, attorneys' fees) arising from or in connection with your use of our Site or any breach by you of these Terms.`,
        ],
      },
      {
        type: 'text',
        subheader: `TERMINATION`,
        paragraphs: [
          `We reserve the right to terminate or suspend your access to our Site at any time without notice and for any reason, including, without limitation, any violation of these Terms.`,
        ],
      },
      {
        type: 'text',
        subheader: `GOVERNING LAW`,
        paragraphs: [
          `These Terms and your use of our Site shall be governed by and construed in accordance with the laws of Utah, without giving effect to any principles of conflicts of law.`,
        ],
      },
      {
        type: 'text',
        subheader: `DISPUTE RESOLUTION`,
        paragraphs: [
          `Any dispute arising from or relating to these Terms or your use of our Site shall be resolved by binding arbitration in accordance with the rules of the American Arbitration Association. Any arbitration award may be enforced by any court having jurisdiction.`,
        ],
      },
      {
        type: 'text',
        subheader: `MISCELLANEOUS`,
        paragraphs: [
          `These Terms constitute the entire agreement between you and us with respect to your use of our Site, and supersede all prior or contemporaneous communications and proposals, whether oral or written, between you and us. If any provision of these Terms is found to be unenforceable or invalid, that provision shall be limited or eliminated to the minimum extent necessary so that these Terms shall otherwise remain in full force and effect and enforceable. Our failure to enforce any provision of these Terms shall not be deemed a waiver of such provision or of the right to enforce such provision.`,
        ],
      },
    ],
  }
  return versionHistory[versionKey]
}

export const returnCurrentLegalVersionNumbers = () => {
  let currentVersionNumbers = {
    PowerwallInstallAgreement: '2023-06-15',
    PowerwallInstallAgreementExhibitA: '2023-06-15',
    PowerwallInstallAgreementExhibitB: '2023-06-15',
    PaymentTerms: '2023-06-15',
    PrivacyPolicy: '2023-08-04',
    TermsOfService: '2023-05-25',
  }
  return currentVersionNumbers
}

// VehicleDriverAssignmentCustomDialog.tsx

import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { useEffect, useState } from 'react'
import { DatabaseRef_Vehicle_Document } from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import { DatabaseRef_VehicleAssignmentHistory_Document } from 'rfbp_aux/services/database_endpoints/operations/vehicle_assignment_history'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseBatchUpdate } from 'rfbp_core/services/database_management'
import { v4 as uuidv4 } from 'uuid'

interface VehicleDriverAssignmentCustomDialogProps {
  onClose: () => void
  uc_RootData_ClientKey: string | null // Add this prop
}

export const VehicleDriverAssignmentCustomDialog: React.FC<VehicleDriverAssignmentCustomDialogProps> = ({ onClose, uc_RootData_ClientKey }) => {
  const [us_newDriverAssignmentDriverKey, setUs_newDriverAssignmentDriverKey] = useState<string | null>(null)
  const [us_newDriverAssignmentDriverName, setUs_newDriverAssignmentDriverName] = useState<string | null>(null)
  const [us_newDriverAssignmentVehicleKey, setUs_newDriverAssignmentVehicleKey] = useState<string | null>(null)
  const [us_newDriverAssignmentVehicleName, setUs_newDriverAssignmentVehicleName] = useState<string | null>(null)
  const [us_newDriverAssignmentDate, setUs_newDriverAssignmentDate] = useState<string | null>(null)
  const [us_newDriverAssignmentUpdateRoot, setUs_newDriverAssignmentUpdateRoot] = useState<boolean>(true)

  useEffect(() => {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hours = date.getHours()
    let minutes = date.getMinutes()
    let compositeDate = year.toString() + '-'
    if (month < 10) {
      compositeDate += '0' + month.toString() + '-'
    } else {
      compositeDate += month.toString() + '-'
    }
    if (day < 10) {
      compositeDate += '0' + day.toString()
    } else {
      compositeDate += day.toString()
    }
    compositeDate += 'T'
    if (hours < 10) {
      // @ts-expect-error - TODO: reason for error
      hours = '0' + hours.toString()
    }
    compositeDate += hours
    if (minutes < 10) {
      // @ts-expect-error - TODO: reason for error
      minutes = '0' + minutes.toString()
    } else {
      // @ts-expect-error - TODO: reason for error
      minutes = minutes.toString()
    }
    compositeDate += ':'
    compositeDate += minutes
    // Set to field value
    setUs_newDriverAssignmentDate(compositeDate)
  }, [])

  const rJSX_SearchResult = (option: any, searchInputValue: string | null, inputHooks: any, additionalSearchData: any): JSX.Element => {
    return (
      <Box
        sx={{ marginLeft: '8px', marginRight: '8px' }}
        onClick={() => {
          if (option.type === 'vehicle') {
            setUs_newDriverAssignmentVehicleKey(option.id)
            setUs_newDriverAssignmentVehicleName(option.name)
          } else {
            setUs_newDriverAssignmentDriverKey(option.id)
            setUs_newDriverAssignmentDriverName(option.name)
          }
        }}
      >
        <Typography className="tw-cursor-pointer">{rJSX_HighlightedSearchString(searchInputValue, option.name)}</Typography>
      </Box>
    )
  }

  const rJSX_DatePicker = (): JSX.Element => {
    return (
      <Box className="tw-align-top">
        <FormControl sx={{ width: '100%' }}>
          <TextField
            sx={{ width: '100%' }}
            color="primary"
            value={us_newDriverAssignmentDate || ''}
            label={rLIB('Custody Start Date')}
            margin="normal"
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                setUs_newDriverAssignmentDate(event.target.value)
              }
            }}
            type="datetime-local"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      </Box>
    )
  }

  const rJSX_Selection = (): JSX.Element => {
    return (
      <Box>
        {!us_newDriverAssignmentVehicleName ? (
          <>
            <Typography variant="h6">{rLIB('Search Vehicle')}</Typography>
            <SearchInput
              clientKey={uc_RootData_ClientKey as string}
              searchIndexKey={'vehicles'}
              searchFilters={[]}
              searchResultRenderer={(option, searchInputValue, inputHooks, additionalSearchData) =>
                rJSX_SearchResult({ ...option, type: 'vehicle' }, searchInputValue, inputHooks, additionalSearchData)
              }
              additionalSearchData={{}}
              sx={{ minWidth: '100%' }}
            />
          </>
        ) : (
          <Typography variant="h6">
            {rLIB('Selected Vehicle')}: {us_newDriverAssignmentVehicleName}
          </Typography>
        )}

        {!us_newDriverAssignmentDriverName ? (
          <>
            <Typography
              variant="h6"
              className="tw-pt-4"
            >
              {rLIB('Search Driver')}
            </Typography>
            <SearchInput
              clientKey={uc_RootData_ClientKey as string}
              searchIndexKey={'users'}
              searchFilters={[]}
              searchResultRenderer={(option, searchInputValue, inputHooks, additionalSearchData) =>
                rJSX_SearchResult({ ...option, type: 'driver' }, searchInputValue, inputHooks, additionalSearchData)
              }
              additionalSearchData={{}}
              sx={{ minWidth: '100%' }}
            />
          </>
        ) : (
          <Typography variant="h6">
            {rLIB('Selected Driver')}: {us_newDriverAssignmentDriverName}
          </Typography>
        )}

        <Box className="tw-mt-4">{rJSX_DatePicker()}</Box>
        <Box className="tw-mt-4">{rJSX_UpdateRootSelection()}</Box>
      </Box>
    )
  }

  const rJSX_UpdateRootSelection = (): JSX.Element => {
    return (
      <FormControl fullWidth>
        <RadioGroup
          onChange={(event, value) => {
            if (value != null) {
              setUs_newDriverAssignmentUpdateRoot(value as unknown as boolean)
            }
          }}
          value={us_newDriverAssignmentUpdateRoot}
          sx={{ display: 'block' }}
        >
          <FormControlLabel
            sx={{ display: 'flex' }}
            control={<Radio />}
            label={rLIB('Update Active Assigned Driver')}
            value={true}
          />
          <FormControlLabel
            sx={{ display: 'flex' }}
            control={<Radio />}
            label={rLIB("Don't Update Active Assigned Driver")}
            value={false}
          />
        </RadioGroup>
      </FormControl>
    )
  }

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
  ) {
    return (
      <Slide
        direction="up"
        ref={ref}
        {...props}
      />
    )
  })

  const rJSX_SubmitButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        className="tw-mt-2"
        color="success"
        disabled={
          us_newDriverAssignmentDate == null ||
          us_newDriverAssignmentDriverKey == null ||
          us_newDriverAssignmentDriverName == null ||
          us_newDriverAssignmentVehicleKey == null ||
          us_newDriverAssignmentVehicleName == null
        }
        onClick={() => {
          if (
            us_newDriverAssignmentDate != null &&
            us_newDriverAssignmentDriverKey != null &&
            us_newDriverAssignmentDriverName != null &&
            us_newDriverAssignmentVehicleKey != null &&
            us_newDriverAssignmentVehicleName != null
          ) {
            const rootUpdateObject = {
              associated_user_key: us_newDriverAssignmentDriverKey,
              associated_user_name: us_newDriverAssignmentDriverName,
            }
            const logKey = uuidv4()
            const updateObject = {
              key: logKey,
              associated_user_key: us_newDriverAssignmentDriverKey,
              associated_user_name: us_newDriverAssignmentDriverName,
              associated_vehicle_key: us_newDriverAssignmentVehicleKey,
              timestamp_start: new Date(us_newDriverAssignmentDate as string),
            }
            const updateArray: any[] = [
              {
                type: 'setMerge',
                ref: DatabaseRef_VehicleAssignmentHistory_Document(uc_RootData_ClientKey as string, logKey),
                data: updateObject,
              },
            ]
            if (us_newDriverAssignmentUpdateRoot === true) {
              updateArray.push({
                type: 'setMerge',
                ref: DatabaseRef_Vehicle_Document(uc_RootData_ClientKey as string, us_newDriverAssignmentVehicleKey),
                data: rootUpdateObject,
              })
            }
            DatabaseBatchUpdate(updateArray)
              .then(() => {
                onClose()
              })
              .catch((error) => {
                console.log('Error: ', error)
              })
          }
        }}
      >
        <Icon
          icon="user-plus"
          className="tw-mr-2"
        />
        {'Add Driver Assignment'}
      </Button>
    )
  }

  return (
    <Box>
      <Dialog
        className="bp_dialog_lg_width"
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        open={true}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              disabled
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, color: '#fff !important' }}
            >
              <Icon icon="pen-to-square" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              {rLIB('Assign Vehicle')}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            {rJSX_Selection()}
            <Box className="tw-mt-2 tw-text-right">{rJSX_SubmitButton()}</Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

import { AppBar, Box, Card, Dialog, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { useEffect, useState } from 'react'
import { DatabaseRef_TrailerAssignmentHistory_SpecificTrailer_Query } from 'rfbp_aux/services/database_endpoints/operations/trailer_assignment_history'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TsInterface_TableDataRow, TsInterface_TableHooks } from 'rfbp_core/components/table'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { tableColumns_TrailerAssignmentHistoryData, tableSettings_TrailerAssignmentHistoryData } from '../tables/table_trailer_assignment_history'

const TrailerAssignmentHistoryDialog: React.FC<{
  us_trailer: TsInterface_TableDataRow
  tableHooks: TsInterface_TableHooks
  uc_setUserInterface_CustomDialogDisplay: any
}> = ({ us_trailer, tableHooks, uc_setUserInterface_CustomDialogDisplay }) => {
  const [us_formattedTrailerAssignmentHistoryData, us_setFormattedTrailerAssignmentHistoryData] = useState<TsInterface_TableDataRow[]>([])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      const formattedData = Object.values(newData).map((item: any) => ({
        key: item.key,
        associated_user_name: item.associated_user_name,
        timestamp_start: item.timestamp_start,
        timestamp_end: item.timestamp_end,
      }))
      us_setFormattedTrailerAssignmentHistoryData(formattedData)
      tableHooks.ur_forceRerender()
    }
    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (us_trailer.key != null) {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_TrailerAssignmentHistory_SpecificTrailer_Query(res_GCK.clientKey, us_trailer.key as string),
            updateLiveData,
          )
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [tableHooks, us_trailer.key])

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
  ) {
    return (
      <Slide
        direction="up"
        ref={ref}
        {...props}
      />
    )
  })

  if (us_formattedTrailerAssignmentHistoryData.length === 0) {
    return null
  }

  return (
    <Box>
      <Dialog
        className="bp_dialog_lg_width"
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              disabled
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, color: '#fff !important' }}
            >
              <Icon icon="magnifying-glass" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              {`Assignment History for ${us_trailer.name}`}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Card className="tw-mt-2">
            <TableBasic
              tableAdditionalData={{}}
              tableColumns={tableColumns_TrailerAssignmentHistoryData}
              tableData={us_formattedTrailerAssignmentHistoryData}
              tableSettings={tableSettings_TrailerAssignmentHistoryData}
            />
          </Card>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default TrailerAssignmentHistoryDialog

/* eslint-disable react/prop-types */
///////////////////////////////
// Imports
///////////////////////////////

import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { TsInterface_ApiDocumentation, TsInterface_ApiDocumentationUserInfo } from '../components/api_documentation'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

const apiDeployVersion = '1.1.0'
const exampleAPIKey = '7336da09-76c1-48e2-92c5-8634b87157a9'
const exampleUIDKey = '4b6677b0-0c24-445f-9e96-d85f88367e95'
const exampleProjectKey = '8TnoJ31dzmaJCFgHKLP5'

// Reusable Response Objects
const exampleResponse401 = {
  success: false,
  error: {
    code: 'ER-API-VA-01',
    message: 'No Client User Found for UID',
  },
}

///////////////////////////////
// Exports
///////////////////////////////

export const returnApiDocumentation = (
  userApiPermissions: TsInterface_UnspecifiedObject,
  userInfo: TsInterface_ApiDocumentationUserInfo,
): TsInterface_ApiDocumentation => {
  let apiDocumentation: TsInterface_ApiDocumentation = {
    base_data: {
      base_url: 'https://us-central1-etw-energy.cloudfunctions.net/api',
      version: apiDeployVersion,
    },
    endpoints: {
      'GET /version/': {
        description: 'Returns the version of the API. You can also use this endpoint to check your credentials are active.',
        short_description: 'Returns API version',
        method: 'GET',
        url: '/version/',
        parameters: {
          api_key: {
            key: 'api_key',
            required: true,
            description: "The API key for the client's account.",
            example_value: getProp(userInfo, 'api_key', exampleAPIKey),
            data_type: 'string',
          },
          uid: {
            key: 'uid',
            required: true,
            description: "The UID for the client's account.",
            example_value: getProp(userInfo, 'uid', exampleUIDKey),
            data_type: 'string',
          },
        },
        example_request: {
          api_key: getProp(userInfo, 'api_key', exampleAPIKey),
          uid: getProp(userInfo, 'uid', exampleUIDKey),
        },
        example_responses: {
          '200': {
            key: '200',
            response: {
              success: true,
              api_version: apiDeployVersion,
              query: {
                uid: getProp(userInfo, 'uid', exampleUIDKey),
                api_key: getProp(userInfo, 'api_key', exampleAPIKey),
              },
            },
          },
          '401': {
            key: '401',
            response: exampleResponse401,
          },
        },
      },
    },
  }
  // Contacts Endpoints
  if (userApiPermissions.contacts_write === true) {
    // TODO
    apiDocumentation['endpoints']['POST /contacts/'] = {
      description: 'Create a contact. Either an Email or Phone number are required.',
      short_description: 'Create a contact',
      method: 'POST',
      url: '/contacts/',
      parameters: {
        api_key: {
          key: 'api_key',
          required: true,
          description: "The API key for the client's account.",
          example_value: getProp(userInfo, 'api_key', exampleAPIKey),
          data_type: 'string',
        },
        location_key: {
          key: 'location_key',
          required: true,
          description: 'The location key for the market that the contact resides in.',
          example_value: 'peoria',
          data_type: 'string',
        },
        uid: {
          key: 'uid',
          required: true,
          description: "The UID for the client's account.",
          example_value: getProp(userInfo, 'uid', exampleUIDKey),
          data_type: 'string',
        },
        firstName: {
          key: 'firstName',
          required: false,
          description: 'The first name of the contact.',
          example_value: 'Rosan',
          data_type: 'string',
        },
        lastName: {
          key: 'lastName',
          required: false,
          description: 'The last name of the contact.',
          example_value: 'Deo',
          data_type: 'string',
        },
        name: {
          key: 'name',
          required: false,
          description: 'The full name of the contact.',
          example_value: 'Rosan Deo',
          data_type: 'string',
        },
        email: {
          key: 'email',
          required: false,
          description: 'The email address of the contact.',
          example_value: 'rosan@deos.com',
          data_type: 'string',
        },
        gender: {
          key: 'gender',
          required: false,
          description: 'The gender of the contact.',
          example_value: 'male',
          data_type: 'string',
        },
        phone: {
          key: 'phone',
          required: false,
          description: 'The phone number of the contact',
          example_value: '+1 888-888-8888',
          data_type: 'string',
        },
        address1: {
          key: 'address1',
          required: false,
          description: "The first line of the contact's address.",
          example_value: '3535 1st St N',
          data_type: 'string',
        },
        city: {
          key: 'city',
          required: false,
          description: 'The city of the contact.',
          example_value: 'Dolomite',
          data_type: 'string',
        },
        state: {
          key: 'state',
          required: false,
          description: 'The state of the contact.',
          example_value: 'AL',
          data_type: 'string',
        },
        postalCode: {
          key: 'postalCode',
          required: false,
          description: 'The postal code of the contact.',
          example_value: '35061',
          data_type: 'string',
        },
        website: {
          key: 'website',
          required: false,
          description: 'The lead source website',
          example_value: 'https://www.website.com',
          data_type: 'string',
        },
        timezone: {
          key: 'timezone',
          required: false,
          description: 'The timezone the contact lives in.',
          example_value: 'America/Chihuahua',
          data_type: 'string',
        },
        dnd: {
          key: 'dnd',
          required: false,
          description: 'Do not disturb status',
          example_value: 'false',
          data_type: 'string',
        },
        source: {
          key: 'source',
          required: false,
          description: 'Lead source',
          example_value: 'public api',
          data_type: 'string',
        },
        dateOfBirth: {
          key: 'dateOfBirth',
          required: false,
          description: 'The date of birth of the contact.',
          example_value: '01/01/1980',
          data_type: 'string',
        },
        country: {
          key: 'country',
          required: false,
          description: 'The country the contact lives in.',
          example_value: 'US',
          data_type: 'string',
        },
        companyName: {
          key: 'companyName',
          required: false,
          description: 'The company name of the contact.',
          example_value: 'DGS VolMAX',
          data_type: 'string',
        },
        contact_comments: {
          key: 'contact_comments',
          required: false,
          description: 'Any comments about the contact.',
          example_value: 'Comments',
          data_type: 'string',
        },
        contact_credit_rating: {
          key: 'contact_credit_rating',
          required: false,
          description: 'The credit rating of the contact.',
          example_value: 'Greater than 650',
          data_type: 'string',
        },
        contact_jornaya_leadid: {
          key: 'contact_jornaya_leadid',
          required: false,
          description: 'The Jornaya lead ID of the contact.',
          example_value: '78afe12f-3f07-4a82-8e7c-88b39f0fb9cb',
          data_type: 'string',
        },
        contact_landing_page_url: {
          key: 'contact_landing_page_url',
          required: false,
          description: 'The lead source landing page URL',
          example_value: 'www.website.com',
          data_type: 'string',
        },
        contact_lead_id: {
          key: 'contact_lead_id',
          required: false,
          description: 'The lead ID of the contact. Used for updating an existing contact.',
          example_value: '123456789',
          data_type: 'string',
        },
        contact_lead_source: {
          key: 'contact_lead_source',
          required: false,
          description: 'The lead source that the contact came from.',
          example_value: 'LeadSource',
          data_type: 'string',
        },
        contact_monthly_electric_bill: {
          key: 'contact_monthly_electric_bill',
          required: false,
          description: "The contact's monthly electric bill.",
          example_value: '$150',
          data_type: 'string',
        },
        contact_promo_code: {
          key: 'contact_promo_code',
          required: false,
          description: 'Promo code used by the contact.',
          example_value: '12345',
          data_type: 'string',
        },
        contact_property_ownership: {
          key: 'contact_property_ownership',
          required: false,
          description: 'Whether the contact owns the property.',
          example_value: 'Yes',
          data_type: 'string',
        },
        contact_property_type: {
          key: 'contact_property_type',
          required: false,
          description: 'The type of property the contact owns.',
          example_value: 'Single Family',
          data_type: 'string',
        },
        contact_roof_age: {
          key: 'contact_roof_age',
          required: false,
          description: "The age of the contact's roof.",
          example_value: '1-5 years',
          data_type: 'string',
        },
        contact_roof_shade: {
          key: 'contact_roof_shade',
          required: false,
          description: "How much shade the contact's roof gets.",
          example_value: 'partial sun',
          data_type: 'string',
        },
        contact_roof_type: {
          key: 'contact_roof_type',
          required: false,
          description: 'The type of roof the contact has.',
          example_value: 'asphalt',
          data_type: 'string',
        },
        contact_tcpa_text: {
          key: 'contact_tcpa_text',
          required: false,
          description: 'The TCPA text agreed to by the contact.',
          example_value: 'By clicking the button above...',
          data_type: 'string',
        },
        contact_today: {
          key: 'contact_today',
          required: false,
          description: 'The date the contact was created.',
          example_value: '06/01/2024', // TODO: Check
          data_type: 'string',
        },
        contact_trustedform_url: {
          key: 'contact_trustedform_url',
          required: false,
          description: 'The TrustedForm URL of the contact.',
          example_value: 'https://cert.trustedform.com/{id}',
          data_type: 'string',
        },
        contact_utility_provider: {
          key: 'contact_utility_provider',
          required: false,
          description: "The utility provider servicing the contact's residence.",
          example_value: 'Ameren Illinois Company',
          data_type: 'string',
        },
      },
      example_request: {
        api_key: getProp(userInfo, 'api_key', exampleAPIKey),
        uid: getProp(userInfo, 'uid', exampleUIDKey),
        location_key: 'peoria',
        // GHL Data
        firstName: 'Rosan',
        lastName: 'Deo',
        name: 'Rosan Deo',
        email: 'rosan@deos.com',
        gender: 'male',
        phone: '+1 888-888-8888',
        address1: '3535 1st St N',
        city: 'Dolomite',
        state: 'AL',
        postalCode: '35061',
        website: 'https://www.website.com',
        timezone: 'America/Chihuahua',
        source: 'public api',
        country: 'US',
        companyName: 'DGS VolMAX',
        contact_comments: 'Comments',
        contact_jornaya_leadid: '78afe12f-3f07-4a82-8e7c-88b39f0fb9cb',
        contact_landing_page_url: 'www.website.com',
        contact_lead_id: '123456789',
        contact_lead_source: 'LeadSource',
        contact_monthly_electric_bill: '$150',
        contact_promo_code: 'PromoCode',
        contact_property_ownership: 'Yes',
        contact_property_type: 'Single Family',
        contact_roof_age: '1-5 years',
        contact_roof_shade: 'partial sun',
        contact_roof_type: 'asphalt',
        contact_tcpa_text:
          'By clicking the button above, you authorize up to four home services or solar companies to call you and send you pre-recorded messages and text messages at the number you entered above, using an autodialer, with offers about their products or services, even if your phone number is on any national or state "Do Not Call" list. Message and data rates may apply. Your consent here is not based on a condition of purchase.',
        contact_trustedform_url: 'https://cert.trustedform.com/{id}',
        contact_utility_provider: 'Ameren Illinois Company',
      },
      example_responses: {
        '200': {
          key: '200',
          response: {
            new: true,
            contact: {
              id: 'seD4PfOuKoVMLkEZqohJ',
              name: 'rubika deo',
              locationId: 've9EPM428h8vShlRW1KT',
              firstName: 'rubika',
              lastName: 'Deo',
              email: 'rubika@deos.com',
              emailLowerCase: 'rubika@deos.com',
              timezone: '',
              companyName: 'DGS VolMAX',
              phone: '+18832327657',
              dnd: true,
              dndSettings: {
                Call: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
                Email: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
                SMS: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
                WhatsApp: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
                GMB: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
                FB: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
              },
              type: 'read',
              source: 'public api',
              assignedTo: 've9EPM428h8vShlRW1KT',
              address1: '3535 1st St N',
              city: 'ruDolomitebika',
              state: 'AL',
              country: 'US',
              postalCode: '35061',
              website: 'https://www.tesla.com',
              tags: ['nisi sint commodo amet', 'consequat'],
              dateOfBirth: '1990-09-25T00:00:00.000Z',
              dateAdded: '2021-07-02T05:18:26.704Z',
              dateUpdated: '2021-07-02T05:18:26.704Z',
              attachments: 'string',
              ssn: 'string',
              gender: 'male',
              keyword: 'test',
              firstNameLowerCase: 'rubika',
              fullNameLowerCase: 'rubika deo',
              lastNameLowerCase: 'deo',
              lastActivity: '2021-07-16T11:39:30.564Z',
              customFields: [
                {
                  id: 'MgobCB14YMVKuE4Ka8p1',
                  value: 'name',
                },
              ],
              businessId: '641c094001436dbc2081e642',
              attributionSource: {
                url: 'Trigger Link',
                campaign: 'string',
                utmSource: 'string',
                utmMedium: 'string',
                utmContent: 'string',
                referrer: 'https: //www.google.com',
                campaignId: 'string',
                fbclid: 'string',
                gclid: 'CjOKCQjwnNyUBhCZARISAI9AYIFtNnIcWcYGIOQINz_ZoFI5SSLRRugSoPZoiEu27IZBY£1-MAIWmEaAo2VEALW_WCB',
                msclikid: 'string',
                dclid: 'string',
                fbc: 'string',
                fbp: 'fb. 1.1674748390986.1171287961',
                fbEventId: 'Mozilla/5.0',
                userAgent: 'Mozilla/5.0',
                ip: '58.111.106.198',
                medium: 'survey',
                mediumId: 'FglfHAn30PRwsZVyQlKp',
              },
              lastAttributionSource: {
                url: 'Trigger Link',
                campaign: 'string',
                utmSource: 'string',
                utmMedium: 'string',
                utmContent: 'string',
                referrer: 'https: //www.google.com',
                campaignId: 'string',
                fbclid: 'string',
                gclid: 'CjOKCQjwnNyUBhCZARISAI9AYIFtNnIcWcYGIOQINz_ZoFI5SSLRRugSoPZoiEu27IZBY£1-MAIWmEaAo2VEALW_WCB',
                msclikid: 'string',
                dclid: 'string',
                fbc: 'string',
                fbp: 'fb. 1.1674748390986.1171287961',
                fbEventId: 'Mozilla/5.0',
                userAgent: 'Mozilla/5.0',
                ip: '58.111.106.198',
                medium: 'survey',
                mediumId: 'FglfHAn30PRwsZVyQlKp',
              },
            },
            traceId: 'string',
          },
        },
        '400': {
          key: '400',
          response: {
            statusCode: 400,
            message: 'Bad Request',
          },
        },
        '401': {
          key: '401',
          response: {
            statusCode: 401,
            message: 'Invalid token: access token is invalid',
            error: 'Unauthorized',
          },
        },
        '422': {
          key: '422',
          response: {
            statusCode: 422,
            message: ['Unprocessable Entity'],
            error: 'Unprocessable Entity',
          },
        },
      },
    }
  }
  if (userApiPermissions.contacts_read === true) {
    apiDocumentation['endpoints']['GET /contacts/{id}'] = {
      description: 'Fetch a specific contact by ID',
      short_description: 'Fetch a contact',
      method: 'GET',
      url: '/contacts/{id}',
      parameters: {
        '{id}': {
          key: '{id}',
          required: true,
          description: 'The ID of the contact to retrieve.',
          example_value: 'ocQHyuzHvysMo5N5VsXc',
          data_type: 'string',
        },
        'api_key': {
          key: 'api_key',
          required: true,
          description: "The API key for the client's account.",
          example_value: getProp(userInfo, 'api_key', exampleAPIKey),
          data_type: 'string',
        },
        'location_key': {
          key: 'location_key',
          required: true,
          description: 'The location key for the market that the contact resides in.',
          example_value: 'peoria',
          data_type: 'string',
        },
        'uid': {
          key: 'uid',
          required: true,
          description: "The UID for the client's account.",
          example_value: getProp(userInfo, 'uid', exampleUIDKey),
          data_type: 'string',
        },
      },
      example_request: {
        api_key: getProp(userInfo, 'api_key', exampleAPIKey),
        uid: getProp(userInfo, 'uid', exampleUIDKey),
        location_key: 'peoria',
      },
      example_responses: {
        '200': {
          key: '200',
          response: {
            contact: {
              id: 'seD4PfOuKoVMLkEZqohJ',
              name: 'rubika deo',
              locationId: 've9EPM428h8vShlRW1KT',
              firstName: 'rubika',
              lastName: 'Deo',
              email: 'rubika@deos.com',
              emailLowerCase: 'rubika@deos.com',
              timezone: '',
              companyName: 'DGS VolMAX',
              phone: '+18832327657',
              dnd: true,
              dndSettings: {
                Call: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
                Email: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
                SMS: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
                WhatsApp: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
                GMB: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
                FB: {
                  status: 'active',
                  message: 'string',
                  code: 'string',
                },
              },
              type: 'read',
              source: 'public api',
              assignedTo: 've9EPM428h8vShlRW1KT',
              address1: '3535 1st St N',
              city: 'ruDolomitebika',
              state: 'AL',
              country: 'US',
              postalCode: '35061',
              website: 'https://www.tesla.com',
              tags: ['nisi sint commodo amet', 'consequat'],
              dateOfBirth: '1990-09-25T00:00:00.000Z',
              dateAdded: '2021-07-02T05:18:26.704Z',
              dateUpdated: '2021-07-02T05:18:26.704Z',
              attachments: 'string',
              ssn: 'string',
              gender: 'male',
              keyword: 'test',
              firstNameLowerCase: 'rubika',
              fullNameLowerCase: 'rubika deo',
              lastNameLowerCase: 'deo',
              lastActivity: '2021-07-16T11:39:30.564Z',
              customFields: [
                {
                  id: 'MgobCB14YMVKuE4Ka8p1',
                  value: 'name',
                },
              ],
              businessId: '641c094001436dbc2081e642',
              attributionSource: {
                url: 'Trigger Link',
                campaign: 'string',
                utmSource: 'string',
                utmMedium: 'string',
                utmContent: 'string',
                referrer: 'https: //www.google.com',
                campaignId: 'string',
                fbclid: 'string',
                gclid: 'CjOKCQjwnNyUBhCZARISAI9AYIFtNnIcWcYGIOQINz_ZoFI5SSLRRugSoPZoiEu27IZBY£1-MAIWmEaAo2VEALW_WCB',
                msclikid: 'string',
                dclid: 'string',
                fbc: 'string',
                fbp: 'fb. 1.1674748390986.1171287961',
                fbEventId: 'Mozilla/5.0',
                userAgent: 'Mozilla/5.0',
                ip: '58.111.106.198',
                medium: 'survey',
                mediumId: 'FglfHAn30PRwsZVyQlKp',
              },
              lastAttributionSource: {
                url: 'Trigger Link',
                campaign: 'string',
                utmSource: 'string',
                utmMedium: 'string',
                utmContent: 'string',
                referrer: 'https: //www.google.com',
                campaignId: 'string',
                fbclid: 'string',
                gclid: 'CjOKCQjwnNyUBhCZARISAI9AYIFtNnIcWcYGIOQINz_ZoFI5SSLRRugSoPZoiEu27IZBY£1-MAIWmEaAo2VEALW_WCB',
                msclikid: 'string',
                dclid: 'string',
                fbc: 'string',
                fbp: 'fb. 1.1674748390986.1171287961',
                fbEventId: 'Mozilla/5.0',
                userAgent: 'Mozilla/5.0',
                ip: '58.111.106.198',
                medium: 'survey',
                mediumId: 'FglfHAn30PRwsZVyQlKp',
              },
            },
          },
        },
        '400': {
          key: '400',
          response: {
            statusCode: 400,
            message: 'Bad Request',
          },
        },
        '401': {
          key: '401',
          response: {
            statusCode: 401,
            message: 'Invalid token: access token is invalid',
            error: 'Unauthorized',
          },
        },
        '422': {
          key: '422',
          response: {
            statusCode: 422,
            message: ['Unprocessable Entity'],
            error: 'Unprocessable Entity',
          },
        },
      },
    }
  }
  // Project Endpoints
  if (userApiPermissions.projects_read === true) {
    apiDocumentation['endpoints']['GET /project/data/{id}'] = {
      description: 'Fetch project data by its ID',
      short_description: 'Fetch project data',
      method: 'GET',
      url: '/projects/data/{id}',
      parameters: {
        '{id}': {
          key: '{id}',
          required: true,
          description: 'The ID of the project to fetch.',
          example_value: exampleProjectKey,
          data_type: 'string',
        },
        'api_key': {
          key: 'api_key',
          required: true,
          description: "The API key for the client's account.",
          example_value: getProp(userInfo, 'api_key', exampleAPIKey),
          data_type: 'string',
        },
        'uid': {
          key: 'uid',
          required: true,
          description: "The UID for the client's account.",
          example_value: getProp(userInfo, 'uid', exampleUIDKey),
          data_type: 'string',
        },
      },
      example_request: {
        api_key: getProp(userInfo, 'api_key', exampleAPIKey),
        uid: getProp(userInfo, 'uid', exampleUIDKey),
      },
      example_responses: {
        '200': {
          key: '200',
          response: {
            success: true,
            project_id: exampleProjectKey,
            projects: [
              {
                etw_project_key: exampleProjectKey,
                project_data: {
                  associated_region_key: 'gRXJK0Sz4959H09axReR',
                  system_storage_manufacturer: null,
                  internal_id_number: 12042,
                  location_county: 'Peoria',
                  status_complete: false,
                  system_usage_offset: null,
                  location_jurisdiction: 'Peoria',
                  location_state: 'Illinois',
                  associated_customer_name: 'Test Customer',
                  location_city: 'Peoria',
                  system_inverter_quantity: null,
                  location_zip: '61525',
                  associated_customer_phone: '18888888888',
                  location_address: '100 Main St',
                  id_number: '309E-100CUST',
                  system_storage_quantity: null,
                  associated_region_name: 'Peoria',
                  associated_customer_email: 'customer@test.com',
                  system_storage_model: null,
                  system_storage_total_kwh: null,
                  key: exampleProjectKey,
                  additional_project_data: {
                    messages_threads_instantiated: true,
                  },
                  location_latitude: 40.6904119,
                  location_longitude: -89.5877788,
                  associated_sales_partner_name: 'Palmetto',
                  associated_sales_partner_key: 'palmetto',
                  system_panel_model: 'LR5-54HPB-400M',
                  system_max_roof_pitch: 18,
                  system_number_of_arrays: 3,
                  system_inverter_model: ' IQ8PLUS-72-2-US',
                  system_size_dc: 5.2,
                  system_inverter_manufacturer: 'Enphase',
                  system_panel_quantity: 13,
                  system_panel_manufacturer: ' LONGI GREEN ENERGY TECHNOLOGY CO',
                  home_roof_type: 'Comp Shingle',
                  associated_task_workflow_key: 'V1wXT6S4yYdv1iVKk9as',
                  associated_task_workflow_version_key: '1718224453737',
                  associated_task_workflow_version_number: 2,
                  associated_task_workflow_name: 'ETW',
                  task_statuses: {
                    'activation_meter_registration_1695846960149': false,
                    'install_installation_complete_call_1695846914250': false,
                    'permitting_and_approvals_building_permit_cancellation_1696514068191': false,
                    'design_layout_+_sow_review___huddle_1695846464329': false,
                    'financing_validate_installation_complete_on_loan_portal_1695846926201': false,
                    'SCHEDULE_inspections_utility_inspection_1695760058626': false,
                    'inspections_ahj_inspection_1695847734918': false,
                    'permitting_and_approvals_building_permit_approval_1695846858514': false,
                    'SCHEDULE_inspections_ahj_inspection_1695847734918': false,
                    'permitting_and_approvals_hoa_approval_1695846841352': false,
                    'sale_introduction_call_1695846312762': false,
                    'install_picc_rtg_1696450587937': false,
                    'inspections_utility_inspection_1695760058626': false,
                    'permitting_and_approvals_interconnection_approval_1695846846531': false,
                    'install_pv_install_1695846909084': false,
                    'financing_loan_approval_1695846453667': false,
                    'activation_final_interconnection_approval_1695846973115': false,
                    'financing_validate_pto_complete_on_loan_portal_1695846933081': false,
                    'activation_activate_system_1695847390451': false,
                    'SCHEDULE_site_audit_site_audit_1695846324448': false,
                    'design_complete_cap_package_1695846469413': false,
                    'site_audit_site_audit_1695846324448': false,
                    'design_complete_design_package_1695846474596': false,
                    'SCHEDULE_install_pv_install_1695846909084': false,
                    'sale_customer_signature_1695846303814': false,
                    'activation_interconnection_cancellation_1696513969290': false,
                    'design_post_design_review_1703008455680': false,
                  },
                  associated_css_rep_key: 'vVxRNtIY4FcFQ4GuACwN6pzRrm53',
                  associated_css_rep_name: 'John Morrell',
                  status: 'active',
                  task_completion_stats: {
                    cap_to_install: {
                      ready_green: 0,
                      timestamp_last_task_completed: null,
                      completed_total: 0,
                      completed_yellow: 0,
                      unassigned: 4,
                      completed_green: 0,
                      ready_yellow: 0,
                      unknown: 0,
                      total: 9,
                      ready_total: 0,
                      ready_red: 0,
                      deleted: 1,
                      active_tasks: {},
                      not_ready: 5,
                      completed_red: 0,
                    },
                    sale_to_cap: {
                      ready_green: 1,
                      timestamp_last_task_completed: null,
                      completed_total: 0,
                      completed_yellow: 0,
                      unassigned: 3,
                      completed_green: 0,
                      ready_yellow: 0,
                      unknown: 0,
                      total: 7,
                      ready_total: 1,
                      ready_red: 0,
                      deleted: 0,
                      active_tasks: {
                        '8TnoJ31dzmaJCFgHKLP5_sale_customer_signature_1695846303814': '8TnoJ31dzmaJCFgHKLP5_sale_customer_signature_1695846303814',
                      },
                      not_ready: 3,
                      completed_red: 0,
                    },
                    install_to_activation: {
                      ready_green: 0,
                      timestamp_last_task_completed: null,
                      completed_total: 0,
                      completed_yellow: 0,
                      unassigned: 5,
                      completed_green: 0,
                      ready_yellow: 0,
                      unknown: 0,
                      total: 9,
                      ready_total: 0,
                      ready_red: 0,
                      deleted: 1,
                      active_tasks: {},
                      not_ready: 4,
                      completed_red: 0,
                    },
                  },
                  current_active_tasks: [
                    {
                      id_number: '309E-100CUST-001CSIG',
                      associated_owner_name: 'John Morrell',
                      status_yellow_days_cutoff: 2,
                      name: 'Customer Signature',
                      associated_team_name: null,
                      key: '8TnoJ31dzmaJCFgHKLP5_sale_customer_signature_1695846303814',
                      status_green_days_cutoff: 1,
                      timestamp_start: {
                        _seconds: 1719596789,
                        _nanoseconds: 356000000,
                      },
                    },
                  ],
                  associated_accounts_payable_key: 'Jb8EcdrI15fIbAMZ6G2iOu1qavA2',
                  associated_accounts_payable_name: 'John Morrell',
                  last_contact_log_notes: 'Test Contact Log with Customer',
                  last_contact_log_person: 'John Morrell',
                  timestamp_last_contact_log: {
                    _seconds: 1719596760,
                    _nanoseconds: 0,
                  },
                  last_contact_log_type: 'phone',
                },
              },
            ],
          },
        },
        '400': {
          key: '400',
          response: {
            success: false,
            error: {
              code: 'ER-API-GPDBK-04',
              message: 'Request Missing Required Parameters',
            },
          },
        },
        '401': {
          key: '401',
          response: exampleResponse401,
        },
      },
    }
    apiDocumentation['endpoints']['GET /project/contact_logs/{id}'] = {
      description: 'Fetch project contact logs by its ID',
      short_description: 'Fetch project contact logs',
      method: 'GET',
      url: '/projects/contact_logs/{id}',
      parameters: {
        '{id}': {
          key: '{id}',
          required: true,
          description: 'The ID of the project to fetch.',
          example_value: exampleProjectKey,
          data_type: 'string',
        },
        'api_key': {
          key: 'api_key',
          required: true,
          description: "The API key for the client's account.",
          example_value: getProp(userInfo, 'api_key', exampleAPIKey),
          data_type: 'string',
        },
        'uid': {
          key: 'uid',
          required: true,
          description: "The UID for the client's account.",
          example_value: getProp(userInfo, 'uid', exampleUIDKey),
          data_type: 'string',
        },
      },
      example_request: {
        api_key: getProp(userInfo, 'api_key', exampleAPIKey),
        uid: getProp(userInfo, 'uid', exampleUIDKey),
      },
      example_responses: {
        '200': {
          key: '200',
          response: {
            success: true,
            project_id: exampleProjectKey,
            projects: [
              {
                etw_project_key: exampleProjectKey,
                contact_logs: {
                  '1719596819893_p5ufzn': {
                    contact_type: 'phone',
                    notes: 'Test Contact Log with Customer',
                    contact_person: 'John Morrell',
                    contact_date: {
                      _seconds: 1719596760,
                      _nanoseconds: 0,
                    },
                    key: '1719596819893_p5ufzn',
                  },
                },
              },
            ],
          },
        },
        '400': {
          key: '400',
          response: {
            success: false,
            error: {
              code: 'ER-API-GPCLBK-04',
              message: 'Request Missing Required Parameters',
            },
          },
        },
        '401': {
          key: '401',
          response: exampleResponse401,
        },
      },
    }
    apiDocumentation['endpoints']['GET /project/documents/{id}'] = {
      description: 'Fetch project documents by its ID - Links will expire in 24 hours.',
      short_description: 'Fetch project documents',
      method: 'GET',
      url: '/projects/documents/{id}',
      parameters: {
        '{id}': {
          key: '{id}',
          required: true,
          description: 'The ID of the project to fetch.',
          example_value: exampleProjectKey,
          data_type: 'string',
        },
        'api_key': {
          key: 'api_key',
          required: true,
          description: "The API key for the client's account.",
          example_value: getProp(userInfo, 'api_key', exampleAPIKey),
          data_type: 'string',
        },
        'uid': {
          key: 'uid',
          required: true,
          description: "The UID for the client's account.",
          example_value: getProp(userInfo, 'uid', exampleUIDKey),
          data_type: 'string',
        },
      },
      example_request: {
        api_key: getProp(userInfo, 'api_key', exampleAPIKey),
        uid: getProp(userInfo, 'uid', exampleUIDKey),
      },
      example_responses: {
        '200': {
          key: '200',
          response: {
            success: true,
            project_id: '8TnoJ31dzmaJCFgHKLP5',
            projects: [
              {
                etw_project_key: '8TnoJ31dzmaJCFgHKLP5',
                agreements: [
                  'https://storage.googleapis.com/etw-energy.appspot.com/clients/etw_energy/projects/8TnoJ31dzmaJCFgHKLP5/documents/agreements/solar-gettyimages-525206743%20%2806-28-2024%2012%3A49%3A14%29.webp?GoogleAccessId=firebase-adminsdk-xwn78%40etw-energy.iam.gserviceaccount.com&Expires=1719686968&Signature=C3rDtv97Q4uAxApyZcdWVDmooD942hCC2UDDmfoHvcFXrgHwlZcHnhdMxQ6c7JupgSC1vh8oh5S4ASrAoM6FTh%2F48BppjE%2FC%2FnxXtouFXpcCVRQh95xRBrak3tRB%2BZgkZpqhHSjkxHz3OI2IebsG82PwejOZwO%2FPnGIXTC5oOdN4yr2UI4EyWd89%2Favl%2FYcJLcuEvVw6KfiTbCrgItBkfDXnygcyCR0fXW9MOqCpET5O%2Fgi%2BX468LPtjKesH00%2FvzgeuQoB0aCEeOrRvguF4rWdPxBWkhMrghXY1zIM4O03pa3rPPRxL2xC33LMzS46ClO3YR8UsL5EhUah9hRCTug%3D%3D',
                ],
                design: [],
                hoa: [],
                inspections: [],
                interconnection: [],
                loan: [],
                permits: [],
                proposals: [],
                site_audit: [],
                sow_documents: [],
                utility: [],
              },
            ],
          },
        },
        '400': {
          key: '400',
          response: {
            success: false,
            error: {
              code: 'ER-API-GPDBK-04',
              message: 'Request Missing Required Parameters',
            },
          },
        },
        '401': {
          key: '401',
          response: exampleResponse401,
        },
      },
    }
    apiDocumentation['endpoints']['GET /project/photos/{id}'] = {
      description: 'Fetch project photos by its ID - Links will expire in 24 hours.',
      short_description: 'Fetch project photos',
      method: 'GET',
      url: '/projects/photos/{id}',
      parameters: {
        '{id}': {
          key: '{id}',
          required: true,
          description: 'The ID of the project to fetch.',
          example_value: exampleProjectKey,
          data_type: 'string',
        },
        'api_key': {
          key: 'api_key',
          required: true,
          description: "The API key for the client's account.",
          example_value: getProp(userInfo, 'api_key', exampleAPIKey),
          data_type: 'string',
        },
        'uid': {
          key: 'uid',
          required: true,
          description: "The UID for the client's account.",
          example_value: getProp(userInfo, 'uid', exampleUIDKey),
          data_type: 'string',
        },
      },
      example_request: {
        api_key: getProp(userInfo, 'api_key', exampleAPIKey),
        uid: getProp(userInfo, 'uid', exampleUIDKey),
      },
      example_responses: {
        '200': {
          key: '200',
          response: {
            success: true,
            project_id: '8TnoJ31dzmaJCFgHKLP5',
            projects: [
              {
                etw_project_key: '8TnoJ31dzmaJCFgHKLP5',
                photos: [
                  'https://storage.googleapis.com/etw-energy.appspot.com/clients/etw_energy/projects/8TnoJ31dzmaJCFgHKLP5/images/solar-gettyimages-525206743%20%2806-28-2024%2011%3A47%3A34%29.webp?GoogleAccessId=firebase-adminsdk-xwn78%40etw-energy.iam.gserviceaccount.com&Expires=1719686105&Signature=ZXPsd%2BkuLTEJg7SGTISyJF2JIMfafswV%2Bl4npORAwvNk2JbNvhZPdNfugbXzoYi8nWjaI7oLs1oulxebdltVvJg6kRic03anAMaKEpqZ5t5HLxsltya1BT4MYgXRK99ljl%2Blg%2BkK7UsjR53GiSPWecuQ28wx3GLFXEvoPwDjK%2FognUSVhC7UuXEaPH8VBjw6DkxcJf8GZKhg06oHeMSCGiK%2Bfm8%2Ft2hJbmVN%2BgASq3S2%2FI4uULpaJrw0mjNtvDsqddVrpcEAjF5ahU8UIG5e5FH8jHMqhS1DpwTqiYpWBcNefMIowWIXgaHfDNMoBdSL03mrOFSqLlBmo9NFgCBpdQ%3D%3D',
                  'https://storage.googleapis.com/etw-energy.appspot.com/clients/etw_energy/projects/8TnoJ31dzmaJCFgHKLP5/images/thumbnails/solar-gettyimages-525206743%20%2806-28-2024%2011%3A47%3A34%29_200x200.webp?GoogleAccessId=firebase-adminsdk-xwn78%40etw-energy.iam.gserviceaccount.com&Expires=1719686105&Signature=ELlkvj9si%2FV5yGoaf3VxCg1A%2Bk8jWnKimkM%2BHeAvdbkdCTh3e1n8u9pflROxal6Yz4xJMOKH%2Fcq1zDES2%2FqTxSnJKmsjkPttkQUbc%2BaKtn5dTm5h0s0hEF9Ddo6D7Wj3kr%2FTfIKvS8xjdF%2Bgnh1XHlocQuAuGzzpkanqswvVBayQ5DlzHyzYIdfyYH7N%2BWXbDlvQ%2F0oBGAO9azP6JtQoKfGlEpcOSVTLUMG8aXKijsGNrxX%2B2utFTVPL%2Fbm1UkJB9V5SU%2BAElpyUEjLjp4CaG0cx9CIRBhXqlWu%2FvxJ64cjZvW35aX2BZgMG9CaR5uqKb3NhF7lgZwJccFNB%2FBjIKA%3D%3D',
                ],
              },
            ],
          },
        },
        '400': {
          key: '400',
          response: {
            success: false,
            error: {
              code: 'ER-API-GPIBK-04',
              message: 'Request Missing Required Parameters',
            },
          },
        },
        '401': {
          key: '401',
          response: exampleResponse401,
        },
      },
    }
  }
  if (userApiPermissions.projects_write === true) {
    apiDocumentation['endpoints']['POST /project_sync/{id}'] = {
      description: 'Initiate a sync of a project by its ID',
      short_description: 'Initiate project sync',
      alert_banner: 'This endpoint requires additional configuration steps from ETW Energy. Please contact support for assistance.',
      method: 'POST',
      url: '/project_sync/{id}',
      parameters: {
        '{id}': {
          key: '{id}',
          required: true,
          description: 'The ID of the project to sync.',
          example_value: exampleProjectKey,
          data_type: 'string',
        },
        'api_key': {
          key: 'api_key',
          required: true,
          description: "The API key for the client's account.",
          example_value: getProp(userInfo, 'api_key', exampleAPIKey),
          data_type: 'string',
        },
        'uid': {
          key: 'uid',
          required: true,
          description: "The UID for the client's account.",
          example_value: getProp(userInfo, 'uid', exampleUIDKey),
          data_type: 'string',
        },
      },
      example_request: {
        api_key: getProp(userInfo, 'api_key', exampleAPIKey),
        uid: getProp(userInfo, 'uid', exampleUIDKey),
      },
      example_responses: {
        '200': {
          key: '200',
          response: {
            success: true,
            project_id: exampleProjectKey,
            timestamp_sync_started: new Date().toISOString(),
          },
        },
        '400': {
          key: '400',
          response: {
            success: false,
            error: {
              code: 'ER-API-TPS-01',
              message: 'Sync Configuration has not been set up for your account. Please contact support.',
            },
          },
        },
        '401': {
          key: '401',
          response: exampleResponse401,
        },
      },
    }
  }

  return apiDocumentation
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			The component documentation component renders all the info needed to quickly build a boilerplate component with working examples, todo lists, etc

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Card, Divider, Typography } from '@mui/material/'
import { Trans } from 'react-i18next'
import { Html, Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic, TsInterface_TabContent } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentPropsList {
  propKey: string
  propDescription: string
  propTypeOrInterface: string
  propExampleValue: TsInterface_UnspecifiedObject | string
}

interface TsInterface_ComponentDocumentationIndividualObject {
  component_name: JSX.Element | string
  component_description: JSX.Element | string
  component_jsx: JSX.Element
  component_example_jsx_string: string
  component_props: {
    [$propKey: string]: TsInterface_ComponentPropsList
  }
  component_todo: {
    [$todoKey: string]: JSX.Element | string
  }
}

export interface TsInterface_ComponentDocumentation {
  group_name: JSX.Element | string
  group_description: JSX.Element | string
  components: {
    [$componentKey: string]: TsInterface_ComponentDocumentationIndividualObject
  }
}

interface TsInterface_ComponentProps {
  documentation: TsInterface_ComponentDocumentation
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_COMPONENT: JSX.Element = <Trans>Component</Trans>
const s_EXAMPLE: JSX.Element = <Trans>Example</Trans>
const s_PROPS: JSX.Element = <Trans>Props</Trans>
const s_USAGE: JSX.Element = <Trans>Usage</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const ComponentDocumentation = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_documentation: TsInterface_ComponentDocumentation = getProp(props, 'documentation', { components: {} })

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const returnComponentSubTabs = (component: TsInterface_ComponentDocumentationIndividualObject): TsInterface_TabContent[] => {
    let componentDataTabsArray: TsInterface_TabContent[] = [
      {
        tabHeader: (
          <Box>
            <Icon
              icon="memo"
              className="tw-inline-block tw-mr-2"
            />
            <Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{s_COMPONENT}</Typography>
          </Box>
        ),
        tabContent: (
          <Box className="tw-m-4">
            <Typography variant="body1">{component.component_description}</Typography>
          </Box>
        ),
      },
      {
        tabHeader: (
          <Box>
            <Icon
              icon="code"
              className="tw-inline-block tw-mr-2"
            />
            <Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{s_PROPS}</Typography>
          </Box>
        ),
        tabContent: (
          <Box className="tw-m-4">
            <Box>
              <Typography
                variant="h6"
                className="tw-font-bold"
              >
                {s_USAGE}
              </Typography>
              <Box className="tw-ml-2">
                <Html data={component.component_example_jsx_string} />
              </Box>
            </Box>
            <Box className="tw-mt-2">
              <Typography
                variant="h6"
                className="tw-font-bold"
              >
                {s_PROPS}
              </Typography>
              {objectToArray(component.component_props).map((prop: TsInterface_ComponentPropsList, index: number) => (
                <Box key={index}>
                  <Box>
                    <Typography
                      variant="subtitle1"
                      className="tw-font-bold tw-inline-block tw-text-info_main tw-mr-1"
                    >
                      {prop.propKey}:
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className="tw-inline-block tw-opacity-50"
                    >
                      {prop.propTypeOrInterface}
                    </Typography>
                  </Box>
                  <Box>
                    <Icon
                      icon="circle-arrow-right"
                      className="tw-inline-block tw-ml-2 tw-mr-1 tw-text-info_main tw-mt-1"
                      size="xl"
                    />
                    <Typography
                      variant="subtitle1"
                      className="tw-inline-block tw-align-top"
                    >
                      {prop.propDescription}
                    </Typography>
                  </Box>
                  <Box className="tw-mb-4 tw-ml-2">
                    <Json
                      alphabetized={false}
                      data={prop.propExampleValue}
                      include_functions={true}
                    />
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        ),
      },
      {
        tabHeader: (
          <Box>
            <Icon
              icon="bookmark"
              className="tw-inline-block tw-mr-2"
            />
            <Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{s_EXAMPLE}</Typography>
          </Box>
        ),
        tabContent: <Box className="tw-m-4">{component.component_jsx}</Box>,
      },
      {
        tabHeader: (
          <Box>
            <Icon
              icon="clipboard-check"
              className="tw-inline-block tw-mr-2"
            />
            <Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{rLIB('Todo')}</Typography>
          </Box>
        ),
        tabContent: (
          <Box className="tw-m-4">
            {objectToArray(component.component_todo).map((todoItem: string, index: number) => (
              <Box
                key={index}
                className="tw-align-top"
              >
                <Icon
                  icon="square"
                  type="light"
                  size="sm"
                  className="tw-mr-3 tw-opacity-50 tw-mt-1"
                />
                <Typography
                  variant="subtitle1"
                  className="tw-inline-block tw-align-top"
                >
                  {todoItem}
                </Typography>
              </Box>
            ))}
          </Box>
        ),
      },
    ]
    return componentDataTabsArray
  }

  const returnComponentTabs = (): TsInterface_TabContent[] => {
    let componentTabsArray: TsInterface_TabContent[] = []
    for (let componentKey in pr_documentation['components']) {
      let loopComponent = pr_documentation['components'][componentKey]
      let loopComponentObject = {
        tabHeader: (
          <Box>
            <Typography className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">{loopComponent.component_name}</Typography>
          </Box>
        ),
        tabContent: (
          <Card>
            <TabsBasic
              tabs={returnComponentSubTabs(loopComponent)}
              tabsSettings={{}}
            />
          </Card>
        ),
      }
      componentTabsArray.push(loopComponentObject)
    }
    return componentTabsArray
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <Box className="tw-mt-2">
          <Icon
            icon="folder-grid"
            className="tw-inline-block tw-mr-2"
          />
          <Typography
            variant="h5"
            className="tw-font-bold tw-inline-block tw-align-top"
          >
            {pr_documentation.group_name}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">{pr_documentation.group_description}</Typography>
          <Divider className="tw-mt-4 tw-mb-1" />
          <TabsBasic
            tabs={returnComponentTabs()}
            tabsSettings={{}}
          />
        </Box>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

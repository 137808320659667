///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			Move previewTaskForm to a service?
			More outcome conditions (conditional logic)
			More outcome events (sms, email, more notification options...)
			Guard rails on publishing to prod

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Card,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material/'
import { returnTaskRows } from 'app/models/tasks'
import { globalTaskTypes, rJSX_TaskTypeSelector } from 'app/models/tasks/global_tasks'
import {
  adHocTaskAssignmentTypeInputOptions,
  checkIfTaskIsUpstream,
  findRecursiveTasks,
  returnTaskPrerequisiteAnalysisObject,
  taskAssignmentTypeInputOptions,
} from 'app/models/tasks/task_workflow_services'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, hardcodedProjectPhases, returnClientUserRoles, schedulingTeamTypes } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveTaskBlueprints_Query } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_TaskFormsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import {
  DatabaseRef_TaskWorkflowConfigItems_Collection,
  DatabaseRef_TaskWorkflowConfigItems_Document,
  DatabaseRef_TaskWorkflowProdTaskGroups_Document,
  DatabaseRef_TaskWorkflowProdTasksHistory_Document,
  DatabaseRef_TaskWorkflowProd_Document,
  DatabaseRef_TaskWorkflowSowItems_Collection,
  DatabaseRef_TaskWorkflowSowItems_Document,
  DatabaseRef_TaskWorkflowStagingTasks_Collection,
  DatabaseRef_TaskWorkflowStagingTasks_Document,
  DatabaseRef_TaskWorkflowTaskGroups_Collection,
  DatabaseRef_TaskWorkflow_Document,
} from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableManageAction,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TabsUrl, TsInterface_TabContentUrlArray, TsInterface_TabsSettingsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
  TsInterface_PromptDialogObject,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseDeleteDocument,
  DatabaseFieldDelete,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { arrayToObject, cloneObjectWithoutReference, dynamicSort, generateRandomString, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColors, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_AddTaskToV2WorkflowDialog {
  us_blueprintTasks: TsInterface_UnspecifiedObject
  pr_workflowKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseTaskWorkflowViewPage']['key']

// Form Settings
const estimatedDurationOptions: TsInterface_UnspecifiedObject = {
  1: {
    key: 1,
    value: (
      <>
        {1} {rLIB('hour')}
      </>
    ),
  },
  2: {
    key: 2,
    value: (
      <>
        {2} {rLIB('hours')}
      </>
    ),
  },
  3: {
    key: 3,
    value: (
      <>
        {3} {rLIB('hours')}
      </>
    ),
  },
  4: {
    key: 4,
    value: (
      <>
        {4} {rLIB('hours')}
      </>
    ),
  },
  5: {
    key: 5,
    value: (
      <>
        {5} {rLIB('hours')}
      </>
    ),
  },
  6: {
    key: 6,
    value: (
      <>
        {6} {rLIB('hours')}
      </>
    ),
  },
  7: {
    key: 7,
    value: (
      <>
        {7} {rLIB('hours')}
      </>
    ),
  },
  8: {
    key: 8,
    value: (
      <>
        {8} {rLIB('hours')}
      </>
    ),
  },
  9: {
    key: 9,
    value: (
      <>
        {9} {rLIB('hours')}
      </>
    ),
  },
  10: {
    key: 10,
    value: (
      <>
        {10} {rLIB('hours')}
      </>
    ),
  },
  12: {
    key: 12,
    value: (
      <>
        {12} {rLIB('hours')}
      </>
    ),
  },
  16: {
    key: 16,
    value: (
      <>
        {16} {rLIB('hours')}
      </>
    ),
  },
  20: {
    key: 20,
    value: (
      <>
        {20} {rLIB('hours')}
      </>
    ),
  },
  24: {
    key: 24,
    value: (
      <>
        {24} {rLIB('hours')}
      </>
    ),
  },
  32: {
    key: 32,
    value: (
      <>
        {32} {rLIB('hours')}
      </>
    ),
  },
  40: {
    key: 40,
    value: (
      <>
        {40} {rLIB('hours')}
      </>
    ),
  },
}

const formInputs_TaskName: TsInterface_FormInputs = {
  name: {
    key: 'name',
    label: rLIB('Task Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
}

const formInputs_TaskAbbreviation: TsInterface_FormInputs = {
  abbreviation: {
    key: 'abbreviation',
    label: rLIB('Task Abbreviation'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
}

const formInputs_CompletionTime: TsInterface_FormInputs = {
  completion_time: {
    key: 'completion_time',
    label: rLIB('Completion Time'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: [
      { key: 'main', value: rLIB('Main Task') },
      { key: 'cancellation', value: rLIB('Cancellation Task') },
      { key: 'completion', value: rLIB('Completion Task') },
    ],
  },
}

// const formInputs_TaskRequired: TsInterface_FormInputs = {
// required: {
// 	key: "required",
// 	label: s_REQUIRED_TASK,
// 	input_type: "multiple_choice_radio",
// 	required: true,
// 	data_type: "string",
// 	options: objectToArray( taskRequiredInputOptions )
// },
// }

const returnProjectPhaseOptions = (): TsInterface_UnspecifiedObject[] => {
  let options: TsInterface_UnspecifiedObject[] = []
  for (let loopProjectPhaseKey in hardcodedProjectPhases) {
    let loopProjectPhase = hardcodedProjectPhases[loopProjectPhaseKey]
    options.push({
      key: loopProjectPhase.key,
      value: loopProjectPhase.name,
    })
  }
  return options
}

const formInputs_ProjectPhase: TsInterface_FormInputs = {
  associated_project_phase_key: {
    key: 'associated_project_phase_key',
    label: rLIB('Project Phase'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: returnProjectPhaseOptions(),
  },
}

const formInputs_TaskAssignmentType: TsInterface_FormInputs = {
  task_completion_type: {
    key: 'task_completion_type',
    label: rLIB('Task Completion Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(taskAssignmentTypeInputOptions),
  },
}

const formInputs_SowTaskAssignmentType: TsInterface_FormInputs = {
  task_completion_type: {
    key: 'task_completion_type',
    label: rLIB('Task Completion Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(adHocTaskAssignmentTypeInputOptions),
  },
}

// const formInputs_TaskAssignerType: TsInterface_FormInputs = {
// associated_assigner_type: {
// 	key: "associated_assigner_type",
// 	label: s_ASSIGNER_USER_TYPE,
// 	input_type: "multiple_choice_radio",
// 	required: true,
// 	data_type: "string",
// 	options: []
// },
// }

const formInputs_AssociatedDispatchedTasks: TsInterface_FormInputs = {
  associated_dispatched_task_key: {
    key: 'associated_dispatched_task_key',
    label: (
      <>
        {rLIB('Task to Dispatch')} ({rLIB('Must have this task as a prerequisite')})
      </>
    ),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: [],
  },
}

const formInputs_AssociatedTeamType: TsInterface_FormInputs = {
  associated_team_type: {
    key: 'associated_team_type',
    label: <>{rLIB('Dispatched Team Type')}</>,
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(schedulingTeamTypes),
  },
}

const formInputs_EstimatedTaskDuration: TsInterface_FormInputs = {
  estimated_task_duration_hours: {
    key: 'estimated_task_duration_hours',
    label: (
      <>
        {rLIB('Estimated Task Duration')} ({rLIB('hours')})
      </>
    ),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(estimatedDurationOptions),
  },
}

const formInputs_TaskOwnerType: TsInterface_FormInputs = {
  associated_owner_type: {
    key: 'associated_owner_type',
    label: rLIB('Owner User Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: [],
  },
}

const formInputs_TaskDispatcherType: TsInterface_FormInputs = {
  associated_dispatcher_type: {
    key: 'associated_dispatcher_type',
    label: rLIB('Dispatcher Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: [],
  },
}

const formInputs_TaskTeamType: TsInterface_FormInputs = {
  associated_team_type: {
    key: 'associated_team_type',
    label: rLIB('Scheduled Team Type'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: objectToArray(schedulingTeamTypes),
  },
}

const formInputs_DelayReason: TsInterface_FormInputs = {
  delay_reason: {
    key: 'delay_reason',
    label: rLIB('Delay Reason'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
}

const formInputs_AssociatedTaskForm: TsInterface_FormInputs = {
  associated_task_form_key: {
    key: 'associated_task_form_key',
    label: rLIB('Task Form'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    submit_on_change: true,
    options: [],
  },
}

const formInputs_TaskGroupKey: TsInterface_FormInputs = {
  associated_task_group_key: {
    key: 'associated_task_group_key',
    label: rLIB('Task Group'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: [],
  },
}

const formInputs_EstimatedDays: TsInterface_FormInputs = {
  status_green_days_cutoff: {
    key: 'status_green_days_cutoff',
    label: rLIB('Green Status Cutoff (days)'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
    startAdornment: (
      <Box sx={{ color: themeVariables.success_main }}>
        <Icon icon="circle-play" />
      </Box>
    ),
  },
  status_yellow_days_cutoff: {
    key: 'status_yellow_days_cutoff',
    label: rLIB('Yellow Status Cutoff (days)'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
    startAdornment: (
      <Box sx={{ color: themeVariables.warning_main }}>
        <Icon icon="triangle-exclamation" />
      </Box>
    ),
  },
  // status_red_days_cutoff: {
  // key: "status_red_days_cutoff",
  // label: s_RED_STATUS_CUTOFF_DAYS,
  // input_type: "text_number",
  // required: true,
  // data_type: "number",
  // startAdornment: <Box sx={{ color: themeVariables.error_main }}><Icon icon="siren-on"/></Box>
  // },
}

const formInputs_TaskOutcome: TsInterface_FormInputs = {
  name: {
    key: 'name',
    label: rLIB('Task Outcome Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
}

const formInputs_OutcomeName: TsInterface_FormInputs = {
  name: {
    key: 'name',
    label: rLIB('Outcome Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
}

const formInputs_TaskOutcomeCondition: TsInterface_FormInputs = {
  outcome_condition_type: {
    key: 'outcome_condition_type',
    label: rLIB('Condition'),
    input_type: 'multiple_choice_select',
    required: true,
    data_type: 'string',
    options: [
      { key: 'always', value: rLIB('Always Trigger') },
      { key: 'conditional', value: rLIB('Conditional Trigger'), disabled: true }, // TODO - need to handle in function below
    ],
  },
  outcome_condition_logic: {
    // TODO
    data_type: 'string',
    key: 'outcome_condition_logic',
    input_type: 'custom_form_input_jsx',
    label: rLIB('Condition'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'outcome_condition_type',
        comparator: '==',
        value: 'conditional',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'outcome_condition_type',
        comparator: '==',
        value: 'conditional',
        conditions: [],
      },
    },
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = <>TODO Conditional</>
      return inputJSX
    },
  },
}

const formInputs_TaskOutcomeEvent: TsInterface_FormInputs = {
  outcome_event_type: {
    key: 'outcome_event_type',
    label: rLIB('Condition'),
    input_type: 'multiple_choice_select',
    required: true,
    data_type: 'string',
    options: [{ key: 'app_notification', value: rLIB('Send in platform notification') }],
  },
  app_notification_recipient: {
    data_type: 'string',
    key: 'app_notification_recipient',
    input_type: 'multiple_choice_radio',
    options: [{ key: 'css_rep', value: rLIB('CSS Rep') }],
    label: rLIB('Notification Recipient'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'outcome_event_type',
        comparator: '==',
        value: 'app_notification',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'outcome_event_type',
        comparator: '==',
        value: 'app_notification',
        conditions: [],
      },
    },
  },
  app_notification_text: {
    data_type: 'string',
    key: 'app_notification_text',
    input_type: 'text_basic',
    label: rLIB('Notification Text'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'outcome_event_type',
        comparator: '==',
        value: 'app_notification',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'outcome_event_type',
        comparator: '==',
        value: 'app_notification',
        conditions: [],
      },
    },
  },
  app_notification_type: {
    data_type: 'string',
    key: 'app_notification_type',
    input_type: 'multiple_choice_radio',
    options: [
      { key: 'success', value: rLIB('Success') },
      { key: 'info', value: rLIB('Info') },
      { key: 'warning', value: rLIB('Warning') },
      { key: 'error', value: rLIB('Error') },
    ],
    label: rLIB('Notification Type'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'outcome_event_type',
        comparator: '==',
        value: 'app_notification',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'outcome_event_type',
        comparator: '==',
        value: 'app_notification',
        conditions: [],
      },
    },
  },
}

// Tables
const tableColumns_TaskGroups: TsInterface_TableColumns = {
  manage: TableCellManage({
    move_up: {
      icon: <Icon icon="up" />,
      label: <>{rLIB('Move up')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (tableAdditionalData != null && tableAdditionalData['stagingTaskGroups'] != null && tableAdditionalData['workflowKey'] != null) {
          let stagingTaskGroupsArray = objectToArray(tableAdditionalData['stagingTaskGroups']).sort(dynamicSort('timestamp_created', null))
          let matchIndex: number = -1
          for (let loopIndex = 0; loopIndex < stagingTaskGroupsArray.length; loopIndex++) {
            let loopTaskGroup = stagingTaskGroupsArray[loopIndex]
            if (loopTaskGroup.key === rowData.key) {
              matchIndex = loopIndex
            }
          }
          if (matchIndex !== -1 && matchIndex !== 0) {
            let updateObjectOne = { timestamp_created: stagingTaskGroupsArray[matchIndex]['timestamp_created'] }
            let updateObjectTwo = { timestamp_created: stagingTaskGroupsArray[matchIndex - 1]['timestamp_created'] }
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                  {
                    type: 'setMerge',
                    ref: DatabaseRef_TaskWorkflowProdTaskGroups_Document(
                      res_GCK.clientKey,
                      tableAdditionalData.workflowKey,
                      stagingTaskGroupsArray[matchIndex]['key'],
                    ),
                    data: updateObjectTwo,
                  },
                  {
                    type: 'setMerge',
                    ref: DatabaseRef_TaskWorkflowProdTaskGroups_Document(
                      res_GCK.clientKey,
                      tableAdditionalData.workflowKey,
                      stagingTaskGroupsArray[matchIndex - 1]['key'],
                    ),
                    data: updateObjectOne,
                  },
                ]
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    // Nothing
                  })
                  .catch((rej_DBU) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                  })
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          } else {
            // Nothing
          }
        }
      },
    },
    move_down: {
      icon: <Icon icon="down" />,
      label: <>{rLIB('Move down')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (tableAdditionalData != null && tableAdditionalData['stagingTaskGroups'] != null && tableAdditionalData['workflowKey'] != null) {
          let stagingTaskGroupsArray = objectToArray(tableAdditionalData['stagingTaskGroups']).sort(dynamicSort('timestamp_created', null))
          let matchIndex: number = -1
          for (let loopIndex = 0; loopIndex < stagingTaskGroupsArray.length; loopIndex++) {
            let loopTaskGroup = stagingTaskGroupsArray[loopIndex]
            if (loopTaskGroup.key === rowData.key) {
              matchIndex = loopIndex
            }
          }
          if (matchIndex !== -1 && matchIndex < stagingTaskGroupsArray.length - 1) {
            let updateObjectOne = { timestamp_created: stagingTaskGroupsArray[matchIndex]['timestamp_created'] }
            let updateObjectTwo = { timestamp_created: stagingTaskGroupsArray[matchIndex + 1]['timestamp_created'] }
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                  {
                    type: 'setMerge',
                    ref: DatabaseRef_TaskWorkflowProdTaskGroups_Document(
                      res_GCK.clientKey,
                      tableAdditionalData.workflowKey,
                      stagingTaskGroupsArray[matchIndex]['key'],
                    ),
                    data: updateObjectTwo,
                  },
                  {
                    type: 'setMerge',
                    ref: DatabaseRef_TaskWorkflowProdTaskGroups_Document(
                      res_GCK.clientKey,
                      tableAdditionalData.workflowKey,
                      stagingTaskGroupsArray[matchIndex + 1]['key'],
                    ),
                    data: updateObjectOne,
                  },
                ]
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    // Nothing
                  })
                  .catch((rej_DBU) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                  })
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          } else {
            // Nothing
          }
        }
      },
    },
    delete: {
      icon: <Icon icon="trash" />,
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (tableAdditionalData.workflowKey != null && rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Task Group'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this task group?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseDeleteDocument(
                        DatabaseRef_TaskWorkflowProdTaskGroups_Document(res_GCK.clientKey, tableAdditionalData.workflowKey, rowData.key as string),
                      )
                        .then((res_DDD) => {
                          resolve(res_DDD)
                        })
                        .catch((rej_DDD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  TEMP_publishedIcon: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let prodTaskItems: TsInterface_UnspecifiedObject = {}
        if (tableAdditionalData != null && tableAdditionalData['prodWorkflow'] != null && tableAdditionalData['prodWorkflow']['task_groups'] != null) {
          prodTaskItems = arrayToObject(tableAdditionalData['prodWorkflow']['task_groups'], 'key')
        }
        if (
          rowData != null &&
          rowData.key != null &&
          prodTaskItems != null &&
          prodTaskItems[rowData.key as string] != null &&
          prodTaskItems[rowData.key as string]['name'] === rowData.name &&
          prodTaskItems[rowData.key as string]['associated_project_phase_key'] === rowData.associated_project_phase_key
        ) {
          cellJSX = (
            <Tooltip title={rLIB('Published to Prod')}>
              <Box sx={{ color: themeVariables.success_main }}>
                <Icon icon="circle-check" />
              </Box>
            </Tooltip>
          )
        } else {
          cellJSX = (
            <Tooltip title={rLIB('Not Published to Prod')}>
              <Box sx={{ color: themeVariables.gray_600 }}>
                <Icon icon="ghost" />
              </Box>
            </Tooltip>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'tw-w-4'
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
  },
  name: TableCellBasic('name', rLIB('Group Name'), 'name'),
  project_phase: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        const openProjectEdit = () => {
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: tableAdditionalData,
                formData: rowData,
                formInputs: formInputs_ProjectPhase,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    if (formAdditionalData != null && formAdditionalData.workflowKey != null && rowData != null && rowData.key != null) {
                      getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                        .then((res_GCK) => {
                          DatabaseSetMergeDocument(
                            DatabaseRef_TaskWorkflowProdTaskGroups_Document(res_GCK.clientKey, formAdditionalData.workflowKey as string, rowData.key as string),
                            formSubmittedData,
                          )
                            .then((res_DSMD) => {
                              resolve(res_DSMD)
                            })
                            .catch((rej_DSMD) => {
                              tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            })
                        })
                        .catch((rej_GCK) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        })
                    } else {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({
                        display: true,
                        error: {
                          message: rLIB('Failed to select project phase'),
                          details: rLIB('Missing required parameters'),
                          code: 'ER-S-TWV-TCTG-01',
                        },
                      })
                    }
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Select Project Phase'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }
        if (
          rowData.associated_project_phase_key != null &&
          hardcodedProjectPhases[rowData.associated_project_phase_key as string] != null &&
          hardcodedProjectPhases[rowData.associated_project_phase_key as string]['name'] != null
        ) {
          cellJSX = (
            <Box>
              <Typography
                variant="body1"
                className="tw-inline-block"
              >
                {hardcodedProjectPhases[rowData.associated_project_phase_key as string]['name']}
              </Typography>
              <Box
                className="tw-ml-2 tw-inline-block tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
                onClick={() => {
                  openProjectEdit()
                }}
              >
                <Icon icon="pen-to-square"></Icon>
              </Box>
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Typography
                variant="body1"
                className="tw-inline-block tw-italic tw-opacity-30"
              >
                {rLIB('Not Selected')}
              </Typography>
              <Box
                className="tw-ml-2 tw-inline-block tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
                onClick={() => {
                  openProjectEdit()
                }}
              >
                <Icon icon="pen-to-square"></Icon>
              </Box>
            </Box>
          )
        }
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Project Phase')
      },
      header_sort_by: null,
    },
  },
}

const tableSettings_TaskGroups: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'timestamp_created',
  sortable: false,
  collapsible_columns: true,
  searchable: true,
  search_settings_basic: {
    search_no_data_message: rLIB('No task groups found by search') as JSX.Element,
    search_property_options: [
      { key: 'name', value: rLIB('Group Name') },
      { key: 'project_phase', value: rLIB('Project Phase') },
    ],
  },
}

const tableSettings_SowItems: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
  collapsible_columns: true,
  searchable: true,
  search_settings_basic: {
    search_no_data_message: rLIB('No Additional Work Items found by search') as JSX.Element,
    search_property_options: [{ key: 'name', value: rLIB('Additional Work Item Name') }],
  },
}

const tableSettings_TaskTable: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'TEMP_id_number_sort',
  sortable: true,
  collapsible_columns: true,
  searchable: true,
  search_settings_basic: {
    search_no_data_message: rLIB('No tasks found by search') as JSX.Element,
    search_property_options: [
      { key: 'name', value: rLIB('Task Name') },
      { key: 'abbreviation', value: rLIB('Task Abbreviation') },
    ],
  },
}

const tableSettings_AdditionalWorkTable: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
  collapsible_columns: true,
  searchable: true,
  search_settings_basic: {
    search_no_data_message: rLIB('No tasks found by search') as JSX.Element,
    search_property_options: [
      { key: 'name', value: rLIB('Task Name') },
      { key: 'abbreviation', value: rLIB('Task Abbreviation') },
    ],
  },
}

// CSS
let workflowCss: string =
  `
		.workflow_table_highlight {
			background:` +
  themeVariables.info_main +
  `;
		}
	`

// Custom Dialog
const AddTaskToV2WorkflowCustomDialog: React.FC<TsInterface_AddTaskToV2WorkflowDialog> = ({ us_blueprintTasks, pr_workflowKey }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_initalStagingDataLoaded, us_setInitalStagingDataLoaded ] = 	useState< boolean >( false )
  const [us_forceRefreshStagingData, us_setForceRefreshStagingData] = useState<number>(0)
  const [us_stagingWorkflowTasks, us_setStagingWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskType, us_setTaskType] = useState<string>('')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // Determine Task ID Numbers
      // let sortedStagingTasksArray: TsInterface_UnspecifiedObject[] = []
      let taskIDNumbersObject: TsInterface_UnspecifiedObject = {}
      let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(newData))
      let sortedTaskRows = returnTaskRows(newData, {}, tasksThatReferenceThemselves, ['Task Workflow View useEffect 1'])
      let currentTaskID = 1
      for (let loopRowKey in sortedTaskRows) {
        let loopRow = sortedTaskRows[loopRowKey]
        if (loopRow != null && loopRow['tasks'] != null) {
          for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('timestamp_created', null))) {
            let loopTask = loopRow['tasks'][loopTaskIndex]
            if (loopTask != null && loopTask.key != null) {
              taskIDNumbersObject[loopTask.key] = currentTaskID
              currentTaskID++
            }
          }
        }
      }
      if (newData != null) {
        for (let loopTaskKey in newData) {
          let loopTask = newData[loopTaskKey]
          if (loopTask != null && loopTask.key != null) {
            for (let loopPrerequisiteTaskKey in loopTask['prerequisite_tasks']) {
              loopTask['prerequisite_tasks'][loopPrerequisiteTaskKey] = loopPrerequisiteTaskKey
            }
            loopTask['TEMP_id_number'] = getProp(taskIDNumbersObject, loopTaskKey, null)
            if (loopTask['TEMP_id_number'] != null && !isNaN(parseInt(loopTask['TEMP_id_number']))) {
              loopTask['TEMP_id_number'] = parseInt(loopTask['TEMP_id_number'])
              loopTask['TEMP_id_number_sort'] = 10000 + parseInt(loopTask['TEMP_id_number'])
            }
          }
          // sortedStagingTasksArray.push( loopTask )
        }
      }
      us_setStagingWorkflowTasks(newData)
      if (us_forceRefreshStagingData != null) {
        // us_setInitalStagingDataLoaded( true )
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskWorkflowStagingTasks_Collection(res_GCK.clientKey, pr_workflowKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_workflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_forceRefreshStagingData])

  // Other Variables
  const tableColumns_AddTaskToWorkflowTable: TsInterface_TableColumns = {
    name: TableCellBasic('name', rLIB('Task Name'), 'name'),
    TEMP_addToWorkflow: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Add Task to Workflow')
        },
        header_sort_by: null,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData['us_stagingWorkflowTasks'] != null &&
            tableAdditionalData['us_stagingWorkflowTasks'][rowData.key as string] != null
          ) {
            cellJSX = (
              <Button
                size="small"
                variant="contained"
                color="success"
                disabled={true}
                onClick={() => {
                  // Nothing
                }}
              >
                <Icon
                  icon="circle-plus"
                  className="tw-mr-2"
                />
                {rLIB('Task added to workflow')}
              </Button>
            )
          } else {
            cellJSX = (
              <Button
                size="small"
                variant="contained"
                color="success"
                onClick={() => {
                  let taskUpdateObject = {
                    associated_task_blueprint_key: rowData.key,
                    key: rowData.key,
                    name: rowData.name,
                    timestamp_created: new Date(),
                    associated_task_group_key: us_taskType,
                    associated_task_group_name: globalTaskTypes[us_taskType].name_string,
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(
                        DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, tableAdditionalData.pr_workflowKey, rowData.key as string),
                        taskUpdateObject,
                      )
                        .then((res_DSMD) => {
                          tableAdditionalData.us_setForceRefreshStagingData(new Date().getTime())
                          tableHooks.ur_forceRerender()
                        })
                        .catch((rej_DSMD) => {
                          console.error(rej_DSMD)
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }}
              >
                <Icon
                  icon="circle-plus"
                  className="tw-mr-2"
                />
                {rLIB('Add Task to Workflow')}
              </Button>
            )
          }
          ;<Box>
            <Box>{rowData['name']}</Box>
          </Box>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          return cellCSS
        },
      },
    },
  }

  // Functions
  // const returnFilteredTaskTableData

  // JSX Generation

  const rJSX_FilteredTaskTable = (): JSX.Element => {
    let taskTable = <></>
    if (us_taskType !== '') {
      taskTable = (
        <Box>
          <Box className="tw-p-2">{rJSX_TaskTypeSelector(us_taskType, us_setTaskType, 'all')}</Box>
          <TableBasic
            tableAdditionalData={{
              us_stagingWorkflowTasks: us_stagingWorkflowTasks,
              pr_workflowKey: pr_workflowKey,
              us_setForceRefreshStagingData: us_setForceRefreshStagingData,
            }}
            tableColumns={tableColumns_AddTaskToWorkflowTable}
            tableData={objectToArray(us_blueprintTasks).filter((task: TsInterface_UnspecifiedObject) => task.task_type === us_taskType)}
            tableSettings={{}}
          />
        </Box>
      )
    } else {
      taskTable = (
        <Box className="tw-text-center tw-pb-2">
          <Typography variant="body1">{rLIB('Select a task type')}</Typography>
          {rJSX_TaskTypeSelector(us_taskType, us_setTaskType, 'all')}
        </Box>
      )
    }
    return taskTable
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: themeVariables.background_json }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="circle-plus" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {rLIB('Add Task to Workflow')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent className="tw-p-0">
            <Box>{rJSX_FilteredTaskTable()}</Box>
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_workflowKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_additionalWorkTaskType, us_setAdditionalWorkTaskType] = useState<null | string>(null)
  const [us_blueprintTasks, us_setBlueprintTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredTaskBlueprints, us_setFilteredTaskBlueprints] = useState<TsInterface_UnspecifiedObject>({})
  const [us_forceRefreshStagingData, us_setForceRefreshStagingData] = useState<number>(0)
  const [us_initialProdDataLoaded, us_setInitialProdDataLoaded] = useState<boolean>(false)
  const [us_initialStagingDataLoaded, us_setInitialStagingDataLoaded] = useState<boolean>(false)
  const [us_lastEditedTaskKey, us_setLastEditedTaskKey] = useState<null | string>(null)
  const [us_prodRecursiveTasks, us_setProdRecursiveTasks] = useState<TsInterface_UnspecifiedObject | null>(null)
  const [us_prodWorkflow, us_setProdWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_prodWorkflowTasks, us_setProdWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_stagingConfigItems, us_setStagingConfigItems] = useState<TsInterface_UnspecifiedObject>({})
  const [us_stagingRecursiveTasks, us_setStagingRecursiveTasks] = useState<TsInterface_UnspecifiedObject | null>(null)
  const [us_stagingSowItems, us_setStagingSowItems] = useState<TsInterface_UnspecifiedObject>({})
  const [us_stagingTaskGroups, us_setStagingTaskGroups] = useState<TsInterface_UnspecifiedObject>({})
  const [us_stagingWorkflowTasks, us_setStagingWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskBlueprints, us_setTaskBlueprints] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskFormOptions, us_setTaskFormOptions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskGroupOptions, us_setTaskGroupOptions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskOwnerOptions, us_setTaskOwnerOptions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskView, us_setTaskView] = useState<string>('table')
  const [us_taskWorkflow, us_setTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_uiMinifiedTasks, us_setUiMinifiedTasks] = useState<boolean>(true)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'task_workflows'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  // useEffect(() => {
  // 	if( us_taskWorkflow != null && us_taskWorkflow.name != null ){
  // 		document.title = se_WORKFLOW + ": " + us_taskWorkflow.name
  // 	}
  // }, [ us_taskWorkflow ])

  useEffect(() => {
    // Generate List of Tasks with bad prerequisite data structure
    let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(us_stagingWorkflowTasks))
    us_setStagingRecursiveTasks(tasksThatReferenceThemselves)
  }, [us_stagingWorkflowTasks])

  useEffect(() => {
    // Generate List of Tasks with bad prerequisite data structure
    let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(us_prodWorkflowTasks))
    us_setProdRecursiveTasks(tasksThatReferenceThemselves)
  }, [us_prodWorkflowTasks])

  useEffect(() => {
    setTimeout(() => {
      let filteredTaskBlueprints = objectToArray(us_taskBlueprints).filter(
        (task: TsInterface_UnspecifiedObject) => task.task_type === us_additionalWorkTaskType,
      )
      us_setFilteredTaskBlueprints(filteredTaskBlueprints)
    }, 1)
  }, [us_taskBlueprints, us_additionalWorkTaskType, us_forceRefreshStagingData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskWorkflow(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, pr_workflowKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_workflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_forceRefreshStagingData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setBlueprintTasks(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTaskBlueprints_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_workflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_forceRefreshStagingData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // Determine Task ID Numbers
      // let sortedStagingTasksArray: TsInterface_UnspecifiedObject[] = []
      let taskIDNumbersObject: TsInterface_UnspecifiedObject = {}
      let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(newData))
      let sortedTaskRows = returnTaskRows(newData, {}, tasksThatReferenceThemselves, ['Task Workflow View useEffect 1'])
      let currentTaskID = 1
      for (let loopRowKey in sortedTaskRows) {
        let loopRow = sortedTaskRows[loopRowKey]
        if (loopRow != null && loopRow['tasks'] != null) {
          for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('timestamp_created', null))) {
            let loopTask = loopRow['tasks'][loopTaskIndex]
            if (loopTask != null && loopTask.key != null) {
              taskIDNumbersObject[loopTask.key] = currentTaskID
              currentTaskID++
            }
          }
        }
      }
      if (newData != null) {
        for (let loopTaskKey in newData) {
          let loopTask = newData[loopTaskKey]
          if (loopTask != null && loopTask.key != null) {
            for (let loopPrerequisiteTaskKey in loopTask['prerequisite_tasks']) {
              loopTask['prerequisite_tasks'][loopPrerequisiteTaskKey] = loopPrerequisiteTaskKey
            }
            loopTask['TEMP_id_number'] = getProp(taskIDNumbersObject, loopTaskKey, null)
            if (loopTask['TEMP_id_number'] != null && !isNaN(parseInt(loopTask['TEMP_id_number']))) {
              loopTask['TEMP_id_number'] = parseInt(loopTask['TEMP_id_number'])
              loopTask['TEMP_id_number_sort'] = 10000 + parseInt(loopTask['TEMP_id_number'])
            }
          }
          // sortedStagingTasksArray.push( loopTask )
        }
      }
      us_setStagingWorkflowTasks(newData)
      if (us_forceRefreshStagingData != null) {
        us_setInitialStagingDataLoaded(true)
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskWorkflowStagingTasks_Collection(res_GCK.clientKey, pr_workflowKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_workflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_forceRefreshStagingData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProdWorkflow(newData)
      // setInitalProdDataLoaded( true )
      // ur_forceRerender()
      // Determine Task ID Numbers
      // let sortedStagingTasksArray: TsInterface_UnspecifiedObject[] = []
      if (newData != null && newData['tasks'] != null) {
        let taskIDNumbersObject: TsInterface_UnspecifiedObject = {}
        let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(newData['tasks']))
        let sortedTaskRows = returnTaskRows(newData.tasks, {}, tasksThatReferenceThemselves, ['Task Workflow View useEffect 2'])
        let currentTaskID = 1
        for (let loopRowKey in sortedTaskRows) {
          let loopRow = sortedTaskRows[loopRowKey]
          if (loopRow != null && loopRow['tasks'] != null) {
            for (let loopTaskIndex in loopRow['tasks'].sort(dynamicSort('timestamp_created', null))) {
              let loopTask = loopRow['tasks'][loopTaskIndex]
              if (loopTask != null && loopTask.key != null) {
                taskIDNumbersObject[loopTask.key] = currentTaskID
                currentTaskID++
              }
            }
          }
        }
        for (let loopTaskKey in newData['tasks']) {
          let loopTask = newData['tasks'][loopTaskKey]
          for (let loopPrerequisiteTaskKey in loopTask['prerequisite_tasks']) {
            loopTask['prerequisite_tasks'][loopPrerequisiteTaskKey] = loopPrerequisiteTaskKey
          }
          loopTask['TEMP_id_number'] = getProp(taskIDNumbersObject, loopTaskKey, null)
          if (loopTask['TEMP_id_number'] != null && !isNaN(parseInt(loopTask['TEMP_id_number']))) {
            loopTask['TEMP_id_number'] = parseInt(loopTask['TEMP_id_number'])
            loopTask['TEMP_id_number_sort'] = 10000 + parseInt(loopTask['TEMP_id_number'])
          }
          // sortedStagingTasksArray.push( loopTask )
        }
      }
      us_setProdWorkflowTasks(newData)
      us_setInitialProdDataLoaded(true)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, pr_workflowKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_workflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setStagingTaskGroups(newData)
      let taskGroupsDataOptions: TsInterface_UnspecifiedObject = {}
      for (let loopTaskGroupKey in newData) {
        let loopTaskGroup = newData[loopTaskGroupKey]
        if (loopTaskGroup != null && loopTaskGroup['name'] != null) {
          taskGroupsDataOptions[loopTaskGroupKey] = {
            key: loopTaskGroupKey,
            value: loopTaskGroup['name'],
            timestamp_created: loopTaskGroup['timestamp_created'],
          }
        }
      }
      us_setTaskGroupOptions(taskGroupsDataOptions)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskWorkflowTaskGroups_Collection(res_GCK.clientKey, pr_workflowKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_workflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setStagingSowItems(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskWorkflowSowItems_Collection(res_GCK.clientKey, pr_workflowKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_workflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setStagingConfigItems(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskWorkflowConfigItems_Collection(res_GCK.clientKey, pr_workflowKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_workflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let clientUserRoles = returnClientUserRoles(res_GCK.clientKey)
        let formattedUserRoles: TsInterface_UnspecifiedObject = {}
        for (let loopUserRoleKey in clientUserRoles) {
          formattedUserRoles[loopUserRoleKey] = {
            key: loopUserRoleKey,
            value: clientUserRoles[loopUserRoleKey]['name'],
          }
        }
        us_setTaskOwnerOptions(formattedUserRoles)
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let taskFormsDataOptions: TsInterface_UnspecifiedObject = {}
      for (let loopTaskFormKey in newData) {
        let loopTaskForm = newData[loopTaskFormKey]
        if (loopTaskForm != null && loopTaskForm['name'] != null) {
          taskFormsDataOptions[loopTaskFormKey] = {
            key: loopTaskFormKey,
            value: loopTaskForm['name'],
          }
          if (loopTaskForm['available_for_use'] !== true) {
            taskFormsDataOptions[loopTaskFormKey]['disabled'] = true
          }
        }
      }
      us_setTaskFormOptions(taskFormsDataOptions)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskFormsActive_Query(res_GCK.clientKey, 'name', false, null, {}), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTaskBlueprints(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTaskBlueprints_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [pr_workflowKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Other Variables
  const tabsSettings: TsInterface_TabsSettingsUrl = {
    baseUrl: ApplicationPages.AdminDatabaseTaskWorkflowViewPage.url(pr_workflowKey),
    tabQueryParam: 'tab',
    overridePageTitle: true,
    basePageTitle: getProp(us_taskWorkflow, 'name', 'Workflow'),
  }
  formInputs_TaskOwnerType['associated_owner_type']['options'] = objectToArray(us_taskOwnerOptions)
  formInputs_TaskDispatcherType['associated_dispatcher_type']['options'] = objectToArray(us_taskOwnerOptions)
  formInputs_TaskGroupKey['associated_task_group_key']['options'] = objectToArray(us_taskGroupOptions).sort(dynamicSort('timestamp_created', 'asc'))

  let tableAdditionalData_TaskGroups: TsInterface_TableAdditionalData = {
    workflowKey: pr_workflowKey,
    stagingTaskGroups: us_stagingTaskGroups,
    prodWorkflow: us_prodWorkflow,
  }
  let tableAdditionalData_SowItems: TsInterface_TableAdditionalData = {
    workflowKey: pr_workflowKey,
    prodWorkflow: us_prodWorkflow,
    editable: true,
    projectTasks: us_stagingWorkflowTasks,
  }

  const newTaskGroupPromptObject: TsInterface_PromptDialogObject = {
    color: 'info',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create task group'),
    icon: (
      <Icon
        icon="sitemap"
        type="solid"
      />
    ),
    input_label: rLIB('Task Group Name') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for you new task group'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let updateObject = {
          name: promptValue,
          timestamp_created: new Date(),
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseAddDocument(DatabaseRef_TaskWorkflowTaskGroups_Collection(res_GCK.clientKey, pr_workflowKey), updateObject, true)
              .then((res_DAD) => {
                resolve({ success: true })
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      })
    },
  }

  const newSowItemPropmt: TsInterface_PromptDialogObject = {
    color: 'info',
    confirm_text: rLIB('Create') as JSX.Element,
    default_value: null,
    header: rLIB('Create additional work item'),
    icon: (
      <Icon
        icon="sitemap"
        type="solid"
      />
    ),
    input_label: rLIB('Additional Work Item Name') as JSX.Element,
    input_type: 'text',
    text: rLIB('Enter a name for you new additional work item'),
    submit_callback: (promptValue: string) => {
      return new Promise((resolve, reject) => {
        let updateObject = {
          name: promptValue,
          timestamp_created: new Date(),
        }
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
          .then((res_GCK) => {
            DatabaseAddDocument(DatabaseRef_TaskWorkflowSowItems_Collection(res_GCK.clientKey, pr_workflowKey), updateObject, true)
              .then((res_DAD) => {
                resolve({ success: true })
              })
              .catch((rej_DAD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
              })
          })
          .catch((rej_GCK) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          })
      })
    },
  }

  const manageCellEditTaskName: TsInterface_TableManageAction = {
    icon: <Icon icon="pen-to-square" />,
    label: <>{rLIB('Edit task name')}</>,
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      if (tableAdditionalData.editable === true) {
        openTaskPropertyEditForm(rowData, formInputs_TaskName)
      }
    },
  }
  const manageCellHighlightTaskRow: TsInterface_TableManageAction = {
    icon: <Icon icon="highlighter-line" />,
    label: <>{rLIB('Highlight Task Row')}</>,
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      us_setLastEditedTaskKey(rowData.key as string)
    },
  }
  const manageCellDeleteTask: TsInterface_TableManageAction = {
    icon: <Icon icon="trash" />,
    label: <>{rLIB('Delete')}</>,
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      if (tableAdditionalData.editable === true) {
        deleteTask(rowData)
      }
    },
  }

  const tableColumns_TaskTableV1: TsInterface_TableColumns = {
    manage: TableCellManage({
      edit_task_name: manageCellEditTaskName,
      highlight: manageCellHighlightTaskRow,
      delete: manageCellDeleteTask,
    }),
    // key: TableCellBasic( "key", "key", "key"),
    // Milestone Star
    TEMP_id_number_sort: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return <>{rLIB('Order')}</>
        },
        header_sort_by: 'TEMP_id_number_sort',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rowData['TEMP_id_number_sort']}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    // name1: {
    // header: {
    // 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return rLIB("Task Name") },
    // 	header_sort_by: "name"
    // },
    // cell: {
    // 	cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
    // 		let cellJSX = <Json data={ rowData } />
    // 		return cellJSX
    // 	},
    // 	cell_css: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData ) => {
    // 		let cellCSS = ""
    // 		if( tableAdditionalData.lastEditedTaskKey === rowData.key ){ cellCSS="workflow_table_highlight" }
    // 		return cellCSS
    // 	}
    // },
    // },
    name: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Name')
        },
        header_sort_by: 'name',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = (
            <Box>
              <Box>{rowData['name']}</Box>
            </Box>
          )
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    filter_milestone: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Milestone')
        },
        header_sort_by: 'filter_milestone',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_FilterMilestone(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    abbreviation: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Abbreviation') as JSX.Element
        },
        header_sort_by: 'abbreviation',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_AbbreviationChip(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    associated_task_group_key: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Group')
        },
        header_sort_by: 'associated_task_group_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_TaskGroupChip(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    prerequisite_tasks: {
      cell: {
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          const getPrerequisiteTaskJSX = (prerequisiteTaskKey: string): JSX.Element => {
            let taskJSX = <></>
            if (
              prerequisiteTaskKey != null &&
              tableAdditionalData != null &&
              tableAdditionalData['projectTasks'] != null &&
              tableAdditionalData['projectTasks'][prerequisiteTaskKey] != null
            ) {
              let prerequisiteTask = tableAdditionalData['projectTasks'][prerequisiteTaskKey]
              // let fullTaskTableData = arrayToObject( tableAdditionalData.tableData, "key" )
              // let idNumberJSX = <></>
              // if(
              // 	tableAdditionalData != null &&
              // 	tableAdditionalData.tableData != null &&
              // 	tableAdditionalData.tableData[ prerequisiteTaskKey ] != null &&
              // 	tableAdditionalData.tableData[ prerequisiteTaskKey ]["id_number"] != null
              // ){
              // 	idNumberJSX = <>{ tableAdditionalData.tableData[ prerequisiteTaskKey ]["id_number"] }) </>
              // }
              taskJSX = (
                <Box className="tw-mb-1">
                  <Box className="tw-inline-block tw-mr-1">
                    <Icon
                      size="xl"
                      className="tw-mr-1"
                      icon="square-check"
                    />
                  </Box>
                  <Box className="tw-inline-block">
                    {prerequisiteTask.name}
                    <Tooltip title={rLIB('Delete Task Prerequisite')}>
                      <Typography
                        variant="body1"
                        className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
                        sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                        onClick={() => {
                          if (tableAdditionalData.editable === true) {
                            deleteTaskPrerequisite(rowData, prerequisiteTaskKey)
                          }
                        }}
                      >
                        <>
                          <Icon
                            icon="trash"
                            type="solid"
                          ></Icon>
                        </>
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              )
            }
            return taskJSX
          }
          if (
            rowData != null &&
            rowData.prerequisite_tasks != null &&
            objectToArray(rowData.prerequisite_tasks as unknown as TsInterface_UnspecifiedObject).length > 0
          ) {
            // TODO - get to sort rowData.prerequisite_tasks alphabetically - right now it's just keys so may need a helper function that returns sortable data before looping
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addTaskPrerequisite(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Task Prerequisite')}</Box>
                </Box>
                {objectToArray(rowData.prerequisite_tasks as unknown as TsInterface_UnspecifiedObject).map((prerequisiteTaskKey, taskIndex) => (
                  <Box key={taskIndex}>{getPrerequisiteTaskJSX(prerequisiteTaskKey)}</Box>
                ))}
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addTaskPrerequisite(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Task Prerequisite')}</Box>
                </Box>
              </Box>
            )
          }
          return cellJSX
        },
      },
      header: {
        header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return ''
        },
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Prerequisite Tasks')
        },
        header_sort_by: null,
      },
    },
    delay_reasons: {
      cell: {
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          const getDelayReasonsJSX = (delayReasonKey: string): JSX.Element => {
            let taskJSX = (
              <Box className="tw-mb-1">
                <Box className="tw-inline-block tw-mr-1">
                  <Icon
                    size="xl"
                    className="tw-mr-1"
                    icon="square-check"
                  />
                </Box>
                <Box className="tw-inline-block">
                  {delayReasonKey}
                  <Tooltip title={rLIB('Delete Delay Reason')}>
                    <Typography
                      variant="body1"
                      className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
                      sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                      onClick={() => {
                        if (tableAdditionalData.editable === true) {
                          deleteDelayReason(rowData, delayReasonKey)
                        }
                      }}
                    >
                      <>
                        <Icon
                          icon="trash"
                          type="solid"
                        ></Icon>
                      </>
                    </Typography>
                  </Tooltip>
                  {rJSX_DelayReasonStarIcon(rowData, delayReasonKey, true)}
                </Box>
              </Box>
            )
            return taskJSX
          }
          if (rowData != null && rowData.delay_reasons != null && objectToArray(rowData.delay_reasons as unknown as TsInterface_UnspecifiedObject).length > 0) {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addDelayReason(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Delay Reason')}</Box>
                </Box>
                {objectToArray(rowData.delay_reasons as unknown as TsInterface_UnspecifiedObject)
                  .sort()
                  .map((delayReasonKey, taskIndex) => (
                    <Box key={taskIndex}>{getDelayReasonsJSX(delayReasonKey)}</Box>
                  ))}
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addDelayReason(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Delay Reason')}</Box>
                </Box>
              </Box>
            )
          }
          return cellJSX
        },
      },
      header: {
        header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return ''
        },
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Delay Reasons')
        },
        header_sort_by: null,
      },
    },
    task_completion_type: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Type')
        },
        header_sort_by: 'task_completion_type',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_CompletionTypeChip(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    task_completion_time: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Completion Time')
        },
        header_sort_by: 'task_completion_time',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_CompletionTime(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    associated_dispatched_task_key: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task to Dispatch')
        },
        header_sort_by: 'associated_dispatched_task_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_DispatchedTaskChip(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    associated_task_form_key: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Form')
        },
        header_sort_by: 'associated_task_form_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_FormTypeChip(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    associated_owner_type: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Owner User Type')
        },
        header_sort_by: 'associated_owner_type',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_OwnerTypeChip(rowData, tableAdditionalData.editable, true)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    status_green_days_cutoff: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Status cutoff (days)')
        },
        header_sort_by: 'status_green_days_cutoff',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_EstimatedDaysChips(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    TEMP_outcomes: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Outcomes')
        },
        header_sort_by: null,
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = (
            <>
              <Typography className="tw-opacity-30 tw-italic">{rLIB('handle in flowchart')}</Typography>
            </>
          )
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    name_repeat: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Name')
        },
        header_sort_by: 'name',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = (
            <Box>
              <Box>{rowData['name']}</Box>
              {/* <Box className="tw-opacity-10">{ rowData["key"] }</Box> */}
            </Box>
          )
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    manage2: TableCellManage({
      edit_task_name: manageCellEditTaskName,
      highlight: manageCellHighlightTaskRow,
      delete: manageCellDeleteTask,
    }),
  }

  const tableColumns_TaskTableV2: TsInterface_TableColumns = {
    manage: TableCellManage({
      highlight: manageCellHighlightTaskRow,
      delete: manageCellDeleteTask,
    }),
    // key: TableCellBasic( "key", "key", "key"),
    // Milestone Star
    TEMP_id_number_sort: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return <>{rLIB('Order')}</>
        },
        header_sort_by: 'TEMP_id_number_sort',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rowData['TEMP_id_number_sort']}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    name: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Name')
        },
        header_sort_by: 'name',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = (
            <Box>
              <Box>{rowData['name']}</Box>
            </Box>
          )
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    filter_milestone: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Milestone')
        },
        header_sort_by: 'filter_milestone',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_FilterMilestone(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    abbreviation: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Abbreviation')
        },
        header_sort_by: 'abbreviation',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_BlueprintAbbreviationChip(rowData)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    associated_task_group_key: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Group')
        },
        header_sort_by: 'associated_task_group_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <Box>{rJSX_BlueprintTaskGroupChip(rowData)}</Box>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    prerequisite_tasks: {
      cell: {
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          const getPrerequisiteTaskJSX = (prerequisiteTaskKey: string): JSX.Element => {
            let taskJSX = <></>
            if (
              prerequisiteTaskKey != null &&
              tableAdditionalData != null &&
              tableAdditionalData['projectTasks'] != null &&
              tableAdditionalData['projectTasks'][prerequisiteTaskKey] != null
            ) {
              let prerequisiteTask = tableAdditionalData['projectTasks'][prerequisiteTaskKey]
              taskJSX = (
                <Box className="tw-mb-1">
                  <Box className="tw-inline-block tw-mr-1">
                    <Icon
                      size="xl"
                      className="tw-mr-1"
                      icon="square-check"
                    />
                  </Box>
                  <Box className="tw-inline-block">
                    {prerequisiteTask.name}
                    <Tooltip title={rLIB('Delete Task Prerequisite')}>
                      <Typography
                        variant="body1"
                        className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
                        sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                        onClick={() => {
                          if (tableAdditionalData.editable === true) {
                            deleteTaskPrerequisite(rowData, prerequisiteTaskKey)
                          }
                        }}
                      >
                        <>
                          <Icon
                            icon="trash"
                            type="solid"
                          ></Icon>
                        </>
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              )
            }
            return taskJSX
          }
          if (
            rowData != null &&
            rowData.prerequisite_tasks != null &&
            objectToArray(rowData.prerequisite_tasks as unknown as TsInterface_UnspecifiedObject).length > 0
          ) {
            // TODO - get to sort rowData.prerequisite_tasks alphabetically - right now it's just keys so may need a helper function that returns sortable data before looping
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addTaskPrerequisite(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Task Prerequisite')}</Box>
                </Box>
                {objectToArray(rowData.prerequisite_tasks as unknown as TsInterface_UnspecifiedObject).map((prerequisiteTaskKey, taskIndex) => (
                  <Box key={taskIndex}>{getPrerequisiteTaskJSX(prerequisiteTaskKey)}</Box>
                ))}
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addTaskPrerequisite(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Task Prerequisite')}</Box>
                </Box>
              </Box>
            )
          }
          return cellJSX
        },
      },
      header: {
        header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return ''
        },
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Prerequisite Tasks')
        },
        header_sort_by: null,
      },
    },
    // delay_reasons: {
    // cell: {
    // 	cell_css: (
    // 		rowData: TsInterface_TableDataRow,
    // 		tableAdditionalData: TsInterface_TableAdditionalData
    // 	) => {
    // 		let cellCSS = ""
    // 		if( tableAdditionalData.lastEditedTaskKey === rowData.key ){ cellCSS="workflow_table_highlight" }
    // 		return cellCSS
    // 	},
    // 	cell_jsx: (
    // 		rowData: TsInterface_TableDataRow,
    // 		tableAdditionalData: TsInterface_TableAdditionalData,
    // 		tableHooks: TsInterface_TableHooks
    // 	) => {
    // 		let cellJSX = <></>
    // 		const getDelayReasonsJSX = ( delayReasonKey: string ): JSX.Element => {
    // 			let taskJSX  =
    // 			<Box className="tw-mb-1">
    // 				<Box className="tw-inline-block tw-mr-1">
    // 					<Icon size="xl" className="tw-mr-1" icon="square-check" />
    // 				</Box>
    // 				<Box className="tw-inline-block">
    // 					{ delayReasonKey }
    // 					<Tooltip title={ rLIB("Delete Delay Reason") }>
    // 						<Typography variant="body1" className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top" sx={{ opacity: 0.2, "&:hover": { opacity: 1 } }} onClick={ () => {
    // 							if( tableAdditionalData.editable === true ){
    // 								deleteDelayReason( rowData, delayReasonKey )
    // 							}
    // 						}}>
    // 							<>
    // 								<Icon icon="trash" type="solid"></Icon>
    // 							</>
    // 						</Typography>
    // 					</Tooltip>
    // 					{ rJSX_DelayReasonStarIcon( rowData, delayReasonKey, true ) }
    // 				</Box>
    // 			</Box>
    // 			return taskJSX
    // 		}
    // 		if( rowData != null && rowData.delay_reasons != null && objectToArray( rowData.delay_reasons as unknown as TsInterface_UnspecifiedObject ).length > 0 ){
    // 			cellJSX =
    // 			<Box>
    // 				<Box className="tw-mb-1">
    // 					<Box className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block" sx={{ opacity: 0.2, "&:hover": { opacity: 1 } }} onClick={ () => {
    // 						if( tableAdditionalData.editable === true ){
    // 							addDelayReason( rowData )
    // 						}
    // 					}}>
    // 						<>
    // 							<Icon size="xl" className="tw-mr-1" icon="circle-plus" type="solid"></Icon>
    // 						</>
    // 					</Box>
    // 					<Box className="tw-opacity-30 tw-inline-block">{ rLIB("Add Delay Reason") }</Box>
    // 				</Box>
    // 				{ objectToArray( rowData.delay_reasons as unknown as TsInterface_UnspecifiedObject ).sort().map(( delayReasonKey, taskIndex ) => (
    // 					<Box key={taskIndex}>
    // 						{ getDelayReasonsJSX( delayReasonKey ) }
    // 					</Box>
    // 				))}
    // 			</Box>
    // 		} else {
    // 			cellJSX =
    // 			<Box>
    // 				<Box className="tw-mb-1">
    // 					<Box className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block" sx={{ opacity: 0.2, "&:hover": { opacity: 1 } }} onClick={ () => {
    // 						if( tableAdditionalData.editable === true ){
    // 							addDelayReason( rowData )
    // 						}
    // 					}}>
    // 						<>
    // 							<Icon size="xl" className="tw-mr-1" icon="circle-plus" type="solid"></Icon>
    // 						</>
    // 					</Box>
    // 					<Box className="tw-opacity-30 tw-inline-block">{ rLIB("Add Delay Reason") }</Box>
    // 				</Box>
    // 			</Box>
    // 		}
    // 		return cellJSX
    // 	},
    // },
    // header: {
    // 	header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
    // 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return rLIB("Delay Reasons") },
    // 	header_sort_by: null
    // }
    // },
    task_completion_type: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Type')
        },
        header_sort_by: 'task_completion_type',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_BlueprintCompletionTypeChip(rowData)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    task_completion_time: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Completion Time')
        },
        header_sort_by: 'task_completion_time',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_CompletionTime(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    // associated_dispatched_task_key: {
    // header: {
    // 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return rLIB("Task to Dispatch") },
    // 	header_sort_by: "associated_dispatched_task_key"
    // },
    // cell: {
    // 	cell_jsx: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks ) => {
    // 		let cellJSX = <>{ rJSX_DispatchedTaskChip( rowData, tableAdditionalData.editable ) }</>
    // 		return cellJSX
    // 	},
    // 	cell_css: ( rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData ) => {
    // 		let cellCSS = ""
    // 		if( tableAdditionalData.lastEditedTaskKey === rowData.key ){ cellCSS="workflow_table_highlight" }
    // 		return cellCSS
    // 	}
    // },
    // },
    associated_task_form_key: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Form')
        },
        header_sort_by: 'associated_task_form_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_BlueprintFormTypeChip(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          // if( rowData.associated_task_form_key != null ){
          // 	cellCSS = "tw-bg-warning_dark"
          // }
          return cellCSS
        },
      },
    },
    associated_owner_type: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Owner User Type')
        },
        header_sort_by: 'associated_owner_type',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_BlueprintOwnerTypeChip(rowData, tableAdditionalData.editable, true)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    status_green_days_cutoff: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Status cutoff (days)')
        },
        header_sort_by: 'status_green_days_cutoff',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_BlueprintEstimatedDaysChips(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    manage2: TableCellManage({
      highlight: manageCellHighlightTaskRow,
      delete: manageCellDeleteTask,
    }),
  }

  const manageCellRenameSowItem: TsInterface_TableManageAction = {
    icon: <Icon icon="pen-to-square" />,
    label: <>{rLIB('Edit')}</>,
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      if (tableAdditionalData.workflowKey != null && rowData.key != null) {
        const editSowItemPropmt: TsInterface_PromptDialogObject = {
          color: 'info',
          confirm_text: rLIB('Rename') as JSX.Element,
          default_value: getProp(rowData, 'name', null),
          header: rLIB('Rename Additional Work Item'),
          icon: (
            <Icon
              icon="sitemap"
              type="solid"
            />
          ),
          input_label: rLIB('Additional Work Item Name') as JSX.Element,
          input_type: 'text',
          text: rLIB('Enter a name for your Additional Work Item'),
          submit_callback: (promptValue: string) => {
            return new Promise((resolve, reject) => {
              let updateObject = {
                name: promptValue,
              }
              getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(
                    DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, tableAdditionalData.workflowKey, rowData.key as string),
                    updateObject,
                  )
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        }
        tableHooks.uc_setUserInterface_PromptDialogDisplay({
          display: true,
          prompt: editSowItemPropmt,
        })
      }
    },
  }

  const manageCellDeleteSowItem: TsInterface_TableManageAction = {
    icon: <Icon icon="trash" />,
    label: <>{rLIB('Delete')}</>,
    onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
      if (tableAdditionalData.workflowKey != null && rowData.key != null) {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: rLIB('Delete Additional Work Task'),
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: rLIB('Delete'),
            text: rLIB('Are you sure that you want to delete this additional work Task?'),
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseDeleteDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, tableAdditionalData.workflowKey, rowData.key as string))
                      .then((res_DDD) => {
                        resolve(res_DDD)
                      })
                      .catch((rej_DDD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              })
            },
          },
        })
      }
    },
  }

  const tableColumns_SowItems: TsInterface_TableColumns = {
    manage: TableCellManage({
      rename: manageCellRenameSowItem,
      delete: manageCellDeleteSowItem,
    }),
    TEMP_publishedIcon: {
      cell: {
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          return ''
        },
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          let prodSowItems: TsInterface_UnspecifiedObject = {}
          if (tableAdditionalData != null && tableAdditionalData['prodWorkflow'] != null && tableAdditionalData['prodWorkflow']['sow_items'] != null) {
            prodSowItems = arrayToObject(tableAdditionalData['prodWorkflow']['sow_items'], 'key')
          }
          if (
            rowData != null &&
            rowData.key != null &&
            prodSowItems != null &&
            prodSowItems[rowData.key as string] != null &&
            prodSowItems[rowData.key as string]['name'] === rowData.name
          ) {
            cellJSX = (
              <Tooltip title={rLIB('Published to Prod')}>
                <Box sx={{ color: themeVariables.success_main }}>
                  <Icon icon="circle-check" />
                </Box>
              </Tooltip>
            )
          } else {
            cellJSX = (
              <Tooltip title={rLIB('Not Published to Prod')}>
                <Box sx={{ color: themeVariables.gray_600 }}>
                  <Icon icon="ghost" />
                </Box>
              </Tooltip>
            )
          }
          return cellJSX
        },
      },
      header: {
        header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return 'tw-w-4'
        },
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return <></>
        },
        header_sort_by: null,
      },
    },
    name: TableCellBasic('name', rLIB('Additional Work Item Name'), null),
    associated_task_group_key: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Group')
        },
        header_sort_by: 'associated_task_group_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_SowTaskGroupChip(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    upstream_tasks: {
      cell: {
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          const getUpstreamTaskJSX = (upstreamTaskKey: string): JSX.Element => {
            let taskJSX = <></>
            if (
              upstreamTaskKey != null &&
              tableAdditionalData != null &&
              tableAdditionalData['projectTasks'] != null &&
              tableAdditionalData['projectTasks'][upstreamTaskKey] != null
            ) {
              let upstreamTask = tableAdditionalData['projectTasks'][upstreamTaskKey]
              taskJSX = (
                <Box className="tw-mb-1">
                  <Box className="tw-inline-block tw-mr-1">
                    <Icon
                      size="xl"
                      className="tw-mr-1"
                      icon="square-check"
                    />
                  </Box>
                  <Box className="tw-inline-block">
                    {upstreamTask.name}
                    <Tooltip title={rLIB('Delete Upstream Task')}>
                      <Typography
                        variant="body1"
                        className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
                        sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                        onClick={() => {
                          if (tableAdditionalData.editable === true) {
                            deleteSowUpstreamTask(rowData, upstreamTaskKey)
                          }
                        }}
                      >
                        <>
                          <Icon
                            icon="trash"
                            type="solid"
                          ></Icon>
                        </>
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              )
            }
            return taskJSX
          }
          if (
            rowData != null &&
            rowData.upstream_tasks != null &&
            objectToArray(rowData.upstream_tasks as unknown as TsInterface_UnspecifiedObject).length > 0
          ) {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addSowUpstreamTask(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Upstream Task')}</Box>
                </Box>
                {objectToArray(rowData.upstream_tasks as unknown as TsInterface_UnspecifiedObject)
                  .sort()
                  .map((upstreamTaskKey, taskIndex) => (
                    <Box key={taskIndex}>{getUpstreamTaskJSX(upstreamTaskKey)}</Box>
                  ))}
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addSowUpstreamTask(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Upstream Task')}</Box>
                </Box>
              </Box>
            )
          }
          return cellJSX
        },
      },
      header: {
        header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return ''
        },
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Upstream Tasks')
        },
        header_sort_by: null,
      },
    },
    downstream_tasks: {
      cell: {
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          const getDownstreamTaskJSX = (downstreamTaskKey: string): JSX.Element => {
            let taskJSX = <></>
            if (
              downstreamTaskKey != null &&
              tableAdditionalData != null &&
              tableAdditionalData['projectTasks'] != null &&
              tableAdditionalData['projectTasks'][downstreamTaskKey] != null
            ) {
              let downstreamTask = tableAdditionalData['projectTasks'][downstreamTaskKey]
              taskJSX = (
                <Box className="tw-mb-1">
                  <Box className="tw-inline-block tw-mr-1">
                    <Icon
                      size="xl"
                      className="tw-mr-1"
                      icon="square-check"
                    />
                  </Box>
                  <Box className="tw-inline-block">
                    {downstreamTask.name}
                    <Tooltip title={rLIB('Delete Downstream Task')}>
                      <Typography
                        variant="body1"
                        className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
                        sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                        onClick={() => {
                          if (tableAdditionalData.editable === true) {
                            deleteSowDownstreamTask(rowData, downstreamTaskKey)
                          }
                        }}
                      >
                        <>
                          <Icon
                            icon="trash"
                            type="solid"
                          ></Icon>
                        </>
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              )
            }
            return taskJSX
          }
          if (
            rowData != null &&
            rowData.downstream_tasks != null &&
            objectToArray(rowData.downstream_tasks as unknown as TsInterface_UnspecifiedObject).length > 0
          ) {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addSowDownstreamTask(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Downstream Task')}</Box>
                </Box>
                {objectToArray(rowData.downstream_tasks as unknown as TsInterface_UnspecifiedObject)
                  .sort()
                  .map((downstreamTaskKey, taskIndex) => (
                    <Box key={taskIndex}>{getDownstreamTaskJSX(downstreamTaskKey)}</Box>
                  ))}
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addSowDownstreamTask(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Downstream Task')}</Box>
                </Box>
              </Box>
            )
          }
          return cellJSX
        },
      },
      header: {
        header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return ''
        },
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Downstream Tasks')
        },
        header_sort_by: null,
      },
    },
    delay_reasons: {
      cell: {
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          const getDelayReasonsJSX = (delayReasonKey: string): JSX.Element => {
            let taskJSX = (
              <Box className="tw-mb-1">
                <Box className="tw-inline-block tw-mr-1">
                  <Icon
                    size="xl"
                    className="tw-mr-1"
                    icon="square-check"
                  />
                </Box>
                <Box className="tw-inline-block">
                  {delayReasonKey}
                  <Tooltip title={rLIB('Delete Delay Reason')}>
                    <Typography
                      variant="body1"
                      className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
                      sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                      onClick={() => {
                        if (tableAdditionalData.editable === true) {
                          deleteSowDelayReason(rowData, delayReasonKey)
                        }
                      }}
                    >
                      <>
                        <Icon
                          icon="trash"
                          type="solid"
                        ></Icon>
                      </>
                    </Typography>
                  </Tooltip>
                  {rJSX_SowDelayReasonStarIcon(rowData, delayReasonKey, true)}
                </Box>
              </Box>
            )
            return taskJSX
          }
          if (rowData != null && rowData.delay_reasons != null && objectToArray(rowData.delay_reasons as unknown as TsInterface_UnspecifiedObject).length > 0) {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addSowDelayReason(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Delay Reason')}</Box>
                </Box>
                {objectToArray(rowData.delay_reasons as unknown as TsInterface_UnspecifiedObject)
                  .sort()
                  .map((delayReasonKey, taskIndex) => (
                    <Box key={taskIndex}>{getDelayReasonsJSX(delayReasonKey)}</Box>
                  ))}
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addSowDelayReason(rowData)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Delay Reason')}</Box>
                </Box>
              </Box>
            )
          }
          return cellJSX
        },
      },
      header: {
        header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return ''
        },
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Delay Reasons')
        },
        header_sort_by: null,
      },
    },
    task_completion_type: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Type')
        },
        header_sort_by: 'task_completion_type',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_SowCompletionTypeChip(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    associated_dispatcher_type: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Dispatcher Type')
        },
        header_sort_by: 'associated_task_group_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          if (rowData.task_completion_type === 'scheduled') {
            cellJSX = <>{rJSX_SowDispatcherChip(rowData, tableAdditionalData.editable)}</>
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Not Applicable')}</Box>
          }
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    associated_team_type: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Scheduled Team Type')
        },
        header_sort_by: 'associated_task_group_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          if (rowData.task_completion_type === 'scheduled') {
            cellJSX = <>{rJSX_SowTeamTypeGroupChip(rowData, tableAdditionalData.editable)}</>
          } else {
            cellJSX = <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Not Applicable')}</Box>
          }
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    associated_task_form_key: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Form')
        },
        header_sort_by: 'associated_task_form_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_SowFormTypeChip(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    associated_owner_type: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Owner User Type')
        },
        header_sort_by: 'associated_owner_type',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_SowOwnerTypeChip(rowData, tableAdditionalData.editable, true)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    status_green_days_cutoff: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Status cutoff (days)')
        },
        header_sort_by: 'status_green_days_cutoff',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_SowEstimatedDaysChips(rowData, tableAdditionalData.editable)}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    manage2: TableCellManage({
      rename: manageCellRenameSowItem,
      delete: manageCellDeleteSowItem,
    }),
  }

  const tableColumns_AdditionalWorkTable: TsInterface_TableColumns = {
    name: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Additional Work Item Name')
        },
        header_sort_by: 'name',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = (
            <Box>
              <Box>{rowData['name']}</Box>
            </Box>
          )
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    upstream_tasks: {
      header: {
        header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return ''
        },
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Upstream Tasks')
        },
        header_sort_by: null,
      },
      cell: {
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          const getUpstreamTaskJSX = (upstreamTaskKey: string): JSX.Element => {
            let taskJSX = <></>

            if (
              upstreamTaskKey != null &&
              tableAdditionalData != null &&
              tableAdditionalData['projectTasks'] != null &&
              tableAdditionalData['projectTasks'][upstreamTaskKey] != null
            ) {
              let upstreamTask = tableAdditionalData['projectTasks'][upstreamTaskKey]
              taskJSX = (
                <Box className="tw-mb-1">
                  <Box className="tw-inline-block tw-mr-1">
                    <Icon
                      size="xl"
                      className="tw-mr-1"
                      icon="square-check"
                    />
                  </Box>
                  <Box className="tw-inline-block">
                    {upstreamTask.name}
                    <Tooltip title={rLIB('Delete Upstream Task')}>
                      <Typography
                        variant="body1"
                        className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
                        sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                        onClick={() => {
                          if (
                            tableAdditionalData.editable === true &&
                            tableAdditionalData['additionalWorkTasks'] != null &&
                            tableAdditionalData['additionalWorkTasks'][rowData.key as string] != null
                          ) {
                            let taskToEdit = { ...tableAdditionalData['additionalWorkTasks'][rowData.key as string] }
                            taskToEdit.key = rowData.key
                            deleteSowUpstreamTask(taskToEdit, upstreamTaskKey)
                          }
                        }}
                      >
                        <>
                          <Icon
                            icon="trash"
                            type="solid"
                          ></Icon>
                        </>
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              )
            }
            return taskJSX
          }
          let blueprintTask: TsInterface_UnspecifiedObject = {}
          if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData['us_blueprintTasks'] != null &&
            tableAdditionalData['us_blueprintTasks'][rowData.key as string] != null
          ) {
            blueprintTask = tableAdditionalData['us_blueprintTasks'][rowData.key as string]
          }
          let taskCopy: TsInterface_UnspecifiedObject = { ...blueprintTask }
          if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData['additionalWorkTasks'] != null &&
            tableAdditionalData['additionalWorkTasks'][rowData.key as string] != null &&
            tableAdditionalData['additionalWorkTasks'][rowData.key as string]['upstream_tasks'] != null &&
            objectToArray(tableAdditionalData['additionalWorkTasks'][rowData.key as string]['upstream_tasks'] as unknown as TsInterface_UnspecifiedObject)
              .length > 0
            // rowData.upstream_tasks != null &&
            // objectToArray( rowData.upstream_tasks as unknown as TsInterface_UnspecifiedObject ).length > 0
          ) {
            taskCopy['upstream_tasks'] = tableAdditionalData['additionalWorkTasks'][rowData.key as string]['upstream_tasks']
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addSowUpstreamTask(taskCopy)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Upstream Task')}</Box>
                </Box>
                {objectToArray(tableAdditionalData['additionalWorkTasks'][rowData.key as string]['upstream_tasks'] as unknown as TsInterface_UnspecifiedObject)
                  .sort()
                  .map((upstreamTaskKey, taskIndex) => (
                    <Box key={taskIndex}>{getUpstreamTaskJSX(upstreamTaskKey)}</Box>
                  ))}
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addSowUpstreamTask(taskCopy)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Upstream Task')}</Box>
                </Box>
              </Box>
            )
          }
          return cellJSX
        },
      },
    },
    downstream_tasks: {
      header: {
        header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return ''
        },
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Downstream Tasks')
        },
        header_sort_by: null,
      },
      cell: {
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <></>
          const getDownstreamTaskJSX = (downstreamTaskKey: string): JSX.Element => {
            let taskJSX = <></>
            if (
              downstreamTaskKey != null &&
              tableAdditionalData != null &&
              tableAdditionalData['projectTasks'] != null &&
              tableAdditionalData['projectTasks'][downstreamTaskKey] != null
            ) {
              let downstreamTask = tableAdditionalData['projectTasks'][downstreamTaskKey]
              taskJSX = (
                <Box className="tw-mb-1">
                  <Box className="tw-inline-block tw-mr-1">
                    <Icon
                      size="xl"
                      className="tw-mr-1"
                      icon="square-check"
                    />
                  </Box>
                  <Box className="tw-inline-block">
                    {downstreamTask.name}
                    <Tooltip title={rLIB('Delete Downstream Task')}>
                      <Typography
                        variant="body1"
                        className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
                        sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                        onClick={() => {
                          if (
                            tableAdditionalData.editable === true &&
                            tableAdditionalData['additionalWorkTasks'] != null &&
                            tableAdditionalData['additionalWorkTasks'][rowData.key as string] != null
                          ) {
                            let taskToEdit = { ...tableAdditionalData['additionalWorkTasks'][rowData.key as string] }
                            taskToEdit.key = rowData.key
                            deleteSowDownstreamTask(taskToEdit, downstreamTaskKey)
                          }
                        }}
                      >
                        <>
                          <Icon
                            icon="trash"
                            type="solid"
                          ></Icon>
                        </>
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              )
            }
            return taskJSX
          }
          let blueprintTask: TsInterface_UnspecifiedObject = {}
          if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData['us_blueprintTasks'] != null &&
            tableAdditionalData['us_blueprintTasks'][rowData.key as string] != null
          ) {
            blueprintTask = tableAdditionalData['us_blueprintTasks'][rowData.key as string]
          }
          let taskCopy: TsInterface_UnspecifiedObject = { ...blueprintTask }
          if (
            rowData != null &&
            rowData.key != null &&
            tableAdditionalData != null &&
            tableAdditionalData['additionalWorkTasks'] != null &&
            tableAdditionalData['additionalWorkTasks'][rowData.key as string] != null &&
            tableAdditionalData['additionalWorkTasks'][rowData.key as string]['downstream_tasks'] != null &&
            objectToArray(tableAdditionalData['additionalWorkTasks'][rowData.key as string]['downstream_tasks'] as unknown as TsInterface_UnspecifiedObject)
              .length > 0
            // rowData.downstream_tasks != null &&
            // objectToArray( rowData.downstream_tasks as unknown as TsInterface_UnspecifiedObject ).length > 0
          ) {
            taskCopy['downstream_tasks'] = tableAdditionalData['additionalWorkTasks'][rowData.key as string]['downstream_tasks']
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addSowDownstreamTask(taskCopy)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Downstream Task')}</Box>
                </Box>
                {objectToArray(
                  tableAdditionalData['additionalWorkTasks'][rowData.key as string]['downstream_tasks'] as unknown as TsInterface_UnspecifiedObject,
                )
                  .sort()
                  .map((downstreamTaskKey, taskIndex) => (
                    <Box key={taskIndex}>{getDownstreamTaskJSX(downstreamTaskKey)}</Box>
                  ))}
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box className="tw-mb-1">
                  <Box
                    className="tw-mt-0 tw-mr-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (tableAdditionalData.editable === true) {
                        addSowDownstreamTask(taskCopy)
                      }
                    }}
                  >
                    <>
                      <Icon
                        size="xl"
                        className="tw-mr-1"
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                  <Box className="tw-opacity-30 tw-inline-block">{rLIB('Add Downstream Task')}</Box>
                </Box>
              </Box>
            )
          }
          return cellJSX
        },
      },
    },
    associated_task_form_key: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Task Form')
        },
        header_sort_by: 'associated_task_form_key',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = <>{rJSX_AdditionalWorkFormTypeChip(rowData, tableAdditionalData.editable, getProp(tableAdditionalData, 'additionalWorkTasks', {}))}</>
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    associated_owner_type: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Owner User Type')
        },
        header_sort_by: 'associated_owner_type',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = (
            <>{rJSX_AdditionalWorkOwnerTypeChip(rowData, tableAdditionalData.editable, true, getProp(tableAdditionalData, 'additionalWorkTasks', {}))}</>
          )
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
    status_green_days_cutoff: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Status cutoff (days)')
        },
        header_sort_by: 'status_green_days_cutoff',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
          let cellJSX = (
            <>{rJSX_AdditionalWorkEstimatedDaysChips(rowData, tableAdditionalData.editable, getProp(tableAdditionalData, 'additionalWorkTasks', {}))}</>
          )
          return cellJSX
        },
        cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
          let cellCSS = ''
          if (tableAdditionalData.lastEditedTaskKey === rowData.key) {
            cellCSS = 'workflow_table_highlight'
          }
          return cellCSS
        },
      },
    },
  }

  // Functions
  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <>{rLIB('Task Workflow')}</>
    if (us_taskWorkflow != null && us_taskWorkflow.name != null) {
      pageHeader = (
        <>
          {rLIB('Task Workflow')} - {us_taskWorkflow.name}
        </>
      )
    }
    return pageHeader
  }

  const generateDownstreamDispatchedTasksForForm = (task: TsInterface_UnspecifiedObject): void => {
    let dispatchedTaskOptions: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in us_stagingWorkflowTasks) {
      let loopTask = us_stagingWorkflowTasks[loopTaskKey]
      if (
        task != null &&
        task.key != null &&
        loopTask != null &&
        loopTask.key != null &&
        loopTask['prerequisite_tasks'] != null &&
        loopTask['prerequisite_tasks'][task.key] != null
      ) {
        let prerequisiteTaskKey = loopTask.key
        if (
          prerequisiteTaskKey != null &&
          us_stagingWorkflowTasks != null &&
          us_stagingWorkflowTasks[prerequisiteTaskKey] != null &&
          us_stagingWorkflowTasks[prerequisiteTaskKey]['task_completion_type'] === 'scheduled'
        ) {
          dispatchedTaskOptions[loopTask.key] = {
            key: loopTask.key,
            value: loopTask.name,
          }
        }
      }
    }
    formInputs_AssociatedDispatchedTasks['associated_dispatched_task_key']['options'] = objectToArray(dispatchedTaskOptions)
  }

  // TASKS
  const createStagingTask = (): void => {
    if (us_taskWorkflow.workflow_version_number === 2) {
      uc_setUserInterface_CustomDialogDisplay({
        display: true,
        dialog: {
          dialog_jsx: (
            <AddTaskToV2WorkflowCustomDialog
              us_blueprintTasks={us_blueprintTasks}
              pr_workflowKey={pr_workflowKey}
            />
          ),
          settings: {
            max_width: 'lg',
          },
        },
      })
    } else if (us_taskWorkflow.workflow_version_number === 1) {
      uc_setUserInterface_FormDialogDisplay({
        display: true,
        form: {
          form: {
            formAdditionalData: {},
            formData: {},
            formInputs: formInputs_TaskName,
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {},
            formSettings: {},
            formSubmission: (
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                let taskUpdateObject = {
                  name: formSubmittedData.name,
                  timestamp_created: new Date(),
                }
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseAddDocument(DatabaseRef_TaskWorkflowStagingTasks_Collection(res_GCK.clientKey, pr_workflowKey), taskUpdateObject, true)
                      .then((res_DAD) => {
                        us_setLastEditedTaskKey(res_DAD.key)
                        resolve({ success: true })
                      })
                      .catch((rej_DAD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              })
            },
          },
          dialog: {
            formDialogHeaderColor: 'success',
            formDialogHeaderText: rLIB('Create an initial task'),
            formDialogIcon: (
              <Icon
                type="solid"
                icon="circle-plus"
              />
            ),
          },
        },
      })
    } else {
      uc_setUserInterface_ErrorDialogDisplay({
        display: true,
        error: {
          message: rLIB('Failed to create task'),
          details: rLIB('Invalid workflow version number. Please contact support'),
          code: 'ER-D-TWV-CST-01',
        },
      })
    }
  }

  const updateTaskProperty = (taskKey: string, propKey: string, value: string | number | boolean | null) => {
    return new Promise((resolve, reject) => {
      let updateObject: TsInterface_UnspecifiedObject = {}
      updateObject[propKey] = value
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, taskKey), updateObject)
            .then((res_DSMD) => {
              us_setLastEditedTaskKey(taskKey)
              resolve({ success: true })
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    })
  }

  const openTaskPropertyEditForm = (task: TsInterface_UnspecifiedObject, formInputs: TsInterface_FormInputs): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: task,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                      us_setLastEditedTaskKey(task.key)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit Task')} - {task.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const addTaskPrerequisite = (task: TsInterface_UnspecifiedObject): void => {
    // Generate List of Tasks
    let prerequisiteTaskOptions: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in us_stagingWorkflowTasks) {
      let loopTask = us_stagingWorkflowTasks[loopTaskKey]
      if (loopTask != null && loopTask.name != null && loopTask.key != null) {
        prerequisiteTaskOptions[loopTask.key] = {
          key: loopTask.key,
          value: loopTask.name,
        }
        if (task.key === loopTask.key) {
          prerequisiteTaskOptions[loopTask.key]['disabled'] = true
          prerequisiteTaskOptions[loopTask.key]['value'] += (' ' + rLIB('(this task)', false)) as string
        } else if (checkIfTaskIsUpstream(us_stagingWorkflowTasks, task, loopTask, us_stagingRecursiveTasks, ['addTaskPrerequisite']) === true) {
          prerequisiteTaskOptions[loopTask.key]['disabled'] = true
          prerequisiteTaskOptions[loopTask.key]['value'] += (' ' + rLIB('(downstream from this task)', false)) as string
        } else if (task.prerequisite_tasks != null && task.prerequisite_tasks[loopTask.key] != null) {
          prerequisiteTaskOptions[loopTask.key]['disabled'] = true
          prerequisiteTaskOptions[loopTask.key]['value'] += (' ' + rLIB('(already added)', false)) as string
        }
      }
    }
    let formInputs: TsInterface_FormInputs = {
      TEMP_new_prerequisite_task: {
        key: 'TEMP_new_prerequisite_task',
        label: rLIB('Prerequisite Task'),
        input_type: 'multiple_choice_select',
        required: true,
        data_type: 'string',
        options: objectToArray(prerequisiteTaskOptions).sort(dynamicSort('value', null)),
      },
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: task,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let taskUpdateObject: TsInterface_UnspecifiedObject = {
                prerequisite_tasks: {},
              }
              taskUpdateObject['prerequisite_tasks'][formSubmittedData.TEMP_new_prerequisite_task] = formSubmittedData.TEMP_new_prerequisite_task
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DSMD) => {
                      us_setLastEditedTaskKey(task.key)
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit Task')} - {task.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const deleteTaskPrerequisite = (task: TsInterface_UnspecifiedObject, prerequisiteTaskKey: string): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Task Prerequisite'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this prerequisite?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            if (prerequisiteTaskKey != null && task != null && task.prerequisite_tasks != null && task.prerequisite_tasks[prerequisiteTaskKey] != null) {
              let taskUpdateObject = {
                prerequisite_tasks: task.prerequisite_tasks,
              }
              delete taskUpdateObject['prerequisite_tasks'][prerequisiteTaskKey]
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseUpdateDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DUD) => {
                      us_setLastEditedTaskKey(task.key)
                      resolve({ success: true })
                    })
                    .catch((rej_DUD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            } else {
              resolve({ success: false })
            }
          })
        },
      },
    })
  }

  const addDelayReason = (task: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_DelayReason,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let taskUpdateObject: TsInterface_UnspecifiedObject = {
                delay_reasons: {},
              }
              taskUpdateObject['delay_reasons'][formSubmittedData.delay_reason] = formSubmittedData.delay_reason
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DSMD) => {
                      us_setLastEditedTaskKey(task.key)
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Add Delay Reason'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const deleteDelayReason = (task: TsInterface_UnspecifiedObject, delayReasonKey: string): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Delay Reason'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this delay reason?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            if (delayReasonKey != null && task != null && task.delay_reasons != null && task.delay_reasons[delayReasonKey] != null) {
              let taskUpdateObject = {
                delay_reasons: task.delay_reasons,
              }
              delete taskUpdateObject['delay_reasons'][delayReasonKey]
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseUpdateDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DUD) => {
                      us_setLastEditedTaskKey(task.key)
                      resolve({ success: true })
                    })
                    .catch((rej_DUD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            } else {
              resolve({ success: false })
            }
          })
        },
      },
    })
  }

  const deleteTask = (task: TsInterface_UnspecifiedObject): void => {
    // Check for downstream jobs
    let hasDownstreamTasks = false
    for (let loopTaskKey in us_stagingWorkflowTasks) {
      let loopTask = us_stagingWorkflowTasks[loopTaskKey]
      if (loopTask != null && loopTask['prerequisite_tasks'] && loopTask['prerequisite_tasks'][task.key] != null) {
        hasDownstreamTasks = true
      }
    }
    if (hasDownstreamTasks === true) {
      uc_setUserInterface_AlertDialogDisplay({
        display: true,
        alert: {
          color: 'error',
          header: rLIB('Unable to delete task') as JSX.Element,
          icon: (
            <Icon
              icon="triangle-exclamation"
              type="solid"
            />
          ),
          text: rLIB('This task is used as a prerequisite for other tasks.') as JSX.Element,
        },
      })
    } else {
      uc_setUserInterface_ConfirmDialogDisplay({
        display: true,
        confirm: {
          color: 'error',
          header: rLIB('Delete task'),
          icon: (
            <Icon
              icon="trash"
              type="solid"
            />
          ),
          submit_text: rLIB('Delete'),
          text: rLIB('Are you sure that you want to delete this task?'),
          submit_callback: () => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseDeleteDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key))
                    .then((res_DDD) => {
                      us_setLastEditedTaskKey(null)
                      us_setForceRefreshStagingData(new Date().getTime())
                      resolve({ success: true })
                    })
                    .catch((rej_DDD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
      })
    }
  }

  const updateTaskFormOptionsSortOrder = (): void => {
    let formsThatAreBeingUsed: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in us_stagingWorkflowTasks) {
      let loopTask = us_stagingWorkflowTasks[loopTaskKey]
      if (loopTask != null && loopTask['associated_task_form_key'] != null) {
        formsThatAreBeingUsed[loopTask['associated_task_form_key']] = true
      }
    }
    let sortedTaskFormOptions: TsInterface_UnspecifiedObject[] = []
    for (let loopTaskKey in us_taskFormOptions) {
      let loopTask = us_taskFormOptions[loopTaskKey]
      if (loopTask != null && formsThatAreBeingUsed[loopTaskKey] == null) {
        sortedTaskFormOptions.push(loopTask)
      }
    }
    sortedTaskFormOptions.push({ key: 'TEMP_' + new Date().getTime().toString(), disabled: true, value: '-------- Forms that have been used --------' })
    for (let loopTaskKey in us_taskFormOptions) {
      let loopTask = us_taskFormOptions[loopTaskKey]
      if (loopTask != null && formsThatAreBeingUsed[loopTaskKey] != null) {
        sortedTaskFormOptions.push(loopTask)
      }
    }
    formInputs_AssociatedTaskForm['associated_task_form_key']['options'] = sortedTaskFormOptions
  }

  // TASK OUTCOMES
  const addTaskOutcome = (task: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_TaskOutcome,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let taskUpdateObject: TsInterface_UnspecifiedObject = {
                possible_outcomes: {},
              }
              let timestampKey = new Date().getTime().toString() + '_' + generateRandomString(6, null)
              taskUpdateObject['possible_outcomes'][timestampKey] = {
                name: formSubmittedData.name,
                outcome_condition: {},
                key: timestampKey,
                outcome_event: {},
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Add a Task Outcome'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="circle-plus"
            />
          ),
        },
      },
    })
  }

  const editTaskOutcomeName = (task: TsInterface_UnspecifiedObject, outcome: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: outcome,
          formInputs: formInputs_OutcomeName,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let taskUpdateObject: TsInterface_UnspecifiedObject = {
                possible_outcomes: {},
              }
              taskUpdateObject['possible_outcomes'][outcome.key] = {
                name: formSubmittedData.name,
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Create an initial task'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="circle-plus"
            />
          ),
        },
      },
    })
  }

  const deleteTaskOutcome = (task: TsInterface_UnspecifiedObject, outcome: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Task Outcome'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this outcome?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            if (outcome != null && outcome.key != null && task != null && task.possible_outcomes != null && task.possible_outcomes[outcome.key] != null) {
              let taskUpdateObject = {
                possible_outcomes: task.possible_outcomes,
              }
              delete taskUpdateObject['possible_outcomes'][outcome.key]
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseUpdateDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DUD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DUD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            } else {
              resolve({ success: false })
            }
          })
        },
      },
    })
  }

  const editTaskOutcomeCondition = (task: TsInterface_UnspecifiedObject, outcome: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: outcome['outcome_condition'],
          formInputs: formInputs_TaskOutcomeCondition,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let outcomeUpdateObject: TsInterface_UnspecifiedObject = {
                possible_outcomes: {},
              }
              outcomeUpdateObject['possible_outcomes'][outcome.key] = {
                outcome_condition: {
                  outcome_condition_type: getProp(formSubmittedData, 'outcome_condition_type', null),
                  outcome_condition_logic: getProp(formSubmittedData, 'outcome_condition_logic', null),
                },
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), outcomeUpdateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Add Outcome Condition'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="circle-plus"
            />
          ),
        },
      },
    })
  }

  const editTaskOutcomeEvent = (task: TsInterface_UnspecifiedObject, outcome: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: outcome['outcome_event'],
          formInputs: formInputs_TaskOutcomeEvent,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let outcomeUpdateObject: TsInterface_UnspecifiedObject = {
                possible_outcomes: {},
              }
              outcomeUpdateObject['possible_outcomes'][outcome.key] = {
                outcome_event: {
                  outcome_event_type: formSubmittedData['outcome_event_type'],
                  app_notification_recipient: getProp(formSubmittedData, 'app_notification_recipient', null),
                  app_notification_text: getProp(formSubmittedData, 'app_notification_text', null),
                  app_notification_type: getProp(formSubmittedData, 'app_notification_type', null),
                },
              }
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), outcomeUpdateObject)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Add Outcome Event'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="circle-plus"
            />
          ),
        },
      },
    })
  }

  const checkForDirectlyDownstreamTasks = (task: TsInterface_UnspecifiedObject): boolean => {
    let foundDownstreamTask = false
    for (let loopTaskKey in us_stagingWorkflowTasks) {
      let loopTask = us_stagingWorkflowTasks[loopTaskKey]
      if (loopTask != null && loopTask['prerequisite_tasks'] != null && loopTask['prerequisite_tasks'][task.key] != null) {
        foundDownstreamTask = true
      }
    }
    return foundDownstreamTask
  }

  // PUBLISH
  const publishStagingToProductionWorkflow = (): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'primary',
        header: rLIB('Publish staging to prod'),
        icon: (
          <Icon
            icon="paper-plane"
            type="solid"
          />
        ),
        submit_text: rLIB('Publish'),
        text: rLIB('Are you sure that you want to publish the current staging workflow to production?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            let publishTimestamp = new Date().getTime()
            let copyOfTasks: TsInterface_UnspecifiedObject = cloneObjectWithoutReference(us_stagingWorkflowTasks)
            for (let loopTaskKey in copyOfTasks) {
              let loopTask = copyOfTasks[loopTaskKey]
              for (let loopPropKey in loopTask) {
                // eslint-disable-next-line no-prototype-builtins
                if (loopTask.hasOwnProperty(loopPropKey) && loopPropKey.startsWith('TEMP_')) {
                  delete loopTask[loopPropKey]
                }
              }
            }
            let updateObject: TsInterface_UnspecifiedObject = {
              // tasks: us_stagingWorkflowTasks,
              tasks: copyOfTasks,
              task_groups: objectToArray(us_stagingTaskGroups).sort(dynamicSort('timestamp_created', null)),
              project_phases: objectToArray(hardcodedProjectPhases).sort(dynamicSort('order', null)),
              config: us_stagingConfigItems,
              timestamp_published: publishTimestamp,
              associated_prod_history_key: publishTimestamp.toString(),
              associated_publisher_key: getProp(uc_RootData_ClientUser, 'key', null),
              associated_publisher_name: getProp(uc_RootData_ClientUser, 'name', null),
            }
            if (us_taskWorkflow.workflow_version_number === 2) {
              updateObject['workflow_version_number'] = 2
              updateObject['additional_work_tasks'] = us_stagingSowItems
              let taskGroups: TsInterface_UnspecifiedObject = {}
              for (let loopTaskGroupKey in globalTaskTypes) {
                let loopTaskGroup = globalTaskTypes[loopTaskGroupKey]
                if (loopTaskGroup != null && loopTaskGroup['name'] != null && loopTaskGroup['associated_project_phase_key'] != null) {
                  taskGroups[loopTaskGroupKey] = {
                    associated_project_phase_key: loopTaskGroup['associated_project_phase_key'],
                    key: loopTaskGroupKey,
                    name: loopTaskGroup['name_string'],
                  }
                }
              }
              updateObject['task_groups'] = objectToArray(taskGroups)
            } else {
              updateObject['workflow_version_number'] = 1
              updateObject['sow_items'] = objectToArray(us_stagingSowItems).sort(dynamicSort('name', null))
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                  { type: 'setOverwrite', ref: DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, pr_workflowKey), data: updateObject },
                  {
                    type: 'setOverwrite',
                    ref: DatabaseRef_TaskWorkflowProdTasksHistory_Document(res_GCK.clientKey, pr_workflowKey, publishTimestamp.toString()),
                    data: updateObject,
                  },
                ]
                if (us_taskWorkflow == null || us_taskWorkflow['published_to_prod'] !== true) {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, pr_workflowKey),
                    data: { published_to_prod: true, available_for_use: false },
                  })
                }
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    uc_setUserInterface_AlertDialogDisplay({
                      display: true,
                      alert: {
                        color: 'primary',
                        header: rLIB('Success') as JSX.Element,
                        icon: (
                          <Icon
                            icon="circle-ok"
                            type="solid"
                          />
                        ),
                        text: rLIB('Staging workflow published') as JSX.Element,
                      },
                    })
                    resolve({ success: true })
                  })
                  .catch((rej_DBU) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          })
        },
      },
    })
  }

  // Additional Work
  const openSowTaskPropertyEditForm = (task: TsInterface_UnspecifiedObject, formInputs: TsInterface_FormInputs): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: task,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                      us_setLastEditedTaskKey(task.key)
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit Task')} - {task.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const addSowUpstreamTask = (task: TsInterface_UnspecifiedObject): void => {
    // Generate List of Tasks
    let taskOptions: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in us_stagingWorkflowTasks) {
      let loopTask = us_stagingWorkflowTasks[loopTaskKey]
      if (loopTask != null && loopTask.name != null && loopTask.key != null) {
        taskOptions[loopTask.key] = {
          key: loopTask.key,
          value: loopTask.name,
        }
        if (task.key === loopTask.key) {
          taskOptions[loopTask.key]['disabled'] = true
          taskOptions[loopTask.key]['value'] += (' ' + rLIB('(this task)', false)) as string
        } else if (checkIfTaskIsUpstream(us_stagingWorkflowTasks, task, loopTask, us_stagingRecursiveTasks, ['addSowUpstreamTask']) === true) {
          taskOptions[loopTask.key]['disabled'] = true
          taskOptions[loopTask.key]['value'] += (' ' + rLIB('(downstream from this task)', false)) as string
        } else if (task.upstream_tasks != null && task.upstream_tasks[loopTask.key] != null) {
          taskOptions[loopTask.key]['disabled'] = true
          taskOptions[loopTask.key]['value'] += (' ' + rLIB('(already added)', false)) as string
        }
      }
    }
    let formInputs: TsInterface_FormInputs = {
      TEMP_upstream_tasks: {
        key: 'TEMP_upstream_tasks',
        label: rLIB('Upstream Tasks'),
        input_type: 'multiple_choice_radio',
        submit_on_change: true,
        required: true,
        data_type: 'string',
        options: objectToArray(taskOptions).sort(dynamicSort('value', null)),
      },
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: task,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let taskUpdateObject: TsInterface_UnspecifiedObject = {
                upstream_tasks: {},
              }
              taskUpdateObject['upstream_tasks'][formSubmittedData.TEMP_upstream_tasks] = formSubmittedData.TEMP_upstream_tasks
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DSMD) => {
                      us_setLastEditedTaskKey(task.key)
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit Task')} - {task.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const deleteSowUpstreamTask = (task: TsInterface_UnspecifiedObject, upstreamTaskKey: string): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Task Prerequisite'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this prerequisite?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            if (upstreamTaskKey != null && task != null && task.upstream_tasks != null && task.upstream_tasks[upstreamTaskKey] != null) {
              let taskUpdateObject = {
                upstream_tasks: task.upstream_tasks,
              }
              delete taskUpdateObject['upstream_tasks'][upstreamTaskKey]
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseUpdateDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DUD) => {
                      us_setLastEditedTaskKey(task.key)
                      resolve({ success: true })
                    })
                    .catch((rej_DUD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            } else {
              resolve({ success: false })
            }
          })
        },
      },
    })
  }

  const addSowDownstreamTask = (task: TsInterface_UnspecifiedObject): void => {
    // Generate List of Tasks
    let taskOptions: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in us_stagingWorkflowTasks) {
      let loopTask = us_stagingWorkflowTasks[loopTaskKey]
      if (loopTask != null && loopTask.name != null && loopTask.key != null) {
        taskOptions[loopTask.key] = {
          key: loopTask.key,
          value: loopTask.name,
        }
        if (task.key === loopTask.key) {
          taskOptions[loopTask.key]['disabled'] = true
          taskOptions[loopTask.key]['value'] += (' ' + rLIB('(this task)', false)) as string
        } else if (checkIfTaskIsUpstream(us_stagingWorkflowTasks, task, loopTask, us_stagingRecursiveTasks, ['addSowDownstreamTask']) === true) {
          taskOptions[loopTask.key]['disabled'] = true
          taskOptions[loopTask.key]['value'] += (' ' + rLIB('(downstream from this task)', false)) as string
        } else if (task.downstream_tasks != null && task.downstream_tasks[loopTask.key] != null) {
          taskOptions[loopTask.key]['disabled'] = true
          taskOptions[loopTask.key]['value'] += (' ' + rLIB('(already added)', false)) as string
        }
      }
    }
    let formInputs: TsInterface_FormInputs = {
      TEMP_downstream_tasks: {
        key: 'TEMP_downstream_tasks',
        label: rLIB('Downstream Tasks'),
        input_type: 'multiple_choice_radio',
        submit_on_change: true,
        required: true,
        data_type: 'string',
        options: objectToArray(taskOptions).sort(dynamicSort('value', null)),
      },
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: task,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let taskUpdateObject: TsInterface_UnspecifiedObject = {
                downstream_tasks: {},
              }
              taskUpdateObject['downstream_tasks'][formSubmittedData.TEMP_downstream_tasks] = formSubmittedData.TEMP_downstream_tasks
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DSMD) => {
                      us_setLastEditedTaskKey(task.key)
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit Task')} - {task.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const deleteSowDownstreamTask = (task: TsInterface_UnspecifiedObject, downstreamTaskKey: string): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Task Prerequisite'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this downstream task requirement?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            if (downstreamTaskKey != null && task != null && task.downstream_tasks != null && task.downstream_tasks[downstreamTaskKey] != null) {
              let taskUpdateObject = {
                downstream_tasks: task.downstream_tasks,
              }
              delete taskUpdateObject['downstream_tasks'][downstreamTaskKey]
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseUpdateDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DUD) => {
                      us_setLastEditedTaskKey(task.key)
                      resolve({ success: true })
                    })
                    .catch((rej_DUD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            } else {
              resolve({ success: false })
            }
          })
        },
      },
    })
  }

  const addSowDelayReason = (task: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_DelayReason,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let taskUpdateObject: TsInterface_UnspecifiedObject = {
                delay_reasons: {},
              }
              taskUpdateObject['delay_reasons'][formSubmittedData.delay_reason] = formSubmittedData.delay_reason
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DSMD) => {
                      us_setLastEditedTaskKey(task.key)
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Add Delay Reason'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const deleteSowDelayReason = (task: TsInterface_UnspecifiedObject, delayReasonKey: string): void => {
    uc_setUserInterface_ConfirmDialogDisplay({
      display: true,
      confirm: {
        color: 'error',
        header: rLIB('Delete Delay Reason'),
        icon: (
          <Icon
            icon="trash"
            type="solid"
          />
        ),
        submit_text: rLIB('Delete'),
        text: rLIB('Are you sure that you want to delete this delay reason?'),
        submit_callback: () => {
          return new Promise((resolve, reject) => {
            if (delayReasonKey != null && task != null && task.delay_reasons != null && task.delay_reasons[delayReasonKey] != null) {
              let taskUpdateObject = {
                delay_reasons: task.delay_reasons,
              }
              delete taskUpdateObject['delay_reasons'][delayReasonKey]
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseUpdateDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), taskUpdateObject)
                    .then((res_DUD) => {
                      us_setLastEditedTaskKey(task.key)
                      resolve({ success: true })
                    })
                    .catch((rej_DUD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            } else {
              resolve({ success: false })
            }
          })
        },
      },
    })
  }

  // CONFIG
  const openSiteAuditAssignmentTaskDialog = (label: JSX.Element, formInputBaseKey: string): void => {
    let taskOptions: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in us_stagingWorkflowTasks) {
      let loopTask = us_stagingWorkflowTasks[loopTaskKey]
      if (loopTask != null && loopTask.task_completion_type === 'dispatcher') {
        taskOptions[loopTaskKey] = {
          key: loopTaskKey,
          value: us_stagingWorkflowTasks[loopTaskKey]['name'],
        }
      }
    }
    let formInputs: TsInterface_FormInputs = {}
    formInputs['associated_dispatcher_task_key'] = {
      data_type: 'string',
      input_type: 'multiple_choice_select',
      key: 'associated_dispatcher_task_key',
      label: label,
      required: false,
      options: objectToArray(taskOptions).sort(dynamicSort('value', 'asc')),
    }
    // Import Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: getProp(us_stagingConfigItems, 'site_audit', {}),
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject: TsInterface_UnspecifiedObject = {}
                  updateObject['associated_dispatcher_task_key'] = formSubmittedData['associated_dispatcher_task_key']
                  updateObject['associated_dispatcher_task_name'] = taskOptions[formSubmittedData['associated_dispatcher_task_key']]['value']
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowConfigItems_Document(res_GCK.clientKey, pr_workflowKey, formInputBaseKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{label}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const openSiteAuditAssignmentV2TaskDialog = (label: JSX.Element, formInputBaseKey: string): void => {
    let taskOptions: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in us_stagingWorkflowTasks) {
      let loopTask = us_stagingWorkflowTasks[loopTaskKey]
      let blueprintTask = getProp(us_blueprintTasks, loopTaskKey, null)
      if (loopTask != null && blueprintTask != null && blueprintTask.task_completion_type === 'scheduled') {
        taskOptions['SCHEDULE_' + loopTaskKey] = {
          key: 'SCHEDULE_' + loopTaskKey,
          value: us_stagingWorkflowTasks[loopTaskKey]['name'],
        }
      }
    }
    let formInputs: TsInterface_FormInputs = {}
    formInputs['associated_dispatcher_task_key'] = {
      data_type: 'string',
      input_type: 'multiple_choice_select',
      key: 'associated_dispatcher_task_key',
      label: label,
      required: false,
      options: objectToArray(taskOptions).sort(dynamicSort('value', 'asc')),
    }
    // Import Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: getProp(us_stagingConfigItems, 'site_audit', {}),
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject: TsInterface_UnspecifiedObject = {}
                  updateObject['associated_dispatcher_task_key'] = formSubmittedData['associated_dispatcher_task_key']
                  updateObject['associated_dispatcher_task_name'] = taskOptions[formSubmittedData['associated_dispatcher_task_key']]['value']
                  DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowConfigItems_Document(res_GCK.clientKey, pr_workflowKey, formInputBaseKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{label}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_FilterMilestone = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let starIconJSX = <></>
    if (task != null && task.key != null) {
      if (us_taskWorkflow != null && us_taskWorkflow['filter_milestones'] != null && us_taskWorkflow['filter_milestones'][task.key] === true) {
        starIconJSX = (
          <Box
            className="tw-cursor-pointer"
            sx={{ 'fontSize': '20px', 'color': themeVariables.warning_main, '&:hover': { color: themeVariables.warning_dark } }}
            onClick={() => {
              if (editable === true) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  filter_milestones: {},
                }
                updateObject['filter_milestones'][task.key] = false
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, pr_workflowKey), updateObject)
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        console.error(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          >
            <Icon
              icon="star"
              type="solid"
            ></Icon>
          </Box>
        )
      } else {
        starIconJSX = (
          <Box
            className="tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100"
            sx={{ fontSize: '20px' }}
            onClick={() => {
              if (editable === true) {
                let updateObject: TsInterface_UnspecifiedObject = {
                  filter_milestones: {},
                }
                updateObject['filter_milestones'][task.key] = true
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_TaskWorkflow_Document(res_GCK.clientKey, pr_workflowKey), updateObject)
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        console.error(rej_DSMD)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          >
            <Icon
              icon="star"
              type="regular"
            ></Icon>
          </Box>
        )
      }
    }
    return starIconJSX
  }

  const rJSX_AbbreviationChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let requiredTypeJSX = <></>
    if (task.abbreviation != null) {
      requiredTypeJSX = (
        <Chip
          sx={{ maxWidth: '230px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={task.abbreviation}
          variant="filled"
          onClick={() => {
            if (editable === true) {
              openTaskPropertyEditForm(task, formInputs_TaskAbbreviation)
            }
          }}
        />
      )
    } else {
      requiredTypeJSX = (
        <Chip
          sx={{ maxWidth: '230px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openTaskPropertyEditForm(task, formInputs_TaskAbbreviation)
            }
          }}
        />
      )
    }
    return requiredTypeJSX
  }

  const rJSX_BlueprintAbbreviationChip = (task: TsInterface_UnspecifiedObject): JSX.Element => {
    let requiredTypeJSX = <></>
    if (
      task != null &&
      task.key != null &&
      us_blueprintTasks != null &&
      us_blueprintTasks[task.key] != null &&
      us_blueprintTasks[task.key]['abbreviation'] != null
    ) {
      requiredTypeJSX = (
        <Chip
          sx={{ maxWidth: '210px' }}
          className="tw-text-ellipsis tw-overflow-hidden"
          label={us_blueprintTasks[task.key]['abbreviation']}
          variant="outlined"
        />
      )
    } else {
      requiredTypeJSX = (
        <Chip
          sx={{ maxWidth: '210px' }}
          className="tw-text-ellipsis tw-overflow-hidden"
          label={rLIB('Missing')}
          variant="outlined"
          color="error"
        />
      )
    }
    return requiredTypeJSX
  }

  const rJSX_CompletionTime = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let completionTimeJSX = <></>
    if (task.completion_time == null || task.completion_time === 'main') {
      completionTimeJSX = (
        <Chip
          sx={{ maxWidth: '230px' }}
          color="success"
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Main Task')}
          variant="filled"
          onClick={() => {
            if (editable === true) {
              openTaskPropertyEditForm(task, formInputs_CompletionTime)
            }
          }}
        />
      )
    } else if (task.completion_time === 'cancellation') {
      completionTimeJSX = (
        <Chip
          sx={{ maxWidth: '230px' }}
          color="error"
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Cancellation Task')}
          variant="filled"
          onClick={() => {
            if (editable === true) {
              openTaskPropertyEditForm(task, formInputs_CompletionTime)
            }
          }}
        />
      )
    } else if (task.completion_time === 'completion') {
      completionTimeJSX = (
        <Chip
          sx={{ maxWidth: '230px' }}
          color="primary"
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Completion Task')}
          variant="filled"
          onClick={() => {
            if (editable === true) {
              openTaskPropertyEditForm(task, formInputs_CompletionTime)
            }
          }}
        />
      )
    }
    return completionTimeJSX
  }

  const rJSX_CompletionTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let assignmentTypeJSX = <></>
    if (task.task_completion_type != null) {
      let labelValue = task['task_completion_type']

      let chipVariant: 'outlined' | 'filled' | undefined = 'outlined'
      let chipColor: TsType_MuiComponentColors = 'warning'
      if (task.task_completion_type !== 'scheduled') {
        chipVariant = 'filled'
        chipColor = undefined
      } else {
        for (let loopTaskKey in us_stagingWorkflowTasks) {
          let loopTask = us_stagingWorkflowTasks[loopTaskKey]
          if (task != null && task.key != null && loopTask != null && loopTask['associated_dispatched_task_key'] === task.key) {
            chipVariant = 'filled'
            chipColor = undefined
          }
        }
      }
      if (
        formInputs_TaskAssignmentType != null &&
        formInputs_TaskAssignmentType['task_completion_type'] != null &&
        formInputs_TaskAssignmentType['task_completion_type']['options'] != null
      ) {
        let optionsObject = arrayToObject(formInputs_TaskAssignmentType['task_completion_type']['options'], 'key')
        if (optionsObject != null && optionsObject[labelValue] != null && optionsObject[labelValue]['value'] != null) {
          labelValue = optionsObject[labelValue]['value']
        }
      }
      assignmentTypeJSX = (
        <Box className="tw-inline-block">
          <Chip
            sx={{ maxWidth: '165px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={labelValue}
            variant={chipVariant}
            color={chipColor}
            onClick={() => {
              if (editable === true) {
                openTaskPropertyEditForm(task, formInputs_TaskAssignmentType)
              }
            }}
          />
        </Box>
      )
    } else {
      assignmentTypeJSX = (
        <Chip
          sx={{ maxWidth: '165px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openTaskPropertyEditForm(task, formInputs_TaskAssignmentType)
            }
          }}
        />
      )
    }
    return assignmentTypeJSX
  }

  const rJSX_BlueprintCompletionTypeChip = (task: TsInterface_UnspecifiedObject): JSX.Element => {
    let assignmentTypeJSX = <></>
    if (
      task != null &&
      task.key != null &&
      us_blueprintTasks != null &&
      us_blueprintTasks[task.key] != null &&
      us_blueprintTasks[task.key]['task_completion_type'] != null
    ) {
      let labelValue = us_blueprintTasks[task.key]['task_completion_type']

      let chipColor: TsType_MuiComponentColors = 'warning'
      if (
        adHocTaskAssignmentTypeInputOptions != null &&
        adHocTaskAssignmentTypeInputOptions[labelValue] != null &&
        adHocTaskAssignmentTypeInputOptions[labelValue]['value'] != null
      ) {
        chipColor = undefined
        labelValue = adHocTaskAssignmentTypeInputOptions[labelValue]['value']
      } else {
        for (let loopTaskKey in us_stagingWorkflowTasks) {
          let loopTask = us_stagingWorkflowTasks[loopTaskKey]
          if (task != null && task.key != null && loopTask != null && loopTask['associated_dispatched_task_key'] === task.key) {
            chipColor = undefined
          }
        }
      }

      assignmentTypeJSX = (
        <Box className="tw-inline-block">
          <Chip
            sx={{ maxWidth: '165px' }}
            className="tw-text-ellipsis tw-overflow-hidden"
            label={labelValue}
            variant="outlined"
            color={chipColor}
          />
        </Box>
      )
    } else {
      assignmentTypeJSX = (
        <Chip
          sx={{ maxWidth: '165px' }}
          className="tw-text-ellipsis tw-overflow-hidden"
          label={rLIB('Not Selected')}
          variant="outlined"
          color="error"
        />
      )
    }
    return assignmentTypeJSX
  }

  const rJSX_DispatchedTaskChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let chipJSX = <></>
    let firstChipJSX = <></>
    let secondChipJSX = <></>
    if (task['task_completion_type'] === 'scheduled') {
      // Display only chip to show dispatcher task
      firstChipJSX = (
        <Box
          className="tw-italic tw-inline-block"
          sx={{ color: themeVariables.warning_main }}
        >
          {rLIB('Missing Dispatcher Task')}
        </Box>
      )
      for (let loopTaskKey in us_stagingWorkflowTasks) {
        let loopTask = us_stagingWorkflowTasks[loopTaskKey]
        if (task != null && task.key != null && loopTask != null && loopTask['associated_dispatched_task_key'] === task.key) {
          firstChipJSX = (
            <Box className="tw-italic tw-opacity-50 tw-inline-block">
              <Chip
                sx={{ maxWidth: '170px' }}
                className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
                icon={<Icon icon="paper-plane" />}
                label={loopTask.name}
                variant="outlined"
              />
            </Box>
          )
        }
      }
      // Estimated Duration Chip
      if (task.estimated_task_duration_hours != null) {
        if (
          estimatedDurationOptions != null &&
          estimatedDurationOptions[task.estimated_task_duration_hours] != null &&
          estimatedDurationOptions[task.estimated_task_duration_hours]['value'] != null
        ) {
          secondChipJSX = (
            <Chip
              sx={{ maxWidth: '170px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={estimatedDurationOptions[task.estimated_task_duration_hours]['value']}
              variant="filled"
              onClick={() => {
                if (editable === true) {
                  openTaskPropertyEditForm(task, formInputs_EstimatedTaskDuration)
                }
              }}
            />
          )
        } else {
          secondChipJSX = (
            <Chip
              sx={{ maxWidth: '170px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={task.estimated_task_duration_hours}
              variant="filled"
              color="warning"
              onClick={() => {
                if (editable === true) {
                  openTaskPropertyEditForm(task, formInputs_EstimatedTaskDuration)
                }
              }}
            />
          )
        }
      } else {
        secondChipJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={rLIB('Estimated Duration')}
            variant="filled"
            color="error"
            onClick={() => {
              if (editable === true) {
                openTaskPropertyEditForm(task, formInputs_EstimatedTaskDuration)
              }
            }}
          />
        )
      }
      // Combined
      chipJSX = (
        <Box className="tw-inline-block">
          <Box>{firstChipJSX}</Box>
          <Box className="tw-mt-1">{secondChipJSX}</Box>
        </Box>
      )
    } else if (task['task_completion_type'] === 'dispatcher') {
      let currentTaskOptions: TsInterface_UnspecifiedObject = {}
      for (let loopTaskKey in us_stagingWorkflowTasks) {
        let loopTask = us_stagingWorkflowTasks[loopTaskKey]
        currentTaskOptions[loopTask.key] = {
          key: loopTask.key,
          value: loopTask.name,
        }
      }
      // Task To Dispatch
      if (task.associated_dispatched_task_key != null) {
        if (
          currentTaskOptions != null &&
          currentTaskOptions[task.associated_dispatched_task_key] != null &&
          currentTaskOptions[task.associated_dispatched_task_key]['value'] != null
        ) {
          firstChipJSX = (
            <Chip
              sx={{ maxWidth: '170px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={currentTaskOptions[task.associated_dispatched_task_key]['value']}
              variant="filled"
              onClick={() => {
                if (editable === true) {
                  generateDownstreamDispatchedTasksForForm(task)
                  openTaskPropertyEditForm(task, formInputs_AssociatedDispatchedTasks)
                }
              }}
            />
          )
        } else {
          firstChipJSX = (
            <Chip
              sx={{ maxWidth: '170px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={task.associated_dispatched_task_key}
              variant="filled"
              color="warning"
              onClick={() => {
                if (editable === true) {
                  generateDownstreamDispatchedTasksForForm(task)
                  openTaskPropertyEditForm(task, formInputs_AssociatedDispatchedTasks)
                }
              }}
            />
          )
        }
      } else {
        firstChipJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={rLIB('Not Selected')}
            variant="filled"
            color="error"
            onClick={() => {
              if (editable === true) {
                generateDownstreamDispatchedTasksForForm(task)
                openTaskPropertyEditForm(task, formInputs_AssociatedDispatchedTasks)
              }
            }}
          />
        )
      }
      // Team Type
      if (task.associated_team_type != null) {
        if (
          schedulingTeamTypes != null &&
          schedulingTeamTypes[task.associated_team_type] != null &&
          schedulingTeamTypes[task.associated_team_type]['value'] != null
        ) {
          secondChipJSX = (
            <Chip
              sx={{ maxWidth: '170px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={schedulingTeamTypes[task.associated_team_type]['value']}
              variant="filled"
              onClick={() => {
                if (editable === true) {
                  openTaskPropertyEditForm(task, formInputs_AssociatedTeamType)
                }
              }}
            />
          )
        } else {
          secondChipJSX = (
            <Chip
              sx={{ maxWidth: '170px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={task.associated_team_type}
              variant="filled"
              color="warning"
              onClick={() => {
                if (editable === true) {
                  openTaskPropertyEditForm(task, formInputs_AssociatedTeamType)
                }
              }}
            />
          )
        }
      } else {
        secondChipJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={rLIB('Team Type Not Selected')}
            variant="filled"
            color="error"
            onClick={() => {
              if (editable === true) {
                openTaskPropertyEditForm(task, formInputs_AssociatedTeamType)
              }
            }}
          />
        )
      }
      // Combined
      chipJSX = (
        <Box className="tw-inline-block">
          <Box>{firstChipJSX}</Box>
          <Box className="tw-mt-1">{secondChipJSX}</Box>
        </Box>
      )
    } else {
      chipJSX = <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Not Applicable')}</Box>
    }
    return chipJSX
  }

  const rJSX_OwnerTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean, styleOverride: boolean): JSX.Element => {
    let ownerTypeJSX = <></>
    if (task.associated_owner_type != null) {
      if (
        us_taskOwnerOptions != null &&
        us_taskOwnerOptions[task.associated_owner_type] != null &&
        us_taskOwnerOptions[task.associated_owner_type]['value'] != null
      ) {
        ownerTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={us_taskOwnerOptions[task.associated_owner_type]['value']}
            variant="filled"
            onClick={() => {
              if (editable === true) {
                openTaskPropertyEditForm(task, formInputs_TaskOwnerType)
              }
            }}
          />
        )
      } else {
        ownerTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_owner_type}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openTaskPropertyEditForm(task, formInputs_TaskOwnerType)
              }
            }}
          />
        )
      }
    } else {
      ownerTypeJSX = (
        <Chip
          sx={{ maxWidth: '170px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openTaskPropertyEditForm(task, formInputs_TaskOwnerType)
            }
          }}
        />
      )
    }
    return ownerTypeJSX
  }

  const rJSX_BlueprintOwnerTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean, styleOverride: boolean): JSX.Element => {
    let ownerTypeJSX = <></>
    if (task != null && task.key != null && us_blueprintTasks != null && us_blueprintTasks[task.key] != null) {
      if (task.associated_owner_type != null) {
        // Has been overriden
        ownerTypeJSX = (
          <Box>
            <Box>
              <Box className="tw-inline-block tw-bg-warning_dark tw-p-0.5 tw-m-1 tw-rounded">{rLIB('Override')}:</Box>
              <Icon
                icon="rotate-left"
                className="tw-ml-0.5 tw-cursor-pointer tw-opacity-30 tw-p-0.5 hover:tw-opacity-100"
                tooltip={rLIB('Revert to default')}
                tooltipPlacement="right"
                onClick={() => {
                  if (editable === true) {
                    let updateObject = {
                      associated_owner_type: DatabaseFieldDelete,
                    }
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseUpdateDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), updateObject)
                          .then((res_DSMD) => {
                            us_setLastEditedTaskKey(task.key)
                            us_setForceRefreshStagingData(new Date().getTime())
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  }
                }}
              />
            </Box>
            {rJSX_OwnerTypeChip(task, editable, styleOverride)}
          </Box>
        )
      } else {
        // Show Default
        ownerTypeJSX = (
          <Box>
            <Box className="tw-opacity-30 tw-p-0.5">{rLIB('Uses default')}:</Box>
            {rJSX_OwnerTypeChip(us_blueprintTasks[task.key], editable, styleOverride)}
          </Box>
        )
      }
    } else {
      ownerTypeJSX = (
        <Chip
          sx={{ maxWidth: '210px' }}
          className="tw-text-ellipsis tw-overflow-hidden"
          label={rLIB('Missing')}
          variant="outlined"
          color="error"
        />
      )
    }
    return ownerTypeJSX
  }

  const rJSX_FormTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let chipJSX = <></>
    if (task['task_completion_type'] !== 'dispatcher') {
      if (task.associated_task_form_key != null) {
        if (
          us_taskFormOptions != null &&
          us_taskFormOptions[task.associated_task_form_key] != null &&
          us_taskFormOptions[task.associated_task_form_key]['value'] != null
        ) {
          chipJSX = (
            <Box className="tw-inline-block">
              <Chip
                sx={{ maxWidth: '190px' }}
                className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
                label={us_taskFormOptions[task.associated_task_form_key]['value']}
                variant="filled"
                onClick={() => {
                  if (editable === true) {
                    updateTaskFormOptionsSortOrder()
                    openTaskPropertyEditForm(task, formInputs_AssociatedTaskForm)
                  }
                }}
              />
              {/* <Box className="tw-inline-block tw-cursor-pointer tw-ml-2" onClick={ () => {
								previewTaskForm( task )
							}}>
								<Icon icon="magnifying-glass" />
							</Box> */}
            </Box>
          )
        } else {
          chipJSX = (
            <Chip
              sx={{ maxWidth: '190px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={task.associated_task_form_key}
              variant="filled"
              color="warning"
              onClick={() => {
                if (editable === true) {
                  updateTaskFormOptionsSortOrder()
                  openTaskPropertyEditForm(task, formInputs_AssociatedTaskForm)
                }
              }}
            />
          )
        }
      } else {
        chipJSX = (
          <Chip
            sx={{ maxWidth: '190px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={rLIB('Not Selected')}
            variant="filled"
            color="error"
            onClick={() => {
              if (editable === true) {
                updateTaskFormOptionsSortOrder()
                openTaskPropertyEditForm(task, formInputs_AssociatedTaskForm)
              }
            }}
          />
        )
      }
    } else {
      chipJSX = <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Not Applicable')}</Box>
    }
    return chipJSX
  }

  const rJSX_BlueprintFormTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let formTypeJSX = <></>
    if (task != null && task.key != null && us_blueprintTasks != null && us_blueprintTasks[task.key] != null) {
      if (task.associated_task_form_key != null) {
        // Has been overriden
        formTypeJSX = (
          <Box>
            <Box>
              <Box className="tw-inline-block tw-bg-warning_dark tw-p-0.5 tw-m-1 tw-rounded">{rLIB('Override')}:</Box>
              <Icon
                icon="rotate-left"
                className="tw-ml-0.5 tw-cursor-pointer tw-opacity-30 tw-p-0.5 hover:tw-opacity-100"
                tooltip={rLIB('Revert to default')}
                tooltipPlacement="right"
                onClick={() => {
                  if (editable === true) {
                    let updateObject = {
                      associated_task_form_key: DatabaseFieldDelete,
                    }
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseUpdateDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), updateObject)
                          .then((res_DSMD) => {
                            us_setLastEditedTaskKey(task.key)
                            us_setForceRefreshStagingData(new Date().getTime())
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  }
                }}
              />
            </Box>
            {rJSX_FormTypeChip(task, editable)}
          </Box>
        )
      } else {
        // Show Default
        formTypeJSX = (
          <Box>
            <Box className="tw-opacity-30 tw-p-0.5">{rLIB('Uses default')}:</Box>
            {rJSX_FormTypeChip(us_blueprintTasks[task.key], editable)}
          </Box>
        )
      }
    } else {
      formTypeJSX = (
        <Chip
          sx={{ maxWidth: '210px' }}
          className="tw-text-ellipsis tw-overflow-hidden"
          label={rLIB('Missing')}
          variant="outlined"
          color="error"
        />
      )
    }
    return formTypeJSX
  }

  const rJSX_TaskGroupChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let ownerTypeJSX = <></>
    if (task.associated_task_group_key != null) {
      if (
        us_taskGroupOptions != null &&
        us_taskGroupOptions[task.associated_task_group_key] != null &&
        us_taskGroupOptions[task.associated_task_group_key]['value'] != null
      ) {
        ownerTypeJSX = (
          <Chip
            sx={{ maxWidth: '210px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={us_taskGroupOptions[task.associated_task_group_key]['value']}
            variant="filled"
            onClick={() => {
              if (editable === true) {
                openTaskPropertyEditForm(task, formInputs_TaskGroupKey)
              }
            }}
          />
        )
      } else {
        ownerTypeJSX = (
          <Chip
            sx={{ maxWidth: '210px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_task_group_key}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openTaskPropertyEditForm(task, formInputs_TaskGroupKey)
              }
            }}
          />
        )
      }
    } else {
      ownerTypeJSX = (
        <Chip
          sx={{ maxWidth: '210px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openTaskPropertyEditForm(task, formInputs_TaskGroupKey)
            }
          }}
        />
      )
    }
    return ownerTypeJSX
  }

  const rJSX_BlueprintTaskGroupChip = (task: TsInterface_UnspecifiedObject): JSX.Element => {
    let ownerTypeJSX = <></>
    if (task.associated_task_group_name != null) {
      ownerTypeJSX = (
        <Chip
          sx={{ maxWidth: '210px' }}
          className="tw-text-ellipsis tw-overflow-hidden"
          label={task.associated_task_group_name}
          variant="outlined"
        />
      )
    } else {
      ownerTypeJSX = (
        <Chip
          sx={{ maxWidth: '210px' }}
          className="tw-text-ellipsis tw-overflow-hidden"
          label={rLIB('Missing')}
          variant="outlined"
          color="error"
        />
      )
    }
    return ownerTypeJSX
  }

  const rJSX_EstimatedDaysChips = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let ownerTypeJSX = <></>
    // if( task.status_green_days_cutoff != null && task.status_yellow_days_cutoff != null && task.status_red_days_cutoff != null ){
    if (task.status_green_days_cutoff != null && task.status_yellow_days_cutoff != null) {
      // let blackLabel = ( task.status_red_days_cutoff + 1 ) + "+"
      let redLabel = task.status_yellow_days_cutoff + 1 + '+'
      ownerTypeJSX = (
        <Box className="tw-inline-block">
          <Chip
            className="tw-cursor-pointer tw-mx-0.5"
            label={task.status_green_days_cutoff}
            variant="filled"
            color="success"
            onClick={() => {
              if (editable === true) {
                openTaskPropertyEditForm(task, formInputs_EstimatedDays)
              }
            }}
          />
          <Chip
            className="tw-cursor-pointer tw-mx-0.5"
            label={task.status_yellow_days_cutoff}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openTaskPropertyEditForm(task, formInputs_EstimatedDays)
              }
            }}
          />
          <Chip
            className="tw-cursor-pointer tw-mx-0.5"
            label={redLabel}
            variant="filled"
            color="error"
            onClick={() => {
              if (editable === true) {
                openTaskPropertyEditForm(task, formInputs_EstimatedDays)
              }
            }}
          />
          {/* <Chip className="tw-cursor-pointer tw-mx-0.5" label={ blackLabel  } variant="filled" sx={{ background: themeVariables.palettes.custom_dark_red.main, "&:hover": { background: themeVariables.palettes.custom_dark_red.dark, opacity: 1 } }} onClick={ () => {
						if( editable === true ) {
							openTaskPropertyEditForm( task, formInputs_EstimatedDays )
						}
					} }/> */}
        </Box>
      )
    } else {
      ownerTypeJSX = (
        <Chip
          className="tw-cursor-pointer"
          label={rLIB('Not Specified')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openTaskPropertyEditForm(task, formInputs_EstimatedDays)
            }
          }}
        />
      )
    }
    return ownerTypeJSX
  }

  const rJSX_BlueprintEstimatedDaysChips = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let estimatedDaysJSX = <></>
    if (task != null && task.key != null && us_blueprintTasks != null && us_blueprintTasks[task.key] != null) {
      if (task.status_green_days_cutoff != null || task.status_yellow_days_cutoff != null) {
        // Has been overriden
        estimatedDaysJSX = (
          <Box>
            <Box>
              <Box className="tw-inline-block tw-bg-warning_dark tw-p-0.5 tw-m-1 tw-rounded">{rLIB('Override')}:</Box>
              <Icon
                icon="rotate-left"
                className="tw-ml-0.5 tw-cursor-pointer tw-opacity-30 tw-p-0.5 hover:tw-opacity-100"
                tooltip={rLIB('Revert to default')}
                tooltipPlacement="right"
                onClick={() => {
                  if (editable === true) {
                    let updateObject = {
                      status_green_days_cutoff: DatabaseFieldDelete,
                      status_yellow_days_cutoff: DatabaseFieldDelete,
                    }
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseUpdateDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), updateObject)
                          .then((res_DSMD) => {
                            us_setLastEditedTaskKey(task.key)
                            us_setForceRefreshStagingData(new Date().getTime())
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  }
                }}
              />
            </Box>
            {rJSX_EstimatedDaysChips(task, editable)}
          </Box>
        )
      } else {
        // Show Default
        estimatedDaysJSX = (
          <Box>
            <Box className="tw-opacity-30 tw-p-0.5">{rLIB('Uses default')}:</Box>
            {rJSX_EstimatedDaysChips(us_blueprintTasks[task.key], editable)}
          </Box>
        )
      }
    } else {
      estimatedDaysJSX = (
        <Chip
          sx={{ maxWidth: '210px' }}
          className="tw-text-ellipsis tw-overflow-hidden"
          label={rLIB('Missing')}
          variant="outlined"
          color="error"
        />
      )
    }
    return estimatedDaysJSX
  }

  const rJSX_TaskPrerequisites = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let taskPrerequisites = <></>
    if (task != null && task['prerequisite_tasks'] != null && objectToArray(task['prerequisite_tasks']).length > 0) {
      taskPrerequisites = (
        <Box>
          {objectToArray(task.prerequisite_tasks)
            .sort()
            .map((prerequisiteTaskKey, taskIndex) => (
              <Box key={taskIndex}>
                <Typography
                  className="tw-inline-block tw-text-ellipsis tw-overflow-hidden"
                  variant="body1"
                  sx={{ maxWidth: '280px' }}
                >
                  <Icon
                    icon="square-check"
                    type="solid"
                    className="tw-mr-2"
                  />
                  {us_stagingWorkflowTasks[prerequisiteTaskKey].name}
                </Typography>
                <Tooltip title={rLIB('Delete Task Prerequisite')}>
                  <Typography
                    variant="body1"
                    className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (editable === true) {
                        deleteTaskPrerequisite(task, prerequisiteTaskKey)
                      }
                    }}
                  >
                    <>
                      <Icon
                        icon="trash"
                        type="solid"
                      ></Icon>
                    </>
                  </Typography>
                </Tooltip>
              </Box>
            ))}
        </Box>
      )
    } else {
      taskPrerequisites = (
        <Box>
          <Typography
            variant="body1"
            className="tw-mt-0 tw-inline-block tw-mr-2 tw-opacity-30 tw-italic"
          >
            {rLIB('No Task Prerequisites')}
          </Typography>
        </Box>
      )
    }
    return taskPrerequisites
  }

  const rJSX_DelayReasonStarIcon = (task: TsInterface_UnspecifiedObject, delayReason: string, editable: boolean): JSX.Element => {
    let iconJSX = <></>
    if (task.default_delay_reason != null && task.default_delay_reason === delayReason) {
      iconJSX = (
        <Tooltip title={rLIB('Default Delay Reason')}>
          <Typography
            variant="body1"
            className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
            sx={{}}
            onClick={() => {
              if (editable === true) {
                let updateObject = {
                  default_delay_reason: null,
                }
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), updateObject)
                      .then((res_DSMD) => {
                        us_setLastEditedTaskKey(task.key)
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          >
            <>
              <Icon
                icon="star"
                type="solid"
              ></Icon>
            </>
          </Typography>
        </Tooltip>
      )
    } else {
      iconJSX = (
        <Tooltip title={rLIB('Default Delay Reason')}>
          <Typography
            variant="body1"
            className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
            sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
            onClick={() => {
              if (editable === true) {
                let updateObject = {
                  default_delay_reason: delayReason,
                }
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowStagingTasks_Document(res_GCK.clientKey, pr_workflowKey, task.key), updateObject)
                      .then((res_DSMD) => {
                        us_setLastEditedTaskKey(task.key)
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          >
            <>
              <Icon
                icon="star"
                type="thin"
              ></Icon>
            </>
          </Typography>
        </Tooltip>
      )
    }
    return iconJSX
  }

  const rJSX_DelayReasons = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let taskPrerequisites = <></>
    if (task != null && task['delay_reasons'] != null && objectToArray(task['delay_reasons']).length > 0) {
      taskPrerequisites = (
        <Box>
          {objectToArray(task.delay_reasons)
            .sort()
            .map((delayReason, taskIndex) => (
              <Box key={taskIndex}>
                <Typography
                  className="tw-inline-block tw-text-ellipsis tw-overflow-hidden"
                  variant="body1"
                  sx={{ maxWidth: '280px' }}
                >
                  <Icon
                    icon="clock"
                    type="solid"
                    className="tw-mr-2"
                  />
                  {delayReason}
                </Typography>
                <Tooltip title={rLIB('Delete Delay Reason')}>
                  <Typography
                    variant="body1"
                    className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (editable === true) {
                        deleteDelayReason(task, delayReason)
                      }
                    }}
                  >
                    <>
                      <Icon
                        icon="trash"
                        type="solid"
                      ></Icon>
                    </>
                  </Typography>
                </Tooltip>
                {rJSX_DelayReasonStarIcon(task, delayReason, editable)}
              </Box>
            ))}
        </Box>
      )
    } else {
      taskPrerequisites = (
        <Box>
          <Typography
            variant="body1"
            className="tw-mt-0 tw-inline-block tw-mr-2 tw-opacity-30 tw-italic"
          >
            {rLIB('No Delay Reasons')}
          </Typography>
        </Box>
      )
    }
    return taskPrerequisites
  }

  const rJSX_DirectlyDownstreamTasks = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let directlyDownstreamTasksJSX = <></>
    let directlyDowstreamTasks: TsInterface_UnspecifiedObject = {}
    let foundDownstreamTask = false
    for (let loopTaskKey in us_stagingWorkflowTasks) {
      let loopTask = us_stagingWorkflowTasks[loopTaskKey]
      if (loopTask != null && loopTask['prerequisite_tasks'] != null && loopTask['prerequisite_tasks'][task.key] != null) {
        directlyDowstreamTasks[loopTaskKey] = loopTask
        foundDownstreamTask = true
      }
    }
    if (foundDownstreamTask === true) {
      directlyDownstreamTasksJSX = (
        <Box className="tw-mb-4">
          <Box
            className="tw-p-2 tw-text-left tw-rounded-t-lg"
            sx={{ background: themeVariables.info_light, minWidth: '320px' }}
          >
            <Stack
              direction="row"
              className="tw-justify-between"
            >
              <Box>
                <Typography
                  className="tw-italic tw-inline-block"
                  variant="body1"
                >
                  <Icon
                    icon="link"
                    type="solid"
                    className="tw-mr-2"
                  ></Icon>
                  {rLIB('Followup Tasks')}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{ borderColor: themeVariables.info_light }}
            className="tw-border-solid tw-border-x-2 tw-border-b-2 tw-border-t-0 tw-rounded-b-lg"
          >
            {objectToArray(directlyDowstreamTasks)
              .sort(dynamicSort('name', null))
              .map((loopTask, loopTaskIndex) => (
                <Box
                  sx={{ maxWidth: '310px' }}
                  key={loopTaskIndex}
                  className="tw-text-ellipsis tw-overflow-hidden tw-text-left"
                >
                  {/* <Tooltip placement="right" title={ loopTask.name }> */}
                  <Icon
                    icon="forward"
                    className="tw-mx-1"
                  ></Icon>
                  {loopTask.name}
                  {/* </Tooltip> */}
                </Box>
              ))}
          </Box>
        </Box>
      )
    } else {
      directlyDownstreamTasksJSX = (
        <Box className="tw-mb-4">
          <Box
            className="tw-p-2 tw-text-left tw-rounded-t-lg"
            sx={{ background: themeVariables.background_paper, minWidth: '320px' }}
          >
            <Stack
              direction="row"
              className="tw-justify-between"
            >
              <Box>
                <Typography
                  className="tw-italic tw-inline-block tw-opacity-30"
                  variant="body1"
                >
                  <Icon
                    icon="link"
                    type="solid"
                    className="tw-mr-2"
                  ></Icon>
                  {rLIB('Followup Tasks')}
                </Typography>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{ borderColor: themeVariables.background_paper }}
            className="tw-border-solid tw-border-x-2 tw-border-b-2 tw-border-t-0 tw-rounded-b-lg"
          >
            <Typography
              className="tw-italic tw-inline-block tw-opacity-30"
              variant="body1"
            >
              {rLIB('No Followup Tasks')}
            </Typography>
          </Box>
        </Box>
      )
    }
    return directlyDownstreamTasksJSX
  }

  const rJSX_TaskOutcomeCondition = (task: TsInterface_UnspecifiedObject, outcome: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let taskOutcomeConditionJSX = <></>

    // TODO - render condition after that is figured out

    if (outcome != null && outcome['outcome_condition'] != null && outcome['outcome_condition']['outcome_condition_type'] === 'always') {
      taskOutcomeConditionJSX = (
        <Box className="tw-text-left tw-mx-1">
          <Typography
            variant="body1"
            className="tw-inline-block"
          >
            {rLIB('Condition')}: {rLIB('Always Trigger')}
          </Typography>
          <Box
            className="tw-ml-2 tw-inline-block tw-cursor_pointer tw-opacity-30 hover:tw-opacity-100"
            onClick={() => {
              if (editable === true) {
                editTaskOutcomeCondition(task, outcome)
              }
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        </Box>
      )
    } else if (outcome != null && outcome['outcome_condition'] != null && outcome['outcome_condition']['outcome_condition_type'] === 'conditional') {
      taskOutcomeConditionJSX = (
        <Box className="tw-text-left tw-mx-1">
          <Typography
            variant="body1"
            className="tw-inline-block"
          >
            {rLIB('Condition')}: TODO{' '}
          </Typography>
          <Box
            className="tw-ml-2 tw-inline-block tw-cursor_pointer tw-opacity-30 hover:tw-opacity-100"
            onClick={() => {
              if (editable === true) {
                editTaskOutcomeCondition(task, outcome)
              }
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        </Box>
      )
    } else {
      taskOutcomeConditionJSX = (
        <Button
          className="tw-m-2"
          color="inherit"
          variant="outlined"
          startIcon={<Icon icon="circle-plus"></Icon>}
          onClick={() => {
            if (editable === true) {
              editTaskOutcomeCondition(task, outcome)
            }
          }}
        >
          {rLIB('Add Condition')}
        </Button>
      )
    }
    return taskOutcomeConditionJSX
  }

  const rJSX_TaskOutcomeTriggeredEvent = (task: TsInterface_UnspecifiedObject, outcome: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let taskOutcomeEventJSX = <></>

    // TODO - Other Events

    if (outcome != null && outcome['outcome_event'] != null && outcome['outcome_event']['outcome_event_type'] === 'app_notification') {
      let iconJSX = <></>
      switch (outcome['outcome_event']['app_notification_type']) {
        case 'success':
          iconJSX = (
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.success_main }}
            >
              <Icon
                type="solid"
                icon="circle-check"
              />
            </Box>
          )
          break
        case 'info':
          iconJSX = (
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.info_main }}
            >
              <Icon
                type="solid"
                icon="circle-info"
              />
            </Box>
          )
          break
        case 'warning':
          iconJSX = (
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.warning_main }}
            >
              <Icon
                type="solid"
                icon="triangle-exclamation"
              />
            </Box>
          )
          break
        case 'error':
          iconJSX = (
            <Box
              className="tw-inline-block tw-mr-2"
              sx={{ color: themeVariables.error_main }}
            >
              <Icon
                type="solid"
                icon="siren-on"
              />
            </Box>
          )
          break
      }
      taskOutcomeEventJSX = (
        <Box>
          <Box className="tw-text-left tw-mx-1">
            <Typography
              variant="body1"
              className="tw-inline-block"
            >
              {rLIB('Event')}: {rLIB('Send in platform notification')}
            </Typography>
            <Box
              className="tw-ml-2 tw-inline-block tw-cursor_pointer tw-opacity-30 hover:tw-opacity-100"
              onClick={() => {
                if (editable === true) {
                  editTaskOutcomeEvent(task, outcome)
                }
              }}
            >
              <Icon icon="pen-to-square" />
            </Box>
          </Box>
          <Box className="tw-text-left tw-mx-1">
            {iconJSX}
            {outcome['outcome_event']['app_notification_text']}
          </Box>
        </Box>
      )
    } else {
      taskOutcomeEventJSX = (
        <Button
          className="tw-m-2"
          color="inherit"
          variant="outlined"
          startIcon={<Icon icon="circle-plus"></Icon>}
          onClick={() => {
            if (editable === true) {
              editTaskOutcomeEvent(task, outcome)
            }
          }}
        >
          {rLIB('Add Event')}
        </Button>
      )
    }
    return taskOutcomeEventJSX
  }

  const rJSX_TaskPrerequisitesMinified = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let taskPrerequisites = <></>
    if (task != null && task['prerequisite_tasks'] != null && objectToArray(task['prerequisite_tasks']).length > 0) {
      taskPrerequisites = (
        <Box>
          <Box
            className="tw-p-2 tw-text-left tw-rounded-t-lg"
            sx={{ background: themeVariables.error_main, minWidth: '320px' }}
          >
            <Stack
              direction="row"
              className="tw-justify-between"
            >
              <Box>
                <Typography
                  className="tw-italic tw-inline-block"
                  variant="body1"
                >
                  <Icon
                    icon="link"
                    type="solid"
                    className="tw-mr-2"
                  ></Icon>
                  {rLIB('Prerequisite Tasks')}
                </Typography>
                <Tooltip title={rLIB('Add Task Prerequisite')}>
                  <Box
                    className="tw-mt-0 tw-ml-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (editable === true) {
                        addTaskPrerequisite(task)
                      }
                    }}
                  >
                    <>
                      <Icon
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                </Tooltip>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{ borderColor: themeVariables.error_main }}
            className="tw-border-solid tw-border-x-2 tw-border-b-2 tw-border-t-0 tw-rounded-b-lg"
          >
            {objectToArray(task.prerequisite_tasks)
              .sort(dynamicSort('name', 'asc'))
              .map((prerequisiteTaskKey, taskIndex) => (
                <Box
                  key={taskIndex}
                  sx={{ maxWidth: '310px' }}
                  className="tw-text-ellipsis tw-overflow-hidden tw-text-left"
                >
                  <Icon
                    icon="square-check"
                    type="solid"
                    className="tw-mx-1"
                  />
                  {us_stagingWorkflowTasks[prerequisiteTaskKey].name}
                </Box>
              ))}
          </Box>
        </Box>
      )
    } else {
      taskPrerequisites = (
        <Box>
          <Box
            className="tw-p-2 tw-text-left tw-rounded-t-lg"
            sx={{ background: themeVariables.background_paper, minWidth: '320px' }}
          >
            <Stack
              direction="row"
              className="tw-justify-between"
            >
              <Box>
                <Typography
                  className="tw-italic tw-inline-block tw-opacity-30"
                  variant="body1"
                >
                  <Icon
                    icon="link"
                    type="solid"
                    className="tw-mr-2"
                  ></Icon>
                  {rLIB('Prerequisite Tasks')}
                </Typography>
                <Tooltip title={rLIB('Add Task Prerequisite')}>
                  <Box
                    className="tw-mt-0 tw-ml-1 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (editable === true) {
                        addTaskPrerequisite(task)
                      }
                    }}
                  >
                    <>
                      <Icon
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                </Tooltip>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{ borderColor: themeVariables.background_paper }}
            className="tw-border-solid tw-border-x-2 tw-border-b-2 tw-border-t-0 tw-rounded-b-lg"
          >
            <Typography
              className="tw-italic tw-inline-block tw-opacity-30"
              variant="body1"
            >
              {rLIB('No Task Prerequisites')}
            </Typography>
          </Box>
        </Box>
      )
    }
    return taskPrerequisites
  }

  const rJSX_TaskOutcomes = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let combinedTaskOutcomeJSX = <></>
    // JSX - Arrow
    let arrowJSX = <></>
    if (us_uiMinifiedTasks === false) {
      if ((task.possible_outcomes == null || objectToArray(task.possible_outcomes).length === 0) && checkForDirectlyDownstreamTasks(task) === false) {
        arrowJSX = (
          <Box className="tw-my-3">
            <Icon
              type="solid"
              icon="arrow-down"
              size="2x"
              className="tw-opacity-10"
            ></Icon>
          </Box>
        )
      } else {
        arrowJSX = (
          <Box className="tw-my-3">
            <Icon
              type="solid"
              icon="arrow-down"
              size="2x"
            ></Icon>
          </Box>
        )
      }
    }
    // JSX - Follup Tasks
    let followupTasksJSX = rJSX_DirectlyDownstreamTasks(task, editable)
    // JSX - Outcomes List
    let outcomesListJSX = <></>
    if (us_uiMinifiedTasks === false) {
      if (task.possible_outcomes != null && objectToArray(task.possible_outcomes).length > 0) {
        outcomesListJSX = (
          <Box>
            {objectToArray(task.possible_outcomes)
              .sort(dynamicSort('key', null))
              .map((outcome, index) => (
                <Box
                  key={index}
                  className="tw-block tw-align-top tw-mb-3"
                >
                  <Box
                    className="tw-p-2 tw-text-left tw-rounded-t-lg"
                    sx={{ background: themeVariables.success_dark, minWidth: '320px' }}
                  >
                    <Stack
                      direction="row"
                      className="tw-justify-between"
                    >
                      <Box>
                        <Typography
                          className="tw-italic tw-inline-block"
                          variant="body1"
                        >
                          <Icon
                            icon="arrow-turn-down-left"
                            type="solid"
                            className="tw-mr-2 flip_horizontal"
                          ></Icon>
                          {outcome.name}
                        </Typography>
                        <Tooltip title={rLIB('Edit Outcome Name')}>
                          <Box
                            className="tw-mt-0 tw-cursor-pointer tw-inline-block"
                            sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                            onClick={() => {
                              if (editable === true) {
                                editTaskOutcomeName(task, outcome)
                              }
                            }}
                          >
                            <>
                              <Icon
                                icon="pen-to-square"
                                type="solid"
                                className="tw-ml-2"
                              ></Icon>
                            </>
                          </Box>
                        </Tooltip>
                      </Box>
                      <Box>
                        <Tooltip title={rLIB('Delete Outcome')}>
                          <Typography
                            variant="body1"
                            className="tw-mt-0 tw-cursor-pointer"
                            sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                            onClick={() => {
                              if (editable === true) {
                                deleteTaskOutcome(task, outcome)
                              }
                            }}
                          >
                            <>
                              <Icon
                                icon="trash"
                                type="solid"
                              ></Icon>
                            </>
                          </Typography>
                        </Tooltip>
                      </Box>
                    </Stack>
                  </Box>
                  <Box
                    sx={{ borderColor: themeVariables.success_dark }}
                    className="tw-border-solid tw-border-x-2 tw-border-b-2 tw-border-t-0"
                  >
                    {rJSX_TaskOutcomeCondition(task, outcome, editable)}
                  </Box>
                  <Box
                    sx={{ borderColor: themeVariables.success_dark }}
                    className="tw-border-solid tw-border-x-2 tw-border-b-2 tw-border-t-0 tw-rounded-b-lg"
                  >
                    {rJSX_TaskOutcomeTriggeredEvent(task, outcome, editable)}
                  </Box>
                </Box>
              ))}
          </Box>
        )
      }
    } else {
      // if( task.possible_outcomes != null && objectToArray( task.possible_outcomes ).length > 0 ){
      // 	let labelJSX = <></>
      // 	if( objectToArray( task.possible_outcomes ).length === 1 ){
      // 		labelJSX = s_TASK_OUTCOME
      // 	} else {
      // 		labelJSX = rLIB("Task Outcomes")
      // 	}
      // 	outcomesListJSX =
      // 	<Box className="tw-mb-4">
      // 		<Box className="tw-p-2 tw-text-left tw-rounded-lg" sx={{ background: themeVariables.success_dark, minWidth: "320px" }}>
      // 			<Stack direction="row" className="tw-justify-between">
      // 				<Box>
      // 					<Typography className="tw-italic tw-inline-block" variant="body1">
      // 						<Icon icon="arrow-turn-down-left" type="solid" className="tw-mr-2 flip_horizontal"></Icon>
      // 						<strong>{ objectToArray( task.possible_outcomes ).length }</strong> { labelJSX }
      // 					</Typography>
      // 				</Box>
      // 			</Stack>
      // 		</Box>
      // 	</Box>
      // } else {
      // 	outcomesListJSX =
      // 	<Box className="tw-mb-4">
      // 		<Box className="tw-p-2 tw-text-left tw-rounded-lg" sx={{ background: themeVariables.background_paper, minWidth: "320px" }}>
      // 			<Stack direction="row" className="tw-justify-between">
      // 				<Box>
      // 					<Typography className="tw-italic tw-inline-block tw-opacity-30" variant="body1">
      // 						<Icon icon="arrow-turn-down-left" type="solid" className="tw-mr-2 flip_horizontal"></Icon>
      // 						{ rLIB("No Task Outcomes") }
      // 					</Typography>
      // 				</Box>
      // 			</Stack>
      // 		</Box>
      // 	</Box>
      // }
    }
    // JSX - Button
    let addTaskButtonJSX = <></>
    if (us_uiMinifiedTasks === false) {
      if (task.possible_outcomes == null || objectToArray(task.possible_outcomes).length === 0) {
        addTaskButtonJSX = (
          <Box className="tw-mb-4">
            <Box
              className="tw-p-2 tw-text-left tw-rounded-t-lg"
              sx={{ background: themeVariables.background_paper, minWidth: '320px' }}
            >
              <Stack
                direction="row"
                className="tw-justify-between"
              >
                <Box>
                  <Typography
                    className="tw-italic tw-inline-block tw-opacity-30"
                    variant="body1"
                  >
                    <Icon
                      icon="arrow-turn-down-left"
                      type="solid"
                      className="tw-mr-2 flip_horizontal"
                    ></Icon>
                    {rLIB('No Task Outcomes')}
                  </Typography>
                </Box>
              </Stack>
            </Box>
            <Box
              sx={{ borderColor: themeVariables.background_paper }}
              className="tw-border-solid tw-border-x-2 tw-border-b-2 tw-border-t-0 tw-rounded-b-lg"
            >
              <Button
                className="tw-m-2 tw-opacity-30"
                color="inherit"
                variant="outlined"
                startIcon={<Icon icon="circle-plus"></Icon>}
                onClick={() => {
                  if (editable === true) {
                    addTaskOutcome(task)
                  }
                }}
              >
                {rLIB('Add Task Outcome')}
              </Button>
            </Box>
          </Box>
        )
      } else {
        addTaskButtonJSX = (
          <Button
            className="tw-m-2 tw-opacity-30"
            color="inherit"
            variant="outlined"
            startIcon={<Icon icon="circle-plus"></Icon>}
            onClick={() => {
              if (editable === true) {
                addTaskOutcome(task)
              }
            }}
          >
            {rLIB('Add Another Task Outcome')}
          </Button>
        )
      }
    }
    // JSX - Combined
    combinedTaskOutcomeJSX = (
      <Box>
        {arrowJSX}
        {followupTasksJSX}
        {outcomesListJSX}
        {addTaskButtonJSX}
      </Box>
    )
    // Return
    return combinedTaskOutcomeJSX
  }

  const rJSX_StarIcon = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let starIconJSX = <></>
    if (task.milestone_task === true) {
      starIconJSX = (
        <Tooltip title={rLIB('Click to not display as milestone to customer')}>
          <Box
            onClick={() => {
              if (editable === true) {
                updateTaskProperty(task.key, 'milestone_task', false)
              }
            }}
          >
            <Box
              className="tw-inline-block tw-mr-1"
              sx={{ color: themeVariables.warning_dark }}
            >
              <Icon
                icon="star"
                type="solid"
                className="tw-cursor-pointer"
              ></Icon>
            </Box>
            <Typography
              variant="body1"
              className="tw-mt-0 tw-inline-block"
            >
              {rLIB('Display as milestone step')}
            </Typography>
          </Box>
        </Tooltip>
      )
    } else {
      starIconJSX = (
        <Tooltip title={rLIB('Click to display as milestone to customer')}>
          <Box
            onClick={() => {
              if (editable === true) {
                updateTaskProperty(task.key, 'milestone_task', true)
              }
            }}
          >
            <Box
              className="tw-inline-block tw-mr-1"
              sx={{ color: themeVariables.warning_dark }}
            >
              <Icon
                icon="star"
                type="light"
                className="tw-cursor-pointer tw-opacity-50"
              ></Icon>
            </Box>
            <Typography
              variant="body1"
              className="tw-mt-0 tw-inline-block tw-opacity-30"
            >
              {rLIB('Not displayed as milestone step')}
            </Typography>
          </Box>
        </Tooltip>
      )
    }
    return starIconJSX
  }

  const rJSX_IndividualTask = (task: TsInterface_UnspecifiedObject, levelIndex: number, editable: boolean): JSX.Element => {
    let taskJSX = <></>
    if (us_uiMinifiedTasks === false) {
      let taskSX: TsInterface_UnspecifiedObject = { minWidth: '320px' }
      if (us_lastEditedTaskKey === task.key) {
        taskSX = { minWidth: '320px', border: '2px solid ' + themeVariables.primary_main, borderRadius: '10px' }
      } else {
        taskSX = { minWidth: '320px', border: '2px solid rgba(0,0,0,0)', borderRadius: '10px' }
      }
      // Version variants
      let abbreviationChipJSX = rJSX_AbbreviationChip(task, editable)
      let completionTypeChipJSX = rJSX_CompletionTypeChip(task, editable)
      let taskGroupChipJSX = rJSX_TaskGroupChip(task, editable)
      let formTypeChipJSX = rJSX_FormTypeChip(task, editable)
      let ownerTypeChipJSX = rJSX_OwnerTypeChip(task, editable, false)
      let estimatedDaysChipJSX = rJSX_EstimatedDaysChips(task, editable)
      let delayReasonJSX = (
        <Box className="tw-mt-2">
          <Typography
            variant="body1"
            className="tw-mt-0 tw-inline-block tw-mr-2"
          >
            {rLIB('Delay Reasons')}:{' '}
          </Typography>
          <Tooltip title={rLIB('Add Delay Reason')}>
            <Box
              className="tw-mt-0 tw-mb-2 tw-cursor-pointer tw-inline-block"
              sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
              onClick={() => {
                if (editable === true) {
                  addDelayReason(task)
                }
              }}
            >
              <>
                <Icon
                  icon="circle-plus"
                  type="solid"
                ></Icon>
              </>
            </Box>
          </Tooltip>
          <Box>{rJSX_DelayReasons(task, editable)}</Box>
        </Box>
      )
      let dispatchJSX = (
        <Box className="tw-mt-2">
          <Typography
            variant="body1"
            className="tw-mt-0 tw-inline-block tw-mr-2 tw-align-top"
          >
            {rLIB('Task to Dispatch')}:{' '}
          </Typography>
          {rJSX_DispatchedTaskChip(task, editable)}
        </Box>
      )
      if (us_taskWorkflow.workflow_version_number === 2) {
        abbreviationChipJSX = rJSX_BlueprintAbbreviationChip(task)
        completionTypeChipJSX = rJSX_BlueprintCompletionTypeChip(task)
        taskGroupChipJSX = rJSX_BlueprintTaskGroupChip(task)
        formTypeChipJSX = rJSX_BlueprintFormTypeChip(task, editable)
        ownerTypeChipJSX = rJSX_BlueprintOwnerTypeChip(task, editable, false)
        estimatedDaysChipJSX = rJSX_BlueprintEstimatedDaysChips(task, editable)
        delayReasonJSX = <></>
        dispatchJSX = <></>
      }
      // Full JSX
      taskJSX = (
        <Box
          className="tw-m-2"
          sx={taskSX}
        >
          <Box
            className="tw-rounded-lg tw-overflow-x-hidden"
            sx={{ background: themeVariables.background_paper, maxWidth: '320px' }}
          >
            <Box
              className="tw-p-2 tw-text-left tw-rounded-t-lg"
              sx={{ background: themeVariables.background_json, minWidth: '320px' }}
            >
              <Stack
                direction="row"
                className="tw-justify-between"
              >
                <Box>
                  <Box>
                    <Tooltip
                      title={task.name}
                      placement="top"
                    >
                      <Typography
                        variant="h6"
                        className="tw-mt-0 tw-inline-block tw-text-ellipsis tw-overflow-hidden"
                        sx={{ maxWidth: '260px' }}
                      >
                        {task.name}
                      </Typography>
                    </Tooltip>
                    <Tooltip title={rLIB('Edit task name')}>
                      <Box
                        className="tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top tw-mt-1.5"
                        sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                        onClick={() => {
                          if (editable === true) {
                            openTaskPropertyEditForm(task, formInputs_TaskName)
                          }
                        }}
                      >
                        <>
                          <Icon
                            icon="pen-to-square"
                            type="solid"
                          ></Icon>
                        </>
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
                <Box>
                  <Tooltip title={rLIB('Delete task')}>
                    <Typography
                      variant="body1"
                      className="tw-mt-0 tw-cursor-pointer"
                      sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                      onClick={() => {
                        if (editable === true) {
                          deleteTask(task)
                        }
                      }}
                    >
                      <>
                        <Icon
                          icon="trash"
                          type="solid"
                        ></Icon>
                      </>
                    </Typography>
                  </Tooltip>
                </Box>
              </Stack>
            </Box>
            <Box className="tw-p-2 tw-text-left">
              <Box className="tw-mt-0 tw-inline-block">{rJSX_StarIcon(task, editable)}</Box>
              <Box className="tw-mt-2">
                <Typography
                  variant="body1"
                  className="tw-mt-0 tw-inline-block tw-mr-2"
                >
                  {rLIB('Task Abbreviation')}:{' '}
                </Typography>
                {abbreviationChipJSX}
              </Box>
              <Box className="tw-mt-2">
                <Typography
                  variant="body1"
                  className="tw-mt-0 tw-inline-block tw-mr-2"
                >
                  {rLIB('Task Group')}:{' '}
                </Typography>
                {taskGroupChipJSX}
              </Box>
              <Divider className="tw-mt-2" />
              <Box className="tw-mt-2">
                <Typography
                  variant="body1"
                  className="tw-mt-0 tw-inline-block tw-mr-2"
                >
                  {rLIB('Task Prerequisites')}:{' '}
                </Typography>
                <Tooltip title={rLIB('Add Task Prerequisite')}>
                  <Box
                    className="tw-mt-0 tw-mb-2 tw-cursor-pointer tw-inline-block"
                    sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
                    onClick={() => {
                      if (editable === true) {
                        addTaskPrerequisite(task)
                      }
                    }}
                  >
                    <>
                      <Icon
                        icon="circle-plus"
                        type="solid"
                      ></Icon>
                    </>
                  </Box>
                </Tooltip>
                <Box>{rJSX_TaskPrerequisites(task, editable)}</Box>
              </Box>
              {delayReasonJSX}
              <Divider className="tw-mt-2" />
              <Box className="tw-mt-2">
                <Typography
                  variant="body1"
                  className="tw-mt-0 tw-inline-block tw-mr-2"
                >
                  {rLIB('Task Type')}:{' '}
                </Typography>
                {completionTypeChipJSX}
              </Box>
              {dispatchJSX}
              <Box className="tw-mt-2">
                <Typography
                  variant="body1"
                  className="tw-mt-0 tw-inline-block tw-mr-2"
                >
                  {rLIB('Task Form')}:{' '}
                </Typography>
                {formTypeChipJSX}
              </Box>
              <Divider className="tw-mt-2" />
              <Box className="tw-mt-2">
                <Typography
                  variant="body1"
                  className="tw-mt-0 tw-inline-block tw-mr-2"
                >
                  {rLIB('Owner User Type')}:{' '}
                </Typography>
                {ownerTypeChipJSX}
              </Box>
              <Box className="tw-mt-2 tw-overflow-hidden">
                <Typography
                  variant="body1"
                  className="tw-mt-0 tw-inline-block tw-mr-2"
                >
                  {rLIB('Status cutoff (days)')}:{' '}
                </Typography>
                {estimatedDaysChipJSX}
              </Box>
            </Box>
          </Box>
          {rJSX_TaskOutcomes(task, editable)}
        </Box>
      )
    } else {
      taskJSX = (
        <Box
          className="tw-m-2"
          sx={{ minWidth: '320px' }}
        >
          {rJSX_TaskPrerequisitesMinified(task, editable)}
          <Box
            className="tw-rounded-lg"
            sx={{ background: themeVariables.info_dark, maxWidth: '320px' }}
          >
            <Box
              className="tw-p-2 tw-text-left tw-rounded-lg"
              sx={{ background: themeVariables.warning_dark, minWidth: '320px' }}
            >
              <Stack
                direction="row"
                className="tw-justify-between"
              >
                <Tooltip
                  title={task.name}
                  placement="top"
                >
                  <Typography
                    variant="h6"
                    className="tw-mt-0 tw-inline-block tw-text-ellipsis tw-overflow-hidden"
                    sx={{ maxWidth: '320px' }}
                  >
                    {task.name}
                  </Typography>
                </Tooltip>
              </Stack>
            </Box>
          </Box>
          {rJSX_TaskOutcomes(task, editable)}
        </Box>
      )
    }
    return taskJSX
  }

  const rJSX_ToggleUiMinifyButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_uiMinifiedTasks === false) {
      buttonJSX = (
        <Button
          color="secondary"
          variant="contained"
          className="tw-align-top"
          startIcon={<Icon icon="arrows-minimize"></Icon>}
          onClick={() => {
            us_setUiMinifiedTasks(true)
          }}
        >
          {rLIB('Hide Task Details')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          color="secondary"
          variant="outlined"
          className="tw-align-top"
          startIcon={<Icon icon="arrows-maximize"></Icon>}
          onClick={() => {
            us_setUiMinifiedTasks(false)
          }}
        >
          {rLIB('Show Task Details')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_AddTaskButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="success"
        variant="contained"
        className="tw-mr-2 tw-align-top"
        startIcon={<Icon icon="circle-plus"></Icon>}
        onClick={() => {
          createStagingTask()
        }}
      >
        {rLIB('Add a task')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_TaskWorkflow = (): JSX.Element => {
    let workflowJSX = <></>
    if (us_stagingWorkflowTasks == null || objectToArray(us_stagingWorkflowTasks).length === 0) {
      // No tasks currently listed
      workflowJSX = (
        <Box className="tw-text-center">
          <Typography variant="h6">{rLIB('No tasks added yet')}</Typography>
        </Box>
      )
    } else {
      workflowJSX = (
        <Box className="tw-text-center">
          <Box>
            {objectToArray(returnTaskRows(us_stagingWorkflowTasks, {}, us_stagingRecursiveTasks, ['rJSX_TaskWorkflow']))
              .sort(dynamicSort('row', null))
              .map((row, rowIndex) => (
                <Box
                  key={rowIndex}
                  className="tw-overflow-x-scroll tw-whitespace-nowrap bp_show_horizontal_scroll"
                >
                  {objectToArray(row.tasks)
                    .sort(dynamicSort('timestamp_created', null))
                    .map((task, taskIndex) => (
                      <Box
                        key={taskIndex}
                        className="tw-inline-block tw-align-top"
                      >
                        {rJSX_IndividualTask(task, rowIndex, true)}
                      </Box>
                    ))}
                  <Divider />
                </Box>
              ))}
          </Box>
        </Box>
      )
    }
    return workflowJSX
  }

  const rJSX_StagingFlowChart = (): JSX.Element => {
    let stagingTabJSX = <></>
    if (us_initialStagingDataLoaded === true) {
      stagingTabJSX = <Box className="tw-p-4">{rJSX_TaskWorkflow()}</Box>
    } else {
      stagingTabJSX = (
        <Box className="tw-p-4 tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    }
    return stagingTabJSX
  }

  const rJSX_StagingTable = (): JSX.Element => {
    let tabJSX = <></>
    if (us_stagingWorkflowTasks == null || objectToArray(us_stagingWorkflowTasks).length === 0) {
      // No tasks currently listed
      tabJSX = (
        <Box className="tw-text-center">
          <Typography variant="h6">{rLIB('No tasks added yet')}</Typography>
        </Box>
      )
    } else {
      let tableColumns: TsInterface_TableColumns = {}
      if (us_taskWorkflow.workflow_version_number === 2) {
        tableColumns = tableColumns_TaskTableV2
      } else {
        tableColumns = tableColumns_TaskTableV1
      }
      tabJSX = (
        <Box>
          <Card>
            <TableBasic
              tableAdditionalData={{
                projectTasks: us_stagingWorkflowTasks,
                lastEditedTaskKey: us_lastEditedTaskKey,
                tableData: us_stagingWorkflowTasks,
                us_prodWorkflow: us_prodWorkflow,
                editable: true,
              }}
              tableColumns={tableColumns}
              tableData={objectToArray(us_stagingWorkflowTasks)}
              tableSettings={tableSettings_TaskTable}
            />
          </Card>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_TableViewButtonGroup = (): JSX.Element => {
    let buttonGroupJSX = <></>
    if (us_taskView === 'table') {
      buttonGroupJSX = (
        <ButtonGroup>
          <Button
            color="secondary"
            variant="contained"
            startIcon={<Icon icon="table-list"></Icon>}
          >
            {rLIB('Table View')}
          </Button>
          <Button
            color="secondary"
            variant="outlined"
            startIcon={<Icon icon="sitemap"></Icon>}
            onClick={() => {
              us_setTaskView('flowchart')
            }}
          >
            {rLIB('Flowchart View')}
          </Button>
        </ButtonGroup>
      )
    } else if (us_taskView === 'flowchart') {
      buttonGroupJSX = (
        <Box className="tw-inline-block">
          <ButtonGroup className="tw-mr-2">
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<Icon icon="table-list"></Icon>}
              onClick={() => {
                us_setTaskView('table')
              }}
            >
              {rLIB('Table View')}
            </Button>
            <Button
              color="secondary"
              variant="contained"
              startIcon={<Icon icon="sitemap"></Icon>}
            >
              {rLIB('Flowchart View')}
            </Button>
          </ButtonGroup>
          {rJSX_ToggleUiMinifyButton()}
        </Box>
      )
    }
    return buttonGroupJSX
  }

  const rJSX_StagingTaskTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_taskView === 'table') {
      tabJSX = <Box>{rJSX_StagingTable()}</Box>
    } else if (us_taskView === 'flowchart') {
      tabJSX = <Box>{rJSX_StagingFlowChart()}</Box>
    } else {
      tabJSX = (
        <Box className="tw-mt-2">
          <Stack
            direction="row"
            spacing={1}
            className="tw-mb-2"
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<Icon icon="table-list"></Icon>}
              onClick={() => {
                us_setTaskView('table')
              }}
            >
              {rLIB('Table View')}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<Icon icon="sitemap"></Icon>}
              onClick={() => {
                us_setTaskView('flowchart')
              }}
            >
              {rLIB('Flowchart View')}
            </Button>
          </Stack>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_NewTaskGroupButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        startIcon={<Icon icon="circle-plus"></Icon>}
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: newTaskGroupPromptObject,
          })
        }}
      >
        {rLIB('Create task group')}
      </Button>
    )
  }

  const rJSX_TaskGroupsTab = (): JSX.Element => {
    let taskGroupTabJSX = <></>
    if (us_stagingTaskGroups != null && objectToArray(us_stagingTaskGroups).length > 0) {
      taskGroupTabJSX = (
        <Box>
          <Card>
            <TableBasic
              tableAdditionalData={tableAdditionalData_TaskGroups}
              tableColumns={tableColumns_TaskGroups}
              tableData={objectToArray(us_stagingTaskGroups)}
              tableSettings={tableSettings_TaskGroups}
            />
          </Card>
        </Box>
      )
    } else {
      taskGroupTabJSX = (
        <Box className="tw-p-4 tw-text-center">
          <Typography
            variant="h6"
            className="tw-mb-4"
          >
            {rLIB('No Task Groups Yet')}
          </Typography>
        </Box>
      )
    }
    return taskGroupTabJSX
  }

  const rJSX_NewSowTaskButton = (): JSX.Element => {
    return (
      <Button
        color="success"
        variant="contained"
        startIcon={<Icon icon="circle-plus"></Icon>}
        onClick={() => {
          uc_setUserInterface_PromptDialogDisplay({
            display: true,
            prompt: newSowItemPropmt,
          })
        }}
      >
        {rLIB('Create additional work item')}
      </Button>
    )
  }

  const rJSX_SowTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_stagingSowItems != null && objectToArray(us_stagingSowItems).length > 0) {
      tabJSX = (
        <Box>
          <Card>
            <TableBasic
              tableAdditionalData={tableAdditionalData_SowItems}
              tableColumns={tableColumns_SowItems}
              tableData={objectToArray(us_stagingSowItems)}
              tableSettings={tableSettings_SowItems}
            />
          </Card>
        </Box>
      )
    } else {
      tabJSX = (
        <Box className="tw-p-4 tw-text-center">
          <Typography
            variant="h6"
            className="tw-mb-4"
          >
            {rLIB('No Additional Work Items')}
          </Typography>
        </Box>
      )
    }
    return tabJSX
  }

  // Additional Work Tab
  const rJSX_StagingAdditionalWorkTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_additionalWorkTaskType == null) {
      tabJSX = (
        <Box className="tw-py-4 tw-text-center tw-opacity-50 tw-mb-2">
          <Typography variant="h6">{rLIB('Select a task type')}</Typography>
        </Box>
      )
    } else {
      tabJSX = (
        <Box>
          <Card>
            <TableBasic
              tableAdditionalData={{
                projectTasks: us_stagingWorkflowTasks,
                lastEditedTaskKey: us_lastEditedTaskKey,
                additionalWorkTasks: us_stagingSowItems,
                us_blueprintTasks: us_blueprintTasks,
                editable: true,
              }}
              tableColumns={tableColumns_AdditionalWorkTable}
              tableData={objectToArray(us_filteredTaskBlueprints)}
              tableSettings={tableSettings_AdditionalWorkTable}
            />
          </Card>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_ProdAdditionalWorkTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_additionalWorkTaskType == null) {
      tabJSX = (
        <Box className="tw-py-4 tw-text-center tw-opacity-50 tw-mb-2">
          <Typography variant="h6">{rLIB('Select a task type')}</Typography>
        </Box>
      )
    } else {
      tabJSX = (
        <Box>
          <Card>
            <TableBasic
              tableAdditionalData={{
                projectTasks: us_stagingWorkflowTasks,
                lastEditedTaskKey: us_lastEditedTaskKey,
                additionalWorkTasks: getProp(us_prodWorkflowTasks, 'additional_work_tasks', {}),
                us_blueprintTasks: us_blueprintTasks,
                editable: false,
              }}
              tableColumns={tableColumns_AdditionalWorkTable}
              tableData={objectToArray(us_filteredTaskBlueprints)}
              tableSettings={tableSettings_AdditionalWorkTable}
            />
          </Card>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_SowTaskGroupChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let ownerTypeJSX = <></>
    if (task.associated_task_group_key != null) {
      if (
        us_taskGroupOptions != null &&
        us_taskGroupOptions[task.associated_task_group_key] != null &&
        us_taskGroupOptions[task.associated_task_group_key]['value'] != null
      ) {
        ownerTypeJSX = (
          <Chip
            sx={{ maxWidth: '210px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={us_taskGroupOptions[task.associated_task_group_key]['value']}
            variant="filled"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskGroupKey)
              }
            }}
          />
        )
      } else {
        ownerTypeJSX = (
          <Chip
            sx={{ maxWidth: '210px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_task_group_key}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskGroupKey)
              }
            }}
          />
        )
      }
    } else {
      ownerTypeJSX = (
        <Chip
          sx={{ maxWidth: '210px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_TaskGroupKey)
            }
          }}
        />
      )
    }
    return ownerTypeJSX
  }

  const rJSX_SowCompletionTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let assignmentTypeJSX = <></>
    if (task.task_completion_type != null) {
      let labelValue = task['task_completion_type']
      // let chipVariant: "outlined" | "filled" | undefined = "outlined"
      // let chipColor: TsType_MuiComponentColors = "warning"
      let chipVariant: 'outlined' | 'filled' | undefined = 'filled'
      let chipColor: TsType_MuiComponentColors = undefined
      // if( task.task_completion_type !== "scheduled" ){
      // 	chipVariant = "filled"
      // 	chipColor = undefined
      // } else {
      // 	for( let loopTaskKey in stagingWorkflowTasks){
      // 		let loopTask = stagingWorkflowTasks[ loopTaskKey ]
      // 		if(
      // 			task != null &&
      // 			task.key != null &&
      // 			loopTask != null &&
      // 			loopTask["associated_dispatched_task_key"] === task.key
      // 		){
      // 			chipVariant = "filled"
      // 			chipColor = undefined
      // 		}
      // 	}
      // }
      if (
        formInputs_SowTaskAssignmentType != null &&
        formInputs_SowTaskAssignmentType['task_completion_type'] != null &&
        formInputs_SowTaskAssignmentType['task_completion_type']['options'] != null
      ) {
        let optionsObject = arrayToObject(formInputs_SowTaskAssignmentType['task_completion_type']['options'], 'key')
        if (optionsObject != null && optionsObject[labelValue] != null && optionsObject[labelValue]['value'] != null) {
          labelValue = optionsObject[labelValue]['value']
        }
      }
      assignmentTypeJSX = (
        <Box className="tw-inline-block">
          <Chip
            sx={{ maxWidth: '165px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={labelValue}
            variant={chipVariant}
            color={chipColor}
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_SowTaskAssignmentType)
              }
            }}
          />
        </Box>
      )
    } else {
      assignmentTypeJSX = (
        <Chip
          sx={{ maxWidth: '165px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_SowTaskAssignmentType)
            }
          }}
        />
      )
    }
    return assignmentTypeJSX
  }

  const rJSX_SowFormTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let chipJSX = <></>
    if (task.associated_task_form_key != null) {
      if (
        us_taskFormOptions != null &&
        us_taskFormOptions[task.associated_task_form_key] != null &&
        us_taskFormOptions[task.associated_task_form_key]['value'] != null
      ) {
        chipJSX = (
          <Box className="tw-inline-block">
            <Chip
              sx={{ maxWidth: '190px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={us_taskFormOptions[task.associated_task_form_key]['value']}
              variant="filled"
              onClick={() => {
                if (editable === true) {
                  updateTaskFormOptionsSortOrder()
                  openSowTaskPropertyEditForm(task, formInputs_AssociatedTaskForm)
                }
              }}
            />
            {/* <Box className="tw-inline-block tw-cursor-pointer tw-ml-2" onClick={ () => {
							previewTaskForm( task )
						}}>
							<Icon icon="magnifying-glass" />
						</Box> */}
          </Box>
        )
      } else {
        chipJSX = (
          <Chip
            sx={{ maxWidth: '190px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_task_form_key}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                updateTaskFormOptionsSortOrder()
                openSowTaskPropertyEditForm(task, formInputs_AssociatedTaskForm)
              }
            }}
          />
        )
      }
    } else {
      chipJSX = (
        <Chip
          sx={{ maxWidth: '190px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              updateTaskFormOptionsSortOrder()
              openSowTaskPropertyEditForm(task, formInputs_AssociatedTaskForm)
            }
          }}
        />
      )
    }
    return chipJSX
  }

  // us_stagingSowItems > tableAdditionalData["additionalWorkTasks"]

  const rJSX_AdditionalWorkFormTypeChip = (
    task: TsInterface_UnspecifiedObject,
    editable: boolean,
    additionalWorkTasks: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let chipJSX = <></>
    if (additionalWorkTasks != null && additionalWorkTasks[task.key] != null && additionalWorkTasks[task.key]['associated_task_form_key'] != null) {
      // Has been overriden
      chipJSX = (
        <Box>
          <Box>
            <Box className="tw-inline-block tw-bg-warning_dark tw-p-0.5 tw-m-1 tw-rounded">{rLIB('Override')}:</Box>
            <Icon
              icon="rotate-left"
              className="tw-ml-0.5 tw-cursor-pointer tw-opacity-30 tw-p-0.5 hover:tw-opacity-100"
              tooltip={rLIB('Revert to default')}
              tooltipPlacement="right"
              onClick={() => {
                if (editable === true) {
                  let updateObject = {
                    associated_task_form_key: DatabaseFieldDelete,
                  }
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseUpdateDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), updateObject)
                        .then((res_DSMD) => {
                          us_setLastEditedTaskKey(task.key)
                          us_setForceRefreshStagingData(new Date().getTime())
                        })
                        .catch((rej_DSMD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
            />
          </Box>
          {rJSX_SowFormTypeChip(additionalWorkTasks[task.key], editable)}
        </Box>
      )
    } else {
      // Show Default
      chipJSX = (
        <Box>
          <Box className="tw-opacity-30 tw-p-0.5">{rLIB('Uses default')}:</Box>
          {rJSX_SowFormTypeChip({ ...task }, editable)}
        </Box>
      )
    }
    return chipJSX
  }

  const rJSX_SowOwnerTypeChip = (task: TsInterface_UnspecifiedObject, editable: boolean, styleOverride: boolean): JSX.Element => {
    let ownerTypeJSX = <></>
    if (task.associated_owner_type != null) {
      if (
        us_taskOwnerOptions != null &&
        us_taskOwnerOptions[task.associated_owner_type] != null &&
        us_taskOwnerOptions[task.associated_owner_type]['value'] != null
      ) {
        ownerTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={us_taskOwnerOptions[task.associated_owner_type]['value']}
            variant="filled"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskOwnerType)
              }
            }}
          />
        )
      } else {
        ownerTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_owner_type}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskOwnerType)
              }
            }}
          />
        )
      }
    } else {
      ownerTypeJSX = (
        <Chip
          sx={{ maxWidth: '170px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_TaskOwnerType)
            }
          }}
        />
      )
    }
    return ownerTypeJSX
  }

  const rJSX_AdditionalWorkOwnerTypeChip = (
    task: TsInterface_UnspecifiedObject,
    editable: boolean,
    styleOverride: boolean,
    additionalWorkTasks: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let chipJSX = <></>
    if (additionalWorkTasks != null && additionalWorkTasks[task.key] != null && additionalWorkTasks[task.key]['associated_owner_type'] != null) {
      // Has been overriden
      chipJSX = (
        <Box>
          <Box>
            <Box className="tw-inline-block tw-bg-warning_dark tw-p-0.5 tw-m-1 tw-rounded">{rLIB('Override')}:</Box>
            <Icon
              icon="rotate-left"
              className="tw-ml-0.5 tw-cursor-pointer tw-opacity-30 tw-p-0.5 hover:tw-opacity-100"
              tooltip={rLIB('Revert to default')}
              tooltipPlacement="right"
              onClick={() => {
                if (editable === true) {
                  let updateObject = {
                    associated_owner_type: DatabaseFieldDelete,
                  }
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseUpdateDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), updateObject)
                        .then((res_DSMD) => {
                          us_setLastEditedTaskKey(task.key)
                          us_setForceRefreshStagingData(new Date().getTime())
                        })
                        .catch((rej_DSMD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
            />
          </Box>
          {rJSX_SowOwnerTypeChip(additionalWorkTasks[task.key], editable, styleOverride)}
        </Box>
      )
    } else {
      // Show Default
      chipJSX = (
        <Box>
          <Box className="tw-opacity-30 tw-p-0.5">{rLIB('Uses default')}:</Box>
          {rJSX_SowOwnerTypeChip({ ...task }, editable, styleOverride)}
        </Box>
      )
    }
    return chipJSX
  }

  const rJSX_SowEstimatedDaysChips = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let ownerTypeJSX = <></>
    // if( task.status_green_days_cutoff != null && task.status_yellow_days_cutoff != null && task.status_red_days_cutoff != null ){
    if (task.status_green_days_cutoff != null && task.status_yellow_days_cutoff != null) {
      // let blackLabel = ( task.status_red_days_cutoff + 1 ) + "+"
      let redLabel = task.status_yellow_days_cutoff + 1 + '+'
      ownerTypeJSX = (
        <Box className="tw-inline-block">
          <Chip
            className="tw-cursor-pointer tw-mx-0.5"
            label={task.status_green_days_cutoff}
            variant="filled"
            color="success"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_EstimatedDays)
              }
            }}
          />
          <Chip
            className="tw-cursor-pointer tw-mx-0.5"
            label={task.status_yellow_days_cutoff}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_EstimatedDays)
              }
            }}
          />
          <Chip
            className="tw-cursor-pointer tw-mx-0.5"
            label={redLabel}
            variant="filled"
            color="error"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_EstimatedDays)
              }
            }}
          />
          {/* <Chip className="tw-cursor-pointer tw-mx-0.5" label={ blackLabel  } variant="filled" sx={{ background: themeVariables.palettes.custom_dark_red.main, "&:hover": { background: themeVariables.palettes.custom_dark_red.dark, opacity: 1 } }} onClick={ () => {
						if( editable === true ) {
							openSowTaskPropertyEditForm( task, formInputs_EstimatedDays )
						}
					} }/> */}
        </Box>
      )
    } else {
      ownerTypeJSX = (
        <Chip
          className="tw-cursor-pointer"
          label={rLIB('Not Specified')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_EstimatedDays)
            }
          }}
        />
      )
    }
    return ownerTypeJSX
  }

  const rJSX_AdditionalWorkEstimatedDaysChips = (
    task: TsInterface_UnspecifiedObject,
    editable: boolean,
    additionalWorkTasks: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    let chipJSX = <></>
    if (
      additionalWorkTasks != null &&
      additionalWorkTasks[task.key] != null &&
      additionalWorkTasks[task.key]['status_green_days_cutoff'] != null &&
      additionalWorkTasks[task.key]['status_yellow_days_cutoff'] != null
    ) {
      // Has been overriden
      chipJSX = (
        <Box>
          <Box>
            <Box className="tw-inline-block tw-bg-warning_dark tw-p-0.5 tw-m-1 tw-rounded">{rLIB('Override')}:</Box>
            <Icon
              icon="rotate-left"
              className="tw-ml-0.5 tw-cursor-pointer tw-opacity-30 tw-p-0.5 hover:tw-opacity-100"
              tooltip={rLIB('Revert to default')}
              tooltipPlacement="right"
              onClick={() => {
                if (editable === true) {
                  let updateObject = {
                    status_green_days_cutoff: DatabaseFieldDelete,
                    status_yellow_days_cutoff: DatabaseFieldDelete,
                  }
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseUpdateDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), updateObject)
                        .then((res_DSMD) => {
                          us_setLastEditedTaskKey(task.key)
                          us_setForceRefreshStagingData(new Date().getTime())
                        })
                        .catch((rej_DSMD) => {
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                }
              }}
            />
          </Box>
          {rJSX_SowEstimatedDaysChips(additionalWorkTasks[task.key], editable)}
        </Box>
      )
    } else {
      // Show Default
      chipJSX = (
        <Box>
          <Box className="tw-opacity-30 tw-p-0.5">{rLIB('Uses default')}:</Box>
          {rJSX_SowEstimatedDaysChips({ ...task }, editable)}
        </Box>
      )
    }
    return chipJSX
  }

  const rJSX_SowDispatcherChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let dispatcherTypeJSX = <></>
    if (task.associated_dispatcher_type != null) {
      if (
        us_taskOwnerOptions != null &&
        us_taskOwnerOptions[task.associated_dispatcher_type] != null &&
        us_taskOwnerOptions[task.associated_dispatcher_type]['value'] != null
      ) {
        dispatcherTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={us_taskOwnerOptions[task.associated_dispatcher_type]['value']}
            variant="filled"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskDispatcherType)
              }
            }}
          />
        )
      } else {
        dispatcherTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_dispatcher_type}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskDispatcherType)
              }
            }}
          />
        )
      }
    } else {
      dispatcherTypeJSX = (
        <Chip
          sx={{ maxWidth: '170px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_TaskDispatcherType)
            }
          }}
        />
      )
    }
    return dispatcherTypeJSX
  }

  const rJSX_SowTeamTypeGroupChip = (task: TsInterface_UnspecifiedObject, editable: boolean): JSX.Element => {
    let teamTypeJSX = <></>
    if (task.associated_team_type != null) {
      if (
        schedulingTeamTypes != null &&
        schedulingTeamTypes[task.associated_team_type] != null &&
        schedulingTeamTypes[task.associated_team_type]['value'] != null
      ) {
        teamTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={schedulingTeamTypes[task.associated_team_type]['value']}
            variant="filled"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskTeamType)
              }
            }}
          />
        )
      } else {
        teamTypeJSX = (
          <Chip
            sx={{ maxWidth: '170px' }}
            className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
            label={task.associated_team_type}
            variant="filled"
            color="warning"
            onClick={() => {
              if (editable === true) {
                openSowTaskPropertyEditForm(task, formInputs_TaskTeamType)
              }
            }}
          />
        )
      }
    } else {
      teamTypeJSX = (
        <Chip
          sx={{ maxWidth: '170px' }}
          className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
          label={rLIB('Not Selected')}
          variant="filled"
          color="error"
          onClick={() => {
            if (editable === true) {
              openSowTaskPropertyEditForm(task, formInputs_TaskTeamType)
            }
          }}
        />
      )
    }
    return teamTypeJSX
  }

  const rJSX_SowDelayReasonStarIcon = (task: TsInterface_UnspecifiedObject, delayReason: string, editable: boolean): JSX.Element => {
    let iconJSX = <></>
    if (task.default_delay_reason != null && task.default_delay_reason === delayReason) {
      iconJSX = (
        <Tooltip title={rLIB('Default Delay Reason')}>
          <Typography
            variant="body1"
            className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
            sx={{}}
            onClick={() => {
              if (editable === true) {
                let updateObject = {
                  default_delay_reason: null,
                }
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), updateObject)
                      .then((res_DSMD) => {
                        // setLastEditedTaskKey( task.key )
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          >
            <>
              <Icon
                icon="star"
                type="solid"
              ></Icon>
            </>
          </Typography>
        </Tooltip>
      )
    } else {
      iconJSX = (
        <Tooltip title={rLIB('Default Delay Reason')}>
          <Typography
            variant="body1"
            className="tw-mt-0 tw-cursor-pointer tw-inline-block tw-ml-2 tw-align-top"
            sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}
            onClick={() => {
              if (editable === true) {
                let updateObject = {
                  default_delay_reason: delayReason,
                }
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_TaskWorkflowSowItems_Document(res_GCK.clientKey, pr_workflowKey, task.key), updateObject)
                      .then((res_DSMD) => {
                        // setLastEditedTaskKey( task.key )
                      })
                      .catch((rej_DSMD) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }
            }}
          >
            <>
              <Icon
                icon="star"
                type="thin"
              ></Icon>
            </>
          </Typography>
        </Tooltip>
      )
    }
    return iconJSX
  }

  const rJSX_PublishToProdButton = () => {
    let publishButtonJSX = (
      <Button
        color="warning"
        variant="contained"
        className="tw-align-top tw-mr-2"
        startIcon={<Icon icon="paper-plane"></Icon>}
        onClick={() => {
          publishStagingToProductionWorkflow()
        }}
      >
        {rLIB('Publish staging to prod')}
      </Button>
    )
    return publishButtonJSX
  }

  const rJSX_ProdFlowChart = (): JSX.Element => {
    let prodTabJSX = <></>
    if (us_initialProdDataLoaded === true) {
      // Page JSX
      if (us_prodWorkflowTasks != null && us_prodWorkflowTasks.tasks != null && objectToArray(us_prodWorkflowTasks.tasks).length > 0) {
        prodTabJSX = (
          <Box className="tw-text-center tw-p-4">
            <Box>
              {objectToArray(returnTaskRows(us_prodWorkflowTasks.tasks, {}, us_prodRecursiveTasks, ['rJSX_ProdFlowChart']))
                .sort(dynamicSort('row', null))
                .map((row, rowIndex) => (
                  <Box key={rowIndex}>
                    {objectToArray(row.tasks)
                      .sort(dynamicSort('timestamp_created', null))
                      .map((task, taskIndex) => (
                        <Box
                          key={taskIndex}
                          className="tw-inline-block tw-align-top"
                        >
                          {rJSX_IndividualTask(task, rowIndex, false)}
                        </Box>
                      ))}
                    <Divider />
                  </Box>
                ))}
            </Box>
          </Box>
        )
      } else {
        prodTabJSX = (
          <Box className="tw-text-center">
            <Typography variant="h6">{rLIB('No production task workflow')}</Typography>
          </Box>
        )
      }
    } else {
      prodTabJSX = (
        <Box className="tw-p-4 tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    }
    return prodTabJSX
  }

  const rJSX_ProdTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_initialProdDataLoaded === true) {
      if (us_prodWorkflowTasks != null && us_prodWorkflowTasks.tasks != null && objectToArray(us_prodWorkflowTasks.tasks).length > 0) {
        let tableColumns: TsInterface_TableColumns = {}
        if (us_taskWorkflow.workflow_version_number === 2) {
          tableColumns = tableColumns_TaskTableV2
        } else {
          tableColumns = tableColumns_TaskTableV1
        }
        tableJSX = (
          <Box>
            <Card>
              <TableBasic
                tableAdditionalData={{
                  projectTasks: us_stagingWorkflowTasks,
                  lastEditedTaskKey: us_lastEditedTaskKey,
                  tableData: us_stagingWorkflowTasks,
                  editable: false,
                }}
                tableColumns={tableColumns}
                tableData={objectToArray(us_prodWorkflowTasks.tasks)}
                tableSettings={tableSettings_TaskTable}
              />
            </Card>
          </Box>
        )
      } else {
        // No tasks currently listed
        tableJSX = (
          <Box className="tw-text-center">
            <Typography variant="h6">{rLIB('No production task workflow')}</Typography>
          </Box>
        )
      }
    } else {
      tableJSX = (
        <Box className="tw-p-4 tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    }
    return tableJSX
  }

  const rJSX_ProdTaskTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_taskView === 'table') {
      tabJSX = <Box>{rJSX_ProdTable()}</Box>
    } else if (us_taskView === 'flowchart') {
      tabJSX = <Box>{rJSX_ProdFlowChart()}</Box>
    } else {
      tabJSX = (
        <Box className="tw-mt-2">
          <Stack
            direction="row"
            spacing={1}
            className="tw-mb-2"
          >
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<Icon icon="table-list"></Icon>}
              onClick={() => {
                us_setTaskView('table')
              }}
            >
              {rLIB('Table View')}
            </Button>
            <Button
              color="secondary"
              variant="outlined"
              startIcon={<Icon icon="sitemap"></Icon>}
              onClick={() => {
                us_setTaskView('flowchart')
              }}
            >
              {rLIB('Flowchart View')}
            </Button>
          </Stack>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_ConfigTab = (): JSX.Element => {
    let missingJSX = <Box className="tw-inline-block tw-italic tw-opacity-30">{rLIB('Missing')}</Box>
    let siteAuditTaskJSX = rLIB('Schedule Site Audit Task')
    if (us_taskWorkflow.workflow_version_number === 2) {
      siteAuditTaskJSX = rLIB('Site Audit Task')
    }
    let tabJSX = (
      <Card className="tw-p-4">
        <Typography variant="h6">
          {siteAuditTaskJSX}:{' '}
          <Box className="tw-inline-block tw-italic tw-opacity-30">
            {getProp(us_stagingConfigItems.site_audit, 'associated_dispatcher_task_name', missingJSX)}
          </Box>
          <Tooltip
            title={rLIB('Edit')}
            placement="right"
          >
            <Box
              className="tw-inline-block"
              onClick={() => {
                if (us_taskWorkflow.workflow_version_number === 2) {
                  openSiteAuditAssignmentV2TaskDialog(siteAuditTaskJSX as JSX.Element, 'site_audit')
                } else if (us_taskWorkflow.workflow_version_number === 1) {
                  openSiteAuditAssignmentTaskDialog(siteAuditTaskJSX as JSX.Element, 'site_audit')
                }
              }}
            >
              <Icon
                className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
                icon="pen-to-square"
              />
            </Box>
          </Tooltip>
        </Typography>
      </Card>
    )
    return tabJSX
  }

  const rJSX_ConfigTabHeader = (): JSX.Element => {
    let headerJSX = rLIB('Config') as JSX.Element
    if (
      us_stagingConfigItems == null ||
      us_stagingConfigItems.site_audit == null ||
      us_stagingConfigItems.site_audit.associated_dispatcher_task_name == null ||
      us_stagingWorkflowTasks == null ||
      us_stagingWorkflowTasks[us_stagingConfigItems.site_audit.associated_dispatcher_task_key] == null
    ) {
      headerJSX = (
        <Box>
          <Badge
            badgeContent={<Icon icon="triangle-exclamation" />}
            color="error"
          >
            {rLIB('Config')}
          </Badge>
        </Box>
      )
    } else if (
      us_stagingConfigItems == null ||
      us_stagingConfigItems.site_audit == null ||
      us_stagingConfigItems.site_audit.associated_dispatcher_task_name == null ||
      us_prodWorkflowTasks == null ||
      us_prodWorkflowTasks.tasks == null ||
      us_prodWorkflowTasks.tasks[us_stagingConfigItems.site_audit.associated_dispatcher_task_key] == null
    ) {
      headerJSX = (
        <Box>
          <Badge
            badgeContent={<Icon icon="triangle-exclamation" />}
            color="warning"
          >
            {rLIB('Config')}
          </Badge>
        </Box>
      )
    }
    return headerJSX
  }

  const rJSX_BackButton = (): JSX.Element => {
    return (
      <Button
        color="inherit"
        variant="outlined"
        className="tw-mr-2 tw-align-top"
        startIcon={<Icon icon="chevron-left"></Icon>}
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseTaskWorkflowListPage.url())
        }}
      >
        {rLIB('Back to all Workflows')}
      </Button>
    )
  }

  const returnTabsArray = (): TsInterface_TabContentUrlArray => {
    let tabsArray: TsInterface_TabContentUrlArray = []
    if (us_taskWorkflow.workflow_version_number === 2) {
      tabsArray = [
        {
          tabUrlKey: 'Draft_Tasks',
          tabHeader: rLIB('Draft Tasks'),
          tabButtons: [
            { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_AddTaskButton(), minJSX: rJSX_AddTaskButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_TableViewButtonGroup(), minJSX: rJSX_TableViewButtonGroup(), sizeCutoff: 0 },
          ],
          tabContent: <Box>{rJSX_StagingTaskTab()}</Box>,
        },
        {
          tabUrlKey: 'Draft_Additional_Work',
          tabHeader: rLIB('Draft Additional Work'),
          tabButtons: [
            { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
            {
              fullJSX: rJSX_TaskTypeSelector(us_additionalWorkTaskType, us_setAdditionalWorkTaskType, 'all'),
              minJSX: rJSX_TaskTypeSelector(us_additionalWorkTaskType, us_setAdditionalWorkTaskType, 'all'),
              sizeCutoff: 0,
            },
          ],
          tabContent: <Box>{rJSX_StagingAdditionalWorkTab()}</Box>,
        },
        {
          tabUrlKey: 'Published_Tasks',
          tabHeader: rLIB('Published Tasks'),
          tabButtons: [
            { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_PublishToProdButton(), minJSX: rJSX_PublishToProdButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_TableViewButtonGroup(), minJSX: rJSX_TableViewButtonGroup(), sizeCutoff: 0 },
          ],
          tabContent: <Box>{rJSX_ProdTaskTab()}</Box>,
        },
        {
          tabUrlKey: 'Published_Additional_Work',
          tabHeader: rLIB('Published Additional Work'),
          tabButtons: [
            { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
            {
              fullJSX: rJSX_TaskTypeSelector(us_additionalWorkTaskType, us_setAdditionalWorkTaskType, 'all'),
              minJSX: rJSX_TaskTypeSelector(us_additionalWorkTaskType, us_setAdditionalWorkTaskType, 'all'),
              sizeCutoff: 0,
            },
          ],
          tabContent: <Box>{rJSX_ProdAdditionalWorkTab()}</Box>,
        },
        {
          tabUrlKey: 'Config',
          tabHeader: rJSX_ConfigTabHeader(),
          tabButtons: [{ fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 }],
          tabContent: <Box>{rJSX_ConfigTab()}</Box>,
        },
      ]
    } else if (us_taskWorkflow.workflow_version_number === 1) {
      tabsArray = [
        {
          tabUrlKey: 'Staging',
          tabHeader: rLIB('Staging Tasks'),
          tabButtons: [
            { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_AddTaskButton(), minJSX: rJSX_AddTaskButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_TableViewButtonGroup(), minJSX: rJSX_TableViewButtonGroup(), sizeCutoff: 0 },
          ],
          tabContent: <Box>{rJSX_StagingTaskTab()}</Box>,
        },
        {
          tabUrlKey: 'Task_Groups',
          tabHeader: rLIB('Task Groups'),
          tabButtons: [
            { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_NewTaskGroupButton(), minJSX: rJSX_NewTaskGroupButton(), sizeCutoff: 0 },
          ],
          tabContent: <Box>{rJSX_TaskGroupsTab()}</Box>,
        },
        {
          tabUrlKey: 'SOW',
          tabHeader: rLIB('SOW'),
          tabButtons: [
            { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_NewSowTaskButton(), minJSX: rJSX_NewSowTaskButton(), sizeCutoff: 0 },
          ],
          tabContent: <Box>{rJSX_SowTab()}</Box>,
        },
        {
          tabUrlKey: 'Production',
          tabHeader: rLIB('Prod Tasks'),
          tabButtons: [
            { fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_PublishToProdButton(), minJSX: rJSX_PublishToProdButton(), sizeCutoff: 0 },
            { fullJSX: rJSX_TableViewButtonGroup(), minJSX: rJSX_TableViewButtonGroup(), sizeCutoff: 0 },
          ],
          tabContent: <Box>{rJSX_ProdTaskTab()}</Box>,
        },
        {
          tabUrlKey: 'Config',
          tabHeader: rJSX_ConfigTabHeader(),
          tabButtons: [{ fullJSX: rJSX_BackButton(), minJSX: rJSX_BackButton(), sizeCutoff: 0 }],
          tabContent: <Box>{rJSX_ConfigTab()}</Box>,
        },
      ]
    }
    return tabsArray
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              <TabsUrl
                tabs={returnTabsArray()}
                tabsSettings={tabsSettings}
              />
            </Box>
            <style>{workflowCss}</style>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

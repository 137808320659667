///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Tooltip,
} from '@mui/material/'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_TrailersFilterByGarage_Query,
  DatabaseRef_TrailersFilterByParkedAndGarage_Query,
  DatabaseRef_TrailersFilterByParked_Query,
  DatabaseRef_Trailers_Collection,
} from 'rfbp_aux/services/database_endpoints/directory/trailers'
import {
  DatabaseRef_VehiclesFilterByGarage_Query,
  DatabaseRef_VehiclesFilterByParkedAndGarage_Query,
  DatabaseRef_VehiclesFilterByParked_Query,
  DatabaseRef_Vehicles_Collection,
} from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import { DatabaseRef_ActiveVehicleMileageGarages_Query } from 'rfbp_aux/services/database_endpoints/operations/vehicle_mileage_logs'
import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'
import { TrailerAssignmentCustomDialog } from './dialogs/dialog_trailer_assign_button'
import { VehicleDriverAssignmentCustomDialog } from './dialogs/dialog_vehicle_assign_button'
import { formInputs_NewTrailer } from './forms/form_new_trailer'
import { formInputs_NewVehicle } from './forms/form_new_vehicle'
import { tableColumns_Trailers, tableSettings_Trailers } from './tables/table_trailers'
import { tableSettings_Filtered_Trailers } from './tables/table_trailer_filtered'
import { tableColumns_Vehicles, tableSettings_Vehicles } from './tables/table_vehcles'
import { tableSettings_Filtered_Vehicles } from './tables/table_vehicle_filtered'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseVehiclesListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const [us_activeGaragesList, us_setActiveGaragesList] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredVehicleData, us_setFilteredVehicleData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredTrailerData, us_setFilteredTrailerData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tableFilterActive, us_setTableFilterActive] = useState<string>('inactive')
  const [us_garageOptions, us_setGarageOptions] = useState<TsInterface_UnspecifiedObject>([])
  const [us_openFilterDialog, us_setOpenFilterDialog] = useState(false)
  const [us_tableFilters, us_setTableFilters] = useState<TsInterface_UnspecifiedObject>({
    fieldStatus: 'ALL',
    garageKey: 'ALL',
  })
  const [us_showDeleted, us_setShowDeleted] = useState<boolean>(false)
  const [us_openVehicleAssignmentDialog, us_setOpenVehicleAssignmentDialog] = useState(false)
  const [us_openTrailerAssignmentDialog, us_setOpenTrailerAssignmentDialog] = useState(false)

  const handleHideDeletedToggle = () => {
    us_setShowDeleted((prevHideDeleted) => !prevHideDeleted)
    us_setTableFilterActive('active')
  }

  // { sort-end } - hooks

  // Hooks - useEffect
  // Get Vehicles
  useEffect(() => {
    let unsubscribeLiveData: any
    const updateLiveData = (newData: any) => {
      let dataArray = Object.values(newData)

      if (us_showDeleted) {
        dataArray = dataArray.filter((item: any) => item.status === 'deleted')
      } else {
        dataArray = dataArray.filter((item: any) => item.status !== 'deleted')
      }
      const sortedData: any = dataArray.reduce((acc: any, item: any) => {
        acc[item.key] = item
        return acc
      }, {})

      us_setFilteredVehicleData(sortedData)
      ur_forceRerender()
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const clientKey = res_GCK.clientKey
        let vehicleQuery

        if (us_showDeleted) {
          vehicleQuery = DatabaseRef_Vehicles_Collection(clientKey)
        } else {
          let { garageKey, fieldStatus } = us_tableFilters
          if (fieldStatus === 'active') {
            fieldStatus = true
          } else if (fieldStatus === 'parked') {
            fieldStatus = false
          }
          if (garageKey !== 'ALL' && fieldStatus !== 'ALL') {
            vehicleQuery = DatabaseRef_VehiclesFilterByParkedAndGarage_Query(clientKey, garageKey, fieldStatus)
          } else if (garageKey !== 'ALL') {
            vehicleQuery = DatabaseRef_VehiclesFilterByGarage_Query(clientKey, garageKey)
          } else if (fieldStatus !== 'ALL') {
            vehicleQuery = DatabaseRef_VehiclesFilterByParked_Query(clientKey, fieldStatus)
          } else {
            vehicleQuery = DatabaseRef_Vehicles_Collection(clientKey)
            us_setTableFilterActive('inactive')
          }
        }

        if (vehicleQuery) {
          unsubscribeLiveData = DatabaseGetLiveCollection(vehicleQuery, updateLiveData)
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_tableFilterActive, us_tableFilters, us_showDeleted])

  // Get Trailers
  useEffect(() => {
    let unsubscribeLiveData: any
    const updateLiveData = (newData: any) => {
      let dataArray = Object.values(newData)

      if (us_showDeleted) {
        dataArray = dataArray.filter((item: any) => item.status === 'deleted')
      } else {
        dataArray = dataArray.filter((item: any) => item.status !== 'deleted')
      }
      const sortedData: any = dataArray.reduce((acc: any, item: any) => {
        acc[item.key] = item
        return acc
      }, {})

      us_setFilteredTrailerData(sortedData)
      ur_forceRerender()
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const clientKey = res_GCK.clientKey
        let trailerQuery

        if (us_showDeleted) {
          trailerQuery = DatabaseRef_Trailers_Collection(clientKey)
        } else {
          let { garageKey, fieldStatus } = us_tableFilters
          if (fieldStatus === 'active') {
            fieldStatus = true
          } else if (fieldStatus === 'parked') {
            fieldStatus = false
          }
          if (garageKey !== 'ALL' && fieldStatus !== 'ALL') {
            trailerQuery = DatabaseRef_TrailersFilterByParkedAndGarage_Query(clientKey, garageKey, fieldStatus)
          } else if (garageKey !== 'ALL') {
            trailerQuery = DatabaseRef_TrailersFilterByGarage_Query(clientKey, garageKey)
          } else if (fieldStatus !== 'ALL') {
            trailerQuery = DatabaseRef_TrailersFilterByParked_Query(clientKey, fieldStatus)
          } else {
            trailerQuery = DatabaseRef_Trailers_Collection(clientKey)
            us_setTableFilterActive('inactive')
          }
        }

        if (trailerQuery) {
          unsubscribeLiveData = DatabaseGetLiveCollection(trailerQuery, updateLiveData)
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_tableFilterActive, us_tableFilters, us_showDeleted])

  //Get garage options
  useEffect(() => {
    let unsubscribeLiveData: any

    const updateLiveData = (newData: any) => {
      const dataArray = Object.values(newData)
      const garageSet = new Set()
      const garages = dataArray
        .map((item: any) => {
          if (!garageSet.has(item.associated_garage_key)) {
            garageSet.add(item.associated_garage_key)
            return { key: item.associated_garage_key, value: item.associated_garage_name }
          }
          return null
        })
        .filter(Boolean)
      us_setGarageOptions(garages)
    }

    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        const clientKey = res_GCK.clientKey
        const vehicleQuery = DatabaseRef_Vehicles_Collection(clientKey)
        if (vehicleQuery) {
          unsubscribeLiveData = DatabaseGetLiveCollection(vehicleQuery, updateLiveData)
        }
      })
      .catch((rej_GCK) => {
        console.error('Error getting client key:', rej_GCK)
      })

    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'vehicles'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    document.title = 'Vehicles'
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveGaragesList(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveVehicleMileageGarages_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  // Functions
  const tableDatabaseEndpoint_Vehicles = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Vehicles_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_Trailers = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Trailers_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const createVehicle = (uc_setUserInterface_FormDialogDisplay: any): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewVehicle,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  const clientKey = res_GCK.clientKey
                  const vinToCheck = formSubmittedData.vin
                  DatabaseGetCollection(DatabaseRef_Vehicles_Collection(clientKey))
                    .then((vehicles) => {
                      const vehicleObjects = Object.values(vehicles)
                      const existingVINs = vehicleObjects.flatMap((vehicleObj: any) => {
                        const vehicleList = Object.values(vehicleObj)
                        return vehicleList
                          .map((vehicle: any) => {
                            const vin = vehicle?.vin
                            return vin
                          })
                          .filter((vin) => vin !== undefined)
                      })
                      if (existingVINs.includes(vinToCheck)) {
                        reject({
                          error: {
                            code: rLIB('DUPLICATE_VIN'),
                            message: rLIB('A vehicle with this VIN already exists.'),
                            details: `The VIN ${vinToCheck} is already registered in the database. Please use a different VIN.`,
                          },
                        })
                      } else {
                        formSubmittedData.status = 'active'
                        DatabaseAddDocument(DatabaseRef_Vehicles_Collection(clientKey), formSubmittedData, true)
                          .then((res_DSMD) => {
                            resolve({ success: true })
                            directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseVehicleViewPage.url(res_DSMD.key))
                          })
                          .catch((rej_DSMD) => {
                            reject(rej_DSMD)
                          })
                      }
                    })
                    .catch((error) => {
                      reject(error)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            }).catch((error) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Create Vehicle')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const createTrailer = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_NewTrailer,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  const clientKey = res_GCK.clientKey
                  const licensePlateToCheck = formSubmittedData.license_plate

                  DatabaseGetCollection(DatabaseRef_Trailers_Collection(clientKey))
                    .then((trailers) => {
                      const trailerObjects = Object.values(trailers)
                      const existingLicensePlates = trailerObjects.flatMap((trailerObj: any) => {
                        const trailerList = Object.values(trailerObj)
                        return trailerList
                          .map((trailer: any) => {
                            const licensePlate = trailer?.license_plate
                            return licensePlate
                          })
                          .filter((licensePlate) => licensePlate !== undefined)
                      })

                      if (existingLicensePlates.includes(licensePlateToCheck)) {
                        // License plate already exists
                        reject({
                          error: {
                            code: rLIB('DUPLICATE_LICENSE_PLATE'),
                            message: rLIB('A trailer with this license plate already exists.'),
                            details: `The license plate ${licensePlateToCheck} is already registered in the database. Please use a different license plate.`,
                          },
                        })
                      } else {
                        formSubmittedData.status = 'active'
                        DatabaseAddDocument(DatabaseRef_Trailers_Collection(clientKey), formSubmittedData, true)
                          .then((res_DSMD) => {
                            resolve({ success: true })
                            directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseTrailerViewPage.url(res_DSMD.key))
                          })
                          .catch((rej_DSMD) => {
                            reject(rej_DSMD)
                          })
                      }
                    })
                    .catch((error) => {
                      reject(error)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            }).catch((error) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error.error })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Create Trailer')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_MileageReportsButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        className="tw-mr-2"
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseVehicleUsageReportsPage.url())
        }}
        disableElevation
        startIcon={
          <Icon
            icon="road"
            type="solid"
          />
        }
      >
        {rLIB('Vehicle Usage Reports')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AssignVehicleButton = (): JSX.Element => {
    return (
      <>
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            us_setOpenVehicleAssignmentDialog(true)
          }}
          disableElevation
          className="tw-mr-2"
          startIcon={
            <Icon
              icon="pen-to-square"
              type="solid"
            />
          }
        >
          {rLIB('Assign Vehicle')}
        </Button>
      </>
    )
  }

  const rJSX_AssignmentDialog = () => {
    return (
      <>
        {us_openVehicleAssignmentDialog && (
          <VehicleDriverAssignmentCustomDialog
            onClose={() => {
              us_setOpenVehicleAssignmentDialog(false)
            }}
            uc_RootData_ClientKey={uc_RootData_ClientKey}
          />
        )}
      </>
    )
  }

  const rJSX_trailerAssignmentDialog = () => {
    return (
      <>
        {us_openTrailerAssignmentDialog && (
          <TrailerAssignmentCustomDialog
            onClose={() => {
              us_setOpenTrailerAssignmentDialog(false)
            }}
            uc_RootData_ClientKey={uc_RootData_ClientKey}
          />
        )}
      </>
    )
  }

  const rJSX_AssignTrailerButton = () => {
    return (
      <>
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            us_setOpenTrailerAssignmentDialog(true)
          }}
          disableElevation
          className="tw-mr-2"
          startIcon={
            <Icon
              icon="pen-to-square"
              type="solid"
            />
          }
        >
          {rLIB('Assign Trailer')}
        </Button>
      </>
    )
  }

  // Vehicles
  const rJSX_NewVehicleButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createVehicle(uc_setUserInterface_FormDialogDisplay)
        }}
        disableElevation
        className="tw-mr-2"
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Vehicle')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_VehicleTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_tableFilterActive == 'inactive') {
      if (uc_RootData_ClientKey != null) {
        // if (tableSettings_Vehicles.search_settings_database == null) {
        //   tableSettings_Vehicles.search_settings_database = { search_type: 'firebase' }
        // }
        // tableSettings_Vehicles.search_settings_database.search_endpoint = tableSearchEndpoint_Vehicles
        if (tableSettings_Vehicles.search_settings_database == null) {
          tableSettings_Vehicles.search_settings_database = { search_type: 'meilisearch' }
        }
        tableSettings_Vehicles.search_settings_database.search_client_key = uc_RootData_ClientKey
        tableJSX = (
          <Box>
            <Card className="">
              <TableDatabase
                tableAdditionalData={{ us_activeGaragesList: us_activeGaragesList }}
                tableColumns={tableColumns_Vehicles}
                tableDatabaseEndpoint={tableDatabaseEndpoint_Vehicles}
                tableSettings={tableSettings_Vehicles}
              />
            </Card>
          </Box>
        )
      }
    } else {
      if (us_tableFilterActive == 'active') {
        tableJSX = (
          <Box>
            {rJSX_tableFilterLabel()}
            {rJSX_tableFilterDeletedLabel()}
            <Card className="">
              <TableBasic
                tableAdditionalData={{ us_activeGaragesList: us_activeGaragesList }}
                tableColumns={tableColumns_Vehicles}
                tableData={objectToArray(us_filteredVehicleData)}
                tableSettings={tableSettings_Filtered_Vehicles}
              />
            </Card>
          </Box>
        )
      }
    }
    return tableJSX
  }

  // Trailers
  const rJSX_NewTrailerButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createTrailer()
        }}
        disableElevation
        className="tw-mr-2"
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {rLIB('New Trailer')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_TrailerTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_tableFilterActive == 'inactive') {
      if (uc_RootData_ClientKey != null) {
        // if (tableSettings_Trailers.search_settings_database == null) {
        //   tableSettings_Trailers.search_settings_database = { search_type: 'firebase' }
        // }
        // tableSettings_Trailers.search_settings_database.search_endpoint = tableSearchEndpoint_Trailers
        if (tableSettings_Trailers.search_settings_database == null) {
          tableSettings_Trailers.search_settings_database = { search_type: 'meilisearch' }
        }
        tableSettings_Trailers.search_settings_database.search_client_key = uc_RootData_ClientKey
        tableJSX = (
          <Box>
            <Card className="">
              <TableDatabase
                tableAdditionalData={{ us_activeGaragesList: us_activeGaragesList }}
                tableColumns={tableColumns_Trailers}
                tableDatabaseEndpoint={tableDatabaseEndpoint_Trailers}
                tableSettings={tableSettings_Trailers}
              />
            </Card>
          </Box>
        )
      }
    } else {
      tableJSX = (
        <Box>
          {rJSX_tableFilterLabel()}
          {rJSX_tableFilterDeletedLabel()}

          <Card className="">
            <TableBasic
              tableAdditionalData={{ us_activeGaragesList: us_activeGaragesList }}
              tableColumns={tableColumns_Trailers}
              tableData={objectToArray(us_filteredTrailerData)}
              tableSettings={tableSettings_Filtered_Trailers}
            />
          </Card>
        </Box>
      )
    }
    return tableJSX
  }
  const rJSX_tableFilterDeletedLabel = (): JSX.Element => {
    let labelJSX = <></>
    if (us_showDeleted === true) {
      labelJSX = (
        <Chip
          color="error"
          variant="filled"
          className="tw-mt-2"
          label={
            <Box>
              <Icon
                icon="trash"
                className="tw-mr-2"
              />
              {rLIB('Deleted')}
            </Box>
          }
        />
      )
    }

    return labelJSX
  }

  const rJSX_tableFilterLabel = (): JSX.Element => {
    let labelJSX = <></>
    if (us_showDeleted) {
      return <></>
    }
    if (us_tableFilters.fieldStatus === 'ALL' && us_tableFilters.garageKey === 'ALL') {
      return <></>
    }
    let statusLabel = 'All'
    if (us_tableFilters.fieldStatus === 'active') {
      statusLabel = 'Active'
    } else if (us_tableFilters.fieldStatus === 'parked') {
      statusLabel = 'Parked'
    }
    const garageLabel = us_garageOptions.find((option: any) => option.key === us_tableFilters.garageKey)?.value || 'All Garages'
    labelJSX = (
      <Chip
        color="secondary"
        variant="filled"
        className="tw-mt-2"
        label={
          <Box>
            <Icon
              icon="filter"
              className="tw-mr-2"
            />
            {`${statusLabel} - ${garageLabel}`}
          </Box>
        }
      />
    )
    return labelJSX
  }

  const rJSX_VehicleStatusFilterButton = () => {
    const handleOpen = () => us_setOpenFilterDialog(true)
    const handleClose = () => us_setOpenFilterDialog(false)

    const updateFilterSelections = (filterProp: string, filterValue: string) => {
      us_setTableFilters((prevState) => {
        return { ...prevState, [filterProp]: filterValue }
      })
      us_setTableFilterActive('active')
    }

    const handleReset = () => {
      us_setTableFilters({
        fieldStatus: 'ALL',
        garageKey: 'ALL',
      })
      us_setShowDeleted(false)
      us_setTableFilterActive('inactive')
    }

    const statusOptions = [
      { key: 'ALL', value: rLIB('Active or Parked') },
      { key: 'active', value: rLIB('Active') },
      { key: 'parked', value: rLIB('Parked') },
    ]

    return (
      <>
        <Button
          variant={us_tableFilterActive === 'active' ? 'contained' : 'outlined'}
          color="secondary"
          className=""
          startIcon={<Icon icon="filter" />}
          onClick={handleOpen}
        >
          {rLIB('Filter')}
        </Button>

        {us_tableFilterActive === 'active' && (
          <Tooltip title={rLIB('Reset Filters')}>
            <IconButton
              color="secondary"
              className="tw-ml-0"
              onClick={handleReset}
            >
              <Icon icon="times-circle" />
            </IconButton>
          </Tooltip>
        )}

        <Dialog
          open={us_openFilterDialog}
          onClose={handleClose}
          className="bp_dialog_lg_width"
        >
          <DialogTitle>{rLIB('Vehicle and Trailer Filters')}</DialogTitle>
          <Divider></Divider>
          <DialogContent>
            <FormGroup>
              <FormControl
                className="tw-mb-6"
                component="fieldset"
              >
                <FormLabel component="legend">{rLIB('Field Status')}</FormLabel>
                <RadioGroup
                  value={getProp(us_tableFilters, 'fieldStatus', '') || 'ALL'}
                  onChange={(e) => {
                    updateFilterSelections('fieldStatus', e.target.value)
                  }}
                >
                  {statusOptions.map((option) => (
                    <FormControlLabel
                      key={option.key.toString()}
                      value={option.key}
                      control={<Radio />}
                      label={option.value}
                    />
                  ))}
                </RadioGroup>
              </FormControl>

              <FormControl
                className="tw-mb-6"
                component="fieldset"
              >
                <FormLabel component="legend">{rLIB('Garage')}</FormLabel>
                <RadioGroup
                  value={getProp(us_tableFilters, 'garageKey', '') || 'ALL'}
                  onChange={(e) => {
                    updateFilterSelections('garageKey', e.target.value)
                  }}
                >
                  <FormControlLabel
                    value="ALL"
                    control={<Radio />}
                    label={rLIB('All Garages')}
                  />
                  {us_garageOptions.map((option: any) => (
                    <FormControlLabel
                      key={option.key}
                      value={option.key}
                      control={<Radio />}
                      label={option.value}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Divider></Divider>
              <FormControl className="tw-pt-4">
                <FormControlLabel
                  label={rLIB('Only Show Deleted')}
                  control={
                    <Switch
                      checked={us_showDeleted}
                      onChange={handleHideDeletedToggle}
                      color="primary"
                      name="hideDeletedToggle"
                      inputProps={{ 'aria-label': 'Show Deleted Toggle' }}
                    />
                  }
                />
              </FormControl>
            </FormGroup>
          </DialogContent>
        </Dialog>
      </>
    )
  }

  // Page
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Vehicles')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminDatabaseVehiclesListPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: 'Vehicles',
              }}
              tabs={[
                {
                  tabUrlKey: 'Vehicles',
                  tabHeader: rLIB('Vehicles'),
                  tabContent: rJSX_VehicleTable(),
                  tabButtons: [
                    { fullJSX: rJSX_MileageReportsButton(), minJSX: rJSX_MileageReportsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_AssignVehicleButton(), minJSX: rJSX_AssignVehicleButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_NewVehicleButton(), minJSX: rJSX_NewVehicleButton(), sizeCutoff: 0 },

                    { fullJSX: rJSX_VehicleStatusFilterButton(), minJSX: rJSX_VehicleStatusFilterButton(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'Trailers',
                  tabHeader: rLIB('Trailers'),
                  tabContent: rJSX_TrailerTable(),
                  tabButtons: [
                    { fullJSX: rJSX_MileageReportsButton(), minJSX: rJSX_MileageReportsButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_AssignTrailerButton(), minJSX: rJSX_AssignTrailerButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_NewTrailerButton(), minJSX: rJSX_NewTrailerButton(), sizeCutoff: 0 },

                    { fullJSX: rJSX_VehicleStatusFilterButton(), minJSX: rJSX_VehicleStatusFilterButton(), sizeCutoff: 0 },
                  ],
                },
              ]}
            />
            {rJSX_AssignmentDialog()}
            {rJSX_trailerAssignmentDialog()}
          </Box>
        }
      />
    )

    return pageJSX
  }

  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import { PDFViewer } from '@react-pdf/renderer'
import React, { useEffect } from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { generatePdfFromData, TsInterface_PdfTemplate } from 'rfbp_core/components/pdf/custom'
import { LayeredPdfEditor } from 'rfbp_core/components/pdf/overlayed'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
// @ts-expect-error - TODO: reason for error
import logoSrc from '../../../app/images/logos/final_logo_horizontal.png'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// const styles = StyleSheet.create({
//   page: {
//     flexDirection: 'column',
//     paddingTop: '0in',
//     paddingBottom: '0in',
//     paddingLeft: '0in',
//     paddingRight: '0in',
//   },
//   header: {
//     backgroundColor: '#919196',
//     width: '100%',
//     height: '0.5in',
//   },
//   contents: {
//     paddingTop: '0.5in',
//     paddingBottom: '0.5in',
//     paddingLeft: '0.5in',
//     paddingRight: '0.5in',
//   },
//   // label: {
//   //   // width: '2.625in', // Width of each label
//   //   width: '2.625in', // Width of each label
//   //   height: '1in', // Height of each label
//   //   marginVertical: 0, // No margin on top and bottom
//   //   marginHorizontal: '0.1875in', // Margin on left and right
//   //   padding: 0,
//   //   border: '0px solid #FFF', // Border for each label
//   //   borderRadius: 8, // Rounded corners
//   //   justifyContent: 'center',
//   //   fontSize: 10,
//   //   flexDirection: 'row', // Arrange QR code and address side by side
//   // },
//   // address: {
//   //   flex: 1,
//   //   padding: 8,
//   //   textAlign: 'left',
//   // },
//   // row: {
//   //   flexDirection: 'row',
//   //   marginBottom: '0.0in', // Space between rows
//   // },
//   // qrCode: {
//   //   width: '1in',
//   //   height: '1in',
//   //   marginRight: 5,
//   // },
//   table: {
//     display: 'flex',
//     width: 'auto',
//     // borderStyle: 'solid',
//     // borderWidth: 1,
//     borderRightWidth: 0,
//     borderBottomWidth: 0,
//   },
//   tableRow: {
//     flexDirection: 'row',
//   },
//   tableCol: {
//     width: '25%', // adjust the percentage based on the number of columns
//     borderStyle: 'solid',
//     // borderWidth: 1,
//     borderLeftWidth: 0,
//     borderTopWidth: 0,
//   },
//   tableCell: {
//     margin: 5,
//     fontSize: 10,
//   },
// })

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminInvoiceIndexPage']['key']

// PDF Generation

const examplePdfTemplate: TsInterface_PdfTemplate = {
  page_settings: {
    flexDirection: 'column',
    paddingTop: '0in',
    paddingBottom: '0in',
    paddingLeft: '0in',
    paddingRight: '0in',
  },
  page_contents: {
    COLOR_HEADER: {
      component_type: 'static_table',
      table_style: {
        display: 'flex',
        width: 'auto',
      },
      rows: {
        ROW_1: {
          row_style: {
            flexDirection: 'row',
          },
          cells: {
            CELL_1: {
              cell_style: {
                backgroundColor: '#929598',
                width: '100%',
                height: '0.5in',
              },
              contents: {
                PLACEHOLDER: {
                  component_type: 'static_text',
                  text: '',
                },
              },
            },
          },
        },
      },
    },
    CONTENT_VIEW: {
      component_type: 'view',
      view_style: {
        paddingTop: '0.5in',
        paddingBottom: '0.5in',
        paddingLeft: '0.5in',
        paddingRight: '0.5in',
      },
      contents: {
        LOGO_HEADER: {
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 'auto',
          },
          rows: {
            ROW1: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                LOGO_CELL: {
                  cell_style: {
                    width: '60%',
                  },
                  contents: {
                    LOGO: {
                      component_type: 'static_image',
                      image_style: {
                        width: '2.5in',
                      },
                      src: logoSrc,
                    },
                  },
                },
                INVOICE_CELL: {
                  cell_style: {
                    width: '40%',
                  },
                  contents: {
                    INVOICE_TEXT: {
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 24,
                        textAlign: 'right',
                        color: '#929598',
                        marginBottom: '10px',
                      },
                      text: 'INVOICE',
                    },
                    TABLE: {
                      component_type: 'static_table',
                      table_style: {
                        display: 'flex',
                        width: 'auto',
                        textAlign: 'right',
                      },
                      rows: {
                        ROW1: {
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              cell_style: {
                                width: '65%',
                                padding: 2,
                              },
                              contents: {
                                INVOICE_NUMBER_LABEL: {
                                  component_type: 'static_text',
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'INVOICE NUMBER',
                                },
                              },
                            },
                            CELL2: {
                              cell_style: {
                                width: '35%',
                                padding: 2,
                              },
                              contents: {
                                INVOICE_NUMBER: {
                                  component_type: 'variable_text',
                                  text_style: {
                                    fontSize: 10,
                                    color: '#000000',
                                  },
                                  variable_mapping_key: 'invoice_number',
                                },
                              },
                            },
                          },
                        },
                        ROW2: {
                          row_style: {
                            flexDirection: 'row',
                          },
                          cells: {
                            CELL1: {
                              cell_style: {
                                width: '65%',
                                padding: 2,
                              },
                              contents: {
                                INVOICE_DATE_LABEL: {
                                  component_type: 'static_text',
                                  text_style: {
                                    fontSize: 10,
                                    textAlign: 'right',
                                    color: '#BBBBBB',
                                  },
                                  text: 'DATE OF ISSUE ',
                                },
                              },
                            },
                            CELL2: {
                              cell_style: {
                                width: '35%',
                                padding: 2,
                              },
                              contents: {
                                INVOICE_DATE: {
                                  component_type: 'variable_text',
                                  text_style: {
                                    fontSize: 10,
                                  },
                                  variable_mapping_key: 'invoice_date',
                                  fallback_text: 'MISSING',
                                  fallback_text_style: {
                                    fontSize: 10,
                                    color: '#FF0000',
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ADDRESS_TABLE: {
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 'auto',
          },
          rows: {
            ROW1: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '25%',
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      text_style: {
                        marginTop: '5px',
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: '3331 N. PanAm Expy',
                    },
                  },
                },
                CELL2: {
                  cell_style: {
                    width: '25%',
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      text_style: {
                        marginTop: '5px',
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'Accounts Payable',
                    },
                  },
                },
              },
            },
            ROW2: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '25%',
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      text_style: {
                        marginTop: '5px',
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'San Antonio, TX',
                    },
                  },
                },
                CELL2: {
                  cell_style: {
                    width: '25%',
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      text_style: {
                        marginTop: '5px',
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'ap@etwenergy.com',
                    },
                  },
                },
              },
            },
            ROW3: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '25%',
                  },
                  contents: {
                    ADDRESS_LABEL: {
                      component_type: 'static_text',
                      text_style: {
                        marginTop: '5px',
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: '78219',
                    },
                  },
                },
                CELL2: {
                  cell_style: {
                    width: '25%',
                  },
                  contents: {
                    ADDRESS: {
                      component_type: 'static_text',
                      text_style: {
                        marginTop: '5px',
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: '801-864-1503',
                    },
                  },
                },
              },
            },
          },
        },
        BILLED_TO_TABLE: {
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 'auto',
          },
          rows: {
            ROW1: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '100%',
                    padding: 2,
                    marginTop: '16px',
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#BBBBBB',
                      },
                      text: 'BILLED TO',
                    },
                  },
                },
              },
            },
            ROW2: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '100%%',
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE: {
                      component_type: 'variable_text',
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'client_name',
                      fallback_text: 'MISSING',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW3: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '100%%',
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE: {
                      component_type: 'variable_text',
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'client_street_address',
                      fallback_text: 'MISSING',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW4: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '100%%',
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE: {
                      component_type: 'variable_text',
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'client_cite_state',
                      fallback_text: 'MISSING',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
            ROW5: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '100%%',
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE: {
                      component_type: 'variable_text',
                      text_style: {
                        fontSize: 10,
                        color: '#444444',
                      },
                      variable_mapping_key: 'client_zip',
                      fallback_text: 'MISSING',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        JOB_CODE: {
          component_type: 'variable_text',
          text_style: {
            fontSize: 14,
            color: '#55D3FE',
            marginTop: '20px',
            marginBottom: '8px',
          },
          variable_mapping_key: 'job_code',
          fallback_text: 'MISSING',
          fallback_text_style: {
            fontSize: 14,
            color: '#FF0000',
            marginTop: '20px',
            marginBottom: '8px',
          },
        },
        LINE_ITEMS_TABLE: {
          component_type: 'looped_data_table',
          table_style: {
            display: 'flex',
            width: 'auto',
          },
          row_style: {
            flexDirection: 'row',
          },
          table_data_variable_mapping_key: 'line_items',
          columns: {
            COL1: {
              column_header_style: {
                width: '40%',
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: '40%',
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'DESCRIPTION',
              variable_mapping_key: 'item_description',
              fallback_text: 'MISSING',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL2: {
              column_header_style: {
                width: '20%',
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: '20%',
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'RATE ($)',
              variable_mapping_key: 'item_rate',
              fallback_text: 'MISSING',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL3: {
              column_header_style: {
                width: '20%',
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: '20%',
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'ADDERS ($)',
              variable_mapping_key: 'item_adders',
              fallback_text: 'MISSING',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
            COL4: {
              column_header_style: {
                width: '20%',
                backgroundColor: '#929598',
                color: 'white',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              column_body_style: {
                width: '20%',
                backgroundColor: '#F3F3F3',
                fontSize: 10,
                padding: 8,
                marginBottom: 4,
              },
              header_text: 'AMOUNT ($)',
              variable_mapping_key: 'item_total',
              fallback_text: 'MISSING',
              fallback_text_style: {
                color: '#FF0000',
              },
            },
          },
        },
        NOTES_TABLE: {
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 'auto',
          },
          rows: {
            ROW1: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '6%',
                    padding: 2,
                    marginTop: '6px',
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#000000',
                      },
                      text: 'Notes:',
                    },
                  },
                },
                CELL2: {
                  cell_style: {
                    width: '94%',
                    padding: 2,
                    marginTop: '6px',
                  },
                  contents: {
                    NOTES: {
                      component_type: 'variable_text',
                      text_style: {
                        fontSize: 10,
                        fontWeight: 'bold',
                      },
                      variable_mapping_key: 'line_item_notes',
                      fallback_text: 'MISSING',
                      fallback_text_style: {
                        fontSize: 10,
                        color: '#FF0000',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        TOTAL_TABLE: {
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 'auto',
            marginTop: '20px',
          },
          rows: {
            ROW1: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '100%',
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'right',
                        color: '#BBBBBB',
                      },
                      text: 'INVOICE TOTAL',
                    },
                  },
                },
              },
            },
            ROW2: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '100%',
                    padding: 2,
                  },
                  contents: {
                    NOTES: {
                      component_type: 'variable_text',
                      text_style: {
                        fontSize: 16,
                        fontWeight: 'bold',
                        textAlign: 'right',
                      },
                      variable_mapping_key: 'line_item_total',
                      fallback_text: 'MISSING',
                      fallback_text_style: {
                        fontSize: 16,
                        color: '#FF0000',
                        textAlign: 'right',
                      },
                    },
                  },
                },
              },
            },
          },
        },
        TERMS_TABLE: {
          component_type: 'static_table',
          table_style: {
            display: 'flex',
            width: 'auto',
            marginTop: '40px',
          },
          rows: {
            ROW1: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '100%',
                    padding: 2,
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: 'NET10',
                    },
                  },
                },
              },
            },
            ROW2: {
              row_style: {
                flexDirection: 'row',
              },
              cells: {
                CELL1: {
                  cell_style: {
                    width: '100%',
                    padding: 2,
                    borderBottom: '1px solid #444444',
                  },
                  contents: {
                    INVOICE_DATE_LABEL: {
                      component_type: 'static_text',
                      text_style: {
                        fontSize: 10,
                        textAlign: 'left',
                        color: '#444444',
                      },
                      text: ' ',
                    },
                  },
                },
              },
            },
          },
        },
      },
    },
  },
}

const pdfData = {
  invoice_number: 'INV-0001',
  invoice_date: '08/16/2024',
  client_name: "Test Customer's Name",
  client_street_address: '1234 Test St',
  client_cite_state: 'Test City, Test State',
  client_zip: '12345',
  job_code: '353R-123TEST',
  line_items: [
    {
      item_description: 'Test Description 1',
      item_rate: '$100.00',
      item_adders: '$100.00',
      item_total: '$200.00',
    },
    {
      item_description: 'Test Description 2',
      item_rate: '$50.00',
      item_adders: '$50.00',
      item_total: '$100.00',
    },
  ],
  line_item_total: '$300.00',
  line_item_notes: 'Test Notes',
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  // const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = 		useContext( Context_RootData_ClientKey )
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Invoicing', false) as string
  }, [])

  // Functions

  // JSX Generation
  const rJSX_PdfContent = (): JSX.Element => {
    let pdfJSX = (
      <Box sx={{ width: '100%', height: 'calc( 100vh - 120px )' }}>
        <PDFViewer style={{ width: '100%', height: '100%' }}>{generatePdfFromData(examplePdfTemplate, pdfData)}</PDFViewer>
      </Box>
    )
    return pdfJSX
  }

  const rJSX_PdfTab = (): JSX.Element => {
    let tabJSX = <>{rJSX_PdfContent()}</>
    return tabJSX
  }

  const rJSX_Pdf2Content = (): JSX.Element => {
    let tabJSX = (
      <>
        {LayeredPdfEditor({
          pdfUrl:
            'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/temp%2Fpdf%20test.pdf?alt=media&token=b7a9ea87-166d-466d-a65f-40a1337623d1',
          initialAnnotations: [
            {
              x: 100,
              y: 400,
              text: 'Test Annotation 1',
              page: 1,
            },
            {
              x: 170,
              y: 142,
              text: 'Test Annotation 2a',
              page: 2,
            },
            {
              x: 10,
              y: 10,
              text: 'Test Annotation 2b',
              page: 2,
            },
          ],
        })}
      </>
    )

    return tabJSX
  }

  const rJSX_Pdf2Tab = (): JSX.Element => {
    let tabJSX = <>{rJSX_Pdf2Content()}</>

    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Invoicing')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.AdminInvoiceIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('PDF', false) as string,
              }}
              tabs={[
                {
                  tabUrlKey: 'PDF',
                  tabOnChange: () => {},
                  tabHeader: rLIB('PDF'),
                  tabContent: <Box>{rJSX_PdfTab()}</Box>,
                  tabButtons: [
                    // { fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(true), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'PDF2',
                  tabOnChange: () => {},
                  tabHeader: 'PDF2',
                  tabContent: <Box>{rJSX_Pdf2Tab()}</Box>,
                  tabButtons: [
                    // { fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(true), sizeCutoff: 0 },
                  ],
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_SalesHierarchyUser_Collection = (clientKey: string, salesPartnerKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'sales_hierarchies', salesPartnerKey)
}

export const DatabaseRef_SalesHierarchyUser_Document = (clientKey: string, salesPartnerKey: string, userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'sales_hierarchies', salesPartnerKey, userKey)
}

///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_JurisdictionFile = (clientKey: string, jurisdictionKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/jurisdictions/' + jurisdictionKey + '/files/' + fileName)
}

export const StorageRef_Jurisdiction_MessageThread_File = (
  clientKey: string,
  jurisdictionKey: string,
  threadKey: string,
  fileName: string,
): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/jurisdictions/' + jurisdictionKey + '/threads/' + threadKey + '/files/' + fileName)
}

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Chip, Tooltip } from '@mui/material'
import { DatabaseRef_PayrollRates_UnitPay_Document } from 'rfbp_aux/services/database_endpoints/finances/payroll_rates'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { formatCurrency, getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Functions
///////////////////////////////

const openUnitPayPropertyEditForm = (
  regionKey: string,
  unitPayRow: TsInterface_UnspecifiedObject,
  unitPayKey: string,
  unitPayRoleKey: string,
  unitPayValue: number | null,
  uc_setUserInterface_ErrorDialogDisplay: any,
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
): void => {
  let employeeText = rLIB('Employees')
  if (unitPayRow.employee_count === 1) {
    employeeText = rLIB('Employee')
  }
  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: {},
        formData: {
          unit_pay: unitPayValue,
        },
        formInputs: {
          unit_pay: {
            key: 'unit_pay',
            label: rLIB('Unit Pay'),
            input_type: 'text_number',
            required: true,
            data_type: 'number',
          },
        },
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: {},
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            let updateObject: TsInterface_UnspecifiedObject = {
              [regionKey]: {
                [unitPayKey]: {
                  [unitPayRoleKey]: formSubmittedData['unit_pay'],
                },
              },
            }
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                DatabaseSetMergeDocument(DatabaseRef_PayrollRates_UnitPay_Document(res_GCK.clientKey), updateObject)
                  .then((res_DSMD) => {
                    resolve({ success: true })
                  })
                  .catch((rej_DSMD) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                  })
              })
              .catch((rej_GCK) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: (
          <>
            {rLIB('Edit Unit Pay')} - {unitPayRow.name} ({unitPayRow.employee_count} {employeeText})
          </>
        ),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

const rJSX_UnitPayRatesChips = (
  rowData: TsInterface_TableDataRow,
  tableAdditionalData: TsInterface_TableAdditionalData,
  tableHooks: TsInterface_TableHooks,
  roleKey: string,
  roleNumber: number,
): JSX.Element => {
  // Default Value
  let defaultChipJSX = <></>
  let regionChipJSX = <></>
  let deleteRegionOverideIconJSX = <></>
  if (rowData != null && rowData['employee_count'] != null && getProp(rowData, 'employee_count', 0) < roleNumber) {
    // Nothing
  } else {
    // Region Overrides
    let defaultChipOpacity = '1'
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData['us_selectedRegion'] != null &&
      tableAdditionalData['us_selectedRegion'] !== ''
    ) {
      if (
        rowData != null &&
        rowData.key != null &&
        tableAdditionalData != null &&
        tableAdditionalData.us_payrollRates != null &&
        tableAdditionalData.us_payrollRates['unit_pay'] != null &&
        tableAdditionalData.us_payrollRates['unit_pay'][tableAdditionalData['us_selectedRegion']] != null &&
        tableAdditionalData.us_payrollRates['unit_pay'][tableAdditionalData['us_selectedRegion']][rowData.key as string] != null &&
        tableAdditionalData.us_payrollRates['unit_pay'][tableAdditionalData['us_selectedRegion']][rowData.key as string][roleKey] != null
      ) {
        defaultChipOpacity = '.3'
        let unitPayRateValue = tableAdditionalData.us_payrollRates['unit_pay'][tableAdditionalData['us_selectedRegion']][rowData.key as string][roleKey]
        regionChipJSX = (
          <Box className="tw-inline-block tw-ml-2">
            <Tooltip
              title={getProp(tableAdditionalData, 'us_selectedRegionName', rLIB('Region Overrides'))}
              placement="right"
            >
              <Chip
                sx={{ maxWidth: '190px' }}
                className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
                label={formatCurrency(unitPayRateValue, 'USD', 'en-US')}
                color="warning"
                variant="filled"
                onClick={() => {
                  if (getProp(tableAdditionalData, 'editable', false) === true) {
                    openUnitPayPropertyEditForm(
                      tableAdditionalData['us_selectedRegion'],
                      rowData,
                      getProp(rowData, 'key', null),
                      roleKey,
                      tableAdditionalData.us_payrollRates['unit_pay'][tableAdditionalData['us_selectedRegion']][rowData.key as string][roleKey],
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                      tableHooks.uc_setUserInterface_FormDialogDisplay,
                      tableHooks.uc_RootData_ClientKey,
                      tableHooks.uc_setRootData_ClientKey,
                    )
                  }
                }}
              />
            </Tooltip>
          </Box>
        )
        deleteRegionOverideIconJSX = (
          <Box
            className="tw-inline-block"
            sx={{ fontSize: '20px', verticalAlign: 'top', marginTop: '2px' }}
          >
            <Icon
              icon="circle-xmark"
              tooltip={rLIB('Delete Region Override')}
              tooltipPlacement="right"
              className="tw-ml-2 tw-cursor-pointer tw-opacity-30 hover:tw-opacity-100 hover:tw-text-error_main"
              onClick={() => {
                let updateObject: TsInterface_UnspecifiedObject = {
                  [tableAdditionalData['us_selectedRegion']]: {
                    [rowData.key as string]: {
                      [roleKey]: null,
                    },
                  },
                }
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    DatabaseSetMergeDocument(DatabaseRef_PayrollRates_UnitPay_Document(res_GCK.clientKey), updateObject)
                      .then((res_DSMD) => {
                        // resolve({success: true})
                      })
                      .catch((rej_DSMD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              }}
            />
          </Box>
        )
      } else {
        regionChipJSX = (
          <Box
            className="tw-inline-block tw-ml-2"
            sx={{ opacity: defaultChipOpacity }}
          >
            <Tooltip
              title={getProp(tableAdditionalData, 'us_selectedRegionName', rLIB('Region Overrides'))}
              placement="right"
            >
              <Chip
                sx={{ maxWidth: '190px' }}
                className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer tw-opacity-30"
                label={rLIB('No Region Override')}
                variant="outlined"
                color="default"
                onClick={() => {
                  if (getProp(tableAdditionalData, 'editable', false) === true) {
                    openUnitPayPropertyEditForm(
                      tableAdditionalData['us_selectedRegion'],
                      rowData,
                      getProp(rowData, 'key', null),
                      roleKey,
                      null,
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                      tableHooks.uc_setUserInterface_FormDialogDisplay,
                      tableHooks.uc_RootData_ClientKey,
                      tableHooks.uc_setRootData_ClientKey,
                    )
                  }
                }}
              />
            </Tooltip>
          </Box>
        )
      }
    }
    // Default Chip
    if (
      rowData != null &&
      rowData.key != null &&
      tableAdditionalData != null &&
      tableAdditionalData.us_payrollRates != null &&
      tableAdditionalData.us_payrollRates['unit_pay'] != null &&
      tableAdditionalData.us_payrollRates['unit_pay']['DEFAULT'] != null &&
      tableAdditionalData.us_payrollRates['unit_pay']['DEFAULT'][rowData.key as string] != null &&
      tableAdditionalData.us_payrollRates['unit_pay']['DEFAULT'][rowData.key as string][roleKey] != null
    ) {
      let unitPayRateValue = tableAdditionalData.us_payrollRates['unit_pay']['DEFAULT'][rowData.key as string][roleKey]
      defaultChipJSX = (
        <Box
          className="tw-inline-block"
          sx={{ opacity: defaultChipOpacity }}
        >
          <Tooltip
            title={rLIB('Default Rate')}
            placement="left"
          >
            <Chip
              sx={{ maxWidth: '190px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={formatCurrency(unitPayRateValue, 'USD', 'en-US')}
              variant="filled"
              onClick={() => {
                if (getProp(tableAdditionalData, 'editable', false) === true) {
                  openUnitPayPropertyEditForm(
                    'DEFAULT',
                    rowData,
                    getProp(rowData, 'key', null),
                    roleKey,
                    tableAdditionalData.us_payrollRates['unit_pay']['DEFAULT'][rowData.key as string][roleKey],
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                    tableHooks.uc_setUserInterface_FormDialogDisplay,
                    tableHooks.uc_RootData_ClientKey,
                    tableHooks.uc_setRootData_ClientKey,
                  )
                }
              }}
            />
          </Tooltip>
        </Box>
      )
    } else {
      defaultChipJSX = (
        <Box
          className="tw-inline-block"
          sx={{ opacity: defaultChipOpacity }}
        >
          <Tooltip
            title={rLIB('Default Rate')}
            placement="left"
          >
            <Chip
              sx={{ maxWidth: '190px' }}
              className="tw-text-ellipsis tw-overflow-hidden tw-cursor-pointer"
              label={rLIB('Not Set')}
              variant="filled"
              color="error"
              onClick={() => {
                if (getProp(tableAdditionalData, 'editable', false) === true) {
                  openUnitPayPropertyEditForm(
                    'DEFAULT',
                    rowData,
                    getProp(rowData, 'key', null),
                    roleKey,
                    null,
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay,
                    tableHooks.uc_setUserInterface_FormDialogDisplay,
                    tableHooks.uc_RootData_ClientKey,
                    tableHooks.uc_setRootData_ClientKey,
                  )
                }
              }}
            />
          </Tooltip>
        </Box>
      )
    }
  }
  // Cell JSX
  let cellJSX = (
    <Box>
      {defaultChipJSX}
      {regionChipJSX}
      {deleteRegionOverideIconJSX}
    </Box>
  )
  return cellJSX
}

///////////////////////////////
// Exports
///////////////////////////////

export const tableColumns_UnitPayRates: TsInterface_TableColumns = {
  abbreviation: TableCellBasic('abbreviation', rLIB('Work Type'), 'abbreviation'),
  employee_count: TableCellBasic('employee_count', rLIB('Employees'), 'employee_count'),
  unit_type: TableCellBasic('unit_type', rLIB('Unit Type'), 'unit_type'),
  TEMP_foreman: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <Box className="tw-font-bold tw-opacity-30">{rLIB('Foreman')}</Box>
      },
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_UnitPayRatesChips(rowData, tableAdditionalData, tableHooks, 'foreman', 1)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_lead: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <Box className="tw-font-bold tw-opacity-30">{rLIB('Lead or Helper')}</Box>
      },
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_UnitPayRatesChips(rowData, tableAdditionalData, tableHooks, 'lead', 2)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (rowData != null && rowData['employee_count'] != null && getProp(rowData, 'employee_count', 0) < 2) {
          cellCSS = 'tw-bg-background_json'
        }
        return cellCSS
      },
    },
  },
  TEMP_helper1: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <Box className="tw-font-bold tw-opacity-30">{rLIB('Helper 1')}</Box>
      },
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_UnitPayRatesChips(rowData, tableAdditionalData, tableHooks, 'helper1', 3)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (rowData != null && rowData['employee_count'] != null && getProp(rowData, 'employee_count', 0) < 3) {
          cellCSS = 'tw-bg-background_json'
        }
        return cellCSS
      },
    },
  },
  TEMP_helper2: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <Box className="tw-font-bold tw-opacity-30">{rLIB('Helper 2')}</Box>
      },
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        return rJSX_UnitPayRatesChips(rowData, tableAdditionalData, tableHooks, 'helper2', 4)
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        if (rowData != null && rowData['employee_count'] != null && getProp(rowData, 'employee_count', 0) < 4) {
          cellCSS = 'tw-bg-background_json'
        }
        return cellCSS
      },
    },
  },
}

export const tableSettings_UnitPayRates: TsInterface_TableSettings = {
  size: 'small',
  sortable: false,
  sort_property_default: null,
  paginated: false,
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 290px)',
}

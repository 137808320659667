///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { formInputs_HardcodedBonus, formInputs_PayrollOrganizationCode, formInputs_PayrollUserId, hourlyOrSalariedOptions } from 'app/models/users/user_form'
import { rJSX_UserRoleChip } from 'app/models/users/user_table'
import { DatabaseRef_LifetimePanels_Document } from 'rfbp_aux/services/database_endpoints/finances/lifetime_panels'
import { DatabaseRef_LifetimeReferrals_Document } from 'rfbp_aux/services/database_endpoints/finances/lifetime_referrals'
import { DatabaseRef_LifetimeUnits_Document } from 'rfbp_aux/services/database_endpoints/finances/lifetime_units'
import { DatabaseRef_PayrollRates_EmployeeBaseRates_Document } from 'rfbp_aux/services/database_endpoints/finances/payroll_rates'
import { DatabaseRef_ClientUser_Document } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { dynamicSort, formatCurrency, getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Exports
///////////////////////////////

export const tableSettings_EmployeePayrollSettings: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  sticky_header: true,
  sticky_table_height: 'calc(100vh - 350px)',
}

export const tableColumns_EmployeePayrollSettings: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Employee'), 'name'),
  user_role: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('User Type')
      },
      header_sort_by: 'user_role',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <Box>{rJSX_UserRoleChip(rowData)}</Box>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  // hourly_or_salaried: TableCellBasic( "hourly_or_salaried", rLIB("Hourly or Salaried"), "hourly_or_salaried" ),
  hourly_or_salaried: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Hourly or Salaried')
      },
      header_sort_by: 'hourly_or_salaried',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let editIconJSX = (
          <Box
            className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
            onClick={() => {
              tableAdditionalData.editHourlyOrSalaried(rowData.key, rowData)
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        )
        if (rowData != null && rowData.hourly_or_salaried != null) {
          if (
            hourlyOrSalariedOptions != null &&
            hourlyOrSalariedOptions[rowData.hourly_or_salaried as string] != null &&
            hourlyOrSalariedOptions[rowData.hourly_or_salaried as string]['value'] != null
          ) {
            cellJSX = (
              <Box>
                <Box className="tw-inline-block">{hourlyOrSalariedOptions[rowData.hourly_or_salaried as string]['value']}</Box>
                {editIconJSX}
              </Box>
            )
          } else {
            cellJSX = (
              <Box>
                <Box className="tw-inline-block">{rowData.hourly_or_salaried}</Box>
                {editIconJSX}
              </Box>
            )
          }
        } else {
          cellJSX = (
            <Box>
              <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  default_location_state: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Default Minimum Wage State')
      },
      header_sort_by: 'default_location_state',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let editIconJSX = (
          <Box
            className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
            onClick={() => {
              tableAdditionalData.editDefaultState(rowData.key, rowData)
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        )
        if (rowData != null && rowData.default_location_state != null) {
          cellJSX = (
            <Box>
              <Box className="tw-inline-block">{rowData.default_location_state}</Box>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_base_pay: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Base Hourly Pay')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // Display
        let payValueJSX = <></>
        let payValueNumber: null | number = null
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_payrollRates != null &&
          tableAdditionalData.us_payrollRates['employee_base_rates'] != null &&
          tableAdditionalData.us_payrollRates['employee_base_rates'][rowData.key as string] != null
        ) {
          payValueJSX = (
            <Box className="tw-inline-block">
              {formatCurrency(tableAdditionalData.us_payrollRates['employee_base_rates'][rowData.key as string], 'USD', 'en-US')}
            </Box>
          )
          payValueNumber = tableAdditionalData.us_payrollRates['employee_base_rates'][rowData.key as string]
        } else {
          payValueJSX = <Box className="tw-inline-block tw-opacity-30 tw-italic">{rLIB('Missing')}</Box>
        }
        // Edit
        let editIconJSX = (
          <Icon
            icon="pen-to-square"
            className="tw-ml-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-success_main"
            tooltip={rLIB('Edit')}
            tooltipPlacement="right"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {
                      base_pay: payValueNumber,
                    },
                    formInputs: {
                      base_pay: {
                        data_type: 'number',
                        input_type: 'text_number',
                        key: 'base_pay',
                        label: rLIB('Base Hourly Pay'),
                        required: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject: TsInterface_UnspecifiedObject = {
                              [rowData.key as string]: parseFloat(formSubmittedData['base_pay']),
                            }
                            DatabaseSetMergeDocument(DatabaseRef_PayrollRates_EmployeeBaseRates_Document(res_GCK.clientKey), updateObject)
                              .then((res_SMD) => {
                                resolve(res_SMD)
                              })
                              .catch((rej_SMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SMD.error })
                                reject(rej_SMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rowData.name} - {rLIB('Base Hourly Pay')}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        // Full
        let cellJSX = (
          <Box>
            {payValueJSX}
            {editIconJSX}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  id_number_payroll: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Payroll User ID')
      },
      header_sort_by: 'id_number_payroll',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let editIconJSX = (
          <Box
            className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: rowData,
                    formInputs: formInputs_PayrollUserId,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), formSubmittedData)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                reject(rej_DSMD)
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              })
                          })
                          .catch((rej_GCK) => {
                            reject(rej_GCK)
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Edit User')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        )
        if (rowData != null && rowData.id_number_payroll != null) {
          cellJSX = (
            <Box>
              <Box className="tw-inline-block">{rowData.id_number_payroll}</Box>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  id_organization_payroll: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Payroll Organization ID')
      },
      header_sort_by: 'id_organization_payroll',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let editIconJSX = (
          <Box
            className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: rowData,
                    formInputs: formInputs_PayrollOrganizationCode,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), formSubmittedData)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                reject(rej_DSMD)
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              })
                          })
                          .catch((rej_GCK) => {
                            reject(rej_GCK)
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Edit User')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        )
        if (rowData != null && rowData.id_organization_payroll != null) {
          cellJSX = (
            <Box>
              <Box className="tw-inline-block">{rowData.id_organization_payroll}</Box>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  hardcoded_bonus: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Hardcoded Payroll Bonus')
      },
      header_sort_by: 'hardcoded_bonus',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let editIconJSX = (
          <Box
            className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-ml-2 tw-cursor-pointer"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: rowData,
                    formInputs: formInputs_HardcodedBonus,
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, rowData.key as string), formSubmittedData)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                reject(rej_DSMD)
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                              })
                          })
                          .catch((rej_GCK) => {
                            reject(rej_GCK)
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Edit User')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          >
            <Icon icon="pen-to-square" />
          </Box>
        )
        if (rowData != null && rowData.hardcoded_bonus != null) {
          cellJSX = (
            <Box>
              <Box className="tw-inline-block">{formatCurrency(rowData.hardcoded_bonus as number)}</Box>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  lifetime_panels_before_cutoff: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Lifetime panels before cutoff')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let editIconJSX = (
          <Icon
            icon="pen-to-square"
            className="tw-ml-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-success_main"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {},
                    formInputs: {
                      before_cutoff: {
                        data_type: 'number',
                        input_type: 'text_number',
                        key: 'before_cutoff',
                        label: rLIB('Lifetime panels before cutoff'),
                        required: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          before_cutoff: formSubmittedData.before_cutoff,
                        }
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_LifetimePanels_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve({ success: true })
                              })
                              .catch((rej_DSMD) => {
                                reject({ success: false, error: rej_DSMD.error })
                              })
                          })
                          .catch((rej_GCK) => {
                            reject({ success: false, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rowData.name} - {rLIB('Lifetime panels before cutoff')}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        if (
          tableAdditionalData != null &&
          tableAdditionalData['us_lifetimePanelData'] != null &&
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData['us_lifetimePanelData'][rowData.key as string] != null &&
          tableAdditionalData['us_lifetimePanelData'][rowData.key as string]['before_cutoff'] != null
        ) {
          cellJSX = (
            <Box>
              <Box className="tw-inline-block">{tableAdditionalData['us_lifetimePanelData'][rowData.key as string]['before_cutoff']}</Box>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  lifetime_units_before_cutoff: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Lifetime units before cutoff')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let editIconJSX = (
          <Icon
            icon="pen-to-square"
            className="tw-ml-2 tw-opacity-30 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-success_main"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {},
                    formInputs: {
                      before_cutoff: {
                        data_type: 'number',
                        input_type: 'text_number',
                        key: 'before_cutoff',
                        label: rLIB('Lifetime panels before cutoff'),
                        required: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          before_cutoff: formSubmittedData.before_cutoff,
                        }
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(DatabaseRef_LifetimeUnits_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve({ success: true })
                              })
                              .catch((rej_DSMD) => {
                                reject({ success: false, error: rej_DSMD.error })
                              })
                          })
                          .catch((rej_GCK) => {
                            reject({ success: false, error: rej_GCK.error })
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rowData.name} - {rLIB('Lifetime panels before cutoff')}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        if (
          tableAdditionalData != null &&
          tableAdditionalData['us_lifetimeUnitData'] != null &&
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData['us_lifetimeUnitData'][rowData.key as string] != null &&
          tableAdditionalData['us_lifetimeUnitData'][rowData.key as string]['before_cutoff'] != null
        ) {
          cellJSX = (
            <Box>
              <Box className="tw-inline-block">{tableAdditionalData['us_lifetimeUnitData'][rowData.key as string]['before_cutoff']}</Box>
              {editIconJSX}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Box className="tw-opacity-30 tw-italic tw-inline-block">{rLIB('Missing')}</Box>
              {editIconJSX}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_referrer: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Referrer (For Lifetime Bonus)')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <>{rowData.key}</>
        let editIcon = (
          <Icon
            icon="pen-to-square"
            tooltip={rLIB('Edit')}
            tooltipPlacement="left"
            className="tw-opacity-30 tw-ml-2 tw-cursor-pointer hover:tw-opacity-100 hover:tw-text-success_main"
            onClick={() => {
              // Generate Form Inputs
              let referrerOptions: TsInterface_UnspecifiedObject[] = []
              for (let loopUserKey in getProp(tableAdditionalData, 'us_activeHourlyUsers', {})) {
                if (getProp(tableAdditionalData.us_activeHourlyUsers[loopUserKey], 'name', null) != null) {
                  if (loopUserKey === rowData.key) {
                    referrerOptions.push({
                      key: loopUserKey,
                      value: getProp(tableAdditionalData.us_activeHourlyUsers[loopUserKey], 'name', ''),
                      disabled: true,
                    })
                  } else {
                    referrerOptions.push({
                      key: loopUserKey,
                      value: getProp(tableAdditionalData.us_activeHourlyUsers[loopUserKey], 'name', ''),
                    })
                  }
                }
              }
              referrerOptions = referrerOptions.sort(dynamicSort('value', 'asc'))
              // Open Form Dialog
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {},
                    formData: {},
                    formInputs: {
                      associated_referrer_key: {
                        data_type: 'string',
                        input_type: 'multiple_choice_select',
                        key: 'associated_referrer_key',
                        label: rLIB('Referrer'),
                        required: true,
                        options: referrerOptions,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        console.log(formSubmittedData)
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = {
                              [rowData.key as string]: {
                                associated_referrer_key: formSubmittedData.associated_referrer_key,
                                associated_referrer_name: getProp(
                                  referrerOptions.find((option) => option.key === formSubmittedData.associated_referrer_key),
                                  'value',
                                  '',
                                ),
                              },
                            }
                            console.log(updateObject)
                            DatabaseSetMergeDocument(DatabaseRef_LifetimeReferrals_Document(res_GCK.clientKey), updateObject)
                              .then((res_DAD) => {
                                resolve(res_DAD)
                              })
                              .catch((rej_DAD) => {
                                reject(rej_DAD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Edit Referrer for')} {rowData.name}
                      </>
                    ),
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        if (
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.us_lifetimeReferrals != null &&
          tableAdditionalData.us_lifetimeReferrals[rowData.key as string] != null &&
          tableAdditionalData.us_lifetimeReferrals[rowData.key as string]['associated_referrer_key'] != null &&
          tableAdditionalData.us_lifetimeReferrals[rowData.key as string]['associated_referrer_name'] != null
        ) {
          cellJSX = (
            <Box>
              <Box className="tw-inline-block ">{tableAdditionalData.us_lifetimeReferrals[rowData.key as string]['associated_referrer_name']}</Box>
              {editIcon}
            </Box>
          )
        } else {
          cellJSX = (
            <Box>
              <Box className="tw-inline-block tw-opacity-30 tw-italic">{rLIB('No referrer')}</Box>
              {editIcon}
            </Box>
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

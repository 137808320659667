///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const taskFormIconOptions: TsInterface_UnspecifiedObject = {
  'none': {
    key: 'none',
    value: (
      <Box sx={{ color: themeVariables.gray_700 }}>
        <Icon
          size="lg"
          icon="circle"
        />
      </Box>
    ),
  },
  'ruler-combined': {
    key: 'ruler-combined',
    value: (
      <Box sx={{ color: themeVariables.info_main }}>
        <Icon
          size="lg"
          icon="ruler-combined"
        />
      </Box>
    ),
  },
  'signature': {
    key: 'signature',
    value: (
      <Box sx={{ color: themeVariables.primary_main }}>
        <Icon
          size="lg"
          icon="signature"
        />
      </Box>
    ),
  },
  'phone': {
    key: 'phone',
    value: (
      <Box sx={{ color: themeVariables.success_light }}>
        <Icon
          size="lg"
          icon="phone"
        />
      </Box>
    ),
  },
  'yellow-signature': {
    key: 'yellow-signature',
    value: (
      <Box sx={{ color: themeVariables.warning_dark }}>
        <Icon
          size="lg"
          icon="signature"
        />
      </Box>
    ),
  },
  'sun': {
    key: 'sun',
    value: (
      <Box sx={{ color: themeVariables.warning_dark }}>
        <Icon
          size="lg"
          icon="sun"
        />
      </Box>
    ),
  },
  'calendar-day': {
    key: 'calendar-day',
    value: (
      <Box sx={{ color: themeVariables.error_main }}>
        <Icon
          size="lg"
          icon="calendar-day"
        />
      </Box>
    ),
  },
}

export const alertSeverityIconOptions: TsInterface_UnspecifiedObject = {
  none: {
    key: 'none',
    value: (
      <Box sx={{ color: themeVariables.gray_700 }}>
        <Icon
          size="lg"
          icon="circle"
        />
      </Box>
    ),
  },
  bomb: {
    key: 'bomb',
    value: (
      <Box sx={{ color: themeVariables.black }}>
        <Icon
          size="lg"
          icon="bomb"
        />
      </Box>
    ),
  },
  error: {
    key: 'error',
    value: (
      <Box sx={{ color: themeVariables.error_main }}>
        <Icon
          size="lg"
          icon="siren-on"
        />
      </Box>
    ),
  },
  stop: {
    key: 'stop',
    value: (
      <Box sx={{ color: themeVariables.orange_500 }}>
        <Icon
          size="lg"
          icon="hexagon-exclamation"
        />
      </Box>
    ),
  },
  warning: {
    key: 'warning',
    value: (
      <Box sx={{ color: themeVariables.warning_main }}>
        <Icon
          size="lg"
          icon="triangle-exclamation"
        />
      </Box>
    ),
  },
  info: {
    key: 'info',
    value: (
      <Box sx={{ color: themeVariables.info_main }}>
        <Icon
          size="lg"
          icon="square-info"
        />
      </Box>
    ),
  },
  thumbs_up: {
    key: 'thumbs_up',
    value: (
      <Box sx={{ color: themeVariables.teal_700 }}>
        <Icon
          size="lg"
          icon="thumbs-up"
        />
      </Box>
    ),
  },
  success: {
    key: 'success',
    value: (
      <Box sx={{ color: themeVariables.success_main }}>
        <Icon
          size="lg"
          icon="circle-check"
        />
      </Box>
    ),
  },
}

export const delayStatusOptions: TsInterface_UnspecifiedObject = {
  // not_started: {
  // 	key: "not_started",
  // 	value: s_NOT_STARTED,
  // 	icon: <Box className="tw-inline-block" sx={{ color: themeVariables.gray_700 }}><Icon icon="circle-pause" /></Box>
  // },
  // in_progress: {
  // 	key: "in_progress",
  // 	value: s_IN_PROGRESS,
  // 	icon: <Box className="tw-inline-block" sx={{ color: themeVariables.success_main }}><Icon icon="thumbs-up" /></Box>
  // },
  no_roadblock: {
    key: 'no_roadblock',
    value: rLIB('No Current Roadblocks'),
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.success_main }}
      >
        <Icon
          icon="thumbs-up"
          size="lg"
        />
      </Box>
    ),
  },
  roadblock: {
    key: 'roadblock',
    value: rLIB('Roadblocks'),
    icon: (
      <Box
        className="tw-inline-block"
        sx={{ color: themeVariables.error_main }}
      >
        <Icon
          icon="octagon-exclamation"
          size="lg"
        />
      </Box>
    ),
  },
  // complete:{
  // 	key: "complete",
  // 	value: s_COMPLETE,
  // 	icon: <Box className="tw-inline-block" sx={{ color: themeVariables.info_main }}><Icon icon="circle-check" /></Box>
  // },
}

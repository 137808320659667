//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Used to generate Confirm Dialogs

		TODO:
			[ ] Typescript - 2 instances of any - function copied from MUI Docs
			[ ] Typescript - 1 instance of unknown
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, IconButton, Slide, Stack, Toolbar, Typography } from '@mui/material/'
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'
import { Trans } from 'react-i18next'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  TsInterface_ConfirmDialogObject,
  TsInterface_UserInterface_ConfirmDialogInternalState,
  TsType_UserInterface_AllDialogsDismissCallback,
} from 'rfbp_core/services/context'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsType_MuiAppBarColors } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ARE_YOU_SURE_YOU_WANT_TO_CONFIRM: JSX.Element = <Trans>Are you sure you want to confirm</Trans>
const s_CONFIRM: JSX.Element = <Trans>Confirm</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  )
})

///////////////////////////////
// Component
///////////////////////////////

export const ConfirmDialog = (
  confirm: TsInterface_ConfirmDialogObject,
  dismissDialog: TsType_UserInterface_AllDialogsDismissCallback,
  ConfirmDialogState: TsInterface_UserInterface_ConfirmDialogInternalState,
  setConfirmDialogState: React.Dispatch<React.SetStateAction<TsInterface_UserInterface_ConfirmDialogInternalState>>,
): JSX.Element => {
  // Props
  let pr_confirmColor: TsInterface_ConfirmDialogObject['color'] = getProp(confirm, 'color', 'success')
  let pr_confirmHeader: TsInterface_ConfirmDialogObject['header'] = getProp(confirm, 'header', s_CONFIRM)
  let pr_confirmIcon: TsInterface_ConfirmDialogObject['icon'] = getProp(confirm, 'icon', <Icon icon="square-check" />)
  let pr_confirmSubmit: TsInterface_ConfirmDialogObject['submit_text'] = getProp(confirm, 'submit_text', s_CONFIRM)
  let pr_confirmText: TsInterface_ConfirmDialogObject['text'] = getProp(confirm, 'text', s_ARE_YOU_SURE_YOU_WANT_TO_CONFIRM)
  let pr_submitCallback: TsInterface_ConfirmDialogObject['submit_callback'] = getProp(confirm, 'submit_callback', () => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  })

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const handleClose = (): void => {
    dismissDialog()
  }

  const handleSubmit = (): void => {
    setConfirmDialogState({ confirming: true })
    pr_submitCallback!()
      .then(() => {
        setConfirmDialogState({ confirming: false })
        dismissDialog()
      })
      .catch(() => {
        setConfirmDialogState({ confirming: false })
        dismissDialog()
      })
  }

  // JSX Generation
  const rJSX_DialogActions = (): JSX.Element => {
    let dialogActionsJSX
    if (ConfirmDialogState != null && ConfirmDialogState['confirming'] === true) {
      dialogActionsJSX = (
        <Stack
          spacing={1}
          direction="row"
        >
          <Button
            color={pr_confirmColor}
            disabled
            onClick={handleClose}
            variant="outlined"
          >
            {rLIB('Dismiss')}
          </Button>
          <Button
            color={pr_confirmColor}
            disabled
            onClick={handleSubmit}
            variant="contained"
            startIcon={
              <Icon
                icon="arrows-rotate"
                className="bp_spin"
              />
            }
          >
            {pr_confirmSubmit}
          </Button>
        </Stack>
      )
    } else {
      dialogActionsJSX = (
        <Stack
          spacing={1}
          direction="row"
        >
          <Button
            color={pr_confirmColor}
            onClick={handleClose}
            variant="outlined"
          >
            {rLIB('Dismiss')}
          </Button>
          <Button
            color={pr_confirmColor}
            onClick={handleSubmit}
            variant="contained"
          >
            {pr_confirmSubmit}
          </Button>
        </Stack>
      )
    }
    return dialogActionsJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <Dialog
          // container={() => document.getElementById('root')}
          open={true}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="confirm-dialog-title"
          aria-describedby="confirm-dialog-description"
          className="bp_dialog_md_width"
        >
          <AppBar
            position="static"
            color={pr_confirmColor as TsType_MuiAppBarColors}
          >
            <Toolbar>
              <IconButton
                disabled
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                {pr_confirmIcon}
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {pr_confirmHeader}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Box>
              <Typography
                variant="subtitle1"
                display="block"
              >
                {pr_confirmText}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>{rJSX_DialogActions()}</DialogActions>
        </Dialog>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

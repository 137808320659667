///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_SunrunLead_Collection = (clientKey: string, collectionKey: 'prod' | 'staging'): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_leads', collectionKey)
}

export const DatabaseRef_SunrunLead_Document = (clientKey: string, leadKey: string, collectionKey: 'prod' | 'staging'): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_leads', collectionKey, leadKey)
}

export const DatabaseRef_SunrunLeadPayment_Collection = (clientKey: string, collectionKey: 'prod' | 'staging'): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_lead_payments', collectionKey)
}

export const DatabaseRef_SunrunLeadPayment_Document = (
  clientKey: string,
  leadPaymentKey: string,
  collectionKey: 'prod' | 'staging',
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_lead_payments', collectionKey, leadPaymentKey)
}

export const DatabaseRef_SunrunProposal_Collection = (clientKey: string, collectionKey: 'prod' | 'staging'): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_proposal', collectionKey)
}

export const DatabaseRef_SunrunProposal_Document = (
  clientKey: string,
  proposalId: string,
  collectionKey: 'prod' | 'staging',
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_proposal', collectionKey, proposalId)
}

export const DatabaseRef_SunrunProposalByProspectId_Document = (
  clientKey: string,
  prospectId: string,
  collectionKey: 'prod' | 'staging',
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_proposal_by_prospect_id', collectionKey, prospectId)
}

export const DatabaseRef_SunrunServiceContact_Collection = (clientKey: string, collectionKey: 'prod' | 'staging'): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_service_contract', collectionKey)
}

export const DatabaseRef_SunrunServiceContact_Document = (
  clientKey: string,
  serviceContractKey: string,
  collectionKey: 'prod' | 'staging',
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_service_contract', collectionKey, serviceContractKey)
}

export const DatabaseRef_SunrunPastQueue_Collection = (clientKey: string, collectionKey: 'prod' | 'staging'): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_past_queue', collectionKey)
}

export const DatabaseRef_SunrunPastQueue_Document = (
  clientKey: string,
  messageKey: string,
  collectionKey: 'prod' | 'staging',
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_past_queue', collectionKey, messageKey)
}

export const DatabaseRef_SunrunUnhandledQueue_Collection = (clientKey: string, collectionKey: 'prod' | 'staging'): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_unhandled_queue', collectionKey)
}

export const DatabaseRef_SunrunUnhandledQueue_Document = (
  clientKey: string,
  messageKey: string,
  collectionKey: 'prod' | 'staging',
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_unhandled_queue', collectionKey, messageKey)
}

export const DatabaseRef_SunrunProposalAssets_Document = (clientKey: string, proposalId: string, collectionKey: 'prod' | 'staging') => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_proposal_assets', collectionKey, proposalId)
}

export const DatabaseRef_SunrunProposalAssetsByProspectId_Document = (clientKey: string, prospectId: string, collectionKey: 'prod' | 'staging') => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_proposal_assets_by_prospect_id', collectionKey, prospectId)
}

export const DatabaseRef_SunrunProposalDocs_Document = (clientKey: string, proposalId: string, collectionKey: 'prod' | 'staging') => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_proposal_docs', collectionKey, proposalId)
}

export const DatabaseRef_SunrunProposalDocsByProspectId_Document = (clientKey: string, prospectId: string, collectionKey: 'prod' | 'staging') => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'sunrun_proposal_docs_by_prospect_id', collectionKey, prospectId)
}

import { Box, Tooltip, Typography } from '@mui/material/'
import { Stack } from '@mui/system'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { TsInterface_FeedbackData, TsInterface_FeedbackGraphSettings } from './feedback_types'

interface TsInterface_ComponentProps {
  feedbackData: TsInterface_FeedbackData
  feedbackSettings: TsInterface_FeedbackGraphSettings
}

interface TsInterface_NpsData {
  promoters: number
  passives: number
  detractors: number
  total: number
  score: number
}

const s_DETRACTORS: JSX.Element = <Trans>Detractors</Trans>
const s_PASSIVES: JSX.Element = <Trans>Passives</Trans>

const emptyNpsData = {
  promoters: 0,
  passives: 0,
  detractors: 0,
  total: 0,
  score: 0,
}

export const FeedbackNpsGraph = (props: TsInterface_ComponentProps): JSX.Element => {
  const pr_feedbackData: TsInterface_ComponentProps['feedbackData'] = getProp(props, 'feedbackData', {})
  const pr_feedbackSettings: TsInterface_ComponentProps['feedbackSettings'] = getProp(props, 'feedbackSettings', {})

  const [us_npsData, us_setNpsData] = useState<TsInterface_NpsData>(emptyNpsData)

  useEffect(() => {
    let newNpsData = {
      promoters: 0,
      passives: 0,
      detractors: 0,
      total: 0,
      score: 0,
    }
    for (let loopFeedbackKey in pr_feedbackData) {
      let loopFeedbackItem = pr_feedbackData[loopFeedbackKey]
      if (loopFeedbackItem != null && loopFeedbackItem.feedback_rating != null) {
        if (loopFeedbackItem.feedback_rating === 9 || loopFeedbackItem.feedback_rating === 10) {
          newNpsData['promoters']++
          newNpsData['total']++
        } else if (loopFeedbackItem.feedback_rating === 7 || loopFeedbackItem.feedback_rating === 8) {
          newNpsData['passives']++
          newNpsData['total']++
        } else if (loopFeedbackItem.feedback_rating >= 0 && loopFeedbackItem.feedback_rating <= 6) {
          newNpsData['detractors']++
          newNpsData['total']++
        }
      }
    }
    newNpsData['score'] = (newNpsData.promoters / newNpsData.total - newNpsData.detractors / newNpsData.total) * 100
    us_setNpsData(newNpsData)
  }, [pr_feedbackData])

  // Functions

  // JSX Generation
  const rJSX_BreakdownBarSegmentLabels = (label: number): JSX.Element => {
    let labelJSX = <></>
    if (pr_feedbackSettings['show_graph_labels'] === true) {
      labelJSX = <>{label}</>
    }
    return labelJSX
  }

  const rJSX_NpsGraph = (): JSX.Element => {
    return (
      <Box
        sx={{ width: '100%', height: '40px', minWidth: '300px', overflow: 'hidden' }}
        className="tw-rounded-lg"
      >
        <Stack
          direction="row"
          sx={{ width: '100%' }}
        >
          <Tooltip
            title={
              <>
                {getProp(us_npsData, 'detractors', 0)} {s_DETRACTORS}{' '}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.error_main,
                width: (getProp(us_npsData, 'detractors', 0) / us_npsData.total) * 100 + '%',
                height: '40px',
              }}
            >
              {rJSX_BreakdownBarSegmentLabels(getProp(us_npsData, 'detractors', ''))}
            </Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(us_npsData, 'passives', 0)} {s_PASSIVES}{' '}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.warning_main,
                width: (getProp(us_npsData, 'passives', 0) / us_npsData.total) * 100 + '%',
                height: '40px',
              }}
            >
              {rJSX_BreakdownBarSegmentLabels(getProp(us_npsData, 'passives', ''))}
            </Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(us_npsData, 'promoters', 0)} {rLIB('Promoters')}{' '}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.success_main,
                width: (getProp(us_npsData, 'promoters', 0) / us_npsData.total) * 100 + '%',
                height: '40px',
              }}
            >
              {rJSX_BreakdownBarSegmentLabels(getProp(us_npsData, 'promoters', ''))}
            </Box>
          </Tooltip>
        </Stack>
      </Box>
    )
  }

  const rJSX_GraphScore = (): JSX.Element => {
    let scoreJSX = <></>
    if (us_npsData != null && us_npsData['score'] != null && !isNaN(us_npsData['score'])) {
      let npsScore = parseInt(us_npsData['score'].toFixed(0))
      let textSX: TsInterface_UnspecifiedObject = {}
      if (npsScore >= 70) {
        textSX = { color: themeVariables.teal_700 }
      } else if (npsScore >= 30) {
        textSX = { color: themeVariables.success_main }
      } else if (npsScore >= 0) {
        textSX = { color: themeVariables.warning_main }
      } else {
        textSX = { color: themeVariables.error_main }
      }
      textSX = { color: themeVariables.white }
      scoreJSX = (
        <Typography
          variant="h4"
          className="tw-mt-0"
          sx={textSX}
        >
          {npsScore}
        </Typography>
      )
    }
    return scoreJSX
  }

  const rJSX_Component = (): JSX.Element => {
    return (
      <Box sx={{ overflow: 'hidden' }}>
        <Box sx={{ width: '100%' }}>{rJSX_NpsGraph()}</Box>
        {pr_feedbackSettings['show_graph_score'] && (
          <Box
            className="tw-inline-block tw-text-center tw-align-top"
            sx={{ position: 'absolute', right: '5px' }}
          >
            {rJSX_GraphScore()}
          </Box>
        )}
      </Box>
    )
  }

  return <>{rJSX_Component()}</>
}

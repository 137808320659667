//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:
			[ ] Feature - Conditional logic rework

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material/'
import { useContext, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'rfbp_core/components/icons'
import { evaluateConditionLogic } from 'rfbp_core/components/logic'
import {
  TsInterface_TableAdditionalData,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableManageAction,
  TsInterface_TableManageActionsObject,
} from 'rfbp_core/components/table'
import {
  Context_RootData_AuthenticatedUser,
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_RootData_UserPermissions,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  actions: TsInterface_TableManageActionsObject
  rowData: TsInterface_TableDataRow
  tableAdditionalData: TsInterface_TableAdditionalData
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const TableCellManageProper = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_actions: TsInterface_ComponentProps['actions'] = getProp(props, 'actions', {})
  let pr_rowData: TsInterface_ComponentProps['rowData'] = getProp(props, 'rowData', {})
  let pr_tableAdditionalData: TsInterface_ComponentProps['tableAdditionalData'] = getProp(props, 'tableAdditionalData', {})

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_anchorEl, us_setAnchorEl] = useState(null)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_AuthenticatedUser } = useContext(Context_RootData_AuthenticatedUser)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_RootData_UserPermissions } = useContext(Context_RootData_UserPermissions)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect

  // Other Variables
  const open = Boolean(us_anchorEl)
  let tableHooks: TsInterface_TableHooks = {
    uc_setUserInterface_AlertDialogDisplay: uc_setUserInterface_AlertDialogDisplay,
    uc_setUserInterface_ConfirmDialogDisplay: uc_setUserInterface_ConfirmDialogDisplay,
    uc_setUserInterface_CustomDialogDisplay: uc_setUserInterface_CustomDialogDisplay,
    uc_setUserInterface_ErrorDialogDisplay: uc_setUserInterface_ErrorDialogDisplay,
    uc_setUserInterface_FormDialogDisplay: uc_setUserInterface_FormDialogDisplay,
    uc_setUserInterface_PromptDialogDisplay: uc_setUserInterface_PromptDialogDisplay,
    uc_RootData_AuthenticatedUser: uc_RootData_AuthenticatedUser,
    uc_RootData_ClientKey: uc_RootData_ClientKey,
    uc_setRootData_ClientKey: uc_setRootData_ClientKey,
    uc_RootData_ClientPermissions: uc_RootData_ClientPermissions,
    uc_RootData_ClientUser: uc_RootData_ClientUser,
    uc_RootData_GlobalUser: uc_RootData_GlobalUser,
    uc_RootData_UserPermissions: uc_RootData_UserPermissions,
    un_routerNavigation: un_routerNavigation,
    ur_forceRerender: ur_forceRerender,
  }

  // Functions
  const handleClick = (event: any): void => {
    us_setAnchorEl(event.currentTarget)
  }

  const handleClose = (): void => {
    us_setAnchorEl(null)
  }

  const rJSX_MenuItem = (
    action: TsInterface_TableManageAction,
    actionIndex: number,
    rowData: TsInterface_TableDataRow,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ): JSX.Element => {
    let menuItem = <></>
    let display = true
    let disabled = false
    if (action != null && action['conditional_display'] != null) {
      display = false
      if (evaluateConditionLogic(action['conditional_display'], { rowData: rowData, tableAdditionalData: tableAdditionalData }) === true) {
        display = true
      }
    }
    if (action != null && action['conditional_disable'] != null) {
      if (evaluateConditionLogic(action['conditional_disable'], { rowData: rowData, tableAdditionalData: tableAdditionalData }) === true) {
        disabled = true
      }
    }
    // If displayed
    if (display === true) {
      menuItem = (
        <MenuItem
          key={actionIndex}
          disabled={disabled}
          onClick={() => {
            action['onClick'](rowData, tableAdditionalData, tableHooks)
            handleClose()
          }}
        >
          <ListItemIcon>{action['icon']}</ListItemIcon>
          <ListItemText>{action['label']}</ListItemText>
        </MenuItem>
      )
    } else {
      menuItem = <></>
    }
    return menuItem
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <Box
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          color="inherit"
          className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
        >
          {/* <Icon icon="ellipsis-vertical"/> */}
          {/* <Icon icon="circle-chevron-down" size="xl"/> */}
          <Icon
            icon="square-ellipsis"
            size="xl"
          />
          {/* <Icon icon="square-caret-down" size="xl"/> */}
        </Box>
        <Menu
          sx={{ maxWidth: '100%' }}
          id="basic-menu"
          anchorEl={us_anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuList sx={{ maxWidth: '100%' }}>
            {objectToArray(pr_actions).map((action: TsInterface_TableManageAction, actionIndex: number) => (
              <Box
                component="div"
                key={actionIndex}
              >
                {rJSX_MenuItem(action, actionIndex, pr_rowData, pr_tableAdditionalData)}
              </Box>
            ))}
          </MenuList>
        </Menu>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

export const TableCellManage = (actionsObject: TsInterface_TableManageActionsObject) => {
  let basicCell = {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return 'bp_table_manage_cell bp-text-right tw-bg-transparent'
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (actionsObject != null && objectToArray(actionsObject).length > 0) {
          return (
            <TableCellManageProper
              actions={actionsObject}
              rowData={rowData}
              tableAdditionalData={tableAdditionalData}
            />
          )
        } else {
          return <></>
        }
      },
    },
  }
  return basicCell
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {} from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_UtilityFiles_Collection,
  DatabaseRef_UtilityFile_Document,
  DatabaseRef_UtilityForumMessages_Collection,
  DatabaseRef_UtilityForumMessage_Document,
  DatabaseRef_UtilityForumThreads_Collection,
  DatabaseRef_UtilityForumThread_Document,
  DatabaseRef_Utility_Document,
} from 'rfbp_aux/services/database_endpoints/directory/utilities'
import { StorageRef_UtilityFile, StorageRef_Utility_MessageThread_File } from 'rfbp_aux/services/storage_endpoints/utilities'
import { FileSystemBasic } from 'rfbp_core/components/file_system/file_system_basic'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { ForumBasic } from 'rfbp_core/components/forum/forum_basic'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseUtilityViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_DETAILS: JSX.Element = <Trans>Details</Trans>
const s_EDIT: JSX.Element = <Trans>Edit</Trans>
const s_EDIT_UTILITY: JSX.Element = <Trans>Edit Utility</Trans>
const s_EMAIL: JSX.Element = <Trans>Email</Trans>
const s_FILES: JSX.Element = <Trans>Files</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_NOTES: JSX.Element = <Trans>Notes</Trans>
const s_PHONE: JSX.Element = <Trans>Phone</Trans>
const s_UTILITIES: JSX.Element = <Trans>Utilities</Trans>
const s_UTILITY: JSX.Element = <Trans>Utility</Trans>
const s_UTILITY_NAME: JSX.Element = <Trans>Utility Name</Trans>
const se_UTILITY: string = 'Utility'
// { sort-end } - displayed text

// Forms
const formInputs_UtilityName: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: s_UTILITY_NAME,
    required: true,
  },
}

const formInputs_UtilityPhone: TsInterface_FormInputs = {
  phone: {
    data_type: 'string',
    input_type: 'phone_number_usa',
    key: 'phone',
    label: s_PHONE,
    required: true,
    background_color: '#494D53',
  },
}

const formInputs_UtilityEmail: TsInterface_FormInputs = {
  email: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email',
    label: s_EMAIL,
    required: true,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const utilityKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_utility, us_setUtility] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'utilities'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    document.title = se_UTILITY
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUtility(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Utility_Document(res_GCK.clientKey, utilityKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, utilityKey])

  // Functions
  const editItemProperty = (formInputs: TsInterface_FormInputs) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_utility,
          formInputs: formInputs,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_Utility_Document(res_GCK.clientKey, utilityKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{s_EDIT_UTILITY}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseUtilitiesListPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {rLIB('Back to all utilities')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_EditableLineItem = (displayText: JSX.Element, propKey: string, formInputs: TsInterface_FormInputs): JSX.Element => {
    let lineJSX = <></>
    // Missing Prop
    let missingTextJSX = (
      <Box
        component={'span'}
        className="tw-opacity-80 tw-italic"
      >
        {s_MISSING}
      </Box>
    )
    // Edit Icon
    let editIconJSX = (
      <Icon
        icon="pen-to-square"
        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2"
        tooltip={s_EDIT}
        tooltipPlacement="right"
        onClick={() => {
          editItemProperty(formInputs)
        }}
      />
    )
    // Full JSX
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box
            className="tw-pr-2 tw-font-bold"
            component={'span'}
          >
            {displayText}:
          </Box>
          <Box
            className="tw-pr-1 tw-opacity-50 tw-font-normal"
            component={'span'}
          >
            {getProp(us_utility, propKey, missingTextJSX)}
          </Box>
          {editIconJSX}
        </Typography>
      </Box>
    )
    return lineJSX
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-p-2">
        {rJSX_EditableLineItem(s_UTILITY, 'name', formInputs_UtilityName)}
        {rJSX_EditableLineItem(s_PHONE, 'phone', formInputs_UtilityPhone)}
        {rJSX_EditableLineItem(s_EMAIL, 'email', formInputs_UtilityEmail)}
      </Card>
    )
    return tabJSX
  }

  const rJSX_NotesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <ForumBasic
          forumThreadCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_UtilityForumThreads_Collection(clientKey, utilityKey)
          }}
          forumThreadDocumentDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_UtilityForumThread_Document(clientKey, utilityKey, threadKey)
          }}
          forumMessageCollectionDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_UtilityForumMessages_Collection(clientKey, utilityKey, threadKey)
          }}
          forumMessageDocumentDatabaseEndpoint={(clientKey, threadKey, messageKey) => {
            return DatabaseRef_UtilityForumMessage_Document(clientKey, utilityKey, threadKey, messageKey)
          }}
          forumMessageStorageEndpoint={(clientKey, threadKey, messageKey, fileName) => {
            return StorageRef_Utility_MessageThread_File(clientKey, utilityKey, threadKey, fileName)
          }}
          forumSettings={{
            allow_thread_creation: true,
            scrollbar_track_color: '#272b33',
            scrollbar_thumb_color: '#888',
            input_text_color: '#fff',
            base_message_container_offset_px: 336,
          }}
        />
      </Box>
    )
    return tabJSX
  }

  const rJSX_FilesTab = (): JSX.Element => {
    let tabJSX = (
      <FileSystemBasic
        fileSystemCollectionDatabaseEndpoint={(clientKey) => {
          return DatabaseRef_UtilityFiles_Collection(clientKey, utilityKey)
        }}
        fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
          return DatabaseRef_UtilityFile_Document(clientKey, utilityKey, fileKey)
        }}
        fileSystemStorageEndpoint={(clientKey, fileName) => {
          return StorageRef_UtilityFile(clientKey, utilityKey, fileName)
        }}
        fileSystemHardCodedFolders={{}}
        fileSystemSettings={{
          allow_file_archiving: true,
          allow_file_movement: true,
          allow_file_unarchiving: true,
          allow_file_uploads: true,
          allow_folder_creation: true,
          allow_folder_deletion: true,
          allow_folder_movement: true,
          allow_folder_rename: true,
          archive_filter_visible: true,
        }}
      />
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_UTILITIES}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <Box>
              <TabsUrl
                tabsSettings={{
                  baseUrl: ApplicationPages.AdminDatabaseUtilityViewPage.url(utilityKey),
                  tabQueryParam: 'tab',
                  overridePageTitle: true,
                  basePageTitle: se_UTILITY,
                }}
                tabs={[
                  {
                    tabUrlKey: 'Details',
                    tabHeader: s_DETAILS,
                    tabContent: rJSX_DetailsTab(),
                  },
                  {
                    tabUrlKey: 'Notes',
                    tabHeader: s_NOTES,
                    tabContent: rJSX_NotesTab(),
                  },
                  {
                    tabUrlKey: 'Files',
                    tabHeader: s_FILES,
                    tabContent: rJSX_FilesTab(),
                  },
                ]}
              />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

/*
		name
		phone
		email
		website
		utility_portal_rul
		username
		password

	*/

/*
		BASIC File Folder System Component

			Similar to drive with folders and files (start with just table view with folders sorted above files)

			Params
				Endpoint for collection to load - just load all

			Associated Folder Key (if null thow in root)

			Type = file or folder

	/*
		Forum Component

			Just copy gchat for the most part

			Params
				Endpoint for threads
				Endpoint for comments
				Storage Endpoint for images?
				Other settings????
					how many replies to render
					allow inline images
					allow emoji responses

			Table
				Clicking on Row set state variabe and changes component to render forum view with back button
				Initial Thread creation with first reply?
				Followup Replies
				Edits on content that you wrote??? (Edited date)
				Inline Images or other files?
				Reactions with emojis?
					- https://www.npmjs.com/package/emoji-picker-react

			Questions
				Show number or messages on table
				How to handle read???


		*/

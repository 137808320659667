//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Database Mangement provides all methods of reading or writing to the database

		TODO:
			Backup Logging
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { saveAs } from 'file-saver'
import {
  addDoc,
  CollectionReference,
  deleteDoc,
  deleteField,
  DocumentData,
  DocumentReference,
  endAt,
  endBefore,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  Query,
  query,
  runTransaction,
  setDoc,
  startAfter,
  startAt,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore'
import { deleteObject, getDownloadURL, listAll, StorageReference, uploadBytes } from 'firebase/storage'
import JSZip from 'jszip'
import { Trans } from 'react-i18next'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_PromiseArray, TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_SetDataCallback {
  (data: TsInterface_UnspecifiedObject): void
}

interface TsInterface_DatabaseSetMergeDocumentResult {
  success: boolean
  error: object
}

interface TsInterface_DatabaseGetDataResult {
  data: {
    [propKey: string]: any
  }
  key?: string
  success: boolean
  error: object
}

interface TsInterface_QueryOperator {
  prop: string
  comparator: '<' | '<=' | '==' | '>' | '>=' | '!=' | 'array-contains' | 'array-contains-any' | 'in' | 'not-in'
  value: string | number | boolean | Date | string[]
}

export interface TsInterface_QueryOperatorsArray extends Array<TsInterface_QueryOperator> {}

interface TsInterface_OrderBy {
  prop: string | null
  desc: boolean
}

export interface TsInterface_OrderByArray extends Array<TsInterface_OrderBy> {}

export interface TsInterface_QueryCursorsObject {
  startAt?: string | number | Date
  startAfter?: string | number | Date
  endAt?: string | number | Date
  endBefore?: string | number | Date
}

export interface TsInterface_DatabaseAddDocumentResult {
  key: string
  success: boolean
  error: object
}

interface TsInterface_DatabaseBatchUpdate {
  type: 'setOverwrite' | 'setMerge' | 'update' | 'delete'
  ref: DocumentReference<DocumentData>
  data: TsInterface_UnspecifiedObject
}

interface TsInterface_DatabaseBatchDelete {
  type: 'delete'
  ref: DocumentReference<DocumentData>
}

export interface TsInterface_DatabaseBatchUpdatesArray extends Array<TsInterface_DatabaseBatchUpdate | TsInterface_DatabaseBatchDelete> {}

export interface TsInterface_LogOptions {
  log?: boolean
  key?: string
}

interface TsInterface_StorageData {
  files: {
    [propKey: string]: any
  }
  folders: {
    [propKey: string]: any
  }
}

interface TsInterface_StorageListFilesResult {
  data: {
    files: {
      [propKey: string]: any
    }
    folders: {
      [propKey: string]: any
    }
  }
  success: boolean
  error: object
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_FAILED_TO_DELETE_DOCUMENT_FROM_DATABASE: JSX.Element = <Trans>Failed to delete document from database</Trans>
const s_FAILED_TO_DOWNLOAD_FILES: JSX.Element = <Trans>Failed to download files</Trans>
const s_FAILED_TO_GET_COLLECTION: JSX.Element = <Trans>Failed to get collection</Trans>
const s_FAILED_TO_GET_DOCUMENT: JSX.Element = <Trans>Failed to get document</Trans>
const s_FAILED_TO_GET_FILES_LIST: JSX.Element = <Trans>Failed to get files list</Trans>
const s_FAILED_TO_GET_FILE_URL: JSX.Element = <Trans>Failed to get file url</Trans>
const s_FAILED_TO_PERFORM_BATCH_UPDATE: JSX.Element = <Trans>Failed to perform batch update</Trans>
const s_FAILED_TO_PERFORM_STAGED_BATCH_UPDATE: JSX.Element = <Trans>Failed to perform staged batch update</Trans>
const s_FAILED_TO_RUN_TRANSACTION: JSX.Element = <Trans>Failed to run transaction</Trans>
const s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE: JSX.Element = <Trans>Failed to save document to database</Trans>
const s_FAILED_TO_UPLOAD_FILE: JSX.Element = <Trans>Failed to upload file</Trans>
const s_NO_DOCUMENT_FOR_SPECIFIED_DATABASE_LOCATION: JSX.Element = <Trans>No Document for specified database location</Trans>
const s_ONE_OR_MORE_SUB_BATCHES_FAILED_TO_SAVE: JSX.Element = <Trans>One or more sub batches failed to save</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

// TODO - serverTimestamp and deleteField replacements in clean function

// const cleanDatabaseData = () => {
// return new Promise( ( resolve, reject ) => {
// 	try {
// 		resolve({
// 			success: true,
// 			data: data
// 		})
// 	} catch (rej_T) {
// 		reject({
// 			success: false,
// 			error: { message: rej_T.message, details: null, code: "ER-D-SDM-DGC-01" }
// 		})
// 	}
// })
// }

///////////////////////////////
// Exports
///////////////////////////////

// Generate Query Endpoint
export const generateDatabaseQuery = (
  baseCollectionEndpoint: CollectionReference<DocumentData>,
  queryOperatorsArray: TsInterface_QueryOperatorsArray,
  orderByArray: TsInterface_OrderByArray,
  queryCursorsObject: TsInterface_QueryCursorsObject,
  limitCount: number | null,
): Query<DocumentData> => {
  let databaseQueryParams = []
  // Base Endpoint
  databaseQueryParams.push(baseCollectionEndpoint)
  // Query Operators
  for (let queryOperatorIndex in queryOperatorsArray) {
    let queryOperator = queryOperatorsArray[queryOperatorIndex]
    if (queryOperator.prop != null && queryOperator.comparator != null && queryOperator.value != null) {
      databaseQueryParams.push(where(queryOperator.prop, queryOperator.comparator, queryOperator.value))
    }
  }
  // Order By
  for (let orderByIndex in orderByArray) {
    let orderByItem = orderByArray[orderByIndex]
    if (orderByItem.prop != null) {
      if (orderByItem.desc === true) {
        databaseQueryParams.push(orderBy(orderByItem.prop, 'desc'))
      } else {
        databaseQueryParams.push(orderBy(orderByItem.prop))
      }
    }
  }
  // Query Cursors
  // TODO - implement compound query cursors - i.e. startAt("Springfield", "Missouri")
  if (queryCursorsObject['startAt'] != null) {
    databaseQueryParams.push(startAt(queryCursorsObject['startAt']))
  } else if (queryCursorsObject['startAfter'] != null) {
    databaseQueryParams.push(startAfter(queryCursorsObject['startAfter']))
  }
  if (queryCursorsObject['endAt'] != null) {
    databaseQueryParams.push(endAt(queryCursorsObject['endAt']))
  } else if (queryCursorsObject['endBefore'] != null) {
    databaseQueryParams.push(endBefore(queryCursorsObject['endBefore']))
  }
  // Limits
  if (limitCount != null && !isNaN(limitCount)) {
    databaseQueryParams.push(limit(limitCount))
  }
  // Return
  // @ts-expect-error - TODO: reason for error
  // eslint-disable-next-line prefer-spread
  return query.apply(null, databaseQueryParams)
}

export const generateDatabaseArrayContainsAnyQuery = (
  baseCollectionEndpoint: CollectionReference<DocumentData>,
  queryField: string,
  queryArrayValues: (string | number)[],
): Query<DocumentData> => {
  return query(baseCollectionEndpoint, where(queryField, 'array-contains-any', queryArrayValues))
}

// Read Functions
export const DatabaseGetDocument = async (documentRef: DocumentReference<DocumentData>): Promise<TsInterface_DatabaseGetDataResult> => {
  return new Promise((resolve, reject) => {
    try {
      getDoc(documentRef)
        .then((res_GD) => {
          if (res_GD.exists()) {
            resolve({
              success: true,
              key: res_GD.id,
              data: res_GD.data(),
              error: {},
            })
          } else {
            reject({
              success: false,
              error: {
                message: s_FAILED_TO_GET_DOCUMENT,
                details: s_NO_DOCUMENT_FOR_SPECIFIED_DATABASE_LOCATION,
                code: 'ER-D-SDM-DGD-01',
                additional: {
                  path: documentRef.path,
                },
              },
            })
          }
        })
        .catch((rej_GD) => {
          console.error(rej_GD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_GET_DOCUMENT,
              details: rej_GD.message,
              code: 'ER-D-SDM-DGD-02',
            },
          })
        })
    } catch (rej_T: any) {
      console.error(rej_T)
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_GET_DOCUMENT,
          details: rej_T.message,
          code: 'ER-D-SDM-DGD-03',
        },
      })
    }
  })
}

export const DatabaseGetCollection = async (collectionRef: CollectionReference<DocumentData> | Query<unknown>): Promise<TsInterface_DatabaseGetDataResult> => {
  return new Promise((resolve, reject) => {
    try {
      getDocs(collectionRef)
        .then((res_GD) => {
          let data: TsInterface_UnspecifiedObject = {}
          res_GD.forEach((doc) => {
            data[doc.id] = doc.data()
          })
          resolve({
            success: true,
            data: data,
            error: {},
          })
        })
        .catch((rej_GD) => {
          console.error(rej_GD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_GET_COLLECTION,
              details: rej_GD.message,
              code: 'ER-D-SDM-DGC-01',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_GET_COLLECTION,
          details: rej_T.message,
          code: 'ER-D-SDM-DGC-01',
        },
      })
    }
  })
}

// export const DatabaseGetCollectionIds - ONLY WORKS ON SERVER

export const DatabaseGetLiveDocument = (documentRef: DocumentReference<DocumentData>, setDataCallback: TsInterface_SetDataCallback) => {
  let data: TsInterface_UnspecifiedObject | undefined = {}
  const unsubscribeLiveData = onSnapshot(
    documentRef,
    (documentSnapshot) => {
      // let source = documentSnapshot.metadata.hasPendingWrites ? 'Local' : 'Server'
      data = documentSnapshot.data()
      setDataCallback(data as TsInterface_UnspecifiedObject)
    },
    (error) => {
      console.error(error)
    },
  )
  return unsubscribeLiveData
}

export const DatabaseGetLiveCollection = (collectionRef: CollectionReference<DocumentData> | Query<unknown>, setDataCallback: TsInterface_SetDataCallback) => {
  let data: TsInterface_UnspecifiedObject = {}
  const unsubscribeLiveData = onSnapshot(
    collectionRef,
    (querySnapshot) => {
      data = {}
      querySnapshot.forEach((documentSnapshot) => {
        data[documentSnapshot.id] = documentSnapshot.data()
      })
      setDataCallback(data)
    },
    (error) => {
      console.error(error)
    },
  )
  return unsubscribeLiveData
}

export const DatabaseSearchCollection = async (
  baseCollectionEndpoint: CollectionReference<DocumentData> | Query<unknown>,
  searchProp: string,
  searchTerm: string,
  limitCount: number,
): Promise<TsInterface_DatabaseGetDataResult> => {
  return new Promise((resolve, reject) => {
    let searchResults = {}
    if (searchTerm != null) {
      searchTerm = searchTerm.toLowerCase()
      let strlength = searchTerm.length
      let strFrontCode = searchTerm.slice(0, strlength - 1)
      let strEndCode = searchTerm.slice(strlength - 1, searchTerm.length)
      let endCode = strFrontCode + String.fromCharCode(strEndCode.charCodeAt(0) + 1)
      // Generate Database Query
      let orderByArray = [{ prop: searchProp, desc: false }]
      let queryCursorsObject = {}
      let queryOperatorsArray = [
        { prop: searchProp, comparator: '>=', value: searchTerm },
        { prop: searchProp, comparator: '<', value: endCode },
      ]
      // @ts-expect-error - TODO: reason for error
      DatabaseGetCollection(generateDatabaseQuery(baseCollectionEndpoint, queryOperatorsArray, orderByArray, queryCursorsObject, limitCount))
        .then((res_DGC: TsInterface_DatabaseGetDataResult) => {
          resolve(res_DGC)
        })
        .catch((rej_DGC) => {
          reject(rej_DGC)
        })
    } else {
      resolve({ success: false, data: searchResults, error: {} })
    }
  })
}

// Write Functions
export const DatabaseAddDocument = async (
  collectionRef: CollectionReference<DocumentData>,
  updateObject: TsInterface_UnspecifiedObject,
  addKey: boolean,
): Promise<TsInterface_DatabaseAddDocumentResult> => {
  return new Promise((resolve, reject) => {
    try {
      let databasePromiseArray: TsInterface_PromiseArray = []
      addDoc(collectionRef, updateObject)
        .then((res_AD) => {
          if (addKey === true) {
            const keyUpdateObject = { key: res_AD.id }
            databasePromiseArray.push(setDoc(res_AD, keyUpdateObject, { merge: true }))
          }
          Promise.all(databasePromiseArray).finally(() => {
            resolve({
              success: true,
              key: res_AD.id,
              error: {},
            })
          })
        })
        .catch((rej_AD) => {
          console.error(rej_AD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE,
              details: rej_AD.message,
              code: 'ER-D-SDM-DAD-01',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE,
          details: rej_T.message,
          code: 'ER-D-SDM-DAD-02',
        },
      })
    }
  })
}

export const DatabaseSetReplaceDocument = async (
  documentRef: DocumentReference<DocumentData>,
  updateObject: TsInterface_UnspecifiedObject,
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    try {
      setDoc(documentRef, updateObject, { merge: false })
        .then((res_SD) => {
          resolve({
            success: true,
          })
        })
        .catch((rej_SD) => {
          console.error(rej_SD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE,
              details: rej_SD.message,
              code: 'ER-D-SDM-DSRD-01',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE,
          details: rej_T.message,
          code: 'ER-D-SDM-DSRD-02',
        },
      })
    }
  })
}

export const DatabaseSetMergeDocument = async (
  documentRef: DocumentReference<DocumentData>,
  updateObject: TsInterface_UnspecifiedObject,
): Promise<TsInterface_DatabaseSetMergeDocumentResult> => {
  return new Promise((resolve, reject) => {
    try {
      setDoc(documentRef, updateObject, { merge: true })
        .then((res_SD) => {
          resolve({
            success: true,
            error: {},
          })
        })
        .catch((rej_SD) => {
          console.error(rej_SD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE,
              details: rej_SD.message,
              code: 'ER-D-SDM-DSMD-01',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE,
          details: rej_T.message,
          code: 'ER-D-SDM-DSMD-02',
        },
      })
    }
  })
}

export const DatabaseUpdateDocument = async (documentRef: DocumentReference<DocumentData>, updateObject: TsInterface_UnspecifiedObject): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    try {
      updateDoc(documentRef, updateObject)
        .then((res_UD) => {
          resolve({
            success: true,
          })
        })
        .catch((rej_UD) => {
          console.error(rej_UD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE,
              details: rej_UD.message,
              code: 'ER-D-SDM-DSUD-01',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE,
          details: rej_T.message,
          code: 'ER-D-SDM-DSUD-02',
        },
      })
    }
  })
}

export const DatabaseDeleteDocument = async (documentRef: DocumentReference<DocumentData>): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    try {
      deleteDoc(documentRef)
        .then((res_DD) => {
          resolve({
            success: true,
          })
        })
        .catch((rej_DD) => {
          console.error(rej_DD)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_DELETE_DOCUMENT_FROM_DATABASE,
              details: rej_DD.message,
              code: 'ER-D-SDM-DDD-01',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_DELETE_DOCUMENT_FROM_DATABASE,
          details: rej_T.message,
          code: 'ER-D-SDM-DDD-02',
        },
      })
    }
  })
}

export const DatabaseBatchUpdate = async (batchUpdatesArray: TsInterface_DatabaseBatchUpdatesArray) => {
  return new Promise((resolve, reject) => {
    try {
      // Create batch
      let firestore = getFirestore()
      let batch = writeBatch(firestore)
      // Loop through and add to batch
      for (let i = 0; i < batchUpdatesArray.length; i++) {
        let batchUpdate = batchUpdatesArray[i]
        if (batchUpdate != null) {
          switch (batchUpdate.type) {
            case 'setOverwrite':
              batch.set(batchUpdate.ref, batchUpdate.data, { merge: false })
              break
            case 'setMerge':
              batch.set(batchUpdate.ref, batchUpdate.data, { merge: true })
              break
            case 'update':
              batch.update(batchUpdate.ref, batchUpdate.data)
              break
            case 'delete':
              batch.delete(batchUpdate.ref)
              break
          }
        }
      }
      // Commit Batch
      batch
        .commit()
        .then((res_BC) => {
          resolve({ success: true })
        })
        .catch((rej_BC) => {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_PERFORM_BATCH_UPDATE,
              details: rej_BC.message,
              code: 'ER-D-SDM-DBU-01',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_PERFORM_BATCH_UPDATE,
          details: rej_T.message,
          code: 'ER-D-SDM-DBU-02',
        },
      })
    }
  })
}

const DatabaseStagedBatchUpdateHelper = async (batchUpdatesArray: TsInterface_DatabaseBatchUpdatesArray, timeoutMilliseconds: number) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      DatabaseBatchUpdate(batchUpdatesArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    }, timeoutMilliseconds)
  })
}

export const DatabaseStagedBatchUpdate = async (batchUpdatesArray: TsInterface_DatabaseBatchUpdatesArray) => {
  return new Promise((resolve, reject) => {
    let batchLimit = 500
    let baseTimeoutMilliseconds = 1500
    let errorSavingData = false
    let updateArrayOfArrays: TsInterface_DatabaseBatchUpdatesArray[] = [[]]
    let currentBatchArrayIndex = 0
    for (let loopIndex in batchUpdatesArray) {
      let loopItem = batchUpdatesArray[loopIndex]
      let effectiveLoopIndex = parseInt(loopIndex) - currentBatchArrayIndex * batchLimit
      if (effectiveLoopIndex >= batchLimit) {
        currentBatchArrayIndex++
        updateArrayOfArrays[currentBatchArrayIndex] = []
      }
      updateArrayOfArrays[currentBatchArrayIndex].push(loopItem)
    }
    let promiseArray: TsInterface_PromiseArray = []
    for (let loopArrayIndex in updateArrayOfArrays) {
      let loopArray = updateArrayOfArrays[loopArrayIndex]
      promiseArray.push(
        DatabaseStagedBatchUpdateHelper(loopArray, baseTimeoutMilliseconds * parseInt(loopArrayIndex))
          .then((res_DBU) => {
            // Nothing
            // eslint-disable-next-line no-loop-func
          })
          .catch((rej_DBU) => {
            errorSavingData = true
          }),
      )
    }
    // After all promises have resolved
    Promise.all(promiseArray).finally(() => {
      if (errorSavingData === false) {
        resolve({
          success: true,
        })
      } else {
        reject({
          success: false,
          error: {
            message: s_FAILED_TO_PERFORM_STAGED_BATCH_UPDATE,
            details: s_ONE_OR_MORE_SUB_BATCHES_FAILED_TO_SAVE,
            code: 'ER-D-SDM-DSBU-01',
          },
        })
      }
    })
  })
}

export const DatabaseTransactionIncrement = async (
  documentRef: DocumentReference<DocumentData>,
  incrementPropertyKey: string,
  defaultIncrementPropertyValue: number,
) => {
  return new Promise((resolve, reject) => {
    try {
      let incrementValue = defaultIncrementPropertyValue
      runTransaction(getFirestore(), async (transaction) => {
        return transaction
          .get(documentRef)
          .then((res_TG) => {
            if (res_TG.exists()) {
              incrementValue = res_TG.data()[incrementPropertyKey] + 1
              let updateObject: TsInterface_UnspecifiedObject = {}
              updateObject[incrementPropertyKey] = incrementValue
              transaction.update(documentRef, updateObject)
            } else {
              incrementValue = defaultIncrementPropertyValue
              let updateObject: TsInterface_UnspecifiedObject = {}
              updateObject[incrementPropertyKey] = incrementValue
              transaction.set(documentRef, updateObject)
            }
          })
          .catch((rej_TG) => {
            console.error(rej_TG)
            reject({
              success: false,
              error: {
                message: s_FAILED_TO_RUN_TRANSACTION,
                details: rej_TG.message,
                code: 'ER-D-SDM-DTI-01',
              },
            })
          })
      })
        .then((res_RT) => {
          resolve({
            success: true,
            incrementPropertyKey: incrementPropertyKey,
            incrementValue: incrementValue,
          })
        })
        .catch((rej_RT) => {
          console.error(rej_RT)
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_RUN_TRANSACTION,
              details: rej_RT.message,
              code: 'ER-D-SDM-DTI-02',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          code: 'ER-D-SDM-DTI-03',
          message: rej_T.message,
        },
      })
    }
  })
}

// Storage Functions
export const StorageListFiles = async (storageRef: StorageReference): Promise<TsInterface_StorageListFilesResult> => {
  // TODO - clean up?

  return new Promise((resolve, reject) => {
    try {
      listAll(storageRef)
        .then((res_LA) => {
          let dataOutput: TsInterface_StorageData = {
            folders: {},
            files: {},
          }
          if (res_LA.prefixes != null) {
            for (let folderIndex in res_LA.prefixes) {
              let folder = res_LA.prefixes[folderIndex]
              dataOutput['folders'][folder.name] = {
                name: folder.name,
                path: folder.fullPath,
                ref: folder,
              }
            }
          }
          if (res_LA.items != null) {
            for (let fileIndex in res_LA.items) {
              let file = res_LA.items[fileIndex]
              dataOutput['files'][file.name] = {
                name: file.name,
                path: file.fullPath,
                ref: file,
              }
            }
          }
          resolve({ success: true, data: dataOutput, error: {} })
        })
        .catch((rej_LA) => {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_GET_FILE_URL,
              details: rej_LA.message,
              code: 'ER-D-SDM-SLF-01',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_GET_FILES_LIST,
          details: rej_T.message,
          code: 'ER-D-SDM-SLF-02',
        },
      })
    }
  })
}

export const StorageGetDownloadUrl = async (storageRef: StorageReference): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    try {
      getDownloadURL(storageRef)
        .then((res_GDU) => {
          resolve({ success: true, url: res_GDU })
        })
        .catch((rej_GDU) => {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_GET_FILE_URL,
              details: rej_GDU.message,
              code: 'ER-D-SDM-SGDU-01',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_GET_FILE_URL,
          details: rej_T.message,
          code: 'ER-D-SDM-SGDU-02',
        },
      })
    }
  })
}

export const StorageUploadFile = async (
  storageRef: StorageReference,
  file: Blob | Uint8Array | ArrayBuffer,
  metadata: TsInterface_UnspecifiedObject,
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    try {
      if (metadata == null) {
        metadata = {}
      }
      uploadBytes(storageRef, file, { customMetadata: metadata })
        .then((res_SUF) => {
          getDownloadURL(res_SUF.ref)
            .then((res_GDU) => {
              resolve({ success: true, snapshot: res_SUF, url: res_GDU })
            })
            .catch((rej_GDU) => {
              reject({
                success: false,
                error: {
                  message: s_FAILED_TO_UPLOAD_FILE,
                  details: rej_GDU.message,
                  code: 'ER-D-SDM-SUF-01',
                },
              })
            })
        })
        .catch((rej_SUF) => {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_UPLOAD_FILE,
              details: rej_SUF.message,
              code: 'ER-D-SDM-SUF-02',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          message: s_FAILED_TO_UPLOAD_FILE,
          details: rej_T.message,
          code: 'ER-D-SDM-SUF-03',
        },
      })
    }
  })
}

export const StorageDeleteFile = async (storageRef: StorageReference): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    try {
      deleteObject(storageRef)
        .then((res_DO) => {
          resolve(res_DO)
        })
        .catch((rej_DO) => {
          reject({
            success: false,
            error: {
              message: rLIB('Failed to delete file'),
              details: rej_DO.message,
              code: 'ER-D-SDM-SDF-01',
            },
          })
        })
    } catch (rej_T: any) {
      reject({
        success: false,
        error: {
          message: rLIB('Failed to delete file'),
          details: rej_T.message,
          code: 'ER-D-SDM-SDF-02',
        },
      })
    }
  })
}

export const DownloadStorageFileFromURL = (storageRef: StorageReference): void => {
  // let link = document.createElement("a")
  // link.download = fileName
  // link.href = url
  // document.body.appendChild(link)
  // link.click()
  // document.body.removeChild(link)
  // eslint-disable-next-line no-delete-var
  // delete link
  getDownloadURL(storageRef).then((res_GDU) => {
    // This can be downloaded directly:
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.onload = (event) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const blob = xhr.response
    }
    xhr.open('GET', res_GDU)
    xhr.send()
  })
}

export const DownloadFromUrlsAsZip = (
  urlsArray: TsInterface_UnspecifiedObject[], // name, url
  downloadZipName: string, // 'download.zip'
): Promise<unknown> => {
  return new Promise((resolve, reject) => {
    let promiseArray = []
    const jszip = new JSZip()
    // Loop through URLs and load as blobs
    for (let loopUrlIndex in urlsArray) {
      let loopUrl = urlsArray[loopUrlIndex]
      promiseArray.push(
        fetch(loopUrl.url).then((res_F) => {
          jszip.file(loopUrl.name, res_F.blob())
        }),
      )
    }
    // After all urls are loaded, download as zip
    Promise.all(promiseArray).finally(() => {
      jszip
        .generateAsync({ type: 'blob' })
        .then((res_JGA) => {
          saveAs(res_JGA, downloadZipName)
          resolve({ success: true })
        })
        .catch((rej_JGA) => {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_DOWNLOAD_FILES,
              details: rej_JGA.message,
              code: 'ER-D-SDM-DFUAZ-01',
            },
          })
        })
    })
  })
}

export const DatabaseFieldDelete = deleteField()

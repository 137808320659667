///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Timeline, TimelineConnector, TimelineContent, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab'
import { Box, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const rJSX_ListBulletPoint = (text: string | JSX.Element): JSX.Element => {
  let listBulletPoint = <></>

  listBulletPoint = (
    <Box className="tw-my-2 tw-ml-12">
      <Box
        className="tw-mr-2 tw-inline-block"
        sx={{ color: themeVariables.primary_main }}
      >
        <Icon icon="circle-small" />
      </Box>
      {text}
    </Box>
  )

  return listBulletPoint
}

export const rJSX_defaultProjectTimeline = (): JSX.Element => {
  let defaultProjectTimelineJSX = (
    <Timeline position="right">
      <TimelineItem>
        <TimelineOppositeContent sx={{ display: 'none' }}></TimelineOppositeContent>
        <TimelineSeparator>
          {/* <TimelineConnector /> */}
          {/* <TimelineDot sx={{ fontSize: "36px ", width: "48px", height: "48px"}} color="primary"> */}
          <Box
            sx={{ fontSize: '36px', marginBottom: '6px', width: '48px', height: '48px', color: themeVariables.primary_main }}
            className="tw-text-center"
          >
            <Icon
              icon="magnifying-glass-location"
              type="light"
            />
          </Box>
          {/* </TimelineDot> */}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Box
            className="tw-text-left"
            sx={{ marginTop: '-4px' }}
          >
            <Typography
              variant="h5"
              className="tw-font-bold"
              component="span"
              color="primary"
            >
              1) {rLIB('Site Audit')}
            </Typography>
            {rJSX_ListBulletPoint(
              rLIB('A technician will visit your home and make a final assessment of your requirements and readiness of your home for the powerwall'),
            )}
            {rJSX_ListBulletPoint(
              rLIB(
                'This will be your opportunity to finalize the loads that you would like to back up and make sure that the Powerwall units you are purchasing can support those loads',
              ),
            )}
            {rJSX_ListBulletPoint(
              rLIB('If there are any pricing changes, you will know at this point and will have the opportunity to ask questions, proceed or cancel'),
            )}
          </Box>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent sx={{ display: 'none' }}></TimelineOppositeContent>
        <TimelineSeparator>
          {/* <TimelineConnector /> */}
          {/* <TimelineDot sx={{ fontSize: "28px ", width: "48px", height: "48px"}} color="primary"> */}
          <Box
            sx={{ fontSize: '36px', marginBottom: '6px', width: '48px', height: '48px', color: themeVariables.primary_main }}
            className="tw-text-center"
          >
            <Icon
              icon="file-certificate"
              type="light"
            />
          </Box>
          {/* </TimelineDot> */}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Box
            className="tw-text-left"
            sx={{ marginTop: '-4px' }}
          >
            <Typography
              variant="h5"
              className="tw-font-bold"
              component="span"
              color="primary"
            >
              2) {rLIB('Permitting and Approvals')}
            </Typography>
            {rJSX_ListBulletPoint(
              rLIB(
                'Our team will generate an electrical design, apply for and get approvals from your local city and utility to connect your Powerwall backup to the grid',
              ),
            )}
            {rJSX_ListBulletPoint(rLIB('If you are a part of a HOA and require their approval, our team will apply for that as well on your behalf'))}
          </Box>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent sx={{ display: 'none' }}></TimelineOppositeContent>
        <TimelineSeparator>
          {/* <TimelineConnector /> */}
          {/* <TimelineDot sx={{ fontSize: "28px ", width: "48px", height: "48px"}} color="primary"> */}
          <Box
            sx={{ fontSize: '36px', marginBottom: '6px', width: '48px', height: '48px', color: themeVariables.primary_main }}
            className="tw-text-center"
          >
            <Icon
              icon="screwdriver-wrench"
              type="light"
            />
          </Box>
          {/* </TimelineDot> */}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Box
            className="tw-text-left"
            sx={{ marginTop: '-4px' }}
          >
            <Typography
              variant="h5"
              className="tw-font-bold"
              component="span"
              color="primary"
            >
              3) {rLIB('Install')}
            </Typography>
            {rJSX_ListBulletPoint(rLIB("Once we have approvals, we'll schedule a date with you to come out and install your Powerwall units"))}
            {rJSX_ListBulletPoint(rLIB('The loads that you specified during the site audit will be backed up'))}
            {rJSX_ListBulletPoint(rLIB("We'll want to have your credit card or ACH details on file before the install"))}
          </Box>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent sx={{ display: 'none' }}></TimelineOppositeContent>
        <TimelineSeparator>
          {/* <TimelineConnector /> */}
          {/* <TimelineDot sx={{ fontSize: "28px ", width: "48px", height: "48px"}} color="primary"> */}
          <Box
            sx={{ fontSize: '36px', marginBottom: '6px', width: '48px', height: '48px', color: themeVariables.primary_main }}
            className="tw-text-center"
          >
            <Icon
              icon="clipboard-list-check"
              type="light"
            />
          </Box>
          {/* </TimelineDot> */}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Box
            className="tw-text-left"
            sx={{ marginTop: '-4px' }}
          >
            <Typography
              variant="h5"
              className="tw-font-bold"
              component="span"
              color="primary"
            >
              4) {rLIB('Inspection')}
            </Typography>
            {rJSX_ListBulletPoint(rLIB('Most cities and utilities will want to come back out and inspect the system to make sure that it meets code'))}
            {rJSX_ListBulletPoint(rLIB('We will schedule the inspection and be on-site if the city/utility requires it'))}
            {rJSX_ListBulletPoint(rLIB('We will also address any exceptions if they are identified (at our cost)'))}
          </Box>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent sx={{ display: 'none' }}></TimelineOppositeContent>
        <TimelineSeparator>
          {/* <TimelineConnector /> */}
          {/* <TimelineDot sx={{ fontSize: "28px ", width: "48px", height: "48px"}} color="primary"> */}
          <Box
            sx={{ fontSize: '36px', marginBottom: '6px', width: '48px', height: '48px', color: themeVariables.primary_main }}
            className="tw-text-center"
          >
            <Icon
              icon="battery-bolt"
              type="light"
            />
          </Box>
          {/* </TimelineDot> */}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Box
            className="tw-text-left"
            sx={{ marginTop: '-4px' }}
          >
            <Typography
              variant="h5"
              className="tw-font-bold"
              component="span"
              color="primary"
            >
              5) {rLIB('Activation!')}
            </Typography>
            {rJSX_ListBulletPoint(rLIB("Once your utility gives us the permission to operate, we'll work with Tesla to flip the switch and you'll be live!"))}
            {rJSX_ListBulletPoint(rLIB('We will charge your card or bank for the full amount at this point'))}
            {rJSX_ListBulletPoint(
              rLIB("We'll continue to watch the system for a period of 3 months to make sure that everything is working the way it should"),
            )}
            {rJSX_ListBulletPoint(
              rLIB(
                "Our install also comes with a 5-year workmanship warranty so you can rest assured that we'll come back out and fix things if needed. For clarity your hardware warranty is provided directly by Tesla",
              ),
            )}
          </Box>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  )

  return defaultProjectTimelineJSX
}

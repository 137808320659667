///////////////////////////////
// Imports
///////////////////////////////
import { Autocomplete, Box, FormControl, TextField, Typography } from '@mui/material'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { utilityCompaniesList } from '../data/utility_companies'

///////////////////////////////
// Exports
///////////////////////////////
export const formInputs_HomeInfo: TsInterface_FormInputs = {
  address: {
    data_type: 'string',
    key: 'address',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      // Helper Functions
      const geocodeAddress = (fullAddress: string) => {
        if (fullAddress != null && fullAddress !== '') {
          formInputChange('address', fullAddress, true)
          geocodeByAddress(fullAddress)
            .then((res_GCBY) => {
              if (res_GCBY != null && res_GCBY[0] != null && res_GCBY[0]['address_components'] != null) {
                let formattedAddressComponents: TsInterface_UnspecifiedObject = {}
                for (let loopAddressComponentIndex in res_GCBY[0]['address_components']) {
                  let loopAddressComponent = res_GCBY[0]['address_components'][loopAddressComponentIndex]
                  if (
                    loopAddressComponent != null &&
                    loopAddressComponent['long_name'] != null &&
                    loopAddressComponent['types'] != null &&
                    loopAddressComponent['types'][0] != null
                  ) {
                    formattedAddressComponents[loopAddressComponent['types'][0]] = loopAddressComponent['long_name']
                  }
                }
                // Save All Address Components
                formInputChange('address_components', formattedAddressComponents, true)
                // Get Zip Code Out and Check for region and service areas
                if (formattedAddressComponents != null && formattedAddressComponents['postal_code'] != null) {
                  let zipCode = formattedAddressComponents['postal_code']
                  // Check if zip code is in service area
                  formInputChange('location_zip_code', zipCode, true)
                  if (
                    zipCode != null &&
                    formAdditionalData != null &&
                    formAdditionalData['serviceAreaLookup'] != null &&
                    // @ts-expect-error - TODO: reason for error
                    formAdditionalData['serviceAreaLookup'][zipCode] != null
                  ) {
                    let zipCodeDetails = getProp(formAdditionalData['serviceAreaLookup'], zipCode, {})
                    let regionKey = getProp(zipCodeDetails, 'region', null)
                    // Save Zip Code
                    formInputChange('utility_company', getProp(zipCodeDetails, 'utility_company', null), true)
                    formInputChange('region', getProp(zipCodeDetails, 'region', null), true)
                    if (
                      regionKey != null &&
                      // @ts-expect-error - TODO: reason for error
                      utilityCompaniesList[regionKey] != null
                    ) {
                      // @ts-expect-error - TODO: reason for error
                      formInputs.utility_company.options = objectToArray(utilityCompaniesList[regionKey])
                    } else {
                      formInputs.utility_company.options = objectToArray(utilityCompaniesList['all_regions'])
                    }
                  } else {
                    formInputChange('utility_company', null, true)
                    formInputChange('region', null, true)
                    formInputs.utility_company.options = objectToArray(utilityCompaniesList['all_regions'])
                  }
                } else {
                  formInputChange('utility_company', null, true)
                  formInputChange('region', null, true)
                  formInputs.utility_company.options = objectToArray(utilityCompaniesList['all_regions'])
                }
              }
            })
            .catch((rej_GCBY) => {
              console.error(rej_GCBY)
            })
        }
      }
      const handleKeyDown = (event: any) => {
        if (event != null && (event.keyCode === 9 || event.keyCode === 13) && event.target != null && event.target.defaultValue != null) {
          geocodeAddress(event.target.defaultValue)
        }
      }
      // JSX
      let inputJSX = (
        <Box className="tw-mt-4">
          <FormControl fullWidth>
            <PlacesAutocomplete
              value={formData['address'] as string}
              onChange={(address) => {
                formInputChange('address', address, true)
              }}
              searchOptions={{
                types: ['address'],
                componentRestrictions: { country: 'us' },
              }}
              debounce={200}
              highlightFirstSuggestion
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return (
                  <Autocomplete
                    options={suggestions.map((s: any) => s.description)}
                    freeSolo={true}
                    inputValue={formData['address'] as string}
                    onKeyDown={handleKeyDown}
                    onInputChange={(event) => {
                      if (
                        event != null &&
                        event.target != null &&
                        // @ts-expect-error - TODO: reason for error
                        event.target.textContent != null &&
                        // @ts-expect-error - TODO: reason for error
                        event.target.textContent !== ''
                      ) {
                        // @ts-expect-error - TODO: reason for error
                        geocodeAddress(event.target.textContent)
                      }
                    }}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...getInputProps({
                          placeholder: '',
                        })}
                        disabled={formAdditionalData.disabled_all_inputs === true}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        name="search"
                        // InputProps={{
                        // 	autoComplete: 'off',
                        //   }}
                        label={rLIB('Address')}
                        fullWidth
                      />
                    )}
                  />
                )
              }}
            </PlacesAutocomplete>
          </FormControl>
        </Box>
      )
      return inputJSX
    },
  },
  addressInServiceArea: {
    data_type: 'string',
    key: 'header',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = <></>
      if (
        formData.location_zip_code != null &&
        formData.location_zip_code !== '' &&
        formAdditionalData != null &&
        formAdditionalData['serviceAreaLookup'] != null &&
        // @ts-expect-error - TODO: reason for error
        formAdditionalData['serviceAreaLookup'][formData.location_zip_code] != null
      ) {
        // @ts-expect-error - TODO: reason for error
        if (formAdditionalData['serviceAreaLookup'][formData.location_zip_code]['area'] === 'Core') {
          inputJSX = (
            <Box
              className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
              sx={{ border: '1px solid ' + themeVariables.success_wash }}
            >
              <Typography
                variant="body1"
                sx={{ color: themeVariables.success_wash }}
                className=""
              >
                <Icon
                  icon="thumbs-up"
                  className="tw-mr-2"
                />
                <>
                  {rLIB('Zip Code')} {formData.location_zip_code} {rLIB('is within our service area')}
                </>
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: themeVariables.gray_500 }}
                className="tw-mt-2"
              >
                <>{rLIB("You'll be able to schedule your appointments from our app")}</>
              </Typography>
            </Box>
          )
        } else {
          inputJSX = (
            <Box
              className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
              sx={{ border: '1px solid ' + themeVariables.warning_main }}
            >
              <Typography
                variant="body1"
                sx={{ color: themeVariables.warning_main }}
                className=""
              >
                <Icon
                  icon="thumbs-up"
                  className="tw-mr-2"
                />
                <>
                  {rLIB('Zip Code')} {formData.location_zip_code} {rLIB('is at the edge of our service area')}
                </>
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: themeVariables.gray_500 }}
                className="tw-mt-2"
              >
                <>{rLIB("You'll have to talk to our support team to schedule appointments")}</>
              </Typography>
            </Box>
          )
        }
      } else if (
        formData.location_zip_code != null &&
        formData.location_zip_code !== '' &&
        formAdditionalData != null &&
        formAdditionalData['serviceAreaLookup'] != null &&
        // @ts-expect-error - TODO: reason for error
        formAdditionalData['serviceAreaLookup'][formData.location_zip_code] == null
      ) {
        inputJSX = (
          <Box
            className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
            sx={{ border: '1px solid ' + themeVariables.error_main }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className=""
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              <>
                {rLIB('Zip Code')} {formData.location_zip_code} {rLIB('might not be in our service area')}
              </>
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: themeVariables.gray_500 }}
              className="tw-mt-2"
            ></Typography>
          </Box>
        )
      } else {
        inputJSX = (
          <Box
            className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
            sx={{ border: '1px solid ' + themeVariables.gray_500 }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.gray_500 }}
              className=""
            >
              <Icon
                icon="circle-question"
                className="tw-mr-2"
              />
              {rLIB('Use the address autofill to determine if your address is in one of our service areas')}
            </Typography>
          </Box>
        )
      }
      return inputJSX
    },
  },
  utility_company: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'utility_company',
    label: rLIB('Your Utility Company'),
    required: true,
    options: objectToArray(utilityCompaniesList['all_regions']),
  },
  utility_company_other: {
    key: 'utility_company_other',
    label: rLIB('Your Utility Company'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'utility_company',
        comparator: '==',
        value: 'other',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'utility_company',
        comparator: '==',
        value: 'other',
        conditions: [],
      },
    },
  },
  utility_bill: {
    data_type: 'number',
    input_type: 'text_basic',
    key: 'utility_bill',
    label: rLIB('Your Monthly Utility Bill (not required)'),
    required: false,
  },
  utility_consumption: {
    data_type: 'number',
    input_type: 'text_basic',
    key: 'utility_consumption',
    label: rLIB('Your Monthly Utility Consumption (KW/h)'),
    required: true,
  },
  roof_type: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'roof_type',
    label: rLIB('Roof Type'),
    required: true,
    options: [
      { key: 'Shingles', value: 'Shingle (most common)' },
      { key: 'Metal', value: 'Metal' },
      { key: 'Tile', value: 'Tile' },
    ],
  },
}

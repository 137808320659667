import { Box } from '@mui/material'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import { Icon } from 'rfbp_core/components/icons'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { TsInterface_LSEDetails } from '../interfaces/i_sales_tools'
import * as utilityCompanyJson from '../us_utility_company_info.json'

export const rJSX_LoadingBox = (height: string, width: string) => {
  let loadingBox = (
    <Box sx={{ height: height, width: width, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Icon
        icon="arrows-rotate"
        className="bp_spin tw-mr-2"
      />
    </Box>
  )
  return loadingBox
}

export const CustomCircularProgress = (
  props: CircularProgressProps & {
    value: number
    max: number
    iconName: string
    size: number
    iconColor?: string
    customRender: (value: number, max: number, iconName: string, iconColor: string) => JSX.Element
  },
) => {
  const percentage = Math.round((props.value / props.max) * 100)
  let color = 'white'
  if (props.iconColor) {
    color = props.iconColor
  }
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant="determinate"
        {...props}
        size={props.size}
        value={percentage}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {props.customRender(props.value, props.max, props.iconName, color)}
      </Box>
    </Box>
  )
}

export const formatNumberToDollar = (number: number) => {
  if (typeof number !== 'number') {
    return 'Invalid Input'
  }
  const formattedNumber = number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  return formattedNumber
}

export const formatNumberToPercent = (number: number) => {
  if (typeof number !== 'number') {
    return 'Invalid Input'
  }
  const formattedNumber = number.toFixed(2)
  return formattedNumber
}

export const getUtilityCompanyList = () => {
  const jsonString = JSON.stringify(utilityCompanyJson)
  let jsonData: TsInterface_LSEDetails[] = JSON.parse(jsonString)
  const utilityCompanyList: TsInterface_UnspecifiedObject[] = Object.values(jsonData).map((company: TsInterface_LSEDetails) => {
    return { label: company.name, lseId: company.lseId, key: company.name }
  })
  return utilityCompanyList
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:
			[ ] Typescript - 1 instance of any

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material/'
import {
  TsInterface_DynamicAttributes,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormSettings,
  TsType_InputChangeCallback,
} from 'rfbp_core/components/form'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  formAdditionalData: TsInterface_FormAdditionalData
  formData: TsInterface_FormData
  formHooks: TsInterface_FormHooksObject
  formInput: TsInterface_FormInput
  formSettings: TsInterface_FormSettings
  inputChangeCallback: TsType_InputChangeCallback
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

export const listOfUnitedStates = {
  'Alabama': { key: 'Alabama', value: 'Alabama' },
  'Alaska': { key: 'Alaska', value: 'Alaska' },
  'Arizona': { key: 'Arizona', value: 'Arizona' },
  'Arkansas': { key: 'Arkansas', value: 'Arkansas' },
  'California': { key: 'California', value: 'California' },
  'Colorado': { key: 'Colorado', value: 'Colorado' },
  'Connecticut': { key: 'Connecticut', value: 'Connecticut' },
  'Delaware': { key: 'Delaware', value: 'Delaware' },
  'District of Columbia': { key: 'District of Columbia', value: 'District of Columbia' },
  'Florida': { key: 'Florida', value: 'Florida' },
  'Georgia': { key: 'Georgia', value: 'Georgia' },
  'Hawaii': { key: 'Hawaii', value: 'Hawaii' },
  'Idaho': { key: 'Idaho', value: 'Idaho' },
  'Illinois': { key: 'Illinois', value: 'Illinois' },
  'Indiana': { key: 'Indiana', value: 'Indiana' },
  'Iowa': { key: 'Iowa', value: 'Iowa' },
  'Kansas': { key: 'Kansas', value: 'Kansas' },
  'Kentucky': { key: 'Kentucky', value: 'Kentucky' },
  'Louisiana': { key: 'Louisiana', value: 'Louisiana' },
  'Maine': { key: 'Maine', value: 'Maine' },
  'Maryland': { key: 'Maryland', value: 'Maryland' },
  'Massachusetts': { key: 'Massachusetts', value: 'Massachusetts' },
  'Michigan': { key: 'Michigan', value: 'Michigan' },
  'Minnesota': { key: 'Minnesota', value: 'Minnesota' },
  'Mississippi': { key: 'Mississippi', value: 'Mississippi' },
  'Missouri': { key: 'Missouri', value: 'Missouri' },
  'Montana': { key: 'Montana', value: 'Montana' },
  'Nebraska': { key: 'Nebraska', value: 'Nebraska' },
  'Nevada': { key: 'Nevada', value: 'Nevada' },
  'New Hampshire': { key: 'New Hampshire', value: 'New Hampshire' },
  'New Jersey': { key: 'New Jersey', value: 'New Jersey' },
  'New Mexico': { key: 'New Mexico', value: 'New Mexico' },
  'New York': { key: 'New York', value: 'New York' },
  'North Carolina': { key: 'North Carolina', value: 'North Carolina' },
  'North Dakota': { key: 'North Dakota', value: 'North Dakota' },
  'Ohio': { key: 'Ohio', value: 'Ohio' },
  'Oklahoma': { key: 'Oklahoma', value: 'Oklahoma' },
  'Oregon': { key: 'Oregon', value: 'Oregon' },
  'Pennsylvania': { key: 'Pennsylvania', value: 'Pennsylvania' },
  'Rhode Island': { key: 'Rhode Island', value: 'Rhode Island' },
  'South Carolina': { key: 'South Carolina', value: 'South Carolina' },
  'South Dakota': { key: 'South Dakota', value: 'South Dakota' },
  'Tennessee': { key: 'Tennessee', value: 'Tennessee' },
  'Texas': { key: 'Texas', value: 'Texas' },
  'Utah': { key: 'Utah', value: 'Utah' },
  'Vermont': { key: 'Vermont', value: 'Vermont' },
  'Virginia': { key: 'Virginia', value: 'Virginia' },
  'Washington': { key: 'Washington', value: 'Washington' },
  'West Virginia': { key: 'West Virginia', value: 'West Virginia' },
  'Wisconsin': { key: 'Wisconsin', value: 'Wisconsin' },
  'Wyoming': { key: 'Wyoming', value: 'Wyoming' },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const MultipleChoiceSelectStateUSA = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_formData: TsInterface_FormData = getProp(props, 'formData', {})
  let pr_formInput: TsInterface_FormInput = getProp(props, 'formInput', {})
  let pr_formSettings: TsInterface_FormSettings = getProp(props, 'formSettings', {})
  let pr_inputChangeCallback: TsType_InputChangeCallback = props.inputChangeCallback

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const returnLabelText = (): JSX.Element => {
    let labelJSX = <></>
    if (pr_formInput['required'] === true) {
      labelJSX = (
        <>
          {pr_formInput['label']}
          {' *'}
        </>
      )
    } else {
      labelJSX = <>{pr_formInput['label']}</>
    }
    return labelJSX
  }

  // Generate JSX

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    // Dynamic Class Name
    let dynamicClassName = ''
    // Dynamic Attributes
    let dynamicAttributes: TsInterface_DynamicAttributes = {}
    if (pr_formInput['required'] === true) {
      dynamicAttributes['required'] = true
    }
    if (
      pr_formInput['required'] === true &&
      pr_formSettings.highlight_missing === true &&
      (pr_formData == null || pr_formData[pr_formInput['key']] == null || pr_formData[pr_formInput['key']] === '')
    ) {
      dynamicAttributes['error'] = true
    }
    if (pr_formInput['disabled'] === true) {
      dynamicAttributes['disabled'] = true
    }
    // Form Input JSX
    let componentJSX = (
      <Box>
        <div style={{ width: '100%', height: '16px' }}></div>
        <FormControl
          className={dynamicClassName}
          fullWidth
        >
          <InputLabel
            shrink={true}
            id={pr_formInput['key']}
          >
            {returnLabelText()}
          </InputLabel>
          <Select
            color="primary"
            value={pr_formData[pr_formInput.key] || ''}
            id={pr_formInput['key']}
            // label={ formInput["label"] }
            label={returnLabelText()}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                pr_inputChangeCallback(pr_formInput.key, event.target.value, true)
              }
            }}
            variant="outlined"
            notched={true}
            // InputProps={{
            // 	startAdornment: rJSX_InputAdornment( formInput, "start" ),
            // 	endAdornment: rJSX_InputAdornment( formInput, "end" ),
            // }}
            {...dynamicAttributes}
          >
            {objectToArray(listOfUnitedStates).map((option) => (
              <MenuItem
                key={option['key']}
                value={option['key']}
              >
                {option['value']}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ width: '100%', height: '8px' }}></div>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			// Bug where if you have scheduled PTO in the future, it will show up at the most recent punch event breaking the clock in / out functionality
				// Probably need to have a "type" scheduled vs actual or something and then query only on the actual for the most recent punch event
				// This is also breaking the function that merges the punch events together

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import { useContext, useState } from 'react'
import 'react-clock/dist/Clock.css'
import { Trans } from 'react-i18next'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientUser } from 'rfbp_core/services/context'
import { getProp } from 'rfbp_core/services/helper_functions'
import { Tab as AllocationSettingsTab } from './tabs/tab_admin_allocation_settings'
import { Tab as ClockedInEmployeesTab } from './tabs/tab_admin_map'
import { Tab as TimeSheetReviewTab } from './tabs/tab_admin_review'
import { Tab as UserSettingsTab } from './tabs/tab_admin_user_settings'
import { Tab as ReviewSubmitTab } from './tabs/tab_individual_review'
import { Tab as TimeClockTab } from './tabs/tab_individual_time_clock'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AllTimesheetsIndexPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ALLOCATION_TYPES: JSX.Element = <Trans>Allocation Types</Trans>
const s_CLOCKED_IN_EMPLOYEES: JSX.Element = <Trans>Clocked In Employees</Trans>
const s_REVIEW_AND_SUBMIT: JSX.Element = <Trans>Review and Submit</Trans>
const s_TIME_CLOCK: JSX.Element = <Trans>Time Clock</Trans>
const s_TIME_SHEET_REVIEW: JSX.Element = <Trans>Time Sheet Review</Trans>
const s_USER_SETTINGS: JSX.Element = <Trans>User Settings</Trans>
const se_TIMESHEETS: string = 'Time Sheets'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  // { sort-end } - hooks

  // Hooks - useEffect
  // useEffect(() => {
  // 	document.title = se_TIMESHEETS
  // }, [ ])

  // Functions

  // JSX Generation

  // If hourly
  // - display clock in and clock out button as a tab
  // Pull lat event, either clocked out or clocked in with timer
  // Actually pull whole week and display total hours so far
  // - review and submit as a tab
  // List of each day, total hours, how they were allocated
  // Ability to list PTO, sick, etc

  // If admin permissions, display active (rename), review, and settings
  // Active - map and list of all users who are clocked in
  // Ability to click on users to see past historical locations for a specific day
  // Ability to click on user to pull up their timesheet for the week
  // Review - calendar picker to select date, load week, and then get aggregate hours for each user
  // Highlight if over 40 hours, over alloted times, underutilized, etc
  // Export to XML, CSV, etc
  // Settings - manage default allocations, override allocations, hourly vs salary

  // If salary or null and not admin, display notice saying account access not granted
  // - Call Tab "Timesheet"

  // Maybe make tabs as standalone components that take in some useEffect firebase data as params - test

  const rJSX_TimesheetTabs = (): JSX.Element => {
    let timesheetTabsJSX = <></>
    let tabs: TsInterface_TabContentUrlArray = []
    // @ts-expect-error - TODO: reason for error
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.hourly_or_salaried === 'hourly') {
      tabs.push({
        tabUrlKey: 'Time_Clock',
        tabButtons: [],
        tabHeader: s_TIME_CLOCK,
        tabContent: (
          <Box>
            <TimeClockTab />
          </Box>
        ),
      })
      tabs.push({
        tabUrlKey: 'Review',
        tabButtons: [],
        tabHeader: s_REVIEW_AND_SUBMIT,
        tabContent: (
          <Box>
            <ReviewSubmitTab
              selectedDate={us_selectedDate}
              setSelectedDate={us_setSelectedDate}
            />
          </Box>
        ),
      })
    }
    if (
      uc_RootData_ClientUser != null &&
      (uc_RootData_ClientUser.user_role === 'admin' || getProp(uc_RootData_ClientUser.task_roles, 'admin', false) === true)
    ) {
      tabs.push({
        tabUrlKey: 'Employees',
        tabButtons: [],
        tabHeader: s_CLOCKED_IN_EMPLOYEES,
        tabContent: (
          <Box>
            <ClockedInEmployeesTab
              selectedDate={us_selectedDate}
              setSelectedDate={us_setSelectedDate}
            />
          </Box>
        ),
      })
      tabs.push({
        tabUrlKey: 'Admin_Review',
        tabButtons: [],
        tabHeader: s_TIME_SHEET_REVIEW,
        tabContent: (
          <Box>
            <TimeSheetReviewTab
              selectedDate={us_selectedDate}
              setSelectedDate={us_setSelectedDate}
            />
          </Box>
        ),
      })
      tabs.push({
        tabUrlKey: 'User_Settings',
        tabButtons: [],
        tabHeader: s_USER_SETTINGS,
        tabContent: (
          <Box>
            <UserSettingsTab
              selectedDate={us_selectedDate}
              setSelectedDate={us_setSelectedDate}
            />
          </Box>
        ),
      })
      tabs.push({
        tabUrlKey: 'Allocation_Settings',
        tabButtons: [],
        tabHeader: s_ALLOCATION_TYPES,
        tabContent: (
          <Box>
            <AllocationSettingsTab />
          </Box>
        ),
      })
    }

    timesheetTabsJSX = (
      <Box>
        <TabsUrl
          tabs={tabs}
          tabsSettings={{
            baseUrl: ApplicationPages.AllTimesheetsIndexPage.url(),
            tabQueryParam: 'tab',
            overridePageTitle: true,
            basePageTitle: se_TIMESHEETS,
          }}
        />
      </Box>
    )
    return timesheetTabsJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Time Sheets')}
        pageKey={pageKey}
        content={<Box>{rJSX_TimesheetTabs()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

/* eslint-disable react/display-name */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			example component description

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Divider, Stack, Typography } from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import { MapBasic, TsInterface_MapMarkers } from 'rfbp_core/components/map'
import { TsInterface_MapPolygons } from 'rfbp_core/components/map/map_types'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

const black = '#000000'
const white = '#ffffff'
const teal = '#1E83A1'
const lightTeal = '#33b3d9'
const darkTeal = '#166076'
const lightGray = '#F4F7F9'
const lightGray2 = '#dbe5eb'
const darkGray = '#9C9C9E'
const darkGray2 = '#3D4E59'
const green = '#1ea13c'
const lightGreen = '#33d95e'
const yellow = '#f7a830'

// TODO: Rename and fill out
interface TsInterface_ComponentName {
  editModeActive: boolean
  activePanelSelection: TsInterface_EagleviewActivePanelData
  mapHeight?: string
  mapWidth?: string
  onChange: (value: TsInterface_EagleviewVisualizationCallbackData) => void
  panelData: TsInterface_EagleviewPanelData
  panelsLatOffset?: number
  panelsLngOffset?: number
  disablePanelSelection?: boolean
}

interface TsInterface_EagleviewPanelData_Facet {
  'Average SAV': number
  'Average TSRF': number
  'Azimuth': number
  'Facet Id': string
  'No of Obstructions': number
  'Pitch (degree)': number
  'Sq Ft': number
}

interface TsInterface_EagleviewPanelData_EnergyTable {
  'Facet': string
  'Panel Id': number
  'Production Multiplier': number
  'SAV(Each panel)': number
  'System Production Multiplier': number
  'TSRF(Each panel)': number
  'coordinates': number[][]
  'kW size': number
  'kW size (Cumulative)': number
  'kWh/yr': number
  'kWh/yr(Cumulative)': number
}

export interface TsInterface_EagleviewPanelData {
  // dataOutput: {
  energyTable: TsInterface_EagleviewPanelData_EnergyTable[]
  facet: TsInterface_EagleviewPanelData_Facet[]
  roof: {
    'Existing panels on structure': string
    'Home Classification': string
    'Imagery date': string
    'Job Id': string
    'Max Number of Panels': number
    'Max kW system': number
    'Max kWh/yr system': number
    'Number of Facets': number
    'Predominant Pitch (degree)': number
    'Total Area of Max System': number
    'Total Sq Ft of Roof': number
    'Tree Overhang %': number
    'pointData': string
  }
  // }
  // jobId: string
  // productId: number
}

export interface TsInterface_EagleviewActivePanelData {
  [key: string]: boolean
}

export interface TsInterface_EagleviewVisualizationCallbackData {
  active_panels: TsInterface_EagleviewActivePanelData
  panel_data: TsInterface_EagleviewPanelData
  lat_offset: number
  lng_offset: number
  event_type: string
}

///////////////////////////////
// Variables
///////////////////////////////

const coordinateStepSize = 0.0000025

///////////////////////////////
// Functions
///////////////////////////////

const setInitialActivePanelSelection = (
  activePanelSelection: TsInterface_EagleviewActivePanelData,
  panelData: TsInterface_EagleviewPanelData,
): TsInterface_EagleviewActivePanelData => {
  let combinedActivePanelSelection: TsInterface_EagleviewActivePanelData = {}
  if (panelData != null && panelData['energyTable'] != null) {
    for (let loopPanelIndex in panelData['energyTable']) {
      combinedActivePanelSelection[loopPanelIndex] = true
    }
  }
  if (activePanelSelection != null) {
    for (let loopPanelIndex in activePanelSelection) {
      combinedActivePanelSelection[loopPanelIndex] = getProp(activePanelSelection, loopPanelIndex, true)
    }
  }
  return combinedActivePanelSelection
}

///////////////////////////////
// Container
///////////////////////////////

export const EagleviewProposePanelsVisualization = forwardRef((props: TsInterface_ComponentName, ref: React.ForwardedRef<unknown>): JSX.Element => {
  // Props
  let pr_activePanelSelection: TsInterface_EagleviewActivePanelData = getProp(props, 'activePanelSelection', {})
  let pr_editModeActive: boolean = getProp(props, 'editModeActive', false)
  let pr_panelsLatOffset: number = getProp(props, 'panelsLatOffset', 0)
  let pr_panelsLngOffset: number = getProp(props, 'panelsLngOffset', 0)
  let pr_mapHeight: string = getProp(props, 'mapHeight', 'calc( 100vh - 300px )')
  let pr_mapWidth: string = getProp(props, 'mapWidth', '100%')
  let pr_onChange: TsInterface_ComponentName['onChange'] = getProp(props, 'onChange', (panelData: TsInterface_EagleviewVisualizationCallbackData) => {})
  let pr_panelData: TsInterface_ComponentName['panelData'] = getProp(props, 'panelData', {})
  let pr_disablePanelSelection: TsInterface_ComponentName['disablePanelSelection'] = getProp(props, 'disablePanelSelection', false)

  // Hooks - useContext, useState, useReducer, other
  const ur_mapRef = useRef(null)
  const [us_activePanelSelection, us_setActivePanelSelection] = useState<TsInterface_EagleviewActivePanelData>(
    setInitialActivePanelSelection(pr_activePanelSelection, pr_panelData),
  )
  const [us_latOffset, us_setLatOffset] = useState<number>(pr_panelsLatOffset)
  const [us_lngOffset, us_setLngOffset] = useState<number>(pr_panelsLngOffset)
  const [us_hoveredPanel, us_setHoveredPanel] = useState<string | null | number>('')
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void

  // Hooks - useEffect
  useEffect(() => {
    setTimeout(() => {
      // @ts-expect-error - TODO: reason for error
      if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.mapCenter != null) {
        // @ts-expect-error - TODO: reason for error
        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
          // @ts-expect-error - TODO: reason for error
          ur_mapRef.current.recalculateMapBounds(true)
        }
      }
    }, 0)
  }, [])

  useEffect(() => {
    us_setActivePanelSelection(setInitialActivePanelSelection(pr_activePanelSelection, pr_panelData))
  }, [pr_activePanelSelection, pr_panelData])

  // Functions
  const rData_MapPolygons = (panelData: TsInterface_EagleviewPanelData): TsInterface_MapPolygons => {
    let mapPolygons: TsInterface_MapPolygons = {}
    if (pr_panelData != null && pr_panelData['energyTable'] != null) {
      for (let loopPanelIndex in pr_panelData['energyTable']) {
        let loopPanel = pr_panelData['energyTable'][loopPanelIndex]
        if (loopPanel != null && loopPanel.coordinates != null) {
          // Polygon Colors
          let fillColor = darkGray2
          let strokeOpacity = 0.1
          if (pr_editModeActive) {
            fillColor = themeVariables.success_main
            strokeOpacity = 1
          }
          let fillOpacity = 1
          let strokeColor = '#FFFFFF'

          if (us_activePanelSelection != null && us_activePanelSelection[loopPanelIndex] === false) {
            if (pr_editModeActive) {
              fillColor = themeVariables.error_main
              strokeOpacity = 1
            } else {
              fillOpacity = 0
              strokeOpacity = 0
            }
          }
          if (us_hoveredPanel != null && us_hoveredPanel.toString() === loopPanelIndex.toString()) {
            if (pr_editModeActive) {
              fillColor = themeVariables.warning_main
              strokeOpacity = 1
            } else {
              strokeOpacity = 0.1
            }
          }
          // Polygon Paths
          let loopPolygonPaths: any = []
          for (let loopPolygonIndex in loopPanel.coordinates) {
            loopPolygonPaths.push({
              lat: loopPanel.coordinates[loopPolygonIndex][1] + us_latOffset,
              lng: loopPanel.coordinates[loopPolygonIndex][0] + us_lngOffset,
            })
          }
          loopPolygonPaths.push({
            lat: loopPanel.coordinates[0][1] + us_latOffset,
            lng: loopPanel.coordinates[0][0] + us_lngOffset,
          })
          mapPolygons['panel_' + loopPanelIndex] = {
            // @ts-ignore
            paths: loopPolygonPaths,
            options: {
              fillColor: fillColor,
              fillOpacity: fillOpacity,
              strokeColor: strokeColor,
              strokeOpacity: strokeOpacity,
              strokeWeight: 1,
            },
            additional_data: {
              panelIndex: loopPanelIndex,
              panelData: loopPanel,
            },
            onClick: (event: any) => {
              // console.log('EVENT==================', event)
              if (pr_disablePanelSelection !== true) {
                if (
                  event != null &&
                  event.polygon != null &&
                  event.polygon.additional_data != null &&
                  event.polygon.additional_data.panelIndex != null &&
                  event.polygon.additional_data.panelData != null
                ) {
                  let panelIndex = event.polygon.additional_data.panelIndex
                  let stateUpdateObject: TsInterface_EagleviewActivePanelData = { ...us_activePanelSelection }
                  if (getProp(us_activePanelSelection, panelIndex, true) == true) {
                    stateUpdateObject[panelIndex] = false
                  } else {
                    stateUpdateObject[panelIndex] = true
                  }
                  us_setActivePanelSelection(stateUpdateObject)
                  handleDataChange(stateUpdateObject, us_latOffset, us_lngOffset, 'panel_toggle')
                }
              }
            },
          }
        }
      }
    }
    return mapPolygons
  }

  const rData_MapMarkers = (panelData: TsInterface_EagleviewPanelData): TsInterface_MapMarkers => {
    let mapMarkers: TsInterface_MapMarkers = {}
    if (pr_panelData != null && pr_panelData['energyTable'] != null) {
      for (let loopPanelIndex in pr_panelData['energyTable']) {
        let loopPanel = pr_panelData['energyTable'][loopPanelIndex]
        if (loopPanel != null && loopPanel.coordinates != null) {
          // Get Average Coordinates
          let totalLatitude = 0
          let totalLongitude = 0
          let totalCoordinateCount = 0
          for (let loopCoordinateIndex in loopPanel.coordinates) {
            let loopCoordinate = loopPanel.coordinates[loopCoordinateIndex]
            totalLatitude += loopCoordinate[1]
            totalLongitude += loopCoordinate[0]
            totalCoordinateCount++
          }
          let averageLatitude = totalLatitude / totalCoordinateCount
          let averageLongitude = totalLongitude / totalCoordinateCount
          mapMarkers['panel_' + loopPanelIndex] = {
            position: {
              lat: averageLatitude + us_latOffset,
              // lat: loopPanel.coordinates[0][1] + us_latOffset,
              lng: averageLongitude + us_lngOffset,
              // lng: loopPanel.coordinates[0][0] + us_lngOffset,
            },
            icon: {
              path: 'M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z',
              fillColor: 'rgba(0,0,0,0)',
              fillOpacity: 0,
              strokeWeight: 0,
              strokeColor: 'rgba(0,0,0,0)',
              scale: 0,
            },
            title: '',
            label: {
              color: pr_editModeActive ? 'rgba(255,255,255,0.6)' : 'rgba(0,0,0,0)',
              fontSize: '7px',
              fontWeight: 'bold',
              text: (parseInt(loopPanelIndex) + 1).toString(),
            },
          }
        }
      }
    }
    return mapMarkers
  }

  const handleDataChange = (activePanelSelection: TsInterface_EagleviewActivePanelData, latOffset: number, lngOffset: number, eventType: string): void => {
    pr_onChange({
      active_panels: activePanelSelection,
      panel_data: pr_panelData,
      lat_offset: latOffset,
      lng_offset: lngOffset,
      event_type: eventType,
    })
  }

  // JSX Generation
  const rJSX_Map = (): JSX.Element => {
    return (
      <Box>
        <MapBasic
          mapSettings={{
            center_lat: 0,
            center_lng: 0,
            height: pr_mapHeight,
            render_clusters: false,
            render_heatmap: false,
            render_markers: true,
            width: pr_mapWidth,
            zoom: 20,
            map_type_id: 'satellite',
            additional_map_options: {
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
            },
          }}
          mapMarkers={rData_MapMarkers(pr_panelData)}
          mapPolygons={rData_MapPolygons(pr_panelData)}
          ref={ur_mapRef}
        />
      </Box>
    )
  }

  // TODO: TEMP
  const rJSX_MoveButtonUp = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="warning"
        sx={{ width: '32px', minWidth: '32px', minHeight: '32px', height: '32px' }}
        onClick={() => {
          us_setLatOffset(us_latOffset + coordinateStepSize)
          handleDataChange(us_activePanelSelection, us_latOffset + coordinateStepSize, us_lngOffset, 'offset')
        }}
      >
        <Icon icon="up" />
      </Button>
    )
    return buttonJSX
  }

  const rJSX_MoveButtonDown = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="warning"
        sx={{ width: '32px', minWidth: '32px', minHeight: '32px', height: '32px' }}
        onClick={() => {
          us_setLatOffset(us_latOffset - coordinateStepSize)
          handleDataChange(us_activePanelSelection, us_latOffset - coordinateStepSize, us_lngOffset, 'offset')
        }}
      >
        <Icon icon="down" />
      </Button>
    )
    return buttonJSX
  }

  const rJSX_MoveButtonLeft = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="warning"
        sx={{ width: '32px', minWidth: '32px', minHeight: '32px', height: '32px' }}
        onClick={() => {
          us_setLngOffset(us_lngOffset - coordinateStepSize)
          handleDataChange(us_activePanelSelection, us_latOffset, us_lngOffset - coordinateStepSize, 'offset')
        }}
      >
        <Icon icon="left" />
      </Button>
    )
    return buttonJSX
  }

  const rJSX_MoveButtonRight = (): JSX.Element => {
    let buttonJSX = (
      <Button
        variant="contained"
        color="warning"
        sx={{ width: '32px', minWidth: '32px', minHeight: '32px', height: '32px' }}
        onClick={() => {
          us_setLngOffset(us_lngOffset + coordinateStepSize)
          handleDataChange(us_activePanelSelection, us_latOffset, us_lngOffset + coordinateStepSize, 'offset')
        }}
      >
        <Icon icon="right" />
      </Button>
    )
    return buttonJSX
  }

  const rJSX_MoveButtonPlaceholder = (): JSX.Element => {
    let buttonJSX = <Box sx={{ width: '32px', minWidth: '32px', minHeight: '32px', height: '32px' }}></Box>
    return buttonJSX
  }

  const rJSX_DirectionAdjustArrows = (): JSX.Element => {
    let componentJSX = (
      // <Box sx={{ marginTop: '-100px !important', display: 'inline-block', marginLeft: 'calc(100% - 100px)' }}>
      <Box>
        <Stack direction="row">
          {rJSX_MoveButtonPlaceholder()}
          {rJSX_MoveButtonUp()}
          {rJSX_MoveButtonPlaceholder()}
        </Stack>
        <Stack direction="row">
          {rJSX_MoveButtonLeft()}
          {rJSX_MoveButtonPlaceholder()}
          {rJSX_MoveButtonRight()}
        </Stack>
        <Stack direction="row">
          {rJSX_MoveButtonPlaceholder()}
          {rJSX_MoveButtonDown()}
          {rJSX_MoveButtonPlaceholder()}
        </Stack>
      </Box>
    )
    return componentJSX
  }

  const rJSX_panelSelectionButton = (panel: TsInterface_UnspecifiedObject, panelIndex: number): JSX.Element => {
    let buttonJSX = <></>
    let panelBaseColor = themeVariables.success_main
    let panelOpacity = 1
    if (us_activePanelSelection != null && us_activePanelSelection[panelIndex] === false) {
      panelBaseColor = themeVariables.error_main
    }
    if (pr_disablePanelSelection) {
      panelOpacity = 0.5
    }
    buttonJSX = (
      <Box
        onMouseEnter={() => us_setHoveredPanel(panelIndex)}
        onMouseLeave={() => us_setHoveredPanel('')}
        sx={{
          border: us_hoveredPanel === panelIndex ? '2px solid ' + themeVariables.warning_main : '2px solid ' + panelBaseColor,
          background: panelBaseColor,
          opacity: panelOpacity,
        }}
        className="tw-m-1 tw-rounded tw-px-2 tw-py-1 tw-text-center"
        onClick={() => {
          if (pr_disablePanelSelection !== true) {
            let stateUpdateObject: TsInterface_EagleviewActivePanelData = { ...us_activePanelSelection }
            if (getProp(us_activePanelSelection, panelIndex.toString(), true) == true) {
              stateUpdateObject[panelIndex.toString()] = false
            } else {
              stateUpdateObject[panelIndex.toString()] = true
            }
            us_setActivePanelSelection(stateUpdateObject)
            handleDataChange(stateUpdateObject, us_latOffset, us_lngOffset, 'panel_toggle')
          }
        }}
      >
        <Icon
          icon="solar-panel"
          className="tw-mr-1"
        />
        {panelIndex + 1}
      </Box>
    )

    return buttonJSX
  }

  const rJSX_PanelList = (): JSX.Element => {
    let listJSX = <></>
    listJSX = (
      <Box
        sx={{
          width: '100%',
          height: pr_mapHeight,
          overflowY: 'scroll',
          backgroundColor: themeVariables.background_paper,
        }}
      >
        <Box className="tw-p-1 tw-text-center">
          <Typography
            variant="body1"
            sx={{ color: themeVariables.white, fontWeight: 'bold' }}
          >
            <Icon
              icon="solar-panel"
              className="tw-mr-1"
            />
            {rLIB('Panels')}
          </Typography>
        </Box>
        <Divider className="tw-2" />

        {objectToArray(pr_panelData['energyTable']).map((panel: TsInterface_UnspecifiedObject, index: number) => (
          <Box key={index}>{rJSX_panelSelectionButton(panel, index)}</Box>
        ))}
      </Box>
    )
    return listJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Grid2
        container
        sx={{ flexDirection: 'row' }}
      >
        {pr_editModeActive && (
          <Grid2
            xs={3}
            sm={2}
            md={2}
            lg={1}
            xl={1}
          >
            {rJSX_PanelList()}
          </Grid2>
        )}
        <Grid2
          xs={pr_editModeActive ? 9 : 12}
          sm={pr_editModeActive ? 10 : 12}
          md={pr_editModeActive ? 10 : 12}
          lg={pr_editModeActive ? 11 : 12}
          xl={pr_editModeActive ? 11 : 12}
        >
          <Box sx={{ position: 'relative' }}>
            {rJSX_Map()}
            <Box sx={{ position: 'absolute', right: '10px', bottom: '20px' }}>{rJSX_DirectionAdjustArrows()}</Box>
          </Box>
        </Grid2>
      </Grid2>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
})

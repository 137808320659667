//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Example documentation for map components

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button } from '@mui/material/'
import { useRef } from 'react'
import { MapBasic, TsInterface_MapMarkers, TsInterface_MapSettings, TsType_MapOnClick } from 'rfbp_core/components/map'
import { ComponentDocumentation, TsInterface_ComponentDocumentation } from 'rfbp_core/components/_example_components'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {}

///////////////////////////////
// Variables
///////////////////////////////

// Html strings
const MapBasicHtmlExample = `    <MapBasic
	ref=ur_mapRef} /* Used to call functions inside child component - specifically setting map bounds */
	mapSettings={ mapSettings }
	mapMarkers={ mapMarkers }
	mapOnClick={ mapOnClick }
	/>`

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const ExampleMaps = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const ur_mapRef = useRef(null)
  // { sort-end } - hooks

  // Hooks - useEffect

  // Other Variables
  const mapBasicSettings: TsInterface_MapSettings = {
    center_lat: 40.2338,
    center_lng: -111.6585,
    height: '500px',
    render_clusters: false,
    render_heatmap: false,
    render_markers: true,
    width: '100%',
    zoom: 12,
  }

  const mapBasicOnClick: TsType_MapOnClick = (lat: number, lng: number) => {}

  // const exampleIcon = "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png"
  const exampleIcon = ''

  const mapBasicMarkers: TsInterface_MapMarkers = {
    m1: {
      position: { lat: 40.2338, lng: -111.6585 },
      icon: exampleIcon,
      label: 'Provo',
      onClick: () => {
        console.log('onClick')
      },
      onDblClick: () => {
        console.log('onDblClick')
      },
      onRightClick: () => {
        console.log('onRightClick')
      },
      onMouseOver: () => {
        console.log('onMouseOver')
      },
      onMouseOut: () => {
        console.log('onMouseOut')
      },
      title: 'TITLE',
    },
    m2: { position: { lat: 40.2969, lng: -111.6946 }, icon: exampleIcon, label: 'Orem' },
    m3: { position: { lat: 40.1652, lng: -111.6108 }, icon: exampleIcon, label: 'Springville' },
    m4: { position: { lat: 40.115, lng: -111.6549 }, icon: exampleIcon, label: 'Spanish Fork' },
    m5: { position: { lat: 40.1302, lng: -111.5785 }, icon: exampleIcon, label: 'Mapleton' },
    m6: { position: { lat: 40.507, lng: -111.4133 }, icon: exampleIcon, label: 'Heber' },
    m7: { position: { lat: 40.3641, lng: -111.7385 }, icon: exampleIcon, label: 'Pleasant Grove' },
  }

  const documentationObject: TsInterface_ComponentDocumentation = {
    group_name: 'Maps',
    group_description: 'Components related to maps',
    components: {
      MapBasic: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">MapBasic</span>
          </Box>
        ),
        component_description: 'Basic map display component',
        component_example_jsx_string: MapBasicHtmlExample,
        component_jsx: (
          <Box>
            <MapBasic
              ref={ur_mapRef}
              mapSettings={mapBasicSettings}
              mapMarkers={mapBasicMarkers}
              mapOnClick={mapBasicOnClick}
            />
            <Button
              variant="outlined"
              className="tw-ml-2 tw-px-1 tw-inline-block tw-text-center"
              onClick={(event) => {
                // @ts-expect-error - TODO: reason for error
                if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
                  // @ts-expect-error - TODO: reason for error
                  ur_mapRef.current.recalculateMapBounds(true)
                }
              }}
            >
              {rLIB('Reset Map Bounds')}
            </Button>
          </Box>
        ),
        component_props: {
          mapSettings: {
            propKey: 'mapSettings',
            propTypeOrInterface: 'TsInterface_MapSettings',
            propDescription: 'Settings used for setting up map',
            propExampleValue: mapBasicSettings,
          },
          mapMarkers: {
            propKey: 'mapMarkers',
            propTypeOrInterface: 'TsInterface_MapMarkers',
            propDescription: 'Markers that render on map',
            propExampleValue: mapBasicMarkers,
            // propExampleValue: locations
          },
          mapOnClick: {
            propKey: 'mapOnClick',
            propTypeOrInterface: 'TsType_MapOnClick',
            propDescription: 'Function that returns lat and lng on map click',
            propExampleValue: 'mapOnClick',
          },
        },
        component_todo: {
          1: 'Some Typescript things to work out',
          2: 'Some customization options',
          3: 'Routes',
        },
      },
    },
  }

  // Functions

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <ComponentDocumentation documentation={documentationObject} />
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Utilities_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'utilities', 'main')
}

export const DatabaseRef_Utility_Document = (clientKey: string, utilityKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'utilities', 'main', utilityKey)
}

export const DatabaseRef_Utilities_Search = (clientKey: string, searchProperty: string, searchValue: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: searchProperty, comparator: '>=', value: searchValue },
    { prop: searchProperty, comparator: '<=', value: searchValue + '~' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'directory', 'utilities', 'main'), queryOperatorsArray, orderByArray, {}, limit)
}

export const DatabaseRef_UtilityFiles_Collection = (clientKey: string, utilityKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'utilities', 'main', utilityKey, 'files')
}

export const DatabaseRef_UtilityFile_Document = (clientKey: string, utilityKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'utilities', 'main', utilityKey, 'files', fileKey)
}

// Forum
export const DatabaseRef_UtilityForumThreads_Collection = (clientKey: string, utilityKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'utilities', 'main', utilityKey, 'forum')
}

export const DatabaseRef_UtilityForumThread_Document = (clientKey: string, utilityKey: string, threadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'utilities', 'main', utilityKey, 'forum', threadKey)
}

export const DatabaseRef_UtilityForumMessages_Collection = (clientKey: string, utilityKey: string, threadKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'utilities', 'main', utilityKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_UtilityForumMessage_Document = (
  clientKey: string,
  utilityKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'utilities', 'main', utilityKey, 'forum', threadKey, 'messages', messageKey)
}

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Card, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useContext, useEffect, useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_SalesOpportunity_Document,
  DatabaseRef_SalesOpportunity_PageAnalyticsSessions_Collection,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import { MapBasic } from 'rfbp_core/components/map'
import {
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_CustomDialog, UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnFormattedPhoneNumberString,
} from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_AnalyticsDialog {
  opportunity: TsInterface_UnspecifiedObject
  opportunityKey: string
}

///////////////////////////////
// Functions
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const OpportunityAnalyticsCustomDialog: React.FC<TsInterface_AnalyticsDialog> = ({ opportunity, opportunityKey }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const { uc_setUserInterface_FormDialogDisplay } = 					useContext( Context_UserInterface_FormDialog )
  const [us_analyticsSessions, us_setAnalyticsSessions] = useState<TsInterface_UnspecifiedObject>({})
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAnalyticsSessions(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // eslint-disable-next-line react/prop-types
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_SalesOpportunity_PageAnalyticsSessions_Collection(res_GCK.clientKey, opportunityKey),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, opportunityKey])

  // Functions

  // JSX Generation
  const rJSX_Map = (analyticsSession: TsInterface_UnspecifiedObject): JSX.Element => {
    let mapJSX = <></>
    if (
      analyticsSession != null &&
      analyticsSession.location_metadata != null &&
      analyticsSession.location_metadata.location_latitude != null &&
      analyticsSession.location_metadata.location_longitude != null
    ) {
      mapJSX = (
        <Box className="tw-rounded">
          <MapBasic
            mapMarkers={{
              pin: {
                label: '',
                position: {
                  lat: parseFloat(analyticsSession.location_metadata.location_latitude),
                  lng: parseFloat(analyticsSession.location_metadata.location_longitude),
                },
              },
            }}
            mapSettings={{
              center_lat: parseFloat(analyticsSession.location_metadata.location_latitude),
              center_lng: parseFloat(analyticsSession.location_metadata.location_longitude),
              map_type_id: 'roadmap',
              height: '328px',
              width: '100%',
              zoom: 12,
              tilt: 0,
              render_heatmap: false,
            }}
          />
        </Box>
      )
    } else {
      mapJSX = (
        <Box className="tw-text-center">
          <Box>{rLIB('No Location Data From IP Address')}</Box>
        </Box>
      )
    }
    return mapJSX
  }

  const rJSX_Dialog = (): JSX.Element => {
    let dialogJSX = (
      <Box>
        <Dialog
          className="bp_dialog_xl_width"
          keepMounted
          onClose={() => {
            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
          }}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            // sx={{ backgroundColor: us_backgroundColor }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="magnifying-glass-chart" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {getProp(opportunity, 'name', '')}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent sx={{ padding: '0px' }}>
            {objectToArray(us_analyticsSessions)
              .sort(dynamicSort('timestamp_session_started', 'desc'))
              .map((analyticsSession: TsInterface_UnspecifiedObject, index: number) => (
                <Box
                  key={index}
                  className="tw-p-4"
                >
                  <Card className="tw-p-2">
                    <Box sx={{ fontSize: '18px' }}>
                      <Box className="tw-inline-block">
                        {returnFormattedDate(returnDateFromUnknownDateFormat(analyticsSession.timestamp_session_started), 'D MMM YYYY h:mm a')}
                      </Box>
                      <Box className="tw-inline-block">
                        {analyticsSession.session_length_seconds != null && analyticsSession.session_length_seconds >= 0 ? (
                          <Box className="tw-inline-block tw-opacity-40 tw-ml-2">
                            ({(analyticsSession.session_length_seconds / 60).toFixed(2)} {rLIB('minutes')})
                          </Box>
                        ) : (
                          <></>
                        )}
                      </Box>
                    </Box>
                    <Grid2
                      container
                      spacing={1}
                    >
                      <Grid2
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                      >
                        <Json
                          alphabetized={true}
                          data={getProp(analyticsSession, 'metadata', {})}
                        />
                      </Grid2>
                      <Grid2
                        xs={12}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                      >
                        {rJSX_Map(analyticsSession)}
                      </Grid2>
                    </Grid2>
                    <Box>
                      <Json
                        alphabetized={true}
                        data={getProp(analyticsSession, 'actions_taken', {})}
                      />
                    </Box>
                  </Card>
                </Box>
              ))}
          </DialogContent>
        </Dialog>
      </Box>
    )
    return dialogJSX
  }

  return <>{rJSX_Dialog()}</>
}

///////////////////////////////
// Exports
///////////////////////////////
export const tableColumns_SalesOpportunities: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          let url = ApplicationPages.AdminSalesOpportunityPage.url(rowData.key as string)
          window.open(url, '_blank')
        }
      },
    },
    view_unauthenticated_customer_page: {
      icon: (
        <Icon
          type="solid"
          icon="mobile-screen-button"
        />
      ),
      label: <>{rLIB('View Customer Page')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          let url = ApplicationPages.UnauthenticatedSalesOpportunityPage.url(rowData.key as string) + '?tracking=false'
          window.open(url, '_blank')
        }
      },
    },
    view_analytics: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass-chart"
        />
      ),
      label: <>{rLIB('View Analytics')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: (
                <OpportunityAnalyticsCustomDialog
                  opportunity={rowData}
                  opportunityKey={rowData.key as string}
                />
              ),
              settings: {
                max_width: 'lg',
              },
            },
          })
        }
      },
    },
    undelete: {
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      icon: (
        <Icon
          type="solid"
          icon="wand-magic-sparkles"
        />
      ),
      label: <>{rLIB('Undelete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: rLIB('Undelete Opportunity'),
              icon: (
                <Icon
                  icon="wand-magic-sparkles"
                  type="solid"
                />
              ),
              submit_text: rLIB('Undelete'),
              text: rLIB('Are you sure that you want to undelete this opportunity?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = { status: 'active', timestamp_deleted: new Date() }
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DDD) => {
                          resolve(res_DDD)
                        })
                        .catch((rej_DDD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
    delete: {
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Opportunity'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this opportunity?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = { status: 'deleted', timestamp_deleted: new Date() }
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DDD) => {
                          resolve(res_DDD)
                        })
                        .catch((rej_DDD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  TEMP_icon: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.qualified === true) {
          if (rowData.sessions != null && objectToArray(getProp(rowData, 'sessions', {})).length > 0) {
            cellJSX = (
              <Icon
                sx={{ color: themeVariables.success_main, fontSize: '18px' }}
                tooltip={rLIB('Lead Qualified and Link Viewed')}
                tooltipPlacement="right"
                icon="check-circle"
                type="solid"
              />
            )
          } else {
            cellJSX = (
              <Icon
                sx={{ color: themeVariables.info_main, fontSize: '18px' }}
                tooltip={rLIB('Lead Qualified')}
                tooltipPlacement="right"
                icon="check-circle"
                type="regular"
              />
            )
          }
        } else if (rowData != null && rowData.qualified === false) {
          cellJSX = (
            <Icon
              sx={{ color: themeVariables.gray_700, fontSize: '18px' }}
              tooltip={rLIB('Lead Not Qualified')}
              tooltipPlacement="right"
              icon="skull"
              type="solid"
            />
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  },
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Opportunity Name')
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminSalesOpportunityPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_address: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Address')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.qualified === true) {
          cellJSX = (
            <Box>
              {rowData.address1} {rowData.city}, {rowData.state}, {rowData.postal_code}
            </Box>
          )
        } else if (rowData != null && rowData.qualified === false) {
          cellJSX = <Box className="tw-opacity-30">{rLIB('Lead Not Qualified')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_contact_info: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Contact Info')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.qualified === true) {
          cellJSX = (
            <Box>
              <Box>{rowData.phone != null ? returnFormattedPhoneNumberString(rowData.phone as string) : <></>}</Box>
              <Box>{rowData.email}</Box>
            </Box>
          )
        } else if (rowData != null && rowData.qualified === false) {
          cellJSX = <Box className="tw-opacity-30">{rLIB('Lead Not Qualified')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  },
  TEMP_analytics: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Analytics')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData != null && rowData.sessions != null && objectToArray(getProp(rowData, 'sessions', {})).length > 0) {
          // Get total minutes spent on site
          let totalMinutes = 0
          objectToArray(getProp(rowData, 'sessions', {})).forEach((session) => {
            totalMinutes += session.session_length_seconds
          })
          totalMinutes /= 60
          // Get Last Visit Date
          let lastVisitDate = ''
          objectToArray(getProp(rowData, 'sessions', {})).forEach((session) => {
            if (session.timestamp_session_last_updated != null) {
              if (lastVisitDate === '' || new Date(session.timestamp_session_last_updated) > new Date(lastVisitDate)) {
                lastVisitDate = session.timestamp_session_last_updated
              }
            }
          })
          cellJSX = (
            <Box>
              <Box>
                {objectToArray(getProp(rowData, 'sessions', {})).length} {rLIB('Customer Page Visits')}
              </Box>
              <Box>
                <Box className="tw-inline-block tw-mr-2">{rLIB('Time Spent')}:</Box>
                <Box
                  className="tw-inline-block"
                  sx={{ color: themeVariables.info_main }}
                >
                  {totalMinutes.toFixed(2)} {rLIB('minutes')}
                </Box>
              </Box>
              <Box>
                <Box className="tw-inline-block tw-mr-2">{rLIB('Last Visit')}:</Box>
                <Box
                  className="tw-inline-block"
                  sx={{ color: themeVariables.info_main }}
                >
                  {returnFormattedDate(new Date(lastVisitDate), 'D MMM YYYY h:mm a')}
                </Box>
              </Box>
            </Box>
          )
        } else if (rowData != null && rowData.qualified === false) {
          cellJSX = <Box className="tw-opacity-30">{rLIB('No Customer Page Visits')}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  },
  // created_by_name: TableCellBasic('created_by_name', rLIB('Created By'), 'created_by_name'),
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Creation Date'), 'timestamp_created', 'D MMM YYYY h:mm a', false),
}

export const tableSettings_SalesOpportunities: TsInterface_TableDatabaseSettings = {
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        comparator: '==',
        conditions: [],
        logic_type: 'comparison',
        prop: 'status',
        source: 'rowData',
        value: 'deleted',
      },
    },
  ],
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_created',
  use_live_data: true,
}

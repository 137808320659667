///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_InternalAPILogs_Collection } from 'rfbp_aux/services/database_endpoints/global/internal_api_logs'
import { DatabaseRef_ClientUser_Document, DatabaseRef_Users_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Icon } from 'rfbp_core/components/icons'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_ConfirmDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'
import { rJSX_ApiDocumentation } from './components/api_documentation'
import { returnApiDocumentation } from './data/api_documentation'
import { tableColumns_APILogs, tableSettings_APILogs } from './tables/api_logs'
import { tableColumns_APIUsers, tableSettings_APIUsers } from './tables/api_users'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseAPIManagementIndexPage']['key']

// Hard Coded Location Options
const hardCodedClientKeysWithAPIAccess: TsInterface_UnspecifiedObject = {
  etw_energy: true,
}

// Endpoints - Updated 28 Jun 2024
const endpointOptions = [
  '/cache_ghl_contact/',
  '/cache_ghl_contacts/',
  '/cache_ghl_conversations/',
  '/cache_ghl_message/',
  '/cache_ghl_messages/',
  '/cache_ghl_note/',
  '/cache_ghl_notes/',
  '/cache_ghl_opportunities/',
  '/cache_ghl_outbound_messages/',
  '/cache_ghl_users/',
  '/contacts/',
  '/contacts/:id',
  '/copy_prod_data_to_staging/',
  '/create_ghl_contact/',
  '/create_ghl_contact_eos_project_lookup/',
  '/custom_fields/',
  '/customer_engagement_post_install_messages/',
  '/customer_nps/',
  '/customer_nps/',
  '/filter_lead/',
  '/find_eos_project_from_ghl_contact/',
  '/five_outreach_attempts/',
  '/fsd_calendar_email/',
  '/fsd_upload/',
  '/get_ghl_access_token/',
  '/instantiate_project/',
  '/new_lead_outreach_times/',
  '/nps_non-numeric_analysis/',
  '/projects/contact_logs/:id',
  '/projects/data/:id',
  '/projects/documents/:id',
  '/projects/photos/:id',
  '/projects/sync/:id',
  '/response_times_email/',
  '/send_sms/',
  '/set_appointment_reminder/',
  '/shorten_url/',
  '/test_cron/',
  '/version/',
]

///////////////////////////////
// Functions
///////////////////////////////

const maskString = (str: string): string => {
  // Check if the input string has at least 4 characters
  if (str.length < 4) {
    return str
  }
  // Extract the first 2 and last 2 characters
  const firstTwo = str.slice(0, 3)
  const lastTwo = str.slice(-3)
  // Create a mask with asterisks for the remaining characters
  const mask = str.slice(3, -3).replace(/[^-]/g, '*')
  // Combine the first 2, mask, and last 2 characters
  const maskedString = firstTwo + mask + lastTwo
  return maskedString
}

///////////////////////////////
// Container
///////////////////////////////
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_selectedFilterEndpoint, us_setSelectedFilterEndpoint] = useState<string>('ALL_ENDPOINTS')
  const [us_clientHasAccess, us_setClientHasAccess] = useState<boolean | null>(null)
  const [us_showAPIKey, us_setShowAPIKey] = useState<boolean>(false)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        if (hardCodedClientKeysWithAPIAccess[res_GCK.clientKey] === true) {
          us_setClientHasAccess(true)
        } else {
          us_setClientHasAccess(false)
        }
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'api_management'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    document.title = rLIB('API Management', false) as string
  }, [])

  const tableDatabaseEndpoint_APIUsers = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' },
      { prop: 'task_roles.api_user', comparator: '==', value: true },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Users_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const tableDatabaseEndpoint_APILogs = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    if (us_selectedFilterEndpoint != null && us_selectedFilterEndpoint !== 'ALL_ENDPOINTS') {
      queryOperatorsArray.push({ prop: 'endpoint', comparator: '==', value: us_selectedFilterEndpoint })
    }
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_InternalAPILogs_Collection(), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  // Functions
  const generateAPIKey = (): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let updateObject = {
        api_key: uuidv4(),
      }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, uc_RootData_GlobalUser.key as string), updateObject)
            .then((res_DSMD) => {
              resolve(res_DSMD)
            })
            .catch((rej_DSMD) => {
              reject(rej_DSMD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
          reject(rej_GCK)
        })
    })
  }

  // JSX Generation
  const rJSX_APILogsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="">
        <TableDatabase
          tableAdditionalData={{}}
          tableColumns={tableColumns_APILogs}
          tableDatabaseEndpoint={tableDatabaseEndpoint_APILogs}
          tableSettings={tableSettings_APILogs}
        />
      </Card>
    )
    return tabJSX
  }

  const rJSX_UserManagementTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_APIUsers}
            tableDatabaseEndpoint={tableDatabaseEndpoint_APIUsers}
            tableSettings={tableSettings_APIUsers}
          />
        </Card>
      )
    }
    return tabJSX
  }

  const rJSX_ApiVisibilityButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_showAPIKey === true) {
      buttonJSX = (
        <Button
          size="small"
          variant="contained"
          color="info"
          className="tw-mr-2"
          onClick={() => {
            us_setShowAPIKey(false)
          }}
          disableElevation
          startIcon={
            <Icon
              icon="eyes"
              type="light"
            />
          }
        >
          {rLIB('Hide API Key')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          size="small"
          variant="outlined"
          color="info"
          className="tw-mr-2"
          onClick={() => {
            us_setShowAPIKey(true)
          }}
          disableElevation
          startIcon={
            <Icon
              icon="eyes"
              type="light"
            />
          }
        >
          {rLIB('Show API Key')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_RegenerateAPIKeyButton = (): JSX.Element => {
    let buttonJSX = <></>

    buttonJSX = (
      <Button
        size="small"
        variant="outlined"
        color="error"
        onClick={() => {
          uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Regenerate API Key'),
              icon: (
                <Icon
                  icon="key"
                  type="solid"
                />
              ),
              submit_text: rLIB('Regenerate API Key'),
              text: (
                <>
                  {rLIB('Are you sure that you want to regenerate this API key?')} {rLIB('This will break any integrations using this API key.')}
                </>
              ),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  generateAPIKey()
                    .then((res_GAK) => {
                      resolve(res_GAK)
                    })
                    .catch((rej_GAK) => {
                      reject(rej_GAK)
                    })
                })
              },
            },
          })
        }}
        disableElevation
        startIcon={<Icon icon="key" />}
      >
        {rLIB('Regenerate API Key')}
      </Button>
    )

    return buttonJSX
  }

  const rJSX_AuthTokenTableCellContent = (): JSX.Element => {
    let contentJSX = <></>
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.api_key != null) {
      if (us_showAPIKey === true) {
        contentJSX = (
          <Box>
            <Typography
              variant="body1"
              className="tw-mr-2 tw-inline-block"
            >
              {getProp(uc_RootData_ClientUser, 'api_key', '')}
            </Typography>
            {rJSX_ApiVisibilityButton()}
            {rJSX_RegenerateAPIKeyButton()}
          </Box>
        )
      } else {
        contentJSX = (
          <Box>
            <Typography
              variant="body1"
              className="tw-mr-2 tw-inline-block"
            >
              {maskString(getProp(uc_RootData_ClientUser, 'api_key', ''))}
            </Typography>
            {rJSX_ApiVisibilityButton()}
            {rJSX_RegenerateAPIKeyButton()}
          </Box>
        )
      }
    } else {
      contentJSX = (
        <Button
          size="small"
          variant="outlined"
          color="warning"
          onClick={() => {
            generateAPIKey()
          }}
          disableElevation
          startIcon={<Icon icon="key" />}
        >
          {rLIB('Generate New API Key')}
        </Button>
      )
    }
    return contentJSX
  }

  const rJSX_TokensAndIdsTab = (): JSX.Element => {
    let tabJSX = <></>
    const rJSX_LocationList = () => {
      let locationJSX = <></>
      let apiLocations = getProp(uc_RootData_ClientUser, 'api_locations', {})
      let apiLocationsArray: string[] = []
      for (let loopLocationKey in apiLocations) {
        if (apiLocations[loopLocationKey] === true) {
          apiLocationsArray.push(loopLocationKey)
        }
      }
      locationJSX = (
        <Box>
          {apiLocationsArray.sort().map((locationKey: string, index: number) => (
            <Typography
              variant="body1"
              key={index}
            >
              <Icon
                icon="key"
                sx={{ color: themeVariables.error_main }}
                className="tw-mr-2"
              />
              {locationKey}
            </Typography>
          ))}
        </Box>
      )
      return locationJSX
    }
    // Table JSX
    tabJSX = (
      <Card>
        <TableContainer>
          <Table size="small">
            <TableBody>
              <TableRow>
                <TableCell sx={{ width: '32px' }}></TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    className="tw-opacity-30 tw-font-black"
                  >
                    {rLIB('Fields')}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="body1"
                    className="tw-opacity-30 tw-font-black"
                  >
                    {rLIB('Values')}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: '32px' }}>
                  <Typography variant="body1">
                    <Icon
                      icon="user"
                      sx={{ color: themeVariables.primary_main }}
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">uid</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">{uc_RootData_GlobalUser.key}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: '32px' }}>
                  <Typography variant="body1">
                    <Icon
                      icon="key"
                      sx={{ color: themeVariables.warning_main }}
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">api_key</Typography>
                </TableCell>
                <TableCell>{rJSX_AuthTokenTableCellContent()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ width: '32px' }}>
                  <Typography variant="body1">
                    <Icon
                      icon="location-dot"
                      sx={{ color: themeVariables.error_main }}
                    />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body1">location_key</Typography>
                </TableCell>
                <TableCell>{rJSX_LocationList()}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    )
    return tabJSX
  }

  const rJSX_DocumentationTab = (): JSX.Element => {
    let tabJSX = (
      <Box className="tw-mt-2">
        {rJSX_ApiDocumentation(
          returnApiDocumentation(getProp(uc_RootData_ClientUser, 'api_endpoint_permissions', {}), {
            uid: getProp(uc_RootData_GlobalUser, 'key', null),
            api_key: getProp(uc_RootData_ClientUser, 'api_key', null),
          }),
        )}
      </Box>
    )
    return tabJSX
  }

  const rJSX_FilterLogsSelect = (): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl className="bp_thin_select_input">
          <InputLabel id={'region_filter'}>{rLIB('Endpoint')}</InputLabel>
          <Select
            id={'region_filter'}
            labelId={'region_filter'}
            color="primary"
            value={us_selectedFilterEndpoint}
            label={rLIB('Endpoint')}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedFilterEndpoint(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL_ENDPOINTS'}>{rLIB('All Endpoints')}</MenuItem>
            {endpointOptions.sort().map((option: string, index: number) => (
              <MenuItem
                key={index}
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_PageContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_clientHasAccess === true) {
      if (
        (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role === 'admin') ||
        (uc_RootData_ClientUser != null && uc_RootData_ClientUser.task_roles != null && uc_RootData_ClientUser.task_roles['admin'] === true)
      ) {
        let tabs: TsInterface_TabContentUrlArray = [
          {
            tabUrlKey: 'Documentation',
            tabHeader: rLIB('Documentation'),
            tabContent: rJSX_DocumentationTab(),
          },
          {
            tabUrlKey: 'Logs',
            tabHeader: rLIB('Logs'),
            tabContent: rJSX_APILogsTab(),
            tabButtons: [{ fullJSX: rJSX_FilterLogsSelect(), minJSX: rJSX_FilterLogsSelect(), sizeCutoff: 0 }],
          },
          {
            tabUrlKey: 'Users',
            tabHeader: rLIB('Users with API access'),
            tabContent: rJSX_UserManagementTab(),
          },
        ]
        if (uc_RootData_ClientUser != null && getProp(uc_RootData_ClientUser, 'api_access', null) === true) {
          tabs.push({
            tabUrlKey: 'Your_Keys',
            tabHeader: rLIB('Your API Keys'),
            tabContent: rJSX_TokensAndIdsTab(),
          })
        }
        contentJSX = (
          <TabsUrl
            tabsSettings={{
              baseUrl: ApplicationPages.AdminDatabaseAPIManagementIndexPage.url(),
              tabQueryParam: 'tab',
              overridePageTitle: true,
              basePageTitle: rLIB('API Management', false) as string,
            }}
            tabs={tabs}
          />
        )
      } else if (
        (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role === 'api_user' && getProp(uc_RootData_ClientUser, 'api_access', null) === true) ||
        (uc_RootData_ClientUser != null &&
          uc_RootData_ClientUser.task_roles != null &&
          uc_RootData_ClientUser.task_roles['api_user'] === true &&
          getProp(uc_RootData_ClientUser, 'api_access', null) === true)
      ) {
        contentJSX = (
          <TabsUrl
            tabsSettings={{
              baseUrl: ApplicationPages.AdminDatabaseAPIManagementIndexPage.url(),
              tabQueryParam: 'tab',
              overridePageTitle: true,
              basePageTitle: rLIB('API Management', false) as string,
            }}
            tabs={[
              {
                tabUrlKey: 'Documentation',
                tabHeader: rLIB('Documentation'),
                tabContent: rJSX_DocumentationTab(),
              },
              {
                tabUrlKey: 'Your_Keys',
                tabHeader: rLIB('Your API Keys'),
                tabContent: rJSX_TokensAndIdsTab(),
              },
            ]}
          />
        )
      } else if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.user_role !== '') {
        contentJSX = (
          <Box className="tw-text-center">
            <Typography variant="h6">{rLIB('Your account does not have API access')}</Typography>
            <Typography
              variant="h6"
              className="tw-opacity-40 tw-mt-2"
            >
              {rLIB('Please contact support if you need access')}
            </Typography>
          </Box>
        )
      }
    } else if (us_clientHasAccess === false) {
      contentJSX = (
        <Box className="tw-text-center">
          <Typography variant="h6">{rLIB('Your organization does not have API access')}</Typography>
        </Box>
      )
    }
    return contentJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('API Management')}
        pageKey={pageKey}
        content={<Box>{rJSX_PageContent()}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { formatCurrency } from 'rfbp_core/services/helper_functions'
import { TsInterface_TableAdditionalData, TsInterface_TableColumn, TsInterface_TableDataRow, TsInterface_TableHooks, TsType_TableSortBy } from '../table_types'

///////////////////////////////
// Exports
///////////////////////////////

export const TableCellCurrency = (
  propKey: string,
  propName: string | JSX.Element,
  sortBy: TsType_TableSortBy,
  currency: string = 'USD',
  locale: string = 'en-US',
): TsInterface_TableColumn => {
  return {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{propName}</>
      },
      header_sort_by: sortBy,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const value = rowData[propKey]
        const formattedValue = typeof value === 'number' ? formatCurrency(value, currency, locale) : 'Invalid number'
        return <>{formattedValue}</>
      },
    },
  }
}

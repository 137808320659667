///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Chip, FormControl, MenuItem, Select, Switch, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from '@mui/material/'
import { rJSX_TaskTypeSelector } from 'app/models/tasks/global_tasks'
import { formInputs_DefaultLocationState, formInputs_UserHourlyOrSalaried } from 'app/models/users/user_form'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, ClientUserRoles, returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_TaskBlueprints_Collection } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_TaskWorkflowProd_Document, DatabaseRef_TaskWorkflowsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_workflows'
import { DatabaseRef_TimecardHourlyUsers_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_LifetimePanels_Collection } from 'rfbp_aux/services/database_endpoints/finances/lifetime_panels'
import { DatabaseRef_LifetimeReferrals_Document } from 'rfbp_aux/services/database_endpoints/finances/lifetime_referrals'
import { DatabaseRef_LifetimeUnits_Collection } from 'rfbp_aux/services/database_endpoints/finances/lifetime_units'
import {
  DatabaseRef_PayrollRates_Collection,
  DatabaseRef_PayrollRates_MinimumWage_Document,
  DatabaseRef_PayrollRates_RegionAdders_Document,
} from 'rfbp_aux/services/database_endpoints/finances/payroll_rates'
import { DatabaseRef_ClientUser_Document, DatabaseRef_Users_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TableBasic, TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'
import { unitWorkTypes } from './data/payroll_calculations'
import { listOfStates } from './data/us_states'
import { tableColumns_EmployeePayrollSettings, tableSettings_EmployeePayrollSettings } from './tables/employee_settings'
import { tableColumns_MinimumWage, tableSettings_MinimumWage } from './tables/minimum_wage'
import {
  tableColumns_V1TasksPayrollMapping,
  tableColumns_V2TasksPayrollMapping,
  tableSettings_V1TasksPayrollMapping,
  tableSettings_V2TasksPayrollMapping,
} from './tables/task_payroll_mapping'
import { tableColumns_UnitPayRates, tableSettings_UnitPayRates } from './tables/unit_pay_rates'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminPayrollSettingsPage']['key']

// Unit Pay

// Employee Table

// States

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_lifetimeReferrals, us_setLifetimeReferrals] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeHourlyUsers, us_setActiveHourlyUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clientUserRoles, us_setClientUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lifetimePanelData, us_setLifetimePanelData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_lifetimeUnitData, us_setLifetimeUnitData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_minimumWageData, us_setMinimumWageData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_payrollRates, us_setPayrollRates] = useState<TsInterface_UnspecifiedObject>({})
  const [us_regionLists, us_setRegionLists] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedRegion, us_setSelectedRegion] = useState<string>('')
  const [us_selectedRegionName, us_setSelectedRegionName] = useState<string>('')
  const [us_selectedTaskVersion, us_setSelectedTaskVersion] = useState<string>('v2')
  const [us_selectedV1Workflow, us_setSelectedV1Workflow] = useState<string>('')
  const [us_selectedWorkflowTasks, us_setSelectedWorkflowTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_showAllWithTaskRole, us_setShowAllWithTaskRole] = useState<boolean>(false)
  const [us_taskType, us_setTaskType] = useState<string>('')
  const [us_unitPayMappingTableData, us_setUnitPayMappingTableData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userTableFilter, us_setUserTableFilter] = useState<string>('ALL_INTERNAL_USERS')
  const [us_v1TaskWorkflows, us_setV1TaskWorkflows] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Payroll Settings', false) as string
  }, [])

  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'payroll'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setClientUserRoles(returnClientUserRoles(res_GCK.clientKey))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setPayrollRates(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_PayrollRates_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegionLists(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let v1Workflows: TsInterface_UnspecifiedObject = {}
      for (let loopTaskworkflowKey in newData) {
        let loopTaskworkflow = newData[loopTaskworkflowKey]
        if (loopTaskworkflow['workflow_version_number'] === 1) {
          v1Workflows[loopTaskworkflowKey] = loopTaskworkflow
        }
      }
      us_setV1TaskWorkflows(v1Workflows)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TaskWorkflowsActive_Query(res_GCK.clientKey, 'name', false, null, {}), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let tasks = getProp(newData, 'tasks', {})
      let sowItems = getProp(newData, 'sow_items', {})
      us_setSelectedWorkflowTasks(Object.assign({}, tasks, sowItems))
      ur_forceRerender()
    }
    if (us_selectedV1Workflow != null && us_selectedV1Workflow !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TaskWorkflowProd_Document(res_GCK.clientKey, us_selectedV1Workflow), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_selectedV1Workflow])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setMinimumWageData(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_PayrollRates_MinimumWage_Document(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_selectedV1Workflow])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLifetimePanelData(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_LifetimePanels_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLifetimeUnitData(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_LifetimeUnits_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unitPayMappingTableData: TsInterface_UnspecifiedObject = {}
    for (let loopUnitWorkTypeKey in unitWorkTypes) {
      let loopUnitWorkType = unitWorkTypes[loopUnitWorkTypeKey]
      for (let loopIndex = loopUnitWorkType['min_employees']; loopIndex <= loopUnitWorkType['max_employees']; loopIndex++) {
        unitPayMappingTableData[loopUnitWorkType['key'] + '_' + loopIndex + '_employee'] = {
          key: loopUnitWorkType['key'] + '_' + loopIndex + '_employee',
          unit_type: loopUnitWorkType['unit_type'],
          unit_work_type: loopUnitWorkType['key'],
          name: loopUnitWorkType['name'],
          min_employees: loopUnitWorkType['min_employees'],
          max_employees: loopUnitWorkType['max_employees'],
          abbreviation: loopUnitWorkType['abbreviation'],
          employee_count: loopIndex,
        }
      }
    }
    us_setUnitPayMappingTableData(unitPayMappingTableData)
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLifetimeReferrals(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_LifetimeReferrals_Document(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveHourlyUsers(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TimecardHourlyUsers_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const editHourlyOrSalaried = (userKey: string, user: TsInterface_UnspecifiedObject) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: user,
          formInputs: formInputs_UserHourlyOrSalaried,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, userKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit User')} - {user.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editDefaultState = (userKey: string, user: TsInterface_UnspecifiedObject) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: user,
          formInputs: formInputs_DefaultLocationState,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, userKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {rLIB('Edit User')} - {user.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const tableDatabaseEndpoint_Tasks = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'status', comparator: '==', value: 'active' },
      { prop: 'task_type', comparator: '==', value: us_taskType },
    ]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(
      DatabaseRef_TaskBlueprints_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_User = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    if (us_showAllWithTaskRole === true && tableAdditionalData != null && tableAdditionalData.user_role != null) {
      orderByArray = []
      queryOperatorsArray.push({ prop: 'task_roles.' + tableAdditionalData.user_role, comparator: '==', value: true })
    } else {
      if (tableAdditionalData != null && tableAdditionalData.user_status != null) {
        queryOperatorsArray.push({ prop: 'status', comparator: '==', value: tableAdditionalData.user_status })
      }
      if (tableAdditionalData != null && tableAdditionalData.associated_organization_type != null) {
        queryOperatorsArray.push({ prop: 'associated_organization_type', comparator: '==', value: tableAdditionalData.associated_organization_type })
      } else if (tableAdditionalData != null && tableAdditionalData.user_role != null) {
        queryOperatorsArray.push({ prop: 'user_role', comparator: '==', value: tableAdditionalData.user_role })
      }
    }
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Users_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  // JSX Generation

  // All
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminPayrollIndexPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {rLIB('Back to Payroll')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_RegionDropdown = (): JSX.Element => {
    // JSX
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_selectedRegion}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedRegion(event.target.value)
                us_setSelectedRegionName(us_regionLists[event.target.value]['name'])
              }
            }}
            variant="outlined"
          >
            {objectToArray(us_regionLists)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  // Unit Pay Rates
  const rJSX_UnitPayRatesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Box className="tw-mb-2">{rJSX_RegionDropdown()}</Box>
        <Card>
          <TableBasic
            tableAdditionalData={{
              editable: true,
              us_payrollRates: us_payrollRates,
              us_selectedRegion: us_selectedRegion,
              us_selectedRegionName: us_selectedRegionName,
            }}
            tableColumns={tableColumns_UnitPayRates}
            tableData={objectToArray(us_unitPayMappingTableData)}
            tableSettings={tableSettings_UnitPayRates}
          />
        </Card>
      </Box>
    )
    return tabJSX
  }

  // Employee Base Rates
  const rJSX_EmployeeRatesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Box>
          {rJSX_UserTypeFilterDropdown()}
          {rJSX_ShowAllTaskRolesToggle()}
        </Box>
        {rJSX_EmployeeTable()}
      </Box>
    )
    return tabJSX
  }

  const rJSX_ShowAllTaskRolesToggle = (): JSX.Element => {
    let toggleJSX = (
      <Tooltip
        title={rLIB('Show all users with task roles')}
        placement="top"
      >
        <Switch
          color="info"
          checked={us_showAllWithTaskRole}
          onChange={(event, value) => {
            if (value === true) {
              us_setShowAllWithTaskRole(true)
            } else if (value === false) {
              us_setShowAllWithTaskRole(false)
            }
          }}
        />
      </Tooltip>
    )
    return toggleJSX
  }

  const rJSX_UserTypeFilterDropdown = (): JSX.Element => {
    // TODO - Filter visible user roles

    // if(
    // 	ClientTypes != null &&
    // 	uc_RootData_ClientPermissions != null &&
    // 	uc_RootData_ClientPermissions["client_type"] != null &&
    // 	ClientTypes[ uc_RootData_ClientPermissions["client_type"] ] != null &&
    // 	ClientTypes[ uc_RootData_ClientPermissions["client_type"] ]["user_roles"] != null
    // ){

    // }

    // JSX
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_userTableFilter}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setUserTableFilter(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL_INTERNAL_USERS'}>
              <Chip
                size={'small'}
                label={rLIB('All Internal Users')}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            {objectToArray(us_clientUserRoles)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  <Chip
                    size={'small'}
                    label={ClientUserRoles[option['key']]['name']}
                    color={ClientUserRoles[option['key']]['color']}
                    icon={ClientUserRoles[option['key']]['icon']}
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_EmployeeTable = (): JSX.Element => {
    let tableJSX = <></>
    if (tableSettings_EmployeePayrollSettings.search_settings_database == null) {
      tableSettings_EmployeePayrollSettings.search_settings_database = { search_type: 'firebase' }
    }
    let userStatus = 'active'
    if (us_userTableFilter === 'ALL_DEACTIVATED_USERS') {
      userStatus = 'deleted'
    }
    if (uc_RootData_ClientKey != null) {
      if (us_userTableFilter === 'ALL_INTERNAL_USERS' || us_userTableFilter === 'ALL_EXTERNAL_USERS') {
        let organizationType = 'internal'
        if (us_userTableFilter === 'ALL_EXTERNAL_USERS') {
          organizationType = 'external'
        }
        let tableAdditionalData_AllUsers: TsInterface_TableAdditionalData = {
          associated_organization_type: organizationType,
          task_roles: ClientUserRoles,
          client_type: uc_RootData_ClientPermissions.client_type,
          user_status: userStatus,
          us_payrollRates: us_payrollRates,
          editHourlyOrSalaried: editHourlyOrSalaried,
          editDefaultState: editDefaultState,
          us_lifetimePanelData: us_lifetimePanelData,
          us_lifetimeUnitData: us_lifetimeUnitData,
          us_activeHourlyUsers: us_activeHourlyUsers,
          us_lifetimeReferrals: us_lifetimeReferrals,
        }
        tableJSX = (
          <Card className="tw-mt-4">
            <TableDatabase
              tableAdditionalData={tableAdditionalData_AllUsers}
              tableColumns={tableColumns_EmployeePayrollSettings}
              tableDatabaseEndpoint={tableDatabaseEndpoint_User}
              tableSettings={tableSettings_EmployeePayrollSettings}
            />
          </Card>
        )
      } else if (us_userTableFilter === 'ALL_USERS' || us_userTableFilter === 'ALL_DEACTIVATED_USERS') {
        let tableAdditionalData_AllUsers: TsInterface_TableAdditionalData = {
          task_roles: ClientUserRoles,
          client_type: uc_RootData_ClientPermissions.client_type,
          user_status: userStatus,
          us_payrollRates: us_payrollRates,
          editHourlyOrSalaried: editHourlyOrSalaried,
          editDefaultState: editDefaultState,
          us_lifetimePanelData: us_lifetimePanelData,
          us_lifetimeUnitData: us_lifetimeUnitData,
          us_activeHourlyUsers: us_activeHourlyUsers,
          us_lifetimeReferrals: us_lifetimeReferrals,
        }
        tableJSX = (
          <Card className="tw-mt-4">
            <TableDatabase
              tableAdditionalData={tableAdditionalData_AllUsers}
              tableColumns={tableColumns_EmployeePayrollSettings}
              tableDatabaseEndpoint={tableDatabaseEndpoint_User}
              tableSettings={tableSettings_EmployeePayrollSettings}
            />
          </Card>
        )
      } else {
        let tableAdditionalData_User: TsInterface_TableAdditionalData = {
          user_role: us_userTableFilter,
          task_roles: ClientUserRoles,
          client_type: uc_RootData_ClientPermissions.client_type,
          user_status: userStatus,
          us_payrollRates: us_payrollRates,
          editHourlyOrSalaried: editHourlyOrSalaried,
          editDefaultState: editDefaultState,
          us_lifetimePanelData: us_lifetimePanelData,
          us_lifetimeUnitData: us_lifetimeUnitData,
          us_activeHourlyUsers: us_activeHourlyUsers,
          us_lifetimeReferrals: us_lifetimeReferrals,
        }
        tableJSX = (
          <Card className="tw-mt-4">
            <TableDatabase
              tableAdditionalData={tableAdditionalData_User}
              tableColumns={tableColumns_EmployeePayrollSettings}
              tableDatabaseEndpoint={tableDatabaseEndpoint_User}
              tableSettings={tableSettings_EmployeePayrollSettings}
            />
          </Card>
        )
      }
    }
    return tableJSX
  }

  // Minimum Wage
  const rJSX_MinimumWageTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Card>
          <TableBasic
            tableAdditionalData={{
              us_minimumWageData: us_minimumWageData,
            }}
            tableColumns={tableColumns_MinimumWage}
            tableData={objectToArray(listOfStates)}
            tableSettings={tableSettings_MinimumWage}
          />
        </Card>
      </Box>
    )
    return tabJSX
  }

  // Task Mapping
  const rJSX_TaskVersionDropdown = (): JSX.Element => {
    // JSX
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_selectedTaskVersion}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedTaskVersion(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'v1'}>{rLIB('Task Workflow Version')} 1</MenuItem>
            <MenuItem value={'v2'}>{rLIB('Task Workflow Version')} 2</MenuItem>
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_TaskMappingTab = (): JSX.Element => {
    let taskTableJSX = <></>
    let taskSelectorDropdownJSX = <></>
    if (us_selectedTaskVersion === 'v2') {
      taskTableJSX = rJSX_TaskTableV2()
      taskSelectorDropdownJSX = rJSX_TaskTypeSelector(us_taskType, us_setTaskType, 'all')
    } else if (us_selectedTaskVersion === 'v1') {
      taskTableJSX = rJSX_TaskTableV1()
      taskSelectorDropdownJSX = rJSX_WorkflowSelection()
    }
    let tabJSX = (
      <Box>
        <Box>
          <Box>
            {rJSX_TaskVersionDropdown()}
            {taskSelectorDropdownJSX}
          </Box>
          {taskTableJSX}
        </Box>
      </Box>
    )
    return tabJSX
  }

  const rJSX_TaskTableV2 = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="tw-mt-4">
          <TableDatabase
            tableAdditionalData={{
              editable: true,
              us_payrollRates: us_payrollRates,
            }}
            tableColumns={tableColumns_V2TasksPayrollMapping}
            tableDatabaseEndpoint={tableDatabaseEndpoint_Tasks}
            tableSettings={tableSettings_V2TasksPayrollMapping}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_WorkflowSelection = (): JSX.Element => {
    let selectJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_selectedV1Workflow}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedV1Workflow(event.target.value)
              }
            }}
            variant="outlined"
          >
            {objectToArray(us_v1TaskWorkflows)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return selectJSX
  }

  const rJSX_TaskTableV1 = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="tw-mt-4">
          <TableBasic
            tableAdditionalData={{
              editable: true,
              us_payrollRates: us_payrollRates,
            }}
            tableColumns={tableColumns_V1TasksPayrollMapping}
            tableData={objectToArray(us_selectedWorkflowTasks)}
            tableSettings={tableSettings_V1TasksPayrollMapping}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_EfficiencyCalculationToggle = (regionKey: string, worktype: string): JSX.Element => {
    let toggleJSX = <></>
    if (unitWorkTypes != null && unitWorkTypes[worktype] != null && unitWorkTypes[worktype]['include_in_efficiency_calculation'] === true) {
      toggleJSX = (
        <Icon
          icon="toggle-large-on"
          className="tw-text-success_main"
          sx={{ fontSize: '24px' }}
        />
      )
    } else {
      toggleJSX = (
        <Icon
          icon="toggle-large-off"
          className="tw-text-error_main"
          sx={{ fontSize: '24px' }}
        />
      )
    }
    return toggleJSX
  }

  const rJSX_LifetimeCalculationToggle = (regionKey: string, worktype: string): JSX.Element => {
    let toggleJSX = <></>
    if (unitWorkTypes != null && unitWorkTypes[worktype] != null && unitWorkTypes[worktype]['include_in_lifetime_calculation'] === true) {
      toggleJSX = (
        <Icon
          icon="toggle-large-on"
          className="tw-text-success_main"
          sx={{ fontSize: '24px' }}
        />
      )
    } else {
      toggleJSX = (
        <Icon
          icon="toggle-large-off"
          className="tw-text-error_main"
          sx={{ fontSize: '24px' }}
        />
      )
    }
    return toggleJSX
  }

  // Adders
  const rJSX_DistanceInstallEdit = (region: TsInterface_UnspecifiedObject, regionKey: string, worktype: string): JSX.Element => {
    let editJSX = <></>
    let formInputs_DistanceCutoff: TsInterface_FormInputs = {
      cutoff_miles: {
        key: 'cutoff_miles',
        label: rLIB('Mile Cutoff'),
        input_type: 'text_number',
        required: true,
        data_type: 'number',
      },
      units: {
        key: 'units',
        label: rLIB('Units'),
        input_type: 'text_number',
        required: true,
        data_type: 'number',
      },
    }
    editJSX = (
      <Icon
        icon="circle-plus"
        tooltip={rLIB('Add distance cutoff')}
        tooltipPlacement="right"
        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-success_main"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: formInputs_DistanceCutoff,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          [regionKey]: {
                            ['mileage_cutoffs_' + worktype]: {
                              [formSubmittedData.cutoff_miles]: {
                                cutoff_miles: formSubmittedData.cutoff_miles,
                                units: formSubmittedData.units,
                              },
                            },
                          },
                        }
                        DatabaseSetMergeDocument(DatabaseRef_PayrollRates_RegionAdders_Document(res_GCK.clientKey), updateObject)
                          .then((res_DSMD) => {
                            resolve({ success: true })
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: (
                  <>
                    {rLIB('New Distance Adder Cutoff')} - {region.name}
                  </>
                ),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    return editJSX
  }

  const rJSX_DistanceInstallRows = (regionKey: string, worktype: string): JSX.Element => {
    let rowJSX = <></>
    if (
      us_payrollRates != null &&
      us_payrollRates['region_adders'] != null &&
      us_payrollRates['region_adders'][regionKey] != null &&
      us_payrollRates['region_adders'][regionKey]['mileage_cutoffs_' + worktype] != null
    ) {
      rowJSX = (
        <Box>
          {objectToArray(us_payrollRates['region_adders'][regionKey]['mileage_cutoffs_' + worktype])
            .sort(dynamicSort('cutoff_miles', null))
            .map((distanceData: TsInterface_UnspecifiedObject, distanceIndex: number) => (
              <Box key={distanceIndex}>
                <Box className="tw-text-info_main tw-inline-block">
                  {distanceData['cutoff_miles']} {rLIB('Miles')} - {distanceData['units']} {rLIB('Units')}
                </Box>
                <Icon
                  icon="trash"
                  className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-error_main"
                  tooltip={rLIB('Delete')}
                  tooltipPlacement="right"
                  onClick={() => {
                    uc_setUserInterface_ConfirmDialogDisplay({
                      display: true,
                      confirm: {
                        color: 'error',
                        icon: <Icon icon="trash-xmark" />,
                        header: rLIB('Delete Cutoff'),
                        text: <>{rLIB('Are you sure that you want to delete this mileage cutoff?')}</>,
                        submit_text: rLIB('Delete'),
                        submit_callback: () => {
                          return new Promise((resolve, reject) => {
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = {
                                  [regionKey]: {
                                    ['mileage_cutoffs_' + worktype]: {
                                      [distanceData['cutoff_miles']]: null,
                                    },
                                  },
                                }
                                DatabaseSetMergeDocument(DatabaseRef_PayrollRates_RegionAdders_Document(res_GCK.clientKey), updateObject)
                                  .then((res_DSMD) => {
                                    resolve({ success: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                    })
                  }}
                />
              </Box>
            ))}
        </Box>
      )
    }
    return rowJSX
  }

  const rJSX_PitchInstallEdit = (region: TsInterface_UnspecifiedObject, regionKey: string, worktype: string): JSX.Element => {
    let editJSX = <></>
    let formInputs_PitchCutoff: TsInterface_FormInputs = {
      cutoff_pitch: {
        key: 'cutoff_pitch',
        label: rLIB('Cutoff Pitch'),
        input_type: 'text_number',
        required: true,
        data_type: 'number',
      },
      units: {
        key: 'units',
        label: rLIB('Units'),
        input_type: 'text_number',
        required: true,
        data_type: 'number',
      },
    }
    editJSX = (
      <Icon
        icon="circle-plus"
        tooltip={rLIB('Add distance cutoff')}
        tooltipPlacement="right"
        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-success_main"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: formInputs_PitchCutoff,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          [regionKey]: {
                            ['pitch_cutoffs_' + worktype]: {
                              [formSubmittedData.cutoff_pitch]: {
                                cutoff_pitch: formSubmittedData.cutoff_pitch,
                                units: formSubmittedData.units,
                              },
                            },
                          },
                        }
                        DatabaseSetMergeDocument(DatabaseRef_PayrollRates_RegionAdders_Document(res_GCK.clientKey), updateObject)
                          .then((res_DSMD) => {
                            resolve({ success: true })
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: (
                  <>
                    {rLIB('New Distance Adder Cutoff')} - {region.name}
                  </>
                ),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    return editJSX
  }

  const rJSX_PitchInstallRows = (regionKey: string, worktype: string): JSX.Element => {
    let rowJSX = <></>
    if (
      us_payrollRates != null &&
      us_payrollRates['region_adders'] != null &&
      us_payrollRates['region_adders'][regionKey] != null &&
      us_payrollRates['region_adders'][regionKey]['pitch_cutoffs_' + worktype] != null
    ) {
      rowJSX = (
        <Box>
          {objectToArray(us_payrollRates['region_adders'][regionKey]['pitch_cutoffs_' + worktype])
            .sort(dynamicSort('cutoff_pitch', null))
            .map((distanceData: TsInterface_UnspecifiedObject, distanceIndex: number) => (
              <Box key={distanceIndex}>
                <Box className="tw-text-info_main tw-inline-block">
                  {distanceData['cutoff_pitch']}° - {distanceData['units']} {rLIB('Units')}
                </Box>
                <Icon
                  icon="trash"
                  className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-error_main"
                  tooltip={rLIB('Delete')}
                  tooltipPlacement="right"
                  onClick={() => {
                    uc_setUserInterface_ConfirmDialogDisplay({
                      display: true,
                      confirm: {
                        color: 'error',
                        icon: <Icon icon="trash-xmark" />,
                        header: rLIB('Delete Cutoff'),
                        text: <>{rLIB('Are you sure that you want to delete this mileage cutoff?')}</>,
                        submit_text: rLIB('Delete'),
                        submit_callback: () => {
                          return new Promise((resolve, reject) => {
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = {
                                  [regionKey]: {
                                    ['pitch_cutoffs_' + worktype]: {
                                      [distanceData['cutoff_pitch']]: null,
                                    },
                                  },
                                }
                                DatabaseSetMergeDocument(DatabaseRef_PayrollRates_RegionAdders_Document(res_GCK.clientKey), updateObject)
                                  .then((res_DSMD) => {
                                    resolve({ success: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                    })
                  }}
                />
              </Box>
            ))}
        </Box>
      )
    }
    return rowJSX
  }

  const rJSX_BatteryInstallEdit = (region: TsInterface_UnspecifiedObject, regionKey: string, worktype: string): JSX.Element => {
    let editJSX = <></>
    let formInputs_BatteryCutoff: TsInterface_FormInputs = {
      cutoff_battery: {
        key: 'cutoff_battery',
        label: rLIB('Battery Cutoff'),
        input_type: 'text_number',
        required: true,
        data_type: 'number',
      },
      units: {
        key: 'units',
        label: rLIB('Units'),
        input_type: 'text_number',
        required: true,
        data_type: 'number',
      },
    }
    editJSX = (
      <Icon
        icon="circle-plus"
        tooltip={rLIB('Add Battery Cutoff')}
        tooltipPlacement="right"
        className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-success_main"
        onClick={() => {
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: {},
                formInputs: formInputs_BatteryCutoff,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject = {
                          [regionKey]: {
                            ['battery_cutoffs_' + worktype]: {
                              [formSubmittedData.cutoff_battery]: {
                                cutoff_battery: formSubmittedData.cutoff_battery,
                                units: formSubmittedData.units,
                              },
                            },
                          },
                        }
                        DatabaseSetMergeDocument(DatabaseRef_PayrollRates_RegionAdders_Document(res_GCK.clientKey), updateObject)
                          .then((res_DSMD) => {
                            resolve({ success: true })
                          })
                          .catch((rej_DSMD) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: (
                  <>
                    {rLIB('New Distance Adder Cutoff')} - {region.name}
                  </>
                ),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }}
      />
    )
    return editJSX
  }

  const rJSX_BatteryInstallRows = (regionKey: string, worktype: string): JSX.Element => {
    let rowJSX = <></>
    const rJSX_BatteryInstallText = (number: number): JSX.Element => {
      let textJSX = rLIB('Battery') as JSX.Element
      if (number > 1) {
        textJSX = rLIB('Batteries') as JSX.Element
      }
      return textJSX
    }
    if (
      us_payrollRates != null &&
      us_payrollRates['region_adders'] != null &&
      us_payrollRates['region_adders'][regionKey] != null &&
      us_payrollRates['region_adders'][regionKey]['battery_cutoffs_' + worktype] != null
    ) {
      rowJSX = (
        <Box>
          {objectToArray(us_payrollRates['region_adders'][regionKey]['battery_cutoffs_' + worktype])
            .sort(dynamicSort('cutoff_battery', null))
            .map((distanceData: TsInterface_UnspecifiedObject, distanceIndex: number) => (
              <Box key={distanceIndex}>
                <Box className="tw-text-info_main tw-inline-block">
                  {distanceData['cutoff_battery']} {rJSX_BatteryInstallText(distanceData['cutoff_battery'])} - {distanceData['units']} {rLIB('Units')}
                </Box>
                <Icon
                  icon="trash"
                  className="tw-ml-2 tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer hover:tw-text-error_main"
                  tooltip={rLIB('Delete')}
                  tooltipPlacement="right"
                  onClick={() => {
                    uc_setUserInterface_ConfirmDialogDisplay({
                      display: true,
                      confirm: {
                        color: 'error',
                        icon: <Icon icon="trash-xmark" />,
                        header: rLIB('Delete Cutoff'),
                        text: <>{rLIB('Are you sure that you want to delete this mileage cutoff?')}</>,
                        submit_text: rLIB('Delete'),
                        submit_callback: () => {
                          return new Promise((resolve, reject) => {
                            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                              .then((res_GCK) => {
                                let updateObject = {
                                  [regionKey]: {
                                    ['battery_cutoffs_' + worktype]: {
                                      [distanceData['cutoff_battery']]: null,
                                    },
                                  },
                                }
                                DatabaseSetMergeDocument(DatabaseRef_PayrollRates_RegionAdders_Document(res_GCK.clientKey), updateObject)
                                  .then((res_DSMD) => {
                                    resolve({ success: true })
                                  })
                                  .catch((rej_DSMD) => {
                                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                  })
                              })
                              .catch((rej_GCK) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                              })
                          })
                        },
                      },
                    })
                  }}
                />
              </Box>
            ))}
        </Box>
      )
    }
    return rowJSX
  }

  const rJSX_AdderTabRegionContent = (): JSX.Element => {
    let contentJSX = <></>
    if (us_selectedRegion != null && us_selectedRegion !== '' && us_regionLists != null && us_regionLists[us_selectedRegion] != null) {
      contentJSX = (
        <Card>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>{rLIB('Work Type')}</TableCell>
                  <TableCell>{rLIB('Unit Type')}</TableCell>
                  <TableCell>{rLIB('Efficiency Calc')}</TableCell>
                  <TableCell>{rLIB('Lifetime Calc')}</TableCell>
                  <TableCell>{rLIB('Distance Adder')}</TableCell>
                  <TableCell>{rLIB('Pitch Adder')}</TableCell>
                  <TableCell>{rLIB('Battery Adder')}</TableCell>
                </TableRow>
              </TableBody>
              {objectToArray(unitWorkTypes)
                .sort(dynamicSort('abbreviation', null))
                .map((unitWorkType: TsInterface_UnspecifiedObject, index: number) => (
                  <TableBody key={index}>
                    <TableRow>
                      <TableCell>{unitWorkType.abbreviation}</TableCell>
                      <TableCell>{unitWorkType.unit_type}</TableCell>
                      <TableCell>
                        <Box>{rJSX_EfficiencyCalculationToggle(us_selectedRegion, unitWorkType.key)}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>{rJSX_LifetimeCalculationToggle(us_selectedRegion, unitWorkType.key)}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>{rJSX_DistanceInstallRows(us_selectedRegion, unitWorkType.key)}</Box>
                        <Box>{rJSX_DistanceInstallEdit(us_regionLists[us_selectedRegion], us_selectedRegion, unitWorkType.key)}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>{rJSX_PitchInstallRows(us_selectedRegion, unitWorkType.key)}</Box>
                        <Box>{rJSX_PitchInstallEdit(us_regionLists[us_selectedRegion], us_selectedRegion, unitWorkType.key)}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>{rJSX_BatteryInstallRows(us_selectedRegion, unitWorkType.key)}</Box>
                        <Box>{rJSX_BatteryInstallEdit(us_regionLists[us_selectedRegion], us_selectedRegion, unitWorkType.key)}</Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ))}
            </Table>
          </TableContainer>
        </Card>
      )
    } else {
      contentJSX = <Box className="tw-mt-4 tw-text-center">{rLIB('Select a Region')}</Box>
    }
    return contentJSX
  }

  const rJSX_AddersTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Box className="tw-mb-2">{rJSX_RegionDropdown()}</Box>
        {rJSX_AdderTabRegionContent()}
      </Box>
    )
    return tabJSX
  }

  // Page
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Payroll Settings')}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <Box>
              <TabsUrl
                tabs={[
                  {
                    tabUrlKey: 'Unit_Pay_Rates',
                    tabHeader: rLIB('Unit Pay Rates'),
                    tabContent: rJSX_UnitPayRatesTab(),
                  },
                  {
                    tabUrlKey: 'Task_Mapping',
                    tabHeader: rLIB('Task Mapping'),
                    tabContent: rJSX_TaskMappingTab(),
                  },
                  {
                    tabUrlKey: 'Adders',
                    tabHeader: rLIB('Adders'),
                    tabContent: rJSX_AddersTab(),
                  },
                  {
                    tabUrlKey: 'Employee_Rates',
                    tabHeader: rLIB('Employee Base Rates'),
                    tabContent: rJSX_EmployeeRatesTab(),
                  },
                  {
                    tabUrlKey: 'Minimum_Wage',
                    tabHeader: rLIB('Minimum Wage'),
                    tabContent: rJSX_MinimumWageTab(),
                  },
                ]}
                tabsSettings={{
                  baseUrl: ApplicationPages.AdminPayrollSettingsPage.url(),
                  tabQueryParam: 'tab',
                  overridePageTitle: true,
                  basePageTitle: rLIB('Payroll Settings', false) as string,
                }}
              />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

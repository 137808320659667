///////////////////////////////
// Imports
///////////////////////////////

import { doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_LifetimeReferrals_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'lifetime_referrals', 'main', 'all')
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:

			Disabled submit button seems to aggresive (have to click on a few inputs)

			On save verify that the day is in thje right week

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Typography } from '@mui/material/'
import { LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { returnClockInOptions } from 'app/models/timesheets/timesheet_functions'
import { formInputs_ClockInAllocationSelection } from 'app/models/users/user_form'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { DatabaseRef_CancelledScheduledTasksOnDateForSpecificUser_Query } from 'rfbp_aux/services/database_endpoints/operations/cancelled_scheduled_tasks'
import { DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatabaseRef_TimeSheetsAllocationTypes_Document } from 'rfbp_aux/services/database_endpoints/timesheets/allocation_types'
import { DatabaseRef_TimePunchesHistory_Document, DatabaseRef_TimePunches_Document } from 'rfbp_aux/services/database_endpoints/timesheets/time_punches'
import {
  Form,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import {
  generateRandomString,
  getProp,
  returnDateCorrectedForTimezoneOffset,
  returnFormattedDate,
  returnFormattedDateKey,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import {
  TsInterface_UnspecifiedObject,
  TsType_MuiComponentColorsWithInherit,
  TsType_UnknownPromise,
  TsType_VoidFunction,
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  type: 'new' | 'edit'
  userKey: string | null
  punchData: TsInterface_UnspecifiedObject
  startDate: Date | null
  endDate: Date | null
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_AND: JSX.Element = <Trans>and</Trans>
const s_CLOCK_IN: JSX.Element = <Trans>Clock In</Trans>
const s_CLOCK_OUT: JSX.Element = <Trans>Clock Out</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing required parameters</Trans>
const s_NEW_DATE_MUST_BE_BETWEEN: JSX.Element = <Trans>New date must be between</Trans>
const s_NEW_DATE_MUST_BE_IN_THE_SAME_WEEK_AS_THE_ORIGINAL_DATE: JSX.Element = <Trans>New date must be in the same week as the original date</Trans>
const s_SAVE: JSX.Element = <Trans>Save</Trans>
const s_TIME: JSX.Element = <Trans>Time</Trans>
const s_UNABLE_TO_SAVE_PUNCH: JSX.Element = <Trans>Unable to save punch</Trans>
const s_UNSUPPORTED_ACTION: JSX.Element = <Trans>Unsupported Action</Trans>
// { sort-end } - displayed text

// Forms
const formInputs_DateAndTime: TsInterface_FormInputs = {
  // date: {
  // 	data_type: "string",
  // 	input_type: "timestamp_date",
  // 	key: "date",
  // 	label: rLIB("Date"),
  // 	required: true,
  // },
  time: {
    data_type: 'string',
    input_type: 'timestamp_time',
    key: 'time',
    label: s_TIME,
    required: true,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

const getStartAndEndOfWeek = (date: Date) => {
  const startOfWeek = new Date(date)
  startOfWeek.setHours(0, 0, 0, 0)
  startOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 0)
  const endOfWeek = new Date(date)
  endOfWeek.setHours(23, 59, 59, 999)
  endOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 6)
  return {
    startOfWeek,
    endOfWeek,
  }
}

let datePickerCSS: string = `
		#custom_static_date_picker .MuiDatePickerToolbar-root {
			display: none;
		}
		#custom_static_date_picker .MuiDialogActions-root {
			display: none;
		}
		#custom_static_date_picker .MuiPickerStaticWrapper-content {
			background: rgba(0,0,0,0);
		}
	`

///////////////////////////////
// Component
///////////////////////////////

export const TimesheetPunchEdit = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_adminTimeAllocationTypes, us_setAdminTimeAllocationTypes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_nonWorkingTimeAllocationTypes, us_setNonWorkingTimeAllocationTypes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_originalPunchData, us_setOriginalPunchData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_punchDate, us_setPunchDate] = useState<string | null>(null)
  const [us_punchDateAndTimeFormData, us_setPunchDateAndTimeFormData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_punchSelectedAllocation, us_setPunchSelectedAllocation] = useState<TsInterface_UnspecifiedObject>({})
  const [us_punchTime, us_setPunchTime] = useState<string | null>(null)
  const [us_punchType, us_setPunchType] = useState<string | null>(null)
  const [us_scheduledCancelledTasks, us_setScheduledCancelledTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasks, us_setScheduledTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userClockInOptions, us_setUserClockInOptions] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    us_setOriginalPunchData({ ...props.punchData })
    return () => {}
  }, [props])

  useEffect(() => {
    if (props != null && props.punchData != null) {
      us_setPunchType(getProp(props.punchData, 'type', null))
      us_setPunchDate(returnFormattedDate(getProp(props.punchData, 'timestamp', new Date()), 'YYYY-MM-DD'))
      us_setPunchTime(returnFormattedDate(getProp(props.punchData, 'timestamp', new Date()), 'HH:mm'))
      us_setPunchDateAndTimeFormData({
        date: returnFormattedDate(getProp(props.punchData, 'timestamp', new Date()), 'YYYY-MM-DD'),
        time: returnFormattedDate(getProp(props.punchData, 'timestamp', new Date()), 'HH:mm'),
      })
      let allocationTypeKey = getProp(props.punchData, 'associated_allocation_type_key', null)
      let allocationSubtypeKey = getProp(props.punchData, 'associated_allocation_subtype_key', null)
      let combinedAllocationKey: string | null = null
      if (allocationTypeKey != null && allocationSubtypeKey != null) {
        combinedAllocationKey = allocationTypeKey + '_' + allocationSubtypeKey
      }
      us_setPunchSelectedAllocation({
        key: combinedAllocationKey,
        allocation_type_key: allocationTypeKey,
        associated_allocation_subtype_key: allocationSubtypeKey,
      })
      ur_forceRerender()
    } else {
      us_setPunchType(null)
      us_setPunchDate(returnFormattedDate(new Date(), 'YYYY-MM-DD'))
      us_setPunchTime(returnFormattedDate(new Date(), 'HH:mm'))
      us_setPunchDateAndTimeFormData({
        date: returnFormattedDate(new Date(), 'YYYY-MM-DD'),
        time: returnFormattedDate(new Date(), 'HH:mm'),
      })
      us_setPunchSelectedAllocation({
        key: null,
        allocation_type_key: null,
        associated_allocation_subtype_key: null,
      })
      ur_forceRerender()
    }
    return () => {}
  }, [props, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasks(newData)
      ur_forceRerender()
    }
    if (props != null && props.punchData != null && props.punchData.associated_user_key != null && us_punchDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_punchDate, props.punchData.associated_user_key as string),
            // DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query( res_GCK.clientKey, dateKey, props.punchData.associated_user_key as string ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, props, us_punchDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledCancelledTasks(newData)
      ur_forceRerender()
    }
    if (props != null && props.punchData != null && props.punchData.associated_user_key != null && us_punchDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_CancelledScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_punchDate, props.punchData.associated_user_key as string),
            // DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query( res_GCK.clientKey, dateKey, props.punchData.associated_user_key as string ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledCancelledTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, props, us_punchDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAdminTimeAllocationTypes(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TimeSheetsAllocationTypes_Document(res_GCK.clientKey, 'admin_time'), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setNonWorkingTimeAllocationTypes(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TimeSheetsAllocationTypes_Document(res_GCK.clientKey, 'non_working_time'), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    if (props != null && props.punchData != null && props.punchData.associated_user_key != null && us_punchDate != null) {
      let combinedTasks: TsInterface_UnspecifiedObject = { ...us_scheduledTasks, ...us_scheduledCancelledTasks }
      let weekStartDate = getStartAndEndOfWeek(returnDateCorrectedForTimezoneOffset(us_punchDate)).startOfWeek
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          returnClockInOptions(res_GCK.clientKey, props.punchData.associated_user_key, weekStartDate, us_adminTimeAllocationTypes, combinedTasks)
            .then((res_RCIO) => {
              if (res_RCIO == null) {
                res_RCIO = {}
              }
              // @ts-expect-error - TODO: reason for error
              if (res_RCIO['clockInOptions'] == null) {
                // @ts-expect-error - TODO: reason for error
                res_RCIO['clockInOptions'] = {}
              }
              // @ts-expect-error - TODO: reason for error
              res_RCIO['clockInOptions']['break_break'] = {
                allocation_subtype_code: 'break',
                allocation_subtype_key: 'break',
                allocation_subtype_name: 'Break',
                allocation_type_code: 'break',
                allocation_type_key: 'break',
                allocation_type_name: 'Break',
                associated_project_key: null,
                associated_project_tasks: null,
                full_name: 'Break',
                hours: null,
                key: 'break_break',
              }
              // Non Working Time
              if (us_nonWorkingTimeAllocationTypes != null && us_nonWorkingTimeAllocationTypes.subtypes != null) {
                console.log(us_nonWorkingTimeAllocationTypes)

                for (let loopSubtypeKey in us_nonWorkingTimeAllocationTypes.subtypes) {
                  let loopSubtype = us_nonWorkingTimeAllocationTypes.subtypes[loopSubtypeKey]
                  if (loopSubtype.status !== 'deleted') {
                    // @ts-expect-error - TODO: reason for error
                    res_RCIO['clockInOptions']['non_working_time_' + loopSubtypeKey] = {
                      allocation_subtype_code: getProp(loopSubtype, 'code', null),
                      allocation_subtype_key: getProp(loopSubtype, 'key', null),
                      allocation_subtype_name: getProp(loopSubtype, 'name', null),
                      allocation_type_code: getProp(us_nonWorkingTimeAllocationTypes, 'code', null),
                      allocation_type_key: getProp(us_nonWorkingTimeAllocationTypes, 'key', null),
                      allocation_type_name: getProp(us_nonWorkingTimeAllocationTypes, 'name', null),
                      associated_project_key: null,
                      associated_project_tasks: null,
                      full_name: 'Non Working Time - ' + getProp(loopSubtype, 'name', null),
                      hours: null,
                      key: 'non_working_time_' + loopSubtypeKey,
                    }
                  }
                }
              }
              us_setUserClockInOptions(getProp(res_RCIO, 'clockInOptions', {}))
            })
            .catch((rej_RCIO) => {
              console.error(rej_RCIO)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {}
  }, [
    uc_RootData_ClientKey,
    us_adminTimeAllocationTypes,
    ur_forceRerender,
    props,
    us_punchDate,
    us_scheduledTasks,
    us_scheduledCancelledTasks,
    uc_setRootData_ClientKey,
    us_nonWorkingTimeAllocationTypes,
  ])

  // Other Variables
  let formAdditionalData_DateAndTime: TsInterface_FormAdditionalData = {}

  // Functions
  const returnPunchUpdateObject = (): TsInterface_UnspecifiedObject => {
    let updateObject: TsInterface_UnspecifiedObject = {
      status: 'active',
    }
    // Static Data
    updateObject['associated_user_key'] = getProp(props.punchData, 'associated_user_key', null)
    updateObject['associated_user_name'] = getProp(props.punchData, 'associated_user_name', null)
    // Form Data
    updateObject['type'] = us_punchType
    let formattedPunchDate = us_punchDate
    if (us_punchDate != null && typeof us_punchDate === 'object') {
      formattedPunchDate = returnFormattedDate(us_punchDate, 'YYYY-MM-DD')
    }
    if (formattedPunchDate != null && us_punchTime != null) {
      updateObject['timestamp'] = new Date(formattedPunchDate + ' ' + us_punchTime)
    }
    updateObject['associated_allocation_subtype_code'] = getProp(us_punchSelectedAllocation, 'allocation_subtype_code', null)
    updateObject['associated_allocation_subtype_key'] = getProp(us_punchSelectedAllocation, 'allocation_subtype_key', null)
    updateObject['associated_allocation_subtype_name'] = getProp(us_punchSelectedAllocation, 'allocation_subtype_name', null)
    updateObject['associated_allocation_type_code'] = getProp(us_punchSelectedAllocation, 'allocation_type_code', null)
    updateObject['associated_allocation_type_key'] = getProp(us_punchSelectedAllocation, 'allocation_type_key', null)
    updateObject['associated_allocation_type_name'] = getProp(us_punchSelectedAllocation, 'allocation_type_name', null)
    updateObject['associated_project_key'] = getProp(us_punchSelectedAllocation, 'associated_project_key', null)
    updateObject['associated_tasks'] = getProp(us_punchSelectedAllocation, 'associated_project_tasks', null)
    // Depends on Form Type
    if (props != null && props.type === 'edit') {
      updateObject['key'] = getProp(props.punchData, 'key', null)
      updateObject['timestamp_created'] = getProp(props.punchData, 'timestamp_created', null)
    } else if (props != null && props.type === 'new') {
      // Nothing at this time
    }
    // Not Handled on Desktop
    // updateObject["location_latitude"]
    // updateObject["location_longitude"]
    return updateObject
  }

  const savePunchToDatabase = (): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      let updateObject = returnPunchUpdateObject()
      let logUpdateObject = us_originalPunchData
      let clockTimestamp = getProp(updateObject, 'timestamp', new Date())
      if ((props.startDate == null || clockTimestamp >= props.startDate) && (props.endDate == null || clockTimestamp <= props.endDate)) {
        if (props != null && props.userKey != null) {
          if (props != null && props.type === 'edit') {
            updateObject['associated_editor_key'] = getProp(uc_RootData_ClientUser, 'key', null)
            updateObject['associated_editor_name'] = getProp(uc_RootData_ClientUser, 'name', null)
            updateObject['management_edits'] = { edited_by_management: true }
            updateObject['timestamp_last_updated'] = new Date()
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let logTimePunchKey = clockTimestamp.getTime().toString() + '_' + props.userKey + '_' + generateRandomString(6, null)
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                  { type: 'setMerge', ref: DatabaseRef_TimePunches_Document(res_GCK.clientKey, us_originalPunchData.key), data: updateObject },
                  {
                    type: 'setMerge',
                    ref: DatabaseRef_TimePunchesHistory_Document(res_GCK.clientKey, us_originalPunchData.key, logTimePunchKey),
                    data: logUpdateObject,
                  },
                ]
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    resolve({ success: true })
                  })
                  .catch((rej_DBU) => {
                    console.error(rej_DBU)
                    reject(rej_DBU)
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                  })
              })
              .catch((rej_GCK) => {
                reject(rej_GCK)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          } else if (props != null && props.type === 'new') {
            updateObject['associated_creator_key'] = getProp(uc_RootData_ClientUser, 'key', null)
            updateObject['associated_creator_name'] = getProp(uc_RootData_ClientUser, 'name', null)
            updateObject['management_edits'] = { created_by_management: true }
            updateObject['timestamp_created'] = new Date()
            updateObject['timestamp_last_updated'] = new Date()
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let newTimePunchKey = clockTimestamp.getTime().toString() + '_' + props.userKey + '_' + generateRandomString(6, null)
                updateObject['key'] = newTimePunchKey
                let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                  { type: 'setMerge', ref: DatabaseRef_TimePunches_Document(res_GCK.clientKey, newTimePunchKey), data: updateObject },
                ]
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    resolve({ success: true })
                  })
                  .catch((rej_DBU) => {
                    console.error(rej_DBU)
                    reject(rej_DBU)
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                  })
              })
              .catch((rej_GCK) => {
                reject(rej_GCK)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          } else {
            // TODO - error - unsupported action
            reject({
              success: false,
              error: {
                message: s_UNABLE_TO_SAVE_PUNCH,
                details: s_UNSUPPORTED_ACTION,
                code: 'ER-D-TPE-SPTD-01',
              },
            })
          }
        } else {
          reject({
            success: false,
            error: {
              message: s_UNABLE_TO_SAVE_PUNCH,
              details: s_MISSING_REQUIRED_PARAMETERS,
              code: 'ER-D-TPE-SPTD-02',
            },
          })
        }
      } else {
        if (props.startDate != null && props.endDate != null) {
          reject({
            success: false,
            error: {
              message: s_UNABLE_TO_SAVE_PUNCH,
              details: (
                <>
                  {s_NEW_DATE_MUST_BE_BETWEEN} {returnFormattedDateKey(props.startDate)} {s_AND} {returnFormattedDateKey(props.endDate)}
                </>
              ),
              code: 'ER-D-TPE-SPTD-03',
            },
          })
        } else {
          reject({
            success: false,
            error: {
              message: s_UNABLE_TO_SAVE_PUNCH,
              details: <>{s_NEW_DATE_MUST_BE_IN_THE_SAME_WEEK_AS_THE_ORIGINAL_DATE}</>,
              code: 'ER-D-TPE-SPTD-04',
            },
          })
        }
      }
    })
  }

  // JSX Generation
  const rJSX_PunchTypeInput = (): JSX.Element => {
    let inputJSX = <></>
    let clockInColor: TsType_MuiComponentColorsWithInherit = 'inherit'
    let clockInVariant: 'outlined' | 'contained' = 'outlined'
    let clockInSX: TsInterface_UnspecifiedObject = { opacity: 0.5 }
    let clockOutColor: TsType_MuiComponentColorsWithInherit = 'inherit'
    let clockOutVariant: 'outlined' | 'contained' = 'outlined'
    let clockOutSX: TsInterface_UnspecifiedObject = { opacity: 0.5 }
    if (us_punchType === 'clock_in') {
      clockInColor = 'success'
      clockInVariant = 'contained'
      clockInSX = {}
    } else if (us_punchType === 'clock_out') {
      clockOutColor = 'error'
      clockOutVariant = 'contained'
      clockOutSX = {}
    }
    inputJSX = (
      <Box className="tw-text-center">
        <Button
          className="tw-m-2"
          variant={clockInVariant}
          color={clockInColor}
          sx={clockInSX}
          onClick={() => {
            us_setPunchType('clock_in')
          }}
        >
          <Typography variant="h6">
            <Icon
              icon="play"
              className="tw-mr-2"
            />
            {s_CLOCK_IN}
          </Typography>
        </Button>
        <Button
          className="tw-m-2"
          variant={clockOutVariant}
          color={clockOutColor}
          sx={clockOutSX}
          onClick={() => {
            us_setPunchType('clock_out')
          }}
        >
          <Typography variant="h6">
            <Icon
              icon="pause"
              className="tw-mr-2"
            />
            {s_CLOCK_OUT}
          </Typography>
        </Button>
      </Box>
    )
    return inputJSX
  }

  const rJSX_DateInputs = (): JSX.Element => {
    let inputJSX = <></>
    if (us_punchDate != null) {
      inputJSX = (
        <Box
          className="tw-text-center tw-mt-2 tw-m-auto"
          sx={{ maxWidth: '400px' }}
        >
          <Box id="custom_static_date_picker">
            <style>{datePickerCSS}</style>
            <LocalizationProvider dateAdapter={AdapterDayjs as any}>
              <StaticDatePicker
                label={rLIB('Date')}
                value={us_punchDate}
                openTo="day"
                renderInput={(params) => <></>}
                onChange={(newDate: any) => {
                  if (newDate != null) {
                    if (newDate.$d != null && typeof newDate.$d === 'object') {
                      us_setPunchDate(returnFormattedDate(newDate.$d, 'YYYY-MM-DD'))
                    } else {
                      us_setPunchDate(newDate.$d as string)
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Box>
          <Form
            formAdditionalData={formAdditionalData_DateAndTime}
            formData={us_punchDateAndTimeFormData}
            formInputs={formInputs_DateAndTime}
            formOnChange={(
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {
              // setPunchDate( formData.date as string )
              us_setPunchTime(formData.time as string)
            }}
            formSettings={{
              highlight_missing: false,
              submit_button_hide: true,
              update_form_each_keystroke: true,
            }}
            formSubmission={(
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                // Submission handled outside of form
                resolve({ success: true })
              })
            }}
          />
        </Box>
      )
    }
    return inputJSX
  }

  const rJSX_AllocationSelection = (): JSX.Element => {
    let inputJSX = <></>
    if (us_punchType === 'clock_in') {
      inputJSX = (
        <Box
          className="tw-text-center tw-mt-2 tw-m-auto"
          sx={{ maxWidth: '400px' }}
        >
          <Form
            formAdditionalData={{
              clockInOptions: us_userClockInOptions,
            }}
            formData={{
              selected_allocation: us_punchSelectedAllocation,
            }}
            formInputs={formInputs_ClockInAllocationSelection}
            formOnChange={(
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {
              us_setPunchSelectedAllocation(getProp(formData, 'selected_allocation', {}))
            }}
            formSettings={{
              highlight_missing: false,
              submit_button_hide: true,
            }}
            formSubmission={(
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                // Submission handled outside of form
                resolve({ success: true })
              })
            }}
          />
        </Box>
      )
    }
    return inputJSX
  }

  const rJSX_SubmitButton = (): JSX.Element => {
    let buttonJSX = <></>
    // Disable Button if missing fields
    let buttonDisbled = false
    let testUpdateObject = returnPunchUpdateObject()
    if (
      (testUpdateObject.type !== 'clock_in' && testUpdateObject.type !== 'clock_out') ||
      testUpdateObject.timestamp == null ||
      (testUpdateObject.type === 'clock_in' && testUpdateObject.associated_allocation_type_key == null)
    ) {
      buttonDisbled = true
    }
    // JSX
    buttonJSX = (
      <Box className="tw-text-center">
        <Button
          className="tw-m-2"
          variant="contained"
          color="success"
          disabled={buttonDisbled}
          onClick={() => {
            savePunchToDatabase()
              .then((res_SPTD) => {
                uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              })
              .catch((rej_SPTD) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_SPTD.error })
              })
          }}
        >
          <Typography variant="h6">
            <Icon
              icon="floppy-disk"
              className="tw-mr-2"
            />
            {s_SAVE}
          </Typography>
        </Button>
      </Box>
    )
    return buttonJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box className="tw-p-4">
        {rJSX_PunchTypeInput()}
        {rJSX_DateInputs()}
        {rJSX_AllocationSelection()}
        {rJSX_SubmitButton()}
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Exports
///////////////////////////////

export const formInputs_OrderPageFilters: TsInterface_FormInputs = {
  order_status: {
    key: 'order_status',
    label: rLIB('Visible Orders'),
    input_type: 'multiple_choice_radio',
    required: true,
    data_type: 'string',
    options: [
      {
        key: 'not_deleted',
        value: rLIB('Hide Deleted Orders'),
      },
      {
        key: 'all',
        value: rLIB('All Orders'),
      },
      {
        key: 'locked',
        value: rLIB('Locked Orders'),
      },
      {
        key: 'new',
        value: rLIB('New Orders'),
      },
    ],
  },
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_MLEvaluationProcedures_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'machine_learning', 'evaluation_procedures', 'main')
}

export const DatabaseRef_ActiveMLEvaluationProcedures_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'machine_learning', 'evaluation_procedures', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_MLEvaluationProcedures_Document = (clientKey: string, evalutaionKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'machine_learning', 'evaluation_procedures', 'main', evalutaionKey)
}

export const DatabaseRef_MLEvaluationProceduresStagingData_Document = (clientKey: string, evalutaionKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'machine_learning', 'evaluation_procedures', 'main', evalutaionKey, 'data', 'staging')
}

export const DatabaseRef_MLEvaluationProceduresProdData_Document = (clientKey: string, evalutaionKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'machine_learning', 'evaluation_procedures', 'main', evalutaionKey, 'data', 'prod')
}

import React from 'react'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { rLIB } from 'rfbp_core/localization/library'
import { Container as AdminOpportunityView } from './unauthenticated_sales_opportunity_view'

export const Container: React.FC = (): JSX.Element => {
  return (
    <AuthenticatedContainer
      content={<AdminOpportunityView isAuthenticatedUser={true} />}
      pageHeader={rLIB('Admin Sales Opportunity Page') as JSX.Element}
      pageKey={ApplicationPages['AdminSalesOpportunityPage']['key']}
      noPadding={true}
    ></AuthenticatedContainer>
  )
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Trans } from 'react-i18next'
import { DatabaseRef_Reminders_Collection } from 'rfbp_aux/services/database_endpoints/operations/reminders'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_RootData_GlobalUser } from 'rfbp_core/services/context'
import { DatabaseAddDocument } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_REMINDER_DATE: JSX.Element = <Trans>Reminder Date</Trans>
const s_TEXT: JSX.Element = <Trans>Text</Trans>
const s_TITLE: JSX.Element = <Trans>Title</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

export const formSettings_ReminderNew: TsInterface_FormSettings = {}

export const formInputs_ReminderNew: TsInterface_FormInputs = {
  // associated_user_key
  // status: unread
  // timestamp_created
  timestamp_reminder: {
    key: 'timestamp_reminder',
    label: s_REMINDER_DATE,
    input_type: 'timestamp_datetime',
    required: true,
    data_type: 'number',
  },
  title: {
    key: 'title',
    label: s_TITLE,
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  text: {
    key: 'text',
    label: s_TEXT,
    input_type: 'text_multiline',
    required: true,
    data_type: 'string',
  },
}

export const createScheduledReminder = (
  uc_setUserInterface_FormDialogDisplay: any,
  uc_RootData_GlobalUser: TsInterface_RootData_GlobalUser,
  projectKey: string | null,
  uc_RootData_ClientKey: any,
  uc_setRootData_ClientKey: any,
) => {
  uc_setUserInterface_FormDialogDisplay({
    display: true,
    form: {
      form: {
        formAdditionalData: { associated_user_key: uc_RootData_GlobalUser.key },
        formData: {},
        formInputs: formInputs_ReminderNew,
        formOnChange: (
          formAdditionalData: TsInterface_FormAdditionalData,
          formData: TsInterface_FormData,
          formInputs: TsInterface_FormInputs,
          formSettings: TsInterface_FormSettings,
        ) => {},
        formSettings: formSettings_ReminderNew,
        formSubmission: (
          formSubmittedData: TsInterface_FormSubmittedData,
          formAdditionalData: TsInterface_FormAdditionalData,
          formHooks: TsInterface_FormHooksObject,
        ) => {
          return new Promise((resolve, reject) => {
            getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
              .then((res_GCK) => {
                let updateObject = formSubmittedData
                updateObject['associated_user_key'] = formAdditionalData.associated_user_key
                updateObject['associated_project_key'] = projectKey
                updateObject['status'] = 'unread'
                updateObject['timestamp_created'] = new Date()
                updateObject['timestamp_reminder'] = new Date(updateObject.timestamp_reminder)
                DatabaseAddDocument(DatabaseRef_Reminders_Collection(res_GCK.clientKey), updateObject, true)
                  .then((res_DAD) => {
                    resolve(res_DAD)
                  })
                  .catch((rej_DAD) => {
                    reject(rej_DAD)
                  })
              })
              .catch((rej_GCK) => {
                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                reject(rej_GCK)
              })
          })
        },
      },
      dialog: {
        formDialogHeaderColor: 'success',
        formDialogHeaderText: rLIB('New Reminder'),
        formDialogIcon: (
          <Icon
            type="solid"
            icon="pen-to-square"
          />
        ),
      },
    },
  })
}

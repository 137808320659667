//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Chip, Typography } from '@mui/material/'
import { Stack } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { rLIB } from 'rfbp_core/localization/library'
import { dynamicSort, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { TsInterface_UnspecifiedObject, TsType_MuiComponentColorsWithInherit } from 'rfbp_core/typescript/global_types'
import { TsInterface_FeedbackCommentsSettings, TsInterface_FeedbackData } from './feedback_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  feedbackData: TsInterface_FeedbackData
  feedbackSettings: TsInterface_FeedbackCommentsSettings
}

///////////////////////////////
// Variables
///////////////////////////////

const defaultMapping: TsInterface_FeedbackCommentsSettings['mapping'] = {
  rating: 'feedback_rating',
  name: 'associated_user_name',
  text: 'feedback_notes',
  date: 'timestamp_created',
  chips: {
    // None
  },
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const FeedbackNpsComments = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_feedbackData: TsInterface_ComponentProps['feedbackData'] = getProp(props, 'feedbackData', {})
  let pr_feedbackSettings: TsInterface_ComponentProps['feedbackSettings'] = getProp(props, 'feedbackSettings', {})
  let pr_sortOrder: string = getProp(pr_feedbackSettings, 'sort_order', 'timestamp')
  let pr_sortDirection: string = getProp(pr_feedbackSettings, 'sort_direction', 'desc')
  let pr_mapping: TsInterface_FeedbackCommentsSettings['mapping'] = getProp(pr_feedbackSettings, 'mapping', defaultMapping)

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const un_routerNavigation = useNavigate()
  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions

  // JSX Generation
  const rJSX_ProjectButton = (comment: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonColor: TsType_MuiComponentColorsWithInherit = 'inherit'
    if (comment != null) {
      let rating = comment[pr_mapping.rating]
      if (rating >= 9) {
        buttonColor = 'success'
      } else if (rating >= 7) {
        buttonColor = 'warning'
      } else if (rating >= 0) {
        buttonColor = 'error'
      }
    }
    let buttonJSX = <></>
    if (
      comment != null &&
      comment['associated_project_key'] != null &&
      pr_feedbackSettings != null &&
      pr_feedbackSettings.display_buttons != null &&
      pr_feedbackSettings.display_buttons.associated_project_key === true
    ) {
      buttonJSX = (
        <Button
          variant="outlined"
          color={buttonColor}
          onClick={(event) => {
            if (comment['associated_project_key'] != null) {
              // TODO - handle other user types probably
              directAppNavigation(un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(comment['associated_project_key']))
            }
          }}
        >
          {rLIB('View Project')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_CommentChip = (comment: TsInterface_UnspecifiedObject, chip: TsInterface_UnspecifiedObject): JSX.Element => {
    let chipJSX = <></>
    if (comment != null && chip != null && chip.key != null && comment[chip.key] != null) {
      chipJSX = (
        <Chip
          variant="filled"
          label={comment[chip.key]}
          onClick={() => {
            // TODO
          }}
        />
      )
    }
    return chipJSX
  }

  const rJSX_CommentChips = (comment: TsInterface_UnspecifiedObject): JSX.Element => {
    let chipsJSX = <></>
    if (pr_mapping != null && pr_mapping.chips != null) {
      chipsJSX = (
        <Box>
          {objectToArray(pr_mapping.chips).map((chip, index) => (
            <Box
              key={index}
              className="tw-inline-block tw-mr-2"
            >
              {rJSX_CommentChip(comment, chip)}
            </Box>
          ))}
        </Box>
      )
    }
    return chipsJSX
  }

  const rJSX_Comment = (comment: TsInterface_UnspecifiedObject): JSX.Element => {
    let ratingJSX = <></>
    let ratingBackgroundColor = themeVariables.gray_700
    if (comment != null) {
      let rating = comment[pr_mapping.rating]
      if (rating >= 9) {
        ratingBackgroundColor = themeVariables.success_main
      } else if (rating >= 7) {
        ratingBackgroundColor = themeVariables.warning_main
      } else if (rating >= 0) {
        ratingBackgroundColor = themeVariables.error_main
      }
    }
    ratingJSX = (
      <Box
        className="tw-text-center"
        sx={{ width: '40px', padding: '4px', borderRadius: '6px', background: ratingBackgroundColor }}
      >
        <Typography
          variant="h5"
          className="tw-mt-0"
        >
          {getProp(comment, pr_mapping.rating, '')}
        </Typography>
      </Box>
    )
    let commentJSX = <></>
    commentJSX = (
      <Card className="tw-p-2">
        <Box
          className="tw-inline-block"
          sx={{ width: '50px' }}
        >
          {ratingJSX}
        </Box>
        <Box
          className="tw-inline-block tw-text-left tw-align-top"
          sx={{ width: 'calc( 100% - 50px )' }}
        >
          <Typography
            variant="h6"
            className="tw-font-bold"
          >
            {getProp(comment, pr_mapping.name, '')}
          </Typography>
          {rJSX_CommentChips(comment)}
          <Typography
            variant="body1"
            className="tw-opacity-70"
          >
            {getProp(comment, pr_mapping.text, '')}
          </Typography>
          <Stack
            direction="row"
            className="tw-mt-2 tw-justify-between"
            spacing={1}
          >
            <Box>{rJSX_ProjectButton(comment)}</Box>
            <Box className="tw-opacity-30">
              {returnFormattedDate(getProp(comment, pr_mapping.date, null), 'h:mm a')} ·{' '}
              {returnFormattedDate(getProp(comment, pr_mapping.date, null), 'MMM D, YYYY')}
            </Box>
          </Stack>

          {/* <Json data={ comment } /> */}
        </Box>
      </Card>
    )
    return commentJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        {objectToArray(pr_feedbackData)
          .sort(dynamicSort(pr_sortOrder, pr_sortDirection))
          .map((comment, index) => (
            <Box
              key={index}
              className="tw-mt-2"
            >
              {rJSX_Comment(comment)}
            </Box>
          ))}
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			External Requests contain all functions that interface with cloud function endpoints or external APIs

		TODO:


	*/

///////////////////////////////
// Imports
///////////////////////////////

import axios from 'axios'
import { baseCloudFunctionUrl } from 'rfbp_aux/config/config'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Types
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

// Cloud Functions

///////////////////////////////
// Functions
///////////////////////////////

// const parseCloudFunctionError = (cloudFunctionError: any): TsInterface_UnspecifiedObject => {
//   if (typeof cloudFunctionError === 'string') {
//     return { message: cloudFunctionError }
//   } else if (typeof cloudFunctionError === 'object') {
//     return cloudFunctionError
//   } else if (cloudFunctionError != null && cloudFunctionError.message != null) {
//     return JSON.parse(cloudFunctionError.message)
//   } else {
//     return { message: 'parseCloudFunctionError' }
//   }
// }

///////////////////////////////
// Exports
///////////////////////////////
export const cloudFunctionUnauthenticatedRequests = (requestParams: TsInterface_UnspecifiedObject) => {
  return new Promise((resolve, reject) => {
    let config = {
      method: 'GET',
      url: baseCloudFunctionUrl + 'unauthenticatedRequests',
      params: requestParams,
    }
    axios
      .request(config)
      .then((res_A) => {
        resolve(res_A)
      })
      .catch((rej_A) => {
        reject(rej_A)
      })
  })
}

export const sendAppPushNotification = (
  userKeysArray: string[],
  type: string,
  title: string,
  text: string,
  creatorUserKey: string | null,
  projectKey: string | null,
  taskKey: string | null,
  metadata: TsInterface_UnspecifiedObject,
  pruneTokens: boolean,
) => {
  return new Promise((resolve, reject) => {
    cloudFunctionManageRequest('manageMessages', {
      function: 'sendPushNotification',
      user_keys: userKeysArray,
      type: type,
      title: title,
      text: text,
      associated_creator_key: creatorUserKey,
      associated_project_key: projectKey,
      associated_task_key: taskKey,
      metadata: metadata,
      prune_tokens: pruneTokens,
    })
      .then((res_CFMMR) => {
        resolve(res_CFMMR)
      })
      .catch((rej_CFMMR) => {
        reject(rej_CFMMR)
      })
  })
}

///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import {
  materialSupplierClassificationTypes,
  materialSupplierPickBaseUnitOfMeasurements,
  materialSupplierPickPackagingTypes,
} from '../data/data_material_options'

///////////////////////////////
// Exports
///////////////////////////////

export const formInputs_EditSku: TsInterface_FormInputs = {
  unique_identifier: {
    key: 'unique_identifier',
    label: rLIB('Unique Identifier'),
    input_type: 'text_basic',
    required: true,
    disabled: true,
    data_type: 'string',
  },
  name: {
    key: 'name',
    label: rLIB('Product Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  associated_manufacturer_unique_identifier: {
    key: 'associated_manufacturer_unique_identifier',
    label: rLIB('Manufacturer Part Number'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  pick_packaging_type: {
    key: 'pick_packaging_type',
    label: rLIB('Package'),
    input_type: 'multiple_choice_select',
    required: true,
    data_type: 'string',
    options: objectToArray(materialSupplierPickPackagingTypes),
  },
  pick_unit_quantity_per_package: {
    key: 'pick_unit_quantity_per_package',
    label: rLIB('Quantity per Package'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
  },
  pick_base_unit_of_measurement: {
    key: 'pick_base_unit_of_measurement',
    label: rLIB('UOM'),
    input_type: 'multiple_choice_select',
    required: true,
    data_type: 'string',
    options: objectToArray(materialSupplierPickBaseUnitOfMeasurements),
  },
  pick_estimated_price_per_package: {
    key: 'pick_estimated_price_per_package',
    label: rLIB('Estimated Price'),
    input_type: 'text_number',
    required: true,
    data_type: 'number',
  },
  associated_category_name: {
    key: 'associated_category_name',
    label: rLIB('Category'),
    input_type: 'multiple_choice_select',
    required: true,
    data_type: 'string',
    options: [],
  },
  associated_classification_name: {
    key: 'associated_classification_name',
    label: rLIB('Classification'),
    input_type: 'multiple_choice_select',
    required: true,
    data_type: 'string',
    options: objectToArray(materialSupplierClassificationTypes),
  },
}

///////////////////////////////
// Imports
///////////////////////////////
import { Box, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'
import { getUtilityCompanyList } from '../components/helper'

///////////////////////////////
// Exports
///////////////////////////////
export const formInputs_NewSalesOpportunity: TsInterface_FormInputs = {
  contact_info: {
    data_type: 'string',
    key: 'contact_info',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-center tw-mb-4">
          <Typography
            variant="h6"
            sx={{ color: themeVariables.white }}
            className="tw-mt-2"
          >
            {rLIB('Contact Info')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('Sales Opportunity Name'),
    required: true,
  },
  phone: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'phone',
    label: rLIB('Phone Number'),
    required: false,
  },
  contact_utility_provider: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'contact_utility_provider',
    label: rLIB('Utility Company'),
    options: getUtilityCompanyList().map((company, index) => {
      return { key: `${company.label}`, value: company.label }
    }),
    required: false,
  },
  temp_address_header: {
    data_type: 'string',
    key: 'temp_address_header',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-center tw-mb-4">
          <Typography
            variant="h6"
            sx={{ color: themeVariables.white }}
            className="tw-mt-2"
          >
            {rLIB('Address')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  address1: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'address1',
    label: rLIB('Address'),
    required: true,
  },
  city: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'city',
    label: rLIB('City'),
    required: true,
  },
  state: {
    data_type: 'string',
    input_type: 'multiple_choice_select_state_usa_abbreviated',
    key: 'state',
    label: rLIB('State'),
    required: true,
  },
  postal_code: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'postal_code',
    label: rLIB('Postal Code'),
    required: true,
  },
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_PowerwallOrders_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'webstore', 'orders', 'powerwall')
}

export const DatabaseRef_PowerwallOrder_Document = (clientKey: string, userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'webstore', 'orders', 'powerwall', userKey)
}

export const DatabaseRef_PowerwallOrders_Search = (clientKey: string, searchProperty: string, searchValue: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: searchProperty, comparator: '>=', value: searchValue },
    { prop: searchProperty, comparator: '<=', value: searchValue + '~' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'webstore', 'orders', 'powerwall'),
    queryOperatorsArray,
    orderByArray,
    {},
    limit,
  )
}

export const DatabaseRef_PowerwallOrders_Query = (clientKey: string, startTimestamp: Date, endTimestamp: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_created', comparator: '>=', value: startTimestamp },
    { prop: 'timestamp_created', comparator: '<=', value: endTimestamp },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_created', desc: false }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'webstore', 'orders', 'powerwall'), queryOperatorsArray, orderByArray, {}, null)
}

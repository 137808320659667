//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Text Input and submit button for chats

		TODO:
			Use settings to customize look and feel?

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, FormControl, TextField } from '@mui/material/'
import { useReducer, useState } from 'react'
import { themeVariables } from 'rfbp_aux/config/app_theme' // OUTSIDE BOILERPLATE
import { TsInterface_ChatInputSettings, TsType_ChatInputOnSubmit } from 'rfbp_core/components/chat'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_DynamicInputAttributes } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  chatInputOnSubmit: TsType_ChatInputOnSubmit
  chatInputSettings: TsInterface_ChatInputSettings
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const ChatMessageEntry = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_chatInputOnSubmit: TsInterface_ComponentProps['chatInputOnSubmit'] = getProp(props, 'chatInputOnSubmit', (message: string) => {
    return new Promise((resolve, reject) => {
      resolve({ success: true })
    })
  })

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_chatInputValue, us_setChatInputValue] = useState('')
  const [us_sendingMessage, us_setSendingMessage] = useState(false)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const sendMessage = (): void => {
    us_setSendingMessage(true)
    ur_forceRerender()
    pr_chatInputOnSubmit(us_chatInputValue)
      .then((res_CIOS) => {
        us_setChatInputValue('')
      })
      .finally(() => {
        us_setSendingMessage(false)
        ur_forceRerender()
      })
  }

  // JSX Generation
  const rJSX_SendMessageButton = (): JSX.Element => {
    let buttonJSX
    if (us_sendingMessage === true) {
      buttonJSX = (
        <Button
          className="bp_button_with_loading_icon_child"
          disabled
          color="primary"
          type="submit"
          variant="contained"
          disableElevation
        >
          <Icon icon="arrows-rotate" />
        </Button>
      )
    } else if (us_chatInputValue.length === 0) {
      buttonJSX = (
        <Button
          disabled
          color="primary"
          type="submit"
          variant="contained"
          onClick={sendMessage}
          disableElevation
        >
          <Icon icon="paper-plane" />
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          color="primary"
          type="submit"
          variant="contained"
          onClick={sendMessage}
          disableElevation
        >
          <Icon icon="paper-plane" />
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let dynamicAttributes: TsInterface_DynamicInputAttributes = {}
    if (us_sendingMessage === true) {
      dynamicAttributes['disabled'] = true
    } else {
      dynamicAttributes['disabled'] = false
    }
    let componentJSX = (
      <Box component="div">
        <Box
          component="div"
          className="bp_message_input tw-inline-block"
        >
          <FormControl fullWidth>
            <TextField
              sx={{ background: themeVariables.background_paper }}
              id="message_input"
              color="primary"
              label={rLIB('Message')}
              margin="normal"
              value={us_chatInputValue}
              onChange={(event) => {
                us_setChatInputValue(event.target.value)
              }}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  if (us_chatInputValue != null && us_chatInputValue.length !== 0) {
                    sendMessage()
                  }
                }
              }}
              variant="outlined"
              {...dynamicAttributes}
            />
          </FormControl>
        </Box>
        <Box
          component="div"
          className="bp_message_send_button tw-inline-block"
        >
          {rJSX_SendMessageButton()}
        </Box>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { returnCombinedTaskRoles, returnExpandedTaskTableData } from 'app/models/projects/project_services'
import { tableColumns_SubKOpenTasks, tableColumns_SubKRecentlyClosedTasks } from 'app/models/tasks/task_table'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
// Services
import { Badge, Box, Card, Typography } from '@mui/material/'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query,
  DatabaseRef_UserOpenAndReadyTasks_Query,
  DatabaseRef_UserRecentlyCompletedTasks_Query,
} from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatePicker } from 'rfbp_core/components/date_picker/date_picker'
import { TableBasic, TsInterface_TableAdditionalData, TsInterface_TableSettings } from 'rfbp_core/components/table'
import { TabsUrl, TsInterface_TabsSettingsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_RootData_GlobalUser } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import {
  getProp,
  millisecondsPerDay,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnFormattedDateKey,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SubKActiveTasksListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_IN_THE_PAST_2_WEEKS: JSX.Element = <Trans>In the past 2 weeks</Trans>
const s_MY_ACTIVE_TASKS: JSX.Element = <Trans>My Active Tasks</Trans>
const s_MY_RECENTLY_COMPLETED_TASKS: JSX.Element = <Trans>My Recently Completed Tasks</Trans>
const s_NO_OPEN_TASKS: JSX.Element = <Trans>No Open Tasks</Trans>
const s_NO_RECENTLY_COMPLETED_TASKS: JSX.Element = <Trans>No Recently Completed Tasks</Trans>
const s_NO_TASKS_FOR_SELECTED_DATE: JSX.Element = <Trans>No tasks for selected date</Trans>
const s_SCHEDULED_DATE: JSX.Element = <Trans>Scheduled Date</Trans>
const s_SCHEDULED_TASKS: JSX.Element = <Trans>Scheduled Tasks</Trans>
const s_TASKS: JSX.Element = <Trans>Tasks</Trans>
const s_TASKS_FROM: JSX.Element = <Trans>Tasks from</Trans>
const se_TASKS = 'Tasks'
// { sort-end } - displayed text

// Tables
const tableSettings_ProjectTasks: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'id_number',
  sortable: true,
}

///////////////////////////////
// Functions
///////////////////////////////

function filterCompletedWithinLastTwoWeeks(obj: TsInterface_UnspecifiedObject) {
  const filteredObj: TsInterface_UnspecifiedObject = {}
  for (const key in obj) {
    const subObj = obj[key]
    if (subObj.status_complete === false) {
      filteredObj[key] = subObj
    } else if (subObj.timestamp_completed != null && isCompletedWithinLastTwoWeeks(returnDateFromUnknownDateFormat(subObj.timestamp_completed))) {
      filteredObj[key] = subObj
    }
  }
  return filteredObj
}

function isCompletedWithinLastTwoWeeks(timestamp: Date) {
  const twoWeeksAgo = new Date()
  twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 14) // Subtract 14 days
  const completedDate = new Date(timestamp)
  return completedDate >= twoWeeksAgo && completedDate <= new Date()
}

function getStartAndEndOfWeek(date: Date) {
  const startOfWeek = new Date(date)
  startOfWeek.setHours(0, 0, 0, 0)
  startOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 0)
  const endOfWeek = new Date(date)
  endOfWeek.setHours(23, 59, 59, 999)
  endOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 6)
  return {
    startOfWeek,
    endOfWeek,
  }
}

function getDatesInRange(startDate: string, endDate: string) {
  const dates = []
  let currentDate = new Date(startDate)
  while (currentDate <= new Date(endDate)) {
    dates.push(currentDate.toISOString().slice(0, 10))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dates
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_loadedMyOpenTaskData, us_setLoadedMyOpenTaskData] = useState<boolean>(false)
  const [us_loadedMyRecentTaskData, us_setLoadedMyRecentTaskData] = useState<boolean>(false)
  const [us_myOpenTasks, us_setMyOpenTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_recentTasks, us_setRecentTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasks, us_setScheduledTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksFri, us_setScheduledTasksFri] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksMon, us_setScheduledTasksMon] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksSat, us_setScheduledTasksSat] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksSun, us_setScheduledTasksSun] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksThu, us_setScheduledTasksThu] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksTue, us_setScheduledTasksTue] = useState<TsInterface_UnspecifiedObject>({})
  const [us_scheduledTasksWed, us_setScheduledTasksWed] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const [us_weekDates, us_setWeekDates] = useState<string[]>([])
  const [us_weekEndDate, us_setWeekEndDate] = useState<Date | null>(null)
  const [us_weekStartDate, us_setWeekStartDate] = useState<Date | null>(null)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let weekBoundingDates = getStartAndEndOfWeek(us_selectedDate)
    us_setWeekStartDate(weekBoundingDates.startOfWeek)
    us_setWeekEndDate(weekBoundingDates.endOfWeek)
    let startDateKey = returnFormattedDateKey(weekBoundingDates.startOfWeek)
    let endDateKey = returnFormattedDateKey(weekBoundingDates.endOfWeek)
    let dateArray = getDatesInRange(startDateKey, endDateKey)
    us_setWeekDates(dateArray)
    return () => {}
  }, [us_selectedDate])

  // useEffect(() => {
  // 	document.title = se_TASKS
  // }, [ ])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let tasksToComplete: TsInterface_UnspecifiedObject = {}
      for (let loopTaskKey in newData) {
        let loopTask = newData[loopTaskKey]
        let hasIncompletePrerequisites = false
        for (let loopPrerequisiteTaskKey in loopTask.prerequisite_tasks) {
          if (loopTask.prerequisite_tasks_completion == null || loopTask.prerequisite_tasks_completion[loopPrerequisiteTaskKey] == null) {
            hasIncompletePrerequisites = true
          }
        }
        if (hasIncompletePrerequisites !== true) {
          tasksToComplete[loopTaskKey] = loopTask
        }
      }
      us_setMyOpenTasks(tasksToComplete)
      us_setLoadedMyOpenTaskData(true)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_UserOpenAndReadyTasks_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setMyOpenTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRecentTasks(newData)
      us_setLoadedMyRecentTaskData(true)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let cutoffDate = new Date(new Date().getTime() - 14 * millisecondsPerDay)
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_UserRecentlyCompletedTasks_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string, cutoffDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setRecentTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRecentTasks(newData)
      us_setLoadedMyRecentTaskData(true)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let cutoffDate = new Date(new Date().getTime() - 14 * millisecondsPerDay)
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_UserRecentlyCompletedTasks_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string, cutoffDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setRecentTasks({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksMon(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[0] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[0], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksMon({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksTue(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[1] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[1], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksTue({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksWed(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[2] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[2], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksWed({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksThu(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[3] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[3], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksThu({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksFri(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[4] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[4], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksFri({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksSat(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[5] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[5], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksSat({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setScheduledTasksSun(filterCompletedWithinLastTwoWeeks(newData))
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null && us_weekDates != null && us_weekDates.length > 0 && us_weekDates[6] != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksOnDateForSpecificUser_Query(res_GCK.clientKey, us_weekDates[6], uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setScheduledTasksSun({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_RootData_GlobalUser.key, ur_forceRerender, uc_setRootData_ClientKey, us_weekDates])

  useEffect(() => {
    let groupedScheduledTasks: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in us_scheduledTasksMon) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksMon[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksTue) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksTue[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksWed) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksWed[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksThu) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksThu[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksFri) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksFri[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksSat) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksSat[loopTaskKey]
    }
    for (let loopTaskKey in us_scheduledTasksSun) {
      groupedScheduledTasks[loopTaskKey] = us_scheduledTasksSun[loopTaskKey]
    }
    us_setScheduledTasks(groupedScheduledTasks)
    return () => {}
  }, [us_scheduledTasksMon, us_scheduledTasksTue, us_scheduledTasksWed, us_scheduledTasksThu, us_scheduledTasksFri, us_scheduledTasksSat, us_scheduledTasksSun])

  // Other Variables
  const tableAdditionalData_ProjectTasks: TsInterface_TableAdditionalData = {
    // projectTaskWorkflow: projectTaskWorkflow,
    combinedUserRoles: returnCombinedTaskRoles(uc_RootData_ClientKey),
    // projectTasks: projectTasks,
  }
  const tabsSettings: TsInterface_TabsSettingsUrl = {
    baseUrl: ApplicationPages.SubKActiveTasksListPage.url(),
    tabQueryParam: 'tab',
    overridePageTitle: true,
    basePageTitle: se_TASKS,
  }

  // Functions
  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  // Call Functions

  // JSX Generation
  const rJSX_MyActiveTasksTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_loadedMyOpenTaskData === true) {
      let activeTaskList = returnExpandedTaskTableData(
        uc_RootData_ClientKey,
        us_myOpenTasks,
        {},
        'not_deleted',
        getProp(uc_RootData_GlobalUser, 'key', null),
        {},
        ['rJSX_MyActiveTasksTab'],
      )
      if (objectToArray(activeTaskList).length > 0) {
        tabJSX = (
          <Card className="tw-mt-0">
            <TableBasic
              tableAdditionalData={tableAdditionalData_ProjectTasks}
              tableColumns={tableColumns_SubKOpenTasks}
              tableData={returnExpandedTaskTableData(
                uc_RootData_ClientKey,
                us_myOpenTasks,
                {},
                'not_deleted',
                getProp(uc_RootData_GlobalUser, 'key', null),
                {},
                ['rJSX_MyActiveTasksTab'],
              )}
              tableSettings={tableSettings_ProjectTasks}
            />
          </Card>
        )
      } else {
        tabJSX = (
          <Card className="tw-p-4 tw-text-center">
            <Typography variant="body1">{s_NO_OPEN_TASKS}</Typography>
          </Card>
        )
      }
    }
    return tabJSX
  }

  const rJSX_RecentlyCompletedTasksTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_loadedMyRecentTaskData === true) {
      if (objectToArray(us_recentTasks).length > 0) {
        tabJSX = (
          <Card className="tw-mt-0">
            <TableBasic
              tableAdditionalData={tableAdditionalData_ProjectTasks}
              tableColumns={tableColumns_SubKRecentlyClosedTasks}
              tableData={returnExpandedTaskTableData(
                uc_RootData_ClientKey,
                us_recentTasks,
                {},
                'not_deleted',
                getProp(uc_RootData_GlobalUser, 'key', null),
                {},
                ['rJSX_RecentlyCompletedTasksTab'],
              )}
              tableSettings={tableSettings_ProjectTasks}
            />
          </Card>
        )
      } else {
        tabJSX = (
          <Card className="tw-p-4 tw-text-center">
            <Typography variant="body1">{s_NO_RECENTLY_COMPLETED_TASKS}</Typography>
            <Typography
              variant="body1"
              className="tw-opacity-50"
            >
              {s_IN_THE_PAST_2_WEEKS}
            </Typography>
          </Card>
        )
      }
    }
    return tabJSX
  }

  const returnJSXScheduledTasksTab = (): JSX.Element => {
    let tabJSX = <></>
    let datePickerJSX = (
      <Box>
        <DatePicker
          key={'calendar_date'}
          datePickerText={s_SCHEDULED_DATE}
          datePickerDate={us_selectedDate}
          datePickerDisabled={false}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
        />
        <Box className="tw-inline-block tw-pl-2 tw-pt-1.5 tw-opacity-30">
          {s_TASKS_FROM} {returnFormattedDate(us_weekStartDate, 'D MMM YYYY')} {rLIB('to')} {returnFormattedDate(us_weekEndDate, 'D MMM YYYY')}
        </Box>
      </Box>
    )
    if (objectToArray(us_scheduledTasks).length > 0) {
      tabJSX = (
        <Box>
          {datePickerJSX}
          <Card className="tw-mt-2">
            <TableBasic
              tableAdditionalData={tableAdditionalData_ProjectTasks}
              tableColumns={tableColumns_SubKOpenTasks}
              tableData={objectToArray(us_scheduledTasks)}
              tableSettings={tableSettings_ProjectTasks}
            />
          </Card>
        </Box>
      )
    } else {
      tabJSX = (
        <Box>
          {datePickerJSX}
          <Card className="tw-p-4 tw-text-center tw-mt-2">
            <Typography variant="body1">{s_NO_TASKS_FOR_SELECTED_DATE}</Typography>
          </Card>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let activeTaskList = returnExpandedTaskTableData(
      uc_RootData_ClientKey,
      us_myOpenTasks,
      {},
      'not_deleted',
      getProp(uc_RootData_GlobalUser, 'key', null),
      {},
      ['rJSX_MyActiveTasksTab'],
    )

    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_TASKS}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Active_Tasks',
                  tabHeader: (
                    <Badge
                      badgeContent={activeTaskList.length}
                      color="error"
                    >
                      {s_MY_ACTIVE_TASKS}
                    </Badge>
                  ),
                  tabContent: rJSX_MyActiveTasksTab(),
                },
                // {
                // 	tabUrlKey: "Scheduled_Tasks",
                // 	tabHeader:
                // 	<Badge badgeContent={ objectToArray(us_scheduledTasks).length } color="error">
                // 		{s_SCHEDULED_TASKS}
                // 	</Badge>,
                // 	tabContent: returnJSXScheduledTasksTab()
                // },
                {
                  tabUrlKey: 'Recently_Completed_Tasks',
                  tabHeader: s_MY_RECENTLY_COMPLETED_TASKS,
                  tabContent: rJSX_RecentlyCompletedTasksTab(),
                },
              ]}
              tabsSettings={tabsSettings}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:

		- NEW UI instead of using ChatMessagesDisplay?
			- Edit Messages (show edited and timestamp)
			- Delete Messages (show message deleted)
			- line breaks not being saved or rendered???

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Avatar, Box, Button, Card, Divider, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material/'
import { CollectionReference, DocumentData, DocumentReference } from 'firebase/firestore'
import { StorageReference } from 'firebase/storage'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_PromptDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseBatchUpdate,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import { stringAvatar, stringToColor } from '../chat/chat_helper_functions'
import { Icon } from '../icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellTimestamp,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from '../table'

///////////////////////////////
// Typescript
///////////////////////////////

export type TsType_ForumThreadCollectionDatabaseEndpoint = (clientKey: string) => CollectionReference<DocumentData>
export type TsType_ForumThreadDocumentDatabaseEndpoint = (clientKey: string, threadKey: string) => DocumentReference<DocumentData>
export type TsType_ForumMessageCollectionDatabaseEndpoint = (clientKey: string, threadKey: string) => CollectionReference<DocumentData>
export type TsType_ForumMessageDocumentDatabaseEndpoint = (clientKey: string, threadKey: string, messageKey: string) => DocumentReference<DocumentData>
export type TsType_ForumMessageStorageEndpoint = (clientKey: string, threadKey: string, messageKey: string, fileName: string) => StorageReference

export interface TsInterface_ForumSettings {
  allow_thread_creation: boolean
  scrollbar_track_color: string
  scrollbar_thumb_color: string
  input_text_color: string
  base_message_container_offset_px: number
}

interface TsInterface_ComponentProps {
  forumThreadCollectionDatabaseEndpoint: TsType_ForumThreadCollectionDatabaseEndpoint
  forumThreadDocumentDatabaseEndpoint: TsType_ForumThreadDocumentDatabaseEndpoint
  forumMessageCollectionDatabaseEndpoint: TsType_ForumMessageCollectionDatabaseEndpoint
  forumMessageDocumentDatabaseEndpoint: TsType_ForumMessageDocumentDatabaseEndpoint
  forumMessageStorageEndpoint: TsType_ForumMessageStorageEndpoint
  forumSettings: TsInterface_ForumSettings
}

interface TsInterface_FormInputProps {
  scrollbarTrackColor: string
  scrollbarThumbColor: string
  inputTextColor: string
  inputSubmitCallback: (inputText: string) => TsType_UnknownPromise
  inputSetText: any
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_CREATE_NEW_THREAD: JSX.Element = <Trans>Create New Thread</Trans>
const s_CREATE_THREAD: JSX.Element = <Trans>Create Thread</Trans>
const s_ENTER_A_NAME_FOR_THIS_NEW_THREAD: JSX.Element = <Trans>Enter a name for this new thread</Trans>
const s_LAST_UPDATED: JSX.Element = <Trans>Last Updated</Trans>
const s_LAST_UPDATED_BY: JSX.Element = <Trans>Last Updated By</Trans>
const s_NEW_THREAD: JSX.Element = <Trans>New Thread</Trans>
const s_SEND_MESSAGE: JSX.Element = <Trans>Send Message</Trans>
const s_THREAD_NAME: JSX.Element = <Trans>Thread Name</Trans>
const s_VIEW_THREAD: JSX.Element = <Trans>View Thread</Trans>
// { sort-end } - displayed text

let defaultForumSettings: TsInterface_ForumSettings = {
  allow_thread_creation: true,
  scrollbar_track_color: 'rgba(0,0,0,0)',
  scrollbar_thumb_color: '#888',
  input_text_color: '#888',
  base_message_container_offset_px: 320,
}

// Table
const tableColumns_Threads: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{s_VIEW_THREAD}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableAdditionalData.us_setActiveThreadKey(rowData.key)
        }
      },
    },
  }),
  thread_name: TableCellBasic('thread_name', s_THREAD_NAME, 'thread_name'),
  timestamp_last_updated: TableCellTimestamp('timestamp_last_updated', s_LAST_UPDATED, 'timestamp_last_updated', 'MMM D, YYYY - HH:mm', false),
  last_updated_by_name: TableCellBasic('last_updated_by_name', s_LAST_UPDATED_BY, 'last_updated_by_name'),
}

const tableSettings_Threads: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_last_updated',
  use_live_data: true,
}

///////////////////////////////
// Functions
///////////////////////////////

const ForumTextInput = (props: TsInterface_FormInputProps): JSX.Element => {
  // Props
  let pr_scrollbarTrackColor: string = getProp(props, 'scrollbarTrackColor', 'rgba(0,0,0,0)')
  let pr_scrollbarThumbColor: string = getProp(props, 'scrollbarThumbColor', '#888')
  let pr_inputTextColor: string = getProp(props, 'inputTextColor', '#888')
  let pr_inputSubmitCallback: any = getProp(props, 'inputSubmitCallback', '#888')
  let pr_inputSetText: any = getProp(props, 'inputSetText', null)

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_text, us_setText] = useState<string>('')
  const textareaRef = React.createRef()
  // { sort-end } - hooks

  // Hooks - useEffect

  // Other Variables
  let maxHeight = '300px'
  let inputCss: string = `
		#textarea {
			width: calc( 100% );
			padding: 2px;
			font-size: 16px;
			transition: height 0.2s ease-in-out;
			min-height: 40px; /* Set a minimum height */
			max-height: ${maxHeight}; /* Set a maximum height */
			background-color: rgba(0,0,0,0);
			overflow: auto !important;
			padding-right: 32px;
		}

		#textarea:focus {
			outline: none; /* or outline: 0; */
		}

		#textarea::-webkit-scrollbar {
			width: 12px; /* Adjust the width of the scrollbar if needed */
		}

		#textarea::-webkit-scrollbar-track {
			border: none;
			cursor: default;
			background-color: ${pr_scrollbarTrackColor} !important; /* Set your desired background color */
		}

		#textarea::-webkit-scrollbar-thumb {
			background-color: ${pr_scrollbarThumbColor}; /* Set your desired background color */
		}
	`

  // Functions
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      // @ts-expect-error - TODO: reason for error
      textareaRef.current.style.height = 'auto'
      // @ts-expect-error - TODO: reason for error
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = <></>
    componentJSX = (
      <Box>
        <style>{inputCss}</style>
        <Box
          sx={{
            border: '1px solid #999',
            paddingTop: '8px',
            paddingBottom: '8px',
            paddingLeft: '8px',
            paddingRight: '8px',
            borderRadius: '24px',
            position: 'relative',
          }}
        >
          <textarea
            id="textarea"
            ref={textareaRef as React.RefObject<HTMLTextAreaElement>}
            value={us_text}
            onChange={(event) => {
              us_setText(event.target.value)
              adjustTextareaHeight()
              if (pr_inputSetText != null) {
                pr_inputSetText(event.target.value)
              }
            }}
            style={{
              resize: 'none',
              fontFamily: 'Arial, sans-serif',
              borderColor: 'rgba(0,0,0,0)',
              color: pr_inputTextColor,
            }}
            placeholder="Type something here..."
          />
          <Box
            className="tw-inline-block tw-text-center"
            sx={{
              verticalAlign: 'bottom',
              position: 'absolute',
              bottom: '22px',
              right: '22px',
              height: '30px',
              width: '30px',
              background: themeVariables.info_main,
              borderRadius: '8px',
              textAlign: 'center',
              padding: '3px',
              cursor: 'pointer',
            }}
            onClick={() => {
              pr_inputSubmitCallback(us_text).then(() => {
                us_setText('')
                if (pr_inputSetText != null) {
                  pr_inputSetText('')
                }
              })
            }}
          >
            <Icon
              icon="paper-plane-top"
              tooltip={s_SEND_MESSAGE}
              className=""
            />
          </Box>
        </Box>
      </Box>
    )

    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Component
///////////////////////////////

export const ForumBasic = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_forumThreadCollectionDatabaseEndpoint: TsType_ForumThreadCollectionDatabaseEndpoint = getProp(
    props,
    'forumThreadCollectionDatabaseEndpoint',
    (clientKey: string) => {
      return null
    },
  )
  let pr_forumThreadDocumentDatabaseEndpoint: TsType_ForumThreadDocumentDatabaseEndpoint = getProp(
    props,
    'forumThreadDocumentDatabaseEndpoint',
    (clientKey: string, threadKey: string) => {
      return null
    },
  )
  let pr_forumMessageCollectionDatabaseEndpoint: TsType_ForumMessageCollectionDatabaseEndpoint = getProp(
    props,
    'forumMessageCollectionDatabaseEndpoint',
    (clientKey: string, threadKey: string) => {
      return null
    },
  )
  let pr_forumMessageDocumentDatabaseEndpoint: TsType_ForumMessageDocumentDatabaseEndpoint = getProp(
    props,
    'forumMessageDocumentDatabaseEndpoint',
    (clientKey: string, threadKey: string, messageKey: string) => {
      return null
    },
  )
  let pr_forumMessageStorageEndpoint: TsType_ForumMessageStorageEndpoint = getProp(
    props,
    'forumMessageStorageEndpoint',
    (clientKey: string, threadKey: string, messageKey: string, fileName: string) => {
      return null
    },
  )
  let pr_forumSettings: TsInterface_ForumSettings = getProp(props, 'forumSettings', defaultForumSettings)

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeThreadKey, us_setActiveThreadKey] = useState<string | null>(null)
  const [us_inputText, us_setInputText] = useState<string>('')
  const [us_messageContainerHeight, us_setMessageContainerHeight] = useState<string>(
    'calc( 100vh - ' + (pr_forumSettings.base_message_container_offset_px + 66) + 'px )',
  )
  const [us_selectedThread, us_setSelectedThread] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedThreadMessages, us_setSelectedThreadMessages] = useState<TsInterface_UnspecifiedObject>({})
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    ur_forceRerender()
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setSelectedThread(newData)
      ur_forceRerender()
    }
    if (us_activeThreadKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(pr_forumThreadDocumentDatabaseEndpoint(res_GCK.clientKey, us_activeThreadKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_activeThreadKey, pr_forumThreadDocumentDatabaseEndpoint])

  useEffect(() => {
    // TODO -lazy load most recent 100 and then load more as needed

    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      // let formattedData: TsInterface_UnspecifiedObject = {}
      // for( let loopMessageKey in newData ){
      // 	let loopMessage = newData[loopMessageKey]
      // 	formattedData[ loopMessageKey ] = {
      // 		"associated_sender_key": loopMessage["associated_sender_key"],
      // 		"associated_sender_name": loopMessage["associated_sender_name"],
      // 		"message": loopMessage["message"],
      // 		"timestamp": loopMessage["timestamp_sent"],
      // 		"element_type": "message",
      // 		"include_avatar": true
      // 	}
      // }
      // us_setSelectedThreadMessages( formattedData )
      us_setSelectedThreadMessages(newData)
      ur_forceRerender()
    }
    if (us_activeThreadKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(pr_forumMessageCollectionDatabaseEndpoint(res_GCK.clientKey, us_activeThreadKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_activeThreadKey, pr_forumMessageCollectionDatabaseEndpoint])

  useEffect(() => {
    let element = document.getElementById('rfbp-forum-input-container')
    let inputContainerHeight = 66
    if (element) {
      inputContainerHeight = element.offsetHeight
    }
    let height = 'calc( 100vh - ' + (pr_forumSettings.base_message_container_offset_px + inputContainerHeight) + 'px )'
    us_setMessageContainerHeight(height)
    return () => {}
  }, [pr_forumSettings, us_inputText])

  // Functions
  const tableDatabaseEndpoint_Threads = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    // let queryOperatorsArray: TsInterface_QueryOperatorsArray = [ { prop: "status", comparator: "==", value: "active" } ]
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_last_updated', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      pr_forumThreadCollectionDatabaseEndpoint(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // Thread Management
  const createThread = (): void => {
    uc_setUserInterface_PromptDialogDisplay({
      display: true,
      prompt: {
        color: 'success',
        confirm_text: s_CREATE_THREAD,
        default_value: '',
        header: s_NEW_THREAD,
        icon: (
          <Icon
            icon="circle-plus"
            type="solid"
          />
        ),
        input_label: s_THREAD_NAME,
        input_type: 'text',
        text: <>{s_ENTER_A_NAME_FOR_THIS_NEW_THREAD}</>,
        submit_callback: (promptValue: string) => {
          return new Promise((resolve, reject) => {
            if (promptValue !== '' && promptValue != null) {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let threadUpdateObject = {
                    thread_name: promptValue,
                    status: 'active',
                    timestamp_created: new Date(),
                    timestamp_last_updated: new Date(),
                    last_updated_by_key: getProp(uc_RootData_ClientUser, 'key', null),
                    last_updated_by_name: getProp(uc_RootData_ClientUser, 'name', null),
                  }
                  DatabaseAddDocument(pr_forumThreadCollectionDatabaseEndpoint(res_GCK.clientKey), threadUpdateObject, true)
                    .then((res_DAD) => {
                      resolve({ close_dialog: true })
                    })
                    .catch((rej_DAD) => {
                      reject(rej_DAD)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                })
            } else {
              resolve({ close_dialog: false })
            }
          })
        },
      },
    })
  }

  const submitMessage = (message: string): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let messageKey = new Date().getTime() + '_' + uuidv4()
          let messageUpdateObject = {
            message: message,
            key: messageKey,
            timestamp_sent: new Date(),
            associated_sender_key: getProp(uc_RootData_ClientUser, 'key', null),
            associated_sender_name: getProp(uc_RootData_ClientUser, 'name', null),
          }
          let threadUpdateObject = {
            timestamp_last_updated: new Date(),
            last_updated_by_key: getProp(uc_RootData_ClientUser, 'key', null),
            last_updated_by_name: getProp(uc_RootData_ClientUser, 'name', null),
          }
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            {
              type: 'setMerge',
              ref: pr_forumThreadDocumentDatabaseEndpoint(res_GCK.clientKey, us_activeThreadKey as string),
              data: threadUpdateObject,
            },
            {
              type: 'setMerge',
              ref: pr_forumMessageDocumentDatabaseEndpoint(res_GCK.clientKey, us_activeThreadKey as string, messageKey),
              data: messageUpdateObject,
            },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
              reject(rej_DBU)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
          reject(rej_GCK)
        })
    })
  }

  // JSX Generation
  const rJSX_ThreadTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tableJSX = (
        <Card className="">
          <TableDatabase
            tableAdditionalData={{ us_setActiveThreadKey: us_setActiveThreadKey }}
            tableColumns={tableColumns_Threads}
            tableDatabaseEndpoint={tableDatabaseEndpoint_Threads}
            tableSettings={tableSettings_Threads}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_CreateThreadButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (pr_forumSettings.allow_thread_creation === true) {
      buttonJSX = (
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            createThread()
          }}
          disableElevation
          className="tw-mr-2"
        >
          <Icon
            icon="circle-plus"
            className="tw-mr-2"
          />
          {s_CREATE_NEW_THREAD}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_ThreadList = (): JSX.Element => {
    let listJSX = <></>
    listJSX = (
      <Box>
        <Box>{rJSX_CreateThreadButton()}</Box>
        <Box className="tw-mt-2">{rJSX_ThreadTable()}</Box>
      </Box>
    )
    return listJSX
  }

  const rJSX_ThreadHeader = (): JSX.Element => {
    let headerJSX = <></>
    headerJSX = (
      <Box>
        <Stack
          direction="row"
          spacing={2}
        >
          <Icon
            icon="angle-left"
            className="tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer tw-pt-1"
            sx={{ fontSize: '24px' }}
            onClick={() => {
              us_setActiveThreadKey(null)
            }}
          />
          <Typography
            variant="h5"
            className="tw-ml-3"
          >
            {us_selectedThread.thread_name}
          </Typography>
        </Stack>
        <Divider className="tw-my-2" />
      </Box>
    )
    return headerJSX
  }

  // const rJSX_ThreadMessage = (): JSX.Element => {
  // 	let messageJSX = <></>

  // 	// First Column - Avatar
  // 	// Second Column - Name and Time (edited) <br> Message <br> Emojies / Attachments

  // 	return messageJSX
  // }

  const rJSX_ThreadMessages = (): JSX.Element => {
    let messagesJSX = (
      <Box>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {objectToArray(us_selectedThreadMessages)
                .sort(dynamicSort('timestamp_sent', 'asc'))
                .map((message: TsInterface_UnspecifiedObject, messageIndex: number) => (
                  <TableRow
                    key={messageIndex}
                    className="tw-cursor-pointer"
                  >
                    <TableCell className="tw-align-top">
                      <Avatar
                        {...stringAvatar(message.associated_sender_name)}
                        sx={{ bgcolor: stringToColor(message.associated_sender_name) }}
                      />
                    </TableCell>
                    <TableCell>
                      <Box>
                        <Box className="tw-inline-block tw-font-bold tw-mr-2 tw-mb-2">{message.associated_sender_name}</Box>
                        <Box className="tw-inline-block tw-opacity-30">{returnFormattedDate(message.timestamp_sent, 'MMM D, YYYY - h:mm a')}</Box>
                      </Box>
                      <Box>
                        <Box dangerouslySetInnerHTML={{ __html: message.message.replace(/\n/g, '<br/>') }} />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
    return messagesJSX
  }

  const rJSX_NewMessageInput = (): JSX.Element => {
    let inputJSX = <></>
    inputJSX = (
      <Box className="tw-mt-2">
        <ForumTextInput
          scrollbarTrackColor={pr_forumSettings.scrollbar_track_color}
          scrollbarThumbColor={pr_forumSettings.scrollbar_thumb_color}
          inputTextColor={pr_forumSettings.input_text_color}
          inputSubmitCallback={submitMessage}
          inputSetText={us_setInputText}
        />
      </Box>
    )
    return inputJSX
  }

  const rJSX_ThreadView = (): JSX.Element => {
    let threadViewJSX = <></>

    // TODO - CSS

    threadViewJSX = (
      <Card
        className="tw-p-2"
        // sx={{ position: "relative" }}
      >
        <Box>{rJSX_ThreadHeader()}</Box>
        <Box
          sx={{
            maxHeight: us_messageContainerHeight,
            overflowY: 'scroll',
          }}
        >
          {rJSX_ThreadMessages()}
        </Box>
        <Box
          id="rfbp-forum-input-container"
          // sx={{
          // 	position: "absolute",
          // 	bottom: "0",
          // 	left: "0",
          // 	background: themeVariables.background_paper,
          // 	width: "100%",
          // 	padding: "4px"
          // }}
        >
          {rJSX_NewMessageInput()}
        </Box>
      </Card>
    )
    return threadViewJSX
  }

  const rJSX_Component = (): JSX.Element => {
    let componentJSX = <></>
    if (us_activeThreadKey != null) {
      componentJSX = rJSX_ThreadView()
    } else {
      componentJSX = rJSX_ThreadList()
    }
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

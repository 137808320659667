///////////////////////////////
// Imports
///////////////////////////////

import { Box, Dialog, DialogContent, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_InboundDataProcedure_Document } from 'rfbp_aux/services/database_endpoints/global/inbound_data_procedures'
import { Json } from 'rfbp_core/components/code_display'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { convertToPacificTime, formInputs_GoogleSheetScraper, googleSheetScraperOptions, timezoneOptions } from '../forms/google_sheet_scraper'

///////////////////////////////
// Exports
///////////////////////////////

export const tableColumns_GoogleSheets: TsInterface_TableColumns = {
  manage: TableCellManage({
    // activate: {
    //   icon: (
    //     <Icon
    //       type="solid"
    //       icon="circle-play"
    //     />
    //   ),
    //   label: <>{rLIB('Activate')}</>,
    //   conditional_display: {
    //     active: true,
    //     logic_type: 'and',
    //     source: null,
    //     prop: null,
    //     comparator: null,
    //     value: null,
    //     conditions: [
    //       {
    //         active: true,
    //         logic_type: 'comparison',
    //         source: 'rowData',
    //         prop: 'status',
    //         comparator: '!=',
    //         value: 'active',
    //         conditions: [],
    //       },
    //     ],
    //   },
    //   onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    //     if (rowData.key != null) {
    //       let updateObject = {
    //         status: 'active',
    //       }

    //       // TODO: Batch Update and add to queue

    //       DatabaseSetMergeDocument(DatabaseRef_InboundDataProcedure_Document(rowData.key as string), updateObject)
    //         .then((res_DSMD) => {
    //           // Nothing
    //         })
    //         .catch((rej_DSMD) => {
    //           tableHooks.uc_setUserInterface_ErrorDialogDisplay({
    //             display: true,
    //             error: rej_DSMD.error,
    //           })
    //         })
    //     }
    //   },
    // },
    // deactivate: {
    //   icon: (
    //     <Icon
    //       type="solid"
    //       icon="circle-pause"
    //     />
    //   ),
    //   label: <>{rLIB('Pause')}</>,
    //   conditional_display: {
    //     active: true,
    //     logic_type: 'and',
    //     source: null,
    //     prop: null,
    //     comparator: null,
    //     value: null,
    //     conditions: [
    //       {
    //         active: true,
    //         logic_type: 'comparison',
    //         source: 'rowData',
    //         prop: 'status',
    //         comparator: '==',
    //         value: 'active',
    //         conditions: [],
    //       },
    //     ],
    //   },
    //   onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
    //     if (rowData.key != null) {
    //       let updateObject = {
    //         status: 'paused',
    //       }

    //       // TODO: Batch Update and remove from queue

    //       DatabaseSetMergeDocument(DatabaseRef_InboundDataProcedure_Document(rowData.key as string), updateObject)
    //         .then((res_DSMD) => {
    //           // Nothing
    //         })
    //         .catch((rej_DSMD) => {
    //           tableHooks.uc_setUserInterface_ErrorDialogDisplay({
    //             display: true,
    //             error: rej_DSMD.error,
    //           })
    //         })
    //     }
    //   },
    // },
    edit: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{rLIB('Edit')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: rowData,
                formInputs: formInputs_GoogleSheetScraper,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let updateObject: TsInterface_UnspecifiedObject = {
                          name: formSubmittedData.name,
                          google_sheet_id: formSubmittedData.google_sheet_id,
                          google_sheet_range: formSubmittedData.google_sheet_range,
                          parser_procedure: formSubmittedData.parser_procedure,
                          // status: 'paused',
                          // client_key: res_GCK.clientKey,
                          inbound_data_type: 'google_sheet_scraper',
                          timestamp_email_scheduled: formSubmittedData.timestamp_email_scheduled,
                          timestamp_email_scheduled_timezone: formSubmittedData.timestamp_email_scheduled_timezone,
                          timestamp_email_scheduled_pacific: convertToPacificTime(
                            formSubmittedData.timestamp_email_scheduled,
                            formSubmittedData.timestamp_email_scheduled_timezone,
                          ),
                        }
                        DatabaseSetMergeDocument(DatabaseRef_InboundDataProcedure_Document(rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            resolve({ success: true })
                          })
                          .catch((rej_DSMD) => {
                            reject(rej_DSMD)
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                          })
                      })
                      .catch((rej_GCK) => {
                        reject(rej_GCK)
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: <>{rLIB('New Google Sheet Scraper')}</>,
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        }
      },
    },
    delete: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '!=',
            value: 'active',
            conditions: [],
          },
        ],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              icon: <Icon icon="trash" />,
              header: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this inbound data procedure?'),
              submit_text: rLIB('Delete'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      if (res_GCK.clientKey != null && rowData.key != null) {
                        let updateObject = {
                          status: 'deleted',
                        }
                        DatabaseSetMergeDocument(DatabaseRef_InboundDataProcedure_Document(rowData.key as string), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      }
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
    test: {
      icon: (
        <Icon
          type="solid"
          icon="flask-round-potion"
        />
      ),
      label: <>{rLIB('Test')}</>,
      conditional_display: {
        active: true,
        logic_type: 'and',
        source: null,
        prop: null,
        comparator: null,
        value: null,
        conditions: [
          {
            active: true,
            logic_type: 'comparison',
            source: 'rowData',
            prop: 'status',
            comparator: '!=',
            value: 'active',
            conditions: [],
          },
        ],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              icon: <Icon icon="flask-round-potion" />,
              header: rLIB('Test'),
              text: rLIB('Are you sure that you want to test this inbound data procedure?'),
              submit_text: rLIB('Test'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      if (res_GCK.clientKey != null && rowData.key != null) {
                        cloudFunctionManageRequest('manageData', {
                          function: 'return_google_sheets_scraper',
                          client_key: res_GCK.clientKey,
                          google_sheet_id: rowData['google_sheet_id'],
                          google_sheet_range: rowData['google_sheet_range'],
                        })
                          .then((res_CFMR) => {
                            let rawSheetData = getProp(getProp(res_CFMR, 'data', {}), 'values', [])

                            tableHooks.uc_setUserInterface_CustomDialogDisplay({
                              display: true,
                              dialog: {
                                dialog_jsx: (
                                  <Dialog
                                    className="bp_dialog_xl_width"
                                    keepMounted
                                    onClose={() => {
                                      tableHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                                    }}
                                    open={true}
                                  >
                                    <DialogContent sx={{ padding: '0px' }}>
                                      <TableContainer>
                                        <Table size="small">
                                          <TableBody>
                                            {rawSheetData.map((row: any[], rowIndex: number) => (
                                              <TableRow key={rowIndex}>
                                                {row.map((cell: any, cellIndex: number) => (
                                                  <TableCell
                                                    key={cellIndex}
                                                    className="tw-px-1"
                                                  >
                                                    {cell}
                                                  </TableCell>
                                                ))}
                                              </TableRow>
                                            ))}
                                          </TableBody>
                                        </Table>
                                      </TableContainer>

                                      <Json data={getProp(res_CFMR, 'data', {})} />
                                    </DialogContent>
                                  </Dialog>
                                ),
                                settings: {
                                  max_width: 'lg',
                                },
                              },
                            })
                            resolve(res_CFMR)
                          })
                          .catch((rej_CFMR) => {
                            console.error(rej_CFMR)
                            tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CFMR.error })
                            reject(rej_CFMR)
                          })
                      }
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                      reject(rej_GCK)
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  icon: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (rowData.status === 'paused') {
          cellJSX = (
            <Icon
              icon="circle-pause"
              tooltip={rLIB('Paused')}
              tooltipPlacement="right"
              sx={{ color: themeVariables.warning_main, fontSize: '20px' }}
            />
          )
        } else if (rowData.status === 'active') {
          cellJSX = (
            <Icon
              icon="play"
              tooltip={rLIB('Active')}
              tooltipPlacement="right"
              sx={{ color: themeVariables.success_main, fontSize: '20px' }}
            />
          )
        } else if (rowData.status === 'deleted') {
          cellJSX = (
            <Icon
              icon="trash"
              tooltip={rLIB('Active')}
              tooltipPlacement="right"
              sx={{ color: themeVariables.error_main, fontSize: '20px' }}
            />
          )
        } else {
          cellJSX = (
            <Icon
              icon="circle-question"
              tooltip={rowData.status as string}
              tooltipPlacement="right"
              sx={{ color: themeVariables.secondary_main, fontSize: '20px' }}
            />
          )
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  name: TableCellBasic('name', rLIB('Google Sheet Name'), 'name'),
  google_sheet_id: TableCellBasic('google_sheet_id', rLIB('Google Sheet ID'), 'google_sheet_id'),
  google_sheet_range: TableCellBasic('google_sheet_range', rLIB('Google Sheet Range'), 'google_sheet_range'),
  parser_procedure: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Parser Procedure')
      },
      header_sort_by: 'parser_procedure',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData.parser_procedure != null &&
          googleSheetScraperOptions != null &&
          googleSheetScraperOptions[rowData.parser_procedure as string] != null &&
          googleSheetScraperOptions[rowData.parser_procedure as string].value != null
        ) {
          cellJSX = <Box>{googleSheetScraperOptions[rowData.parser_procedure as string].value}</Box>
        } else {
          cellJSX = <Box>{rowData.parser_procedure}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  timestamp_email_scheduled: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Timestamp to Run')
      },
      header_sort_by: 'timestamp_email_scheduled',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        if (
          rowData != null &&
          rowData.timestamp_email_scheduled != null &&
          rowData.timestamp_email_scheduled_timezone != null &&
          timezoneOptions != null &&
          timezoneOptions[rowData.timestamp_email_scheduled_timezone as string] != null &&
          timezoneOptions[rowData.timestamp_email_scheduled_timezone as string].value != null
        ) {
          cellJSX = (
            <Box>
              <Box className="tw-inline-block tw-mr-1.5">{rowData.timestamp_email_scheduled}</Box>
              <Box className="tw-inline-block tw-opacity-30">{timezoneOptions[rowData.timestamp_email_scheduled_timezone as string].value}</Box>
            </Box>
          )
        } else {
          cellJSX = <Box>{rowData.timestamp_email_scheduled}</Box>
        }
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
}

export const tableSettings_GoogleSheets: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
}

import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { useEffect, useState } from 'react'
import { DatabaseRef_Vehicle_Document } from 'rfbp_aux/services/database_endpoints/directory/vehicles'
import { DatabaseRef_VehicleAssignmentHistory_Document } from 'rfbp_aux/services/database_endpoints/operations/vehicle_assignment_history'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import { rLIB } from 'rfbp_core/localization/library'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseBatchUpdate, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'

const VehicleAssignmentInTableDialog: React.FC<any> = ({ us_vehicle, tableHooks, uc_setUserInterface_CustomDialogDisplay }) => {
  const [us_newDriverAssignmentDriverKey, setUs_newDriverAssignmentDriverKey] = useState<string | null>(null)
  const [us_newDriverAssignmentDriverName, setUs_newDriverAssignmentDriverName] = useState<string | null>(null)
  const [us_newDriverAssignmentDate, setUs_newDriverAssignmentDate] = useState<string | null>(null)
  const [us_newDriverAssignmentUpdateRoot, setUs_newDriverAssignmentUpdateRoot] = useState<boolean>(true)

  useEffect(() => {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hours = date.getHours()
    let minutes = date.getMinutes()

    let compositeDate = year.toString() + '-'
    if (month < 10) {
      compositeDate += '0' + month.toString() + '-'
    } else {
      compositeDate += month.toString() + '-'
    }
    if (day < 10) {
      compositeDate += '0' + day.toString()
    } else {
      compositeDate += day.toString()
    }
    compositeDate += 'T'
    if (hours < 10) {
      // @ts-expect-error - TODO: reason for error
      hours = '0' + hours.toString()
    }
    compositeDate += hours
    if (minutes < 10) {
      // @ts-expect-error - TODO: reason for error
      minutes = '0' + minutes.toString()
    } else {
      // @ts-expect-error - TODO: reason for error
      minutes = minutes.toString()
    }
    compositeDate += ':'
    compositeDate += minutes

    // Set to field value
    setUs_newDriverAssignmentDate(compositeDate)
  }, [])

  const rJSX_UserSearchResult = (
    option: TsInterface_UnspecifiedObject,
    searchInputValue: string | null,
    inputHooks: TsInterface_InputHooksObject,
    additionalSearchData: TsInterface_UnspecifiedObject,
  ): JSX.Element => {
    return (
      <Box
        sx={{ marginLeft: '8px', marginRight: '8px' }}
        onClick={() => {
          setUs_newDriverAssignmentDriverKey(option.id)
          setUs_newDriverAssignmentDriverName(option.name)
        }}
      >
        <Typography className="tw-cursor-pointer">{rJSX_HighlightedSearchString(searchInputValue, option.name)}</Typography>
      </Box>
    )
  }

  const rJSX_DatePicker = (): JSX.Element => {
    return (
      <Box className="tw-align-top">
        <FormControl sx={{ width: '100%' }}>
          <TextField
            sx={{ width: '100%' }}
            color="primary"
            value={us_newDriverAssignmentDate || ''}
            label={rLIB('Custody Start Date')}
            margin="normal"
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                setUs_newDriverAssignmentDate(event.target.value)
              }
            }}
            type="datetime-local"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      </Box>
    )
  }

  const rJSX_UserSelection = (): JSX.Element => {
    if (us_newDriverAssignmentDriverKey == null || us_newDriverAssignmentDriverName == null) {
      return (
        <Box>
          <SearchInput
            clientKey={tableHooks.uc_RootData_ClientKey as string}
            searchIndexKey={'users'}
            searchFilters={[]}
            searchResultRenderer={rJSX_UserSearchResult}
            additionalSearchData={{}}
            defaultSearchValue={''}
            sx={{ minWidth: '100%' }}
          />
          <Box className="tw-mt-4">{rJSX_DatePicker()}</Box>
          <Box className="tw-mt-4">{rJSX_UpdateRootSelection()}</Box>
        </Box>
      )
    } else {
      return (
        <Box>
          <Typography variant="h6">
            {rLIB('Assigned To')}: {us_newDriverAssignmentDriverName}
          </Typography>
          <Box className="tw-mt-4">{rJSX_DatePicker()}</Box>
          <Box className="tw-mt-4">{rJSX_UpdateRootSelection()}</Box>
        </Box>
      )
    }
  }

  const rJSX_UpdateRootSelection = (): JSX.Element => {
    return (
      <FormControl fullWidth>
        <RadioGroup
          onChange={(event, value) => {
            if (value != null) {
              setUs_newDriverAssignmentUpdateRoot(value as unknown as boolean)
            }
          }}
          value={us_newDriverAssignmentUpdateRoot}
          sx={{ display: 'block' }}
        >
          <FormControlLabel
            sx={{ display: 'flex' }}
            control={<Radio />}
            label={rLIB('Update Active Assigned Driver')}
            value={true}
          />
          <FormControlLabel
            sx={{ display: 'flex' }}
            control={<Radio />}
            label={rLIB("Don't Update Active Assigned Driver")}
            value={false}
          />
        </RadioGroup>
      </FormControl>
    )
  }

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
  ) {
    return (
      <Slide
        direction="up"
        ref={ref}
        {...props}
      />
    )
  })

  const rJSX_SubmitButton = (): JSX.Element => {
    return (
      <Button
        variant="contained"
        className="tw-mt-2"
        color="success"
        disabled={us_newDriverAssignmentDate == null || us_newDriverAssignmentDriverKey == null || us_newDriverAssignmentDriverName == null}
        onClick={() => {
          if (us_newDriverAssignmentDate != null && us_newDriverAssignmentDriverKey != null && us_newDriverAssignmentDriverName != null) {
            const rootUpdateObject = {
              associated_user_key: us_newDriverAssignmentDriverKey,
              associated_user_name: us_newDriverAssignmentDriverName,
            }
            const logKey = uuidv4()
            const updateObject = {
              key: logKey,
              associated_user_key: us_newDriverAssignmentDriverKey,
              associated_user_name: us_newDriverAssignmentDriverName,
              associated_vehicle_key: us_vehicle.key,
              timestamp_start: new Date(us_newDriverAssignmentDate as string),
            }
            getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
              .then((res_GCK) => {
                const updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                  {
                    type: 'setMerge',
                    ref: DatabaseRef_VehicleAssignmentHistory_Document(res_GCK.clientKey, logKey),
                    data: updateObject,
                  },
                ]
                if (us_newDriverAssignmentUpdateRoot === true) {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_Vehicle_Document(res_GCK.clientKey, us_vehicle.key),
                    data: rootUpdateObject,
                  })
                }
                DatabaseBatchUpdate(updateArray)
                  .then((res_DBU) => {
                    uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                  })
                  .catch((rej_DBU) => {
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                  })
              })
              .catch((rej_GCK) => {
                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
              })
          }
        }}
      >
        <Icon
          icon="user-plus"
          className="tw-mr-2"
        />
        {rLIB('Add Driver Assignment')}
      </Button>
    )
  }

  return (
    <Box>
      <Dialog
        className="bp_dialog_lg_width"
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              disabled
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, color: '#fff !important' }}
            >
              <Icon icon="pen-to-square" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              {rLIB('Assign Vehicle')}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            {rJSX_UserSelection()}
            <Box className="tw-mt-2 tw-text-right">{rJSX_SubmitButton()}</Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default VehicleAssignmentInTableDialog

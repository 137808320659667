///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesOpportunityDiscoverySession_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellManage,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'

///////////////////////////////
// Functions
///////////////////////////////

export const tableSettings_OpportunityDiscovery: TsInterface_TableDatabaseSettings = {
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        comparator: '==',
        conditions: [],
        logic_type: 'comparison',
        prop: 'status',
        source: 'rowData',
        value: 'deleted',
      },
    },
  ],
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_created',
  use_live_data: true,
}

export const tableColumns_OpportunityCampaign: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.SalesOpportunityDiscoverySessionViewPage.url(rowData.key as string))
        }
      },
    },
    undelete: {
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
      icon: (
        <Icon
          type="solid"
          icon="wand-magic-sparkles"
        />
      ),
      label: <>{rLIB('Undelete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'info',
              header: rLIB('Undelete Opportunity Campaign'),
              icon: (
                <Icon
                  icon="wand-magic-sparkles"
                  type="solid"
                />
              ),
              submit_text: rLIB('Undelete'),
              text: rLIB('Are you sure that you want to undelete this discovery session?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = { status: 'active', timestamp_deleted: new Date() }
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunityDiscoverySession_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DDD) => {
                          resolve(res_DDD)
                        })
                        .catch((rej_DDD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
    delete: {
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '!=',
        value: 'deleted',
        conditions: [],
      },
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: <>{rLIB('Delete')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
            display: true,
            confirm: {
              color: 'error',
              header: rLIB('Delete Opportunity Campaign'),
              icon: (
                <Icon
                  icon="trash"
                  type="solid"
                />
              ),
              submit_text: rLIB('Delete'),
              text: rLIB('Are you sure that you want to delete this discovery session?'),
              submit_callback: () => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      let updateObject = { status: 'deleted', timestamp_deleted: new Date() }
                      DatabaseSetMergeDocument(DatabaseRef_SalesOpportunityDiscoverySession_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                        .then((res_DDD) => {
                          resolve(res_DDD)
                        })
                        .catch((rej_DDD) => {
                          tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DDD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
          })
        }
      },
    },
  }),
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Campaign Name')
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(
                    event,
                    tableHooks.un_routerNavigation,
                    ApplicationPages.SalesOpportunityDiscoverySessionViewPage.url(rowData.key as string),
                  )
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  },
  timestamp_created: TableCellTimestamp('timestamp_created', rLIB('Creation Date'), 'timestamp_created', 'D MMM YYYY h:mm a', false),
}

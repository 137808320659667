///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_GlobalDesktopWiki_SectionActiveItems_Query = (pageKey: string, sectionKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'global', 'wiki', 'desktop', pageKey, 'sections', sectionKey, 'items'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_GlobalDesktopWiki_SectionItems_Collection = (pageKey: string, sectionKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'global', 'wiki', 'desktop', pageKey, 'sections', sectionKey, 'items')
}

export const DatabaseRef_GlobalDesktopWiki_SectionItem_Document = (pageKey: string, sectionKey: string, itemKey: string) => {
  return doc(getFirestore(), 'global', 'wiki', 'desktop', pageKey, 'sections', sectionKey, 'items', itemKey)
}

///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Exports
///////////////////////////////

export const formInputs_Supplier: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('Supplier Name'),
    required: true,
  },
  po_abbreviation: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'po_abbreviation',
    label: rLIB('PO Abbreviation'),
    required: true,
  },
  // location_address: {
  //   data_type: 'string',
  //   input_type: 'text_basic',
  //   key: 'location_address',
  //   label: s_STREET_ADDRESS,
  //   required: true,
  // },
  // location_city: {
  //   data_type: 'string',
  //   input_type: 'text_basic',
  //   key: 'location_city',
  //   label: s_CITY,
  //   required: true,
  // },
  // location_state: {
  //   data_type: 'string',
  //   input_type: 'multiple_choice_select_state_usa',
  //   key: 'location_state',
  //   label: s_STATE,
  //   required: true,
  // },
  // location_zip: {
  //   data_type: 'string',
  //   input_type: 'text_basic',
  //   key: 'location_zip',
  //   label: s_ZIP,
  //   required: true,
  // },
}

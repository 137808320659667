//======================================//
//		  ooOOOO BOILERPLATE TEMPLATE   //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Generic unbranded login page

		TODO:
			[ ] Feature - Get Login email working

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import { useContext, useEffect } from 'react'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { rJSX_StagingWarning } from 'rfbp_aux/containers/authenticated_container'
import { DatabaseRef_ShortUrl_Document } from 'rfbp_aux/services/database_endpoints/global/short_urls'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import { DatabaseGetDocument } from 'rfbp_core/services/database_management'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_FAILED_TO_REDIRECT: JSX.Element = <Trans>Failed to redirect</Trans>
const se_REDIRECTING = 'Redirecting...'
// { sort-end } - displayed text

// CSS

// Passwordless Auth

// Form Properties

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const linkKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_REDIRECTING
  }, [])

  useEffect(() => {
    DatabaseGetDocument(DatabaseRef_ShortUrl_Document(linkKey))
      .then((res_DGD) => {
        if (res_DGD.data != null && res_DGD.data.redirect_url != null) {
          window.location.href = res_DGD.data.redirect_url
        } else {
          uc_setUserInterface_ErrorDialogDisplay({
            display: true,
            error: {
              message: s_FAILED_TO_REDIRECT,
              details: rLIB('Invalid Url'),
              code: 'ER-D-SU-RD-01',
            },
          })
        }
      })
      .catch((err_DGD) => {
        uc_setUserInterface_ErrorDialogDisplay({
          display: true,
          error: {
            message: s_FAILED_TO_REDIRECT,
            details: rLIB('Invalid Url'),
            code: 'ER-D-SU-RD-02',
          },
        })
      })
  }, [linkKey, uc_setUserInterface_ErrorDialogDisplay])

  // Functions

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = <Box>{rJSX_StagingWarning()}</Box>
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Messages_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'messages', 'main')
}

export const DatabaseRef_Message_Document = (clientKey: string, messageKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'messages', 'main', messageKey)
}

export const DatabaseRef_MessageThreads_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'messages', 'main')
}

export const DatabaseRef_MessageThread_Document = (clientKey: string, messageThreadKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'messages', 'main', messageThreadKey)
}

export const DatabaseRef_MessageThreadMessages_Collection = (clientKey: string, messageThreadKey: string) => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'messages', 'main', messageThreadKey, 'messages')
}

export const DatabaseRef_UserAllMessageThreadsCollection_Query = (clientKey: string, userKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_member_keys.' + userKey, comparator: '==', value: true }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'messages', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_ProjectMessageThreadsCollection_Query = (clientKey: string, projectKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_project_key', comparator: '==', value: projectKey }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'messages', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_UserUnreadMessageThreadsCollection_Query = (clientKey: string, userKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_member_keys.' + userKey, comparator: '==', value: true },
    { prop: 'associated_member_unread.' + userKey, comparator: '==', value: true },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'messages', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_MemberSearchMessageThreadsCollection_Query = (clientKey: string, members: TsInterface_UnspecifiedObject): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'total_members', comparator: '==', value: objectToArray(members).length }]
  for (let memberKey in members) {
    queryOperatorsArray.push({ prop: 'associated_member_keys.' + memberKey, comparator: '==', value: true })
  }
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'messages', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_MessageThreadMessages_CollectionOrdered_Query = (
  clientKey: string,
  messageThreadKey: string,
  limit: number,
  startAt: Date | null,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
  if (startAt == null) {
    startAt = new Date()
  }
  let queryCursorsObject = { startAt: startAt }
  let orderByArray = [{ prop: 'timestamp', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'messages', 'main', messageThreadKey, 'messages'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}

export const DatabaseRef_UserUnreadMessageThreads_AllNav_Query = (clientKey: string, userKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_member_keys.' + userKey, comparator: '==', value: true },
    { prop: 'associated_member_unread.' + userKey, comparator: '==', value: true },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'messages', 'main'), queryOperatorsArray, [], {}, 1)
}

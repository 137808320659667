import { Box, Tooltip } from '@mui/material/'
import { Stack } from '@mui/system'
import { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_FeedbackData, TsInterface_FeedbackGraphSettings } from '../../../rfbp_core/components/feedback/feedback_types'

interface TsInterface_ComponentProps {
  feedbackData: TsInterface_FeedbackData
  feedbackSettings: TsInterface_FeedbackGraphSettings
}

interface TsInterface_CycleTimeData {
  lessThan45: number
  between45And120: number
  greaterThan120: number
  total: number
}

const s_LESS_THAN_45: JSX.Element = <Trans>Less than 45 days</Trans>
const s_BETWEEN_45_AND_120: JSX.Element = <Trans>45-120 days</Trans>
const s_GREATER_THAN_120: JSX.Element = <Trans>Greater than 120 days</Trans>

const emptyCycleTimeData = {
  lessThan45: 0,
  between45And120: 0,
  greaterThan120: 0,
  total: 0,
}

export const FeedbackCycleTimeGraph = (props: TsInterface_ComponentProps): JSX.Element => {
  const pr_feedbackData: TsInterface_ComponentProps['feedbackData'] = getProp(props, 'feedbackData', {})
  const pr_feedbackSettings: TsInterface_ComponentProps['feedbackSettings'] = getProp(props, 'feedbackSettings', {})

  const [us_cycleTimeData, us_setCycleTimeData] = useState<TsInterface_CycleTimeData>(emptyCycleTimeData)

  useEffect(() => {
    let newCycleTimeData = {
      lessThan45: 0,
      between45And120: 0,
      greaterThan120: 0,
      total: 0,
    }
    for (let loopFeedbackKey in pr_feedbackData) {
      let loopFeedbackItem = pr_feedbackData[loopFeedbackKey]
      if (loopFeedbackItem != null && loopFeedbackItem.cycle_time != null) {
        if (loopFeedbackItem.cycle_time < 45) {
          newCycleTimeData['lessThan45']++
          newCycleTimeData['total']++
        } else if (loopFeedbackItem.cycle_time >= 45 && loopFeedbackItem.cycle_time <= 120) {
          newCycleTimeData['between45And120']++
          newCycleTimeData['total']++
        } else if (loopFeedbackItem.cycle_time > 120) {
          newCycleTimeData['greaterThan120']++
          newCycleTimeData['total']++
        }
      }
    }
    us_setCycleTimeData(newCycleTimeData)
  }, [pr_feedbackData])

  const rJSX_BreakdownBarSegmentLabels = (label: number): JSX.Element => {
    let labelJSX = <></>
    if (pr_feedbackSettings['show_graph_labels'] === true) {
      labelJSX = <>{label}</>
    }
    return labelJSX
  }

  const rJSX_CycleTimeGraph = (): JSX.Element => {
    let breakdownBarJSX = (
      <Box
        sx={{ width: '100%', height: '40px', minWidth: '300px', overflow: 'hidden' }}
        className="tw-rounded-lg"
      >
        <Stack
          direction="row"
          sx={{ width: '100%' }}
        >
          <Tooltip
            title={
              <>
                {getProp(us_cycleTimeData, 'lessThan45', 0)} {s_LESS_THAN_45}{' '}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.success_main,
                width: (getProp(us_cycleTimeData, 'lessThan45', 0) / us_cycleTimeData.total) * 100 + '%',
                height: '40px',
              }}
            >
              {rJSX_BreakdownBarSegmentLabels(getProp(us_cycleTimeData, 'lessThan45', ''))}
            </Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(us_cycleTimeData, 'between45And120', 0)} {s_BETWEEN_45_AND_120}{' '}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.warning_main,
                width: (getProp(us_cycleTimeData, 'between45And120', 0) / us_cycleTimeData.total) * 100 + '%',
                height: '40px',
              }}
            >
              {rJSX_BreakdownBarSegmentLabels(getProp(us_cycleTimeData, 'between45And120', ''))}
            </Box>
          </Tooltip>
          <Tooltip
            title={
              <>
                {getProp(us_cycleTimeData, 'greaterThan120', 0)} {s_GREATER_THAN_120}{' '}
              </>
            }
            placement="top"
          >
            <Box
              className="tw-text-center tw-py-2"
              sx={{
                background: themeVariables.error_main,
                width: (getProp(us_cycleTimeData, 'greaterThan120', 0) / us_cycleTimeData.total) * 100 + '%',
                height: '40px',
              }}
            >
              {rJSX_BreakdownBarSegmentLabels(getProp(us_cycleTimeData, 'greaterThan120', ''))}
            </Box>
          </Tooltip>
        </Stack>
      </Box>
    )
    return breakdownBarJSX
  }

  const rJSX_Component = (): JSX.Element => {
    return <Box sx={{ overflow: 'hidden' }}>{rJSX_CycleTimeGraph()}</Box>
  }

  return <>{rJSX_Component()}</>
}

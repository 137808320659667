///////////////////////////////
// Imports
///////////////////////////////
import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Exports
///////////////////////////////
export const formInputs_NewSalesOpportunityDiscoverySession: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('Sales Opportunity Campaign Name'),
    required: true,
  },
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_VehicleFuelLogs_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_fuel_logs', 'main')
}

export const DatabaseRef_VehicleFuelLogs_Document = (clientKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_fuel_logs', 'main', logKey)
}

export const DatabaseRef_VehicleFuelLogs_Query = (clientKey: string, startTimestamp: Date, endTimestamp: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_transaction', comparator: '>=', value: startTimestamp },
    { prop: 'timestamp_transaction', comparator: '<=', value: endTimestamp },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_fuel_logs', 'main'), queryOperatorsArray, [], {}, null)
}

// Fuel Log Geolocation
export const DatabaseRef_FuelLogGeolocation_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_fuel_logs', 'fuel_log_geolocation')
}

export const DatabaseRef_FuelLogGeolocation_Document = (clientKey: string, gasStationAddressKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_fuel_logs', 'fuel_log_geolocation', gasStationAddressKey)
}

// Fuel Card Driver Pins
export const DatabaseRef_FuelLogDriverPin_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_fuel_logs', 'fuel_log_driver_pins')
}

export const DatabaseRef_FuelLogDriverPin_Document = (clientKey: string, driverPin: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_fuel_logs', 'fuel_log_driver_pins', driverPin)
}

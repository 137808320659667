//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Used to render data

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import { Trans } from 'react-i18next'
import {
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  // startDate: Date
  // endDate: Date
  logEndpoint: any
  logType: string
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ASSIGNED_TO_A_PROJECT_ROLE: JSX.Element = <Trans>assigned to a project role</Trans>
const s_CODE: JSX.Element = <Trans>Code</Trans>
const s_COMPLETED: JSX.Element = <Trans>Completed</Trans>
const s_CREATED: JSX.Element = <Trans>Created</Trans>
const s_DATE: JSX.Element = <Trans>Date</Trans>
const s_DELETED: JSX.Element = <Trans>Deleted</Trans>
const s_DISABLED: JSX.Element = <Trans>Disabled</Trans>
const s_ENABLED: JSX.Element = <Trans>Enabled</Trans>
const s_LOCKED: JSX.Element = <Trans>Locked</Trans>
const s_MISSING_MAPPING: JSX.Element = <Trans>Missing Mapping</Trans>
const s_PROJECT_CREATED: JSX.Element = <Trans>Project created</Trans>
const s_TASK: JSX.Element = <Trans>Task</Trans>
const s_TASK_WORKFLOW_REMOVED: JSX.Element = <Trans>Task workflow removed</Trans>
const s_TASK_WORKFLOW_SELECTED: JSX.Element = <Trans>Task workflow created</Trans>
const s_TIME: JSX.Element = <Trans>Time</Trans>
const s_UNDELETED: JSX.Element = <Trans>Undeleted</Trans>
const s_UNLOCKED: JSX.Element = <Trans>Unlocked</Trans>
const s_UPDATED: JSX.Element = <Trans>Updated</Trans>
const s_USER: JSX.Element = <Trans>User</Trans>
const s_USER_ACCOUNT_CREATED: JSX.Element = <Trans>User account created</Trans>
const s_USER_ACCOUNT_DEACTIVATED: JSX.Element = <Trans>User account deactivated</Trans>
const s_USER_ACCOUNT_REACTIVATED: JSX.Element = <Trans>User account reactivated</Trans>
const s_USER_ACCOUNT_UPDATED: JSX.Element = <Trans>User account updated</Trans>
// { sort-end } - displayed text

// Table Settings
const tableSettings_Logs: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp',
  use_live_data: true,
  // searchable: true,
  // search_settings: {
  // 	search_no_data_message: s_NO_LOGS_FOUND_BY_SEARCH,
  // 	search_property_options: [
  // 		{ key: "associated_user_name", value: s_USER },
  // 	]
  // },
}

const tableColumns_Logs: TsInterface_TableColumns = {
  date: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let formattedLog = returnFormattedLogs(getProp(tableAdditionalData, 'logType', null), rowData)
        let timestampText = ''
        if (formattedLog.timestamp != null) {
          timestampText = returnFormattedDate(formattedLog.timestamp, 'D MMM YYYY')
        }
        let cellJSX = <Box>{timestampText}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_DATE
      },
      header_sort_by: null,
    },
  },
  time: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let formattedLog = returnFormattedLogs(getProp(tableAdditionalData, 'logType', null), rowData)
        let timestampText = ''
        if (formattedLog.timestamp != null) {
          timestampText = returnFormattedDate(formattedLog.timestamp, 'h:mm a')
        }
        let cellJSX = <Box>{timestampText}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_TIME
      },
      header_sort_by: null,
    },
  },
  associated_user_name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let formattedLog = returnFormattedLogs(getProp(tableAdditionalData, 'logType', null), rowData)
        let cellJSX = <Box>{formattedLog.user}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_USER
      },
      header_sort_by: null,
    },
  },
  event: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let formattedLog = returnFormattedLogs(getProp(tableAdditionalData, 'logType', null), rowData)
        let cellJSX = <Box>{formattedLog.event}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Event')
      },
      header_sort_by: null,
    },
  },
  code: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let formattedLog = returnFormattedLogs(getProp(tableAdditionalData, 'logType', null), rowData)
        let cellJSX = <Box className="tw-italic tw-opacity-30">{formattedLog.code}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_CODE
      },
      header_sort_by: null,
    },
  },
}

///////////////////////////////
// Functions
///////////////////////////////

const returnFormattedLogs = (logType: string, rawLogsObject: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  // Instantiate Variables
  let missingJSX = <Box className="">{s_MISSING_MAPPING}</Box>
  let formattedLog: TsInterface_UnspecifiedObject = {
    code: getProp(rawLogsObject, 'event', null),
    event: getProp(rawLogsObject, 'event', null),
    timestamp: getProp(rawLogsObject, 'timestamp', null),
    user: getProp(rawLogsObject, 'associated_user_name', null),
  }
  switch (logType) {
    case 'project':
      if (rawLogsObject != null && rawLogsObject['timestamp'] != null && rawLogsObject['event']) {
        // Event
        let targettedUserJSX = <></>
        if (rawLogsObject['additional_data'] != null && rawLogsObject['additional_data']['associated_owner_name'] != null) {
          targettedUserJSX = <>{rawLogsObject['additional_data']['associated_owner_name']} </>
        } else {
          targettedUserJSX = <>{s_USER} </>
        }
        let taskNameJSX = <></>
        if (rawLogsObject['additional_data'] != null && rawLogsObject['additional_data']['associated_task_name'] != null) {
          taskNameJSX = <>{rawLogsObject['additional_data']['associated_task_name']}</>
        } else {
          taskNameJSX = <>{s_TASK}</>
        }
        switch (rawLogsObject['event']) {
          case 'add_task_workflow':
            formattedLog['event'] = <>{s_TASK_WORKFLOW_SELECTED}</>
            break
          case 'assigned_to_project_role':
            formattedLog['event'] = (
              <>
                {targettedUserJSX} {s_ASSIGNED_TO_A_PROJECT_ROLE}
              </>
            )
            break
          case 'complete_task':
            formattedLog['event'] = (
              <>
                {taskNameJSX} {s_COMPLETED}
              </>
            )
            break
          case 'create':
            formattedLog['event'] = <>{s_PROJECT_CREATED}</>
            break
          case 'create_ad_hoc_task':
            formattedLog['event'] = (
              <>
                {taskNameJSX} {s_CREATED}
              </>
            )
            break
          case 'create_sow_task':
            formattedLog['event'] = (
              <>
                {taskNameJSX} {s_CREATED}
              </>
            )
            break
          case 'delete_task':
            formattedLog['event'] = (
              <>
                {taskNameJSX} {s_DELETED}
              </>
            )
            break
          case 'remove_task_workflow':
            formattedLog['event'] = <>{s_TASK_WORKFLOW_REMOVED}</>
            break
          default:
            formattedLog['event'] = missingJSX
            break
        }
      }
      break
    case 'user':
      formattedLog['code'] = getProp(rawLogsObject, 'log_type', null)
      switch (formattedLog['code']) {
        case 'create':
          formattedLog['event'] = <>{s_CREATED}</>
          break
        case 'update':
          formattedLog['event'] = <>{s_UPDATED}</>
          break
        case 'delete':
          formattedLog['event'] = <>{s_DELETED}</>
          break
        case 'undelete':
          formattedLog['event'] = <>{s_UNDELETED}</>
          break
        case 'disable':
          formattedLog['event'] = <>{s_DISABLED}</>
          break
        case 'enable':
          formattedLog['event'] = <>{s_ENABLED}</>
          break
        case 'user_account_create':
          formattedLog['event'] = <>{s_USER_ACCOUNT_CREATED}</>
          break
        case 'user_account_update':
          formattedLog['event'] = <>{s_USER_ACCOUNT_UPDATED}</>
          break
        case 'user_account_disable':
          formattedLog['event'] = <>{s_USER_ACCOUNT_DEACTIVATED}</>
          break
        case 'user_account_reenable':
          formattedLog['event'] = <>{s_USER_ACCOUNT_REACTIVATED}</>
          break
        case 'lock':
          formattedLog['event'] = <>{s_LOCKED}</>
          break
        case 'unlock':
          formattedLog['event'] = <>{s_UNLOCKED}</>
          break
        default:
          formattedLog['event'] = missingJSX
          break
      }
      break
    default:
    // Nothing
  }
  return formattedLog
}

///////////////////////////////
// Component
///////////////////////////////

export const LogTable = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_logEndpoint: any = getProp(props, 'logEndpoint', null)
  let pr_logType: string = getProp(props, 'logType', null)

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const tableDatabaseEndpoint_Logs = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(pr_logEndpoint, queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <TableDatabase
          tableAdditionalData={{ logType: pr_logType }}
          tableColumns={tableColumns_Logs}
          tableDatabaseEndpoint={tableDatabaseEndpoint_Logs}
          tableSettings={tableSettings_Logs}
        />
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_GlobalCronJobRecurringDailyTasks_Collection = (): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'global', 'cron_job_tasks', 'recurring_daily')
}

export const DatabaseRef_GlobalCronJobRecurringDailyTask_Document = (taskKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'global', 'cron_job_tasks', 'recurring_daily', taskKey)
}

export const DatabaseRef_GlobalCronJobRecurringDailyTaskTimestamp_Query = (oneDayAgo: Date): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'timestamp_last_run', comparator: '<=', value: oneDayAgo }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'global', 'cron_job_tasks', 'recurring_daily'), queryOperatorsArray, orderByArray, {}, 100)
}

//======================================//
//		  ooOOOO USED BY BOILERPLATE    //
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//======================================//

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Database Endpoints provides all references to locations in firestore

			// documentRef = doc(getFirestore(), "items", "itemKey")
			// collectionRef = collection(getFirestore(), "items")
			// collectionRef = query( collection(getFirestore(), "items"), where("type", "==", "new") )

			// Query Operators
				// <
				// <=
				// ==
				// >
				// >=
				// !=
				// array-contains 			where("regions", "array-contains", "west_coast")
				// array-contains-any 		where('regions', 'array-contains-any', ['west_coast', 'east_coast'])
				// in 						where('country', 'in', ['USA', 'Japan'])
				// not-in					where('country', 'not-in', ['USA', 'Japan'])

		TODO:
			[ ] Feature - compound queries
			[ ] Feature - Move users out of directory into their own area??
			[ ] Typescript - 1 instance of "@ts-expect-error"
			[ ] Typescript - 2 instances of unknown
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

/* DATABASE QUERTY GENERATION PARAMS
		baseCollectionEndpoint: FUNCTION
			- must be a firestore collection

		queryOperatorsArray: ARRAY
			- array of query comparators
			EXAMPLE:
			let queryOperatorsArray = [
				{
					prop: "state",
					comparator: "==",
					value: "UT"
				},
				{
					prop: "population",
					comparator: "<=",
					value: 10000
				},
			]

		orderByArray: ARRAY
			- array of fields to order by (typically one field)

			EXAMPLE:
				let orderByArray = [{prop: "city_name", desc: true}]
				let orderByArray = [{prop: "city_name", desc: true}, {prop: "city_name", desc: null}]
				// NOTE - if multiple orders are used, Query Cursors will need to include all properties

		queryCursorsObject: OBJECT
			- object containing start and end bounds to a query
			EXAMPLE:
				// Get Population between 10000 and 20000
				let queryCursorsObject = { // Inclusive
					startAt: 10000,
					endAt: 20000,
				}
				let queryCursorsObject = { // Exclusive (not used if inclusive queries are also used)
					startAfter: 10000,
					endBefore: 20000,
				}

		limitCount: NUMBER

	*/

///////////////////////////////
// Exports
///////////////////////////////

// Global Users
export const DatabaseRef_GlobalUser_Document = (userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'users', userKey)
}

export const DatabaseRef_GlobalUserSuper_Collection = (): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'super', comparator: '==', value: true }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'users'), queryOperatorsArray, orderByArray, {}, null)
}

export const DatabaseRef_GlobalUsersWithToggle_Collection = (): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'has_toggle', comparator: '==', value: true }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(collection(getFirestore(), 'users'), queryOperatorsArray, orderByArray, {}, null)
}

// Root Clients
export const DatabaseRef_RootClient_Collection = (): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients')
}

export const DatabaseRef_RootClient_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey)
}

// Client Permissions
export const DatabaseRef_ClientPermissions_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'settings', 'permissions')
}

// Directory - Users
export const DatabaseRef_Users_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main')
}

export const DatabaseRef_ClientUser_Document = (clientKey: string, userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main', userKey)
}

export const DatabaseRef_UsersOrdered_Query = (
  clientKey: string,
  orderBy: string,
  orderDesc: boolean,
  limit: number | null,
  queryCursorsObject: TsInterface_QueryCursorsObject,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
  // let queryOperatorsArray = [ { prop: "active", comparator: "==", value: true } ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: orderBy, desc: orderDesc }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}

export const DatabaseRef_UserLogs_Collection = (clientKey: string, userKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'users', 'main', userKey, 'logs')
}

// Boilerplate Docs - Items
export const DatabaseRef_Items_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'items', 'main')
}

export const DatabaseRef_Item_Document = (clientKey: string, itemKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'items', 'main', itemKey)
}

export const DatabaseRef_ItemsOrdered_Query = (
  clientKey: string,
  orderBy: string,
  orderDesc: boolean,
  limit: number | null,
  queryCursorsObject: TsInterface_QueryCursorsObject,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
  // let queryOperatorsArray = [ { prop: "active", comparator: "==", value: true } ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: orderBy, desc: orderDesc }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'items', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}

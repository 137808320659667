///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SuppliersWithSpecificPO_Query, DatabaseRef_Supplier_Document } from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseGetCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { formInputs_Supplier } from '../forms/form_new_supplier'

///////////////////////////////
// Exports
///////////////////////////////

export const tableColumns_MaterialSuppliers: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View Supplier')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsSupplierViewPage.url(rowData.key as string))
        }
      },
    },
    view_portal: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{rLIB('View Supplier Order Portal')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
            .then((res_GCK) => {
              directAppNavigation(
                tableHooks.un_routerNavigation,
                ApplicationPages.UnauthenticatedMaterialsSupplierOrdersListPage.url(res_GCK.clientKey, rowData.key as string),
              )
            })
            .catch((rej_GCK) => {
              console.error(rej_GCK)
            })
        }
      },
    },
    edit: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: <>{rLIB('Edit Supplier')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: rowData,
                formInputs: formInputs_Supplier,
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        // Make sure that the PO abbreviation is unique
                        let promiseArray: TsType_UnknownPromise[] = []
                        let poAbbreviation = formSubmittedData.po_abbreviation.toUpperCase()
                        let matchingSuppliers: TsInterface_UnspecifiedObject = {}
                        promiseArray.push(
                          DatabaseGetCollection(DatabaseRef_SuppliersWithSpecificPO_Query(res_GCK.clientKey, poAbbreviation))
                            .then((res_DGC) => {
                              for (let supplierKey in res_DGC.data) {
                                if (supplierKey !== rowData.key) {
                                  matchingSuppliers[supplierKey] = res_DGC.data[supplierKey]
                                }
                              }
                            })
                            .catch((rej_DGC) => {
                              console.error(rej_DGC)
                            }),
                        )
                        Promise.all(promiseArray).finally(() => {
                          if (objectToArray(matchingSuppliers).length === 0) {
                            let updateObject = { ...formSubmittedData }
                            updateObject.po_abbreviation = poAbbreviation
                            updateObject.status = 'active'
                            // Save to Database
                            DatabaseSetMergeDocument(DatabaseRef_Supplier_Document(res_GCK.clientKey, rowData.key as string), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          } else {
                            let error = {
                              message: rLIB('Failed to update supplier'),
                              details: rLIB('PO Abbreviation already used'),
                              code: 'ER-D-MI-ES-01',
                            }
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                            reject({ success: false, error: error })
                          }
                        })
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject(rej_GCK)
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: rLIB('Update Supplier'),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="circle-plus"
                  />
                ),
              },
            },
          })
        }
      },
    },
  }),
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Supplier Name')
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(
                    event,
                    tableHooks.un_routerNavigation,
                    ApplicationPages.AdminDatabaseMaterialsSupplierViewPage.url(rowData.key as string),
                  )
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  po_abbreviation: TableCellBasic('po_abbreviation', rLIB('PO Abbreviation'), 'po_abbreviation'),
  // location_address: TableCellBasic('location_address', rLIB('Street Address'), 'location_address'),
  // location_city: TableCellBasic('location_city', rLIB('City'), 'location_city'),
  // location_state: TableCellBasic('location_state', rLIB('State'), 'location_state'),
  // location_zip: TableCellBasic('location_zip', rLIB('Zip'), 'location_zip'),
}

export const tableSettings_MaterialSuppliers: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: true,
  // conditional_row_styles: [
  // 	{
  // 		className: "tw-opacity-30 tw-line-through",
  // 		conditional_display: {
  // 			active: true,
  // 			logic_type: "comparison",
  // 			source: "rowData",
  // 			prop: "status",
  // 			comparator: "==",
  // 			value: "deleted",
  // 			conditions: []
  // 		},
  // 	},
  // ],
  // searchable: true,
  // search_settings_database: {
  // 	search_type: "firebase",
  // 	search_no_data_message: s_NO_VEHICLES_FOUND_BY_SEARCH,
  // 	search_property_options: [
  // 		{ key: "vin", value: s_VEHICLE_VIN },
  // 	]
  // },
}

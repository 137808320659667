///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Notifications_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'notifications', 'main')
}

export const DatabaseRef_Notification_Document = (clientKey: string, notificationKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'notifications', 'main', notificationKey)
}

export const DatabaseRef_NotificationsUserUnread_Query = (
  clientKey: string,
  userKey: string,
  orderBy: string,
  orderDesc: boolean,
  limit: number | null,
  queryCursorsObject: TsInterface_QueryCursorsObject,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'unread' },
    { prop: 'associated_user_key', comparator: '==', value: userKey },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: orderBy, desc: orderDesc }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'notifications', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}

export const DatabaseRef_NotificationsUserRead_Query = (
  clientKey: string,
  userKey: string,
  orderBy: string,
  orderDesc: boolean,
  limit: number | null,
  queryCursorsObject: TsInterface_QueryCursorsObject,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'read' },
    { prop: 'associated_user_key', comparator: '==', value: userKey },
  ]
  let orderByArray: TsInterface_OrderByArray = [{ prop: orderBy, desc: orderDesc }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'notifications', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}

export const DatabaseRef_UnreadNotifications_AllNav_Query = (clientKey: string, userKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'status', comparator: '==', value: 'unread' },
    { prop: 'associated_user_key', comparator: '==', value: userKey },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'notifications', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    1,
  )
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Basic Data Display

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Typography } from '@mui/material/'
import { TsInterface_DataViewerAdditionalData, TsInterface_DataViewerData, TsInterface_DataViewerLine } from 'rfbp_core/components/data_viewer'
import { returnFormattedPhoneNumberString, returnStringFromValue } from 'rfbp_core/services/helper_functions'

import { TsInterface_DataViewerHooksObject } from '../data_viewer_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const ViewerLinePhone = (label: string | JSX.Element, propKey: string): TsInterface_DataViewerLine => {
  let viewerLine = {
    line_jsx: (
      viewerData: TsInterface_DataViewerData,
      viewerAdditionalData: TsInterface_DataViewerAdditionalData,
      viewerHooks: TsInterface_DataViewerHooksObject,
    ) => {
      let propValue = ''
      if (propKey != null && viewerData != null && viewerData[propKey] != null) {
        propValue = returnStringFromValue(viewerData[propKey] as string | number | boolean)
      }
      let lineJSX = (
        <Typography className="tw-ml-4">
          <Box component="span">{label}: </Box>
          <Box
            component="span"
            className="tw-opacity-50"
          >
            {returnFormattedPhoneNumberString(propValue)}
          </Box>
        </Typography>
      )
      return lineJSX
    },
    line_css: (viewerData: TsInterface_DataViewerData, viewerAdditionalData: TsInterface_DataViewerAdditionalData) => {
      return ''
    },
  }
  return viewerLine
}

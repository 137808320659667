///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, FormControl, Stack, TextField } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_RootData_ClientKey } from 'rfbp_core/services/context'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SunrunApiPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_PROD_PROPOSAL_ID: JSX.Element = <Trans>Prod Proposal ID</Trans>
const s_PROD_PROSPECT_ID: JSX.Element = <Trans>Prod Prospect ID</Trans>
const s_STAGING_PROPOSAL_ID: JSX.Element = <Trans>Staging Proposal ID</Trans>
const s_STAGING_PROSPECT_ID: JSX.Element = <Trans>Staging Prospect ID</Trans>
const se_SUNRUN_API = 'Sunrun API'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_apiResults, us_setApiResults] = useState<TsInterface_UnspecifiedObject>({})
  const [us_disabled, us_setDisabled] = useState<boolean>(false)
  const [us_prodProposalID, us_setProdProposalID] = useState<string>('PK4LFCVNRKAA:001-K')
  const [us_prodProspectID, us_setProdProspectID] = useState<string>('7623649561')
  const [us_stagingProposalID, us_setStagingProposalID] = useState<string>('PK4CFDL7V1D9-J')
  const [us_stagingProspectID, us_setStagingProspectID] = useState<string>('1267552807')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_SUNRUN_API
  }, [])

  // Functions

  const getProdLeadByProspectID = (identifier: string) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setApiResults({})
        us_setDisabled(true)
        cloudFunctionManageRequest('manageIntegrations', {
          function: 'sunrunProdGetLeadByProspectID',
          client_key: res_GCK.clientKey,
          prospect_id: identifier,
        })
          .then((res_CFMIR: any) => {
            console.log(res_CFMIR)
            us_setApiResults(res_CFMIR.data)
            us_setDisabled(false)
            ur_forceRerender()
          })
          .catch((rej_CFMIR) => {
            console.error(rej_CFMIR)
            us_setApiResults(rej_CFMIR)
            us_setDisabled(false)
            ur_forceRerender()
          })
      })
      .catch((rej_GCK) => {
        us_setApiResults({})
        us_setDisabled(false)
        console.error(rej_GCK)
      })
  }

  const getProdProposalbyProposalId = (identifier: string) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setApiResults({})
        us_setDisabled(true)
        cloudFunctionManageRequest('manageIntegrations', {
          function: 'sunrunProdGetProposalbyProposalId',
          client_key: res_GCK.clientKey,
          proposal_id: identifier,
        })
          .then((res_CFMIR: any) => {
            console.log(res_CFMIR)
            us_setApiResults(res_CFMIR.data)
            us_setDisabled(false)
            ur_forceRerender()
          })
          .catch((rej_CFMIR) => {
            console.error(rej_CFMIR)
            us_setApiResults(rej_CFMIR)
            us_setDisabled(false)
            ur_forceRerender()
          })
      })
      .catch((rej_GCK) => {
        us_setApiResults({})
        us_setDisabled(false)
        console.error(rej_GCK)
      })
  }

  const getProdAssetsByProposalId = (identifier: string) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setApiResults({})
        us_setDisabled(true)
        cloudFunctionManageRequest('manageIntegrations', {
          function: 'sunrunProdGetAssetsByProposalId',
          client_key: res_GCK.clientKey,
          proposal_id: identifier,
        })
          .then((res_CFMIR: any) => {
            console.log(res_CFMIR)
            us_setApiResults(res_CFMIR.data)
            us_setDisabled(false)
            ur_forceRerender()
          })
          .catch((rej_CFMIR) => {
            console.error(rej_CFMIR)
            us_setApiResults(rej_CFMIR)
            us_setDisabled(false)
            ur_forceRerender()
          })
      })
      .catch((rej_GCK) => {
        us_setApiResults({})
        us_setDisabled(false)
        console.error(rej_GCK)
      })
  }

  const getProdDocsByProposalId = (identifier: string) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setApiResults({})
        us_setDisabled(true)
        cloudFunctionManageRequest('manageIntegrations', {
          function: 'sunrunProdGetDocsByProposalId',
          client_key: res_GCK.clientKey,
          proposal_id: identifier,
        })
          .then((res_CFMIR: any) => {
            console.log(res_CFMIR)
            us_setApiResults(res_CFMIR.data)
            us_setDisabled(false)
            ur_forceRerender()
          })
          .catch((rej_CFMIR) => {
            console.error(rej_CFMIR)
            us_setApiResults(rej_CFMIR)
            us_setDisabled(false)
            ur_forceRerender()
          })
      })
      .catch((rej_GCK) => {
        us_setApiResults({})
        us_setDisabled(false)
        console.error(rej_GCK)
      })
  }

  const getProdContractByProspectID = (identifier: string) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setApiResults({})
        us_setDisabled(true)
        cloudFunctionManageRequest('manageIntegrations', {
          function: 'sunrunProdGetContractByProspectID',
          client_key: res_GCK.clientKey,
          prospect_id: identifier,
        })
          .then((res_CFMIR: any) => {
            console.log(res_CFMIR)
            us_setApiResults(res_CFMIR.data)
            us_setDisabled(false)
            ur_forceRerender()
          })
          .catch((rej_CFMIR) => {
            console.error(rej_CFMIR)
            us_setApiResults(rej_CFMIR)
            us_setDisabled(false)
            ur_forceRerender()
          })
      })
      .catch((rej_GCK) => {
        us_setApiResults({})
        us_setDisabled(false)
        console.error(rej_GCK)
      })
  }

  const getStagingLeadByProspectID = (identifier: string) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setApiResults({})
        us_setDisabled(true)
        cloudFunctionManageRequest('manageIntegrations', {
          function: 'sunrunStagingGetLeadByProspectID',
          client_key: res_GCK.clientKey,
          prospect_id: identifier,
        })
          .then((res_CFMIR: any) => {
            console.log(res_CFMIR)
            us_setApiResults(res_CFMIR.data)
            us_setDisabled(false)
            ur_forceRerender()
          })
          .catch((rej_CFMIR) => {
            console.error(rej_CFMIR)
            us_setApiResults(rej_CFMIR)
            us_setDisabled(false)
            ur_forceRerender()
          })
      })
      .catch((rej_GCK) => {
        us_setApiResults({})
        us_setDisabled(false)
        console.error(rej_GCK)
      })
  }

  const getStagingProposalbyProposalId = (identifier: string) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setApiResults({})
        us_setDisabled(true)
        cloudFunctionManageRequest('manageIntegrations', {
          function: 'sunrunStagingGetProposalbyProposalId',
          client_key: res_GCK.clientKey,
          proposal_id: identifier,
        })
          .then((res_CFMIR: any) => {
            console.log(res_CFMIR)
            us_setApiResults(res_CFMIR.data)
            us_setDisabled(false)
            ur_forceRerender()
          })
          .catch((rej_CFMIR) => {
            console.error(rej_CFMIR)
            us_setApiResults(rej_CFMIR)
            us_setDisabled(false)
            ur_forceRerender()
          })
      })
      .catch((rej_GCK) => {
        us_setApiResults({})
        us_setDisabled(false)
        console.error(rej_GCK)
      })
  }

  const getStagingAssetsByProposalId = (identifier: string) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setApiResults({})
        us_setDisabled(true)
        cloudFunctionManageRequest('manageIntegrations', {
          function: 'sunrunStagingGetAssetsByProposalId',
          client_key: res_GCK.clientKey,
          proposal_id: identifier,
        })
          .then((res_CFMIR: any) => {
            console.log(res_CFMIR)
            us_setApiResults(res_CFMIR.data)
            us_setDisabled(false)
            ur_forceRerender()
          })
          .catch((rej_CFMIR) => {
            console.error(rej_CFMIR)
            us_setApiResults(rej_CFMIR)
            us_setDisabled(false)
            ur_forceRerender()
          })
      })
      .catch((rej_GCK) => {
        us_setApiResults({})
        us_setDisabled(false)
        console.error(rej_GCK)
      })
  }

  const getStagingDocsByProposalId = (identifier: string) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setApiResults({})
        us_setDisabled(true)
        cloudFunctionManageRequest('manageIntegrations', {
          function: 'sunrunStagingGetDocsByProposalId',
          client_key: res_GCK.clientKey,
          proposal_id: identifier,
        })
          .then((res_CFMIR: any) => {
            console.log(res_CFMIR)
            us_setApiResults(res_CFMIR.data)
            us_setDisabled(false)
            ur_forceRerender()
          })
          .catch((rej_CFMIR) => {
            console.error(rej_CFMIR)
            us_setApiResults(rej_CFMIR)
            us_setDisabled(false)
            ur_forceRerender()
          })
      })
      .catch((rej_GCK) => {
        us_setApiResults({})
        us_setDisabled(false)
        console.error(rej_GCK)
      })
  }

  const getStagingContractByProspectID = (identifier: string) => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setApiResults({})
        us_setDisabled(true)
        cloudFunctionManageRequest('manageIntegrations', {
          function: 'sunrunStagingGetContractByProspectID',
          client_key: res_GCK.clientKey,
          prospect_id: identifier,
        })
          .then((res_CFMIR: any) => {
            console.log(res_CFMIR)
            us_setApiResults(res_CFMIR.data)
            us_setDisabled(false)
            ur_forceRerender()
          })
          .catch((rej_CFMIR) => {
            console.error(rej_CFMIR)
            us_setApiResults(rej_CFMIR)
            us_setDisabled(false)
            ur_forceRerender()
          })
      })
      .catch((rej_GCK) => {
        us_setApiResults({})
        us_setDisabled(false)
        console.error(rej_GCK)
      })
  }

  // JSX Generation
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sunrun API')}
        pageKey={pageKey}
        content={
          <Box>
            <Stack
              spacing={2}
              direction="row"
            >
              <FormControl>
                <TextField
                  color="primary"
                  value={us_prodProspectID}
                  label={s_PROD_PROSPECT_ID}
                  margin="normal"
                  onChange={(event: any) => {
                    if (event != null && event.target != null && event.target.value != null) {
                      us_setProdProspectID(event.target.value)
                    }
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  color="primary"
                  value={us_prodProposalID}
                  label={s_PROD_PROPOSAL_ID}
                  margin="normal"
                  onChange={(event: any) => {
                    if (event != null && event.target != null && event.target.value != null) {
                      us_setProdProposalID(event.target.value)
                    }
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  color="primary"
                  value={us_stagingProspectID}
                  label={s_STAGING_PROSPECT_ID}
                  margin="normal"
                  onChange={(event: any) => {
                    if (event != null && event.target != null && event.target.value != null) {
                      us_setStagingProspectID(event.target.value)
                    }
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
              <FormControl>
                <TextField
                  color="primary"
                  value={us_stagingProposalID}
                  label={s_STAGING_PROPOSAL_ID}
                  margin="normal"
                  onChange={(event: any) => {
                    if (event != null && event.target != null && event.target.value != null) {
                      us_setStagingProposalID(event.target.value)
                    }
                  }}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                />
              </FormControl>
            </Stack>
            <Button
              className="tw-m-1"
              color="info"
              variant="contained"
              disabled={us_disabled}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                getProdLeadByProspectID(us_prodProspectID)
              }}
            >
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
              />
              Prod - getProdLeadByProspectID
            </Button>
            <Button
              className="tw-m-1"
              color="info"
              variant="contained"
              disabled={us_disabled}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                getProdContractByProspectID(us_prodProspectID)
              }}
            >
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
              />
              Prod - getProdContractByProspectID
            </Button>
            <Button
              className="tw-m-1"
              color="primary"
              variant="contained"
              disabled={us_disabled}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                getProdProposalbyProposalId(us_prodProposalID)
              }}
            >
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
              />
              Prod - getProdProposalbyProposalId
            </Button>
            <Button
              className="tw-m-1"
              color="primary"
              variant="contained"
              disabled={us_disabled}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                getProdAssetsByProposalId(us_prodProposalID)
              }}
            >
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
              />
              Prod - getProdAssetsByProposalId
            </Button>
            <Button
              className="tw-m-1"
              color="primary"
              variant="contained"
              disabled={us_disabled}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                getProdDocsByProposalId(us_prodProposalID)
              }}
            >
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
              />
              Prod - getProdDocsByProposalId
            </Button>

            <Button
              className="tw-m-1"
              color="error"
              variant="contained"
              disabled={us_disabled}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                getStagingLeadByProspectID(us_stagingProspectID)
              }}
            >
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
              />
              Staging - getStagingLeadByProspectID
            </Button>
            <Button
              className="tw-m-1"
              color="error"
              variant="contained"
              disabled={us_disabled}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                getStagingContractByProspectID(us_stagingProspectID)
              }}
            >
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
              />
              Staging - getStagingContractByProspectID
            </Button>
            <Button
              className="tw-m-1"
              color="secondary"
              variant="contained"
              disabled={us_disabled}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                getStagingProposalbyProposalId(us_stagingProposalID)
              }}
            >
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
              />
              Staging - getStagingProposalbyProposalId
            </Button>
            <Button
              className="tw-m-1"
              color="secondary"
              variant="contained"
              disabled={us_disabled}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                getStagingAssetsByProposalId(us_stagingProposalID)
              }}
            >
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
              />
              Staging - getStagingAssetsByProposalId
            </Button>
            <Button
              className="tw-m-1"
              color="secondary"
              variant="contained"
              disabled={us_disabled}
              sx={{ textTransform: 'none' }}
              onClick={() => {
                getStagingDocsByProposalId(us_stagingProposalID)
              }}
            >
              <Icon
                icon="cloud-arrow-up"
                className="tw-mr-2"
              />
              Staging - getStagingDocsByProposalId
            </Button>
            <Box className="tw-mt-2">
              <Json
                data={us_apiResults}
                alphabetized={true}
              />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

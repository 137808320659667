///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			Close dialog on file select

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Avatar, Box, Button, Card, Dialog, DialogContent, IconButton, LinearProgress, Stack, Toolbar, Typography } from '@mui/material/'
import React, { useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ClientUser_Document } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { StorageRef_UserProfilePictures } from 'rfbp_aux/services/storage_endpoints/user_profiles'
import { stringAvatar, stringToColor } from 'rfbp_core/components/chat'
import { Json } from 'rfbp_core/components/code_display'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_AuthenticatedUser,
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_RootData_UserPermissions,
  Context_UserInterface_CustomDialog,
  UserInterface_Default_CustomDialogDisplayState,
} from 'rfbp_core/services/context'
import { DatabaseSetMergeDocument, StorageUploadFile } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, returnFormattedPhoneNumberString } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey, logOut, updateUserClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_AuthorizedClient {
  [key: string]: string
}

interface TsInterface_AuthorizedClientsObject {
  [key: string]: boolean
}

// interface TsInterface_AuthorizedClientArray extends Array<TsInterface_AuthorizedClient> {}

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['UserSettingsPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_CLIENT_TOGGLE: JSX.Element = <Trans>Client Toggle</Trans>
const s_FAILED_TO_UPLOAD_FILE: JSX.Element = <Trans>Failed to upload file</Trans>
const s_INVALID_DOCUMENT_SELECTION: JSX.Element = <Trans>Invalid Document Selection</Trans>
const s_LOG_OUT: JSX.Element = <Trans>Log Out</Trans>
const s_MISSING_REQUIRED_KEYS: JSX.Element = <Trans>Missing Required Keys</Trans>
const s_NAME: JSX.Element = <Trans>Name</Trans>
const s_NO_PROFILE_PICTURE_UPLOADED: JSX.Element = <Trans>No Profile Picture Uploaded</Trans>
const s_PHONE: JSX.Element = <Trans>Phone</Trans>
const s_PROFILE: JSX.Element = <Trans>Profile</Trans>
const s_PROFILE_PHOTO: JSX.Element = <Trans>Profile Photo</Trans>
const s_ROOT_DATA: JSX.Element = <Trans>Root Data</Trans>
const s_UPLOAD_PHOTO: JSX.Element = <Trans>Upload Photo</Trans>
const s_USER: JSX.Element = <Trans>User</Trans>
const se_USER_SETTINGS = 'User Settings'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_changingClientKey, us_setChangingClientKey] = useState(false)
  const un_routerNavigation = useNavigate()
  const { uc_RootData_AuthenticatedUser } = useContext(Context_RootData_AuthenticatedUser)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_RootData_UserPermissions } = useContext(Context_RootData_UserPermissions)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_USER_SETTINGS
  }, [])

  // Other Variables
  let displayUserName: string = getProp(uc_RootData_ClientUser, 'name', null)
  let displayUserEmail: string = getProp(uc_RootData_ClientUser, 'email', null)
  let displayUserPhone: string = getProp(uc_RootData_ClientUser, 'phone', null)
  // let displayUserRole: string = getProp( uc_RootData_ClientUser, "user_role", null )
  let authorizedClients: TsInterface_AuthorizedClientsObject = getProp(uc_RootData_GlobalUser, 'authorized_clients', {})
  let authorizedClientArray: any = []
  // let appVersionNumber: string = returnAppVersionString()

  // Functions
  const signOut = (): void => {
    logOut()
      .then((res_LO) => {
        directAppNavigation(un_routerNavigation, ApplicationPages.UnauthenticatedLoginPage.url())
      })
      .catch((rej_LO) => {
        console.error(rej_LO)
      })
  }

  const generateAuthorizedClientList = (): void => {
    for (let clientKey in authorizedClients) {
      authorizedClientArray.push({ key: clientKey })
    }
    authorizedClientArray.sort(dynamicSort('key', null))
  }

  const selectClientKey = (clientKey: string): void => {
    us_setChangingClientKey(true)
    updateUserClientKey(clientKey, uc_RootData_GlobalUser).finally(() => {
      us_setChangingClientKey(false)
    })
  }

  const readAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader()
      fr.onerror = reject
      fr.onload = () => {
        resolve(fr.result)
      }
      fr.readAsDataURL(file)
    })
  }

  const fileOnSelect = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
        let promiseArray: TsType_UnknownPromise[] = []
        let files = event.target.files
        let readFiles: TsInterface_UnspecifiedObject = {}
        let file = files[0]
        if (file != null && typeof file === 'object') {
          promiseArray.push(
            readAsDataURL(file)
              .then((res_RADURL) => {
                readFiles[0] = {
                  file_name: file.name,
                  file: file,
                  data_url: res_RADURL,
                }
              })
              .catch((rej_RADURL) => {
                // Nothing
              }),
          )
        }
        Promise.all(promiseArray).finally(() => {
          let clientKey = uc_RootData_ClientKey
          let userKey = getProp(uc_RootData_GlobalUser, 'key', null)
          if (clientKey != null && userKey != null) {
            StorageUploadFile(StorageRef_UserProfilePictures(clientKey, userKey, readFiles[0].file_name), readFiles[0].file, {
              clientKey: clientKey,
              userKey: userKey,
            })
              .then((res_SUF: any) => {
                // files[ fileIndex ]["storage_url"] = getProp(res_SUF, "url", null)
                let updateObject = {
                  profile_photo_url: getProp(res_SUF, 'url', null),
                }
                DatabaseSetMergeDocument(
                  // @ts-ignore
                  DatabaseRef_ClientUser_Document(clientKey, userKey),
                  updateObject,
                )
                  .then((res_DSMD) => {
                    resolve(res_DSMD)
                  })
                  .catch((rej_DSMD) => {
                    reject(rej_DSMD)
                  })
              })
              .catch((rej_SUF) => {
                console.error(rej_SUF)
                reject(rej_SUF)
              })
          } else {
            reject({
              success: false,
              error: {
                message: s_FAILED_TO_UPLOAD_FILE,
                details: s_MISSING_REQUIRED_KEYS,
                code: 'ER-D-US-FOS-01',
              },
            })
          }
        })
      } else {
        reject({
          success: false,
          error: {
            message: s_FAILED_TO_UPLOAD_FILE,
            details: s_INVALID_DOCUMENT_SELECTION,
            code: 'ER-D-US-FOS-02',
          },
        })
      }
    })
  }

  const openProfilePhotoDialog = () => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let profilePicturePreview = <></>
        if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.profile_photo_url != null) {
          profilePicturePreview = (
            <Box className="tw-text-center">
              <Avatar
                className="tw-mx-auto tw-my-2"
                src={uc_RootData_ClientUser.profile_photo_url}
                sx={{ width: 200, height: 200 }}
              />
            </Box>
          )
        } else {
          profilePicturePreview = <Typography variant="h6">{s_NO_PROFILE_PICTURE_UPLOADED}</Typography>
        }
        let dialogJSX = (
          <Box>
            <Dialog
              // TransitionComponent={ Transition }
              className="bp_dialog_md_width"
              keepMounted
              onClose={() => {
                uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
              }}
              open={true}
            >
              <AppBar
                position="static"
                color="inherit"
              >
                <Toolbar>
                  <IconButton
                    aria-label="menu"
                    color="inherit"
                    disabled
                    edge="start"
                    size="large"
                    sx={{ mr: 2, color: '#fff !important' }}
                  >
                    <Icon icon="square-user" />
                  </IconButton>
                  <Typography
                    component={'span'}
                    variant={'h6'}
                    sx={{ flexGrow: 1 }}
                  >
                    <Box className="tw-inline-block">{s_PROFILE_PHOTO}</Box>
                  </Typography>
                </Toolbar>
              </AppBar>
              <DialogContent sx={{ padding: '0px' }}>
                <Box className="tw-p-4 tw-text-center">
                  {profilePicturePreview}
                  <FileUploadButton
                    multiple={true}
                    accept="image/*"
                    onChange={fileOnSelect}
                    button={{
                      text: s_UPLOAD_PHOTO,
                      icon: (
                        <Icon
                          icon="cloud-arrow-up"
                          className="tw-mr-2"
                        ></Icon>
                      ),
                      color: 'info',
                      className: '',
                      variant: 'contained',
                      disabled: false,
                    }}
                    additionalFileUploadParams={{
                      clientKey: uc_RootData_ClientKey,
                      userKey: getProp(uc_RootData_GlobalUser, 'key', null),
                    }}
                  />
                </Box>
              </DialogContent>
            </Dialog>
          </Box>
        )

        uc_setUserInterface_CustomDialogDisplay({
          display: true,
          dialog: {
            dialog_jsx: dialogJSX,
            settings: {
              max_width: 'lg',
            },
          },
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  const rJSX_ClientToggleButton = (clientKey: string): JSX.Element => {
    let buttonJSX
    if (uc_RootData_ClientKey === clientKey) {
      buttonJSX = (
        <Button
          onClick={() => {}}
          color="success"
          variant="contained"
          disabled={us_changingClientKey}
        >
          <Icon icon="square-check" />
          <Box
            component="div"
            sx={{ paddingLeft: '4px' }}
          >
            {clientKey}
          </Box>
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          onClick={() => {
            selectClientKey(clientKey)
          }}
          variant="outlined"
          disabled={us_changingClientKey}
        >
          <Icon
            icon="square"
            type="light"
          />
          <Box
            component="div"
            sx={{ paddingLeft: '4px' }}
          >
            {clientKey}
          </Box>
        </Button>
      )
    }
    return buttonJSX
  }

  generateAuthorizedClientList()

  // JSX Generation

  const rJSX_ProgressBar = (): JSX.Element => {
    let progressJSX
    if (us_changingClientKey) {
      progressJSX = <LinearProgress />
    } else {
      progressJSX = <Box component="div"></Box>
    }
    return progressJSX
  }

  const rJSX_Avatar = (): JSX.Element => {
    let avatarJSX = <></>
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.profile_photo_url != null) {
      avatarJSX = (
        <Avatar
          sx={{ width: 96, height: 96, margin: 'auto', bgcolor: stringToColor(displayUserName) }}
          src={uc_RootData_ClientUser.profile_photo_url}
          onClick={() => {
            openProfilePhotoDialog()
          }}
        />
      )
    } else if (uc_RootData_ClientUser != null) {
      avatarJSX = (
        <Avatar
          // eslint-disable-next-line react/no-children-prop
          children={stringAvatar(displayUserName)['children']}
          sx={{ width: 96, height: 96, margin: 'auto', bgcolor: stringToColor(displayUserName) }}
          onClick={() => {
            openProfilePhotoDialog()
          }}
        />
      )
    }
    return avatarJSX
  }

  const rJSX_ProfileTab = (): JSX.Element => {
    let profileTabJSX = (
      <Card variant="outlined">
        <Box className="tw-text-center">
          <Box className="tw-p-4 tw-text-left tw-inline-block">
            <Box className="tw-m-auto">{rJSX_Avatar()}</Box>
            <Box className="tw-my-4">
              <Typography variant="subtitle1">
                <strong>{s_NAME}:</strong> {displayUserName}
              </Typography>
              <Typography variant="subtitle1">
                <strong>{rLIB('Email')}:</strong> {displayUserEmail}
              </Typography>
              <Typography variant="subtitle1">
                <strong>{s_PHONE}:</strong> {returnFormattedPhoneNumberString(displayUserPhone)}
              </Typography>
              {/* <Typography variant="subtitle1"><strong>{ s_ROLE }:</strong> { displayUserRole }</Typography> */}
              {/* <Typography variant="subtitle1"><strong>{ s_APP_VERSION }:</strong> { appVersionNumber }</Typography> */}
              {/* <Typography variant="subtitle1"><strong>{ s_DATABASE_KEY }:</strong> { uc_RootData_GlobalUser.key }</Typography> */}
            </Box>
            <Button
              color="error"
              onClick={() => {
                signOut()
              }}
              variant="contained"
            >
              {s_LOG_OUT}
            </Button>
          </Box>
        </Box>
      </Card>
    )
    return profileTabJSX
  }

  const rJSX_ClientToggleTab = (): JSX.Element => {
    let clientToggleTabJSX = (
      <Box>
        {rJSX_ProgressBar()}
        <Box
          component="div"
          className="tw-p-4"
        >
          <Stack
            spacing={1}
            direction="column"
          >
            {authorizedClientArray.map((client: any) => (
              <span key={client.key}>{rJSX_ClientToggleButton(client.key)}</span>
            ))}
          </Stack>
        </Box>
      </Box>
    )
    return clientToggleTabJSX
  }

  const rJSX_DiagnosticsTab = (): JSX.Element => {
    let diagnosticsTabJSX = (
      <Card
        variant="outlined"
        className="tw-p-4"
      >
        <Typography variant="subtitle1">uc_RootData_AuthenticatedUser</Typography>
        <Json
          alphabetized={true}
          data={uc_RootData_AuthenticatedUser}
        />
        <Typography variant="subtitle1">uc_RootData_ClientKey</Typography>
        <Json
          alphabetized={true}
          data={uc_RootData_ClientKey as string}
        />
        <Typography variant="subtitle1">uc_RootData_GlobalUser</Typography>
        <Json
          alphabetized={true}
          data={uc_RootData_GlobalUser}
        />
        <Typography variant="subtitle1">uc_RootData_ClientUser</Typography>
        <Json
          alphabetized={true}
          data={uc_RootData_ClientUser}
        />
        <Typography variant="subtitle1">uc_RootData_ClientPermissions</Typography>
        <Json
          alphabetized={true}
          data={uc_RootData_ClientPermissions}
        />
        <Typography variant="subtitle1">uc_RootData_UserPermissions</Typography>
        <Json
          alphabetized={true}
          data={uc_RootData_UserPermissions}
        />
      </Card>
    )

    return diagnosticsTabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let tabsJSX = <></>
    if (uc_RootData_GlobalUser != null && uc_RootData_GlobalUser.super === true) {
      tabsJSX = (
        <TabsBasic
          tabs={[
            { tabHeader: s_USER, tabContent: <Box>{rJSX_ProfileTab()}</Box> },
            { tabHeader: s_CLIENT_TOGGLE, tabContent: <Box>{rJSX_ClientToggleTab()}</Box> },
            { tabHeader: s_ROOT_DATA, tabContent: <Box>{rJSX_DiagnosticsTab()}</Box> },
          ]}
          tabsSettings={{}}
        />
      )
    } else {
      tabsJSX = (
        <TabsBasic
          tabs={[{ tabHeader: s_USER, tabContent: <Box>{rJSX_ProfileTab()}</Box> }]}
          tabsSettings={{}}
        />
      )
    }
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_PROFILE}
        pageKey={pageKey}
        content={<Box>{tabsJSX}</Box>}
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

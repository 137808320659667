///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { DatabaseRef_GlobalCronJobOneTimeCompletedTasks_Collection } from 'rfbp_aux/services/database_endpoints/global/cronjob_tasks/one_time_completed'
import { Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Exports
///////////////////////////////

export const tableDatabaseEndpoint_CronjobsOneSuccess = (
  queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
  tableAdditionalData: TsInterface_TableAdditionalData,
) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp_to_run', desc: true }]
  let queryCursorsObject: TsInterface_QueryCursorsObject = {}
  if (queryGenerationData['startAfter'] != null) {
    queryCursorsObject['startAfter'] = queryGenerationData.startAfter
  }
  if (queryGenerationData['startAt'] != null) {
    queryCursorsObject['startAt'] = queryGenerationData.startAt
  }
  if (queryGenerationData['endAt'] != null) {
    queryCursorsObject['endAt'] = queryGenerationData.endAt
  }
  if (queryGenerationData['endBefore'] != null) {
    queryCursorsObject['endBefore'] = queryGenerationData.endBefore
  }
  let limit = getProp(queryGenerationData, 'limit', 5)
  return generateDatabaseQuery(DatabaseRef_GlobalCronJobOneTimeCompletedTasks_Collection(), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
}

export const tableSettings_CronjobsOneSuccess: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  size: 'small',
  sort_direction: 'desc',
  sort_property: 'timestamp_last_run',
  use_live_data: true,
}

export const tableColumns_CronjobsOneSuccess: TsInterface_TableColumns = {
  query: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Full Log')
      },
      header_sort_by: null,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        cellJSX = (
          <Box
            className="tw-p-1 tw-rounded-md tw-bg-success_main tw-cursor-pointer tw-inline-block hover:tw-bg-success_dark tw-text-white"
            onClick={() => {
              tableHooks.uc_setUserInterface_CustomDialogDisplay({
                display: true,
                dialog: {
                  dialog_jsx: (
                    <Json
                      data={rowData}
                      alphabetized={true}
                    />
                  ),
                  settings: {
                    max_width: 'lg',
                  },
                },
              })
            }}
          >
            <Icon
              icon="code"
              className="tw-mr-2"
            />
            {rLIB('Full Log')}
          </Box>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        let cellCSS = ''
        return cellCSS
      },
    },
  },
  key: TableCellBasic('key', rLIB('Key'), 'key'),
  timestamp_to_run: TableCellTimestamp('timestamp_to_run', rLIB('Run Time'), 'timestamp_to_run', 'YYYY-MM-DD HH:mm', true),
  timestamp_success: TableCellTimestamp('timestamp_success', rLIB('Success Time'), 'timestamp_success', 'YYYY-MM-DD HH:mm', true),
  client_key: TableCellBasic('client_key', rLIB('Client Key'), 'client_key'),
  function: TableCellBasic('function', rLIB('Function'), 'function'),
}

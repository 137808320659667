///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_FormInputs, TsInterface_FormSettings } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Exports
///////////////////////////////

export const formInputs_StickyNote: TsInterface_FormInputs = {
  sticky_note: {
    key: 'sticky_note',
    label: rLIB('Sticky Note'),
    input_type: 'text_multiline',
    required: false,
    data_type: 'string',
  },
}

export const formSettings_StickyNote: TsInterface_FormSettings = {
  //
}

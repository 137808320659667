import { TsInterface_TableSettings } from 'rfbp_core/components/table'

export const tableSettings_Filtered_Vehicles: TsInterface_TableSettings = {
  sortable: true,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',

  collapsible_columns: true,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
  searchable: true,
  // search_settings_database: {
  //   search_type: 'firebase',
  //   search_no_data_message: s_NO_TRAILERS_FOUND_BY_SEARCH,
  //   search_property_options: [{ key: 'license_plate', value: rLIB('License Plate') }],
  // },
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Dialog, DialogContent, IconButton, Toolbar, Typography } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_InternalUsers_Query } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_PayrollRates_EmployeeBaseRates_Document } from 'rfbp_aux/services/database_endpoints/finances/payroll_rates'
import { DatabaseRef_TimeSheetsAllocationTypes_Collection } from 'rfbp_aux/services/database_endpoints/timesheets/allocation_types'
import { DatabaseRef_TimePunches_DateRange_Query } from 'rfbp_aux/services/database_endpoints/timesheets/time_punches'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetDocument } from 'rfbp_core/services/database_management'
import {
  downloadCSV,
  dynamicSort,
  formatCurrency,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnFormattedDateKey,
} from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
import { hourlyOrSalariedOptions } from '../users/user_form'

///////////////////////////////
// Typescript
///////////////////////////////

type TsType_CSVCellContents = string | number

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const getStateAbbreviation = (address: string) => {
  const stateRegex = /(?:, | )([A-Z]{2})(?: \d{5}, |, |$)/
  const match = address.match(stateRegex)
  if (match && match[1]) {
    return match[1]
  } else {
    return null
  }
}

const rJSX_ErrorMessage = (userErrorObject: TsInterface_UnspecifiedObject, errorKey: string, errorText: JSX.Element | string): JSX.Element => {
  let errorJSX = <></>
  if (userErrorObject != null && userErrorObject[errorKey] === true) {
    errorJSX = (
      <Box className="tw-opacity-40">
        <Icon
          icon="chevron-right"
          className="tw-mr-2"
        />
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {errorText}
        </Typography>
      </Box>
    )
  }
  return errorJSX
}

const rJSX_WarningMessage = (warningObject: TsInterface_UnspecifiedObject, warningKey: string, warningText: JSX.Element | string): JSX.Element => {
  let warningJSX = <></>
  if (warningObject != null && warningObject[warningKey] === true) {
    warningJSX = (
      <Box className="tw-opacity-40">
        <Icon
          icon="chevron-right"
          className="tw-mr-2"
        />
        <Typography
          variant="body1"
          className="tw-inline-block"
        >
          {warningText}
        </Typography>
      </Box>
    )
  }
  return warningJSX
}

const rJSX_ErrorsAndWarnings = (errorObject: TsInterface_UnspecifiedObject, warningObject: TsInterface_UnspecifiedObject): JSX.Element => {
  let timecardErrorsAndWarningsJSX = <></>
  let errorListJSX = <></>
  let warningListJSX = <></>
  let noErrorsOrWarningsJSX = <></>
  if (objectToArray(errorObject).length > 0) {
    errorListJSX = (
      <Box className="tw-mb-2">
        <Typography
          variant="h6"
          sx={{ color: themeVariables.error_light }}
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-2"
          />
          {rLIB('Errors')}:
        </Typography>
        <Box className="tw-pl-6">
          {objectToArray(errorObject)
            .sort(dynamicSort('name', 'asc'))
            .map((userErrorObject: TsInterface_UnspecifiedObject, index: number) => (
              <Box key={index}>
                <Box>
                  <Icon
                    icon="user"
                    className="tw-mr-1"
                  />
                  <Typography
                    variant="body1"
                    className="tw-inline-block"
                  >
                    {userErrorObject.name}
                  </Typography>
                </Box>
                <Box className="tw-pl-6">
                  {rJSX_ErrorMessage(userErrorObject, 'first_punch_not_clock_in', rLIB('Missing first clock in'))}
                  {rJSX_ErrorMessage(userErrorObject, 'last_punch_not_clock_out', rLIB('Missing Last Clock Out'))}
                  {rJSX_ErrorMessage(userErrorObject, 'back_to_back_punches', rLIB('Missing a Time Punch'))}
                  {rJSX_ErrorMessage(userErrorObject, 'punch_type_not_clock_in_or_clock_out', rLIB('Invalid Punch Type'))}
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    )
  }
  if (objectToArray(warningObject).length > 0) {
    warningListJSX = (
      <Box className="tw-mb-2">
        <Typography
          variant="h6"
          sx={{ color: themeVariables.warning_main }}
        >
          <Icon
            icon="triangle-exclamation"
            className="tw-mr-2"
          />
          {rLIB('Warnings')}:
        </Typography>
        <Box className="tw-pl-6">{rJSX_WarningMessage(warningObject, 'no_data', rLIB('No Hourly Employee Timesheet data found for selected week'))}</Box>
      </Box>
    )
  }
  if (objectToArray(errorObject).length === 0 && objectToArray(warningObject).length === 0) {
    noErrorsOrWarningsJSX = (
      <Box className="tw-mb-2 tw-text-center">
        <Typography
          variant="h6"
          sx={{ color: themeVariables.success_light }}
        >
          <Icon
            icon="badge-check"
            className="tw-mr-2"
          ></Icon>
          {rLIB('No Errors or Warnings')}
        </Typography>
      </Box>
    )
  }
  timecardErrorsAndWarningsJSX = (
    <Box>
      {errorListJSX}
      {warningListJSX}
      {noErrorsOrWarningsJSX}
    </Box>
  )
  return timecardErrorsAndWarningsJSX
}

const rJSX_DownloadErrorAndWarningDialog = (
  uc_setUserInterface_CustomDialogDisplay: any,
  errorObject: TsInterface_UnspecifiedObject,
  warningObject: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let dialogJSX = <></>
  dialogJSX = (
    <Dialog
      className="bp_dialog_lg_width"
      keepMounted
      onClose={() => {
        uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
      }}
      open={true}
    >
      <AppBar
        position="static"
        color="inherit"
      >
        <Toolbar>
          <IconButton
            aria-label="menu"
            color="inherit"
            disabled
            edge="start"
            size="large"
            sx={{ mr: 2, color: '#fff !important' }}
          >
            <Icon icon="download" />
          </IconButton>
          <Typography
            component={'span'}
            variant={'h6'}
            sx={{ flexGrow: 1 }}
          >
            <Box className="tw-inline-block">{rLIB('Download Complete')}</Box>
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ padding: '0px' }}>
        <Box className="tw-p-4">{rJSX_ErrorsAndWarnings(errorObject, warningObject)}</Box>
      </DialogContent>
    </Dialog>
  )
  return dialogJSX
}

// Very Important Function
export const downloadAggregatedPunchData = (
  clientKey: string,
  startDate: Date,
  endDate: Date,
  includeWages: boolean,
  uc_setUserInterface_CustomDialogDisplay: any,
  downloadType: 'payroll' | 'payroll_json' | 'categories' | 'categories_json' | 'category_keys_json' | 'raw' | 'raw_json',
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // Instantiate Variables
    let promiseArray: TsType_UnknownPromise[] = []
    let errorsLoadingData = false
    let employeeBaseRates: TsInterface_UnspecifiedObject = {}
    let rawPunchData: TsInterface_UnspecifiedObject = {}
    let employeePunchData: TsInterface_UnspecifiedObject = {}
    let internalUsers: TsInterface_UnspecifiedObject = {} // TODO
    let aggregatedPunchData: TsInterface_UnspecifiedObject = {}
    let punchDataErrors: TsInterface_UnspecifiedObject = {}
    let punchDataWarnings: TsInterface_UnspecifiedObject = {}
    let allocationTypes: TsInterface_UnspecifiedObject = {}
    let employeeTaskStates: TsInterface_UnspecifiedObject = {}
    // Load Data
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TimePunches_DateRange_Query(clientKey, startDate, endDate))
        .then((res_DGC) => {
          rawPunchData = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
          errorsLoadingData = true
        }),
    )
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TimeSheetsAllocationTypes_Collection(clientKey))
        .then((res_DGC) => {
          allocationTypes = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
          errorsLoadingData = true
        }),
    )
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_InternalUsers_Query(clientKey))
        .then((res_DGC) => {
          // internalUsers = res_DGC.data
          for (let loopUserKey in res_DGC.data) {
            if (res_DGC.data[loopUserKey]['status'] !== 'deleted') {
              internalUsers[loopUserKey] = res_DGC.data[loopUserKey]
            }
          }
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
          errorsLoadingData = true
        }),
    )
    if (includeWages === true) {
      promiseArray.push(
        DatabaseGetDocument(DatabaseRef_PayrollRates_EmployeeBaseRates_Document(clientKey))
          .then((res_GCC) => {
            employeeBaseRates = res_GCC.data
          })
          .catch((rej_GCC) => {
            console.error(rej_GCC)
          }),
      )
    }
    // After Data Loaded
    Promise.all(promiseArray).finally(() => {
      if (errorsLoadingData === false) {
        // Loop through and separate punches by employee
        for (let loopPunchKey in rawPunchData) {
          let loopPunch = rawPunchData[loopPunchKey]
          if (employeePunchData[loopPunch.associated_user_key] == null) {
            employeePunchData[loopPunch.associated_user_key] = []
          }
          loopPunch.timestamp = returnDateFromUnknownDateFormat(loopPunch.timestamp).getTime()
          if (loopPunch.status !== 'deleted') employeePunchData[loopPunch.associated_user_key].push(loopPunch)
        }
        // Loop through employees and sort punches by timestamp
        for (let loopEmployeeKey in employeePunchData) {
          let loopEmployeePunches = employeePunchData[loopEmployeeKey]
          loopEmployeePunches.sort((a: { timestamp: number }, b: { timestamp: number }) => {
            return a.timestamp - b.timestamp
          })
          // Loop through punches and make sure that the first punch is a clock in and the last punch is a clock out
          if (loopEmployeePunches != null && loopEmployeePunches[0] != null && loopEmployeePunches[0].type !== 'clock_in') {
            // Error: First punch is not a clock in
            if (punchDataErrors[loopEmployeeKey] == null) {
              punchDataErrors[loopEmployeeKey] = {
                name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                key: loopEmployeeKey,
              }
            }
            punchDataErrors[loopEmployeeKey]['first_punch_not_clock_in'] = true
          }
          if (
            loopEmployeePunches != null &&
            loopEmployeePunches[loopEmployeePunches.length - 1] != null &&
            loopEmployeePunches[loopEmployeePunches.length - 1].type !== 'clock_out'
          ) {
            // Error: Last punch is not a clock out
            if (punchDataErrors[loopEmployeeKey] == null) {
              punchDataErrors[loopEmployeeKey] = {
                name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                key: loopEmployeeKey,
              }
            }
            punchDataErrors[loopEmployeeKey]['last_punch_not_clock_out'] = true
          }
          // Loop through punches and make sure that they alternate between clock in and clock out
          // Also total up the time between punches
          for (let loopPunchIndex in loopEmployeePunches) {
            let loopPunch = loopEmployeePunches[loopPunchIndex]
            // if( loopPunch ){
            // 	console.error( loopPunch )
            // }
            if (parseInt(loopPunchIndex) !== 0) {
              let previousLoopPunch = loopEmployeePunches[parseInt(loopPunchIndex) - 1]
              if (loopPunch.type === previousLoopPunch.type) {
                // Error: Back to back punches
                if (punchDataErrors[loopEmployeeKey] == null) {
                  punchDataErrors[loopEmployeeKey] = {
                    name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                    key: loopEmployeeKey,
                  }
                }
                punchDataErrors[loopEmployeeKey]['back_to_back_punches'] = true
              }
              // Error: If loop punch type is not a clock_in or clock_out
              if (loopPunch.type !== 'clock_in' && loopPunch.type !== 'clock_out') {
                if (punchDataErrors[loopEmployeeKey] == null) {
                  punchDataErrors[loopEmployeeKey] = {
                    name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                    key: loopEmployeeKey,
                  }
                }
                punchDataErrors[loopEmployeeKey]['punch_type_not_clock_in_or_clock_out'] = true
              }
              if (loopPunch.type === 'clock_out') {
                // Subtract previous punch timestamp from current punch timestamp to get total time
                let totalMinutes = (loopPunch.timestamp - previousLoopPunch.timestamp) / (1000 * 60)
                let totalHours = totalMinutes / 60
                // Group by allocation subtype codes
                if (aggregatedPunchData[loopEmployeeKey] == null) {
                  aggregatedPunchData[loopEmployeeKey] = {
                    key: loopEmployeeKey,
                    name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                    user_role: getProp(internalUsers, loopEmployeeKey + '.user_role', null),
                    hourly_or_salaried: getProp(internalUsers, loopEmployeeKey + '.hourly_or_salaried', null),
                    default_location_state: getProp(internalUsers, loopEmployeeKey + '.default_location_state', null),
                    id_number_payroll: getProp(internalUsers, loopEmployeeKey + '.id_number_payroll', null),
                    id_organization_payroll: getProp(internalUsers, loopEmployeeKey + '.id_organization_payroll', null),
                    totals: {},
                    payroll_category_breakdown: {},
                    codes: {},
                    raw_codes: {},
                  }
                }
                // Get State from full address
                let state = null
                if (loopPunch.location_state != null) {
                  state = loopPunch.location_state
                  if (employeeTaskStates[loopEmployeeKey] == null) {
                    employeeTaskStates[loopEmployeeKey] = {}
                  }
                  employeeTaskStates[loopEmployeeKey][loopPunch.key] = {
                    state: state,
                    timestamp: loopPunch.timestamp,
                  }
                } else if (loopPunch.location_address != null) {
                  state = getStateAbbreviation(loopPunch.location_address)
                  if (employeeTaskStates[loopEmployeeKey] == null) {
                    employeeTaskStates[loopEmployeeKey] = {}
                  }
                  employeeTaskStates[loopEmployeeKey][loopPunch.key] = {
                    state: state,
                    timestamp: loopPunch.timestamp,
                  }
                }
                // Aggregation Totals
                let selectedAllocationCode = null
                let payrollBreakdownCode = null
                let rawAllocationCode = null
                if (previousLoopPunch != null && previousLoopPunch['associated_allocation_type_key'] != null) {
                  let selectedAllocationType = getProp(allocationTypes, previousLoopPunch['associated_allocation_type_key'], {})
                  let selectedAllocationSubtypes = getProp(selectedAllocationType, 'subtypes', {})
                  switch (previousLoopPunch['associated_allocation_type_key']) {
                    case 'admin_time':
                      payrollBreakdownCode = 'OFFICE'
                      if (downloadType === 'payroll' || downloadType === 'payroll_json') {
                        selectedAllocationCode = 'REG'
                      } else if (downloadType === 'categories' || downloadType === 'categories_json') {
                        selectedAllocationCode = 'ADMIN'
                      } else if (downloadType === 'raw' || downloadType === 'raw_json') {
                        if (
                          selectedAllocationSubtypes != null &&
                          selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']] != null &&
                          selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name'] != null
                        ) {
                          selectedAllocationCode = selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name']
                        }
                      }
                      // Raw Allocation Code
                      rawAllocationCode = 'OFFICE'
                      if (
                        selectedAllocationSubtypes != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']] != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name'] != null
                      ) {
                        rawAllocationCode = selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name']
                      }
                      break
                    case 'break':
                      payrollBreakdownCode = 'OFFICE'
                      if (downloadType === 'payroll' || downloadType === 'payroll_json') {
                        selectedAllocationCode = 'REG'
                      } else if (downloadType === 'categories' || downloadType === 'categories_json') {
                        selectedAllocationCode = 'BREAK'
                      } else if (downloadType === 'raw' || downloadType === 'raw_json') {
                        selectedAllocationCode = 'BREAK'
                      }
                      // Raw Allocation Code
                      rawAllocationCode = 'OFFICE'
                      break
                    case 'field_work':
                      payrollBreakdownCode = 'FIELD'
                      if (downloadType === 'payroll' || downloadType === 'payroll_json') {
                        selectedAllocationCode = 'REG'
                      } else if (downloadType === 'categories' || downloadType === 'categories_json') {
                        selectedAllocationCode = 'FIELD'
                      } else if (downloadType === 'raw' || downloadType === 'raw_json') {
                        selectedAllocationCode =
                          getProp(previousLoopPunch, 'associated_allocation_type_name', 'FIELD') +
                          ' - ' +
                          getProp(previousLoopPunch, 'associated_allocation_subtype_name', '(UNKNOWN)')
                      }
                      // Raw Allocation Code
                      rawAllocationCode =
                        getProp(previousLoopPunch, 'associated_allocation_type_name', 'FIELD') +
                        ' - ' +
                        getProp(previousLoopPunch, 'associated_allocation_subtype_name', '(UNKNOWN)')
                      break
                    case 'non_working_time':
                      payrollBreakdownCode = 'OFF'
                      if (downloadType === 'payroll' || downloadType === 'payroll_json') {
                        if (
                          selectedAllocationSubtypes != null &&
                          selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']] != null &&
                          selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['code'] != null
                        ) {
                          selectedAllocationCode = selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['code']
                        }
                      } else if (downloadType === 'categories' || downloadType === 'categories_json') {
                        selectedAllocationCode = 'OFF'
                      } else if (downloadType === 'raw' || downloadType === 'raw_json') {
                        if (
                          selectedAllocationSubtypes != null &&
                          selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']] != null &&
                          selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name'] != null
                        ) {
                          selectedAllocationCode = selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name']
                        }
                      }
                      // Raw Allocation Code
                      rawAllocationCode = 'OFF'
                      if (
                        selectedAllocationSubtypes != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']] != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['code'] != null
                      ) {
                        rawAllocationCode = selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['code']
                      }
                      break
                  }
                }
                if (selectedAllocationCode === null) {
                  selectedAllocationCode = 'MISSING'
                }
                if (payrollBreakdownCode === null) {
                  payrollBreakdownCode = 'OFFICE'
                }
                if (aggregatedPunchData[loopEmployeeKey]['payroll_category_breakdown'][payrollBreakdownCode] == null) {
                  aggregatedPunchData[loopEmployeeKey]['payroll_category_breakdown'][payrollBreakdownCode] = 0
                }
                aggregatedPunchData[loopEmployeeKey]['payroll_category_breakdown'][payrollBreakdownCode] += totalHours
                if (aggregatedPunchData[loopEmployeeKey]['totals'][selectedAllocationCode] == null) {
                  aggregatedPunchData[loopEmployeeKey]['totals'][selectedAllocationCode] = 0
                }
                aggregatedPunchData[loopEmployeeKey]['totals'][selectedAllocationCode] += totalHours
                if (aggregatedPunchData[loopEmployeeKey]['codes'][selectedAllocationCode] == null) {
                  aggregatedPunchData[loopEmployeeKey]['codes'][selectedAllocationCode] = {}
                }
                let combinedAllocationKey =
                  getProp(previousLoopPunch, 'associated_allocation_type_key', 'MISSING') +
                  '_' +
                  getProp(previousLoopPunch, 'associated_allocation_subtype_key', 'MISSING')
                aggregatedPunchData[loopEmployeeKey]['codes'][selectedAllocationCode][combinedAllocationKey] = true
                if (rawAllocationCode != null) {
                  if (aggregatedPunchData[loopEmployeeKey]['raw_codes'][rawAllocationCode] == null) {
                    aggregatedPunchData[loopEmployeeKey]['raw_codes'][rawAllocationCode] = 0
                  }
                  aggregatedPunchData[loopEmployeeKey]['raw_codes'][rawAllocationCode] += totalHours
                }
              }
            }
          }
        }
        // Warnings
        if (objectToArray(aggregatedPunchData).length === 0) {
          punchDataWarnings['no_data'] = true
        }
        // TODO - Might need to have an error if the subtypes that are being grouped are not the same (different allocation type / sub key but same code)
        // punchDataWarnings["duplicate_allocation_codes"]
        let csvData: TsType_CSVCellContents[][] = []
        let csvHeaders: TsType_CSVCellContents[] = [
          'Insperity Person ID',
          'WageType',
          'BeginPayPeriodDate',
          'EndPayPeriodDate',
          'PayCode',
          'Duration',
          'Wage',
          'PROJECT CODE',
          'Labor Level 2',
          'Labor Level 3',
          'Insperity Company ID',
          'Name',
        ]
        csvData.push(csvHeaders)
        let csvRowData: TsType_CSVCellContents[] = []
        for (let loopUserKey in aggregatedPunchData) {
          let loopUser = aggregatedPunchData[loopUserKey]
          // Add Task States to aggregatedPunchData
          if (employeeTaskStates != null && employeeTaskStates[loopUserKey] != null) {
            aggregatedPunchData[loopUserKey]['task_states'] = employeeTaskStates[loopUserKey]
          }
          // Loop through each aggregate punch type and get the total hours
          for (let loopCodeKey in loopUser['totals']) {
            let loopCodeHours = loopUser['totals'][loopCodeKey].toFixed(6)
            let hourlyOrSalariedAbbreviation = getProp(loopUser, 'hourly_or_salaried', '')
            if (
              hourlyOrSalariedOptions != null &&
              hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation] != null &&
              hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation'] != null
            ) {
              hourlyOrSalariedAbbreviation = hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation']
            }
            // Create overtime if REG is greater than 40
            let loopUserBaseRate = getProp(employeeBaseRates, loopUserKey, null)
            aggregatedPunchData[loopUserKey]['base_wage'] = loopUserBaseRate
            if (downloadType === 'payroll') {
              if (loopCodeKey === 'REG' && loopCodeHours > 40) {
                csvRowData = []
                csvRowData.push(getProp(loopUser, 'id_number_payroll', ''))
                csvRowData.push(hourlyOrSalariedAbbreviation)
                csvRowData.push(returnFormattedDate(startDate, 'YYYY-MM-DD'))
                csvRowData.push(returnFormattedDate(endDate, 'YYYY-MM-DD'))
                // Code
                csvRowData.push(loopCodeKey)
                csvRowData.push((40).toFixed(6))
                // Wage Multiplication
                if (includeWages === true && loopUserBaseRate != null) {
                  let totalWage = loopUserBaseRate * 40
                  csvRowData.push(formatCurrency(totalWage))
                } else {
                  csvRowData.push('')
                }
                // TODO - payroll_category_breakdown - minimum wage issue
                // Probably need to load projects to get state to get minimum wage
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push(getProp(loopUser, 'id_organization_payroll', ''))
                csvRowData.push(getProp(loopUser, 'name', ''))
                csvData.push(csvRowData)
                // Overtime
                csvRowData = []
                csvRowData.push(getProp(loopUser, 'id_number_payroll', ''))
                csvRowData.push(hourlyOrSalariedAbbreviation)
                csvRowData.push(returnFormattedDate(startDate, 'YYYY-MM-DD'))
                csvRowData.push(returnFormattedDate(endDate, 'YYYY-MM-DD'))
                // Code
                csvRowData.push('OVT')
                csvRowData.push((loopCodeHours - 40).toFixed(6))
                // Wage Multiplication
                if (includeWages === true && loopUserBaseRate != null) {
                  let totalWage = loopUserBaseRate * (loopCodeHours - 40) * 1.5
                  csvRowData.push(formatCurrency(totalWage))
                } else {
                  csvRowData.push('')
                }
                // TODO - payroll_category_breakdown - minimum wage issue
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push(getProp(loopUser, 'id_organization_payroll', ''))
                csvRowData.push(getProp(loopUser, 'name', ''))
                csvData.push(csvRowData)
              } else {
                csvRowData = []
                csvRowData.push(getProp(loopUser, 'id_number_payroll', ''))
                csvRowData.push(hourlyOrSalariedAbbreviation)
                csvRowData.push(returnFormattedDate(startDate, 'YYYY-MM-DD'))
                csvRowData.push(returnFormattedDate(endDate, 'YYYY-MM-DD'))
                // Code
                csvRowData.push(loopCodeKey)
                csvRowData.push(loopCodeHours)
                // Wage Multiplication
                if (includeWages === true && loopUserBaseRate != null) {
                  let totalWage = loopUserBaseRate * loopCodeHours
                  csvRowData.push(formatCurrency(totalWage))
                } else {
                  csvRowData.push('')
                }
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push(getProp(loopUser, 'id_organization_payroll', ''))
                csvRowData.push(getProp(loopUser, 'name', ''))
                csvData.push(csvRowData)
              }
            } else if (downloadType === 'categories') {
              csvRowData = []
              csvRowData.push(getProp(loopUser, 'id_number_payroll', ''))
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(returnFormattedDate(startDate, 'YYYY-MM-DD'))
              csvRowData.push(returnFormattedDate(endDate, 'YYYY-MM-DD'))
              // Code
              csvRowData.push(loopCodeKey)
              csvRowData.push(loopCodeHours)
              // Wage Multiplication
              if (includeWages === true && loopUserBaseRate != null) {
                let totalWage = loopUserBaseRate * loopCodeHours
                csvRowData.push(formatCurrency(totalWage))
              } else {
                csvRowData.push('')
              }
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(getProp(loopUser, 'id_organization_payroll', ''))
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvData.push(csvRowData)
            } else if (downloadType === 'raw') {
              csvRowData = []
              csvRowData.push(getProp(loopUser, 'id_number_payroll', ''))
              csvRowData.push(hourlyOrSalariedAbbreviation)
              csvRowData.push(returnFormattedDate(startDate, 'YYYY-MM-DD'))
              csvRowData.push(returnFormattedDate(endDate, 'YYYY-MM-DD'))
              // Code
              csvRowData.push(loopCodeKey)
              csvRowData.push(loopCodeHours)
              // Wage Multiplication
              if (includeWages === true && loopUserBaseRate != null) {
                let totalWage = loopUserBaseRate * loopCodeHours
                csvRowData.push(formatCurrency(totalWage))
              } else {
                csvRowData.push('')
              }
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push('')
              csvRowData.push(getProp(loopUser, 'id_organization_payroll', ''))
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvData.push(csvRowData)
            }
          }
        }
        if (downloadType === 'payroll_json' || downloadType === 'categories_json' || downloadType === 'raw_json') {
          resolve({
            success: true,
            data: aggregatedPunchData,
            punchDataErrors: punchDataErrors,
            punchDataWarnings: punchDataWarnings,
            internalUsers: internalUsers,
          })
        } else {
          // Loop through each user and add salaried users to the CSV
          if (downloadType === 'payroll') {
            for (let loopUserKey in internalUsers) {
              let loopUser = internalUsers[loopUserKey]
              let hourlyOrSalariedAbbreviation = getProp(loopUser, 'hourly_or_salaried', '')
              if (
                hourlyOrSalariedOptions != null &&
                hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation] != null &&
                hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation'] != null
              ) {
                hourlyOrSalariedAbbreviation = hourlyOrSalariedOptions[hourlyOrSalariedAbbreviation]['abbreviation']
              }
              if (
                loopUser != null &&
                loopUser['hourly_or_salaried'] != null &&
                loopUser['hourly_or_salaried'] !== '' &&
                loopUser['hourly_or_salaried'] !== 'hourly'
              ) {
                csvRowData = []
                csvRowData.push(getProp(loopUser, 'id_number_payroll', ''))
                csvRowData.push(hourlyOrSalariedAbbreviation)
                csvRowData.push(returnFormattedDate(startDate, 'YYYY-MM-DD'))
                csvRowData.push(returnFormattedDate(endDate, 'YYYY-MM-DD'))
                csvRowData.push('REG') // Pay Code
                csvRowData.push(40) // Hours - TODO - subtract if NWT?
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push('')
                csvRowData.push(getProp(loopUser, 'id_organization_payroll', ''))
                csvRowData.push(getProp(loopUser, 'name', ''))
                csvData.push(csvRowData)
              }
            }
          }
          // Download CSV

          // TODO - clientID
          if (downloadType === 'payroll') {
            downloadCSV(
              '(' +
                returnFormattedDate(new Date(), 'YYYY-MM-DD') +
                ') ETW Payroll Export ' +
                returnFormattedDate(startDate, 'YYYY-MM-DD') +
                ' - ' +
                returnFormattedDate(endDate, 'YYYY-MM-DD') +
                '.csv',
              csvData,
            )
          } else if (downloadType === 'categories') {
            downloadCSV(
              '(' +
                returnFormattedDate(new Date(), 'YYYY-MM-DD') +
                ') ETW Category Export ' +
                returnFormattedDate(startDate, 'YYYY-MM-DD') +
                ' - ' +
                returnFormattedDate(endDate, 'YYYY-MM-DD') +
                '.csv',
              csvData,
            )
          } else if (downloadType === 'raw') {
            downloadCSV(
              '(' +
                returnFormattedDate(new Date(), 'YYYY-MM-DD') +
                ') ETW Breakdown Export ' +
                returnFormattedDate(startDate, 'YYYY-MM-DD') +
                ' - ' +
                returnFormattedDate(endDate, 'YYYY-MM-DD') +
                '.csv',
              csvData,
            )
          }
          uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: rJSX_DownloadErrorAndWarningDialog(uc_setUserInterface_CustomDialogDisplay, punchDataErrors, punchDataWarnings),
              settings: {
                max_width: 'lg',
              },
            },
          })
          resolve({ success: true })
        }
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to download aggregate timesheet data'),
            details: rLIB('Failed to query database'),
            code: 'ER-D-TF-DAPD-01',
          },
        })
      }
    })
  })
}

export const downloadPanelPayReport = (
  clientKey: string,
  startDate: Date,
  endDate: Date,
  uc_setUserInterface_CustomDialogDisplay: any,
): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // Instantiate Variables
    let promiseArray: TsType_UnknownPromise[] = []
    let errorsLoadingData: boolean = false
    let rawPunchData: TsInterface_UnspecifiedObject = {}
    let employeePunchData: TsInterface_UnspecifiedObject = {}
    let internalUsers: TsInterface_UnspecifiedObject = {} // TODO
    let aggregatedPunchData: TsInterface_UnspecifiedObject = {}
    let punchDataErrors: TsInterface_UnspecifiedObject = {}
    let punchDataWarnings: TsInterface_UnspecifiedObject = {}
    let allocationTypes: TsInterface_UnspecifiedObject = {}
    // Load Data
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TimePunches_DateRange_Query(clientKey, startDate, endDate))
        .then((res_DGC) => {
          rawPunchData = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
          errorsLoadingData = true
        }),
    )
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_TimeSheetsAllocationTypes_Collection(clientKey))
        .then((res_DGC) => {
          allocationTypes = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
          errorsLoadingData = true
        }),
    )
    promiseArray.push(
      DatabaseGetCollection(DatabaseRef_InternalUsers_Query(clientKey))
        .then((res_DGC) => {
          internalUsers = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
          errorsLoadingData = true
        }),
    )
    // After Data Loaded
    Promise.all(promiseArray).finally(() => {
      if (errorsLoadingData === false) {
        // Loop through and separate punches by employee
        for (let loopPunchKey in rawPunchData) {
          let loopPunch = rawPunchData[loopPunchKey]
          if (employeePunchData[loopPunch.associated_user_key] == null) {
            employeePunchData[loopPunch.associated_user_key] = []
          }
          loopPunch.timestamp = returnDateFromUnknownDateFormat(loopPunch.timestamp).getTime()
          if (loopPunch.status !== 'deleted') employeePunchData[loopPunch.associated_user_key].push(loopPunch)
        }
        // Loop through employees and sort punchest by timestamp
        for (let loopEmployeeKey in employeePunchData) {
          let loopEmployeePunches = employeePunchData[loopEmployeeKey]
          loopEmployeePunches.sort((a: { timestamp: number }, b: { timestamp: number }) => {
            return a.timestamp - b.timestamp
          })
          // Loop through punches and make sure that the first punch is a clock in and the last punch is a clock out
          if (loopEmployeePunches != null && loopEmployeePunches[0] != null && loopEmployeePunches[0].type !== 'clock_in') {
            // Error: First punch is not a clock in
            if (punchDataErrors[loopEmployeeKey] == null) {
              punchDataErrors[loopEmployeeKey] = {
                name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                key: loopEmployeeKey,
              }
            }
            punchDataErrors[loopEmployeeKey]['first_punch_not_clock_in'] = true
          }
          if (
            loopEmployeePunches != null &&
            loopEmployeePunches[loopEmployeePunches.length - 1] != null &&
            loopEmployeePunches[loopEmployeePunches.length - 1].type !== 'clock_out'
          ) {
            // Error: Last punch is not a clock out
            if (punchDataErrors[loopEmployeeKey] == null) {
              punchDataErrors[loopEmployeeKey] = {
                name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                key: loopEmployeeKey,
              }
            }
            punchDataErrors[loopEmployeeKey]['last_punch_not_clock_out'] = true
          }
          // Loop through punches and make sure that they alternate between clock in and clock out
          // Also total up the time between punches
          for (let loopPunchIndex in loopEmployeePunches) {
            let loopPunch = loopEmployeePunches[loopPunchIndex]
            let punchTimestamp = returnFormattedDateKey(returnDateFromUnknownDateFormat(loopPunch.timestamp))
            if (parseInt(loopPunchIndex) !== 0) {
              let previousLoopPunch = loopEmployeePunches[parseInt(loopPunchIndex) - 1]
              if (loopPunch.type === previousLoopPunch.type) {
                // Error: Back to back punches
                if (punchDataErrors[loopEmployeeKey] == null) {
                  punchDataErrors[loopEmployeeKey] = {
                    name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                    key: loopEmployeeKey,
                  }
                }
                punchDataErrors[loopEmployeeKey]['back_to_back_punches'] = true
              }
              // Error: If loop punch type is not a clock_in or clock_out
              if (loopPunch.type !== 'clock_in' && loopPunch.type !== 'clock_out') {
                if (punchDataErrors[loopEmployeeKey] == null) {
                  punchDataErrors[loopEmployeeKey] = {
                    name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                    key: loopEmployeeKey,
                  }
                }
                punchDataErrors[loopEmployeeKey]['punch_type_not_clock_in_or_clock_out'] = true
              }
              if (loopPunch.type === 'clock_out') {
                // Subtract previous punch timestamp from current punch timestamp to get total time
                let totalMinutes = (loopPunch.timestamp - previousLoopPunch.timestamp) / (1000 * 60)
                let totalHours = totalMinutes / 60
                // Group by allocation subtype codes
                if (aggregatedPunchData[loopEmployeeKey] == null) {
                  aggregatedPunchData[loopEmployeeKey] = {
                    key: loopEmployeeKey,
                    name: getProp(internalUsers, loopEmployeeKey + '.name', null),
                    hourly_or_salaried: getProp(internalUsers, loopEmployeeKey + '.hourly_or_salaried', null),
                    id_number_payroll: getProp(internalUsers, loopEmployeeKey + '.id_number_payroll', null),
                    id_organization_payroll: getProp(internalUsers, loopEmployeeKey + '.id_organization_payroll', null),
                    totals: {},
                  }
                }
                // Aggregation Totals
                let selectedAllocationCode = null
                let allocationTypeCategory = ''
                if (previousLoopPunch != null && previousLoopPunch['associated_allocation_type_key'] != null) {
                  let selectedAllocationType = getProp(allocationTypes, previousLoopPunch['associated_allocation_type_key'], {})
                  let selectedAllocationSubtypes = getProp(selectedAllocationType, 'subtypes', {})
                  switch (previousLoopPunch['associated_allocation_type_key']) {
                    case 'admin_time':
                      allocationTypeCategory = 'ADMIN TIME'
                      if (
                        selectedAllocationSubtypes != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']] != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name'] != null
                      ) {
                        selectedAllocationCode = selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name']
                      }
                      break
                    case 'break':
                      allocationTypeCategory = 'BREAK'
                      selectedAllocationCode = 'BREAK'
                      break
                    case 'field_work':
                      allocationTypeCategory = 'FIELD WORK'
                      selectedAllocationCode =
                        getProp(previousLoopPunch, 'associated_allocation_type_name', 'FIELD') +
                        ' - ' +
                        getProp(previousLoopPunch, 'associated_allocation_subtype_name', '(UNKNOWN)')
                      break
                    case 'non_working_time':
                      allocationTypeCategory = 'NON WORKING TIME'
                      if (
                        selectedAllocationSubtypes != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']] != null &&
                        selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name'] != null
                      ) {
                        selectedAllocationCode = selectedAllocationSubtypes[previousLoopPunch['associated_allocation_subtype_key']]['name']
                      }
                      break
                  }
                }
                if (selectedAllocationCode === null) {
                  selectedAllocationCode = 'MISSING'
                }
                if (aggregatedPunchData[loopEmployeeKey]['totals'][punchTimestamp] == null) {
                  aggregatedPunchData[loopEmployeeKey]['totals'][punchTimestamp] = {}
                }
                if (aggregatedPunchData[loopEmployeeKey]['totals'][punchTimestamp][selectedAllocationCode] == null) {
                  aggregatedPunchData[loopEmployeeKey]['totals'][punchTimestamp][selectedAllocationCode] = {
                    hours: 0,
                    category: allocationTypeCategory,
                    type: getProp(previousLoopPunch, 'associated_allocation_type_name', 'FIELD'),
                    subtype: getProp(previousLoopPunch, 'associated_allocation_subtype_name', '(UNKNOWN)'),
                  }
                }
                aggregatedPunchData[loopEmployeeKey]['totals'][punchTimestamp][selectedAllocationCode]['hours'] += totalHours
              }
            }
          }
        }
        // Warnings
        if (objectToArray(aggregatedPunchData).length === 0) {
          punchDataWarnings['no_data'] = true
        }
        // Generate CSV
        let csvData: TsType_CSVCellContents[][] = []
        let csvHeaders: TsType_CSVCellContents[] = ['Name', 'Insperity Person ID', 'Date', 'Allocation Type', 'Allocation', 'SubAllocation', 'Duration']
        csvData.push(csvHeaders)
        let csvRowData: TsType_CSVCellContents[] = []
        for (let loopUserKey in aggregatedPunchData) {
          let loopUser = aggregatedPunchData[loopUserKey]
          // Loop through each aggregate punch type and get the total hours
          for (let loopDateKey in loopUser['totals']) {
            for (let loopCodeKey in loopUser['totals'][loopDateKey]) {
              let loopCodeHours = loopUser['totals'][loopDateKey][loopCodeKey]['hours'].toFixed(6)
              csvRowData = []
              csvRowData.push(getProp(loopUser, 'name', ''))
              csvRowData.push(getProp(loopUser, 'id_number_payroll', ''))
              csvRowData.push(loopDateKey)
              csvRowData.push(getProp(loopUser['totals'][loopDateKey][loopCodeKey], 'category', ''))
              csvRowData.push(getProp(loopUser['totals'][loopDateKey][loopCodeKey], 'type', ''))
              csvRowData.push(getProp(loopUser['totals'][loopDateKey][loopCodeKey], 'subtype', ''))
              csvRowData.push(loopCodeHours)
              csvData.push(csvRowData)
            }
          }
        }
        // Download CSV
        downloadCSV(
          '(' +
            returnFormattedDate(new Date(), 'YYYY-MM-DD') +
            ') ETW Day Breakdown Export ' +
            returnFormattedDate(startDate, 'YYYY-MM-DD') +
            ' - ' +
            returnFormattedDate(endDate, 'YYYY-MM-DD') +
            '.csv',
          csvData,
        )
        uc_setUserInterface_CustomDialogDisplay({
          display: true,
          dialog: {
            dialog_jsx: rJSX_DownloadErrorAndWarningDialog(uc_setUserInterface_CustomDialogDisplay, punchDataErrors, punchDataWarnings),
            settings: {
              max_width: 'lg',
            },
          },
        })
        resolve({ success: true })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to download aggregate timesheet data'),
            details: rLIB('Failed to query database'),
            code: 'ER-D-TF-DPPR-01',
          },
        })
      }
    })
  })
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:
			[ ] Typescript - 1 instance of any

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Autocomplete, Box, FormControl, TextField } from '@mui/material/'
import {
  TsInterface_DynamicAttributes,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormSettings,
  TsType_FormInputOptionArray,
  TsType_InputChangeCallback,
} from 'rfbp_core/components/form'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  formAdditionalData: TsInterface_FormAdditionalData
  formData: TsInterface_FormData
  formHooks: TsInterface_FormHooksObject
  formInput: TsInterface_FormInput
  formSettings: TsInterface_FormSettings
  inputChangeCallback: TsType_InputChangeCallback
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const AssociationAutocompleteComboBox = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_formData: TsInterface_FormData = getProp(props, 'formData', {})
  let pr_formInput: TsInterface_FormInput = getProp(props, 'formInput', {})
  let pr_formSettings: TsInterface_FormSettings = getProp(props, 'formSettings', {})
  let pr_inputOptions: TsType_FormInputOptionArray = getProp(pr_formInput, 'options', [])
  let pr_inputChangeCallback: TsType_InputChangeCallback = props.inputChangeCallback

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions

  // Generate JSX

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    // Dynamic Class Name
    let dynamicClassName = ''
    // Dynamic Attributes
    let dynamicAttributes: TsInterface_DynamicAttributes = {}
    if (pr_formInput['required'] === true) {
      dynamicAttributes['required'] = true
    }
    if (
      pr_formInput['required'] === true &&
      pr_formSettings.highlight_missing === true &&
      (pr_formData == null || pr_formData[pr_formInput['key']] == null || pr_formData[pr_formInput['key']] === '')
    ) {
      dynamicAttributes['error'] = true
    }
    if (pr_formInput['disabled'] === true) {
      dynamicAttributes['disabled'] = true
    }
    // Form Input JSX
    let componentJSX = (
      // <Box sx={{ marginTop: "16px", marginBottom: "8px" }}>
      <Box>
        <FormControl
          className={dynamicClassName}
          fullWidth
        >
          <Autocomplete
            disablePortal={false}
            id={pr_formInput.key}
            options={pr_inputOptions}
            disableClearable
            getOptionLabel={(option) => option.value as string}
            onChange={(event: any, selectedOption: any) => {
              if (selectedOption != null && selectedOption.key != null && selectedOption.value != null) {
                pr_inputChangeCallback(pr_formInput.key + '_name', selectedOption.value, true)
                pr_inputChangeCallback(pr_formInput.key + '_key', selectedOption.key, true)
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                value={pr_formData[pr_formInput.key] || ''}
                label={pr_formInput.label}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                {...dynamicAttributes}
              />
            )}
            {...dynamicAttributes}
          />
        </FormControl>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

// <TextField
// 	color="primary"
// 	value={ formData[formInput.key] || "" }
// 	id={ formInput["key"] }
// 	label={ formInput["label"] }
// 	margin="normal"
// 	onChange={ (event: any) => {
// 		if ( event != null && event.target != null && event.target.value != null ){
// 			inputChangeCallback( formInput.key, event.target.value )
// 		}
// 	} }
// 	variant="outlined"
// 	InputLabelProps={{ shrink: true }}
// 	InputProps={{
// 		startAdornment: rJSX_InputAdornment( formInput, "start" ),
// 		endAdornment: rJSX_InputAdornment( formInput, "end" ),
// 	}}
// 	{ ...dynamicAttributes }
// />

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_SalesOpportunityDiscoverySession_Address_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { OpportunityAnalyticsCustomDialog } from './opportunities'

///////////////////////////////
// Functions
///////////////////////////////

export const tableSettings_CampaignOpportunities: TsInterface_TableDatabaseSettings = {
  rows_per_page: 100,
  show_header: true,
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        comparator: '==',
        conditions: [],
        logic_type: 'comparison',
        prop: 'status',
        source: 'rowData',
        value: 'deleted',
      },
    },
  ],
  searchable: false,
}

export const tableColumns_CampaignOpportunities: TsInterface_TableColumns = {
  manage: TableCellManage({
    view_unauthenticated_customer_page: {
      icon: (
        <Icon
          type="solid"
          icon="mobile-screen-button"
        />
      ),
      label: <>{rLIB('View Customer Page')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          let url = ApplicationPages.UnauthenticatedSalesOpportunityPage.url(rowData.key as string) + '?tracking=false'
          window.open(url, '_blank')
        }
      },
    },
    view_analytics: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass-chart"
        />
      ),
      label: <>{rLIB('View Analytics')}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          tableHooks.uc_setUserInterface_CustomDialogDisplay({
            display: true,
            dialog: {
              dialog_jsx: (
                <OpportunityAnalyticsCustomDialog
                  opportunity={rowData}
                  opportunityKey={rowData.key as string}
                />
              ),
              settings: {
                max_width: 'lg',
              },
            },
          })
        }
      },
    },
  }),
  TEMP_icon: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: 'color',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let color = themeVariables.gray_700
        switch (rowData.associated_campaign_group_color) {
          case 'blue':
            color = themeVariables.info_main
            break
          case 'green':
            color = themeVariables.success_main
            break
          case 'yellow':
            color = themeVariables.warning_main
            break
          default:
            color = themeVariables.gray_700
        }
        let colorCircleIconJSX = (
          <Icon
            icon="circle"
            type="solid"
            sx={{ color: color, fontSize: '18px' }}
            className="tw-cursor-pointer"
            onClick={() => {
              tableHooks.uc_setUserInterface_FormDialogDisplay({
                display: true,
                form: {
                  form: {
                    formAdditionalData: {
                      taskKey: rowData.key,
                    },
                    formData: rowData,
                    formInputs: {
                      color: {
                        data_type: 'string',
                        input_type: 'multiple_choice_radio',
                        key: 'color',
                        label: rLIB('Color'),
                        required: true,
                        options: [
                          {
                            value: (
                              <Icon
                                icon="circle"
                                sx={{ fontSize: '18px', color: themeVariables.error_main }}
                              />
                            ),
                            key: 'red',
                          },
                          {
                            value: (
                              <Icon
                                icon="circle"
                                sx={{ fontSize: '18px', color: themeVariables.warning_main }}
                              />
                            ),
                            key: 'yellow',
                          },
                          {
                            value: (
                              <Icon
                                icon="circle"
                                sx={{ fontSize: '18px', color: themeVariables.success_main }}
                              />
                            ),
                            key: 'green',
                          },
                          {
                            value: (
                              <Icon
                                icon="circle"
                                sx={{ fontSize: '18px', color: themeVariables.gray_700 }}
                              />
                            ),
                            key: 'gray',
                          },
                        ],
                        submit_on_change: true,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {
                      submit_button_hide: true,
                    },
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        getClientKey(formHooks.uc_RootData_ClientKey, formHooks.uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            DatabaseSetMergeDocument(
                              DatabaseRef_SalesOpportunityDiscoverySession_Address_Document(
                                res_GCK.clientKey,
                                tableAdditionalData.discoverySessionKey,
                                rowData.key as string,
                              ),
                              formSubmittedData,
                            )
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: <>{rLIB('Address Color')}</>,
                    formDialogIcon: (
                      <Icon
                        type="solid"
                        icon="pen-to-square"
                      />
                    ),
                  },
                },
              })
            }}
          />
        )
        // Cell JSX
        cellJSX = <Box>{colorCircleIconJSX}</Box>
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  },
  address1: TableCellBasic('address1', rLIB('Address'), 'address1'),
  city: TableCellBasic('city', rLIB('City'), 'city'),
  state: TableCellBasic('state', rLIB('State'), 'state'),
  postal_code: TableCellBasic('postal_code', rLIB('Zip'), 'postal_code'),
  latitude: TableCellBasic('latitude', rLIB('Latitude'), 'latitude'),
  longitude: TableCellBasic('longitude', rLIB('Longitude'), 'longitude'),
  google_solar_max_production_kwh_for_15_panels: TableCellBasic(
    'google_solar_max_production_kwh_for_15_panels',
    rLIB('Annual Production kWh (15 Panels)'),
    'google_solar_max_production_kwh_for_15_panels',
  ),
  google_solar_max_panels: TableCellBasic('google_solar_max_panels', rLIB('Max Panels'), 'google_solar_max_panels'),
  key: TableCellBasic('key', rLIB('Key'), 'key'),
}

export const tableSettings_CampaignOpportunityGroups: TsInterface_TableSettings = {
  // conditional_row_styles: [
  //   {
  //     className: 'tw-opacity-30 tw-line-through',
  //     conditional_display: {
  //       active: true,
  //       comparator: '==',
  //       conditions: [],
  //       logic_type: 'comparison',
  //       prop: 'status',
  //       source: 'rowData',
  //       value: 'deleted',
  //     },
  //   },
  // ],
  size: 'small',
  sortable: true,
  sort_property_default: 'google_solar_max_production_kwh_for_15_panels',
  paginated: true,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_VehicleMileageLogs_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'main')
}

export const DatabaseRef_VehicleMileageLogs_Document = (clientKey: string, logKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'main', logKey)
}

export const DatabaseRef_VehicleMileageLogs_Query = (clientKey: string, startTimestamp: Date, endTimestamp: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_start', comparator: '>=', value: startTimestamp },
    { prop: 'timestamp_start', comparator: '<=', value: endTimestamp },
  ]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'main'),
    queryOperatorsArray,
    [],
    {},
    null,
  )
}

// Analysis
export const DatabaseRef_VehicleMileageLogs_Analysis_Document = (clientKey: string, analysisDateKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'analysis', analysisDateKey)
}

export const DatabaseRef_VehicleMileageLogs_Analysis_Drivers_Collection = (clientKey: string, analysisDateKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'analysis', analysisDateKey, 'drivers')
}

export const DatabaseRef_VehicleMileageLogs_Analysis_Driver_Document = (
  clientKey: string,
  analysisDateKey: string,
  driverKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'analysis', analysisDateKey, 'drivers', driverKey)
}

export const DatabaseRef_VehicleMileageLogs_Analysis_GeocodedDistances_Document = (
  clientKey: string,
  analysisDateKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'analysis', analysisDateKey, 'calculated_mileges', 'all')
}

// Garages
export const DatabaseRef_VehicleMileageGarages_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'garages')
}

export const DatabaseRef_VehicleMileageGarages_Document = (clientKey: string, garageKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'garages', garageKey)
}

export const DatabaseRef_ActiveVehicleMileageGarages_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'garages'),
    queryOperatorsArray,
    orderByArray,
    {},
    null,
  )
}

// Default Additional Mile Allocation
export const DatabaseRef_DefaultAdditionalMiles_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'default_additional_miles')
}

export const DatabaseRef_DefaultAdditionalMiles_Document = (clientKey: string, userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'default_additional_miles', userKey)
}

// Override Additional Mile Allocation
export const DatabaseRef_OverrideAdditionalMiles_Document = (clientKey: string, dateUserKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'override_additional_miles', dateUserKey)
}

export const DatabaseRef_OverrideAdditionalMiles_SpecificDate_Query = (clientKey: string, dateKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_date_key', comparator: '==', value: dateKey }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'vehicle_mileage_logs', 'override_additional_miles'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const materialSupplierClassificationTypes = {
  'MIS': { key: 'MIS', value: 'MIS' },
  'Electrical': { key: 'Electrical', value: 'Electrical' },
  'Truck Stock': { key: 'Truck Stock', value: 'Truck Stock' },
  'Tools': { key: 'Tools', value: 'Tools' },
}

export const materialSupplierClassificationTypesArray = ['MIS', 'Electrical', 'Truck Stock', 'Tools']

export const materialSupplierPickPackagingTypes = {
  ea: { key: 'ea', value: 'Each' },
  bag: { key: 'bag', value: 'Bag' },
  box: { key: 'box', value: 'Box' },
  flat: { key: 'flat', value: 'Flat' },
  bundle: { key: 'bundle', value: 'Bundle' },
  roll: { key: 'roll', value: 'Roll' },
  spool: { key: 'spool', value: 'Spool' },
  stick: { key: 'stick', value: 'Stick' },
  pallet: { key: 'pallet', value: 'Pallet' },
}

export const materialSupplierPickPackagingTypesArray = ['ea', 'bag', 'box', 'flat', 'bundle', 'roll', 'spool', 'stick', 'pallet']

export const materialSupplierPickBaseUnitOfMeasurements = {
  ea: { key: 'ea', value: 'Each' },
  ft: { key: 'ft', value: 'Foot' },
}

export const materialSupplierPickBaseUnitOfMeasurementsArray = ['ea', 'ft']

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import {
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_TaskFormDefaults_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'defaults')
}

export const DatabaseRef_TaskFormDefault_Document = (clientKey: string, taskFormKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'defaults', taskFormKey)
}

export const DatabaseRef_TaskForms_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'main')
}

export const DatabaseRef_TaskFormsActive_Query = (
  clientKey: string,
  orderBy: string,
  orderDesc: boolean,
  limit: number | null,
  queryCursorsObject: TsInterface_QueryCursorsObject,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let orderByArray: TsInterface_OrderByArray = [{ prop: orderBy, desc: orderDesc }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    limit,
  )
}

export const DatabaseRef_TaskForm_Document = (clientKey: string, taskFormKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'main', taskFormKey)
}

export const DatabaseRef_TaskFormInstructions_Document = (clientKey: string, taskFormKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'main', taskFormKey, 'instructions_staging', 'current')
}

export const DatabaseRef_TaskFormConfig_Document = (clientKey: string, taskFormKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'main', taskFormKey, 'config_staging', 'current')
}

export const DatabaseRef_TaskFormStagingPages_Collection = (clientKey: string, taskFormKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'main', taskFormKey, 'form_staging')
}

export const DatabaseRef_TaskFormStagingPages_Document = (clientKey: string, taskFormKey: string, taskKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'main', taskFormKey, 'form_staging', taskKey)
}

export const DatabaseRef_TaskFormProdPages_Document = (clientKey: string, taskFormKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'main', taskFormKey, 'form_prod', 'current')
}

export const DatabaseRef_TaskFormProdPagesHistory_Document = (
  clientKey: string,
  taskFormKey: string,
  timestampKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'main', taskFormKey, 'form_prod_history', timestampKey)
}

export const DatabaseRef_TaskForms_Search = (clientKey: string, searchProperty: string, searchValue: string, limit: number): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: searchProperty, comparator: '>=', value: searchValue },
    { prop: searchProperty, comparator: '<=', value: searchValue + '~' },
  ]
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'directory', 'task_forms', 'main'),
    queryOperatorsArray,
    orderByArray,
    {},
    limit,
  )
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Feedback_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'feedback', 'main')
}

export const DatabaseRef_Feedback_Document = (clientKey: string, feedbackKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'feedback', 'main', feedbackKey)
}

export const DatabaseRef_Feedback_Query = (clientKey: string, startTimestamp: Date, endTimestamp: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_created', comparator: '>=', value: startTimestamp },
    { prop: 'timestamp_created', comparator: '<=', value: endTimestamp },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'feedback', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_ProjectFeedback_Query = (clientKey: string, projectKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_project_key', comparator: '==', value: projectKey }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'feedback', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_FeedbackMissingAssociatedUser_Query = (clientKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'missing_associated_user_key', comparator: '==', value: true }]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'feedback', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_FeedbackByDateAndRegion_Query = (clientKey: string, startTimestamp: Date, endTimestamp: Date, regionKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_created', comparator: '>=', value: startTimestamp },
    { prop: 'timestamp_created', comparator: '<=', value: endTimestamp },
    { prop: 'associated_region_key', comparator: '==', value: regionKey },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'feedback', 'main'), queryOperatorsArray, [], {}, null)
}

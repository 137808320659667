// TrailerAssignmentCustomDialog.tsx

import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import React, { useEffect, useState } from 'react'
import { DatabaseRef_Trailer_Document } from 'rfbp_aux/services/database_endpoints/directory/trailers'
import { DatabaseRef_TrailerAssignmentHistory_Document } from 'rfbp_aux/services/database_endpoints/operations/trailer_assignment_history'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseBatchUpdate } from 'rfbp_core/services/database_management'
import { v4 as uuidv4 } from 'uuid'

interface TrailerAssignmentCustomDialogProps {
  onClose: () => void
  uc_RootData_ClientKey: string | null // Add this prop
}

export const TrailerAssignmentCustomDialog: React.FC<TrailerAssignmentCustomDialogProps> = ({ onClose, uc_RootData_ClientKey }) => {
  const [newAssignmentTrailerKey, setNewAssignmentTrailerKey] = useState<string | null>(null)
  const [newAssignmentTrailerName, setNewAssignmentTrailerName] = useState<string | null>(null)
  const [newAssignmentDriverKey, setNewAssignmentDriverKey] = useState<string | null>(null)
  const [newAssignmentDriverName, setNewAssignmentDriverName] = useState<string | null>(null)
  const [newAssignmentDate, setNewAssignmentDate] = useState<string | null>(null)
  const [updateRoot, setUpdateRoot] = useState<boolean>(true)

  useEffect(() => {
    const date = new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const day = date.getDate()
    const hours = date.getHours()
    const minutes = date.getMinutes()

    const compositeDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}T${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`

    setNewAssignmentDate(compositeDate)
  }, [])

  const handleSearchResultClick = (option: any) => {
    if (option.type === 'trailer') {
      setNewAssignmentTrailerKey(option.id)
      setNewAssignmentTrailerName(option.name)
    } else {
      setNewAssignmentDriverKey(option.id)
      setNewAssignmentDriverName(option.name)
    }
  }

  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>
    },
    ref: React.Ref<unknown>,
  ) {
    return (
      <Slide
        direction="up"
        ref={ref}
        {...props}
      />
    )
  })

  const renderSearchResult = (option: any, searchInputValue: string | null) => (
    <Box
      sx={{ marginLeft: '8px', marginRight: '8px' }}
      onClick={() => handleSearchResultClick(option)}
    >
      <Typography className="tw-cursor-pointer">{rJSX_HighlightedSearchString(searchInputValue, option.name)}</Typography>
    </Box>
  )

  const renderDatePicker = () => (
    <Box className="tw-align-top">
      <FormControl sx={{ width: '100%' }}>
        <TextField
          sx={{ width: '100%' }}
          color="primary"
          value={newAssignmentDate || ''}
          label={rLIB('Assignment Start Date')}
          margin="normal"
          onChange={(event) => setNewAssignmentDate(event.target.value)}
          type="datetime-local"
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
    </Box>
  )

  const renderSelection = () => (
    <Box>
      {!newAssignmentTrailerName ? (
        <>
          <Typography variant="h6">{rLIB('Search Trailer')}</Typography>
          <SearchInput
            clientKey={uc_RootData_ClientKey as string}
            searchIndexKey={'trailers'}
            searchFilters={[]}
            searchResultRenderer={(option, searchInputValue) => renderSearchResult({ ...option, type: 'trailer' }, searchInputValue)}
            additionalSearchData={{}}
            sx={{ minWidth: '100%' }}
          />
        </>
      ) : (
        <Typography variant="h6">
          {rLIB('Selected Trailer')}: {newAssignmentTrailerName}
        </Typography>
      )}

      {!newAssignmentDriverName ? (
        <>
          <Typography
            variant="h6"
            className="tw-pt-4"
          >
            {rLIB('Search Driver')}
          </Typography>
          <SearchInput
            clientKey={uc_RootData_ClientKey as string}
            searchIndexKey={'users'}
            searchFilters={[]}
            searchResultRenderer={(option, searchInputValue) => renderSearchResult({ ...option, type: 'driver' }, searchInputValue)}
            additionalSearchData={{}}
            sx={{ minWidth: '100%' }}
          />
        </>
      ) : (
        <Typography variant="h6">
          {rLIB('Selected Driver')}: {newAssignmentDriverName}
        </Typography>
      )}

      <Box className="tw-mt-4">{renderDatePicker()}</Box>
      <Box className="tw-mt-4">{renderUpdateRootSelection()}</Box>
    </Box>
  )

  const renderUpdateRootSelection = () => (
    <FormControl fullWidth>
      <RadioGroup
        onChange={(event, value) => setUpdateRoot(value === 'true')}
        value={updateRoot}
        sx={{ display: 'block' }}
      >
        <FormControlLabel
          sx={{ display: 'flex' }}
          control={<Radio />}
          label={rLIB('Update Active Assigned Trailer')}
          value={true}
        />
        <FormControlLabel
          sx={{ display: 'flex' }}
          control={<Radio />}
          label={rLIB("Don't Update Active Assigned Trailer")}
          value={false}
        />
      </RadioGroup>
    </FormControl>
  )

  const handleSubmit = () => {
    if (newAssignmentDate && newAssignmentTrailerKey && newAssignmentTrailerName && newAssignmentDriverKey && newAssignmentDriverName) {
      const rootUpdateObject = {
        associated_user_key: newAssignmentDriverKey,
        associated_user_name: newAssignmentDriverName,
        field_status: true,
      }
      const logKey = uuidv4()
      const updateObject = {
        key: logKey,
        associated_trailer_key: newAssignmentTrailerKey,
        associated_trailer_name: newAssignmentTrailerName,
        associated_user_key: newAssignmentDriverKey,
        associated_user_name: newAssignmentDriverName,
        timestamp_start: new Date(newAssignmentDate),
      }
      const updateArray: any[] = [
        {
          type: 'setMerge',
          ref: DatabaseRef_TrailerAssignmentHistory_Document(uc_RootData_ClientKey as string, logKey),
          data: updateObject,
        },
      ]
      if (updateRoot) {
        updateArray.push({
          type: 'setMerge',
          ref: DatabaseRef_Trailer_Document(uc_RootData_ClientKey as string, newAssignmentTrailerKey),
          data: rootUpdateObject,
        })
      }
      DatabaseBatchUpdate(updateArray)
        .then(() => onClose())
        .catch((error) => {
          // Handle the error as required
        })
    }
  }

  return (
    <Box>
      <Dialog
        className="bp_dialog_lg_width"
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        open={true}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              disabled
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2, color: '#fff !important' }}
            >
              <Icon icon="pen-to-square" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              {rLIB('Assign Trailer')}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent>
          <Box>
            {renderSelection()}
            <Box className="tw-mt-2 tw-text-right">
              <Button
                variant="contained"
                className="tw-mt-2"
                color="success"
                disabled={!newAssignmentDate || !newAssignmentTrailerKey || !newAssignmentTrailerName || !newAssignmentDriverKey || !newAssignmentDriverName}
                onClick={handleSubmit}
              >
                <Icon
                  icon="user-plus"
                  className="tw-mr-2"
                />
                {'Add Trailer Assignment'}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

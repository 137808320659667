/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Card, Chip } from '@mui/material'
import { determineDatabasePageSpecificPermissions } from 'app/pages/database/database_home'
import { useContext, useEffect, useReducer } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Approvals_Collection, DatabaseRef_Approvals_Document } from 'rfbp_aux/services/database_endpoints/finances/spending'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
} from 'rfbp_core/components/table'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { TableCellLink } from 'rfbp_core/components/table/cells/table_cell_link'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_RootData_ClientUser } from 'rfbp_core/services/context'
import {
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsType_MuiComponentColors } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TabProps {
  selectedDate: Date
  setSelectedDate: any
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_APPROVE: JSX.Element = <Trans>Approve</Trans>
const s_REJECT: JSX.Element = <Trans>Reject</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const se_APPROVALS = 'Approvals'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// JSX Exports
///////////////////////////////

export const Tab: React.FC<TabProps> = ({ selectedDate, setSelectedDate }): JSX.Element => {
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)

  // Hooks - useEffect
  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'spending'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        un_routerNavigation(ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    document.title = se_APPROVALS
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions

  const tableDatabaseEndpoint_Approvals = () => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '!=', value: 'deleted' }]
    let orderByArray: TsInterface_OrderByArray = []
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = 100
    return generateDatabaseQuery(
      DatabaseRef_Approvals_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const handleApprovalMenuClick = (rowData: TsInterface_TableDataRow, status: string) => {
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
        let statusData = {
          status,
          [`timestamp_${status}`]: new Date(),
        }
        DatabaseSetMergeDocument(DatabaseRef_Approvals_Document(res_GCK.clientKey, rowData.key as string), statusData)
          .then((res_DSM) => {
            resolve(res_DSM)
          })
          .catch((rej_DSM) => {
            reject(rej_DSM)
          })
      })
    })
  }

  const tableColumns_Approvals: TsInterface_TableColumns = {
    manage: TableCellManage({
      approve: {
        icon: (
          <Icon
            type="solid"
            icon="check"
          />
        ),
        label: <>{s_APPROVE}</>,
        onClick: (rowData: TsInterface_TableDataRow) => {
          handleApprovalMenuClick(rowData, 'approved')
        },
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '==',
          value: 'new',
          conditions: [],
        },
      },
      reject: {
        icon: (
          <Icon
            type="solid"
            icon="x"
          />
        ),
        label: <>{s_REJECT}</>,
        onClick: (rowData: TsInterface_TableDataRow) => {
          handleApprovalMenuClick(rowData, 'rejected')
        },
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '==',
          value: 'new',
          conditions: [],
        },
      },
      delete: {
        icon: (
          <Icon
            type="solid"
            icon="trash"
          />
        ),
        label: <>{s_DELETE}</>,
        onClick: (rowData: TsInterface_TableDataRow) => {
          handleApprovalMenuClick(rowData, 'deleted')
        },
      },
    }),
    date: TableCellBasic('requested_date', 'Date', 'requested_date'),
    name: TableCellBasic('associated_user_name', 'User Name', 'associated_user_name'),
    project: TableCellBasic('associated_project_id_number', 'Project ID', 'associated_project_id_number'),
    notes: TableCellBasic('requested_notes', 'Notes', 'requested_notes'),
    total: TableCellCurrency('requested_amount', 'Total', 'requested_total'),
    status: {
      header: {
        header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
          return rLIB('Status')
        },
        header_sort_by: 'status',
      },
      cell: {
        cell_jsx: (rowData: TsInterface_TableDataRow) => {
          let chipColor: TsType_MuiComponentColors = 'info'
          if (rowData.status === 'new') {
            chipColor = 'info'
          } else if (rowData.status === 'approved') {
            chipColor = 'primary'
          } else if (rowData.status === 'rejected') {
            chipColor = 'error'
          } else if (rowData.status === 'deleted') {
            chipColor = 'warning'
          } else if (rowData.status === 'completed') {
            chipColor = 'success'
          }
          let chipIcon = 'triangle-exclamation'
          if (rowData.status === 'approved') {
            chipIcon = 'check'
          } else if (rowData.status === 'rejected') {
            chipIcon = 'x'
          } else if (rowData.status === 'deleted') {
            chipIcon = 'trash'
          } else if (rowData.status === 'completed') {
            chipIcon = 'check'
          }
          let cellJSX = (
            <Box>
              <Chip
                label={rowData.status}
                color={chipColor}
                icon={<Icon icon={chipIcon} />}
              />
            </Box>
          )
          return cellJSX
        },
      },
    },
    authorization: TableCellLink(
      'associated_issuing_authorization_id',
      'Authorization URL',
      'associated_issuing_authorization_id',
      'https://dashboard.stripe.com/issuing/authorizations',
    ),
  }

  const tableSettings_Approvals: TsInterface_TableDatabaseSettings = {
    rows_per_page: 100,
    show_header: true,
    size: 'small',
    sort_direction: 'desc',
    sort_property: 'timestamp_created',
    use_live_data: true,
    collapsible_columns: true,
    conditional_row_styles: [
      {
        className: 'tw-opacity-30 tw-line-through',
        conditional_display: {
          active: true,
          logic_type: 'comparison',
          source: 'rowData',
          prop: 'status',
          comparator: '==',
          value: 'deleted',
          conditions: [],
        },
      },
    ],
  }

  // Call Functions

  // JSX Generation

  const rJSX_Table = (): JSX.Element => {
    let tableJSX = <></>

    let tableAdditionalData_Approvals: TsInterface_TableAdditionalData = {}

    tableJSX = (
      <Card className="">
        <TableDatabase
          tableAdditionalData={tableAdditionalData_Approvals}
          tableColumns={tableColumns_Approvals}
          tableDatabaseEndpoint={tableDatabaseEndpoint_Approvals}
          tableSettings={tableSettings_Approvals}
        />
      </Card>
    )

    return tableJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-pt-1">
        <Box>{rJSX_Table()}</Box>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_VehicleFile = (clientKey: string, vehicleKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/vehicles/' + vehicleKey + '/files/' + fileName)
}

export const StorageRef_Vehicle_MessageThread_File = (clientKey: string, vehicleKey: string, threadKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/vehicles/' + vehicleKey + '/threads/' + threadKey + '/files/' + fileName)
}

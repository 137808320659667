///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material'
import {
  aggregateTimesheetPunchData,
  cleanPunchDataBeforeEvaluation,
  convertToTimeFormat,
  createNonWorkingTimePunches,
  deleteNonWorkingTimePunches,
  editNonWorkingEventPunches,
  submitTimeSheet,
} from 'app/models/timesheets/timesheet_functions'
import { hardCodedAllocationTypes } from 'app/models/timesheets/timesheet_hard_coded_data'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { DatabaseRef_TimePunches_UserDateRange_Query } from 'rfbp_aux/services/database_endpoints/timesheets/time_punches'
import { DatabaseRef_TimeSheets_Document } from 'rfbp_aux/services/database_endpoints/timesheets/time_sheets'
import { DatePicker } from 'rfbp_core/components/date_picker'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TsInterface_TableAdditionalData,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableManageActionsObject,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseUpdateDocument,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnFormattedDate, returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_IndividualTimeSheet } from '../components/individual_week_timesheet'

///////////////////////////////
// Typescript
///////////////////////////////

interface TabProps {
  selectedDate: Date
  setSelectedDate: any
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_AGGREGATED_PUNCHES: JSX.Element = <Trans>aggregated punches</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_REQUESTED_CORRECTION: JSX.Element = (
  <Trans>Are you sure that you want to delete this requested correction?</Trans>
)
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_SCHEDULED_NON_WORKING_TIME: JSX.Element = (
  <Trans>Are you sure that you want to delete this scheduled non working time?</Trans>
)
const s_CORRECTION_NOTES: JSX.Element = <Trans>Correction Notes</Trans>
const s_DAYS_OFF: JSX.Element = <Trans>Days Off</Trans>
const s_DELETE: JSX.Element = <Trans>Delete</Trans>
const s_DELETE_NON_WORKING_TIME: JSX.Element = <Trans>Delete Non Working Time</Trans>
const s_DELETE_REQUESTED_CORRECTION: JSX.Element = <Trans>Delete Requested Correction</Trans>
const s_EDIT_NON_WORKING_TIME: JSX.Element = <Trans>Edit Non Working Time</Trans>
const s_EDIT_REQUESTED_CORRECTION: JSX.Element = <Trans>Edit Requested Correction</Trans>
const s_END_TIME: JSX.Element = <Trans>End Time</Trans>
const s_FAILED_TO_SAVE_CORRECTION_NOTE: JSX.Element = <Trans>Failed to save correction note</Trans>
const s_FAILED_TO_SCHEDULE_NON_WORKING_TIME: JSX.Element = <Trans>Failed to schedule non working time</Trans>
const s_IVE_REVIEWED_MY_TIMESHEET_AND_EVERYTHING_LOOKS_GOOD: JSX.Element = <Trans>I've reviewed my timesheet and everything looks good</Trans>
const s_MISSING_REQUIRED_PARAMETERS: JSX.Element = <Trans>Missing Required Parameters</Trans>
const s_NO_DAYS_SELECTED: JSX.Element = <Trans>No Days Selected</Trans>
const s_RAW_PUNCHES: JSX.Element = <Trans>raw punches</Trans>
const s_REQUEST_CORRECTION: JSX.Element = <Trans>Request Correction</Trans>
const s_SCHEDULE_NON_WORKING_TIME: JSX.Element = <Trans>Schedule Non Working Time</Trans>
const s_START_TIME: JSX.Element = <Trans>Start Time</Trans>
const s_SUBMIT: JSX.Element = <Trans>Submit</Trans>
const s_SUBMITTED: JSX.Element = <Trans>Submitted</Trans>
const s_SUBMIT_TIMESHEET: JSX.Element = <Trans>Submit Timesheet</Trans>
const s_TIME_OFF_TYPE: JSX.Element = <Trans>Time Off Type</Trans>
const s_TO: JSX.Element = <Trans>To</Trans>
const s_TOTAL: JSX.Element = <Trans>Total</Trans>
const s_YOUR_START_TIME_IS_AFTER_YOUR_END_TIME: JSX.Element = <Trans>Your start time is after your end time</Trans>
// { sort-end } - displayed text

// Forms
const formInputs_EditNonWorkingTime: TsInterface_FormInputs = {
  start_time: {
    data_type: 'string',
    input_type: 'timestamp_time',
    key: 'start_time',
    label: s_START_TIME,
    required: true,
  },
  end_time: {
    data_type: 'string',
    input_type: 'timestamp_time',
    key: 'end_time',
    label: s_END_TIME,
    required: true,
  },
}

const formInputs_TimesheetSubmission: TsInterface_FormInputs = {
  week_review: {
    data_type: 'string',
    input_type: 'custom_form_input_jsx',
    key: 'week_review',
    label: <></>,
    required: false,
    renderCustomFormInput: (
      formInput: any,
      formInputs: any,
      formData: any,
      formInputChange: any,
      formSettings: any,
      formAdditionalData: any,
      formHooks: any,
    ) => {
      let inputJSX = <></>
      const returnAllocationHoursFromMinutes = (allocationTypeKey: string | null) => {
        if (
          allocationTypeKey != null &&
          formAdditionalData != null &&
          formAdditionalData.aggregatePunchData != null &&
          formAdditionalData.aggregatePunchData.breakdowns != null &&
          formAdditionalData.aggregatePunchData.breakdowns[allocationTypeKey] != null &&
          formAdditionalData.aggregatePunchData.breakdowns[allocationTypeKey]['total_minutes'] != null
        ) {
          return convertToTimeFormat(formAdditionalData.aggregatePunchData.breakdowns[allocationTypeKey]['total_minutes'])
        } else {
          return convertToTimeFormat(0)
        }
      }
      let breakdownJSX = <></>
      breakdownJSX = (
        <Box>
          {objectToArray(hardCodedAllocationTypes).map((allocationType: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Typography variant="h6">
                <Box className="tw-font-bold tw-mb-1 tw-mr-1 tw-inline-block">{allocationType.name}:</Box>
                <Box className="tw-font-light tw-mb-1 tw-mr-1 tw-inline-block tw-opacity-30">{returnAllocationHoursFromMinutes(allocationType.key)}</Box>
              </Typography>
            </Box>
          ))}
          <Box>
            <Typography variant="h6">
              <Box className="tw-font-bold tw-mb-1 tw-mr-1 tw-inline-block">{s_TOTAL}:</Box>
              <Box className="tw-font-light tw-mb-1 tw-mr-1 tw-inline-block tw-opacity-30">
                {convertToTimeFormat(getProp(formAdditionalData.aggregatePunchData.totals, 'total_minutes', 0))}
              </Box>
            </Typography>
          </Box>
        </Box>
      )
      inputJSX = <Box>{breakdownJSX}</Box>
      return inputJSX
    },
  },
  reviewed: {
    data_type: 'boolean',
    input_type: 'boolean_checkbox',
    key: 'reviewed',
    label: s_IVE_REVIEWED_MY_TIMESHEET_AND_EVERYTHING_LOOKS_GOOD,
    required: true,
  },
}

///////////////////////////////
// Functions
///////////////////////////////

function getStartAndEndOfWeek(date: Date) {
  const startOfWeek = new Date(date)
  startOfWeek.setHours(0, 0, 0, 0)
  startOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 0)
  const endOfWeek = new Date(date)
  endOfWeek.setHours(23, 59, 59, 999)
  endOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 6)
  return {
    startOfWeek,
    endOfWeek,
  }
}

function getDatesInRange(startDate: string, endDate: string) {
  const dates = []
  let currentDate = new Date(startDate)
  while (currentDate <= new Date(endDate)) {
    dates.push(currentDate.toISOString().slice(0, 10))
    currentDate.setDate(currentDate.getDate() + 1)
  }
  return dates
}

function isTimeLessThan(firstTime: string, secondTime: string) {
  const [firstHour, firstMinute] = firstTime.split(':').map(Number)
  const [secondHour, secondMinute] = secondTime.split(':').map(Number)
  if (firstHour < secondHour) {
    return true
  } else if (firstHour === secondHour && firstMinute < secondMinute) {
    return true
  } else {
    return false
  }
}

///////////////////////////////
// JSX Exports
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const Tab: React.FC<TabProps> = ({ selectedDate, setSelectedDate }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_aggregatePunchData, us_setAggregatePunchData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawPunchData, us_setRawPunchData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_submissionData, us_setSubmissionData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tableViewMode, us_setTableViewMode] = useState<'aggregated' | 'raw'>('raw')
  const [us_weekEndDate, us_setWeekEndDate] = useState<Date | null>(null)
  const [us_weekStartDate, us_setWeekStartDate] = useState<Date | null>(null)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let weekBoundingDates = getStartAndEndOfWeek(selectedDate)
    us_setWeekStartDate(weekBoundingDates.startOfWeek)
    us_setWeekEndDate(weekBoundingDates.endOfWeek)
    return () => {}
  }, [selectedDate])

  useEffect(() => {
    // Refresh Aggregate Punch Data every 10 seconds
    us_setAggregatePunchData(aggregateTimesheetPunchData(objectToArray(cleanPunchDataBeforeEvaluation(us_rawPunchData))))
    const interval = setInterval(() => {
      us_setAggregatePunchData(aggregateTimesheetPunchData(objectToArray(cleanPunchDataBeforeEvaluation(us_rawPunchData))))
    }, 10000)
    return () => {
      clearInterval(interval)
    }
  }, [us_rawPunchData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSubmissionData(newData)
      } else {
        us_setSubmissionData({})
      }
      ur_forceRerender()
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.key != null && us_weekStartDate != null && us_weekEndDate != null) {
      let timesheetKey = uc_RootData_ClientUser.key + '_' + returnFormattedDateKey(us_weekStartDate)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_TimeSheets_Document(res_GCK.clientKey, timesheetKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_ClientUser, us_weekStartDate, us_weekEndDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRawPunchData(newData)
      ur_forceRerender()
    }
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.key != null && us_weekStartDate != null && us_weekEndDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_TimePunches_UserDateRange_Query(res_GCK.clientKey, uc_RootData_ClientUser.key, us_weekStartDate, us_weekEndDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_ClientUser, us_weekStartDate, us_weekEndDate])

  // Functions
  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      // let formattedDateKey = returnFormattedDateKey( new Date( newDate ) )
      setSelectedDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const scheduleTimeOff = (startDateKey: string, endDateKey: string, selectedDate: Date | null): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let promiseArray: TsType_UnknownPromise[] = []
        let nonWorkingTimeData: TsInterface_UnspecifiedObject = {}
        let nonWorkingTimeSubtypes: TsInterface_UnspecifiedObject = {}
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_TimeSheets_Document(res_GCK.clientKey, 'non_working_time'))
            .then((res_DGD) => {
              nonWorkingTimeData = res_DGD.data
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            }),
        )
        Promise.all(promiseArray).finally(() => {
          let nonWorkingTimeOptions: TsInterface_UnspecifiedObject[] = []
          if (nonWorkingTimeData != null && nonWorkingTimeData['subtypes'] != null) {
            nonWorkingTimeSubtypes = nonWorkingTimeData['subtypes']
            for (let loopSubtypeKey in nonWorkingTimeData['subtypes']) {
              let loopSubtype = nonWorkingTimeData['subtypes'][loopSubtypeKey]
              if (loopSubtype.status === 'active') {
                nonWorkingTimeOptions.push({
                  key: loopSubtypeKey,
                  value: loopSubtype.name,
                })
              }
            }
          }
          nonWorkingTimeOptions.sort(dynamicSort('value', 'asc'))
          let initialFormData: TsInterface_UnspecifiedObject = {
            // formData
            end_time: '17:00',
            start_time: '09:00',
            selected_dates: {},
          }
          if (selectedDate != null) {
            initialFormData['selected_dates'][returnFormattedDateKey(selectedDate as unknown as Date)] = true
          }
          let datesArray: string[] = getDatesInRange(startDateKey, endDateKey)
          uc_setUserInterface_FormDialogDisplay({
            display: true,
            form: {
              form: {
                formAdditionalData: {},
                formData: initialFormData,
                formInputs: {
                  allocation_subtype_key: {
                    key: 'allocation_subtype_key',
                    label: s_TIME_OFF_TYPE,
                    input_type: 'multiple_choice_radio',
                    required: true,
                    data_type: 'string',
                    options: nonWorkingTimeOptions,
                  },
                  selected_dates: {
                    data_type: 'string',
                    input_type: 'custom_form_input_jsx',
                    key: 'selected_dates',
                    label: <></>,
                    required: true,
                    renderCustomFormInput: (
                      formInput: any,
                      formInputs: any,
                      formData: any,
                      formInputChange: any,
                      formSettings: any,
                      formAdditionalData: any,
                      formHooks: any,
                    ) => {
                      let inputJSX = <></>
                      // JSX
                      inputJSX = (
                        <Box className="">
                          <Box>
                            <Typography
                              variant="body1"
                              className="tw-opacity-70"
                            >
                              {s_DAYS_OFF}
                            </Typography>
                          </Box>
                          <Box className="tw-ml-6">
                            {datesArray.map((date: string, index: number) => (
                              <Box key={index}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={formData['selected_dates'][date] || false}
                                      onChange={(event, value) => {
                                        if (value != null) {
                                          let selectedDates = getProp(formData, 'selected_dates', {})
                                          selectedDates[date] = value
                                          formInputChange('selected_dates', selectedDates, true)
                                        }
                                      }}
                                    />
                                  }
                                  key={date}
                                  label={date}
                                />
                              </Box>
                            ))}
                          </Box>
                        </Box>
                      )
                      return inputJSX
                    },
                  },
                  start_time: {
                    data_type: 'string',
                    input_type: 'timestamp_time',
                    key: 'start_time',
                    label: s_START_TIME,
                    required: true,
                  },
                  end_time: {
                    data_type: 'string',
                    input_type: 'timestamp_time',
                    key: 'end_time',
                    label: s_END_TIME,
                    required: true,
                  },
                },
                formOnChange: (
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formData: TsInterface_FormData,
                  formInputs: TsInterface_FormInputs,
                  formSettings: TsInterface_FormSettings,
                ) => {},
                formSettings: {},
                formSubmission: (
                  formSubmittedData: TsInterface_FormSubmittedData,
                  formAdditionalData: TsInterface_FormAdditionalData,
                  formHooks: TsInterface_FormHooksObject,
                ) => {
                  return new Promise((resolve, reject) => {
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        let requestedNonWorkingDatesObject: TsInterface_UnspecifiedObject = {}
                        let dateSelections: TsInterface_UnspecifiedObject = getProp(formSubmittedData, 'selected_dates', {})
                        let selectedDates: TsInterface_UnspecifiedObject = {}
                        for (let loopDayKey in dateSelections) {
                          if (dateSelections[loopDayKey] === true) {
                            selectedDates[loopDayKey] = true
                          }
                        }
                        if (objectToArray(selectedDates).length > 0) {
                          if (isTimeLessThan(formSubmittedData.start_time, formSubmittedData.end_time) === true) {
                            for (let loopDayKey in selectedDates) {
                              requestedNonWorkingDatesObject[loopDayKey] = {
                                start_time: new Date(loopDayKey + ' ' + formSubmittedData.start_time),
                                end_time: new Date(loopDayKey + ' ' + formSubmittedData.end_time),
                              }
                            }
                            let subTypeKey = getProp(formSubmittedData, 'allocation_subtype_key', null)
                            let subtypeName = null
                            let subtypeCode = null
                            if (subTypeKey != null && nonWorkingTimeSubtypes != null && nonWorkingTimeSubtypes[subTypeKey] != null) {
                              subtypeName = getProp(nonWorkingTimeSubtypes[subTypeKey], 'name', null)
                              subtypeCode = getProp(nonWorkingTimeSubtypes[subTypeKey], 'code', null)
                            }
                            createNonWorkingTimePunches(
                              res_GCK.clientKey,
                              requestedNonWorkingDatesObject,
                              us_rawPunchData,
                              new Date(),
                              getProp(uc_RootData_ClientUser, 'key', null),
                              getProp(uc_RootData_ClientUser, 'name', null),
                              getProp(uc_RootData_ClientUser, 'key', null),
                              getProp(uc_RootData_ClientUser, 'name', null),
                              'non_working_time',
                              'Non Working Time',
                              getProp(nonWorkingTimeData, 'code', null),
                              subTypeKey,
                              subtypeName,
                              subtypeCode,
                              // allocationSubtypeName: string,
                              // allocationSubtypeCode: string,
                            )
                              .then((res_CNWTP) => {
                                resolve(res_CNWTP)
                              })
                              .catch((rej_CNWTP) => {
                                formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CNWTP.error })
                                reject(rej_CNWTP)
                              })
                          } else {
                            let error = {
                              message: s_FAILED_TO_SCHEDULE_NON_WORKING_TIME,
                              details: s_YOUR_START_TIME_IS_AFTER_YOUR_END_TIME,
                              code: 'ER-D-TIR-WREA-01',
                            }
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                            reject({ success: false, error: error })
                          }
                        } else {
                          let error = {
                            message: s_FAILED_TO_SCHEDULE_NON_WORKING_TIME,
                            details: s_NO_DAYS_SELECTED,
                            code: 'ER-D-TIR-WREA-02',
                          }
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                          reject({ success: false, error: error })
                        }
                      })
                      .catch((rej_GCK) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                        reject({ success: false, error: rej_GCK.error })
                      })
                  })
                },
              },
              dialog: {
                formDialogHeaderColor: 'success',
                formDialogHeaderText: (
                  <>
                    {s_SCHEDULE_NON_WORKING_TIME} - {returnFormattedDateKey(selectedDate as any)}
                  </>
                ),
                formDialogIcon: (
                  <Icon
                    type="solid"
                    icon="pen-to-square"
                  />
                ),
              },
            },
          })
        })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
  }

  const editTimeOff = (selectedDate: Date, combinedPunchData: TsInterface_UnspecifiedObject): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {
            start_time: returnFormattedDate(getProp(combinedPunchData, 'clock_in_timestamp', null), 'HH:mm'),
            end_time: returnFormattedDate(getProp(combinedPunchData, 'clock_out_timestamp', null), 'HH:mm'),
          },
          formInputs: formInputs_EditNonWorkingTime,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  editNonWorkingEventPunches(
                    res_GCK.clientKey,
                    returnFormattedDateKey(selectedDate),
                    getProp(combinedPunchData, 'clock_in_key', null),
                    getProp(formSubmittedData, 'start_time', null),
                    getProp(combinedPunchData, 'clock_out_key', null),
                    getProp(formSubmittedData, 'end_time', null),
                  )
                    .then((res_ENWTEP) => {
                      resolve(res_ENWTEP)
                    })
                    .catch((rej_ENWTEP) => {
                      reject(rej_ENWTEP)
                      reject({ success: false, error: rej_ENWTEP.error })
                    })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject({ success: false, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {s_EDIT_NON_WORKING_TIME} - {returnFormattedDateKey(selectedDate)}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const submitWeekTimeSheet = () => {
    let headerJSX = <>{s_SUBMIT_TIMESHEET}</>
    if (us_weekStartDate != null && us_weekEndDate != null) {
      headerJSX = (
        <>
          {s_SUBMIT_TIMESHEET} - {returnFormattedDateKey(us_weekStartDate)} {s_TO} {returnFormattedDateKey(us_weekEndDate)}
        </>
      )
      // Open Dialog
      uc_setUserInterface_FormDialogDisplay({
        display: true,
        form: {
          form: {
            formAdditionalData: {
              aggregatePunchData: us_aggregatePunchData,
            },
            formData: {},
            formInputs: formInputs_TimesheetSubmission,
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {
              if (formData != null && formData.reviewed === true) {
                formInputs.reviewed.disabled = true
              }
            },
            formSettings: {
              submit_button_icon: (
                <Icon
                  type="solid"
                  icon="paper-plane"
                />
              ),
              submit_button_text: s_SUBMIT_TIMESHEET,
            },
            formSubmission: (
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let aggregatePunchData = getProp(formAdditionalData, 'aggregatePunchData', {})
                    let breakdownTotals = getProp(aggregatePunchData, 'breakdowns', {})
                    let adminTimeBreakdown = getProp(breakdownTotals, 'admin_time', {})
                    let breakBreakdown = getProp(breakdownTotals, 'break', {})
                    let fieldWorkBreakdown = getProp(breakdownTotals, 'field_work', {})
                    let nonWorkingTimeBreakdown = getProp(breakdownTotals, 'non_working_time', {})
                    submitTimeSheet(
                      res_GCK.clientKey,
                      returnFormattedDateKey(us_weekStartDate),
                      getProp(uc_RootData_ClientUser, 'key', null),
                      getProp(uc_RootData_ClientUser, 'name', null),
                      getProp(uc_RootData_ClientUser, 'key', null),
                      getProp(uc_RootData_ClientUser, 'name', null),
                      getProp(adminTimeBreakdown, 'total_minutes', 0) / 60,
                      getProp(breakBreakdown, 'total_minutes', 0) / 60,
                      getProp(fieldWorkBreakdown, 'total_minutes', 0) / 60,
                      getProp(nonWorkingTimeBreakdown, 'total_minutes', 0) / 60,
                    )
                      .then((res_STS) => {
                        resolve(res_STS)
                      })
                      .catch((rej_STS) => {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_STS.error })
                        reject({ success: false, error: rej_STS.error })
                      })
                  })
                  .catch((rej_GCK) => {
                    formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject({ success: false, error: rej_GCK.error })
                  })
              })
            },
          },
          dialog: {
            formDialogHeaderColor: 'success',
            formDialogHeaderText: <>{headerJSX}</>,
            formDialogIcon: (
              <Icon
                type="solid"
                icon="paper-plane"
              />
            ),
          },
        },
      })
    }
  }

  // Call Functions

  // JSX Generation
  const weekRowEditActions: TsInterface_TableManageActionsObject = {
    request_correction: {
      icon: (
        <Icon
          type="solid"
          icon="message-exclamation"
        />
      ),
      label: s_REQUEST_CORRECTION,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let requestCorrectionData: TsInterface_UnspecifiedObject = {}
        if (
          tableAdditionalData != null &&
          tableAdditionalData.submissionData != null &&
          tableAdditionalData.submissionData.requested_corrections != null &&
          rowData != null &&
          rowData.date != null
        ) {
          let dateKey = returnFormattedDateKey(getProp(rowData, 'date', null))
          if (
            tableAdditionalData.submissionData.requested_corrections[dateKey] != null &&
            tableAdditionalData.submissionData.requested_corrections[dateKey].correction_notes != null
          ) {
            requestCorrectionData = { correction_notes: tableAdditionalData.submissionData.requested_corrections[dateKey].correction_notes }
          }
        }
        tableHooks.uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: tableAdditionalData,
              formData: requestCorrectionData,
              formInputs: {
                correction_notes: {
                  data_type: 'string',
                  input_type: 'text_multiline',
                  key: 'correction_notes',
                  label: s_CORRECTION_NOTES,
                  required: true,
                },
              },
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      if (
                        formSubmittedData != null &&
                        formSubmittedData.correction_notes != null &&
                        rowData != null &&
                        rowData.date != null &&
                        formAdditionalData != null &&
                        formAdditionalData.userKey != null &&
                        formAdditionalData.startDateKey != null
                      ) {
                        let timesheetKey = formAdditionalData.userKey + '_' + formAdditionalData.startDateKey
                        let updateObject: TsInterface_UnspecifiedObject = {
                          key: timesheetKey,
                          associated_date_key: formAdditionalData.startDateKey,
                          requested_corrections: {},
                        }
                        let selectedDateKey = returnFormattedDateKey(getProp(rowData, 'date', null))
                        updateObject['requested_corrections'][selectedDateKey] = {
                          date: selectedDateKey,
                          timestamp_created: new Date(),
                          correction_notes: getProp(formSubmittedData, 'correction_notes', null),
                          key: selectedDateKey,
                        }
                        DatabaseSetMergeDocument(DatabaseRef_TimeSheets_Document(res_GCK.clientKey, timesheetKey), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      } else {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: s_FAILED_TO_SAVE_CORRECTION_NOTE,
                            details: s_MISSING_REQUIRED_PARAMETERS,
                            code: 'ER-D-TIR-EREA-01',
                          },
                        })
                      }
                    })
                    .catch((rej_GCK) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: (
                <>
                  {s_REQUEST_CORRECTION} - {returnFormattedDateKey(rowData.date as any)}
                </>
              ),
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      },
    },
    schedule_non_working_time: {
      icon: (
        <Icon
          type="solid"
          icon="island-tropical"
        />
      ),
      label: s_SCHEDULE_NON_WORKING_TIME,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        scheduleTimeOff(tableAdditionalData.startDateKey, tableAdditionalData.endDateKey, getProp(rowData, 'date', null))
      },
    },
  }

  const eventRowEditActions: TsInterface_TableManageActionsObject = {
    // TODO - maybe just have edit and delete in rawRowEditActions for admin users
  }

  const rawRowEditActions: TsInterface_TableManageActionsObject = {
    // TODO - admin user
  }

  const otherRowEditActions: TsInterface_TableManageActionsObject = {
    edit_request_correction: {
      icon: (
        <Icon
          type="solid"
          icon="message-exclamation"
        />
      ),
      label: s_EDIT_REQUESTED_CORRECTION,
      conditional_disable: {
        active: true,
        logic_type: 'comparison',
        source: 'tableAdditionalData',
        prop: 'timeSheetHideActions',
        comparator: '==',
        value: true,
        conditions: [],
      },
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let requestCorrectionData: TsInterface_UnspecifiedObject = {}
        if (
          tableAdditionalData != null &&
          tableAdditionalData.submissionData != null &&
          tableAdditionalData.submissionData.requested_corrections != null &&
          rowData != null &&
          rowData.date != null
        ) {
          let dateKey = returnFormattedDateKey(getProp(rowData, 'date', null))
          if (
            tableAdditionalData.submissionData.requested_corrections[dateKey] != null &&
            tableAdditionalData.submissionData.requested_corrections[dateKey].correction_notes != null
          ) {
            requestCorrectionData = { correction_notes: tableAdditionalData.submissionData.requested_corrections[dateKey].correction_notes }
          }
        }
        tableHooks.uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: tableAdditionalData,
              formData: requestCorrectionData,
              formInputs: {
                correction_notes: {
                  data_type: 'string',
                  input_type: 'text_multiline',
                  key: 'correction_notes',
                  label: s_CORRECTION_NOTES,
                  required: true,
                },
              },
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      if (
                        formSubmittedData != null &&
                        formSubmittedData.correction_notes != null &&
                        rowData != null &&
                        rowData.date != null &&
                        formAdditionalData != null &&
                        formAdditionalData.userKey != null &&
                        formAdditionalData.startDateKey != null
                      ) {
                        let selectedDateKey = returnFormattedDateKey(getProp(rowData, 'date', null))
                        let timesheetKey = formAdditionalData.userKey + '_' + formAdditionalData.startDateKey
                        let updateObject: TsInterface_UnspecifiedObject = {
                          key: timesheetKey,
                          associated_date_key: formAdditionalData.startDateKey,
                          requested_corrections: {},
                        }
                        updateObject['requested_corrections'][selectedDateKey] = {
                          date: selectedDateKey,
                          timestamp_created: new Date(),
                          correction_notes: getProp(formSubmittedData, 'correction_notes', null),
                          key: selectedDateKey,
                        }
                        DatabaseSetMergeDocument(DatabaseRef_TimeSheets_Document(res_GCK.clientKey, timesheetKey), updateObject)
                          .then((res_DSMD) => {
                            resolve(res_DSMD)
                          })
                          .catch((rej_DSMD) => {
                            formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                            reject(rej_DSMD)
                          })
                      } else {
                        formHooks.uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: s_FAILED_TO_SAVE_CORRECTION_NOTE,
                            details: s_MISSING_REQUIRED_PARAMETERS,
                            code: 'ER-D-TIR-EREA-01',
                          },
                        })
                      }
                    })
                    .catch((rej_GCK) => {
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: (
                <>
                  {s_REQUEST_CORRECTION} - {returnFormattedDateKey(rowData.date as any)}
                </>
              ),
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="pen-to-square"
                />
              ),
            },
          },
        })
      },
    },
    delete_correction_request: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: s_DELETE_REQUESTED_CORRECTION,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        tableHooks.uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: s_DELETE_REQUESTED_CORRECTION,
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: s_DELETE,
            text: s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_REQUESTED_CORRECTION,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let requestedCorrections = getProp(tableAdditionalData, 'submissionData', {})
                    let updateObject: TsInterface_UnspecifiedObject = {
                      requested_corrections: getProp(requestedCorrections, 'requested_corrections', {}),
                    }
                    let selectedDateKey = returnFormattedDateKey(getProp(rowData, 'date', null))
                    let timesheetKey = tableAdditionalData.userKey + '_' + tableAdditionalData.startDateKey
                    delete updateObject['requested_corrections'][selectedDateKey]
                    DatabaseUpdateDocument(DatabaseRef_TimeSheets_Document(res_GCK.clientKey, timesheetKey), updateObject)
                      .then((res_DUD) => {
                        resolve(res_DUD)
                      })
                      .catch((rej_DUD) => {
                        tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DUD.error })
                        reject(rej_DUD)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                    tableHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      },
    },
    edit_non_working_time: {
      icon: (
        <Icon
          type="solid"
          icon="pen-to-square"
        />
      ),
      label: s_EDIT_NON_WORKING_TIME,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData != null && rowData.date != null && rowData.combinedPunchEvent != null) {
          editTimeOff(rowData.date as unknown as Date, getProp(rowData, 'combinedPunchEvent', null))
        }
      },
    },
    delete_non_working_time: {
      icon: (
        <Icon
          type="solid"
          icon="trash"
        />
      ),
      label: s_DELETE_NON_WORKING_TIME,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        uc_setUserInterface_ConfirmDialogDisplay({
          display: true,
          confirm: {
            color: 'error',
            header: <>{s_DELETE_NON_WORKING_TIME}</>,
            icon: (
              <Icon
                icon="trash"
                type="solid"
              />
            ),
            submit_text: s_DELETE,
            text: <>{s_ARE_YOU_SURE_THAT_YOU_WANT_TO_DELETE_THIS_SCHEDULED_NON_WORKING_TIME}</>,
            submit_callback: () => {
              return new Promise((resolve, reject) => {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let combinedPunchEvent = getProp(rowData, 'combinedPunchEvent', {})
                    let clockInKey = getProp(combinedPunchEvent, 'clock_in_key', null)
                    let clockOutKey = getProp(combinedPunchEvent, 'clock_out_key', null)
                    deleteNonWorkingTimePunches(res_GCK.clientKey, clockInKey, clockOutKey)
                      .then((res_DNWTP) => {
                        resolve(res_DNWTP)
                      })
                      .catch((rej_DNWTP) => {
                        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DNWTP.error })
                        reject(rej_DNWTP)
                      })
                  })
                  .catch((rej_GCK) => {
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    reject(rej_GCK)
                  })
              })
            },
          },
        })
      },
    },
  }

  const rJSX_DatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mb-2 tw-align-top">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Date')}
          datePickerDate={selectedDate}
          datePickerDisabled={false}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_IndividualReviewTable = (): JSX.Element => {
    let tableJSX = <></>
    if (us_weekStartDate != null && us_weekEndDate != null) {
      let startDateKey = returnFormattedDateKey(us_weekStartDate)
      let endDateKey = returnFormattedDateKey(us_weekEndDate)
      let timeSheetHideActions = false
      if (us_submissionData != null && us_submissionData.timestamp_submitted != null) {
        timeSheetHideActions = true
      }
      tableJSX = (
        <Box>
          {rJSX_IndividualTimeSheet(
            'user',
            uc_RootData_ClientUser.key,
            startDateKey,
            endDateKey,
            us_aggregatePunchData,
            us_submissionData,
            weekRowEditActions,
            eventRowEditActions,
            otherRowEditActions,
            us_rawPunchData,
            rawRowEditActions,
            us_tableViewMode,
            us_setTableViewMode,
            timeSheetHideActions,
            null,
          )}
        </Box>
      )
    }
    return tableJSX
  }

  const rJSX_SubmitButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_submissionData != null && us_submissionData.timestamp_submitted != null) {
      buttonJSX = (
        <Button
          variant="contained"
          className="tw-mb-2"
          color="success"
          disabled={true}
          onClick={() => {
            submitWeekTimeSheet()
          }}
        >
          <Icon
            icon="paper-plane"
            className="tw-mr-2"
          />
          {s_SUBMITTED}: {returnFormattedDate(us_submissionData.timestamp_submitted, 'd MMM YYYY h:mm a')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          variant="contained"
          className="tw-mb-2"
          color="success"
          disabled={us_submissionData.timestamp_submitted != null}
          onClick={() => {
            submitWeekTimeSheet()
          }}
        >
          <Icon
            icon="paper-plane"
            className="tw-mr-2"
          />
          {s_SUBMIT}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_ToggleTableTypeButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_tableViewMode === 'aggregated') {
      buttonJSX = (
        <Button
          className="tw-mb-2 tw-mr-2 tw-ml-2"
          variant="contained"
          color="secondary"
          onClick={() => {
            us_setTableViewMode('raw')
          }}
        >
          <Icon
            icon="box-check"
            className="tw-mr-2"
          />
          {s_AGGREGATED_PUNCHES}
        </Button>
      )
    } else if (us_tableViewMode === 'raw') {
      buttonJSX = (
        <Button
          className="tw-mb-2 tw-mr-2 tw-ml-2"
          variant="contained"
          color="secondary"
          onClick={() => {
            us_setTableViewMode('aggregated')
          }}
        >
          <Icon
            icon="list-timeline"
            className="tw-mr-2"
          />
          {s_RAW_PUNCHES}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-pt-1">
        <Box>
          {rJSX_DatePicker()}
          {rJSX_ToggleTableTypeButton()}
          {rJSX_SubmitButton()}
          {rJSX_IndividualReviewTable()}
        </Box>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
}

///////////////////////////////
// Description
///////////////////////////////

///////////////////////////////
// Imports
///////////////////////////////

import { Document, Image, Page, Text, View } from '@react-pdf/renderer'
import React from 'react'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_PdfTemplateComponent_StaticTable {
  component_type: 'static_table'
  table_style?: TsInterface_UnspecifiedObject
  rows: {
    [key: string]: {
      row_style?: TsInterface_UnspecifiedObject
      cells: {
        [key: string]: {
          cell_style?: TsInterface_UnspecifiedObject
          contents: {
            [key: string]: TsType_PdfTemplateComponent
          }
        }
      }
    }
  }
}

interface TsInterface_PdfTemplateComponent_LoopedDataTable {
  component_type: 'looped_data_table'
  table_style?: TsInterface_UnspecifiedObject
  table_data_variable_mapping_key: string
  row_style?: TsInterface_UnspecifiedObject
  columns: {
    [key: string]: {
      column_header_style?: TsInterface_UnspecifiedObject
      column_body_style?: TsInterface_UnspecifiedObject
      header_text: string
      variable_mapping_key: string
      fallback_text?: string
      fallback_text_style?: TsInterface_UnspecifiedObject
    }
  }
  // TODO
}

interface TsInterface_PdfTemplateComponent_StaticImage {
  component_type: 'static_image'
  image_style?: TsInterface_UnspecifiedObject
  src: string
}

interface TsInterface_PdfTemplateComponent_StaticText {
  component_type: 'static_text'
  text_style?: TsInterface_UnspecifiedObject
  text: string
}

interface TsInterface_PdfTemplateComponent_VariableText {
  component_type: 'variable_text'
  text_style?: TsInterface_UnspecifiedObject
  variable_mapping_key: string
  fallback_text?: string
  fallback_text_style?: TsInterface_UnspecifiedObject
}

interface TsInterface_PdfTemplateComponent_View {
  component_type: 'view'
  view_style?: TsInterface_UnspecifiedObject
  contents: {
    [key: string]: TsType_PdfTemplateComponent
  }
}

type TsType_PdfTemplateComponent =
  | TsInterface_PdfTemplateComponent_StaticTable
  | TsInterface_PdfTemplateComponent_LoopedDataTable
  | TsInterface_PdfTemplateComponent_StaticImage
  | TsInterface_PdfTemplateComponent_StaticText
  | TsInterface_PdfTemplateComponent_VariableText
  | TsInterface_PdfTemplateComponent_View

export interface TsInterface_PdfTemplate {
  page_settings: {
    flexDirection: string
    paddingTop: string
    paddingBottom: string
    paddingLeft: string
    paddingRight: string
  }
  page_contents: {
    [key: string]: TsType_PdfTemplateComponent
  }
}

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

const generatePdfComponent_StaticTable = (component: TsInterface_PdfTemplateComponent_StaticTable, pdfData: TsInterface_UnspecifiedObject): JSX.Element => {
  let componentJSX = (
    <View style={getProp(component, 'table_style', {})}>
      {objectToArray(component.rows).map((row: TsInterface_UnspecifiedObject, rowIndex: number) => (
        <View
          key={rowIndex}
          style={getProp(row, 'row_style', {})}
        >
          {objectToArray(row.cells).map((cell: TsInterface_UnspecifiedObject, cellIndex: number) => (
            <View
              key={cellIndex}
              style={getProp(cell, 'cell_style', {})}
            >
              {objectToArray(cell.contents).map((component: TsType_PdfTemplateComponent, componentIndex: number) => (
                <React.Fragment key={componentIndex}>{generatePdfComponent(component, pdfData)}</React.Fragment>
              ))}
            </View>
          ))}
        </View>
      ))}
    </View>
  )
  return componentJSX
}

const generatePdfComponent_LoopedDataTable = (
  component: TsInterface_PdfTemplateComponent_LoopedDataTable,
  pdfData: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let tableData = getProp(pdfData, component.table_data_variable_mapping_key, [])
  let componentJSX = (
    <View style={getProp(component, 'table_style', {})}>
      <View style={getProp(component, 'row_style', {})}>
        {objectToArray(getProp(component, 'columns', {})).map((column: TsInterface_UnspecifiedObject, columnIndex: number) => (
          <View
            style={getProp(column, 'column_header_style', {})}
            key={columnIndex}
          >
            <Text>{column.header_text}</Text>
          </View>
        ))}
      </View>
      {objectToArray(tableData).map((rowData: TsInterface_UnspecifiedObject, rowIndex: number) => (
        <View
          style={getProp(component, 'row_style', {})}
          key={rowIndex}
        >
          {objectToArray(component.columns).map((column: TsInterface_UnspecifiedObject, columnIndex: number) => (
            <View
              style={getProp(column, 'column_body_style', {})}
              key={columnIndex}
            >
              {generatePdfComponent_VariableText(column as TsInterface_PdfTemplateComponent_VariableText, rowData)}
            </View>
          ))}
        </View>
      ))}
    </View>
  )
  return componentJSX
}

const generatePdfComponent_StaticImage = (component: TsInterface_PdfTemplateComponent_StaticImage, pdfData: TsInterface_UnspecifiedObject): JSX.Element => {
  let componentJSX = (
    <Image
      src={component.src}
      style={getProp(component, 'image_style', {})}
    />
  )
  return componentJSX
}

const generatePdfComponent_StaticText = (component: TsInterface_PdfTemplateComponent_StaticText, pdfData: TsInterface_UnspecifiedObject): JSX.Element => {
  let componentJSX = <Text style={getProp(component, 'text_style', {})}>{component.text}</Text>
  return componentJSX
}

const generatePdfComponent_VariableText = (component: TsInterface_PdfTemplateComponent_VariableText, pdfData: TsInterface_UnspecifiedObject): JSX.Element => {
  let componentJSX = <></>
  if (pdfData != null && component.variable_mapping_key != null && pdfData[component.variable_mapping_key] != null) {
    componentJSX = <Text style={getProp(component, 'text_style', {})}>{pdfData[component.variable_mapping_key]}</Text>
  } else if (component.fallback_text != null) {
    componentJSX = <Text style={getProp(component, 'fallback_text_style', getProp(component, 'text_style', {}))}>{component.fallback_text}</Text>
  }
  return componentJSX
}

const generatePdfComponent_View = (component: TsInterface_PdfTemplateComponent_View, pdfData: TsInterface_UnspecifiedObject): JSX.Element => {
  let componentJSX = (
    <View style={getProp(component, 'view_style', {})}>
      {objectToArray(component.contents).map((component: TsType_PdfTemplateComponent, index: number) => (
        <React.Fragment key={index}>{generatePdfComponent(component, pdfData)}</React.Fragment>
      ))}
    </View>
  )
  return componentJSX
}

const generatePdfComponent = (component: TsType_PdfTemplateComponent, pdfData: TsInterface_UnspecifiedObject): JSX.Element => {
  let componentJSX = <></>
  switch (component.component_type) {
    case 'view':
      componentJSX = generatePdfComponent_View(component as TsInterface_PdfTemplateComponent_View, pdfData)
      break
    case 'static_table':
      componentJSX = generatePdfComponent_StaticTable(component as TsInterface_PdfTemplateComponent_StaticTable, pdfData)
      break
    case 'looped_data_table':
      componentJSX = generatePdfComponent_LoopedDataTable(component as TsInterface_PdfTemplateComponent_LoopedDataTable, pdfData)
      break
    case 'static_image':
      componentJSX = generatePdfComponent_StaticImage(component as TsInterface_PdfTemplateComponent_StaticImage, pdfData)
      break
    case 'static_text':
      componentJSX = generatePdfComponent_StaticText(component as TsInterface_PdfTemplateComponent_StaticText, pdfData)
      break
    case 'variable_text':
      componentJSX = generatePdfComponent_VariableText(component as TsInterface_PdfTemplateComponent_VariableText, pdfData)
      break
  }
  return componentJSX
}

///////////////////////////////
// Exports
///////////////////////////////

export const generatePdfFromData = (examplePdfTemplate: TsInterface_PdfTemplate, pdfData: TsInterface_UnspecifiedObject): JSX.Element => {
  let documentJSX = (
    <Document>
      <Page
        size="LETTER"
        style={{}}
      >
        {objectToArray(examplePdfTemplate.page_contents).map((component: TsType_PdfTemplateComponent, index: number) => (
          <React.Fragment key={index}>{generatePdfComponent(component, pdfData)}</React.Fragment>
        ))}
      </Page>
    </Document>
  )
  return documentJSX
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Divider, Stack, Tooltip, Typography } from '@mui/material/'
import Grid2 from '@mui/material/Unstable_Grid2'
import { Document, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer'
import QRCode from 'qrcode'
import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_GoogleSolarData_Document,
  DatabaseRef_SalesOpportunities_Collection,
  DatabaseRef_SalesOpportunityDiscoverySession_Document,
  DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_Document,
  DatabaseRef_SalesOpportunity_DiscoverySession_Query,
  DatabaseRef_SalesOpportunity_Document,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { BasicImportButtonAndDialog } from 'rfbp_core/components/imports/basic_import_button_and_dialog'
import { MapBasic, TsInterface_MapMarkers } from 'rfbp_core/components/map'
import { TableBasic, TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  DatabaseStagedBatchUpdate,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { deleteUndefinedPropertiesFromObject, getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getCoordinatesFromAddress } from 'rfbp_core/services/helper_functions/get_coordinates_from_address'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import {
  tableColumns_CampaignOpportunities,
  tableSettings_CampaignOpportunities,
  tableSettings_CampaignOpportunityGroups,
} from './tables/discovery_session_addresses'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesOpportunityDiscoverySessionViewPage']['key']

// PDF Styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingTop: '0.5in',
    paddingBottom: '0.5in',
    paddingLeft: '0.0in',
    paddingRight: '0.0in',
  },
  label: {
    // width: '2.625in', // Width of each label
    width: '2.625in', // Width of each label
    height: '1in', // Height of each label
    marginVertical: 0, // No margin on top and bottom
    marginHorizontal: '0.1875in', // Margin on left and right
    padding: 0,
    border: '0px solid #FFF', // Border for each label
    borderRadius: 8, // Rounded corners
    justifyContent: 'center',
    fontSize: 10,
    flexDirection: 'row', // Arrange QR code and address side by side
  },
  address: {
    flex: 1,
    padding: 8,
    textAlign: 'left',
  },
  row: {
    flexDirection: 'row',
    marginBottom: '0.0in', // Space between rows
  },
  qrCode: {
    width: '1in',
    height: '1in',
    marginRight: 5,
  },
})

///////////////////////////////
// Functions
///////////////////////////////

const returnMapIcons = (iconKey: string): TsInterface_UnspecifiedObject => {
  let icon: TsInterface_UnspecifiedObject = {}
  switch (iconKey) {
    case 'pin':
      icon = {
        path: 'M25,1C19.486,1 15,5.486 15,11C15,16.514 19.486,21 25,21C30.514,21 35,16.514 35,11C35,5.486 30.514,1 25,1ZM25,5C25.552,5 26,5.447 26,6C26,6.553 25.552,7 25,7C22.794,7 21,8.794 21,11C21,11.553 20.552,12 20,12C19.448,12 19,11.553 19,11C19,7.691 21.691,5 25,5ZM22,22.605L22,43.244L25,48.99L28,43.244L28,22.605C27.039,22.854 26.037,23 25,23C23.963,23 22.961,22.854 22,22.605Z',
        scale: 1,
        anchor: new google.maps.Point(20, 48),
      }
      break
    case 'circle':
      icon = {
        path: 'M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z',
        scale: 0.6,
        anchor: new google.maps.Point(14, 14),
      }
      break
    default:
      icon = {
        path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
        scale: 0.05,
        anchor: new google.maps.Point(400, 800),
      }
  }
  return icon
}

function formatTime(minutes: number) {
  const hours = Math.floor(minutes / 60)
  const remainingMinutes = minutes % 60
  let result = ''

  if (hours > 0) {
    result += `${hours} hr${hours > 1 ? 's' : ''}`
  }

  if (remainingMinutes > 0) {
    if (result) {
      result += ' '
    }
    result += `${remainingMinutes.toFixed(0)} min${remainingMinutes > 1 ? 's' : ''}`
  }

  return result || '0 mins'
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_blueGroupAddresses, us_setBlueGroupAddresses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_blueOptimizedRoutes, us_setBlueOptimizedRoutes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_calculatedRouteAddresses, us_setCalculatedRouteAddresses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_calculatedRoutes, us_setCalculatedRoutes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_discoverySession, us_setDiscoverySession] = useState<TsInterface_UnspecifiedObject>({})
  const [us_greenGroupAddresses, us_setGreenGroupAddresses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_greenOptimizedRoutes, us_setGreenOptimizedRoutes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_mapPins, us_setMapPins] = useState<TsInterface_MapMarkers>({})
  const [us_processingData, us_setProcessingData] = useState<boolean>(false)
  const [us_runningRouteOptimization, us_setRunningRouteOptimization] = useState<boolean>(false)
  const [us_yellowGroupAddresses, us_setYellowGroupAddresses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_yellowOptimizedRoutes, us_setYellowOptimizedRoutes] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const ur_mapRef = useRef(null)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Sales Campaigns', false) as string
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDiscoverySession(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SalesOpportunityDiscoverySession_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setBlueOptimizedRoutes(newData)
    }
    if (us_discoverySession != null && us_discoverySession['route_optimization_calculated_blue_key']) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_Document(
              res_GCK.clientKey,
              itemKey,
              us_discoverySession['route_optimization_calculated_blue_key'],
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay, us_discoverySession])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setGreenOptimizedRoutes(newData)
    }
    if (us_discoverySession != null && us_discoverySession['route_optimization_calculated_green_key']) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_Document(
              res_GCK.clientKey,
              itemKey,
              us_discoverySession['route_optimization_calculated_green_key'],
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay, us_discoverySession])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setYellowOptimizedRoutes(newData)
    }
    if (us_discoverySession != null && us_discoverySession['route_optimization_calculated_yellow_key']) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_Document(
              res_GCK.clientKey,
              itemKey,
              us_discoverySession['route_optimization_calculated_yellow_key'],
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay, us_discoverySession])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setBlueGroupAddresses(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
          { prop: 'associated_discovery_session_key', comparator: '==', value: itemKey },
          { prop: 'associated_campaign_group_color', comparator: '==', value: 'blue' },
        ]
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_SalesOpportunity_DiscoverySession_Query(res_GCK.clientKey, queryOperatorsArray, null),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setGreenGroupAddresses(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
          { prop: 'associated_discovery_session_key', comparator: '==', value: itemKey },
          { prop: 'associated_campaign_group_color', comparator: '==', value: 'green' },
        ]
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_SalesOpportunity_DiscoverySession_Query(res_GCK.clientKey, queryOperatorsArray, null),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setYellowGroupAddresses(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
          { prop: 'associated_discovery_session_key', comparator: '==', value: itemKey },
          { prop: 'associated_campaign_group_color', comparator: '==', value: 'yellow' },
        ]
        unsubscribeLiveData = DatabaseGetLiveCollection(
          DatabaseRef_SalesOpportunity_DiscoverySession_Query(res_GCK.clientKey, queryOperatorsArray, null),
          updateLiveData,
        )
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay])

  useEffect(() => {
    let mapPins: TsInterface_MapMarkers = {}
    let strokeMultiplier = 2
    let scaleMultiplier = 2.5
    // Red
    if (
      getProp(us_discoverySession, 'route_optimization_start_latitude', null) != null &&
      getProp(us_discoverySession, 'route_optimization_start_longitude', null) != null
    ) {
      mapPins['ORIGIN'] = {
        label: '',
        icon: {
          path: returnMapIcons('circle')['path'],
          fillColor: themeVariables.error_main,
          fillOpacity: 1,
          strokeWeight: 0.5 * strokeMultiplier,
          strokeColor: 'white',
          rotation: 0,
          scale: returnMapIcons('circle')['scale'] * scaleMultiplier,
          anchor: returnMapIcons('circle')['anchor'],
        },
        position: {
          lat: parseFloat(getProp(us_discoverySession, 'route_optimization_start_latitude', null)),
          lng: parseFloat(getProp(us_discoverySession, 'route_optimization_start_longitude', null)),
        },
      }
    }

    // Blue
    for (let loopAddressKey in us_blueGroupAddresses) {
      let loopAddress = us_blueGroupAddresses[loopAddressKey]
      if (loopAddress.latitude != null && loopAddress.longitude != null) {
        mapPins[loopAddressKey] = {
          label: '',
          icon: {
            path: returnMapIcons('circle')['path'],
            fillColor: themeVariables.info_main,
            fillOpacity: 1,
            strokeWeight: 0.5 * strokeMultiplier,
            strokeColor: 'white',
            rotation: 0,
            scale: returnMapIcons('circle')['scale'] * scaleMultiplier,
            anchor: returnMapIcons('circle')['anchor'],
          },
          position: {
            lat: parseFloat(loopAddress.latitude),
            lng: parseFloat(loopAddress.longitude),
          },
        }
      }
    }
    // Green
    for (let loopAddressKey in us_greenGroupAddresses) {
      let loopAddress = us_greenGroupAddresses[loopAddressKey]
      if (loopAddress.latitude != null && loopAddress.longitude != null) {
        mapPins[loopAddressKey] = {
          label: '',
          icon: {
            path: returnMapIcons('circle')['path'],
            fillColor: themeVariables.success_main,
            fillOpacity: 1,
            strokeWeight: 0.5 * strokeMultiplier,
            strokeColor: 'white',
            rotation: 0,
            scale: returnMapIcons('circle')['scale'] * scaleMultiplier,
            anchor: returnMapIcons('circle')['anchor'],
          },
          position: {
            lat: parseFloat(loopAddress.latitude),
            lng: parseFloat(loopAddress.longitude),
          },
        }
      }
    }
    // Yellow
    for (let loopAddressKey in us_yellowGroupAddresses) {
      let loopAddress = us_yellowGroupAddresses[loopAddressKey]
      if (loopAddress.latitude != null && loopAddress.longitude != null) {
        mapPins[loopAddressKey] = {
          label: '',
          icon: {
            path: returnMapIcons('circle')['path'],
            fillColor: themeVariables.warning_main,
            fillOpacity: 1,
            strokeWeight: 0.5 * strokeMultiplier,
            strokeColor: 'white',
            rotation: 0,
            scale: returnMapIcons('circle')['scale'] * scaleMultiplier,
            anchor: returnMapIcons('circle')['anchor'],
          },
          position: {
            lat: parseFloat(loopAddress.latitude),
            lng: parseFloat(loopAddress.longitude),
          },
        }
      }
    }
    us_setMapPins(mapPins)
    setTimeout(() => {
      // @ts-expect-error - TODO: reason for error
      if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.mapCenter != null) {
        // @ts-expect-error - TODO: reason for error
        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
          // @ts-expect-error - TODO: reason for error
          ur_mapRef.current.recalculateMapBounds(true)
        }
      }
    }, 0)
  }, [us_blueGroupAddresses, us_discoverySession, us_greenGroupAddresses, us_yellowGroupAddresses])

  useEffect(() => {
    let calculatedRoutes: TsInterface_UnspecifiedObject = {}
    if (
      us_discoverySession != null &&
      us_discoverySession.route_optimization_calculated_blue_key != null &&
      us_discoverySession.route_optimization_calculated_blue_routes != null &&
      us_discoverySession.route_optimization_calculated_blue_routes > 0
    ) {
      let optimizationData = us_blueOptimizedRoutes
      for (let loopIndex = 1; loopIndex <= us_discoverySession.route_optimization_calculated_blue_routes; loopIndex++) {
        let visitCount = 0
        let totalTime = 0
        if (optimizationData != null && optimizationData['routes'] != null && optimizationData['routes'][loopIndex.toString()] != null) {
          visitCount = optimizationData['routes'][loopIndex.toString()]['visits'].length
          totalTime = optimizationData['routes'][loopIndex.toString()]['total_time'] / 60
        }
        calculatedRoutes['blue_' + loopIndex.toString()] = {
          key: 'blue_' + loopIndex.toString(),
          color_key: 'blue',
          associated_route_optimization_key: us_discoverySession.route_optimization_calculated_blue_key,
          associated_campaign_key: itemKey,
          name: 'Blue ' + loopIndex.toString(),
          color: themeVariables.info_main,
          color_dark: themeVariables.info_dark,
          address_count: visitCount,
          duration: totalTime,
        }
      }
    }
    // Green Routes
    if (
      us_discoverySession != null &&
      us_discoverySession.route_optimization_calculated_green_key != null &&
      us_discoverySession.route_optimization_calculated_green_routes != null &&
      us_discoverySession.route_optimization_calculated_green_routes > 0
    ) {
      let optimizationData = us_greenOptimizedRoutes
      for (let loopIndex = 1; loopIndex <= us_discoverySession.route_optimization_calculated_green_routes; loopIndex++) {
        let visitCount = 0
        let totalTime = 0
        if (optimizationData != null && optimizationData['routes'] != null && optimizationData['routes'][loopIndex.toString()] != null) {
          visitCount = optimizationData['routes'][loopIndex.toString()]['visits'].length
          totalTime = optimizationData['routes'][loopIndex.toString()]['total_time'] / 60
        }
        calculatedRoutes['green_' + loopIndex.toString()] = {
          key: 'green_' + loopIndex.toString(),
          color_key: 'green',
          associated_route_optimization_key: us_discoverySession.route_optimization_calculated_green_key,
          associated_campaign_key: itemKey,
          name: 'Green ' + loopIndex.toString(),
          color: themeVariables.success_main,
          color_dark: themeVariables.success_dark,
          address_count: visitCount,
          duration: totalTime,
        }
      }
    }
    // Yellow Routes
    if (
      us_discoverySession != null &&
      us_discoverySession.route_optimization_calculated_yellow_key != null &&
      us_discoverySession.route_optimization_calculated_yellow_routes != null &&
      us_discoverySession.route_optimization_calculated_yellow_routes > 0
    ) {
      let optimizationData = us_yellowOptimizedRoutes
      for (let loopIndex = 1; loopIndex <= us_discoverySession.route_optimization_calculated_yellow_routes; loopIndex++) {
        let visitCount = 0
        let totalTime = 0
        if (optimizationData != null && optimizationData['routes'] != null && optimizationData['routes'][loopIndex.toString()] != null) {
          visitCount = optimizationData['routes'][loopIndex.toString()]['visits'].length
          totalTime = optimizationData['routes'][loopIndex.toString()]['total_time'] / 60
        }
        calculatedRoutes['yellow_' + loopIndex.toString()] = {
          key: 'yellow_' + loopIndex.toString(),
          color_key: 'yellow',
          associated_route_optimization_key: us_discoverySession.route_optimization_calculated_yellow_key,
          associated_campaign_key: itemKey,
          name: 'Yellow ' + loopIndex.toString(),
          color: themeVariables.warning_main,
          color_dark: themeVariables.warning_dark,
          address_count: visitCount,
          duration: totalTime,
        }
      }
    }
    // Generate Addresses For PDFs
    let routeAddresses: TsInterface_UnspecifiedObject = {}
    for (let loopRouteKey in calculatedRoutes) {
      let route = calculatedRoutes[loopRouteKey]
      //
      let addresses: TsInterface_UnspecifiedObject[] = []
      if (route != null && route.color_key != null && route.key != null) {
        let routeKey = route.key
        // Gte Number After underscore to get route number
        let routeNumber = routeKey.split('_')[1]
        let routeData: TsInterface_UnspecifiedObject = {}
        let optimizationData: TsInterface_UnspecifiedObject = {}
        let addressData: TsInterface_UnspecifiedObject = {}
        switch (route.color_key) {
          case 'blue':
            optimizationData = us_blueOptimizedRoutes
            addressData = us_blueGroupAddresses
            break
          case 'green':
            optimizationData = us_greenOptimizedRoutes
            addressData = us_greenGroupAddresses
            break
          case 'yellow':
            optimizationData = us_yellowOptimizedRoutes
            addressData = us_yellowGroupAddresses
            break
        }
        if (
          routeNumber != null &&
          routeNumber != '' &&
          optimizationData != null &&
          optimizationData['routes'] != null &&
          optimizationData['routes'][routeNumber] != null &&
          optimizationData['routes'][routeNumber]['visits'] != null
        ) {
          routeData = optimizationData['routes'][routeNumber]
        }
        for (let loopVisitKey in getProp(routeData, 'visits', [])) {
          let loopVisit = routeData.visits[loopVisitKey]
          if (loopVisit != null && loopVisit['visit_key'] != null && addressData != null && addressData[loopVisit['visit_key']] != null) {
            let loopAddress = addressData[loopVisit['visit_key']]
            addresses.push({
              address: getProp(loopAddress, 'address1', ''),
              city: getProp(loopAddress, 'city', ''),
              state: getProp(loopAddress, 'state', ''),
              zip: getProp(loopAddress, 'postal_code', ''),
              url: 'https://www.etwenergy.com/go_solar/' + loopAddress['key'],
            })
          } else {
            addresses.push({
              address: 'MISSING',
              city: 'MISSING',
              state: 'MISSING',
              zip: 'MISSING',
              url: 'https://www.etwenergy.com',
            })
          }
        }
      }
      // If the addresses array is not divisible by 3, add empty objects to the end to make it divisible by 3
      while (addresses.length % 3 !== 0) {
        addresses.push({
          address: 'PLACEHOLDER',
          city: 'PLACEHOLDER',
          state: 'PLACEHOLDER',
          zip: 'PLACEHOLDER',
          url: 'https://www.etwenergy.com',
        })
      }
      routeAddresses[route.key] = addresses
    }

    us_setCalculatedRoutes(calculatedRoutes)
    us_setCalculatedRouteAddresses(routeAddresses)
    ur_forceRerender()
  }, [
    itemKey,
    us_blueGroupAddresses,
    us_blueOptimizedRoutes,
    us_discoverySession,
    us_greenGroupAddresses,
    us_greenOptimizedRoutes,
    us_yellowGroupAddresses,
    us_yellowOptimizedRoutes,
    ur_forceRerender,
  ])

  // Functions
  const tableDatabaseEndpoint_CampaignOpportunities = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_discovery_session_key', comparator: '==', value: itemKey }]
    let orderByArray: TsInterface_OrderByArray = []
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_SalesOpportunities_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const geocodeOpportunities = (runNumber: number, successCount: number, failureCount: number) => {
    const timeoutTime = 100
    let currentGeocodeSessionNumber = 1
    const currentSalesDiscoverySessionKey = itemKey
    if (us_processingData === false) {
      us_setProcessingData(true)
    }
    // Iterate as long as retry is true
    geocodeOpportunitiesProper(currentGeocodeSessionNumber, currentSalesDiscoverySessionKey)
      .then((res_TGOP: any) => {
        console.log('===============================')
        console.log(res_TGOP)
        console.log('runNumber: ' + runNumber + ' | successCount: ' + successCount + ' | failureCount: ' + failureCount)
        let newSuccessCount = successCount
        if (res_TGOP.success === true) {
          newSuccessCount = successCount + 1
        }
        let newFailureCount = failureCount
        if (res_TGOP.success === false) {
          newFailureCount = failureCount + 1
        }
        if (res_TGOP.retry === true) {
          setTimeout(() => {
            geocodeOpportunities(runNumber + 1, newSuccessCount, newFailureCount)
          }, timeoutTime)
        } else {
          console.log('===============================')
          console.log('===============================')
          console.log('===============================')
          console.log('GeoCoding Process Complete')
          us_setProcessingData(false)
        }
      })
      .catch((rej_TGOP: any) => {
        console.error('FAILED OUT')
      })
  }

  const geocodeOpportunitiesProper = (currentGeocodeSessionNumber: number, currentSalesDiscoverySessionKey: string) => {
    return new Promise((resolve, reject) => {
      // Get Client Key
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
            { prop: 'associated_discovery_session_key', comparator: '==', value: currentSalesDiscoverySessionKey },
            { prop: 'associated_discovery_session_geocode_data_queue_number', comparator: '==', value: currentGeocodeSessionNumber },
          ]
          DatabaseGetCollection(DatabaseRef_SalesOpportunity_DiscoverySession_Query(res_GCK.clientKey, queryOperatorsArray, 1))
            .then((res_DGC) => {
              if (res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                let singleDataItem = objectToArray(res_DGC.data)[0]
                console.log(singleDataItem.name)
                let combinedAddress = singleDataItem.address1 + ' ' + singleDataItem.city + ', ' + singleDataItem.state + ' ' + singleDataItem.postal_code
                getCoordinatesFromAddress(combinedAddress)
                  .then((res_GCF: any) => {
                    let updateObject = {
                      associated_discovery_session_geocode_data_generated: true,
                      associated_discovery_session_geocode_data_queue_number: 0,
                      latitude: res_GCF.coordinates.latitude,
                      longitude: res_GCF.coordinates.longitude,
                    }
                    DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, singleDataItem.key), updateObject)
                      .then((res_DSMD) => {
                        resolve({ success: true, retry: true, path: 1 })
                      })
                      .catch((rej_DSMD) => {
                        resolve({ success: false, retry: false, path: 2 })
                      })
                  })
                  .catch((rej_GCF) => {
                    let updateObject = {
                      associated_discovery_session_geocode_data_queue_number: currentGeocodeSessionNumber + 1,
                    }
                    DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, singleDataItem.key), updateObject)
                      .then((res_DSMD) => {
                        resolve({ success: false, retry: true, path: 3 })
                      })
                      .catch((rej_DSMD) => {
                        resolve({ success: false, retry: false, path: 4 })
                      })
                  })
              } else {
                resolve({ success: false, retry: false, path: 5 })
              }
            })
            .catch((rej_DGC) => {
              resolve({ success: false, retry: false, path: 6 })
            })
        })
        .catch((rej_GCK) => {
          resolve({ success: false, retry: false, path: 7 })
        })
    })
  }

  const getSolarDataForOpportunity = (runNumber: number, successCount: number, failureCount: number) => {
    const timeoutTime = 500
    let currentGeocodeSessionNumber = 1
    const currentSalesDiscoverySessionKey = itemKey
    if (us_processingData === false) {
      us_setProcessingData(true)
    }
    // Iterate as long as retry is true
    getSolarDataForOpportunityProper(currentGeocodeSessionNumber, currentSalesDiscoverySessionKey)
      .then((res_TGSDFOP: any) => {
        console.log('===============================')
        console.log(res_TGSDFOP)
        console.log('runNumber: ' + runNumber + ' | successCount: ' + successCount + ' | failureCount: ' + failureCount)
        let newSuccessCount = successCount
        if (res_TGSDFOP.success === true) {
          newSuccessCount = successCount + 1
        }
        let newFailureCount = failureCount
        if (res_TGSDFOP.success === false) {
          newFailureCount = failureCount + 1
        }
        if (res_TGSDFOP.retry === true) {
          setTimeout(() => {
            getSolarDataForOpportunity(runNumber + 1, newSuccessCount, newFailureCount)
          }, timeoutTime)
        } else {
          console.log('===============================')
          console.log('===============================')
          console.log('===============================')
          console.log('GeoCoding Process Complete')
          us_setProcessingData(false)
        }
      })
      .catch((rej_TGSDFOP: any) => {
        console.error('FAILED OUT')
      })
  }

  const getSolarDataForOpportunityProper = (currentGeocodeSessionNumber: number, currentSalesDiscoverySessionKey: string) => {
    const panelCount = 15
    return new Promise((resolve, reject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
            { prop: 'associated_discovery_session_key', comparator: '==', value: currentSalesDiscoverySessionKey },
            { prop: 'associated_discovery_session_google_data_queue_number', comparator: '==', value: currentGeocodeSessionNumber },
          ]
          DatabaseGetCollection(DatabaseRef_SalesOpportunity_DiscoverySession_Query(res_GCK.clientKey, queryOperatorsArray, 1))
            .then((res_DGC) => {
              if (res_DGC.data != null && objectToArray(res_DGC.data).length > 0) {
                let singleDataItem = objectToArray(res_DGC.data)[0]
                console.log(singleDataItem.name)
                console.log(singleDataItem.key)
                cloudFunctionManageRequest('manageData', {
                  function: 'run_google_solar_request_for_coordinates',
                  latitude: singleDataItem.latitude,
                  longitude: singleDataItem.longitude,
                })
                  .then((res_CFMR) => {
                    console.log(res_CFMR)
                    let googleSolarUpdateObject = getProp(res_CFMR, 'data', {})
                    let updateObject: TsInterface_UnspecifiedObject = {
                      associated_discovery_session_google_data_generated: true,
                      associated_discovery_session_google_data_queue_number: 0,
                    }
                    let matchProductionPanels: number | null = null
                    let matchProductionKwh: number | null = null
                    // If the solarPanels length array is greater than 100, truncate it to 100
                    if (
                      googleSolarUpdateObject != null &&
                      googleSolarUpdateObject['solarPotential'] != null &&
                      googleSolarUpdateObject['solarPotential']['solarPanels'] != null &&
                      googleSolarUpdateObject['solarPotential']['solarPanels'].length > 100
                    ) {
                      googleSolarUpdateObject['solarPotential']['solarPanels'] = googleSolarUpdateObject['solarPotential']['solarPanels'].slice(0, 100)
                      googleSolarUpdateObject['NOTE_SOLAR_PANEL'] = 'removed solar panels since too large for firebase'
                    }
                    if (
                      googleSolarUpdateObject != null &&
                      googleSolarUpdateObject['solarPotential'] != null &&
                      googleSolarUpdateObject['solarPotential']['solarPanelConfigs'] != null &&
                      googleSolarUpdateObject['solarPotential']['solarPanelConfigs'].length > 100
                    ) {
                      googleSolarUpdateObject['solarPotential']['solarPanelConfigs'] = googleSolarUpdateObject['solarPotential']['solarPanelConfigs'].slice(
                        0,
                        100,
                      )
                      googleSolarUpdateObject['NOTE_SOLAR_PANEL_CONFIG'] = 'removed solar panel configs since too large for firebase'
                    }
                    if (
                      googleSolarUpdateObject != null &&
                      googleSolarUpdateObject['solarPotential'] != null &&
                      googleSolarUpdateObject['solarPotential']['solarPanelConfigs'] != null
                    ) {
                      // Loop through and find the config that matches the specified number of panels
                      for (let loopConfigIndex in googleSolarUpdateObject['solarPotential']['solarPanelConfigs']) {
                        let loopConfig = googleSolarUpdateObject['solarPotential']['solarPanelConfigs'][loopConfigIndex]
                        if (loopConfig != null && loopConfig.panelsCount === panelCount && loopConfig.yearlyEnergyDcKwh != null) {
                          matchProductionPanels = loopConfig.panelsCount
                          matchProductionKwh = loopConfig.yearlyEnergyDcKwh
                        }
                      }
                    }
                    if (matchProductionKwh != null) {
                      // updateObject['google_solar_max_production_kwh_for_' + panelCount.toString() + '_panels'] = matchProductionKwh
                      updateObject['google_solar_max_production_kwh_for_15_panels'] = matchProductionKwh
                    }
                    updateObject['google_solar_max_panels'] = googleSolarUpdateObject['solarPotential']['maxArrayPanelsCount']
                    // 15 panels, 405 watt panels, 6.05 KW System

                    console.log('matchProductionPanels: ' + matchProductionPanels)
                    console.log('matchProductionKwh: ' + matchProductionKwh)

                    let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, singleDataItem.key),
                        data: updateObject,
                      },
                      {
                        type: 'setMerge',
                        ref: DatabaseRef_GoogleSolarData_Document(res_GCK.clientKey, singleDataItem.key),
                        data: deleteUndefinedPropertiesFromObject(googleSolarUpdateObject),
                      },
                    ]
                    console.log(updateArray)
                    DatabaseStagedBatchUpdate(updateArray)
                      .then((res_DBU) => {
                        resolve({ success: true, retry: true, path: 1 })
                      })
                      .catch((rej_DBU) => {
                        console.error(rej_DBU)
                        resolve({ success: false, retry: false, path: 2 })
                      })
                  })
                  .catch((rej_CFMR) => {
                    let updateObject = {
                      associated_discovery_session_google_data_queue_number: currentGeocodeSessionNumber + 1,
                    }
                    DatabaseSetMergeDocument(DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, singleDataItem.key), updateObject)
                      .then((res_DSMD) => {
                        resolve({ success: false, retry: true, path: 3 })
                      })
                      .catch((rej_DSMD) => {
                        resolve({ success: false, retry: false, path: 4 })
                      })
                  })
              } else {
                resolve({ success: false, retry: false, path: 5 })
              }
            })
            .catch((rej_DGC) => {
              resolve({ success: false, retry: false, path: 6 })
            })
        })
        .catch((rej_GCK) => {
          resolve({ success: false, retry: false, path: 7 })
        })
    })
  }

  const updateAddressFilterSettings = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_discoverySession,
          formInputs: {
            group_cutoff_blue: {
              data_type: 'string',
              input_type: 'text_number',
              key: 'group_cutoff_blue',
              label: (
                <>
                  <Icon
                    icon="circle"
                    className="tw-mr-2"
                    sx={{ color: themeVariables.info_main }}
                  />
                  {rLIB('Blue Cutoff')}
                </>
              ),
              required: true,
            },
            group_cutoff_green: {
              data_type: 'string',
              input_type: 'text_number',
              key: 'group_cutoff_green',
              label: (
                <>
                  <Icon
                    icon="circle"
                    className="tw-mr-2"
                    sx={{ color: themeVariables.success_main }}
                  />
                  {rLIB('Green Cutoff')}
                </>
              ),
              required: true,
            },
            group_cutoff_yellow: {
              data_type: 'string',
              input_type: 'text_number',
              key: 'group_cutoff_yellow',
              label: (
                <>
                  <Icon
                    icon="circle"
                    className="tw-mr-2"
                    sx={{ color: themeVariables.warning_main }}
                  />
                  {rLIB('Yellow Cutoff')}
                </>
              ),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    group_cutoff_blue: formSubmittedData.group_cutoff_blue,
                    group_cutoff_green: formSubmittedData.group_cutoff_green,
                    group_cutoff_yellow: formSubmittedData.group_cutoff_yellow,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_SalesOpportunityDiscoverySession_Document(res_GCK.clientKey as string, itemKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                      // Recalculate
                      filterAddressesByProduction(
                        formSubmittedData.group_cutoff_blue,
                        formSubmittedData.group_cutoff_green,
                        formSubmittedData.group_cutoff_yellow,
                      )
                    })
                    .catch((rej_DSMD) => {
                      console.error(rej_DSMD)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Group Cutoffs')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const filterAddressesByProduction = (blueCutoffAmount: number, greenCutoffAmount: number, yellowCutoffAmount: number) => {
    let currentSalesDiscoverySessionKey = itemKey
    us_setProcessingData(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
          { prop: 'associated_discovery_session_key', comparator: '==', value: currentSalesDiscoverySessionKey },
        ]
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        let totalCount = 0
        DatabaseGetCollection(DatabaseRef_SalesOpportunity_DiscoverySession_Query(res_GCK.clientKey, queryOperatorsArray, null))
          .then((res_DGC) => {
            console.log(objectToArray(res_DGC.data).length)
            console.log(res_DGC.data)
            // Loop through and see if the max_panels
            for (let loopDataItemKey in res_DGC.data) {
              let loopDataItem = res_DGC.data[loopDataItemKey]
              totalCount++
              if (
                loopDataItem != null &&
                loopDataItem['google_solar_max_production_kwh_for_15_panels'] != null &&
                loopDataItem['google_solar_max_production_kwh_for_15_panels'] >= blueCutoffAmount
              ) {
                if (getProp(loopDataItem, 'associated_campaign_group_color', null) !== 'blue') {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, loopDataItemKey),
                    data: {
                      associated_campaign_group_color: 'blue',
                    },
                  })
                }
              } else if (
                loopDataItem != null &&
                loopDataItem['google_solar_max_production_kwh_for_15_panels'] != null &&
                loopDataItem['google_solar_max_production_kwh_for_15_panels'] >= greenCutoffAmount
              ) {
                if (getProp(loopDataItem, 'associated_campaign_group_color', null) !== 'green') {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, loopDataItemKey),
                    data: {
                      associated_campaign_group_color: 'green',
                    },
                  })
                }
              } else if (
                loopDataItem != null &&
                loopDataItem['google_solar_max_production_kwh_for_15_panels'] != null &&
                loopDataItem['google_solar_max_production_kwh_for_15_panels'] >= yellowCutoffAmount
              ) {
                if (getProp(loopDataItem, 'associated_campaign_group_color', null) !== 'yellow') {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, loopDataItemKey),
                    data: {
                      associated_campaign_group_color: 'yellow',
                    },
                  })
                }
              } else {
                if (getProp(loopDataItem, 'associated_campaign_group_color', null) !== null) {
                  updateArray.push({
                    type: 'setMerge',
                    ref: DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, loopDataItemKey),
                    data: {
                      associated_campaign_group_color: null,
                    },
                  })
                }
              }
            }
            updateArray.push({
              type: 'setMerge',
              ref: DatabaseRef_SalesOpportunityDiscoverySession_Document(res_GCK.clientKey, itemKey),
              data: {
                total_address_count: totalCount,
              },
            })
            DatabaseStagedBatchUpdate(updateArray)
              .then((res_DBU) => {
                console.log('DONE')
                us_setProcessingData(false)
              })
              .catch((rej_DBU) => {
                console.error(rej_DBU)
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
                us_setProcessingData(false)
              })
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
            us_setProcessingData(false)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        us_setProcessingData(false)
      })
  }

  const updateRouteOptimizationSettings = () => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_discoverySession,
          formInputs: {
            route_optimization_start_latitude: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'route_optimization_start_latitude',
              label: rLIB('Start Latitude'),
              required: true,
            },
            route_optimization_start_longitude: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'route_optimization_start_longitude',
              label: rLIB('Start Longitude'),
              required: true,
            },
            route_optimization_blue_routes: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'route_optimization_blue_routes',
              label: rLIB('Max Number of Blue Routes'),
              required: true,
            },
            route_optimization_blue_hours: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'route_optimization_blue_hours',
              label: rLIB('Hours per Blue Routes'),
              required: true,
            },
            route_optimization_green_routes: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'route_optimization_green_routes',
              label: rLIB('Max Number of Green Routes'),
              required: true,
            },
            route_optimization_green_hours: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'route_optimization_green_hours',
              label: rLIB('Hours per Green Routes'),
              required: true,
            },
            route_optimization_yellow_routes: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'route_optimization_yellow_routes',
              label: rLIB('Max Number of Yellow Routes'),
              required: true,
            },
            route_optimization_yellow_hours: {
              data_type: 'number',
              input_type: 'text_number',
              key: 'route_optimization_yellow_hours',
              label: rLIB('Hours per Yellow Routes'),
              required: true,
            },
          },
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    route_optimization_start_latitude: formSubmittedData.route_optimization_start_latitude,
                    route_optimization_start_longitude: formSubmittedData.route_optimization_start_longitude,
                    route_optimization_blue_routes: formSubmittedData.route_optimization_blue_routes,
                    route_optimization_blue_hours: formSubmittedData.route_optimization_blue_hours,
                    route_optimization_green_routes: formSubmittedData.route_optimization_green_routes,
                    route_optimization_green_hours: formSubmittedData.route_optimization_green_hours,
                    route_optimization_yellow_routes: formSubmittedData.route_optimization_yellow_routes,
                    route_optimization_yellow_hours: formSubmittedData.route_optimization_yellow_hours,
                  }
                  DatabaseSetMergeDocument(DatabaseRef_SalesOpportunityDiscoverySession_Document(res_GCK.clientKey as string, itemKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      console.error(rej_DSMD)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                      reject(rej_DSMD)
                    })
                })
                .catch((rej_GCK) => {
                  console.error(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{rLIB('Edit Group Cutoffs')}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const runRouteOptimization = (startLatitude: number, startLongitude: number, color: string, maxRouteCount: number, maxRouteHours: number) => {
    // Get Dates
    let currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    let startTimeString = currentDate.toISOString()
    let endTimeString = new Date(currentDate.getTime() + maxRouteHours * 60 * 60 * 1000).toISOString()
    // Get Vehicles
    let vehicles: TsInterface_UnspecifiedObject[] = []
    for (let loopIndex = 0; loopIndex < maxRouteCount; loopIndex++) {
      vehicles.push({
        startLatitude: startLatitude,
        startLongitude: startLongitude,
        endLatitude: startLatitude,
        endLongitude: startLongitude,
        capacity: 10000,
        key: color + ' ' + (loopIndex + 1).toString(),
      })
    }
    // Get Deliveries
    let deliveries: TsInterface_UnspecifiedObject[] = []
    switch (color) {
      case 'blue':
        for (let loopAddressKey in us_blueGroupAddresses) {
          let loopAddress = us_blueGroupAddresses[loopAddressKey]
          if (loopAddress.latitude != null && loopAddress.longitude != null) {
            deliveries.push({
              latitude: parseFloat(loopAddress.latitude),
              longitude: parseFloat(loopAddress.longitude),
              key: loopAddressKey,
            })
          }
        }
        break
      case 'green':
        for (let loopAddressKey in us_greenGroupAddresses) {
          let loopAddress = us_greenGroupAddresses[loopAddressKey]
          if (loopAddress.latitude != null && loopAddress.longitude != null) {
            deliveries.push({
              latitude: parseFloat(loopAddress.latitude),
              longitude: parseFloat(loopAddress.longitude),
              key: loopAddressKey,
            })
          }
        }
        break
      case 'yellow':
        for (let loopAddressKey in us_yellowGroupAddresses) {
          let loopAddress = us_yellowGroupAddresses[loopAddressKey]
          if (loopAddress.latitude != null && loopAddress.longitude != null) {
            deliveries.push({
              latitude: parseFloat(loopAddress.latitude),
              longitude: parseFloat(loopAddress.longitude),
              key: loopAddressKey,
            })
          }
        }
        break
    }
    // Make Request
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let requestObject = {
          function: 'getGoogleRouteOptimization',
          client_key: res_GCK.clientKey,
          campaign_key: itemKey,
          group_color: color,
          start_time: startTimeString,
          end_time: endTimeString,
          deliveries: deliveries,
          vehicles: vehicles,
          seconds_per_stop: 60,
        }
        us_setRunningRouteOptimization(true)
        cloudFunctionManageRequest('manageIntegrations', requestObject)
          .then((res_CFMIR: any) => {
            console.log(res_CFMIR)
            us_setRunningRouteOptimization(false)
          })
          .catch((rej_CFMIR) => {
            console.error(rej_CFMIR)
            us_setRunningRouteOptimization(false)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })

    // UPDATE route_optimization_routes on discovery session with keys (update to wipe old data)
    // Save to subcollection all of the route information - enough to generate route list and PDF so need keys and stuff usable
    // How to handle if a route can't handle all of the addresses?  Need to show if a route is overfilled and needs to be split
  }

  // JSX Generation
  const rJSX_BackButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="inherit"
          variant="outlined"
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminSalesToolsIndexPage.url() + '?tab=opportunity_finder')
          }}
          disableElevation
          startIcon={<Icon icon="chevron-left" />}
          className="tw-mr-2"
        >
          {rLIB('Back to all Orders')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Back to all Orders')}
          placement="top"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminSalesToolsIndexPage.url() + '?tab=opportunity_finder')
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="chevron-left" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_GeocodeAddressesButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="info"
        variant="contained"
        startIcon={
          us_processingData ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="calculator-simple" />
          )
        }
        className="tw-mr-2"
        disabled={us_processingData || us_discoverySession.locked === true}
        onClick={() => {
          geocodeOpportunities(1, 0, 0)
        }}
      >
        {rLIB('Get Coordinates')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_RunSolarButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="info"
        variant="contained"
        startIcon={
          us_processingData ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="calculator-simple" />
          )
        }
        className="tw-mr-2"
        disabled={us_processingData || us_discoverySession.locked === true}
        onClick={() => {
          getSolarDataForOpportunity(1, 0, 0)
        }}
      >
        {rLIB('Get Solar Data')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AddressesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Card>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{}}
              tableColumns={tableColumns_CampaignOpportunities}
              tableDatabaseEndpoint={tableDatabaseEndpoint_CampaignOpportunities}
              tableSettings={tableSettings_CampaignOpportunities}
            />
          </Card>
        </Card>
      )
    }
    return tabJSX
  }

  const rJSX_BlueGroupAddressTab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Card>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_CampaignOpportunities}
            tableData={objectToArray(us_blueGroupAddresses)}
            tableSettings={tableSettings_CampaignOpportunityGroups}
          />
        </Card>
        <Typography
          variant="body1"
          className="tw-inline-block tw-align-top"
        >
          <Box
            component={'span'}
            className="tw-opacity-30"
          >
            {'>'} {getProp(us_discoverySession, 'group_cutoff_blue', 0)} {rLIB('Annual kWh')} {rLIB('for', false) + ' 15 ' + rLIB('panels', false)}
          </Box>
        </Typography>
      </Box>
    )
    return tabJSX
  }

  const rJSX_GreenGroupAddressTab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Card>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_CampaignOpportunities}
            tableData={objectToArray(us_greenGroupAddresses)}
            tableSettings={tableSettings_CampaignOpportunityGroups}
          />
        </Card>
        <Typography
          variant="body1"
          className="tw-inline-block tw-align-top"
        >
          <Box
            component={'span'}
            className="tw-opacity-30"
          >
            {getProp(us_discoverySession, 'group_cutoff_green', 0)} - {getProp(us_discoverySession, 'group_cutoff_blue', 0)} {rLIB('Annual kWh')}{' '}
            {rLIB('for', false) + ' 15 ' + rLIB('panels', false)}
          </Box>
        </Typography>
      </Box>
    )

    return tabJSX
  }

  const rJSX_YellowGroupAddressTab = (): JSX.Element => {
    let tabJSX = (
      <Box>
        <Card>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_CampaignOpportunities}
            tableData={objectToArray(us_yellowGroupAddresses)}
            tableSettings={tableSettings_CampaignOpportunityGroups}
          />
        </Card>
        <Typography
          variant="body1"
          className="tw-inline-block tw-align-top"
        >
          <Box
            component={'span'}
            className="tw-opacity-30"
          >
            {getProp(us_discoverySession, 'group_cutoff_yellow', 0)} - {getProp(us_discoverySession, 'group_cutoff_green', 0)} {rLIB('Annual kWh')}{' '}
            {rLIB('for', false) + ' 15 ' + rLIB('panels', false)}
          </Box>
        </Typography>
      </Box>
    )

    return tabJSX
  }

  const rJSX_MapLegend = (): JSX.Element => {
    let legendJSX = <></>
    legendJSX = (
      <Box>
        <Stack
          direction={'row'}
          spacing={2}
          className="tw-mt-2"
        >
          <Box>
            <Box
              className="tw-rounded-md tw-inline-block tw-mr-1 tw-align-top"
              style={{ backgroundColor: themeVariables.warning_main, width: '18px', height: '18px', marginTop: '2px' }}
            />
            <Typography className="tw-inline-block tw-align-top">
              {getProp(us_discoverySession, 'group_cutoff_yellow', 0)} - {getProp(us_discoverySession, 'group_cutoff_green', 0)} {rLIB('Annual kWh')}{' '}
              {rLIB('for', false) + ' 15 ' + rLIB('panels', false)}
            </Typography>
          </Box>
          <Box>
            <Box
              className="tw-rounded-md tw-inline-block tw-mr-1 tw-align-top"
              style={{ backgroundColor: themeVariables.success_main, width: '18px', height: '18px', marginTop: '2px' }}
            />
            <Typography className="tw-inline-block tw-align-top">
              {getProp(us_discoverySession, 'group_cutoff_green', 0)} - {getProp(us_discoverySession, 'group_cutoff_blue', 0)} {rLIB('Annual kWh')}{' '}
              {rLIB('for', false) + ' 15 ' + rLIB('panels', false)}
            </Typography>
          </Box>
          <Box>
            <Box
              className="tw-rounded-md tw-inline-block tw-mr-1 tw-align-top"
              style={{ backgroundColor: themeVariables.info_main, width: '18px', height: '18px', marginTop: '2px' }}
            />
            <Typography className="tw-inline-block tw-align-top">
              {'>'} {getProp(us_discoverySession, 'group_cutoff_blue', 0)} {rLIB('Annual kWh')} {rLIB('for', false) + ' 15 ' + rLIB('panels', false)}
            </Typography>
          </Box>
        </Stack>
      </Box>
    )
    return legendJSX
  }

  const rJSX_MapTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <MapBasic
          mapSettings={{
            center_lat: 0,
            center_lng: 0,
            height: 'calc( 100vh - 200px )',
            render_clusters: false,
            render_heatmap: false,
            render_markers: true,
            width: '100%',
            zoom: 12,
          }}
          mapMarkers={us_mapPins}
          ref={ur_mapRef}
        />
        {rJSX_MapLegend()}
      </Box>
    )
    return tabJSX
  }

  const rJSX_ImportAddressesButton = (shrink: boolean): JSX.Element => {
    const currentSalesDiscoverySessionKey = itemKey
    let buttonJSX = (
      <BasicImportButtonAndDialog
        importAdditionalData={{}}
        importButtonColor={'success'}
        importButtonDisabled={us_discoverySession.locked === true}
        importButtonShrink={shrink}
        importButtonText={rLIB('Import Addresses')}
        importDialogHeader={rLIB('Import Addresses')}
        importMappingOptions={{
          key: { key: 'key', required: true, label: 'key', automatch_properties: ['key'] },
          address1: { key: 'address1', required: true, label: 'address1', automatch_properties: ['address1'] },
          city: { key: 'city', required: true, label: 'city', automatch_properties: ['city'] },
          state: { key: 'state', required: true, label: 'state', automatch_properties: ['state'] },
          postal_code: { key: 'postal_code', required: true, label: 'postal_code', automatch_properties: ['postal_code'] },
          latitude: { key: 'latitude', required: false, label: 'latitude', automatch_properties: ['latitude'] },
          longitude: { key: 'longitude', required: false, label: 'longitude', automatch_properties: ['longitude'] },
        }}
        importSubmission={(spreadsheetData, importAdditionalData, importHooks) => {
          return new Promise((resolve, reject) => {
            try {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  for (let loopImportItemIndex in spreadsheetData) {
                    let loopImportItem = spreadsheetData[loopImportItemIndex]

                    let latitude = null
                    let longitude = null
                    let coordinatesCalculated = false
                    let coordinatesQueueNumber = 1
                    if (
                      getProp(loopImportItem, 'latitude', null) &&
                      !isNaN(parseFloat(loopImportItem.latitude)) &&
                      getProp(loopImportItem, 'longitude', null) &&
                      !isNaN(parseFloat(loopImportItem.longitude))
                    ) {
                      latitude = parseFloat(loopImportItem.latitude)
                      longitude = parseFloat(loopImportItem.longitude)
                      coordinatesCalculated = true
                      coordinatesQueueNumber = 0
                    }
                    let updateObject = {
                      key: loopImportItem.key,
                      status: 'pending',
                      timestamp_created: new Date(),
                      created_by_key: 'automated_admin',
                      created_by_name: 'Automated Admin',
                      address1: loopImportItem.address1,
                      city: loopImportItem.city,
                      state: loopImportItem.state,
                      postal_code: loopImportItem.postal_code,
                      latitude: latitude,
                      longitude: longitude,
                      name: loopImportItem.address1 + ' ' + loopImportItem.city + ', ' + loopImportItem.state + ' ' + loopImportItem.postal_code,
                      associated_discovery_session_key: currentSalesDiscoverySessionKey, // Used to wipe fields if needed in the future
                      associated_discovery_session_geocode_data_generated: coordinatesCalculated, // Order and then delete when generated
                      associated_discovery_session_geocode_data_queue_number: coordinatesQueueNumber, // Order and then delete when generated
                      associated_discovery_session_google_data_generated: false,
                      associated_discovery_session_google_data_queue_number: 1, // Order and then delete when generated
                    }
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_SalesOpportunity_Document(res_GCK.clientKey, loopImportItem.key),
                      data: updateObject,
                    })
                  }
                  DatabaseStagedBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                })
                .catch((rej_GCK) => {
                  console.error('Client Key Error:', rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            } catch (error) {
              console.error('Error during import submission:', error)
              reject(error)
            }
          })
        }}
      />
    )
    return buttonJSX
  }

  const rJSX_FilterAddressesButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="secondary"
        variant="contained"
        startIcon={
          us_processingData ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="filter" />
          )
        }
        className="tw-mr-2"
        disabled={us_processingData || us_discoverySession.locked === true}
        onClick={() => {
          updateAddressFilterSettings()
        }}
      >
        {rLIB('Filter Addresses')}
      </Button>
    )
    return buttonJSX
  }

  // Route Optimization
  const rJSX_RouteOptimizationSettingsButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="secondary"
        variant="contained"
        startIcon={
          us_processingData ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="route" />
          )
        }
        className="tw-mr-2"
        disabled={us_processingData || us_discoverySession.locked === true}
        onClick={() => {
          updateRouteOptimizationSettings()
        }}
      >
        {rLIB('Route Optimization Settings')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_CampaignRoutes = (): JSX.Element => {
    let routesJSX = <></>
    routesJSX = (
      <Box>
        <Grid2
          container
          spacing={1}
        >
          {objectToArray(us_calculatedRoutes).map((loopRouteItem) => {
            return (
              <Grid2
                key={loopRouteItem.key}
                xs={12}
                md={6}
                lg={4}
              >
                <Box sx={{ border: '2px solid ' + loopRouteItem.color_dark, background: loopRouteItem.color, borderRadius: '5px', padding: '8px' }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: 700 }}
                  >
                    {rLIB('Route')}:
                    <Box
                      component="span"
                      className="tw-ml-1 tw-opacity-70"
                    >
                      {loopRouteItem.name}
                    </Box>
                  </Typography>
                  <Box
                    className=" tw-ml-1"
                    component="span"
                  >
                    {loopRouteItem.address_count} {rLIB('Addresses')} - {formatTime(loopRouteItem.duration)}
                  </Box>
                  <Box className="tw-mt-2">
                    {/* {rJSX_PDFDownloadButton(loopRouteItem)} */}
                    {rJSX_RouteButton(loopRouteItem.key)}
                  </Box>
                </Box>
              </Grid2>
            )
          })}
        </Grid2>
      </Box>
    )
    return routesJSX
  }

  const rJSX_RouteOptimizationTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <Box>
          <Card className="tw-p-4">
            <Typography variant="h6">{rLIB('Route Optimization Settings')}</Typography>
            <Divider className="tw-my-2" />
            <Box>
              <Box className="tw-mb-4">
                <Typography variant="body1">
                  {rLIB('Start Latitude')}:
                  <Box
                    sx={{ color: themeVariables.error_main }}
                    className=" tw-ml-1"
                    component="span"
                  >
                    {getProp(us_discoverySession, 'route_optimization_start_latitude', '')}
                  </Box>
                </Typography>
                <Typography variant="body1">
                  {rLIB('Start Longitude')}:
                  <Box
                    sx={{ color: themeVariables.error_main }}
                    className=" tw-ml-1"
                    component="span"
                  >
                    {getProp(us_discoverySession, 'route_optimization_start_longitude', '')}
                  </Box>
                </Typography>
              </Box>
              <Box className="tw-mb-4">
                <Typography variant="body1">
                  {rLIB('Number of Blue Addresses')}:
                  <Box
                    sx={{ color: themeVariables.info_main }}
                    className=" tw-ml-1"
                    component="span"
                  >
                    {objectToArray(us_blueGroupAddresses).length}
                  </Box>
                </Typography>
                <Typography variant="body1">
                  {rLIB('Max Number of Blue Routes')}:
                  <Box
                    sx={{ color: themeVariables.info_main }}
                    className=" tw-ml-1"
                    component="span"
                  >
                    {getProp(us_discoverySession, 'route_optimization_blue_routes', '')}
                  </Box>
                </Typography>
                <Typography variant="body1">
                  {rLIB('Hours per Blue Routes')}:
                  <Box
                    sx={{ color: themeVariables.info_main }}
                    className=" tw-ml-1"
                    component="span"
                  >
                    {getProp(us_discoverySession, 'route_optimization_blue_hours', '')}
                  </Box>
                </Typography>
                <Button
                  color="info"
                  variant="contained"
                  startIcon={
                    us_runningRouteOptimization ? (
                      <Icon
                        icon="arrows-rotate"
                        className="bp_spin"
                      />
                    ) : (
                      <Icon icon="route" />
                    )
                  }
                  className="tw-mr-2 tw-mt-1"
                  disabled={us_processingData || us_runningRouteOptimization || us_discoverySession.locked === true}
                  onClick={() => {
                    runRouteOptimization(
                      getProp(us_discoverySession, 'route_optimization_start_latitude', 0),
                      getProp(us_discoverySession, 'route_optimization_start_longitude', 0),
                      'blue',
                      getProp(us_discoverySession, 'route_optimization_blue_routes', 0),
                      getProp(us_discoverySession, 'route_optimization_blue_hours', 0),
                    )
                  }}
                >
                  {rLIB('Optimize Blue Routes')}
                </Button>
              </Box>
              <Box className="tw-mb-4">
                <Typography variant="body1">
                  {rLIB('Number of Green Addresses')}:
                  <Box
                    sx={{ color: themeVariables.success_main }}
                    className=" tw-ml-1"
                    component="span"
                  >
                    {objectToArray(us_greenGroupAddresses).length}
                  </Box>
                </Typography>
                <Typography variant="body1">
                  {rLIB('Max Number of Green Routes')}:
                  <Box
                    sx={{ color: themeVariables.success_main }}
                    className=" tw-ml-1"
                    component="span"
                  >
                    {getProp(us_discoverySession, 'route_optimization_green_routes', '')}
                  </Box>
                </Typography>
                <Typography variant="body1">
                  {rLIB('Hours per Green Routes')}:
                  <Box
                    sx={{ color: themeVariables.success_main }}
                    className=" tw-ml-1"
                    component="span"
                  >
                    {getProp(us_discoverySession, 'route_optimization_green_hours', '')}
                  </Box>
                </Typography>
                <Button
                  color="success"
                  variant="contained"
                  startIcon={
                    us_runningRouteOptimization ? (
                      <Icon
                        icon="arrows-rotate"
                        className="bp_spin"
                      />
                    ) : (
                      <Icon icon="route" />
                    )
                  }
                  className="tw-mr-2 tw-mt-1"
                  disabled={us_processingData || us_runningRouteOptimization || us_discoverySession.locked === true}
                  onClick={() => {
                    runRouteOptimization(
                      getProp(us_discoverySession, 'route_optimization_start_latitude', 0),
                      getProp(us_discoverySession, 'route_optimization_start_longitude', 0),
                      'green',
                      getProp(us_discoverySession, 'route_optimization_green_routes', 0),
                      getProp(us_discoverySession, 'route_optimization_green_hours', 0),
                    )
                  }}
                >
                  {rLIB('Optimize Green Routes')}
                </Button>
              </Box>
              <Box>
                <Typography variant="body1">
                  {rLIB('Number of Yellow Addresses')}:
                  <Box
                    sx={{ color: themeVariables.warning_main }}
                    className=" tw-ml-1"
                    component="span"
                  >
                    {objectToArray(us_yellowGroupAddresses).length}
                  </Box>
                </Typography>
                <Typography variant="body1">
                  {rLIB('Max Number of Yellow Routes')}:
                  <Box
                    sx={{ color: themeVariables.warning_main }}
                    className=" tw-ml-1"
                    component="span"
                  >
                    {getProp(us_discoverySession, 'route_optimization_yellow_routes', '')}
                  </Box>
                </Typography>
                <Typography variant="body1">
                  {rLIB('Hours per Yellow Routes')}:
                  <Box
                    sx={{ color: themeVariables.warning_main }}
                    className=" tw-ml-1"
                    component="span"
                  >
                    {getProp(us_discoverySession, 'route_optimization_yellow_hours', '')}
                  </Box>
                </Typography>
                <Button
                  color="warning"
                  variant="contained"
                  startIcon={
                    us_runningRouteOptimization ? (
                      <Icon
                        icon="arrows-rotate"
                        className="bp_spin"
                      />
                    ) : (
                      <Icon icon="route" />
                    )
                  }
                  className="tw-mr-2 tw-mt-1"
                  disabled={us_processingData || us_runningRouteOptimization || us_discoverySession.locked === true}
                  onClick={() => {
                    runRouteOptimization(
                      getProp(us_discoverySession, 'route_optimization_start_latitude', 0),
                      getProp(us_discoverySession, 'route_optimization_start_longitude', 0),
                      'yellow',
                      getProp(us_discoverySession, 'route_optimization_yellow_routes', 0),
                      getProp(us_discoverySession, 'route_optimization_yellow_hours', 0),
                    )
                  }}
                >
                  {rLIB('Optimize Yellow Routes')}
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
        <Divider className="tw-my-2" />
        {rJSX_CampaignRoutes()}
      </Box>
    )
    return tabJSX
  }

  // PDFs
  const generateQRCodeDataUrl = (data: string) => {
    const canvas = document.createElement('canvas')
    // @ts-ignore
    QRCode.toCanvas(canvas, data, { width: 100, height: 100 })
    return canvas.toDataURL('image/png')
  }

  const returnPDFDownloadDocument = (route: TsInterface_UnspecifiedObject): JSX.Element => {
    // TODO: Figure out how to make this less laggy - generate PDF not in render cycle
    let addresses: TsInterface_UnspecifiedObject[] = getProp(us_calculatedRouteAddresses, route.key, [])
    const rows = []
    for (let i = 0; i < addresses.length; i += 3) {
      rows.push(addresses.slice(i, i + 3))
    }
    return (
      <Document>
        <Page
          size="LETTER"
          style={styles.page}
        >
          {rows.map((row, rowIndex) => (
            <View
              key={rowIndex}
              style={styles.row}
            >
              {row.map((address, colIndex) => (
                <View
                  key={colIndex}
                  style={styles.label}
                >
                  <View style={styles.address}>
                    <Text>{address.address}</Text>
                    <Text>{address.city}</Text>
                    <Text>
                      {address.state} {address.zip}
                    </Text>
                  </View>
                  <View style={styles.qrCode}>
                    <Image
                      src={generateQRCodeDataUrl(address.url)}
                      style={styles.qrCode}
                    />
                  </View>
                </View>
              ))}
            </View>
          ))}
        </Page>
      </Document>
    )
  }

  const rJSX_RouteButton = (routeKey: string): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="contained"
        className="tw-ml-2"
        startIcon={<Icon icon="map-location-dot" />}
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesDoorhangerRoutePage.url(itemKey) + '?route=' + routeKey)
        }}
      >
        {rLIB('View Route')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_DownloadButtonProper = (disabled: boolean, loading: boolean): JSX.Element => {
    let buttonJSX = (
      <Button
        color="error"
        variant="contained"
        disabled={disabled}
        startIcon={
          loading ? (
            <Icon
              icon="arrows-rotate"
              className="bp_spin"
            />
          ) : (
            <Icon icon="file-pdf" />
          )
        }
      >
        {rLIB('Download PDF')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_PDFDownloadButton = (route: TsInterface_UnspecifiedObject): JSX.Element => {
    let buttonJSX = (
      <PDFDownloadLink
        document={returnPDFDownloadDocument(route)}
        fileName={route.key + '.pdf'}
      >
        {({ blob, url, loading, error }) => (loading ? rJSX_DownloadButtonProper(true, loading) : rJSX_DownloadButtonProper(false, loading))}
      </PDFDownloadLink>
    )
    return buttonJSX
  }

  const rJSX_AllAddressTabHeader = (): JSX.Element => {
    let tabHeaderJSX = <></>
    if (getProp(us_discoverySession, 'total_address_count', null) != null) {
      tabHeaderJSX = (
        <Box>
          {rLIB('All Addresses')} ({getProp(us_discoverySession, 'total_address_count', 0)})
        </Box>
      )
    } else {
      tabHeaderJSX = <Box>{rLIB('All Addresses')}</Box>
    }
    return tabHeaderJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Sales Campaigns')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabsSettings={{
                baseUrl: ApplicationPages.SalesOpportunityDiscoverySessionViewPage.url(itemKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Sales Campaigns', false) as string,
              }}
              tabs={[
                {
                  tabHeader: rJSX_AllAddressTabHeader(),
                  tabUrlKey: 'Addresses',
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_ImportAddressesButton(false), minJSX: rJSX_ImportAddressesButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_GeocodeAddressesButton(false), minJSX: rJSX_GeocodeAddressesButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_RunSolarButton(false), minJSX: rJSX_RunSolarButton(true), sizeCutoff: 0 },
                  ],
                  tabContent: <Box>{rJSX_AddressesTab()}</Box>,
                },
                {
                  tabHeader: (
                    <Box>
                      <Icon
                        icon="circle"
                        className="tw-mr-2"
                        sx={{ color: themeVariables.info_main }}
                      />
                      {rLIB('Group')} ({objectToArray(us_blueGroupAddresses).length})
                    </Box>
                  ),
                  tabUrlKey: 'Blue_Group',
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_FilterAddressesButton(false), minJSX: rJSX_FilterAddressesButton(true), sizeCutoff: 0 },
                  ],
                  tabContent: <Box>{rJSX_BlueGroupAddressTab()}</Box>,
                },
                {
                  tabHeader: (
                    <Box>
                      <Icon
                        icon="circle"
                        className="tw-mr-2"
                        sx={{ color: themeVariables.success_main }}
                      />
                      {rLIB('Group')} ({objectToArray(us_greenGroupAddresses).length})
                    </Box>
                  ),
                  tabUrlKey: 'Green_Group',
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_FilterAddressesButton(false), minJSX: rJSX_FilterAddressesButton(true), sizeCutoff: 0 },
                  ],
                  tabContent: <Box>{rJSX_GreenGroupAddressTab()}</Box>,
                },
                {
                  tabHeader: (
                    <Box>
                      <Icon
                        icon="circle"
                        className="tw-mr-2"
                        sx={{ color: themeVariables.warning_main }}
                      />
                      {rLIB('Group')} ({objectToArray(us_yellowGroupAddresses).length})
                    </Box>
                  ),
                  tabUrlKey: 'Yellow_Group',
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_FilterAddressesButton(false), minJSX: rJSX_FilterAddressesButton(true), sizeCutoff: 0 },
                  ],
                  tabContent: <Box>{rJSX_YellowGroupAddressTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Map'),
                  tabUrlKey: 'Map',
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_FilterAddressesButton(false), minJSX: rJSX_FilterAddressesButton(true), sizeCutoff: 0 },
                  ],
                  tabContent: <Box>{rJSX_MapTab()}</Box>,
                  tabOnChange: () => {
                    setTimeout(() => {
                      // @ts-expect-error - TODO: reason for error
                      if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.mapCenter != null) {
                        // @ts-expect-error - TODO: reason for error
                        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
                          // @ts-expect-error - TODO: reason for error
                          ur_mapRef.current.recalculateMapBounds(true)
                        }
                      }
                    }, 0)
                  },
                },
                {
                  tabHeader: rLIB('Routes'),
                  tabUrlKey: 'Routes',
                  tabButtons: [
                    { fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_RouteOptimizationSettingsButton(false), minJSX: rJSX_RouteOptimizationSettingsButton(true), sizeCutoff: 0 },
                  ],
                  tabContent: <Box>{rJSX_RouteOptimizationTab()}</Box>,
                },
                {
                  tabHeader: rLIB('Analytics'),
                  tabUrlKey: 'Analytics',
                  tabButtons: [{ fullJSX: rJSX_BackButton(false), minJSX: rJSX_BackButton(true), sizeCutoff: 0 }],
                  tabContent: <Box></Box>,
                },
              ]}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_TimeSheetsGeolocationUsers_Document = (clientKey: string, userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'timesheets', 'geolocation', 'main', userKey)
}

export const DatabaseRef_TimeSheetsGeolocationUsers_ClockedInUsers_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'last_time_punch_type', comparator: '==', value: 'clock_in' }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'geolocation', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_TimeSheetsGeolocationUsers_ClockedOutUsers_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'last_time_punch_type', comparator: '==', value: 'clock_out' }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'geolocation', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_TimeSheetsGeolocationUsersHistory_DateRange_Query = (
  clientKey: string,
  userKey: string,
  startDate: Date,
  endDate: Date,
): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp', comparator: '>=', value: startDate },
    { prop: 'timestamp', comparator: '<=', value: endDate },
  ]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'geolocation', 'main', userKey, 'history'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

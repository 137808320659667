//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Example documentation for data filters

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import { useEffect, useState } from 'react'
import { Json } from 'rfbp_core/components/code_display'
import { DataFilter, TsInterface_DataFilterFiltersObject } from 'rfbp_core/components/data_filter'
import { ComponentDocumentation, TsInterface_ComponentDocumentation } from 'rfbp_core/components/_example_components'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

// Html strings
const DataFilterHtmlExample = `    <DataFilter
	filterInputData={ items }
	filtersFormData={ dataFiltersFormData }
	filtersObject={ dataFiltersObject }
	setFilterOutputData={ setFilteredItems }
	/>`

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const ExampleDataFilter = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_dataFiltersFormData, us_setDataFiltersFormData] = useState({})
  const [us_filteredItems, us_setFilteredItems] = useState({})
  const [us_items, us_setItems] = useState({})
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    us_setItems({
      item1: { key: 'item1', name: 'Item 1', status: 'b' },
      item2: { key: 'item2', name: 'Item 2', status: 'c' },
      item3: { key: 'item3', name: 'Item 3', status: 'a' },
      item4: { key: 'item4', name: 'Item 4', status: 'a' },
      item5: { key: 'item5', name: 'Item 5', status: 'c' },
      item6: { key: 'item6', name: 'Item 6', status: 'b' },
      item7: { key: 'item7', name: 'Item 7', status: 'b' },
    })
  }, [us_setDataFiltersFormData])

  useEffect(() => {
    us_setDataFiltersFormData({
      // status: ["b"],
      // key: "item1"
    })
  }, [us_setDataFiltersFormData])

  // Other Variables
  const dataFiltersObject: TsInterface_DataFilterFiltersObject = {
    status: {
      key: 'status',
      label: 'Status',
      selection_type: 'multiple',
      options: [
        { key: 'a', value: 'Status a', disabled: false },
        { key: 'b', value: 'Status b', disabled: false },
        { key: 'c', value: 'Status c', disabled: false },
        { key: 'd', value: 'Status d', disabled: false },
        { key: 'e', value: 'Status e', disabled: true },
      ],
    },
    key: {
      key: 'key',
      label: 'Key',
      selection_type: 'single',
      options: [
        { key: 'item1', value: 'item1', disabled: false },
        { key: 'item2', value: 'item2', disabled: false },
        { key: 'item3', value: 'item3', disabled: false },
      ],
    },
  }

  const documentationObject: TsInterface_ComponentDocumentation = {
    group_name: 'Data Filters',
    group_description: 'Data filters are used to allow simple filtering of data.',
    components: {
      DataFilter: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">DataFilter</span>
          </Box>
        ),
        component_description:
          "The dataFilter component allows users to filter down a dataset to specific criteria assuming all data is loaded into the container or component's state.",
        component_example_jsx_string: DataFilterHtmlExample,
        component_jsx: (
          <Box className="">
            <DataFilter
              filtersFormData={us_dataFiltersFormData}
              filterInputData={us_items}
              setFilterOutputData={us_setFilteredItems}
              filtersObject={dataFiltersObject}
            />
            <Box className="tw-mt-4">
              <Json
                alphabetized={false}
                data={us_filteredItems}
              />
            </Box>
          </Box>
        ),
        component_props: {
          filterInputData: {
            propKey: 'filterInputData',
            propDescription: 'the raw data that will be filtered down upon input change',
            propTypeOrInterface: 'TsInterface_UnspecifiedObject',
            propExampleValue: us_items,
          },
          filtersFormData: {
            propKey: 'filtersFormData',
            propDescription: 'Used to prefill the filter form inputs with provided values',
            propTypeOrInterface: 'TsInterface_UnspecifiedObject',
            propExampleValue: us_dataFiltersFormData,
          },
          filtersObject: {
            propKey: 'filtersObject',
            propDescription: 'object that is used to build the form inputs at the top for filtering',
            propTypeOrInterface: 'TsInterface_DataFilterFiltersObject',
            propExampleValue: dataFiltersObject,
          },
          setFilterOutputData: {
            propKey: 'setFilterOutputData',
            propDescription: 'Function that is used to set the filtered output data back to the component / container state',
            propTypeOrInterface: 'TsType_SetStateUnspecifiedObject | TsType_DefaultSetFilteredData',
            propExampleValue: 'setFilteredItems',
          },
        },
        component_todo: {
          1: 'Preseting with filtersFormData not working (starts out blank)',
          2: "single selection_type doesn't seem to be working either",
        },
      },
      DatabaseDataFilter: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">DatabaseDataFilter</span>
          </Box>
        ),
        component_description: 'In progress',
        component_example_jsx_string: '',
        component_jsx: <Box></Box>,
        component_props: {},
        component_todo: {
          0: 'BUILD',
        },
      },
    },
  }

  // Functions

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <ComponentDocumentation documentation={documentationObject} />
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

/* eslint-disable eqeqeq */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			I have questions technical questions
			I have questions about the price
			I have questions about my energy savings

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, CircularProgress, Divider, Link, Stack, Step, StepLabel, Stepper, Typography } from '@mui/material/'
import {
  formInputs_BackupLoads,
  formInputs_ContactInfo,
  formInputs_HomeInfo,
  returnPowerwallPricing,
  rJSX_OrderFinanceDetails,
  serviceAreaLookup,
  supportEmail,
  supportPhone,
  utilityCompaniesList,
} from 'app/models/powerwall/powerwall_form'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { rJSX_StagingWarning } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_PowerwallOrder_Document } from 'rfbp_aux/services/database_endpoints/webstore/orders'
import { Form, TsInterface_FormAdditionalData, TsInterface_FormSettings, TsType_FormOnChange, TsType_FormSubmission } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_UserInterface_CustomDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

// @ts-expect-error - TODO: reason for error
import logoSrc from '../../images/logos/logo_temp.png'
// @ts-expect-error - TODO: reason for error
import bg2Src from '../../images/unauthenticated/powerwall2.jpg'

///////////////////////////////
// Typescript
///////////////////////////////

// import {
// 	loadStripe
// } from '@stripe/stripe-js'

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
// const pageKey: string = ApplicationPages["HomePage"]["key"]

// Stripe
// const stripePromise = loadStripe('pk_live_51N5WTkHfXx6TTlJsYq9SmQiGnBJuPHfCmWqrY3x7dhP7t1481crtw7OXebzeOtbve713vFFKDMFiq6DI8r4RT79P00h6K1tWYV');
// const stripePromise = loadStripe('pk_test_51N5WTkHfXx6TTlJs3vs1k8RZO2aCoHZDu6VdiLNxA44q7OcW6Qg7xsVFAwCCIgNXiS9Vm1CsEcbFToAg4e1j3Fp900EmhG6W02');

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_FAIELD_TO_CREATE_CHECKOUT_SESSION_PLEASE_CONTACT_SUPPORT: JSX.Element = 			<Trans>Failed to create checkout session. Please contact support</Trans>
// const s_FAILED_TO_COMMUNICATE_WITH_STRIPE_PLEASE_CONTACT_SUPPORT: JSX.Element = 			<Trans>Failed to communicate with stripe. Please contact support</Trans>
// const s_FAILED_TO_OPEN_CHECKOUT_PAGE: JSX.Element = 										<Trans>Failed to open checkout page</Trans>
// const s_INVALID_SESSION_ID_PLEASE_CONTACT_SUPPORT: JSX.Element = 						<Trans>Invalid session ID. Please contact support</Trans>
// const s_I_HAVE_QUESTIONS: JSX.Element = 													<Trans>I have questions</Trans>
// const s_ORDER_WITH_CARD: JSX.Element = 													<Trans>Order With Card</Trans>
const s_BACK: JSX.Element = <Trans>Back</Trans>
const s_BACKUP_LOADS: JSX.Element = <Trans>Backup Loads</Trans>
const s_COMPLETE_ORDER: JSX.Element = <Trans>Complete Order</Trans>
const s_CONTACT_INFO: JSX.Element = <Trans>Contact Info</Trans>
const s_ENERGY_ORDER_TERMS: JSX.Element = <Trans>Energy Order Terms</Trans>
const s_HAVE_QUESTIONS: JSX.Element = <Trans>Have questions?</Trans>
const s_HOME_INFO: JSX.Element = <Trans>Home Info</Trans>
const s_OR: JSX.Element = <Trans>or</Trans>
const s_PAYMENT_TERMS: JSX.Element = <Trans>Payment Terms</Trans>
const s_PRIVACY_NOTICE: JSX.Element = <Trans>Privacy Notice</Trans>
const s_REACH_OUT_TO_OUR_TEAM_AT: JSX.Element = <Trans>Reach out to our team at</Trans>
const s_SCHEDULE_YOUR: JSX.Element = <Trans>Schedule Your</Trans>
const s_START_MY_ORDER: JSX.Element = <Trans>Start my Order</Trans>
const s_TESLA_POWERWALL_INSTALLATION: JSX.Element = <Trans>Tesla Powerwall Installation</Trans>
const se_POWERWALL_INSTALLATION = 'Powerwall Installation'
// { sort-end } - displayed text

// CSS - for full page scroll
let pageCSS: string = `
		@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;500;600;700&display=swap');
		body {
			background: #000000;
		}
		.etw_landing_font {
			font-family: "Inter", "Manrope", "Roboto","Helvetica","Arial",sans-serif;
		}
		.etw_landing_smooth_container {
			scroll-snap-type: y mandatory;
		}
		.etw_landing_smooth_container .etw_landing_smooth_section {
			scroll-behavior: smooth;
			scroll-snap-align: start;
		}
		.etw_contact_form_input_container .MuiInputBase-root {
			border-radius: 30px;
			background: #1F253A;
		}
		/* YELLOW AUTOFILL */
		input:-webkit-autofill {
			/* Override default yellow background */
			-webkit-box-shadow: 0 0 0px 1000px white inset;
		}
		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus
		input:-webkit-autofill,
		input:-webkit-autofill:active,
		textarea:-webkit-autofill,
		textarea:-webkit-autofill:hover
		textarea:-webkit-autofill:focus,
		select:-webkit-autofill,
		select:-webkit-autofill:hover,
		select:-webkit-autofill:focus {
		  border:none !important;
		  -webkit-text-fill-color: #fff !important;
		  -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
		  transition: background-color 5000s ease-in-out 0s;
		  background-clip: content-box !important;
		}
	`

// Config

///////////////////////////////
// Functions
///////////////////////////////

const formOnChange_AllForms: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
  if (formAdditionalData != null && formAdditionalData.clientKey != null && formAdditionalData.userKey != null) {
    formData.key = formAdditionalData.userKey as string
    if (formData.status == null) {
      formData.status = 'new'
    }
    if (formAdditionalData.step != null) {
      formData.step = formAdditionalData.step as number
    }
    if (
      formAdditionalData != null &&
      formAdditionalData.powerwallOrderData != null &&
      // @ts-expect-error - TODO: reason for error
      formAdditionalData.powerwallOrderData['timestamp_created'] == null
    ) {
      // @ts-expect-error - TODO: reason for error
      formData['timestamp_created'] = new Date()
    }
    DatabaseSetMergeDocument(DatabaseRef_PowerwallOrder_Document(formAdditionalData.clientKey as string, formAdditionalData.userKey as string), formData)
      .then((res_DSMD) => {
        // Nothing
      })
      .catch((rej_DSMD) => {
        console.error(rej_DSMD)
      })
  }
}

const formOnChange_ContactInfo: TsType_FormOnChange = (formAdditionalData, formData, formInputs, formSettings) => {
  if (formAdditionalData != null && formAdditionalData.clientKey != null && formAdditionalData.userKey != null) {
    formData.key = formAdditionalData.userKey as string
    if (formData.status == null) {
      formData.status = 'new'
    }
    if (formAdditionalData.step != null) {
      formData.step = formAdditionalData.step as number
    }

    if (formData.email != null) {
      formData.valid_email = isValidEmail(formData.email as string)
    } else {
      formData.valid_email = false
    }
    if (
      formAdditionalData != null &&
      formAdditionalData.powerwallOrderData != null &&
      // @ts-expect-error - TODO: reason for error
      formAdditionalData.powerwallOrderData['timestamp_created'] == null
    ) {
      // @ts-expect-error - TODO: reason for error
      formData['timestamp_created'] = new Date()
    }
    DatabaseSetMergeDocument(DatabaseRef_PowerwallOrder_Document(formAdditionalData.clientKey as string, formAdditionalData.userKey as string), formData)
      .then((res_DSMD) => {
        // Nothing
      })
      .catch((rej_DSMD) => {
        console.error(rej_DSMD)
      })
  }
}

const formSettings_AllForms: TsInterface_FormSettings = {
  highlight_missing: false,
  submit_button_hide: true,
}

function scrollToTop() {
  window.scroll(0, 0)
}

function isValidEmail(email: string) {
  // Regular expression pattern for validating email address
  let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  // Test the email against the pattern
  return emailPattern.test(email)
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  const pr_clientKey = 'etw_energy'

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_browserData, us_setBrowserData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadingCheckout, us_setLoadingCheckout] = useState<boolean>(false)
  const [us_powerwallOrderData, us_setPowerwallOrderData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_showForm, us_setShowForm] = useState<boolean>(false)
  const [us_showImage, us_setShowImage] = useState<boolean>(true)
  const [us_userAuthenticated, us_setUserAuthenticated] = useState<boolean>(false)
  const [us_userKey, us_setUserKey] = useState<string | null>(null)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_POWERWALL_INSTALLATION
  }, [])

  useEffect(() => {
    us_setBrowserData({
      cookie_enabled: navigator.cookieEnabled,
      language: navigator.language,
      platform: navigator.platform,
      screen_resolution: `${window.screen.width}x${window.screen.height}`,
      user_agent: navigator.userAgent,
      vendor: navigator.vendor,
    })
  }, [])

  useEffect(() => {
    // Uses anonymous authentication if the user is not logged in so that we can still save their data
    const auth = getAuth()
    if (auth != null && auth.currentUser != null && auth.currentUser.uid != null) {
      us_setUserKey(auth.currentUser.uid)
      us_setUserAuthenticated(true)
    } else {
      signInAnonymously(auth)
        .then((res_SIA) => {
          if (res_SIA != null && res_SIA.user != null && res_SIA.user.uid != null) {
            us_setUserKey(res_SIA.user.uid)
            us_setUserAuthenticated(true)
          } else {
            us_setUserAuthenticated(false)
          }
        })
        .catch((rej_SIA) => {
          console.error(rej_SIA)
          us_setUserAuthenticated(false)
        })
    }
  }, [ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setPowerwallOrderData(newData)
        setTimeout(() => {
          us_setShowForm(true)
          ur_forceRerender()
        }, 1)
      } else {
        setTimeout(() => {
          us_setShowForm(true)
          ur_forceRerender()
        }, 1)
      }
    }
    if (pr_clientKey != null && us_userKey != null) {
      unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_PowerwallOrder_Document(pr_clientKey, us_userKey), updateLiveData)
    } else {
      us_setPowerwallOrderData({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
    // us_setPowerwallOrderData({
    // 	"number_of_powerwalls_to_install": "5",
    // 	"expanding_powerwall": "yes",
    // 	// "expanding_powerwall": "no",
    // 	"include_batteries_in_order": "include_batteries_in_order",
    // 	// "include_batteries_in_order": "already_purchased_batteries",
    // })
  }, [ur_forceRerender, pr_clientKey, us_userKey])

  // Other Variables
  const formAdditionalData_ContactInfo: TsInterface_FormAdditionalData = {
    clientKey: pr_clientKey,
    userKey: us_userKey,
    step: 0,
    powerwallOrderData: us_powerwallOrderData,
    disabled_all_inputs: false,
  }

  const formAdditionalData_HomeInfo: TsInterface_FormAdditionalData = {
    clientKey: pr_clientKey,
    userKey: us_userKey,
    step: 1,
    powerwallOrderData: us_powerwallOrderData,
    serviceAreaLookup: serviceAreaLookup,
    utilityCompaniesList: utilityCompaniesList,
    disabled_all_inputs: false,
  }

  const formAdditionalData_BackupLoads: TsInterface_FormAdditionalData = {
    clientKey: pr_clientKey,
    userKey: us_userKey,
    step: 2,
    powerwallOrderData: us_powerwallOrderData,
    showImage: us_showImage,
    // @ts-expect-error - TODO: reason for error
    us_setShowImage: us_setShowImage,
    disabled_all_inputs: false,
  }

  // Functions
  const formSubmission_AllForms: TsType_FormSubmission = (formSubmittedData, formAdditionalData, formHooks) => {
    return new Promise((resolve, reject) => {
      // Nothing
      resolve({ success: true })
    })
  }

  const returnStepperActiveStep = (): number => {
    let step = 0
    if (us_powerwallOrderData != null && us_powerwallOrderData.step != null) {
      step = us_powerwallOrderData.step as number
    }
    // step = 3 // TODO delete
    return step
  }

  const updatePowerwallOrderData = (updateObject: TsInterface_UnspecifiedObject): void => {
    if (pr_clientKey != null && us_userKey != null) {
      if (us_powerwallOrderData == null || us_powerwallOrderData['timestamp_created'] == null) {
        updateObject['timestamp_created'] = new Date()
      }
      // Handle Metadata
      if (us_browserData != null) {
        updateObject['browser_metadata'] = {
          cookie_enabled: getProp(us_browserData, 'cookie_enabled', null),
          language: getProp(us_browserData, 'language', null),
          platform: getProp(us_browserData, 'platform', null),
          screen_resolution: getProp(us_browserData, 'screen_resolution', null),
          user_agent: getProp(us_browserData, 'user_agent', null),
          vendor: getProp(us_browserData, 'vendor', null),
        }
      }
      DatabaseSetMergeDocument(DatabaseRef_PowerwallOrder_Document(pr_clientKey as string, us_userKey as string), updateObject)
        .then((res_DSMD) => {
          // Nothing
        })
        .catch((rej_DSMD) => {
          console.error(rej_DSMD)
        })
    }
  }

  // const openStripeCheckout = () => {
  // return new Promise( ( resolve, reject ) => {
  // 	cloudFunctionManageRequest('managePayments',{
  // 		function: "createStripeCheckoutSession",
  // 		client_key: pr_clientKey,
  // 		user_key: userKey,
  // 		user_email: powerwallOrderData["email"],
  // 		webstore_item_key: "powerwall_deposit",
  // 		base_url: window.location.origin,
  // 	}).then(( res_CFMPR: any ) => {
  // 		if( res_CFMPR != null && res_CFMPR.sessionId != null ){
  // 			stripePromise.then((res_SP) => {
  // 				try {
  // 					// @ts-expect-error - TODO: reason for error
  // 					res_SP.redirectToCheckout({
  // 						sessionId: res_CFMPR.sessionId,
  // 					}).then((res_RTC) => {
  // 						if (res_RTC.error) {
  // 							// Handle any errors during redirection
  // 							console.error(res_RTC.error)
  // 							reject({success: false})
  // 						} else {
  // 							resolve({success: true})
  // 						}
  // 					})
  // 				} catch( rej_T ){
  // 					console.error(err)
  // 					reject({success: false})
  // 					uc_setUserInterface_ErrorDialogDisplay({
  // 						display: true,
  // 						error: {
  // 							message: s_FAILED_TO_OPEN_CHECKOUT_PAGE,
  // 							details: s_FAILED_TO_COMMUNICATE_WITH_STRIPE_PLEASE_CONTACT_SUPPORT,
  // 							code: "ER-D-TP-OSC-01",
  // 						}
  // 					})
  // 				}
  // 			}).catch((rej_SP) => {
  // 				console.error(rej_SP)
  // 				reject({success: false})
  // 				uc_setUserInterface_ErrorDialogDisplay({
  // 					display: true,
  // 					error: {
  // 						message: s_FAILED_TO_OPEN_CHECKOUT_PAGE,
  // 						details: s_FAILED_TO_COMMUNICATE_WITH_STRIPE_PLEASE_CONTACT_SUPPORT,
  // 						code: "ER-D-TP-OSC-02",
  // 					}
  // 				})
  // 			})
  // 		} else {
  // 			console.error( "Error: No Session ID" )
  // 			reject({success: false})
  // 			uc_setUserInterface_ErrorDialogDisplay({
  // 				display: true,
  // 				error: {
  // 					message: s_FAILED_TO_OPEN_CHECKOUT_PAGE,
  // 					details: s_INVALID_SESSION_ID_PLEASE_CONTACT_SUPPORT,
  // 					code: "ER-D-TP-OSC-03",
  // 				}
  // 			})
  // 		}
  // 	}).catch(( rej_CFMPR: any ) => {
  // 		reject({success: false})
  // 		uc_setUserInterface_ErrorDialogDisplay({
  // 			display: true,
  // 			error: {
  // 				message: s_FAILED_TO_OPEN_CHECKOUT_PAGE,
  // 				details: s_FAIELD_TO_CREATE_CHECKOUT_SESSION_PLEASE_CONTACT_SUPPORT,
  // 				code: "ER-D-TP-OSC-04",
  // 			}
  // 		})
  // 	})
  // })
  // }

  const sendNewPowerwallOrderEmail = () => {
    return new Promise((resolve, reject) => {
      cloudFunctionManageRequest('managePayments', {
        function: 'newPowerwallAlertEmail',
        client_key: pr_clientKey,
        user_key: us_userKey,
      })
        .then((res_CFMPR: any) => {
          resolve(res_CFMPR)
        })
        .catch((rej_CFMPR: any) => {
          reject(rej_CFMPR)
        })
    })
  }

  // JSX Generation
  const rJSX_Header = (): JSX.Element => {
    let headerJSX = (
      <Stack
        direction="row"
        className="tw-justify-between"
      >
        <Box
          className="tw-m-2"
          component="img"
          sx={{ width: 126, height: 36 }}
          alt="logo"
          src={logoSrc}
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.UnauthenticatedLandingPage.url())
          }}
        />
      </Stack>
    )
    return headerJSX
  }

  const rJSX_Intro = (): JSX.Element => {
    let introJSX = (
      <Box className="tw-text-center">
        <Typography
          className="etw_landing_font "
          variant="h3"
        >
          <Box className="tw-font-light">{s_SCHEDULE_YOUR}</Box>
        </Typography>
        <Typography
          className="etw_landing_font "
          variant="h4"
        >
          <Box className="tw-font-regular tw-mt-2">{s_TESLA_POWERWALL_INSTALLATION}</Box>
        </Typography>
      </Box>
    )
    return introJSX
  }

  const rJSX_SupportEmailText = (): JSX.Element => {
    let supportEmailTextJSX = <></>
    supportEmailTextJSX = (
      <Box className="tw-text-center tw-mt-4 tw-opacity-50">
        <Box className="tw-inline-block tw-mr-2">
          {s_HAVE_QUESTIONS} {s_REACH_OUT_TO_OUR_TEAM_AT}
        </Box>
        <Link
          href={`tel:${supportPhone}`}
          target="_blank"
        >
          {supportPhone}
        </Link>{' '}
        {s_OR}{' '}
        <Link
          href={`mailto:${supportEmail}`}
          target="_blank"
        >
          {supportEmail}
        </Link>
        .
        <Box className="tw-text-center">
          <Link
            href={ApplicationPages.PowerwallInstallAgreementPage.url()}
            target="_blank"
            className="tw-mx-2"
          >
            {s_ENERGY_ORDER_TERMS}
          </Link>{' '}
          |
          <Link
            href={ApplicationPages.PaymentTermsPage.url()}
            target="_blank"
            className="tw-mx-2"
          >
            {s_PAYMENT_TERMS}
          </Link>{' '}
          |
          <Link
            href={ApplicationPages.PrivacyPolicyPage.url()}
            target="_blank"
            className="tw-mx-2"
          >
            {s_PRIVACY_NOTICE}
          </Link>
        </Box>
      </Box>
    )
    return supportEmailTextJSX
  }

  const rJSX_SubmitOrderButton = (): JSX.Element => {
    let orderWithCardButtonJSX = <></>
    let orderIcon = <></>
    if (us_loadingCheckout === true) {
      orderIcon = (
        <Icon
          icon="arrows-rotate"
          className="bp_spin tw-mr-2"
        />
      )
    }
    orderWithCardButtonJSX = (
      <Button
        variant="outlined"
        color="inherit"
        onClick={() => {
          let updateObject: TsInterface_UnspecifiedObject = {}
          if (
            us_powerwallOrderData != null &&
            us_powerwallOrderData['number_of_powerwalls_to_install'] != null &&
            us_powerwallOrderData['include_batteries_in_order'] != null
          ) {
            if (
              getProp(us_powerwallOrderData, 'number_of_powerwalls_to_install', null) != null &&
              getProp(us_powerwallOrderData, 'expanding_powerwall', null) != null &&
              getProp(us_powerwallOrderData, 'include_batteries_in_order', null) != null
            ) {
              updateObject['pricing_agreed_to'] = returnPowerwallPricing(
                getProp(us_powerwallOrderData, 'number_of_powerwalls_to_install', null),
                getProp(us_powerwallOrderData, 'expanding_powerwall', null),
                getProp(us_powerwallOrderData, 'include_batteries_in_order', null),
                new Date(),
              )
            }

            // if( us_powerwallOrderData["include_batteries_in_order"] === "already_purchased_batteries" ){
            // 	if( us_powerwallOrderData["number_of_powerwalls_to_install"] === "1" ){
            // 		updateObject["pricing_agreed_to"] = powerwallPricingV1["just_install_1"]["values"]
            // 	} else if( us_powerwallOrderData["number_of_powerwalls_to_install"] === "2" ){
            // 		updateObject["pricing_agreed_to"] = powerwallPricingV1["just_install_2"]["values"]
            // 	} else if( us_powerwallOrderData["number_of_powerwalls_to_install"] === "3" ){
            // 		updateObject["pricing_agreed_to"] = powerwallPricingV1["just_install_3"]["values"]
            // 	} else if( us_powerwallOrderData["number_of_powerwalls_to_install"] === "4+" ){
            // 		updateObject["pricing_agreed_to"] = powerwallPricingV1["just_install_4+"]["values"]
            // 	}
            // } else {
            // 	if( us_powerwallOrderData["number_of_powerwalls_to_install"] === "1" ){
            // 		updateObject["pricing_agreed_to"] = powerwallPricingV1["buy_and_install_1"]["values"]
            // 	} else if( us_powerwallOrderData["number_of_powerwalls_to_install"] === "2" ){
            // 		updateObject["pricing_agreed_to"] = powerwallPricingV1["buy_and_install_2"]["values"]
            // 	} else if( us_powerwallOrderData["number_of_powerwalls_to_install"] === "3" ){
            // 		updateObject["pricing_agreed_to"] = powerwallPricingV1["buy_and_install_3"]["values"]
            // 	} else if( us_powerwallOrderData["number_of_powerwalls_to_install"] === "4+" ){
            // 		updateObject["pricing_agreed_to"] = powerwallPricingV1["buy_and_install_4+"]["values"]
            // 	}
            // }
          }
          if (pr_clientKey != null && us_userKey != null) {
            DatabaseSetMergeDocument(DatabaseRef_PowerwallOrder_Document(pr_clientKey as string, us_userKey as string), updateObject)
              .then((res_DSMD) => {
                us_setLoadingCheckout(true)
                ur_forceRerender()
                // SEND EMAIL AND INSTANTIATE ACCOUNT
                directAppNavigation(un_routerNavigation, ApplicationPages.TeslaPowerwallConfirm.url(pr_clientKey, us_userKey))
              })
              .catch((rej_DSMD) => {
                us_setLoadingCheckout(false)
                ur_forceRerender()
              })
          } else {
            us_setLoadingCheckout(false)
            ur_forceRerender()
          }
        }}
        disabled={us_powerwallOrderData['agreed_to_terms'] != true || us_loadingCheckout === true}
      >
        {orderIcon}
        {s_START_MY_ORDER}
      </Button>
    )
    return orderWithCardButtonJSX
  }

  // const rJSX_QuestionButton = (): JSX.Element => {
  // let questionButtonJSX = <></>
  // questionButtonJSX =
  // <Button
  // className="tw-mt-1"
  // 	variant="outlined"
  // 	color="inherit"
  // 	onClick={ () => {
  // 		// uc_setUserInterface_CustomDialogDisplay({

  // 		// })
  // 	}}
  // >
  // 	{ s_I_HAVE_QUESTIONS }
  // </Button>
  // return questionButtonJSX
  // }

  const rJSX_OrderSummaryPage = (): JSX.Element => {
    let formJSX = <></>
    formJSX = (
      <Box>
        <Box
          className="tw-m-auto"
          sx={{ maxWidth: '600px' }}
        >
          {rJSX_OrderFinanceDetails(uc_setUserInterface_CustomDialogDisplay, us_powerwallOrderData, pr_clientKey, us_userKey)}
        </Box>
        <Box className="tw-mt-4">
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Box>
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  updatePowerwallOrderData({ step: 2 })
                  // scrollToTop()
                }}
              >
                {s_BACK}
              </Button>
            </Box>
            <Box>
              {/* { rJSX_OrderWithCardButton() } */}
              {rJSX_SubmitOrderButton()}
              {/* <Box>
								{ rJSX_QuestionButton() }
							</Box> */}
            </Box>
          </Stack>
        </Box>
        {rJSX_SupportEmailText()}
      </Box>
    )
    return formJSX
  }

  const rJSX_VisiblePageForm = (): JSX.Element => {
    let formJSX = <></>
    let step = returnStepperActiveStep()
    if (step === 0) {
      if (us_showForm === true) {
        formJSX = (
          <Box>
            <Box>
              <Form
                formAdditionalData={formAdditionalData_ContactInfo}
                formData={us_powerwallOrderData}
                formInputs={formInputs_ContactInfo}
                formOnChange={formOnChange_ContactInfo}
                formSettings={formSettings_AllForms}
                formSubmission={formSubmission_AllForms}
              />
            </Box>
            <Box className="tw-mt-4">
              <Stack
                direction="row"
                spacing={1}
                className="tw-justify-between"
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  disabled={true}
                >
                  {s_BACK}
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    updatePowerwallOrderData({ step: 1 })
                    sendNewPowerwallOrderEmail()
                    scrollToTop()
                  }}
                  disabled={
                    us_powerwallOrderData.valid_email !== true ||
                    us_powerwallOrderData.email == null ||
                    us_powerwallOrderData.email === '' ||
                    us_powerwallOrderData.first_name == null ||
                    us_powerwallOrderData.first_name === '' ||
                    us_powerwallOrderData.last_name == null ||
                    us_powerwallOrderData.last_name === '' ||
                    us_powerwallOrderData.phone == null ||
                    us_powerwallOrderData.phone === '' ||
                    us_powerwallOrderData.best_contact_method == null ||
                    us_powerwallOrderData.best_contact_method === '' ||
                    us_powerwallOrderData.best_time_to_contact == null ||
                    us_powerwallOrderData.best_time_to_contact === '' ||
                    (us_powerwallOrderData.best_time_to_contact === 'other' &&
                      (us_powerwallOrderData.best_time_to_contact_other == null || us_powerwallOrderData.best_time_to_contact_other === ''))
                  }
                >
                  {rLIB('Next')}
                </Button>
              </Stack>
            </Box>
            {rJSX_SupportEmailText()}
          </Box>
        )
      }
    } else if (step === 1) {
      if (us_showForm === true) {
        formJSX = (
          <Box>
            <Box>
              <Form
                formAdditionalData={formAdditionalData_HomeInfo}
                formData={us_powerwallOrderData}
                formInputs={formInputs_HomeInfo}
                formOnChange={formOnChange_AllForms}
                formSettings={formSettings_AllForms}
                formSubmission={formSubmission_AllForms}
              />
            </Box>
            <Box className="tw-mt-4">
              <Stack
                direction="row"
                spacing={1}
                className="tw-justify-between"
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    updatePowerwallOrderData({ step: 0 })
                    // scrollToTop()
                  }}
                >
                  {s_BACK}
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    updatePowerwallOrderData({ step: 2 })
                    scrollToTop()
                  }}
                  disabled={
                    us_powerwallOrderData.install_location == null ||
                    us_powerwallOrderData.install_location === '' ||
                    us_powerwallOrderData.utility_company == null ||
                    us_powerwallOrderData.utility_company === '' ||
                    (us_powerwallOrderData.utility_company === 'other' &&
                      (us_powerwallOrderData.utility_company_other == null || us_powerwallOrderData.utility_company_other === '')) ||
                    us_powerwallOrderData.home_square_footage == null ||
                    us_powerwallOrderData.home_square_footage === '' ||
                    us_powerwallOrderData.home_year_built == null ||
                    us_powerwallOrderData.home_year_built === '' ||
                    us_powerwallOrderData.is_home_owner == null ||
                    us_powerwallOrderData.is_home_owner === '' ||
                    us_powerwallOrderData.is_home_owner === 'no' ||
                    us_powerwallOrderData.home_type == null ||
                    us_powerwallOrderData.home_type === '' ||
                    us_powerwallOrderData.home_type === 'Manufactured home' ||
                    us_powerwallOrderData.home_type === 'Mobile home' ||
                    us_powerwallOrderData.home_type === 'Apartment' ||
                    us_powerwallOrderData.home_type === 'Condo' ||
                    us_powerwallOrderData.include_batteries_in_order == null ||
                    us_powerwallOrderData.include_batteries_in_order === '' ||
                    us_powerwallOrderData.has_solar == null ||
                    us_powerwallOrderData.has_solar === '' ||
                    us_powerwallOrderData.expanding_powerwall == null ||
                    us_powerwallOrderData.expanding_powerwall === ''
                  }
                >
                  {rLIB('Next')}
                </Button>
              </Stack>
            </Box>
            {rJSX_SupportEmailText()}
          </Box>
        )
      }
    } else if (step === 2) {
      if (us_showForm === true) {
        formJSX = (
          <Box>
            <Box>
              <Form
                formAdditionalData={formAdditionalData_BackupLoads}
                formData={us_powerwallOrderData}
                formInputs={formInputs_BackupLoads}
                formOnChange={formOnChange_AllForms}
                formSettings={formSettings_AllForms}
                formSubmission={formSubmission_AllForms}
              />
            </Box>
            <Box className="tw-mt-4">
              <Stack
                direction="row"
                spacing={1}
                className="tw-justify-between"
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    updatePowerwallOrderData({ step: 1 })
                    scrollToTop()
                  }}
                >
                  {s_BACK}
                </Button>
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    updatePowerwallOrderData({ step: 3 })
                    scrollToTop()
                  }}
                  disabled={
                    us_powerwallOrderData.number_of_powerwalls_to_install == null ||
                    us_powerwallOrderData.number_of_powerwalls_to_install === '' ||
                    us_powerwallOrderData.include_batteries_in_order == null ||
                    us_powerwallOrderData.include_batteries_in_order === ''
                  }
                >
                  {rLIB('Next')}
                </Button>
              </Stack>
            </Box>
            {rJSX_SupportEmailText()}
          </Box>
        )
      }
    } else if (step === 3) {
      if (us_showForm === true) {
        formJSX = rJSX_OrderSummaryPage()
      }
    } else if (step === 4) {
      // Ntohing?
    }
    return formJSX
  }

  const rJSX_PowerwallPreOrderForm = (): JSX.Element => {
    let formJSX = <></>
    formJSX = (
      <Box
        sx={{
          width: '95%',
          maxWidth: '800px',
          borderRadius: '8px',
          border: '1px solid #fff',
          backgroundColor: 'rgba(0,0,0,.85)',
        }}
        className="tw-mx-auto tw-p-4 tw-mt-6 tw-mb-6"
      >
        <Box className="tw-mb-4">
          <Stepper
            activeStep={returnStepperActiveStep()}
            alternativeLabel
          >
            <Step key={1}>
              <StepLabel>{s_CONTACT_INFO}</StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>{s_HOME_INFO}</StepLabel>
            </Step>
            <Step key={3}>
              <StepLabel>{s_BACKUP_LOADS}</StepLabel>
            </Step>
            <Step key={4}>
              <StepLabel>{s_COMPLETE_ORDER}</StepLabel>
            </Step>
          </Stepper>
        </Box>
        <Box>{rJSX_VisiblePageForm()}</Box>
      </Box>
    )
    return formJSX
  }

  // const rJSX_PowerwallPostOrderForm = (): JSX.Element => {
  // let formJSX = <></>

  // // TODO

  // return formJSX
  // }

  const rJSX_PowerwallOrderContainer = (): JSX.Element => {
    let containerJSX = <></>
    if (us_userAuthenticated === true) {
      containerJSX = <Box>{rJSX_PowerwallPreOrderForm()}</Box>
      // { rJSX_PowerwallPostOrderForm() }
    } else {
      containerJSX = (
        <Box className="tw-text-center tw-mt-6">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="primary"
          />
        </Box>
      )
    }
    return containerJSX
  }

  // const rJSX_ContactForm = (): JSX.Element => {
  // let contactFormJSX = <></>
  // if( formSubmitted === true ){
  // 	contactFormJSX =
  // 	<Box>
  // 		<Box
  // 			sx={{
  // 				width: "95%",
  // 				maxWidth: "800px",
  // 				borderRadius: "8px",
  // 				border: "1px solid #fff",
  // 				backgroundColor: "rgba(0,0,0,.6)"
  // 			}}
  // 			className="tw-mx-auto tw-p-4 tw-mt-6 tw-mb-6 tw-text-center"
  // 		>
  // 			<Typography className="etw_landing_font" variant="h5">
  // 				<Box className="tw-font-thin">{ s_REQUEST_SUBMITTED_SUCCESSFULLY }</Box>
  // 			</Typography>
  // 			<Typography className="etw_landing_font tw-mt-3" variant="h6">
  // 				<Box className="tw-font-thin">{ s_WELL_BE_IN_TOUCH_SOON }</Box>
  // 			</Typography>
  // 		</Box>
  // 	</Box>
  // } else {
  // 	contactFormJSX =
  // 	<Box>
  // 		<Box
  // 			sx={{
  // 				width: "95%",
  // 				maxWidth: "800px",
  // 				borderRadius: "8px",
  // 				border: "1px solid #fff",
  // 				backgroundColor: "rgba(0,0,0,.6)"
  // 			}}
  // 			className="tw-mx-auto tw-p-4 tw-mt-6 tw-mb-6"
  // 		>
  // 			<Form
  // 				formAdditionalData={ formAdditionalData_Contact }
  // 				formData={ formData_Contact }
  // 				formInputs={ formInputs_ContactInfo }
  // 				formOnChange={ formOnChange_Contact }
  // 				formSettings={ formSettings_Contact }
  // 				formSubmission={ formSubmission_Contact }
  // 			/>
  // 		</Box>
  // 	</Box>
  // }
  // return contactFormJSX
  // }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <Box className="etw_landing_smooth_container">
        {rJSX_StagingWarning()}
        <Box
          id="intro"
          className="etw_landing_smooth_section tw-w-full"
          sx={{
            backgroundImage: {
              xs: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(' + bg2Src + ')',
              md: 'url(' + bg2Src + ')',
            },
            backgroundColor: '#000',
            backgroundRepeat: 'no-repeat',
            backgroundSize: {
              xs: 'contain',
              md: 'contain',
            },
            backgroundPositionX: {
              xs: 'center',
              md: 'center',
            },
            minHeight: '100vh',
          }}
        >
          <Box
            className="tw-m-auto"
            sx={{ maxWidth: '1000px' }}
          >
            {rJSX_Header()}
          </Box>
          <Divider />
          <Box sx={{ height: { xs: '20px', md: '30px', lg: '40px' } }}></Box>
          {rJSX_Intro()}
          <Box
            className="tw-p-2 tw-m-auto"
            sx={{ maxWidth: '1000px' }}
          >
            {rJSX_PowerwallOrderContainer()}
            {/* <Box className="tw-text-center tw-mb-4 tw-opacity-20">
							{ userKey }
						</Box> */}
          </Box>
        </Box>
        <style>{pageCSS}</style>
      </Box>
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

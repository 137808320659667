///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Card } from '@mui/material'
import { DatabaseRef_ProjectContactLogs_Collection } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { LogTable } from 'rfbp_core/components/logs/log_table'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// JSX Exports
///////////////////////////////

export const rJSX_LogsTab = (readOrWrite: 'read' | 'write', uc_RootData_ClientKey: string | null, projectKey: string | null): JSX.Element => {
  let tabJSX = <></>
  if (uc_RootData_ClientKey != null && projectKey != null) {
    tabJSX = (
      <Card>
        <LogTable
          logType="project"
          logEndpoint={DatabaseRef_ProjectContactLogs_Collection(uc_RootData_ClientKey as string, projectKey)}
        />
      </Card>
    )
  }
  return tabJSX
}

///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_MaterialSkuPhotos = (clientKey: string, skuKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/material_skus/' + skuKey + '/images/' + fileName)
}

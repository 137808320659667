///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'
import { objectToArray } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Exports
///////////////////////////////

export const inboundEmailParserOptions: TsInterface_UnspecifiedObject = {
  test: {
    value: rLIB('Test'),
    key: 'test',
  },
  motorq: {
    value: rLIB('MotorQ Trip Stop Report Details'),
    key: 'motorq',
    // disabled: true,
  },
  // vehicle_fuel_logs: {
  //   value: rLIB('Vehicle Fuel Logs'),
  //   key: 'vehicle_fuel_logs',
  //   disabled: true,
  // },
}

export const formInputs_InboundEmailParser: TsInterface_FormInputs = {
  email_address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'email_address',
    label: rLIB('Email Address'),
    required: true,
  },
  parser_procedure: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'parser_procedure',
    label: rLIB('Parser'),
    options: objectToArray(inboundEmailParserOptions),
    required: true,
  },
}

///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Exports
///////////////////////////////

export const formInputs_NewSupplierLocation: TsInterface_FormInputs = {
  name: {
    key: 'name',
    label: rLIB('Location Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  associated_region_key: {
    key: 'associated_region_key',
    label: rLIB('Region'),
    input_type: 'multiple_choice_select',
    required: true,
    data_type: 'string',
    options: [],
  },
  location_address: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_address',
    label: rLIB('Street Address'),
    required: true,
  },
  location_city: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_city',
    label: rLIB('City'),
    required: true,
  },
  location_state: {
    data_type: 'string',
    input_type: 'multiple_choice_select_state_usa',
    key: 'location_state',
    label: rLIB('State'),
    required: true,
  },
  location_zip: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'location_zip',
    label: rLIB('Zip Code'),
    required: true,
  },
}

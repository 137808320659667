import { rLIB } from 'rfbp_core/localization/library'

export const getCoordinatesFromAddress = (address: string) => {
  return new Promise((resolve, reject) => {
    let geocoder = new google.maps.Geocoder()
    geocoder.geocode({ address: address }, (results: any, status: any) => {
      // if there is a result
      if (status === 'OK') {
        const coordinates = {
          latitude: results[0].geometry.location.lat(),
          longitude: results[0].geometry.location.lng(),
        }
        resolve({ success: true, coordinates: coordinates })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to calculate coordinates'),
            details: status,
            code: 'ER-D-TDI-ACTT-01',
          },
        })
      }
    })
  })
}

///////////////////////////////
// Imports
///////////////////////////////

import { themeVariables } from 'rfbp_aux/config/app_theme'
import { objectToArray } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

// TEMP

export const processVehicleMilesData = (rawVehicleData: any, mondaysInDateRange: any): { summary_rows: any[]; data_rows: any[] } => {
  const today = new Date()
  today.setHours(0, 0, 0, 0) // Normalize today's date

  const formattedData: any = {
    summary_rows: [],
    data_rows: [],
  }

  const dateHeaders: any[] = [
    {
      cellValue: 'Vehicle Usage',
      conditionalFormatting: { fontWeight: 'bold', width: '200px' },
    },
  ]
  const driverDataMap: any = {} // Map to store each driver's data across weeks

  const roundNumber = (num: number): number => {
    if (num > 0 && num < 1) return 1 // Round any positive number less than 1 up to 1
    return Math.round(num) // Otherwise, round the number to the nearest integer
  }

  // Step 1: Identify All Unique Drivers, Excluding "Unknown Driver"
  objectToArray(mondaysInDateRange).forEach((monday: string) => {
    Object.keys(rawVehicleData).forEach((week) => {
      const drivers = rawVehicleData[week].data
      Object.keys(drivers).forEach((driver) => {
        if (driver === 'Unknown Driver') return // Skip Unknown Driver

        const userKey = drivers[driver].associated_user_key
        if (!driverDataMap[userKey]) {
          driverDataMap[userKey] = {
            name: driver,
            data: Array(objectToArray(mondaysInDateRange).length).fill('-'), // Fill with placeholder values
          }
        }
      })
    })
  })

  // Step 2: Populate Weekly Data
  objectToArray(mondaysInDateRange).forEach((monday: string, weekIndex: number) => {
    Object.entries(rawVehicleData).forEach(([weekDate, weekData]: [string, any]) => {
      const weekDrivers = weekData.data

      // Only process data for the current week
      if (weekDate !== monday) return

      Object.entries(weekDrivers).forEach(([driver, driverData]: [string, any]) => {
        if (driver === 'Unknown Driver') return // Skip Unknown Driver

        const userKey = driverData.associated_user_key
        const additionalMiles = driverData.additional_miles || 0
        let totalMilesDriven = 0
        let totalExpectedMilesWithAdditional = 0

        // Accumulate totals for all days in the week for this driver
        Object.keys(driverData).forEach((date) => {
          const dayData = driverData[date]
          if (dayData && dayData.miles_driven !== undefined && dayData.expected_miles !== undefined) {
            totalMilesDriven += roundNumber(dayData.miles_driven)
            if (dayData.expected_miles > 0) {
              totalExpectedMilesWithAdditional += roundNumber(dayData.expected_miles + additionalMiles)
            }
          }
        })

        const difference = roundNumber(totalMilesDriven) - roundNumber(totalExpectedMilesWithAdditional)

        // Assign the calculated difference to the correct week index
        driverDataMap[userKey].data[weekIndex] = difference !== 0 ? difference : '-'
      })
    })
  })

  // Step 3: Build Date Headers
  objectToArray(mondaysInDateRange).forEach((monday: string) => {
    const date = new Date(monday)
    date.setDate(date.getDate() + 1) // Adjust the date to ensure it's showing the correct day
    const formattedDate = date.toLocaleDateString('en-US', {
      month: 'short',
      day: '2-digit',
    })
    const isFuture = date > today

    dateHeaders.push({
      cellValue: formattedDate,
      conditionalFormatting: {
        fontWeight: 'bold',
        width: '120px',
        opacity: isFuture ? 0.5 : 1,
      },
    })
  })

  // Step 4: Convert the driver data map to the final data_rows format
  const dataRows = Object.keys(driverDataMap)
    .sort((a, b) => driverDataMap[a].name.localeCompare(driverDataMap[b].name))
    .map((userKey) => {
      const driver = driverDataMap[userKey]
      return [
        {
          cellValue: driver.name,
          conditionalFormatting: { fontWeight: 'bold', width: '200px' },
        },
        ...driver.data.map((value: any, weekIndex: number) => {
          return {
            cellValue: value,
            conditionalFormatting: {
              backgroundColor: value === '-' ? 'inherit' : value < 0 ? themeVariables.success_main : themeVariables.error_main,
              color: value === '-' ? 'inherit' : 'white',
              width: '120px',
            },
          }
        }),
      ]
    })

  // Step 5: Calculate Summary Row Totals
  const totalsRow: any[] = [{ cellValue: 'Total', conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]

  objectToArray(mondaysInDateRange).forEach((monday: string, index: number) => {
    let totalDifference = 0

    // Sum only the positive values in the data_rows for this week
    dataRows.forEach((row: any) => {
      const cellValue = row[index + 1]?.cellValue
      const difference = typeof cellValue === 'number' && cellValue > 0 ? cellValue : 0
      totalDifference += difference
    })

    totalsRow.push({
      cellValue: totalDifference !== 0 ? totalDifference : '-',
      conditionalFormatting: {
        fontWeight: 'normal',
        width: '120px',
        backgroundColor: totalDifference > 0 ? themeVariables.error_main : totalDifference < 0 ? themeVariables.success_main : 'inherit',
        color: totalDifference !== 0 ? 'white' : 'inherit',
      },
    })
  })

  // Add the summary row headers and sorted data rows to the formatted data structure
  formattedData.summary_rows.push(dateHeaders)
  formattedData.data_rows = dataRows
  formattedData.summary_rows.push(totalsRow)

  return formattedData
}

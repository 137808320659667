///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, useMediaQuery } from '@mui/material/'
import { returnProjectDocumentFolders } from 'app/models/projects/project_document_folders'
import { returnImageTags } from 'app/models/projects/project_image_tags'
import {
  generateDefaultMessageThreadsInDatabase,
  generateStandardProjectMessages,
  returnCombinedTaskRoles,
  returnGroupedTasksData,
} from 'app/models/projects/project_services'
import { findRecursiveTasks, returnTaskPrerequisiteAnalysisObject } from 'app/models/tasks/task_workflow_services'
import { rJSX_SunrunProjectDetailsTab } from 'app/pages/projects/project_view/tab_details_sunrun'
import { rJSX_TeslaProjectDetailsTab } from 'app/pages/projects/project_view/tab_details_tesla'
import { rJSX_DocumentsTab } from 'app/pages/projects/project_view/tab_documents'
import { defaultEmptyMessageThread } from 'app/pages/projects/project_view/tab_messages'
import { rJSX_NotesTab } from 'app/pages/projects/project_view/tab_notes'
import { rJSX_PhotosTab } from 'app/pages/projects/project_view/tab_photos'
import { rJSX_AdditionalWorkTab } from 'app/pages/projects/project_view/tab_sow'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveTaskBlueprints_Query } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_TaskFormProdPages_Document, DatabaseRef_TaskFormsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import { DatabaseRef_ProjectFeedback_Query } from 'rfbp_aux/services/database_endpoints/operations/feedback'
import {
  DatabaseRef_MessageThreadMessages_CollectionOrdered_Query,
  DatabaseRef_ProjectMessageThreadsCollection_Query,
} from 'rfbp_aux/services/database_endpoints/operations/messages'
import {
  DatabaseRef_ProjectAdditionalData_Document,
  DatabaseRef_ProjectDesignPreferences_Document,
  DatabaseRef_ProjectNoteThreadNotes_Collection,
  DatabaseRef_ProjectNoteThreads_Collection,
  DatabaseRef_ProjectRoleAssignments_Document,
  DatabaseRef_ProjectTaskFormData_Collection,
  DatabaseRef_ProjectTaskFormData_Document,
  DatabaseRef_ProjectTaskWorkflow_Document,
  DatabaseRef_Project_Document,
  DatabaseRef_Project_Documents_Collection,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_ProjectReminders_Query } from 'rfbp_aux/services/database_endpoints/operations/reminders'
import { DatabaseRef_AllProjectTasks_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { StorageRef_ProjectImageThumbnailFile, StorageRef_ProjectImageThumbnailsFolder } from 'rfbp_aux/services/storage_endpoints/projects'
import { TsInterface_ChatMessages, TsInterface_ChatThread } from 'rfbp_core/components/chat'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl, TsInterface_TabContentUrlArray } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
  Context_UserInterface_Snackbar,
} from 'rfbp_core/services/context'
import {
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  StorageGetDownloadUrl,
  StorageListFiles,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_ETWEnergyProjectDetailsTab } from '../projects/project_view/tab_details_etw_energy'

///////////////////////////////
// Typescript
///////////////////////////////

type TsType_TaskTableFilterOptions =
  | 'all'
  | 'active_tasks'
  | 'completed_tasks'
  | 'future_tasks'
  | 'deleted'
  | 'not_deleted'
  | 'user_tasks'
  | 'unassigned'
  | 'invalid_prereq_data_structure'
type TsType_NoteTableFilterOptions = 'all' | 'deleted' | 'not_deleted' | 'starred'
type TsType_DocumentTableFilterOptions = 'all' | 'archived' | 'not_archived' | 'starred' | 'visible_to_customer' | 'hidden_from_customer'

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SubKProjectViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ADDITIONAL_WORK: JSX.Element = <Trans>Additional Work</Trans>
const s_BACK_TO_TASKS: JSX.Element = <Trans>Back to tasks</Trans>
const s_DETAILS: JSX.Element = <Trans>Details</Trans>
const s_DOCUMENTS: JSX.Element = <Trans>Documents</Trans>
const s_NOTES: JSX.Element = <Trans>Notes</Trans>
const s_PHOTOS: JSX.Element = <Trans>Photos</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

const scrollToBottomOfChat = (): void => {
  setTimeout(() => {
    let chatBox = document.getElementById('etw_chat_message_display')
    if (chatBox != null) {
      chatBox.scrollTop = chatBox.scrollHeight
    }
  }, 1)
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_projectKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeBlueprintTasks, us_setActiveBlueprintTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_activeTab, us_setActiveTab] = useState<string>('details')
  const [us_additionalSalesforceData, us_setAdditionalSalesforceData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionalWorkTaskType, us_setAdditionalWorkTaskType] = useState<null | string>(null)
  const [us_allTaskFormData, us_setAllTaskFormData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_availableImageTags, us_setAvailableImageTags] = useState<string[]>([])
  const [us_chatThreads, us_setChatThreads] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clientUserRoles, us_setClientUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_combinedUserRoles, us_setCombinedUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_documentTableFilter, us_setDocumentTableFilter] = useState<TsType_DocumentTableFilterOptions>('not_archived')
  const [us_documentsViewLevel, us_setDocumentsViewLevel] = useState<string>('root') // root | folder
  const [us_downloadingPhotos, us_setDownloadingPhotos] = useState<boolean>(false)
  const [us_filteredPhotosList, us_setFilteredPhotosList] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_flatImageTagCounts, us_setFlatImageTagCounts] = useState<TsInterface_UnspecifiedObject>({})
  const [us_flatImageUploadData, us_setFlatImageUploadData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_groupedTaskData, us_setGroupedTaskData] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_loadingMessages, us_setLoadingMessages] = useState<boolean>(false)
  const [us_messageLastSent, us_setMessageLastSent] = useState<number>(0)
  const [us_minimizedCustomerDetails, us_setMinimizedCustomerDetails] = useState<boolean>(false)
  const [us_minimizedCustomerHappiness, us_setMinimizedCustomerHappiness] = useState<boolean>(false)
  const [us_minimizedEstimatedTimeline, us_setMinimizedEstimatedTimeline] = useState<boolean>(false)
  const [us_minimizedFeedbackDetails, us_setMinimizedFeedbackDetails] = useState<boolean>(false)
  const [us_minimizedHomeDetails, us_setMinimizedHomeDetails] = useState<boolean>(true)
  const [us_minimizedMostRecentContact, us_setMinimizedMostRecentContact] = useState<boolean>(false)
  const [us_minimizedProgressDetails, us_setMinimizedProgressDetails] = useState<boolean>(false)
  const [us_minimizedProjectFinancialData, us_setMinimizedProjectFinancialData] = useState<boolean>(true)
  const [us_minimizedProjectTimestampsData, us_setMinimizedProjectTimestampsData] = useState<boolean>(true)
  const [us_minimizedReminderDetails, us_setMinimizedReminderDetails] = useState<boolean>(false)
  const [us_minimizedSalesPartnerSpecificData, us_setMinimizedSalesPartnerSpecificData] = useState<boolean>(true)
  const [us_minimizedStickyNote, us_setMinimizedStickyNote] = useState<boolean>(false)
  const [us_minimizedSystemDetails, us_setMinimizedSystemDetails] = useState<boolean>(true)
  const [us_newNoteEditorVisibility, us_setNewNoteEditorVisibility] = useState<boolean>(false)
  const [us_noteReplyRichContentEditorValue, us_setNoteReplyRichContentEditorValue] = useState<string>('')
  const [us_noteRichContentEditorValue, us_setNoteRichContentEditorValue] = useState<string>('')
  const [us_noteSortOrder, us_setNoteSetOrder] = useState<string>('timestamp_last_updated_desc')
  const [us_noteSubjectValue, us_setNoteSubjectValue] = useState<string>('')
  const [us_noteTableFilter, us_setNoteTableFilter] = useState<TsType_NoteTableFilterOptions>('not_deleted')
  const [us_photosViewLevel, us_setPhotosViewLevel] = useState<string>('root') // root | task | page | subfolder
  const [us_photosViewType, us_setPhotosViewType] = useState<string>('tag') // folder | tag
  const [us_projectDesignPreferences, us_setProjectDesignPreferences] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectDocumentFolders, us_setProjectDocumentFolders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectFeedback, us_setProjectFeedback] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectImageThumbnails, us_setProjectImageThumbnails] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectNoteThreads, us_setProjectNoteThreads] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectOpenReminders, us_setProjectOpenReminders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectRoleAssignments, us_setProjectRoleAssignments] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectRootPhotosFolders, us_setProjectRootPhotosFolders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTaskWorkflow, us_setProjectTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTaskWorkflowUserRoles, us_setProjectTaskWorkflowUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasksLoaded, us_setProjectTasksLoaded] = useState<boolean>(false)
  const [us_refreshingData, us_setRefreshingData] = useState<boolean>(false)
  const [us_refreshingProgressBar, us_setRefreshingProgressBar] = useState<boolean>(false)
  const [us_reloadTaskWorfklow, us_setReloadTaskWorfklow] = useState<number>(0)
  const [us_replyEditorVisibility, us_setReplyEditorVisibility] = useState<boolean>(false)
  const [us_rescursiveTasks, us_setRescursiveTasks] = useState<TsInterface_UnspecifiedObject | null>(null)
  const [us_rootProject, us_setRootProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootProjectLoaded, us_setRootProjectLoaded] = useState<boolean>(false)
  const [us_runningImageAnalysis, us_setRunningImageAnalysis] = useState<boolean>(false)
  const [us_screenSize, us_setScreenSize] = useState<string>('md')
  const [us_selectedAllImageFilterTag, us_setSelectedAllImagesFilterTag] = useState<boolean>(false)
  const [us_selectedDocumentsFolder, us_setSelectedDocumentsFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDocumentsFolderData, us_setSelectedDocumentsFolderData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedImageFilterTags, us_setSelectedImageFilterTags] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedMessageThread, us_setSelectedMessageThread] = useState<TsInterface_ChatThread>(defaultEmptyMessageThread)
  const [us_selectedMessageThreadKey, us_setSelectedMessageThreadKey] = useState<null | string>(null)
  const [us_selectedMessages, us_setSelectedMessages] = useState<TsInterface_ChatMessages>({})
  const [us_selectedNoteThreadKey, us_setSelectedNoteThreadKey] = useState<null | string>(null)
  const [us_selectedPhotosTaskFolder, us_setSelectedPhotosTaskFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolderData, us_setSelectedPhotosTaskFolderData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolderForm, us_setSelectedPhotosTaskFolderForm] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskPageFolder, us_setSelectedPhotosTaskPageFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskPageSubfolder, us_setSelectedPhotosTaskPageSubfolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedThreadNotes, us_setSelectedThreadNotes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskPrerequisitesValidityCheck, us_setTaskPrerequisitesValidityCheck] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskTableFilter, us_setTaskTableFilter] = useState<TsType_TaskTableFilterOptions>('not_deleted')
  const [us_taskTableView, us_setTaskTableView] = useState<string>('tasks') // "tasks", "groups"
  const [us_tempTaskFormOptions, us_setTempTaskFormOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_tempTaskOwnerRolesOptions, us_setTempTaskOwnerRolesOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_usersWithDirectOrScheduledTasksRoles, us_setUsersWithDirectOrScheduledTasksRoles] = useState<TsInterface_UnspecifiedObject>({})
  const umq_isExtraSmallScreen = useMediaQuery('(max-width: 400px)')
  const umq_isLargeScreen = useMediaQuery('(min-width: 961px) and (max-width: 1280px)')
  const umq_isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)')
  const umq_isSmallScreen = useMediaQuery('(min-width: 401px) and (max-width: 600px)')
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (umq_isExtraSmallScreen === true) {
      us_setScreenSize('xs')
    } else if (umq_isSmallScreen === true) {
      us_setScreenSize('sm')
    } else if (umq_isMediumScreen === true) {
      us_setScreenSize('md')
    } else if (umq_isLargeScreen === true) {
      us_setScreenSize('lg')
    } else {
      us_setScreenSize('xl')
    }
    ur_forceRerender()
    return () => {}
  }, [umq_isExtraSmallScreen, umq_isSmallScreen, umq_isMediumScreen, umq_isLargeScreen, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setActiveBlueprintTasks(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTaskBlueprints_Query(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectRoleAssignments({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  // useEffect(() => {
  // 	// Browser Tab Name
  // 	if( us_rootProject != null && us_rootProject.id_number != null ){
  // 		document.title = us_rootProject.id_number
  // 	}
  // }, [ us_rootProject ])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setClientUserRoles(returnClientUserRoles(res_GCK.clientKey))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootProject(newData)
      us_setRootProjectLoaded(true)
      // TODO - TEMP
      if (newData != null && newData['task_completion_stats'] != null && newData['task_completion_stats']['unknown'] != null) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
          cloudFunctionManageRequest('manageTasks', {
            function: 'refreshProjectTaskProgressBar',
            client_key: res_GCK.clientKey,
            project_key: pr_projectKey,
          })
          ur_forceRerender()
        })
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectDesignPreferences(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProjectDesignPreferences_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectRoleAssignments({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectRoleAssignments(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProjectRoleAssignments_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectRoleAssignments({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
        us_setProjectTasksLoaded(true)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
      us_setProjectTasksLoaded(true)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    // TEMP - Forms - only load if tasks can be created
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_TaskFormsActive_Query(res_GCK.clientKey, 'name', false, null, {}))
          .then((res_DGC) => {
            let taskFormOptions: TsInterface_UnspecifiedObject[] = []
            for (let loopFormKey in res_DGC.data) {
              let loopForm = res_DGC.data[loopFormKey]
              taskFormOptions.push({ key: loopForm.key, value: loopForm.name })
            }
            us_setTempTaskFormOptions(taskFormOptions)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    // TEMP - Forms - only load if tasks can be created
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey))
          .then((res_DGD) => {
            us_setAdditionalSalesforceData(res_DGD.data)
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_projectKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let clientUserRoles = returnClientUserRoles(res_GCK.clientKey)
        us_setTempTaskOwnerRolesOptions(objectToArray(clientUserRoles))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_clientUserRoles])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setCombinedUserRoles(returnCombinedTaskRoles(res_GCK.clientKey))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let filteredImages: TsInterface_UnspecifiedObject[] = []
    for (let loopImageIndex in us_flatImageUploadData) {
      let loopImage = us_flatImageUploadData[loopImageIndex]
      for (let loopThumbnailKey in us_projectImageThumbnails) {
        let loopThumbnail = us_projectImageThumbnails[loopThumbnailKey]
        let loopImageName = loopImage.name
        let loopThumbnailName = loopThumbnail.name
        let fileNameWithoutExtension = loopImageName.split('.').slice(0, -1).join('.')
        if (loopThumbnailName.startsWith(fileNameWithoutExtension)) {
          loopImage.thumbnail_url = loopThumbnail.thumbnail_url
        }
      }
      if (us_selectedAllImageFilterTag === true) {
        filteredImages.push(loopImage)
      } else {
        let includePhotoInDisplay = true
        if (objectToArray(us_selectedImageFilterTags).length === 0) {
          includePhotoInDisplay = false
        }
        for (let loopTagKey in us_selectedImageFilterTags) {
          if (us_selectedImageFilterTags[loopTagKey] === true) {
            if (loopImage != null && loopImage.tags != null && loopImage.tags.indexOf(loopTagKey) === -1) {
              includePhotoInDisplay = false
            }
          }
        }
        if (includePhotoInDisplay === true) {
          filteredImages.push(loopImage)
        }
      }
    }
    us_setFilteredPhotosList(filteredImages)
    return () => {}
  }, [us_setFilteredPhotosList, us_flatImageUploadData, us_selectedAllImageFilterTag, us_selectedImageFilterTags, us_projectImageThumbnails])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let allProjectDocumentFolders: TsInterface_UnspecifiedObject = returnProjectDocumentFolders(res_GCK.clientKey)
        let filteredDocumentFolders: TsInterface_UnspecifiedObject = {}
        if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.task_roles != null) {
          for (let loopRoleKey in uc_RootData_ClientUser.task_roles) {
            if (uc_RootData_ClientUser.task_roles[loopRoleKey] === true) {
              for (let loopFolderKey in allProjectDocumentFolders) {
                let loopFolder = allProjectDocumentFolders[loopFolderKey]
                if (loopFolder.subcontractor_visibility != null && loopFolder.subcontractor_visibility[loopRoleKey] === true) {
                  filteredDocumentFolders[loopFolderKey] = loopFolder
                }
              }
            }
          }
        }
        us_setProjectDocumentFolders(filteredDocumentFolders)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, uc_RootData_ClientUser])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSelectedDocumentsFolderData(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null && us_selectedDocumentsFolder != null && us_selectedDocumentsFolder.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_Project_Documents_Collection(res_GCK.clientKey, pr_projectKey, us_selectedDocumentsFolder.key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedDocumentsFolderData({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedDocumentsFolder, pr_projectKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSelectedPhotosTaskFolderData(newData)
      }
      ur_forceRerender()
    }
    if (us_selectedPhotosTaskFolder != null && us_selectedPhotosTaskFolder.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_ProjectTaskFormData_Document(res_GCK.clientKey, pr_projectKey, us_selectedPhotosTaskFolder.key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedPhotosTaskFolderData({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedPhotosTaskFolder, pr_projectKey, ur_forceRerender])

  useEffect(() => {
    if (
      us_selectedPhotosTaskFolder != null &&
      us_selectedPhotosTaskFolder.key != null &&
      us_projectTasks != null &&
      us_projectTasks[us_selectedPhotosTaskFolder.key] != null &&
      us_projectTasks[us_selectedPhotosTaskFolder.key]['associated_task_form_key'] != null
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(
            DatabaseRef_TaskFormProdPages_Document(res_GCK.clientKey, us_projectTasks[us_selectedPhotosTaskFolder.key]['associated_task_form_key']),
          )
            .then((res_DGD) => {
              us_setSelectedPhotosTaskFolderForm(res_DGD.data)
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedPhotosTaskFolderForm({})
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedPhotosTaskFolder, pr_projectKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setAvailableImageTags(returnImageTags(res_GCK.clientKey))
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    if (us_activeTab === 'photos' && pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          StorageListFiles(StorageRef_ProjectImageThumbnailsFolder(res_GCK.clientKey, pr_projectKey))
            .then((res_SLF) => {
              let thumbnailFiles: TsInterface_UnspecifiedObject = {}
              let promiseArray: TsType_UnknownPromise[] = []
              if (res_SLF != null && res_SLF.data != null && res_SLF.data.files != null) {
                for (let loopFileKey in res_SLF.data.files) {
                  thumbnailFiles[loopFileKey] = res_SLF.data.files[loopFileKey]
                  promiseArray.push(
                    StorageGetDownloadUrl(StorageRef_ProjectImageThumbnailFile(res_GCK.clientKey, pr_projectKey, loopFileKey))
                      .then((res_SGDU) => {
                        thumbnailFiles[loopFileKey]['thumbnail_url'] = getProp(res_SGDU, 'url', null)
                      })
                      .catch((rej_SGDU) => {
                        console.error(rej_SGDU)
                      }),
                  )
                }
              }
              Promise.all(promiseArray).finally(() => {
                us_setProjectImageThumbnails(thumbnailFiles)
              })
            })
            .catch((rej_SLF) => {
              console.error(rej_SLF)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_activeTab])

  useEffect(() => {
    let tagCounts: TsInterface_UnspecifiedObject = { all_photos: 0 }
    let calculatedProjectRootPhotosFolders: TsInterface_UnspecifiedObject = {}
    if (us_allTaskFormData != null) {
      let taskFormImages: TsInterface_UnspecifiedObject[] = []
      for (let loopTaskKey in us_allTaskFormData) {
        let loopTaskData = us_allTaskFormData[loopTaskKey]
        for (let loopPageKey in loopTaskData) {
          let loopPageData = loopTaskData[loopPageKey]
          if (loopPageData != null && loopPageData['folders'] != null) {
            for (let loopFolderKey in loopPageData['folders']) {
              let loopFolder = loopPageData['folders'][loopFolderKey]
              for (let loopFileUploadIndex in loopFolder) {
                let loopFileUpload = loopFolder[loopFileUploadIndex]
                if (loopFileUpload != null && loopFileUpload['upload_type'] === 'image') {
                  if (calculatedProjectRootPhotosFolders[loopTaskKey] == null) {
                    calculatedProjectRootPhotosFolders[loopTaskKey] = {
                      key: loopTaskKey,
                      path: null,
                    }
                  }
                  loopFileUpload['TEMP_associated_task_key'] = loopTaskKey
                  loopFileUpload['TEMP_associated_page_key'] = loopPageKey
                  loopFileUpload['TEMP_associated_folder_key'] = loopFolderKey
                  loopFileUpload['TEMP_subfolder_name'] = loopFolder.name
                  loopFileUpload['TEMP_upload_index'] = loopFileUploadIndex
                  taskFormImages.push(loopFileUpload)
                  tagCounts['all_photos']++
                  if (loopFileUpload != null && loopFileUpload['tags'] != null) {
                    let includePhotoInCounts = true
                    for (let loopTagKey in us_selectedImageFilterTags) {
                      if (us_selectedImageFilterTags[loopTagKey] === true) {
                        if (loopFileUpload != null && loopFileUpload.tags != null && loopFileUpload.tags.indexOf(loopTagKey) === -1) {
                          includePhotoInCounts = false
                        }
                      }
                    }
                    if (includePhotoInCounts === true) {
                      for (let loopTagIndex in loopFileUpload['tags']) {
                        let loopTag = loopFileUpload['tags'][loopTagIndex]
                        if (tagCounts[loopTag] == null) {
                          tagCounts[loopTag] = 0
                        }
                        tagCounts[loopTag]++
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      us_setFlatImageUploadData(taskFormImages)
      us_setFlatImageTagCounts(tagCounts)
      us_setProjectRootPhotosFolders(calculatedProjectRootPhotosFolders)
    }
    ur_forceRerender()
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_allTaskFormData, ur_forceRerender, us_selectedImageFilterTags])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllTaskFormData(newData)
      ur_forceRerender()
    }
    if (us_activeTab === 'photos') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectTaskFormData_Collection(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setFlatImageUploadData([])
      us_setFlatImageTagCounts({})
      us_setAllTaskFormData({})
      us_setProjectRootPhotosFolders({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, ur_forceRerender, us_activeTab, us_selectedImageFilterTags])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(res_GCK.clientKey, pr_projectKey))
          .then((res_DGD) => {
            us_setProjectTaskWorkflow(res_DGD.data)
            let taskWorkflowUserRolesList: TsInterface_UnspecifiedObject = {}
            let taskWorkflowUserRolesWithDirectOrScheduledTasksList: TsInterface_UnspecifiedObject = {}
            if (res_DGD.data != null && res_DGD.data['tasks'] != null) {
              for (let loopTaskKey in res_DGD.data['tasks']) {
                let loopTask = res_DGD.data['tasks'][loopTaskKey]
                if (us_reloadTaskWorfklow >= 0) {
                  // Nothing - just used for reloads
                }
                if (loopTask != null && loopTask['associated_owner_type'] != null) {
                  taskWorkflowUserRolesList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  if (loopTask['task_completion_type'] === 'direct' || loopTask['task_completion_type'] === 'dispatcher') {
                    taskWorkflowUserRolesWithDirectOrScheduledTasksList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  }
                }
              }
            }
            us_setProjectTaskWorkflowUserRoles(taskWorkflowUserRolesList)
            us_setUsersWithDirectOrScheduledTasksRoles(taskWorkflowUserRolesWithDirectOrScheduledTasksList)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, us_reloadTaskWorfklow])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectNoteThreads(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectNoteThreads_Collection(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectNoteThreads({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSelectedThreadNotes(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null && us_selectedNoteThreadKey != null && us_selectedNoteThreadKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ProjectNoteThreadNotes_Collection(res_GCK.clientKey, pr_projectKey, us_selectedNoteThreadKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedThreadNotes({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_selectedNoteThreadKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let newMessageObject: TsInterface_UnspecifiedObject = {}
      for (let messageKey in newData) {
        let loopMessage = newData[messageKey]
        newMessageObject[messageKey] = loopMessage
      }
      us_setSelectedMessages(newMessageObject)
      us_setLoadingMessages(false)
      ur_forceRerender()
      scrollToBottomOfChat()
    }
    if (us_selectedMessageThread != null && us_selectedMessageThread.key != null && us_selectedMessageThread.key !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          if (uc_RootData_ClientKey != null) {
            unsubscribeLiveData = DatabaseGetLiveCollection(
              DatabaseRef_MessageThreadMessages_CollectionOrdered_Query(uc_RootData_ClientKey, us_selectedMessageThread.key, 100, null),
              updateLiveData,
            )
          }
        })
        .catch((rej_GCK) => {
          // Should always be set, temp solution used to get messages to rerender on message send
          if (us_messageLastSent != null) {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          }
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [
    ur_forceRerender,
    uc_RootData_ClientKey,
    us_selectedMessageThread,
    uc_setUserInterface_ErrorDialogDisplay,
    uc_setRootData_ClientKey,
    us_messageLastSent,
    us_selectedMessageThreadKey,
  ])

  // TODO - TEMP? (Maybe should be in project creation)
  useEffect(() => {
    if (
      us_rootProject != null &&
      (us_rootProject.additional_project_data == null || us_rootProject.additional_project_data.messages_threads_instantiated !== true)
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          generateDefaultMessageThreadsInDatabase(
            res_GCK.clientKey,
            pr_projectKey,
            generateStandardProjectMessages(
              us_setChatThreads,
              {},
              pr_projectKey,
              us_selectedMessageThreadKey,
              us_setSelectedMessageThread,
              'set_threads_to_state',
            ),
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, us_selectedMessageThreadKey, us_rootProject])

  useEffect(() => {
    // TODO - This listener is linked to one in app.js so it renders two changes one without timestamps so whichever
    // project has the queried unread message gets a weird flash as the unread message goes to the top
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        // setDatabaseChatThreads( newData )
        generateStandardProjectMessages(
          us_setChatThreads,
          newData,
          pr_projectKey,
          us_selectedMessageThreadKey,
          us_setSelectedMessageThread,
          'set_threads_to_state',
        )
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectMessageThreadsCollection_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      generateStandardProjectMessages(us_setChatThreads, {}, pr_projectKey, us_selectedMessageThreadKey, us_setSelectedMessageThread, 'set_threads_to_state')
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_selectedMessageThreadKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectFeedback(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectFeedback_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectFeedback({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_selectedMessageThreadKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectOpenReminders(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectReminders_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectOpenReminders({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_selectedMessageThreadKey])

  useEffect(() => {
    // Generate List of Tasks with bad prerequisite data structure
    if (us_projectTasks != null && objectToArray(us_projectTasks).length > 0) {
      let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(us_projectTasks))
      us_setRescursiveTasks(tasksThatReferenceThemselves)
      let invertedBooleanObject: TsInterface_UnspecifiedObject = {}
      for (let loopKey in tasksThatReferenceThemselves) {
        invertedBooleanObject[loopKey] = !tasksThatReferenceThemselves[loopKey]
      }
      us_setTaskPrerequisitesValidityCheck(invertedBooleanObject)
    }
  }, [us_projectTasks])

  useEffect(() => {
    if (
      us_projectTaskWorkflow != null &&
      us_projectTaskWorkflow.task_groups != null &&
      us_projectTaskWorkflow.task_groups.length > 0 &&
      us_projectTasks != null &&
      objectToArray(us_projectTasks).length > 0
    ) {
      us_setGroupedTaskData(returnGroupedTasksData(us_projectTaskWorkflow.task_groups, us_projectTasks))
    }
  }, [us_projectTaskWorkflow, us_projectTasks])

  // Other Variables
  let projectUsersFlatObject: TsInterface_UnspecifiedObject = {}

  // Functions

  // JSX Generation
  const rJSX_DetailsTab = (): JSX.Element => {
    let detailsTab = <></>
    if (us_rootProject != null && us_rootProject.key != null) {
      if (us_rootProject['associated_sales_partner_key'] === 'sunrun') {
        detailsTab = (
          <Box className="tw-m-auto">
            <Box className="tw-m-auto">
              {rJSX_SunrunProjectDetailsTab(
                'read',
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      } else if (us_rootProject['associated_sales_partner_key'] === 'tesla') {
        detailsTab = (
          <Box className="tw-m-auto">
            <Box className="tw-m-auto">
              {rJSX_TeslaProjectDetailsTab(
                'read',
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      } else if (us_rootProject['associated_sales_partner_key'] === 'etw_energy') {
        detailsTab = (
          <Box className="tw-m-auto">
            <Box className="tw-m-auto">
              {rJSX_ETWEnergyProjectDetailsTab(
                'read',
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      } else {
        detailsTab = (
          <Box className="tw-m-auto">
            <Box className="tw-m-auto">
              {rJSX_ETWEnergyProjectDetailsTab(
                'read',
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      }
    }
    return detailsTab
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    let jobCodeJSX = <></>
    if (us_rootProject != null && us_rootProject.id_number != null) {
      jobCodeJSX = <>{us_rootProject.id_number}</>
    } else {
      jobCodeJSX = <>{rLIB('Project')}</>
    }
    if (us_rootProject != null && us_rootProject.associated_customer_name != null) {
      pageHeader = (
        <>
          {jobCodeJSX}: {us_rootProject.associated_customer_name}
        </>
      )
    } else {
      pageHeader = <>{jobCodeJSX}</>
    }
    return pageHeader
  }

  const rJSX_Page = (): JSX.Element => {
    let tabsData: TsInterface_TabContentUrlArray = []
    tabsData.push({
      tabUrlKey: 'Details',
      tabHeader: s_DETAILS,
      tabOnChange: () => {
        us_setActiveTab('details')
      },
      tabContent: rJSX_DetailsTab(),
    })
    // Design Specific Tabs
    if (uc_RootData_ClientUser != null && uc_RootData_ClientUser.task_roles != null && uc_RootData_ClientUser.task_roles['design'] === true) {
      tabsData.push({
        tabUrlKey: 'Additional_Work',
        tabHeader: s_ADDITIONAL_WORK,
        tabOnChange: () => {
          us_setActiveTab('sow')
        },
        tabContent: (
          <Box className="tw-m-auto">
            <Box className="tw-m-auto">
              {rJSX_AdditionalWorkTab(
                'read',
                pr_projectKey,
                us_projectTaskWorkflow,
                us_projectTasks,
                uc_setUserInterface_FormDialogDisplay,
                us_tempTaskFormOptions,
                us_tempTaskOwnerRolesOptions,
                us_activeBlueprintTasks,
                us_additionalWorkTaskType,
                us_setAdditionalWorkTaskType,
              )}
            </Box>
          </Box>
        ),
      })
      tabsData.push({
        tabUrlKey: 'Notes',
        tabHeader: s_NOTES,
        tabOnChange: () => {
          us_setActiveTab('notes')
        },
        tabContent: (
          <Box className="tw-m-auto">
            <Box className="tw-m-auto">
              {rJSX_NotesTab(
                'read',
                uc_RootData_ClientKey,
                uc_RootData_ClientUser,
                us_newNoteEditorVisibility,
                us_noteReplyRichContentEditorValue,
                us_noteRichContentEditorValue,
                us_noteSortOrder,
                us_noteSubjectValue,
                us_noteTableFilter,
                pr_projectKey,
                us_projectNoteThreads,
                us_replyEditorVisibility,
                us_selectedNoteThreadKey,
                us_selectedThreadNotes,
                us_setNewNoteEditorVisibility,
                us_setNoteReplyRichContentEditorValue,
                us_setNoteRichContentEditorValue,
                us_setNoteSetOrder,
                us_setNoteSubjectValue,
                us_setNoteTableFilter,
                us_setReplyEditorVisibility,
                uc_setRootData_ClientKey,
                us_setSelectedNoteThreadKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        ),
      })
      tabsData.push({
        tabUrlKey: 'Documents',
        tabHeader: s_DOCUMENTS,
        tabOnChange: () => {
          us_setActiveTab('documents')
        },
        tabContent: (
          <Box className="tw-m-auto">
            <Box className="tw-m-auto">
              {rJSX_DocumentsTab(
                'read',
                uc_RootData_ClientKey,
                uc_RootData_ClientUser,
                us_documentTableFilter,
                us_documentsViewLevel,
                us_projectDocumentFolders,
                pr_projectKey,
                us_selectedDocumentsFolder,
                us_selectedDocumentsFolderData,
                us_setDocumentTableFilter,
                us_setDocumentsViewLevel,
                uc_setRootData_ClientKey,
                us_setSelectedDocumentsFolder,
                us_setSelectedDocumentsFolderData,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        ),
      })
    }
    tabsData.push({
      tabUrlKey: 'Photos',
      tabHeader: s_PHOTOS,
      tabOnChange: () => {
        us_setActiveTab('photos')
      },
      tabContent: (
        <Box className="tw-m-auto">
          <Box className="tw-m-auto">
            {rJSX_PhotosTab(
              'read',
              uc_RootData_ClientKey,
              us_allTaskFormData,
              us_availableImageTags,
              us_downloadingPhotos,
              us_filteredPhotosList,
              us_flatImageTagCounts,
              ur_forceRerender,
              us_photosViewLevel,
              us_photosViewType,
              pr_projectKey,
              us_projectRootPhotosFolders,
              us_projectTaskWorkflow,
              us_projectTasks,
              us_selectedAllImageFilterTag,
              us_selectedImageFilterTags,
              us_selectedPhotosTaskFolder,
              us_selectedPhotosTaskFolderData,
              us_selectedPhotosTaskFolderForm,
              us_selectedPhotosTaskPageFolder,
              us_selectedPhotosTaskPageSubfolder,
              us_setDownloadingPhotos,
              us_setPhotosViewLevel,
              us_setPhotosViewType,
              uc_setRootData_ClientKey,
              us_setSelectedAllImagesFilterTag,
              us_setSelectedImageFilterTags,
              us_setSelectedPhotosTaskFolder,
              us_setSelectedPhotosTaskFolderData,
              us_setSelectedPhotosTaskPageFolder,
              us_setSelectedPhotosTaskPageSubfolder,
              uc_setUserInterface_CustomDialogDisplay,
              uc_setUserInterface_ErrorDialogDisplay,
              uc_setUserInterface_FormDialogDisplay,
              uc_setUserInterface_AlertDialogDisplay,
              us_runningImageAnalysis,
              us_setRunningImageAnalysis,
              uc_setUserInterface_SnackbarDisplay,
              us_screenSize,
            )}
          </Box>
        </Box>
      ),
    })

    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              <Button
                color="inherit"
                variant="outlined"
                startIcon={<Icon icon="chevron-left"></Icon>}
                onClick={(event) => {
                  onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SubKActiveTasksListPage.url())
                }}
              >
                {s_BACK_TO_TASKS}
              </Button>
            </Box>
            <TabsUrl
              tabs={tabsData}
              tabsSettings={{
                baseUrl: ApplicationPages.SubKProjectViewPage.url(pr_projectKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: getProp(us_rootProject, 'id_number', 'Project'),
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { Trans } from 'react-i18next'
import textLibrary from '../../app/localization/library_en.json'

///////////////////////////////
// Exports
///////////////////////////////

export function rLIB<K extends keyof typeof textLibrary>(key: K, translate: boolean = true): string | JSX.Element {
  if (textLibrary == null || textLibrary[key] === undefined) {
    return key
  } else if (translate) {
    return <Trans>{textLibrary[key]}</Trans>
  } else {
    return textLibrary[key]
  }
}

///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_UserFile = (clientKey: string, trailerKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/user/' + trailerKey + '/files/' + fileName)
}

export const StorageRef_User_MessageThread_File = (clientKey: string, trailerKey: string, threadKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/user/' + trailerKey + '/threads/' + threadKey + '/files/' + fileName)
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_TimeSheetsAllocationTypes_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'timesheets', 'allocation_types', 'main')
}

export const DatabaseRef_TimeSheetsAllocationTypes_Document = (clientKey: string, allocationKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'timesheets', 'allocation_types', 'main', allocationKey)
}

export const DatabaseRef_ActiveTimeSheetAllocationTypes_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'allocation_types', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Example documentation for code displays

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import { Html, Json } from 'rfbp_core/components/code_display'
import { ComponentDocumentation, TsInterface_ComponentDocumentation } from 'rfbp_core/components/_example_components'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

// Html strings
const JsonHtmlExample = `    <Json
	alphabetized={ true }
	data={ data }
	/>`
const HtmlHtmlExample = `    <Html
	data={ data }
	/>`

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const ExampleCodeDisplays = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Other Variables
  const documentationObject: TsInterface_ComponentDocumentation = {
    group_name: 'Code Displays',
    group_description: 'Code display components display code ¯\\_(ツ)_/¯',
    components: {
      Html: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">Html</span>
          </Box>
        ),
        component_description: 'Renders Html editor looking card',
        component_example_jsx_string: HtmlHtmlExample,
        component_jsx: (
          <Box>
            <Html data={`<h1>Hello World</h1>`} />
          </Box>
        ),
        component_props: {
          data: {
            propKey: 'data',
            propTypeOrInterface: 'string',
            propDescription:
              'The data that you want rendered as an html code editor looking pre tag (will look weird in the followup json thing but check out next tab)',
            propExampleValue: `<h1>Hello World</h1>`,
          },
        },
        component_todo: {
          0: 'Nothing',
        },
      },
      Json: {
        component_name: (
          <Box component="span">
            <span className="tw-font-bold tw-inline-block tw-align-top tw-normal-case">Json</span>
          </Box>
        ),
        component_description: 'Renders Json editor looking card',
        component_example_jsx_string: JsonHtmlExample,
        component_jsx: (
          <Box>
            <Json
              alphabetized={true}
              data={{ key1: 'value1', key2: 'value2' }}
            />
          </Box>
        ),
        component_props: {
          alphabetized: {
            propKey: 'alphabetized',
            propTypeOrInterface: 'boolean',
            propDescription: 'Whether or not properies should be alpehebetized or not',
            propExampleValue: 'true',
          },
          data: {
            propKey: 'data',
            propTypeOrInterface: 'TsInterface_UnspecifiedObject',
            propDescription: 'The data that you want rendered as an html code editor looking pre tag',
            propExampleValue: { key1: 'value1', key2: 'value2' },
          },
        },
        component_todo: {
          0: 'Nothing',
        },
      },
    },
  }

  // Functions

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <ComponentDocumentation documentation={documentationObject} />
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

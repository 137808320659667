///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button } from '@mui/material/'
import { TaskFormCustomDialog } from 'app/models/tasks/task_form_services'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ScheduledTasksInDateRangeArray_Query } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatePicker } from 'rfbp_core/components/date_picker'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_RootData_ClientUser, Context_UserInterface_CustomDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection } from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['LiveReviewIndexPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_IMAGES_FINISHED_WITH_OBJECT_DETECTION: JSX.Element = 				<Trans>Images finished with object detection</Trans>
// const s_IMAGES_INCLUDED_IN_AGGREGATE_ANALYSIS: JSX.Element = 				<Trans>Images included in aggregate analysis</Trans>
// const s_MAXIMUM_OF: JSX.Element = 											<Trans>Maximum of</Trans>
// const s_MESSAGES: JSX.Element = 												<Trans>messages</Trans>
// const s_MINIMUM_OF: JSX.Element = 											<Trans>Minimum of</Trans>
// const s_ML_AGGREGATE_RESULTS: JSX.Element = 									<Trans>ML Aggregate Results</Trans>
// const s_NOT_EVALUATED_YET: JSX.Element = 									<Trans>Not evaluated yet</Trans>
// const s_NO_ERROR_OR_WARNING_MESSAGES: JSX.Element = 							<Trans>No error or warning messages</Trans>
// const s_NO_MAXIMUM: JSX.Element = 											<Trans>No maximum</Trans>
// const s_NO_MINIMUM: JSX.Element = 											<Trans>No minimum</Trans>
// const s_OF: JSX.Element = 													<Trans>of</Trans>
// const s_RUN_ML_ANALYSIS: JSX.Element = 										<Trans>Run ML Analysis</Trans>
// const s_SHOWING: JSX.Element = 												<Trans>Showing</Trans>
// const s_UPLOAD: JSX.Element = 												<Trans>Upload</Trans>
// const s_UPLOADS: JSX.Element = 												<Trans>Uploads</Trans>
// const s_VIEWING_ALL_MESSAGES: JSX.Element = 									<Trans>Viewing all messages</Trans>
// const s_VIEWING_ONLY_ERROR_MESSAGES: JSX.Element = 							<Trans>Viewing only error messages</Trans>
// const s_VIEW_INSTALL_TASK: JSX.Element = 									<Trans>View Install Task</Trans>
const s_LIVE_REVIEW: JSX.Element = <Trans>Live Review</Trans>
const se_LIVE_REVIEW: string = 'Live Review'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

// function sortByTwoProperties(arr: TsInterface_UnspecifiedObject[], firstProp: string, secondProp: string) {
// 	arr.sort((a, b) => {
// 		if (a[firstProp] === b[firstProp]) {
// 			return a[secondProp] < b[secondProp] ? -1 : 1;
// 		}
// 		return a[firstProp] < b[firstProp] ? -1 : 1;
// 	});
// 	return arr;
// }

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  // const [ us_activeTasksProjectData, us_setActiveTasksProjectData ] = 	useState<TsInterface_UnspecifiedObject>( {} )
  // const [ us_items, us_setItems ] = 		useState<TsInterface_UnspecifiedObject>({})
  // const [ us_openImageFolderKey, us_setOpenImageFolderKey ] = 			useState<string | null>( null )
  // const [ us_openImagePageKey, us_setOpenImagePageKey ] = 				useState<string | null>( null )
  // const [ us_openImagePreview, us_setOpenImagePreview ] = 				useState<boolean>( false )
  // const [ us_openImagePreviewData, us_setOpenImagePreviewData ] = 		useState<TsInterface_UnspecifiedObject>( {} )
  // const [ us_runningMLAnalysis, us_setRunningMLAnalysis ] = 				useState<boolean>( false )
  // const [ us_showAggregateMessageType, us_setShowAggregateMessageType ] = useState<string>( "errors" )
  // const [ us_taskForms, us_setTaskForms ] = 								useState<TsInterface_UnspecifiedObject>( {} )
  // const [ us_taskMLAggregateResults, us_setTaskMLAggregateResults ] = 	useState<TsInterface_UnspecifiedObject>( {} )
  // const [ us_taskMLImages, us_setTaskMLImages ] = 						useState<TsInterface_UnspecifiedObject>( {} )
  // const [ us_visibleProject, us_setVisibleProject ] = 					useState<string | null>( null )
  // const { uc_RootData_ClientUser } = 										useContext( Context_RootData_ClientUser )
  // const { uc_setUserInterface_SnackbarDisplay } = 						useContext( Context_UserInterface_Snackbar )
  const [us_activeTasks, us_setActiveTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_formattedDate, us_setFormattedDate] = useState<string | null>(null)
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_LIVE_REVIEW
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey])

  useEffect(() => {
    let formattedDateKey: string = returnFormattedDateKey(us_selectedDate)
    us_setFormattedDate(formattedDateKey)
  }, [us_selectedDate])

  useEffect(() => {
    let formattedDateKey: string = returnFormattedDateKey(us_selectedDate)
    us_setFormattedDate(formattedDateKey)
  }, [us_selectedDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          // let taskMlImages: TsInterface_UnspecifiedObject = {}
          // let taskMLAggregateResults: TsInterface_UnspecifiedObject = {}
          // let taskForms: TsInterface_UnspecifiedObject = {}
          let filteredData: TsInterface_UnspecifiedObject = {}
          // let projectTaskData: TsInterface_UnspecifiedObject = {}
          let promiseArray: TsType_UnknownPromise[] = []
          // let taskFormsToLoad: TsInterface_UnspecifiedObject = {}
          for (let loopTaskKey in newData) {
            let loopTask = newData[loopTaskKey]
            if (loopTask.associated_team_type === 'install_crew') {
              filteredData[loopTaskKey] = loopTask
              // if( loopTask.associated_project_key != null ){
              // promiseArray.push( DatabaseGetDocument( DatabaseRef_ProjectTaskFormData_Document( res_GCK.clientKey, loopTask.associated_project_key, loopTaskKey ) ).then(( res_GD ) => {
              // 	projectTaskData[ loopTaskKey ] = res_GD.data
              // }).catch(( rej_GD ) => {
              // 	console.error( rej_GD )
              // }) )
              // promiseArray.push( DatabaseGetCollection( DatabaseRef_ProjectMLImageEvaluationsForTask_Query( res_GCK.clientKey, loopTask.associated_project_key, loopTaskKey ) ).then(( res_GD ) => {
              // 	taskMlImages[ loopTaskKey ] = res_GD.data
              // }).catch(( rej_GD ) => {
              // 	console.error( rej_GD )
              // }) )
              // promiseArray.push( DatabaseGetCollection( DatabaseRef_ProjectMLAggregateEvaluations_Collection( res_GCK.clientKey, loopTask.associated_project_key ) ).then(( res_GD ) => {
              // 	taskMLAggregateResults[ loopTaskKey ] = res_GD.data
              // }).catch(( rej_GD ) => {
              // 	console.error( rej_GD )
              // }) )
              // }
              // if( loopTask.associated_task_form_key != null ){
              // 	taskFormsToLoad[loopTask.associated_task_form_key] = true
              // }
            }
          }
          // for( let loopTaskFormKey in taskFormsToLoad ){
          // promiseArray.push( DatabaseGetDocument( DatabaseRef_TaskFormProdPages_Document( res_GCK.clientKey, loopTaskFormKey ) ).then(( res_GD ) => {
          // 	taskForms[ loopTaskFormKey ] = res_GD.data
          // }).catch(( rej_GD ) => {
          // 	console.error( rej_GD )
          // }) )
          // }
          Promise.all(promiseArray).finally(() => {
            // us_setTaskMLAggregateResults( taskMLAggregateResults )
            // us_setTaskMLImages( taskMlImages )
            us_setActiveTasks(filteredData)
            // us_setActiveTasksProjectData( projectTaskData )
            // us_setTaskForms( taskForms )
            ur_forceRerender()
          })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    if (us_formattedDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ScheduledTasksInDateRangeArray_Query(res_GCK.clientKey, [us_formattedDate]),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_formattedDate])

  // Functions
  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  // const runMLAnalysisOnFolder = (
  // 	clientKey: string,
  // 	projectKey: string,
  // 	taskKey: string,
  // 	pageKey: string,
  // 	folderKey: string,
  // 	taskFormData: TsInterface_UnspecifiedObject,
  // ): TsType_UnknownPromise => {
  // 	return new Promise((resolve, reject) => {
  // 		// If all the images have not been analyzed
  // 		let imageArray: TsInterface_UnspecifiedObject[] = []
  // 		if(
  // 			taskKey != null &&
  // 			pageKey != null &&
  // 			folderKey != null &&
  // 			taskFormData != null &&
  // 			taskFormData[ pageKey ] != null &&
  // 			taskFormData[ pageKey ]["folders"] != null &&
  // 			taskFormData[ pageKey ]["folders"][ folderKey ] != null
  // 		){
  // 			for( let loopImageKey in taskFormData[ pageKey ]["folders"][ folderKey ] ){
  // 				let loopImage = taskFormData[ pageKey ]["folders"][ folderKey ][ loopImageKey ]
  // 				if(
  // 					loopImage != null &&
  // 					loopImage.key != null &&
  // 					loopImage.url != null
  // 				){
  // 					let analysisAlreadyRan = false
  // 					if(
  // 						us_taskMLImages != null &&
  // 						us_taskMLImages[ taskKey ] != null &&
  // 						us_taskMLImages[ taskKey ][ loopImage.key ] != null &&
  // 						us_taskMLImages[ taskKey ][ loopImage.key ]["task_folder_model_mapping"] != null &&
  // 						us_taskMLImages[ taskKey ][ loopImage.key ]["task_folder_model_mapping"][ taskKey ] != null &&
  // 						us_taskMLImages[ taskKey ][ loopImage.key ]["task_folder_model_mapping"][ taskKey ][ pageKey ] != null &&
  // 						us_taskMLImages[ taskKey ][ loopImage.key ]["task_folder_model_mapping"][ taskKey ][ pageKey ][ folderKey ] != null
  // 					){
  // 						analysisAlreadyRan = true
  // 					}
  // 					if( analysisAlreadyRan === false ){
  // 						imageArray.push({
  // 							key: loopImage.key,
  // 							url: loopImage.url
  // 						})
  // 					}
  // 				}
  // 			}
  // 		}
  // 		if( imageArray.length > 0 ){
  // 			// Run ML Analysis on Images
  // 			us_setRunningMLAnalysis( true )
  // 			cloudFunctionManageRequest('manageMLFunctions',{
  // 				function: "runImagesAnalysis",
  // 				client_key: clientKey,
  // 				project_key: projectKey,
  // 				task_key: taskKey,
  // 				task_page_key: pageKey,
  // 				task_folder_key: folderKey,
  // 				image_url_array: imageArray, // [ { "key": "$imageKey", "url": "$imageUrl"}, ... ]
  // 				show_logs: true
  // 			}).then((res_CFMMLFR) => {
  // 				cloudFunctionManageRequest('manageMLFunctions',{
  // 					function: "runAggregateAnalysis",
  // 					client_key: clientKey,
  // 					project_key: projectKey,
  // 					task_key: taskKey,
  // 					task_page_key: pageKey,
  // 					task_folder_key: folderKey,
  // 				}).then((res_CFMMLFR) => {
  // 					us_setRunningMLAnalysis( false )
  // 					resolve( res_CFMMLFR )
  // 				}).catch((rej_CFMMLFR) => {
  // 					us_setRunningMLAnalysis( false )
  // 					reject( rej_CFMMLFR )
  // 				})
  // 			}).catch((rej_CFMMLFR) => {
  // 				us_setRunningMLAnalysis( false )
  // 				reject( rej_CFMMLFR )
  // 			})
  // 		} else {
  // 			us_setRunningMLAnalysis( true )
  // 			cloudFunctionManageRequest('manageMLFunctions',{
  // 				function: "runAggregateAnalysis",
  // 				client_key: clientKey,
  // 				project_key: projectKey,
  // 				task_key: taskKey,
  // 				task_page_key: pageKey,
  // 				task_folder_key: folderKey,
  // 			}).then((res_CFMMLFR) => {
  // 				us_setRunningMLAnalysis( false )
  // 				resolve( res_CFMMLFR )
  // 			}).catch((rej_CFMMLFR) => {
  // 				us_setRunningMLAnalysis( false )
  // 				reject( rej_CFMMLFR )
  // 			})
  // 		}
  // 	})
  // }

  // JSX Generation
  // const rJSX_toggleMLMessageTypes = (
  // 	messageCount: number,
  // 	visibleMessageCount: number,
  // ): JSX.Element => {
  // 	let toggleJSX = <></>
  // 	if( us_showAggregateMessageType === "errors" ){
  // 		toggleJSX =
  // 		<Box className="tw-mb-1 tw-px-1 tw-rounded">
  // 			<Box className="tw-inline-block tw-opacity-30" sx={{ fontSize: "16px" }}>
  // 				{ s_SHOWING } { visibleMessageCount } { s_OF } { messageCount } { s_MESSAGES }
  // 			</Box>
  // 			<Tooltip title={ s_VIEWING_ONLY_ERROR_MESSAGES } placement="right">
  // 				<Box className="tw-ml-2" component={"span"} onClick={ () => {
  // 					us_setShowAggregateMessageType( "all")
  // 				}}>
  // 					<Icon icon="message-exclamation" className="tw-mr-2" sx={{ color: themeVariables.error_light }} />
  // 				</Box>
  // 			</Tooltip>
  // 		</Box>
  // 	} else {
  // 		toggleJSX =
  // 		<Box className="tw-mb-1 tw-px-1 tw-rounded">
  // 			<Box className="tw-inline-block tw-opacity-30" sx={{ fontSize: "16px" }}>
  // 				{ s_SHOWING } { visibleMessageCount } { s_OF } { messageCount } { s_MESSAGES }
  // 			</Box>
  // 			<Tooltip title={ s_VIEWING_ALL_MESSAGES } placement="right">
  // 				<Box className="tw-ml-2" component={"span"} onClick={ () => {
  // 					us_setShowAggregateMessageType( "errors")
  // 				}}>
  // 					<Icon icon="message-check" className="tw-mr-2" sx={{ color: themeVariables.success_light }} />
  // 				</Box>
  // 			</Tooltip>
  // 		</Box>
  // 	}
  // 	return toggleJSX
  // }

  // const rJSX_ImageUploadTaskPreview = (
  // 	clientKey: string,
  // 	userName: string,
  // 	projectKey: string,
  // 	taskKey: string,
  // 	page: TsInterface_UnspecifiedObject,
  // 	taskFormData: TsInterface_UnspecifiedObject,
  // 	editable: boolean,
  // 	uc_setUserInterface_CustomDialogDisplay: any
  // ): JSX.Element => {
  // 	let fileUploadJSX =
  // 	<Box>
  // 		{ objectToArray( page.folders ).sort( dynamicSort("order", "asc" )).map(( folder, folderIndex ) => (
  // 			<Box key={folderIndex} >
  // 				{ rJSX_ImageUploadFolder(
  // 					clientKey,
  // 					userName,
  // 					projectKey,
  // 					taskKey,
  // 					page,
  // 					page.key,
  // 					folder,
  // 					taskFormData,
  // 					editable,
  // 					uc_setUserInterface_CustomDialogDisplay
  // 				) }
  // 				<Divider />
  // 			</Box>
  // 		))}
  // 	</Box>
  // 	return fileUploadJSX
  // }

  // const rJSX_ImagePreview = (
  // 	file: TsInterface_UnspecifiedObject
  // ): JSX.Element => {
  // 	let imageJSX = <></>
  // 	if( file != null && file.name.endsWith('.heic') ){
  // 		imageJSX =
  // 		<Box
  // 			sx={{
  // 				width: "60px",
  // 				height: "40px",
  // 				border: "1px solid " + themeVariables.background_json,
  // 				borderRadius: "5px",
  // 			}}
  // 			className="tw-opacity-40 tw-p-1"
  // 		>
  // 			.heic
  // 		</Box>
  // 	} else {
  // 		imageJSX =
  // 		<img
  // 			src={file.url}
  // 			alt={file.name}
  // 			loading="lazy"
  // 			width={"60px"}
  // 		/>
  // 	}
  // 	return imageJSX
  // }

  // const rJSX_MLAnalysisData = (
  // 	folder: TsInterface_UnspecifiedObject,
  // 	file: TsInterface_UnspecifiedObject,
  // 	projectKey: string,
  // 	pageKey: string,
  // 	folderKey: string
  // ): JSX.Element => {
  // 	let mlAnalysisJSX = <></>
  // 	if(
  // 		folder["run_ml_evaluation_procedure_on_images"] === true &&
  // 		folder["associated_ml_evaluation_procedure_key"] != null
  // 	){
  // 		let mlAnalysisComplete: boolean = false
  // 		let modelKey = null
  // 		let boundingBoxListJSX = <></>
  // 		let timestampCompleteJSX = <></>
  // 		if(
  // 			us_taskMLImages != null &&
  // 			us_taskMLImages[ file.key ] != null
  // 		){
  // 			if(
  // 				us_taskMLImages[ file.key ]["task_folder_model_mapping"] != null &&
  // 				us_taskMLImages[ file.key ]["task_folder_model_mapping"][taskKey] != null &&
  // 				us_taskMLImages[ file.key ]["task_folder_model_mapping"][taskKey][pageKey] != null &&
  // 				us_taskMLImages[ file.key ]["task_folder_model_mapping"][taskKey][pageKey][folderKey] != null
  // 			){
  // 				modelKey = us_taskMLImages[ file.key ]["task_folder_model_mapping"][taskKey][pageKey][folderKey]
  // 				mlAnalysisComplete = true
  // 			}
  // 			if(
  // 				modelKey != null &&
  // 				us_taskMLImages[ file.key ]["model_results"] != null &&
  // 				us_taskMLImages[ file.key ]["model_results"][ modelKey ] != null &&
  // 				us_taskMLImages[ file.key ]["model_results"][ modelKey ]["timestamp_evaluation_completed"] != null
  // 			){
  // 				timestampCompleteJSX =
  // 				<Box className="tw-ml-1 tw-opacity-20 tw-inline-block">
  // 					({ returnFormattedDate( us_taskMLImages[ file.key ]["model_results"][ modelKey ]["timestamp_evaluation_completed"], "D MMM YYYY h:mm a" ) })
  // 				</Box>
  // 				if(
  // 					us_taskMLImages[ file.key ]["model_results"][ modelKey ]["model_output"] != null &&
  // 					us_taskMLImages[ file.key ]["model_results"][ modelKey ]["model_output"].length > 0
  // 				){
  // 					let boundingBoxes = us_taskMLImages[ file.key ]["model_results"][ modelKey ]["model_output"]
  // 					boundingBoxListJSX =
  // 					<Box className="tw-ml-6">
  // 						<Box sx={{ color: themeVariables.white }}>
  // 							<Box className="tw-inline-block" sx={{ color: themeVariables.secondary_main }}>
  // 								<Icon icon="brackets-square" className="tw-mr-2" type="regular" />
  // 							</Box>
  // 							{ objectToArray( boundingBoxes ).sort( dynamicSort("confidence", "desc") ).map(( box: TsInterface_UnspecifiedObject, index: number ) => (
  // 								<Box key={ index } className="tw-inline-block tw-mr-1">
  // 									<Box className="tw-inline-block" sx={{ color: themeVariables.secondary_main }}>
  // 										{ box.category_string }
  // 									</Box>
  // 									<Box className="tw-inline-block tw-ml-1 tw-opacity-20">
  // 										({ (box.confidence * 100).toFixed(1) }%)
  // 									</Box>
  // 								</Box>
  // 							))}
  // 						</Box>
  // 					</Box>
  // 				} else {
  // 					boundingBoxListJSX =
  // 					<Box className="tw-ml-6">
  // 						<Box sx={{ color: themeVariables.white }}>
  // 							<Box className="tw-inline-block tw-opacity-20" sx={{ color: themeVariables.white }}>
  // 								<Icon icon="brackets-square" className="tw-mr-2" type="regular" />
  // 							</Box>
  // 							<Box className="tw-inline-block tw-opacity-20">
  // 								{ s_NO_TARGET_OBJECTS_DETECTED }
  // 							</Box>
  // 						</Box>
  // 					</Box>
  // 				}
  // 			}
  // 		}
  // 		if( mlAnalysisComplete === true ){
  // 			mlAnalysisJSX =
  // 			<Box>
  // 				<Box sx={{ color: themeVariables.success_main }} className="tw-inline-block">
  // 					<Icon icon="robot" className="tw-mr-2" />
  // 					{ s_ML_ANALYSIS_COMPLETE }
  // 				</Box>
  // 				{ timestampCompleteJSX }
  // 				{ boundingBoxListJSX }
  // 			</Box>
  // 		} else {
  // 			mlAnalysisJSX =
  // 			<Box>
  // 				<Box sx={{ opacity: 0.2 }}>
  // 					<Icon icon="robot" className="tw-mr-2" />
  // 					{ s_ML_ANALYSIS_NOT_COMPLETE }
  // 					{ rJSX_RunMLAnalysisButton(
  // 						folder,
  // 						file,
  // 						projectKey,
  // 						pageKey,
  // 						folderKey
  // 					) }
  // 				</Box>
  // 			</Box>
  // 		}
  // 	}
  // 	return mlAnalysisJSX
  // }

  // const rJSX_ImagesUploaded = (
  // 	clientKey: string,
  // 	projectKey: string,
  // 	taskKey: string,
  // 	pageKey: string,
  // 	folder: TsInterface_UnspecifiedObject,
  // 	folderKey: string,
  // 	taskFormData: TsInterface_UnspecifiedObject,
  // 	uc_setUserInterface_CustomDialogDisplay: any
  // ): JSX.Element => {
  // 	let filesJSX = <></>
  // 	let filesList: TsInterface_UnspecifiedObject[] = []
  // 	if(
  // 		pageKey != null &&
  // 		folderKey != null &&
  // 		taskFormData != null &&
  // 		taskFormData[pageKey] != null &&
  // 		taskFormData[pageKey]["folders"] != null &&
  // 		taskFormData[pageKey]["folders"][folderKey] != null
  // 	){
  // 		filesList = taskFormData[pageKey]["folders"][folderKey]
  // 	}

  // 	// TODO - if heic show preview square thing?

  // 	// JSX
  // 	filesJSX =
  // 	<Box className="tw-mb-2">
  // 		{filesList.sort( dynamicSort("name", "asc" )).map(( file: TsInterface_UnspecifiedObject, fileIndex: number ) => (
  // 			<Box key={ fileIndex }>
  // 				<Accordion sx={{ borderBottom: "1px solid " + themeVariables.background_paper }}>
  // 					<AccordionSummary
  // 						// sx={{ width: "100%" }}
  // 						expandIcon={<Icon icon="angle-down" />}
  // 						aria-controls="panel1a-content"
  // 						id="panel1a-header"
  // 					>
  // 						<Box sx={{ display: "block", width: "100%" }}>
  // 							<Box
  // 								className="tw-inline-block tw-mr-2 tw-mt-2 tw-align-top"
  // 								sx={{ width: "70px" }}
  // 								onClick={ (event) => {
  // 									event.stopPropagation()
  // 									// if( event.detail === 2 ){ // Double Click
  // 										// window.open(file.url as string, '_blank');
  // 										us_setOpenImagePreview( true )
  // 										us_setOpenImagePreviewData( file )
  // 										us_setOpenImagePageKey( pageKey )
  // 										us_setOpenImageFolderKey( folderKey )
  // 									// }
  // 								}}
  // 							>
  // 								{ rJSX_ImagePreview( file ) }
  // 							</Box>
  // 							<Box className="tw-inline-block" sx={{ width: "calc( 100% - 80px )" }}>
  // 								<Box>
  // 									<Box className="tw-inline-block tw-mr-2" >{ file.name }</Box>
  // 								</Box>
  // 								<Box className="tw-inline-block tw-mr-2" sx={{ color: themeVariables.warning_main }} >
  // 									<Icon icon="tags" className="tw-mr-2"/>
  // 									{ getProp( file, "tags", []).join(", ") }
  // 								</Box>
  // 								{ rJSX_MLAnalysisData(
  // 									folder,
  // 									file,
  // 									projectKey,
  // 									pageKey,
  // 									folderKey
  // 								) }
  // 							</Box>
  // 						</Box>
  // 					</AccordionSummary>
  // 					<AccordionDetails sx={{ background: themeVariables.background_json }}>
  // 						{ rJSX_ImageTagsEditor(
  // 							clientKey,
  // 							projectKey,
  // 							taskKey,
  // 							pageKey,
  // 							folderKey,
  // 							taskFormData,
  // 							file,
  // 							fileIndex,
  // 							{ display: "block", paddingLeft: "0px" }
  // 						) }
  // 					</AccordionDetails>
  // 				</Accordion>
  // 			</Box>
  // 		))}
  // 	</Box>
  // 	return filesJSX
  // }

  // const rJSX_NumberOfImagesAnalyzed = (
  // 	filesList: any,
  // 	modelResults: any,
  // 	imagesIncludedInAggregateEvaluationCount: number,
  // 	imagesNotIncludedInAggregateEvaluationCount: number,
  // 	lastAggregateAnalysisTimestamp: number | null,
  // ): JSX.Element => {
  // 	let messageJSX = <></>

  // 	let totalImagesCount = filesList.length
  // 	let evaluatedImagesCount = objectToArray( modelResults ).filter((photoResult) => photoResult.status === "success" ).length

  // 	let individualAnalysisTextJSX = <></>
  // 	if( evaluatedImagesCount < totalImagesCount ){
  // 		individualAnalysisTextJSX =
  // 		<Box className="tw-mb-1 tw-px-1 tw-rounded" sx={{ fontSize: "16px", background: themeVariables.warning_main }}>
  // 			<Box className="tw-opacity-100">
  // 				<Box className="tw-inline-block tw-mr-1">
  // 					{ evaluatedImagesCount } { s_OF } { totalImagesCount }
  // 				</Box>
  // 				{ s_IMAGES_FINISHED_WITH_OBJECT_DETECTION }
  // 			</Box>
  // 		</Box>
  // 	} else {
  // 		individualAnalysisTextJSX =
  // 		<Box className="tw-mb-1 tw-px-1 tw-rounded" sx={{ fontSize: "16px" }}>
  // 			<Box className="tw-opacity-30">
  // 				<Box className="tw-inline-block tw-mr-1">
  // 					{ evaluatedImagesCount } { s_OF } { totalImagesCount }
  // 				</Box>
  // 				{ s_IMAGES_FINISHED_WITH_OBJECT_DETECTION }
  // 			</Box>
  // 		</Box>
  // 	}

  // 	let aggregateAnalysisTextJSX = <></>
  // 	if( imagesIncludedInAggregateEvaluationCount < totalImagesCount ){
  // 		aggregateAnalysisTextJSX =
  // 		<Box className="tw-mb-1 tw-px-1 tw-rounded" sx={{ fontSize: "16px", background: themeVariables.warning_main }}>
  // 			<Box className="tw-opacity-100">
  // 				<Box className="tw-inline-block tw-mr-1">
  // 					{ imagesIncludedInAggregateEvaluationCount } { s_OF } { totalImagesCount }
  // 				</Box>
  // 				{ s_IMAGES_INCLUDED_IN_AGGREGATE_ANALYSIS }
  // 			</Box>
  // 		</Box>
  // 	} else {
  // 		aggregateAnalysisTextJSX =
  // 		<Box className="tw-mb-1 tw-px-1 tw-rounded" sx={{ fontSize: "16px" }}>
  // 			<Box className="tw-opacity-30">
  // 				<Box className="tw-inline-block tw-mr-1">
  // 					{ imagesIncludedInAggregateEvaluationCount } { s_OF } { totalImagesCount }
  // 				</Box>
  // 				{ s_IMAGES_INCLUDED_IN_AGGREGATE_ANALYSIS }
  // 			</Box>
  // 		</Box>
  // 	}
  // 	messageJSX =
  // 	<Box>
  // 		{ individualAnalysisTextJSX }
  // 		{ aggregateAnalysisTextJSX }
  // 	</Box>
  // 	return messageJSX
  // }

  // const rJSX_ImageUploadFolder = (
  // 	clientKey: string,
  // 	userName: string,
  // 	projectKey: string,
  // 	taskKey: string,
  // 	page: TsInterface_UnspecifiedObject,
  // 	pageKey: string,
  // 	folder: TsInterface_UnspecifiedObject,
  // 	taskFormData: TsInterface_UnspecifiedObject,
  // 	editable: boolean,
  // 	uc_setUserInterface_CustomDialogDisplay: any
  // ): JSX.Element => {
  // 	// Instantiate Variables
  // 	let fileUploadTaskPreviewJSX = <></>
  // 	let minimumFilesJSX = <></>
  // 	let maximumFilesJSX = <></>
  // 	let mlButtonJSX = <></>
  // 	let mlAggregateResultsJSX = <></>
  // 	let minUploadsTextJSX = s_UPLOADS
  // 	let maxUploadsTextJSX = s_UPLOADS
  // 	// JSX Generation
  // 	if( folder["min_file_upload_count"] === 1 ){
  // 		minUploadsTextJSX = s_UPLOAD
  // 	}
  // 	if( folder["max_file_upload_count"] === 1 ){
  // 		maxUploadsTextJSX = s_UPLOAD
  // 	}
  // 	if(
  // 		folder["run_ml_evaluation_procedure_on_images"] === true &&
  // 		folder["associated_ml_evaluation_procedure_key"] != null
  // 	){
  // 		// Button JSX
  // 		let mlButtonIconJSX = <Icon icon="robot" className="tw-mr-2" />
  // 		if( us_runningMLAnalysis === true ){
  // 			mlButtonIconJSX = <Icon icon="arrows-rotate" className="bp_spin tw-mr-2" />
  // 		}

  // 		let buttonVariant: "contained" | "outlined" = "outlined"
  // 		// If there are any images that have not been analyzed
  // 		let mostRecentImageAnalysisTimestamp = 0
  // 		let filesList: TsInterface_UnspecifiedObject[] = []
  // 		if(
  // 			pageKey != null &&
  // 			folder != null &&
  // 			folder.key != null &&
  // 			taskFormData != null &&
  // 			taskFormData[pageKey] != null &&
  // 			taskFormData[pageKey]["folders"] != null &&
  // 			taskFormData[pageKey]["folders"][folder.key] != null
  // 		){
  // 			filesList = taskFormData[pageKey]["folders"][folder.key]
  // 		}
  // 		let imageMLResults: TsInterface_UnspecifiedObject = {}
  // 		let imagesIncludedInAggregateEvaluationCount = 0
  // 		let imagesNotIncludedInAggregateEvaluationCount = 0
  // 		let lastAggregateAnalysisTimestamp = null
  // 		if(
  // 			us_taskMLAggregateResults != null &&
  // 			taskKey != null &&
  // 			pageKey != null &&
  // 			folder != null &&
  // 			folder.key != null &&
  // 			us_taskMLAggregateResults[ taskKey ] != null &&
  // 			us_taskMLAggregateResults[ taskKey ][ taskKey + "_" + pageKey + "_" + folder.key ] != null &&
  // 			us_taskMLAggregateResults[ taskKey ][ taskKey + "_" + pageKey + "_" + folder.key ].timestamp_evaluation_completed != null
  // 		){
  // 			lastAggregateAnalysisTimestamp = returnDateFromUnknownDateFormat( us_taskMLAggregateResults[ taskKey ][ taskKey + "_" + pageKey + "_" + folder.key ].timestamp_evaluation_completed ).getTime()
  // 		}
  // 		for( let fileKey in filesList){
  // 			let modelKey = null
  // 			let file = filesList[ fileKey ]
  // 			if(
  // 				folder != null &&
  // 				folder.key != null &&
  // 				us_taskMLImages[ taskKey ] != null &&
  // 				us_taskMLImages[ taskKey ][ file.key ] != null &&
  // 				us_taskMLImages[ taskKey ][ file.key ]["task_folder_model_mapping"] != null &&
  // 				us_taskMLImages[ taskKey ][ file.key ]["task_folder_model_mapping"][taskKey] != null &&
  // 				us_taskMLImages[ taskKey ][ file.key ]["task_folder_model_mapping"][taskKey][pageKey] != null &&
  // 				us_taskMLImages[ taskKey ][ file.key ]["task_folder_model_mapping"][taskKey][pageKey][folder.key] != null
  // 			){
  // 				modelKey = us_taskMLImages[ taskKey ][ file.key ]["task_folder_model_mapping"][taskKey][pageKey][folder.key]
  // 			} else {
  // 				buttonVariant = "contained"
  // 			}
  // 			if(
  // 				modelKey != null &&
  // 				us_taskMLImages[ taskKey ] != null &&
  // 				us_taskMLImages[ taskKey ][ file.key ]["model_results"] != null &&
  // 				us_taskMLImages[ taskKey ][ file.key ]["model_results"][ modelKey ] != null &&
  // 				us_taskMLImages[ taskKey ][ file.key ]["model_results"][ modelKey ]["timestamp_evaluation_completed"] != null
  // 			){
  // 				imageMLResults[ file.key ] = us_taskMLImages[ taskKey ][ file.key ]["model_results"][ modelKey ]
  // 				let timestampCompleted = returnDateFromUnknownDateFormat( us_taskMLImages[ taskKey ][ file.key ]["model_results"][ modelKey ]["timestamp_evaluation_completed"] ).getTime()
  // 				if( timestampCompleted != null && timestampCompleted > mostRecentImageAnalysisTimestamp ){
  // 					mostRecentImageAnalysisTimestamp = timestampCompleted
  // 				}
  // 				if(
  // 					lastAggregateAnalysisTimestamp != null &&
  // 					lastAggregateAnalysisTimestamp >= timestampCompleted
  // 				){
  // 					imagesIncludedInAggregateEvaluationCount++
  // 				} else {
  // 					imagesNotIncludedInAggregateEvaluationCount++
  // 				}
  // 			} else {
  // 				imagesNotIncludedInAggregateEvaluationCount++
  // 			}
  // 		}
  // 		// If there isn't an aggregate evaluation for this folder OR
  // 		// If any image analysis dates are newer than the aggregate evaluation date
  // 		if(
  // 			lastAggregateAnalysisTimestamp != null &&
  // 			lastAggregateAnalysisTimestamp < mostRecentImageAnalysisTimestamp
  // 		){
  // 			buttonVariant = "contained"
  // 		} else {
  // 			buttonVariant = "contained"
  // 		}
  // 		// JSX
  // 		mlButtonJSX =
  // 		<Button
  // 			className="tw-ml-2"
  // 			color="secondary"
  // 			variant={ buttonVariant }
  // 			disabled={ us_runningMLAnalysis }
  // 			onClick={ () => {
  // 				runMLAnalysisOnFolder(
  // 					clientKey,
  // 					projectKey,
  // 					taskKey,
  // 					pageKey,
  // 					folder.key,
  // 					taskFormData
  // 				).then(( res_RMLAOF ) => {
  // 					uc_setUserInterface_SnackbarDisplay({
  // 						display: true,
  // 						snackbar: {
  // 							message: "Analysis Complete",
  // 							alertType: "success",
  // 							verticalAlignment: "top",
  // 							horizontalAlignment: "left"
  // 						}
  // 					})
  // 				}).catch(( rej_RMLAOF ) => {
  // 					// uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_RMLAOF.error })
  // 				})
  // 			}}
  // 		>
  // 			{ mlButtonIconJSX }
  // 			{ s_RUN_ML_ANALYSIS }
  // 		</Button>
  // 		if(
  // 			us_taskMLAggregateResults != null &&
  // 			taskKey != null &&
  // 			pageKey != null &&
  // 			folder != null &&
  // 			folder.key != null &&
  // 			us_taskMLAggregateResults[ taskKey ] != null &&
  // 			us_taskMLAggregateResults[ taskKey ][ taskKey + "_" + pageKey + "_" + folder.key ] != null
  // 		){
  // 			// Generate Messages
  // 			let messageCount = 0
  // 			let visibleMessageCount = 0
  // 			let evaluationMessages: TsInterface_UnspecifiedObject[] = []
  // 			for( let loopMessageKey in us_taskMLAggregateResults[ taskKey ][ taskKey + "_" + pageKey + "_" + folder.key ]["evaluation_messages"] ){
  // 				let loopMessage = us_taskMLAggregateResults[ taskKey ][ taskKey + "_" + pageKey + "_" + folder.key ]["evaluation_messages"][ loopMessageKey ]
  // 				if(
  // 					loopMessage != null &&
  // 					loopMessage.message != null
  // 				){
  // 					messageCount++
  // 					let addMessageToList = false
  // 					if( us_showAggregateMessageType === "errors" ){
  // 						if( loopMessage.message_type === "error"){
  // 							addMessageToList = true
  // 						}
  // 					} else {
  // 						addMessageToList = true
  // 					}
  // 					if( addMessageToList === true ){
  // 						visibleMessageCount++
  // 						let severity = 5
  // 						let iconJSX = <Box sx={{ opacity: 0.3, marginRight: "8px", display: "inline-block" }} ><Icon icon="circle-question" /></Box>
  // 						if(
  // 							loopMessage.message_type != null &&
  // 							severityMessageTypes[ loopMessage.message_type ] != null
  // 						){
  // 							severity = severityMessageTypes[ loopMessage.message_type ]["severity"]
  // 							iconJSX = severityMessageTypes[ loopMessage.message_type ]["icon"]
  // 						}
  // 						evaluationMessages.push({
  // 							message: loopMessage.message,
  // 							severity: severity,
  // 							iconJSX: iconJSX,
  // 						})
  // 					}
  // 				}
  // 			}
  // 			// JSX
  // 			let aggregateMessagesListJSX = <></>
  // 			if( evaluationMessages.length > 0 ){
  // 				aggregateMessagesListJSX =
  // 				<Box>
  // 					{ sortByTwoProperties( evaluationMessages, "severity", "message" ).map(( message: TsInterface_UnspecifiedObject, index: number ) => (
  // 						<Box key={ index } className="tw-mt-2">
  // 							{ message.iconJSX }{ message.message }
  // 						</Box>
  // 					))}
  // 				</Box>
  // 			} else {
  // 				aggregateMessagesListJSX =
  // 				<Typography variant="body1" className="tw-ml-8 tw-italic tw-opacity-20">
  // 					{ s_NO_ERROR_OR_WARNING_MESSAGES }
  // 				</Typography>
  // 			}
  // 			mlAggregateResultsJSX =
  // 			<Box className="tw-py-2">
  // 				<Box>
  // 					<Typography variant="h6" sx={{ color: themeVariables.secondary_main }} className="tw-inline-block tw-mr-1">
  // 						<Icon icon="robot" className="tw-mr-2" />
  // 						{ s_ML_AGGREGATE_RESULTS }
  // 					</Typography>
  // 					<Box className="tw-opacity-20 tw-italic tw-inline-block">
  // 						{ returnFormattedDate(us_taskMLAggregateResults[ taskKey + "_" + pageKey + "_" + folder.key ].timestamp_evaluation_completed, "MMMM D, YYYY h:mm a" ) }
  // 					</Box>
  // 				</Box>
  // 				<Box className="tw-ml-8">
  // 					{ rJSX_NumberOfImagesAnalyzed(
  // 						filesList,
  // 						imageMLResults,
  // 						imagesIncludedInAggregateEvaluationCount,
  // 						imagesNotIncludedInAggregateEvaluationCount,
  // 						lastAggregateAnalysisTimestamp
  // 					) }
  // 				</Box>
  // 				<Box className="tw-ml-8">
  // 					{ rJSX_toggleMLMessageTypes( messageCount, visibleMessageCount ) }
  // 				</Box>
  // 				<Box className="tw-ml-8">
  // 					{ aggregateMessagesListJSX }
  // 				</Box>
  // 			</Box>
  // 		} else {
  // 			mlAggregateResultsJSX =
  // 			<Box className="tw-py-2">
  // 				<Typography variant="h6" sx={{ color: themeVariables.secondary_main }}>
  // 					<Icon icon="robot" className="tw-mr-2" />
  // 					{ s_ML_AGGREGATE_RESULTS }
  // 				</Typography>
  // 				<Typography variant="body1" className="tw-ml-8 tw-italic tw-opacity-20">
  // 					{ s_NOT_EVALUATED_YET }
  // 				</Typography>
  // 			</Box>
  // 		}
  // 	}
  // 	if( folder["require_min_file_upload"] === true && folder["min_file_upload_count"] != null ){
  // 		minimumFilesJSX =
  // 		<Box className="tw-inline-block tw-mx-1">
  // 			<Typography variant="body1">{ s_MINIMUM_OF } { folder["min_file_upload_count"] } { minUploadsTextJSX }</Typography>
  // 		</Box>
  // 	} else {
  // 		minimumFilesJSX =
  // 		<Box className="tw-inline-block tw-mx-1">
  // 			<Typography variant="body1" className="tw-opacity-30 tw-italic">{ s_NO_MINIMUM }</Typography>
  // 		</Box>
  // 	}
  // 	if( folder["require_max_file_upload"] === true && folder["max_file_upload_count"] != null ){
  // 		maximumFilesJSX =
  // 		<Box className="tw-inline-block tw-mx-1">
  // 			<Typography variant="body1">{ s_MAXIMUM_OF } { folder["max_file_upload_count"]} { maxUploadsTextJSX }</Typography>
  // 		</Box>
  // 	} else {
  // 		maximumFilesJSX =
  // 		<Box className="tw-inline-block tw-mx-1">
  // 			<Typography variant="body1" className="tw-opacity-30 tw-italic">{ s_NO_MAXIMUM }</Typography>
  // 		</Box>
  // 	}
  // 	// Determine if disabled
  // 	let disableUpload = !editable
  // 	let uploadedCount = 0
  // 	if(
  // 		taskFormData != null &&
  // 		taskFormData[ pageKey ] != null &&
  // 		taskFormData[ pageKey ]["folders"] != null &&
  // 		taskFormData[ pageKey ]["folders"][ folder.key ] != null
  // 	){
  // 		uploadedCount = taskFormData[ pageKey ]["folders"][ folder.key ].length
  // 	}
  // 	if(
  // 		folder["require_max_file_upload"] === true &&
  // 		folder["max_file_upload_count"] != null
  // 	){
  // 		if( folder["max_file_upload_count"] <= uploadedCount ){
  // 			disableUpload = true
  // 		}
  // 	}
  // 	// File Upload Component
  // 	fileUploadTaskPreviewJSX =
  // 	<Box>
  // 		<Typography variant="h6">
  // 			<Icon icon="camera" type="light" className="tw-mr-2 tw-opacity-50" />
  // 			{ folder["name"] }
  // 		</Typography>
  // 		<Box sx={{ paddingBottom: "12px" }}>
  // 			{ rJSX_ImagesUploaded( clientKey, projectKey, taskKey, pageKey, folder, folder.key, taskFormData, uc_setUserInterface_CustomDialogDisplay ) }
  // 			<Box>
  // 				{ mlAggregateResultsJSX }
  // 			</Box>
  // 			<Box className="tw-mb-2 tw-text-center">
  // 				{/* <FileUploadButton
  // 					multiple={ true }
  // 					accept='image/*'
  // 					onChange={ fileOnSelect }
  // 					button={{
  // 						text: s_UPLOAD,
  // 						icon: <Icon icon="cloud-arrow-up" className="tw-mr-2"></Icon>,
  // 						color: "info",
  // 						className: "",
  // 						variant: "contained",
  // 						disabled: disableUpload
  // 					}}
  // 					additionalFileUploadParams={{
  // 						clientKey: clientKey,
  // 						userName: userName,
  // 						projectKey: projectKey,
  // 						taskKey: taskKey,
  // 						page: page,
  // 						pageKey: pageKey,
  // 						folderKey: folder.key,
  // 						folder: folder
  // 					}}
  // 				/> */}
  // 				{ mlButtonJSX }
  // 				<Box>
  // 					<Box className="tw-inline-block tw-opacity-30">(</Box>
  // 					<Box className="tw-inline-block">{ minimumFilesJSX }</Box>
  // 					<Box className="tw-inline-block tw-opacity-30">-</Box>
  // 					<Box className="tw-inline-block">{ maximumFilesJSX }</Box>
  // 					<Box className="tw-inline-block tw-opacity-30">)</Box>
  // 				</Box>
  // 			</Box>
  // 		</Box>
  // 	</Box>
  // 	return fileUploadTaskPreviewJSX
  // }

  // const rJSX_TaskFormRender = ( task: TsInterface_UnspecifiedObject ): JSX.Element => {
  // 	let taskFormJSX: JSX.Element = <></>

  // 	console.log( "<><>" )
  // 	console.log( task )
  // 	console.log( us_taskForms )

  // 	if(
  // 		task != null &&
  // 		task.associated_task_form_key != null &&
  // 		us_taskForms != null &&
  // 		us_taskForms[ task.associated_task_form_key ] != null &&
  // 		us_taskForms[ task.associated_task_form_key ]["pages"] != null
  // 	){
  // 		let formPreviewPages: TsInterface_TabContentArray = []
  // 		let sortedPagesArray = objectToArray(us_taskForms[ task.associated_task_form_key ]["pages"] ).sort( dynamicSort( "order", null ))
  // 		for( let loopPageIndex in sortedPagesArray ){
  // 			let loopPage = sortedPagesArray[ loopPageIndex ]
  // 			if( loopPage != null && loopPage["page_type"] === "image_uploads" ) {
  // 				formPreviewPages[ loopPageIndex ] = {
  // 					tabHeader: getProp( loopPage, "name", "" ),
  // 					tabContent:
  // 					<Box sx={{ padding: "16px" }}>
  // 						{ rJSX_ImageUploadTaskPreview(
  // 							clientKey,
  // 							userName,
  // 							us_taskData.associated_project_key,
  // 							taskKey,
  // 							loopPage,
  // 							us_taskFormData,
  // 							us_editable,
  // 							uc_setUserInterface_CustomDialogDisplay
  // 						) }

  // 					</Box>
  // 				}
  // 				// Full JSX
  // 				taskFormJSX =
  // 				<Box>
  // 					<TabsBasic
  // 						tabs={ formPreviewPages }
  // 						tabsSettings={ {} }
  // 					/>
  // 				</Box>
  // 			}
  // 		}
  // 	}

  // 	return taskFormJSX
  // }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_LIVE_REVIEW}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              <DatePicker
                key={'calendar_date'}
                datePickerText={rLIB('Date')}
                datePickerDate={us_selectedDate}
                datePickerDisabled={false}
                datePickerDateOnChange={changeCalendarDate}
                datePickerSettings={{ thin_input: true }}
              />
            </Box>
            <Box className="tw-mt-2">
              {objectToArray(us_activeTasks)
                .sort(dynamicSort('associated_project_id_number', 'asc'))
                .map((task, taskIndex) => (
                  <Box
                    key={taskIndex}
                    className="tw-mb-2 tw-mr-2 tw-inline-block"
                  >
                    <Button
                      variant="contained"
                      color="info"
                      onClick={() => {
                        if (uc_RootData_ClientKey != null) {
                          let taskWorkflowKey = null
                          // if(
                          // 	task != null &&
                          // 	task.associated_project != null &&
                          // ){
                          // 	taskWorkflowKey
                          // }

                          uc_setUserInterface_CustomDialogDisplay({
                            display: true,
                            dialog: {
                              dialog_jsx: (
                                <Box>
                                  <TaskFormCustomDialog
                                    clientKey={uc_RootData_ClientKey}
                                    taskKey={task.key}
                                    userKey={getProp(uc_RootData_ClientUser, 'key', null)}
                                    userName={getProp(uc_RootData_ClientUser, 'name', null)}
                                    startEditable={false}
                                    workflowKey={taskWorkflowKey}
                                  />
                                </Box>
                              ),
                              settings: {
                                max_width: 'md',
                              },
                            },
                          })
                        }
                      }}
                    >
                      <Icon
                        icon="magnifying-glass"
                        className="tw-mr-2"
                      />
                      {task.associated_project_id_number}
                    </Button>
                  </Box>
                ))}
              {/* { objectToArray( us_activeTasks ).sort( dynamicSort( "associated_project_id_number", "asc" ) ).map(( task, taskIndex ) => (
							<Box key={taskIndex} className="tw-mb-2">
								<Card>
									<List sx={{ width: '100%' }} className="tw-py-0">
										<ListItemButton
											sx={{ background: us_visibleProject === task.key? themeVariables.background_hover: "inherit" }}
											onClick={ () => {
												if( us_visibleProject === task.key ){
													us_setVisibleProject( null )
												} else {
													us_setVisibleProject( task.key )
												}
											}}
										>
											<ListItemText primary={
												<Typography variant="h5" component="h6">
													{ task.associated_project_id_number }
												</Typography>
											} />
											{ us_visibleProject === task.key ? <Icon icon="chevron-up" /> : <Icon icon="chevron-down" />}
										</ListItemButton>
										<Collapse in={ us_visibleProject === task.key } timeout="auto" unmountOnExit>
											<Divider />
										</Collapse>
									</List>
								</Card>
							</Box>
						))} */}
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_Reminders_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'reminders', 'main')
}

export const DatabaseRef_Reminder_Document = (clientKey: string, reminderKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'reminders', 'main', reminderKey)
}

export const DatabaseRef_RemindersDateRange_Query = (clientKey: string, userKey: string, startTimestamp: Date, endTimestamp: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_reminder', comparator: '>=', value: startTimestamp },
    { prop: 'timestamp_reminder', comparator: '<=', value: endTimestamp },
    { prop: 'associated_user_key', comparator: '==', value: userKey },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'reminders', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_OpenReminders_Query = (clientKey: string, userKey: string, timestamp: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_reminder', comparator: '<=', value: timestamp },
    { prop: 'associated_user_key', comparator: '==', value: userKey },
    { prop: 'status', comparator: '==', value: 'unread' },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'reminders', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_ProjectReminders_Query = (clientKey: string, projectKey: string) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'associated_project_key', comparator: '==', value: projectKey },
    { prop: 'status', comparator: '==', value: 'unread' },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'reminders', 'main'), queryOperatorsArray, [], {}, null)
}

export const DatabaseRef_OpenReminders_Query_AllNav_Query = (clientKey: string, userKey: string, timestamp: Date) => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp_reminder', comparator: '<=', value: timestamp },
    { prop: 'associated_user_key', comparator: '==', value: userKey },
    { prop: 'status', comparator: '==', value: 'unread' },
  ]
  return generateDatabaseQuery(collection(getFirestore(), 'clients', clientKey, 'operations', 'reminders', 'main'), queryOperatorsArray, [], {}, 1)
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

			Get start and end date for selected day
			use effect if selected user is not null, load punches for day
			Left side - list of timestamps and coordinates - hover shows on map
			Right side - map with markers for each timestamp, small circles unless highlighted
			Highlight on the right side shows on left (highlighted timestamp)
				probably also a label on the map marker to indicate timestamp


			Get Timezone pin highlighting to work
			Label on pin with timestamps??? - have GPT get range of timestamps for each pin maybe???
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { Trans } from 'react-i18next'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_TimeSheetsGeolocationUsersHistory_DateRange_Query,
  DatabaseRef_TimeSheetsGeolocationUsers_ClockedInUsers_Query,
  DatabaseRef_TimeSheetsGeolocationUsers_ClockedOutUsers_Query,
  DatabaseRef_TimeSheetsGeolocationUsers_Document,
} from 'rfbp_aux/services/database_endpoints/timesheets/geolocation'
import { DatePicker } from 'rfbp_core/components/date_picker'
import { Icon } from 'rfbp_core/components/icons'
import { MapBasic, TsInterface_MapMarkers, TsInterface_MapSettings, TsType_MapOnClick } from 'rfbp_core/components/map'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_PromptDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  underscoresToSpaces,
} from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TabProps {
  selectedDate: Date
  setSelectedDate: any
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ALLOCATION_TYPE: JSX.Element = <Trans>Allocation Type</Trans>
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UPDATE_THIS_USER_TO_BE_CLOCKED_IN: JSX.Element = (
  <Trans>Are you sure that you want to update this user to be clocked in?</Trans>
)
const s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UPDATE_THIS_USER_TO_BE_CLOCKED_OUT: JSX.Element = (
  <Trans>Are you sure that you want to update this user to be clocked out?</Trans>
)
const s_AT: JSX.Element = <Trans>at</Trans>
const s_AUTO: JSX.Element = <Trans>Auto</Trans>
const s_CLOCKED_IN_USERS: JSX.Element = <Trans>Clocked In Users</Trans>
const s_CLOCKED_OUT_USERS: JSX.Element = <Trans>Clocked Out Users</Trans>
const s_CLOCK_IN: JSX.Element = <Trans>Clock In</Trans>
const s_CLOCK_IN_ADDRESS: JSX.Element = <Trans>Clock In Address</Trans>
const s_CLOCK_OUT: JSX.Element = <Trans>Clock Out</Trans>
const s_DATE: JSX.Element = <Trans>Date</Trans>
const s_FAILED_TO_CHANGE_USERS_CLOCK_STATUS: JSX.Element = <Trans>Failed to change user's clock status</Trans>
const s_JOB_CODE: JSX.Element = <Trans>Job Code</Trans>
const s_LAST_PING: JSX.Element = <Trans>Last Ping</Trans>
const s_LOCATIONS: JSX.Element = <Trans>locations</Trans>
const s_MANUALLY_CLOCK_USER_IN: JSX.Element = <Trans>Manually clock user in</Trans>
const s_MANUALLY_CLOCK_USER_OUT: JSX.Element = <Trans>Manually clock user out</Trans>
const s_MOVEMENT: JSX.Element = <Trans>Movement</Trans>
const s_MPH: JSX.Element = <Trans>mph</Trans>
const s_NO_LOCATION_PINGS_FOR: JSX.Element = <Trans>No location pings for</Trans>
const s_OTHERWISE_CLICK_DISMISS: JSX.Element = <Trans>Otherwise click dismiss</Trans>
const s_PINGS: JSX.Element = <Trans>pings</Trans>
const s_THIS_WILL_NOT_CREATE_A_PUNCH_EVENT_IT_WILL_JUST_MOVE_THEM_TO_THE_CLOCKED_IN_LIST: JSX.Element = (
  <Trans>This will not create a punch event, it will just move them to the clocked in list.</Trans>
)
const s_THIS_WILL_NOT_CREATE_A_PUNCH_EVENT_IT_WILL_JUST_MOVE_THEM_TO_THE_CLOCKED_OUT_LIST: JSX.Element = (
  <Trans>This will not create a punch event, it will just move them to the clocked out list.</Trans>
)
const s_TYPE_YES_TO_PROCEED: JSX.Element = <Trans>Type YES to proceed</Trans>
const s_UPDATE_STATUS: JSX.Element = <Trans>Update Status</Trans>
const s_VIEW_ALL_USERS: JSX.Element = <Trans>View all users</Trans>
const s_VIEW_LOCATION_HISTORY: JSX.Element = <Trans>View Location History</Trans>
const s_YOU_MUST_ENTER_YES_IN_ORDER_TO_PROCEED: JSX.Element = <Trans>You must enter YES in order to proceed</Trans>
// { sort-end } - displayed text

// Map Icons
const returnMapIcons = (iconKey: string): TsInterface_UnspecifiedObject => {
  let icon: TsInterface_UnspecifiedObject = {}
  switch (iconKey) {
    case 'pin':
      icon = {
        path: 'M25,1C19.486,1 15,5.486 15,11C15,16.514 19.486,21 25,21C30.514,21 35,16.514 35,11C35,5.486 30.514,1 25,1ZM25,5C25.552,5 26,5.447 26,6C26,6.553 25.552,7 25,7C22.794,7 21,8.794 21,11C21,11.553 20.552,12 20,12C19.448,12 19,11.553 19,11C19,7.691 21.691,5 25,5ZM22,22.605L22,43.244L25,48.99L28,43.244L28,22.605C27.039,22.854 26.037,23 25,23C23.963,23 22.961,22.854 22,22.605Z',
        scale: 1,
        anchor: new google.maps.Point(20, 48),

        // scale: 10,
        // fillColor: "blue",
        // fillOpacity: 0.7,
        // strokeColor: "black",
        // strokeWeight: 0.5,
      }
      break
    // case "pin":
    // 	icon = {
    // 		path: "M550,325L550,50L600,50L600,0L200,0L200,50L250,50L250,325C250,325 150,400 150,500C150,525 245,535 350,535L350,645C350,680 360,715 375,750L400,800L425,750C440,720 450,685 450,645L450,535C555,535 650,520 650,500C650,400 550,325 550,325ZM350,330C350,330 325,345 270,400C220,450 195,495 195,495C195,495 200,445 235,400C280,345 300,330 300,330L300,50L350,50L350,330Z",
    // 		scale: 0.05,
    // 		anchor: new google.maps.Point(400, 800),
    // 	}
    // 	break

    default:
      icon = {
        path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
        scale: 0.05,
        anchor: new google.maps.Point(400, 800),
      }
  }
  return icon
}

///////////////////////////////
// Functions
///////////////////////////////

function consolidateGPSData(gpsData: TsInterface_UnspecifiedObject, distanceThreshold: number) {
  // Helper function to calculate the distance between two coordinates in meters
  const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371e3 // Earth's radius in meters
    const rad = Math.PI / 180 // Degree to radian conversion factor

    const dLat = (lat2 - lat1) * rad
    const dLon = (lon2 - lon1) * rad

    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * rad) * Math.cos(lat2 * rad) * Math.sin(dLon / 2) * Math.sin(dLon / 2)

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

    return R * c // Distance in meters
  }

  const outputData: TsInterface_UnspecifiedObject = {
    timestamp_data: {},
    marker_data: {},
  }

  Object.keys(gpsData).forEach((timestampKey) => {
    const { location_latitude: latitude, location_longitude: longitude, location_timestamp: timestamp, ...rest } = gpsData[timestampKey]

    let markerKey = null

    // Loop through existing markers
    for (let existingMarkerKey of Object.keys(outputData.marker_data)) {
      const { location_latitude: existingLatitude, location_longitude: existingLongitude } = outputData.marker_data[existingMarkerKey]

      if (calculateDistance(latitude, longitude, existingLatitude, existingLongitude) <= distanceThreshold) {
        markerKey = existingMarkerKey
        outputData.marker_data[existingMarkerKey].timestamp_keys[timestampKey] = true

        const existingFirstTimestamp = new Date(returnDateFromUnknownDateFormat(outputData.marker_data[existingMarkerKey].first_timestamp))
        const existingLastTimestamp = new Date(returnDateFromUnknownDateFormat(outputData.marker_data[existingMarkerKey].last_timestamp))
        const currentTimestamp = new Date(returnDateFromUnknownDateFormat(timestamp))

        if (currentTimestamp < existingFirstTimestamp) {
          outputData.marker_data[existingMarkerKey].first_timestamp = timestamp
        }
        if (currentTimestamp > existingLastTimestamp) {
          outputData.marker_data[existingMarkerKey].last_timestamp = timestamp
        }
        // Break the loop as we found a matching marker
        break
      }
    }

    if (!markerKey) {
      markerKey = latitude.toString() + '_' + longitude.toString()
      outputData.marker_data[markerKey] = {
        location_latitude: latitude,
        location_longitude: longitude,
        first_timestamp: timestamp,
        last_timestamp: timestamp,
        timestamp_keys: {
          [timestampKey]: true,
        },
      }
    } else {
      const existingFirstTimestamp = new Date(returnDateFromUnknownDateFormat(outputData.marker_data[markerKey].first_timestamp))
      const existingLastTimestamp = new Date(returnDateFromUnknownDateFormat(outputData.marker_data[markerKey].last_timestamp))
      const currentTimestamp = new Date(returnDateFromUnknownDateFormat(timestamp))
      if (currentTimestamp < existingFirstTimestamp) {
        outputData.marker_data[markerKey].first_timestamp = timestamp
      }
      if (currentTimestamp > existingLastTimestamp) {
        outputData.marker_data[markerKey].last_timestamp = timestamp
      }
    }
    outputData.timestamp_data[timestampKey] = {
      location_latitude: latitude,
      location_longitude: longitude,
      location_timestamp: timestamp,
      marker_key: markerKey,
      actual_key: timestampKey,
      ...rest,
    }
  })
  return outputData
}

function scrollToSubDiv(subDivId: string) {
  const container = document.getElementById('user_list_container')
  const subDiv = document.getElementById(subDivId)
  if (container != null) {
    if (!subDiv) {
      console.error(`Element with ID ${subDivId} not found.`)
      return
    }
    const containerRect = container.getBoundingClientRect()
    const subDivRect = subDiv.getBoundingClientRect()
    const offsetTop = subDivRect.top - containerRect.top
    container.scrollTop = offsetTop
  }
}

function getCardinalDirection(heading: number) {
  const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', 'N']
  const index = Math.round(heading / 45) % 8
  return directions[index]
}

function mpsToMph(speedMps: number) {
  const mph = speedMps * 2.23694 // Conversion factor
  return mph
}

///////////////////////////////
// JSX Exports
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const Tab: React.FC<TabProps> = ({ selectedDate, setSelectedDate }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_clockedInUsers, us_setClockedInUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clockedOutUsers, us_setClockedOutUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_highlightedMarkerKey, us_setHighlightedMarkerKey] = useState<string | null>(null)
  const [us_highlightedTimestampKeys, us_setHighlightedTimestampKeys] = useState<TsInterface_UnspecifiedObject>({})
  const [us_highlightedUserKey, us_setHighlightedUserKey] = useState<string | null>(null)
  const [us_selectedIndividualUser, us_setSelectedIndividualUser] = useState<TsInterface_UnspecifiedObject | null>(null)
  const [us_timelineData, us_setTimelineData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_timelineMarkerData, us_setTimelineMarkerData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_timelineTimeData, us_setTimelineTimeData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_visibleUserClockedStatus, us_setVisibleUserClockedStatus] = useState<'clocked_in' | 'clocked_out'>('clocked_in')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const ur_mapRef = useRef(null)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClockedInUsers(objectToArray(newData))
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TimeSheetsGeolocationUsers_ClockedInUsers_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setClockedOutUsers(objectToArray(newData))
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_TimeSheetsGeolocationUsers_ClockedOutUsers_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setTimelineData(newData)
      ur_forceRerender()
    }
    if (us_selectedIndividualUser != null && us_selectedIndividualUser['key'] != null && selectedDate != null) {
      // eslint-disable-next-line react/prop-types
      let startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0)
      // eslint-disable-next-line react/prop-types
      let endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23, 59, 59, 999)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_TimeSheetsGeolocationUsersHistory_DateRange_Query(res_GCK.clientKey, us_selectedIndividualUser['key'], startDate, endDate),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedIndividualUser, selectedDate, ur_forceRerender])

  useEffect(() => {
    if (us_selectedIndividualUser != null && us_selectedIndividualUser['key'] != null && selectedDate != null) {
      let output = consolidateGPSData(us_timelineData, 100)
      us_setTimelineTimeData(getProp(output, 'timestamp_data', {}))
      us_setTimelineMarkerData(getProp(output, 'marker_data', {}))
    }
    return () => {}
  }, [us_selectedIndividualUser, selectedDate, us_timelineData])

  // Functions
  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      setSelectedDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const resizeMap = (): void => {
    setTimeout(() => {
      // @ts-expect-error - TODO: reason for error
      if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.mapCenter != null) {
        // @ts-expect-error - TODO: reason for error
        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
          // @ts-expect-error - TODO: reason for error
          ur_mapRef.current.recalculateMapBounds(true)
        }
      }
    }, 1)
  }

  // Call Functions

  // JSX Generation
  const rJSX_MissingText = (): JSX.Element => {
    let missingJSX = <></>
    missingJSX = <Box className="tw-inline-block tw-italic tw-opacity-40">{rLIB('Missing')}</Box>

    return missingJSX
  }

  const rJSX_ManualClockInAndOutButtons = (user: TsInterface_UnspecifiedObject, clockStatus: string): JSX.Element => {
    let buttonJSX = <></>
    if (clockStatus === 'clocked_in') {
      buttonJSX = (
        <Tooltip
          title={s_MANUALLY_CLOCK_USER_OUT}
          placement="top"
        >
          <Box
            className="tw-inline-block tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100"
            onClick={(event) => {
              event.stopPropagation()
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'warning',
                  confirm_text: s_UPDATE_STATUS,
                  default_value: '',
                  header: s_MANUALLY_CLOCK_USER_OUT,
                  icon: (
                    <Icon
                      icon="clock"
                      type="solid"
                    />
                  ),
                  input_label: s_TYPE_YES_TO_PROCEED,
                  input_type: 'text',
                  text: (
                    <>
                      {s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UPDATE_THIS_USER_TO_BE_CLOCKED_OUT}{' '}
                      {s_THIS_WILL_NOT_CREATE_A_PUNCH_EVENT_IT_WILL_JUST_MOVE_THEM_TO_THE_CLOCKED_OUT_LIST}
                    </>
                  ),
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve, reject) => {
                      if (promptValue === 'YES') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = { last_time_punch_type: 'clock_out' }
                            DatabaseSetMergeDocument(DatabaseRef_TimeSheetsGeolocationUsers_Document(res_GCK.clientKey, user.key), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: s_FAILED_TO_CHANGE_USERS_CLOCK_STATUS,
                            details: (
                              <>
                                {s_YOU_MUST_ENTER_YES_IN_ORDER_TO_PROCEED} {s_OTHERWISE_CLICK_DISMISS}
                              </>
                            ),
                            code: 'ER-D-APV-DW-01',
                          },
                        })
                        resolve({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            }}
          >
            <Icon
              icon="traffic-light"
              className="tw-mx-2"
            />
          </Box>
        </Tooltip>
      )
    } else if (clockStatus === 'clocked_out') {
      buttonJSX = (
        <Tooltip
          title={s_MANUALLY_CLOCK_USER_IN}
          placement="top"
        >
          <Box
            className="tw-inline-block tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100"
            onClick={(event) => {
              event.stopPropagation()
              uc_setUserInterface_PromptDialogDisplay({
                display: true,
                prompt: {
                  color: 'warning',
                  confirm_text: s_UPDATE_STATUS,
                  default_value: '',
                  header: s_MANUALLY_CLOCK_USER_IN,
                  icon: (
                    <Icon
                      icon="clock"
                      type="solid"
                    />
                  ),
                  input_label: s_TYPE_YES_TO_PROCEED,
                  input_type: 'text',
                  text: (
                    <>
                      {s_ARE_YOU_SURE_THAT_YOU_WANT_TO_UPDATE_THIS_USER_TO_BE_CLOCKED_IN}{' '}
                      {s_THIS_WILL_NOT_CREATE_A_PUNCH_EVENT_IT_WILL_JUST_MOVE_THEM_TO_THE_CLOCKED_IN_LIST}
                    </>
                  ),
                  submit_callback: (promptValue: string) => {
                    return new Promise((resolve, reject) => {
                      if (promptValue === 'YES') {
                        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                          .then((res_GCK) => {
                            let updateObject = { last_time_punch_type: 'clock_in' }
                            DatabaseSetMergeDocument(DatabaseRef_TimeSheetsGeolocationUsers_Document(res_GCK.clientKey, user.key), updateObject)
                              .then((res_DSMD) => {
                                resolve(res_DSMD)
                              })
                              .catch((rej_DSMD) => {
                                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                                reject(rej_DSMD)
                              })
                          })
                          .catch((rej_GCK) => {
                            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                            reject(rej_GCK)
                          })
                      } else {
                        uc_setUserInterface_ErrorDialogDisplay({
                          display: true,
                          error: {
                            message: s_FAILED_TO_CHANGE_USERS_CLOCK_STATUS,
                            details: (
                              <>
                                {s_YOU_MUST_ENTER_YES_IN_ORDER_TO_PROCEED} {s_OTHERWISE_CLICK_DISMISS}
                              </>
                            ),
                            code: 'ER-D-APV-DW-01',
                          },
                        })
                        resolve({ close_dialog: false })
                      }
                    })
                  },
                },
              })
            }}
          >
            <Icon
              icon="traffic-light"
              className="tw-mx-2"
            />
          </Box>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_UserLocationTimestamp = (user: TsInterface_UnspecifiedObject): JSX.Element => {
    let timestampJSX = <></>
    if (user != null && user.location_timestamp != null) {
      timestampJSX = <>{returnFormattedDate(user.location_timestamp, 'h:mm a M/D/YY')}</>
    }
    return timestampJSX
  }

  const rJSX_UserTile = (user: TsInterface_UnspecifiedObject, clockStatus: string): JSX.Element => {
    let userJSX = <></>
    let backgroundColor = themeVariables.background_json
    if (us_highlightedUserKey === user.key && user.location_latitude != null && user.location_longitude != null) {
      backgroundColor = themeVariables.error_main
    } else if (us_highlightedUserKey === user.key) {
      backgroundColor = themeVariables.warning_main
    }
    // let coordinatesJSX = <></>
    let coordinatesIconJSX = <></>
    let clockIconJSX = <></>
    let timestampJSX = <></>
    if (user.location_latitude != null && user.location_longitude != null) {
      coordinatesIconJSX = (
        <Box
          sx={{ color: themeVariables.primary_main }}
          className="tw-mr-2 tw-inline-block"
        >
          <Icon icon="map-pin" />
        </Box>
      )
      clockIconJSX = (
        <Box
          sx={{ color: themeVariables.primary_main }}
          className="tw-mr-2 tw-inline-block"
        >
          <Icon icon="clock" />
        </Box>
      )
      // coordinatesJSX =
      // <Box className="tw-mt-1">({ user.location_latitude }, { user.location_longitude })</Box>
    } else {
      coordinatesIconJSX = (
        <Box
          sx={{ color: themeVariables.error_main }}
          className="tw-mr-2 tw-inline-block"
        >
          <Icon icon="triangle-exclamation" />
        </Box>
      )
      clockIconJSX = (
        <Box
          sx={{ color: themeVariables.error_main }}
          className="tw-mr-2 tw-inline-block"
        >
          <Icon icon="triangle-exclamation" />
        </Box>
      )
      // coordinatesJSX = rJSX_MissingText()
    }
    if (user.location_timestamp != null) {
      // location_timestamp
      timestampJSX = (
        <Box className="tw-inline-block tw-mr-1 tw-opacity-60">
          {/* <ReactTimeAgo date={returnDateFromUnknownDateFormat(user.location_timestamp)} /> */}
          <Box className="tw-inline-block tw-ml-1">( {rJSX_UserLocationTimestamp(user)} )</Box>
        </Box>
      )
    } else {
      timestampJSX = rJSX_MissingText()
    }
    userJSX = (
      <Box
        id={user.key}
        className="tw-p-2 tw-m-1 tw-rounded"
        sx={{
          background: backgroundColor,
        }}
        onMouseEnter={() => us_setHighlightedUserKey(user.key)}
        onMouseLeave={() => us_setHighlightedUserKey(null)}
        onClick={() => {
          if (user != null && user.location_latitude != null && user.location_longitude != null) {
            // @ts-expect-error - TODO: reason for error
            if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.setNewMapCenter != null) {
              // @ts-expect-error - TODO: reason for error
              ur_mapRef.current.setNewMapCenter(user.location_latitude, user.location_longitude, 18)
            }
          }
        }}
      >
        <Box>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Box>
              <Icon
                icon="user"
                className="tw-mr-2"
              />
              {user.name}
            </Box>
            <Box>
              {rJSX_ManualClockInAndOutButtons(user, clockStatus)}

              <Tooltip
                title={s_VIEW_LOCATION_HISTORY}
                placement="top"
              >
                <Box
                  className="tw-inline-block tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100"
                  onClick={(event) => {
                    event.stopPropagation()
                    us_setSelectedIndividualUser(user)
                    resizeMap()
                  }}
                >
                  <Icon
                    icon="clock-rotate-left"
                    className="tw-mx-1"
                  />
                </Box>
              </Tooltip>
            </Box>
          </Stack>
        </Box>
        <Divider />
        <Box className="tw-pl-6 tw-mt-1">
          <Box className="tw-font-normal">
            <Box
              sx={{ color: themeVariables.primary_main }}
              className="tw-mr-2 tw-inline-block"
            >
              <Icon icon="shelves" />
            </Box>
            <Box className="tw-inline-block tw-mr-1">{s_ALLOCATION_TYPE}: </Box>
            <Box className="tw-inline-block tw-mr-1 tw-opacity-60">
              {user.last_time_punch_associated_allocation_type_name != null && user.last_time_punch_associated_allocation_type_name !== ''
                ? user.last_time_punch_associated_allocation_type_name
                : rJSX_MissingText()}{' '}
              -{' '}
              {user.last_time_punch_associated_allocation_subtype_name != null && user.last_time_punch_associated_allocation_subtype_name !== ''
                ? user.last_time_punch_associated_allocation_subtype_name
                : rJSX_MissingText()}
            </Box>
          </Box>
          <Box className="tw-font-normal">
            <Box
              sx={{ color: themeVariables.primary_main }}
              className="tw-mr-2 tw-inline-block"
            >
              <Icon icon="barcode" />
            </Box>
            <Box className="tw-inline-block tw-mr-1">{s_JOB_CODE}: </Box>
            <Box className="tw-inline-block tw-mr-1 tw-opacity-60">
              {user.associated_project_id_number != null && user.associated_project_id_number !== '' ? user.associated_project_id_number : rJSX_MissingText()}
            </Box>
          </Box>

          {/* Show task names next to job code */}

          <Box className="tw-font-normal">
            {coordinatesIconJSX}
            <Box className="tw-inline-block tw-mr-1">{s_CLOCK_IN_ADDRESS}: </Box>
            <Box className="tw-inline-block tw-mr-1 tw-opacity-60">
              {user.location_address != null && user.location_address !== '' ? user.location_address : rJSX_MissingText()}
            </Box>
          </Box>
          <Box className="tw-font-normal">
            {clockIconJSX}
            <Box className="tw-inline-block tw-mr-1">{s_LAST_PING}: </Box>
            {timestampJSX}
          </Box>
        </Box>
      </Box>
    )
    return userJSX
  }

  const rJSX_ClockedInUsers = (): JSX.Element => {
    let usersJSX = <></>
    usersJSX = (
      <Box>
        {objectToArray(us_clockedInUsers)
          .sort(dynamicSort('name', 'asc'))
          .map((loopUser, index) => (
            <Box key={index}>{rJSX_UserTile(loopUser, 'clocked_in')}</Box>
          ))}
      </Box>
    )
    return usersJSX
  }

  const rJSX_ClockedOutUsers = (): JSX.Element => {
    let usersJSX = <></>
    usersJSX = (
      <Box>
        {objectToArray(us_clockedOutUsers)
          .sort(dynamicSort('name', 'asc'))
          .map((loopUser, index) => (
            <Box key={index}>{rJSX_UserTile(loopUser, 'clocked_out')}</Box>
          ))}
      </Box>
    )
    return usersJSX
  }

  const rJSX_UserListPanel = (): JSX.Element => {
    let panelJSX = <></>
    // User List
    let userListJSX = <></>
    if (us_visibleUserClockedStatus === 'clocked_in') {
      userListJSX = <>{rJSX_ClockedInUsers()}</>
    } else if (us_visibleUserClockedStatus === 'clocked_out') {
      userListJSX = <>{rJSX_ClockedOutUsers()}</>
    }
    // Toggle JSX
    let toggleJSX = (
      <Box className="tw-text-center tw-mt-2 tw-mb-2">
        <Button
          className="tw-mr-2"
          sx={{ opacity: us_visibleUserClockedStatus === 'clocked_in' ? '1' : '0.2' }}
          variant={us_visibleUserClockedStatus === 'clocked_in' ? 'contained' : 'outlined'}
          color={us_visibleUserClockedStatus === 'clocked_in' ? 'success' : 'inherit'}
          onClick={() => {
            us_setVisibleUserClockedStatus('clocked_in')
            resizeMap()
          }}
        >
          <Icon
            icon="play"
            className="tw-mr-2"
          />
          {s_CLOCKED_IN_USERS}
        </Button>
        <Button
          className="tw-mr-2"
          sx={{ opacity: us_visibleUserClockedStatus === 'clocked_out' ? '1' : '0.2' }}
          variant={us_visibleUserClockedStatus === 'clocked_out' ? 'contained' : 'outlined'}
          color={us_visibleUserClockedStatus === 'clocked_out' ? 'error' : 'inherit'}
          onClick={() => {
            us_setVisibleUserClockedStatus('clocked_out')
            resizeMap()
          }}
        >
          <Icon
            icon="pause"
            className="tw-mr-2"
          />
          {s_CLOCKED_OUT_USERS}
        </Button>
      </Box>
    )
    // Combined JSX
    panelJSX = (
      <Box
        className="tw-text-left"
        id="user_list_container"
        sx={{ height: 'calc(100vh - 220px)', overflowY: 'scroll' }}
      >
        {toggleJSX}
        {userListJSX}
      </Box>
    )
    return panelJSX
  }

  const rJSX_UsersMapPanel = (): JSX.Element => {
    // Instantiate Variables
    let mapBasicMarkers: TsInterface_MapMarkers = {}
    let mapBasicSettings: TsInterface_MapSettings = {
      center_lat: 0,
      center_lng: 0,
      height: 'calc(100vh - 220px)',
      render_clusters: false,
      render_heatmap: false,
      render_markers: true,
      width: '100%',
      zoom: 12,
    }
    // Unused Map On Click
    const mapBasicOnClick: TsType_MapOnClick = (lat: number, lng: number) => {
      // Nothing
    }
    // Generate Markers
    let usersToMap: TsInterface_UnspecifiedObject = {}
    if (us_visibleUserClockedStatus === 'clocked_in') {
      usersToMap = us_clockedInUsers
    }
    if (us_visibleUserClockedStatus === 'clocked_out') {
      usersToMap = us_clockedOutUsers
    }

    for (let loopUserKey in usersToMap) {
      let loopUser = usersToMap[loopUserKey]
      if (loopUser != null && loopUser['location_latitude'] != null && loopUser['location_longitude'] != null) {
        mapBasicMarkers['USER_' + loopUser.key] = {
          position: {
            lat: loopUser['location_latitude'],
            lng: loopUser['location_longitude'],
          },
          icon: {
            path: returnMapIcons('pin')['path'],
            fillColor: us_highlightedUserKey === loopUser.key ? themeVariables.error_main : themeVariables.background_json,
            fillOpacity: 1,
            strokeWeight: 0.5,
            strokeColor: 'white',
            rotation: 0,
            scale: returnMapIcons('pin')['scale'],
            anchor: returnMapIcons('pin')['anchor'],
          },
          title: loopUser.name,
          label: '',
          onMouseOver: () => {
            us_setHighlightedUserKey(loopUser.key)
          },
          onMouseOut: () => {
            us_setHighlightedUserKey(null)
          },
          onClick: () => {
            scrollToSubDiv(loopUser.key)
          },
        }
      }
    }
    // Resize Map Bounds
    setTimeout(() => {
      if (
        ur_mapRef != null &&
        ur_mapRef.current != null &&
        // @ts-expect-error - TODO: reason for error
        ur_mapRef.current.mapCenter != null &&
        // @ts-expect-error - TODO: reason for error
        ur_mapRef.current.mapCenter.lat === 0 &&
        // @ts-expect-error - TODO: reason for error
        (ur_mapRef.current.mapCenter.lng === 0 || ur_mapRef.current.mapCenter.lng === 180)
      ) {
        // @ts-expect-error - TODO: reason for error
        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
          // @ts-expect-error - TODO: reason for error
          ur_mapRef.current.recalculateMapBounds(true)
        }
      }
    }, 0)
    // JSX
    let panelJSX = <></>
    panelJSX = (
      <Box>
        <MapBasic
          ref={ur_mapRef}
          mapSettings={mapBasicSettings}
          mapMarkers={mapBasicMarkers}
          mapOnClick={mapBasicOnClick}
        />
      </Box>
    )
    return panelJSX
  }

  // Timeline View
  const rJSX_SelectedUserTimelineMap = (): JSX.Element => {
    // Instantiate Variables
    let mapBasicMarkers: TsInterface_MapMarkers = {}
    let mapBasicSettings: TsInterface_MapSettings = {
      center_lat: 0,
      center_lng: 0,
      height: 'calc(100vh - 220px)',
      render_clusters: false,
      render_heatmap: false,
      render_markers: true,
      width: '100%',
      zoom: 12,
    }
    // Unused Map On Click
    const mapBasicOnClick: TsType_MapOnClick = (lat: number, lng: number) => {
      // Nothing
    }
    // Generate Markers
    for (let loopMarkerKey in us_timelineMarkerData) {
      let loopMarker = us_timelineMarkerData[loopMarkerKey]
      if (loopMarker != null && loopMarker['location_latitude'] != null && loopMarker['location_longitude'] != null) {
        mapBasicMarkers['PIN_' + loopMarkerKey] = {
          position: {
            lat: loopMarker['location_latitude'],
            lng: loopMarker['location_longitude'],
          },
          icon: {
            path: returnMapIcons('pin')['path'],
            fillColor: us_highlightedMarkerKey === loopMarkerKey ? themeVariables.warning_main : themeVariables.error_main,
            fillOpacity: 1,
            strokeWeight: 0.5,
            strokeColor: 'white',
            rotation: 0,
            scale: returnMapIcons('pin')['scale'],
            anchor: returnMapIcons('pin')['anchor'],
          },
          label: '',
          // title: loopMarker.name,
          title: returnFormattedDate(loopMarker.first_timestamp, 'h:mm a') + ' to ' + returnFormattedDate(loopMarker.last_timestamp, 'h:mm a'),
          onMouseOver: () => {
            us_setHighlightedTimestampKeys(getProp(loopMarker, 'timestamp_keys', {}))
            us_setHighlightedMarkerKey(loopMarkerKey)
          },
          onMouseOut: () => {
            us_setHighlightedTimestampKeys({})
            us_setHighlightedMarkerKey(null)
          },
        }
      }
    }
    // Resize Map Bounds
    setTimeout(() => {
      if (
        ur_mapRef != null &&
        ur_mapRef.current != null &&
        // @ts-expect-error - TODO: reason for error
        ur_mapRef.current.mapCenter != null &&
        // @ts-expect-error - TODO: reason for error
        ur_mapRef.current.mapCenter.lat === 0 &&
        // @ts-expect-error - TODO: reason for error
        (ur_mapRef.current.mapCenter.lng === 0 || ur_mapRef.current.mapCenter.lng === 180)
      ) {
        // @ts-expect-error - TODO: reason for error
        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
          // @ts-expect-error - TODO: reason for error
          ur_mapRef.current.recalculateMapBounds(true)
        }
      }
    }, 0)
    // JSX
    let panelJSX = <></>
    panelJSX = (
      <Box>
        <MapBasic
          ref={ur_mapRef}
          mapSettings={mapBasicSettings}
          mapMarkers={mapBasicMarkers}
          mapOnClick={mapBasicOnClick}
        />
      </Box>
    )
    return panelJSX
  }

  const rJSX_DatePicker = (disabled: boolean): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mb-2 tw-align-top">
        <DatePicker
          key={'calendar_date'}
          datePickerText={s_DATE}
          datePickerDate={selectedDate}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
          datePickerDisabled={disabled}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_IndividualDriverHistoryTile = (): JSX.Element => {
    let tileJSX = <></>
    // Timestamps
    let rangeTimestampJSX = <></>
    if (objectToArray(us_timelineData).length > 0) {
      let firstPunch = objectToArray(us_timelineData).sort(dynamicSort('location_timestamp', 'asc'))[0]
      let lastPunch = objectToArray(us_timelineData).sort(dynamicSort('location_timestamp', 'desc'))[0]
      rangeTimestampJSX = (
        <Box className="tw-inline-block tw-opacity-30 tw-italic tw-ml-1">
          {returnFormattedDate(firstPunch.location_timestamp, 'h:mm a')} - {returnFormattedDate(lastPunch.location_timestamp, 'h:mm a')}
        </Box>
      )
    }
    // Tile
    if (us_selectedIndividualUser != null) {
      tileJSX = (
        <Box
          className="tw-p-2 tw-m-1 tw-rounded"
          sx={{ background: themeVariables.background_json }}
        >
          <Box>
            <Stack
              direction="row"
              spacing={1}
              className="tw-justify-between"
            >
              <Box>
                <Icon
                  icon="user"
                  className="tw-mr-2"
                />
                {us_selectedIndividualUser.name}
              </Box>
            </Stack>
          </Box>
          <Divider />
          <Box className="tw-pl-6 tw-mt-1">
            <Box>
              <Box
                sx={{ color: themeVariables.error_main }}
                className="tw-mr-2 tw-inline-block"
              >
                <Icon icon="calendar" />
              </Box>
              {returnFormattedDate(selectedDate, 'D MMM YYYY')}
              {rangeTimestampJSX}
            </Box>
            <Box>
              <Box
                sx={{ color: themeVariables.error_main }}
                className="tw-mr-2 tw-inline-block"
              >
                <Icon icon="map-pin" />
              </Box>
              <Box className="tw-mr-2 tw-inline-block">
                {objectToArray(us_timelineData).length} {s_PINGS} {s_AT} {objectToArray(us_timelineMarkerData).length} {s_LOCATIONS}
              </Box>
            </Box>
          </Box>
        </Box>
      )
    }
    return tileJSX
  }

  const rJSX_GeolocationTimestamp = (timestampObject: TsInterface_UnspecifiedObject): JSX.Element => {
    let timestampJSX = <></>
    if (
      timestampObject != null &&
      timestampObject.location_timestamp != null &&
      timestampObject.location_latitude != null &&
      timestampObject.location_longitude != null
    ) {
      let timestampInnerContentJSX = <></>
      if (timestampObject.type === 'clock_in') {
        let allocationTypeNameJSX = <></>
        let allocationSubtypeNameJSX = <></>
        if (getProp(timestampObject, 'last_time_punch_associated_allocation_type_name', null) != null) {
          allocationTypeNameJSX = (
            <Box className="tw-inline-block tw-opacity-100">{getProp(timestampObject, 'last_time_punch_associated_allocation_type_name', null)}</Box>
          )
        }
        if (
          getProp(timestampObject, 'last_time_punch_associated_allocation_subtype_name', null) != null &&
          getProp(timestampObject, 'last_time_punch_associated_allocation_type_key', null) !== 'break'
        ) {
          allocationSubtypeNameJSX = (
            <Box className="tw-inline-block tw-ml-1 tw-opacity-100">
              {' '}
              - {getProp(timestampObject, 'last_time_punch_associated_allocation_subtype_name', null)}
            </Box>
          )
        }
        // location_address
        timestampInnerContentJSX = (
          <Box className="tw-inline-block tw-align-top">
            <Box
              className="tw-inline-block tw-px-1 tw-py-0 tw-rounded-lg tw-mr-1 tw-align-top"
              sx={{ background: themeVariables.success_main }}
            >
              {s_CLOCK_IN}
            </Box>
            <Box className="tw-inline-block">
              <Box>
                {allocationTypeNameJSX}
                {allocationSubtypeNameJSX}
              </Box>
              <Box className="tw-opacity-50 tw-italic tw-font-thin">{getProp(timestampObject, 'location_address', '')}</Box>
            </Box>
          </Box>
        )
      } else if (timestampObject.type === 'clock_out') {
        timestampInnerContentJSX = (
          <Box className="tw-inline-block">
            <Box
              className="tw-inline-block tw-px-1 tw-py-0 tw-rounded-lg tw-mr-1"
              sx={{ background: themeVariables.error_main }}
            >
              {s_CLOCK_OUT}
            </Box>
          </Box>
        )
      } else if (timestampObject.type === 'auto') {
        if (timestampObject.location_is_moving === true) {
          let speedAndHeadingJSX = <></>
          if (timestampObject.location_heading != null && timestampObject.location_speed != null && timestampObject.location_speed >= 0) {
            speedAndHeadingJSX = (
              <Box className="tw-inline-block tw-ml-1 tw-opacity-50">
                {mpsToMph(timestampObject.location_speed).toFixed(0)} {s_MPH} {getCardinalDirection(timestampObject.location_heading)}
              </Box>
            )
          }
          timestampInnerContentJSX = (
            <Box className="tw-inline-block">
              <Box
                className="tw-inline-block tw-px-1 tw-py-0 tw-rounded-lg tw-mr-1"
                sx={{ background: themeVariables.info_main }}
              >
                {s_MOVEMENT}
              </Box>
              {underscoresToSpaces(getProp(timestampObject, 'location_activity_type', ''))}
              {speedAndHeadingJSX}
            </Box>
          )
        } else {
          timestampInnerContentJSX = (
            <Box className="tw-inline-block">
              <Box
                className="tw-inline-block tw-px-1 tw-py-0 tw-rounded-lg tw-mr-1"
                sx={{ background: themeVariables.info_main }}
              >
                {s_AUTO}
              </Box>
            </Box>
          )
        }
      } else {
        timestampInnerContentJSX = (
          <Box className="tw-inline-block">
            <Box
              className="tw-inline-block tw-px-1 tw-py-0 tw-rounded-lg tw-mr-1"
              sx={{ background: themeVariables.warning_main }}
            >
              {timestampObject.type}
            </Box>
          </Box>
        )
      }

      // Generate JSX Parts

      // Background Color
      let backgroundColor = 'rgba(0,0,0,0)'
      let timestampKey = returnDateFromUnknownDateFormat(timestampObject.location_timestamp).getTime().toString()
      if (timestampObject.actual_key != null && us_highlightedTimestampKeys[timestampObject.actual_key] === true) {
        backgroundColor = themeVariables.warning_dark
      }
      // JSX
      timestampJSX = (
        <Box
          className="tw-pb-1"
          onMouseEnter={() => {
            let markerKey = getProp(timestampObject, 'marker_key', null)
            let timestampKeysToHighlight: TsInterface_UnspecifiedObject = {}
            if (
              markerKey != null &&
              us_timelineMarkerData != null &&
              us_timelineMarkerData[markerKey] != null &&
              us_timelineMarkerData[markerKey].timestamp_keys != null
            ) {
              timestampKeysToHighlight = us_timelineMarkerData[markerKey].timestamp_keys
            } else {
              timestampKeysToHighlight[getProp(timestampObject, 'actual_key', timestampKey)] = true
            }
            us_setHighlightedTimestampKeys(timestampKeysToHighlight)
            us_setHighlightedMarkerKey(getProp(timestampObject, 'marker_key', null))
          }}
          onMouseLeave={() => {
            us_setHighlightedTimestampKeys({})
            us_setHighlightedMarkerKey(null)
          }}
          onClick={() => {
            // @ts-expect-error - TODO: reason for error
            if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.setNewMapCenter != null) {
              // @ts-expect-error - TODO: reason for error
              ur_mapRef.current.setNewMapCenter(timestampObject.location_latitude, timestampObject.location_longitude, 17)
            }
          }}
        >
          <Box
            className="tw-rounded tw-p-1 tw-cursor-default"
            sx={{ background: backgroundColor }}
          >
            <Tooltip
              title={
                <>
                  ({timestampObject.location_latitude}, {timestampObject.location_longitude})
                </>
              }
              placement="top"
            >
              <Box
                className="tw-inline-block"
                sx={{ color: themeVariables.error_main }}
              >
                <Icon
                  icon="map-pin"
                  className="tw-mr-2"
                />
              </Box>
            </Tooltip>
            <Box className="tw-inline-block tw-mr-1">
              {returnFormattedDate(returnDateFromUnknownDateFormat(timestampObject.location_timestamp), 'h:mm a')} -
            </Box>
            {timestampInnerContentJSX}
          </Box>
        </Box>
      )
    }
    return timestampJSX
  }

  const rJSX_LocationPings = (): JSX.Element => {
    let pingsJSX = <></>
    if (objectToArray(us_timelineTimeData).length > 0) {
      pingsJSX = (
        <Box className="tw-m-1">
          {objectToArray(us_timelineTimeData)
            .sort(dynamicSort('location_timestamp', 'asc'))
            .map((timestampObject, index) => (
              <Box key={index}>{rJSX_GeolocationTimestamp(timestampObject)}</Box>
            ))}
        </Box>
      )
    } else {
      pingsJSX = (
        <Box className="tw-text-center">
          <Box sx={{ opacity: 0.2 }}>
            <Icon
              icon="triangle-exclamation"
              className="tw-mr-2"
            />
            <Typography
              variant="h6"
              className="tw-inline-block"
            >
              {s_NO_LOCATION_PINGS_FOR} {returnFormattedDate(selectedDate, 'D MMM YYYY')}
            </Typography>
          </Box>
        </Box>
      )
    }
    return pingsJSX
  }

  const rJSX_SelectedUserTimeline = (): JSX.Element => {
    let timelineJSX = <></>
    timelineJSX = (
      <Box
        className="tw-text-left tw-pt-1"
        sx={{ height: 'calc(100vh - 220px)', overflowY: 'scroll' }}
      >
        <Box>
          <Button
            color="inherit"
            variant="outlined"
            className="tw-mr-2 tw-mb-2"
            onClick={() => {
              us_setSelectedIndividualUser(null)
              resizeMap()
            }}
          >
            <Icon
              icon="users"
              className="tw-mr-1"
            />
            {s_VIEW_ALL_USERS}
          </Button>
          {rJSX_DatePicker(false)}
        </Box>
        <Box>
          {rJSX_IndividualDriverHistoryTile()}
          {rJSX_LocationPings()}
        </Box>
      </Box>
    )
    return timelineJSX
  }

  // Tab
  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_selectedIndividualUser != null) {
      tabJSX = (
        <Box className="tw-text-center tw-pt-1">
          <PanelGroup direction="horizontal">
            <Panel
              defaultSize={50}
              minSize={20}
            >
              {rJSX_SelectedUserTimeline()}
            </Panel>
            <PanelResizeHandle className="ResizeHandleOuter">
              <Box className="ResizeHandleInner">
                <svg
                  className="ResizeHandleIcon"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M18,16V13H15V22H13V2H15V11H18V8L22,12L18,16M2,12L6,16V13H9V22H11V2H9V11H6V8L2,12Z"
                  />
                </svg>
              </Box>
            </PanelResizeHandle>
            <Panel minSize={20}>{rJSX_SelectedUserTimelineMap()}</Panel>
          </PanelGroup>
        </Box>
      )
    } else {
      tabJSX = (
        <Box className="tw-text-center tw-pt-1">
          <PanelGroup direction="horizontal">
            <Panel
              defaultSize={50}
              minSize={20}
            >
              {rJSX_UserListPanel()}
            </Panel>
            <PanelResizeHandle className="ResizeHandleOuter">
              <Box className="ResizeHandleInner">
                <svg
                  className="ResizeHandleIcon"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M18,16V13H15V22H13V2H15V11H18V8L22,12L18,16M2,12L6,16V13H9V22H11V2H9V11H6V8L2,12Z"
                  />
                </svg>
              </Box>
            </PanelResizeHandle>
            <Panel minSize={20}>{rJSX_UsersMapPanel()}</Panel>
          </PanelGroup>
        </Box>
      )
    }
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { DatabaseRef_Projects_Collection } from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseGetCollection } from 'rfbp_core/services/database_management'
import { downloadCSV, getProp, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

// TEMP
export const exportAllProjects = (clientKey: string) => {
  return new Promise((resolve, reject) => {
    // Instantiate Variables
    let promiseArray1: TsType_UnknownPromise[] = []
    let exportData = [
      [
        'Job Code',
        'Status',
        'NPS Score',
        'NPS Comments',
        'Product Package',
        'Region',
        'Customer Name',
        'Address',
        'City',
        'County',
        'State',
        'Jurisdiction (AHJ)',
        'Utility Company',
        'System Size',
        'Panel Count',
        'Battery Type',
        'Battery Count',
        'SOW Y/N',
        'MLI Count',
        'Roof Pitch',
        'Roof Type',
        'Distance from WHSE',
        'Miles Driven',
        'Customer Signed',
        'CAP Prepared',
        'CAP Approved',
        'Ready to Install',
        'Install Start',
        'Install End',
        'PTO',
        'System Activated',
        'CS to CAPp',
        'CAPp to CAPa',
        'CAPa to RTI',
        'RTI to Installed',
        'Installed to PTO',
        'PTO to Activated',
        'CAPa to PTO',
        'CSS',
        'Site Auditor',
        'Foreman',
        'Lead',
        'Installer 1',
        'Installer 2',
        'SOW Foreman',
        'SOW Helper',
        'Foreman',
        'Lead',
        'Installer 1',
        'Installer 2',
        'SOW Foreman',
        'SOW Helper',
        'Foreman',
        'Lead',
        'Installer 1',
        'Installer 2',
        'SOW Foreman',
        'SOW Helper',
        'Distance',
        'Pitch',
        'Battery',
        'Material',
        'Install Miles',
        'SOW Miles',
        'Team Members',
      ],
    ]
    let projects: TsInterface_UnspecifiedObject = {}
    // Get Data
    promiseArray1.push(
      DatabaseGetCollection(DatabaseRef_Projects_Collection(clientKey))
        .then((res_DGC) => {
          projects = res_DGC.data
        })
        .catch((rej_DGC) => {
          console.error(rej_DGC)
        }),
    )

    // After Data is Loaded
    Promise.all(promiseArray1).then(() => {
      // Build Export Data
      for (let loopProjectKey in projects) {
        let rowData: string[] = []
        let loopProject = projects[loopProjectKey]
        // Job Code
        rowData.push(getProp(loopProject, 'id_number', ''))
        // Status
        rowData.push('') // TODO
        // NPS Score
        rowData.push('') // TODO
        // NPS Comments
        rowData.push('') // TODO
        // Product Package
        rowData.push(getProp(loopProject, 'associated_product_name', ''))
        // Region
        rowData.push(getProp(loopProject, 'associated_region_name', ''))
        // Customer Name
        rowData.push(getProp(loopProject, 'associated_customer_name', ''))
        // Address
        rowData.push(getProp(loopProject, 'location_address', ''))
        // City
        rowData.push(getProp(loopProject, 'location_city', ''))
        // County
        rowData.push(getProp(loopProject, 'location_county', ''))
        // State
        rowData.push(getProp(loopProject, 'location_state', ''))
        // Jurisdiction (AHJ)
        rowData.push(getProp(loopProject, 'location_jurisdiction', ''))
        // Utility Company
        rowData.push(getProp(loopProject, 'associated_utility_company_name', ''))
        // System Size
        rowData.push(getProp(loopProject, 'system_size_dc', ''))
        // Panel Count
        rowData.push(getProp(loopProject, 'system_panel_quantity', ''))
        // Battery Type
        rowData.push(getProp(loopProject, 'system_storage_manufacturer', '') + ' ' + getProp(loopProject, 'system_storage_model', ''))
        // Battery Count
        rowData.push(getProp(loopProject, 'system_storage_quantity', ''))
        // SOW Y/N
        rowData.push('') // TODO - project_has_any_sow - SUBC
        // MLI Count
        rowData.push('') // TODO
        // Roof Pitch
        rowData.push(getProp(loopProject, 'system_max_roof_pitch', ''))
        // Roof Type
        rowData.push(getProp(loopProject, 'home_roof_type', ''))
        // Distance from WHSE
        rowData.push(getProp(loopProject, 'location_distance_from_warehouse', ''))
        // Miles Driven
        rowData.push('') // TODO
        // Customer Signed
        if (getProp(loopProject, 'timestamp_customer_signed', null) != null) {
          rowData.push(returnFormattedDate(loopProject['timestamp_customer_signed'], 'D MMM YYYY'))
        } else {
          rowData.push('')
        }
        // CAP Prepared
        rowData.push('') // TODO - timestamp_customer_proposal_initiated - SUBC
        // CAP Approved
        if (getProp(loopProject, 'timestamp_customer_proposal_approved', null) != null) {
          rowData.push(returnFormattedDate(loopProject['timestamp_customer_proposal_approved'], 'D MMM YYYY'))
        } else {
          rowData.push('')
        }
        // Ready to Install
        rowData.push('') // TODO
        // Install Start
        rowData.push('') // TODO
        // Install End
        rowData.push('') // TODO
        // PTO
        rowData.push('') // TODO
        // System Activated
        rowData.push('') // TODO
        // CS to CAPp
        rowData.push('') // TODO
        // CAPp to CAPa
        rowData.push('') // TODO
        // CAPa to RTI
        rowData.push('') // TODO
        // RTI to Installed
        rowData.push('') // TODO
        // Installed to PTO
        rowData.push('') // TODO
        // PTO to Activated
        rowData.push('') // TODO
        // CAPa to PTO
        rowData.push('') // TODO
        // CSS
        rowData.push(getProp(loopProject, 'associated_css_rep_name', ''))
        // Site Auditor
        rowData.push('') // TODO
        // Foreman
        rowData.push('') // TODO
        // Lead
        rowData.push('') // TODO
        // Installer 1
        rowData.push('') // TODO
        // Installer 2
        rowData.push('') // TODO
        // SOW Foreman
        rowData.push('') // TODO
        // SOW Helper
        rowData.push('') // TODO
        // Foreman
        rowData.push('') // TODO
        // Lead
        rowData.push('') // TODO
        // Installer 1
        rowData.push('') // TODO
        // Installer 2
        rowData.push('') // TODO
        // SOW Foreman
        rowData.push('') // TODO
        // SOW Helper
        rowData.push('') // TODO
        // Foreman
        rowData.push('') // TODO
        // Lead
        rowData.push('') // TODO
        // Installer 1
        rowData.push('') // TODO
        // Installer 2
        rowData.push('') // TODO
        // SOW Foreman
        rowData.push('') // TODO
        // SOW Helper
        rowData.push('') // TODO
        // Distance
        rowData.push('') // TODO
        // Pitch
        rowData.push('') // TODO
        // Battery
        rowData.push('') // TODO
        // Material
        rowData.push('') // TODO
        // Install Miles
        rowData.push('') // TODO
        // SOW Miles
        rowData.push('') // TODO
        // Team Members
        rowData.push('') // TODO
        // Add To Download Array
        exportData.push(rowData)
      }
      // Export CSV
      let fileName = 'Project Export (' + returnFormattedDate(new Date(), 'D MMM YYYY') + ')'
      downloadCSV(fileName, exportData)
        .then((res_DCSV) => {
          resolve(res_DCSV)
        })
        .catch((rej_DCSV) => {
          reject(rej_DCSV)
        })
    })
  })
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { Box, Button, Card, CardContent, CardHeader, Checkbox, Chip, FormControlLabel, Tooltip, Typography } from '@mui/material/'
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_UserSettings_Document } from 'rfbp_aux/services/database_endpoints/directory/users'
import { DatabaseRef_SpendingLimits_Collection } from 'rfbp_aux/services/database_endpoints/finances/spending'
import { Icon } from 'rfbp_core/components/icons'
import { TableCellBasic, TableDatabase, TsInterface_TableColumns, TsInterface_TableDatabaseSettings } from 'rfbp_core/components/table'
import { TableCellCommaSeparated } from 'rfbp_core/components/table/cells/table_cell_comma_separated'
import { TableCellCurrency } from 'rfbp_core/components/table/cells/table_cell_currency'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_RootData_ClientKey, Context_RootData_ClientUser } from 'rfbp_core/services/context'
import {
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { formatDateToYYYYMMDD, getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import Stripe from 'stripe'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminFinanceSpendingCardholdersViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_BACK_TO_ALL_CARDHOLDERS: JSX.Element = <Trans>Back to all Cardholders</Trans>
const s_DETAILS: JSX.Element = <Trans>Details</Trans>
const s_ALLOWED_CATEGORIES: JSX.Element = <Trans>Allowed Categories</Trans>
const s_AUTHORIZATIONS: JSX.Element = <Trans>Authorizations</Trans>
const s_TRANSACTIONS: JSX.Element = <Trans>Transactions</Trans>
const s_CARD_ID: JSX.Element = <Trans>Card ID</Trans>
const s_BRAND: JSX.Element = <Trans>Brand</Trans>
const s_NAME: JSX.Element = <Trans>Name</Trans>
const s_STRIPE_ID: JSX.Element = <Trans>Stripe ID</Trans>
const s_CREATED: JSX.Element = <Trans>Created</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_CARDS: JSX.Element = <Trans>Cards</Trans>
const s_LIMITS: JSX.Element = <Trans>Limits</Trans>
const s_STATUS: JSX.Element = <Trans>Status</Trans>
const s_CARD_NUMBER: JSX.Element = <Trans>Card Number</Trans>
const s_EXP_MONTH: JSX.Element = <Trans>Exp. Month</Trans>
const s_EXP_YEAR: JSX.Element = <Trans>Exp. Year</Trans>
const s_TYPE: JSX.Element = <Trans>Type</Trans>
const s_ZIP_CODE: JSX.Element = <Trans>Zip Code</Trans>
const s_VERIFICATIONS: JSX.Element = <Trans>Verifications</Trans>
const s_METADATA: JSX.Element = <Trans>Metadata</Trans>
const se_Cardholder = 'Cardholder'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const userKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_userSettings, us_setUserSettings] = useState<TsInterface_UnspecifiedObject>({})
  const [us_cardholder, us_setCardholder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_authorizations, us_setAuthorizations] = useState<Stripe.Issuing.Authorization[]>([])
  const [us_transactions, us_setTransactions] = useState<Stripe.Issuing.Transaction[]>([])
  const [us_cards, us_setCards] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'spending'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        un_routerNavigation(ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    document.title = se_Cardholder
  }, [])

  useEffect(() => {
    if (us_userSettings?.stripe_cardholder_id) {
      cloudFunctionManageRequest('manageFinances', {
        function: 'fetchAuthorizations',
        cardholder_id: us_userSettings.stripe_cardholder_id,
        livemode: us_userSettings.stripe_livemode,
      })
        .then((res_CFMMR: any) => {
          us_setAuthorizations(res_CFMMR.authorizations)
        })
        .catch((rej_CFMMR) => {
          console.error(rej_CFMMR)
        })
    }
  }, [us_userSettings])

  useEffect(() => {
    if (us_userSettings?.stripe_cardholder_id) {
      cloudFunctionManageRequest('manageFinances', {
        function: 'fetchTransactions',
        cardholder_id: us_userSettings.stripe_cardholder_id,
        livemode: us_userSettings.stripe_livemode,
      })
        .then((res_CFMMR: any) => {
          console.log('res_CFMMR')
          console.log(res_CFMMR)
          us_setTransactions(res_CFMMR.transactions)
        })
        .catch((rej_CFMMR) => {
          console.error(rej_CFMMR)
        })
    }
  }, [us_userSettings])

  useEffect(() => {
    if (us_userSettings?.stripe_cardholder_id) {
      cloudFunctionManageRequest('manageFinances', {
        function: 'fetchCardholder',
        cardholder_id: us_userSettings.stripe_cardholder_id,
        livemode: us_userSettings.stripe_livemode,
      })
        .then((res_CFMMR: any) => {
          us_setCardholder(res_CFMMR.cardholder)
        })
        .catch((rej_CFMMR) => {
          console.error(rej_CFMMR)
        })
    }
  }, [us_userSettings])

  useEffect(() => {
    if (us_userSettings?.stripe_cardholder_id) {
      cloudFunctionManageRequest('manageFinances', {
        function: 'fetchCardholderCards',
        cardholder_id: us_userSettings.stripe_cardholder_id,
        livemode: us_userSettings.stripe_livemode,
      })
        .then((res_CFMMR: any) => {
          us_setCards(res_CFMMR.cards)
        })
        .catch((rej_CFMMR) => {
          console.error(rej_CFMMR)
        })
    }
  }, [us_userSettings])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUserSettings(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_UserSettings_Document(res_GCK.clientKey, userKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, userKey])

  const tableDatabaseEndpoint_Limits = () => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
      { prop: 'associated_user_key', comparator: '==', value: userKey },
      {
        prop: 'status',
        comparator: '==',
        value: 'active',
      },
    ]
    let orderByArray: TsInterface_OrderByArray = []
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    let limit = 100
    return generateDatabaseQuery(
      DatabaseRef_SpendingLimits_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  // Functions

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={() => {
          un_routerNavigation(ApplicationPages.AdminFinanceSpendingIndexPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {s_BACK_TO_ALL_CARDHOLDERS}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_LineItem = (displayText: JSX.Element, propKey: string, obj: TsInterface_UnspecifiedObject, dataType: string): JSX.Element => {
    let lineJSX = <></>
    let missingTextJSX = (
      <Box
        component={'span'}
        className="tw-text-error_light tw-italic"
      >
        {s_MISSING}
      </Box>
    )

    const propValue = getProp(obj, propKey, missingTextJSX)

    let contentToRender

    if (dataType === 'date') {
      try {
        contentToRender = formatDateToYYYYMMDD(new Date(propValue * 1000))
      } catch (e) {
        contentToRender = propValue
      }
    } else if (dataType === 'card') {
      contentToRender = `**** **** **** ${propValue}`
    } else if (typeof propValue === 'object' && propValue !== null) {
      contentToRender = Object.entries(propValue).map(([key, value]) => {
        let valueString = value as string
        return (
          <Box
            key={key}
            className="tw-mb-1"
          >
            <Box
              className="tw-pr-2 tw-font-bold tw-pl-6"
              component={'span'}
            >
              {key}:
            </Box>
            <Box
              className="tw-pr-1 tw-opacity-50 tw-font-normal"
              component={'span'}
            >
              {valueString}
            </Box>
          </Box>
        )
      })
    } else {
      contentToRender = propValue
    }

    if (propValue === missingTextJSX) {
      contentToRender = propValue
    }

    // Full JSX
    lineJSX = (
      <Box>
        <Typography variant="h6">
          <Box
            className="tw-pr-2 tw-font-bold"
            component={'span'}
          >
            {displayText}:
          </Box>
          <Box
            className="tw-pr-1 tw-opacity-50 tw-font-normal"
            component={'span'}
          >
            {contentToRender}
          </Box>
        </Typography>
      </Box>
    )
    return lineJSX
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    let tabJSX = <></>
    console.log(us_cardholder)
    console.log(us_cards)
    tabJSX = (
      <Card className="tw-p-2">
        {rJSX_LineItem(s_STRIPE_ID, 'id', us_cardholder, 'string')}
        {rJSX_LineItem(s_NAME, 'name', us_cardholder, 'string')}
        {rJSX_LineItem(s_ZIP_CODE, 'billing.address.postal_code', us_cardholder, 'string')}
        {rJSX_LineItem(s_CREATED, 'created', us_cardholder, 'date')}
        {rJSX_LineItem(s_STATUS, 'status', us_cardholder, 'string')}
        {rJSX_LineItem(s_METADATA, 'metadata', us_cardholder, 'object')}
      </Card>
    )
    return tabJSX
  }

  const rJSX_CardsTab = (): JSX.Element => {
    let tabArray = [] as JSX.Element[]
    let tabJSX = <>{tabArray}</>
    for (let i = 0; i < us_cards.length; i++) {
      tabArray.push(
        <Card className="tw-p-2 tw-mb-2">
          {rJSX_LineItem(s_CARD_ID, 'id', us_cards[i], 'string')}
          {rJSX_LineItem(s_BRAND, 'brand', us_cards[i], 'string')}
          {rJSX_LineItem(s_CREATED, 'created', us_cards[i], 'date')}
          {rJSX_LineItem(s_STATUS, 'status', us_cards[i], 'string')}
          {rJSX_LineItem(s_TYPE, 'type', us_cards[i], 'string')}
          {rJSX_LineItem(s_EXP_MONTH, 'exp_month', us_cards[i], 'string')}
          {rJSX_LineItem(s_EXP_YEAR, 'exp_year', us_cards[i], 'string')}
          {rJSX_LineItem(s_CARD_NUMBER, 'last4', us_cards[i], 'card')}
          {rJSX_LineItem(s_ALLOWED_CATEGORIES, 'spending_controls.allowed_categories', us_cards[i], 'array')}
          {/* {rJSX_LineItem(s_SPENDING_LIMITS, 'spending_controls.spending_limits', us_cards[i], 'object')} */}
        </Card>,
      )
    }
    return tabJSX
  }

  const rJSX_VerificationsTab = (): JSX.Element => {
    let tabJSX = (
      <>
        <Card>
          <CardHeader title={'Vehicles'} />
          <CardContent>
            <FormControlLabel
              control={
                <Checkbox
                  checked={getProp(us_userSettings, 'stripe_authorizations.vehicle_assigned', true)}
                  onChange={(event, value) => {
                    DatabaseSetMergeDocument(DatabaseRef_UserSettings_Document(uc_RootData_ClientKey as string, userKey), {
                      stripe_authorizations: {
                        vehicle_assigned: value,
                      },
                    })
                  }}
                />
              }
              label={'Vehicle Assigned'}
            />
          </CardContent>
        </Card>
      </>
    )
    return tabJSX
  }

  const rJSX_LimitsTab = (): JSX.Element => {
    const tableColumns_Limits: TsInterface_TableColumns = {
      name: TableCellCurrency('amount', 'Amount', 'amount'),
      interval: TableCellBasic('interval', 'Interval', 'interval'),
      categories: TableCellCommaSeparated('categories', 'Categories', 'categories'),
      // stripeID: TableCellBasic('stripe_cardholder_id', 'Stripe ID', 'stripe_cardholder_id'),
      // authorizations: spendingAuthorizationsColumn,
      // spendingLimits: spendingLimitsColumn,
    }

    const tableSettings_Limits: TsInterface_TableDatabaseSettings = {
      rows_per_page: 100,
      sort_direction: 'asc',
      sort_property: 'key',
    }

    const pageJSX = (
      <Card className="">
        <TableDatabase
          tableColumns={tableColumns_Limits}
          tableDatabaseEndpoint={tableDatabaseEndpoint_Limits}
          tableSettings={tableSettings_Limits}
          tableAdditionalData={{}}
        />
      </Card>
    )
    return pageJSX
  }

  const rJSX_AuthorizationsTab = (): JSX.Element => {
    const columns: GridColDef[] = [
      {
        field: 'created',
        headerName: 'DATE',
        width: 120,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>
              {new Date(params.value * 1000).toLocaleString('en-US', { month: 'short' })} {new Date(params.value * 1000).getDate()}
            </Typography>
          </Box>
        ),
      },
      {
        field: 'merchant_data.name',
        headerName: 'DESCRIPTION',
        width: 200,
        renderCell: (params) => {
          const name = params.row.merchant_data?.name || ''
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'approved',
        headerName: 'STATUS',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Tooltip
            title={params.row.metadata?.error_details ? `${params.row.metadata?.error_details || ''}`.trim() : ''}
            arrow
          >
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {params.value ? (
                <>
                  <CheckCircleOutlineIcon
                    color="success"
                    sx={{ mr: 1 }}
                  />
                  <Chip
                    label="Completed"
                    color="success"
                    size="small"
                  />
                </>
              ) : (
                <>
                  <ErrorOutlineIcon
                    color="error"
                    sx={{ mr: 1 }}
                  />
                  <Chip
                    label="Failed"
                    color="error"
                    size="small"
                  />
                </>
              )}
            </Box>
          </Tooltip>
        ),
      },
      {
        field: 'amount',
        headerName: 'AMOUNT',
        flex: 1,
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{`$${(params.value / 100).toFixed(2)}`}</Typography>
          </Box>
        ),
      },
    ]

    let tabJSX = (
      <Box
        sx={{
          flex: 1,
          height: '100%',
        }}
      >
        <DataGrid
          rows={us_authorizations}
          columns={columns}
          // onRowClick={(params) => setSelectedTransaction(params.row)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          disableRowSelectionOnClick
          pageSizeOptions={[25]}
          localeText={{
            noRowsLabel: '',
          }}
          sx={{
            'width': '100%',
            '& .MuiDataGrid-main': {
              width: '100%',
            },
          }}
        />
      </Box>
    )
    return tabJSX
  }

  const rJSX_TransactionsTab = (): JSX.Element => {
    const columns: GridColDef[] = [
      {
        field: 'created',
        headerName: 'DATE',
        width: 120,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{new Date(params.value * 1000).toLocaleDateString()}</Typography>
          </Box>
        ),
      },
      {
        field: 'merchant_data.name',
        headerName: 'DESCRIPTION',
        width: 200,
        renderCell: (params) => {
          const name = params.row.merchant_data?.name || ''
          return (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {name}
            </Box>
          )
        },
      },
      {
        field: 'type',
        headerName: 'STATUS',
        width: 150,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {params.value === 'capture' ? (
              <>
                <CheckCircleOutlineIcon
                  color="success"
                  sx={{ mr: 1 }}
                />
                <Chip
                  label="Completed"
                  color="success"
                  size="small"
                />
              </>
            ) : (
              <>
                <ErrorOutlineIcon
                  color="error"
                  sx={{ mr: 1 }}
                />
                <Chip
                  label="Failed"
                  color="error"
                  size="small"
                />
              </>
            )}
          </Box>
        ),
      },
      {
        field: 'amount',
        headerName: 'AMOUNT',
        width: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography>{`$${(params.value / -100).toFixed(2)}`}</Typography>
          </Box>
        ),
      },
    ]

    let tabJSX = (
      <Box sx={{ flexGrow: 1, height: '100%' }}>
        <DataGrid
          rows={us_transactions}
          columns={columns}
          // onRowClick={(params) => setSelectedTransaction(params.row)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[25]}
          localeText={{
            noRowsLabel: '',
          }}
        />
      </Box>
    )
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={us_userSettings?.name || ''}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <Box className="">
              <TabsUrl
                tabsSettings={{
                  baseUrl: ApplicationPages.AdminFinanceSpendingCardholdersViewPage.url(userKey),
                  tabQueryParam: 'tab',
                  overridePageTitle: true,
                  basePageTitle: se_Cardholder,
                }}
                tabs={[
                  {
                    tabUrlKey: 'Details',
                    tabHeader: s_DETAILS,
                    tabContent: rJSX_DetailsTab(),
                  },
                  {
                    tabUrlKey: 'Cards',
                    tabHeader: s_CARDS,
                    tabContent: rJSX_CardsTab(),
                  },
                  {
                    tabUrlKey: 'Verifications',
                    tabHeader: s_VERIFICATIONS,
                    tabContent: rJSX_VerificationsTab(),
                  },
                  {
                    tabUrlKey: 'Limits',
                    tabHeader: s_LIMITS,
                    tabContent: rJSX_LimitsTab(),
                  },
                  {
                    tabUrlKey: 'Authorizations',
                    tabHeader: s_AUTHORIZATIONS,
                    tabContent: rJSX_AuthorizationsTab(),
                  },
                  {
                    tabUrlKey: 'Transactions',
                    tabHeader: s_TRANSACTIONS,
                    tabContent: rJSX_TransactionsTab(),
                  },
                ]}
              />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  return <>{rJSX_Page()}</>
}

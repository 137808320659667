///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_UserProfilePictures = (clientKey: string, userKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/user_profiles/' + userKey + '/images/' + fileName)
}

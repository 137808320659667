///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientUser } from 'rfbp_core/services/context'
import { dynamicSort, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseHomePage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Page Specific Permissions
///////////////////////////////

const defaultPagePermissions: TsInterface_UnspecifiedObject = {
  api_management: false,
  company_documents: false,
  // contacts: false,
  finance_partners: false,
  // form_options: false,
  hoas: false,
  incident_reports: false,
  jurisdiction: false,
  leaderboard: false,
  materials: false,
  ml: false,
  payroll: false,
  regions: false,
  sales_partners: false,
  sales_tools: false,
  spending: false,
  task_forms: false,
  task_library: false,
  task_workflows: false,
  tools: false,
  users: false,
  utilities: false,
  vehicles: false,
  warehouses: false,
}

// TODO - database sub pages need to pull from this somehow...

const pageRolePermissions: TsInterface_UnspecifiedObject = {
  accounts_payable: {
    company_documents: true,
    leaderboard: true,
    payroll: true,
  },
  api_user: {
    api_management: true,
  },
  admin: {
    api_management: true,
    company_documents: true,
    // contacts: true,
    finance_partners: true,
    form_options: true,
    hoas: true,
    incident_reports: true,
    jurisdiction: true,
    leaderboard: true,
    materials: true,
    ml: true,
    regions: true,
    sales_partners: true,
    sales_tools: true,
    spending: true,
    task_forms: true,
    task_library: true,
    task_workflows: true,
    tools: true,
    users: true,
    utilities: true,
    vehicles: true,
    warehouses: true,
  },
  construction_lead: {
    company_documents: true,
    hoas: true,
    jurisdiction: true,
    leaderboard: true,
    utilities: true,
  },
  css_manager: {
    company_documents: true,
    hoas: true,
    jurisdiction: true,
    leaderboard: true,
    utilities: true,
  },
  css_rep: {
    company_documents: true,
    hoas: true,
    jurisdiction: true,
    leaderboard: true,
    utilities: true,
  },
  // design
  // electrical_foreman
  // field_service
  general_manager: {
    company_documents: true,
    hoas: true,
    incident_reports: true,
    jurisdiction: true,
    leaderboard: true,
    materials: true,
    tools: true,
    utilities: true,
    vehicles: true,
  },
  // home_upgrades_electrician
  // home_upgrades_electrician_trainee
  // inspection
  install_coordinator: {
    company_documents: true,
    hoas: true,
    jurisdiction: true,
    leaderboard: true,
    utilities: true,
  },
  // install_lead
  // installer
  interconnection: {
    company_documents: true,
    hoas: true,
    jurisdiction: true,
    leaderboard: true,
    utilities: true,
  },
  permitting: {
    company_documents: true,
    hoas: true,
    jurisdiction: true,
    leaderboard: true,
    utilities: true,
  },
  // repair
  // scheduling
  // site_auditor
  warehouse: {
    company_documents: true,
    incident_reports: true,
    leaderboard: true,
    materials: true,
    tools: true,
    vehicles: true,
  },
}

export const determineDatabasePageSpecificPermissions = (clientUser: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let pageSpecificPermissions: TsInterface_UnspecifiedObject = { ...defaultPagePermissions }
  if (clientUser != null && clientUser.user_role != null) {
    for (let loopPermissionKey in defaultPagePermissions) {
      if (
        pageRolePermissions != null &&
        pageRolePermissions[clientUser.user_role] != null &&
        pageRolePermissions[clientUser.user_role][loopPermissionKey] === true
      ) {
        pageSpecificPermissions[loopPermissionKey] = true
      }
    }
  }
  if (clientUser != null && clientUser.task_roles != null) {
    for (let loopTaskRole in clientUser.task_roles) {
      let loopRoleValue = clientUser.task_roles[loopTaskRole]
      if (loopRoleValue === true) {
        for (let loopPermissionKey in defaultPagePermissions) {
          if (pageRolePermissions != null && pageRolePermissions[loopTaskRole] != null && pageRolePermissions[loopTaskRole][loopPermissionKey] === true) {
            pageSpecificPermissions[loopPermissionKey] = true
          }
        }
      }
    }
  }
  return pageSpecificPermissions
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_pageSpecificUserRolePermission, us_setPageSpecificUserRolePermission] = useState<TsInterface_UnspecifiedObject>(defaultPagePermissions)
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Admin Database', false) as string
  }, [])

  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      us_setPageSpecificUserRolePermission(determineDatabasePageSpecificPermissions(uc_RootData_ClientUser))
    }
  }, [uc_RootData_ClientUser])

  // Other Variables
  const databasePages = {
    api_management: {
      key: 'api_management',
      name: rLIB('API Management'),
      icon: (
        <Icon
          icon="code"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseAPIManagementIndexPage.url(),
    },
    company_documents: {
      key: 'company_documents',
      name: rLIB('Company Documents'),
      icon: (
        <Icon
          icon="folder-open"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseCompanyDocumentsPage.url(),
    },
    // contacts: {
    // key: "contacts",
    // name: s_CONTACTS,
    // icon: <Icon icon="phone" size="3x" type="light" />,
    // url: ApplicationPages.AdminDatabaseContactsListPage.url(),
    // },
    hoas: {
      key: 'hoas',
      name: rLIB('HOAs'),
      icon: (
        <Icon
          icon="house-flag"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseHoasListPage.url(),
    },
    incident_reports: {
      key: 'incident_reports',
      name: rLIB('Incident Reports'),
      icon: (
        <Icon
          icon="car-burst"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseIncidentReportsListPage.url(),
    },
    jurisdiction: {
      key: 'jurisdiction',
      name: rLIB('Jurisdictions'),
      icon: (
        <Icon
          icon="gavel"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseJurisdictionsListPage.url(),
    },
    leaderboard: {
      key: 'leaderboard',
      name: rLIB('Leaderboard'),
      icon: (
        <Icon
          icon="ranking-star"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseLeaderboardIndexPage.url(),
    },
    materials: {
      key: 'materials',
      name: rLIB('Materials'),
      icon: (
        <Icon
          icon="reel"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseMaterialsIndexPage.url(),
    },
    ml: {
      key: 'ml',
      name: rLIB('Machine Learning'),
      icon: (
        <Icon
          icon="robot"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.MachineLearningIndexPage.url(),
    },
    payroll: {
      key: 'payroll',
      name: rLIB('Payroll'),
      icon: (
        <Icon
          icon="money-check-dollar-pen"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminPayrollIndexPage.url(),
    },
    regions: {
      key: 'regions',
      name: rLIB('Regions'),
      icon: (
        <Icon
          icon="compass"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseRegionsListPage.url(),
    },
    sales_partners: {
      key: 'sales_partners',
      name: rLIB('Sales Partners'),
      icon: (
        <Icon
          icon="badge-dollar"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseSalesPartnersListPage.url(),
    },
    sales_tools: {
      key: 'sales_tools',
      name: rLIB('Sales Tools'),
      icon: (
        <Icon
          icon="credit-card"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminSalesToolsIndexPage.url(),
    },
    spending: {
      key: 'spending',
      name: rLIB('Spending'),
      icon: (
        <Icon
          icon="credit-card"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminFinanceSpendingIndexPage.url(),
    },
    task_forms: {
      key: 'task_forms',
      name: rLIB('Task Forms'),
      icon: (
        <Icon
          icon="clipboard-list-check"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseTaskFormsListPage.url(),
    },
    task_library: {
      key: 'task_library',
      name: rLIB('Task Library'),
      icon: (
        <Icon
          icon="square-check"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseTasksListPage.url(),
    },
    task_workflows: {
      key: 'task_workflows',
      name: rLIB('Task Workflows'),
      icon: (
        <Icon
          icon="sitemap"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseTaskWorkflowListPage.url(),
    },
    tools: {
      key: 'tools',
      name: rLIB('Tools'),
      icon: (
        <Icon
          icon="toolbox"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseToolsListPage.url(),
    },
    users: {
      key: 'users',
      name: rLIB('Users'),
      icon: (
        <Icon
          icon="users"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseUserListPage.url(),
    },
    utilities: {
      key: 'utilities',
      name: rLIB('Utilities'),
      icon: (
        <Icon
          icon="transformer-bolt"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseUtilitiesListPage.url(),
    },
    vehicles: {
      key: 'vehicles',
      name: rLIB('Vehicles'),
      icon: (
        <Icon
          icon="truck-pickup"
          size="3x"
          type="light"
        />
      ),
      url: ApplicationPages.AdminDatabaseVehiclesListPage.url(),
    },
    // warehouses: {
    // key: "warehouses",
    // name: s_WAREHOUSES,
    // icon: <Icon icon="warehouse-full" size="3x" type="light" />,
    // url: ApplicationPages.AdminDatabaseWarehousesListPage.url(),
    // },
  }

  // Functions

  // JSX Generation
  const rJSX_PageLinks = (page: TsInterface_UnspecifiedObject): JSX.Element => {
    let linkJSX = <></>
    if (page != null && page.key != null && us_pageSpecificUserRolePermission != null && us_pageSpecificUserRolePermission[page.key] === true) {
      linkJSX = (
        <Box
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, page.url)
          }}
          className="tw-inline-block tw-text-center tw-m-2 tw-p-2 tw-border-2 tw-rounded-xl tw-border-solid tw-cursor-pointer"
          sx={{
            'height': '100px',
            'width': '250px',
            'borderColor': themeVariables.white,
            '&:hover': {
              backgroundColor: themeVariables.background_paper,
              borderColor: themeVariables.info_main,
              color: themeVariables.info_main,
            },
          }}
        >
          <Box className="tw-mt-1">{page.icon}</Box>
          <Box className="tw-mt-2">{page.name}</Box>
        </Box>
      )
    }
    return linkJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Admin Database') as JSX.Element}
        pageKey={pageKey}
        content={
          <Box
            className="tw-text-center tw-m-auto"
            sx={{ maxWidth: '900px' }}
          >
            {objectToArray(databasePages)
              .sort(dynamicSort('key', null))
              .map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>{rJSX_PageLinks(page)}</React.Fragment>
              ))}
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_ProjectTaskPageFile = (clientKey: string, projectKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/projects/' + projectKey + '/images/' + fileName)
}

export const StorageRef_ProjectImageThumbnailsFolder = (clientKey: string, projectKey: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/projects/' + projectKey + '/images/thumbnails/')
}

export const StorageRef_ProjectImageThumbnailFile = (clientKey: string, projectKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/projects/' + projectKey + '/images/thumbnails/' + fileName)
}

export const StorageRef_ProjectDocumentsFolder = (clientKey: string, projectKey: string, folderKey: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/projects/' + projectKey + '/documents/' + folderKey + '/')
}

export const StorageRef_ProjectDocumentsFile = (clientKey: string, projectKey: string, folderKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/projects/' + projectKey + '/documents/' + folderKey + '/' + fileName)
}

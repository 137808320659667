///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { useContext, useEffect, useReducer } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_Hoas_Collection, DatabaseRef_Hoas_Search } from 'rfbp_aux/services/database_endpoints/directory/hoas'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TableCellManage,
  TableCellPhone,
  TableDatabase,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDatabaseEndpointQueryObject,
  TsInterface_TableDatabaseSettings,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
} from 'rfbp_core/components/table'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseHoasListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_CREATE_HOA: JSX.Element = <Trans>Create HOA</Trans>
const s_EMAIL: JSX.Element = <Trans>Email</Trans>
const s_HOAS: JSX.Element = <Trans>HOAs</Trans>
const s_HOA_NAME: JSX.Element = <Trans>HOA Name</Trans>
const s_NEW_HOA: JSX.Element = <Trans>New HOA</Trans>
const s_NO_HOAS_FOUND_BY_SEARCH: JSX.Element = <Trans>No HOAs found by search</Trans>
const s_PHONE: JSX.Element = <Trans>Phone</Trans>
const s_VIEW: JSX.Element = <Trans>View</Trans>
const se_HOAS: string = 'HOAs'
// { sort-end } - displayed text

// Forms
const formInputs_HoaName: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: s_HOA_NAME,
    required: true,
  },
}

// Table
const tableColumns_HOAS: TsInterface_TableColumns = {
  manage: TableCellManage({
    view: {
      icon: (
        <Icon
          type="solid"
          icon="magnifying-glass"
        />
      ),
      label: <>{s_VIEW}</>,
      onClick: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        if (rowData.key != null) {
          directAppNavigation(tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseHoaViewPage.url(rowData.key as string))
        }
      },
    },
  }),
  name: {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_HOA_NAME
      },
      header_sort_by: 'name',
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const cellJSX = (
          <>
            <Box
              className="tw-cursor-pointer tw-rounded-md tw-p-1 tw-text-center tw-inline-block"
              sx={{ 'background': themeVariables.background_default, '&:hover': { background: themeVariables.background_json } }}
              onClick={(event) => {
                if (rowData.key != null) {
                  // TODO - handle other user types probably
                  onClickAppNavigation(event, tableHooks.un_routerNavigation, ApplicationPages.AdminDatabaseHoaViewPage.url(rowData.key as string))
                }
              }}
            >
              {rowData.name}
            </Box>
          </>
        )
        return cellJSX
      },
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        const cellCSS = ''
        return cellCSS
      },
    },
  },
  phone: TableCellPhone('phone', s_PHONE, 'phone'),
  email: TableCellBasic('email', s_EMAIL, 'email'),
}

const tableSettings_Hoas: TsInterface_TableDatabaseSettings = {
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        comparator: '==',
        conditions: [],
        logic_type: 'comparison',
        prop: 'status',
        source: 'rowData',
        value: 'deleted',
      },
    },
  ],
  rows_per_page: 100,
  search_settings_database: {
    search_no_data_message: s_NO_HOAS_FOUND_BY_SEARCH,
    search_property_options: [
      {
        key: 'name',
        value: s_HOA_NAME,
      },
    ],
    search_type: 'firebase',
  },
  searchable: true,
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property: 'name',
  use_live_data: false,
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)

  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'hoas'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    document.title = se_HOAS
  }, [])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const tableDatabaseEndpoint_Hoas = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(DatabaseRef_Hoas_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const createHoa = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_HoaName,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  formSubmittedData.status = 'active'
                  DatabaseAddDocument(DatabaseRef_Hoas_Collection(res_GCK.clientKey), formSubmittedData, true)
                    .then((res_DSMD) => {
                      resolve({ success: true })
                      directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHoaViewPage.url(res_DSMD.key))
                    })
                    .catch((rej_DSMD) => {
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: <>{s_CREATE_HOA}</>,
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const tableSearchEndpoint_Hoas = (searchProperty: string, searchInput: string) => {
    let limit = tableSettings_Hoas.rows_per_page
    if (limit === null) {
      limit = 20
    }
    return DatabaseRef_Hoas_Search(uc_RootData_ClientKey as string, searchProperty, searchInput, limit)
  }

  // JSX Generation
  const rJSX_NewHoaButton = (): JSX.Element => {
    let buttonJSX = <></>
    buttonJSX = (
      <Button
        color="success"
        variant="contained"
        onClick={() => {
          createHoa()
        }}
        disableElevation
        startIcon={
          <Icon
            icon="circle-plus"
            type="solid"
          />
        }
      >
        {s_NEW_HOA}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_HoaTable = (): JSX.Element => {
    let tableJSX = <></>
    if (uc_RootData_ClientKey != null) {
      if (tableSettings_Hoas.search_settings_database == null) {
        tableSettings_Hoas.search_settings_database = { search_type: 'firebase' }
      }
      tableSettings_Hoas.search_settings_database.search_endpoint = tableSearchEndpoint_Hoas
      tableJSX = (
        <Box>
          <Card className="">
            <TableDatabase
              tableAdditionalData={{ visible_active_tasks: {} }}
              tableColumns={tableColumns_HOAS}
              tableDatabaseEndpoint={tableDatabaseEndpoint_Hoas}
              tableSettings={tableSettings_Hoas}
            />
          </Card>
        </Box>
      )
    } else {
      tableJSX = <></>
    }
    return tableJSX
  }

  const rJSX_Page = (): JSX.Element => {
    const pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_HOAS}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_NewHoaButton()}</Box>
            <Box className="tw-mt-2">{rJSX_HoaTable()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, Button, Stack, Typography, useMediaQuery } from '@mui/material/'
import { returnProjectDesignPreferences } from 'app/models/projects/project_design_preferences'
import { returnProjectDocumentFolders } from 'app/models/projects/project_document_folders'
import { returnImageTags } from 'app/models/projects/project_image_tags'
import {
  generateDefaultMessageThreadsInDatabase,
  generateStandardProjectMessages,
  returnCombinedTaskRoles,
  returnGroupedTasksData,
} from 'app/models/projects/project_services'
import { findRecursiveTasks, returnTaskPrerequisiteAnalysisObject } from 'app/models/tasks/task_workflow_services'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveTaskBlueprints_Query } from 'rfbp_aux/services/database_endpoints/directory/task_blueprints'
import { DatabaseRef_TaskFormProdPages_Document, DatabaseRef_TaskFormsActive_Query } from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import { DatabaseRef_ProjectFeedback_Query } from 'rfbp_aux/services/database_endpoints/operations/feedback'
import {
  DatabaseRef_MessageThreadMessages_CollectionOrdered_Query,
  DatabaseRef_ProjectMessageThreadsCollection_Query,
} from 'rfbp_aux/services/database_endpoints/operations/messages'
import {
  DatabaseRef_ProjectAdditionalData_Document,
  DatabaseRef_ProjectDesignPreferences_Document,
  DatabaseRef_ProjectNoteThreadNotes_Collection,
  DatabaseRef_ProjectNoteThreads_Collection,
  DatabaseRef_ProjectRoleAssignments_Document,
  DatabaseRef_ProjectTaskFormData_Collection,
  DatabaseRef_ProjectTaskFormData_Document,
  DatabaseRef_ProjectTaskWorkflow_Document,
  DatabaseRef_Project_Document,
  DatabaseRef_Project_Documents_Collection,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { DatabaseRef_ProjectReminders_Query } from 'rfbp_aux/services/database_endpoints/operations/reminders'
import { DatabaseRef_AllProjectTasks_Query, DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { StorageRef_ProjectImageThumbnailFile, StorageRef_ProjectImageThumbnailsFolder } from 'rfbp_aux/services/storage_endpoints/projects'
import { TsInterface_ChatMessages, TsInterface_ChatThread } from 'rfbp_core/components/chat'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientUser,
  Context_RootData_GlobalUser,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_ConfirmDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_PromptDialog,
  Context_UserInterface_Snackbar,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  StorageGetDownloadUrl,
  StorageListFiles,
  TsInterface_DatabaseBatchUpdatesArray,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_ContactLogsTabHeader, rJSX_ContactLogTab } from './project_view/tab_contact_log'
import { rJSX_ETWEnergyProjectDetailsTab } from './project_view/tab_details_etw_energy'
import { rJSX_SunrunProjectDetailsTab } from './project_view/tab_details_sunrun'
import { rJSX_TeslaProjectDetailsTab } from './project_view/tab_details_tesla'
import { rJSX_DocumentsTab } from './project_view/tab_documents'
import { rJSX_LogsTab } from './project_view/tab_logs'
import { defaultEmptyMessageThread, rJSX_ChatTabHeader, rJSX_MessagesTab } from './project_view/tab_messages'
import { rJSX_NotesTab } from './project_view/tab_notes'
import { rJSX_PhotosTab } from './project_view/tab_photos'
import { rJSX_AdditionalWorkTab } from './project_view/tab_sow'
import { rJSX_TasksTab, rJSX_TaskTabHeader } from './project_view/tab_tasks'
import { rJSX_SelectProjectCodeButton, rJSX_SelectTaskWorkflowButton, rJSX_TeamTab } from './project_view/tab_team'

///////////////////////////////
// Typescript
///////////////////////////////

type TsType_TaskTableFilterOptions =
  | 'all'
  | 'active_tasks'
  | 'completed_tasks'
  | 'future_tasks'
  | 'deleted'
  | 'not_deleted'
  | 'user_tasks'
  | 'unassigned'
  | 'invalid_prereq_data_structure'
type TsType_NoteTableFilterOptions = 'all' | 'deleted' | 'not_deleted' | 'starred'
export type TsType_DocumentTableFilterOptions = 'all' | 'archived' | 'not_archived' | 'starred' | 'visible_to_customer' | 'hidden_from_customer'

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminActiveProjectViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ADDITIONAL_WORK: JSX.Element = <Trans>Additional Work</Trans>
const s_BACK_TO_ALL_ACTIVE_PROJECTS: JSX.Element = <Trans>Back to all active projects</Trans>
const s_CHECK_TASK_PAGE_TO_SEE_WHY_THIS_PROJECT_IS_STALLED: JSX.Element = <Trans>Check task page to see why this project is stalled</Trans>
const s_CUSTOMER_DETAILS: JSX.Element = <Trans>Customer Details</Trans>
const s_DESIGN: JSX.Element = <Trans>Design</Trans>
const s_DETAILS: JSX.Element = <Trans>Details</Trans>
const s_FAILED_TO_CALCULATE_COORDIATES: JSX.Element = <Trans>Failed to Calculate Coordinates</Trans>
const s_LOGS: JSX.Element = <Trans>Logs</Trans>
const s_MISSING_TEAM_MEMBER_ASSIGNMENTS: JSX.Element = <Trans>Missing team member assignments</Trans>
const s_NEW_PROJECT: JSX.Element = <Trans>New Project</Trans>
const s_NOTES: JSX.Element = <Trans>Notes</Trans>
const s_NO_ACTIVE_TASKS: JSX.Element = <Trans>No Active Tasks</Trans>
const s_NO_PROJECT_ID_GENERATED_YET: JSX.Element = <Trans>No Project ID Generated Yet</Trans>
const s_NO_TASK_WOKFLOW_SELECTED_FOR_PROJECT: JSX.Element = <Trans>No task workflow selected for project</Trans>
const s_PHOTOS: JSX.Element = <Trans>Photos</Trans>
const s_PROJECT_CANCELLED: JSX.Element = <Trans>Project Cancelled</Trans>
const s_PROJECT_COMPLETE: JSX.Element = <Trans>Project Complete</Trans>
const s_PROJECT_ON_HOLD: JSX.Element = <Trans>Project On Hold</Trans>
const s_TEAM: JSX.Element = <Trans>Team</Trans>
// { sort-end } - displayed text

const taskTagPrefix = 'TASK: '

///////////////////////////////
// Functions
///////////////////////////////

const scrollToBottomOfChat = (): void => {
  setTimeout(() => {
    let chatBox = document.getElementById('etw_chat_message_display')
    if (chatBox != null) {
      chatBox.scrollTop = chatBox.scrollHeight
    }
  }, 1)
}

const checkAndRepairTaskStatuses = (clientKey: string, tasks: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    let messedUpTasks: TsInterface_UnspecifiedObject = {}
    let taskRepairUpdateObjects: TsInterface_UnspecifiedObject = {}
    let hasTaskRepairs = false
    // Loop through tasks
    for (let loopTaskKey in tasks) {
      let loopTask = tasks[loopTaskKey]
      // If there are prerequisite tasks
      if (loopTask.prerequisite_tasks == null || objectToArray(loopTask.prerequisite_tasks).length === 0) {
        if (loopTask['ready_to_start'] === false && loopTask['status'] !== 'deleted') {
          taskRepairUpdateObjects[loopTaskKey] = { ready_to_start: true }
        }
      }
      if (loopTask.prerequisite_tasks != null) {
        // Loop through prerequisite tasks
        for (let loopPrereqTaskKey in loopTask.prerequisite_tasks) {
          let loopPrereqTask = tasks[loopPrereqTaskKey]
          if (loopPrereqTask != null && loopPrereqTask.status_complete === true) {
            if (loopTask['ready_to_start'] === false && loopTask['status'] !== 'deleted') {
              messedUpTasks[loopTaskKey] = loopTask
              if (taskRepairUpdateObjects[loopTaskKey] == null) {
                taskRepairUpdateObjects[loopTaskKey] = {
                  ready_to_start: true,
                  prerequisite_tasks_completion: {},
                }
              }
              if (loopPrereqTask['timestamp_completed'] != null) {
                taskRepairUpdateObjects[loopTaskKey]['prerequisite_tasks_completion'][loopPrereqTaskKey] = loopPrereqTask['timestamp_completed']
              }
              hasTaskRepairs = true
            }
            loopTask.prerequisite_tasks[loopPrereqTaskKey] = loopPrereqTaskKey
          }
        }
      }
    }
    if (hasTaskRepairs === true) {
      let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
      for (let loopTaskKey in taskRepairUpdateObjects) {
        taskRepairUpdateObjects[loopTaskKey]['timestamp_last_updated'] = new Date()
        updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskKey), data: taskRepairUpdateObjects[loopTaskKey] })
      }
      DatabaseBatchUpdate(updateArray)
        .then((res_DBU) => {
          resolve(res_DBU)
        })
        .catch((rej_DBU) => {
          reject(rej_DBU)
        })
    } else {
      resolve({ success: true })
    }
  })
}

const potentiallyGeocodeAddressForProject = (clientKey: string, projectKey: string, project: TsInterface_UnspecifiedObject) => {
  return new Promise((resolve, reject) => {
    if (project != null && project['location_latitude'] != null && project['location_longitude'] != null) {
      resolve({ success: true })
    } else if (
      project != null &&
      project['location_address'] != null &&
      project['location_city'] != null &&
      project['location_state'] != null &&
      project['location_zip'] != null
    ) {
      // Get Coordinates
      let address = ''
      if (project['location_address'] != null) {
        address += project['location_address'] + ' '
      }
      if (project['location_city'] != null) {
        address += project['location_city'] + ' '
      }
      if (project['location_state'] != null) {
        address += project['location_state'] + ' '
      }
      if (project['location_zip'] != null) {
        address += project['location_zip'] + ' '
      }
      // Have Google Maps Geocode it
      let geocoder = new google.maps.Geocoder()
      geocoder.geocode({ address: address }, (results: any, status: any) => {
        // If there is a result
        if (status === 'OK') {
          // Set Latitude and Longitude
          let updateObject = {
            location_latitude: results[0].geometry.location.lat(),
            location_longitude: results[0].geometry.location.lng(),
          }
          let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
            { type: 'setMerge', ref: DatabaseRef_Project_Document(clientKey, projectKey), data: updateObject },
          ]
          DatabaseBatchUpdate(updateArray)
            .then((res_DBU) => {
              resolve(res_DBU)
            })
            .catch((rej_DBU) => {
              reject(rej_DBU)
            })
        } else {
          reject({
            success: false,
            error: {
              message: s_FAILED_TO_CALCULATE_COORDIATES,
              details: status,
              code: 'ER-D-TDI-ACTT-01',
            },
          })
        }
      })
    } else {
      // Not enough data to generate GPS Coordinates
      reject({ success: false })
    }
  })
}

const potentiallyCopyCoordinatesToTasks = (
  clientKey: string,
  projectKey: string,
  project: TsInterface_UnspecifiedObject,
  tasks: TsInterface_UnspecifiedObject,
) => {
  return new Promise((resolve, reject) => {
    let hasTasksToUpdate = false
    let tasksToUpdate: TsInterface_UnspecifiedObject = {}
    if (project.location_latitude != null && project.location_longitude != null) {
      for (let loopTaskKey in tasks) {
        let loopTask = tasks[loopTaskKey]
        if (loopTask.location_latitude == null || loopTask.location_longitude == null) {
          hasTasksToUpdate = true
          tasksToUpdate[loopTaskKey] = {
            location_latitude: project.location_latitude,
            location_longitude: project.location_longitude,
          }
        }
      }
      if (hasTasksToUpdate === true) {
        let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
        for (let loopTaskKey in tasksToUpdate) {
          tasksToUpdate[loopTaskKey]['timestamp_last_updated'] = new Date()
          updateArray.push({ type: 'setMerge', ref: DatabaseRef_Task_Document(clientKey, loopTaskKey), data: tasksToUpdate[loopTaskKey] })
        }
        DatabaseBatchUpdate(updateArray)
          .then((res_DBU) => {
            resolve(res_DBU)
          })
          .catch((rej_DBU) => {
            reject(rej_DBU)
          })
      } else {
        resolve({ success: true })
      }
    } else {
      resolve({ success: true })
    }
  })
}

///////////////////////////////
// Page Specific Permissions
///////////////////////////////

const defaultPagePermissions: TsInterface_UnspecifiedObject = {
  details: 'read',
  team: 'read',
  tasks: 'read',
  sow: 'read',
  design: 'read',
  messages: 'read',
  contact_log: 'read',
  notes: 'read',
  documents: 'read',
  photos: 'read',
  logs: 'read',
}

const pageRolePermissions: TsInterface_UnspecifiedObject = {
  api_user: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
  accounts_payable: {
    details: 'write',
    team: 'write',
    tasks: 'write',
    sow: 'write',
    design: 'write',
    messages: 'write',
    contact_log: 'write',
    notes: 'write',
    documents: 'write',
    photos: 'write',
    logs: 'write',
  },
  admin: {
    details: 'write',
    team: 'write',
    tasks: 'write',
    sow: 'write',
    design: 'write',
    messages: 'write',
    contact_log: 'write',
    notes: 'write',
    documents: 'write',
    photos: 'write',
    logs: 'write',
  },
  construction_lead: {
    details: 'write',
    team: 'write',
    tasks: 'write',
    sow: 'write',
    design: 'write',
    messages: 'write',
    contact_log: 'write',
    notes: 'write',
    documents: 'write',
    photos: 'write',
    logs: 'write',
  },
  css_manager: {
    details: 'write',
    team: 'write',
    tasks: 'write',
    sow: 'write',
    design: 'write',
    messages: 'write',
    contact_log: 'write',
    notes: 'write',
    documents: 'write',
    photos: 'write',
    logs: 'write',
  },
  css_rep: {
    details: 'write',
    team: 'write',
    tasks: 'write',
    sow: 'write',
    design: 'write',
    messages: 'write',
    contact_log: 'write',
    notes: 'write',
    documents: 'write',
    photos: 'write',
    logs: 'write',
  },
  design: {
    details: 'write',
    team: 'write',
    tasks: 'write',
    sow: 'write',
    design: 'write',
    messages: 'write',
    contact_log: 'write',
    notes: 'write',
    documents: 'write',
    photos: 'write',
    logs: 'write',
  },
  electrical_foreman: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
  field_service: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
  general_manager: {
    details: 'write',
    team: 'write',
    tasks: 'write',
    sow: 'write',
    design: 'write',
    messages: 'write',
    contact_log: 'write',
    notes: 'write',
    documents: 'write',
    photos: 'write',
    logs: 'write',
  },
  home_upgrades_electrician: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
  home_upgrades_electrician_trainee: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
  inspection: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
  install_coordinator: {
    details: 'write',
    team: 'write',
    tasks: 'write',
    sow: 'write',
    design: 'write',
    messages: 'write',
    contact_log: 'write',
    notes: 'write',
    documents: 'write',
    photos: 'write',
    logs: 'write',
  },
  install_lead: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
  installer: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
  interconnection: {
    details: 'write',
    team: 'write',
    tasks: 'write',
    sow: 'write',
    design: 'write',
    messages: 'write',
    contact_log: 'write',
    notes: 'write',
    documents: 'write',
    photos: 'write',
    logs: 'write',
  },
  permitting: {
    details: 'write',
    team: 'write',
    tasks: 'write',
    sow: 'write',
    design: 'write',
    messages: 'write',
    contact_log: 'write',
    notes: 'write',
    documents: 'write',
    photos: 'write',
    logs: 'write',
  },
  repair: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
  scheduling: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
  site_auditor: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
  warehouse: {
    details: 'read',
    team: 'read',
    tasks: 'read',
    sow: 'read',
    design: 'read',
    messages: 'read',
    contact_log: 'read',
    notes: 'read',
    documents: 'read',
    photos: 'read',
    logs: 'read',
  },
}

const determinePageSpecificPermissions = (clientUser: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let pageSpecificPermissions: TsInterface_UnspecifiedObject = { ...defaultPagePermissions }
  if (clientUser != null && clientUser.user_role != null) {
    for (let loopPermissionKey in defaultPagePermissions) {
      if (
        pageRolePermissions != null &&
        pageRolePermissions[clientUser.user_role] != null &&
        pageRolePermissions[clientUser.user_role][loopPermissionKey] === 'write'
      ) {
        pageSpecificPermissions[loopPermissionKey] = 'write'
      }
    }
  }
  if (clientUser != null && clientUser.task_roles != null) {
    for (let loopTaskRole in clientUser.task_roles) {
      let loopRoleValue = clientUser.task_roles[loopTaskRole]
      if (loopRoleValue === true) {
        for (let loopPermissionKey in defaultPagePermissions) {
          if (pageRolePermissions != null && pageRolePermissions[loopTaskRole] != null && pageRolePermissions[loopTaskRole][loopPermissionKey] === 'write') {
            pageSpecificPermissions[loopPermissionKey] = 'write'
          }
        }
      }
    }
  }
  return pageSpecificPermissions
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_projectKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeBlueprintTasks, us_setActiveBlueprintTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadedTaskFormPages, us_setLoadedTaskFormPages] = useState<boolean>(false)
  const [us_activeTab, us_setActiveTab] = useState<string>('details')
  const [us_additionalSalesforceData, us_setAdditionalSalesforceData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_additionalWorkTaskType, us_setAdditionalWorkTaskType] = useState<null | string>(null)
  const [us_allTaskFormData, us_setAllTaskFormData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_attemptedAddressGeocode, us_setAttemptedAddressGeocode] = useState<boolean>(false)
  const [us_attemptedTaskCoordinateCopy, us_setAttemptedTaskCoordinateCopy] = useState<boolean>(false)
  const [us_availableImageTags, us_setAvailableImageTags] = useState<string[]>([])
  const [us_chatThreads, us_setChatThreads] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clientUserRoles, us_setClientUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_combinedUserRoles, us_setCombinedUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_documentTableFilter, us_setDocumentTableFilter] = useState<TsType_DocumentTableFilterOptions>('not_archived')
  const [us_documentsViewLevel, us_setDocumentsViewLevel] = useState<string>('root') // root | folder
  const [us_downloadingPhotos, us_setDownloadingPhotos] = useState<boolean>(false)
  const [us_filteredPhotosList, us_setFilteredPhotosList] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_flatImageTagCounts, us_setFlatImageTagCounts] = useState<TsInterface_UnspecifiedObject>({})
  const [us_flatImageUploadData, us_setFlatImageUploadData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_groupedTaskData, us_setGroupedTaskData] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_loadingMessages, us_setLoadingMessages] = useState<boolean>(false)
  const [us_messageLastSent, us_setMessageLastSent] = useState<number>(0)
  const [us_minimizedCustomerDetails, us_setMinimizedCustomerDetails] = useState<boolean>(false)
  const [us_minimizedCustomerHappiness, us_setMinimizedCustomerHappiness] = useState<boolean>(false)
  const [us_minimizedEstimatedTimeline, us_setMinimizedEstimatedTimeline] = useState<boolean>(false)
  const [us_minimizedFeedbackDetails, us_setMinimizedFeedbackDetails] = useState<boolean>(false)
  const [us_minimizedHomeDetails, us_setMinimizedHomeDetails] = useState<boolean>(true)
  const [us_minimizedMostRecentContact, us_setMinimizedMostRecentContact] = useState<boolean>(false)
  const [us_minimizedProgressDetails, us_setMinimizedProgressDetails] = useState<boolean>(false)
  const [us_minimizedProjectFinancialData, us_setMinimizedProjectFinancialData] = useState<boolean>(true)
  const [us_minimizedProjectTimestampsData, us_setMinimizedProjectTimestampsData] = useState<boolean>(true)
  const [us_minimizedReminderDetails, us_setMinimizedReminderDetails] = useState<boolean>(false)
  const [us_minimizedSalesPartnerSpecificData, us_setMinimizedSalesPartnerSpecificData] = useState<boolean>(true)
  const [us_minimizedStickyNote, us_setMinimizedStickyNote] = useState<boolean>(false)
  const [us_minimizedSystemDetails, us_setMinimizedSystemDetails] = useState<boolean>(false)
  const [us_newNoteEditorVisibility, us_setNewNoteEditorVisibility] = useState<boolean>(false)
  const [us_noteReplyRichContentEditorValue, us_setNoteReplyRichContentEditorValue] = useState<string>('')
  const [us_noteRichContentEditorValue, us_setNoteRichContentEditorValue] = useState<string>('')
  const [us_noteSortOrder, us_setNoteSetOrder] = useState<string>('timestamp_last_updated_desc')
  const [us_noteSubjectValue, us_setNoteSubjectValue] = useState<string>('')
  const [us_noteTableFilter, us_setNoteTableFilter] = useState<TsType_NoteTableFilterOptions>('not_deleted')
  const [us_pageSpecificUserRolePermission, us_setPageSpecificUserRolePermission] = useState<TsInterface_UnspecifiedObject>(defaultPagePermissions)
  const [us_photosViewLevel, us_setPhotosViewLevel] = useState<string>('root') // root | task | page | subfolder
  const [us_photosViewType, us_setPhotosViewType] = useState<string>('tag') // folder | tag
  const [us_projectDesignPreferences, us_setProjectDesignPreferences] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectDocumentFolders, us_setProjectDocumentFolders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectFeedback, us_setProjectFeedback] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectImageThumbnails, us_setProjectImageThumbnails] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectNoteThreads, us_setProjectNoteThreads] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectOpenReminders, us_setProjectOpenReminders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectRoleAssignments, us_setProjectRoleAssignments] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectRootPhotosFolders, us_setProjectRootPhotosFolders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTaskWorkflow, us_setProjectTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTaskWorkflowUserRoles, us_setProjectTaskWorkflowUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasksLoaded, us_setProjectTasksLoaded] = useState<boolean>(false)
  const [us_refreshingData, us_setRefreshingData] = useState<boolean>(false)
  const [us_refreshingProgressBar, us_setRefreshingProgressBar] = useState<boolean>(false)
  const [us_reloadTaskWorfklow, us_setReloadTaskWorfklow] = useState<number>(0)
  const [us_repairedTasks, us_setRepairedTasks] = useState<boolean>(false)
  const [us_replyEditorVisibility, us_setReplyEditorVisibility] = useState<boolean>(false)
  const [us_rescursiveTasks, us_setRescursiveTasks] = useState<TsInterface_UnspecifiedObject | null>(null)
  const [us_rootProject, us_setRootProject] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rootProjectLoaded, us_setRootProjectLoaded] = useState<boolean>(false)
  const [us_runningImageAnalysis, us_setRunningImageAnalysis] = useState<boolean>(false)
  const [us_screenSize, us_setScreenSize] = useState<string>('md')
  const [us_selectedAllImageFilterTag, us_setSelectedAllImagesFilterTag] = useState<boolean>(false)
  const [us_selectedDocumentsFolder, us_setSelectedDocumentsFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDocumentsFolderData, us_setSelectedDocumentsFolderData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedImageFilterTags, us_setSelectedImageFilterTags] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedMessageThread, us_setSelectedMessageThread] = useState<TsInterface_ChatThread>(defaultEmptyMessageThread)
  const [us_selectedMessageThreadKey, us_setSelectedMessageThreadKey] = useState<null | string>(null)
  const [us_selectedMessages, us_setSelectedMessages] = useState<TsInterface_ChatMessages>({})
  const [us_selectedNoteThreadKey, us_setSelectedNoteThreadKey] = useState<null | string>(null)
  const [us_selectedPhotosTaskFolder, us_setSelectedPhotosTaskFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolderData, us_setSelectedPhotosTaskFolderData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolderForm, us_setSelectedPhotosTaskFolderForm] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskPageFolder, us_setSelectedPhotosTaskPageFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskPageSubfolder, us_setSelectedPhotosTaskPageSubfolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedThreadNotes, us_setSelectedThreadNotes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskPrerequisitesValidityCheck, us_setTaskPrerequisitesValidityCheck] = useState<TsInterface_UnspecifiedObject>({})
  const [us_taskTableFilter, us_setTaskTableFilter] = useState<TsType_TaskTableFilterOptions>('not_deleted')
  const [us_taskTableView, us_setTaskTableView] = useState<string>('tasks') // "tasks", "groups"
  const [us_taskKeysWithImages, us_setTaskKeysWithImages] = useState<TsInterface_UnspecifiedObject>({})
  const [us_tempTaskFormOptions, us_setTempTaskFormOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_tempTaskOwnerRolesOptions, us_setTempTaskOwnerRolesOptions] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_usersWithDirectOrScheduledTasksRoles, us_setUsersWithDirectOrScheduledTasksRoles] = useState<TsInterface_UnspecifiedObject>({})
  const ul_queryParams = new URLSearchParams(useLocation().search)
  const umq_isExtraSmallScreen = useMediaQuery('(max-width: 400px)')
  const umq_isLargeScreen = useMediaQuery('(min-width: 961px) and (max-width: 1280px)')
  const umq_isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)')
  const umq_isSmallScreen = useMediaQuery('(min-width: 401px) and (max-width: 600px)')
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_ConfirmDialogDisplay } = useContext(Context_UserInterface_ConfirmDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (umq_isExtraSmallScreen === true) {
      us_setScreenSize('xs')
    } else if (umq_isSmallScreen === true) {
      us_setScreenSize('sm')
    } else if (umq_isMediumScreen === true) {
      us_setScreenSize('md')
    } else if (umq_isLargeScreen === true) {
      us_setScreenSize('lg')
    } else {
      us_setScreenSize('xl')
    }
    ur_forceRerender()
    return () => {}
  }, [umq_isExtraSmallScreen, umq_isSmallScreen, umq_isMediumScreen, umq_isLargeScreen, ur_forceRerender])

  // useEffect(() => {
  // 	// Browser Tab Name
  // 	if( us_rootProject != null && us_rootProject.id_number != null ){
  // 		document.title = us_rootProject.id_number;
  // 	}
  // }, [ us_rootProject ])

  useEffect(() => {
    if (ul_queryParams != null && ul_queryParams.get('tab') != null) {
      us_setActiveTab(ul_queryParams.get('tab') as string)
    }
  }, [])

  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      us_setPageSpecificUserRolePermission(determinePageSpecificPermissions(uc_RootData_ClientUser))
    }
  }, [uc_RootData_ClientUser])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setClientUserRoles(returnClientUserRoles(res_GCK.clientKey))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRootProject(newData)
      us_setRootProjectLoaded(true)
      // TODO - TEMP
      if (newData != null && newData['task_completion_stats'] != null && newData['task_completion_stats']['unknown'] != null) {
        getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
          cloudFunctionManageRequest('manageTasks', {
            function: 'refreshProjectTaskProgressBar',
            client_key: res_GCK.clientKey,
            project_key: pr_projectKey,
          })
          ur_forceRerender()
        })
      }
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_Project_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setActiveBlueprintTasks(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTaskBlueprints_Query(res_GCK.clientKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectRoleAssignments({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectDesignPreferences(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProjectDesignPreferences_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectRoleAssignments({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectRoleAssignments(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_ProjectRoleAssignments_Document(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectRoleAssignments({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectTasks(newData)
        us_setProjectTasksLoaded(true)
        if (us_repairedTasks === false) {
          getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey).then((res_GCK) => {
            checkAndRepairTaskStatuses(res_GCK.clientKey, newData)
          })
          us_setRepairedTasks(true)
        }
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_AllProjectTasks_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectTasks({})
      us_setProjectTasksLoaded(true)
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_repairedTasks])

  useEffect(() => {
    // TEMP - Forms - only load if tasks can be created
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_TaskFormsActive_Query(res_GCK.clientKey, 'name', false, null, {}))
          .then((res_DGC) => {
            let taskFormOptions: TsInterface_UnspecifiedObject[] = []
            for (let loopFormKey in res_DGC.data) {
              let loopForm = res_DGC.data[loopFormKey]
              taskFormOptions.push({ key: loopForm.key, value: loopForm.name })
            }
            us_setTempTaskFormOptions(taskFormOptions)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    // TEMP - Forms - only load if tasks can be created
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_ProjectAdditionalData_Document(res_GCK.clientKey, pr_projectKey))
          .then((res_DGD) => {
            us_setAdditionalSalesforceData(res_DGD.data)
          })
          .catch((rej_DGD) => {
            console.error(rej_DGD)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, pr_projectKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let clientUserRoles = returnClientUserRoles(res_GCK.clientKey)
        us_setTempTaskOwnerRolesOptions(objectToArray(clientUserRoles))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_clientUserRoles])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setCombinedUserRoles(returnCombinedTaskRoles(res_GCK.clientKey))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let filteredImages: TsInterface_UnspecifiedObject[] = []
    for (let loopImageIndex in us_flatImageUploadData) {
      let loopImage = us_flatImageUploadData[loopImageIndex]
      for (let loopThumbnailKey in us_projectImageThumbnails) {
        let loopThumbnail = us_projectImageThumbnails[loopThumbnailKey]
        let loopImageName = loopImage.name
        let loopThumbnailName = loopThumbnail.name
        let fileNameWithoutExtension = loopImageName.split('.').slice(0, -1).join('.')
        if (loopThumbnailName.startsWith(fileNameWithoutExtension)) {
          loopImage.thumbnail_url = loopThumbnail.thumbnail_url
        }
      }
      if (us_selectedAllImageFilterTag === true) {
        filteredImages.push(loopImage)
      } else {
        let includePhotoInDisplay = true
        if (objectToArray(us_selectedImageFilterTags).length === 0) {
          includePhotoInDisplay = false
        }
        for (let loopTagKey in us_selectedImageFilterTags) {
          if (us_selectedImageFilterTags[loopTagKey] === true) {
            if (loopImage != null && loopImage.tags != null && loopImage.tags.indexOf(loopTagKey) === -1) {
              includePhotoInDisplay = false
            }
          }
        }
        if (includePhotoInDisplay === true) {
          filteredImages.push(loopImage)
        }
      }
    }
    us_setFilteredPhotosList(filteredImages)
    return () => {}
  }, [us_setFilteredPhotosList, us_flatImageUploadData, us_selectedAllImageFilterTag, us_selectedImageFilterTags, us_projectImageThumbnails])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setProjectDocumentFolders(returnProjectDocumentFolders(res_GCK.clientKey))
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSelectedDocumentsFolderData(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null && us_selectedDocumentsFolder != null && us_selectedDocumentsFolder.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_Project_Documents_Collection(res_GCK.clientKey, pr_projectKey, us_selectedDocumentsFolder.key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedDocumentsFolderData({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedDocumentsFolder, pr_projectKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSelectedPhotosTaskFolderData(newData)
      }
      ur_forceRerender()
    }
    if (us_selectedPhotosTaskFolder != null && us_selectedPhotosTaskFolder.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_ProjectTaskFormData_Document(res_GCK.clientKey, pr_projectKey, us_selectedPhotosTaskFolder.key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedPhotosTaskFolderData({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedPhotosTaskFolder, pr_projectKey, ur_forceRerender])

  useEffect(() => {
    if (
      us_selectedPhotosTaskFolder != null &&
      us_selectedPhotosTaskFolder.key != null &&
      us_projectTasks != null &&
      us_projectTasks[us_selectedPhotosTaskFolder.key] != null &&
      us_projectTasks[us_selectedPhotosTaskFolder.key]['associated_task_form_key'] != null &&
      us_loadedTaskFormPages === false
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(
            DatabaseRef_TaskFormProdPages_Document(res_GCK.clientKey, us_projectTasks[us_selectedPhotosTaskFolder.key]['associated_task_form_key']),
          )
            .then((res_DGD) => {
              us_setSelectedPhotosTaskFolderForm(res_DGD.data)
              us_setLoadedTaskFormPages(true)
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedPhotosTaskFolderForm({})
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedPhotosTaskFolder, pr_projectKey, ur_forceRerender, us_projectTasks, us_loadedTaskFormPages])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        // TODO - merge with
        let combinedTags = objectToArray({ ...returnImageTags(res_GCK.clientKey) })
        for (let loopTaskKey in us_taskKeysWithImages) {
          if (us_projectTasks != null && us_projectTasks[loopTaskKey] != null && us_projectTasks[loopTaskKey]['name'] != null) {
            combinedTags.push(taskTagPrefix + us_projectTasks[loopTaskKey]['name'])
          }
        }
        us_setAvailableImageTags(combinedTags)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_taskKeysWithImages, us_projectTasks])

  useEffect(() => {
    if (us_activeTab === 'photos' && pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          StorageListFiles(StorageRef_ProjectImageThumbnailsFolder(res_GCK.clientKey, pr_projectKey))
            .then((res_SLF) => {
              let thumbnailFiles: TsInterface_UnspecifiedObject = {}
              let promiseArray: TsType_UnknownPromise[] = []
              if (res_SLF != null && res_SLF.data != null && res_SLF.data.files != null) {
                for (let loopFileKey in res_SLF.data.files) {
                  thumbnailFiles[loopFileKey] = res_SLF.data.files[loopFileKey]
                  promiseArray.push(
                    StorageGetDownloadUrl(StorageRef_ProjectImageThumbnailFile(res_GCK.clientKey, pr_projectKey, loopFileKey))
                      .then((res_SGDU) => {
                        thumbnailFiles[loopFileKey]['thumbnail_url'] = getProp(res_SGDU, 'url', null)
                      })
                      .catch((rej_SGDU) => {
                        console.error(rej_SGDU)
                      }),
                  )
                }
              }
              Promise.all(promiseArray).finally(() => {
                us_setProjectImageThumbnails(thumbnailFiles)
              })
            })
            .catch((rej_SLF) => {
              console.error(rej_SLF)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_activeTab])

  useEffect(() => {
    let tagCounts: TsInterface_UnspecifiedObject = { all_photos: 0 }
    let calculatedProjectRootPhotosFolders: TsInterface_UnspecifiedObject = {}
    let tasksWithImages: TsInterface_UnspecifiedObject = {}
    if (us_allTaskFormData != null) {
      let taskFormImages: TsInterface_UnspecifiedObject[] = []
      for (let loopTaskKey in us_allTaskFormData) {
        let loopTaskData = us_allTaskFormData[loopTaskKey]
        for (let loopPageKey in loopTaskData) {
          let loopPageData = loopTaskData[loopPageKey]
          if (loopPageData != null && loopPageData['folders'] != null) {
            for (let loopFolderKey in loopPageData['folders']) {
              let loopFolder = loopPageData['folders'][loopFolderKey]
              for (let loopFileUploadIndex in loopFolder) {
                let loopFileUpload = loopFolder[loopFileUploadIndex]
                if (loopFileUpload != null && loopFileUpload['upload_type'] === 'image') {
                  tasksWithImages[loopTaskKey] = true
                  if (calculatedProjectRootPhotosFolders[loopTaskKey] == null) {
                    calculatedProjectRootPhotosFolders[loopTaskKey] = {
                      key: loopTaskKey,
                      path: null,
                    }
                  }
                  loopFileUpload['TEMP_associated_task_key'] = loopTaskKey
                  loopFileUpload['TEMP_associated_page_key'] = loopPageKey
                  loopFileUpload['TEMP_associated_folder_key'] = loopFolderKey
                  loopFileUpload['TEMP_subfolder_name'] = loopFolder.name
                  loopFileUpload['TEMP_upload_index'] = loopFileUploadIndex
                  taskFormImages.push(loopFileUpload)
                  tagCounts['all_photos']++
                  if (loopFileUpload != null && loopFileUpload['tags'] == null) {
                    loopFileUpload['tags'] = []
                  }
                  if (loopFileUpload != null && loopFileUpload['tags'] != null) {
                    if (
                      loopTaskKey != null &&
                      us_projectTasks != null &&
                      us_projectTasks[loopTaskKey] != null &&
                      us_projectTasks[loopTaskKey]['name'] != null &&
                      loopFileUpload['tags'].indexOf(taskTagPrefix + us_projectTasks[loopTaskKey]['name']) === -1
                    ) {
                      loopFileUpload['tags'].push(taskTagPrefix + us_projectTasks[loopTaskKey]['name'])
                    }
                    let includePhotoInCounts = true
                    for (let loopTagKey in us_selectedImageFilterTags) {
                      if (us_selectedImageFilterTags[loopTagKey] === true) {
                        if (loopFileUpload != null && loopFileUpload.tags != null && loopFileUpload.tags.indexOf(loopTagKey) === -1) {
                          includePhotoInCounts = false
                        }
                      }
                    }
                    if (includePhotoInCounts === true) {
                      for (let loopTagIndex in loopFileUpload['tags']) {
                        let loopTag = loopFileUpload['tags'][loopTagIndex]
                        if (tagCounts[loopTag] == null) {
                          tagCounts[loopTag] = 0
                        }
                        tagCounts[loopTag]++
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      us_setTaskKeysWithImages(tasksWithImages)
      us_setFlatImageUploadData(taskFormImages)
      us_setFlatImageTagCounts(tagCounts)
      us_setProjectRootPhotosFolders(calculatedProjectRootPhotosFolders)
    }
    ur_forceRerender()
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_allTaskFormData, ur_forceRerender, us_selectedImageFilterTags, us_projectTasks])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllTaskFormData(newData)
      ur_forceRerender()
    }
    if (us_activeTab === 'photos') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectTaskFormData_Collection(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setFlatImageUploadData([])
      us_setFlatImageTagCounts({})
      us_setAllTaskFormData({})
      us_setProjectRootPhotosFolders({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, ur_forceRerender, us_activeTab])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(res_GCK.clientKey, pr_projectKey))
          .then((res_DGD) => {
            us_setProjectTaskWorkflow(res_DGD.data)
            let taskWorkflowUserRolesList: TsInterface_UnspecifiedObject = {}
            let taskWorkflowUserRolesWithDirectOrScheduledTasksList: TsInterface_UnspecifiedObject = {}
            if (res_DGD.data != null && res_DGD.data['tasks'] != null) {
              for (let loopTaskKey in res_DGD.data['tasks']) {
                let loopTask = res_DGD.data['tasks'][loopTaskKey]
                if (us_reloadTaskWorfklow >= 0) {
                  // Nothing - just used for reloads
                }
                if (loopTask != null && loopTask['associated_owner_type'] != null) {
                  taskWorkflowUserRolesList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  if (loopTask['task_completion_type'] === 'direct' || loopTask['task_completion_type'] === 'dispatcher') {
                    taskWorkflowUserRolesWithDirectOrScheduledTasksList[loopTask['associated_owner_type']] = loopTask['associated_owner_type']
                  }
                }
              }
            }
            us_setProjectTaskWorkflowUserRoles(taskWorkflowUserRolesList)
            us_setUsersWithDirectOrScheduledTasksRoles(taskWorkflowUserRolesWithDirectOrScheduledTasksList)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, pr_projectKey, uc_setRootData_ClientKey, us_reloadTaskWorfklow])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setProjectNoteThreads(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectNoteThreads_Collection(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectNoteThreads({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSelectedThreadNotes(newData)
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null && us_selectedNoteThreadKey != null && us_selectedNoteThreadKey !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_ProjectNoteThreadNotes_Collection(res_GCK.clientKey, pr_projectKey, us_selectedNoteThreadKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedThreadNotes({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_selectedNoteThreadKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let newMessageObject: TsInterface_UnspecifiedObject = {}
      for (let messageKey in newData) {
        let loopMessage = newData[messageKey]
        newMessageObject[messageKey] = loopMessage
      }
      us_setSelectedMessages(newMessageObject)
      us_setLoadingMessages(false)
      ur_forceRerender()
      scrollToBottomOfChat()
    }
    if (us_selectedMessageThread != null && us_selectedMessageThread.key != null && us_selectedMessageThread.key !== '') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          if (uc_RootData_ClientKey != null) {
            unsubscribeLiveData = DatabaseGetLiveCollection(
              DatabaseRef_MessageThreadMessages_CollectionOrdered_Query(uc_RootData_ClientKey, us_selectedMessageThread.key, 100, null),
              updateLiveData,
            )
          }
        })
        .catch((rej_GCK) => {
          // Should always be set, temp solution used to get messages to rerender on message send
          if (us_messageLastSent != null) {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          }
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [
    ur_forceRerender,
    uc_RootData_ClientKey,
    us_selectedMessageThread,
    uc_setUserInterface_ErrorDialogDisplay,
    uc_setRootData_ClientKey,
    us_messageLastSent,
    us_selectedMessageThreadKey,
  ])

  // TODO - TEMP? (Maybe should be in project creation)
  useEffect(() => {
    if (
      us_rootProject != null &&
      (us_rootProject.additional_project_data == null || us_rootProject.additional_project_data.messages_threads_instantiated !== true)
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          generateDefaultMessageThreadsInDatabase(
            res_GCK.clientKey,
            pr_projectKey,
            generateStandardProjectMessages(
              us_setChatThreads,
              {},
              pr_projectKey,
              us_selectedMessageThreadKey,
              us_setSelectedMessageThread,
              'set_threads_to_state',
            ),
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, us_selectedMessageThreadKey, us_rootProject])

  useEffect(() => {
    // TODO - This listener is linked to one in app.js so it renders two changes one without timestamps so whichever
    // project has the queried unread message gets a weird flash as the unread message goes to the top
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        // setDatabaseChatThreads( newData )
        generateStandardProjectMessages(
          us_setChatThreads,
          newData,
          pr_projectKey,
          us_selectedMessageThreadKey,
          us_setSelectedMessageThread,
          'set_threads_to_state',
        )
      }
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectMessageThreadsCollection_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      generateStandardProjectMessages(us_setChatThreads, {}, pr_projectKey, us_selectedMessageThreadKey, us_setSelectedMessageThread, 'set_threads_to_state')
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_selectedMessageThreadKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectFeedback(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectFeedback_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectFeedback({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_selectedMessageThreadKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setProjectOpenReminders(newData)
      ur_forceRerender()
    }
    if (pr_projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectReminders_Query(res_GCK.clientKey, pr_projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setProjectOpenReminders({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, pr_projectKey, us_selectedMessageThreadKey])

  useEffect(() => {
    // Generate List of Tasks with bad prerequisite data structure
    if (us_projectTasks != null && objectToArray(us_projectTasks).length > 0) {
      let tasksThatReferenceThemselves = findRecursiveTasks(returnTaskPrerequisiteAnalysisObject(us_projectTasks))
      us_setRescursiveTasks(tasksThatReferenceThemselves)
      let invertedBooleanObject: TsInterface_UnspecifiedObject = {}
      for (let loopKey in tasksThatReferenceThemselves) {
        invertedBooleanObject[loopKey] = !tasksThatReferenceThemselves[loopKey]
      }
      us_setTaskPrerequisitesValidityCheck(invertedBooleanObject)
    }
  }, [us_projectTasks])

  useEffect(() => {
    // Generate List of Tasks with bad prerequisite data structure
    if (pr_projectKey != null && us_rootProject != null && us_rootProject['key'] != null && us_attemptedAddressGeocode === false) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          potentiallyGeocodeAddressForProject(res_GCK.clientKey, pr_projectKey, us_rootProject).finally(() => {
            us_setAttemptedAddressGeocode(true)
          })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, us_rootProject, us_attemptedAddressGeocode])

  useEffect(() => {
    // Generate List of Tasks with bad prerequisite data structure
    if (
      pr_projectKey != null &&
      us_rootProject != null &&
      us_rootProject['key'] != null &&
      us_attemptedTaskCoordinateCopy === false &&
      us_projectTasks != null &&
      objectToArray(us_projectTasks).length > 0
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          potentiallyCopyCoordinatesToTasks(res_GCK.clientKey, pr_projectKey, us_rootProject, us_projectTasks).finally(() => {
            us_setAttemptedTaskCoordinateCopy(true)
          })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_projectKey, us_rootProject, us_attemptedTaskCoordinateCopy, us_projectTasks])

  useEffect(() => {
    if (
      us_projectTaskWorkflow != null &&
      us_projectTaskWorkflow.task_groups != null &&
      us_projectTaskWorkflow.task_groups.length > 0 &&
      us_projectTasks != null &&
      objectToArray(us_projectTasks).length > 0
    ) {
      us_setGroupedTaskData(returnGroupedTasksData(us_projectTaskWorkflow.task_groups, us_projectTasks))
    }
  }, [us_projectTaskWorkflow, us_projectTasks])

  // Other Variables
  let projectUsersFlatObject: TsInterface_UnspecifiedObject = {}

  // Functions
  const generateUserRolesFlatObject = (): void => {
    if (us_rootProject != null) {
      if (us_rootProject['associated_customer_key'] != null && us_rootProject['associated_customer_name'] != null) {
        projectUsersFlatObject[us_rootProject[us_rootProject['associated_customer_key']]] = us_rootProject['associated_customer_name']
      }
      if (us_rootProject['associated_sales_rep_key'] != null && us_rootProject['associated_sales_rep_name'] != null) {
        projectUsersFlatObject[us_rootProject[us_rootProject['associated_sales_rep_key']]] = us_rootProject['associated_sales_rep_name']
      }
      if (us_rootProject['associated_css_rep_key'] != null && us_rootProject['associated_css_rep_name'] != null) {
        projectUsersFlatObject[us_rootProject[us_rootProject['associated_css_rep_key']]] = us_rootProject['associated_css_rep_name']
      }
      for (let loopRoleKey in us_projectTaskWorkflowUserRoles) {
        if (us_rootProject['associated_' + loopRoleKey + '_key'] != null && us_rootProject['associated_' + loopRoleKey + '_name'] != null) {
          projectUsersFlatObject[us_rootProject['associated_' + loopRoleKey + '_key']] = us_rootProject['associated_' + loopRoleKey + '_name']
        }
      }
    }
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeader = <></>
    let jobCodeJSX = <></>
    if (us_rootProject != null && us_rootProject.id_number != null) {
      jobCodeJSX = <>{us_rootProject.id_number}</>
    } else {
      jobCodeJSX = <>{s_NEW_PROJECT}</>
    }
    if (us_rootProject != null && us_rootProject.associated_customer_name != null) {
      pageHeader = (
        <>
          {jobCodeJSX}: {us_rootProject.associated_customer_name}
        </>
      )
    } else {
      pageHeader = (
        <>
          {jobCodeJSX}: {s_CUSTOMER_DETAILS}
        </>
      )
    }
    return pageHeader
  }

  generateUserRolesFlatObject()

  // JSX Generation

  // Tab - Design Preferences
  const rJSX_DesignPreferencesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabJSX = (
        <Box>
          {returnProjectDesignPreferences(
            'project',
            uc_RootData_ClientKey,
            pr_projectKey,
            us_projectDesignPreferences,
            uc_setUserInterface_FormDialogDisplay,
            uc_RootData_ClientKey,
            uc_setRootData_ClientKey,
          )}
        </Box>
      )
    }
    return tabJSX
  }

  // ALL - Alert
  const rJSX_AlertBanner = (): JSX.Element => {
    let missingAssignedRoles = false
    for (let loopRoleKey in us_usersWithDirectOrScheduledTasksRoles) {
      if (us_rootProject != null && us_rootProject['associated_' + loopRoleKey + '_key'] == null) {
        missingAssignedRoles = true
      }
    }
    let bannerJSX = <></>
    if (us_rootProjectLoaded === true && us_rootProject != null && us_rootProject['id_number'] == null) {
      bannerJSX = (
        <Box
          sx={{ background: themeVariables.error_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {s_NO_PROJECT_ID_GENERATED_YET}
            </Typography>
            {rJSX_SelectProjectCodeButton(
              uc_RootData_ClientKey,
              pr_projectKey,
              us_rootProject,
              uc_setRootData_ClientKey,
              uc_setUserInterface_ErrorDialogDisplay,
              uc_setUserInterface_PromptDialogDisplay,
            )}
          </Stack>
        </Box>
      )
    } else if (us_rootProjectLoaded === true && us_rootProject != null && us_rootProject['associated_task_workflow_key'] == null) {
      bannerJSX = (
        <Box
          sx={{ background: themeVariables.error_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {s_NO_TASK_WOKFLOW_SELECTED_FOR_PROJECT}
            </Typography>
            {rJSX_SelectTaskWorkflowButton(
              uc_RootData_ClientKey,
              uc_RootData_ClientUser,
              pr_projectKey,
              us_setReloadTaskWorfklow,
              uc_setRootData_ClientKey,
              uc_setUserInterface_ErrorDialogDisplay,
              uc_setUserInterface_FormDialogDisplay,
            )}
          </Stack>
        </Box>
      )
    } else if (us_rootProject['status'] === 'on_hold') {
      bannerJSX = (
        <Box
          sx={{ background: themeVariables.secondary_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="circle-pause"
                className="tw-mr-2"
              />
              {s_PROJECT_ON_HOLD}
            </Typography>
          </Stack>
        </Box>
      )
    } else if (us_rootProject['status'] === 'cancelled') {
      bannerJSX = (
        <Box
          sx={{ background: themeVariables.error_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="skull"
                className="tw-mr-2"
              />
              {s_PROJECT_CANCELLED}
            </Typography>
          </Stack>
        </Box>
      )
    } else if (us_rootProject['status'] === 'completed') {
      bannerJSX = (
        <Box
          sx={{ background: themeVariables.info_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="badge-check"
                className="tw-mr-2"
              />
              {s_PROJECT_COMPLETE}
            </Typography>
          </Stack>
        </Box>
      )
    } else if (missingAssignedRoles === true) {
      bannerJSX = (
        <Box
          sx={{ background: themeVariables.warning_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {s_MISSING_TEAM_MEMBER_ASSIGNMENTS}
            </Typography>
          </Stack>
        </Box>
      )
    } else if (us_rootProject == null || us_rootProject.current_active_tasks == null || objectToArray(us_rootProject.current_active_tasks).length === 0) {
      bannerJSX = (
        <Box
          sx={{ background: themeVariables.error_main }}
          className="tw-p-2 tw-mb-2 tw-rounded-lg"
        >
          <Stack
            direction="row"
            className="tw-justify-between"
          >
            <Typography
              variant="body1"
              className="tw-inline-block tw-mr-2 tw-mt-0.5"
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              {s_NO_ACTIVE_TASKS}. {s_CHECK_TASK_PAGE_TO_SEE_WHY_THIS_PROJECT_IS_STALLED}
            </Typography>
          </Stack>
        </Box>
      )
    }
    return bannerJSX
  }

  const rJSX_TeamTabHeader = (): JSX.Element => {
    let headerJSX = <>{s_TEAM}</>
    let missingAssignedRoles = false
    for (let loopRoleKey in us_usersWithDirectOrScheduledTasksRoles) {
      if (us_rootProject != null && us_rootProject['associated_' + loopRoleKey + '_key'] == null) {
        missingAssignedRoles = true
      }
    }
    if (missingAssignedRoles === true) {
      headerJSX = (
        <>
          <Badge
            badgeContent={<Icon icon="triangle-exclamation" />}
            color="error"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <>{s_TEAM}</>
          </Badge>
        </>
      )
    }
    return headerJSX
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    let detailsTab = <></>
    if (us_rootProject != null && us_rootProject.key != null) {
      if (us_rootProject['associated_sales_partner_key'] === 'sunrun') {
        detailsTab = (
          <Box className="tw-m-auto">
            {rJSX_AlertBanner()}
            <Box className="tw-m-auto">
              {rJSX_SunrunProjectDetailsTab(
                getProp(us_pageSpecificUserRolePermission, 'details', 'read'),
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      } else if (us_rootProject['associated_sales_partner_key'] === 'tesla') {
        detailsTab = (
          <Box className="tw-m-auto">
            {rJSX_AlertBanner()}
            <Box className="tw-m-auto">
              {rJSX_TeslaProjectDetailsTab(
                getProp(us_pageSpecificUserRolePermission, 'details', 'read'),
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      } else if (us_rootProject['associated_sales_partner_key'] === 'etw_energy') {
        detailsTab = (
          <Box className="tw-m-auto">
            {rJSX_AlertBanner()}
            <Box className="tw-m-auto">
              {rJSX_ETWEnergyProjectDetailsTab(
                getProp(us_pageSpecificUserRolePermission, 'details', 'read'),
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      } else {
        detailsTab = (
          <Box className="tw-m-auto">
            {rJSX_AlertBanner()}
            <Box className="tw-m-auto">
              {rJSX_ETWEnergyProjectDetailsTab(
                getProp(us_pageSpecificUserRolePermission, 'details', 'read'),
                uc_RootData_ClientKey,
                uc_RootData_GlobalUser,
                us_additionalSalesforceData,
                ur_forceRerender,
                us_minimizedCustomerDetails,
                us_minimizedCustomerHappiness,
                us_minimizedEstimatedTimeline,
                us_minimizedFeedbackDetails,
                us_minimizedHomeDetails,
                us_minimizedMostRecentContact,
                us_minimizedProgressDetails,
                us_minimizedReminderDetails,
                us_minimizedProjectFinancialData,
                us_minimizedSalesPartnerSpecificData,
                us_minimizedProjectTimestampsData,
                us_minimizedStickyNote,
                us_minimizedSystemDetails,
                us_projectFeedback,
                pr_projectKey,
                us_projectOpenReminders,
                projectUsersFlatObject,
                us_refreshingData,
                us_refreshingProgressBar,
                us_rootProject,
                us_rootProjectLoaded,
                us_setMinimizedCustomerDetails,
                us_setMinimizedCustomerHappiness,
                us_setMinimizedEstimatedTimeline,
                us_setMinimizedFeedbackDetails,
                us_setMinimizedHomeDetails,
                us_setMinimizedMostRecentContact,
                us_setMinimizedProgressDetails,
                us_setMinimizedReminderDetails,
                us_setMinimizedProjectFinancialData,
                us_setMinimizedSalesPartnerSpecificData,
                us_setMinimizedProjectTimestampsData,
                us_setMinimizedStickyNote,
                us_setMinimizedSystemDetails,
                us_setRefreshingData,
                us_setRefreshingProgressBar,
                uc_setRootData_ClientKey,
                uc_setUserInterface_ConfirmDialogDisplay,
                uc_setUserInterface_CustomDialogDisplay,
                uc_setUserInterface_ErrorDialogDisplay,
                uc_setUserInterface_FormDialogDisplay,
              )}
            </Box>
          </Box>
        )
      }
    }
    return detailsTab
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box>
              <Button
                color="inherit"
                variant="outlined"
                startIcon={<Icon icon="chevron-left"></Icon>}
                onClick={(event) => {
                  onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectsListPage.url())
                }}
              >
                {s_BACK_TO_ALL_ACTIVE_PROJECTS}
              </Button>
            </Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'details',
                  tabHeader: s_DETAILS,
                  tabOnChange: () => {
                    us_setActiveTab('details')
                  },
                  tabContent: rJSX_DetailsTab(),
                },
                {
                  tabUrlKey: 'team',
                  tabHeader: rJSX_TeamTabHeader(),
                  tabOnChange: () => {
                    us_setActiveTab('team')
                  },
                  tabContent: (
                    <Box className="tw-m-auto">
                      {rJSX_AlertBanner()}
                      <Box className="tw-m-auto">
                        {rJSX_TeamTab(
                          getProp(us_pageSpecificUserRolePermission, 'team', 'read'),
                          uc_RootData_ClientKey,
                          uc_RootData_ClientUser,
                          us_clientUserRoles,
                          us_combinedUserRoles,
                          pr_projectKey,
                          us_projectRoleAssignments,
                          us_projectTaskWorkflowUserRoles,
                          us_projectTasks,
                          us_rootProject,
                          us_rootProjectLoaded,
                          un_routerNavigation,
                          us_setReloadTaskWorfklow,
                          uc_setRootData_ClientKey,
                          uc_setUserInterface_CustomDialogDisplay,
                          uc_setUserInterface_ErrorDialogDisplay,
                          uc_setUserInterface_FormDialogDisplay,
                          uc_setUserInterface_PromptDialogDisplay,
                        )}
                      </Box>
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'tasks',
                  tabHeader: rJSX_TaskTabHeader(us_rootProject),
                  tabOnChange: () => {
                    us_setActiveTab('tasks')
                  },
                  tabContent: (
                    <Box className="tw-m-auto">
                      {rJSX_AlertBanner()}
                      <Box className="tw-m-auto">
                        {rJSX_TasksTab(
                          'full',
                          getProp(us_pageSpecificUserRolePermission, 'tasks', 'read'),
                          uc_RootData_ClientKey,
                          uc_RootData_GlobalUser,
                          us_combinedUserRoles,
                          pr_projectKey,
                          us_projectTaskWorkflow,
                          us_projectTasks,
                          us_projectTasksLoaded,
                          us_rescursiveTasks,
                          us_rootProject,
                          us_rootProjectLoaded,
                          us_setTaskTableFilter,
                          us_taskTableView,
                          us_setTaskTableView,
                          us_groupedTaskData,
                          uc_setUserInterface_FormDialogDisplay,
                          us_taskPrerequisitesValidityCheck,
                          us_taskTableFilter,
                          us_tempTaskFormOptions,
                          us_tempTaskOwnerRolesOptions,
                        )}
                      </Box>
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'sow',
                  tabHeader: s_ADDITIONAL_WORK,
                  tabOnChange: () => {
                    us_setActiveTab('sow')
                  },
                  tabContent: (
                    <Box className="tw-m-auto">
                      {rJSX_AlertBanner()}
                      <Box className="tw-m-auto">
                        {rJSX_AdditionalWorkTab(
                          getProp(us_pageSpecificUserRolePermission, 'sow', 'read'),
                          pr_projectKey,
                          us_projectTaskWorkflow,
                          us_projectTasks,
                          uc_setUserInterface_FormDialogDisplay,
                          us_tempTaskFormOptions,
                          us_tempTaskOwnerRolesOptions,
                          us_activeBlueprintTasks,
                          us_additionalWorkTaskType,
                          us_setAdditionalWorkTaskType,
                        )}
                      </Box>
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'design_preferences',
                  tabHeader: s_DESIGN,
                  tabOnChange: () => {
                    us_setActiveTab('design_preferences')
                  },
                  tabContent: (
                    <Box className="tw-m-auto">
                      {rJSX_AlertBanner()}
                      <Box className="tw-m-auto">{rJSX_DesignPreferencesTab()}</Box>
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'messages',
                  tabHeader: rJSX_ChatTabHeader(uc_RootData_ClientUser, us_chatThreads),
                  tabOnChange: () => {
                    us_setActiveTab('messages')
                  },
                  tabContent: (
                    <Box className="tw-m-auto">
                      {rJSX_AlertBanner()}
                      <Box className="tw-m-auto">
                        {rJSX_MessagesTab(
                          getProp(us_pageSpecificUserRolePermission, 'messages', 'read'),
                          uc_RootData_ClientKey,
                          uc_RootData_ClientUser,
                          uc_RootData_GlobalUser,
                          us_chatThreads,
                          us_loadingMessages,
                          us_selectedMessageThread,
                          us_selectedMessageThreadKey,
                          us_selectedMessages,
                          us_setLoadingMessages,
                          us_setMessageLastSent,
                          uc_setRootData_ClientKey,
                          us_setSelectedMessageThread,
                          us_setSelectedMessageThreadKey,
                          us_setSelectedMessages,
                          uc_setUserInterface_ConfirmDialogDisplay,
                          uc_setUserInterface_ErrorDialogDisplay,
                        )}
                      </Box>
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'contact_log',
                  tabHeader: rJSX_ContactLogsTabHeader(us_rootProject),
                  tabOnChange: () => {
                    us_setActiveTab('contact_log')
                  },
                  tabContent: (
                    <Box className="tw-m-auto">
                      {rJSX_AlertBanner()}
                      <Box className="tw-m-auto">
                        {rJSX_ContactLogTab(
                          getProp(us_pageSpecificUserRolePermission, 'contact_log', 'read'),
                          uc_RootData_ClientKey,
                          uc_RootData_ClientUser,
                          pr_projectKey,
                          us_rootProject,
                          uc_setRootData_ClientKey,
                          uc_setUserInterface_ErrorDialogDisplay,
                          uc_setUserInterface_FormDialogDisplay,
                        )}
                      </Box>
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'notes',
                  tabHeader: s_NOTES,
                  tabOnChange: () => {
                    us_setActiveTab('notes')
                  },
                  tabContent: (
                    <Box className="tw-m-auto">
                      {rJSX_AlertBanner()}
                      <Box className="tw-m-auto">
                        {rJSX_NotesTab(
                          getProp(us_pageSpecificUserRolePermission, 'notes', 'read'),
                          uc_RootData_ClientKey,
                          uc_RootData_ClientUser,
                          us_newNoteEditorVisibility,
                          us_noteReplyRichContentEditorValue,
                          us_noteRichContentEditorValue,
                          us_noteSortOrder,
                          us_noteSubjectValue,
                          us_noteTableFilter,
                          pr_projectKey,
                          us_projectNoteThreads,
                          us_replyEditorVisibility,
                          us_selectedNoteThreadKey,
                          us_selectedThreadNotes,
                          us_setNewNoteEditorVisibility,
                          us_setNoteReplyRichContentEditorValue,
                          us_setNoteRichContentEditorValue,
                          us_setNoteSetOrder,
                          us_setNoteSubjectValue,
                          us_setNoteTableFilter,
                          us_setReplyEditorVisibility,
                          uc_setRootData_ClientKey,
                          us_setSelectedNoteThreadKey,
                          uc_setUserInterface_ConfirmDialogDisplay,
                          uc_setUserInterface_ErrorDialogDisplay,
                          uc_setUserInterface_FormDialogDisplay,
                        )}
                      </Box>
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'documents',
                  tabHeader: rLIB('Documents'),
                  tabOnChange: () => {
                    us_setActiveTab('documents')
                  },
                  tabContent: (
                    <Box className="tw-m-auto">
                      {rJSX_AlertBanner()}
                      <Box className="tw-m-auto">
                        {rJSX_DocumentsTab(
                          getProp(us_pageSpecificUserRolePermission, 'documents', 'read'),
                          uc_RootData_ClientKey,
                          uc_RootData_ClientUser,
                          us_documentTableFilter,
                          us_documentsViewLevel,
                          us_projectDocumentFolders,
                          pr_projectKey,
                          us_selectedDocumentsFolder,
                          us_selectedDocumentsFolderData,
                          us_setDocumentTableFilter,
                          us_setDocumentsViewLevel,
                          uc_setRootData_ClientKey,
                          us_setSelectedDocumentsFolder,
                          us_setSelectedDocumentsFolderData,
                          uc_setUserInterface_ErrorDialogDisplay,
                          uc_setUserInterface_FormDialogDisplay,
                        )}
                      </Box>
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'photos',
                  tabHeader: s_PHOTOS,
                  tabOnChange: () => {
                    us_setActiveTab('photos')
                  },
                  tabContent: (
                    <Box className="tw-m-auto">
                      {rJSX_AlertBanner()}
                      <Box className="tw-m-auto">
                        {rJSX_PhotosTab(
                          getProp(us_pageSpecificUserRolePermission, 'photos', 'read'),
                          uc_RootData_ClientKey,
                          us_allTaskFormData,
                          us_availableImageTags,
                          us_downloadingPhotos,
                          us_filteredPhotosList,
                          us_flatImageTagCounts,
                          ur_forceRerender,
                          us_photosViewLevel,
                          us_photosViewType,
                          pr_projectKey,
                          us_projectRootPhotosFolders,
                          us_projectTaskWorkflow,
                          us_projectTasks,
                          us_selectedAllImageFilterTag,
                          us_selectedImageFilterTags,
                          us_selectedPhotosTaskFolder,
                          us_selectedPhotosTaskFolderData,
                          us_selectedPhotosTaskFolderForm,
                          us_selectedPhotosTaskPageFolder,
                          us_selectedPhotosTaskPageSubfolder,
                          us_setDownloadingPhotos,
                          us_setPhotosViewLevel,
                          us_setPhotosViewType,
                          uc_setRootData_ClientKey,
                          us_setSelectedAllImagesFilterTag,
                          us_setSelectedImageFilterTags,
                          us_setSelectedPhotosTaskFolder,
                          us_setSelectedPhotosTaskFolderData,
                          us_setSelectedPhotosTaskPageFolder,
                          us_setSelectedPhotosTaskPageSubfolder,
                          uc_setUserInterface_CustomDialogDisplay,
                          uc_setUserInterface_ErrorDialogDisplay,
                          uc_setUserInterface_FormDialogDisplay,
                          uc_setUserInterface_AlertDialogDisplay,
                          us_runningImageAnalysis,
                          us_setRunningImageAnalysis,
                          uc_setUserInterface_SnackbarDisplay,
                          us_screenSize,
                        )}
                      </Box>
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'logs',
                  tabHeader: s_LOGS,
                  tabOnChange: () => {
                    us_setActiveTab('logs')
                  },
                  tabContent: (
                    <Box className="tw-m-auto">
                      {rJSX_AlertBanner()}
                      <Box className="tw-m-auto">
                        {rJSX_LogsTab(getProp(us_pageSpecificUserRolePermission, 'logs', 'read'), uc_RootData_ClientKey, pr_projectKey)}
                      </Box>
                    </Box>
                  ),
                },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.AdminActiveProjectViewPage.url(pr_projectKey),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: getProp(us_rootProject, 'id_number', 'Project'),
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Return folders for documents page in project view page

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Trans } from 'react-i18next'
import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_CREATE_PROJECT_NOTE_1: JSX.Element = <Trans>Create Project Note 1</Trans>
const s_CREATE_PROJECT_NOTE_2: JSX.Element = <Trans>Create Project Note 2</Trans>
const s_CREATE_REMINDER: JSX.Element = <Trans>Create Reminder</Trans>
const s_PROJECT_NOTE_1_TITLE: JSX.Element = <Trans>Project Note 1 Title</Trans>
const s_PROJECT_NOTE_2_TITLE: JSX.Element = <Trans>Project Note 2 Title</Trans>
const s_REMINDER_TEXT: JSX.Element = <Trans>Reminder Text</Trans>
const s_REMINDER_TITLE: JSX.Element = <Trans>Reminder Title</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

export const returnTaskFormConfigFormInputs = (clientKey: string | null): TsInterface_FormInputs => {
  let taskFormConfigInputs: TsInterface_FormInputs = {}
  switch (clientKey) {
    // case "clientKey":
    default:
      taskFormConfigInputs = {
        create_contact_log: {
          data_type: 'boolean',
          input_type: 'boolean_switch',
          key: 'create_contact_log',
          label: rLIB('Create Contact Log'),
          required: false,
        },
        create_reminder1: {
          data_type: 'boolean',
          input_type: 'boolean_switch',
          key: 'create_reminder1',
          label: s_CREATE_REMINDER,
          required: false,
        },
        reminder1_title: {
          data_type: 'string',
          input_type: 'text_basic',
          key: 'reminder1_title',
          label: s_REMINDER_TITLE,
          required: true,
          conditional_display: {
            active: true,
            logic: {
              active: true,
              logic_type: 'comparison',
              source: 'formData',
              prop: 'create_reminder1',
              comparator: '==',
              value: true,
              conditions: [],
            },
          },
          conditional_require: {
            active: true,
            logic: {
              active: true,
              logic_type: 'comparison',
              source: 'formData',
              prop: 'create_reminder1',
              comparator: '==',
              value: true,
              conditions: [],
            },
          },
        },
        reminder1_text: {
          data_type: 'string',
          input_type: 'text_basic',
          key: 'reminder1_text',
          label: s_REMINDER_TEXT,
          required: true,
          conditional_display: {
            active: true,
            logic: {
              active: true,
              logic_type: 'comparison',
              source: 'formData',
              prop: 'create_reminder1',
              comparator: '==',
              value: true,
              conditions: [],
            },
          },
          conditional_require: {
            active: true,
            logic: {
              active: true,
              logic_type: 'comparison',
              source: 'formData',
              prop: 'create_reminder1',
              comparator: '==',
              value: true,
              conditions: [],
            },
          },
        },
        create_project_note_1: {
          data_type: 'boolean',
          input_type: 'boolean_switch',
          key: 'create_project_note_1',
          label: s_CREATE_PROJECT_NOTE_1,
          required: false,
        },
        project_note_1_thread_title: {
          data_type: 'string',
          input_type: 'text_basic',
          key: 'project_note_1_thread_title',
          label: s_PROJECT_NOTE_1_TITLE,
          required: true,
          conditional_display: {
            active: true,
            logic: {
              active: true,
              logic_type: 'comparison',
              source: 'formData',
              prop: 'create_project_note_1',
              comparator: '==',
              value: true,
              conditions: [],
            },
          },
          conditional_require: {
            active: true,
            logic: {
              active: true,
              logic_type: 'comparison',
              source: 'formData',
              prop: 'create_project_note_1',
              comparator: '==',
              value: true,
              conditions: [],
            },
          },
        },

        create_project_note_2: {
          data_type: 'boolean',
          input_type: 'boolean_switch',
          key: 'create_project_note_2',
          label: s_CREATE_PROJECT_NOTE_2,
          required: false,
        },
        project_note_2_thread_title: {
          data_type: 'string',
          input_type: 'text_basic',
          key: 'project_note_2_thread_title',
          label: s_PROJECT_NOTE_2_TITLE,
          required: true,
          conditional_display: {
            active: true,
            logic: {
              active: true,
              logic_type: 'comparison',
              source: 'formData',
              prop: 'create_project_note_2',
              comparator: '==',
              value: true,
              conditions: [],
            },
          },
          conditional_require: {
            active: true,
            logic: {
              active: true,
              logic_type: 'comparison',
              source: 'formData',
              prop: 'create_project_note_2',
              comparator: '==',
              value: true,
              conditions: [],
            },
          },
        },
      }
  }
  return taskFormConfigInputs
}

export const returnTaskFormConfigFields = (clientKey: string | null): TsInterface_UnspecifiedObject => {
  let taskFormConfigFields: TsInterface_UnspecifiedObject = {}
  switch (clientKey) {
    // case "clientKey":
    default:
      taskFormConfigFields = {
        contact_log_date: {
          value: 'Contact Log Date',
          key: 'contact_log_date',
          required_config: 'create_contact_log',
        },
        contact_log_notes: {
          value: 'Contact Log Notes',
          key: 'contact_log_notes',
          required_config: 'create_contact_log',
        },
        reminder1_date: {
          value: 'Reminder Date',
          key: 'reminder1_date',
          required_config: 'create_reminder1',
        },
        project_note_1_text: {
          value: 'Project Notes 1',
          key: 'project_note_1_text',
          required_config: 'create_project_note_1',
        },
        project_note_2_text: {
          value: 'Project Notes 2',
          key: 'project_note_2_text',
          required_config: 'create_project_note_2',
        },

        // TODO - mapped to root project stuff here...
      }
  }
  return taskFormConfigFields
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, FormControl, InputLabel, MenuItem, Select, Switch } from '@mui/material'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import {
  DatabaseRef_MLEvaluationProceduresProdData_Document,
  DatabaseRef_MLEvaluationProceduresStagingData_Document,
} from 'rfbp_aux/services/database_endpoints/machine_learning/evaluation_procedures'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableCellBasic,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import { TsInterface_FormDialogObject } from 'rfbp_core/services/context'
import { DatabaseBatchUpdate, TsInterface_DatabaseBatchUpdatesArray } from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

// Variables - TODO - move to a different file?
export const projectVariablesList: TsInterface_UnspecifiedObject = {
  PROJECT_associated_sales_partner_name: {
    data_type: 'string',
    key: 'PROJECT_associated_sales_partner_name',
    name: 'Sales Partner Name',
    variable_type: 'project',
  },
  PROJECT_associated_utility_company_name: {
    data_type: 'string',
    key: 'PROJECT_associated_utility_company_name',
    name: 'Utility Company Name',
    variable_type: 'project',
  },
  PROJECT_location_city: {
    data_type: 'string',
    key: 'PROJECT_location_city',
    name: 'City',
    variable_type: 'project',
  },
  PROJECT_location_county: {
    data_type: 'string',
    key: 'PROJECT_location_county',
    name: 'County',
    variable_type: 'project',
  },
  PROJECT_location_state: {
    data_type: 'string',
    key: 'PROJECT_location_state',
    name: 'State',
    variable_type: 'project',
  },
  PROJECT_system_panel_manufacturer: {
    data_type: 'string',
    key: 'PROJECT_system_panel_manufacturer',
    name: 'Panel Manufacturer',
    variable_type: 'project',
  },
  PROJECT_system_panel_model: {
    data_type: 'string',
    key: 'PROJECT_system_panel_model',
    name: 'Panel Model',
    variable_type: 'project',
  },
  PROJECT_system_panel_quantity: {
    data_type: 'number',
    key: 'PROJECT_system_panel_quantity',
    name: 'Panel Quantity',
    variable_type: 'project',
  },
  PROJECT_system_storage_manufacturer: {
    data_type: 'string',
    key: 'PROJECT_system_storage_manufacturer',
    name: 'Storage Manufacturer',
    variable_type: 'project',
  },
  PROJECT_system_storage_model: {
    data_type: 'string',
    key: 'PROJECT_system_storage_model',
    name: 'Storage Model',
    variable_type: 'project',
  },
  PROJECT_system_storage_quantity: {
    data_type: 'number',
    key: 'PROJECT_system_storage_quantity',
    name: 'Storage Quantity',
    variable_type: 'project',
  },
  PROJECT_system_inverter_manufacturer: {
    data_type: 'string',
    key: 'PROJECT_system_inverter_manufacturer',
    name: 'Inverter Manufacturer',
    variable_type: 'project',
  },
  PROJECT_system_inverter_model: {
    data_type: 'string',
    key: 'PROJECT_system_inverter_model',
    name: 'Inverter Model',
    variable_type: 'project',
  },
  PROJECT_system_inverter_quantity: {
    data_type: 'number',
    key: 'PROJECT_system_inverter_quantity',
    name: 'Inverter Quantity',
    variable_type: 'project',
  },
}

export const imageMetadataVariablesList: TsInterface_UnspecifiedObject = {
  METADATA_total_number_of_images: {
    data_type: 'number',
    key: 'METADATA_total_number_of_images',
    name: 'Total Number of Images',
    variable_type: 'image_metadata',
  },
  METADATA_date_image_taken: {
    data_type: 'date',
    key: 'METADATA_date_image_taken',
    name: 'Date Image Taken',
    variable_type: 'image_metadata',
  },
  METADATA_time_image_taken: {
    data_type: 'time',
    key: 'METADATA_time_image_taken',
    name: 'Time Image Taken',
    variable_type: 'image_metadata',
  },
  METADATA_datetime_image_taken: {
    data_type: 'datetime',
    key: 'METADATA_datetime_image_taken',
    name: 'Datetime Image Taken',
    variable_type: 'image_metadata',
  },
  METADATA_latitude: {
    data_type: 'number',
    key: 'METADATA_latitude',
    name: 'Latitude',
    variable_type: 'image_metadata',
  },
  METADATA_longitude: {
    data_type: 'number',
    key: 'METADATA_longitude',
    name: 'Longitude',
    variable_type: 'image_metadata',
  },
}

export const dataTypeOptions: TsInterface_UnspecifiedObject = {
  string: {
    key: 'string',
    value: rLIB('String'),
    icon: 'pencil',
    formType: 'text_basic',
    dataType: 'string',
  },
  number: {
    key: 'number',
    value: rLIB('Number'),
    icon: 'calculator-simple',
    formType: 'text_number',
    dataType: 'number',
  },
  boolean: {
    key: 'boolean',
    value: rLIB('Boolean'),
    icon: 'toggle-on',
    formType: 'boolean_checkbox',
    dataType: 'boolean',
  },
  date: {
    key: 'date',
    value: rLIB('Date'),
    icon: 'calendar',
    formType: 'timestamp_date',
    dataType: 'string',
  },
  time: {
    key: 'time',
    value: rLIB('Time'),
    icon: 'clock',
    formType: 'timestamp_time',
    dataType: 'string',
  },
  datetime: {
    key: 'datetime',
    value: rLIB('Datetime'),
    icon: 'calendar-clock',
    formType: 'timestamp_datetime',
    dataType: 'string',
  },
}

// Models Table
export const tableColumns_MLModels: TsInterface_TableColumns = {
  name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let checked = false
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.models_enabled_for_logic != null &&
          tableAdditionalData.models_enabled_for_logic[rowData.key as string] != null
        ) {
          checked = true
        }
        let cellClassName = ''
        if (checked !== true) {
          cellClassName = 'tw-opacity-30'
        }
        let cellJSX = <Box className={cellClassName}>{rowData.name}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Model Name') as JSX.Element
      },
      header_sort_by: 'name',
    },
  },
  // endpoint: {
  // cell: {
  // 	cell_css: (
  // 		rowData: TsInterface_TableDataRow,
  // 		tableAdditionalData: TsInterface_TableAdditionalData
  // 	) => { return "" },
  // 	cell_jsx: (
  // 		rowData: TsInterface_TableDataRow,
  // 		tableAdditionalData: TsInterface_TableAdditionalData,
  // 		tableHooks: TsInterface_TableHooks
  // 	) => {
  // 		let checked = false
  // 		if(
  // 			rowData != null &&
  // 			rowData.key != null &&
  // 			tableAdditionalData != null &&
  // 			tableAdditionalData.models_enabled_for_logic != null &&
  // 			tableAdditionalData.models_enabled_for_logic[ rowData.key as string ] != null
  // 		){
  // 			checked = true
  // 		}
  // 		let cellClassName = ""
  // 		if( checked !== true ){
  // 			cellClassName = "tw-opacity-30"
  // 		}
  // 		let cellJSX =
  // 		<Box className={cellClassName}>
  // 			{ rowData.endpoint }
  // 		</Box>
  // 		return cellJSX
  // 	},
  // },
  // header: {
  // 	header_css: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return "" },
  // 	header_jsx: ( tableAdditionalData: TsInterface_TableAdditionalData ) => { return s_ENDPOINT },
  // 	header_sort_by: "endpoint"
  // }
  // },
  input_type: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let checked = false
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.models_enabled_for_logic != null &&
          tableAdditionalData.models_enabled_for_logic[rowData.key as string] != null
        ) {
          checked = true
        }
        let cellClassName = ''
        if (checked !== true) {
          cellClassName = 'tw-opacity-30'
        }
        let cellJSX = <Box className={cellClassName}>{rowData.input_type}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Model Input Type') as JSX.Element
      },
      header_sort_by: 'input_type',
    },
  },
  output_type: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let checked = false
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.models_enabled_for_logic != null &&
          tableAdditionalData.models_enabled_for_logic[rowData.key as string] != null
        ) {
          checked = true
        }
        let cellClassName = ''
        if (checked !== true) {
          cellClassName = 'tw-opacity-30'
        }
        let cellJSX = <Box className={cellClassName}>{rowData.output_type}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Model Output Type') as JSX.Element
      },
      header_sort_by: 'output_type',
    },
  },
  status: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let checked = false
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.models_enabled_for_logic != null &&
          tableAdditionalData.models_enabled_for_logic[rowData.key as string] != null
        ) {
          checked = true
        }
        let cellClassName = ''
        if (checked !== true) {
          cellClassName = 'tw-opacity-30'
        }
        cellJSX = (
          <Box className={cellClassName}>
            <Switch
              color="info"
              checked={checked}
              onChange={(event, value) => {
                if (rowData.key != null && value === true) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    models: {},
                  }
                  updateObject['models'][rowData.key as string] = rowData
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      saveUpdateToFormulaStaging(res_GCK.clientKey, tableAdditionalData.itemKey, updateObject)
                        .then((res_SUTFS) => {
                          // Nothing
                        })
                        .catch((rej_SUTFS) => {
                          console.error(rej_SUTFS)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                } else if (rowData.key != null && value === false) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    models: {},
                  }
                  updateObject['models'][rowData.key as string] = null
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      saveUpdateToFormulaStaging(res_GCK.clientKey, tableAdditionalData.itemKey, updateObject)
                        .then((res_SUTFS) => {
                          // Nothing
                        })
                        .catch((rej_SUTFS) => {
                          console.error(rej_SUTFS)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Active') as JSX.Element
      },
      header_sort_by: null,
    },
  },
}

export const tableSettings_MLModels: TsInterface_TableSettings = {
  paginated: true,
  pagination_rows_per_page_default: 25,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  show_header: true,
  size: 'small',
  sort_direction: 'asc',
  sort_property_default: 'name',
  sortable: true,
}

// Hardcode Table
const saveUpdateToFormulaStaging = (clientKey: string, formulaKey: string, updateObject: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
  return new Promise((resolve, reject) => {
    // TODO - Temporary setup, full staging to prod pipeline and version history later
    let updateArray: TsInterface_DatabaseBatchUpdatesArray = [
      {
        type: 'setMerge',
        ref: DatabaseRef_MLEvaluationProceduresStagingData_Document(clientKey, formulaKey),
        data: updateObject,
      },
      {
        type: 'setMerge',
        ref: DatabaseRef_MLEvaluationProceduresProdData_Document(clientKey, formulaKey),
        data: updateObject,
      },
    ]
    DatabaseBatchUpdate(updateArray)
      .then((res_DBU) => {
        resolve(res_DBU)
      })
      .catch((rej_DBU) => {
        // uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DBU.error })
        reject(rej_DBU)
      })
  })
}

export const tableSettings_HardcodeVariables: TsInterface_TableSettings = {
  size: 'small',
  sortable: true,
  sort_property_default: 'name',
  paginated: false,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
  conditional_row_styles: [
    {
      className: 'tw-opacity-30 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'inactive',
        conditions: [],
      },
    },
  ],
}

export const tableColumns_HardcodeVariables: TsInterface_TableColumns = {
  name: TableCellBasic('name', rLIB('Variable Name'), 'name'),
  data_type: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellSX: TsInterface_UnspecifiedObject = {}
        if (rowData.data_type == null) {
          cellSX['background'] = themeVariables.warning_light
        }
        let cellJSX = (
          <Box
            className="tw-rounded-md"
            sx={cellSX}
          >
            <FormControl
              className="bp_thin_select_input bp_thin_select_multiple_input"
              sx={{ minWidth: '130px', width: 'calc(100%)' }}
            >
              <InputLabel>{rLIB('Data Type')}</InputLabel>
              <Select
                autoWidth={true}
                label={rLIB('Data Type')}
                onChange={(event, value) => {
                  if (rowData.key != null) {
                    let updateObject: TsInterface_UnspecifiedObject = {
                      variables: {},
                    }
                    updateObject['variables'][rowData.key as string] = {
                      data_type: event.target.value,
                      hardcode_value: null,
                    }
                    getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        saveUpdateToFormulaStaging(res_GCK.clientKey, tableAdditionalData.itemKey, updateObject)
                          .then((res_SUTFS) => {
                            // Nothing
                          })
                          .catch((rej_SUTFS) => {
                            console.error(rej_SUTFS)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }
                }}
                value={rowData.data_type || ''}
              >
                {objectToArray(dataTypeOptions).map((option: TsInterface_UnspecifiedObject) => (
                  <MenuItem
                    key={option['key']}
                    value={option['key']}
                    disabled={option['disabled']}
                  >
                    <Box
                      className="tw-inline-block"
                      sx={{ marginRight: '8px', color: themeVariables.error_main }}
                    >
                      <Icon icon={option['icon']} />
                    </Box>
                    {option['value']}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Data Type') as JSX.Element
      },
      header_sort_by: null,
    },
  },
  hardcode_value: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let propValueJSX = <Box className="tw-italic tw-opacity-30 tw-inline-block">{rLIB('Missing')}</Box>
        let rowDataCopy = { ...rowData }
        if (rowDataCopy != null && rowDataCopy['hardcode_value'] != null) {
          if (rowDataCopy['hardcode_value'] === true) {
            rowDataCopy['hardcode_value'] = 'true'
          } else if (rowDataCopy['hardcode_value'] === false) {
            rowDataCopy['hardcode_value'] = 'false'
          }
          propValueJSX = <Box className="tw-inline-block">{rowDataCopy['hardcode_value']}</Box>
        }
        let editIconJSX = <></>
        if (rowData != null && rowData.data_type != null) {
          editIconJSX = (
            <Box
              sx={{
                'color': themeVariables.success_light,
                '&:hover': {
                  color: themeVariables.success_main,
                },
              }}
              className="tw-inline-block tw-ml-2 tw-cursor-pointer"
              onClick={() => {
                let inputFormType = null
                let inputDataType = null
                if (dataTypeOptions != null && dataTypeOptions[rowData.data_type as any] != null) {
                  inputFormType = getProp(dataTypeOptions[rowData.data_type as any], 'formType', null)
                  inputDataType = getProp(dataTypeOptions[rowData.data_type as any], 'dataType', null)
                }
                const FormDialogObject: TsInterface_FormDialogObject = {
                  form: {
                    formAdditionalData: {},
                    formData: rowData,
                    formInputs: {
                      hardcode_value: {
                        key: 'hardcode_value',
                        label: (
                          <>
                            {rLIB('Hardcode Value')} - {rowData.name}
                          </>
                        ),
                        input_type: inputFormType,
                        required: true,
                        data_type: inputDataType,
                      },
                    },
                    formOnChange: (
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formData: TsInterface_FormData,
                      formInputs: TsInterface_FormInputs,
                      formSettings: TsInterface_FormSettings,
                    ) => {},
                    formSettings: {},
                    formSubmission: (
                      formSubmittedData: TsInterface_FormSubmittedData,
                      formAdditionalData: TsInterface_FormAdditionalData,
                      formHooks: TsInterface_FormHooksObject,
                    ) => {
                      return new Promise((resolve, reject) => {
                        if (tableAdditionalData.itemKey != null && rowData.key != null && formSubmittedData.hardcode_value != null) {
                          let updateObject: TsInterface_UnspecifiedObject = {
                            variables: {},
                          }
                          updateObject['variables'][rowData.key as string] = {
                            hardcode_value: formSubmittedData.hardcode_value,
                          }
                          getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                            .then((res_GCK) => {
                              saveUpdateToFormulaStaging(res_GCK.clientKey, tableAdditionalData.itemKey, updateObject)
                                .then((res_SUTFS) => {
                                  resolve(res_SUTFS)
                                })
                                .catch((rej_SUTFS) => {
                                  reject(rej_SUTFS)
                                })
                            })
                            .catch((rej_GCK) => {
                              reject(rej_GCK)
                            })
                        } else {
                          reject({ success: false })
                        }
                      })
                    },
                  },
                  dialog: {
                    formDialogHeaderColor: 'success',
                    formDialogHeaderText: (
                      <>
                        {rLIB('Edit test value')} - {rowData.name}
                      </>
                    ),
                    formDialogIcon: <Icon icon="pen-to-square" />,
                  },
                }
                tableHooks.uc_setUserInterface_FormDialogDisplay({
                  display: true,
                  form: FormDialogObject,
                })
              }}
            >
              <Icon icon="pen-to-square" />
            </Box>
          )
        }
        cellJSX = (
          <Box>
            {propValueJSX}
            {editIconJSX}
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Hardcode Value') as JSX.Element
      },
      header_sort_by: null,
    },
  },
  status: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let checked = false
        if (rowData.status === 'active') {
          checked = true
        }
        cellJSX = (
          <Box>
            <Switch
              color="info"
              checked={checked}
              onChange={(event, value) => {
                if (rowData.key != null && value === true) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    variables: {},
                  }
                  updateObject['variables'][rowData.key as string] = {
                    status: 'active',
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      saveUpdateToFormulaStaging(res_GCK.clientKey, tableAdditionalData.itemKey, updateObject)
                        .then((res_SUTFS) => {
                          // Nothing
                        })
                        .catch((rek_SUTFS) => {
                          console.error(rek_SUTFS)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                } else if (rowData.key != null && value === false) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    variables: {},
                  }
                  updateObject['variables'][rowData.key as string] = {
                    status: 'inactive',
                  }
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      saveUpdateToFormulaStaging(res_GCK.clientKey, tableAdditionalData.itemKey, updateObject)
                        .then((res_SUTFS) => {
                          // Nothing
                        })
                        .catch((rek_SUTFS) => {
                          console.error(rek_SUTFS)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Active') as JSX.Element
      },
      header_sort_by: null,
    },
  },
  delete: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let checked = false
        if (rowData.status === 'active') {
          checked = true
        }
        cellJSX = (
          <Box>
            <Button
              color="error"
              variant="contained"
              disabled={checked === true}
              onClick={() => {
                let updateObject: TsInterface_UnspecifiedObject = {
                  variables: {},
                }
                updateObject['variables'][rowData.key as string] = null
                getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    saveUpdateToFormulaStaging(res_GCK.clientKey, tableAdditionalData.itemKey, updateObject)
                      .then((res_SUTFS) => {
                        // Nothing
                      })
                      .catch((rej_SUTFS) => {
                        console.error(rej_SUTFS)
                      })
                  })
                  .catch((rej_GCK) => {
                    console.error(rej_GCK)
                  })
              }}
            >
              <Icon icon="trash" />
            </Button>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <></>
      },
      header_sort_by: null,
    },
  },
}

// Project and Metadata Table
export const tableColumns_ProjectAndMetadataVariables: TsInterface_TableColumns = {
  name: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let checked = false
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.variables_enabled_for_logic != null &&
          tableAdditionalData.variables_enabled_for_logic[rowData.key as string] != null
        ) {
          checked = true
        }
        let cellClassName = ''
        if (checked !== true) {
          cellClassName = 'tw-opacity-30'
        }
        let cellJSX = <Box className={cellClassName}>{rowData.name}</Box>
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Variable Name') as JSX.Element
      },
      header_sort_by: 'name',
    },
  },
  data_type: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let checked = false
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.variables_enabled_for_logic != null &&
          tableAdditionalData.variables_enabled_for_logic[rowData.key as string] != null
        ) {
          checked = true
        }
        let cellClassName = ''
        if (checked !== true) {
          cellClassName = 'tw-opacity-30'
        }
        let cellSX: TsInterface_UnspecifiedObject = {}
        if (rowData.data_type == null) {
          cellSX['background'] = themeVariables.warning_light
        }
        let cellJSX = (
          <Box className={cellClassName}>
            <Box
              className="tw-rounded-md"
              sx={cellSX}
            >
              <FormControl
                className="bp_thin_select_input bp_thin_select_multiple_input"
                sx={{ minWidth: '130px', width: 'calc(100%)' }}
              >
                <InputLabel>{rLIB('Data Type')}</InputLabel>
                <Select
                  autoWidth={true}
                  label={rLIB('Data Type')}
                  disabled={true}
                  value={rowData.data_type || ''}
                >
                  {objectToArray(dataTypeOptions).map((option: TsInterface_UnspecifiedObject) => (
                    <MenuItem
                      key={option['key']}
                      value={option['key']}
                      disabled={option['disabled']}
                    >
                      <Box
                        className="tw-inline-block"
                        sx={{ marginRight: '8px', color: themeVariables.error_main }}
                      >
                        <Icon icon={option['icon']} />
                      </Box>
                      {option['value']}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Data Type') as JSX.Element
      },
      header_sort_by: null,
    },
  },
  status: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        let cellJSX = <></>
        let checked = false
        if (
          rowData != null &&
          rowData.key != null &&
          tableAdditionalData != null &&
          tableAdditionalData.variables_enabled_for_logic != null &&
          tableAdditionalData.variables_enabled_for_logic[rowData.key as string] != null
        ) {
          checked = true
        }
        let cellClassName = ''
        if (checked !== true) {
          cellClassName = 'tw-opacity-30'
        }
        cellJSX = (
          <Box className={cellClassName}>
            <Switch
              color="info"
              checked={checked}
              onChange={(event, value) => {
                if (rowData.key != null && value === true) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    variables: {},
                  }
                  updateObject['variables'][rowData.key as string] = rowData
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      saveUpdateToFormulaStaging(res_GCK.clientKey, tableAdditionalData.itemKey, updateObject)
                        .then((res_SUTFS) => {
                          // Nothing
                        })
                        .catch((rej_SUTFS) => {
                          console.error(rej_SUTFS)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                } else if (rowData.key != null && value === false) {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    variables: {},
                  }
                  updateObject['variables'][rowData.key as string] = null
                  getClientKey(tableHooks.uc_RootData_ClientKey, tableHooks.uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      saveUpdateToFormulaStaging(res_GCK.clientKey, tableAdditionalData.itemKey, updateObject)
                        .then((res_SUTFS) => {
                          // Nothing
                        })
                        .catch((rej_SUTFS) => {
                          console.error(rej_SUTFS)
                        })
                    })
                    .catch((rej_GCK) => {
                      console.error(rej_GCK)
                    })
                }
              }}
            />
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return rLIB('Active') as JSX.Element
      },
      header_sort_by: null,
    },
  },
}

export const tableSettings_ProjectAndMetadataVariables: TsInterface_TableSettings = {
  size: 'small',
  sortable: true,
  sort_property_default: 'name',
  paginated: false,
  pagination_rows_per_page_default: 100,
  pagination_rows_per_page_options: [10, 25, 50, 100],
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  AppBar,
  Autocomplete,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material'
import { returnCurrentLegalVersionNumbers } from 'app/models/legal/legal_documents_version_history'
import React from 'react'
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_PowerwallOrder_Document } from 'rfbp_aux/services/database_endpoints/webstore/orders'
import { StorageRef_TeslaPowerwallOrderPhotos } from 'rfbp_aux/services/storage_endpoints/powerwall_orders'
import { FileUploadButton } from 'rfbp_core/components/file_upload'
import {
  Form,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TabsBasic } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { UserInterface_Default_CustomDialogDisplayState } from 'rfbp_core/services/context'
import { DatabaseSetMergeDocument, StorageUploadFile } from 'rfbp_core/services/database_management'
import { getProp, objectToArray, returnDateFromUnknownDateFormat, returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'
// @ts-expect-error - TODO: reason for error
import panelSrc from '../../images/unauthenticated/panel.jpg'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Postal Code Lookup
export const serviceAreaLookup = {
  // SAN ANTONIO / AUSTIN
  '76523': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Edge' },
  '78005': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Core' },
  '78011': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Core' },
  '78026': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Core' },
  '78050': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Core' },
  '78052': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Core' },
  '78057': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Core' },
  '78064': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Core' },
  '78065': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Core' },
  '78159': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Edge' },
  '78629': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Edge' },
  '78632': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Edge' },
  '78959': { region: 'san_antonio', utility_company: 'AEP Texas', area: 'Edge' },
  '76518': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Edge' },
  '76519': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Edge' },
  '76520': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Edge' },
  '76539': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Edge' },
  '76550': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Edge' },
  '76556': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Edge' },
  '76567': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Edge' },
  '76577': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Edge' },
  '76579': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78605': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Edge' },
  '78608': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Edge' },
  '78611': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Edge' },
  '78617': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78650': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78653': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78659': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78660': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78701': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78702': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78703': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78704': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78705': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78712': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78719': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78721': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78722': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78723': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78724': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78725': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78726': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78727': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78728': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78729': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78730': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78731': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78732': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78733': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78735': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78736': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78738': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78739': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78741': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78742': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78744': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78745': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78746': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78747': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78748': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78749': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78750': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78751': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78752': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78753': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78754': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78756': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78757': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78758': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78759': { region: 'san_antonio', utility_company: 'Austin Energy', area: 'Core' },
  '78003': { region: 'san_antonio', utility_company: 'Bandera Electric Cooperative', area: 'Core' },
  '78063': { region: 'san_antonio', utility_company: 'Bandera Electric Cooperative', area: 'Core' },
  '77418': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Edge' },
  '77426': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Edge' },
  '77833': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Edge' },
  '77835': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Edge' },
  '77880': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Edge' },
  '78931': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Edge' },
  '78944': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Edge' },
  '78950': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Edge' },
  '78602': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Core' },
  '78612': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Core' },
  '78616': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Core' },
  '78622': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Core' },
  '78644': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Core' },
  '78655': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Core' },
  '78656': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Core' },
  '78661': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Core' },
  '78662': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Core' },
  '78670': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Core' },
  '78948': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Edge' },
  '78953': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Core' },
  '78963': { region: 'san_antonio', utility_company: 'Bluebonnet Electric Cooperative', area: 'Edge' },
  '77356': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77001': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77002': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77003': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77004': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77005': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77006': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77007': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77008': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77009': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77010': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77011': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77012': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77013': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77014': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77015': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77016': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77017': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77018': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77019': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77020': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77021': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77022': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77023': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77024': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77025': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77026': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77027': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77028': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77029': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77030': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77031': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77032': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77033': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77034': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77035': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77036': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77037': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77038': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77039': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77040': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77041': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77042': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77043': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77044': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77045': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77046': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77047': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77048': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77049': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77050': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77051': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77052': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77053': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77054': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77055': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77056': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77057': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77058': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77059': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77060': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77061': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77062': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77063': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77064': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77065': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77066': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77067': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77068': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77069': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77070': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77071': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77072': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77073': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77074': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77075': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77076': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77077': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77078': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77079': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77080': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77081': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77082': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77083': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77084': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77085': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77086': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77087': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77088': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77089': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77090': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77091': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77092': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77093': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77094': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77095': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77096': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77098': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77099': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77201': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77202': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77203': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77204': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77205': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77206': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77208': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77209': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77210': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77212': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77213': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77215': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77218': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77219': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77220': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77221': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77222': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77223': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77224': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77225': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77226': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77227': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77228': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77229': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77230': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77231': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77233': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77234': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77235': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77236': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77237': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77238': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77240': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77241': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77242': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77243': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77244': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77248': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77249': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77251': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77252': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77253': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77254': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77255': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77256': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77257': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77258': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77259': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77262': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77263': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77265': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77266': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77267': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77268': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77269': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77270': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77271': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77272': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77273': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77274': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77275': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77277': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77279': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77280': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77282': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77284': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77287': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77288': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77290': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77291': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77292': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77293': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77297': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77299': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77315': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77325': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77337': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77338': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77345': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77346': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77347': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77355': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77362': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77373': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77375': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77377': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77379': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77383': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77386': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77388': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77389': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77391': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77393': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77396': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77401': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77402': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77406': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77407': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77410': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77411': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77413': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77417': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77420': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77423': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77429': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77430': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77433': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77435': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77436': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77437': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77441': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77443': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77444': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77445': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77446': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77447': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77448': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77449': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77450': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77451': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77452': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77453': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77454': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77455': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77459': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77461': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77464': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77466': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77469': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77471': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77473': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77474': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77476': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77477': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77478': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77479': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77484': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77485': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77487': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77488': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77489': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77491': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77492': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77493': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77494': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77496': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77497': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77498': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77501': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77502': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77503': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77504': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77505': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77506': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77507': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77508': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77510': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77517': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77518': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77520': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77521': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77523': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77530': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77531': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77532': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77533': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77534': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77536': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77541': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77542': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77545': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77547': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77549': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77552': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77553': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77554': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77555': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77562': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77563': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77566': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77571': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77572': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77574': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77577': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77578': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77582': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77583': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Edge' },
  '77586': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77587': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '77598': { region: 'san_antonio', utility_company: 'Centerpoint Energy', area: 'Core' },
  '78002': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78006': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78013': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78015': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78023': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78069': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78073': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78101': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78108': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78109': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78112': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78124': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78148': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78150': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78152': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78154': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78163': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78201': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78202': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78203': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78204': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78205': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78207': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78208': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78209': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78210': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78211': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78212': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78213': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78214': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78215': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78216': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78217': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78218': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78219': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78220': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78221': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78222': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78223': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78224': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78225': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78226': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78227': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78228': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78229': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78230': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78231': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78232': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78233': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78234': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78235': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78236': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78237': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78238': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78239': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78240': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78242': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78243': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78244': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78245': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78247': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78248': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78249': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78250': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78251': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78252': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78253': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78254': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78255': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78256': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78257': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78258': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78259': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78260': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78261': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78263': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78264': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78266': { region: 'san_antonio', utility_company: 'CPS Energy', area: 'Core' },
  '78639': { region: 'san_antonio', utility_company: 'CTEC', area: 'Edge' },
  '76201': { region: 'san_antonio', utility_company: 'Denton Municipal Electric', area: 'Core' },
  '77301': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77302': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77303': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77304': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77306': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77316': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77318': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77327': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77328': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77336': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77339': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77354': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77357': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77365': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77371': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77372': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77380': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77381': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77382': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77384': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77385': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77387': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77535': { region: 'san_antonio', utility_company: 'Entergy', area: 'Core' },
  '77975': { region: 'san_antonio', utility_company: 'Fayette Electric Cooperative', area: 'Edge' },
  '78941': { region: 'san_antonio', utility_company: 'Fayette Electric Cooperative', area: 'Edge' },
  '78949': { region: 'san_antonio', utility_company: 'Fayette Electric Cooperative', area: 'Edge' },
  '78942': { region: 'san_antonio', utility_company: 'Giddings Light & Power System', area: 'Edge' },
  '78114': { region: 'san_antonio', utility_company: 'GVEC', area: 'Core' },
  '78121': { region: 'san_antonio', utility_company: 'GVEC', area: 'Core' },
  '78122': { region: 'san_antonio', utility_company: 'GVEC', area: 'Edge' },
  '78123': { region: 'san_antonio', utility_company: 'GVEC', area: 'Core' },
  '78140': { region: 'san_antonio', utility_company: 'GVEC', area: 'Edge' },
  '78143': { region: 'san_antonio', utility_company: 'GVEC', area: 'Core' },
  '78147': { region: 'san_antonio', utility_company: 'GVEC', area: 'Core' },
  '78155': { region: 'san_antonio', utility_company: 'GVEC', area: 'Core' },
  '78160': { region: 'san_antonio', utility_company: 'GVEC', area: 'Core' },
  '78161': { region: 'san_antonio', utility_company: 'GVEC', area: 'Core' },
  '78614': { region: 'san_antonio', utility_company: 'GVEC', area: 'Edge' },
  '78638': { region: 'san_antonio', utility_company: 'GVEC', area: 'Core' },
  '78113': { region: 'san_antonio', utility_company: 'Karnes Electric Cooperative', area: 'Core' },
  '78116': { region: 'san_antonio', utility_company: 'Karnes Electric Cooperative', area: 'Core' },
  '78117': { region: 'san_antonio', utility_company: 'Karnes Electric Cooperative', area: 'Core' },
  '78118': { region: 'san_antonio', utility_company: 'Karnes Electric Cooperative', area: 'Core' },
  '78947': { region: 'san_antonio', utility_company: 'Lexington Municipal Electric Department', area: 'Edge' },
  '78648': { region: 'san_antonio', utility_company: 'Luling Utilities', area: 'Core' },
  '78009': { region: 'san_antonio', utility_company: 'Medina Electric Cooperative', area: 'Core' },
  '78016': { region: 'san_antonio', utility_company: 'Medina Electric Cooperative', area: 'Core' },
  '78039': { region: 'san_antonio', utility_company: 'Medina Electric Cooperative', area: 'Core' },
  '78056': { region: 'san_antonio', utility_company: 'Medina Electric Cooperative', area: 'Core' },
  '78059': { region: 'san_antonio', utility_company: 'Medina Electric Cooperative', area: 'Core' },
  '78066': { region: 'san_antonio', utility_company: 'Medina Electric Cooperative', area: 'Core' },
  '78861': { region: 'san_antonio', utility_company: 'Medina Electric Cooperative', area: 'Core' },
  '78886': { region: 'san_antonio', utility_company: 'Medina Electric Cooperative', area: 'Core' },
  '75001': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75002': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75006': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75007': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75009': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75010': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75011': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75013': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75014': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75015': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75016': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75017': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75019': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75020': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75021': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75022': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75023': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75024': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75025': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75026': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75028': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75030': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75032': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75033': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75034': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75035': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75036': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75038': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75039': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75040': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75041': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75042': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75043': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75044': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75048': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75050': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75051': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75052': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75053': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75054': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75056': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75057': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75058': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75060': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75061': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75062': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75063': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75065': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75067': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75068': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75069': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75070': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75071': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75072': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75074': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75075': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75076': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75077': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75078': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75080': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75081': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75082': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75083': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75085': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75086': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75087': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75088': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75089': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75090': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75091': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75092': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75093': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75094': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75097': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75098': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75099': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75101': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75103': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75104': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75105': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75106': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75109': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75114': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75115': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75116': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75118': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75119': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75120': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75121': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75123': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75124': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75125': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75126': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75132': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75134': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75137': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75138': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75141': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75142': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75143': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75144': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75146': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75147': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75148': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75149': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75150': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75151': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75152': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75153': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75154': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75155': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75156': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75157': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75158': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75159': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75160': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75161': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75163': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75164': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75165': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75166': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75167': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75168': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75172': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75173': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75180': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75181': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75182': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75185': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75187': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75189': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75201': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75202': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75203': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75204': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75205': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75206': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75207': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75208': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75209': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75210': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75211': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75212': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75214': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75215': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75216': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75217': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75218': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75219': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75220': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75221': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75222': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75223': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75224': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75225': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75226': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75227': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75228': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75229': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75230': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75231': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75232': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75233': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75234': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75235': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75236': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75237': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75238': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75240': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75241': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75242': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75243': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75244': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75246': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75247': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75248': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75249': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75250': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75251': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75252': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75253': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75254': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75260': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75261': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75262': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75263': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75264': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75265': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75266': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75267': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75270': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75275': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75277': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75283': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75284': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75285': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75287': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75301': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75303': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75312': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75313': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75315': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75320': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75326': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75336': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75339': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75342': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75354': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75355': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75356': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75357': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75358': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75359': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75360': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75367': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75368': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75371': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75372': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75373': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75374': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75376': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75378': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75379': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75380': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75381': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75382': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75390': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75391': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75392': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75393': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75394': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75395': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75397': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75398': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75407': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75409': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75414': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75415': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75420': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75421': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75424': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75425': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75433': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75437': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75439': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75441': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75442': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75452': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75454': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75459': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75461': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75469': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75470': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75475': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75477': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75479': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75482': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75483': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75485': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75489': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75491': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75495': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '75701': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75702': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75703': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75704': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75706': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75707': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75708': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75709': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75710': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75711': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75712': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75713': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75750': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75752': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75754': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75756': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75757': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75758': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75759': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75760': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75762': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75763': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75764': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75766': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75770': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75771': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75772': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75778': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75779': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75780': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75782': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75784': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75785': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75788': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75790': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75791': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75798': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75799': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75802': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75832': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75848': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75849': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75850': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75859': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75882': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75902': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75903': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75904': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75915': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75929': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75937': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75941': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75943': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75944': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75958': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75961': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75962': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75963': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75964': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75965': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75969': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '75978': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76001': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76002': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76003': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76004': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76005': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76006': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76008': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76009': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76010': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76011': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76012': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76013': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76014': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76015': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76016': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76017': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76018': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76019': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76020': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76021': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76022': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76023': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76028': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76031': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76033': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76034': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76035': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76036': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76039': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76040': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76041': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76044': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76050': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76051': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76052': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76053': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76054': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76058': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76059': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76060': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76061': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76063': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76064': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76065': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76071': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76073': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76078': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76082': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76084': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76085': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76086': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76087': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76088': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76092': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76094': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76095': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76096': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76097': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76099': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76101': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76102': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76103': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76104': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76105': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76106': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76107': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76108': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76109': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76110': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76111': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76112': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76114': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76115': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76116': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76117': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76118': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76119': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76120': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76121': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76122': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76123': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76124': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76126': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76127': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76129': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76130': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76131': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76132': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76133': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76134': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76135': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76137': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76140': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76147': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76148': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76155': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76161': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76162': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76163': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76164': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76166': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76177': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76179': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76180': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76182': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76185': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76192': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76196': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76197': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76199': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76202': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76203': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76204': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76205': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76206': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76207': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76208': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76209': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76210': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76225': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76226': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76227': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76233': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76234': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76238': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76240': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76241': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76244': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76245': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76246': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76247': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76248': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76249': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76250': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76252': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76253': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76258': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76259': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76262': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76263': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76264': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76267': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76268': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76271': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76272': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76273': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76301': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76302': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76305': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76306': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76307': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76308': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76309': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76310': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76311': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76351': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76352': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76354': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76367': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76369': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76379': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76402': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76426': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76429': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76431': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76445': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76446': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76461': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76465': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76466': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76468': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76481': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76484': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76564': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76623': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76648': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76650': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76651': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76670': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76803': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76804': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77422': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77431': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77463': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77468': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77480': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77481': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '77482': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77486': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77511': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77512': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77515': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77539': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '77546': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '77550': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '77551': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '77565': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '77568': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '77573': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '77581': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77584': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77588': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '77590': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '77591': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '77592': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '78401': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78402': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78403': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78404': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78405': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78406': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78407': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78408': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78409': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78410': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78411': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78412': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78413': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78414': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78415': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78416': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78417': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78418': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78419': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78426': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78427': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78460': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78463': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78465': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78466': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78467': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78468': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78469': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78470': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78471': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78472': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78473': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78474': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78475': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78476': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78477': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78478': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78480': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78501': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78502': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78503': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78504': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78505': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78520': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78521': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78522': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78523': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78526': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78691': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '79550': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79708': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79710': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79711': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79712': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79733': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79735': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79741': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79742': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79754': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79759': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79760': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79761': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79763': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79764': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79766': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79768': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79769': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '79776': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76501': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76502': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76504': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76508': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76511': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76513': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76522': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76530': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76534': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76537': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76541': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76542': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76543': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76544': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76548': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76549': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76554': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76559': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76569': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76570': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '76571': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76573': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76574': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76578': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '78609': { region: 'san_antonio', utility_company: 'Oncor', area: 'Edge' },
  '78615': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '78621': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '78626': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '78634': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '78664': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '78665': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '78681': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '78717': { region: 'san_antonio', utility_company: 'Oncor', area: 'Core' },
  '76527': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78004': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78027': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78070': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78130': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78132': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78133': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78606': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Edge' },
  '78610': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78613': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78619': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78620': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78623': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78628': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78633': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78635': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Edge' },
  '78636': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Edge' },
  '78640': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78641': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78642': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78645': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78652': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78654': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Edge' },
  '78657': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Edge' },
  '78663': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Edge' },
  '78666': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78669': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78676': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78734': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78737': { region: 'san_antonio', utility_company: 'Pedernales', area: 'Core' },
  '78957': { region: 'san_antonio', utility_company: 'Smithville Utilities Department', area: 'Core' },
  '75413': { region: 'san_antonio', utility_company: 'TNMP', area: 'Core' },
  '75443': { region: 'san_antonio', utility_company: 'TNMP', area: 'Core' },
  '75487': { region: 'san_antonio', utility_company: 'TNMP', area: 'Edge' },
  '76077': { region: 'san_antonio', utility_company: 'TNMP', area: 'Core' },
  '76261': { region: 'san_antonio', utility_company: 'TNMP', area: 'Edge' },
  '76463': { region: 'san_antonio', utility_company: 'TNMP', area: 'Edge' },
  '76475': { region: 'san_antonio', utility_company: 'TNMP', area: 'Edge' },
  '77207': { region: 'san_antonio', utility_company: 'TNMP', area: 'Core' },
  '77216': { region: 'san_antonio', utility_company: 'TNMP', area: 'Core' },
  '77217': { region: 'san_antonio', utility_company: 'TNMP', area: 'Core' },
  '77245': { region: 'san_antonio', utility_company: 'TNMP', area: 'Core' },
  '77261': { region: 'san_antonio', utility_company: 'TNMP', area: 'Core' },
  '77289': { region: 'san_antonio', utility_company: 'TNMP', area: 'Core' },
  '77516': { region: 'san_antonio', utility_company: 'TNMP', area: 'Edge' },
  '77522': { region: 'san_antonio', utility_company: 'TNMP', area: 'Core' },
  '79770': { region: 'san_antonio', utility_company: 'TNMP', area: 'Edge' },
  '79772': { region: 'san_antonio', utility_company: 'TNMP', area: 'Edge' },
  '79785': { region: 'san_antonio', utility_company: 'TNMP', area: 'Edge' },
  '79848': { region: 'san_antonio', utility_company: 'TNMP', area: 'Edge' },
  '75027': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '75029': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76007': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76048': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76049': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76066': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Edge' },
  '76067': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Edge' },
  '76068': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Edge' },
  '76098': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76113': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76136': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76150': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76181': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76191': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76193': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76195': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76198': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76266': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Core' },
  '76439': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Edge' },
  '76472': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Edge' },
  '76485': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Edge' },
  '76486': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Edge' },
  '76487': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Edge' },
  '76490': { region: 'san_antonio', utility_company: 'Tri County Electric Coop', area: 'Edge' },
}

export const utilityCompaniesList = {
  all_regions: {
    // San Antonio
    'AEP Texas': { key: 'AEP Texas', value: 'AEP Texas' },
    'Austin Energy': { key: 'Austin Energy', value: 'Austin Energy' },
    'Bandera Electric Cooperative': { key: 'Bandera Electric Cooperative', value: 'Bandera Electric Cooperative' },
    'Bluebonnet Electric Cooperative': { key: 'Bluebonnet Electric Cooperative', value: 'Bluebonnet Electric Cooperative' },
    'Centerpoint Energy': { key: 'Centerpoint Energy', value: 'Centerpoint Energy' },
    'CPS Energy': { key: 'CPS Energy', value: 'CPS Energy' },
    'CTEC': { key: 'CTEC', value: 'CTEC' },
    'Denton Municipal Electric': { key: 'Denton Municipal Electric', value: 'Denton Municipal Electric' },
    'Entergy': { key: 'Entergy', value: 'Entergy' },
    'Fayette Electric Cooperative': { key: 'Fayette Electric Cooperative', value: 'Fayette Electric Cooperative' },
    'Giddings Light & Power System': { key: 'Giddings Light & Power System', value: 'Giddings Light & Power System' },
    'GVEC': { key: 'GVEC', value: 'GVEC' },
    'Karnes Electric Cooperative': { key: 'Karnes Electric Cooperative', value: 'Karnes Electric Cooperative' },
    'Lexington Municipal Electric Department': { key: 'Lexington Municipal Electric Department', value: 'Lexington Municipal Electric Department' },
    'Luling Utilities': { key: 'Luling Utilities', value: 'Luling Utilities' },
    'Medina Electric Cooperative': { key: 'Medina Electric Cooperative', value: 'Medina Electric Cooperative' },
    'Oncor': { key: 'Oncor', value: 'Oncor' },
    'Pedernales Electric Coop': { key: 'Pedernales Electric Coop', value: 'Pedernales Electric Coop' },
    'Smithville Utilities Department': { key: 'Smithville Utilities Department', value: 'Smithville Utilities Department' },
    'TNMP': { key: 'TNMP', value: 'TNMP' },
    'Tri County Electric Coop': { key: 'Tri County Electric Coop', value: 'Tri County Electric Coop' },
    'other': { key: 'other', value: 'Other, please specify' },
  },
  san_antonio: {
    'AEP Texas': { key: 'AEP Texas', value: 'AEP Texas' },
    'Austin Energy': { key: 'Austin Energy', value: 'Austin Energy' },
    'Bandera Electric Cooperative': { key: 'Bandera Electric Cooperative', value: 'Bandera Electric Cooperative' },
    'Bluebonnet Electric Cooperative': { key: 'Bluebonnet Electric Cooperative', value: 'Bluebonnet Electric Cooperative' },
    'Centerpoint Energy': { key: 'Centerpoint Energy', value: 'Centerpoint Energy' },
    'CPS Energy': { key: 'CPS Energy', value: 'CPS Energy' },
    'CTEC': { key: 'CTEC', value: 'CTEC' },
    'Denton Municipal Electric': { key: 'Denton Municipal Electric', value: 'Denton Municipal Electric' },
    'Entergy': { key: 'Entergy', value: 'Entergy' },
    'Fayette Electric Cooperative': { key: 'Fayette Electric Cooperative', value: 'Fayette Electric Cooperative' },
    'Giddings Light & Power System': { key: 'Giddings Light & Power System', value: 'Giddings Light & Power System' },
    'GVEC': { key: 'GVEC', value: 'GVEC' },
    'Karnes Electric Cooperative': { key: 'Karnes Electric Cooperative', value: 'Karnes Electric Cooperative' },
    'Lexington Municipal Electric Department': { key: 'Lexington Municipal Electric Department', value: 'Lexington Municipal Electric Department' },
    'Luling Utilities': { key: 'Luling Utilities', value: 'Luling Utilities' },
    'Medina Electric Cooperative': { key: 'Medina Electric Cooperative', value: 'Medina Electric Cooperative' },
    'Oncor': { key: 'Oncor', value: 'Oncor' },
    'Pedernales Electric Coop': { key: 'Pedernales Electric Coop', value: 'Pedernales Electric Coop' },
    'Smithville Utilities Department': { key: 'Smithville Utilities Department', value: 'Smithville Utilities Department' },
    'TNMP': { key: 'TNMP', value: 'TNMP' },
    'Tri County Electric Coop': { key: 'Tri County Electric Coop', value: 'Tri County Electric Coop' },
    'other': { key: 'other', value: 'Other, please specify' },
  },
}

// ‡

// Config
const formatNumberToDollar = (number: number) => {
  if (typeof number !== 'number') {
    return 'Invalid Input'
  }
  const formattedNumber = number.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  return formattedNumber
}

export const returnPowerwallPricing = (
  numberOfPowerwallsToInstall: string, // number_of_powerwalls_to_install ( 1-10 )
  expandingPowerwall: string, // expanding_powerwall ( yes | no )
  includeBatteriesInOrder: string, // include_batteries_in_order ( include_batteries_in_order | already_purchased_batteries )
  agreementDate: Date,
) => {
  let pricingObject: TsInterface_UnspecifiedObject | null = null
  let agreementTimestamp = new Date(agreementDate).getTime()
  if (
    agreementTimestamp < 1707937200000 // 14 Feb 2024 12:00:00 MST
  ) {
    if (
      numberOfPowerwallsToInstall != null &&
      parseInt(numberOfPowerwallsToInstall) >= 1 &&
      parseInt(numberOfPowerwallsToInstall) <= 10 &&
      expandingPowerwall != null &&
      includeBatteriesInOrder != null
    ) {
      pricingObject = {
        text: {},
        values: {
          TEMP_tax_rate: 0.0825,
          TEMP_tax_credit_rate: 0.3,
        },
      }
      // text.powerwall_text
      if (parseInt(numberOfPowerwallsToInstall) === 1) {
        pricingObject.text.powerwall_text = (
          <>
            {numberOfPowerwallsToInstall} {rLIB('Powerwall')}
          </>
        )
      } else {
        pricingObject.text.powerwall_text = (
          <>
            {numberOfPowerwallsToInstall} {rLIB('Powerwalls')}
          </>
        )
      }
      // text.powerwall_price / values.powerwall
      let powerwallBasePrice = 7500 // Promo through 10/31/2023
      // let powerwallBasePrice = 7300 // 2024 and beyond
      let totalPowerwallPrice = powerwallBasePrice * parseInt(numberOfPowerwallsToInstall)
      if (includeBatteriesInOrder === 'already_purchased_batteries') {
        pricingObject.text.powerwall_price = rLIB('Already Purchased')
        pricingObject.values.powerwall = 0
      } else {
        pricingObject.text.powerwall_price = formatNumberToDollar(totalPowerwallPrice)
        pricingObject.values.powerwall = totalPowerwallPrice
      }
      // text.gateway_price / values.gateway
      if (includeBatteriesInOrder === 'include_batteries_in_order' && expandingPowerwall === 'no') {
        pricingObject.text.gateway_price = formatNumberToDollar(1000)
        pricingObject.values.gateway = 1000
      } else {
        pricingObject.text.gateway_price = rLIB('Already Purchased')
        pricingObject.values.gateway = 0
      }
      // text.accessories_price / values.accessories
      if (includeBatteriesInOrder === 'include_batteries_in_order') {
        pricingObject.text.accessories_price = formatNumberToDollar(200)
        pricingObject.values.accessories = 200
      } else {
        pricingObject.text.accessories_price = rLIB('Already Purchased')
        pricingObject.values.accessories = 0
      }
      // text.installation_price / values.installation
      let installPrice: null | number = null
      if (expandingPowerwall === 'yes') {
        switch (parseInt(numberOfPowerwallsToInstall)) {
          // 2023 Pricing
          case 1:
            installPrice = 3740
            break
          case 2:
            installPrice = 3990
            break // +250
          case 3:
            installPrice = 4240
            break // +250
          case 4:
            installPrice = 4490
            break // +250
          case 5:
            installPrice = 4990
            break // +500
          case 6:
            installPrice = 5490
            break // +500
          case 7:
            installPrice = 5990
            break // +500
          case 8:
            installPrice = 6490
            break // +500
          case 9:
            installPrice = 6990
            break // +500
          case 10:
            installPrice = 7490
            break // +500
        }
      } else {
        switch (parseInt(numberOfPowerwallsToInstall)) {
          // 2023 Pricing
          case 1:
            installPrice = 5690
            break
          case 2:
            installPrice = 6640
            break
          case 3:
            installPrice = 7290
            break
          case 4:
            installPrice = 7840
            break
          case 5:
            installPrice = 8890
            break
          case 6:
            installPrice = 9640
            break // +750
          case 7:
            installPrice = 10390
            break // +750
          case 8:
            installPrice = 11140
            break // +750
          case 9:
            installPrice = 11890
            break // +750
          case 10:
            installPrice = 12640
            break // +750
        }
      }
      if (installPrice != null) {
        pricingObject.text.installation_price = formatNumberToDollar(installPrice as number)
        pricingObject.values.installation = installPrice
      }
    }
  } else {
    if (
      numberOfPowerwallsToInstall != null &&
      parseInt(numberOfPowerwallsToInstall) >= 1 &&
      parseInt(numberOfPowerwallsToInstall) <= 10 &&
      expandingPowerwall != null &&
      includeBatteriesInOrder != null
    ) {
      pricingObject = {
        text: {},
        values: {
          TEMP_tax_rate: 0.0825,
          TEMP_tax_credit_rate: 0.3,
        },
      }
      // text.powerwall_text
      if (parseInt(numberOfPowerwallsToInstall) === 1) {
        pricingObject.text.powerwall_text = (
          <>
            {numberOfPowerwallsToInstall} {rLIB('Powerwall')}
          </>
        )
      } else {
        pricingObject.text.powerwall_text = (
          <>
            {numberOfPowerwallsToInstall} {rLIB('Powerwalls')}
          </>
        )
      }
      // text.powerwall_price / values.powerwall
      // let powerwallBasePrice = 7500 // Promo through 10/31/2023
      let powerwallBasePrice = 7300 // 2024 and beyond
      let totalPowerwallPrice = powerwallBasePrice * parseInt(numberOfPowerwallsToInstall)
      if (includeBatteriesInOrder === 'already_purchased_batteries') {
        pricingObject.text.powerwall_price = rLIB('Already Purchased')
        pricingObject.values.powerwall = 0
      } else {
        pricingObject.text.powerwall_price = formatNumberToDollar(totalPowerwallPrice)
        pricingObject.values.powerwall = totalPowerwallPrice
      }
      // text.gateway_price / values.gateway
      if (includeBatteriesInOrder === 'include_batteries_in_order' && expandingPowerwall === 'no') {
        pricingObject.text.gateway_price = formatNumberToDollar(1000)
        pricingObject.values.gateway = 1000
      } else {
        pricingObject.text.gateway_price = rLIB('Already Purchased')
        pricingObject.values.gateway = 0
      }
      // text.accessories_price / values.accessories
      if (includeBatteriesInOrder === 'include_batteries_in_order') {
        pricingObject.text.accessories_price = formatNumberToDollar(200)
        pricingObject.values.accessories = 200
      } else {
        pricingObject.text.accessories_price = rLIB('Already Purchased')
        pricingObject.values.accessories = 0
      }
      // text.installation_price / values.installation
      let installPrice: null | number = null
      if (expandingPowerwall === 'yes') {
        switch (parseInt(numberOfPowerwallsToInstall)) {
          // 2024 Pricing
          case 1:
            installPrice = 5500
            break
          case 2:
            installPrice = 6500
            break
          case 3:
            installPrice = 7500
            break
          case 4:
            installPrice = 8500
            break
          case 5:
            installPrice = 9500
            break
          case 6:
            installPrice = 10500
            break
          case 7:
            installPrice = 11500
            break
          case 8:
            installPrice = 12500
            break
          case 9:
            installPrice = 13500
            break
          case 10:
            installPrice = 14500
            break
        }
      } else {
        switch (parseInt(numberOfPowerwallsToInstall)) {
          // 2024 Pricing
          case 1:
            installPrice = 7000
            break
          case 2:
            installPrice = 8000
            break
          case 3:
            installPrice = 9000
            break
          case 4:
            installPrice = 10000
            break
          case 5:
            installPrice = 11000
            break
          case 6:
            installPrice = 12000
            break
          case 7:
            installPrice = 13000
            break
          case 8:
            installPrice = 14000
            break
          case 9:
            installPrice = 15000
            break
          case 10:
            installPrice = 16000
            break
        }
      }
      if (installPrice != null) {
        pricingObject.text.installation_price = formatNumberToDollar(installPrice as number)
        pricingObject.values.installation = installPrice
      }
    }
  }
  return pricingObject
}

///////////////////////////////
// Functions
///////////////////////////////

// Support Contact
export const supportEmail = 'pwinstalls@etwenergy.com'
export const supportPhone = '512-200-2576'

// Form
export const formInputs_ContactInfo: TsInterface_FormInputs = {
  header: {
    data_type: 'string',
    key: 'header',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-center tw-mb-4">
          <Typography
            variant="h6"
            sx={{ color: themeVariables.white }}
            className="tw-mt-2"
          >
            {rLIB('Congratulations on choosing Tesla Powerwall!')}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: themeVariables.white }}
            className="tw-mt-2"
          >
            {rLIB(
              "The ETW Energy team is excited to help you take the next step in getting your Powerwall unit installed. Please complete the following form and we'll have a team out to your house at your earliest convenience.",
            )}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  email: {
    key: 'email',
    label: rLIB('Email'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  warning1: {
    data_type: 'string',
    key: 'warning1',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = <></>
      if (formData.valid_email === false) {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB('Please enter a valid email')}
            </Typography>
          </Box>
        )
      }
      return inputJSX
    },
  },
  header2: {
    data_type: 'string',
    key: 'header2',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-center tw-mb-4">
          <Typography
            variant="body1"
            sx={{ color: themeVariables.white }}
            className="tw-mt-2"
          >
            {rLIB('Your email will be used for you to log in to our app. We will never share your email or spam you.')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  first_name: {
    key: 'first_name',
    label: rLIB('First Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  last_name: {
    key: 'last_name',
    label: rLIB('Last Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  name_on_utility_bill: {
    key: 'name_on_utility_bill',
    label: rLIB('Name on Utility Bill (If Different)'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  phone: {
    key: 'phone',
    label: rLIB('Phone'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  best_time_to_contact: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'best_time_to_contact',
    label: rLIB('Best Time To Reach You'),
    required: true,
    options: [
      { key: 'between_9am_and_noon', value: rLIB('Morning (9am to Noon)') },
      { key: 'from_noon_to_5pm', value: rLIB('Afternoon (Noon to 5pm)') },
      { key: 'from_5pm_to_7pm', value: rLIB('Evening (5pm to 7pm)') },
      { key: 'other', value: rLIB('Other, Please Specify') },
    ],
  },
  best_time_to_contact_other: {
    key: 'best_time_to_contact_other',
    label: rLIB('Other Contact Time'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'best_time_to_contact',
        comparator: '==',
        value: 'other',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'best_time_to_contact',
        comparator: '==',
        value: 'other',
        conditions: [],
      },
    },
  },
  best_contact_method: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'best_contact_method',
    label: rLIB('Preferred Contact Method'),
    required: true,
    options: [
      { key: 'prefer_phone', value: rLIB('I prefer phone calls') },
      { key: 'prefer_text_message', value: rLIB('I prefer text messages') },
      { key: 'prefer_email', value: rLIB('I prefer emails') },
      { key: 'email_only_dont_call', value: rLIB("Emails only - Don't Call or Text Me") },
    ],
  },
}

export const formInputs_HomeInfo: TsInterface_FormInputs = {
  install_location: {
    data_type: 'string',
    key: 'install_location',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      // Helper Functions
      const geocodeAddress = (fullAddress: string) => {
        if (fullAddress != null && fullAddress !== '') {
          formInputChange('install_location', fullAddress, true)
          geocodeByAddress(fullAddress)
            .then((res_GCBY) => {
              if (res_GCBY != null && res_GCBY[0] != null && res_GCBY[0]['address_components'] != null) {
                let formattedAddressComponents: TsInterface_UnspecifiedObject = {}
                for (let loopAddressComponentIndex in res_GCBY[0]['address_components']) {
                  let loopAddressComponent = res_GCBY[0]['address_components'][loopAddressComponentIndex]
                  if (
                    loopAddressComponent != null &&
                    loopAddressComponent['long_name'] != null &&
                    loopAddressComponent['types'] != null &&
                    loopAddressComponent['types'][0] != null
                  ) {
                    formattedAddressComponents[loopAddressComponent['types'][0]] = loopAddressComponent['long_name']
                  }
                }
                // Save All Address Components
                formInputChange('address_components', formattedAddressComponents, true)
                // Get Zip Code Out and Check for region and service areas
                if (formattedAddressComponents != null && formattedAddressComponents['postal_code'] != null) {
                  let zipCode = formattedAddressComponents['postal_code']
                  // Check if zip code is in service area
                  formInputChange('location_zip_code', zipCode, true)
                  if (
                    zipCode != null &&
                    formAdditionalData != null &&
                    formAdditionalData['serviceAreaLookup'] != null &&
                    // @ts-expect-error - TODO: reason for error
                    formAdditionalData['serviceAreaLookup'][zipCode] != null
                  ) {
                    let zipCodeDetails = getProp(formAdditionalData['serviceAreaLookup'], zipCode, {})
                    let regionKey = getProp(zipCodeDetails, 'region', null)
                    // Save Zip Code
                    formInputChange('utility_company', getProp(zipCodeDetails, 'utility_company', null), true)
                    formInputChange('region', getProp(zipCodeDetails, 'region', null), true)
                    if (
                      regionKey != null &&
                      // @ts-expect-error - TODO: reason for error
                      utilityCompaniesList[regionKey] != null
                    ) {
                      // @ts-expect-error - TODO: reason for error
                      formInputs.utility_company.options = objectToArray(utilityCompaniesList[regionKey])
                    } else {
                      formInputs.utility_company.options = objectToArray(utilityCompaniesList['all_regions'])
                    }
                  } else {
                    formInputChange('utility_company', null, true)
                    formInputChange('region', null, true)
                    formInputs.utility_company.options = objectToArray(utilityCompaniesList['all_regions'])
                  }
                } else {
                  formInputChange('utility_company', null, true)
                  formInputChange('region', null, true)
                  formInputs.utility_company.options = objectToArray(utilityCompaniesList['all_regions'])
                }
              }
            })
            .catch((rej_GCBY) => {
              console.error(rej_GCBY)
            })
        }
      }
      const handleKeyDown = (event: any) => {
        if (event != null && (event.keyCode === 9 || event.keyCode === 13) && event.target != null && event.target.defaultValue != null) {
          geocodeAddress(event.target.defaultValue)
        }
      }
      // JSX
      let inputJSX = (
        <Box className="tw-mt-4">
          <FormControl fullWidth>
            <PlacesAutocomplete
              value={formData['install_location'] as string}
              onChange={(address) => {
                formInputChange('install_location', address, true)
              }}
              searchOptions={{
                types: ['address'],
                componentRestrictions: { country: 'us' },
              }}
              debounce={200}
              highlightFirstSuggestion
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return (
                  <Autocomplete
                    options={suggestions.map((s: any) => s.description)}
                    freeSolo={true}
                    inputValue={formData['install_location'] as string}
                    onKeyDown={handleKeyDown}
                    onInputChange={(event) => {
                      if (
                        event != null &&
                        event.target != null &&
                        // @ts-expect-error - TODO: reason for error
                        event.target.textContent != null &&
                        // @ts-expect-error - TODO: reason for error
                        event.target.textContent !== ''
                      ) {
                        // @ts-expect-error - TODO: reason for error
                        geocodeAddress(event.target.textContent)
                      }
                    }}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...getInputProps({
                          placeholder: '',
                        })}
                        disabled={formAdditionalData.disabled_all_inputs === true}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        name="search"
                        // InputProps={{
                        // 	autoComplete: 'off',
                        //   }}
                        label={rLIB('Address (where Powerwall will be installed)')}
                        fullWidth
                      />
                    )}
                  />
                )
              }}
            </PlacesAutocomplete>
          </FormControl>
        </Box>
      )
      return inputJSX
    },
  },
  addressInServiceArea: {
    data_type: 'string',
    key: 'header',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = <></>
      if (
        formData.location_zip_code != null &&
        formData.location_zip_code !== '' &&
        formAdditionalData != null &&
        formAdditionalData['serviceAreaLookup'] != null &&
        // @ts-expect-error - TODO: reason for error
        formAdditionalData['serviceAreaLookup'][formData.location_zip_code] != null
      ) {
        // @ts-expect-error - TODO: reason for error
        if (formAdditionalData['serviceAreaLookup'][formData.location_zip_code]['area'] === 'Core') {
          inputJSX = (
            <Box
              className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
              sx={{ border: '1px solid ' + themeVariables.success_wash }}
            >
              <Typography
                variant="body1"
                sx={{ color: themeVariables.success_wash }}
                className=""
              >
                <Icon
                  icon="thumbs-up"
                  className="tw-mr-2"
                />
                <>
                  {rLIB('Zip Code')} {formData.location_zip_code} {rLIB('is within our service area')}
                </>
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: themeVariables.gray_500 }}
                className="tw-mt-2"
              >
                <>{rLIB("You'll be able to schedule your appointments from our app")}</>
              </Typography>
            </Box>
          )
        } else {
          inputJSX = (
            <Box
              className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
              sx={{ border: '1px solid ' + themeVariables.warning_main }}
            >
              <Typography
                variant="body1"
                sx={{ color: themeVariables.warning_main }}
                className=""
              >
                <Icon
                  icon="thumbs-up"
                  className="tw-mr-2"
                />
                <>
                  {rLIB('Zip Code')} {formData.location_zip_code} {rLIB('is at the edge of our service area')}
                </>
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: themeVariables.gray_500 }}
                className="tw-mt-2"
              >
                <>{rLIB("You'll have to talk to our support team to schedule appointments")}</>
              </Typography>
            </Box>
          )
        }
      } else if (
        formData.location_zip_code != null &&
        formData.location_zip_code !== '' &&
        formAdditionalData != null &&
        formAdditionalData['serviceAreaLookup'] != null &&
        // @ts-expect-error - TODO: reason for error
        formAdditionalData['serviceAreaLookup'][formData.location_zip_code] == null
      ) {
        inputJSX = (
          <Box
            className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
            sx={{ border: '1px solid ' + themeVariables.error_main }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className=""
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              <>
                {rLIB('Zip Code')} {formData.location_zip_code} {rLIB('might not be in our service area')}
              </>
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: themeVariables.gray_500 }}
              className="tw-mt-2"
            >
              <>{rLIB("You'll need to contact our support team to check if we can install your Powerwall unit")}</>
            </Typography>
          </Box>
        )
      } else {
        inputJSX = (
          <Box
            className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
            sx={{ border: '1px solid ' + themeVariables.gray_500 }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.gray_500 }}
              className=""
            >
              <Icon
                icon="circle-question"
                className="tw-mr-2"
              />
              {rLIB('Use the address autofill to determine if your address is in one of our service areas')}
            </Typography>
          </Box>
        )
      }
      return inputJSX
    },
  },
  utility_company: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'utility_company',
    label: rLIB('Your Utility Company'),
    required: true,
    options: objectToArray(utilityCompaniesList['all_regions']),
  },
  utility_company_other: {
    key: 'utility_company_other',
    label: rLIB('Your Utility Company'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'utility_company',
        comparator: '==',
        value: 'other',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'utility_company',
        comparator: '==',
        value: 'other',
        conditions: [],
      },
    },
  },
  home_square_footage: {
    data_type: 'number',
    input_type: 'text_number',
    min: 300,
    max: 25000,
    min_error_message: rLIB('Minimum: 300 Square Feet'),
    max_error_message: rLIB('Maximum: 25000 Square Feet'),
    key: 'home_square_footage',
    label: rLIB('Home Square Footage'),
    required: true,
  },
  home_year_built: {
    data_type: 'number',
    input_type: 'text_number',
    min: 1900,
    max: new Date().getFullYear() + 1,
    min_error_message: (
      <>
        {rLIB('Minimum Year')}: {1900}
      </>
    ),
    max_error_message: (
      <>
        {rLIB('Maximum Year')}: {new Date().getFullYear() + 1}
      </>
    ),
    key: 'home_year_built',
    label: rLIB('Year Home Built (Approximate)'),
    required: true,
  },
  is_home_owner: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'is_home_owner',
    label: rLIB('Do you own your own home?'),
    required: true,
    options: [
      { key: 'yes', value: rLIB('Yes') },
      { key: 'no', value: rLIB('No') },
    ],
  },
  warning1: {
    data_type: 'string',
    key: 'warning1',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = <></>
      if (formData.is_home_owner === 'no') {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB('We can only do Powerwall installations if you are the homeowner')}
            </Typography>
          </Box>
        )
      }
      return inputJSX
    },
  },
  home_type: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'home_type',
    label: rLIB('Home Type'),
    required: true,
    options: [
      { key: 'Detached single family home', value: rLIB('Detached single family home') },
      { key: 'Vertically-split duplex', value: rLIB('Vertically-split duplex') },
      { key: 'Townhome or Rowhome', value: rLIB('Townhome or Rowhome') },
      { key: 'Manufactured home', value: rLIB('Manufactured home') },
      { key: 'Mobile home', value: rLIB('Mobile home') },
      { key: 'Apartment', value: rLIB('Apartment') },
      { key: 'Condo', value: rLIB('Condo') },
    ],
  },
  warning2: {
    data_type: 'string',
    key: 'warning2',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = <></>
      if (formData.home_type === 'Manufactured home') {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB("We aren't able to do Powerwall installations for")} {rLIB('manufactured homes')}
            </Typography>
          </Box>
        )
      }
      if (formData.home_type === 'Mobile home') {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB("We aren't able to do Powerwall installations for")} {rLIB('mobile homes')}
            </Typography>
          </Box>
        )
      }
      if (formData.home_type === 'Apartment') {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB("We aren't able to do Powerwall installations for")} {rLIB('apartments')}
            </Typography>
          </Box>
        )
      }
      if (formData.home_type === 'Condo') {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB("We aren't able to do Powerwall installations for")} {rLIB('condos')}
            </Typography>
          </Box>
        )
      }
      return inputJSX
    },
  },
  include_batteries_in_order: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'include_batteries_in_order',
    label: rLIB('Have you already purchased Powerwall units from Tesla or would you like to include the batteries with this order?'),
    required: true,
    options: [
      { key: 'include_batteries_in_order', value: rLIB('Include batteries in my order') },
      { key: 'already_purchased_batteries', value: rLIB("I've already purchased Powerwalls from Tesla") },
    ],
  },
  has_solar: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'has_solar',
    label: rLIB('Do you currently have solar installed?'),
    required: true,
    options: [
      { key: 'yes', value: rLIB('Yes') },
      { key: 'no', value: rLIB('No') },
    ],
  },
  expanding_powerwall: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'expanding_powerwall',
    label: rLIB('Are you expanding a current Powerwall system?'),
    required: true,
    options: [
      { key: 'yes', value: rLIB('Yes') },
      { key: 'no', value: rLIB('No') },
    ],
  },
  notes: {
    key: 'notes',
    label: rLIB('Anything else we should know'),
    input_type: 'text_multiline',
    required: false,
    data_type: 'string',
  },
}

export const formInputs_BackupLoads: TsInterface_FormInputs = {
  number_of_powerwalls_to_install: {
    data_type: 'string',
    key: 'number_of_powerwalls_to_install',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      const returnSX_CustomButton = (amount: string): TsInterface_UnspecifiedObject => {
        let buttonSX: TsInterface_UnspecifiedObject = {}
        if (formData != null && formData.number_of_powerwalls_to_install === amount) {
          buttonSX = {
            border: '1px solid #fff',
            background: '#fff',
            color: themeVariables.black,
          }
        } else {
          buttonSX = {
            'border': '1px solid #fff',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,0.1)',
            },
          }
        }
        return buttonSX
      }

      // disabled={ formAdditionalData.disabled_all_inputs === true }

      // JSX
      let inputJSX = (
        <Box className="tw-mt-4">
          <Typography
            variant="subtitle1"
            className="tw-mb-4 tw-opacity-70"
          >
            {rLIB('How many Powerwall units are you installing?')}
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{ width: { xs: '20%', sm: '10%' } }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('1')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '1', true)
                  }
                }}
              >
                <Box className="tw-inline-block">1</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: { xs: '20%', sm: '10%' } }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('2')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '2', true)
                  }
                }}
              >
                <Box className="tw-inline-block">2</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: { xs: '20%', sm: '10%' } }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('3')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '3', true)
                  }
                }}
              >
                <Box className="tw-inline-block">3</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: { xs: '20%', sm: '10%' } }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                // sx={ returnSX_CustomButton("4+") }
                sx={returnSX_CustomButton('4')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    // formInputChange( "number_of_powerwalls_to_install", "4+", true )
                    formInputChange('number_of_powerwalls_to_install', '4', true)
                  }
                }}
              >
                <Box className="tw-inline-block">4</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: { xs: '20%', sm: '10%' } }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('5')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '5', true)
                  }
                }}
              >
                <Box className="tw-inline-block">5</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: { xs: '20%', sm: '10%' } }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('6')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '6', true)
                  }
                }}
              >
                <Box className="tw-inline-block">6</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: { xs: '20%', sm: '10%' } }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('7')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '7', true)
                  }
                }}
              >
                <Box className="tw-inline-block">7</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: { xs: '20%', sm: '10%' } }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('8')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '8', true)
                  }
                }}
              >
                <Box className="tw-inline-block">8</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: { xs: '20%', sm: '10%' } }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('9')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '9', true)
                  }
                }}
              >
                <Box className="tw-inline-block">9</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: { xs: '20%', sm: '10%' } }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('10')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '10', true)
                  }
                }}
              >
                <Box className="tw-inline-block">10</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )
      return inputJSX
    },
  },
  items_to_backup: {
    data_type: 'string',
    key: 'items_to_backup',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      // Helper Functions
      const rJSX_ItemClarification = (applianceKey: string): JSX.Element => {
        let clarificationJSX: JSX.Element = <></>
        if (
          applianceKey === 'appliance_backup-2nd_air_conditioner' &&
          formData['number_of_powerwalls_to_install'] != null &&
          // @ts-expect-error - TODO: reason for error
          parseInt(formData['number_of_powerwalls_to_install']) < 3 &&
          formData['appliance_backup-2nd_air_conditioner'] === true
        ) {
          clarificationJSX = (
            <Box
              className="tw-inline-block tw-ml-2"
              sx={{ color: themeVariables.error_main }}
            >
              ({rLIB('Requires 3 Powerwalls')})
            </Box>
          )
        } else if (
          applianceKey === 'appliance_backup-water_and_well_pumps' &&
          formData['number_of_powerwalls_to_install'] != null &&
          // @ts-expect-error - TODO: reason for error
          parseInt(formData['number_of_powerwalls_to_install']) < 3 &&
          formData['appliance_backup-water_and_well_pumps'] === true
        ) {
          clarificationJSX = (
            <Box
              className="tw-inline-block tw-ml-2"
              sx={{ color: themeVariables.error_main }}
            >
              ({rLIB('Requires 3 Powerwalls')})
            </Box>
          )
        } else if (
          applianceKey === 'appliance_backup-electric_water_heater' &&
          formData['number_of_powerwalls_to_install'] != null &&
          // @ts-expect-error - TODO: reason for error
          parseInt(formData['number_of_powerwalls_to_install']) < 2 &&
          formData['appliance_backup-electric_water_heater'] === true
        ) {
          clarificationJSX = (
            <Box
              className="tw-inline-block tw-ml-2"
              sx={{ color: themeVariables.error_main }}
            >
              ({rLIB('Requires 2 Powerwalls')})
            </Box>
          )
        }
        return clarificationJSX
      }

      const returnSX_ApplianceCheckbox = (cutoffNumber: number): TsInterface_UnspecifiedObject => {
        let checkboxSX: TsInterface_UnspecifiedObject = {}
        if (formData != null && formData['number_of_powerwalls_to_install'] != null && formData['number_of_powerwalls_to_install'] === '4+') {
          checkboxSX = { color: themeVariables.success_main + ' !important' }
        } else if (
          formData != null &&
          formData['number_of_powerwalls_to_install'] != null &&
          // @ts-expect-error - TODO: reason for error
          cutoffNumber <= parseInt(formData['number_of_powerwalls_to_install'])
        ) {
          checkboxSX = { color: themeVariables.success_main + ' !important' }
        } else if (
          formData != null &&
          formData['number_of_powerwalls_to_install'] != null &&
          // @ts-expect-error - TODO: reason for error
          cutoffNumber > parseInt(formData['number_of_powerwalls_to_install'])
        ) {
          checkboxSX = { color: themeVariables.error_main + ' !important' }
        } else {
          checkboxSX = { color: themeVariables.gray_500 + ' !important' }
        }
        return checkboxSX
      }

      const returnSX_ApplianceRadio = (cutoffNumber: number): TsInterface_UnspecifiedObject => {
        let radioSX: TsInterface_UnspecifiedObject = {}
        if (formData['appliance_backup-lights_and_plugs'] === true || formData['appliance_backup-microwave_and_fridge'] === true) {
          if (formData != null && formData['number_of_powerwalls_to_install'] != null && formData['number_of_powerwalls_to_install'] === '4+') {
            radioSX = { color: themeVariables.success_main + ' !important' }
          } else if (
            formData != null &&
            formData['number_of_powerwalls_to_install'] != null &&
            // @ts-expect-error - TODO: reason for error
            cutoffNumber <= parseInt(formData['number_of_powerwalls_to_install'])
          ) {
            radioSX = { color: themeVariables.success_main + ' !important' }
          } else if (
            formData != null &&
            formData['number_of_powerwalls_to_install'] != null &&
            // @ts-expect-error - TODO: reason for error
            cutoffNumber > parseInt(formData['number_of_powerwalls_to_install'])
          ) {
            radioSX = { color: themeVariables.error_main + ' !important' }
          } else {
            radioSX = { color: themeVariables.gray_500 + ' !important' }
          }
        } else {
          radioSX = { color: themeVariables.gray_500 + ' !important', opacity: '0.3' }
        }
        return radioSX
      }

      const rJSX_CircuitsInput = (): JSX.Element => {
        let circuitsInputJSX: JSX.Element = <></>
        let warningJSX = <></>
        let disableInput = false
        if (formData['appliance_backup-lights_and_plugs'] === true || formData['appliance_backup-microwave_and_fridge'] === true) {
          if (
            formData['appliance_backup-number_of_circuits'] === '31+' &&
            (formData['number_of_powerwalls_to_install'] === 3 ||
              formData['number_of_powerwalls_to_install'] === 2 ||
              formData['number_of_powerwalls_to_install'] === 1)
          ) {
            warningJSX = (
              <Box
                className=""
                sx={{ color: themeVariables.error_main }}
              >
                ({rLIB('Requires 4+ Powerwalls')})
              </Box>
            )
          } else if (
            formData['appliance_backup-number_of_circuits'] === '21_to_30' &&
            (formData['number_of_powerwalls_to_install'] === 2 || formData['number_of_powerwalls_to_install'] === 1)
          ) {
            warningJSX = (
              <Box
                className=""
                sx={{ color: themeVariables.error_main }}
              >
                ({rLIB('Requires 3 Powerwalls')})
              </Box>
            )
          } else if (formData['appliance_backup-number_of_circuits'] === '11_to_20' && formData['number_of_powerwalls_to_install'] === 1) {
            warningJSX = (
              <Box
                className=""
                sx={{ color: themeVariables.error_main }}
              >
                ({rLIB('Requires 2 Powerwalls')})
              </Box>
            )
          }
        } else {
          disableInput = true
        }
        // JSX
        circuitsInputJSX = (
          <Box className="tw-ml-12">
            <Typography
              variant="subtitle1"
              className="tw-m-0"
            >
              <Box className="tw-opacity-50 tw-inline-block">{rLIB('Number of circuits for lights, plugs, microwave, & fridge')}</Box>
              <Box
                className="tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2 tw-inline-block"
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formHooks.uc_setUserInterface_CustomDialogDisplay({
                      display: true,
                      dialog: {
                        dialog_jsx: (
                          <Dialog
                            className="bp_dialog_lg_width"
                            keepMounted
                            onClose={() => {
                              formHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                            }}
                            open={true}
                          >
                            <DialogContent sx={{ padding: '0px' }}>
                              <Stack
                                direction="row"
                                className="tw-justify-between tw-pt-4 tw-pb-2 tw-px-4"
                              >
                                <Typography
                                  variant="h5"
                                  className=""
                                >
                                  {rLIB('What is a Circuit?')}
                                </Typography>
                                <Box
                                  className="tw-inline-block tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100"
                                  onClick={() => {
                                    formHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                                  }}
                                >
                                  <Icon icon="circle-xmark" />
                                </Box>
                              </Stack>
                              <Divider />
                              <Box className="tw-text-left tw-p-4">
                                <Typography
                                  variant="subtitle1"
                                  className="tw-mb-4"
                                >
                                  {rLIB(
                                    'A home wiring electrical circuit is a closed pathway that allows electricity to flow from a power source to various devices and fixtures in a house. It consists of conductive wiring, switches, outlets, and circuit protection mechanisms such as breakers or fuses to ensure safe and efficient distribution of electrical power.',
                                  )}
                                </Typography>
                                <Box className="tw-text-center">
                                  <Box
                                    className="tw-m-auto"
                                    component="img"
                                    sx={{ maxWidth: 450 }}
                                    src={panelSrc}
                                  />
                                </Box>
                                <Typography variant="subtitle1">
                                  {rLIB(
                                    'You can check your breaker box to see how many circuits you have and want to have backed up. They should be labeled with the appliance or part of your house that they are providing power to. If you have any questions about your circuits, our team can take a look and answer your questions when they come out to do a preliminary audit.',
                                  )}
                                </Typography>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        ),
                        settings: {
                          max_width: 'lg',
                        },
                      },
                    })
                  }
                }}
              >
                <Icon icon="circle-question" />
              </Box>
            </Typography>
            <FormControl>
              <RadioGroup
                row
                onChange={(event) => {
                  formInputChange('appliance_backup-number_of_circuits', event.target.value, true)
                }}
              >
                <FormControlLabel
                  disabled={formAdditionalData.disabled_all_inputs === true}
                  value="0_to_10"
                  control={
                    <Radio
                      disabled={disableInput}
                      sx={returnSX_ApplianceRadio(1)}
                    />
                  }
                  label={rLIB('0 to 10')}
                />
                <FormControlLabel
                  disabled={formAdditionalData.disabled_all_inputs === true}
                  value="11_to_20"
                  control={
                    <Radio
                      disabled={disableInput}
                      sx={returnSX_ApplianceRadio(2)}
                    />
                  }
                  label={rLIB('11 to 20')}
                />
                <FormControlLabel
                  disabled={formAdditionalData.disabled_all_inputs === true}
                  value="21_to_30"
                  control={
                    <Radio
                      disabled={disableInput}
                      sx={returnSX_ApplianceRadio(3)}
                    />
                  }
                  label={rLIB('21 to 30')}
                />
                <FormControlLabel
                  disabled={formAdditionalData.disabled_all_inputs === true}
                  value="31+"
                  control={
                    <Radio
                      disabled={disableInput}
                      sx={returnSX_ApplianceRadio(4)}
                    />
                  }
                  label={rLIB('More than 30')}
                />
              </RadioGroup>
            </FormControl>
            {warningJSX}
          </Box>
        )
        return circuitsInputJSX
      }

      // JSX
      let inputJSX = (
        <Box className="tw-mt-4">
          <Typography
            variant="subtitle1"
            className="tw-mb-4 tw-opacity-70"
          >
            {rLIB('What appliances do you want to backup?')}
          </Typography>
          <Box className="tw-pl-8">
            <Typography
              variant="body1"
              className=""
            >
              {rLIB('Small Appliances (120V)')}
            </Typography>
            <Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-lights_and_plugs'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-lights_and_plugs', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Lights & Plugs')}
                      {rJSX_ItemClarification('appliance_backup-lights_and_plugs')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-microwave_and_fridge'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-microwave_and_fridge', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Microwave & Fridge')}
                      {rJSX_ItemClarification('appliance_backup-microwave_and_fridge')}
                    </Box>
                  }
                />
              </Box>
              <Box>{rJSX_CircuitsInput()}</Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-gas_appliances'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-gas_appliances', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Gas Appliances')}
                      {rJSX_ItemClarification('appliance_backup-gas_appliances')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-washer'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-washer', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Washer')}
                      {rJSX_ItemClarification('appliance_backup-washer')}
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Typography
              variant="body1"
              className=""
            >
              {rLIB('Large Appliances (240V)')}
            </Typography>
            <Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-1st_air_conditioner'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-1st_air_conditioner', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Air Conditioner')}
                      {rJSX_ItemClarification('appliance_backup-1st_air_conditioner')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(3)}
                      checked={(formData['appliance_backup-2nd_air_conditioner'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-2nd_air_conditioner', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('2nd Air Conditioner')}
                      {rJSX_ItemClarification('appliance_backup-2nd_air_conditioner')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-electric_range_and_oven'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-electric_range_and_oven', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Electric Range & Oven')}
                      {rJSX_ItemClarification('appliance_backup-electric_range_and_oven')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(3)}
                      checked={(formData['appliance_backup-water_and_well_pumps'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-water_and_well_pumps', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Water & Well Pumps')}
                      {rJSX_ItemClarification('appliance_backup-water_and_well_pumps')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-tesla_ev_charger'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-tesla_ev_charger', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Tesla EV Charger')}
                      {rJSX_ItemClarification('appliance_backup-tesla_ev_charger')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-electric_dryer'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-electric_dryer', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Electric Dryer')}
                      {rJSX_ItemClarification('appliance_backup-electric_dryer')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(2)}
                      checked={(formData['appliance_backup-electric_water_heater'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-electric_water_heater', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Electric Water Heater')}
                      {rJSX_ItemClarification('appliance_backup-electric_water_heater')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-dishwasher'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-dishwasher', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Dishwasher')}
                      {rJSX_ItemClarification('appliance_backup-dishwasher')}
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Typography
              variant="body1"
              className="tw-mt-2 tw-mb-6 tw-opacity-50"
            >
              *{rLIB('Backup duration when powering appliances is based on your energy consumption during an outage')}
            </Typography>
          </Box>
        </Box>
      )
      return inputJSX
    },
  },
  include_batteries_in_order: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'include_batteries_in_order',
    label: rLIB('Have you already purchased Powerwall units from Tesla or would you like to include the batteries with this order?'),
    required: true,
    options: [
      { key: 'include_batteries_in_order', value: rLIB('Include batteries in my order') },
      { key: 'already_purchased_batteries', value: rLIB("I've already purchased Powerwalls from Tesla") },
    ],
  },
  upload_breaker_image: {
    data_type: 'string',
    key: 'items_to_backup',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      // Helper functions
      const readAsDataURL = (file: any) => {
        return new Promise((resolve, reject) => {
          const fr = new FileReader()
          fr.onerror = reject
          fr.onload = () => {
            resolve(fr.result)
          }
          fr.readAsDataURL(file)
        })
      }

      const fileOnSelect = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
        return new Promise((resolve, reject) => {
          if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
            let promiseArray: TsType_UnknownPromise[] = []
            let files = event.target.files
            let readFiles: TsInterface_UnspecifiedObject = {}
            let file = files[0]
            if (file != null && typeof file === 'object') {
              promiseArray.push(
                readAsDataURL(file)
                  .then((res_RADU) => {
                    readFiles[0] = {
                      file_name: file.name,
                      file: file,
                      data_url: res_RADU,
                    }
                  })
                  .catch((rej_RADU) => {
                    // Nothing
                  }),
              )
            }
            Promise.all(promiseArray).finally(() => {
              let clientKey = getProp(formAdditionalData, 'clientKey', null)
              let userKey = getProp(formAdditionalData, 'userKey', null)
              if (clientKey != null && userKey != null) {
                if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                  setTimeout(() => {
                    // @ts-expect-error - TODO: reason for error
                    formAdditionalData.us_setShowImage(false)
                    formHooks.ur_forceRerender()
                  }, 1)
                }
                StorageUploadFile(StorageRef_TeslaPowerwallOrderPhotos(clientKey, userKey, readFiles[0].file_name), readFiles[0].file, {
                  clientKey: clientKey,
                  userKey: userKey,
                })
                  .then((res_SUF: any) => {
                    // files[ fileIndex ]["storage_url"] = getProp(res_SUF, "url", null)
                    let updateObject = {
                      breaker_box_photo_url: getProp(res_SUF, 'url', null),
                    }
                    DatabaseSetMergeDocument(DatabaseRef_PowerwallOrder_Document(clientKey, userKey), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                        if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                          setTimeout(() => {
                            // @ts-expect-error - TODO: reason for error
                            formAdditionalData.us_setShowImage(true)
                            formHooks.ur_forceRerender()
                          }, 1)
                        }
                        formHooks.ur_forceRerender()
                      })
                      .catch((rej_DSMD) => {
                        if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                          setTimeout(() => {
                            // @ts-expect-error - TODO: reason for error
                            formAdditionalData.us_setShowImage(true)
                            formHooks.ur_forceRerender()
                          }, 1)
                        }
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_SUF) => {
                    if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                      setTimeout(() => {
                        // @ts-expect-error - TODO: reason for error
                        formAdditionalData.us_setShowImage(true)
                        formHooks.ur_forceRerender()
                      }, 1)
                    }
                    console.error(rej_SUF)
                    reject(rej_SUF)
                  })
              } else {
                reject({
                  success: false,
                  error: {
                    message: rLIB('Failed to upload file'),
                    details: rLIB('Missing Required Keys'),
                    code: 'ER-D-PF-FOS-01',
                  },
                })
              }
            })
          } else {
            reject({
              success: false,
              error: {
                message: rLIB('Failed to upload file'),
                details: rLIB('Invalid Document Selection'),
                code: 'ER-D-PF-FOS-02',
              },
            })
          }
        })
      }
      // JSX
      let photoUploadJSX = <></>
      if (formAdditionalData.disabled_all_inputs !== true) {
        if (
          formAdditionalData != null &&
          formAdditionalData.powerwallOrderData != null &&
          // @ts-expect-error - TODO: reason for error
          formAdditionalData.powerwallOrderData['breaker_box_photo_url'] != null
        ) {
          let reuploadButtonJSX = (
            <FileUploadButton
              multiple={false}
              accept="image/*"
              onChange={fileOnSelect}
              button={{
                text: rLIB('Upload a replacement photo') as JSX.Element,
                icon: (
                  <Icon
                    icon="cloud-arrow-up"
                    className="tw-mr-2"
                  ></Icon>
                ),
                color: 'primary',
                className: '',
                variant: 'outlined',
                disabled: false,
              }}
              additionalFileUploadParams={{}}
            />
          )
          if (formAdditionalData.showImage === true) {
            photoUploadJSX = (
              <Box>
                <Box className="tw-pl-8 tw-mb-4">{reuploadButtonJSX}</Box>
                <Box className="tw-text-center tw-pl-8">
                  <Box
                    className="tw-m-auto"
                    component="img"
                    sx={{ width: '100%', maxWidth: 1000 }}
                    // @ts-expect-error - TODO: reason for error
                    src={formAdditionalData.powerwallOrderData.breaker_box_photo_url}
                  />
                </Box>
              </Box>
            )
          } else {
            photoUploadJSX = (
              <Box>
                <Box className="tw-pl-8 tw-mb-4">{reuploadButtonJSX}</Box>
              </Box>
            )
          }
        } else {
          photoUploadJSX = (
            <Box className="tw-pl-8">
              <FileUploadButton
                multiple={false}
                accept="image/*"
                onChange={fileOnSelect}
                button={{
                  text: rLIB('Upload Photo') as JSX.Element,
                  icon: (
                    <Icon
                      icon="cloud-arrow-up"
                      className="tw-mr-2"
                    ></Icon>
                  ),
                  color: 'primary',
                  className: '',
                  variant: 'outlined',
                  disabled: false,
                }}
                additionalFileUploadParams={{}}
              />
            </Box>
          )
        }
      } else {
        photoUploadJSX = (
          <Box className="tw-text-center tw-pl-8">
            <Box
              className="tw-m-auto"
              component="img"
              sx={{ width: '100%', maxWidth: 1000 }}
              // @ts-expect-error - TODO: reason for error
              src={formData.breaker_box_photo_url}
            />
          </Box>
        )
      }
      let inputJSX = (
        <Box className="tw-mt-4">
          <Box>
            <Typography
              variant="subtitle1"
              className="tw-mb-4 tw-opacity-70 tw-inline-block"
            >
              {rLIB('Optional: Upload a photo of your breaker box')}
            </Typography>
            <Box
              className="tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2 tw-inline-block"
              onClick={() => {
                if (formAdditionalData.disabled_all_inputs !== true) {
                  formHooks.uc_setUserInterface_CustomDialogDisplay({
                    display: true,
                    dialog: {
                      dialog_jsx: (
                        <Dialog
                          className="bp_dialog_lg_width"
                          keepMounted
                          onClose={() => {
                            formHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                          }}
                          open={true}
                        >
                          <DialogContent sx={{ padding: '0px' }}>
                            <Stack
                              direction="row"
                              className="tw-justify-between tw-pt-4 tw-pb-2 tw-px-4"
                            >
                              <Typography
                                variant="h5"
                                className=""
                              >
                                {rLIB('Example Breaker Box')}
                              </Typography>
                              <Box
                                className="tw-inline-block tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100"
                                onClick={() => {
                                  formHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                                }}
                              >
                                <Icon icon="circle-xmark" />
                              </Box>
                            </Stack>
                            <Divider />
                            <Box className="tw-text-left tw-p-4">
                              <Box className="tw-text-center">
                                <Box
                                  className="tw-m-auto"
                                  component="img"
                                  sx={{ maxWidth: 450 }}
                                  src={panelSrc}
                                />
                              </Box>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      ),
                      settings: {
                        max_width: 'lg',
                      },
                    },
                  })
                }
              }}
            >
              <Icon icon="circle-question" />
            </Box>
          </Box>
          {photoUploadJSX}
        </Box>
      )
      return inputJSX
    },
  },
}

export const formInputs_DisabledContactInfo: TsInterface_FormInputs = {
  header: {
    data_type: 'string',
    key: 'header',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-center tw-mb-4">
          <Typography
            variant="h6"
            sx={{ color: themeVariables.white }}
            className="tw-mt-2"
          >
            {rLIB('Congratulations on choosing Tesla Powerwall!')}
          </Typography>
          <Typography
            variant="body1"
            sx={{ color: themeVariables.white }}
            className="tw-mt-2"
          >
            {rLIB(
              "The ETW Energy team is excited to help you take the next step in getting your Powerwall unit installed. Please complete the following form and we'll have a team out to your house at your earliest convenience.",
            )}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  email: {
    key: 'email',
    label: rLIB('Email'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  warning1: {
    data_type: 'string',
    key: 'warning1',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = <></>
      if (formData.valid_email === false) {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB('Please enter a valid email')}
            </Typography>
          </Box>
        )
      }
      return inputJSX
    },
  },
  header2: {
    data_type: 'string',
    key: 'header2',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = (
        <Box className="tw-text-center tw-mb-4">
          <Typography
            variant="body1"
            sx={{ color: themeVariables.white }}
            className="tw-mt-2"
          >
            {rLIB('Your email will be used for you to log in to our app. We will never share your email or spam you.')}
          </Typography>
        </Box>
      )
      return inputJSX
    },
  },
  first_name: {
    key: 'first_name',
    label: rLIB('First Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  last_name: {
    key: 'last_name',
    label: rLIB('Last Name'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  name_on_utility_bill: {
    key: 'name_on_utility_bill',
    label: rLIB('Name on Utility Bill (If Different)'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
  },
  phone: {
    key: 'phone',
    label: rLIB('Phone'),
    input_type: 'text_basic',
    required: true,
    data_type: 'string',
  },
  best_time_to_contact: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'best_time_to_contact',
    label: rLIB('Best Time To Reach You'),
    required: true,
    options: [
      { key: 'between_9am_and_noon', value: rLIB('Morning (9am to Noon)') },
      { key: 'from_noon_to_5pm', value: rLIB('Afternoon (Noon to 5pm)') },
      { key: 'from_5pm_to_7pm', value: rLIB('Evening (5pm to 7pm)') },
      { key: 'other', value: rLIB('Other, Please Specify') },
    ],
  },
  best_time_to_contact_other: {
    key: 'best_time_to_contact_other',
    label: rLIB('Other Contact Time'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'best_time_to_contact',
        comparator: '==',
        value: 'other',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'best_time_to_contact',
        comparator: '==',
        value: 'other',
        conditions: [],
      },
    },
  },
  best_contact_method: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'best_contact_method',
    label: rLIB('Preferred Contact Method'),
    required: true,
    options: [
      { key: 'prefer_phone', value: rLIB('I prefer phone calls') },
      { key: 'prefer_text_message', value: rLIB('I prefer text messages') },
      { key: 'prefer_email', value: rLIB('I prefer emails') },
      { key: 'email_only_dont_call', value: rLIB("Emails only - Don't Call or Text Me") },
    ],
  },
}

export const formInputs_DisabledHomeInfo: TsInterface_FormInputs = {
  install_location: {
    data_type: 'string',
    key: 'install_location',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      // Helper Functions
      const geocodeAddress = (fullAddress: string) => {
        if (fullAddress != null && fullAddress !== '') {
          formInputChange('install_location', fullAddress, true)
          geocodeByAddress(fullAddress)
            .then((res_GCBY) => {
              if (res_GCBY != null && res_GCBY[0] != null && res_GCBY[0]['address_components'] != null) {
                let formattedAddressComponents: TsInterface_UnspecifiedObject = {}
                for (let loopAddressComponentIndex in res_GCBY[0]['address_components']) {
                  let loopAddressComponent = res_GCBY[0]['address_components'][loopAddressComponentIndex]
                  if (
                    loopAddressComponent != null &&
                    loopAddressComponent['long_name'] != null &&
                    loopAddressComponent['types'] != null &&
                    loopAddressComponent['types'][0] != null
                  ) {
                    formattedAddressComponents[loopAddressComponent['types'][0]] = loopAddressComponent['long_name']
                  }
                }
                // Save All Address Components
                formInputChange('address_components', formattedAddressComponents, true)
                // Get Zip Code Out and Check for region and service areas
                if (formattedAddressComponents != null && formattedAddressComponents['postal_code'] != null) {
                  let zipCode = formattedAddressComponents['postal_code']
                  // Check if zip code is in service area
                  formInputChange('location_zip_code', zipCode, true)
                  if (
                    zipCode != null &&
                    formAdditionalData != null &&
                    formAdditionalData['serviceAreaLookup'] != null &&
                    // @ts-expect-error - TODO: reason for error
                    formAdditionalData['serviceAreaLookup'][zipCode] != null
                  ) {
                    let zipCodeDetails = getProp(formAdditionalData['serviceAreaLookup'], zipCode, {})
                    let regionKey = getProp(zipCodeDetails, 'region', null)
                    // Save Zip Code
                    formInputChange('utility_company', getProp(zipCodeDetails, 'utility_company', null), true)
                    formInputChange('region', getProp(zipCodeDetails, 'region', null), true)
                    if (
                      regionKey != null &&
                      // @ts-expect-error - TODO: reason for error
                      utilityCompaniesList[regionKey] != null
                    ) {
                      // @ts-expect-error - TODO: reason for error
                      formInputs.utility_company.options = objectToArray(utilityCompaniesList[regionKey])
                    } else {
                      formInputs.utility_company.options = objectToArray(utilityCompaniesList['all_regions'])
                    }
                  } else {
                    formInputChange('utility_company', null, true)
                    formInputChange('region', null, true)
                    formInputs.utility_company.options = objectToArray(utilityCompaniesList['all_regions'])
                  }
                } else {
                  formInputChange('utility_company', null, true)
                  formInputChange('region', null, true)
                  formInputs.utility_company.options = objectToArray(utilityCompaniesList['all_regions'])
                }
              }
            })
            .catch((rej_GCBY) => {
              console.error(rej_GCBY)
            })
        }
      }
      const handleKeyDown = (event: any) => {
        if (event != null && (event.keyCode === 9 || event.keyCode === 13) && event.target != null && event.target.defaultValue != null) {
          geocodeAddress(event.target.defaultValue)
        }
      }
      // JSX
      let inputJSX = (
        <Box className="tw-mt-4">
          <FormControl fullWidth>
            <PlacesAutocomplete
              value={formData['install_location'] as string}
              onChange={(address) => {
                formInputChange('install_location', address, true)
              }}
              searchOptions={{
                types: ['address'],
                componentRestrictions: { country: 'us' },
              }}
              debounce={200}
              highlightFirstSuggestion
            >
              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                return (
                  <Autocomplete
                    options={suggestions.map((s: any) => s.description)}
                    freeSolo={true}
                    inputValue={formData['install_location'] as string}
                    onKeyDown={handleKeyDown}
                    onInputChange={(event) => {
                      if (
                        event != null &&
                        event.target != null &&
                        // @ts-expect-error - TODO: reason for error
                        event.target.textContent != null &&
                        // @ts-expect-error - TODO: reason for error
                        event.target.textContent !== ''
                      ) {
                        // @ts-expect-error - TODO: reason for error
                        geocodeAddress(event.target.textContent)
                      }
                    }}
                    disabled={true}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...getInputProps({
                          placeholder: '',
                        })}
                        disabled={formAdditionalData.disabled_all_inputs === true}
                        autoComplete="off"
                        InputLabelProps={{ shrink: true }}
                        name="search"
                        // InputProps={{
                        // 	autoComplete: 'off',
                        //   }}
                        label={rLIB('Address (where Powerwall will be installed)')}
                        fullWidth
                      />
                    )}
                  />
                )
              }}
            </PlacesAutocomplete>
          </FormControl>
        </Box>
      )
      return inputJSX
    },
  },
  addressInServiceArea: {
    data_type: 'string',
    key: 'header',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = <></>
      if (
        formData.location_zip_code != null &&
        formData.location_zip_code !== '' &&
        formAdditionalData != null &&
        formAdditionalData['serviceAreaLookup'] != null &&
        // @ts-expect-error - TODO: reason for error
        formAdditionalData['serviceAreaLookup'][formData.location_zip_code] != null
      ) {
        // @ts-expect-error - TODO: reason for error
        if (formAdditionalData['serviceAreaLookup'][formData.location_zip_code]['area'] === 'Core') {
          inputJSX = (
            <Box
              className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
              sx={{ border: '1px solid ' + themeVariables.success_wash }}
            >
              <Typography
                variant="body1"
                sx={{ color: themeVariables.success_wash }}
                className=""
              >
                <Icon
                  icon="thumbs-up"
                  className="tw-mr-2"
                />
                <>
                  {rLIB('Zip Code')} {formData.location_zip_code} {rLIB('is within our service area')}
                </>
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: themeVariables.gray_500 }}
                className="tw-mt-2"
              >
                <>{rLIB("You'll be able to schedule your appointments from our app")}</>
              </Typography>
            </Box>
          )
        } else {
          inputJSX = (
            <Box
              className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
              sx={{ border: '1px solid ' + themeVariables.warning_main }}
            >
              <Typography
                variant="body1"
                sx={{ color: themeVariables.warning_main }}
                className=""
              >
                <Icon
                  icon="thumbs-up"
                  className="tw-mr-2"
                />
                <>
                  {rLIB('Zip Code')} {formData.location_zip_code} {rLIB('is at the edge of our service area')}
                </>
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: themeVariables.gray_500 }}
                className="tw-mt-2"
              >
                <>{rLIB("You'll have to talk to our support team to schedule appointments")}</>
              </Typography>
            </Box>
          )
        }
      } else if (
        formData.location_zip_code != null &&
        formData.location_zip_code !== '' &&
        formAdditionalData != null &&
        formAdditionalData['serviceAreaLookup'] != null &&
        // @ts-expect-error - TODO: reason for error
        formAdditionalData['serviceAreaLookup'][formData.location_zip_code] == null
      ) {
        inputJSX = (
          <Box
            className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
            sx={{ border: '1px solid ' + themeVariables.error_main }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className=""
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
              <>
                {rLIB('Zip Code')} {formData.location_zip_code} {rLIB('might not be in our service area')}
              </>
            </Typography>
            <Typography
              variant="body1"
              sx={{ color: themeVariables.gray_500 }}
              className="tw-mt-2"
            >
              <>{rLIB("You'll need to contact our support team to check if we can install your Powerwall unit")}</>
            </Typography>
          </Box>
        )
      } else {
        inputJSX = (
          <Box
            className="tw-text-center tw-p-4 tw-mb-2 tw-mt-4 tw-rounded"
            sx={{ border: '1px solid ' + themeVariables.gray_500 }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.gray_500 }}
              className=""
            >
              <Icon
                icon="circle-question"
                className="tw-mr-2"
              />
              {rLIB('Use the address autofill to determine if your address is in one of our service areas')}
            </Typography>
          </Box>
        )
      }
      return inputJSX
    },
  },
  utility_company: {
    data_type: 'string',
    input_type: 'multiple_choice_select',
    key: 'utility_company',
    label: rLIB('Your Utility Company'),
    required: true,
    options: objectToArray(utilityCompaniesList['all_regions']),
  },
  utility_company_other: {
    key: 'utility_company_other',
    label: rLIB('Your Utility Company'),
    input_type: 'text_basic',
    required: false,
    data_type: 'string',
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'utility_company',
        comparator: '==',
        value: 'other',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'utility_company',
        comparator: '==',
        value: 'other',
        conditions: [],
      },
    },
  },
  home_square_footage: {
    data_type: 'number',
    input_type: 'text_number',
    min: 300,
    max: 25000,
    min_error_message: rLIB('Minimum: 300 Square Feet'),
    max_error_message: rLIB('Maximum: 25000 Square Feet'),
    key: 'home_square_footage',
    label: rLIB('Home Square Footage'),
    required: true,
  },
  home_year_built: {
    data_type: 'number',
    input_type: 'text_number',
    min: 1900,
    max: new Date().getFullYear() + 1,
    min_error_message: (
      <>
        {rLIB('Minimum Year')}: {1900}
      </>
    ),
    max_error_message: (
      <>
        {rLIB('Maximum Year')}: {new Date().getFullYear() + 1}
      </>
    ),
    key: 'home_year_built',
    label: rLIB('Year Home Built (Approximate)'),
    required: true,
  },
  is_home_owner: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'is_home_owner',
    label: rLIB('Do you own your own home?'),
    required: true,
    options: [
      { key: 'yes', value: rLIB('Yes') },
      { key: 'no', value: rLIB('No') },
    ],
  },
  warning1: {
    data_type: 'string',
    key: 'warning1',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = <></>
      if (formData.is_home_owner === 'no') {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB('We can only do Powerwall installations if you are the homeowner')}
            </Typography>
          </Box>
        )
      }
      return inputJSX
    },
  },
  home_type: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'home_type',
    label: rLIB('Home Type'),
    required: true,
    options: [
      { key: 'Detached single family home', value: rLIB('Detached single family home') },
      { key: 'Vertically-split duplex', value: rLIB('Vertically-split duplex') },
      { key: 'Townhome or Rowhome', value: rLIB('Townhome or Rowhome') },
      { key: 'Manufactured home', value: rLIB('Manufactured home') },
      { key: 'Mobile home', value: rLIB('Mobile home') },
      { key: 'Apartment', value: rLIB('Apartment') },
      { key: 'Condo', value: rLIB('Condo') },
    ],
  },
  warning2: {
    data_type: 'string',
    key: 'warning2',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      let inputJSX = <></>
      if (formData.home_type === 'Manufactured home') {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB("We aren't able to do Powerwall installations for")} {rLIB('manufactured homes')}
            </Typography>
          </Box>
        )
      }
      if (formData.home_type === 'Mobile home') {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB("We aren't able to do Powerwall installations for")} {rLIB('mobile homes')}
            </Typography>
          </Box>
        )
      }
      if (formData.home_type === 'Apartment') {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB("We aren't able to do Powerwall installations for")} {rLIB('apartments')}
            </Typography>
          </Box>
        )
      }
      if (formData.home_type === 'Condo') {
        inputJSX = (
          <Box
            className="tw-text-left tw-mb-4"
            sx={{ marginTop: '-10px' }}
          >
            <Typography
              variant="body1"
              sx={{ color: themeVariables.error_main }}
              className="tw-mt-2"
            >
              {rLIB("We aren't able to do Powerwall installations for")} {rLIB('condos')}
            </Typography>
          </Box>
        )
      }
      return inputJSX
    },
  },
  include_batteries_in_order: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'include_batteries_in_order',
    label: rLIB('Have you already purchased Powerwall units from Tesla or would you like to include the batteries with this order?'),
    required: true,
    options: [
      { key: 'include_batteries_in_order', value: rLIB('Include batteries in my order') },
      { key: 'already_purchased_batteries', value: rLIB("I've already purchased Powerwalls from Tesla") },
    ],
  },
  has_solar: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'has_solar',
    label: rLIB('Do you currently have solar installed?'),
    required: true,
    options: [
      { key: 'yes', value: rLIB('Yes') },
      { key: 'no', value: rLIB('No') },
    ],
  },
  expanding_powerwall: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'expanding_powerwall',
    label: rLIB('Are you expanding a current Powerwall system?'),
    required: true,
    options: [
      { key: 'yes', value: rLIB('Yes') },
      { key: 'no', value: rLIB('No') },
    ],
  },
  notes: {
    key: 'notes',
    label: rLIB('Anything else we should know'),
    input_type: 'text_multiline',
    required: false,
    data_type: 'string',
  },
}

export const formInputs_DisabledBackupLoads: TsInterface_FormInputs = {
  number_of_powerwalls_to_install: {
    data_type: 'string',
    key: 'number_of_powerwalls_to_install',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      const returnSX_CustomButton = (amount: string): TsInterface_UnspecifiedObject => {
        let buttonSX: TsInterface_UnspecifiedObject = {}
        if (formData != null && formData.number_of_powerwalls_to_install === amount) {
          buttonSX = {
            border: '1px solid #fff',
            background: '#fff',
            color: themeVariables.black,
          }
        } else {
          buttonSX = {
            'border': '1px solid #fff',
            '&:hover': {
              backgroundColor: 'rgba(255,255,255,0.1)',
            },
          }
        }
        return buttonSX
      }

      // disabled={ formAdditionalData.disabled_all_inputs === true }

      // JSX
      let inputJSX = (
        <Box className="tw-mt-4">
          <Typography
            variant="subtitle1"
            className="tw-mb-4 tw-opacity-70"
          >
            {rLIB('How many Powerwall units are you installing?')}
          </Typography>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{ width: '25%' }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('1')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '1', true)
                  }
                }}
              >
                <Box className="tw-inline-block tw-mr-2">1</Box>
                <Box sx={{ display: { xs: 'none', sm: 'inline-block' } }}>{rLIB('Powerwall')}</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: '25%' }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('2')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '2', true)
                  }
                }}
              >
                <Box className="tw-inline-block tw-mr-2">2</Box>
                <Box sx={{ display: { xs: 'none', sm: 'inline-block' } }}>{rLIB('Powerwalls')}</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: '25%' }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('3')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '3', true)
                  }
                }}
              >
                <Box className="tw-inline-block tw-mr-2">3</Box>
                <Box sx={{ display: { xs: 'none', sm: 'inline-block' } }}>{rLIB('Powerwalls')}</Box>
              </Box>
            </Box>
            <Box
              sx={{ width: '25%' }}
              className="tw-p-1 tw-inline-block"
            >
              <Box
                className="tw-rounded tw-py-3 tw-px-1 tw-text-center tw-cursor-pointer"
                sx={returnSX_CustomButton('4+')}
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formInputChange('number_of_powerwalls_to_install', '4+', true)
                  }
                }}
              >
                <Box className="tw-inline-block tw-mr-2">4+</Box>
                <Box sx={{ display: { xs: 'none', sm: 'inline-block' } }}>{rLIB('Powerwalls')}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )
      return inputJSX
    },
  },
  items_to_backup: {
    data_type: 'string',
    key: 'items_to_backup',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      // Helper Functions
      const rJSX_ItemClarification = (applianceKey: string): JSX.Element => {
        let clarificationJSX: JSX.Element = <></>
        if (
          applianceKey === 'appliance_backup-2nd_air_conditioner' &&
          formData['number_of_powerwalls_to_install'] != null &&
          // @ts-expect-error - TODO: reason for error
          parseInt(formData['number_of_powerwalls_to_install']) < 3 &&
          formData['appliance_backup-2nd_air_conditioner'] === true
        ) {
          clarificationJSX = (
            <Box
              className="tw-inline-block tw-ml-2"
              sx={{ color: themeVariables.error_main }}
            >
              ({rLIB('Requires 3 Powerwalls')})
            </Box>
          )
        } else if (
          applianceKey === 'appliance_backup-water_and_well_pumps' &&
          formData['number_of_powerwalls_to_install'] != null &&
          // @ts-expect-error - TODO: reason for error
          parseInt(formData['number_of_powerwalls_to_install']) < 3 &&
          formData['appliance_backup-water_and_well_pumps'] === true
        ) {
          clarificationJSX = (
            <Box
              className="tw-inline-block tw-ml-2"
              sx={{ color: themeVariables.error_main }}
            >
              ({rLIB('Requires 3 Powerwalls')})
            </Box>
          )
        } else if (
          applianceKey === 'appliance_backup-electric_water_heater' &&
          formData['number_of_powerwalls_to_install'] != null &&
          // @ts-expect-error - TODO: reason for error
          parseInt(formData['number_of_powerwalls_to_install']) < 2 &&
          formData['appliance_backup-electric_water_heater'] === true
        ) {
          clarificationJSX = (
            <Box
              className="tw-inline-block tw-ml-2"
              sx={{ color: themeVariables.error_main }}
            >
              ({rLIB('Requires 2 Powerwalls')})
            </Box>
          )
        }
        return clarificationJSX
      }

      const returnSX_ApplianceCheckbox = (cutoffNumber: number): TsInterface_UnspecifiedObject => {
        let checkboxSX: TsInterface_UnspecifiedObject = {}
        if (formData != null && formData['number_of_powerwalls_to_install'] != null && formData['number_of_powerwalls_to_install'] === '4+') {
          checkboxSX = { color: themeVariables.success_main + ' !important' }
        } else if (
          formData != null &&
          formData['number_of_powerwalls_to_install'] != null &&
          // @ts-expect-error - TODO: reason for error
          cutoffNumber <= parseInt(formData['number_of_powerwalls_to_install'])
        ) {
          checkboxSX = { color: themeVariables.success_main + ' !important' }
        } else if (
          formData != null &&
          formData['number_of_powerwalls_to_install'] != null &&
          // @ts-expect-error - TODO: reason for error
          cutoffNumber > parseInt(formData['number_of_powerwalls_to_install'])
        ) {
          checkboxSX = { color: themeVariables.error_main + ' !important' }
        } else {
          checkboxSX = { color: themeVariables.gray_500 + ' !important' }
        }
        return checkboxSX
      }

      const returnSX_ApplianceRadio = (cutoffNumber: number): TsInterface_UnspecifiedObject => {
        let radioSX: TsInterface_UnspecifiedObject = {}
        if (formData['appliance_backup-lights_and_plugs'] === true || formData['appliance_backup-microwave_and_fridge'] === true) {
          if (formData != null && formData['number_of_powerwalls_to_install'] != null && formData['number_of_powerwalls_to_install'] === '4+') {
            radioSX = { color: themeVariables.success_main + ' !important' }
          } else if (
            formData != null &&
            formData['number_of_powerwalls_to_install'] != null &&
            // @ts-expect-error - TODO: reason for error
            cutoffNumber <= parseInt(formData['number_of_powerwalls_to_install'])
          ) {
            radioSX = { color: themeVariables.success_main + ' !important' }
          } else if (
            formData != null &&
            formData['number_of_powerwalls_to_install'] != null &&
            // @ts-expect-error - TODO: reason for error
            cutoffNumber > parseInt(formData['number_of_powerwalls_to_install'])
          ) {
            radioSX = { color: themeVariables.error_main + ' !important' }
          } else {
            radioSX = { color: themeVariables.gray_500 + ' !important' }
          }
        } else {
          radioSX = { color: themeVariables.gray_500 + ' !important', opacity: '0.3' }
        }
        return radioSX
      }

      const rJSX_CircuitsInput = (): JSX.Element => {
        let circuitsInputJSX: JSX.Element = <></>
        let warningJSX = <></>
        let disableInput = false
        if (formData['appliance_backup-lights_and_plugs'] === true || formData['appliance_backup-microwave_and_fridge'] === true) {
          if (
            formData['appliance_backup-number_of_circuits'] === '31+' &&
            (formData['number_of_powerwalls_to_install'] === 3 ||
              formData['number_of_powerwalls_to_install'] === 2 ||
              formData['number_of_powerwalls_to_install'] === 1)
          ) {
            warningJSX = (
              <Box
                className=""
                sx={{ color: themeVariables.error_main }}
              >
                ({rLIB('Requires 4+ Powerwalls')})
              </Box>
            )
          } else if (
            formData['appliance_backup-number_of_circuits'] === '21_to_30' &&
            (formData['number_of_powerwalls_to_install'] === 2 || formData['number_of_powerwalls_to_install'] === 1)
          ) {
            warningJSX = (
              <Box
                className=""
                sx={{ color: themeVariables.error_main }}
              >
                ({rLIB('Requires 3 Powerwalls')})
              </Box>
            )
          } else if (formData['appliance_backup-number_of_circuits'] === '11_to_20' && formData['number_of_powerwalls_to_install'] === 1) {
            warningJSX = (
              <Box
                className=""
                sx={{ color: themeVariables.error_main }}
              >
                ({rLIB('Requires 2 Powerwalls')})
              </Box>
            )
          }
        } else {
          disableInput = true
        }
        // JSX
        circuitsInputJSX = (
          <Box className="tw-ml-12">
            <Typography
              variant="subtitle1"
              className="tw-m-0"
            >
              <Box className="tw-opacity-50 tw-inline-block">{rLIB('Number of circuits for lights, plugs, microwave, & fridge')}</Box>
              <Box
                className="tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2 tw-inline-block"
                onClick={() => {
                  if (formAdditionalData.disabled_all_inputs !== true) {
                    formHooks.uc_setUserInterface_CustomDialogDisplay({
                      display: true,
                      dialog: {
                        dialog_jsx: (
                          <Dialog
                            className="bp_dialog_lg_width"
                            keepMounted
                            onClose={() => {
                              formHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                            }}
                            open={true}
                          >
                            <DialogContent sx={{ padding: '0px' }}>
                              <Stack
                                direction="row"
                                className="tw-justify-between tw-pt-4 tw-pb-2 tw-px-4"
                              >
                                <Typography
                                  variant="h5"
                                  className=""
                                >
                                  {rLIB('What is a Circuit?')}
                                </Typography>
                                <Box
                                  className="tw-inline-block tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100"
                                  onClick={() => {
                                    formHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                                  }}
                                >
                                  <Icon icon="circle-xmark" />
                                </Box>
                              </Stack>
                              <Divider />
                              <Box className="tw-text-left tw-p-4">
                                <Typography
                                  variant="subtitle1"
                                  className="tw-mb-4"
                                >
                                  {rLIB(
                                    'A home wiring electrical circuit is a closed pathway that allows electricity to flow from a power source to various devices and fixtures in a house. It consists of conductive wiring, switches, outlets, and circuit protection mechanisms such as breakers or fuses to ensure safe and efficient distribution of electrical power.',
                                  )}
                                </Typography>
                                <Box className="tw-text-center">
                                  <Box
                                    className="tw-m-auto"
                                    component="img"
                                    sx={{ maxWidth: 450 }}
                                    src={panelSrc}
                                  />
                                </Box>
                                <Typography variant="subtitle1">
                                  {rLIB(
                                    'You can check your breaker box to see how many circuits you have and want to have backed up. They should be labeled with the appliance or part of your house that they are providing power to. If you have any questions about your circuits, our team can take a look and answer your questions when they come out to do a preliminary audit.',
                                  )}
                                </Typography>
                              </Box>
                            </DialogContent>
                          </Dialog>
                        ),
                        settings: {
                          max_width: 'lg',
                        },
                      },
                    })
                  }
                }}
              >
                <Icon icon="circle-question" />
              </Box>
            </Typography>
            <FormControl>
              <RadioGroup
                row
                onChange={(event) => {
                  formInputChange('appliance_backup-number_of_circuits', event.target.value, true)
                }}
              >
                <FormControlLabel
                  disabled={formAdditionalData.disabled_all_inputs === true}
                  value="0_to_10"
                  control={
                    <Radio
                      disabled={disableInput}
                      sx={returnSX_ApplianceRadio(1)}
                    />
                  }
                  label={rLIB('0 to 10')}
                />
                <FormControlLabel
                  disabled={formAdditionalData.disabled_all_inputs === true}
                  value="11_to_20"
                  control={
                    <Radio
                      disabled={disableInput}
                      sx={returnSX_ApplianceRadio(2)}
                    />
                  }
                  label={rLIB('11 to 20')}
                />
                <FormControlLabel
                  disabled={formAdditionalData.disabled_all_inputs === true}
                  value="21_to_30"
                  control={
                    <Radio
                      disabled={disableInput}
                      sx={returnSX_ApplianceRadio(3)}
                    />
                  }
                  label={rLIB('21 to 30')}
                />
                <FormControlLabel
                  disabled={formAdditionalData.disabled_all_inputs === true}
                  value="31+"
                  control={
                    <Radio
                      disabled={disableInput}
                      sx={returnSX_ApplianceRadio(4)}
                    />
                  }
                  label={rLIB('More than 30')}
                />
              </RadioGroup>
            </FormControl>
            {warningJSX}
          </Box>
        )
        return circuitsInputJSX
      }

      // JSX
      let inputJSX = (
        <Box className="tw-mt-4">
          <Typography
            variant="subtitle1"
            className="tw-mb-4 tw-opacity-70"
          >
            {rLIB('What appliances do you want to backup?')}
          </Typography>
          <Box className="tw-pl-8">
            <Typography
              variant="body1"
              className=""
            >
              {rLIB('Small Appliances (120V)')}
            </Typography>
            <Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-lights_and_plugs'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-lights_and_plugs', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Lights & Plugs')}
                      {rJSX_ItemClarification('appliance_backup-lights_and_plugs')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-microwave_and_fridge'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-microwave_and_fridge', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Microwave & Fridge')}
                      {rJSX_ItemClarification('appliance_backup-microwave_and_fridge')}
                    </Box>
                  }
                />
              </Box>
              <Box>{rJSX_CircuitsInput()}</Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-gas_appliances'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-gas_appliances', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Gas Appliances')}
                      {rJSX_ItemClarification('appliance_backup-gas_appliances')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-washer'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-washer', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Washer')}
                      {rJSX_ItemClarification('appliance_backup-washer')}
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Typography
              variant="body1"
              className=""
            >
              {rLIB('Large Appliances (240V)')}
            </Typography>
            <Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-1st_air_conditioner'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-1st_air_conditioner', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Air Conditioner')}
                      {rJSX_ItemClarification('appliance_backup-1st_air_conditioner')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(3)}
                      checked={(formData['appliance_backup-2nd_air_conditioner'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-2nd_air_conditioner', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('2nd Air Conditioner')}
                      {rJSX_ItemClarification('appliance_backup-2nd_air_conditioner')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-electric_range_and_oven'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-electric_range_and_oven', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Electric Range & Oven')}
                      {rJSX_ItemClarification('appliance_backup-electric_range_and_oven')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(3)}
                      checked={(formData['appliance_backup-water_and_well_pumps'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-water_and_well_pumps', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Water & Well Pumps')}
                      {rJSX_ItemClarification('appliance_backup-water_and_well_pumps')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-tesla_ev_charger'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-tesla_ev_charger', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Tesla EV Charger')}
                      {rJSX_ItemClarification('appliance_backup-tesla_ev_charger')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-electric_dryer'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-electric_dryer', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Electric Dryer')}
                      {rJSX_ItemClarification('appliance_backup-electric_dryer')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(2)}
                      checked={(formData['appliance_backup-electric_water_heater'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-electric_water_heater', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Electric Water Heater')}
                      {rJSX_ItemClarification('appliance_backup-electric_water_heater')}
                    </Box>
                  }
                />
              </Box>
              <Box>
                <FormControlLabel
                  className="tw-ml-1"
                  control={
                    <Checkbox
                      disabled={formAdditionalData.disabled_all_inputs === true}
                      sx={returnSX_ApplianceCheckbox(1)}
                      checked={(formData['appliance_backup-dishwasher'] as boolean) || false}
                      onChange={(event) => {
                        formInputChange('appliance_backup-dishwasher', event.target.checked, true)
                      }}
                    />
                  }
                  label={
                    <Box className="tw-inline-block">
                      {rLIB('Dishwasher')}
                      {rJSX_ItemClarification('appliance_backup-dishwasher')}
                    </Box>
                  }
                />
              </Box>
            </Box>
            <Typography
              variant="body1"
              className="tw-mt-2 tw-mb-6 tw-opacity-50"
            >
              *{rLIB('Backup duration when powering appliances is based on your energy consumption during an outage')}
            </Typography>
          </Box>
        </Box>
      )
      return inputJSX
    },
  },
  include_batteries_in_order: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'include_batteries_in_order',
    label: rLIB('Have you already purchased Powerwall units from Tesla or would you like to include the batteries with this order?'),
    required: true,
    options: [
      { key: 'include_batteries_in_order', value: rLIB('Include batteries in my order') },
      { key: 'already_purchased_batteries', value: rLIB("I've already purchased Powerwalls from Tesla") },
    ],
  },
  upload_breaker_image: {
    data_type: 'string',
    key: 'items_to_backup',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      // Helper functions
      const readAsDataURL = (file: any) => {
        return new Promise((resolve, reject) => {
          const fr = new FileReader()
          fr.onerror = reject
          fr.onload = () => {
            resolve(fr.result)
          }
          fr.readAsDataURL(file)
        })
      }

      const fileOnSelect = (event: React.ChangeEvent<HTMLInputElement>, additionalFileUploadParams: TsInterface_UnspecifiedObject): TsType_UnknownPromise => {
        return new Promise((resolve, reject) => {
          if (event != null && event.target != null && event.target.files !== null && event.target?.files?.length > 0) {
            let promiseArray: TsType_UnknownPromise[] = []
            let files = event.target.files
            let readFiles: TsInterface_UnspecifiedObject = {}
            let file = files[0]
            if (file != null && typeof file === 'object') {
              promiseArray.push(
                readAsDataURL(file)
                  .then((res_RADURL) => {
                    readFiles[0] = {
                      file_name: file.name,
                      file: file,
                      data_url: res_RADURL,
                    }
                  })
                  .catch((rej_RADURL) => {
                    // Nothing
                  }),
              )
            }
            Promise.all(promiseArray).finally(() => {
              let clientKey = getProp(formAdditionalData, 'clientKey', null)
              let userKey = getProp(formAdditionalData, 'userKey', null)
              if (clientKey != null && userKey != null) {
                if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                  setTimeout(() => {
                    // @ts-expect-error - TODO: reason for error
                    formAdditionalData.us_setShowImage(false)
                    formHooks.ur_forceRerender()
                  }, 1)
                }
                StorageUploadFile(StorageRef_TeslaPowerwallOrderPhotos(clientKey, userKey, readFiles[0].file_name), readFiles[0].file, {
                  clientKey: clientKey,
                  userKey: userKey,
                })
                  .then((res_SUF: any) => {
                    // files[ fileIndex ]["storage_url"] = getProp(res_SUF, "url", null)
                    let updateObject = {
                      breaker_box_photo_url: getProp(res_SUF, 'url', null),
                    }
                    DatabaseSetMergeDocument(DatabaseRef_PowerwallOrder_Document(clientKey, userKey), updateObject)
                      .then((res_DSMD) => {
                        resolve(res_DSMD)
                        if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                          setTimeout(() => {
                            // @ts-expect-error - TODO: reason for error
                            formAdditionalData.us_setShowImage(true)
                            formHooks.ur_forceRerender()
                          }, 1)
                        }
                        formHooks.ur_forceRerender()
                      })
                      .catch((rej_DSMD) => {
                        if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                          setTimeout(() => {
                            // @ts-expect-error - TODO: reason for error
                            formAdditionalData.us_setShowImage(true)
                            formHooks.ur_forceRerender()
                          }, 1)
                        }
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_SUF) => {
                    if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                      setTimeout(() => {
                        // @ts-expect-error - TODO: reason for error
                        formAdditionalData.us_setShowImage(true)
                        formHooks.ur_forceRerender()
                      }, 1)
                    }
                    console.error(rej_SUF)
                    reject(rej_SUF)
                  })
              } else {
                reject({
                  success: false,
                  error: {
                    message: rLIB('Failed to upload file'),
                    details: rLIB('Missing Required Keys'),
                    code: 'ER-D-PF-FOS-01',
                  },
                })
              }
            })
          } else {
            reject({
              success: false,
              error: {
                message: rLIB('Failed to upload file'),
                details: rLIB('Invalid Document Selection'),
                code: 'ER-D-PF-FOS-02',
              },
            })
          }
        })
      }
      // JSX
      let photoUploadJSX = <></>
      if (formAdditionalData.disabled_all_inputs !== true) {
        if (
          formAdditionalData != null &&
          formAdditionalData.powerwallOrderData != null &&
          // @ts-expect-error - TODO: reason for error
          formAdditionalData.powerwallOrderData['breaker_box_photo_url'] != null
        ) {
          let reuploadButtonJSX = (
            <FileUploadButton
              multiple={false}
              accept="image/*"
              onChange={fileOnSelect}
              button={{
                text: rLIB('Upload a replacement photo') as JSX.Element,
                icon: (
                  <Icon
                    icon="cloud-arrow-up"
                    className="tw-mr-2"
                  ></Icon>
                ),
                color: 'primary',
                className: '',
                variant: 'outlined',
                disabled: false,
              }}
              additionalFileUploadParams={{}}
            />
          )
          if (formAdditionalData.showImage === true) {
            photoUploadJSX = (
              <Box>
                <Box className="tw-pl-8 tw-mb-4">{reuploadButtonJSX}</Box>
                <Box className="tw-text-center tw-pl-8">
                  <Box
                    className="tw-m-auto"
                    component="img"
                    sx={{ width: '100%', maxWidth: 1000 }}
                    // @ts-expect-error - TODO: reason for error
                    src={formAdditionalData.powerwallOrderData.breaker_box_photo_url}
                  />
                </Box>
              </Box>
            )
          } else {
            photoUploadJSX = (
              <Box>
                <Box className="tw-pl-8 tw-mb-4">{reuploadButtonJSX}</Box>
              </Box>
            )
          }
        } else {
          photoUploadJSX = (
            <Box className="tw-pl-8">
              <FileUploadButton
                multiple={false}
                accept="image/*"
                onChange={fileOnSelect}
                button={{
                  text: rLIB('Upload Photo') as JSX.Element,
                  icon: (
                    <Icon
                      icon="cloud-arrow-up"
                      className="tw-mr-2"
                    ></Icon>
                  ),
                  color: 'primary',
                  className: '',
                  variant: 'outlined',
                  disabled: false,
                }}
                additionalFileUploadParams={{}}
              />
            </Box>
          )
        }
      } else {
        photoUploadJSX = (
          <Box className="tw-text-center tw-pl-8">
            <Box
              className="tw-m-auto"
              component="img"
              sx={{ width: '100%', maxWidth: 1000 }}
              // @ts-expect-error - TODO: reason for error
              src={formData.breaker_box_photo_url}
            />
          </Box>
        )
      }
      let inputJSX = (
        <Box className="tw-mt-4">
          <Box>
            <Typography
              variant="subtitle1"
              className="tw-mb-4 tw-opacity-70 tw-inline-block"
            >
              {rLIB('Optional: Upload a photo of your breaker box')}
            </Typography>
            <Box
              className="tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2 tw-inline-block"
              onClick={() => {
                if (formAdditionalData.disabled_all_inputs !== true) {
                  formHooks.uc_setUserInterface_CustomDialogDisplay({
                    display: true,
                    dialog: {
                      dialog_jsx: (
                        <Dialog
                          className="bp_dialog_lg_width"
                          keepMounted
                          onClose={() => {
                            formHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                          }}
                          open={true}
                        >
                          <DialogContent sx={{ padding: '0px' }}>
                            <Stack
                              direction="row"
                              className="tw-justify-between tw-pt-4 tw-pb-2 tw-px-4"
                            >
                              <Typography
                                variant="h5"
                                className=""
                              >
                                {rLIB('Example Breaker Box')}
                              </Typography>
                              <Box
                                className="tw-inline-block tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100"
                                onClick={() => {
                                  formHooks.uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                                }}
                              >
                                <Icon icon="circle-xmark" />
                              </Box>
                            </Stack>
                            <Divider />
                            <Box className="tw-text-left tw-p-4">
                              <Box className="tw-text-center">
                                <Box
                                  className="tw-m-auto"
                                  component="img"
                                  sx={{ maxWidth: 450 }}
                                  src={panelSrc}
                                />
                              </Box>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      ),
                      settings: {
                        max_width: 'lg',
                      },
                    },
                  })
                }
              }}
            >
              <Icon icon="circle-question" />
            </Box>
          </Box>
          {photoUploadJSX}
        </Box>
      )
      return inputJSX
    },
  },
}

// Helper
function formatDollarAmount(number: number, includeCents: boolean = false) {
  // Convert the number to a string
  let amount = number.toString()
  // Split the string into whole and decimal parts (if any)
  let parts = amount.split('.')
  // Retrieve the whole part
  let wholePart = parts[0]
  // Determine if the number is negative
  let isNegative = false
  if (wholePart.charAt(0) === '-') {
    isNegative = true
    wholePart = wholePart.slice(1) // Remove the minus sign
  }
  // Add commas to the whole part
  let formattedAmount = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // Add the dollar sign and minus sign (if necessary)
  if (isNegative) {
    formattedAmount = '-$' + formattedAmount
  } else {
    formattedAmount = '$' + formattedAmount
  }
  // Include cents if the flag is set to true
  if (includeCents) {
    let cents = parts[1] || '00' // Default to "00" if no decimal part
    cents = parseFloat(`0.${cents}`).toFixed(2).slice(2) // Round to 2 decimal places and remove leading "0."
    formattedAmount += '.' + cents
  }
  // Return the formatted amount
  return formattedAmount
}

const returnTaxTotal = (billingDataValues: TsInterface_UnspecifiedObject): string => {
  if (billingDataValues['hide_calculated_fields'] === true) {
    return '--'
  } else {
    let taxAmount =
      (billingDataValues['powerwall'] + billingDataValues['gateway'] + billingDataValues['accessories'] + billingDataValues['installation']) *
      billingDataValues['TEMP_tax_rate']
    return formatDollarAmount(taxAmount, true)
  }
}

const returnCashPriceTotal = (billingDataValues: TsInterface_UnspecifiedObject): string => {
  if (billingDataValues['hide_calculated_fields'] === true) {
    return '--'
  } else {
    let taxAmount =
      (billingDataValues['powerwall'] + billingDataValues['gateway'] + billingDataValues['accessories'] + billingDataValues['installation']) *
      billingDataValues['TEMP_tax_rate']
    let cashPriceAmount =
      billingDataValues['powerwall'] + billingDataValues['gateway'] + billingDataValues['accessories'] + billingDataValues['installation'] + taxAmount
    return formatDollarAmount(cashPriceAmount, true)
  }
}

const returnTaxCreditTotal = (billingDataValues: TsInterface_UnspecifiedObject): string => {
  if (billingDataValues['hide_calculated_fields'] === true) {
    return '--'
  } else {
    let taxAmount =
      (billingDataValues['powerwall'] + billingDataValues['gateway'] + billingDataValues['accessories'] + billingDataValues['installation']) *
      billingDataValues['TEMP_tax_rate']
    let cashPriceAmount =
      billingDataValues['powerwall'] + billingDataValues['gateway'] + billingDataValues['accessories'] + billingDataValues['installation'] + taxAmount
    let taxCreditAmount = cashPriceAmount * billingDataValues['TEMP_tax_credit_rate'] * -1
    return formatDollarAmount(taxCreditAmount, true)
  }
}

const returnPriceAfterIncentivesTotal = (billingDataValues: TsInterface_UnspecifiedObject): string => {
  if (billingDataValues['hide_calculated_fields'] === true) {
    return '--'
  } else {
    let taxAmount =
      (billingDataValues['powerwall'] + billingDataValues['gateway'] + billingDataValues['accessories'] + billingDataValues['installation']) *
      billingDataValues['TEMP_tax_rate']
    let cashPriceAmount =
      billingDataValues['powerwall'] + billingDataValues['gateway'] + billingDataValues['accessories'] + billingDataValues['installation'] + taxAmount
    let taxCreditAmount = cashPriceAmount * billingDataValues['TEMP_tax_credit_rate'] * -1
    let priceAfterIncentivesAmount = cashPriceAmount + taxCreditAmount
    return formatDollarAmount(priceAfterIncentivesAmount, true)
  }
}

// JSX
const rJSX_AgreedTimestamp = (powerwallOrderData: TsInterface_UnspecifiedObject): JSX.Element => {
  let timestampJSX = <></>
  if (powerwallOrderData != null && powerwallOrderData['timestamp_agreed_to_terms'] != null) {
    timestampJSX = (
      <Box className="tw-italic tw-opacity-30 tw-ml-1 tw-inline-block">
        ({returnFormattedDate(returnDateFromUnknownDateFormat(powerwallOrderData['timestamp_agreed_to_terms']), 'D MMM YYYY h:mma')})
      </Box>
    )
  }
  return timestampJSX
}

export const rJSX_OrderFinanceDetails = (
  uc_setUserInterface_CustomDialogDisplay: any,
  powerwallOrderData: TsInterface_UnspecifiedObject,
  clientKey: string | null,
  userKey: string | null,
): JSX.Element => {
  // Determine what to display
  let orderSubheaderJSX = (
    <Typography
      variant="body1"
      className="tw-m-auto tw-opacity-50"
      sx={{ maxWidth: '300px' }}
    >
      {rLIB('Final price will be provided when your system design is complete')}
    </Typography>
  )
  // let customOrderJSX =
  // <Box>
  // 	<Typography variant="body1" className="tw-m-auto tw-opacity-50" sx={{ maxWidth: "450px" }}>
  // 		{ s_CUSTOM_ORDER_TEXT } <Link href={`mailto:${supportEmail}`} target="_blank">{ supportEmail }</Link> { s_OR } <Link href={`tel:${supportPhone}`} target="_blank">{ supportPhone }</Link>
  // 	</Typography>
  // </Box>
  // Determine Which Billing to display
  let billingDetails: TsInterface_UnspecifiedObject | null = null
  // if( powerwallOrderData["include_batteries_in_order"] === "already_purchased_batteries" ){
  // 	if( powerwallOrderData["number_of_powerwalls_to_install"] === "1" ){
  // 		billingDetails = powerwallPricingV1["just_install_1"]
  // 	} else if( powerwallOrderData["number_of_powerwalls_to_install"] === "2" ){
  // 		billingDetails = powerwallPricingV1["just_install_2"]
  // 	} else if( powerwallOrderData["number_of_powerwalls_to_install"] === "3" ){
  // 		billingDetails = powerwallPricingV1["just_install_3"]
  // 	} else if( powerwallOrderData["number_of_powerwalls_to_install"] === "4+" ){
  // 		billingDetails = powerwallPricingV1["just_install_4+"]
  // 		orderSubheaderJSX = customOrderJSX
  // 	}
  // } else {
  // 	if( powerwallOrderData["number_of_powerwalls_to_install"] === "1" ){
  // 		billingDetails = powerwallPricingV1["buy_and_install_1"]
  // 	} else if( powerwallOrderData["number_of_powerwalls_to_install"] === "2" ){
  // 		billingDetails = powerwallPricingV1["buy_and_install_2"]
  // 	} else if( powerwallOrderData["number_of_powerwalls_to_install"] === "3" ){
  // 		billingDetails = powerwallPricingV1["buy_and_install_3"]
  // 	} else if( powerwallOrderData["number_of_powerwalls_to_install"] === "4+" ){
  // 		billingDetails = powerwallPricingV1["buy_and_install_4+"]
  // 		orderSubheaderJSX = customOrderJSX
  // 	}
  // }
  let orderDate = new Date()
  if (powerwallOrderData != null && powerwallOrderData['timestamp_agreed_to_terms'] != null) {
    orderDate = returnDateFromUnknownDateFormat(powerwallOrderData['timestamp_agreed_to_terms'])
  }
  if (
    getProp(powerwallOrderData, 'number_of_powerwalls_to_install', null) != null &&
    getProp(powerwallOrderData, 'expanding_powerwall', null) != null &&
    getProp(powerwallOrderData, 'include_batteries_in_order', null) != null
  ) {
    billingDetails = returnPowerwallPricing(
      getProp(powerwallOrderData, 'number_of_powerwalls_to_install', null),
      getProp(powerwallOrderData, 'expanding_powerwall', null),
      getProp(powerwallOrderData, 'include_batteries_in_order', null),
      orderDate,
    )
  }
  // JSX
  let orderFinanceDetailsJSX = <></>
  if (billingDetails != null) {
    orderFinanceDetailsJSX = (
      <Box>
        <Box className="tw-text-center">
          <Typography
            variant="h4"
            className="tw-mb-2"
            sx={{ fontSize: '28px' }}
          >
            {rLIB('Order Summary')}
          </Typography>
          {orderSubheaderJSX}
        </Box>
        <Box
          className="tw-mt-4 tw-p-2"
          sx={{ border: '1px solid rgba(255,255,255,0.2)' }}
        >
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Typography
              variant="subtitle1"
              className="tw-font-bold"
            >
              {rLIB('Your System')}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
            >
              {billingDetails.text.powerwall_text}
            </Typography>
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
              sx={{ color: themeVariables.primary_main }}
            >
              {billingDetails.text.powerwall_price}
              {/* <sup className="tw-sups">‡</sup> */}
              {/* Promo through 10/31/2023 */}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
            >
              {rLIB('Gateway')}
            </Typography>
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
              sx={{ color: themeVariables.primary_main }}
            >
              {billingDetails.text.gateway_price}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
            >
              {rLIB('Accessories')}
            </Typography>
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
              sx={{ color: themeVariables.primary_main }}
            >
              {billingDetails.text.accessories_price}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
            >
              {rLIB('Installation')}
            </Typography>
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
              sx={{ color: themeVariables.primary_main }}
            >
              {billingDetails.text.installation_price}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
            >
              {rLIB('Tax')} (~{billingDetails.values.TEMP_tax_rate * 100}%)*
            </Typography>
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
              sx={{ color: themeVariables.primary_main }}
            >
              {returnTaxTotal(billingDetails.values)}*
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
            >
              {rLIB('Cash Price')}
            </Typography>
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
              sx={{ color: themeVariables.primary_main }}
            >
              {returnCashPriceTotal(billingDetails.values)}*
            </Typography>
          </Stack>
          <Box className="tw-mt-6"></Box>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Typography
              variant="subtitle1"
              className="tw-font-bold"
            >
              {rLIB('Potential Incentives')}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
            >
              {rLIB('Federal Tax Credit (30%)')}
              <Box
                className="tw-opacity-50 hover:tw-opacity-100 tw-cursor-pointer tw-ml-2 tw-inline-block"
                onClick={() => {
                  uc_setUserInterface_CustomDialogDisplay({
                    display: true,
                    dialog: {
                      dialog_jsx: (
                        <Dialog
                          className="bp_dialog_lg_width"
                          keepMounted
                          onClose={() => {
                            uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                          }}
                          open={true}
                        >
                          <DialogContent sx={{ padding: '0px' }}>
                            <Stack
                              direction="row"
                              className="tw-justify-between tw-pt-4 tw-pb-2 tw-px-4"
                            >
                              <Typography
                                variant="h5"
                                className=""
                              >
                                {rLIB('Federal Tax Credit')}
                              </Typography>
                              <Box
                                className="tw-inline-block tw-cursor-pointer tw-opacity-50 hover:tw-opacity-100"
                                onClick={() => {
                                  uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
                                }}
                              >
                                <Icon icon="circle-xmark" />
                              </Box>
                            </Stack>
                            <Divider />
                            <Box className="tw-text-left tw-p-4">
                              <Typography
                                variant="subtitle1"
                                className="tw-mb-4"
                              >
                                {rLIB(
                                  'The Inflation Reduction Act provides for a tax credit for homeowners (referred to as "Section 25D") for standalone energy storage. You will apply for this tax credit as part of your annual tax filing and the credit will be offset against your taxes paid or due. Please consult with a tax professional for details, qualification, and the process to claim these credits',
                                )}
                              </Typography>
                            </Box>
                          </DialogContent>
                        </Dialog>
                      ),
                      settings: {
                        max_width: 'lg',
                      },
                    },
                  })
                }}
              >
                <Icon icon="circle-question" />
              </Box>
            </Typography>
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
              sx={{ color: themeVariables.primary_main }}
            >
              {returnTaxCreditTotal(billingDetails.values)}*
            </Typography>
          </Stack>
          <Box className="tw-mt-6"></Box>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Typography
              variant="subtitle1"
              className="tw-font-bold"
            >
              {rLIB('Price After Potential Incentives')}
            </Typography>
            <Typography
              variant="subtitle1"
              className="tw-opacity-70"
              sx={{ color: themeVariables.primary_main }}
            >
              {returnPriceAfterIncentivesTotal(billingDetails.values)}*
            </Typography>
          </Stack>
        </Box>
        <Box className="tw-p-2">
          {/* <Stack direction="row" spacing={1} className="tw-justify-between">
						<Typography variant="h6" className="">{ s_DUE_TODAY }</Typography>
						<Typography variant="h6" className="tw-opacity-70" sx={{ color: themeVariables.primary_main }}>{ billingDetails.text.due_today_price }</Typography>
					</Stack> */}
          {/* <Typography className="tw-opacity-50" sx={{ fontSize: "14px" }}>
						{ s_REFUNDABLE_DEPOST_UNTIL_YOU_ACCEPT_YOUR_DESIGN }
					</Typography> */}
          {/* <Typography className="tw-opacity-50 tw-mt-4" sx={{ fontSize: "14px" }}>
						<Box className="tw-inline-block tw-mr-2"><sup className="tw-sups tw-inline-block tw-mr-1">‡</sup>{ s_TESLA_PROMO_TEXT }<Link className="tw-inline-block" href="https://www.tesla.com/support/energy/powerwall/order/rebate" target="_blank">{ s_CLICK_HERE }</Link></Box>
					</Typography> */}
          <Typography
            className="tw-opacity-50 tw-mt-4"
            sx={{ fontSize: '14px' }}
          >
            *{rLIB('Tax rate may vary based on your location')}
          </Typography>
          <Typography
            className="tw-opacity-50 tw-mt-4"
            sx={{ fontSize: '14px' }}
          >
            {rLIB('By submitting this order, I agree to the')}{' '}
            <Link
              href={ApplicationPages.PowerwallInstallAgreementPage.url()}
              target="_blank"
            >
              {rLIB('Energy Order Terms')}
            </Link>
            ,{' '}
            <Link
              href={ApplicationPages.PaymentTermsPage.url()}
              target="_blank"
            >
              {rLIB('Payment Terms')}
            </Link>
            , {rLIB('and')}{' '}
            <Link
              href={ApplicationPages.PrivacyPolicyPage.url()}
              target="_blank"
            >
              {rLIB('Privacy Notice')}
            </Link>
            .{rLIB('I also authorize ETW Energy to begin submitting permits and preparing for my upcoming installation.')}
          </Typography>
          <FormControlLabel
            className="tw-mr-1 tw-opacity-50"
            control={
              <Checkbox
                checked={(powerwallOrderData['agreed_to_terms'] as boolean) || false}
                onChange={(event) => {
                  let updateObject: TsInterface_UnspecifiedObject = {
                    agreed_to_terms: event.target.checked,
                  }
                  if (event.target.checked === true) {
                    updateObject['timestamp_agreed_to_terms'] = new Date()
                    updateObject['agreement_versions'] = returnCurrentLegalVersionNumbers()
                  }
                  if (userKey != null && clientKey != null) {
                    DatabaseSetMergeDocument(DatabaseRef_PowerwallOrder_Document(clientKey, userKey), updateObject)
                      .then((res_DSMD) => {
                        // Nothing
                      })
                      .catch((rej_DSMD) => {
                        console.error(rej_DSMD)
                      })
                  }
                }}
              />
            }
            label={
              <>
                {rLIB('I agree to the terms listed above')}
                {rJSX_AgreedTimestamp(powerwallOrderData)}
              </>
            }
          />

          {/* <Box className="tw-text-center">
						<Link href={ ApplicationPages.PowerwallInstallAgreementPage.url() } target="_blank" className="tw-mx-2">{ rLIB("Energy Order Terms") }</Link> |
						<Link href={ ApplicationPages.PaymentTermsPage.url() } target="_blank" className="tw-mx-2">{ rLIB("Payment Terms") }</Link> |
						<Link href={ ApplicationPages.PrivacyPolicyPage.url() } target="_blank" className="tw-mx-2">{ rLIB("Privacy Notice") }</Link>
					</Box> */}
        </Box>
      </Box>
    )
  } else {
    orderFinanceDetailsJSX = (
      <Box>
        <Box className="tw-text-center">
          <Typography variant="h6">
            <Box
              className="tw-inline-block tw-mr-1"
              sx={{ color: themeVariables.warning_main }}
            >
              <Icon
                icon="triangle-exclamation"
                className="tw-mr-2"
              />
            </Box>
            {rLIB('Missing number of powerwalls to install')}
          </Typography>
        </Box>
      </Box>
    )
  }
  return orderFinanceDetailsJSX
}

// Post Order Display
export const openPowerwallOrderSummaryDialog = (uc_setUserInterface_CustomDialogDisplay: any, powerwallOrderData: TsInterface_UnspecifiedObject) => {
  // Disable All Forms
  let disabledFormInputs_ContactInfo = { ...formInputs_DisabledContactInfo }
  for (let inputKey in disabledFormInputs_ContactInfo) {
    disabledFormInputs_ContactInfo[inputKey]['disabled'] = true
  }
  let disabledFormInputs_HomeInfo = { ...formInputs_DisabledHomeInfo }
  for (let inputKey in disabledFormInputs_HomeInfo) {
    disabledFormInputs_HomeInfo[inputKey]['disabled'] = true
  }
  let disabledFormInputs_BackupLoads = { ...formInputs_DisabledBackupLoads }
  for (let inputKey in disabledFormInputs_BackupLoads) {
    disabledFormInputs_BackupLoads[inputKey]['disabled'] = true
  }
  // JSX
  let dialogJSX = (
    <Box>
      <Dialog
        // TransitionComponent={ Transition }
        className="bp_dialog_xl_width"
        keepMounted
        onClose={() => {
          uc_setUserInterface_CustomDialogDisplay(UserInterface_Default_CustomDialogDisplayState)
        }}
        open={true}
      >
        <AppBar
          position="static"
          color="inherit"
        >
          <Toolbar>
            <IconButton
              aria-label="menu"
              color="inherit"
              disabled
              edge="start"
              size="large"
              sx={{ mr: 2, color: '#fff !important' }}
            >
              <Icon icon="bolt" />
            </IconButton>
            <Typography
              component={'span'}
              variant={'h6'}
              sx={{ flexGrow: 1 }}
            >
              {rLIB('Powerwall Order')} - {powerwallOrderData.first_name} {powerwallOrderData.last_name}
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent sx={{ padding: '0px' }}>
          <Box>
            <TabsBasic
              tabs={[
                {
                  tabHeader: <>{rLIB('Contact Info')}</>,
                  tabContent: (
                    <Box className="tw-p-2">
                      <Box
                        className="tw-m-auto"
                        sx={{ maxWidth: '700px' }}
                      >
                        <Form
                          formAdditionalData={{
                            disabled_all_inputs: true,
                          }}
                          formData={powerwallOrderData}
                          formInputs={disabledFormInputs_ContactInfo}
                          formOnChange={(
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formData: TsInterface_FormData,
                            formInputs: TsInterface_FormInputs,
                            formSettings: TsInterface_FormSettings,
                          ) => {
                            /* Nothing */
                          }}
                          formSettings={{
                            highlight_missing: false,
                            submit_button_hide: true,
                          }}
                          formSubmission={(
                            formSubmittedData: TsInterface_FormSubmittedData,
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formHooks: TsInterface_FormHooksObject,
                          ) => {
                            return new Promise((resolve, reject) => {
                              resolve({ success: true })
                            })
                          }}
                        />
                      </Box>
                    </Box>
                  ),
                },
                {
                  tabHeader: <>{rLIB('Home Info')}</>,
                  tabContent: (
                    <Box className="tw-p-2">
                      <Box
                        className="tw-m-auto"
                        sx={{ maxWidth: '700px' }}
                      >
                        <Form
                          formAdditionalData={{
                            disabled_all_inputs: true,
                          }}
                          formData={powerwallOrderData}
                          formInputs={disabledFormInputs_HomeInfo}
                          formOnChange={(
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formData: TsInterface_FormData,
                            formInputs: TsInterface_FormInputs,
                            formSettings: TsInterface_FormSettings,
                          ) => {
                            /* Nothing */
                          }}
                          formSettings={{
                            highlight_missing: false,
                            submit_button_hide: true,
                          }}
                          formSubmission={(
                            formSubmittedData: TsInterface_FormSubmittedData,
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formHooks: TsInterface_FormHooksObject,
                          ) => {
                            return new Promise((resolve, reject) => {
                              resolve({ success: true })
                            })
                          }}
                        />
                      </Box>
                    </Box>
                  ),
                },
                {
                  tabHeader: <>{rLIB('Backup Loads')}</>,
                  tabContent: (
                    <Box className="tw-p-2">
                      <Box
                        className="tw-m-auto"
                        sx={{ maxWidth: '700px' }}
                      >
                        <Form
                          formAdditionalData={{
                            disabled_all_inputs: true,
                            showImage: true,
                          }}
                          formData={powerwallOrderData}
                          formInputs={disabledFormInputs_BackupLoads}
                          formOnChange={(
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formData: TsInterface_FormData,
                            formInputs: TsInterface_FormInputs,
                            formSettings: TsInterface_FormSettings,
                          ) => {
                            /* Nothing */
                          }}
                          formSettings={{
                            highlight_missing: false,
                            submit_button_hide: true,
                          }}
                          formSubmission={(
                            formSubmittedData: TsInterface_FormSubmittedData,
                            formAdditionalData: TsInterface_FormAdditionalData,
                            formHooks: TsInterface_FormHooksObject,
                          ) => {
                            return new Promise((resolve, reject) => {
                              resolve({ success: true })
                            })
                          }}
                        />
                      </Box>
                    </Box>
                  ),
                },
                {
                  tabHeader: <>{rLIB('Order')}</>,
                  tabContent: <Box className="tw-p-2">{rJSX_OrderFinanceDetails(uc_setUserInterface_CustomDialogDisplay, powerwallOrderData, null, null)}</Box>,
                },
              ]}
              tabsSettings={{}}
            />
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )

  // Open Dialog
  uc_setUserInterface_CustomDialogDisplay({
    display: true,
    dialog: {
      dialog_jsx: dialogJSX,
      settings: {
        max_width: 'lg',
      },
    },
  })
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

		GET Documents on server like collection ids - otherwise ones without props don't show up...

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, CircularProgress, Divider, Typography } from '@mui/material/'
import { collection, doc, getFirestore } from 'firebase/firestore'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Json } from 'rfbp_core/components/code_display'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { cloudFunctionManageRequest } from 'rfbp_core/services/cloud_functions'
import { Context_UserInterface_PromptDialog } from 'rfbp_core/services/context'
import { DatabaseGetCollection, DatabaseGetDocument, generateDatabaseQuery } from 'rfbp_core/services/database_management'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SuperDatabaseManagementPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_DATABASE_MANAGEMENT: JSX.Element = <Trans>Database Management</Trans>
const s_ROOT: JSX.Element = <Trans>Root</Trans>
const s_CONFIRM: JSX.Element = <Trans>Confirm</Trans>
const s_VALUE: JSX.Element = <Trans>Value</Trans>
const se_DATABASE_MANAGEMENT: string = 'Database Management'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_initialDataLoad, us_setInitialDataLoad] = useState<boolean>(false)
  const [us_collectionIds, us_setCollectionIds] = useState<string[]>([])
  const [us_databasePathArray, us_setDatabasePathArray] = useState<string[]>(['clients'])
  const [us_documentData, us_setDocumentData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_loadingCollectionQuery, us_setLoadingCollectionQuery] = useState<boolean>(false)
  const [us_loadingDocumentQuery, us_setLoadingDocumentQuery] = useState<boolean>(false)
  const [us_loadingSubcollectionIDs, us_setLoadingSubcollectionIDs] = useState<boolean>(false)
  const [us_subcollectionIds, us_setSubcollectionIds] = useState<string[]>([])
  const [us_visibleSection, us_setVisibleSection] = useState<'odd' | 'even'>('odd')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_setUserInterface_PromptDialogDisplay } = useContext(Context_UserInterface_PromptDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = se_DATABASE_MANAGEMENT
  }, [])

  useEffect(() => {
    // Initial Load
    if (us_initialDataLoad === false) {
      us_setLoadingCollectionQuery(true)
      // @ts-ignore
      let collectionPath = generateDatabaseQuery(collection(getFirestore(), 'clients'), [], [], {}, 100)
      DatabaseGetCollection(collectionPath)
        .then((res_DGC) => {
          let formattedStartingCollectionIDs: string[] = []
          for (let loopDataItemKey in res_DGC.data) {
            formattedStartingCollectionIDs.push(loopDataItemKey)
          }
          us_setCollectionIds(formattedStartingCollectionIDs)
        })
        .catch((rej_DGC) => {
          us_setCollectionIds([])
        })
        .finally(() => {
          us_setLoadingCollectionQuery(false)
          ur_forceRerender()
        })
      us_setInitialDataLoad(true)
    }
  }, [ur_forceRerender, us_initialDataLoad])

  // Functions
  const loadCollectionData = (collectionPathStringArray: string[]) => {
    us_setVisibleSection('odd')
    us_setDatabasePathArray(collectionPathStringArray)
    us_setLoadingCollectionQuery(true)

    cloudFunctionManageRequest('manageSearch', { function: 'getDatabaseDocumentIds', database_path: collectionPathStringArray.join('/') })
      .then((res_CFMUR: any) => {
        us_setCollectionIds(res_CFMUR.data)
      })
      .catch((rej_CFMUR: unknown) => {
        console.error(rej_CFMUR)
        us_setCollectionIds([])
      })
      .finally(() => {
        us_setLoadingCollectionQuery(false)
        ur_forceRerender()
      })
  }

  const loadDocumentDataAndSubcollections = (documentPathStringArray: string[]) => {
    us_setVisibleSection('even')
    us_setDatabasePathArray(documentPathStringArray)
    us_setLoadingDocumentQuery(true)
    us_setLoadingSubcollectionIDs(true)
    // @ts-ignore
    let documentPath = doc(getFirestore(), ...documentPathStringArray)
    us_setDocumentData({})
    DatabaseGetDocument(documentPath)
      .then((res_DGC) => {
        us_setDocumentData(res_DGC.data)
      })
      .catch((rej_DGC) => {
        us_setDocumentData({})
      })
      .finally(() => {
        us_setLoadingDocumentQuery(false)
        ur_forceRerender()
      })
    us_setSubcollectionIds([])
    cloudFunctionManageRequest('manageSearch', { function: 'getDatabaseSubcollectionIds', database_path: documentPathStringArray.join('/') })
      .then((res_CFMUR: any) => {
        us_setSubcollectionIds(res_CFMUR.data)
      })
      .catch((rej_CFMUR: unknown) => {
        us_setSubcollectionIds([])
      })
      .finally(() => {
        us_setLoadingSubcollectionIDs(false)
        ur_forceRerender()
      })
  }

  // JSX Generation
  const rJSX_DatabaseContents = (): JSX.Element => {
    let contentsJSX = <></>
    if (us_visibleSection === 'odd') {
      if (us_loadingCollectionQuery === true) {
        contentsJSX = (
          <Box className="tw-text-center">
            <CircularProgress />
          </Box>
        )
      } else {
        contentsJSX = (
          <Box>
            {us_collectionIds.sort().map((itemKey: string, index: number) => (
              <Box key={index}>
                <Button
                  className="tw-py-0 tw-px-1 tw-mb-2 tw-min-w-0 tw-text-left tw-align-top"
                  variant="outlined"
                  color="inherit"
                  onClick={() => {
                    let newPathArray: string[] = us_databasePathArray
                    newPathArray.push(itemKey)
                    loadDocumentDataAndSubcollections(newPathArray)
                  }}
                >
                  {itemKey}
                </Button>
              </Box>
            ))}
          </Box>
        )
      }
    } else {
      let loadingJSX = <></>
      if (us_loadingDocumentQuery === true || us_loadingSubcollectionIDs === true) {
        loadingJSX = (
          <Box className="tw-text-center">
            <CircularProgress />
          </Box>
        )
      }
      contentsJSX = (
        <Box>
          {loadingJSX}
          {us_subcollectionIds.sort().map((itemKey: string, index: number) => (
            <Box key={index}>
              <Button
                className="tw-py-0 tw-px-1 tw-mb-2 tw-min-w-0 tw-text-left tw-align-top"
                variant="outlined"
                color="inherit"
                onClick={() => {
                  let newPathArray: string[] = us_databasePathArray
                  newPathArray.push(itemKey)
                  loadCollectionData(newPathArray)
                }}
              >
                {itemKey}
              </Button>
            </Box>
          ))}
          <Json data={us_documentData} />
        </Box>
      )
    }
    return contentsJSX
  }

  const rJSX_DatabasePath = (): JSX.Element => {
    let pathJSX = <></>
    if (us_databasePathArray.length > 0) {
      pathJSX = (
        <Box>
          {us_databasePathArray.map((item: string, index: number) => (
            <Box
              key={index}
              component={'span'}
            >
              <Button
                className="tw-py-0 tw-px-1 tw-m-0 tw-min-w-0 tw-text-left tw-align-top"
                variant={index === us_databasePathArray.length - 1 ? 'outlined' : 'outlined'}
                color={index === us_databasePathArray.length - 1 ? 'warning' : 'inherit'}
                onClick={() => {
                  if (index === us_databasePathArray.length - 1) {
                    uc_setUserInterface_PromptDialogDisplay({
                      display: true,
                      prompt: {
                        color: 'success',
                        confirm_text: s_CONFIRM,
                        default_value: us_databasePathArray[index],
                        header: rLIB('Edit'),
                        icon: (
                          <Icon
                            icon="pen-to-square"
                            type="solid"
                          />
                        ),
                        input_label: s_VALUE,
                        input_type: 'text',
                        text: rLIB('Edit'),
                        submit_callback: (promptValue: string) => {
                          return new Promise((resolve, reject) => {
                            let newPathArray = us_databasePathArray.slice(0, index)
                            newPathArray.push(promptValue)
                            if (newPathArray.length % 2 === 0) {
                              loadDocumentDataAndSubcollections(newPathArray)
                            } else {
                              loadCollectionData(newPathArray)
                            }
                            resolve({ success: true })
                          })
                        },
                      },
                    })
                  } else {
                    let newPathArray = us_databasePathArray.slice(0, index + 1)
                    if (newPathArray.length % 2 === 0) {
                      loadDocumentDataAndSubcollections(newPathArray)
                    } else {
                      loadCollectionData(newPathArray)
                    }
                  }
                }}
              >
                {item}
              </Button>
              {index === us_databasePathArray.length - 1 ? (
                <></>
              ) : (
                <Typography
                  className="tw-inline-block tw-opacity-30 tw-px-2"
                  sx={{ fontWeight: 900, fontSize: '30px', marginTop: '-14px' }}
                  variant="h6"
                >{`>`}</Typography>
              )}
            </Box>
          ))}
        </Box>
      )
    } else {
      pathJSX = (
        <Box>
          <Typography
            variant="h6"
            className="tw-opacity-30 tw-italic"
          >
            {s_ROOT}
          </Typography>
        </Box>
      )
    }
    return pathJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_DATABASE_MANAGEMENT}
        pageKey={pageKey}
        content={
          <Box>
            {rJSX_DatabasePath()}
            <Divider className="tw-my-2" />
            <Box className="tw-pl-8">{rJSX_DatabaseContents()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

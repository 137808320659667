///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_ShortUrl_Document = (urlKey: string) => {
  return doc(getFirestore(), 'global', 'short_urls', 'main', urlKey)
}

export const DatabaseRef_ShortUrl_Collection = (): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'global', 'short_urls', 'main')
}

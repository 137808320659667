///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card } from '@mui/material/'
import { formInputs_UserEdit, formOnChange_User, formSettings_User, formSubmission_UserUpdate } from 'app/models/users/user_form'
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ClientUser_Document } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Form, TsInterface_FormAdditionalData } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_RootData_ClientUser,
  Context_UserInterface_ErrorDialog,
} from 'rfbp_core/services/context'
import { DatabaseGetDocument } from 'rfbp_core/services/database_management'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseUserEditPage']['key']

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const pr_itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_formData_User, us_setFormData_User] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const ur_formRef = useRef(null)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'users'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    if (us_formData_User != null && us_formData_User['name'] != null) {
      document.title = us_formData_User['name']
    }
  }, [us_formData_User])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, pr_itemKey))
          .then((res_DGD) => {
            us_setFormData_User(res_DGD.data)
            // @ts-expect-error - TODO: reason for error
            if (ur_formRef != null && ur_formRef.current != null && ur_formRef.current.updateFormData != null) {
              // @ts-expect-error - TODO: reason for error
              ur_formRef.current.updateFormData(res_DGD.data)
            }
            ur_forceRerender()
          })
          .catch((rej_DGD) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGD.error })
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, pr_itemKey, uc_setUserInterface_ErrorDialogDisplay, ur_forceRerender])

  // Other Variables
  let formAdditionalData_User: TsInterface_FormAdditionalData = {
    key: pr_itemKey,
    client_type: uc_RootData_ClientPermissions.client_type,
    root_client_permissions: uc_RootData_ClientPermissions,
  }

  // Functions

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    return (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseUserListPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
      >
        {rLIB('Back to all users')}
      </Button>
    )
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Edit User') as JSX.Element}
        pageKey={pageKey}
        content={
          <Box>
            {rJSX_BackButton()}
            <Box className="tw-my-4">
              <Card className="tw-p-4">
                <Form
                  ref={ur_formRef}
                  formAdditionalData={formAdditionalData_User}
                  formData={us_formData_User}
                  formInputs={formInputs_UserEdit}
                  formOnChange={formOnChange_User}
                  formSettings={formSettings_User}
                  formSubmission={formSubmission_UserUpdate}
                />
              </Card>
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_GroupedTimePunches_Document = (clientKey: string, timesheetKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'timesheets', 'linked_time_punches', 'main', timesheetKey)
}

export const DatabaseRef_GroupedTimePunches_DateRange_Query = (clientKey: string, startDate: Date, endDate: Date): Query<DocumentData> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'timestamp', comparator: '>=', value: startDate },
    { prop: 'timestamp', comparator: '<=', value: endDate },
  ]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'timestamp', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'linked_time_punches', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_GroupedTimePunches_Task_Query = (clientKey: string, taskKey: string): Query<DocumentData> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_task_keys.' + taskKey, comparator: '==', value: true }]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = []
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'linked_time_punches', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}

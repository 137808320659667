///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_TableAdditionalData, TsInterface_TableColumn, TsInterface_TableDataRow, TsInterface_TableHooks, TsType_TableSortBy } from '../table_types'

///////////////////////////////
// Exports
///////////////////////////////

export const TableCellTruncatedString = (
  propKey: string,
  propName: string | JSX.Element,
  sortBy: TsType_TableSortBy,
  maxLength: number,
): TsInterface_TableColumn => {
  return {
    header: {
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return <>{propName}</>
      },
      header_sort_by: sortBy,
    },
    cell: {
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        const fullText = rowData[propKey] as string
        const displayedText = fullText.length > maxLength ? `${fullText.substring(0, maxLength)}...` : fullText
        return <>{displayedText}</>
      },
    },
  }
}

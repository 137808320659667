///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Exports
///////////////////////////////

export const listOfStates: TsInterface_UnspecifiedObject = {
  AL: {
    key: 'AL',
    name: 'Alabama',
  },
  AK: {
    key: 'AK',
    name: 'Alaska',
  },
  AZ: {
    key: 'AZ',
    name: 'Arizona',
  },
  AR: {
    key: 'AR',
    name: 'Arkansas',
  },
  CA: {
    key: 'CA',
    name: 'California',
  },
  CO: {
    key: 'CO',
    name: 'Colorado',
  },
  CT: {
    key: 'CT',
    name: 'Connecticut',
  },
  DE: {
    key: 'DE',
    name: 'Delaware',
  },
  FL: {
    key: 'FL',
    name: 'Florida',
  },
  GA: {
    key: 'GA',
    name: 'Georgia',
  },
  HI: {
    key: 'HI',
    name: 'Hawaii',
  },
  ID: {
    key: 'ID',
    name: 'Idaho',
  },
  IL: {
    key: 'IL',
    name: 'Illinois',
  },
  IN: {
    key: 'IN',
    name: 'Indiana',
  },
  IA: {
    key: 'IA',
    name: 'Iowa',
  },
  KS: {
    key: 'KS',
    name: 'Kansas',
  },
  KY: {
    key: 'KY',
    name: 'Kentucky',
  },
  LA: {
    key: 'LA',
    name: 'Louisiana',
  },
  ME: {
    key: 'ME',
    name: 'Maine',
  },
  MD: {
    key: 'MD',
    name: 'Maryland',
  },
  MA: {
    key: 'MA',
    name: 'Massachusetts',
  },
  MI: {
    key: 'MI',
    name: 'Michigan',
  },
  MN: {
    key: 'MN',
    name: 'Minnesota',
  },
  MS: {
    key: 'MS',
    name: 'Mississippi',
  },
  MO: {
    key: 'MO',
    name: 'Missouri',
  },
  MT: {
    key: 'MT',
    name: 'Montana',
  },
  NE: {
    key: 'NE',
    name: 'Nebraska',
  },
  NV: {
    key: 'NV',
    name: 'Nevada',
  },
  NH: {
    key: 'NH',
    name: 'New Hampshire',
  },
  NJ: {
    key: 'NJ',
    name: 'New Jersey',
  },
  NM: {
    key: 'NM',
    name: 'New Mexico',
  },
  NY: {
    key: 'NY',
    name: 'New York',
  },
  NC: {
    key: 'NC',
    name: 'North Carolina',
  },
  ND: {
    key: 'ND',
    name: 'North Dakota',
  },
  OH: {
    key: 'OH',
    name: 'Ohio',
  },
  OK: {
    key: 'OK',
    name: 'Oklahoma',
  },
  OR: {
    key: 'OR',
    name: 'Oregon',
  },
  PA: {
    key: 'PA',
    name: 'Pennsylvania',
  },
  RI: {
    key: 'RI',
    name: 'Rhode Island',
  },
  SC: {
    key: 'SC',
    name: 'South Carolina',
  },
  SD: {
    key: 'SD',
    name: 'South Dakota',
  },
  TN: {
    key: 'TN',
    name: 'Tennessee',
  },
  TX: {
    key: 'TX',
    name: 'Texas',
  },
  UT: {
    key: 'UT',
    name: 'Utah',
  },
  VT: {
    key: 'VT',
    name: 'Vermont',
  },
  VA: {
    key: 'VA',
    name: 'Virginia',
  },
  WA: {
    key: 'WA',
    name: 'Washington',
  },
  WV: {
    key: 'WV',
    name: 'West Virginia',
  },
  WI: {
    key: 'WI',
    name: 'Wisconsin',
  },
  WY: {
    key: 'WY',
    name: 'Wyoming',
  },
}

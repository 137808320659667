///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button } from '@mui/material/'
import { useContext, useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { Icon } from 'rfbp_core/components/icons'
import { Context_RootData_ClientKey, Context_RootData_ClientUser, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'

import { DatabaseRef_UserSettings_Collection, DatabaseRef_Users_Collection } from 'rfbp_aux/services/database_endpoints/directory/users'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseGetCollection } from 'rfbp_core/services/database_management'
import { downloadCSV, getProp } from 'rfbp_core/services/helper_functions'
import { directAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseToolsListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_TOOLS: JSX.Element = <Trans>Tools</Trans>
const s_UNDER_CONSTRUCTION: JSX.Element = <Trans>Under Construction</Trans>
const se_TOOLS: string = 'Tools'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_downloadingCSV, us_setDownloadingCSV] = useState<boolean>(false)
  // const ur_forceRerender = 				useReducer(() => ({}), {})[1] as () => void
  const un_routerNavigation = useNavigate()
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'tools'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    document.title = se_TOOLS
  }, [])

  // Functions
  const combineNamesAndSizeData = (settingsUserData: TsInterface_UnspecifiedObject, mainUserData: TsInterface_UnspecifiedObject): string[][] => {
    // filter user settings data to only include relevant users
    const filteredUserSettingsData = Object.entries(settingsUserData.data).filter(
      (user: TsInterface_UnspecifiedObject) =>
        user[1].equipment_size_boot ||
        user[1].equipment_size_shirt ||
        user[1].equipment_size_jacket ||
        user[1].equipment_size_shoe ||
        user[1].equipment_size_hoodie,
    )

    let combinedUserData: TsInterface_UnspecifiedObject[] = []

    // combine user name from main data and user settings data
    for (const filteredUser of filteredUserSettingsData) {
      // filteredUser consists of ['user key', {settings data}]
      let nameOfUser: string = mainUserData.data[filteredUser[0]].name
      let userSettings = filteredUser[1] as object // ensure that userSettings is recognized as an object to use the spread ... notation
      let combinedUser = {
        name: nameOfUser,
        ...userSettings,
      }
      combinedUserData.push(combinedUser)
    }

    // ensure that all fields are populated — if a field is blank, assign an empty string
    for (let combinedUser of combinedUserData) {
      if (!getProp(combinedUser, 'equipment_size_boot', false)) {
        combinedUser.equipment_size_boot = ''
      }
      if (!getProp(combinedUser, 'equipment_size_jacket', false)) {
        combinedUser.equipment_size_jacket = ''
      }
      if (!getProp(combinedUser, 'equipment_size_hoodie', false)) {
        combinedUser.equipment_size_hoodie = ''
      }
      if (!getProp(combinedUser, 'equipment_size_gender', false)) {
        combinedUser.equipment_size_gender = ''
      }
      if (!getProp(combinedUser, 'equipment_size_shirt', false)) {
        combinedUser.equipment_size_shirt = ''
      }
      if (!getProp(combinedUser, 'equipment_size_shoe', false)) {
        combinedUser.equipment_size_shoe = ''
      }
    }

    // filter out unnecessary props from the combined user data
    const cleanedUserData = combinedUserData.map((combinedUser) => {
      return {
        name: combinedUser.name,
        equipment_size_boot: combinedUser.equipment_size_boot,
        equipment_size_jacket: combinedUser.equipment_size_jacket,
        equipment_size_hoodie: combinedUser.equipment_size_hoodie,
        equipment_size_gender: combinedUser.equipment_size_gender,
        equipment_size_shirt: combinedUser.equipment_size_shirt,
        equipment_size_shoe: combinedUser.equipment_size_shoe,
      }
    })

    let orderedUserData = [['Name', 'Boot', 'Jacket', 'Hoodie', 'Gender', 'Shirt', 'Shoe']]

    // push an ordered array of the data into our final return value
    for (let userData of cleanedUserData) {
      orderedUserData.push([
        userData.name,
        userData.equipment_size_boot,
        userData.equipment_size_jacket,
        userData.equipment_size_hoodie,
        userData.equipment_size_gender,
        userData.equipment_size_shirt,
        userData.equipment_size_shoe,
      ])
    }

    return orderedUserData
  }

  const downloadClothingSizes = (): void => {
    us_setDownloadingCSV(true)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetCollection(DatabaseRef_UserSettings_Collection(res_GCK.clientKey))
          .then((res_DGC) => {
            DatabaseGetCollection(DatabaseRef_Users_Collection(res_GCK.clientKey))
              .then((res_DGC2) => {
                const downloadData = combineNamesAndSizeData(res_DGC, res_DGC2)
                downloadCSV('clothing_sizes', downloadData)
                us_setDownloadingCSV(false)
              })
              .catch((res_DGC2) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: res_DGC2.error })
                us_setDownloadingCSV(true)
              })
          })
          .catch((res_DGC) => {
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: res_DGC.error })
            us_setDownloadingCSV(true)
          })
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        us_setDownloadingCSV(true)
      })
  }

  // JSX Generation
  const rJSX_DownloadClothingSizesButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="info"
        variant="contained"
        onClick={() => {
          downloadClothingSizes()
        }}
        disableElevation
        startIcon={
          !us_downloadingCSV ? (
            <Icon icon="file-arrow-down" />
          ) : (
            <Icon
              icon="arrows-rotate"
              className="bp_spin tw-mr-2"
            />
          )
        }
        className="tw-mr-2"
        disabled={us_downloadingCSV}
      >
        {rLIB('Download Clothing Sizes')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_TOOLS}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_DownloadClothingSizesButton()}</Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

import { Box, Checkbox, FormControlLabel, Slider, Typography } from '@mui/material'
import { DatabaseRef_SalesEstimate_Document } from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'
import { v4 as uuidv4 } from 'uuid'
import {
  TsInterface_ConfigBundle,
  TsInterface_KeyConfigProps,
  TsInterface_SalesOpportunity,
  TsInterface_SelectedConfigKeys,
  TsInterface_SystemConfig,
} from '../interfaces/i_sales_tools'
import { getMatchingConfig } from '../sales_opportunity_view'
import { rJSX_LoadingBox } from './helper'

const updateRelevantStates = (
  config: TsInterface_UnspecifiedObject,
  dropdownSetter: React.Dispatch<React.SetStateAction<TsInterface_ConfigBundle>>,
  visibleKeysSetter: React.Dispatch<React.SetStateAction<TsInterface_SelectedConfigKeys>>,
  opportunitySetter: React.Dispatch<React.SetStateAction<TsInterface_SalesOpportunity>>,
  selectedKeys: TsInterface_SelectedConfigKeys,
) => {
  // update the dropdown configs state
  dropdownSetter((prev) => {
    return {
      ...prev,
      [config.key]: config,
    }
  })

  // set the visible estimate keys in state
  visibleKeysSetter((prev) => {
    selectedKeys = { ...prev }

    // replace the previous key with the new one
    if (selectedKeys['position_1'] === prev['position_1']) {
      selectedKeys['position_1'] = config.key
    } else if (selectedKeys['position_2'] === prev['position_2']) {
      selectedKeys['position_2'] = config.key
    } else if (selectedKeys['position_3'] === prev['position_3']) {
      selectedKeys['position_3'] = config.key
    }

    return {
      ...selectedKeys,
    }
  })

  // set the sales opportunity (because we adjusted the visible estimates)
  opportunitySetter((prev) => {
    return {
      ...prev,
      visible_estimates: { ...selectedKeys },
    }
  })
}

const editConfiguration = (
  config: TsInterface_SystemConfig,
  setter: React.Dispatch<React.SetStateAction<TsInterface_SystemConfig>>,
  dbInfo: TsInterface_UnspecifiedObject,
  dropdownState: TsInterface_ConfigBundle,
  dropdownSetter: React.Dispatch<React.SetStateAction<TsInterface_ConfigBundle>>,
  visibleKeysSetter: React.Dispatch<React.SetStateAction<TsInterface_SelectedConfigKeys>>,
  opportunitySetter: React.Dispatch<React.SetStateAction<TsInterface_SalesOpportunity>>,
  selectedConfigsLoadedSetter: React.Dispatch<React.SetStateAction<boolean>>,
  editProp: string,
  newValue: any,
  subProp: string = '',
): void => {
  // use to update the config in the database
  const setMergeDoc = (dbInfo: TsInterface_UnspecifiedObject, customConfig: TsInterface_SystemConfig): void => {
    getClientKey(dbInfo['client_key'], dbInfo['client_key_setter'])
      .then((res_GCK) => {
        const estimateKey: string = getProp(customConfig, 'key', null)
        DatabaseSetMergeDocument(DatabaseRef_SalesEstimate_Document(res_GCK.clientKey, dbInfo['opportunity_key'], estimateKey), customConfig).catch(
          (rej_DSMD) => {
            dbInfo['error_display']({ display: true, error: rej_DSMD.error })
          },
        )
      })
      .catch((rej_GCK) => {
        dbInfo['error_display']({ display: true, error: rej_GCK.error })
      })
  }

  let updatedConfig: TsInterface_SystemConfig = {}
  let selectedKeys: TsInterface_SelectedConfigKeys = {}
  let newConfig: TsInterface_SystemConfig = {}
  let customConfig: TsInterface_UnspecifiedObject = {}
  let configAlreadyExists = false
  const newKey = uuidv4()

  // set the state (and update db if necessary)
  setter((prevConfig) => {
    customConfig = { ...prevConfig }

    // determine if the config is default or custom
    if (getProp(prevConfig, 'is_default', true)) {
      customConfig['is_default'] = false
    }

    // set the new value of the prop
    if (subProp && subProp !== 'remove_roof' && subProp !== 'remove_trees' && subProp !== 'extended_warranty') {
      //TODO: need to make this generally usable in the event there are multiple nested props
      customConfig[editProp][subProp] = newValue
    } else {
      customConfig[editProp] = newValue
    }

    // if the edit prop is one of the key configuration props, then we need to either choose an existing configuration
    // or create a new configuration in the state and in the db
    if (editProp === 'battery_quantity' || editProp === 'financing_duration') {
      // if we are changing from battery to no battery or vice versa, we need to change the battery value
      if (
        editProp === 'battery_quantity' &&
        ((customConfig['battery_quantity'] > 0 && getProp(prevConfig, 'battery_quantity', null) === 0) ||
          (getProp(prevConfig, 'battery_quantity', null) > 0 && customConfig['battery_quantity'] === 0))
      ) {
        customConfig['battery'] = !customConfig['battery'] // change the value of the battery prop on the config
      }

      if (editProp === 'financing_duration') {
        customConfig['financing_duration'] = newValue
      }

      // first, search to see if we can find a matching config from our dropdowns. If not, create a new one in state
      const customConfigKeyProps: TsInterface_KeyConfigProps = {
        financing_type: customConfig['financing_type'],
        financing_duration: customConfig['financing_duration'],
        battery: customConfig['battery'],
      }

      let existingConfig: TsInterface_UnspecifiedObject = getMatchingConfig(customConfigKeyProps, dropdownState)
      configAlreadyExists = Object.keys(existingConfig).length > 0 ? true : false
      // if we were able to find a match, we will update that configuration - this means that the existing configuration will inherit
      // the properties of the custom configuration
      // TODO: revisit this for dupes
      if (configAlreadyExists) {
        updatedConfig = { ...customConfig, key: existingConfig.key }
        return updatedConfig
      } else {
        // if we couldn't find a match, we create a new configuration and upload it into the state
        newConfig = { ...customConfig, key: newKey }
        return newConfig
      }
    } else {
      // this conditional will fire if the attribute being modified is not a key attribute
      return customConfig
    }
  })

  // set the other relevant states if we are modifying a key attribute
  if (editProp === 'battery_quantity' || editProp === 'financing_duration') {
    // set the dropdown configurations based on whether we created a new config or are updating a config
    if (!configAlreadyExists) {
      updatedConfig = { ...newConfig }
    }
    // update relevant states
    updateRelevantStates(updatedConfig, dropdownSetter, visibleKeysSetter, opportunitySetter, selectedKeys)
    // perform a setmerge on the db to persist our changes
    setMergeDoc(dbInfo, updatedConfig)
  } else {
    // update relevant states
    updateRelevantStates(customConfig, dropdownSetter, visibleKeysSetter, opportunitySetter, selectedKeys)
    // this conditional will fire if the attribute being modified is not a key attribute
    setMergeDoc(dbInfo, customConfig)
  }
  // this will trigger a refresh of all adjustable components on the comparison page
  selectedConfigsLoadedSetter(false)
}

export const rJSX_Slider = (
  title: string,
  min: number,
  max: number,
  step: number,
  defaultValue: number,
  configBundle: TsInterface_UnspecifiedObject = {},
  editProp: string,
  shouldRender: boolean,
) => {
  let slider = (
    <Box
      sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.1)',
        borderRadius: 4,
        width: '100%',
        marginX: 'auto',
      }}
    >
      <Box sx={{ paddingX: 2 }}>
        <Typography
          className="tw-mx-auto"
          id="input-slider"
          gutterBottom
        >
          {title}
        </Typography>
        {shouldRender ? (
          <Slider
            sx={{ height: 10, width: '100%', marginX: 'auto' }}
            defaultValue={defaultValue}
            valueLabelDisplay="auto"
            step={step}
            marks
            min={min}
            max={max}
            onChangeCommitted={(e: any, value: any) => {
              editConfiguration(
                configBundle['config'],
                configBundle['setter'],
                configBundle['db_info'],
                configBundle['dropdown_state'],
                configBundle['dropdown_setter'],
                configBundle['visible_keys_setter'],
                configBundle['opportunity_setter'],
                configBundle['selected_configs_loaded_setter'],
                editProp,
                value,
              )
            }}
          />
        ) : (
          rJSX_LoadingBox('50px', '100%')
        )}
      </Box>
    </Box>
  )
  return slider
}

export const rJSX_CheckBoxes = (
  defaultBoxConfigs: TsInterface_UnspecifiedObject,
  displayInRow: boolean = true,
  configBundle: TsInterface_UnspecifiedObject, // state, will be used to overwrite some of the default data when rendering
  editProp: string,
  shouldRender: boolean,
): JSX.Element => {
  let style = {}
  style = displayInRow
    ? { display: 'flex', justifyContent: 'space-around', marginX: 'auto' }
    : { display: 'flex', flexDirection: 'column', alignItems: 'space-around', marginX: 'auto' }

  return (
    <Box sx={style}>
      {Object.entries(defaultBoxConfigs).map((bc) => {
        const key = bc[0]
        const defConfig = bc[1] // the default configuration to be used initially
        let isChecked: boolean = false
        const config = getProp(configBundle, 'config', editProp)

        // some of the props are nested one layer deep
        if (config && (editProp === 'amenities' || editProp === 'mount' || editProp === 'escalator')) {
          isChecked = getProp(config, key, defConfig['isCheckedByDefault'])
        } else if (config) {
          isChecked = getProp(config, editProp, defConfig['isCheckedByDefault'])
        }

        return (
          <FormControlLabel
            control={
              shouldRender ? (
                <Checkbox
                  defaultChecked={isChecked}
                  onChange={(e, checked) => {
                    editConfiguration(
                      configBundle['config'],
                      configBundle['setter'],
                      configBundle['db_info'],
                      configBundle['dropdown_state'],
                      configBundle['dropdown_setter'],
                      configBundle['visible_keys_setter'],
                      configBundle['opportunity_setter'],
                      configBundle['selected_configs_loaded_setter'],
                      editProp,
                      checked,
                      key,
                    )
                  }}
                />
              ) : (
                rJSX_LoadingBox('20px', '100%')
              )
            }
            label={defConfig['label']}
            key={key}
          />
        )
      })}
    </Box>
  )
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, ButtonGroup, Stack, Tooltip, Typography } from '@mui/material/'
import { useContext, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_SalesOpportunityDiscoverySession_Document,
  DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_Document,
  DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_VisitCompletion_Document,
  DatabaseRef_SalesOpportunity_DiscoverySession_Query,
} from 'rfbp_aux/services/database_endpoints/sales/opportunities'
import { Icon } from 'rfbp_core/components/icons'
import { MapBasic, TsInterface_MapMarkers } from 'rfbp_core/components/map'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog } from 'rfbp_core/services/context'
import {
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  DatabaseSetMergeDocument,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { capitalizeFirstLetterInString } from 'rfbp_core/services/helper_functions/capitalize_first_letter_in_string'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['SalesDoorhangerRoutePage']['key']

// TEMP
// const routeOptimizationRequest = {
//   parent: 'projects/etw-energy-staging',
//   model: {
//     globalStartTime: {
//       _seconds: 1722470400,
//       _nanoseconds: 0,
//     },
//     globalEndTime: {
//       _seconds: 1722556800,
//       _nanoseconds: 0,
//     },
//     shipments: [
//       {
//         label: 'Shipment 0',
//         pickups: [],
//         deliveries: [
//           {
//             id: 'task-0',
//             label: 'Task 0',
//             arrivalLocation: {
//               latitude: 41.7152115,
//               longitude: -88.223374,
//             },
//             visitDuration: {
//               seconds: 60,
//             },
//             address: {
//               type: 'Feature',
//               properties: {
//                 id: '',
//                 unit: '',
//                 number: '2623',
//                 street: 'DREXEL LN',
//                 city: 'NAPERVILLE',
//                 district: '',
//                 region: '',
//                 postcode: '60564',
//                 state: 'IL',
//                 hash: '187a94abbbfa250d',
//               },
//               geometry: { type: 'Point', coordinates: [-88.223374, 41.7152115] },
//             },
//           },
//         ],
//       },
//       {
//         label: 'Shipment 1',
//         pickups: [],
//         deliveries: [
//           {
//             id: 'task-1',
//             label: 'Task 1',
//             arrivalLocation: {
//               latitude: 41.7477315,
//               longitude: -88.1178694,
//             },
//             visitDuration: {
//               seconds: 60,
//             },
//             address: {
//               type: 'Feature',
//               properties: {
//                 id: '',
//                 unit: '',
//                 number: '864',
//                 street: 'HEATHERFIELD CR',
//                 city: 'NAPERVILLE',
//                 district: '',
//                 region: '',
//                 postcode: '60565',
//                 state: 'IL',
//                 hash: '52609f1b040d1db2',
//               },
//               geometry: { type: 'Point', coordinates: [-88.1178694, 41.7477315] },
//             },
//           },
//         ],
//       },
//       {
//         label: 'Shipment 2',
//         pickups: [],
//         deliveries: [
//           {
//             id: 'task-2',
//             label: 'Task 2',
//             arrivalLocation: {
//               latitude: 41.6922958,
//               longitude: -88.2063644,
//             },
//             visitDuration: {
//               seconds: 60,
//             },
//             address: {
//               type: 'Feature',
//               properties: {
//                 id: '',
//                 unit: '',
//                 number: '4197',
//                 street: 'ROYAL MEWS CR',
//                 city: 'NAPERVILLE',
//                 district: '',
//                 region: '',
//                 postcode: '60564',
//                 state: 'IL',
//                 hash: 'ce37a6e20d378780',
//               },
//               geometry: { type: 'Point', coordinates: [-88.2063644, 41.6922958] },
//             },
//           },
//         ],
//       },
//       {
//         label: 'Shipment 3',
//         pickups: [],
//         deliveries: [
//           {
//             id: 'task-3',
//             label: 'Task 3',
//             arrivalLocation: {
//               latitude: 41.7199507,
//               longitude: -88.1737444,
//             },
//             visitDuration: {
//               seconds: 60,
//             },
//             address: {
//               type: 'Feature',
//               properties: {
//                 id: '',
//                 unit: '',
//                 number: '1294',
//                 street: 'MEYER CT',
//                 city: 'NAPERVILLE',
//                 district: '',
//                 region: '',
//                 postcode: '60564',
//                 state: 'IL',
//                 hash: 'bc04431853b7a9e9',
//               },
//               geometry: { type: 'Point', coordinates: [-88.1737444, 41.7199507] },
//             },
//           },
//         ],
//       },
//       {
//         label: 'Shipment 4',
//         pickups: [],
//         deliveries: [
//           {
//             id: 'task-4',
//             label: 'Task 4',
//             arrivalLocation: {
//               latitude: 41.7299173,
//               longitude: -88.0986184,
//             },
//             visitDuration: {
//               seconds: 60,
//             },
//             address: {
//               type: 'Feature',
//               properties: {
//                 id: '',
//                 unit: '',
//                 number: '1635',
//                 street: 'PATHWAY DR',
//                 city: 'NAPERVILLE',
//                 district: '',
//                 region: '',
//                 postcode: '60565',
//                 state: 'IL',
//                 hash: '235e0cd44ea1dbae',
//               },
//               geometry: { type: 'Point', coordinates: [-88.0986184, 41.7299173] },
//             },
//           },
//         ],
//       },
//       {
//         label: 'Shipment 5',
//         pickups: [],
//         deliveries: [
//           {
//             id: 'task-5',
//             label: 'Task 5',
//             arrivalLocation: {
//               latitude: 41.7229145,
//               longitude: -88.1257681,
//             },
//             visitDuration: {
//               seconds: 60,
//             },
//             address: {
//               type: 'Feature',
//               properties: {
//                 id: '',
//                 unit: '',
//                 number: '1635',
//                 street: 'PATHWAY DR',
//                 city: 'NAPERVILLE',
//                 district: '',
//                 region: '',
//                 postcode: '60565',
//                 state: 'IL',
//                 hash: '235e0cd44ea1dbae',
//               },
//               geometry: { type: 'Point', coordinates: [-88.0986184, 41.7299173] },
//             },
//           },
//         ],
//       },
//       {
//         label: 'Shipment 6',
//         pickups: [],
//         deliveries: [
//           {
//             id: 'task-6',
//             label: 'Task 6',
//             arrivalLocation: {
//               latitude: 41.790547,
//               longitude: -88.2191332,
//             },
//             visitDuration: {
//               seconds: 60,
//             },
//             address: {
//               type: 'Feature',
//               properties: {
//                 id: '',
//                 unit: '',
//                 number: '1501',
//                 street: 'FAIRWAY DR',
//                 city: 'NAPERVILLE',
//                 district: '',
//                 region: '',
//                 postcode: '60563',
//                 state: 'IL',
//                 hash: 'd7af81abb53962b5',
//               },
//               geometry: { type: 'Point', coordinates: [-88.2191332, 41.790547] },
//             },
//           },
//         ],
//       },
//       {
//         label: 'Shipment 7',
//         pickups: [],
//         deliveries: [
//           {
//             id: 'task-7',
//             label: 'Task 7',
//             arrivalLocation: {
//               latitude: 41.7002987,
//               longitude: -88.2023847,
//             },
//             visitDuration: {
//               seconds: 60,
//             },
//             address: {
//               type: 'Feature',
//               properties: {
//                 id: '',
//                 unit: '',
//                 number: '2828',
//                 street: 'LANCELOT LN',
//                 city: 'NAPERVILLE',
//                 district: '',
//                 region: '',
//                 postcode: '60564',
//                 state: 'IL',
//                 hash: 'bb1f0f7d61fab6a2',
//               },
//               geometry: { type: 'Point', coordinates: [-88.2023847, 41.7002987] },
//             },
//           },
//         ],
//       },
//       {
//         label: 'Shipment 8',
//         pickups: [],
//         deliveries: [
//           {
//             id: 'task-8',
//             label: 'Task 8',
//             arrivalLocation: {
//               latitude: 41.6960761,
//               longitude: -88.2012733,
//             },
//             visitDuration: {
//               seconds: 60,
//             },
//             address: {
//               type: 'Feature',
//               properties: {
//                 id: '',
//                 unit: '',
//                 number: '3723',
//                 street: 'QUICK FIRE DR',
//                 city: 'NAPERVILLE',
//                 district: '',
//                 region: '',
//                 postcode: '60564',
//                 state: 'IL',
//                 hash: '0cced47552f4af07',
//               },
//               geometry: { type: 'Point', coordinates: [-88.2012733, 41.6960761] },
//             },
//           },
//         ],
//       },
//       {
//         label: 'Shipment 9',
//         pickups: [],
//         deliveries: [
//           {
//             id: 'task-9',
//             label: 'Task 9',
//             arrivalLocation: {
//               latitude: 41.6999567,
//               longitude: -88.1985562,
//             },
//             visitDuration: {
//               seconds: 60,
//             },
//             address: {
//               type: 'Feature',
//               properties: {
//                 id: '',
//                 unit: '',
//                 number: '3644',
//                 street: 'LIME LIGHT DR',
//                 city: 'NAPERVILLE',
//                 district: '',
//                 region: '',
//                 postcode: '60564',
//                 state: 'IL',
//                 hash: '675ee2fc31eb8d70',
//               },
//               geometry: { type: 'Point', coordinates: [-88.1985562, 41.6999567] },
//             },
//           },
//         ],
//       },
//       {
//         label: 'Shipment 10',
//         pickups: [],
//         deliveries: [
//           {
//             id: 'task-10',
//             label: 'Task 10',
//             arrivalLocation: {
//               latitude: 41.7101769,
//               longitude: -88.2082311,
//             },
//             visitDuration: {
//               seconds: 60,
//             },
//             address: {
//               type: 'Feature',
//               properties: {
//                 id: '',
//                 unit: '',
//                 number: '3056',
//                 street: 'ANNA MARIE LN',
//                 city: 'NAPERVILLE',
//                 district: '',
//                 region: '',
//                 postcode: '60564',
//                 state: 'IL',
//                 hash: '65dfe4f34c680603',
//               },
//               geometry: { type: 'Point', coordinates: [-88.2082311, 41.7101769] },
//             },
//           },
//         ],
//       },
//     ],
//     vehicles: [
//       {
//         id: 'vehicle-0',
//         label: 'Vehicle 0',
//         startLocation: {
//           latitude: 41.7013518,
//           longitude: -88.1990534,
//         },
//         endLocation: {
//           latitude: 41.7013518,
//           longitude: -88.1990534,
//         },
//         costPerKilometer: 1,
//         capacity: 1000,
//       },
//     ],
//     globalDurationCostPerHour: 1,
//   },
// }
// const routeOptimizationResults = {
//   routes: [
//     {
//       visits: [
//         {
//           loadDemands: {},
//           shipmentIndex: 9,
//           isPickup: false,
//           visitRequestIndex: 0,
//           startTime: {
//             seconds: '1722470428',
//             nanos: 0,
//           },
//           detour: {
//             seconds: '0',
//             nanos: 0,
//           },
//           shipmentLabel: 'Shipment 9',
//           visitLabel: 'Task 9',
//         },
//         {
//           loadDemands: {},
//           shipmentIndex: 7,
//           isPickup: false,
//           visitRequestIndex: 0,
//           startTime: {
//             seconds: '1722470493',
//             nanos: 0,
//           },
//           detour: {
//             seconds: '0',
//             nanos: 0,
//           },
//           shipmentLabel: 'Shipment 7',
//           visitLabel: 'Task 7',
//         },
//         {
//           loadDemands: {},
//           shipmentIndex: 3,
//           isPickup: false,
//           visitRequestIndex: 0,
//           startTime: {
//             seconds: '1722470962',
//             nanos: 0,
//           },
//           detour: {
//             seconds: '48',
//             nanos: 0,
//           },
//           shipmentLabel: 'Shipment 3',
//           visitLabel: 'Task 3',
//         },
//         {
//           loadDemands: {},
//           shipmentIndex: 5,
//           isPickup: false,
//           visitRequestIndex: 0,
//           startTime: {
//             seconds: '1722471516',
//             nanos: 0,
//           },
//           detour: {
//             seconds: '259',
//             nanos: 0,
//           },
//           shipmentLabel: 'Shipment 5',
//           visitLabel: 'Task 5',
//         },
//         {
//           loadDemands: {},
//           shipmentIndex: 4,
//           isPickup: false,
//           visitRequestIndex: 0,
//           startTime: {
//             seconds: '1722471800',
//             nanos: 0,
//           },
//           detour: {
//             seconds: '382',
//             nanos: 0,
//           },
//           shipmentLabel: 'Shipment 4',
//           visitLabel: 'Task 4',
//         },
//         {
//           loadDemands: {},
//           shipmentIndex: 1,
//           isPickup: false,
//           visitRequestIndex: 0,
//           startTime: {
//             seconds: '1722472143',
//             nanos: 0,
//           },
//           detour: {
//             seconds: '666',
//             nanos: 0,
//           },
//           shipmentLabel: 'Shipment 1',
//           visitLabel: 'Task 1',
//         },
//         {
//           loadDemands: {},
//           shipmentIndex: 6,
//           isPickup: false,
//           visitRequestIndex: 0,
//           startTime: {
//             seconds: '1722473390',
//             nanos: 0,
//           },
//           detour: {
//             seconds: '1922',
//             nanos: 0,
//           },
//           shipmentLabel: 'Shipment 6',
//           visitLabel: 'Task 6',
//         },
//         {
//           loadDemands: {},
//           shipmentIndex: 10,
//           isPickup: false,
//           visitRequestIndex: 0,
//           startTime: {
//             seconds: '1722474317',
//             nanos: 0,
//           },
//           detour: {
//             seconds: '3627',
//             nanos: 0,
//           },
//           shipmentLabel: 'Shipment 10',
//           visitLabel: 'Task 10',
//         },
//         {
//           loadDemands: {},
//           shipmentIndex: 0,
//           isPickup: false,
//           visitRequestIndex: 0,
//           startTime: {
//             seconds: '1722474610',
//             nanos: 0,
//           },
//           detour: {
//             seconds: '3728',
//             nanos: 0,
//           },
//           shipmentLabel: 'Shipment 0',
//           visitLabel: 'Task 0',
//         },
//         {
//           loadDemands: {},
//           shipmentIndex: 2,
//           isPickup: false,
//           visitRequestIndex: 0,
//           startTime: {
//             seconds: '1722475020',
//             nanos: 0,
//           },
//           detour: {
//             seconds: '4419',
//             nanos: 0,
//           },
//           shipmentLabel: 'Shipment 2',
//           visitLabel: 'Task 2',
//         },
//         {
//           loadDemands: {},
//           shipmentIndex: 8,
//           isPickup: false,
//           visitRequestIndex: 0,
//           startTime: {
//             seconds: '1722475165',
//             nanos: 0,
//           },
//           detour: {
//             seconds: '4629',
//             nanos: 0,
//           },
//           shipmentLabel: 'Shipment 8',
//           visitLabel: 'Task 8',
//         },
//       ],
//       transitions: [
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '28',
//             nanos: 0,
//           },
//           travelDistanceMeters: 145,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '28',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722470400',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '65',
//             nanos: 0,
//           },
//           travelDistanceMeters: 402,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '65',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722470428',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '469',
//             nanos: 0,
//           },
//           travelDistanceMeters: 5021,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '469',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722470493',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '554',
//             nanos: 0,
//           },
//           travelDistanceMeters: 5650,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '554',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722470962',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '284',
//             nanos: 0,
//           },
//           travelDistanceMeters: 3453,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '284',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722471516',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '343',
//             nanos: 0,
//           },
//           travelDistanceMeters: 3575,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '343',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722471800',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '1247',
//             nanos: 0,
//           },
//           travelDistanceMeters: 14190,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '1247',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722472143',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '927',
//             nanos: 0,
//           },
//           travelDistanceMeters: 10228,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '927',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722473390',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '293',
//             nanos: 0,
//           },
//           travelDistanceMeters: 2333,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '293',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722474317',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '410',
//             nanos: 0,
//           },
//           travelDistanceMeters: 4445,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '410',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722474610',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '145',
//             nanos: 0,
//           },
//           travelDistanceMeters: 832,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '145',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722475020',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//         {
//           vehicleLoads: {},
//           travelDuration: {
//             seconds: '163',
//             nanos: 0,
//           },
//           travelDistanceMeters: 814,
//           trafficInfoUnavailable: false,
//           delayDuration: null,
//           breakDuration: null,
//           waitDuration: {
//             seconds: '0',
//             nanos: 0,
//           },
//           totalDuration: {
//             seconds: '163',
//             nanos: 0,
//           },
//           startTime: {
//             seconds: '1722475165',
//             nanos: 0,
//           },
//           routePolyline: null,
//         },
//       ],
//       breaks: [],
//       routeCosts: {
//         'model.vehicles.cost_per_kilometer': 51.088,
//       },
//       vehicleIndex: 0,
//       vehicleLabel: 'Vehicle 0',
//       vehicleStartTime: {
//         seconds: '1722470400',
//         nanos: 0,
//       },
//       vehicleEndTime: {
//         seconds: '1722475328',
//         nanos: 0,
//       },
//       hasTrafficInfeasibilities: false,
//       routePolyline: null,
//       metrics: {
//         maxLoads: {},
//         performedShipmentCount: 11,
//         travelDuration: {
//           seconds: '4928',
//           nanos: 0,
//         },
//         waitDuration: {
//           seconds: '0',
//           nanos: 0,
//         },
//         delayDuration: {
//           seconds: '0',
//           nanos: 0,
//         },
//         breakDuration: {
//           seconds: '0',
//           nanos: 0,
//         },
//         visitDuration: {
//           seconds: '0',
//           nanos: 0,
//         },
//         totalDuration: {
//           seconds: '4928',
//           nanos: 0,
//         },
//         travelDistanceMeters: 51088,
//       },
//       routeTotalCost: 51.088,
//     },
//   ],
//   skippedShipments: [],
//   validationErrors: [],
//   requestLabel: '',
//   metrics: {
//     costs: {
//       'model.vehicles.cost_per_kilometer': 51.088,
//       'model.global_duration_cost_per_hour': 1.3688888888888888,
//     },
//     aggregatedRouteMetrics: {
//       maxLoads: {},
//       performedShipmentCount: 11,
//       travelDuration: {
//         seconds: '4928',
//         nanos: 0,
//       },
//       waitDuration: {
//         seconds: '0',
//         nanos: 0,
//       },
//       delayDuration: {
//         seconds: '0',
//         nanos: 0,
//       },
//       breakDuration: {
//         seconds: '0',
//         nanos: 0,
//       },
//       visitDuration: {
//         seconds: '0',
//         nanos: 0,
//       },
//       totalDuration: {
//         seconds: '4928',
//         nanos: 0,
//       },
//       travelDistanceMeters: 51088,
//     },
//     skippedMandatoryShipmentCount: 0,
//     usedVehicleCount: 1,
//     earliestVehicleStartTime: {
//       seconds: '1722470400',
//       nanos: 0,
//     },
//     latestVehicleEndTime: {
//       seconds: '1722475328',
//       nanos: 0,
//     },
//     totalCost: 52.45688888888889,
//   },
// }

///////////////////////////////
// Functions
///////////////////////////////

const parseRouteOptimizationResults = (
  routeOptimizationRoute: TsInterface_UnspecifiedObject,
  routeOptimizationAddresses: TsInterface_UnspecifiedObject,
  routeVisitCompletion: TsInterface_UnspecifiedObject,
): TsInterface_UnspecifiedObject => {
  let steps: TsInterface_UnspecifiedObject[] = []
  if (routeOptimizationRoute != null && routeOptimizationRoute.visits != null && routeOptimizationRoute.transitions != null) {
    for (let i = 0; i < routeOptimizationRoute.visits.length; i++) {
      let visit = routeOptimizationRoute.visits[i]
      let transition = routeOptimizationRoute.transitions[i]
      let matchingRequestStop: TsInterface_UnspecifiedObject = {}
      if (visit != null && visit.visit_key != null && routeOptimizationAddresses != null && routeOptimizationAddresses[visit.visit_key] != null) {
        matchingRequestStop = routeOptimizationAddresses[visit.visit_key]
      }
      let cleanVisit = {
        stop_index: i,
        type: 'stop',
        latitude: matchingRequestStop.latitude,
        longitude: matchingRequestStop.longitude,
        address: matchingRequestStop.address1,
        city: matchingRequestStop.city,
        state: matchingRequestStop.state,
        zip: matchingRequestStop.postcode,
        // Completion
        completed: getProp(routeVisitCompletion, i.toString(), false),
      }
      let cleanTransition = {
        stop_index: i,
        type: 'travel',
        // Miles from meters
        distance: transition.distance_meters / 1609.34,
        time: parseFloat(transition.duration) / 60,
        // Completion
        completed: getProp(routeVisitCompletion, i.toString(), false),
      }
      if (i === 0) {
        // First step
        steps.push(cleanVisit)
      } else if (i === routeOptimizationRoute.visits.length - 1) {
        // Last Stop
        steps.push(cleanTransition)
        steps.push(cleanVisit)
      } else {
        // Middle Steps
        steps.push(cleanTransition)
        steps.push(cleanVisit)
      }
    }
  }

  return steps
}

const returnMapIcons = (iconKey: string): TsInterface_UnspecifiedObject => {
  let icon: TsInterface_UnspecifiedObject = {}
  switch (iconKey) {
    case 'pin':
      icon = {
        path: 'M25,1C19.486,1 15,5.486 15,11C15,16.514 19.486,21 25,21C30.514,21 35,16.514 35,11C35,5.486 30.514,1 25,1ZM25,5C25.552,5 26,5.447 26,6C26,6.553 25.552,7 25,7C22.794,7 21,8.794 21,11C21,11.553 20.552,12 20,12C19.448,12 19,11.553 19,11C19,7.691 21.691,5 25,5ZM22,22.605L22,43.244L25,48.99L28,43.244L28,22.605C27.039,22.854 26.037,23 25,23C23.963,23 22.961,22.854 22,22.605Z',
        scale: 1,
        anchor: new google.maps.Point(20, 48),
      }
      break
    case 'circle':
      icon = {
        path: 'M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z',
        scale: 0.6,
        anchor: new google.maps.Point(14, 14),
      }
      break
    default:
      icon = {
        path: 'M399.999,0C240.037,0 110.867,129.17 110.867,289.132C110.867,344.905 128.684,398.161 153.472,441.926L341.902,768.044C353.715,789.347 377.535,799.998 399.999,799.998C422.465,799.998 445.121,789.347 458.096,768.044L646.526,442.12C671.508,398.351 689.131,346.063 689.131,289.321C689.131,129.364 559.961,0 399.999,0ZM399.999,374.535C341.902,374.535 294.455,327.089 294.455,268.991C294.455,210.894 341.902,163.448 399.999,163.448C458.096,163.448 505.543,210.894 505.543,268.991C505.543,327.282 458.096,374.535 399.999,374.535Z',
        scale: 0.05,
        anchor: new google.maps.Point(400, 800),
      }
  }
  return icon
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  const [us_activeRouteGroupColor, us_setActiveRouteGroupColor] = useState<string | null>(null)
  const [us_activeRouteNumber, us_setActiveRouteNumber] = useState<string | null>(null)
  const [us_blueGroupAddresses, us_setBlueGroupAddresses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_blueOptimizedRoutes, us_setBlueOptimizedRoutes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_colorGroupAddresses, us_setColorGroupAddresses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_discoverySession, us_setDiscoverySession] = useState<TsInterface_UnspecifiedObject>({})
  const [us_greenGroupAddresses, us_setGreenGroupAddresses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_greenOptimizedRoutes, us_setGreenOptimizedRoutes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_mapPins, us_setMapPins] = useState<TsInterface_MapMarkers>({})
  const [us_optimizedRoute, us_setOptimizedRoute] = useState<TsInterface_UnspecifiedObject>({})
  const [us_pageType, us_setPageType] = useState<string>('route')
  const [us_routeKey, us_setRouteKey] = useState<string | null>(null)
  const [us_routeVisitCompletion, us_setRouteVisitCompletion] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedRouteOptimizationKey, us_setSelectedRouteOptimizationKey] = useState<string | null>(null)
  const [us_visitedStepsVisibility, us_setVisitedStepsVisibility] = useState<boolean>(true)
  const [us_yellowGroupAddresses, us_setYellowGroupAddresses] = useState<TsInterface_UnspecifiedObject>({})
  const [us_yellowOptimizedRoutes, us_setYellowOptimizedRoutes] = useState<TsInterface_UnspecifiedObject>({})
  const ul_queryParams = new URLSearchParams(useLocation().search)
  const un_routerNavigation = useNavigate()
  const ur_mapRef = useRef(null)
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Paper Route', false) as string
  }, [])

  useEffect(() => {
    if (ul_queryParams != null && ul_queryParams.get('route') != null) {
      let routeKey = ul_queryParams.get('route')
      if (routeKey != null) {
        us_setRouteKey(routeKey)
        let groupColor = routeKey.split('_')[0]
        let routeNumber = routeKey.split('_')[1]
        us_setActiveRouteGroupColor(groupColor)
        us_setActiveRouteNumber(routeNumber)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setDiscoverySession(newData)
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_SalesOpportunityDiscoverySession_Document(res_GCK.clientKey, itemKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRouteVisitCompletion(newData)
    }
    if (us_routeKey != null && us_selectedRouteOptimizationKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_VisitCompletion_Document(
              res_GCK.clientKey,
              itemKey,
              us_selectedRouteOptimizationKey,
              us_routeKey,
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay, us_routeKey, us_selectedRouteOptimizationKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setBlueOptimizedRoutes(newData)
    }
    if (us_activeRouteGroupColor === 'blue' && us_discoverySession != null && us_discoverySession['route_optimization_calculated_blue_key']) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_Document(
              res_GCK.clientKey,
              itemKey,
              us_discoverySession['route_optimization_calculated_blue_key'],
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    } else {
      us_setBlueOptimizedRoutes({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay, us_activeRouteGroupColor, us_discoverySession])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setGreenOptimizedRoutes(newData)
    }
    if (us_activeRouteGroupColor === 'green' && us_discoverySession != null && us_discoverySession['route_optimization_calculated_green_key']) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_Document(
              res_GCK.clientKey,
              itemKey,
              us_discoverySession['route_optimization_calculated_green_key'],
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    } else {
      us_setGreenOptimizedRoutes({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay, us_discoverySession, us_activeRouteGroupColor])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setYellowOptimizedRoutes(newData)
    }
    if (us_activeRouteGroupColor === 'yellow' && us_discoverySession != null && us_discoverySession['route_optimization_calculated_yellow_key']) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_Document(
              res_GCK.clientKey,
              itemKey,
              us_discoverySession['route_optimization_calculated_yellow_key'],
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    } else {
      us_setYellowOptimizedRoutes({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay, us_discoverySession, us_activeRouteGroupColor])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setBlueGroupAddresses(newData)
    }
    if (us_activeRouteGroupColor === 'blue') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
            { prop: 'associated_discovery_session_key', comparator: '==', value: itemKey },
            { prop: 'associated_campaign_group_color', comparator: '==', value: 'blue' },
          ]
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_SalesOpportunity_DiscoverySession_Query(res_GCK.clientKey, queryOperatorsArray, null),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    } else {
      us_setBlueGroupAddresses({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay, us_activeRouteGroupColor])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setGreenGroupAddresses(newData)
    }
    if (us_activeRouteGroupColor === 'green') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
            { prop: 'associated_discovery_session_key', comparator: '==', value: itemKey },
            { prop: 'associated_campaign_group_color', comparator: '==', value: 'green' },
          ]
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_SalesOpportunity_DiscoverySession_Query(res_GCK.clientKey, queryOperatorsArray, null),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    } else {
      us_setGreenGroupAddresses({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay, us_activeRouteGroupColor])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setYellowGroupAddresses(newData)
    }
    if (us_activeRouteGroupColor === 'yellow') {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
            { prop: 'associated_discovery_session_key', comparator: '==', value: itemKey },
            { prop: 'associated_campaign_group_color', comparator: '==', value: 'yellow' },
          ]
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_SalesOpportunity_DiscoverySession_Query(res_GCK.clientKey, queryOperatorsArray, null),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    } else {
      us_setYellowGroupAddresses({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [itemKey, uc_RootData_ClientKey, uc_setRootData_ClientKey, uc_setUserInterface_ErrorDialogDisplay, us_activeRouteGroupColor])

  useEffect(() => {
    let addresses: TsInterface_UnspecifiedObject = {}
    let optimizedRoutes: TsInterface_UnspecifiedObject = {}
    let selectedRoute: TsInterface_UnspecifiedObject = {}
    let selectedRouteOptimizationKey = null
    switch (us_activeRouteGroupColor) {
      case 'blue':
        addresses = us_blueGroupAddresses
        optimizedRoutes = us_blueOptimizedRoutes
        if (us_discoverySession != null && us_discoverySession['route_optimization_calculated_blue_key'] != null) {
          selectedRouteOptimizationKey = us_discoverySession['route_optimization_calculated_blue_key']
        }
        break
      case 'green':
        addresses = us_greenGroupAddresses
        optimizedRoutes = us_greenOptimizedRoutes
        if (us_discoverySession != null && us_discoverySession['route_optimization_calculated_green_key'] != null) {
          selectedRouteOptimizationKey = us_discoverySession['route_optimization_calculated_green_key']
        }
        break
      case 'yellow':
        addresses = us_yellowGroupAddresses
        optimizedRoutes = us_yellowOptimizedRoutes
        if (us_discoverySession != null && us_discoverySession['route_optimization_calculated_yellow_key'] != null) {
          selectedRouteOptimizationKey = us_discoverySession['route_optimization_calculated_yellow_key']
        }
        break
      default:
        break
    }
    if (
      us_activeRouteGroupColor != null &&
      us_activeRouteNumber != null &&
      optimizedRoutes != null &&
      optimizedRoutes['routes'] != null &&
      optimizedRoutes['routes'][us_activeRouteNumber.toString()] != null
    ) {
      selectedRoute = optimizedRoutes['routes'][us_activeRouteNumber.toString()]
    }
    // Parse Route Steps
    us_setOptimizedRoute(selectedRoute)
    us_setColorGroupAddresses(addresses)
    us_setSelectedRouteOptimizationKey(selectedRouteOptimizationKey)
    return () => {}
  }, [
    us_activeRouteGroupColor,
    us_blueGroupAddresses,
    us_blueOptimizedRoutes,
    us_greenGroupAddresses,
    us_greenOptimizedRoutes,
    us_yellowGroupAddresses,
    us_yellowOptimizedRoutes,
    us_activeRouteNumber,
    us_discoverySession,
  ])

  useEffect(() => {
    let mapPins: TsInterface_MapMarkers = {}
    let strokeMultiplier = 2
    let scaleMultiplier = 2.5

    console.log(us_optimizedRoute)

    for (let loopVisitIndex in getProp(us_optimizedRoute, 'visits', [])) {
      let loopVisit = getProp(us_optimizedRoute, 'visits', [])[loopVisitIndex]
      let fillColor = themeVariables.error_main
      let lat = null
      let lng = null
      if (
        loopVisit.visit_key != null &&
        us_blueGroupAddresses[loopVisit.visit_key] != null &&
        us_blueGroupAddresses[loopVisit.visit_key].latitude != null &&
        us_blueGroupAddresses[loopVisit.visit_key].longitude != null
      ) {
        fillColor = themeVariables.info_main
        lat = us_blueGroupAddresses[loopVisit.visit_key].latitude
        lng = us_blueGroupAddresses[loopVisit.visit_key].longitude
      } else if (
        loopVisit.visit_key != null &&
        us_greenGroupAddresses[loopVisit.visit_key] != null &&
        us_greenGroupAddresses[loopVisit.visit_key].latitude != null &&
        us_greenGroupAddresses[loopVisit.visit_key].longitude != null
      ) {
        fillColor = themeVariables.success_main
        lat = us_greenGroupAddresses[loopVisit.visit_key].latitude
        lng = us_greenGroupAddresses[loopVisit.visit_key].longitude
      } else if (
        loopVisit.visit_key != null &&
        us_yellowGroupAddresses[loopVisit.visit_key] != null &&
        us_yellowGroupAddresses[loopVisit.visit_key].latitude != null &&
        us_yellowGroupAddresses[loopVisit.visit_key].longitude != null
      ) {
        fillColor = themeVariables.warning_main
        lat = us_yellowGroupAddresses[loopVisit.visit_key].latitude
        lng = us_yellowGroupAddresses[loopVisit.visitIndex].longitude
      }
      if (lat != null && lng != null) {
        mapPins[loopVisitIndex] = {
          label: {
            text: (parseInt(loopVisitIndex) + 1).toString(),
            color: 'white',
          },
          icon: {
            path: returnMapIcons('circle')['path'],
            fillColor: fillColor,
            fillOpacity: 1,
            strokeWeight: 0.5 * strokeMultiplier,
            strokeColor: 'white',
            rotation: 0,
            scale: returnMapIcons('circle')['scale'] * scaleMultiplier,
            anchor: returnMapIcons('circle')['anchor'],
            labelOrigin: new google.maps.Point(12, 12),
          },
          position: {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          },
        }
      }
    }
    us_setMapPins(mapPins)
    setTimeout(() => {
      // @ts-expect-error - TODO: reason for error
      if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.mapCenter != null) {
        // @ts-expect-error - TODO: reason for error
        if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
          // @ts-expect-error - TODO: reason for error
          ur_mapRef.current.recalculateMapBounds(true)
        }
      }
    }, 0)
  }, [us_blueGroupAddresses, us_discoverySession, us_greenGroupAddresses, us_optimizedRoute, us_yellowGroupAddresses])

  // Functions
  const openMap = (latitude: number, longitude: number, address: string, city: string, state: string, zip: string) => {
    const formattedAddress = `${encodeURIComponent(address)},${encodeURIComponent(city)},${encodeURIComponent(state)},${encodeURIComponent(zip)}`

    // Check if Google Maps is available
    const googleMapsUrl = `comgooglemaps://?daddr=${latitude},${longitude}(${formattedAddress})&directionsmode=driving`
    const appleMapsUrl = `http://maps.apple.com/?daddr=${latitude},${longitude}(${formattedAddress})&dirflg=d`

    // Create a temporary link element to test if Google Maps is available
    const link = document.createElement('a')
    link.href = googleMapsUrl

    // Try opening Google Maps first
    link.onerror = () => {
      // If there's an error (Google Maps is not available), open Apple Maps
      window.open(appleMapsUrl, '_blank')
    }

    // Try opening Google Maps
    window.open(googleMapsUrl, '_blank')
  }

  const saveVisitCompletion = (step: TsInterface_UnspecifiedObject) => {
    let newCompletionStatus = !step.completed
    if (us_activeRouteNumber != null && us_selectedRouteOptimizationKey != null && us_routeKey != null) {
      let updateObject: TsInterface_UnspecifiedObject = {
        [step.stop_index.toString()]: newCompletionStatus,
      }
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseSetMergeDocument(
            DatabaseRef_SalesOpportunityDiscoverySession_RouteOptimization_VisitCompletion_Document(
              res_GCK.clientKey,
              itemKey,
              us_selectedRouteOptimizationKey,
              us_routeKey,
            ),
            updateObject,
          )
            .then((res_DSMD) => {
              // Nothing
            })
            .catch((rej_DSMD) => {
              uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
            })
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
        })
    }
  }

  // JSX Generation
  const rJSX_BackButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="inherit"
          variant="outlined"
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesOpportunityDiscoverySessionViewPage.url(itemKey) + '?tab=Routes')
          }}
          disableElevation
          startIcon={<Icon icon="chevron-left" />}
          className="tw-mr-2"
        >
          {rLIB('Back')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('Back')}
          placement="top"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={(event) => {
              onClickAppNavigation(event, un_routerNavigation, ApplicationPages.SalesOpportunityDiscoverySessionViewPage.url(itemKey) + '?tab=Routes')
            }}
            disableElevation
            className="tw-mr-2 bp_icon_only_button"
          >
            <Icon icon="chevron-left" />
          </Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_PageTypeButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (us_pageType === 'route') {
      buttonJSX = (
        <Button
          color="info"
          variant="contained"
          onClick={(event) => {
            us_setPageType('map')
            setTimeout(() => {
              // @ts-expect-error - TODO: reason for error
              if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.mapCenter != null) {
                // @ts-expect-error - TODO: reason for error
                if (ur_mapRef != null && ur_mapRef.current != null && ur_mapRef.current.recalculateMapBounds != null) {
                  // @ts-expect-error - TODO: reason for error
                  ur_mapRef.current.recalculateMapBounds(true)
                }
              }
            }, 0)
          }}
          disableElevation
          startIcon={<Icon icon="route" />}
          className="tw-mr-2"
        >
          {rLIB('Route')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          color="info"
          variant="contained"
          onClick={(event) => {
            us_setPageType('route')
          }}
          disableElevation
          startIcon={<Icon icon="map" />}
          className="tw-mr-2"
        >
          {rLIB('Map')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_ViewCompletedStepsToggleButtonGroup = (): JSX.Element => {
    let buttonGroupJSX = (
      <ButtonGroup>
        <Button
          color="secondary"
          variant={us_visitedStepsVisibility ? 'contained' : 'outlined'}
          startIcon={<Icon icon="circle-check"></Icon>}
          onClick={() => {
            us_setVisitedStepsVisibility(!us_visitedStepsVisibility)
          }}
        >
          {rLIB('Show Completed Steps')}
        </Button>
        <Button
          color="secondary"
          variant={us_visitedStepsVisibility ? 'outlined' : 'contained'}
          startIcon={<Icon icon="filter"></Icon>}
          onClick={() => {
            us_setVisitedStepsVisibility(!us_visitedStepsVisibility)
          }}
        >
          {rLIB('Hide Completed Steps')}
        </Button>
      </ButtonGroup>
    )
    return buttonGroupJSX
  }

  const rJSX_RouteSteps = (step: TsInterface_UnspecifiedObject): JSX.Element => {
    let stepJSX = <></>
    if (step.type === 'stop') {
      stepJSX = (
        <Box
          sx={{
            border: '1px solid rgba(255,255,255,0.3)',
            backgroundColor: step.completed ? themeVariables.success_main : themeVariables.background_paper,
          }}
          className="tw-m-2 tw-p-4 tw-rounded"
        >
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Stack
              direction="row"
              spacing={1}
            >
              <Box
                className="tw-mr-1"
                sx={{ fontSize: '36px', color: themeVariables.error_main, fontWeight: 'bold' }}
              >
                {step.stop_index + 1}
              </Box>
              {/* <Icon
                icon="map-pin"
                sx={{ fontSize: '24px', color: themeVariables.error_main }}
              /> */}
              <Box>
                <Typography
                  variant="body1"
                  sx={{ color: themeVariables.white }}
                >
                  {step.address}
                </Typography>
                <Typography
                  variant="body1"
                  className="tw-opacity-30"
                  sx={{ color: themeVariables.white }}
                >
                  {step.city}
                </Typography>
              </Box>
            </Stack>
            <Box>
              {step.completed !== true ? (
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  className="bp_icon_only_button tw-mr-2"
                  sx={{ margin: '0px' }}
                  disableElevation
                  onClick={() => {
                    // Open Map
                    openMap(step.latitude, step.longitude, step.address, step.city, step.state, step.zip)
                  }}
                >
                  <Icon icon="map" />
                </Button>
              ) : (
                <></>
              )}
              <Button
                variant={step.completed ? 'contained' : 'outlined'}
                color="success"
                size="small"
                className="bp_icon_only_button"
                sx={{ margin: '0px' }}
                disableElevation
                onClick={() => {
                  saveVisitCompletion(step)
                }}
              >
                <Icon icon="check-circle" />
              </Button>
            </Box>
          </Stack>
        </Box>
      )
    } else if (step.type === 'travel') {
      stepJSX = (
        <Box
          sx={{
            border: '1px solid rgba(255,255,255,0.3)',
            backgroundColor: step.completed ? themeVariables.success_main : themeVariables.background_default,
          }}
          className="tw-m-2 tw-p-4 tw-rounded"
        >
          <Stack
            direction="row"
            spacing={1}
          >
            <Icon
              icon="directions"
              sx={{ fontSize: '24px', color: themeVariables.warning_main }}
            />
            <Box>
              <Typography
                variant="body1"
                sx={{ color: themeVariables.white }}
              >
                {step.distance.toFixed(1)} {rLIB('Miles')}
                <Box
                  component={'span'}
                  sx={{ color: themeVariables.white, opacity: 0.4, marginLeft: '8px' }}
                >
                  ({step.time.toFixed(1)} {rLIB('min')})
                </Box>
              </Typography>
            </Box>
          </Stack>
        </Box>
      )
    }
    if (us_visitedStepsVisibility === false && step.completed === true) {
      stepJSX = <></>
    }
    return stepJSX
  }

  const rJSX_RouteList = (): JSX.Element => {
    let steps = parseRouteOptimizationResults(us_optimizedRoute, us_colorGroupAddresses, us_routeVisitCompletion)
    let routeListJSX = (
      <Box>
        <Box sx={{ maxWidth: '500px', margin: 'auto' }}>
          {steps.map((step: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>{rJSX_RouteSteps(step)}</Box>
          ))}
        </Box>
      </Box>
    )
    return routeListJSX
  }

  const rJSX_PageHeader = (): JSX.Element => {
    let pageHeaderJSX = <>{rLIB('Paper Route')}</>
    if (us_activeRouteGroupColor != null && us_activeRouteNumber != null) {
      pageHeaderJSX = (
        <>
          {rLIB('Paper Route')}: {capitalizeFirstLetterInString(us_activeRouteGroupColor)} {us_activeRouteNumber}
        </>
      )
    }
    return pageHeaderJSX
  }

  const rJSX_MapView = (): JSX.Element => {
    let mapViewJSX = (
      <Box>
        <MapBasic
          mapSettings={{
            center_lat: 0,
            center_lng: 0,
            height: 'calc( 100vh - 200px )',
            render_clusters: false,
            render_heatmap: false,
            render_markers: true,
            width: '100%',
            zoom: 12,
          }}
          mapMarkers={us_mapPins}
          ref={ur_mapRef}
        />
      </Box>
    )
    return mapViewJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rJSX_PageHeader()}
        pageKey={pageKey}
        content={
          <Box>
            <Box className="tw-mb-2">
              {rJSX_BackButton(false)}
              {rJSX_PageTypeButton()}
            </Box>
            {us_pageType === 'map' ? <Box>{rJSX_MapView()}</Box> : <></>}
            {us_pageType === 'route' ? (
              <Box>
                <Box className="tw-text-center tw-my-4">{rJSX_ViewCompletedStepsToggleButtonGroup()}</Box>
                {rJSX_RouteList()}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Descrition
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

			Filter by supplier
			Filter by order or no order

	*/

///////////////////////////////
// Imports
///////////////////////////////

import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages, schedulingTeamTypes } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ActiveRegions_Query } from 'rfbp_aux/services/database_endpoints/directory/regions'
import { DatabaseRef_OrdersScheduledBetweenDates_Query, DatabaseRef_Orders_Collection } from 'rfbp_aux/services/database_endpoints/materials/orders'
import { DatabaseRef_MaterialUserPoNumber_Document } from 'rfbp_aux/services/database_endpoints/materials/po_number_counters'
import { DatabaseRef_MaterialsReceiptUploads_Collection } from 'rfbp_aux/services/database_endpoints/materials/receipt_uploads'
import {
  DatabaseRef_SkuLineItemsForProject_DateRage_Query,
  DatabaseRef_SkuLineItemsForSupplier_DateRage_Query,
  DatabaseRef_SkuLineItemsForUser_DateRange_Query,
} from 'rfbp_aux/services/database_endpoints/materials/sku_line_items'
import {
  DatabaseRef_ActiveSuppliers_Query,
  DatabaseRef_SuppliersWithSpecificPO_Query,
  DatabaseRef_Suppliers_Collection,
  DatabaseRef_Supplier_Document,
} from 'rfbp_aux/services/database_endpoints/materials/suppliers'
import { DatabaseRef_SchedulingActiveTeams_Query } from 'rfbp_aux/services/database_endpoints/operations/schedules'
import { DatabaseRef_ScheduledTasksInDateRange_Query, DatabaseRef_Task_Document } from 'rfbp_aux/services/database_endpoints/operations/tasks'
import { DatabaseRef_ClientUser_Document } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import { Json } from 'rfbp_core/components/code_display'
import { DatePicker } from 'rfbp_core/components/date_picker'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { TsInterface_InputHooksObject } from 'rfbp_core/components/form/form_types'
import { Icon } from 'rfbp_core/components/icons'
import { rJSX_HighlightedSearchString, SearchInput } from 'rfbp_core/components/search'
import { TableBasic, TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_UserInterface_ErrorDialog, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import {
  DatabaseAddDocument,
  DatabaseGetCollection,
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseTransactionIncrement,
  generateDatabaseQuery,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import {
  cloneObjectWithoutReference,
  dynamicSort,
  getProp,
  objectToArray,
  returnFormattedDate,
  returnFormattedDateKey,
} from 'rfbp_core/services/helper_functions'
import { capitalizeAllWordsInString } from 'rfbp_core/services/helper_functions/capitalize_all_words_in_string'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { getStartAndEndOfWeek } from '../payroll/data/payroll_calculations'
import { formInputs_Supplier } from './forms/form_new_supplier'
import { formInputs_OrderPageFilters } from './forms/form_order_page_filters'
import { tableColumns_MaterialOrders, tableSettings_MaterialOrders } from './tables/table_material_orders'
import { tableColumns_MaterialReceipts, tableSettings_MaterialReceipts } from './tables/table_material_receipts'
import { tableColumns_MaterialSuppliers, tableSettings_MaterialSuppliers } from './tables/table_material_suppliers'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseMaterialsIndexPage']['key']

// Form Inputs
const formInputs_AnalysisFilter: TsInterface_FormInputs = {
  filter_type: {
    data_type: 'string',
    input_type: 'multiple_choice_radio',
    key: 'filter_type',
    options: [
      { key: 'project', value: rLIB('Project') },
      { key: 'user', value: rLIB('User') },
      { key: 'supplier', value: rLIB('Supplier') },
    ],
    label: rLIB('Filter Type'),
    required: true,
  },
  associated_project_key: {
    data_type: 'string',
    key: 'associated_project_key',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      return <></>
    },
  },
  associated_project_id_number: {
    data_type: 'string',
    key: 'associated_project_id_number',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      // Search Result JSX
      const rJSX_ProjectSearchResult = (
        option: TsInterface_UnspecifiedObject,
        searchInputValue: string | null,
        inputHooks: TsInterface_InputHooksObject,
        additionalSearchData: TsInterface_UnspecifiedObject,
      ): JSX.Element => {
        let searchResultJSX = (
          <Box
            onClick={() => {
              formInputChange('associated_project_key', option.id, true)
              formInputChange('associated_project_id_number', option.id_number, true)
              inputHooks.us_setSearchInputValue(option.id_number)
              inputHooks.us_setOpen(false)
            }}
            sx={{ marginLeft: '8px', marginRight: '8px', marginTop: '12px' }}
          >
            {rJSX_HighlightedSearchString(searchInputValue, option.id_number)}
            <Divider />
          </Box>
        )
        return searchResultJSX
      }
      // Input JSX
      let inputJSX = (
        <Box className="tw-text-left">
          <SearchInput
            defaultSearchValue={getProp(formData, 'associated_project_id_number', null)}
            clientKey={formAdditionalData.clientKey as string}
            searchIndexKey={'projects'}
            searchFilters={[]}
            searchResultRenderer={rJSX_ProjectSearchResult}
            additionalSearchData={{}}
          />
        </Box>
      )
      return inputJSX
    },
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'filter_type',
        comparator: '==',
        value: 'project',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'filter_type',
        comparator: '==',
        value: 'project',
        conditions: [],
      },
    },
  },
  associated_user_key: {
    data_type: 'string',
    key: 'associated_user_key',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      return <></>
    },
  },
  associated_user_name: {
    data_type: 'string',
    key: 'associated_user_name',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: false,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
      // Search Result JSX
      const rJSX_UserSearchResult = (
        option: TsInterface_UnspecifiedObject,
        searchInputValue: string | null,
        inputHooks: TsInterface_InputHooksObject,
        additionalSearchData: TsInterface_UnspecifiedObject,
      ): JSX.Element => {
        let searchResultJSX = (
          <Box
            onClick={() => {
              formInputChange('associated_user_key', option.id, true)
              formInputChange('associated_user_name', option.name, true)
              inputHooks.us_setSearchInputValue(option.name)
              inputHooks.us_setOpen(false)
            }}
            sx={{ marginLeft: '8px', marginRight: '8px', marginTop: '12px' }}
          >
            {rJSX_HighlightedSearchString(searchInputValue, option.name)}
            <Divider />
          </Box>
        )
        return searchResultJSX
      }
      // Input JSX
      let inputJSX = (
        <Box className="tw-text-left">
          <SearchInput
            defaultSearchValue={getProp(formData, 'associated_user_name', null)}
            clientKey={formAdditionalData.clientKey as string}
            searchIndexKey={'users'}
            searchFilters={[]}
            searchResultRenderer={rJSX_UserSearchResult}
            additionalSearchData={{}}
          />
        </Box>
      )
      return inputJSX
    },
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'filter_type',
        comparator: '==',
        value: 'user',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'filter_type',
        comparator: '==',
        value: 'user',
        conditions: [],
      },
    },
  },
  associated_supplier_key: {
    data_type: 'string',
    key: 'associated_supplier_key',
    input_type: 'multiple_choice_select',
    options: [],
    label: rLIB('Supplier'),
    required: false,
    conditional_display: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'filter_type',
        comparator: '==',
        value: 'supplier',
        conditions: [],
      },
    },
    conditional_require: {
      active: true,
      logic: {
        active: true,
        logic_type: 'comparison',
        source: 'formData',
        prop: 'filter_type',
        comparator: '==',
        value: 'supplier',
        conditions: [],
      },
    },
  },
}

// Analysis
const processSkuLineItemData = (skuLineItems: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let processedSkuLineItems: TsInterface_UnspecifiedObject = {
    totals: {},
    orders: {},
    projects: {},
    suppliers: {},
    users: {},
    skus: {},
  }
  // Loop through sku line items
  for (let skuLineItemKey in skuLineItems) {
    let skuLineItem = skuLineItems[skuLineItemKey]
    // Increment number of orders
    if (skuLineItem != null) {
      // Resused Variables
      let estimatedTotalCost =
        getProp(skuLineItem, 'associated_sku_pick_estimated_price_per_package', 0) * getProp(skuLineItem, 'associated_sku_pick_requested_quantity', 0)
      // Projects
      if (skuLineItem['associated_project_key'] != null && processedSkuLineItems['projects'][skuLineItem['associated_project_key']] == null) {
        processedSkuLineItems['projects'][skuLineItem['associated_project_key']] = {
          estimated_total_cost: 0,
          skus: {},
        }
      }
      processedSkuLineItems['projects'][skuLineItem['associated_project_key']].estimated_total_cost += estimatedTotalCost
      // Users
      if (skuLineItem['associated_user_key'] != null && processedSkuLineItems['users'][skuLineItem['associated_user_key']] == null) {
        processedSkuLineItems['users'][skuLineItem['associated_user_key']] = {
          estimated_total_cost: 0,
          skus: {},
        }
      }
      processedSkuLineItems['users'][skuLineItem['associated_user_key']].estimated_total_cost += estimatedTotalCost
      // Suppliers
      if (skuLineItem['associated_supplier_key'] != null && processedSkuLineItems['suppliers'][skuLineItem['associated_supplier_key']] == null) {
        processedSkuLineItems['suppliers'][skuLineItem['associated_supplier_key']] = {
          estimated_total_cost: 0,
          skus: {},
        }
      }
      processedSkuLineItems['suppliers'][skuLineItem['associated_supplier_key']].estimated_total_cost += estimatedTotalCost
      // Orders
      if (skuLineItem['associated_order_key'] != null && processedSkuLineItems['orders'][skuLineItem['associated_order_key']] == null) {
        processedSkuLineItems['orders'][skuLineItem['associated_order_key']] = {
          estimated_total_cost: 0,
          skus: {},
        }
      }
      processedSkuLineItems['orders'][skuLineItem['associated_order_key']].estimated_total_cost += estimatedTotalCost
      // Skus
      if (skuLineItem['associated_sku_key'] != null && processedSkuLineItems['skus'][skuLineItem['associated_sku_key']] == null) {
        processedSkuLineItems['skus'][skuLineItem['associated_sku_key']] = {
          count: 0,
          estimated_total_cost: 0,
        }
      }
      if (skuLineItem['associated_sku_estimated_photo_url'] != null) {
        processedSkuLineItems['skus'][skuLineItem['associated_sku_key']].photo_url = skuLineItem['associated_sku_estimated_photo_url']
      }
      processedSkuLineItems['skus'][skuLineItem['associated_sku_key']].count += getProp(skuLineItem, 'SALES', 0)
      processedSkuLineItems['skus'][skuLineItem['associated_sku_key']].estimated_total_cost += estimatedTotalCost
    }
  }
  return processedSkuLineItems
}

///////////////////////////////
// Functions
///////////////////////////////

function timeToDecimal(time: string | number): number {
  if (typeof time === 'number') {
    return time
  } else {
    const [hoursMinutes, amPm] = time.split(' ')
    const [hours, minutes] = hoursMinutes.split(':')
    let hoursInDecimal = (parseInt(hours, 10) % 12) + parseInt(minutes, 10) / 60
    if (amPm != null) {
      return amPm.toLowerCase() === 'pm' ? hoursInDecimal + 12 : hoursInDecimal
    } else {
      if (time.toLowerCase().includes('am')) {
        // Nothing
      } else if (time.toLowerCase().includes('pm')) {
        hoursInDecimal += 12
      }
      return hoursInDecimal
    }
  }
}

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  // const params = useParams()
  // const itemKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeTeams, us_setActiveTeams] = useState<TsInterface_UnspecifiedObject>({})
  const [us_allActiveSuppliers, us_setAllActiveSuppliers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_analysisFilterData, us_setAnalysisFilterData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_analysisLoaded, us_setAnalysisLoaded] = useState<boolean>(false)
  const [us_calendarEvents, us_setCalendarEvents] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filterSelected, us_setFilterSelected] = useState<boolean>(false)
  const [us_filterableTeamOptions, us_setFilterableTeamOptions] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredFriTasks, us_setFilteredFriTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredMonTasks, us_setFilteredMonTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredSatTasks, us_setFilteredSatTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredSunTasks, us_setFilteredSunTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredThuTasks, us_setFilteredThuTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredTueTasks, us_setFilteredTueTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_filteredWedTasks, us_setFilteredWedTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_ordersSortedByTasks, us_setOrdersSortedByTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_pageFilterData, us_setPageFilterData] = useState<TsInterface_UnspecifiedObject>({ order_status: 'not_deleted' })
  const [us_processedAnalysisData, us_setProcessedAnalysisData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_rawAnalysisData, us_setRawAnalysisData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_regionLists, us_setRegionLists] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedDate, us_setSelectedDate] = useState<Date>(new Date())
  const [us_selectedEndDate, us_setSelectedEndDate] = useState<Date>(new Date())
  const [us_selectedRegion, us_setSelectedRegion] = useState<string>('ALL_REGIONS')
  const [us_selectedStartDate, us_setSelectedStartDate] = useState<Date>(new Date(new Date().getTime() - 7 * 86400000))
  const [us_selectedTeamsFilter, us_setSelectedTeamsFilter] = useState<string>('')
  const [us_unlinkedFriOrders, us_setUnlinkedFriOrders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unlinkedMonOrders, us_setUnlinkedMonOrders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unlinkedSatOrders, us_setUnlinkedSatOrders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unlinkedSunOrders, us_setUnlinkedSunOrders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unlinkedThuOrders, us_setUnlinkedThuOrders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unlinkedTueOrders, us_setUnlinkedTueOrders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unlinkedWedOrders, us_setUnlinkedWedOrders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_visibleTeamsTypesFilter, us_setVisibleTeamsTypesFilter] = useState<string[]>(['electrician', 'install_crew', 'repair', 'warehouse'])
  const [us_weekEndDateKey, us_setWeekEndDateKey] = useState<string | null>(null)
  const [us_weekOrdersFilteredData, us_setWeekOrdersFilteredData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekOrdersRawData, us_setWeekOrdersRawData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekOrdersTableData, us_setWeekOrdersTableData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_weekStartDateKey, us_setWeekStartDateKey] = useState<string | null>(null)
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    document.title = rLIB('Materials', false) as string
  }, [])

  useEffect(() => {
    let filteredTableOrderData: TsInterface_UnspecifiedObject = {}
    for (let loopOrderKey in us_weekOrdersFilteredData) {
      let loopOrder = us_weekOrdersFilteredData[loopOrderKey]
      switch (getProp(us_pageFilterData, 'order_status', null)) {
        case 'not_deleted':
          if (loopOrder.status !== 'deleted') {
            filteredTableOrderData[loopOrderKey] = loopOrder
          }
          break
        case 'all':
          filteredTableOrderData[loopOrderKey] = loopOrder
          break
        case 'locked':
          if (loopOrder.status === 'locked') {
            filteredTableOrderData[loopOrderKey] = loopOrder
          }
          break
        case 'new':
          if (loopOrder.status === 'new') {
            filteredTableOrderData[loopOrderKey] = loopOrder
          }
          break
      }
    }
    us_setWeekOrdersTableData(filteredTableOrderData)
  }, [us_pageFilterData, us_weekOrdersFilteredData])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setActiveTeams(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_SchedulingActiveTeams_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let filteredOrderData: TsInterface_UnspecifiedObject = {}
    for (let loopOrderKey in us_weekOrdersRawData) {
      let loopOrder = us_weekOrdersRawData[loopOrderKey]
      if (
        us_selectedRegion === 'ALL_REGIONS' ||
        (loopOrder != null &&
          loopOrder.associated_team_key != null &&
          us_activeTeams[loopOrder.associated_team_key] != null &&
          us_activeTeams[loopOrder.associated_team_key].associated_region_key === us_selectedRegion) ||
        (loopOrder != null &&
          loopOrder.associated_task_key != null &&
          us_calendarEvents != null &&
          us_calendarEvents[loopOrder.associated_task_key] != null &&
          us_calendarEvents[loopOrder.associated_task_key].associated_team_key != null &&
          us_activeTeams[us_calendarEvents[loopOrder.associated_task_key].associated_team_key] != null &&
          us_activeTeams[us_calendarEvents[loopOrder.associated_task_key].associated_team_key].associated_region_key === us_selectedRegion)
      ) {
        filteredOrderData[loopOrderKey] = loopOrder
      }
    }

    us_setWeekOrdersFilteredData(filteredOrderData)
    return () => {}
  }, [us_activeTeams, us_calendarEvents, us_selectedRegion, us_weekOrdersRawData])

  useEffect(() => {
    let visibleTeams: TsInterface_UnspecifiedObject = {}
    for (let loopTeamKey in us_activeTeams) {
      let loopTeam = us_activeTeams[loopTeamKey]
      if (
        loopTeam != null &&
        (us_visibleTeamsTypesFilter.length === 0 || us_visibleTeamsTypesFilter.includes(loopTeam.team_type)) &&
        (us_selectedRegion === 'ALL_REGIONS' || (loopTeam.associated_region_key != null && loopTeam.associated_region_key === us_selectedRegion))
      ) {
        visibleTeams[loopTeamKey] = loopTeam
      }
    }
    us_setFilterableTeamOptions(visibleTeams)
  }, [us_activeTeams, us_selectedRegion, us_visibleTeamsTypesFilter])

  useEffect(() => {
    let dayOfWeek = us_selectedDate.getDay()
    let sunDate = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth(), us_selectedDate.getDate() - dayOfWeek)
    // let sunDateKey = returnFormattedDateKey( sunDate )
    let monDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 1))
    let tueDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 2))
    let wedDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 3))
    let thuDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 4))
    let friDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 5))
    let satDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 6))
    let sunDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 7))
    let monTasks: TsInterface_UnspecifiedObject = {}
    let tueTasks: TsInterface_UnspecifiedObject = {}
    let wedTasks: TsInterface_UnspecifiedObject = {}
    let thuTasks: TsInterface_UnspecifiedObject = {}
    let friTasks: TsInterface_UnspecifiedObject = {}
    let satTasks: TsInterface_UnspecifiedObject = {}
    let sunTasks: TsInterface_UnspecifiedObject = {}
    for (let loopTaskKey in us_calendarEvents) {
      let loopTask = us_calendarEvents[loopTaskKey]
      if (
        loopTask != null &&
        loopTask.task_completion_scheduled_dates != null &&
        schedulingTeamTypes != null &&
        loopTask.associated_team_type != null &&
        schedulingTeamTypes[loopTask.associated_team_type] != null &&
        // schedulingTeamTypes[ loopTask.associated_team_type ]["include_in_material_calendar"] === true &&
        (us_visibleTeamsTypesFilter.length === 0 || us_visibleTeamsTypesFilter.includes(loopTask.associated_team_type)) &&
        (us_selectedRegion === 'ALL_REGIONS' ||
          (loopTask.associated_team_key != null &&
            us_activeTeams[loopTask.associated_team_key] != null &&
            us_activeTeams[loopTask.associated_team_key].associated_region_key === us_selectedRegion)) &&
        (us_selectedTeamsFilter === '' || loopTask.associated_team_key === us_selectedTeamsFilter)
      ) {
        for (let loopDateIndex in loopTask.task_completion_scheduled_dates) {
          let loopDate = loopTask.task_completion_scheduled_dates[loopDateIndex]
          if (loopDate === monDateKey) {
            monTasks[loopTaskKey] = { key: loopTaskKey, associated_project_id_number: loopTask.associated_project_id_number }
          } else if (loopDate === tueDateKey) {
            tueTasks[loopTaskKey] = { key: loopTaskKey, associated_project_id_number: loopTask.associated_project_id_number }
          } else if (loopDate === wedDateKey) {
            wedTasks[loopTaskKey] = { key: loopTaskKey, associated_project_id_number: loopTask.associated_project_id_number }
          } else if (loopDate === thuDateKey) {
            thuTasks[loopTaskKey] = { key: loopTaskKey, associated_project_id_number: loopTask.associated_project_id_number }
          } else if (loopDate === friDateKey) {
            friTasks[loopTaskKey] = { key: loopTaskKey, associated_project_id_number: loopTask.associated_project_id_number }
          } else if (loopDate === satDateKey) {
            satTasks[loopTaskKey] = { key: loopTaskKey, associated_project_id_number: loopTask.associated_project_id_number }
          } else if (loopDate === sunDateKey) {
            sunTasks[loopTaskKey] = { key: loopTaskKey, associated_project_id_number: loopTask.associated_project_id_number }
          }
        }
      }
    }
    us_setFilteredMonTasks(monTasks)
    us_setFilteredTueTasks(tueTasks)
    us_setFilteredWedTasks(wedTasks)
    us_setFilteredThuTasks(thuTasks)
    us_setFilteredFriTasks(friTasks)
    us_setFilteredSatTasks(satTasks)
    us_setFilteredSunTasks(sunTasks)
    return () => {}
  }, [us_activeTeams, us_calendarEvents, us_selectedDate, us_selectedRegion, us_selectedTeamsFilter, us_visibleTeamsTypesFilter])

  useEffect(() => {
    let dayOfWeek = us_selectedDate.getDay()
    let sunDate = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth(), us_selectedDate.getDate() - dayOfWeek)
    // let sunDateKey = returnFormattedDateKey( sunDate )
    let monDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 1))
    let tueDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 2))
    let wedDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 3))
    let thuDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 4))
    let friDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 5))
    let satDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 6))
    let sunDateKey = returnFormattedDateKey(new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 7))
    let monUnlinkedOrders: TsInterface_UnspecifiedObject = {}
    let tueUnlinkedOrders: TsInterface_UnspecifiedObject = {}
    let wedUnlinkedOrders: TsInterface_UnspecifiedObject = {}
    let thuUnlinkedOrders: TsInterface_UnspecifiedObject = {}
    let friUnlinkedOrders: TsInterface_UnspecifiedObject = {}
    let satUnlinkedOrders: TsInterface_UnspecifiedObject = {}
    let sunUnlinkedOrders: TsInterface_UnspecifiedObject = {}
    for (let loopOrderKey in us_weekOrdersFilteredData) {
      let loopOrder = us_weekOrdersFilteredData[loopOrderKey]
      let orderPickupDate = loopOrder.timestamp_scheduled_pickup_date_key
      if (loopOrder.status !== 'deleted') {
        if (orderPickupDate === monDateKey && (loopOrder.associated_task_key == null || us_filteredMonTasks[loopOrder.associated_task_key] == null)) {
          monUnlinkedOrders[loopOrderKey] = loopOrder
        } else if (orderPickupDate === tueDateKey && (loopOrder.associated_task_key == null || us_filteredTueTasks[loopOrder.associated_task_key] == null)) {
          tueUnlinkedOrders[loopOrderKey] = loopOrder
        } else if (orderPickupDate === wedDateKey && (loopOrder.associated_task_key == null || us_filteredWedTasks[loopOrder.associated_task_key] == null)) {
          wedUnlinkedOrders[loopOrderKey] = loopOrder
        } else if (orderPickupDate === thuDateKey && (loopOrder.associated_task_key == null || us_filteredThuTasks[loopOrder.associated_task_key] == null)) {
          thuUnlinkedOrders[loopOrderKey] = loopOrder
        } else if (orderPickupDate === friDateKey && (loopOrder.associated_task_key == null || us_filteredFriTasks[loopOrder.associated_task_key] == null)) {
          friUnlinkedOrders[loopOrderKey] = loopOrder
        } else if (orderPickupDate === satDateKey && (loopOrder.associated_task_key == null || us_filteredSatTasks[loopOrder.associated_task_key] == null)) {
          satUnlinkedOrders[loopOrderKey] = loopOrder
        } else if (orderPickupDate === sunDateKey && (loopOrder.associated_task_key == null || us_filteredSunTasks[loopOrder.associated_task_key] == null)) {
          sunUnlinkedOrders[loopOrderKey] = loopOrder
        }
      }
    }
    us_setUnlinkedMonOrders(monUnlinkedOrders)
    us_setUnlinkedTueOrders(tueUnlinkedOrders)
    us_setUnlinkedWedOrders(wedUnlinkedOrders)
    us_setUnlinkedThuOrders(thuUnlinkedOrders)
    us_setUnlinkedFriOrders(friUnlinkedOrders)
    us_setUnlinkedSatOrders(satUnlinkedOrders)
    us_setUnlinkedSunOrders(sunUnlinkedOrders)
  }, [
    us_filteredFriTasks,
    us_filteredMonTasks,
    us_filteredSatTasks,
    us_filteredSunTasks,
    us_filteredThuTasks,
    us_filteredTueTasks,
    us_filteredWedTasks,
    us_selectedDate,
    us_weekOrdersFilteredData,
  ])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      let cleanEvents: TsInterface_UnspecifiedObject = {}
      for (let loopEventKey in newData) {
        let loopEvent = cloneObjectWithoutReference(newData[loopEventKey])
        if (loopEvent['task_completion_scheduled_start_times'] != null) {
          for (let loopDateKey in loopEvent['task_completion_scheduled_start_times']) {
            if (loopEvent['task_completion_scheduled_start_times'][loopDateKey] != null) {
              loopEvent['task_completion_scheduled_start_times'][loopDateKey] = timeToDecimal(loopEvent['task_completion_scheduled_start_times'][loopDateKey])
            }
          }
        }
        if (loopEvent['task_completion_scheduled_end_times'] != null) {
          for (let loopDateKey in loopEvent['task_completion_scheduled_end_times']) {
            if (loopEvent['task_completion_scheduled_end_times'][loopDateKey] != null) {
              loopEvent['task_completion_scheduled_end_times'][loopDateKey] = timeToDecimal(loopEvent['task_completion_scheduled_end_times'][loopDateKey])
            }
          }
        }
        cleanEvents[loopEventKey] = loopEvent
      }
      us_setCalendarEvents(cleanEvents)
      ur_forceRerender()
    }
    // Determine start and end date
    let dayOfWeek = us_selectedDate.getDay()
    let weekStart = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth(), us_selectedDate.getDate() - dayOfWeek - 1)
    let weekEnd = new Date(weekStart.getFullYear(), weekStart.getMonth(), weekStart.getDate() + 8)
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ScheduledTasksInDateRange_Query(res_GCK.clientKey, weekStart, weekEnd), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, us_selectedDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllActiveSuppliers(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveSuppliers_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setWeekOrdersRawData(newData)
      ur_forceRerender()
    }
    if (us_weekStartDateKey != null && us_weekEndDateKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_OrdersScheduledBetweenDates_Query(res_GCK.clientKey, us_weekStartDateKey, us_weekEndDateKey),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekEndDateKey, us_weekStartDateKey])

  useEffect(() => {
    let ordersSortedByTasks: TsInterface_UnspecifiedObject = {}
    for (let loopOrderKey in us_weekOrdersFilteredData) {
      let loopOrder = us_weekOrdersFilteredData[loopOrderKey]
      if (loopOrder != null && loopOrder.associated_task_key != null && us_calendarEvents != null && us_calendarEvents[loopOrder.associated_task_key] != null) {
        if (ordersSortedByTasks[loopOrder.associated_task_key] == null) {
          ordersSortedByTasks[loopOrder.associated_task_key] = {}
        }
        ordersSortedByTasks[loopOrder.associated_task_key][loopOrderKey] = loopOrder
      }
    }
    us_setOrdersSortedByTasks(ordersSortedByTasks)
  }, [us_calendarEvents, us_weekOrdersFilteredData])

  useEffect(() => {
    us_setProcessedAnalysisData(processSkuLineItemData(us_rawAnalysisData))
  }, [us_rawAnalysisData])

  useEffect(() => {
    let weekBoundingDates = getStartAndEndOfWeek(us_selectedDate)
    us_setWeekStartDateKey(returnFormattedDateKey(weekBoundingDates.startOfWeek))
    us_setWeekEndDateKey(returnFormattedDateKey(weekBoundingDates.endOfWeek))
    return () => {}
  }, [us_selectedDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setRegionLists(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveRegions_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const changeCalendarStartDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedStartDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const changeCalendarEndDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedEndDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const tableDatabaseEndpoint_Suppliers = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_Suppliers_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const tableDatabaseEndpoint_Receipts = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'purchase_date', desc: true }]
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 100)
    return generateDatabaseQuery(
      DatabaseRef_MaterialsReceiptUploads_Collection(uc_RootData_ClientKey as string),
      queryOperatorsArray,
      orderByArray,
      queryCursorsObject,
      limit,
    )
  }

  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setSelectedDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const createNewSupplier = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_Supplier,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  // Make sure that the PO abbreviation is unique
                  let promiseArray: TsType_UnknownPromise[] = []
                  let poAbbreviation = formSubmittedData.po_abbreviation.toUpperCase()
                  let matchingSuppliers: TsInterface_UnspecifiedObject = {}
                  promiseArray.push(
                    DatabaseGetCollection(DatabaseRef_SuppliersWithSpecificPO_Query(res_GCK.clientKey, poAbbreviation))
                      .then((res_DGC) => {
                        matchingSuppliers = res_DGC.data
                      })
                      .catch((rej_DGC) => {
                        console.error(rej_DGC)
                      }),
                  )
                  Promise.all(promiseArray).finally(() => {
                    if (objectToArray(matchingSuppliers).length === 0) {
                      let updateObject = { ...formSubmittedData }
                      updateObject.po_abbreviation = poAbbreviation
                      updateObject.status = 'active'
                      // Save to Database
                      DatabaseAddDocument(DatabaseRef_Suppliers_Collection(res_GCK.clientKey), updateObject, true)
                        .then((res_DAD) => {
                          resolve(res_DAD)
                        })
                        .catch((rej_DAD) => {
                          formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DAD.error })
                          reject(rej_DAD)
                        })
                    } else {
                      let error = {
                        message: rLIB('Failed to create new supplier'),
                        details: rLIB('PO abbreviation already used'),
                        code: 'ER-D-MI-CNOP-01',
                      }
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                      reject({ success: false, error: error })
                    }
                  })
                })
                .catch((rej_GCK) => {
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  reject(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('New Supplier'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="circle-plus"
            />
          ),
        },
      },
    })
  }

  const createNewOrderProper = (
    clientKey: string,
    taskKey: string,
    supplierKey: string,
    supplierLocationKey: string,
    userKey: string,
    requestedPickupDateKey: string,
    deliveryType: string,
    deliveryNotes: string | null,
  ): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      if (clientKey != null && taskKey != null && supplierKey != null && userKey != null && requestedPickupDateKey != null) {
        // Make sure that the order is created before 3pm the day before the requested pickup date

        // Instantiate Variables
        let promiseArray: TsType_UnknownPromise[] = []
        let task: TsInterface_UnspecifiedObject = {}
        let supplier: TsInterface_UnspecifiedObject = {}
        let user: TsInterface_UnspecifiedObject = {}
        // Get Task
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_Task_Document(clientKey, taskKey))
            .then((res_GGD) => {
              task = res_GGD.data
            })
            .catch((rej_GGD) => {
              console.error(rej_GGD)
            }),
        )
        // Get Supplier
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_Supplier_Document(clientKey, supplierKey))
            .then((res_GGD) => {
              supplier = res_GGD.data
            })
            .catch((rej_GGD) => {
              console.error(rej_GGD)
            }),
        )
        // Get User
        promiseArray.push(
          DatabaseGetDocument(DatabaseRef_ClientUser_Document(clientKey, userKey))
            .then((res_GGD) => {
              user = res_GGD.data
            })
            .catch((rej_GGD) => {
              console.error(rej_GGD)
            }),
        )
        // After Data Loaded
        Promise.all(promiseArray).finally(() => {
          if (supplier != null && supplier['po_abbreviation'] != null && user != null && user['name_abbreviation'] != null) {
            // Generate Team Members
            let teamUserNames: TsInterface_UnspecifiedObject = {}
            let teamUserKeys: TsInterface_UnspecifiedObject = {}
            for (let loopDateKey in getProp(task, 'task_completion_scheduled_team_names', {})) {
              let loopDate = task.task_completion_scheduled_team_names[loopDateKey]
              for (let loopUserKey in loopDate) {
                teamUserKeys[loopUserKey] = loopUserKey
                let loopUserName = loopDate[loopUserKey]
                if (loopUserName != null) {
                  teamUserNames[loopUserKey] = loopUserName
                }
              }
            }
            // Generate PO Number
            const dateParts = requestedPickupDateKey.split('-')
            const YY = dateParts[0].slice(2) // taking the last two digits of the year
            const MM = dateParts[1]
            let combinedDate = YY + MM
            let basePONumber = supplier['po_abbreviation'] + YY + MM + '-' + user['name_abbreviation'] + '-'
            // Increment PO Number Counter
            DatabaseTransactionIncrement(DatabaseRef_MaterialUserPoNumber_Document(clientKey, user['name_abbreviation'] + '-' + combinedDate), 'counter', 1)
              .then((res_DTI: any) => {
                // Create PO Number
                let incrementNumber = res_DTI.incrementValue
                let numStr = incrementNumber.toString()
                if (numStr.length < 3) {
                  // Calculate the number of leading zeros needed
                  const leadingZeros = 3 - numStr.length
                  // Add leading zeros to the number
                  numStr = '0'.repeat(leadingZeros) + numStr
                }
                let fullPONumber = basePONumber + numStr
                if (deliveryNotes == null) {
                  deliveryNotes = null
                }
                // Create Order
                let updateObject = {
                  associated_project_id_number: getProp(task, 'associated_project_id_number', null),
                  associated_project_key: getProp(task, 'associated_project_key', null),
                  associated_supplier_key: supplierKey,
                  associated_supplier_name: getProp(supplier, 'name', null),
                  associated_supplier_location_key: supplierLocationKey,
                  associated_supplier_location_name: getProp(getProp(getProp(supplier, 'locations', {}), supplierLocationKey, {}), 'name', null),
                  associated_task_key: taskKey,
                  associated_task_name: getProp(task, 'name', null),
                  associated_team_key: getProp(task, 'associated_team_key', null),
                  associated_team_member_names: teamUserNames,
                  associated_team_member_keys: teamUserKeys,
                  associated_team_name: getProp(task, 'associated_team_name', null),
                  associated_user_name: getProp(user, 'name', null),
                  associated_user_key: userKey,
                  delivery_type: deliveryType,
                  delivery_notes: deliveryNotes,
                  email_sent: false,
                  // key: null,
                  po_number: fullPONumber,
                  status: 'new',
                  timestamp_created: new Date(),
                  // timestamp_email_sent: null,
                  // timestamp_scheduled_pickup: new Date( requestedPickupDateKey ),
                  timestamp_scheduled_pickup_date_key: requestedPickupDateKey,
                }
                // // Save to Database
                DatabaseAddDocument(DatabaseRef_Orders_Collection(clientKey), updateObject, true)
                  .then((res_DAD) => {
                    resolve(res_DAD)
                  })
                  .catch((rej_DAD) => {
                    reject(rej_DAD)
                  })
              })
              .catch((rej_DTI) => {
                reject(rej_DTI)
              })
          } else {
            reject({
              success: false,
              error: {
                message: rLIB('Failed to create new order'),
                details: rLIB('Missing Required Parameters'),
                code: 'ER-D-MI-CNOP-01',
              },
            })
          }
        })
      } else {
        reject({
          success: false,
          error: {
            message: rLIB('Failed to create new order'),
            details: rLIB('Missing Required Parameters'),
            code: 'ER-D-MI-CNOP-02',
          },
        })
      }
    })
  }

  const getMinDateForScheduling = (): string => {
    const currentDate = new Date()
    const currentHour = currentDate.getHours()
    // Check if it's past 3pm, if so, return tomorrow's date
    if (currentHour >= 15) {
      currentDate.setDate(currentDate.getDate() + 1)
    }
    currentDate.setDate(currentDate.getDate() + 1)
    // Format the date as yyyy-mm-dd
    const year = currentDate.getFullYear()
    const month = String(currentDate.getMonth() + 1).padStart(2, '0')
    const day = String(currentDate.getDate()).padStart(2, '0')
    return `${year}-${month}-${day}`
  }

  const createNewOrder = (task: TsInterface_UnspecifiedObject): void => {
    let minDate = getMinDateForScheduling()
    let formInputs_NewOrder: TsInterface_FormInputs = {
      timestamp_scheduled_pickup: {
        key: 'timestamp_scheduled_pickup',
        label: rLIB('Pickup Date'),
        required: true,
        data_type: 'string',
        input_type: 'timestamp_date',
        min: minDate,
      },
      header: {
        data_type: 'string',
        key: 'header',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: false,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
          let inputJSX = (
            <Box className="tw-mb-4">
              <Typography
                variant="body1"
                sx={{ color: themeVariables.warning_main }}
                className="tw-mb-2"
              >
                <Icon
                  icon="circle-exclamation"
                  className="tw-mr-2"
                />
                {rLIB('Orders must be placed by 3pm the day before pickup.')}
              </Typography>
            </Box>
          )
          return inputJSX
        },
      },

      // TODO - warning if region doesn't match between task and supplier location

      associated_supplier_key: {
        data_type: 'string',
        key: 'associated_supplier_key',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: true,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
          let inputJSX = (
            <Box className="tw-mt-4">
              <Box className="tw-opacity-70">{rLIB('Supplier')}</Box>
              <FormControl>
                <RadioGroup
                  onChange={(event) => {
                    formInputChange('associated_supplier_key', event.target.value, true)
                    formInputChange('associated_supplier_location_key', null, true)
                    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                      .then((res_GCK) => {
                        DatabaseGetDocument(DatabaseRef_Supplier_Document(res_GCK.clientKey, event.target.value))
                          .then((res_DGD) => {
                            let supplier = res_DGD.data
                            let supplierLocations: TsInterface_UnspecifiedObject = {}
                            for (let loopLocationKey in getProp(supplier, 'locations', {})) {
                              let loopLocation = supplier.locations[loopLocationKey]
                              if (loopLocation != null && loopLocation.status === 'active') {
                                supplierLocations[loopLocationKey] = { key: loopLocationKey, value: loopLocation.name }
                              }
                            }
                            // formAdditionalData['associated_supplier_location_options'] = supplierLocations
                            formInputChange('associated_supplier_location_options', supplierLocations, true)
                          })
                          .catch((rej_DGD) => {
                            console.error(rej_DGD)
                          })
                      })
                      .catch((rej_GCK) => {
                        console.error(rej_GCK)
                      })
                  }}
                  value={formData.associated_supplier_key || ''}
                >
                  {objectToArray(getProp(formAdditionalData, 'associated_supplier_options', {}))
                    .sort(dynamicSort('name', 'asc'))
                    .map((supplier, index) => (
                      <Box
                        className="tw-ml-8 tw-block"
                        key={index}
                      >
                        <FormControlLabel
                          value={supplier.key}
                          control={<Radio />}
                          label={supplier.value}
                        />
                      </Box>
                    ))}
                </RadioGroup>
              </FormControl>
            </Box>
          )
          return inputJSX
        },
      },
      associated_supplier_location_key: {
        data_type: 'string',
        key: 'associated_supplier_location_key',
        input_type: 'custom_form_input_jsx',
        label: <></>,
        required: true,
        renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData) => {
          let inputJSX = (
            <Box className="tw-mt-4">
              <Box className="tw-opacity-70">{rLIB('Supplier Location')}</Box>
              <FormControl>
                <RadioGroup
                  onChange={(event) => {
                    formInputChange('associated_supplier_location_key', event.target.value, true)
                  }}
                  value={formData.associated_supplier_location_key || ''}
                >
                  {objectToArray(getProp(formData, 'associated_supplier_location_options', {}))
                    .sort(dynamicSort('name', 'asc'))
                    .map((location, index) => (
                      <Box
                        className="tw-ml-8 tw-block"
                        key={index}
                      >
                        <FormControlLabel
                          value={location.key}
                          control={<Radio />}
                          label={location.value}
                        />
                      </Box>
                    ))}
                </RadioGroup>
              </FormControl>
            </Box>
          )
          return inputJSX
        },
      },
      delivery_type: {
        key: 'delivery_type',
        label: rLIB('Delivery Type'),
        input_type: 'multiple_choice_radio',
        required: true,
        data_type: 'string',
        options: [
          { key: 'pickup', value: rLIB('Pickup') },
          { key: 'delivery', value: rLIB('Delivery') },
        ],
      },
      delivery_notes: {
        key: 'delivery_notes',
        label: rLIB('Delivery Notes'),
        input_type: 'text_multiline',
        required: false,
        data_type: 'string',
        conditional_display: {
          active: true,
          logic: {
            active: true,
            logic_type: 'comparison',
            source: 'formData',
            prop: 'delivery_type',
            comparator: '==',
            value: 'delivery',
            conditions: [],
          },
        },
      },
      associated_user_key: {
        key: 'associated_user_key',
        label: rLIB('Employee Placing Order'),
        input_type: 'multiple_choice_radio',
        required: true,
        data_type: 'string',
        options: [],
      },
    }
    // Add supplier options to form inputs
    let supplierOptions: TsInterface_UnspecifiedObject = {}
    for (let loopSupplierKey in us_allActiveSuppliers) {
      let loopSupplier = us_allActiveSuppliers[loopSupplierKey]
      if (loopSupplier != null) {
        supplierOptions[loopSupplierKey] = { key: loopSupplierKey, value: loopSupplier.name }
      }
    }
    // formInputs_NewOrder.associated_supplier_key.options = objectToArray(supplierOptions).sort(dynamicSort('value', 'asc'))
    // Add user options to form inputs
    let teamUserOptions: TsInterface_UnspecifiedObject = {}
    for (let loopDateKey in getProp(task, 'task_completion_scheduled_team_names', {})) {
      let loopDate = task.task_completion_scheduled_team_names[loopDateKey]
      for (let loopUserKey in loopDate) {
        let loopUserName = loopDate[loopUserKey]
        if (loopUserName != null) {
          teamUserOptions[loopUserKey] = { key: loopUserKey, value: loopUserName }
        }
      }
    }
    formInputs_NewOrder.associated_user_key.options = objectToArray(teamUserOptions).sort(dynamicSort('value', 'asc'))
    // Open Form Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {
            associated_supplier_options: supplierOptions,
          },
          formData: {},
          formInputs: formInputs_NewOrder,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  createNewOrderProper(
                    res_GCK.clientKey,
                    task.key,
                    formSubmittedData.associated_supplier_key,
                    formSubmittedData.associated_supplier_location_key,
                    formSubmittedData.associated_user_key,
                    formSubmittedData.timestamp_scheduled_pickup,
                    formSubmittedData.delivery_type,
                    formSubmittedData.delivery_notes,
                  )
                    .then((res_CNOP) => {
                      resolve(res_CNOP)
                    })
                    .catch((rej_CNOP) => {
                      reject(rej_CNOP)
                      formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_CNOP.error })
                      console.error(rej_CNOP)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                  formHooks.uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  console.error(rej_GCK)
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('New Order'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="circle-plus"
            />
          ),
        },
      },
    })
  }

  const openAnalysisFilterFormDialog = (): void => {
    // Add supplier options to form inputs
    let supplierOptions: TsInterface_UnspecifiedObject = {}
    for (let loopSupplierKey in us_allActiveSuppliers) {
      let loopSupplier = us_allActiveSuppliers[loopSupplierKey]
      if (loopSupplier != null) {
        supplierOptions[loopSupplierKey] = { key: loopSupplierKey, value: loopSupplier.name }
      }
    }
    formInputs_AnalysisFilter['associated_supplier_key']['options'] = objectToArray(supplierOptions)
    // Open Form Dialog
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        uc_setUserInterface_FormDialogDisplay({
          display: true,
          form: {
            form: {
              formAdditionalData: {
                clientKey: res_GCK.clientKey,
              },
              formData: cloneObjectWithoutReference(us_analysisFilterData),
              formInputs: formInputs_AnalysisFilter,
              formOnChange: (
                formAdditionalData: TsInterface_FormAdditionalData,
                formData: TsInterface_FormData,
                formInputs: TsInterface_FormInputs,
                formSettings: TsInterface_FormSettings,
              ) => {},
              formSettings: {},
              formSubmission: (
                formSubmittedData: TsInterface_FormSubmittedData,
                formAdditionalData: TsInterface_FormAdditionalData,
                formHooks: TsInterface_FormHooksObject,
              ) => {
                return new Promise((resolve, reject) => {
                  let filterData = { ...formSubmittedData }
                  if (
                    filterData != null &&
                    filterData.associated_supplier_key != null &&
                    supplierOptions != null &&
                    supplierOptions[filterData.associated_supplier_key] != null &&
                    supplierOptions[filterData.associated_supplier_key]['value'] != null
                  ) {
                    filterData['associated_supplier_name'] = supplierOptions[filterData.associated_supplier_key]['value']
                  }
                  us_setAnalysisFilterData(filterData)
                  us_setFilterSelected(true)
                  us_setAnalysisLoaded(false)
                  resolve({ success: true })
                })
              },
            },
            dialog: {
              formDialogHeaderColor: 'success',
              formDialogHeaderText: rLIB('Select Filter'),
              formDialogIcon: (
                <Icon
                  type="solid"
                  icon="filter"
                />
              ),
            },
          },
        })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  const loadAnalysisData = (): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setAnalysisLoaded(true)
      us_setRawAnalysisData({})
      let startDateKey = returnFormattedDateKey(us_selectedStartDate)
      let endDateKey = returnFormattedDateKey(us_selectedEndDate)
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          if (getProp(us_analysisFilterData, 'filter_type', null) === 'project' && getProp(us_analysisFilterData, 'associated_project_key', null) != null) {
            DatabaseGetCollection(
              DatabaseRef_SkuLineItemsForProject_DateRage_Query(
                res_GCK.clientKey,
                getProp(us_analysisFilterData, 'associated_project_key', null),
                startDateKey,
                endDateKey,
              ),
            )
              .then((res_DGC) => {
                us_setRawAnalysisData(res_DGC.data)
              })
              .catch((rej_DGC) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
                reject(rej_DGC)
              })
          } else if (getProp(us_analysisFilterData, 'filter_type', null) === 'user' && getProp(us_analysisFilterData, 'associated_user_key', null) != null) {
            DatabaseGetCollection(
              DatabaseRef_SkuLineItemsForUser_DateRange_Query(
                res_GCK.clientKey,
                getProp(us_analysisFilterData, 'associated_user_key', null),
                startDateKey,
                endDateKey,
              ),
            )
              .then((res_DGC) => {
                us_setRawAnalysisData(res_DGC.data)
              })
              .catch((rej_DGC) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
                reject(rej_DGC)
              })
          } else if (
            getProp(us_analysisFilterData, 'filter_type', null) === 'supplier' &&
            getProp(us_analysisFilterData, 'associated_supplier_key', null) != null
          ) {
            DatabaseGetCollection(
              DatabaseRef_SkuLineItemsForSupplier_DateRage_Query(
                res_GCK.clientKey,
                getProp(us_analysisFilterData, 'associated_supplier_key', null),
                startDateKey,
                endDateKey,
              ),
            )
              .then((res_DGC) => {
                us_setRawAnalysisData(res_DGC.data)
              })
              .catch((rej_DGC) => {
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DGC.error })
                reject(rej_DGC)
              })
          } else {
            let error = {
              message: rLIB('Failed to load analysis data'),
              details: rLIB('Missing Required Parameters'),
              code: 'ER-D-MI-LAD-01',
            }
            uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
            reject({ success: false, error: error })
          }
        })
        .catch((rej_GCK) => {
          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
          reject(rej_GCK)
        })
    })
  }

  const openFilterFormDialog = (): void => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: us_pageFilterData,
          formInputs: formInputs_OrderPageFilters,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              us_setPageFilterData(formSubmittedData)
              resolve({ success: true })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: rLIB('Page Filters'),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="filter"
            />
          ),
        },
      },
    })
  }

  const returnSX_WeekScheduleTableContainer = (): TsInterface_UnspecifiedObject => {
    let tableContainerSX: TsInterface_UnspecifiedObject = {}
    // if( umq_weekTableBreakpoint1 === true ) {
    // 	tableContainerSX = { height: "calc(100vh - 570px)" }
    // } else if( umq_weekTableBreakpoint2 === true ) {
    // 	tableContainerSX = { height: "calc(100vh - 485px)" }
    // } else if( umq_weekTableBreakpoint3 === true ) {
    // 	tableContainerSX = { height: "calc(100vh - 485px)" }
    // } else if( umq_weekTableBreakpoint4 === true ) {
    // 	tableContainerSX = { height: "calc(100vh - 440px)" }
    // } else if( umq_weekTableBreakpoint5 === true ) {
    // 	tableContainerSX = { height: "calc(100vh - 395px)" }
    // } else if( umq_weekTableBreakpoint6 === true ) {
    // 	tableContainerSX = { height: "calc(100vh - 350px)" }
    // } else if( umq_weekTableBreakpoint7 === true ) {
    // 	tableContainerSX = { height: "calc(100vh - 310px)" }
    // } else {
    tableContainerSX = { height: 'calc(100vh - 250px)' }
    // }
    return tableContainerSX
  }

  const returnSX_WeekCell = (day: number, date: Date | null): TsInterface_UnspecifiedObject => {
    let dayCellSX: TsInterface_UnspecifiedObject = { borderLeft: '1px solid ' + themeVariables.gray_700 }
    if (day === 6 || day === 7) {
      dayCellSX['background'] = themeVariables.background_default
    }
    if (date != null) {
      let formattedComparisonDate = returnFormattedDate(date, 'YYYY-MM-DD')
      let formattedComparisonToday = returnFormattedDate(new Date(), 'YYYY-MM-DD')
      if (formattedComparisonDate === formattedComparisonToday) {
        dayCellSX['borderBottom'] = '2px solid ' + themeVariables.white
        dayCellSX['borderLeft'] = '2px solid ' + themeVariables.white
        dayCellSX['borderTop'] = '2px solid ' + themeVariables.white
        dayCellSX['borderRight'] = '2px solid ' + themeVariables.white
      }
    }
    let dayOfWeek = us_selectedDate.getDay()
    if (day === dayOfWeek) {
      dayCellSX['borderColor'] = themeVariables.info_light
      dayCellSX['background'] = themeVariables.info_dark
    }
    return dayCellSX
  }

  const returnSX_WeekCalendarHeader = (date: Date): TsInterface_UnspecifiedObject => {
    let weekHeaderSX: TsInterface_UnspecifiedObject = { width: '70px' }
    let formattedDate = returnFormattedDate(date, 'YYYYMMDD')
    let formattedToday = returnFormattedDate(new Date(), 'YYYYMMDD')
    if (formattedDate === formattedToday) {
      weekHeaderSX['fontWeight'] = 800
    }
    return weekHeaderSX
  }

  // JSX Generation
  const rJSX_NewSupplierButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="success"
          variant="contained"
          onClick={() => {
            createNewSupplier()
          }}
          disableElevation
          startIcon={<Icon icon="circle-plus" />}
        >
          {rLIB('New Supplier')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('New Supplier')}
          placement="top"
        >
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              createNewSupplier()
            }}
            disableElevation
            className="bp_icon_only_button"
            startIcon={<Icon icon="circle-plus" />}
          ></Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_NewReceiptButton = (shrink: boolean): JSX.Element => {
    let buttonJSX = <></>
    if (shrink === false) {
      buttonJSX = (
        <Button
          color="success"
          variant="contained"
          disabled={true}
          onClick={() => {
            // Nothing
          }}
          disableElevation
          startIcon={<Icon icon="circle-plus" />}
        >
          {rLIB('New Invoice')}
        </Button>
      )
    } else {
      buttonJSX = (
        <Tooltip
          title={rLIB('New Invoice')}
          placement="top"
        >
          <Button
            color="success"
            variant="contained"
            disabled={true}
            onClick={() => {
              // Nothing
            }}
            disableElevation
            className="bp_icon_only_button"
            startIcon={<Icon icon="circle-plus" />}
          ></Button>
        </Tooltip>
      )
    }
    return buttonJSX
  }

  const rJSX_DatePicker = (shrinkButton: boolean): JSX.Element => {
    let buttonJSX = <></>
    // if( shrinkButton === true ){

    // } else {
    buttonJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Date')}
          datePickerDate={us_selectedDate}
          datePickerDisabled={false}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    // }
    return buttonJSX
  }

  const rJSX_SuppliersTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabContentJSX = (
        <Card className="tw-mt-2">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_MaterialSuppliers}
            tableDatabaseEndpoint={tableDatabaseEndpoint_Suppliers}
            tableSettings={tableSettings_MaterialSuppliers}
          />
        </Card>
      )
    }
    return tabContentJSX
  }

  // const rJSX_InvoicesTabContent = (): JSX.Element => {
  // let tabContentJSX = <Box></Box>
  // return tabContentJSX
  // }

  const rJSX_CalendarOrderChip = (order: TsInterface_UnspecifiedObject, showJobCode: boolean): JSX.Element => {
    let chipColor: 'default' | 'success' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' = 'default'
    let iconJSX = <></>
    switch (order.status) {
      case 'new':
        chipColor = 'primary'
        iconJSX = (
          <Icon
            sx={{ fontSize: '16px' }}
            icon="square-pen"
            className="tw-mr-2"
          />
        )
        break
      case 'deleted':
        chipColor = 'error'
        iconJSX = (
          <Icon
            sx={{ fontSize: '16px' }}
            icon="trash"
            className="tw-mr-2"
          />
        )
        break
      case 'locked':
        chipColor = 'warning'
        iconJSX = (
          <Icon
            sx={{ fontSize: '16px' }}
            icon="lock"
            className="tw-mr-2"
          />
        )
        break
      case 'submitted':
        chipColor = 'warning'
        iconJSX = (
          <Icon
            sx={{ fontSize: '16px' }}
            icon="paper-plane"
            className="tw-mr-2"
          />
        )
        break
      case 'confirmed':
        chipColor = 'success'
        iconJSX = (
          <Icon
            sx={{ fontSize: '16px' }}
            icon="badge-check"
            className="tw-mr-2"
          />
        )
        break
      // case "picked_up":
      // iconJSX =
      // <Icon sx={{ fontSize: "16px" }}  icon="truck-ramp-box" className="tw-mr-2" />
      // break
      case 'invoiced':
        chipColor = 'info'
        iconJSX = (
          <Icon
            sx={{ fontSize: '16px' }}
            icon="message-dollar"
            className="tw-mr-2"
          />
        )
        break
    }

    // associated_project_id_number ON Order without tasks
    let styleSX: TsInterface_UnspecifiedObject = { margin: '2px' }
    if (showJobCode === true) {
      styleSX['height'] = '40px'
    }
    let orderJSX = (
      <Tooltip
        title={
          <>
            {capitalizeAllWordsInString(getProp(order, 'status', ''))} | {rLIB('Pickup Date')} - {getProp(order, 'timestamp_scheduled_pickup_date_key', '')}
          </>
        }
        placement="right"
      >
        <Chip
          label={
            <Box>
              <Box className="tw-align-top">
                {iconJSX}
                {order.po_number}
              </Box>
              {showJobCode === true ? <Box className="tw-text-xs tw-opacity-70">{getProp(order, 'associated_project_id_number', <></>)}</Box> : <></>}
            </Box>
          }
          color={chipColor}
          variant="outlined"
          className="tw-cursor-pointer"
          sx={styleSX}
          onClick={(event) => {
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseMaterialsOrderViewPage.url(order.key))
          }}
        />
      </Tooltip>
    )
    return orderJSX
  }

  const rJSX_CalendarTask = (task: TsInterface_UnspecifiedObject, dateKey: string, grayOutSubsequentDays: boolean): JSX.Element => {
    // Team Color
    let teamColor = themeVariables.background_json
    if (
      task != null &&
      task.associated_team_type != null &&
      schedulingTeamTypes != null &&
      schedulingTeamTypes[task.associated_team_type] != null &&
      schedulingTeamTypes[task.associated_team_type]['color'] != null
    ) {
      teamColor = schedulingTeamTypes[task.associated_team_type]['color']
    }
    // Get actual orders tied to task
    let ordersLinkedToTask: TsInterface_UnspecifiedObject = {}
    let nonDeletedOrdersLinkedToTaskCount: number = 0
    if (us_ordersSortedByTasks != null && us_ordersSortedByTasks[task.key] != null) {
      ordersLinkedToTask = us_ordersSortedByTasks[task.key]
      nonDeletedOrdersLinkedToTaskCount = objectToArray(ordersLinkedToTask).filter((order) => {
        return order.status !== 'deleted'
      }).length
    }
    // Get First Date vs subsequent dates
    let opacity = 1
    let taskCompletionScheduledDates = getProp(task, 'task_completion_scheduled_dates', {}).sort()
    if (taskCompletionScheduledDates != null && taskCompletionScheduledDates.length > 0 && taskCompletionScheduledDates[0] === dateKey) {
      opacity = 1
    }
    let actualDayCount = 0
    let dayCount = 0
    for (let loopDateIndex in taskCompletionScheduledDates) {
      dayCount++
      if (taskCompletionScheduledDates[loopDateIndex] === dateKey) {
        actualDayCount = dayCount
      }
    }
    let subsequentDayJSX = <></>
    if (actualDayCount > 1) {
      subsequentDayJSX = (
        <Box component={'span'}>
          ({rLIB('Day')} {actualDayCount})
        </Box>
      )
    }
    // Create Order Icon
    // let createOrderIconJSX = <></>
    // if (actualDayCount === 1) {
    let createOrderIconJSX = (
      <Icon
        icon="circle-plus"
        className="tw-cursor-pointer tw-opacity-20 hover:tw-opacity-100"
        onClick={() => {
          createNewOrder(task)
        }}
        tooltip={rLIB('Create New Order')}
        tooltipPlacement="top"
        sx={{ fontSize: '16px', marginTop: '6px', marginRight: '6px' }}
      />
    )
    // }
    // Order vs No Order Placed Banner and Style
    let outlineStyle = 'solid'
    let eventOutline = '1px ' + outlineStyle + ' ' + themeVariables.white
    let taskEventBannerJSX = <></>
    let showOrdersOnTask = true
    if (actualDayCount > 1 && grayOutSubsequentDays === true) {
      taskEventBannerJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.gray_700 }}
          className="tw-pl-1"
        >
          <Icon
            icon="calendar"
            className="tw-mr-2"
          />
          {subsequentDayJSX}
        </Box>
      )
      eventOutline = '2px ' + outlineStyle + ' ' + themeVariables.gray_700

      // TODO - show if the it's the first day for this week?
      showOrdersOnTask = false
    } else if (nonDeletedOrdersLinkedToTaskCount > 0) {
      taskEventBannerJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.success_main }}
          className="tw-pl-1"
        >
          <Stack
            direction={'row'}
            spacing={1}
            className="tw-justify-between"
          >
            <Box sx={{ fontSize: '16px' }}>
              <Icon
                icon="badge-check"
                className="tw-mr-2"
              />
              {rLIB('Order Placed')}
            </Box>
            <Box>{createOrderIconJSX}</Box>
          </Stack>
        </Box>
      )
      eventOutline = '2px ' + outlineStyle + ' ' + themeVariables.success_main
    } else {
      taskEventBannerJSX = (
        <Box
          sx={{ backgroundColor: themeVariables.error_main }}
          className="tw-pl-1"
        >
          <Stack
            direction={'row'}
            spacing={1}
            className="tw-justify-between"
          >
            <Box sx={{ fontSize: '16px' }}>
              <Icon
                icon="circle-question"
                className="tw-mr-2"
              />
              {rLIB('No Order Placed')}
            </Box>
            <Box>{createOrderIconJSX}</Box>
          </Stack>
        </Box>
      )
      eventOutline = '2px ' + outlineStyle + ' ' + themeVariables.error_main
    }
    // Task JSX
    let taskJSX = <></>
    if (showOrdersOnTask === true) {
      taskJSX = (
        <Box>
          <Box className="tw-mt-1">
            {objectToArray(ordersLinkedToTask)
              .sort(dynamicSort('po_number', 'asc'))
              .map((order: TsInterface_UnspecifiedObject, index: number) => (
                <Box
                  key={index}
                  className="tw-text-center"
                >
                  {rJSX_CalendarOrderChip(order, false)}
                </Box>
              ))}
          </Box>
        </Box>
      )
    }
    // JSX
    let calendarTask = (
      <Box>
        <Box
          className="tw-rounded-md tw-m-1 tw-mb-3 tw-text-left"
          sx={{
            outline: eventOutline,
            opacity: opacity,
            background: themeVariables.background_json,
            minWidth: '140px',
            maxWidth: '240px',
          }}
        >
          {taskEventBannerJSX}
          <Box
            className="tw-p-1"
            sx={{ borderLeft: '12px solid ' + teamColor }}
          >
            <Box>{task.associated_project_id_number}</Box>
            <Divider />
            <Box>{task.name}</Box>
            <Divider />
            <Box>{task.associated_team_name}</Box>
            <Divider />
            {taskJSX}
          </Box>
        </Box>
      </Box>
    )
    return calendarTask
  }

  const rJSX_RegionDropdown = (shrinkButton: boolean): JSX.Element => {
    // JSX
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl className="bp_thin_select_input">
          <InputLabel id={'region_filter'}>{rLIB('Region')}</InputLabel>
          <Select
            id={'region_filter'}
            labelId={'region_filter'}
            color="primary"
            value={us_selectedRegion}
            label={rLIB('Region')}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedRegion(event.target.value)
                us_setSelectedTeamsFilter('')
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL_REGIONS'}>{rLIB('All Regions')}</MenuItem>
            {objectToArray(us_regionLists)
              .sort(dynamicSort('name', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  {option['name']}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_OrdersWithoutTasks = (orders: TsInterface_UnspecifiedObject): JSX.Element => {
    let ordersJSX = <></>
    if (objectToArray(orders).length > 0) {
      ordersJSX = (
        <Box
          className="tw-rounded-md tw-m-1 tw-mb-3 tw-text-left"
          sx={{
            outline: '2px solid + ' + themeVariables.warning_dark,
            opacity: 1,
            background: themeVariables.background_json,
            minWidth: '140px',
            maxWidth: '240px',
          }}
        >
          <Box
            sx={{ backgroundColor: themeVariables.warning_dark }}
            className="tw-pl-1 tw-rounded-t-md"
          >
            <Stack
              direction={'row'}
              spacing={1}
              className="tw-justify-between"
            >
              <Box sx={{ fontSize: '16px' }}>
                <Icon
                  icon="triangle-exclamation"
                  className="tw-mr-2"
                />
                {rLIB('Orders Without Tasks')}
              </Box>
            </Stack>
          </Box>
          <Box className="tw-py-2 tw-px-1">
            {objectToArray(orders)
              .sort(dynamicSort('po_number', 'asc'))
              .map((order: TsInterface_UnspecifiedObject, index: number) => (
                <Box
                  key={index}
                  className="tw-text-center"
                >
                  {rJSX_CalendarOrderChip(order, true)}
                </Box>
              ))}
          </Box>
        </Box>
      )
    }
    return ordersJSX
  }

  const rJSX_OrderCalendar = (): JSX.Element => {
    let sortProperty = 'associated_project_id_number'
    let dayOfWeek = us_selectedDate.getDay()
    let sunDate = new Date(us_selectedDate.getFullYear(), us_selectedDate.getMonth(), us_selectedDate.getDate() - dayOfWeek)
    let monDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 1)
    let tueDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 2)
    let wedDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 3)
    let thuDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 4)
    let friDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 5)
    let satDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 6)
    let endSunDate = new Date(sunDate.getFullYear(), sunDate.getMonth(), sunDate.getDate() + 7)
    let monDateKey = returnFormattedDateKey(monDate)
    let tueDateKey = returnFormattedDateKey(tueDate)
    let wedDateKey = returnFormattedDateKey(wedDate)
    let thuDateKey = returnFormattedDateKey(thuDate)
    let friDateKey = returnFormattedDateKey(friDate)
    let satDateKey = returnFormattedDateKey(satDate)
    let endSunDateKey = returnFormattedDateKey(endSunDate)
    let weekOrderCalendar = (
      <Card sx={{ border: '1px solid ' + themeVariables.gray_700 }}>
        <TableContainer
          className="bp_show_horizontal_scroll"
          sx={returnSX_WeekScheduleTableContainer()}
        >
          <Table
            size="small"
            stickyHeader={true}
          >
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(1, monDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(monDate)}
                  >
                    {rLIB('Mon')} {returnFormattedDate(monDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(2, tueDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(tueDate)}
                  >
                    {rLIB('Tue')} {returnFormattedDate(tueDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(3, wedDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(wedDate)}
                  >
                    {rLIB('Wed')} {returnFormattedDate(wedDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(4, thuDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(thuDate)}
                  >
                    {rLIB('Thu')} {returnFormattedDate(thuDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(5, friDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(friDate)}
                  >
                    {rLIB('Fri')} {returnFormattedDate(friDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(6, satDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(satDate)}
                  >
                    {rLIB('Sat')} {returnFormattedDate(satDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(7, endSunDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(endSunDate)}
                  >
                    {rLIB('Sun')} {returnFormattedDate(endSunDate, 'M/D')}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(1, null)}
                >
                  {rJSX_OrdersWithoutTasks(us_unlinkedMonOrders)}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(2, null)}
                >
                  {rJSX_OrdersWithoutTasks(us_unlinkedTueOrders)}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(3, null)}
                >
                  {rJSX_OrdersWithoutTasks(us_unlinkedWedOrders)}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(4, null)}
                >
                  {rJSX_OrdersWithoutTasks(us_unlinkedThuOrders)}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(5, null)}
                >
                  {rJSX_OrdersWithoutTasks(us_unlinkedFriOrders)}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(6, null)}
                >
                  {rJSX_OrdersWithoutTasks(us_unlinkedSatOrders)}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(7, null)}
                >
                  {rJSX_OrdersWithoutTasks(us_unlinkedSunOrders)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(1, null)}
                >
                  {objectToArray(us_filteredMonTasks)
                    .sort(dynamicSort(sortProperty, 'asc'))
                    .map((task: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_CalendarTask(getProp(us_calendarEvents, task.key, {}), monDateKey, false)}</Box>
                    ))}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(2, null)}
                >
                  {objectToArray(us_filteredTueTasks)
                    .sort(dynamicSort(sortProperty, 'asc'))
                    .map((task: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_CalendarTask(getProp(us_calendarEvents, task.key, {}), tueDateKey, false)}</Box>
                    ))}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(3, null)}
                >
                  {objectToArray(us_filteredWedTasks)
                    .sort(dynamicSort(sortProperty, 'asc'))
                    .map((task: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_CalendarTask(getProp(us_calendarEvents, task.key, {}), wedDateKey, false)}</Box>
                    ))}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(4, null)}
                >
                  {objectToArray(us_filteredThuTasks)
                    .sort(dynamicSort(sortProperty, 'asc'))
                    .map((task: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_CalendarTask(getProp(us_calendarEvents, task.key, {}), thuDateKey, false)}</Box>
                    ))}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(5, null)}
                >
                  {objectToArray(us_filteredFriTasks)
                    .sort(dynamicSort(sortProperty, 'asc'))
                    .map((task: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_CalendarTask(getProp(us_calendarEvents, task.key, {}), friDateKey, false)}</Box>
                    ))}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(6, null)}
                >
                  {objectToArray(us_filteredSatTasks)
                    .sort(dynamicSort(sortProperty, 'asc'))
                    .map((task: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_CalendarTask(getProp(us_calendarEvents, task.key, {}), satDateKey, false)}</Box>
                    ))}
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center tw-align-top"
                  sx={returnSX_WeekCell(7, null)}
                >
                  {objectToArray(us_filteredSunTasks)
                    .sort(dynamicSort(sortProperty, 'asc'))
                    .map((task: TsInterface_UnspecifiedObject, index: number) => (
                      <Box key={index}>{rJSX_CalendarTask(getProp(us_calendarEvents, task.key, {}), endSunDateKey, false)}</Box>
                    ))}
                </TableCell>
              </TableRow>
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(1, monDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(monDate)}
                  >
                    {rLIB('Mon')} {returnFormattedDate(monDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(2, tueDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(tueDate)}
                  >
                    {rLIB('Tue')} {returnFormattedDate(tueDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(3, wedDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(wedDate)}
                  >
                    {rLIB('Wed')} {returnFormattedDate(wedDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(4, thuDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(thuDate)}
                  >
                    {rLIB('Thu')} {returnFormattedDate(thuDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(5, friDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(friDate)}
                  >
                    {rLIB('Fri')} {returnFormattedDate(friDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(6, satDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(satDate)}
                  >
                    {rLIB('Sat')} {returnFormattedDate(satDate, 'M/D')}
                  </Box>
                </TableCell>
                <TableCell
                  colSpan={1}
                  className="tw-px-0 tw-text-center"
                  sx={returnSX_WeekCell(7, endSunDate)}
                >
                  <Box
                    className="tw-m-auto"
                    sx={returnSX_WeekCalendarHeader(endSunDate)}
                  >
                    {rLIB('Sun')} {returnFormattedDate(endSunDate, 'M/D')}
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Card>
    )
    return weekOrderCalendar
  }

  const rJSX_OrdersTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    tabContentJSX = (
      <Box>
        <Box className="tw-text-right">
          <Typography
            variant="body1"
            className="tw-opacity-30"
          >
            {rLIB('Orders scheduled for pickup between')} {us_weekStartDateKey} {rLIB('and')} {us_weekEndDateKey}
          </Typography>
        </Box>
        <Card>
          <TableBasic
            tableAdditionalData={{}}
            tableColumns={tableColumns_MaterialOrders}
            tableData={objectToArray(us_weekOrdersTableData)}
            tableSettings={tableSettings_MaterialOrders}
          />
        </Card>
      </Box>
    )
    return tabContentJSX
  }

  const rJSX_CalendarTabContent = (): JSX.Element => {
    let tabContentJSX = <Box>{rJSX_OrderCalendar()}</Box>
    return tabContentJSX
  }

  const rJSX_StartDatePicker = (disabled: boolean): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('Start Date')}
          datePickerDate={us_selectedStartDate}
          datePickerDateOnChange={changeCalendarStartDate}
          datePickerSettings={{ thin_input: true }}
          datePickerDisabled={disabled}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_EndDatePicker = (disabled: boolean): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-align-top tw-mr-2">
        <DatePicker
          key={'calendar_date'}
          datePickerText={rLIB('End Date')}
          datePickerDate={us_selectedEndDate}
          datePickerDateOnChange={changeCalendarEndDate}
          datePickerSettings={{ thin_input: true }}
          datePickerDisabled={disabled}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_FilterTypeSelectionButton = (): JSX.Element => {
    let buttonJSX = <></>
    if (
      getProp(us_analysisFilterData, 'filter_type', null) === 'project' ||
      getProp(us_analysisFilterData, 'filter_type', null) === 'user' ||
      getProp(us_analysisFilterData, 'filter_type', null) === 'supplier'
    ) {
      let filterButtonText = <></>
      if (getProp(us_analysisFilterData, 'filter_type', null) === 'project') {
        filterButtonText = (
          <>
            {rLIB('Project')}: {getProp(us_analysisFilterData, 'associated_project_id_number', '')}
          </>
        )
      } else if (getProp(us_analysisFilterData, 'filter_type', null) === 'user') {
        filterButtonText = (
          <>
            {rLIB('User')}: {getProp(us_analysisFilterData, 'associated_user_name', '')}
          </>
        )
      } else if (getProp(us_analysisFilterData, 'filter_type', null) === 'supplier') {
        filterButtonText = (
          <>
            {rLIB('Supplier')}: {getProp(us_analysisFilterData, 'associated_supplier_name', '')}
          </>
        )
      }
      // JSX
      buttonJSX = (
        <Button
          color="secondary"
          variant="contained"
          disableElevation
          startIcon={<Icon icon="filter" />}
          className="tw-mr-2"
          onClick={() => {
            openAnalysisFilterFormDialog()
          }}
        >
          {filterButtonText}
        </Button>
      )
    } else {
      buttonJSX = (
        <Button
          color="secondary"
          variant="outlined"
          disableElevation
          startIcon={<Icon icon="filter" />}
          className="tw-mr-2"
          onClick={() => {
            openAnalysisFilterFormDialog()
          }}
        >
          {rLIB('Select Filter')}
        </Button>
      )
    }
    return buttonJSX
  }

  const rJSX_LoadAnalysisButton = (): JSX.Element => {
    let buttonJSX = <></>
    let disabledButton = true
    if (us_filterSelected === true) {
      disabledButton = false
    }
    let buttonVariant: 'contained' | 'outlined' = 'contained'
    if (us_analysisLoaded === true) {
      buttonVariant = 'outlined'
    }
    buttonJSX = (
      <Button
        color="success"
        variant={buttonVariant}
        disableElevation
        startIcon={<Icon icon="magnifying-glass-chart" />}
        onClick={() => {
          loadAnalysisData()
        }}
        disabled={disabledButton}
      >
        {rLIB('Load Analysis')}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_AnalysisTabContent = (): JSX.Element => {
    let tabContentJSX = (
      <Box>
        <Box>
          <Json
            data={us_processedAnalysisData}
            alphabetized={true}
          />
          <Json
            data={us_rawAnalysisData}
            alphabetized={true}
          />
        </Box>
      </Box>
    )
    return tabContentJSX
  }

  const rJSX_TeamTypeChip = (teamType: string): JSX.Element => {
    let chipJSX = <></>
    if (teamType != null && schedulingTeamTypes != null && schedulingTeamTypes[teamType] != null && schedulingTeamTypes[teamType] != null) {
      let chipText = getProp(schedulingTeamTypes[teamType], 'value', teamType)
      let chipIcon = getProp(schedulingTeamTypes[teamType], 'icon', <Icon icon="square-question" />)
      let chipColor = getProp(schedulingTeamTypes[teamType], 'color', themeVariables.background_json)
      chipJSX = (
        <Chip
          sx={{ backgroundColor: chipColor }}
          label={
            <>
              <Box className="tw-mr-1 tw-inline-block">{chipIcon}</Box>
              {chipText}
            </>
          }
        />
      )
    } else {
      chipJSX = <Chip label={teamType} />
    }
    return chipJSX
  }

  const rJSX_FilterTeamTypeDropdown = (shrinkButton: boolean): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl
          className="tw-ml-2 bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', maxWidth: '130px' }}
        >
          <InputLabel
            id={'team_type_filter'}
            sx={{ zIndex: '10000 !important' }}
          >
            {rLIB('Team Type')}
          </InputLabel>
          <Select
            // className="bp_thin_select_input"
            autoWidth={true}
            id={'team_type_filter'}
            labelId={'team_type_filter'}
            multiple
            value={us_visibleTeamsTypesFilter}
            label={<Box sx={{ zIndex: 10000 }}>{rLIB('Team Type')}</Box>}
            onChange={(event: SelectChangeEvent<typeof us_visibleTeamsTypesFilter>) => {
              const {
                target: { value },
              } = event
              us_setVisibleTeamsTypesFilter(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
              )
              us_setSelectedTeamsFilter('')
            }}
            sx={{ maxHeight: '36px' }}
            renderValue={(selected) => {
              let displayJSX = (
                <Box>
                  {selected.map((teamKey: string, index: number) => (
                    <Box
                      key={index}
                      className="tw-inline-block tw-mr-1"
                    >
                      {rJSX_TeamTypeChip(teamKey)}
                    </Box>
                  ))}
                </Box>
              )
              return displayJSX
            }}
          >
            {objectToArray(schedulingTeamTypes)
              .sort(dynamicSort('key', 'asc'))
              .map((teamType: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={teamType.key}
                >
                  <Checkbox checked={us_visibleTeamsTypesFilter.indexOf(teamType.key) > -1} />
                  {teamType.value}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Box
          className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
          onClick={() => {
            us_setVisibleTeamsTypesFilter([])
            us_setSelectedTeamsFilter('')
          }}
        >
          <Icon icon="circle-x tw-ml-1 tw-mt-2" />
        </Box>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_OrderFilterButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        className="tw-m-auto tw-ml-2 bp_icon_only_button tw-opacity-25"
        color="inherit"
        variant="outlined"
        onClick={() => {
          openFilterFormDialog()
        }}
      >
        <Icon
          icon="filter"
          type="light"
        ></Icon>
      </Button>
    )
    return buttonJSX
  }

  const rJSX_FilterTeamDropdown = (shrinkButton: boolean): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top">
        <FormControl
          className="tw-ml-2 bp_thin_select_input bp_thin_select_multiple_input"
          sx={{ minWidth: '130px', maxWidth: '130px' }}
        >
          <InputLabel
            id={'team_filter'}
            sx={{ zIndex: '10000 !important' }}
          >
            {rLIB('Team')}
          </InputLabel>
          <Select
            // className="bp_thin_select_input"
            autoWidth={true}
            id={'team_filter'}
            labelId={'team_filter'}
            value={us_selectedTeamsFilter}
            label={<Box sx={{ zIndex: 10000 }}>{rLIB('Team')}</Box>}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setSelectedTeamsFilter(event.target.value)
              }
            }}
            sx={{ maxHeight: '36px' }}
          >
            {objectToArray(us_filterableTeamOptions)
              .sort(dynamicSort('name', 'asc'))
              .map((team: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={team.key}
                >
                  {team.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <Box
          className="tw-inline-block tw-opacity-30 hover:tw-opacity-100 tw-cursor-pointer"
          onClick={() => {
            us_setSelectedTeamsFilter('')
          }}
        >
          <Icon icon="circle-x tw-ml-1 tw-mt-2" />
        </Box>
      </Box>
    )
    return dropdownJSX
  }

  // TODO: TEMP
  const rJSX_ReceiptsTabContent = (): JSX.Element => {
    let tabContentJSX = <></>
    if (uc_RootData_ClientKey != null) {
      tabContentJSX = (
        <Card className="tw-mt-2">
          <TableDatabase
            tableAdditionalData={{}}
            tableColumns={tableColumns_MaterialReceipts}
            tableDatabaseEndpoint={tableDatabaseEndpoint_Receipts}
            tableSettings={tableSettings_MaterialReceipts}
          />
        </Card>
      )
    }
    return tabContentJSX
  }

  // TODO: TEMP
  // let tempDataObject: TsInterface_UnspecifiedObject = {
  //   links: {
  //     portal: 'https://etwenergy.com/s/all_orders/etw_energy/LXYEUvHezEaq82TeYUio/',
  //   },
  //   dates: {
  //     today: returnFormattedDate(new Date(), 'M/D'),
  //     today_key: returnFormattedDateKey(new Date()),
  //     tomorrow: returnFormattedDate(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 'M/D'),
  //     tomorrow_key: returnFormattedDateKey(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
  //   },
  //   order_counts: {
  //     today: 0,
  //     tomorrow: 0,
  //   },
  //   today_orders: {},
  //   tomorrow_orders: {},
  // }

  // TODO: TEMP
  // let templateObject: TsInterface_EmailTemplateObject = {
  //   HEADER_DIV: {
  //     element_type: 'div',
  //     style: {
  //       'box-sizing': 'border-box',
  //       'background': 'rgb(25, 30, 33)',
  //       'font-size': '20px',
  //       'width': '100%',
  //       'min-height': '70px',
  //       'padding': '8px',
  //       'text-align': 'center',
  //       'border-top-left-radius': '8px',
  //       'border-top-right-radius': '8px',
  //     },
  //     contents: {
  //       LOGO_IMG: {
  //         element_type: 'img',
  //         src_type: 'static',
  //         src: 'https://firebasestorage.googleapis.com/v0/b/etw-energy.appspot.com/o/global%2Fetw_logo_narrow.png?alt=media&token=13b9e254-6c87-43a2-82b8-6f65cc1a0250',
  //         alt: 'logo.png',
  //         width: '80px',
  //         height: 'auto',
  //       },
  //     },
  //   },
  //   BODY_DIV: {
  //     element_type: 'div',
  //     style: {
  //       'box-sizing': 'border-box',
  //       'width': '100%',
  //       'background': '#eeeeee',
  //       'padding': '8px',
  //       'border-bottom-left-radius': '8px',
  //       'border-bottom-right-radius': '8px',
  //     },
  //     contents: {
  //       INTRO1: {
  //         element_type: 'p',
  //         text_type: 'static',
  //         text: 'Good morning,',
  //         style: {
  //           'font-size': '14px',
  //           // 'font-weight': 'bold',
  //           'color': 'rgba(0, 0, 0, 0.87)',
  //           'margin-bottom': '8px',
  //         },
  //       },
  //       INTRO2: {
  //         element_type: 'p',
  //         text_type: 'static',
  //         text: 'The following orders are ready to kit and/or deliver today and tomorrow. See view full details at the link below.',
  //         style: {
  //           'display': 'block',
  //           'font-size': '14px',
  //           // 'font-weight': 'bold',
  //           'color': 'rgba(0, 0, 0, 0.87)',
  //           'margin-bottom': '8px',
  //         },
  //       },
  //       LINK: {
  //         element_type: 'a',
  //         text: 'Full Details',
  //         text_type: 'static',
  //         href_type: 'dynamic',
  //         href_url_data_object_key: 'links',
  //         href_url_data_object_prop_key: 'portal',
  //         style: {
  //           'font-weight': 'bold',
  //           'display': 'inline-block',
  //           'font-size': '14px',
  //           'color': 'blue',
  //           'text-decoration': 'underline',
  //           'cursor': 'pointer',
  //         },
  //       },
  //       TODAY_ORDERS: {
  //         element_type: 'div',
  //         style: {
  //           'box-sizing': 'border-box',
  //         },
  //         contents: {
  //           CHIP_DIV: {
  //             element_type: 'div',
  //             style: {
  //               'box-sizing': 'border-box',
  //               'background': '#55D3FE',
  //               'font-size': '20px',
  //               'padding': '4px',
  //               'border-radius': '20px',
  //               'text-align': 'left',
  //               'width': 'auto',
  //               'display': 'inline-block',
  //               'padding-left': '8px',
  //               'padding-right': '8px',
  //               'margin-right': '8px',
  //               'margin-top': '16px',
  //               'margin-bottom': '8px',
  //             },
  //             contents: {
  //               DATE_TEXT: {
  //                 element_type: 'p',
  //                 text_type: 'dynamic',
  //                 data_object_key: 'dates',
  //                 data_object_prop_key: 'today',
  //                 style: {
  //                   'display': 'inline-block',
  //                   'margin-right': '4px',
  //                   'font-weight': 'bold',
  //                   'font-size': '18px',
  //                   'color': '#ffffff',
  //                   'margin-top': '0px',
  //                   'margin-bottom': '0px',
  //                   // 'color': 'rgb(25, 30, 33)',
  //                 },
  //               },
  //               ORDERS_TEXT: {
  //                 element_type: 'p',
  //                 text_type: 'static',
  //                 text: 'Orders',
  //                 style: {
  //                   'display': 'inline-block',
  //                   'font-weight': 'bold',
  //                   'font-size': '18px',
  //                   'color': '#ffffff',
  //                   'margin-top': '0px',
  //                   'margin-bottom': '0px',
  //                   // 'color': 'rgb(25, 30, 33)',
  //                 },
  //               },
  //             },
  //           },
  //           NO_ORDERS_DIV: {
  //             element_type: 'div_conditional',
  //             condition_data_object_key: 'order_counts',
  //             condition_data_object_prop_key: 'today',
  //             condition_operator: '==',
  //             condition_value: 0,
  //             style: {},
  //             contents: {
  //               INTRO2: {
  //                 element_type: 'p',
  //                 text_type: 'static',
  //                 text: 'No orders scheduled for today.',
  //                 style: {
  //                   'display': 'inline-block',
  //                   'font-size': '14px',
  //                   // 'font-weight': 'bold',
  //                   'color': 'rgba(0, 0, 0, 0.87)',
  //                   'margin-right': '4px',
  //                 },
  //               },
  //             },
  //           },
  //           YES_ORDERS_DIV: {
  //             element_type: 'div_conditional',
  //             condition_data_object_key: 'order_counts',
  //             condition_data_object_prop_key: 'today',
  //             condition_operator: '>',
  //             condition_value: 0,
  //             style: {},
  //             contents: {
  //               TABLE: {
  //                 element_type: 'table',
  //                 table_type: 'looped_data',
  //                 looped_data_object_key: 'today_orders',
  //                 table_style: {
  //                   'width': '100%',
  //                   'color': '#000',
  //                   'font-size': '14px',
  //                   'background': '#ffffff',
  //                 },
  //                 table_columns: {
  //                   TYPE_COL: {
  //                     data_object_prop_key: 'delivery_type',
  //                     column_header: 'Order Type',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                   JOB_CODE_COL: {
  //                     data_object_prop_key: 'associated_project_id_number',
  //                     column_header: 'Job Code',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                   REQUESTOR_COL: {
  //                     data_object_prop_key: 'associated_user_name',
  //                     column_header: 'Requestor',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                   CRAWFORD_COL: {
  //                     data_object_prop_key: 'associated_supplier_location_name',
  //                     column_header: 'Location',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                   DELIVERY_NOTES_COL: {
  //                     data_object_prop_key: 'delivery_notes',
  //                     column_header: 'Notes',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                   PO_NUMBER_COL: {
  //                     data_object_prop_key: 'po_number',
  //                     column_header: 'PO#',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                 },
  //               },
  //             },
  //           },
  //         },
  //       },
  //       TOMORROW_ORDERS: {
  //         element_type: 'div',
  //         style: {
  //           'box-sizing': 'border-box',
  //         },
  //         contents: {
  //           CHIP_DIV: {
  //             element_type: 'div',
  //             style: {
  //               'box-sizing': 'border-box',
  //               'background': '#55D3FE',
  //               'font-size': '20px',
  //               'padding': '4px',
  //               'border-radius': '20px',
  //               'text-align': 'left',
  //               'width': 'auto',
  //               'display': 'inline-block',
  //               'padding-left': '8px',
  //               'padding-right': '8px',
  //               'margin-right': '8px',
  //               'margin-top': '16px',
  //               'margin-bottom': '8px',
  //             },
  //             contents: {
  //               DATE_TEXT: {
  //                 element_type: 'p',
  //                 text_type: 'dynamic',
  //                 data_object_key: 'dates',
  //                 data_object_prop_key: 'tomorrow',
  //                 style: {
  //                   'display': 'inline-block',
  //                   'margin-right': '4px',
  //                   'font-weight': 'bold',
  //                   'font-size': '18px',
  //                   'color': '#ffffff',
  //                   'margin-top': '0px',
  //                   'margin-bottom': '0px',
  //                   // 'color': 'rgb(25, 30, 33)',
  //                 },
  //               },
  //               ORDERS_TEXT: {
  //                 element_type: 'p',
  //                 text_type: 'static',
  //                 text: 'Orders',
  //                 style: {
  //                   'display': 'inline-block',
  //                   'font-weight': 'bold',
  //                   'font-size': '18px',
  //                   'color': '#ffffff',
  //                   'margin-top': '0px',
  //                   'margin-bottom': '0px',
  //                   // 'color': 'rgb(25, 30, 33)',
  //                 },
  //               },
  //             },
  //           },
  //           NO_ORDERS_DIV: {
  //             element_type: 'div_conditional',
  //             condition_data_object_key: 'order_counts',
  //             condition_data_object_prop_key: 'tomorrow',
  //             condition_operator: '==',
  //             condition_value: 0,
  //             style: {},
  //             contents: {
  //               INTRO2: {
  //                 element_type: 'p',
  //                 text_type: 'static',
  //                 text: 'No orders scheduled for tomorrow.',
  //                 style: {
  //                   'display': 'inline-block',
  //                   'font-size': '14px',
  //                   // 'font-weight': 'bold',
  //                   'color': 'rgba(0, 0, 0, 0.87)',
  //                   'margin-right': '4px',
  //                 },
  //               },
  //             },
  //           },
  //           YES_ORDERS_DIV: {
  //             element_type: 'div_conditional',
  //             condition_data_object_key: 'order_counts',
  //             condition_data_object_prop_key: 'tomorrow',
  //             condition_operator: '>',
  //             condition_value: 0,
  //             style: {},
  //             contents: {
  //               TABLE: {
  //                 element_type: 'table',
  //                 table_type: 'looped_data',
  //                 looped_data_object_key: 'tomorrow_orders',
  //                 table_style: {
  //                   'width': '100%',
  //                   'color': '#000',
  //                   'font-size': '14px',
  //                   'background': '#ffffff',
  //                 },
  //                 table_columns: {
  //                   TYPE_COL: {
  //                     data_object_prop_key: 'delivery_type',
  //                     column_header: 'Order Type',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                   JOB_CODE_COL: {
  //                     data_object_prop_key: 'associated_project_id_number',
  //                     column_header: 'Job Code',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                   REQUESTOR_COL: {
  //                     data_object_prop_key: 'associated_user_name',
  //                     column_header: 'Requestor',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                   CRAWFORD_COL: {
  //                     data_object_prop_key: 'associated_supplier_location_name',
  //                     column_header: 'Location',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                   DELIVERY_NOTES_COL: {
  //                     data_object_prop_key: 'delivery_notes',
  //                     column_header: 'Notes',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                   PO_NUMBER_COL: {
  //                     data_object_prop_key: 'po_number',
  //                     column_header: 'PO#',
  //                     th_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                     td_style: {
  //                       'padding': '2px',
  //                       'text-align': 'left',
  //                       'vertical-align': 'top',
  //                     },
  //                   },
  //                 },
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //   },
  // }

  // TODO: TEMP
  // const rJSX_ReminderEmailTabContent = (): JSX.Element => {
  // let tabContentJSX = <></>

  // tabContentJSX = (
  //   <Box>
  //     <Box
  //       className="ql-scroll-html-render"
  //       dangerouslySetInnerHTML={{ __html: generateHtmlForEmailFromTemplateObject(templateObject, tempDataObject) }}
  //     />
  //     <Button
  //       onClick={() => {
  //         let clientKey = 'etw_energy'
  //         let supplierKey = 'LXYEUvHezEaq82TeYUio'
  //         let promiseArray: TsType_UnknownPromise[] = []
  //         let startDateKey = us_weekStartDateKey
  //         let endDateKey = us_weekEndDateKey
  //         // let supplier: TsInterface_UnspecifiedObject = {}
  //         let upcomingOrders: TsInterface_UnspecifiedObject = {}
  //         // Load Supplier Data
  //         // promiseArray.push(
  //         // DatabaseGetDocument(DatabaseRef_Supplier_Document(clientKey, supplierKey))
  //         //   .then((res_DGD) => {
  //         //     supplier = res_DGD.data
  //         //   })
  //         //   .catch((rej_DGD) => {
  //         //     console.error(rej_DGD)
  //         //   }),
  //         // )
  //         promiseArray.push(
  //           DatabaseGetCollection(
  //             DatabaseRef_OrdersScheduledBetweenDates_ForSpecificSupplier_Query(clientKey, supplierKey, startDateKey as string, endDateKey as string),
  //           )
  //             .then((res_DGC) => {
  //               upcomingOrders = res_DGC.data
  //             })
  //             .catch((rej_DGC) => {
  //               console.error(rej_DGC)
  //             }),
  //         )
  //         Promise.all(promiseArray).finally(() => {
  //           let listOfUpcomingOrders: string[] = []
  //           for (let loopOrderKey in upcomingOrders) {
  //             let loopOrder = upcomingOrders[loopOrderKey]
  //             if (loopOrder['po_number'] != null) {
  //               // let parsedOrder: TsInterface_UnspecifiedObject = {}
  //               // TODO: parse order to combine address
  //               if (loopOrder['timestamp_scheduled_pickup_date_key'] === tempDataObject['dates']['today_key'] && loopOrder['status'] !== 'deleted') {
  //                 tempDataObject['today_orders'][loopOrderKey] = loopOrder
  //                 // tempDataObject['today_orders'][loopOrderKey] = parsedOrder
  //                 tempDataObject['order_counts']['today'] = tempDataObject['order_counts']['today'] + 1
  //               }
  //               if (loopOrder['timestamp_scheduled_pickup_date_key'] === tempDataObject['dates']['tomorrow_key'] && loopOrder['status'] !== 'deleted') {
  //                 tempDataObject['tomorrow_orders'][loopOrderKey] = loopOrder
  //                 // tempDataObject['tomorrow_orders'][loopOrderKey] = parsedOrder
  //                 tempDataObject['order_counts']['tomorrow'] = tempDataObject['order_counts']['tomorrow'] + 1
  //               }
  //               listOfUpcomingOrders.push(loopOrder['po_number'])
  //             }
  //           }
  //           cloudFunctionManageRequest('manageEmails', {
  //             function: 'sendSendgridHtmlEmail',
  //             // to: ['john.morrell@etwenergy.com', 'raphael@etwenergy.com'],
  //             to: ['john.morrell.if@gmail.com'],
  //             subject: 'Test Formatted Material Reminder Email',
  //             html: generateHtmlForEmailFromTemplateObject(templateObject, tempDataObject),
  //             cc: [],
  //             bcc: [],
  //           })
  //             .then((res) => {
  //               console.log(res)
  //             })
  //             .catch((rej) => {
  //               console.error(rej)
  //             })
  //         })
  //       }}
  //     >
  //       SEND EMAIL
  //     </Button>
  //   </Box>
  // )

  // return tabContentJSX
  // }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={rLIB('Materials')}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Calendar',
                  tabHeader: rLIB('Calendar'),
                  tabContent: rJSX_CalendarTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_DatePicker(false), minJSX: rJSX_DatePicker(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_RegionDropdown(false), minJSX: rJSX_RegionDropdown(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_FilterTeamTypeDropdown(false), minJSX: rJSX_FilterTeamTypeDropdown(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_FilterTeamDropdown(false), minJSX: rJSX_FilterTeamDropdown(true), sizeCutoff: 0 },
                    // { fullJSX: rJSX_ViewOrderButtonToggle( false ), minJSX: rJSX_ViewOrderButtonToggle( true ), sizeCutoff: 585 },
                  ],
                },
                {
                  tabUrlKey: 'Orders',
                  tabHeader: rLIB('Orders'),
                  tabContent: rJSX_OrdersTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_RegionDropdown(false), minJSX: rJSX_RegionDropdown(true), sizeCutoff: 0 },
                    { fullJSX: rJSX_OrderFilterButton(), minJSX: rJSX_OrderFilterButton(), sizeCutoff: 0 },
                  ],
                },
                {
                  tabUrlKey: 'Suppliers',
                  tabHeader: rLIB('Suppliers'),
                  tabContent: rJSX_SuppliersTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewSupplierButton(false), minJSX: rJSX_NewSupplierButton(true), sizeCutoff: 245 }],
                },
                {
                  tabUrlKey: 'Receipts',
                  tabHeader: rLIB('Receipts'),
                  tabContent: rJSX_ReceiptsTabContent(),
                  tabButtons: [{ fullJSX: rJSX_NewReceiptButton(false), minJSX: rJSX_NewReceiptButton(true), sizeCutoff: 0 }],
                },
                // {
                // tabUrlKey: 'Invoices',
                // tabHeader: rLIB('Invoices'),
                // tabContent: rJSX_InvoicesTabContent(),
                // tabButtons: [],
                // },
                {
                  tabUrlKey: 'Analysis',
                  tabHeader: rLIB('Analysis'),
                  tabContent: rJSX_AnalysisTabContent(),
                  tabButtons: [
                    { fullJSX: rJSX_StartDatePicker(false), minJSX: rJSX_StartDatePicker(false), sizeCutoff: 0 },
                    { fullJSX: rJSX_EndDatePicker(false), minJSX: rJSX_EndDatePicker(false), sizeCutoff: 0 },
                    { fullJSX: rJSX_FilterTypeSelectionButton(), minJSX: rJSX_FilterTypeSelectionButton(), sizeCutoff: 0 },
                    { fullJSX: rJSX_LoadAnalysisButton(), minJSX: rJSX_LoadAnalysisButton(), sizeCutoff: 0 },
                  ],
                },
                // TODO: TEMP
                // {
                // tabUrlKey: 'Emails',
                // tabHeader: rLIB('Reminder Emails'),
                // tabContent: rJSX_ReminderEmailTabContent(),
                // tabButtons: [{ fullJSX: rJSX_NewSupplierButton(false), minJSX: rJSX_NewSupplierButton(true), sizeCutoff: 245 }],
                // },
              ]}
              tabsSettings={{
                baseUrl: ApplicationPages.AdminDatabaseMaterialsIndexPage.url(),
                tabQueryParam: 'tab',
                overridePageTitle: true,
                basePageTitle: rLIB('Materials', false) as string,
              }}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Badge, Box, Button, CircularProgress, Stack, Tooltip, Typography } from '@mui/material/'
import { createScheduledReminder } from 'app/models/reminders/reminder_forms'
import React, { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_UserUnreadMessageThreadsCollection_Query } from 'rfbp_aux/services/database_endpoints/operations/messages'
import {
  DatabaseRef_NotificationsUserRead_Query,
  DatabaseRef_NotificationsUserUnread_Query,
  DatabaseRef_Notification_Document,
} from 'rfbp_aux/services/database_endpoints/operations/notifications'
import {
  DatabaseRef_OpenReminders_Query,
  DatabaseRef_RemindersDateRange_Query,
  DatabaseRef_Reminder_Document,
} from 'rfbp_aux/services/database_endpoints/operations/reminders'
import { DatePicker } from 'rfbp_core/components/date_picker'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl, TsInterface_TabsSettingsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_RootData_GlobalUser, Context_UserInterface_FormDialog } from 'rfbp_core/services/context'
import { DatabaseGetLiveCollection, DatabaseSetMergeDocument } from 'rfbp_core/services/database_management'
import {
  dynamicSort,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnTimestampFromUnknownDateFormat,
} from 'rfbp_core/services/helper_functions'
import { onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import {
  TsInterface_UnspecifiedObject,
  TsType_MuiComponentColors,
  TsType_MuiComponentColorsWithInherit,
  TsType_UnknownPromise,
  TsType_VoidFunction,
} from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AllAlertsListPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ALERTS: JSX.Element = <Trans>Alerts</Trans>
const s_CREATE_NEW_REMINDER: JSX.Element = <Trans>Create New Reminder</Trans>
const s_DATE: JSX.Element = <Trans>Date</Trans>
const s_DISMISS: JSX.Element = <Trans>Dismiss</Trans>
const s_END_DATE: JSX.Element = <Trans>End Date</Trans>
const s_GO_TO_PROJECT: JSX.Element = <Trans>Go to project</Trans>
const s_MARK_AS_UNREAD: JSX.Element = <Trans>Mark as Unread</Trans>
const s_NO_ACTIVE_REMINDERS: JSX.Element = <Trans>No Active Reminders</Trans>
const s_NO_READ_ALERTS_FOR: JSX.Element = <Trans>No read alerts for</Trans>
const s_NO_SCHEDULED_REMINDERS: JSX.Element = <Trans>No Scheduled Reminders</Trans>
const s_NO_UNREAD_ALERTS: JSX.Element = <Trans>No unread alerts</Trans>
const s_NO_UNREAD_MESSAGES: JSX.Element = <Trans>No unread messages</Trans>
const s_PAST_ALERTS: JSX.Element = <Trans>Past Alerts</Trans>
const s_SCHEDULED_REMINDERS: JSX.Element = <Trans>Scheduled Reminders</Trans>
const s_START_DATE: JSX.Element = <Trans>Start Date</Trans>
const s_UNREAD_ALERTS: JSX.Element = <Trans>Unread Alerts</Trans>
const s_UNREAD_MESSAGES: JSX.Element = <Trans>Unread Messages</Trans>
const se_ALERTS = 'Alerts'
// { sort-end } - displayed text

// Table
const tabsSettings: TsInterface_TabsSettingsUrl = {
  baseUrl: ApplicationPages.AllAlertsListPage.url(),
  tabQueryParam: 'tab',
  overridePageTitle: true,
  basePageTitle: se_ALERTS,
}

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_activeUserReminders, us_setActiveUserReminders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_alertDate, us_setAlertDate] = useState<Date>(new Date())
  const [us_loadedOpenReminders, us_setLoadedOpenReminders] = useState<boolean>(false)
  const [us_loadedProjectMessages, us_setLoadedProjectMessages] = useState<boolean>(false)
  const [us_loadedQueriedReminders, us_setLoadedQueriedReminders] = useState<boolean>(false)
  const [us_loadedReadAlerts, us_setLoadedReadAlerts] = useState<boolean>(false)
  const [us_loadedUnreadAlerts, us_setLoadedUnreadAlerts] = useState<boolean>(false)
  const [us_notificationReloadTimestamp, us_setNotificationReloadTimestamp] = useState<number>(new Date().getTime())
  const [us_pastAlerts, us_setPastAlerts] = useState<TsInterface_UnspecifiedObject>({})
  const [us_reminderEndDate, us_setReminderEndDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1))
  const [us_reminderStartDate, us_setReminderStartDate] = useState<Date>(new Date(new Date().getFullYear(), new Date().getMonth(), 1))
  const [us_scheduledUserReminders, us_setScheduledUserReminders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unreadAlerts, us_setUnreadAlerts] = useState<TsInterface_UnspecifiedObject>({})
  const [us_unreadProjectMessages, us_setUnreadProjectMessages] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_GlobalUser } = useContext(Context_RootData_GlobalUser)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  // useEffect(() => {
  // document.title = se_ALERTS
  // }, [ ])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnreadAlerts(newData)
      if (us_notificationReloadTimestamp > 0) {
        us_setLoadedUnreadAlerts(true)
      }
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_NotificationsUserUnread_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string, 'timestamp_created', false, null, {}),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser.key, us_notificationReloadTimestamp])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setPastAlerts(newData)
      if (us_notificationReloadTimestamp > 0) {
        us_setLoadedReadAlerts(true)
      }
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      let cleanedAlertDate = returnDateFromUnknownDateFormat(us_alertDate)
      let startTimestamp = new Date(cleanedAlertDate.getFullYear(), cleanedAlertDate.getMonth(), cleanedAlertDate.getDate()).getTime()
      let endTimestamp = new Date(cleanedAlertDate.getFullYear(), cleanedAlertDate.getMonth(), cleanedAlertDate.getDate() + 1).getTime()
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_NotificationsUserRead_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string, 'timestamp_created', false, null, {
              startAt: startTimestamp,
              endAt: endTimestamp,
            }),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser.key, us_notificationReloadTimestamp, us_alertDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUnreadProjectMessages(newData)
      us_setLoadedProjectMessages(true)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_UserUnreadMessageThreadsCollection_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser.key])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLoadedQueriedReminders(true)
      us_setScheduledUserReminders(newData)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_RemindersDateRange_Query(
              res_GCK.clientKey,
              uc_RootData_GlobalUser.key as string,
              new Date(us_reminderStartDate),
              new Date(us_reminderEndDate),
            ),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser.key, us_reminderStartDate, us_reminderEndDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setLoadedOpenReminders(true)
      us_setActiveUserReminders(newData)
      ur_forceRerender()
    }
    if (uc_RootData_GlobalUser.key != null) {
      let cutoffTimestamp = new Date()
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_OpenReminders_Query(res_GCK.clientKey, uc_RootData_GlobalUser.key as string, cutoffTimestamp),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, uc_RootData_GlobalUser.key])

  // Functions
  const calendarDateOnChange = (newCalendarDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setLoadedReadAlerts(false)
      us_setAlertDate(newCalendarDate)
      resolve({ success: true })
    })
  }

  const changeNotificationStatus = (notifcationKey: string, status: string): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let updateObject = {
          status: status,
        }
        DatabaseSetMergeDocument(DatabaseRef_Notification_Document(res_GCK.clientKey, notifcationKey), updateObject)
          .then((res_DSMD) => {
            us_setNotificationReloadTimestamp(new Date().getTime())
            ur_forceRerender()
          })
          .catch((rej_DSMD) => {
            console.error(rej_DSMD)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  const changeReminderStatus = (reminderKey: string, status: string): void => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        let updateObject = {
          status: status,
        }
        DatabaseSetMergeDocument(DatabaseRef_Reminder_Document(res_GCK.clientKey, reminderKey), updateObject)
          .then((res_DSMD) => {
            ur_forceRerender()
          })
          .catch((rej_DSMD) => {
            console.error(rej_DSMD)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }

  const calendarReminderStartDateOnChange = (newCalendarDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setReminderStartDate(newCalendarDate)
      resolve({ success: true })
    })
  }

  const calendarReminderEndDateOnChange = (newCalendarDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      us_setReminderEndDate(newCalendarDate)
      resolve({ success: true })
    })
  }

  // JSX Generation
  const rJSX_Alert = (alert: TsInterface_UnspecifiedObject): JSX.Element => {
    let alertJSX = <></>
    let iconColor = themeVariables.primary_main
    let buttonColor: TsType_MuiComponentColorsWithInherit = 'primary'
    let alertIcon = <></>
    switch (alert.type) {
      case 'success':
        iconColor = themeVariables.success_main
        buttonColor = 'success'
        alertIcon = (
          <Icon
            icon="circle-check"
            type="solid"
            size="lg"
          />
        )
        break
      case 'info':
        iconColor = themeVariables.info_main
        buttonColor = 'info'
        alertIcon = (
          <Icon
            icon="square-info"
            type="solid"
            size="lg"
          />
        )
        break
      case 'warning':
        iconColor = themeVariables.warning_main
        buttonColor = 'warning'
        alertIcon = (
          <Icon
            icon="triangle-exclamation"
            type="solid"
            size="lg"
          />
        )
        break
      case 'error':
        iconColor = themeVariables.error_main
        buttonColor = 'error'
        alertIcon = (
          <Icon
            icon="siren-on"
            type="solid"
            size="lg"
          />
        )
        break
    }
    let actionIconJSX = <></>
    if (alert.status === 'unread') {
      actionIconJSX = (
        <Tooltip title={s_DISMISS}>
          <Box
            onClick={() => {
              changeNotificationStatus(alert.key, 'read')
            }}
          >
            <Icon
              icon="circle-x"
              type="solid"
              className="tw-cursor-pointer"
            ></Icon>
          </Box>
        </Tooltip>
      )
    } else {
      actionIconJSX = (
        <Tooltip title={s_MARK_AS_UNREAD}>
          <Box
            onClick={() => {
              changeNotificationStatus(alert.key, 'unread')
            }}
          >
            <Icon
              icon="bell-on"
              type="solid"
              className="tw-cursor-pointer"
            ></Icon>
          </Box>
        </Tooltip>
      )
    }
    let actionJSX = <></>
    if (alert != null && alert.associated_project_key) {
      actionJSX = (
        <Button
          variant="outlined"
          color={buttonColor}
          onClick={(event) => {
            // TODO - handle other user types probably
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(alert.associated_project_key))
          }}
        >
          {s_GO_TO_PROJECT}
        </Button>
      )
    }
    alertJSX = (
      <Box
        sx={{ background: themeVariables.background_paper, width: '90%', minWidth: '300px', maxWidth: '600px' }}
        className="tw-p-2 tw-rounded-lg tw-my-2 tw-mx-auto"
      >
        <Stack
          direction="row"
          spacing={1}
          className="tw-justify-between"
        >
          <Box className="tw-text-left">
            <Box>
              <Box
                className="tw-inline-block tw-mr-3"
                sx={{ color: iconColor }}
              >
                {alertIcon}
              </Box>
              <Typography
                variant="body1"
                className="tw-inline-block tw-font-black"
              >
                {alert.title}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                className="tw-inline-block tw-ml-8"
              >
                {alert.text}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}>{actionIconJSX}</Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          className="tw-justify-between tw-mt-2"
        >
          <Box className="tw-pl-8">{actionJSX}</Box>
          <Box
            className="tw-opacity-30"
            sx={{ alignSelf: 'flex-end' }}
          >
            <Typography variant="body1">
              {returnFormattedDate(alert.timestamp_created, 'h:mm a')} · {returnFormattedDate(alert.timestamp_created, 'MMM D, YYYY')}
            </Typography>
          </Box>
        </Stack>
      </Box>
    )
    return alertJSX
  }

  const rJSX_UnreadAlertsTab = (): JSX.Element => {
    let unreadAlertsTabJSX = <></>
    if (us_loadedUnreadAlerts === false) {
      unreadAlertsTabJSX = (
        <Box className="tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    } else {
      if (objectToArray(us_unreadAlerts).length === 0) {
        unreadAlertsTabJSX = (
          <Box className="tw-text-center">
            <Typography
              className="tw-my-10"
              variant="h5"
            >
              {s_NO_UNREAD_ALERTS}
            </Typography>
          </Box>
        )
      } else {
        unreadAlertsTabJSX = (
          <Box className="tw-text-center">
            {objectToArray(us_unreadAlerts)
              .sort(dynamicSort('timestamp_created', 'desc'))
              .map((alert, alertIndex) => (
                <Box
                  className="tw-text-center"
                  key={alertIndex}
                >
                  {rJSX_Alert(alert)}
                </Box>
              ))}
          </Box>
        )
      }
    }
    return unreadAlertsTabJSX
  }

  const rJSX_ReadAlertsTab = (): JSX.Element => {
    let unreadAlertsTabJSX = <></>
    if (us_loadedReadAlerts === false) {
      unreadAlertsTabJSX = (
        <Box className="tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    } else {
      if (objectToArray(us_pastAlerts).length === 0) {
        unreadAlertsTabJSX = (
          <Box className="tw-text-center">
            <Typography
              className="tw-my-10"
              variant="h5"
            >
              {s_NO_READ_ALERTS_FOR} {returnFormattedDate(us_alertDate, 'MMM D, YYYY')}
            </Typography>
          </Box>
        )
      } else {
        unreadAlertsTabJSX = (
          <Box className="tw-text-center">
            {objectToArray(us_pastAlerts)
              .sort(dynamicSort('timestamp_created', null))
              .map((alert, alertIndex) => (
                <Box
                  className="tw-text-center"
                  key={alertIndex}
                >
                  {rJSX_Alert(alert)}
                </Box>
              ))}
          </Box>
        )
      }
    }
    return unreadAlertsTabJSX
  }

  const rJSX_UnreadMessage = (message: TsInterface_UnspecifiedObject): JSX.Element => {
    let messageJSX = <></>
    let messageIconJSX = <></>
    let threadType: string | null = null
    let projectKey: string | null = null
    if (message != null) {
      if (message.key != null) {
        let messageThreadKey = message.key
        let lastUnderscore = messageThreadKey.lastIndexOf('_')
        projectKey = messageThreadKey.substring(0, lastUnderscore)
        threadType = messageThreadKey.substring(lastUnderscore + 1, messageThreadKey.length)
      }
      let buttonColor: TsType_MuiComponentColors = undefined
      if (threadType === 'internal1') {
        messageIconJSX = (
          <Box sx={{ color: themeVariables.primary_main }}>
            <Icon
              icon="comments"
              type="solid"
              size="lg"
            />
          </Box>
        )
        buttonColor = 'primary'
      }
      if (threadType === 'internal2') {
        messageIconJSX = (
          <Box sx={{ color: themeVariables.primary_main }}>
            <Icon
              icon="comments"
              type="solid"
              size="lg"
            />
          </Box>
        )
        buttonColor = 'primary'
      }
      if (threadType === 'internal3') {
        messageIconJSX = (
          <Box sx={{ color: themeVariables.primary_main }}>
            <Icon
              icon="comments"
              type="solid"
              size="lg"
            />
          </Box>
        )
        buttonColor = 'primary'
      } else if (threadType === 'sales') {
        messageIconJSX = (
          <Box sx={{ color: themeVariables.success_main }}>
            <Icon
              icon="comments"
              type="solid"
              size="lg"
            />
          </Box>
        )
        buttonColor = 'success'
      } else if (threadType === 'customer') {
        messageIconJSX = (
          <Box sx={{ color: themeVariables.warning_main }}>
            <Icon
              icon="comments"
              type="solid"
              size="lg"
            />
          </Box>
        )
        buttonColor = 'warning'
      }
      let actionJSX = <></>
      if (projectKey != null) {
        actionJSX = (
          <Button
            variant="outlined"
            color={buttonColor}
            onClick={(event) => {
              if (projectKey != null) {
                // TODO - handle other user types probably
                onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(projectKey))
              }
            }}
          >
            {s_GO_TO_PROJECT}
          </Button>
        )
      }
      // Full Message
      messageJSX = (
        <Box
          sx={{ background: themeVariables.background_paper, width: '90%', minWidth: '300px', maxWidth: '600px' }}
          className="tw-p-2 tw-rounded-lg tw-my-2 tw-mx-auto"
        >
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between"
          >
            <Box className="tw-text-left">
              <Box>
                <Box className="tw-inline-block tw-mr-3">{messageIconJSX}</Box>
                <Typography
                  variant="body1"
                  className="tw-inline-block tw-font-black"
                >
                  {getProp(message, 'last_message_sender_name', '')}
                </Typography>
                <Typography
                  variant="body1"
                  className="tw-inline-block tw-italic tw-opacity-30 tw-ml-2"
                >
                  ({getProp(message, 'thread_name_override_desktop', '')})
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="body1"
                  className="tw-inline-block tw-ml-8"
                >
                  {getProp(message, 'last_message_text', '')}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}>{/* { actionIconJSX } */}</Box>
          </Stack>
          <Stack
            direction="row"
            spacing={1}
            className="tw-justify-between tw-mt-2"
          >
            <Box className="tw-pl-8">{actionJSX}</Box>
            <Box
              className="tw-opacity-30"
              sx={{ alignSelf: 'flex-end' }}
            >
              <Typography variant="body1">
                {returnFormattedDate(message.last_message_timestamp, 'h:mm a')} · {returnFormattedDate(message.last_message_timestamp, 'MMM D, YYYY')}
              </Typography>
            </Box>
          </Stack>
        </Box>
      )
    }
    return messageJSX
  }

  const rJSX_UnreadMessagesTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_loadedProjectMessages === false) {
      tabJSX = (
        <Box className="tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    } else {
      if (objectToArray(us_unreadProjectMessages).length === 0) {
        tabJSX = (
          <Box className="tw-text-center">
            <Typography
              className="tw-my-10"
              variant="h5"
            >
              {s_NO_UNREAD_MESSAGES}
            </Typography>
          </Box>
        )
      } else {
        tabJSX = (
          <Box>
            {objectToArray(us_unreadProjectMessages)
              .sort(dynamicSort('last_message_timestamp', 'desc'))
              .map((message, messageIndex) => (
                <Box
                  className="tw-text-center"
                  key={messageIndex}
                >
                  {rJSX_UnreadMessage(message)}
                </Box>
              ))}
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_Reminder = (reminder: TsInterface_UnspecifiedObject): JSX.Element => {
    let reminderJSX = <></>
    let iconColor = themeVariables.primary_main
    let buttonColor: TsType_MuiComponentColorsWithInherit = 'primary'
    let reminderIcon = <></>
    let cutoffDate = new Date().getTime()
    if (reminder.status === 'read') {
      iconColor = themeVariables.info_main
      buttonColor = 'info'
      reminderIcon = (
        <Icon
          icon="circle-check"
          type="solid"
          size="lg"
        />
      )
    } else if (cutoffDate < returnTimestampFromUnknownDateFormat(reminder.timestamp_reminder)) {
      iconColor = themeVariables.warning_main
      buttonColor = 'warning'
      reminderIcon = (
        <Icon
          icon="clock"
          type="solid"
          size="lg"
        />
      )
    } else {
      iconColor = themeVariables.error_main
      buttonColor = 'error'
      reminderIcon = (
        <Icon
          icon="alarm-clock"
          type="solid"
          size="lg"
        />
      )
    }
    let actionIconJSX = <></>
    if (reminder.status === 'unread') {
      actionIconJSX = (
        <Tooltip title={s_DISMISS}>
          <Box
            onClick={() => {
              changeReminderStatus(reminder.key, 'read')
            }}
          >
            <Icon
              icon="circle-x"
              type="solid"
              className="tw-cursor-pointer"
            ></Icon>
          </Box>
        </Tooltip>
      )
    } else {
      actionIconJSX = (
        <Tooltip title={s_MARK_AS_UNREAD}>
          <Box
            onClick={() => {
              changeReminderStatus(reminder.key, 'unread')
            }}
          >
            <Icon
              icon="bell-on"
              type="solid"
              className="tw-cursor-pointer"
            ></Icon>
          </Box>
        </Tooltip>
      )
    }
    let actionJSX = <></>
    if (reminder != null && reminder.associated_project_key) {
      actionJSX = (
        <Button
          variant="outlined"
          color={buttonColor}
          onClick={(event) => {
            // TODO - handle other user types probably
            onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminActiveProjectViewPage.url(reminder.associated_project_key))
          }}
        >
          {s_GO_TO_PROJECT}
        </Button>
      )
    }
    reminderJSX = (
      <Box
        sx={{ background: themeVariables.background_paper, width: '90%', minWidth: '300px', maxWidth: '600px' }}
        className="tw-p-2 tw-rounded-lg tw-my-2 tw-mx-auto"
      >
        <Stack
          direction="row"
          spacing={1}
          className="tw-justify-between"
        >
          <Box className="tw-text-left">
            <Box>
              <Box
                className="tw-inline-block tw-mr-3"
                sx={{ color: iconColor }}
              >
                {reminderIcon}
              </Box>
              <Typography
                variant="body1"
                className="tw-inline-block tw-font-black"
              >
                {reminder.title}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                className="tw-inline-block tw-ml-8"
              >
                {reminder.text}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ 'opacity': 0.2, '&:hover': { opacity: 1 } }}>{actionIconJSX}</Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          className="tw-justify-between tw-mt-2"
        >
          <Box className="tw-pl-8">{actionJSX}</Box>
          <Box
            className="tw-opacity-30"
            sx={{ alignSelf: 'flex-end' }}
          >
            <Typography variant="body1">
              {returnFormattedDate(reminder.timestamp_reminder, 'h:mm a')} · {returnFormattedDate(reminder.timestamp_reminder, 'MMM D, YYYY')}
            </Typography>
          </Box>
        </Stack>
      </Box>
    )
    return reminderJSX
  }

  const rJSX_ActiveRemindersTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_loadedOpenReminders === false) {
      tabJSX = (
        <Box className="tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    } else {
      if (objectToArray(us_activeUserReminders).length === 0) {
        tabJSX = (
          <Box className="tw-text-center">
            <Typography
              className="tw-my-10"
              variant="h5"
            >
              {s_NO_ACTIVE_REMINDERS}
            </Typography>
          </Box>
        )
      } else {
        tabJSX = (
          <Box>
            <Box className="tw-text-center">
              {objectToArray(us_activeUserReminders)
                .sort(dynamicSort('timestamp_reminder', 'asc'))
                .map((reminder, reminderIndex) => (
                  <Box
                    className="tw-text-center"
                    key={reminderIndex}
                  >
                    {rJSX_Reminder(reminder)}
                  </Box>
                ))}
            </Box>
          </Box>
        )
      }
    }
    return tabJSX
  }

  const rJSX_ScheduledRemindersTab = (): JSX.Element => {
    let tabJSX = <></>
    if (us_loadedQueriedReminders === false) {
      tabJSX = (
        <Box className="tw-text-center">
          <CircularProgress
            size="100px"
            variant="indeterminate"
            color="secondary"
            className="tw-m-auto"
          />
        </Box>
      )
    } else {
      let tabContentJSX = <></>
      if (objectToArray(us_scheduledUserReminders).length === 0) {
        tabContentJSX = (
          <Box className="tw-text-center">
            <Typography
              className="tw-my-10"
              variant="h5"
            >
              {s_NO_SCHEDULED_REMINDERS}
            </Typography>
          </Box>
        )
      } else {
        tabContentJSX = (
          <Box>
            <Box className="tw-text-center">
              {objectToArray(us_scheduledUserReminders)
                .sort(dynamicSort('timestamp_reminder', 'asc'))
                .map((reminder, reminderIndex) => (
                  <Box
                    className="tw-text-center"
                    key={reminderIndex}
                  >
                    {rJSX_Reminder(reminder)}
                  </Box>
                ))}
            </Box>
          </Box>
        )
      }
      tabJSX = (
        <Box>
          <Box className="tw-mb-2">
            <Stack
              direction="row"
              spacing={1}
            >
              <DatePicker
                key={'start_date'}
                datePickerText={s_START_DATE}
                datePickerDate={us_reminderStartDate}
                datePickerDisabled={false}
                datePickerDateOnChange={calendarReminderStartDateOnChange}
              />
              <DatePicker
                key={'end_date'}
                datePickerText={s_END_DATE}
                datePickerDate={us_reminderEndDate}
                datePickerDisabled={false}
                datePickerDateOnChange={calendarReminderEndDateOnChange}
              />
              <Button
                className=""
                color="success"
                variant="contained"
                startIcon={<Icon icon="circle-plus"></Icon>}
                onClick={() => {
                  createScheduledReminder(uc_setUserInterface_FormDialogDisplay, uc_RootData_GlobalUser, null, uc_RootData_ClientKey, uc_setRootData_ClientKey)
                }}
              >
                {s_CREATE_NEW_REMINDER}
              </Button>
            </Stack>
          </Box>
          <Box className="tw-text-center tw-p-2">{tabContentJSX}</Box>
        </Box>
      )
    }
    return tabJSX
  }

  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_ALERTS}
        pageKey={pageKey}
        content={
          <Box>
            <TabsUrl
              tabs={[
                {
                  tabUrlKey: 'Unread_Messages',
                  tabHeader: (
                    <>
                      <Badge
                        badgeContent={objectToArray(us_unreadProjectMessages).length}
                        color="error"
                      >
                        {s_UNREAD_MESSAGES}
                      </Badge>
                    </>
                  ),
                  tabContent: <Box className="tw-text-center tw-p-2">{rJSX_UnreadMessagesTab()}</Box>,
                },
                {
                  tabUrlKey: 'Unread_Alerts',
                  tabHeader: (
                    <>
                      <Badge
                        badgeContent={objectToArray(us_unreadAlerts).length}
                        color="error"
                      >
                        {s_UNREAD_ALERTS}
                      </Badge>
                    </>
                  ),
                  tabContent: <Box className="tw-text-center tw-p-2">{rJSX_UnreadAlertsTab()}</Box>,
                },
                {
                  tabUrlKey: 'Past_Alerts',
                  tabHeader: s_PAST_ALERTS,
                  tabContent: (
                    <Box className="tw-text-center tw-p-2">
                      <Box className="tw-text-left">
                        <DatePicker
                          key={'alert_date'}
                          datePickerText={s_DATE}
                          datePickerDate={us_alertDate}
                          datePickerDisabled={false}
                          datePickerDateOnChange={calendarDateOnChange}
                        />
                      </Box>
                      {rJSX_ReadAlertsTab()}
                    </Box>
                  ),
                },
                {
                  tabUrlKey: 'Active_Reminders',
                  tabHeader: (
                    <>
                      <Badge
                        badgeContent={objectToArray(us_activeUserReminders).length}
                        color="error"
                      >
                        {rLIB('Active Reminders')}
                      </Badge>
                    </>
                  ),
                  tabContent: <Box className="tw-text-center tw-p-2">{rJSX_ActiveRemindersTab()}</Box>,
                },
                {
                  tabUrlKey: 'Scheduled_Reminders',
                  tabHeader: s_SCHEDULED_REMINDERS,
                  tabContent: <Box className="tw-text-center tw-p-2">{rJSX_ScheduledRemindersTab()}</Box>,
                },
              ]}
              tabsSettings={tabsSettings}
            />
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

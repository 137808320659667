///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Typography } from '@mui/material/'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { AuthenticatedContainer } from 'rfbp_aux/containers/authenticated_container'
import { ApplicationPages } from 'rfbp_aux/data/application_structure'
import {
  DatabaseRef_IncidentReportFiles_Collection,
  DatabaseRef_IncidentReportFile_Document,
  DatabaseRef_IncidentReportForumMessages_Collection,
  DatabaseRef_IncidentReportForumMessage_Document,
  DatabaseRef_IncidentReportForumThreads_Collection,
  DatabaseRef_IncidentReportForumThread_Document,
  DatabaseRef_IncidentReport_Document,
} from 'rfbp_aux/services/database_endpoints/operations/incident_reports'
import { StorageRef_IncidentReportsFile, StorageRef_IncidentReports_MessageThread_File } from 'rfbp_aux/services/storage_endpoints/incident_reports'
import { FileSystemBasic } from 'rfbp_core/components/file_system/file_system_basic'
import { ForumBasic } from 'rfbp_core/components/forum/forum_basic'
import { Icon } from 'rfbp_core/components/icons'
import { TabsUrl } from 'rfbp_core/components/tabs'
import { rLIB } from 'rfbp_core/localization/library'
import { Context_RootData_ClientKey, Context_RootData_ClientUser } from 'rfbp_core/services/context'
import { DatabaseGetLiveDocument } from 'rfbp_core/services/database_management'
import { returnFormattedDate } from 'rfbp_core/services/helper_functions'
import { directAppNavigation, onClickAppNavigation } from 'rfbp_core/services/navigation/navigation_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { determineDatabasePageSpecificPermissions } from '../database/database_home'
import { incidentReportTypeOptions } from './incident_reports_list'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data
const pageKey: string = ApplicationPages['AdminDatabaseIncidentReportViewPage']['key']

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_BACK_TO_ALL_INCIDENT_REPORTS: JSX.Element = <Trans>Back to all incident reports</Trans>
const s_DRIVER: JSX.Element = <Trans>Driver</Trans>
const s_FILES: JSX.Element = <Trans>Files</Trans>
const s_INCIDENT_DATE: JSX.Element = <Trans>Incident Date</Trans>
const s_INCIDENT_LOCATION: JSX.Element = <Trans>Incident Location</Trans>
const s_INCIDENT_REPORT: JSX.Element = <Trans>Incident Report</Trans>
const s_INCIDENT_TYPE: JSX.Element = <Trans>Incident Type</Trans>
const s_MISSING: JSX.Element = <Trans>Missing</Trans>
const s_NOTES: JSX.Element = <Trans>Notes</Trans>
const s_STATUS: JSX.Element = <Trans>Status</Trans>
const s_VEHICLE_VIN: JSX.Element = <Trans>Vehicle VIN</Trans>
const s_WHAT_HAPPENED: JSX.Element = <Trans>What happened?</Trans>
const se_INCIDENT_REPORT: string = 'Incident Report'
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

export const Container: React.FC = (): JSX.Element => {
  // Props
  const params = useParams()
  const vehicleIncidentReportKey: string = params.id as string

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_vehicleIncidentReport, us_setIncidentReport] = useState<TsInterface_UnspecifiedObject>({})
  const un_routerNavigation = useNavigate()
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientUser } = useContext(Context_RootData_ClientUser)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    if (uc_RootData_ClientUser != null) {
      let databaseSectionKey = 'incident_reports'
      let pagePermissions = determineDatabasePageSpecificPermissions(uc_RootData_ClientUser)
      if (pagePermissions[databaseSectionKey] !== true && uc_RootData_ClientUser.key !== '') {
        directAppNavigation(un_routerNavigation, ApplicationPages.AdminDatabaseHomePage.url())
      }
    }
  }, [uc_RootData_ClientUser, un_routerNavigation])

  useEffect(() => {
    document.title = se_INCIDENT_REPORT
  }, [])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setIncidentReport(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveDocument(DatabaseRef_IncidentReport_Document(res_GCK.clientKey, vehicleIncidentReportKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, vehicleIncidentReportKey])

  // Functions

  // JSX Generation
  const rJSX_BackButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        color="inherit"
        variant="outlined"
        onClick={(event) => {
          onClickAppNavigation(event, un_routerNavigation, ApplicationPages.AdminDatabaseIncidentReportsListPage.url())
        }}
        disableElevation
        startIcon={<Icon icon="chevron-left" />}
        className="tw-mr-2"
      >
        {s_BACK_TO_ALL_INCIDENT_REPORTS}
      </Button>
    )
    return buttonJSX
  }

  // Details Tab
  const rJSX_MissingText = (): JSX.Element => {
    let missingTextJSX = (
      <Box
        component={'span'}
        className="tw-opacity-30 tw-italic"
      >
        {s_MISSING}
      </Box>
    )
    return missingTextJSX
  }

  const rJSX_TimestampDetails = (label: JSX.Element, propKey: string): JSX.Element => {
    let detailsJSX = <></>
    let dateJSX = rJSX_MissingText()
    if (us_vehicleIncidentReport[propKey] != null) {
      dateJSX = (
        <Box
          component={'span'}
          className="tw-mr-2 tw-opacity-50"
        >
          {returnFormattedDate(us_vehicleIncidentReport[propKey], 'D MMM YYYY h:mm a')}
        </Box>
      )
    }
    detailsJSX = (
      <Typography
        variant="h6"
        className=""
      >
        <Box
          component={'span'}
          className="tw-mr-2"
        >
          {label}:
        </Box>
        {dateJSX}
      </Typography>
    )
    return detailsJSX
  }

  const rJSX_StringDetails = (label: JSX.Element, propKey: string): JSX.Element => {
    let detailsJSX = <></>
    let valueJSX = rJSX_MissingText()
    if (us_vehicleIncidentReport[propKey] != null) {
      valueJSX = (
        <Box
          component={'span'}
          className="tw-mr-2 tw-opacity-50"
        >
          {us_vehicleIncidentReport[propKey]}
        </Box>
      )
    }
    detailsJSX = (
      <Typography
        variant="h6"
        className=""
      >
        <Box
          component={'span'}
          className="tw-mr-2"
        >
          {label}:
        </Box>
        {valueJSX}
      </Typography>
    )
    return detailsJSX
  }

  const rJSX_TypeDetails = (label: JSX.Element, propKey: string): JSX.Element => {
    let detailsJSX = <></>
    let valueJSX = rJSX_MissingText()
    if (us_vehicleIncidentReport[propKey] != null) {
      if (
        incidentReportTypeOptions != null &&
        incidentReportTypeOptions[us_vehicleIncidentReport[propKey]] != null &&
        incidentReportTypeOptions[us_vehicleIncidentReport[propKey]]['value'] != null
      ) {
        valueJSX = (
          <Box
            component={'span'}
            className="tw-mr-2 tw-opacity-50"
          >
            {incidentReportTypeOptions[us_vehicleIncidentReport[propKey]]['value']}
          </Box>
        )
      } else {
        valueJSX = (
          <Box
            component={'span'}
            className="tw-mr-2 tw-opacity-50"
          >
            {us_vehicleIncidentReport[propKey]}
          </Box>
        )
      }
    }
    detailsJSX = (
      <Typography
        variant="h6"
        className=""
      >
        <Box
          component={'span'}
          className="tw-mr-2"
        >
          {label}:
        </Box>
        {valueJSX}
      </Typography>
    )
    return detailsJSX
  }

  const rJSX_DetailsTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Card className="tw-p-2">
        {rJSX_TimestampDetails(s_INCIDENT_DATE, 'timestamp_incident')}
        {rJSX_StringDetails(s_DRIVER, 'associated_user_name')}
        {rJSX_StringDetails(s_VEHICLE_VIN, 'associated_vehicle_vin')}
        {rJSX_StringDetails(s_INCIDENT_LOCATION, 'incident_location')}
        {rJSX_TypeDetails(s_INCIDENT_TYPE, 'incident_type')}
        {rJSX_StringDetails(s_WHAT_HAPPENED, 'notes')}
        {rJSX_StringDetails(s_STATUS, 'status')}
      </Card>
    )
    return tabJSX
  }

  /*
			associated_user_name


			timestamp_incident
			associated_user_name
			associated_vehicle_vin

			*/

  // Notes Tab
  const rJSX_NotesTab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box>
        <ForumBasic
          forumThreadCollectionDatabaseEndpoint={(clientKey) => {
            return DatabaseRef_IncidentReportForumThreads_Collection(clientKey, vehicleIncidentReportKey)
          }}
          forumThreadDocumentDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_IncidentReportForumThread_Document(clientKey, vehicleIncidentReportKey, threadKey)
          }}
          forumMessageCollectionDatabaseEndpoint={(clientKey, threadKey) => {
            return DatabaseRef_IncidentReportForumMessages_Collection(clientKey, vehicleIncidentReportKey, threadKey)
          }}
          forumMessageDocumentDatabaseEndpoint={(clientKey, threadKey, messageKey) => {
            return DatabaseRef_IncidentReportForumMessage_Document(clientKey, vehicleIncidentReportKey, threadKey, messageKey)
          }}
          forumMessageStorageEndpoint={(clientKey, threadKey, messageKey, fileName) => {
            return StorageRef_IncidentReports_MessageThread_File(clientKey, vehicleIncidentReportKey, threadKey, fileName)
          }}
          forumSettings={{
            allow_thread_creation: true,
            scrollbar_track_color: '#272b33',
            scrollbar_thumb_color: '#888',
            input_text_color: '#fff',
            base_message_container_offset_px: 336,
          }}
        />
      </Box>
    )
    return tabJSX
  }

  // Files Tab
  const rJSX_FilesTab = (): JSX.Element => {
    let tabJSX = (
      <FileSystemBasic
        fileSystemCollectionDatabaseEndpoint={(clientKey) => {
          return DatabaseRef_IncidentReportFiles_Collection(clientKey, vehicleIncidentReportKey)
        }}
        fileSystemDocumentDatabaseEndpoint={(clientKey, fileKey) => {
          return DatabaseRef_IncidentReportFile_Document(clientKey, vehicleIncidentReportKey, fileKey)
        }}
        fileSystemStorageEndpoint={(clientKey, fileName) => {
          return StorageRef_IncidentReportsFile(clientKey, vehicleIncidentReportKey, fileName)
        }}
        fileSystemHardCodedFolders={{}}
        fileSystemSettings={{
          allow_file_archiving: true,
          allow_file_movement: true,
          allow_file_unarchiving: true,
          allow_file_uploads: true,
          allow_folder_creation: true,
          allow_folder_deletion: true,
          allow_folder_movement: true,
          allow_folder_rename: true,
          archive_filter_visible: true,
        }}
      />
    )
    return tabJSX
  }

  // Page
  const rJSX_Page = (): JSX.Element => {
    let pageJSX = (
      <AuthenticatedContainer
        pageHeader={s_INCIDENT_REPORT}
        pageKey={pageKey}
        content={
          <Box>
            <Box>{rJSX_BackButton()}</Box>
            <Box>
              <TabsUrl
                tabsSettings={{
                  baseUrl: ApplicationPages.AdminDatabaseIncidentReportViewPage.url(vehicleIncidentReportKey),
                  tabQueryParam: 'tab',
                  overridePageTitle: true,
                  basePageTitle: se_INCIDENT_REPORT,
                }}
                tabs={[
                  {
                    tabUrlKey: 'Details',
                    tabHeader: rLIB('Details'),
                    tabContent: rJSX_DetailsTab(),
                  },
                  {
                    tabUrlKey: 'Notes',
                    tabHeader: s_NOTES,
                    tabContent: rJSX_NotesTab(),
                  },
                  {
                    tabUrlKey: 'Files',
                    tabHeader: s_FILES,
                    tabContent: rJSX_FilesTab(),
                  },
                ]}
              />
            </Box>
          </Box>
        }
      />
    )
    return pageJSX
  }

  // Render
  return <>{rJSX_Page()}</>
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:


		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box } from '@mui/material/'
import heic2any from 'heic2any'
import { useEffect, useState } from 'react'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  // propKey: any
  sx: TsInterface_UnspecifiedObject
  url: string
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const HeicImage = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_imageSX: TsInterface_ComponentProps['sx'] = getProp(props, 'sx', {})
  let pr_heicUrl: TsInterface_ComponentProps['url'] = getProp(props, 'url', null)

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_jpegUrl, us_setJpegUrl] = useState<string | null>(null)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    fetch(pr_heicUrl)
      .then((res_F) => res_F.blob())
      .then((res_FB) => {
        if (res_FB.type === 'image/heic') {
          heic2any({
            blob: res_FB,
            toType: 'image/jpeg',
          }).then((res_H2A) => {
            // @ts-expect-error - TODO: reason for error
            const url = URL.createObjectURL(res_H2A)
            us_setJpegUrl(url)
          })
        } else {
          const url = URL.createObjectURL(res_FB)
          us_setJpegUrl(url)
        }
      })
  }, [pr_heicUrl])

  // Functions

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = <Box></Box>
    if (us_jpegUrl != null) {
      pr_imageSX.backgroundImage = "url('" + us_jpegUrl + "')"
      componentJSX = <Box sx={pr_imageSX}></Box>
    }
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

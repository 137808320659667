///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_IncidentReports_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main')
}

export const DatabaseRef_IncidentReport_Document = (clientKey: string, incidentReportKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey)
}

// Files
export const DatabaseRef_IncidentReportFiles_Collection = (clientKey: string, incidentReportKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'files')
}

export const DatabaseRef_IncidentReportFile_Document = (clientKey: string, incidentReportKey: string, fileKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'files', fileKey)
}

// Forum
export const DatabaseRef_IncidentReportForumThreads_Collection = (clientKey: string, incidentReportKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'forum')
}

export const DatabaseRef_IncidentReportForumThread_Document = (
  clientKey: string,
  incidentReportKey: string,
  threadKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'forum', threadKey)
}

export const DatabaseRef_IncidentReportForumMessages_Collection = (
  clientKey: string,
  incidentReportKey: string,
  threadKey: string,
): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'forum', threadKey, 'messages')
}

export const DatabaseRef_IncidentReportForumMessage_Document = (
  clientKey: string,
  incidentReportKey: string,
  threadKey: string,
  messageKey: string,
): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'incident_reports', 'main', incidentReportKey, 'forum', threadKey, 'messages', messageKey)
}

///////////////////////////////
// Imports
///////////////////////////////

import { getStorage, ref, StorageReference } from 'firebase/storage'

///////////////////////////////
// Exports
///////////////////////////////

export const StorageRef_IncidentReportsFile = (clientKey: string, incidentReportKey: string, fileName: string): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/incident_reports/' + incidentReportKey + '/files/' + fileName)
}

export const StorageRef_IncidentReports_MessageThread_File = (
  clientKey: string,
  incidentReportKey: string,
  threadKey: string,
  fileName: string,
): StorageReference => {
  return ref(getStorage(), '/clients/' + clientKey + '/incident_reports/' + incidentReportKey + '/threads/' + threadKey + '/files/' + fileName)
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Components are reused segments of code used to create contend used to create containers (pages)

		TODO:
			[ ] Typescript - 1 instance of any

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, FormControl, TextField } from '@mui/material/'
import {
  rJSX_InputAdornment,
  TsInterface_DynamicAttributes,
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInput,
  TsInterface_FormSettings,
  TsType_InputChangeCallback,
} from 'rfbp_core/components/form'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ComponentProps {
  formAdditionalData: TsInterface_FormAdditionalData
  formData: TsInterface_FormData
  formHooks: TsInterface_FormHooksObject
  formInput: TsInterface_FormInput
  formSettings: TsInterface_FormSettings
  inputChangeCallback: TsType_InputChangeCallback
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Component
///////////////////////////////

export const TimestampDate = (props: TsInterface_ComponentProps): JSX.Element => {
  // Props
  let pr_formData: TsInterface_FormData = getProp(props, 'formData', {})
  let pr_formInput: TsInterface_FormInput = getProp(props, 'formInput', {})
  let pr_formSettings: TsInterface_FormSettings = getProp(props, 'formSettings', {})
  let pr_inputChangeCallback: TsType_InputChangeCallback = props.inputChangeCallback

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions

  // Generate JSX

  // JSX Generation
  const returnComponentJSX = (): JSX.Element => {
    // Dynamic Class Name
    let dynamicClassName = ''
    // Dynamic Attributes
    let dynamicAttributes: TsInterface_DynamicAttributes = {}
    if (pr_formInput['required'] === true) {
      dynamicAttributes['required'] = true
    }
    if (
      pr_formInput['required'] === true &&
      pr_formSettings.highlight_missing === true &&
      (pr_formData == null || pr_formData[pr_formInput['key']] == null || pr_formData[pr_formInput['key']] === '')
    ) {
      dynamicAttributes['error'] = true
    }
    if (pr_formInput['disabled'] === true) {
      dynamicAttributes['disabled'] = true
    }
    // Form Input JSX
    let componentJSX = (
      <Box>
        <FormControl
          className={dynamicClassName}
          fullWidth
        >
          <TextField
            color="primary"
            value={pr_formData[pr_formInput.key] || ''}
            id={pr_formInput['key']}
            label={pr_formInput['label']}
            margin="normal"
            placeholder={getProp(pr_formInput, 'placeholder', undefined)}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                pr_inputChangeCallback(pr_formInput.key, event.target.value, false)
              }
            }}
            onBlur={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                pr_inputChangeCallback(pr_formInput.key, event.target.value, true)
              }
            }}
            type="date"
            variant="outlined"
            InputProps={{
              startAdornment: rJSX_InputAdornment(pr_formInput, 'start'),
              endAdornment: rJSX_InputAdornment(pr_formInput, 'end'),
            }}
            inputProps={{
              min: getProp(pr_formInput, 'min', null),
              max: getProp(pr_formInput, 'max', null),
            }}
            InputLabelProps={{ shrink: true }}
            {...dynamicAttributes}
          />
        </FormControl>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{returnComponentJSX()}</>
}

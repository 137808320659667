///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, DocumentData, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_InternalAPILogs_Collection = (): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'global', 'internal_api_logs', 'main')
}

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Model files contains data and business logic specific to an individual database collection type

		TODO:

			Query most recent punch before right now...

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Divider, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from '@mui/material'
import { convertToTimeFormat, returnDateRangeKeys } from 'app/models/timesheets/timesheet_functions'
import { hardCodedAllocationTypes } from 'app/models/timesheets/timesheet_hard_coded_data'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import {
  TableBasic,
  TableCellBasic,
  TableCellTimestamp,
  TsInterface_TableAdditionalData,
  TsInterface_TableColumns,
  TsInterface_TableDataRow,
  TsInterface_TableHooks,
  TsInterface_TableManageActionsObject,
  TsInterface_TableSettings,
} from 'rfbp_core/components/table'
import { TableCellManage, TableCellManageProper } from 'rfbp_core/components/table/cells/table_cell_manage'
import { rLIB } from 'rfbp_core/localization/library'
import {
  dynamicSort,
  getProp,
  objectToArray,
  returnDateFromUnknownDateFormat,
  returnFormattedDate,
  returnFormattedDateKey,
} from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// const s_SHOWING_AGGREGATED_PUNCHES: JSX.Element =							<Trans>Showing aggregated punches</Trans>
// const s_SHOWING_RAW_PUNCHES: JSX.Element =									<Trans>Showing raw punches</Trans>
const s_ALLOCATION_TYPE: JSX.Element = <Trans>Allocation Type</Trans>
const s_BACK_TO_BACK_CLOCK_INS: JSX.Element = <Trans>Back to back clock ins</Trans>
const s_BACK_TO_BACK_CLOCK_OUTS: JSX.Element = <Trans>Back to back clock outs</Trans>
const s_BREAK: JSX.Element = <Trans>Break</Trans>
const s_CLOCKED_IN: JSX.Element = <Trans>Clocked In</Trans>
const s_CLOCK_IN: JSX.Element = <Trans>Clock In</Trans>
const s_CLOCK_OUT: JSX.Element = <Trans>Clock Out</Trans>
const s_CORRECTION_NOTES: JSX.Element = <Trans>Correction Notes</Trans>
const s_CREATED_BY: JSX.Element = <Trans>Created by</Trans>
const s_CREATE_PUNCH: JSX.Element = <Trans>Create Punch</Trans>
const s_DELETED: JSX.Element = <Trans>Deleted</Trans>
const s_EDITED_BY: JSX.Element = <Trans>Edited by</Trans>
const s_ERROR: JSX.Element = <Trans>Error</Trans>
const s_EVENT: JSX.Element = <Trans>Event</Trans>
const s_FRI: JSX.Element = <Trans>Fri</Trans>
const s_IF_IN_ERROR_REQUEST_CORRECTION_BEFORE_SUBMISSION: JSX.Element = <Trans>If in error, request correction before submission</Trans>
const s_MANAGEMENT: JSX.Element = <Trans>management</Trans>
const s_MISSING_FIRST_CLOCK_IN: JSX.Element = <Trans>Missing first clock in</Trans>
const s_MISSING_LAST_CLOCK_OUT: JSX.Element = <Trans>Missing last clock out</Trans>
const s_MON: JSX.Element = <Trans>Mon</Trans>
const s_MULTI_DAY_PUNCHES: JSX.Element = <Trans>Multi Day Punches</Trans>
const s_NON_WORKING_TIME: JSX.Element = <Trans>Non working time</Trans>
const s_NO_HOURS: JSX.Element = <Trans>No hours</Trans>
const s_OVERNIGHT_PUNCHES: JSX.Element = <Trans>Overnight Punches</Trans>
const s_PLEASE_CLOCK_OUT_BEFORE_SUBMITTING: JSX.Element = <Trans>Please clock out before submitting</Trans>
const s_PLEASE_SUBMIT_CORRECTIONS_BEFORE_SUBMITTING: JSX.Element = <Trans>Please submit corrections before submitting</Trans>
const s_REQUESTED_CORRECTIONS: JSX.Element = <Trans>Requested Corrections</Trans>
const s_SAT: JSX.Element = <Trans>Sat</Trans>
const s_SUBTYPE: JSX.Element = <Trans>Subtype</Trans>
const s_SUN: JSX.Element = <Trans>Sun</Trans>
const s_THU: JSX.Element = <Trans>Thu</Trans>
const s_TIME: JSX.Element = <Trans>Time</Trans>
const s_TIME_ON: JSX.Element = <Trans>Time On</Trans>
const s_TIME_SHEET_APPROVED_ON: JSX.Element = <Trans>Time sheet approved on</Trans>
const s_TIME_SHEET_NOT_SUBMITTED_YET: JSX.Element = <Trans>Time sheet not submitted yet</Trans>
const s_TIME_SHEET_SUBMITTED_ON: JSX.Element = <Trans>Time sheet submitted on</Trans>
const s_TO: JSX.Element = <Trans>to</Trans>
const s_TOTAL: JSX.Element = <Trans>Total</Trans>
const s_TUE: JSX.Element = <Trans>Tue</Trans>
const s_WARNING: JSX.Element = <Trans>Warning</Trans>
const s_WED: JSX.Element = <Trans>Wed</Trans>
// { sort-end } - displayed text

// Tables
const tableColumns_RawPunchData: TsInterface_TableColumns = {
  manage: TableCellManage({}),
  timestamp: TableCellTimestamp('timestamp', rLIB('Date'), 'timestamp', 'D MMM YYYY', false),
  timestamp2: TableCellTimestamp('timestamp', s_TIME, 'timestamp', 'h:mm a', false),
  type: {
    cell: {
      cell_css: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      cell_jsx: (rowData: TsInterface_TableDataRow, tableAdditionalData: TsInterface_TableAdditionalData, tableHooks: TsInterface_TableHooks) => {
        // Clock In Type
        let typeJSX = <>{rowData.type}</>
        let deletedJSX = <></>
        if (rowData.type === 'clock_in') {
          typeJSX = (
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-lg"
              sx={{ background: themeVariables.success_main }}
            >
              {s_CLOCK_IN}
            </Box>
          )
        } else if (rowData.type === 'clock_out') {
          typeJSX = (
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-lg"
              sx={{ background: themeVariables.error_main }}
            >
              {s_CLOCK_OUT}
            </Box>
          )
        }
        if (rowData.status === 'deleted') {
          deletedJSX = (
            <Box
              className="tw-inline-block tw-px-2 tw-py-1 tw-rounded-lg tw-ml-2"
              sx={{ background: themeVariables.gray_500 }}
            >
              {s_DELETED}
            </Box>
          )
        }
        // Logs
        let createdLogJSX = <></>
        let editedLogJSX = <></>
        if (
          rowData != null &&
          rowData.management_edits != null &&
          // @ts-expect-error - TODO: reason for error
          rowData.management_edits.created_by_management === true
        ) {
          if (rowData.associated_creator_name != null) {
            createdLogJSX = (
              <Box className="tw-italic tw-opacity-40">
                {s_CREATED_BY} {rowData.associated_creator_name}
              </Box>
            )
          } else {
            createdLogJSX = (
              <Box className="tw-italic tw-opacity-40">
                {s_CREATED_BY} {s_MANAGEMENT}
              </Box>
            )
          }
        }
        if (
          rowData != null &&
          rowData.management_edits != null &&
          // @ts-expect-error - TODO: reason for error
          rowData.management_edits.edited_by_management === true
        ) {
          if (rowData.associated_editor_name != null) {
            editedLogJSX = (
              <Box className="tw-italic tw-opacity-40">
                {s_EDITED_BY} {rowData.associated_editor_name}
              </Box>
            )
          } else {
            editedLogJSX = (
              <Box className="tw-italic tw-opacity-40">
                {s_EDITED_BY} {s_MANAGEMENT}
              </Box>
            )
          }
        }
        // Cell JSX
        let cellJSX = (
          <Box>
            <Stack direction="row">
              <Box>
                {typeJSX}
                {deletedJSX}
              </Box>
              <Box className="tw-ml-2">
                {createdLogJSX}
                {editedLogJSX}
              </Box>
            </Stack>
          </Box>
        )
        return cellJSX
      },
    },
    header: {
      header_css: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return ''
      },
      header_jsx: (tableAdditionalData: TsInterface_TableAdditionalData) => {
        return s_EVENT
      },
      header_sort_by: null,
    },
  },
  associated_allocation_type_name: TableCellBasic('associated_allocation_type_name', s_ALLOCATION_TYPE, 'associated_allocation_type_name'),
  associated_allocation_subtype_name: TableCellBasic('associated_allocation_subtype_name', s_SUBTYPE, 'associated_allocation_subtype_name'),
}

const tableSettings_RawPunchData: TsInterface_TableSettings = {
  paginated: false,
  // pagination_rows_per_page_default: 100,
  // pagination_rows_per_page_options: [ 10, 25, 50, 100 ],
  show_header: true,
  size: 'small',
  sortable: false,
  conditional_row_styles: [
    {
      className: 'tw-opacity-20 tw-line-through',
      conditional_display: {
        active: true,
        logic_type: 'comparison',
        source: 'rowData',
        prop: 'status',
        comparator: '==',
        value: 'deleted',
        conditions: [],
      },
    },
  ],
}

///////////////////////////////
// Functions
///////////////////////////////

// Individual Time Sheet JSX
const rJSX_TotalWeekHours = (aggregateData: TsInterface_UnspecifiedObject): JSX.Element => {
  let totalDayHoursJSX = <></>
  if (aggregateData != null && aggregateData['totals'] != null && aggregateData['totals']['total_minutes'] != null) {
    totalDayHoursJSX = <Box>{convertToTimeFormat(aggregateData['totals']['total_minutes'])}</Box>
  } else {
    totalDayHoursJSX = <Box className="tw-opacity-50">{convertToTimeFormat(0)}</Box>
  }
  return totalDayHoursJSX
}

const rJSX_TotalWeekBreakdownHours = (aggregateData: TsInterface_UnspecifiedObject, allocationType: TsInterface_UnspecifiedObject): JSX.Element => {
  let totalDayHoursJSX = <></>
  if (
    aggregateData != null &&
    allocationType != null &&
    allocationType.key != null &&
    aggregateData['breakdowns'] != null &&
    aggregateData['breakdowns'][allocationType.key] != null &&
    aggregateData['breakdowns'][allocationType.key]['total_minutes'] != null
  ) {
    totalDayHoursJSX = <Box>{convertToTimeFormat(aggregateData['breakdowns'][allocationType.key]['total_minutes'])}</Box>
  } else {
    totalDayHoursJSX = <Box className="tw-opacity-50">{convertToTimeFormat(0)}</Box>
  }
  return totalDayHoursJSX
}

const rJSX_TotalDayHours = (dateKey: string, aggregateData: TsInterface_UnspecifiedObject): JSX.Element => {
  let totalDayHoursJSX = <></>
  if (
    dateKey != null &&
    aggregateData != null &&
    aggregateData['totals'] != null &&
    aggregateData['totals']['total_minutes_by_date'] != null &&
    aggregateData['totals']['total_minutes_by_date'][dateKey] != null
  ) {
    totalDayHoursJSX = <Box>{convertToTimeFormat(aggregateData['totals']['total_minutes_by_date'][dateKey])}</Box>
  } else {
    totalDayHoursJSX = <Box className="tw-opacity-50">{convertToTimeFormat(0)}</Box>
  }
  return totalDayHoursJSX
}

const rJSX_TotalDayBreakdownHours = (
  dateKey: string,
  aggregateData: TsInterface_UnspecifiedObject,
  allocationType: TsInterface_UnspecifiedObject,
): JSX.Element => {
  let totalDayHoursJSX = <></>
  if (
    dateKey != null &&
    aggregateData != null &&
    allocationType != null &&
    allocationType.key != null &&
    aggregateData['breakdowns'] != null &&
    aggregateData['breakdowns'][allocationType.key] != null &&
    aggregateData['breakdowns'][allocationType.key]['total_minutes_by_date'] != null &&
    aggregateData['breakdowns'][allocationType.key]['total_minutes_by_date'][dateKey] != null
  ) {
    totalDayHoursJSX = <Box>{convertToTimeFormat(aggregateData['breakdowns'][allocationType.key]['total_minutes_by_date'][dateKey])}</Box>
  } else {
    totalDayHoursJSX = <Box className="tw-opacity-50">{convertToTimeFormat(0)}</Box>
  }
  return totalDayHoursJSX
}

const rJSX_MergedPunchHours = (combinedPunchEvent: TsInterface_UnspecifiedObject): JSX.Element => {
  let combinedPunchEventHours = <></>
  if (combinedPunchEvent != null && combinedPunchEvent['total_minutes'] != null) {
    combinedPunchEventHours = <Box>{convertToTimeFormat(combinedPunchEvent['total_minutes'])}</Box>
  } else {
    combinedPunchEventHours = <Box>{convertToTimeFormat(0)}</Box>
  }
  return combinedPunchEventHours
}

const returnTimePunchesFilteredToSpecificDate = (dateKey: string, eventData: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject[] => {
  let filteredData = objectToArray(eventData).filter((obj) => obj['date_key'] === dateKey)
  let sortedData = filteredData.sort(dynamicSort('clock_in_timestamp', 'asc'))
  return sortedData
}

const rJSX_ErrorOrWarningExplanation = (combinedPunchEvent: TsInterface_UnspecifiedObject): JSX.Element => {
  let explanationJSX = <></>
  if (combinedPunchEvent != null && combinedPunchEvent.combined_punch_event_error_type != null) {
    switch (combinedPunchEvent.combined_punch_event_error_type) {
      case 'missing_first_clock_in':
        explanationJSX = s_MISSING_FIRST_CLOCK_IN
        break
      case 'back_to_back_clock_ins':
        explanationJSX = s_BACK_TO_BACK_CLOCK_INS
        break
      case 'back_to_back_clock_outs':
        explanationJSX = s_BACK_TO_BACK_CLOCK_OUTS
        break
    }
  } else if (combinedPunchEvent != null && combinedPunchEvent.combined_punch_event_warning_type != null) {
    switch (combinedPunchEvent.combined_punch_event_warning_type) {
      case 'missing_last_clock_out':
        explanationJSX = s_MISSING_LAST_CLOCK_OUT
        break
      case 'multi_day_punches':
        explanationJSX = s_MULTI_DAY_PUNCHES
        break
    }
  }
  return explanationJSX
}

const rJSX_TimePunchIcon = (combinedPunchEvent: TsInterface_UnspecifiedObject): JSX.Element => {
  let iconJSX = <></>

  // TODO - finalaize icon list

  if (combinedPunchEvent != null && combinedPunchEvent.combined_punch_event_type === 'clocked_in') {
    if (combinedPunchEvent != null && combinedPunchEvent.clock_in_associated_allocation_type_key === 'non_working_time') {
      iconJSX = (
        <Tooltip
          title={s_NON_WORKING_TIME}
          placement="right"
        >
          <Box sx={{ color: themeVariables.info_main }}>
            <Icon icon="island-tropical" />
          </Box>
        </Tooltip>
      )
    } else if (combinedPunchEvent != null && combinedPunchEvent.clock_in_associated_allocation_type_key === 'break') {
      iconJSX = (
        <Tooltip
          title={s_BREAK}
          placement="right"
        >
          <Box sx={{ color: themeVariables.warning_main }}>
            <Icon icon="pause" />
          </Box>
        </Tooltip>
      )
    } else {
      iconJSX = (
        <Tooltip
          title={s_TIME_ON}
          placement="right"
        >
          <Box sx={{ color: themeVariables.success_light }}>
            <Icon icon="play" />
          </Box>
        </Tooltip>
      )
    }
  } else if (combinedPunchEvent != null && combinedPunchEvent.combined_punch_event_type === 'active') {
    iconJSX = (
      <Tooltip
        title={s_CLOCKED_IN}
        placement="right"
      >
        <Box sx={{ color: themeVariables.white }}>
          <Icon icon="traffic-light" />
        </Box>
      </Tooltip>
    )
  } else if (combinedPunchEvent != null && combinedPunchEvent.combined_punch_event_type === 'error') {
    iconJSX = (
      <Tooltip
        title={combinedPunchEvent.combined_punch_event_error_text}
        placement="right"
      >
        <Box sx={{ color: themeVariables.warning_main }}>
          <Icon icon="triangle-exclamation" />
        </Box>
      </Tooltip>
    )
  }
  return iconJSX
}

const rJSX_TimePunchEmptyRow = (dateKey: string, eventData: TsInterface_UnspecifiedObject): JSX.Element => {
  let emptyRowJSX = <></>
  let filteredData = objectToArray(eventData).filter((obj) => obj['date_key'] === dateKey)
  if (filteredData.length === 0) {
    emptyRowJSX = (
      <TableRow>
        <TableCell
          className="tw-px-2"
          sx={{ width: '32px' }}
        ></TableCell>
        <TableCell className="tw-px-2 tw-italic">
          <Box className="tw-opacity-30">{s_NO_HOURS}</Box>
        </TableCell>
        {objectToArray(hardCodedAllocationTypes).map((allocationType, typeIndex) => (
          <TableCell
            className="tw-px-2 tw-text-center"
            key={typeIndex}
          ></TableCell>
        ))}
        <TableCell className="tw-px-2 tw-italic tw-text-center">
          <Box className="tw-opacity-30">{convertToTimeFormat(0)}</Box>
        </TableCell>
      </TableRow>
    )
  }
  return emptyRowJSX
}

const rJSX_DayOfWeek = (date: Date): JSX.Element => {
  let dayOfWeek = <></>
  if (date.getDay() === 0) {
    dayOfWeek = s_SUN
  } else if (date.getDay() === 1) {
    dayOfWeek = s_MON
  } else if (date.getDay() === 2) {
    dayOfWeek = s_TUE
  } else if (date.getDay() === 3) {
    dayOfWeek = s_WED
  } else if (date.getDay() === 4) {
    dayOfWeek = s_THU
  } else if (date.getDay() === 5) {
    dayOfWeek = s_FRI
  } else if (date.getDay() === 6) {
    dayOfWeek = s_SAT
  }
  return dayOfWeek
}

const returnSX_CombinedPunchEventTableRow = (combinedPunchEvent: TsInterface_UnspecifiedObject): TsInterface_UnspecifiedObject => {
  let sx: TsInterface_UnspecifiedObject = {}
  if (combinedPunchEvent != null && combinedPunchEvent.combined_punch_event_type === 'clocked_in') {
    // Nothing
  } else if (combinedPunchEvent != null && combinedPunchEvent.combined_punch_event_type === 'active') {
    sx = { background: themeVariables.success_main }
  } else if (combinedPunchEvent != null && combinedPunchEvent.combined_punch_event_type === 'error') {
    sx = { background: themeVariables.error_main }
  }
  return sx
}

const rJSX_CombinedPunchCellContent = (combinedPunchEvent: TsInterface_UnspecifiedObject, allocationType: TsInterface_UnspecifiedObject): JSX.Element => {
  let cellJSX = <></>
  if (
    allocationType != null &&
    allocationType.key != null &&
    combinedPunchEvent != null &&
    combinedPunchEvent.clock_in_associated_allocation_type_key != null &&
    allocationType.key === combinedPunchEvent.clock_in_associated_allocation_type_key
  ) {
    let jobCodeJSX = <></>
    if (combinedPunchEvent.clock_in_associated_allocation_type_key === 'field_work') {
      jobCodeJSX = <Box className="tw-opacity-50">{getProp(combinedPunchEvent, 'clock_in_associated_allocation_type_name', <></>)}</Box>
    }
    cellJSX = (
      <Box>
        {jobCodeJSX}
        <Box className="tw-opacity-50">{getProp(combinedPunchEvent, 'clock_in_associated_allocation_subtype_name', <></>)}</Box>
        <Box className="tw-opacity-70">{rJSX_MergedPunchHours(combinedPunchEvent)}</Box>
      </Box>
    )
  }
  return cellJSX
}

const rJSX_IndividualTimeSheetHeader = (
  startDateKey: string,
  endDateKey: string,
  aggregateData: TsInterface_UnspecifiedObject,
  submissionData: TsInterface_UnspecifiedObject,
  setTableViewMode: any,
  viewMode: 'aggregated' | 'raw',
): JSX.Element => {
  let headerJSX = <></>
  let startDate = new Date(parseInt(startDateKey.split('-')[0]), parseInt(startDateKey.split('-')[1]) - 1, parseInt(startDateKey.split('-')[2]), 0, 0)
  let endDate = new Date(parseInt(endDateKey.split('-')[0]), parseInt(endDateKey.split('-')[1]) - 1, parseInt(endDateKey.split('-')[2]), 0, 0)
  // Dynaimc Content
  let bannerColor = themeVariables.transparent
  let bannerSecondaryColor = themeVariables.transparent
  let bannerIcon = (
    <Icon
      className="tw-mr-2"
      icon="calendar"
    />
  )
  let bannerText = <></>
  let errors = getProp(aggregateData, 'errors', {})
  let warnings = getProp(aggregateData, 'warnings', {})
  if (submissionData.timestamp_approved != null) {
    bannerColor = themeVariables.info_dark
    bannerSecondaryColor = themeVariables.info_light
    bannerIcon = (
      <Icon
        className="tw-mr-2"
        icon="badge-check"
      />
    )
    bannerText = (
      <>
        {' '}
        {s_TIME_SHEET_APPROVED_ON} {returnFormattedDate(returnDateFromUnknownDateFormat(submissionData.timestamp_approved), 'D MMM YYYY h:mm a')}
      </>
    )
  } else if (submissionData.timestamp_submitted != null) {
    bannerColor = themeVariables.info_dark
    bannerSecondaryColor = themeVariables.info_light
    bannerIcon = (
      <Icon
        className="tw-mr-2"
        icon="check"
      />
    )
    bannerText = (
      <>
        {' '}
        {s_TIME_SHEET_SUBMITTED_ON} {returnFormattedDate(returnDateFromUnknownDateFormat(submissionData.timestamp_submitted), 'D MMM YYYY h:mm a')}
      </>
    )
  } else if (getProp(errors, 'back_to_back_clock_ins', false) === true) {
    bannerColor = themeVariables.error_dark
    bannerSecondaryColor = themeVariables.error_light
    bannerIcon = (
      <Icon
        className="tw-mr-2"
        icon="triangle-exclamation"
      />
    )
    bannerText = (
      <>
        {s_ERROR}: {s_BACK_TO_BACK_CLOCK_INS}. {s_PLEASE_SUBMIT_CORRECTIONS_BEFORE_SUBMITTING}
      </>
    )
  } else if (getProp(errors, 'back_to_back_clock_outs', false) === true) {
    bannerColor = themeVariables.error_dark
    bannerSecondaryColor = themeVariables.error_light
    bannerIcon = (
      <Icon
        className="tw-mr-2"
        icon="triangle-exclamation"
      />
    )
    bannerText = (
      <>
        {s_ERROR}: {s_BACK_TO_BACK_CLOCK_OUTS}. {s_PLEASE_SUBMIT_CORRECTIONS_BEFORE_SUBMITTING}
      </>
    )
  } else if (getProp(errors, 'missing_first_clock_in', false) === true) {
    bannerColor = themeVariables.error_dark
    bannerSecondaryColor = themeVariables.error_light
    bannerIcon = (
      <Icon
        className="tw-mr-2"
        icon="triangle-exclamation"
      />
    )
    bannerText = (
      <>
        {s_ERROR}: {s_MISSING_FIRST_CLOCK_IN}. {s_PLEASE_SUBMIT_CORRECTIONS_BEFORE_SUBMITTING}
      </>
    )
  } else if (getProp(warnings, 'missing_last_clock_out', false) === true) {
    bannerColor = themeVariables.success_dark
    bannerSecondaryColor = themeVariables.success_main
    bannerIcon = (
      <Icon
        className="tw-mr-2"
        icon="triangle-exclamation"
      />
    )
    bannerText = (
      <>
        {s_WARNING}: {s_MISSING_LAST_CLOCK_OUT}. {s_PLEASE_CLOCK_OUT_BEFORE_SUBMITTING}
      </>
    )
  } else if (getProp(warnings, 'multi_day_punches', false) === true) {
    bannerColor = themeVariables.warning_dark
    bannerSecondaryColor = themeVariables.warning_main
    bannerIcon = (
      <Icon
        className="tw-mr-2"
        icon="triangle-exclamation"
      />
    )
    bannerText = (
      <>
        {s_WARNING}: {s_OVERNIGHT_PUNCHES}. {s_IF_IN_ERROR_REQUEST_CORRECTION_BEFORE_SUBMISSION}
      </>
    )
  } else if (
    getProp(errors, 'missing_first_clock_in', null) === false ||
    getProp(warnings, 'missing_last_clock_out', null) === false ||
    getProp(errors, 'back_to_back_clock_ins', null) === false ||
    getProp(errors, 'back_to_back_clock_outs', null) === false
  ) {
    bannerColor = themeVariables.primary_dark
    bannerSecondaryColor = themeVariables.primary_main
    bannerIcon = (
      <Icon
        className="tw-mr-2"
        icon="calendar"
      />
    )
    bannerText = <>{s_TIME_SHEET_NOT_SUBMITTED_YET}</>
  }
  // JSX
  headerJSX = (
    <TableBody>
      <TableRow>
        <TableCell
          colSpan={objectToArray(hardCodedAllocationTypes).length + 3}
          sx={{ background: bannerColor, padding: '8px', borderBottomColor: bannerColor }}
        >
          <Typography
            variant="subtitle1"
            className="tw-m-0 tw-font-bold"
          >
            {returnFormattedDate(startDate, 'D MMM YYYY')} {s_TO} {returnFormattedDate(endDate, 'D MMM YYYY')}
          </Typography>
          <Typography
            variant="body1"
            className="tw-m-0 tw-font-light"
          >
            {bannerIcon}
            {bannerText}
          </Typography>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          colSpan={2}
          className="tw-px-2"
          sx={{
            background: bannerSecondaryColor,
          }}
        ></TableCell>
        {objectToArray(hardCodedAllocationTypes).map((allocationType, typeIndex) => (
          <TableCell
            className="tw-px-2 tw-text-center"
            key={typeIndex}
            sx={{ background: bannerSecondaryColor }}
          >
            <Typography
              variant="body1"
              className=" tw-opacity-50"
            >
              {allocationType.name_short}
            </Typography>
            <Box className="tw-font-bold">{rJSX_TotalWeekBreakdownHours(aggregateData, allocationType)}</Box>
          </TableCell>
        ))}
        <TableCell
          className="tw-px-2 tw-text-center"
          sx={{ background: bannerSecondaryColor }}
        >
          <Typography
            variant="body1"
            className="tw-opacity-50"
          >
            {s_TOTAL}
          </Typography>
          <Box className="tw-font-bold">{rJSX_TotalWeekHours(aggregateData)}</Box>
        </TableCell>
      </TableRow>
    </TableBody>
  )
  return headerJSX
}

const rJSX_WeekActions = (
  userKey: string,
  startDateKey: string,
  endDateKey: string,
  aggregateData: TsInterface_UnspecifiedObject,
  submissionData: TsInterface_UnspecifiedObject,
  date: Date,
  weekRowEditActions: TsInterface_TableManageActionsObject,
  timeSheetHideActions: boolean,
): JSX.Element => {
  let actionsJSX = <></>
  if (objectToArray(weekRowEditActions).length > 0 && timeSheetHideActions === false) {
    actionsJSX = (
      <TableCellManageProper
        actions={weekRowEditActions}
        // @ts-expect-error - TODO: reason for error
        rowData={{ date: returnDateFromUnknownDateFormat(date) }}
        tableAdditionalData={{
          userKey: userKey,
          startDateKey: startDateKey,
          endDateKey: endDateKey,
          aggregateData: aggregateData,
          submissionData: submissionData,
          timeSheetHideActions: timeSheetHideActions,
        }}
      />
    )
  }
  return actionsJSX
}

const rJSX_EventActions = (
  userKey: string,
  startDateKey: string,
  endDateKey: string,
  aggregateData: TsInterface_UnspecifiedObject,
  submissionData: TsInterface_UnspecifiedObject,
  combinedPunchEvent: TsInterface_UnspecifiedObject,
  date: Date,
  eventRowEditActions: TsInterface_TableManageActionsObject,
  otherRowEditActions: TsInterface_TableManageActionsObject,
  timeSheetHideActions: boolean,
): JSX.Element => {
  let actionsJSX = <></>
  let actions: TsInterface_UnspecifiedObject = {}
  if (
    combinedPunchEvent != null &&
    combinedPunchEvent['clock_in_associated_allocation_type_key'] === 'non_working_time' &&
    otherRowEditActions != null &&
    otherRowEditActions['edit_non_working_time'] != null
  ) {
    actions['edit_non_working_time'] = otherRowEditActions['edit_non_working_time']
  }
  if (
    combinedPunchEvent != null &&
    combinedPunchEvent['clock_in_associated_allocation_type_key'] === 'non_working_time' &&
    otherRowEditActions != null &&
    otherRowEditActions['delete_non_working_time'] != null
  ) {
    actions['delete_non_working_time'] = otherRowEditActions['delete_non_working_time']
  }
  for (let loopActionKey in eventRowEditActions) {
    actions[loopActionKey] = eventRowEditActions[loopActionKey]
  }
  if (objectToArray(actions).length > 0 && timeSheetHideActions === false) {
    actionsJSX = (
      <TableCellManageProper
        actions={actions}
        // @ts-expect-error - TODO: reason for error
        rowData={{ date: returnDateFromUnknownDateFormat(date), combinedPunchEvent: combinedPunchEvent }}
        tableAdditionalData={{
          userKey: userKey,
          startDateKey: startDateKey,
          endDateKey: endDateKey,
          aggregateData: aggregateData,
          submissionData: submissionData,
          timeSheetHideActions: timeSheetHideActions,
        }}
      />
    )
  }
  return actionsJSX
}

const rJSX_CorrectionNotes = (
  date: Date,
  dateKey: string,
  userKey: string,
  startDateKey: string,
  endDateKey: string,
  aggregateData: TsInterface_UnspecifiedObject,
  submissionData: TsInterface_UnspecifiedObject,
  weekRowEditActions: TsInterface_TableManageActionsObject,
  otherRowEditActions: TsInterface_TableManageActionsObject,
  timeSheetHideActions: boolean,
): JSX.Element => {
  let managedNotesJSX = <></>
  let correctionNotesJSX = <></>
  if (
    dateKey != null &&
    submissionData != null &&
    submissionData.requested_corrections != null &&
    submissionData.requested_corrections[dateKey] != null &&
    submissionData.requested_corrections[dateKey]['correction_notes'] != null
  ) {
    let manageCorrectionRequestsIcon = <></>
    let actions: TsInterface_UnspecifiedObject = {}
    if (otherRowEditActions != null && otherRowEditActions['edit_request_correction'] != null) {
      actions['edit_request_correction'] = otherRowEditActions['edit_request_correction']
    }
    if (otherRowEditActions != null && otherRowEditActions['delete_correction_request'] != null) {
      actions['delete_correction_request'] = otherRowEditActions['delete_correction_request']
    }
    if (
      otherRowEditActions != null &&
      otherRowEditActions['mark_correction_as_managed'] != null &&
      submissionData.requested_corrections[dateKey]['timestamp_managed'] == null
    ) {
      actions['mark_correction_as_managed'] = otherRowEditActions['mark_correction_as_managed']
    }
    if (
      otherRowEditActions != null &&
      otherRowEditActions['mark_correction_as_unmanaged'] != null &&
      submissionData.requested_corrections[dateKey]['timestamp_managed'] != null
    ) {
      actions['mark_correction_as_unmanaged'] = otherRowEditActions['mark_correction_as_unmanaged']
    }
    if (submissionData.requested_corrections[dateKey]['timestamp_managed'] != null) {
      managedNotesJSX = (
        <Box
          sx={{ color: themeVariables.success_dark }}
          className="tw-font-bold"
        >
          <Icon
            icon="badge-check"
            className="tw-mr-1"
          />
          {getProp(submissionData.requested_corrections[dateKey], 'timestamp_managed_by_name', <></>)} (
          {returnFormattedDate(submissionData.requested_corrections[dateKey]['timestamp_managed'], 'D MMM YY h:mm a')})
        </Box>
      )
    }
    if (objectToArray(actions).length > 0 && timeSheetHideActions === false) {
      manageCorrectionRequestsIcon = (
        <TableCellManageProper
          actions={actions}
          // @ts-expect-error - TODO: reason for error
          rowData={{ date: date }}
          tableAdditionalData={{
            userKey: userKey,
            startDateKey: startDateKey,
            endDateKey: endDateKey,
            aggregateData: aggregateData,
            submissionData: submissionData,
            timeSheetHideActions: timeSheetHideActions,
          }}
        />
      )
    }
    // JSX
    correctionNotesJSX = (
      <TableRow>
        <TableCell
          className="tw-px-2 tw-text-center"
          sx={{ width: '32px', background: themeVariables.warning_dark }}
        >
          <Box
            sx={{ fontSize: '20px' }}
            className="tw-inline-block"
          >
            <Tooltip
              title={s_CORRECTION_NOTES}
              placement="right"
            >
              <Box>
                <Icon icon="message-exclamation" />
              </Box>
            </Tooltip>
          </Box>
          {manageCorrectionRequestsIcon}
        </TableCell>
        <TableCell
          colSpan={objectToArray(hardCodedAllocationTypes).length + 2}
          sx={{ background: themeVariables.warning_dark }}
        >
          <Box>
            <Box className="tw-font-bold tw-inline-block tw-mr-1">{s_CORRECTION_NOTES}:</Box>
            <Box className="tw-font-normal tw-inline-block tw-opacity-70">{submissionData.requested_corrections[dateKey]['correction_notes']}</Box>
          </Box>
          <Box>{managedNotesJSX}</Box>
        </TableCell>
      </TableRow>
    )
  }
  return correctionNotesJSX
}

const rJSX_ManageRequestCorrectionsIcon = (
  date: Date,
  userKey: string,
  startDateKey: string,
  endDateKey: string,
  aggregateData: TsInterface_UnspecifiedObject,
  submissionData: TsInterface_UnspecifiedObject,
  otherRowEditActions: TsInterface_TableManageActionsObject,
  timeSheetHideActions: boolean,
): JSX.Element => {
  let manageCorrectionRequestsIcon = <></>
  let actions: TsInterface_UnspecifiedObject = {}
  let formattedDate = returnDateFromUnknownDateFormat(date)
  let timezoneCorrectedDate = new Date(formattedDate.getTime() + formattedDate.getTimezoneOffset() * 60 * 1000)
  if (otherRowEditActions != null && otherRowEditActions['edit_request_correction'] != null) {
    actions['edit_request_correction'] = otherRowEditActions['edit_request_correction']
  }
  if (otherRowEditActions != null && otherRowEditActions['delete_correction_request'] != null) {
    actions['delete_correction_request'] = otherRowEditActions['delete_correction_request']
  }
  if (objectToArray(actions).length > 0 && timeSheetHideActions === false) {
    manageCorrectionRequestsIcon = (
      <TableCellManageProper
        actions={actions}
        // @ts-expect-error - TODO: reason for error
        rowData={{ date: timezoneCorrectedDate }}
        tableAdditionalData={{
          userKey: userKey,
          startDateKey: startDateKey,
          endDateKey: endDateKey,
          aggregateData: aggregateData,
          submissionData: submissionData,
        }}
      />
    )
  }
  return manageCorrectionRequestsIcon
}

const rJSX_RequestedCorrectionsManagement = (
  submissionData: TsInterface_UnspecifiedObject,
  otherRowEditActions: TsInterface_TableManageActionsObject,
  date: string,
): JSX.Element => {
  let managedNotesJSX = <></>
  let formattedDate = returnDateFromUnknownDateFormat(date)
  let timezoneCorrectedDate = new Date(formattedDate.getTime() + formattedDate.getTimezoneOffset() * 60 * 1000)
  let dateKey = returnFormattedDateKey(timezoneCorrectedDate)
  if (
    dateKey != null &&
    submissionData != null &&
    submissionData.requested_corrections != null &&
    submissionData.requested_corrections[dateKey] != null &&
    submissionData.requested_corrections[dateKey]['timestamp_managed'] != null
  ) {
    managedNotesJSX = (
      <Box
        sx={{ color: themeVariables.success_dark }}
        className="tw-font-bold tw-ml-6"
      >
        <Icon
          icon="badge-check"
          className="tw-mr-1"
        />
        {getProp(submissionData.requested_corrections[dateKey], 'timestamp_managed_by_name', <></>)} (
        {returnFormattedDate(submissionData.requested_corrections[dateKey]['timestamp_managed'], 'D MMM YY h:mm a')})
      </Box>
    )
  }
  return managedNotesJSX
}

const rJSX_RequestedCorrections = (
  userKey: string,
  startDateKey: string,
  endDateKey: string,
  aggregateData: TsInterface_UnspecifiedObject,
  submissionData: TsInterface_UnspecifiedObject,
  otherRowEditActions: TsInterface_TableManageActionsObject,
  timeSheetHideActions: boolean,
): JSX.Element => {
  let requestedCorrectionsJSX = <></>
  if (submissionData != null && submissionData.requested_corrections != null && objectToArray(submissionData.requested_corrections).length > 0) {
    requestedCorrectionsJSX = (
      <Card
        className="tw-mb-2 tw-p-2"
        sx={{ border: '1px solid rgba(255,255,255,0.25)', background: themeVariables.warning_dark }}
      >
        <Box sx={{ fontSize: '20px' }}>
          <Box className="tw-mr-2 tw-inline-block">
            <Icon icon="message-exclamation" />
          </Box>
          {s_REQUESTED_CORRECTIONS}
        </Box>
        <Divider className="tw-mb-2" />
        {objectToArray(submissionData.requested_corrections)
          .sort()
          .map((correction: TsInterface_UnspecifiedObject, index: number) => (
            <Box key={index}>
              <Box className="tw-font-bold tw-mb-1 tw-mr-1 tw-inline-block">{correction.date}:</Box>
              <Box className="tw-font-light tw-opaciity-50 tw-mb-1 tw-inline-block">{correction.correction_notes}</Box>
              <Box className="tw-inline-block tw-ml-2">
                {rJSX_ManageRequestCorrectionsIcon(
                  correction.date,
                  userKey,
                  startDateKey,
                  endDateKey,
                  aggregateData,
                  submissionData,
                  otherRowEditActions,
                  timeSheetHideActions,
                )}
              </Box>
              <Box>{rJSX_RequestedCorrectionsManagement(submissionData, otherRowEditActions, correction.date)}</Box>
            </Box>
          ))}
      </Card>
    )
  }
  return requestedCorrectionsJSX
}

const rJSX_RawPunchTable = (
  userType: 'user' | 'manager',
  userKey: string,
  startDateKey: string,
  endDateKey: string,
  aggregateData: TsInterface_UnspecifiedObject,
  submissionData: TsInterface_UnspecifiedObject,
  weekRowEditActions: TsInterface_TableManageActionsObject,
  eventRowEditActions: TsInterface_TableManageActionsObject,
  otherRowEditActions: TsInterface_TableManageActionsObject,
  rawPunchData: TsInterface_UnspecifiedObject,
  rawRowEditActions: TsInterface_TableManageActionsObject,
  viewMode: 'aggregated' | 'raw',
  setTableViewMode: any,
  timeSheetHideActions: boolean,
  createNewPunch: any,
): JSX.Element => {
  // Actions
  if (rawRowEditActions != null && objectToArray(rawRowEditActions).length > 0) {
    tableColumns_RawPunchData.manage = TableCellManage(rawRowEditActions)
  }
  // Create Button
  let createButtonJSX = <></>
  if (createNewPunch != null) {
    createButtonJSX = (
      <Box>
        <Button
          variant="contained"
          color="success"
          className="tw-mb-2"
          onClick={() => {
            if (createNewPunch != null) {
              createNewPunch(userKey)
            }
          }}
        >
          <Icon
            icon="plus-circle"
            className="tw-mr-2"
          />
          {s_CREATE_PUNCH}
        </Button>
      </Box>
    )
  }
  // JSX
  let timesheetJSX = (
    <Box>
      <Card
        className="tw-mb-2"
        sx={{ border: '1px solid rgba(255,255,255,0.25)' }}
      >
        <TableContainer>
          <Table
            size="small"
            sx={{ borderCollapse: 'inherit' }}
          >
            {rJSX_IndividualTimeSheetHeader(startDateKey, endDateKey, aggregateData, submissionData, setTableViewMode, viewMode)}
          </Table>
        </TableContainer>
      </Card>
      {createButtonJSX}
      <Card
        className="tw-mb-2"
        sx={{ border: '1px solid rgba(255,255,255,0.25)' }}
      >
        <TableBasic
          tableAdditionalData={{}}
          tableColumns={tableColumns_RawPunchData}
          tableData={objectToArray(rawPunchData).sort(dynamicSort('timestamp', 'asc'))}
          tableSettings={tableSettings_RawPunchData}
        />
      </Card>
      {rJSX_RequestedCorrections(userKey, startDateKey, endDateKey, aggregateData, submissionData, otherRowEditActions, timeSheetHideActions)}
    </Box>
  )
  return timesheetJSX
}

const rJSX_AggregatedIndividualTimeSheet = (
  userType: 'user' | 'manager',
  userKey: string,
  startDateKey: string,
  endDateKey: string,
  aggregateData: TsInterface_UnspecifiedObject,
  submissionData: TsInterface_UnspecifiedObject,
  weekRowEditActions: TsInterface_TableManageActionsObject,
  eventRowEditActions: TsInterface_TableManageActionsObject,
  rawPunchData: TsInterface_UnspecifiedObject,
  rawRowEditActions: TsInterface_TableManageActionsObject,
  otherRowEditActions: TsInterface_TableManageActionsObject,
  viewMode: 'aggregated' | 'raw',
  setTableViewMode: any,
  timeSheetHideActions: boolean,
): JSX.Element => {
  let dateKeysArray = returnDateRangeKeys(startDateKey, endDateKey)
  let eventData = aggregateData.combined_punch_events
  let timesheetJSX = (
    <Box>
      <Card
        className="tw-mb-2"
        sx={{ border: '1px solid rgba(255,255,255,0.25)' }}
      >
        <TableContainer>
          <Table
            size="small"
            sx={{ borderCollapse: 'inherit' }}
          >
            {rJSX_IndividualTimeSheetHeader(startDateKey, endDateKey, aggregateData, submissionData, setTableViewMode, viewMode)}
            {dateKeysArray.map((date, index) => (
              <TableBody key={index}>
                <TableRow sx={{ background: themeVariables.background_json }}>
                  <TableCell
                    className="tw-px-2"
                    sx={{ width: '32px' }}
                  >
                    {rJSX_WeekActions(userKey, startDateKey, endDateKey, aggregateData, submissionData, date, weekRowEditActions, timeSheetHideActions)}
                  </TableCell>
                  <TableCell className="tw-px-2 tw-font-bold">
                    {rJSX_DayOfWeek(returnDateFromUnknownDateFormat(date))} {returnFormattedDate(returnDateFromUnknownDateFormat(date), 'M/D')}
                  </TableCell>
                  {objectToArray(hardCodedAllocationTypes).map((allocationType, typeIndex) => (
                    <TableCell
                      className="tw-px-2 tw-text-center"
                      key={typeIndex}
                    >
                      <Typography
                        variant="body1"
                        className=" tw-opacity-50"
                      >
                        {allocationType.name_short}
                      </Typography>
                      <Box className="tw-font-bold">{rJSX_TotalDayBreakdownHours(returnFormattedDateKey(date), aggregateData, allocationType)}</Box>
                    </TableCell>
                  ))}
                  <TableCell className="tw-px-2 tw-text-center">
                    <Typography
                      variant="body1"
                      className="tw-opacity-50"
                    >
                      {s_TOTAL}
                    </Typography>
                    <Box className="tw-font-bold">{rJSX_TotalDayHours(returnFormattedDateKey(date), aggregateData)}</Box>
                  </TableCell>
                </TableRow>
                {rJSX_CorrectionNotes(
                  date,
                  returnFormattedDateKey(date),
                  userKey,
                  startDateKey,
                  endDateKey,
                  aggregateData,
                  submissionData,
                  weekRowEditActions,
                  otherRowEditActions,
                  timeSheetHideActions,
                )}
                {rJSX_TimePunchEmptyRow(returnFormattedDateKey(date), eventData)}
                {returnTimePunchesFilteredToSpecificDate(returnFormattedDateKey(date), eventData).map((combinedPunchEvent, index2) => (
                  <TableRow
                    key={index2}
                    sx={returnSX_CombinedPunchEventTableRow(combinedPunchEvent)}
                  >
                    <TableCell
                      className="tw-px-2 tw-text-center"
                      sx={{ width: '32px', padding: '8px', fontSize: '20px' }}
                    >
                      <Box>
                        {rJSX_TimePunchIcon(combinedPunchEvent)}
                        {rJSX_EventActions(
                          userKey,
                          startDateKey,
                          endDateKey,
                          aggregateData,
                          submissionData,
                          combinedPunchEvent,
                          date,
                          eventRowEditActions,
                          otherRowEditActions,
                          timeSheetHideActions,
                        )}
                      </Box>
                    </TableCell>
                    <TableCell className="tw-px-2">
                      <Box className="tw-opacity-70">
                        {returnFormattedDate(combinedPunchEvent['clock_in_timestamp'], 'h:mm a')} to{' '}
                        {returnFormattedDate(combinedPunchEvent['clock_out_timestamp'], 'h:mm a')}
                        <Box className="tw-opacity-40 tw-italic">{rJSX_ErrorOrWarningExplanation(combinedPunchEvent)}</Box>
                      </Box>
                    </TableCell>
                    {objectToArray(hardCodedAllocationTypes).map((allocationType, typeIndex) => (
                      <TableCell
                        className="tw-px-2 tw-text-center"
                        key={typeIndex}
                      >
                        {rJSX_CombinedPunchCellContent(combinedPunchEvent, allocationType)}
                      </TableCell>
                    ))}
                    <TableCell className="tw-px-2 tw-text-center">
                      <Box className="tw-opacity-70">{rJSX_MergedPunchHours(combinedPunchEvent)}</Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            ))}
          </Table>
        </TableContainer>
      </Card>
    </Box>
  )
  return timesheetJSX
}

export const rJSX_IndividualTimeSheet = (
  userType: 'user' | 'manager',
  userKey: string,
  startDateKey: string,
  endDateKey: string,
  aggregateData: TsInterface_UnspecifiedObject,
  submissionData: TsInterface_UnspecifiedObject,
  weekRowEditActions: TsInterface_TableManageActionsObject,
  eventRowEditActions: TsInterface_TableManageActionsObject,
  otherRowEditActions: TsInterface_TableManageActionsObject,
  rawPunchData: TsInterface_UnspecifiedObject,
  rawRowEditActions: TsInterface_TableManageActionsObject,
  viewMode: 'aggregated' | 'raw',
  setTableViewMode: any,
  timeSheetHideActions: boolean,
  createNewPunch: any,
): JSX.Element => {
  let viewModeJSX = <></>
  if (viewMode === 'aggregated') {
    viewModeJSX = rJSX_AggregatedIndividualTimeSheet(
      userType,
      userKey,
      startDateKey,
      endDateKey,
      aggregateData,
      submissionData,
      weekRowEditActions,
      eventRowEditActions,
      rawPunchData,
      rawRowEditActions,
      otherRowEditActions,
      viewMode,
      setTableViewMode,
      timeSheetHideActions,
    )
  } else if (viewMode === 'raw') {
    viewModeJSX = rJSX_RawPunchTable(
      userType,
      userKey,
      startDateKey,
      endDateKey,
      aggregateData,
      submissionData,
      weekRowEditActions,
      eventRowEditActions,
      otherRowEditActions,
      rawPunchData,
      rawRowEditActions,
      viewMode,
      setTableViewMode,
      timeSheetHideActions,
      createNewPunch,
    )
  }
  return viewModeJSX
}

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Chip, Typography } from '@mui/material'
import Dropzone from 'react-dropzone'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { DatabaseRef_MasterSKU_Document } from 'rfbp_aux/services/database_endpoints/materials/master_sku_items'
import { StorageRef_MaterialSkuPhotos } from 'rfbp_aux/services/storage_endpoints/material_skus'
import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { DatabaseSetMergeDocument, StorageUploadFile } from 'rfbp_core/services/database_management'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Exports
///////////////////////////////

export const formInputs_SkuImageUpload: TsInterface_FormInputs = {
  image_upload: {
    data_type: 'string',
    key: 'image_upload',
    input_type: 'custom_form_input_jsx',
    label: <></>,
    required: true,
    renderCustomFormInput: (formInput, formInputs, formData, formInputChange, formSettings, formAdditionalData, formHooks) => {
      // Helper functions
      const readAsDataURL = (file: any) => {
        return new Promise((resolve, reject) => {
          const fr = new FileReader()
          fr.onerror = reject
          fr.onload = () => {
            resolve(fr.result)
          }
          fr.readAsDataURL(file)
        })
      }
      const fileOnSelect2 = (
        fileArray: TsInterface_UnspecifiedObject,
        // additionalFileUploadParams: TsInterface_UnspecifiedObject
      ): TsType_UnknownPromise => {
        return new Promise((resolve, reject) => {
          if (fileArray != null && fileArray.length > 0) {
            let promiseArray: TsType_UnknownPromise[] = []
            let files = fileArray
            let readFiles: TsInterface_UnspecifiedObject = {}
            let file = files[0]
            if (file != null && typeof file === 'object') {
              promiseArray.push(
                readAsDataURL(file)
                  .then((res_RADU) => {
                    readFiles[0] = {
                      file_name: file.name,
                      file: file,
                      data_url: res_RADU,
                    }
                  })
                  .catch((rej_RADU) => {
                    // Nothing
                  }),
              )
            }
            Promise.all(promiseArray).finally(() => {
              let clientKey = getProp(formAdditionalData, 'clientKey', null)
              let skuKey = getProp(formAdditionalData, 'skuKey', null)
              if (clientKey != null && skuKey != null) {
                if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                  setTimeout(() => {
                    // @ts-expect-error - TODO: reason for error
                    formAdditionalData.us_setShowImage(false)
                    formHooks.ur_forceRerender()
                  }, 1)
                }
                StorageUploadFile(StorageRef_MaterialSkuPhotos(clientKey, skuKey, readFiles[0].file_name), readFiles[0].file, {
                  clientKey: clientKey,
                  skuKey: skuKey,
                })
                  .then((res_SUF: any) => {
                    // files[ fileIndex ]["storage_url"] = getProp(res_SUF, "url", null)
                    let updateObject = {
                      photo_url: getProp(res_SUF, 'url', null),
                    }
                    DatabaseSetMergeDocument(DatabaseRef_MasterSKU_Document(clientKey, skuKey), updateObject)
                      .then((res_DSMD) => {
                        if (formAdditionalData.skuData == null) {
                          formAdditionalData.skuData = {}
                        }
                        // @ts-expect-error - TODO: reason for error
                        formAdditionalData.skuData['photo_url'] = getProp(res_SUF, 'url', null)
                        resolve(res_DSMD)
                        if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                          setTimeout(() => {
                            // @ts-expect-error - TODO: reason for error
                            formAdditionalData.us_setShowImage(true)
                            formHooks.ur_forceRerender()
                          }, 1)
                        }
                        formHooks.ur_forceRerender()
                      })
                      .catch((rej_DSMD) => {
                        if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                          setTimeout(() => {
                            // @ts-expect-error - TODO: reason for error
                            formAdditionalData.us_setShowImage(true)
                            formHooks.ur_forceRerender()
                          }, 1)
                        }
                        reject(rej_DSMD)
                      })
                  })
                  .catch((rej_SUF) => {
                    if (formAdditionalData != null && formAdditionalData.us_setShowImage != null) {
                      setTimeout(() => {
                        // @ts-expect-error - TODO: reason for error
                        formAdditionalData.us_setShowImage(true)
                        formHooks.ur_forceRerender()
                      }, 1)
                    }
                    console.error(rej_SUF)
                    reject(rej_SUF)
                  })
              } else {
                reject({
                  success: false,
                  error: {
                    message: rLIB('Failed to upload image'),
                    details: rLIB('Missing Required Keys'),
                    code: 'ER-D-SV-FOS-01',
                  },
                })
              }
            })
          } else {
            reject({
              success: false,
              error: {
                message: rLIB('Failed to upload image'),
                details: rLIB('Invalid document selection'),
                code: 'ER-D-SV-FOS-02',
              },
            })
          }
        })
      }
      // JSX
      let photoUploadJSX = <></>
      if (
        formAdditionalData != null &&
        formAdditionalData.skuData != null &&
        // @ts-expect-error - TODO: reason for error
        formAdditionalData.skuData['photo_url'] != null
      ) {
        let reuploadButtonJSX = (
          <Dropzone
            onDrop={(acceptedFiles: TsInterface_UnspecifiedObject[]) => {
              fileOnSelect2(acceptedFiles)
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <Box
                className="tw-p-4 tw-rounded-lg"
                sx={{ border: '1px solid ' + themeVariables.gray_600 }}
              >
                <Box {...getRootProps()}>
                  <input
                    accept="image/*"
                    {...getInputProps()}
                  />
                  <Typography
                    variant="h6"
                    className="tw-mb-2"
                  >
                    <Icon
                      icon="cloud-arrow-up"
                      className="tw-mr-2"
                    ></Icon>
                    {rLIB('Drag and Drop or click to upload')}
                  </Typography>
                </Box>
              </Box>
            )}
          </Dropzone>
        )
        // <FileUploadButton
        // 	multiple={ false }
        // 	accept='image/*'
        // 	onChange={ fileOnSelect }
        // 	button={{
        // 		text: s_UPLOAD_A_REPLACEMENT_PHOTO,
        // 		icon: <Icon icon="cloud-arrow-up" className="tw-mr-2"></Icon>,
        // 		color: "primary",
        // 		className: "",
        // 		variant: "outlined",
        // 		disabled: false
        // 	}}
        // 	additionalFileUploadParams={{}}
        // />
        photoUploadJSX = (
          <Box>
            <Box className="tw-text-center tw-pl-8">
              <Box
                className="tw-m-auto"
                component="img"
                sx={{ width: '100%', maxWidth: 1000 }}
                // @ts-expect-error - TODO: reason for error
                src={formAdditionalData.skuData['photo_url']}
              />
            </Box>
            <Box className="tw-text-center tw-mt-4">{reuploadButtonJSX}</Box>
          </Box>
        )
      } else {
        photoUploadJSX = (
          <Box className="tw-text-center">
            <Dropzone
              onDrop={(acceptedFiles: TsInterface_UnspecifiedObject[]) => {
                fileOnSelect2(acceptedFiles)
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <Box
                  className="tw-p-4 tw-rounded-lg"
                  sx={{ border: '1px solid ' + themeVariables.gray_600 }}
                >
                  <Box {...getRootProps()}>
                    <input
                      accept="image/*"
                      {...getInputProps()}
                    />
                    <Typography
                      variant="h6"
                      className="tw-mb-2"
                    >
                      <Icon
                        icon="cloud-arrow-up"
                        className="tw-mr-2"
                      ></Icon>
                      {rLIB('Drag and Drop or click to upload')}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Dropzone>
            {/* <FileUploadButton
							multiple={ false }
							accept='image/*'
							onChange={ fileOnSelect }
							button={{
								text: s_UPLOAD_PHOTO,
								icon: <Icon icon="cloud-arrow-up" className="tw-mr-2"></Icon>,
								color: "primary",
								className: "",
								variant: "outlined",
								disabled: false
							}}
							additionalFileUploadParams={{}}
						/> */}
          </Box>
        )
      }
      let inputJSX = (
        <Box>
          <Box className="tw-mb-2">
            <Chip
              label={getProp(formAdditionalData.skuData, 'unique_identifier', '')}
              className="tw-mr-2 tw-mb-1"
            />
            {getProp(formAdditionalData.skuData, 'name', '')}
          </Box>
          {photoUploadJSX}
        </Box>
      )
      return inputJSX
    },
  },
}

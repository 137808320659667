import { TsInterface_EmailTemplateObject } from 'rfbp_core/services/emails'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_UnspecifiedObject } from 'rfbp_core/typescript/global_types'

export const convertGmDataToEmailTemplateFormat = (gmDataTables: TsInterface_UnspecifiedObject[]): TsInterface_EmailTemplateObject => {
  // Initialize Email Template Object
  let emailTemplateObject: TsInterface_EmailTemplateObject = {
    DIV: {
      element_type: 'div',
      style: {
        'background': '#F1F1F1',
        'font-size': '20px',
        'width': '100%',
        'min-height': '100px',
        'padding': '16px',
        'text-align': 'left',
      },
      contents: {},
    },
  }
  // Loop through gmDataTables and generate tables
  for (let loopTableIndex in gmDataTables) {
    let loopTable = gmDataTables[loopTableIndex]
    let loopTableKey = 'TABLE_' + loopTableIndex
    // Create Empty Table
    // @ts-expect-error
    emailTemplateObject['DIV']['contents'][loopTableKey] = {
      element_type: 'div',
      style: {
        'background': '#FFFFFF',
        'color': 'rgba(0, 0, 0, 0.87)',
        'border-radius': '5px',
        'box-shadow': 'rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px',
        'margin-bottom': '16px',
      },
      contents: {
        TABLE: {
          element_type: 'table',
          table_type: 'static_data',
          looped_data_object_key: 'objectsToLoopThrough',
          table_style: {
            'width': '100%',
            'color': '#000',
            'font-size': '15px',
          },
          table_rows: [],
        },
      },
    }
    // Loop through and add Panel Data
    if (loopTable != null && loopTable['summary_rows'] != null && loopTable['data_rows'] != null) {
      for (let loopRowIndex in loopTable['summary_rows']) {
        let loopRow = loopTable['summary_rows'][loopRowIndex]
        // Add Cells to Table
        let rowContents: TsInterface_UnspecifiedObject[] = []
        for (let loopCellIndex in loopRow) {
          let loopCell = loopRow[loopCellIndex]
          let cellText = ''
          let cellStyle: TsInterface_UnspecifiedObject = {}
          for (let propKey in getProp(loopCell, 'conditionalFormatting', [])) {
            // Convert camelCase to kebab-case
            const hyphenatedProp = propKey.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
            cellStyle[hyphenatedProp] = loopCell['conditionalFormatting'][propKey]
          }
          if (
            loopCell != null &&
            loopCell['cellValue'] != null &&
            loopCell['cellValue']['props'] != null &&
            loopCell['cellValue']['props']['children'] != null
          ) {
            cellText = loopCell['cellValue']['props']['children']
          } else if (loopCell != null && loopCell['cellValue'] != null) {
            cellText = loopCell['cellValue']
          }
          // Add Cell to Row
          rowContents.push({
            td_text: cellText,
            td_style: cellStyle,
          })
        }
        // Add Row to Table
        // @ts-expect-error
        emailTemplateObject['DIV']['contents'][loopTableKey]['contents']['TABLE']['table_rows'].push(rowContents)
      }
      for (let loopRowIndex in loopTable['data_rows']) {
        let loopRow = loopTable['data_rows'][loopRowIndex]
        // Add Cells to Table
        let rowContents: TsInterface_UnspecifiedObject[] = []
        for (let loopCellIndex in loopRow) {
          let loopCell = loopRow[loopCellIndex]
          let cellText = ''
          let cellStyle: TsInterface_UnspecifiedObject = {}
          for (let propKey in getProp(loopCell, 'conditionalFormatting', [])) {
            // Convert camelCase to kebab-case
            const hyphenatedProp = propKey.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
            cellStyle[hyphenatedProp] = loopCell['conditionalFormatting'][propKey]
          }
          if (
            loopCell != null &&
            loopCell['cellValue'] != null &&
            loopCell['cellValue']['props'] != null &&
            loopCell['cellValue']['props']['children'] != null
          ) {
            cellText = loopCell['cellValue']['props']['children']
          } else if (loopCell != null && loopCell['cellValue'] != null) {
            cellText = loopCell['cellValue']
          }
          // Add Cell to Row
          rowContents.push({
            td_text: cellText,
            td_style: cellStyle,
          })
        }
        // Add Row to Table
        // @ts-expect-error
        emailTemplateObject['DIV']['contents'][loopTableKey]['contents']['TABLE']['table_rows'].push(rowContents)
      }
    }
  }
  // Loop through and add Crew Hours

  return emailTemplateObject
}

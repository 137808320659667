///////////////////////////////

///////////////////////////////
// Imports
///////////////////////////////

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Exports
///////////////////////////////

export const processSafetyReviewData = (rawSafetyData: any, mondaysInDateRange: string[]): { summary_rows: any[]; data_rows: any[] } => {
  const today = new Date()
  today.setHours(0, 0, 0, 0) // Normalize today's date

  const formattedData: any = {
    summary_rows: [],
    data_rows: [],
  }

  const dateHeaders: any[] = [{ cellValue: 'Safety Reviews', conditionalFormatting: { fontWeight: 'bold', width: '200px' } }]
  const totals: any = {}
  const teamData: any = {}
  const redColumns: any = {}

  // Initialize totals and date headers
  mondaysInDateRange.forEach((monday: string) => {
    const date = new Date(monday)
    date.setDate(date.getDate() + 1) // Ensure it's showing Monday
    const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
    const isFuture = date > today

    totals[formattedDate] = { passed_reviews: '-', failed_reviews: '-', task_count: '-' }
    redColumns[formattedDate] = false

    dateHeaders.push({
      cellValue: formattedDate,
      conditionalFormatting: { fontWeight: 'bold', opacity: isFuture ? 0.5 : 1, width: '120px' },
    })
  })

  // Process each week's data
  Object.entries(rawSafetyData).forEach(([dateStr, weekData]: [string, any]) => {
    const date = new Date(`${dateStr}T12:00:00`)
    const formattedDate = date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })

    Object.entries(weekData.data || {}).forEach(([teamId, details]: [string, any]) => {
      if (details && 'passed_reviews' in details && 'failed_reviews' in details && 'name' in details && 'task_count' in details) {
        const safeDetails: any = details

        if (!teamData[safeDetails.name]) {
          teamData[safeDetails.name] = {}
          Object.keys(totals).forEach((date: any) => {
            teamData[safeDetails.name][date] = { passed_reviews: '-', failed_reviews: '-', task_count: '-' }
          })
        }

        teamData[safeDetails.name][formattedDate] = {
          passed_reviews: safeDetails.passed_reviews,
          failed_reviews: safeDetails.failed_reviews,
          task_count: safeDetails.task_count,
        }

        if (totals[formattedDate].passed_reviews === '-') {
          totals[formattedDate].passed_reviews = 0
        }
        totals[formattedDate].passed_reviews += safeDetails.passed_reviews

        if (totals[formattedDate].failed_reviews === '-') {
          totals[formattedDate].failed_reviews = 0
        }
        totals[formattedDate].failed_reviews += safeDetails.failed_reviews

        if (totals[formattedDate].task_count === '-') {
          totals[formattedDate].task_count = 0
        }
        totals[formattedDate].task_count += safeDetails.task_count
      }
    })
  })

  // Construct the data rows for each team
  Object.keys(teamData)
    .sort()
    .forEach((teamName: any) => {
      const rowData: any[] = [
        {
          cellValue: teamName,
          conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
        },
      ]

      Object.keys(totals).forEach((date: any) => {
        const reviews: any = teamData[teamName][date]
        const isMetricDefined = reviews.passed_reviews !== '-' || reviews.failed_reviews !== '-'
        const isFuture = new Date(date) > today
        const backgroundColor =
          reviews.failed_reviews > 0 || (reviews.task_count > 0 && reviews.passed_reviews + reviews.failed_reviews === 0)
            ? '#c82424' // Red
            : '#28a56c' // Green

        if (backgroundColor === '#c82424') {
          redColumns[date] = true
        }

        rowData.push({
          cellValue:
            reviews.task_count === '-'
              ? '-'
              : reviews.task_count === 0
                ? 'No tasks for the week'
                : reviews.passed_reviews === 0 && reviews.failed_reviews === 0
                  ? 'Safety review not completed'
                  : isMetricDefined
                    ? `P: ${reviews.passed_reviews}${reviews.failed_reviews > 0 ? ` F: ${reviews.failed_reviews}` : ''}`
                    : '-',
          conditionalFormatting: {
            backgroundColor: reviews.task_count === '-' || reviews.task_count === 0 || isFuture || reviews.passed_reviews === '-' ? 'inherit' : backgroundColor,
            opacity: isFuture ? 0.5 : 1,
            cursor: 'default',
            width: '120px', // Ensure consistent width
          },
        })
      })
      formattedData.data_rows.push(rowData)
    })

  // Append the total metrics summary row
  formattedData.summary_rows.push(dateHeaders)
  const totalMetricsRow: any[] = [
    {
      cellValue: 'Total',
      conditionalFormatting: { fontWeight: 'bold', backgroundColor: '', opacity: 1, width: '200px' },
    },
  ]

  Object.keys(totals).forEach((date: any) => {
    const totalValue: any = totals[date]
    const isFuture = new Date(date) > today
    const backgroundColor = redColumns[date] ? '#c82424' : '#28a56c'
    totalMetricsRow.push({
      cellValue:
        totalValue.task_count === '-'
          ? '-'
          : totalValue.task_count === 0
            ? 'No tasks for the week'
            : `P: ${totalValue.passed_reviews}${totalValue.failed_reviews > 0 ? ` F: ${totalValue.failed_reviews}` : ''}`,
      conditionalFormatting: {
        fontWeight: 'normal',
        backgroundColor:
          totalValue.task_count === '-' || totalValue.task_count === 0 || isFuture || totalValue.passed_reviews === '-' ? 'inherit' : backgroundColor,
        opacity: isFuture ? 0.5 : 1,
        width: '120px',
      },
    })
  })
  formattedData.summary_rows.push(totalMetricsRow)

  return {
    summary_rows: formattedData.summary_rows,
    data_rows: formattedData.data_rows,
  }
}

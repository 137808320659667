///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore } from 'firebase/firestore'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_PayrollRates_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'finances', 'payroll_rates', 'main')
}

export const DatabaseRef_PayrollRates_Adders_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'payroll_rates', 'main', 'adders')
}

export const DatabaseRef_PayrollRates_EmployeeBaseRates_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'payroll_rates', 'main', 'employee_base_rates')
}

export const DatabaseRef_PayrollRates_MinimumWage_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'payroll_rates', 'main', 'minimum_wage')
}

export const DatabaseRef_PayrollRates_RegionAdders_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'payroll_rates', 'main', 'region_adders')
}

export const DatabaseRef_PayrollRates_TaskUnitMapping_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'payroll_rates', 'main', 'task_unit_mapping')
}

export const DatabaseRef_PayrollRates_UnitPay_Document = (clientKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'finances', 'payroll_rates', 'main', 'unit_pay')
}

///////////////////////////////
// Imports
///////////////////////////////

import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

///////////////////////////////
// Exports
///////////////////////////////

export const formInputs_EstimatedTimeline: TsInterface_FormInputs = {
  timestamp_estimated_cap_date: {
    key: 'timestamp_estimated_cap_date',
    label: rLIB('Est Approval Date'),
    input_type: 'timestamp_date',
    required: true,
    data_type: 'string',
  },
  timestamp_estimated_install_date: {
    key: 'timestamp_estimated_install_date',
    label: rLIB('Est Install Date'),
    input_type: 'timestamp_date',
    required: true,
    data_type: 'string',
  },
  timestamp_estimated_activation_date: {
    key: 'timestamp_estimated_activation_date',
    label: rLIB('Est Activation Date'),
    input_type: 'timestamp_date',
    required: true,
    data_type: 'string',
  },
}

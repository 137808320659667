///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_DefaultAllocatedTime_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'timesheets', 'allocated_time', 'default')
}

export const DatabaseRef_DefaultAllocatedTime_Document = (clientKey: string, userKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'timesheets', 'allocated_time', 'default', userKey)
}

export const DatabaseRef_OverrideAllocatedTime_Document = (clientKey: string, userDateKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'timesheets', 'allocated_time', 'overrides', userDateKey)
}

export const DatabaseRef_OverrideAllocatedTime_SpecificDate_Query = (clientKey: string, dateKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'associated_date_key', comparator: '==', value: dateKey }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'timesheets', 'allocated_time', 'overrides'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

import { TsInterface_FormInputs } from 'rfbp_core/components/form'
import { rLIB } from 'rfbp_core/localization/library'

export const formInputs_NewTrailer: TsInterface_FormInputs = {
  name: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'name',
    label: rLIB('Trailer Name'),
    required: true,
  },
  license_plate: {
    data_type: 'string',
    input_type: 'text_basic',
    key: 'license_plate',
    label: rLIB('License Plate'),
    required: true,
  },
}

/* eslint-disable react/prop-types */
///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Button, Card, Chip, FormControl, MenuItem, Popover, Select } from '@mui/material'
import {
  formInputs_DefaultTimeAllocations,
  formInputs_PayrollOrganizationCode,
  formInputs_PayrollUserId,
  formInputs_UserHourlyOrSalaried,
} from 'app/models/users/user_form'
import { tableColumns_UserTimecardSettings, tableSettings_UserTimecardSettings } from 'app/models/users/user_table'
import { useContext, useEffect, useReducer, useState } from 'react'
import { Trans } from 'react-i18next'
import { ClientUserRoles, returnClientUserRoles } from 'rfbp_aux/data/application_structure'
import { DatabaseRef_ClientUser_Document, DatabaseRef_Users_Collection } from 'rfbp_aux/services/database_endpoints/standard_database_endpoints'
import {
  DatabaseRef_DefaultAllocatedTime_Collection,
  DatabaseRef_DefaultAllocatedTime_Document,
  DatabaseRef_OverrideAllocatedTime_Document,
  DatabaseRef_OverrideAllocatedTime_SpecificDate_Query,
} from 'rfbp_aux/services/database_endpoints/timesheets/allocated_time'
import { DatabaseRef_ActiveTimeSheetAllocationTypes_Query } from 'rfbp_aux/services/database_endpoints/timesheets/allocation_types'
import { DatePicker } from 'rfbp_core/components/date_picker'
import {
  TsInterface_FormAdditionalData,
  TsInterface_FormData,
  TsInterface_FormHooksObject,
  TsInterface_FormInputs,
  TsInterface_FormSettings,
  TsInterface_FormSubmittedData,
} from 'rfbp_core/components/form'
import { Icon } from 'rfbp_core/components/icons'
import { TableDatabase, TsInterface_TableAdditionalData, TsInterface_TableDatabaseEndpointQueryObject } from 'rfbp_core/components/table'
import { rLIB } from 'rfbp_core/localization/library'
import {
  Context_RootData_ClientKey,
  Context_RootData_ClientPermissions,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
} from 'rfbp_core/services/context'
import {
  DatabaseBatchUpdate,
  DatabaseGetLiveCollection,
  DatabaseSetMergeDocument,
  generateDatabaseQuery,
  TsInterface_DatabaseBatchUpdatesArray,
  TsInterface_OrderByArray,
  TsInterface_QueryCursorsObject,
  TsInterface_QueryOperatorsArray,
} from 'rfbp_core/services/database_management'
import { dynamicSort, getProp, objectToArray, returnFormattedDate, returnFormattedDateKey } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

interface TabProps {
  selectedDate: Date
  setSelectedDate: any
}

interface FanButtonProps {
  arrayOfUserKeys: string[]
  editMultipleHourlyOrSalaried: any
  editMultipleDefaultTimeAllocations: any
  editMultipleOverrideTimeAllocations: any
}

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_ALL_DEACTIVATED_USERS: JSX.Element = <Trans>All Deactivated Users</Trans>

const s_ALL_EXTERNAL_USERS: JSX.Element = <Trans>All External Users</Trans>
const s_ALL_INTERNAL_USERS: JSX.Element = <Trans>All Internal Users</Trans>
const s_ALL_USERS: JSX.Element = <Trans>All Users</Trans>
const s_CLEAR_SELECTED_USERS: JSX.Element = <Trans>Clear Selected Users</Trans>
const s_DATE: JSX.Element = <Trans>Date</Trans>
const s_DEFAULT_ALLOCATIONS: JSX.Element = <Trans>Default Allocations</Trans>
const s_DEFAULT_TIME_ALLOCATIONS: JSX.Element = <Trans>Default Time Allocations</Trans>
const s_EDIT: JSX.Element = <Trans>Edit</Trans>
const s_EDIT_USER: JSX.Element = <Trans>Edit User</Trans>
const s_FAILED_TO_EDIT_OVERRIDE_TIME_ALLOCATIONS: JSX.Element = <Trans>Failed tp edit overrid time allocations</Trans>
const s_HOURLY_OR_SALARIED: JSX.Element = <Trans>Hourly or Salaried</Trans>
const s_INVALID_DATE_SELECTED: JSX.Element = <Trans>Invalid date selected</Trans>
const s_OVERRIDE_ALLOCATIONS: JSX.Element = <Trans>Override Allocations</Trans>
const s_OVERRIDE_TIME_ALLOCATIONS: JSX.Element = <Trans>Override Time Allocations</Trans>
const s_USER: JSX.Element = <Trans>User</Trans>
const s_USERS: JSX.Element = <Trans>Users</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

function getTrueProps(obj: TsInterface_UnspecifiedObject) {
  return Object.keys(obj).filter((propKey) => obj[propKey] === true)
}

function getStartAndEndOfWeek(date: Date) {
  const startOfWeek = new Date(date)
  startOfWeek.setHours(0, 0, 0, 0)
  startOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 0)
  const endOfWeek = new Date(date)
  endOfWeek.setHours(23, 59, 59, 999)
  endOfWeek.setDate(date.getDate() - ((date.getDay() + 6) % 7) + 6)
  return {
    startOfWeek,
    endOfWeek,
  }
}

// Fan Button Component
const EditMultipleUsersButton: React.FC<FanButtonProps> = ({
  arrayOfUserKeys,
  editMultipleHourlyOrSalaried,
  editMultipleDefaultTimeAllocations,
  editMultipleOverrideTimeAllocations,
}) => {
  const [us_anchorEl, us_setAnchorEl] = useState(null)

  const handleClick = (event: any) => {
    us_setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    us_setAnchorEl(null)
  }

  const open = Boolean(us_anchorEl)
  const id = open ? 'fan-options-popover' : undefined

  let userJSX = s_USERS
  if (arrayOfUserKeys.length === 1) {
    userJSX = s_USER
  }

  // Return JSX
  return (
    <Box className="tw-inline-block tw-ml-2">
      <Button
        variant="contained"
        color="success"
        onClick={handleClick}
        disabled={arrayOfUserKeys.length === 0}
      >
        <Icon
          icon="pen-to-square"
          className="tw-mr-1"
        />
        {s_EDIT} {arrayOfUserKeys.length} {userJSX}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={us_anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ margin: '5px' }}>
          <Button
            variant="outlined"
            className=""
            color="success"
            onClick={() => {
              editMultipleHourlyOrSalaried(arrayOfUserKeys)
            }}
          >
            {s_HOURLY_OR_SALARIED}
          </Button>
        </Box>
        <Box sx={{ margin: '5px' }}>
          <Button
            variant="outlined"
            className=""
            color="success"
            onClick={() => {
              editMultipleDefaultTimeAllocations(arrayOfUserKeys)
            }}
          >
            {s_DEFAULT_ALLOCATIONS}
          </Button>
        </Box>
        <Box sx={{ margin: '5px' }}>
          <Button
            variant="outlined"
            className=""
            color="success"
            onClick={() => {
              editMultipleOverrideTimeAllocations(arrayOfUserKeys)
            }}
          >
            {s_OVERRIDE_ALLOCATIONS}
          </Button>
        </Box>
      </Popover>
    </Box>
  )
}

///////////////////////////////
// JSX Exports
///////////////////////////////

export const Tab: React.FC<TabProps> = ({ selectedDate, setSelectedDate }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_allocationTypes, us_setAllocationTypes] = useState<TsInterface_UnspecifiedObject>({})
  const [us_clientUserRoles, us_setClientUserRoles] = useState<TsInterface_UnspecifiedObject>({})
  const [us_multiSelectedUsers, us_setMultiSelectedUsers] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userDefaultTimeAllocations, us_setUserDefaultTimeAllocations] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userOverrideTimeAllocations, us_setUserOverrideTimeAllocations] = useState<TsInterface_UnspecifiedObject>({})
  const [us_userTableFilter, us_setUserTableFilter] = useState<string>('ALL_INTERNAL_USERS')
  const [us_weekEndDate, us_setWeekEndDate] = useState<Date | null>(null)
  const [us_weekStartDate, us_setWeekStartDate] = useState<Date | null>(null)
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_RootData_ClientPermissions } = useContext(Context_RootData_ClientPermissions)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  // { sort-end } - hooks

  // Hooks - useEffect
  useEffect(() => {
    let weekBoundingDates = getStartAndEndOfWeek(selectedDate)
    us_setWeekStartDate(weekBoundingDates.startOfWeek)
    us_setWeekEndDate(weekBoundingDates.endOfWeek)
    return () => {}
  }, [selectedDate])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setClientUserRoles(returnClientUserRoles(res_GCK.clientKey))
        ur_forceRerender()
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUserDefaultTimeAllocations(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_DefaultAllocatedTime_Collection(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setUserOverrideTimeAllocations(newData)
      ur_forceRerender()
    }
    if (us_weekStartDate != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(
            DatabaseRef_OverrideAllocatedTime_SpecificDate_Query(res_GCK.clientKey, returnFormattedDateKey(us_weekStartDate)),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender, us_weekStartDate])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllocationTypes(newData)
      ur_forceRerender()
    }
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ActiveTimeSheetAllocationTypes_Query(res_GCK.clientKey), updateLiveData)
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, ur_forceRerender])

  // Functions
  const changeCalendarDate = (newDate: Date): TsType_UnknownPromise => {
    return new Promise((resolve, reject) => {
      // let formattedDateKey = returnFormattedDateKey( new Date( newDate ) )
      setSelectedDate(new Date(newDate))
      ur_forceRerender()
      resolve({ success: true })
    })
  }

  const tableDatabaseEndpoint_UserTimecardSettings = (
    queryGenerationData: TsInterface_TableDatabaseEndpointQueryObject,
    tableAdditionalData: TsInterface_TableAdditionalData,
  ) => {
    let queryOperatorsArray: TsInterface_QueryOperatorsArray = []
    let orderByArray: TsInterface_OrderByArray = [{ prop: 'name', desc: false }]
    if (tableAdditionalData != null && tableAdditionalData.user_status != null) {
      queryOperatorsArray.push({ prop: 'status', comparator: '==', value: tableAdditionalData.user_status })
    }
    if (tableAdditionalData != null && tableAdditionalData.associated_organization_type != null) {
      queryOperatorsArray.push({ prop: 'associated_organization_type', comparator: '==', value: tableAdditionalData.associated_organization_type })
    } else if (tableAdditionalData != null && tableAdditionalData.user_role != null) {
      queryOperatorsArray.push({ prop: 'user_role', comparator: '==', value: tableAdditionalData.user_role })
    } else {
      // queryOperatorsArray.push({ prop: "user_role", comparator: "not-in", value: ["customer", "sales_rep", "sales_manager", "sales_partner_admin"] })
      // orderByArray = [ { prop: "user_role", desc: false } ]
    }
    let queryCursorsObject: TsInterface_QueryCursorsObject = {}
    if (queryGenerationData['startAfter'] != null) {
      queryCursorsObject['startAfter'] = queryGenerationData.startAfter
    }
    if (queryGenerationData['startAt'] != null) {
      queryCursorsObject['startAt'] = queryGenerationData.startAt
    }
    if (queryGenerationData['endAt'] != null) {
      queryCursorsObject['endAt'] = queryGenerationData.endAt
    }
    if (queryGenerationData['endBefore'] != null) {
      queryCursorsObject['endBefore'] = queryGenerationData.endBefore
    }
    let limit = getProp(queryGenerationData, 'limit', 5)
    return generateDatabaseQuery(DatabaseRef_Users_Collection(uc_RootData_ClientKey as string), queryOperatorsArray, orderByArray, queryCursorsObject, limit)
  }

  const editHourlyOrSalaried = (userKey: string, user: TsInterface_UnspecifiedObject) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: user,
          formInputs: formInputs_UserHourlyOrSalaried,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, userKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {s_EDIT_USER} - {user.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editPayrollUserID = (userKey: string, user: TsInterface_UnspecifiedObject) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: user,
          formInputs: formInputs_PayrollUserId,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, userKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {s_EDIT_USER} - {user.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editPayrollOrganizationID = (userKey: string, user: TsInterface_UnspecifiedObject) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: user,
          formInputs: formInputs_PayrollOrganizationCode,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_ClientUser_Document(res_GCK.clientKey, userKey), formSubmittedData)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {s_EDIT_USER} - {user.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editMultipleHourlyOrSalaried = (userKeysArray: string[]) => {
    let userJSX = s_USERS
    if (userKeysArray.length === 1) {
      userJSX = s_USER
    }
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {},
          formData: {},
          formInputs: formInputs_UserHourlyOrSalaried,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  for (let loopUserIndex in userKeysArray) {
                    updateArray.push({
                      type: 'setMerge',
                      ref: DatabaseRef_ClientUser_Document(res_GCK.clientKey, userKeysArray[loopUserIndex]),
                      data: formSubmittedData,
                    })
                  }
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {s_EDIT} {userKeysArray.length} {userJSX}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editDefaultTimeAllocations = (userKey: string, user: TsInterface_UnspecifiedObject) => {
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {
            allocationTypes: us_allocationTypes,
          },
          formData: getProp(us_userDefaultTimeAllocations, userKey, {}),
          formInputs: formInputs_DefaultTimeAllocations,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject: TsInterface_UnspecifiedObject = { ...formSubmittedData }
              // Clean Bad Data
              if (updateObject != null && updateObject['allocated_times'] != null) {
                for (let loopAllocationCategoryKey in updateObject['allocated_times']) {
                  let loopAllocationCategory = updateObject['allocated_times'][loopAllocationCategoryKey]
                  for (let loopAllocationSubtypeKey in loopAllocationCategory) {
                    let loopAllocationSubtypeValue = loopAllocationCategory[loopAllocationSubtypeKey]
                    if (loopAllocationSubtypeValue == null || isNaN(parseFloat(loopAllocationSubtypeValue))) {
                      updateObject['allocated_times'][loopAllocationCategoryKey][loopAllocationSubtypeKey] = 0
                    }
                  }
                }
              }
              // Save to Database
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  DatabaseSetMergeDocument(DatabaseRef_DefaultAllocatedTime_Document(res_GCK.clientKey, userKey), updateObject)
                    .then((res_DSMD) => {
                      resolve(res_DSMD)
                    })
                    .catch((rej_DSMD) => {
                      reject(rej_DSMD)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {s_DEFAULT_TIME_ALLOCATIONS} - {user.name}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editMultipleDefaultTimeAllocations = (userKeysArray: string[]) => {
    let userJSX = s_USERS
    if (userKeysArray.length === 1) {
      userJSX = s_USER
    }
    // Open Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {
            allocationTypes: us_allocationTypes,
          },
          formData: {},
          formInputs: formInputs_DefaultTimeAllocations,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              let updateObject: TsInterface_UnspecifiedObject = { ...formSubmittedData }
              // Clean Bad Data
              if (updateObject != null && updateObject['allocated_times'] != null) {
                for (let loopAllocationCategoryKey in updateObject['allocated_times']) {
                  let loopAllocationCategory = updateObject['allocated_times'][loopAllocationCategoryKey]
                  for (let loopAllocationSubtypeKey in loopAllocationCategory) {
                    let loopAllocationSubtypeValue = loopAllocationCategory[loopAllocationSubtypeKey]
                    if (loopAllocationSubtypeValue == null || isNaN(parseFloat(loopAllocationSubtypeValue))) {
                      updateObject['allocated_times'][loopAllocationCategoryKey][loopAllocationSubtypeKey] = 0
                    }
                  }
                }
              }
              // Save to Database
              getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                .then((res_GCK) => {
                  let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                  for (let loopUserIndex in userKeysArray) {
                    updateArray.push({
                      type: 'setOverwrite',
                      ref: DatabaseRef_DefaultAllocatedTime_Document(res_GCK.clientKey, userKeysArray[loopUserIndex]),
                      data: updateObject,
                    })
                  }
                  DatabaseBatchUpdate(updateArray)
                    .then((res_DBU) => {
                      resolve(res_DBU)
                    })
                    .catch((rej_DBU) => {
                      reject(rej_DBU)
                    })
                })
                .catch((rej_GCK) => {
                  reject(rej_GCK)
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                })
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {s_EDIT} {userKeysArray.length} {userJSX} - {s_DEFAULT_TIME_ALLOCATIONS}
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  const editOverrideTimeAllocations = (userKey: string, user: TsInterface_UnspecifiedObject) => {
    if (us_weekStartDate != null) {
      let calculatedFormData: TsInterface_UnspecifiedObject = {}
      let overrideTimeAllocationKey = returnFormattedDateKey(us_weekStartDate) + '_' + userKey
      if (
        us_userOverrideTimeAllocations != null &&
        us_userOverrideTimeAllocations[overrideTimeAllocationKey] != null &&
        us_userOverrideTimeAllocations[overrideTimeAllocationKey]['allocated_times'] != null
      ) {
        calculatedFormData['allocated_times'] = us_userOverrideTimeAllocations[overrideTimeAllocationKey]['allocated_times']
      }
      // Open Dialog
      uc_setUserInterface_FormDialogDisplay({
        display: true,
        form: {
          form: {
            formAdditionalData: {
              allocationTypes: us_allocationTypes,
              showDefaultTimeAllocations: true,
              userDefaultTimeAllocations: getProp(us_userDefaultTimeAllocations, userKey, {}),
            },
            formData: calculatedFormData,
            formInputs: formInputs_DefaultTimeAllocations,
            formOnChange: (
              formAdditionalData: TsInterface_FormAdditionalData,
              formData: TsInterface_FormData,
              formInputs: TsInterface_FormInputs,
              formSettings: TsInterface_FormSettings,
            ) => {},
            formSettings: {},
            formSubmission: (
              formSubmittedData: TsInterface_FormSubmittedData,
              formAdditionalData: TsInterface_FormAdditionalData,
              formHooks: TsInterface_FormHooksObject,
            ) => {
              return new Promise((resolve, reject) => {
                if (us_weekStartDate != null) {
                  // Create Update Object
                  let overrideKey = returnFormattedDateKey(us_weekStartDate) + '_' + userKey
                  let updateObject = { ...formSubmittedData }
                  updateObject['associated_creator_key'] = getProp(formHooks.uc_RootData_GlobalUser, 'key', null)
                  updateObject['associated_creator_name'] = getProp(formHooks.uc_RootData_ClientUser, 'name', null)
                  updateObject['associated_date_key'] = returnFormattedDateKey(us_weekStartDate)
                  updateObject['associated_user_key'] = userKey
                  updateObject['key'] = overrideKey
                  updateObject['timestamp_created'] = new Date()
                  // Clean Bad Data
                  if (updateObject != null && updateObject['allocated_times'] != null) {
                    for (let loopAllocationCategoryKey in updateObject['allocated_times']) {
                      let loopAllocationCategory = updateObject['allocated_times'][loopAllocationCategoryKey]
                      for (let loopAllocationSubtypeKey in loopAllocationCategory) {
                        let loopAllocationSubtypeValue = loopAllocationCategory[loopAllocationSubtypeKey]
                        if (loopAllocationSubtypeValue == null || isNaN(parseFloat(loopAllocationSubtypeValue))) {
                          updateObject['allocated_times'][loopAllocationCategoryKey][loopAllocationSubtypeKey] = 0
                        }
                      }
                    }
                  }
                  // Update Database
                  getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                    .then((res_GCK) => {
                      DatabaseSetMergeDocument(DatabaseRef_OverrideAllocatedTime_Document(res_GCK.clientKey, overrideKey), updateObject)
                        .then((res_DSMD) => {
                          resolve(res_DSMD)
                        })
                        .catch((rej_DSMD) => {
                          reject(rej_DSMD)
                          uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_DSMD.error })
                        })
                    })
                    .catch((rej_GCK) => {
                      reject(rej_GCK)
                      uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                    })
                } else {
                  let error = {
                    message: s_FAILED_TO_EDIT_OVERRIDE_TIME_ALLOCATIONS,
                    details: s_INVALID_DATE_SELECTED,
                    code: 'ER-D-TAUS-EOTA-01',
                  }
                  uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                  reject({ success: false, error: error })
                }
              })
            },
          },
          dialog: {
            formDialogHeaderColor: 'success',
            formDialogHeaderText: (
              <>
                {s_OVERRIDE_TIME_ALLOCATIONS} - {user.name} ({returnFormattedDate(us_weekStartDate, 'D MMM YY')} {rLIB('To')}{' '}
                {returnFormattedDate(us_weekEndDate, 'D MMM YY')})
              </>
            ),
            formDialogIcon: (
              <Icon
                type="solid"
                icon="pen-to-square"
              />
            ),
          },
        },
      })
    }
  }

  const editMultipleOverrideTimeAllocations = (userKeysArray: string[]) => {
    let userJSX = s_USERS
    if (userKeysArray.length === 1) {
      userJSX = s_USER
    }
    // Open Dialog
    uc_setUserInterface_FormDialogDisplay({
      display: true,
      form: {
        form: {
          formAdditionalData: {
            allocationTypes: us_allocationTypes,
          },
          formData: {},
          formInputs: formInputs_DefaultTimeAllocations,
          formOnChange: (
            formAdditionalData: TsInterface_FormAdditionalData,
            formData: TsInterface_FormData,
            formInputs: TsInterface_FormInputs,
            formSettings: TsInterface_FormSettings,
          ) => {},
          formSettings: {},
          formSubmission: (
            formSubmittedData: TsInterface_FormSubmittedData,
            formAdditionalData: TsInterface_FormAdditionalData,
            formHooks: TsInterface_FormHooksObject,
          ) => {
            return new Promise((resolve, reject) => {
              if (us_weekStartDate != null) {
                getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
                  .then((res_GCK) => {
                    let updateArray: TsInterface_DatabaseBatchUpdatesArray = []
                    for (let loopUserIndex in userKeysArray) {
                      let overrideKey = returnFormattedDateKey(us_weekStartDate) + '_' + userKeysArray[loopUserIndex]
                      let updateObject = { ...formSubmittedData }
                      updateObject['associated_creator_key'] = getProp(formHooks.uc_RootData_GlobalUser, 'key', null)
                      updateObject['associated_creator_name'] = getProp(formHooks.uc_RootData_ClientUser, 'name', null)
                      updateObject['associated_date_key'] = returnFormattedDateKey(us_weekStartDate)
                      updateObject['associated_user_key'] = userKeysArray[loopUserIndex]
                      updateObject['key'] = overrideKey
                      updateObject['timestamp_created'] = new Date()
                      // Clean Bad Data
                      if (updateObject != null && updateObject['allocated_times'] != null) {
                        for (let loopAllocationCategoryKey in updateObject['allocated_times']) {
                          let loopAllocationCategory = updateObject['allocated_times'][loopAllocationCategoryKey]
                          for (let loopAllocationSubtypeKey in loopAllocationCategory) {
                            let loopAllocationSubtypeValue = loopAllocationCategory[loopAllocationSubtypeKey]
                            if (loopAllocationSubtypeValue == null || isNaN(parseFloat(loopAllocationSubtypeValue))) {
                              updateObject['allocated_times'][loopAllocationCategoryKey][loopAllocationSubtypeKey] = 0
                            }
                          }
                        }
                      }
                      updateArray.push({
                        type: 'setOverwrite',
                        ref: DatabaseRef_OverrideAllocatedTime_Document(res_GCK.clientKey, overrideKey),
                        data: updateObject,
                      })
                    }
                    DatabaseBatchUpdate(updateArray)
                      .then((res_DBU) => {
                        resolve(res_DBU)
                      })
                      .catch((rej_DBU) => {
                        reject(rej_DBU)
                      })
                  })
                  .catch((rej_GCK) => {
                    reject(rej_GCK)
                    uc_setUserInterface_ErrorDialogDisplay({ display: true, error: rej_GCK.error })
                  })
              } else {
                let error = {
                  message: s_FAILED_TO_EDIT_OVERRIDE_TIME_ALLOCATIONS,
                  details: s_INVALID_DATE_SELECTED,
                  code: 'ER-D-TAUS-EOTA-01',
                }
                uc_setUserInterface_ErrorDialogDisplay({ display: true, error: error })
                reject({ success: false, error: error })
              }
            })
          },
        },
        dialog: {
          formDialogHeaderColor: 'success',
          formDialogHeaderText: (
            <>
              {s_EDIT} {userKeysArray.length} {userJSX} - {s_OVERRIDE_TIME_ALLOCATIONS} ({returnFormattedDate(us_weekStartDate, 'D MMM YY')} {rLIB('To')}{' '}
              {returnFormattedDate(us_weekEndDate, 'D MMM YY')})
            </>
          ),
          formDialogIcon: (
            <Icon
              type="solid"
              icon="pen-to-square"
            />
          ),
        },
      },
    })
  }

  // Call Functions

  // JSX Generation
  const rJSX_DatePicker = (): JSX.Element => {
    let datePickerJSX = (
      <Box className="tw-inline-block tw-mb-2 tw-align-top">
        <DatePicker
          key={'calendar_date'}
          datePickerText={s_DATE}
          datePickerDate={selectedDate}
          datePickerDisabled={false}
          datePickerDateOnChange={changeCalendarDate}
          datePickerSettings={{ thin_input: true }}
        />
      </Box>
    )
    return datePickerJSX
  }

  const rJSX_UserTypeFilterDropdown = (): JSX.Element => {
    let dropdownJSX = (
      <Box className="tw-inline-block tw-align-top tw-ml-2">
        <FormControl className="bp_thin_select_input">
          <Select
            color="primary"
            value={us_userTableFilter}
            onChange={(event: any) => {
              if (event != null && event.target != null && event.target.value != null) {
                us_setUserTableFilter(event.target.value)
              }
            }}
            variant="outlined"
          >
            <MenuItem value={'ALL_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            <MenuItem value={'ALL_INTERNAL_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_INTERNAL_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            <MenuItem value={'ALL_EXTERNAL_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_EXTERNAL_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            <MenuItem value={'ALL_DEACTIVATED_USERS'}>
              <Chip
                size={'small'}
                label={s_ALL_DEACTIVATED_USERS}
                color={'default'}
                icon={<Icon icon="users" />}
              />
            </MenuItem>
            {objectToArray(us_clientUserRoles)
              .sort(dynamicSort('key', null))
              .map((option: TsInterface_UnspecifiedObject, index: number) => (
                <MenuItem
                  key={index}
                  value={option['key']}
                >
                  <Chip
                    size={'small'}
                    label={ClientUserRoles[option['key']]['name']}
                    color={ClientUserRoles[option['key']]['color']}
                    icon={ClientUserRoles[option['key']]['icon']}
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    )
    return dropdownJSX
  }

  const rJSX_ClearSelectedUsersButton = (): JSX.Element => {
    let buttonJSX = (
      <Button
        className="tw-ml-2"
        variant="outlined"
        color="secondary"
        disabled={getTrueProps(us_multiSelectedUsers).length === 0}
        onClick={() => {
          us_setMultiSelectedUsers({})
        }}
      >
        <Icon
          icon="times"
          className="tw-mr-1"
        />
        {s_CLEAR_SELECTED_USERS}
      </Button>
    )
    return buttonJSX
  }

  const rJSX_Table = (): JSX.Element => {
    let tableJSX = <></>
    let userStatus = 'active'
    if (us_userTableFilter === 'ALL_DEACTIVATED_USERS') {
      userStatus = 'deleted'
    }
    if (us_userTableFilter === 'ALL_INTERNAL_USERS' || us_userTableFilter === 'ALL_EXTERNAL_USERS') {
      let organizationType = 'internal'
      if (us_userTableFilter === 'ALL_EXTERNAL_USERS') {
        organizationType = 'external'
      }
      let tableAdditionalData_AllUsers: TsInterface_TableAdditionalData = {
        associated_organization_type: organizationType,
        task_roles: ClientUserRoles,
        client_type: uc_RootData_ClientPermissions.client_type,
        multiSelectedUsers: us_multiSelectedUsers,
        setMultiSelectedUsers: us_setMultiSelectedUsers,
        editHourlyOrSalaried: editHourlyOrSalaried,
        editPayrollUserID: editPayrollUserID,
        editPayrollOrganizationID: editPayrollOrganizationID,
        editDefaultTimeAllocations: editDefaultTimeAllocations,
        editOverrideTimeAllocations: editOverrideTimeAllocations,
        userDefaultTimeAllocations: us_userDefaultTimeAllocations,
        userOverrideTimeAllocations: us_userOverrideTimeAllocations,
        allocationTypes: us_allocationTypes,
        weekStartDate: us_weekStartDate,
        user_status: userStatus,
      }
      tableJSX = (
        <Card className="tw-mt-4">
          <TableDatabase
            tableAdditionalData={tableAdditionalData_AllUsers}
            tableColumns={tableColumns_UserTimecardSettings}
            tableDatabaseEndpoint={tableDatabaseEndpoint_UserTimecardSettings}
            tableSettings={tableSettings_UserTimecardSettings}
          />
        </Card>
      )
    } else if (us_userTableFilter === 'ALL_USERS' || us_userTableFilter === 'ALL_DEACTIVATED_USERS') {
      let tableAdditionalData_AllUsers: TsInterface_TableAdditionalData = {
        task_roles: ClientUserRoles,
        client_type: uc_RootData_ClientPermissions.client_type,
        multiSelectedUsers: us_multiSelectedUsers,
        setMultiSelectedUsers: us_setMultiSelectedUsers,
        editHourlyOrSalaried: editHourlyOrSalaried,
        editPayrollUserID: editPayrollUserID,
        editPayrollOrganizationID: editPayrollOrganizationID,
        editDefaultTimeAllocations: editDefaultTimeAllocations,
        editOverrideTimeAllocations: editOverrideTimeAllocations,
        userDefaultTimeAllocations: us_userDefaultTimeAllocations,
        userOverrideTimeAllocations: us_userOverrideTimeAllocations,
        allocationTypes: us_allocationTypes,
        weekStartDate: us_weekStartDate,
        user_status: userStatus,
      }
      tableJSX = (
        <Card className="tw-mt-4">
          <TableDatabase
            tableAdditionalData={tableAdditionalData_AllUsers}
            tableColumns={tableColumns_UserTimecardSettings}
            tableDatabaseEndpoint={tableDatabaseEndpoint_UserTimecardSettings}
            tableSettings={tableSettings_UserTimecardSettings}
          />
        </Card>
      )
    } else {
      let tableAdditionalData_User: TsInterface_TableAdditionalData = {
        user_role: us_userTableFilter,
        task_roles: ClientUserRoles,
        client_type: uc_RootData_ClientPermissions.client_type,
        multiSelectedUsers: us_multiSelectedUsers,
        setMultiSelectedUsers: us_setMultiSelectedUsers,
        editHourlyOrSalaried: editHourlyOrSalaried,
        editPayrollUserID: editPayrollUserID,
        editPayrollOrganizationID: editPayrollOrganizationID,
        editDefaultTimeAllocations: editDefaultTimeAllocations,
        editOverrideTimeAllocations: editOverrideTimeAllocations,
        userDefaultTimeAllocations: us_userDefaultTimeAllocations,
        userOverrideTimeAllocations: us_userOverrideTimeAllocations,
        allocationTypes: us_allocationTypes,
        weekStartDate: us_weekStartDate,
        user_status: userStatus,
      }
      tableJSX = (
        <Card className="tw-mt-4">
          <TableDatabase
            tableAdditionalData={tableAdditionalData_User}
            tableColumns={tableColumns_UserTimecardSettings}
            tableDatabaseEndpoint={tableDatabaseEndpoint_UserTimecardSettings}
            tableSettings={tableSettings_UserTimecardSettings}
          />
        </Card>
      )
    }
    return tableJSX
  }

  const rJSX_Tab = (): JSX.Element => {
    let tabJSX = <></>
    tabJSX = (
      <Box className="tw-pt-1">
        <Box>
          {rJSX_DatePicker()}
          {rJSX_UserTypeFilterDropdown()}
          <EditMultipleUsersButton
            arrayOfUserKeys={getTrueProps(us_multiSelectedUsers)}
            editMultipleHourlyOrSalaried={editMultipleHourlyOrSalaried}
            editMultipleDefaultTimeAllocations={editMultipleDefaultTimeAllocations}
            editMultipleOverrideTimeAllocations={editMultipleOverrideTimeAllocations}
          />
          {rJSX_ClearSelectedUsersButton()}
        </Box>
        <Box>{rJSX_Table()}</Box>
      </Box>
    )
    return tabJSX
  }

  // Render
  return <>{rJSX_Tab()}</>
}

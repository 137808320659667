///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			containers are pages / views used in the app and are made up of components and can interact with services and models

		TODO:

	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, useMediaQuery } from '@mui/material/'
import { returnImageTags } from 'app/models/projects/project_image_tags'
import { useContext, useEffect, useReducer, useState } from 'react'
import { DatabaseRef_TaskFormProdPages_Document } from 'rfbp_aux/services/database_endpoints/directory/task_forms'
import {
  DatabaseRef_ProjectTaskFormData_Collection,
  DatabaseRef_ProjectTaskFormData_Document,
  DatabaseRef_ProjectTaskWorkflow_Document,
} from 'rfbp_aux/services/database_endpoints/operations/projects'
import { StorageRef_ProjectImageThumbnailFile, StorageRef_ProjectImageThumbnailsFolder } from 'rfbp_aux/services/storage_endpoints/projects'
import {
  Context_RootData_ClientKey,
  Context_UserInterface_AlertDialog,
  Context_UserInterface_CustomDialog,
  Context_UserInterface_ErrorDialog,
  Context_UserInterface_FormDialog,
  Context_UserInterface_Snackbar,
} from 'rfbp_core/services/context'
import {
  DatabaseGetDocument,
  DatabaseGetLiveCollection,
  DatabaseGetLiveDocument,
  StorageGetDownloadUrl,
  StorageListFiles,
} from 'rfbp_core/services/database_management'
import { getProp, objectToArray } from 'rfbp_core/services/helper_functions'
import { getClientKey } from 'rfbp_core/services/user_authentication'
import { TsInterface_UnspecifiedObject, TsType_UnknownPromise, TsType_VoidFunction } from 'rfbp_core/typescript/global_types'
import { rJSX_PhotosTab } from '../project_view/tab_photos'

///////////////////////////////
// Typescript
///////////////////////////////

interface TsInterface_ProjectImagesView {
  projectKey: string
}

///////////////////////////////
// Variables
///////////////////////////////

// Authenticated Nav Data

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
// { sort-end } - displayed text

// Table

///////////////////////////////
// Functions
///////////////////////////////

///////////////////////////////
// Container
///////////////////////////////

// eslint-disable-next-line react/prop-types
export const ProjectImagesView: React.FC<TsInterface_ProjectImagesView> = ({ projectKey }): JSX.Element => {
  // Props

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks
  const [us_allTaskFormData, us_setAllTaskFormData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_availableImageTags, us_setAvailableImageTags] = useState<string[]>([])
  const [us_downloadingPhotos, us_setDownloadingPhotos] = useState<boolean>(false)
  const [us_filteredPhotosList, us_setFilteredPhotosList] = useState<TsInterface_UnspecifiedObject[]>([])
  const [us_flatImageTagCounts, us_setFlatImageTagCounts] = useState<TsInterface_UnspecifiedObject>({})
  const [us_flatImageUploadData, us_setFlatImageUploadData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_photosViewLevel, us_setPhotosViewLevel] = useState<string>('root') // root | task | page | subfolder
  const [us_photosViewType, us_setPhotosViewType] = useState<string>('tag') // folder | tag
  const [us_projectImageThumbnails, us_setProjectImageThumbnails] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectRootPhotosFolders, us_setProjectRootPhotosFolders] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTaskWorkflow, us_setProjectTaskWorkflow] = useState<TsInterface_UnspecifiedObject>({})
  const [us_projectTasks, us_setProjectTasks] = useState<TsInterface_UnspecifiedObject>({})
  const [us_runningImageAnalysis, us_setRunningImageAnalysis] = useState<boolean>(false)
  const [us_screenSize, us_setScreenSize] = useState<string>('md')
  const [us_selectedAllImageFilterTag, us_setSelectedAllImagesFilterTag] = useState<boolean>(false)
  const [us_selectedImageFilterTags, us_setSelectedImageFilterTags] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolder, us_setSelectedPhotosTaskFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolderData, us_setSelectedPhotosTaskFolderData] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskFolderForm, us_setSelectedPhotosTaskFolderForm] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskPageFolder, us_setSelectedPhotosTaskPageFolder] = useState<TsInterface_UnspecifiedObject>({})
  const [us_selectedPhotosTaskPageSubfolder, us_setSelectedPhotosTaskPageSubfolder] = useState<TsInterface_UnspecifiedObject>({})
  const umq_isExtraSmallScreen = useMediaQuery('(max-width: 400px)')
  const umq_isLargeScreen = useMediaQuery('(min-width: 961px) and (max-width: 1280px)')
  const umq_isMediumScreen = useMediaQuery('(min-width: 601px) and (max-width: 960px)')
  const umq_isSmallScreen = useMediaQuery('(min-width: 401px) and (max-width: 600px)')
  const ur_forceRerender = useReducer(() => ({}), {})[1] as () => void
  const { uc_RootData_ClientKey, uc_setRootData_ClientKey } = useContext(Context_RootData_ClientKey)
  const { uc_setUserInterface_AlertDialogDisplay } = useContext(Context_UserInterface_AlertDialog)
  const { uc_setUserInterface_CustomDialogDisplay } = useContext(Context_UserInterface_CustomDialog)
  const { uc_setUserInterface_ErrorDialogDisplay } = useContext(Context_UserInterface_ErrorDialog)
  const { uc_setUserInterface_FormDialogDisplay } = useContext(Context_UserInterface_FormDialog)
  const { uc_setUserInterface_SnackbarDisplay } = useContext(Context_UserInterface_Snackbar)
  // { sort-end } - hooks

  let us_activeTab = 'photos'

  // Hooks - useEffect

  useEffect(() => {
    if (umq_isExtraSmallScreen === true) {
      us_setScreenSize('xs')
    } else if (umq_isSmallScreen === true) {
      us_setScreenSize('sm')
    } else if (umq_isMediumScreen === true) {
      us_setScreenSize('md')
    } else if (umq_isLargeScreen === true) {
      us_setScreenSize('lg')
    } else {
      us_setScreenSize('xl')
    }
    ur_forceRerender()
    return () => {}
  }, [umq_isExtraSmallScreen, umq_isSmallScreen, umq_isMediumScreen, umq_isLargeScreen, ur_forceRerender])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      if (newData != null) {
        us_setSelectedPhotosTaskFolderData(newData)
      }
      ur_forceRerender()
    }
    if (us_selectedPhotosTaskFolder != null && us_selectedPhotosTaskFolder.key != null && projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveDocument(
            DatabaseRef_ProjectTaskFormData_Document(res_GCK.clientKey, projectKey, us_selectedPhotosTaskFolder.key),
            updateLiveData,
          )
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedPhotosTaskFolderData({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedPhotosTaskFolder, projectKey, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        DatabaseGetDocument(DatabaseRef_ProjectTaskWorkflow_Document(res_GCK.clientKey, projectKey))
          .then((res_DGD) => {
            us_setProjectTaskWorkflow(res_DGD.data)
          })
          .catch((rej_DGC) => {
            console.error(rej_DGC)
          })
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
  }, [uc_RootData_ClientKey, projectKey, uc_setRootData_ClientKey])

  useEffect(() => {
    if (
      us_selectedPhotosTaskFolder != null &&
      us_selectedPhotosTaskFolder.key != null &&
      us_projectTasks != null &&
      us_projectTasks[us_selectedPhotosTaskFolder.key] != null &&
      us_projectTasks[us_selectedPhotosTaskFolder.key]['associated_task_form_key'] != null
    ) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          DatabaseGetDocument(
            DatabaseRef_TaskFormProdPages_Document(res_GCK.clientKey, us_projectTasks[us_selectedPhotosTaskFolder.key]['associated_task_form_key']),
          )
            .then((res_DGD) => {
              us_setSelectedPhotosTaskFolderForm(res_DGD.data)
            })
            .catch((rej_DGD) => {
              console.error(rej_DGD)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setSelectedPhotosTaskFolderForm({})
    }
    return () => {}
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_selectedPhotosTaskFolder, ur_forceRerender])

  useEffect(() => {
    getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
      .then((res_GCK) => {
        us_setAvailableImageTags(returnImageTags(res_GCK.clientKey))
      })
      .catch((rej_GCK) => {
        console.error(rej_GCK)
      })
    return () => {}
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey])

  useEffect(() => {
    let filteredImages: TsInterface_UnspecifiedObject[] = []
    for (let loopImageIndex in us_flatImageUploadData) {
      let loopImage = us_flatImageUploadData[loopImageIndex]
      for (let loopThumbnailKey in us_projectImageThumbnails) {
        let loopThumbnail = us_projectImageThumbnails[loopThumbnailKey]
        let loopImageName = loopImage.name
        let loopThumbnailName = loopThumbnail.name
        let fileNameWithoutExtension = loopImageName.split('.').slice(0, -1).join('.')
        if (loopThumbnailName.startsWith(fileNameWithoutExtension)) {
          loopImage.thumbnail_url = loopThumbnail.thumbnail_url
        }
      }
      if (us_selectedAllImageFilterTag === true) {
        filteredImages.push(loopImage)
      } else {
        let includePhotoInDisplay = true
        if (objectToArray(us_selectedImageFilterTags).length === 0) {
          includePhotoInDisplay = false
        }
        for (let loopTagKey in us_selectedImageFilterTags) {
          if (us_selectedImageFilterTags[loopTagKey] === true) {
            if (loopImage != null && loopImage.tags != null && loopImage.tags.indexOf(loopTagKey) === -1) {
              includePhotoInDisplay = false
            }
          }
        }
        if (includePhotoInDisplay === true) {
          filteredImages.push(loopImage)
        }
      }
    }
    us_setFilteredPhotosList(filteredImages)
    return () => {}
  }, [us_setFilteredPhotosList, us_flatImageUploadData, us_selectedAllImageFilterTag, us_selectedImageFilterTags, us_projectImageThumbnails])

  useEffect(() => {
    let tagCounts: TsInterface_UnspecifiedObject = { all_photos: 0 }
    let calculatedProjectRootPhotosFolders: TsInterface_UnspecifiedObject = {}
    if (us_allTaskFormData != null) {
      let taskFormImages: TsInterface_UnspecifiedObject[] = []
      for (let loopTaskKey in us_allTaskFormData) {
        let loopTaskData = us_allTaskFormData[loopTaskKey]
        for (let loopPageKey in loopTaskData) {
          let loopPageData = loopTaskData[loopPageKey]
          if (loopPageData != null && loopPageData['folders'] != null) {
            for (let loopFolderKey in loopPageData['folders']) {
              let loopFolder = loopPageData['folders'][loopFolderKey]
              for (let loopFileUploadIndex in loopFolder) {
                let loopFileUpload = loopFolder[loopFileUploadIndex]
                if (loopFileUpload != null && loopFileUpload['upload_type'] === 'image') {
                  if (calculatedProjectRootPhotosFolders[loopTaskKey] == null) {
                    calculatedProjectRootPhotosFolders[loopTaskKey] = {
                      key: loopTaskKey,
                      path: null,
                    }
                  }
                  loopFileUpload['TEMP_associated_task_key'] = loopTaskKey
                  loopFileUpload['TEMP_associated_page_key'] = loopPageKey
                  loopFileUpload['TEMP_associated_folder_key'] = loopFolderKey
                  loopFileUpload['TEMP_subfolder_name'] = loopFolder.name
                  loopFileUpload['TEMP_upload_index'] = loopFileUploadIndex
                  taskFormImages.push(loopFileUpload)
                  tagCounts['all_photos']++
                  if (loopFileUpload != null && loopFileUpload['tags'] != null) {
                    let includePhotoInCounts = true
                    for (let loopTagKey in us_selectedImageFilterTags) {
                      if (us_selectedImageFilterTags[loopTagKey] === true) {
                        if (loopFileUpload != null && loopFileUpload.tags != null && loopFileUpload.tags.indexOf(loopTagKey) === -1) {
                          includePhotoInCounts = false
                        }
                      }
                    }
                    if (includePhotoInCounts === true) {
                      for (let loopTagIndex in loopFileUpload['tags']) {
                        let loopTag = loopFileUpload['tags'][loopTagIndex]
                        if (tagCounts[loopTag] == null) {
                          tagCounts[loopTag] = 0
                        }
                        tagCounts[loopTag]++
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      us_setFlatImageUploadData(taskFormImages)
      us_setFlatImageTagCounts(tagCounts)
      us_setProjectRootPhotosFolders(calculatedProjectRootPhotosFolders)
    }
    ur_forceRerender()
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, us_allTaskFormData, ur_forceRerender, us_selectedImageFilterTags])

  useEffect(() => {
    let unsubscribeLiveData: TsType_VoidFunction
    const updateLiveData = (newData: TsInterface_UnspecifiedObject) => {
      us_setAllTaskFormData(newData)
      ur_forceRerender()
    }
    if (us_activeTab === 'photos' && projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          unsubscribeLiveData = DatabaseGetLiveCollection(DatabaseRef_ProjectTaskFormData_Collection(res_GCK.clientKey, projectKey), updateLiveData)
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    } else {
      us_setFlatImageUploadData([])
      us_setFlatImageTagCounts({})
      us_setAllTaskFormData({})
      us_setProjectRootPhotosFolders({})
    }
    return () => {
      if (typeof unsubscribeLiveData === 'function') {
        unsubscribeLiveData()
      }
    }
  }, [uc_RootData_ClientKey, uc_setRootData_ClientKey, projectKey, ur_forceRerender, us_activeTab])

  useEffect(() => {
    if (us_activeTab === 'photos' && projectKey != null) {
      getClientKey(uc_RootData_ClientKey, uc_setRootData_ClientKey)
        .then((res_GCK) => {
          StorageListFiles(StorageRef_ProjectImageThumbnailsFolder(res_GCK.clientKey, projectKey))
            .then((res_SLF) => {
              let thumbnailFiles: TsInterface_UnspecifiedObject = {}
              let promiseArray: TsType_UnknownPromise[] = []
              if (res_SLF != null && res_SLF.data != null && res_SLF.data.files != null) {
                for (let loopFileKey in res_SLF.data.files) {
                  thumbnailFiles[loopFileKey] = res_SLF.data.files[loopFileKey]
                  promiseArray.push(
                    StorageGetDownloadUrl(StorageRef_ProjectImageThumbnailFile(res_GCK.clientKey, projectKey, loopFileKey))
                      .then((res_SGDU) => {
                        thumbnailFiles[loopFileKey]['thumbnail_url'] = getProp(res_SGDU, 'url', null)
                      })
                      .catch((rej_SGDU) => {
                        console.error(rej_SGDU)
                      }),
                  )
                }
              }
              Promise.all(promiseArray).finally(() => {
                us_setProjectImageThumbnails(thumbnailFiles)
              })
            })
            .catch((rej_SLF) => {
              console.error(rej_SLF)
            })
        })
        .catch((rej_GCK) => {
          console.error(rej_GCK)
        })
    }
  }, [uc_RootData_ClientKey, ur_forceRerender, uc_setRootData_ClientKey, projectKey, us_activeTab])

  // Functions
  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        {rJSX_PhotosTab(
          'read',
          uc_RootData_ClientKey,
          us_allTaskFormData,
          us_availableImageTags,
          us_downloadingPhotos,
          us_filteredPhotosList,
          us_flatImageTagCounts,
          ur_forceRerender,
          us_photosViewLevel,
          us_photosViewType,
          projectKey,
          us_projectRootPhotosFolders,
          us_projectTaskWorkflow,
          us_projectTasks,
          us_selectedAllImageFilterTag,
          us_selectedImageFilterTags,
          us_selectedPhotosTaskFolder,
          us_selectedPhotosTaskFolderData,
          us_selectedPhotosTaskFolderForm,
          us_selectedPhotosTaskPageFolder,
          us_selectedPhotosTaskPageSubfolder,
          us_setDownloadingPhotos,
          us_setPhotosViewLevel,
          us_setPhotosViewType,
          uc_setRootData_ClientKey,
          us_setSelectedAllImagesFilterTag,
          us_setSelectedImageFilterTags,
          us_setSelectedPhotosTaskFolder,
          us_setSelectedPhotosTaskFolderData,
          us_setSelectedPhotosTaskPageFolder,
          us_setSelectedPhotosTaskPageSubfolder,
          uc_setUserInterface_CustomDialogDisplay,
          uc_setUserInterface_ErrorDialogDisplay,
          uc_setUserInterface_FormDialogDisplay,
          uc_setUserInterface_AlertDialogDisplay,
          us_runningImageAnalysis,
          us_setRunningImageAnalysis,
          uc_setUserInterface_SnackbarDisplay,
          us_screenSize,
        )}
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Used to generate Prompt Dialogs

		TODO:
			[ ] Typescript - 2 instances of any - function copied from MUI Docs
			[ ] Typescript - 1 instance of unknown
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { Box, Dialog, Slide } from '@mui/material/'
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'
import { TsInterface_CustomDialogObject, TsType_UserInterface_AllDialogsDismissCallback } from 'rfbp_core/services/context'
import { getProp } from 'rfbp_core/services/helper_functions'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin

// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  )
})

///////////////////////////////
// Component
///////////////////////////////

export const CustomDialog = (dialog: TsInterface_CustomDialogObject, dismissDialog: TsType_UserInterface_AllDialogsDismissCallback): JSX.Element => {
  // Props
  let pr_maxWidth: TsInterface_CustomDialogObject['settings']['max_width'] = getProp(dialog.settings, 'max_width', 'md')

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const handleClose = (): void => {
    dismissDialog()
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <Dialog
          // container={() => document.getElementById('root')}
          TransitionComponent={Transition}
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          fullWidth={true}
          keepMounted
          maxWidth={pr_maxWidth}
          onClose={handleClose}
          open={true}
        >
          {dialog.dialog_jsx}
        </Dialog>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, documentId, DocumentReference, getFirestore, Query, query, where } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_GM_CachedData_CrewHours_Document = (clientKey: string, mondayDateKey: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'crew_hours', 'main', mondayDateKey)
}

export const DatabaseRef_CrewHours_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'crew_hours', 'main')
}

// export const DatabaseRef_CrewHoursByDate_Query = (clientKey: string, startDate: Date, endDate: Date): Query<unknown> => {
//   let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
//     { prop: 'timestamp_scheduled_pickup_date_key', comparator: '>=', value: startDateKey },
//     { prop: 'timestamp_scheduled_pickup_date_key', comparator: '<=', value: endDateKey },
//   ]
//   let orderByArray: TsInterface_OrderByArray = []
//   return generateDatabaseQuery(
//     collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'crew_hours', 'main'),
//     queryOperatorsArray,
//     orderByArray,
//     {},
//     limit,
//   )
// }

// TODO: Refactor after data is better
export const DatabaseRef_CrewHoursByDate_Query = (clientKey: string, startDate: Date, endDate: Date) => {
  const db = getFirestore()
  const crewHoursCollectionPath = `clients/${clientKey}/operations/gm_dashboards/cached_data/crew_hours/main`

  // Formatting the start and end dates as strings in 'YYYY-MM-DD' format
  const startDateFormat = startDate.toISOString().slice(0, 10)
  const endDateFormat = endDate.toISOString().slice(0, 10)

  // Create a query against the collection based on document IDs.
  const crewHoursQuery = query(collection(db, crewHoursCollectionPath), where(documentId(), '>=', startDateFormat), where(documentId(), '<=', endDateFormat))

  return crewHoursQuery
}

export const DatabaseRef_NewCrewHoursByDate_Query = (clientKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  const startDateFormat = startDate.toISOString().slice(0, 10)
  const endDateFormat = endDate.toISOString().slice(0, 10)
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'key', comparator: '>=', value: startDateFormat },
    { prop: 'key', comparator: '<=', value: endDateFormat },
  ]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'crew_hours', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_SafetyReviewByDate_Query = (clientKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  const startDateFormat = startDate.toISOString().slice(0, 10)
  const endDateFormat = endDate.toISOString().slice(0, 10)
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'key', comparator: '>=', value: startDateFormat },
    { prop: 'key', comparator: '<=', value: endDateFormat },
  ]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'safety_reviews', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_CycleTimeByDate_Query = (clientKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  const startDateFormat = startDate.toISOString().slice(0, 10)
  const endDateFormat = endDate.toISOString().slice(0, 10)
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'key', comparator: '>=', value: startDateFormat },
    { prop: 'key', comparator: '<=', value: endDateFormat },
  ]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'cycle_times', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_CycleTimeByActiveProject_Query = (clientKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'status', comparator: '==', value: 'active' }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'projects', 'timestamps'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_VehiclesByDate_Query = (clientKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  const startDateFormat = startDate.toISOString().slice(0, 10)
  const endDateFormat = endDate.toISOString().slice(0, 10)
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'key', comparator: '>=', value: startDateFormat },
    { prop: 'key', comparator: '<=', value: endDateFormat },
  ]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'vehicles', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_ExpectedVehiclesByDate_Query = (clientKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  const startDateFormat = startDate.toISOString().slice(0, 10)
  const endDateFormat = endDate.toISOString().slice(0, 10)
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'key', comparator: '>=', value: startDateFormat },
    { prop: 'key', comparator: '<=', value: endDateFormat },
  ]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'expected_vehicles', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_CalculateDistanceBetweenTasks_Query = (clientKey: string, cachingKey: string): Query<unknown> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [{ prop: 'key', comparator: '==', value: cachingKey }]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'distance_between_tasks', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_PanelsInstalledByDate_Query = (clientKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  const startDateFormat = startDate.toISOString().slice(0, 10)
  const endDateFormat = endDate.toISOString().slice(0, 10)
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'key', comparator: '>=', value: startDateFormat },
    { prop: 'key', comparator: '<=', value: endDateFormat },
  ]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'panels_installed', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

export const DatabaseRef_CombinedVehiclesByDate_Query = (clientKey: string, startDate: Date, endDate: Date): Query<unknown> => {
  const startDateFormat = startDate.toISOString().slice(0, 10)
  const endDateFormat = endDate.toISOString().slice(0, 10)
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'key', comparator: '>=', value: startDateFormat },
    { prop: 'key', comparator: '<=', value: endDateFormat },
  ]
  let queryCursorsObject = {}
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'operations', 'gm_dashboards', 'cached_data', 'combined_vehicles', 'main'),
    queryOperatorsArray,
    [],
    queryCursorsObject,
    null,
  )
}

//////////////////////////////////////////
//		  ooOOOO BOILERPLATE FILE		//
//		 oo		 _____					//
//		_I__n_n__||_|| ________			//
//	  >(_________|_7_|-|______|			//
//	   /o ()() ()() o   oo  oo			//
//////////////////////////////////////////

///////////////////////////////
// Description
///////////////////////////////

/*
		DESCRIPTION / USAGE:
			Used to generate Error Dialogs

		TODO:
			[ ] Typescript - 2 instances of any - function copied from MUI Docs
			[ ] Typescript - 1 instance of @ts-expect-error - App bar doesn't like error as a color - probably need to use a different component
			[ ] Typescript - 1 instance of unknown
	*/

///////////////////////////////
// Imports
///////////////////////////////

import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, IconButton, Slide, Toolbar, Typography } from '@mui/material/'
import { TransitionProps } from '@mui/material/transitions'
import React from 'react'
import { Trans } from 'react-i18next'
import { themeVariables } from 'rfbp_aux/config/app_theme'
import { Icon } from 'rfbp_core/components/icons'
import { rLIB } from 'rfbp_core/localization/library'
import { TsType_UserInterface_AllDialogsDismissCallback } from 'rfbp_core/services/context'
import { getProp } from 'rfbp_core/services/helper_functions'
import { TsInterface_ErrorDialogObject } from 'rfbp_core/typescript/global_types'

///////////////////////////////
// Typescript
///////////////////////////////

///////////////////////////////
// Variables
///////////////////////////////

// Displayed Translatable Strings
// { sort-start } - displayed text - scoped sort plugin
const s_AN_UNEXPECTED_ERROR_HAS_OCCURRED: JSX.Element = <Trans>An unexpected error has occurred</Trans>
const s_ERROR: JSX.Element = <Trans>Error</Trans>
const s_NO_ADDITIONAL_DETAILS: JSX.Element = <Trans>No additional details</Trans>
// { sort-end } - displayed text

///////////////////////////////
// Functions
///////////////////////////////

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>,
) {
  return (
    <Slide
      direction="up"
      ref={ref}
      {...props}
    />
  )
})

///////////////////////////////
// Component
///////////////////////////////

export const ErrorDialog = (error: TsInterface_ErrorDialogObject, dismissDialog: TsType_UserInterface_AllDialogsDismissCallback): JSX.Element => {
  // Props
  let pr_errorMessage: TsInterface_ErrorDialogObject['message'] = getProp(error, 'message', s_AN_UNEXPECTED_ERROR_HAS_OCCURRED)
  let pr_errorDetails: TsInterface_ErrorDialogObject['details'] = getProp(error, 'details', s_NO_ADDITIONAL_DETAILS)
  let pr_errorCode: TsInterface_ErrorDialogObject['code'] = getProp(error, 'code', 'ER-D-CDED-UNK-01')

  // Hooks - useContext, useState, useReducer, other
  // { sort-start } - hooks

  // { sort-end } - hooks

  // Hooks - useEffect

  // Functions
  const handleClose = (): void => {
    dismissDialog()
  }

  // JSX Generation
  const rJSX_Component = (): JSX.Element => {
    let componentJSX = (
      <Box>
        <Dialog
          // container={() => document.getElementById('root')}
          TransitionComponent={Transition}
          aria-describedby="alert-dialog-description"
          aria-labelledby="alert-dialog-title"
          className="bp_dialog_md_width"
          keepMounted
          onClose={handleClose}
          open={true}
        >
          <AppBar
            position="static"
            color="inherit"
            sx={{ backgroundColor: themeVariables.error_main, color: themeVariables.white, fontWeight: 'bold' }}
          >
            <Toolbar>
              <IconButton
                aria-label="menu"
                color="inherit"
                disabled
                edge="start"
                size="large"
                sx={{ mr: 2, color: '#fff !important' }}
              >
                <Icon icon="triangle-exclamation" />
              </IconButton>
              <Typography
                component={'span'}
                variant={'h6'}
                sx={{ flexGrow: 1 }}
              >
                {s_ERROR}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent>
            <Box>
              <Typography
                variant="subtitle1"
                display="block"
              >
                {pr_errorMessage}
              </Typography>
              <Typography
                className="tw-mt-2"
                variant="body1"
                display="block"
              >
                {pr_errorDetails}
              </Typography>
              <Typography
                className="tw-mt-4"
                variant="caption"
                display="block"
              >
                {pr_errorCode}
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              color="error"
              onClick={handleClose}
              variant="outlined"
            >
              {rLIB('Dismiss')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
    return componentJSX
  }

  // Render
  return <>{rJSX_Component()}</>
}

///////////////////////////////
// Imports
///////////////////////////////

import { collection, CollectionReference, doc, DocumentData, DocumentReference, getFirestore, Query } from 'firebase/firestore'
import { generateDatabaseQuery, TsInterface_OrderByArray, TsInterface_QueryOperatorsArray } from 'rfbp_core/services/database_management'

///////////////////////////////
// Exports
///////////////////////////////

export const DatabaseRef_GHL_Contacts_Document = (clientKey: string, contactID: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'ghl_contacts', 'main', contactID)
}

export const DatabaseRef_GHL_Contacts_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'integrations', 'ghl_contacts', 'main')
}

export const DatabaseRef_GHL_Users_Document = (clientKey: string, contactID: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'ghl_users', 'main', contactID)
}

export const DatabaseRef_GHL_Users_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'integrations', 'ghl_users', 'main')
}

export const DatabaseRef_GHL_Conversations_Document = (clientKey: string, conversationID: string): DocumentReference<DocumentData> => {
  return doc(getFirestore(), 'clients', clientKey, 'integrations', 'ghl_conversations', 'main', conversationID)
}

export const DatabaseRef_GHL_Conversations_Collection = (clientKey: string): CollectionReference<DocumentData> => {
  return collection(getFirestore(), 'clients', clientKey, 'integrations', 'ghl_conversations', 'main')
}

export const DatabaseRef_GHL_Conversations_DateRange_Query = (clientKey: string, startTimestamp: number, endTimestamp: number): Query<DocumentData> => {
  let queryOperatorsArray: TsInterface_QueryOperatorsArray = [
    { prop: 'lastMessageDate', comparator: '>=', value: startTimestamp },
    { prop: 'lastMessageDate', comparator: '<=', value: endTimestamp },
  ]
  let queryCursorsObject = {}
  let orderByArray: TsInterface_OrderByArray = [{ prop: 'lastMessageDate', desc: true }]
  return generateDatabaseQuery(
    collection(getFirestore(), 'clients', clientKey, 'integrations', 'ghl_conversations', 'main'),
    queryOperatorsArray,
    orderByArray,
    queryCursorsObject,
    null,
  )
}
